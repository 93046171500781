import { SharedService } from 'shared/services/pms/shared.services';
import { PricelistItemApi } from './../../../../sdk/services/custom/PricelistItem';
import { Component, Input, OnInit } from '@angular/core';
import { WorkerApi, PricelistApi, UserAnalyticsApi } from 'shared/sdk';
import { AppStateService } from 'shared/services/app-state.service';
import { CommonService } from 'shared/services/common.service';
import { PreloaderService } from 'shared/services/preloader.service';
import axios from 'axios';
import * as querystring from 'querystring';
@Component({
  selector: 'app-catalog-card',
  templateUrl: './catalog-card.component.html',
  styleUrls: ['./catalog-card.component.css']
})
export class CatalogCardComponent implements OnInit {
  @Input() worker: any;
  @Input() tabId: any;
  @Input() recordTypes: any;
  @Input() currentUser: any;
  @Input() sidebarstate: any;
  @Input() fromJobdetails;
  workerDetails: any;
  accessType: string;
  infoTabName = 'vTalent';
  partnerAccountInfo: any;
  selectedWorker: any;
  priceBookName: any;
  jobSiteAddress: any;
  workerAddress: any;
  distanceFromJobsite: any;
  timeFromJobsite: any;
  isAdminPartner = false;
  workerAnalytics: any;
  timeFormat = 'L, LT z';

  constructor(private _commonService: CommonService,
    private _preloader: PreloaderService,
    private _pricelistItemApi: PricelistItemApi,
    private _workerApi: WorkerApi,
    private _appState: AppStateService,
    private _sharedService: SharedService,
    private _userAnalyticsApi: UserAnalyticsApi,
    ) {
    this._commonService.getServiceCatalogInfo().subscribe(res => {
      if (res && Object.keys(res).length) {
        this.partnerAccountInfo = res;
        this.priceBookName = this.partnerAccountInfo && this.partnerAccountInfo.priceList && this.partnerAccountInfo.priceList.Name;
      }
    });
  }

  ngOnInit() {
    this.workerDetails = {};
    this.accessType = this._appState.getAccessType();
    if (this.accessType === 'partner') {
      this.infoTabName = 'wProfile';
    }
    // To check if view as PMS mode is activated.
    const viewAsInfo = JSON.parse(localStorage.getItem('viewAsInfo'));
    this.isAdminPartner = (viewAsInfo && viewAsInfo.viewAsType === 'Partner' && viewAsInfo.isAdmin);
    if (this.isAdminPartner) {
      this.infoTabName = 'wProfile';
    }
    this.getAnalyticsData(this.worker.sfdcId);
    this.setJobsiteLocation();
  }

  setJobsiteLocation() {
    console.log('tabId>>>>>>>>>>>', this.tabId);
    const comma = (prop) => {
      return prop ? ', ' + prop : '';
    }
    if (this.partnerAccountInfo && this.partnerAccountInfo.jobsiteObj) {
          this.jobSiteAddress = this.partnerAccountInfo.jobsiteObj.Street__c
        + comma(this.partnerAccountInfo.jobsiteObj.City__c)
        + comma(this.partnerAccountInfo.jobsiteObj.State__c)
        + comma(this.partnerAccountInfo.jobsiteObj.Zip_Postal_Code__c)
        + comma(this.partnerAccountInfo.jobsiteObj.Country__c);
      if (this.partnerAccountInfo.jobsiteObj.geolocation__Latitude__s && this.partnerAccountInfo.jobsiteObj.geolocation__Longitude__s) {
        this.getDistanceFromJobsite();
      }
    }
    if (this.worker && this.worker.contact) {
      this.workerAddress = this.worker.contact.MailingCity
        + comma(this.worker.contact.MailingState)
        + comma(this.worker.Country_Code__c);
    }
  }

  getDistanceFromJobsite() {
    if (this.worker && this.worker.contact && this.worker.contact.Geo_Location__Latitude__s
      && this.worker.contact.Geo_Location__Longitude__s) {
      const workerLatLng = { lat: this.worker.contact.Geo_Location__Latitude__s, lng: this.worker.contact.Geo_Location__Longitude__s };
      const jobSiteLatLng = {lat: this.partnerAccountInfo.jobsiteObj.geolocation__Latitude__s, lng: this.partnerAccountInfo.jobsiteObj.geolocation__Longitude__s}
      this._sharedService.getDistanceTimeSummary(workerLatLng, jobSiteLatLng).then((res:any) => {
        console.log('DistanceTimeSummary>>>>>>>>>>>', res);
        if (res) {
          this.distanceFromJobsite = res.distance // distance in miles
          this.timeFromJobsite = res.time; // In hr and min format
        }
      }).catch(err => {
        console.log(err);
      })
    }
  }
  
  getWorkerDetails(worker) {
    const workerTalentTypes = [];
    this.selectedWorker = worker;
    if (worker.isCollapsed) {
      worker.talentInformations.forEach(w => {
        if (w && w.Talent_Type__c) {
          workerTalentTypes.push(w.Talent_Type__c);
        }
      });
      this._preloader.showPreloader();
      this.getvendorPriceBook(worker, workerTalentTypes);
    } else {
      this.workerDetails = {};
      this.toggleDetails(worker);

    }
  }

  /**
   * get all worker || vendor skilling and cost
   * @param worker selected worker object
   * @param workerTalentTypes worker talent type
   */
  getvendorPriceBook(worker, workerTalentTypes) {
    const reqObject = {
      fields: ['sfdcId', 'Name', 'Dispatch_Worker_num__c', 'Satisfaction_Rating_Average__c', 'Deliverables_Rating_Average__c',
        'Quality_Rating_Average__c', 'Professionalism_Rating_Average__c', 'On_Time_Rating_Average__c', 'Communication_Rating_Average__c',
        'RecordTypeId', 'GEO_Code__c', 'Primary_Global_Talent_Pool__c', 'Primary_Worker_Skilling_Profile__c', 'Contact__c', 'Account__c',
        'Email__c', 'Personal_Email_ID__c', 'ISG_Email_Address__c', 'Work_Phone_Number__c', 'Mobile_Phone_Number__c', 'State_Code__c', 'Country_Code__c', 'Worker_City__c', 'is_FSE_Worker__c', 'is_FTE_Worker__c'],
      where: {
        sfdcId: worker.sfdcId
      },
      include: [
        {
          relation: 'recordType',
          scope: {
            fields: ['sfdcId', 'Name']
          }
        },
        {
          relation: 'geoMetro',
          scope: {
            fields: ['sfdcId', 'Name', 'Region__c', 'Country__c', 'City__c']
          }
        },
        {
          relation: 'talentPoolWorkers',
          scope: {
            fields: ['sfdcId', 'Worker__c', 'Global_Talent_Pool__c'],
            include: [
              {
                relation: 'globalTalentPool',
                scope: {
                  fields: ['sfdcId', 'Resource_Type__c', 'Pool_Name__c', 'Name', 'Pool_Access_Type__c', 'Ownership__c', 'Community_Type__c'],
                }
              }
            ]
          }
        },
        {
          relation: 'skilling',
          scope: {
            fields: ['sfdcId', 'Name', 'Primary_Talent_Type__c', 'Worker_Skilling_Profile__c'],
            include: [
              {
                relation: 'talentType',
                scope: {
                  fields: ['sfdcId', 'Name', 'Talent_Type_Name__c', 'Description__c']
                }
              },
              {
                relation: 'talentInformations',
                scope: {
                  fields: ['sfdcId', 'SLA__c', 'Coverage_Hours__c', 'Talent_Level__c', 'Talent_Type__c'],
                  include: [
                    {
                      relation: 'talentType',
                      scope: {
                        fields: ['sfdcId', 'Name', 'Talent_Type_Name__c', 'Description__c']
                      }
                    },
                  ]
                }
              }
            ]
          }
        },
        {
          relation: 'contact',
          scope: {
            fields: ['sfdcId,', 'Contact_Name__c', 'MailingCity', 'MailingCountry', 'AccountId', 'GEO_Code__c'],
            include: [
              {
                relation: 'geometro',
                scope: {
                  fields: ['sfdcId', 'Name', 'Region__c', 'Country__c', 'City__c']
                }
              }
            ]
          }
        },
        {
          relation: 'account',
          scope: {
            fields: ['sfdcId,', 'Account_Type__c', 'Name', 'Community_Type__c', 'Vendor__c', 'Default_Pricelist__c', 'Business_Size_c__c'],
            include: [
              {
                relation: 'metroVirtualVendorPool',
                scope: {
                  fields: ['sfdcId,', 'Talent_Type__c', 'Service_Technical_Level__c', 'SLA__c',
                    'Coverage_Hours__c', 'Name', 'Metro_Pool__c'],
                  include: [
                    {
                      relation: 'TalentType',
                      scope: {
                        fields: ['sfdcId', 'Name']
                      }
                    }
                  ]
                }
              },
              // {
              //   relation: 'skilling',
              //   scope: {
              //     fields: ['sfdcId', 'Vendor__c'],
              //     where: {sfdcId: {neq : null}},
              //     include: [
              //       {
              //         relation: 'talentInformations',
              //         scope: {
              //           fields: ['sfdcId', 'Worker_Skilling_Profile__c', 'Talent_Type__c', 'Talent_Level__c', 'SLA__c', 'Coverage_Hours__c'],
              //         }
              //       }
              //     ]
              //   }
              // },
            ]
          }
        }
      ]
    }
    this._workerApi.find(reqObject).subscribe(res => {
      this._preloader.hidePreloader();
      this.workerDetails = this.prepareWorkerDetails(res[0], workerTalentTypes, worker);
      this.getPartnerPriceBook(workerTalentTypes);
      console.log('worker Details recieved', res);
      this.toggleDetails(worker);
    }, err => {
      this.workerDetails = {};
      this._preloader.hidePreloader();
      console.log('Error Fetching details: Worker data API not responding');
    });
  }

  /**
   * Get All Price list related to partner account and project
   */
  getPartnerPriceBook(workerTalentTypes) {
    let whereObj;
    if (this.partnerAccountInfo && this.partnerAccountInfo.priceList && this.partnerAccountInfo.priceList.sfdcId && this.partnerAccountInfo.Product2Id) {
      whereObj = { Pricebook2Id: this.partnerAccountInfo.priceList.sfdcId, Product2Id: this.partnerAccountInfo.Product2Id };
    } else if (this.currentUser && this.currentUser.account && this.currentUser.account.Default_Pricelist__c) {
      whereObj = { Pricebook2Id: this.currentUser.account.Default_Pricelist__c };
    }

    const filter = {
      where: whereObj,
      fields: ['sfdcId', 'Pricebook2Id', 'Coverage_Hours__c', 'CurrencyIsoCode', 'SLA__c', 'PPE_1HR_Standard_Price__c', 'PPE_2HR_Standard_Price__c', 'PPE_4HR_Standard_Price__c', 'PPE_8HR_Standard_Price__c', 'Product2Id', 'UnitPrice', 'FTE_Monthly_Rate__c', 'FTE_Daily_Rate_Standard__c'],
      include: [
        {
          relation: 'Product',
          scope: {
            where: {Talent_Type__c: {neq : null}, SLA__c: {inq: ['NBD', 'SBD2H', 'SBD4H', 'SBD6H', 'BKFL']}},
            fields: ['sfdcId,', 'SLA__c', 'Talent_Type__c']
          }
        },
        {
          relation: 'Pricelist',
          scope: {
            fields: ['sfdcId,', 'Name']
          }
        }
      ]
    }
    this._pricelistItemApi.find(filter).subscribe((res: any) => {
      if (res && res.length) {
       // this.prepareWorkercostbook({pricelist: res}, workerTalentTypes);
       this.priceBookName  = res[0].Pricelist && res[0].Pricelist.Name;
       const costBook = this.prepareWorkercostbook({PricelistItems: res}, workerTalentTypes);
       this.workerDetails['workerPriceBook'] = costBook;
      }
    });
  }

  toggleDetails(worker) {
    worker.isCollapsed = !worker.isCollapsed;
  }

  prepareWorkerDetails(detailsObj, workerTalentTypes, worker) {
    // worker GTP
    if (detailsObj && detailsObj.talentPoolWorkers && detailsObj.talentPoolWorkers.length) {
      const wGtp = { Resource_Type__c: [], Name: [], Pool_Access_Type__c: [], Ownership__c: [], Community_Type__c: [] };
      detailsObj.talentPoolWorkers.forEach(tpw => {
        if (tpw.globalTalentPool) {
          wGtp.Resource_Type__c.push(tpw.globalTalentPool.Resource_Type__c);
          wGtp.Name.push(tpw.globalTalentPool.Name);
          wGtp.Pool_Access_Type__c.push(tpw.globalTalentPool.Pool_Access_Type__c);
          wGtp.Ownership__c.push(tpw.globalTalentPool.Ownership__c);
          wGtp.Community_Type__c.push(tpw.globalTalentPool.Community_Type__c);
        }
      });
      detailsObj['gtpResource_Type__c'] =  this.joinComma(wGtp.Resource_Type__c);
      detailsObj['gtpName'] =  this.joinComma(wGtp.Name);
      detailsObj['gtpPool_Access_Type__c'] =  this.joinComma(wGtp.Pool_Access_Type__c);
      detailsObj['gtpOwnership__c'] =  this.joinComma(wGtp.Ownership__c);
      detailsObj['gtpCommunity_Type__c'] =  this.joinComma(wGtp.Community_Type__c);
    }

    // worker STPLC
    if (detailsObj && detailsObj.skilling && detailsObj.skilling.talentInformations && detailsObj.skilling.talentInformations.length) {
      const workerStplc = {talentTypeName: [], talentSLA: [], talentCoverageHours: [], talentLevel: []};
      detailsObj.skilling.talentInformations.forEach(wInfo => {
        // if (wInfo.talentType) {          
        //  workerStplc.talentTypeName.push(wInfo.talentType.Name);
        // }
        if(!workerStplc.talentTypeName.length) {
          workerStplc.talentTypeName.push(wInfo.talentType.Talent_Type_Name__c)
        }
        workerStplc.talentSLA.push(wInfo.SLA__c);
        workerStplc.talentCoverageHours.push(wInfo.Coverage_Hours__c);
        workerStplc.talentLevel.push(wInfo.Talent_Level__c);
      })
      detailsObj['skilling']['talentTypeName'] = this.joinComma(workerStplc.talentTypeName);
      detailsObj['skilling']['talentSLA'] = this.joinComma(workerStplc.talentSLA);
      detailsObj['skilling']['talentCoverageHours'] = this.joinComma(workerStplc.talentCoverageHours);
      detailsObj['skilling']['talentLevel'] = this.joinComma(workerStplc.talentLevel);
    }
   // Vendor STPLC
    if (detailsObj && detailsObj.account && detailsObj.account.skilling && detailsObj.account.skilling.length
      && detailsObj.account.skilling[0].talentInformations && detailsObj.account.skilling[0].talentInformations.length) {
      const stplc = { SLA__c: [], Coverage_Hours__c: [], Talent_Level__c: []};
        detailsObj.account.skilling[0].talentInformations.forEach(stInfo => {
          stplc.SLA__c.push(stInfo.SLA__c);
          stplc.Coverage_Hours__c.push(stInfo.Coverage_Hours__c);
          stplc.Talent_Level__c.push(stInfo.Talent_Level__c);
      });
      detailsObj['vtalentSLA'] = this.joinComma(stplc.SLA__c);
      detailsObj['vtalentCoverageHours'] = this.joinComma(stplc.Coverage_Hours__c);
      detailsObj['vtalentLevel'] = this.joinComma(stplc.Talent_Level__c);
    }
   // Vendor MVVP STPLC
    if (detailsObj && detailsObj.account
      && detailsObj.account.metroVirtualVendorPool && detailsObj.account.metroVirtualVendorPool.length) {
      const mvvpStplc = { mvvp_Service_Technical_Level__c: [], mvvp_SLA__c: [], mvvp_Coverage_Hours__c: [], mvvp_talentType: [], mvvp_geoMetro: [] }
      detailsObj.account.metroVirtualVendorPool.forEach(mvvp => {
        mvvpStplc.mvvp_Service_Technical_Level__c.push(mvvp.Service_Technical_Level__c);
        mvvpStplc.mvvp_SLA__c.push(mvvp.SLA__c);
        mvvpStplc.mvvp_Coverage_Hours__c.push(mvvp.Coverage_Hours__c);
        if (mvvp.TalentType && mvvp.TalentType.Name) {
        mvvpStplc.mvvp_talentType.push(mvvp.TalentType.Name);
        }
        if (mvvp.Name) {
        mvvpStplc.mvvp_geoMetro.push(mvvp.Name);
        }
      });
      detailsObj['account']['mvvp_Service_Technical_Level__c'] = this.joinComma(mvvpStplc.mvvp_Service_Technical_Level__c);
      detailsObj['account']['mvvp_SLA__c'] =  this.joinComma(mvvpStplc.mvvp_SLA__c);
      detailsObj['account']['mvvp_Coverage_Hours__c'] =  this.joinComma(mvvpStplc.mvvp_Coverage_Hours__c);
      detailsObj['account']['mvvp_talentType'] =  this.joinComma(mvvpStplc.mvvp_talentType);
      detailsObj['account']['mvvp_geoMetro'] =  this.joinComma(mvvpStplc.mvvp_geoMetro);
    }
    // Worker PriceList
    if (worker && worker.account && worker.account.pricelist) {
      // Remove code when we enabled service catalog for this for msp user
      if (worker.account && worker.account.pricelist) {
      detailsObj['vendorPricebookName'] = worker.account.pricelist.Name;
      }
      detailsObj['workerCostBook'] = this.prepareWorkercostbook(worker.account.pricelist, workerTalentTypes);
    }
    return detailsObj;
  }

  joinComma(values) {
    return Array.from(new Set(values)).join();
  }


  prepareWorkercostbook(pricelist, workerTalentTypes) {
    const obj = {};
    if (pricelist.PricelistItems && pricelist.PricelistItems.length) {
      obj['currencyIso'] = pricelist.PricelistItems[0].CurrencyIsoCode;
      obj['payrateRequested'] = '---';
      obj['salaryTerm'] = '---';
      obj['ppe1Hour'] = {};
      obj['ppe2Hour'] = {};
      obj['ppe4Hour'] = {};
      obj['ppe8Hour'] = {};
      pricelist.PricelistItems.forEach(item => {
        if (item.Product && item.Product.SLA__c && item.Product.Talent_Type__c && workerTalentTypes.includes(item.Product.Talent_Type__c)) {
          switch (item.Product.SLA__c) {
            case 'NBD': {
              obj['NBDPrice'] = item.sfdcId;
              obj['ppe1Hour']['nbd'] = (item.PPE_1HR_Standard_Price__c) ? parseFloat(item.PPE_1HR_Standard_Price__c).toFixed(2) : null;
              obj['ppe2Hour']['nbd'] = (item.PPE_2HR_Standard_Price__c) ? parseFloat(item.PPE_2HR_Standard_Price__c).toFixed(2) : null;
              obj['ppe4Hour']['nbd'] = (item.PPE_4HR_Standard_Price__c) ? parseFloat(item.PPE_4HR_Standard_Price__c).toFixed(2) : null;
              obj['ppe8Hour']['nbd'] = (item.PPE_8HR_Standard_Price__c) ? parseFloat(item.PPE_8HR_Standard_Price__c).toFixed(2) : null;
              break;
            };
            case 'SBD2H': {
              obj['SBD2HPrice'] = item.sfdcId;
              obj['ppe1Hour']['sbd2h'] = (item.PPE_1HR_Standard_Price__c) ? parseFloat(item.PPE_1HR_Standard_Price__c).toFixed(2) : null;
              obj['ppe2Hour']['sbd2h'] = (item.PPE_2HR_Standard_Price__c) ? parseFloat(item.PPE_2HR_Standard_Price__c).toFixed(2) : null;
              obj['ppe4Hour']['sbd2h'] = (item.PPE_4HR_Standard_Price__c) ? parseFloat(item.PPE_4HR_Standard_Price__c).toFixed(2) : null;
              obj['ppe8Hour']['sbd2h'] = (item.PPE_8HR_Standard_Price__c) ? parseFloat(item.PPE_8HR_Standard_Price__c).toFixed(2) : null;
              break;
            };
            case 'SBD4H': {
              obj['SBD4HPrice'] = item.sfdcId;
              obj['ppe1Hour']['sbd4h'] = (item.PPE_1HR_Standard_Price__c) ? parseFloat(item.PPE_1HR_Standard_Price__c).toFixed(2) : null;
              obj['ppe2Hour']['sbd4h'] = (item.PPE_2HR_Standard_Price__c) ? parseFloat(item.PPE_2HR_Standard_Price__c).toFixed(2) : null;
              obj['ppe4Hour']['sbd4h'] = (item.PPE_4HR_Standard_Price__c) ? parseFloat(item.PPE_4HR_Standard_Price__c).toFixed(2) : null;
              obj['ppe8Hour']['sbd4h'] = (item.PPE_8HR_Standard_Price__c) ? parseFloat(item.PPE_8HR_Standard_Price__c).toFixed(2) : null;
              break;
            };
            case 'SBD6H': {
              obj['SBD8HPrice'] = item.sfdcId;
              obj['ppe1Hour']['sbd8h'] = (item.PPE_1HR_Standard_Price__c) ? parseFloat(item.PPE_1HR_Standard_Price__c).toFixed(2) : null;
              obj['ppe2Hour']['sbd8h'] = (item.PPE_2HR_Standard_Price__c) ? parseFloat(item.PPE_2HR_Standard_Price__c).toFixed(2) : null;
              obj['ppe4Hour']['sbd8h'] = (item.PPE_4HR_Standard_Price__c) ? parseFloat(item.PPE_4HR_Standard_Price__c).toFixed(2) : null;
              obj['ppe8Hour']['sbd8h'] = (item.PPE_8HR_Standard_Price__c) ? parseFloat(item.PPE_8HR_Standard_Price__c).toFixed(2) : null;
              break;
            };
          }

          if ( this.selectedWorker && this.selectedWorker.is_FTE_Worker__c) {
            obj['UnitPriceSfdcId'] = item.sfdcId;
            obj['fteHourly'] = item.UnitPrice ?  parseFloat(item.UnitPrice).toFixed(2) : null;
            obj['fteDaily'] = item.FTE_Daily_Rate_Standard__c ? parseFloat(item.FTE_Daily_Rate_Standard__c).toFixed(2) : null;
            obj['fteMonthly'] = item.FTE_Monthly_Rate__c ? parseFloat(item.FTE_Monthly_Rate__c).toFixed(2) : null;
          }
        }
      });
    }
    console.log('PriceObj>>>>>>', obj);
    return obj;
  }

  switchCandInfoTab(tabName: string) {
    this.infoTabName = tabName;
  }
  getAnalyticsData(workerId){
    let filter = {workerId: workerId }
    // if(this.jobId){
    //   filter['jobId'] = this.jobId
    // }
    this._userAnalyticsApi.getWorkerJobAccessData(filter).subscribe(
      res => {
        this.workerAnalytics = res;
        this.workerAnalytics['lastLogin'] = this.workerAnalytics.lastLogin ? this._commonService.dateFormate(this.workerAnalytics.lastLogin, '', this.timeFormat) : '';
        this.workerAnalytics['lastJobAccess'] = this.workerAnalytics.lastJobAccess ? this._commonService.dateFormate(this.workerAnalytics.lastJobAccess, '', this.timeFormat) : '';
        this.workerAnalytics['userRegistration'] = this.workerAnalytics.userRegistration ? this._commonService.dateFormate(this.workerAnalytics.userRegistration, '', this.timeFormat) : ''
        this.workerAnalytics['userDeviceRegistration'] = this.workerAnalytics.userDeviceRegistration ? this._commonService.dateFormate(this.workerAnalytics.userDeviceRegistration, '', this.timeFormat) : ''
      },
      err => {
        console.log(err)
      }
    )
  }

}
