import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isInSelectedTeam'
})
export class IsInSelectedTeamPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if(args && args.length){
      var filteredValues = [];
      args.forEach(element => {
        value.forEach(ele => {
          var record = ele.groupIds.indexOf(element);
          if(record >= 0 && filteredValues.indexOf(ele) < 0){
            filteredValues.push(ele);
          }
        });
      });
      return filteredValues.sort((a,b) => (a.Name > b.Name) ? 1 : ((b.Name > a.Name) ? -1 : 0));
    }else{
      return [];
    }
  }

}
