import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkOrderRangeLookupComponent } from './work-order-range-lookup.component';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule
  ],
  declarations: [WorkOrderRangeLookupComponent],
  exports : [WorkOrderRangeLookupComponent]
})
export class WorkOrderRangeLookupModule { }
