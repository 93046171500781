import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, NavigationEnd, ActivatedRoute } from '@angular/router';

import { UsersApi } from '../sdk/services/custom/Users';
import { AppStateService } from './app-state.service';
import { CommonService } from './common.service';
import { SocketService } from '../services/socket.service';
import { MessageSocketService } from '../services/message-socket.service';
import { PersonalConversationSocketService } from 'msp/shared/my-work-place/personal-conversation/services/personal-conversation-socket.service';
import { AuthService } from './authentication.service';
import { NotificationSocketService } from './notification-socket.service';
import { environment } from 'environments/environment'

@Injectable()

export class AuthGuard implements CanActivate {

    constructor(
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private _appState: AppStateService,
        private _usersApi: UsersApi,
        private _socketService: SocketService,
        private _authService: AuthService,
        private _messageSocketService: MessageSocketService,
        private _notificationSocketService: NotificationSocketService,
        private _personalConversationSocketService: PersonalConversationSocketService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
           
             if(state && state.url && state.url.indexOf('vms/jobs/view/') !== -1){
                 const jobSfdcId = state.url.split('/')[4];
              window.location.href = "serviceo://app.serviceo.me/job?sfdcid="+jobSfdcId;
            }
         
          }
        if (this._authService.authenticated) {
            if (!this._appState.getSelectedAccount()) {
                this._authService.setAppState({
                    appData: JSON.parse(localStorage.getItem('appData')),
                    accessType: localStorage.getItem('accessType'),
                    userTypeSlug: localStorage.getItem('userTypeSlug'),
                    filePermissions: JSON.parse(localStorage.getItem('filePermissions')),
                    manageUser: JSON.parse(localStorage.getItem('manageUser')),
                })
            }
            this.setDataTitle();
            return true;
        } else {
            localStorage.setItem('redirectUrl', state.url);
            this._router.navigate(['/login']);
            return false;
        }
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }

    setDataTitle() {
        this._router.events
            .filter((event) => event instanceof NavigationEnd)
            .map(() => this._activatedRoute)
            .map((route) => {
                while (route.firstChild) {
                    route = route.firstChild;
                } return route;
            }).take(1).subscribe((event) => {
                const permissions = (event.data['value']['permissions']) ? event.data['value']['permissions'] : null;
                const accessTypePermissions = (event.data['value']['accessTypePermissions']) ?
                event.data['value']['accessTypePermissions'] : null;
                const accessType = this._appState.getAccessType();
              //  const manageUser = this._appState.getmanageUser();
                const geappData = JSON.parse(localStorage.getItem('appData'));
                const userGroup = geappData && geappData.user && geappData.user.group || [];
                const allowUserManagemet = userGroup && userGroup.includes(environment.groupGSTLUMAT0BB) || false;
                if (permissions && !permissions.includes(accessType)) {
                    this._router.navigate(['/pages-error-403']); // when navigated url not allowed
                } else if (accessTypePermissions && accessTypePermissions[accessType] && !accessTypePermissions[accessType]
                    .includes(this._appState.getUserTypeSlug())) {
                    this._router.navigate(['/pages-error-403']); // when navigated url not allowed
                } else {
                    if (event['_routerState'] && event['_routerState'].snapshot && event['_routerState'].snapshot.url && event['_routerState'].snapshot.url.indexOf('user-management') === -1) {
                        this.adminAccessPermission(event.data['value']);
                    } else if(!allowUserManagemet) {
                        this.userManagementAccess(event.data['value']);
                    }
                    this._socketService.setCountNotification((event.data['value']['title']) ? event.data['value']['title'] : 'ServiceO');
                    setTimeout(()=>{
                        this._messageSocketService.initializeSocket();
                        this._personalConversationSocketService.initializeSocket();
                        this._notificationSocketService.initializeSocket();
                    }, 1000)
                }
            });
    }

    adminAccessPermission(adminPermission) {
        if (adminPermission['adminAccessPermission']) {
            this._usersApi.adminAccessPermission().subscribe(res => {
                if (!res) {
                    this._router.navigate(['/pages-error-403']);
                }
            }, err => {
                console.log(err);
            });
        }
    }

    userManagementAccess(adminPermission) {
        if (adminPermission['adminAccessPermission']) {
            let userTypeSlug = localStorage.getItem('userTypeSlug');
            if (userTypeSlug && userTypeSlug.toLowerCase() != 'super-admin') {
                this._router.navigate(['/pages-error-403']);
            }

        }
    }
}

/*Check user logged in or not. If user logged in than redirect to user landing page.*/
@Injectable()
export class ActiveUserAuthGuard implements CanActivate {

    constructor(
        private _router: Router,
        private _appState: AppStateService,
        private _commonService: CommonService,
        private _usersApi: UsersApi,
        private _authService: AuthService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this._authService.authenticated) {
            const appState = JSON.parse(localStorage.getItem('appData'));
               this._authService.setAppState({
                appData: appState,
                accessType: localStorage.getItem('accessType'),
                userTypeSlug: localStorage.getItem('userTypeSlug'),
                filePermissions:localStorage.getItem('filePermissions')
            })
            if (appState && appState.user && appState.user && appState.user.redirectUrl) {
                const redirectUrl = appState.user.redirectUrl;
                if (redirectUrl.indexOf('?') !== -1) {
                  const url = redirectUrl.slice(0, redirectUrl.indexOf('?'));
                  const querystr = redirectUrl.slice( redirectUrl.indexOf('?') + 1 );
                  let queryParamObj = {};
                  const allQueryParams = querystr.split('&');
                  allQueryParams.forEach((el) => {
                    queryParamObj = { ...queryParamObj,
                      [el.slice(0, el.indexOf('='))]: `${el.slice(el.indexOf('=') + 1)}`,
                    };
                  });
                  this._router.navigate([url], { queryParams: queryParamObj });
                } else {
                  this._router.navigate([redirectUrl]);
                }
            }
            return false;
        }
        return true;
    }
}
