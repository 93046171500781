/* tslint:disable */
import {
  Account
} from '../index';

declare var Object: any;
export interface VCSQDLeadInterface {
  "sfdcId"?: string;
  "Name"?: string;
  "RecordTypeId"?: string;
  "ACH_Routing_Number__c"?: string;
  "AX_Identification_Number__c"?: string;
  "Account_Created__c"?: boolean;
  "After_Hours_Multiplier__c"?: number;
  "Annual_Revenue_Consulting__c"?: number;
  "Annual_Revenue_Hardware__c"?: number;
  "Annual_Revenue_Logistics__c"?: number;
  "Annual_Revenue_Others__c"?: number;
  "Annual_Revenue_Software__c"?: number;
  "Annual_Revenue_Technical__c"?: number;
  "Annual_Revenue__c"?: number;
  "Approval_Levels_Required__c"?: string;
  "Approver__c"?: string;
  "Bachelors_Degree__c"?: string;
  "Bank_Account_Type__c"?: string;
  "Bank_Account_number__c"?: number;
  "Beneficiary_Account_Name__c"?: string;
  "Beneficiary_Bank_Address_City__c"?: string;
  "Beneficiary_Bank_Address_Country__c"?: string;
  "Beneficiary_Bank_Address_State_Province__c"?: string;
  "Beneficiary_Bank_Address_Street_address__c"?: string;
  "Beneficiary_Bank_Address_Zip_Postal_Cod__c"?: string;
  "Beneficiary_Bank_Name__c"?: string;
  "Billing_Contact_City__c"?: string;
  "Billing_Contact_Copied__c"?: boolean;
  "Billing_Contact_Country__c"?: string;
  "Billing_Contact_Email__c"?: string;
  "Billing_Contact_First_Name__c"?: string;
  "Billing_Contact_Last_Name__c"?: string;
  "Billing_Contact_LinkedIn_Profile_Link__c"?: string;
  "Billing_Contact_Mobile__c"?: string;
  "Billing_Contact_Overview__c"?: string;
  "Technical_Contact_Overview__c"?: string;
  "current_Field_Service_Dispatch_Volume__c"?: string;
  "Billing_Contact_Phone__c"?: string;
  "Billing_Contact_State__c"?: string;
  "Billing_Contact_Street__c"?: string;
  "Billing_Contact_Time_Zone__c"?: string;
  "Billing_Contact_Title__c"?: string;
  "Billing_Contact_Zip__c"?: string;
  "Billing_Country__c"?: string;
  "Birth_Date__c"?: Date;
  "On_Demand_Technical_Resources_Pricing__c"?: string;
  "On_Demand_Technical_Resources_Coverage__c"?: string;
  "Business_Owner_Contact_Copied__c"?: boolean;
  "CEO_Contact_Copied__c"?: boolean;
  "CFO_Contact_Copied__c"?: boolean;
  "Case_Management__c"?: string;
  "Certifications__c"?: string;
  "Company_Certifications__c"?: string;
  "Company_City__c"?: string;
  "Company_Contact_Title__c"?: string;
  "Company_Country__c"?: string;
  "Company_Dispatch_Group_Email__c"?: string;
  "Company_Email__c"?: string;
  "Company_Established_Year__c"?: number;
  "Company_LinkedIn_URL__c"?: string;
  "Company_Overview__c"?: string;
  "Company_Phone__c"?: string;
  "Company_Postalcode__c"?: string;
  "Company_Reference_code__c"?: string;
  "Company_State__c"?: string;
  "Company_Street__c"?: string;
  "Company_Website__c"?: string;
  "Company__c"?: string;
  "Comprehensive_General_Liability_Details__c"?: string;
  "Comprehensive_automobile_liability_Detai__c"?: string;
  "Contact_City_CEO__c"?: string;
  "Contact_City_Field__c"?: string;
  "Contact_City_Sales__c"?: string;
  "Contact_City__c"?: string;
  "Contact_Country_CEO__c"?: string;
  "Contact_Country_Field__c"?: string;
  "Contact_Country_Sales__c"?: string;
  "Contact_Country__c"?: string;
  "Contact_Email_CEO__c"?: string;
  "Contact_Email_Field__c"?: string;
  "Contact_Email_Sales__c"?: string;
  "Contact_First_Name_CEO__c"?: string;
  "Contact_First_Name_Field__c"?: string;
  "Contact_First_Name_Sales__c"?: string;
  "Contact_First_Name__c"?: string;
  "Contact_LAst_Name_Field__c"?: string;
  "Contact_Last_Name_CEO__c"?: string;
  "Contact_Last_Name_Sales__c"?: string;
  "Contact_Last_Name__c"?: string;
  "Contact_LinkedIn_Profile_Link_CEO__c"?: string;
  "Contact_LinkedIn_Profile_Link_Field__c"?: string;
  "Contact_LinkedIn_Profile_Link_Sales__c"?: string;
  "Contact_Mobile_CEO__c"?: string;
  "Contact_Mobile_Field__c"?: string;
  "Contact_Mobile_Sales__c"?: string;
  "Contact_Mobile__c"?: string;
  "Contact_Overview__c"?: string;
  "Contact_Phone_CEO__c"?: string;
  "Contact_Phone_Field__c"?: string;
  "Contact_Phone_New__c"?: string;
  "Contact_Phone_Sales__c"?: string;
  "Contact_Phone__c"?: string;
  "Contact_State_CEO__c"?: string;
  "Contact_State_Field__c"?: string;
  "Contact_State_Sales__c"?: string;
  "Contact_State__c"?: string;
  "Contact_Street_CEO__c"?: string;
  "Contact_Street_Field__c"?: string;
  "Contact_Street_Sales__c"?: string;
  "Contact_Street__c"?: string;
  "Contact_Time_Zone__c"?: string;
  "Contact_Timezone_CEO__c"?: string;
  "Contact_Timezone_Sales__c"?: string;
  "Contact_Title_CEO__c"?: string;
  "Contact_Title_Field__c"?: string;
  "Contact_Title_Sales__c"?: string;
  "Contact_Type__c"?: string;
  "Contact_Zip_CEO__c"?: string;
  "Contact_Zip_Field__c"?: string;
  "Contact_Zip_Sales__c"?: string;
  "Contact_Zip__c"?: string;
  "Copy_of_MSA__c"?: string;
  "Countries_Supported__c"?: string;
  "Coverage_Hours__c"?: string;
  "CreatedById"?: string;
  "CreatedDate"?: Date;
  "Created_by_Contact__c"?: string;
  "CurrencyIsoCode"?: string;
  "Currency_you_desire_to_do_transaction__c"?: string;
  "Current_Approval_Level__c"?: string;
  "Current_Employer__c"?: string;
  "Custom_brand_badging__c"?: string;
  "Customer_Overview_Field_Services__c"?: string;
  "Customer_Overview_Key__c"?: string;
  "DataCenter_Operations_Hardware_Assembler__c"?: boolean;
  "Data_Center_Assembler_Next_Buss_Day_SLA__c"?: boolean;
  "Data_Center_Engr_Next_Business_Day_SLA__c"?: boolean;
  "Data_Center_Facilities_Manager__c"?: boolean;
  "Data_Center_Facilities_Technician_Level1__c"?: boolean;
  "Data_Center_Mgr_Next_Business_Day_SLA__c"?: boolean;
  "Data_Center_Operations_Engineer_Level_1__c"?: boolean;
  "Data_Center_Technician_Next_Buss_Day_SLA__c"?: boolean;
  "Decommission_Disposal__c"?: string;
  "Deployment_Project_Only__c"?: boolean;
  "Describe_Your_Staffing_Services__c"?: string;
  "Desktop_Support_Next_Business_Day_SLA__c"?: boolean;
  "Desktop_Support_Technicians_Level_1__c"?: boolean;
  "Distance_from_capital_KM__c"?: number;
  "Do_you_accept_bank_payments_in_EURO_Ye__c"?: string;
  "Do_you_accept_bank_payments_in_US_Dollar__c"?: string;
  "Do_you_currently_support_customers__c"?: string;
  "Do_you_have_Comprehensive_General_Ins__c"?: string;
  "Do_you_have_Comprehensive_automobile_Ins__c"?: string;
  "Do_you_have_Employer_s_liability_insuran__c"?: string;
  "Do_you_have_Professional_and_Cyber_Ins__c"?: string;
  "Do_you_have_Umbrella_Liability_insurance__c"?: string;
  "Do_you_have_Worker_s_Compensation_insura__c"?: string;
  "Do_you_provide_EOR_Services__c"?: string;
  "Do_you_provide_FSE_in_Selected_Country__c"?: boolean;
  "Provide_FSE_in_Selected_Country__c"?: string;
  "Do_you_provide_FTE_in_Selected_Country__c"?: boolean;
  "Provide_FTE_in_Selected_Country__c"?: string;
  "Do_you_provide_ITAD_Services__c"?: string;
  "Do_you_provide_Next_business_day_SLA__c"?: boolean;
  "Do_you_provide_Next_businessday_SLA_NBD__c"?: boolean;
  "Do_you_provide_Same_business_day_SLA_SBD__c"?: boolean;
  "Do_you_provide_Same_business_day_SLASBD__c"?: string;
  "Do_you_provide_technician__c"?: string;
  "Employers_liability_insurance_Details__c"?: string;
  "EUC_DSS_Break_Fi_Fix__c"?: string;
  "explain_your_policy_Coverage_Details_her__c"?: string;
  "Field_Contact_Copied__c"?: boolean;
  "Field_Engineer_4HR_SLA_Rate_US_HR__c"?: number;
  "Field_Engineer_NBD_SLA_Rate_US_HR__c"?: number;
  "Field_Project_Manager_Rate_US_HR__c"?: number;
  "Field_Service_24x7_Onsite_SLA__c"?: boolean;
  "Form_Type__c"?: string;
  "FS_Dispatch_Management_Overview_External__c"?: string;
  "FS_On_HW_Same_Bus_Day_4Hours_SLA_24x7__c"?: boolean;
  "FS_On_HW_Same_Business_Day_4_Hours_SLA__c"?: boolean;
  "FS_Onsite_Next_Business_Day_SLA__c"?: boolean;
  "GDPR_Compliance_Details__c"?: string;
  "Gender__c"?: string;
  "Graduation_Date__c"?: Date;
  "GST_VAT_Registration_Number__c"?: string;
  "Have_legal_entity_in_Country_Selected__c"?: string;
  "Have_you_completed_all_the_steps_of_RFI__c"?: string;
  "Helpdesk_Next_Business_Day_SLA__c"?: boolean;
  "Help_Desk_Technical_Support_Engineer__c"?: boolean;
  "Highest_Level_of_Talent__c"?: string;
  "Holiday_Multiplier__c"?: number;
  "Hourly_Rate__c"?: number;
  "IBAN__c"?: string;
  "If_NOT_US_Dollars_EURO_specify_curren__c"?: string;
  "Industry__c"?: string;
  "Initial_Email_Sent__c"?: boolean;
  "Initiate_Background_Check__c"?: boolean;
  "Integrate_with_Case_System__c"?: string;
  "Iron_Comments__c"?: string;
  "Iron_Comments_All_Contact__c"?: string;
  "Iron_Comments_All_Contact_MSP__c"?: string;
  "Iron_Comments_Banking__c"?: string;
  "Iron_Comments_Banking_MSP__c"?: string;
  "Iron_Comments_Capabilities__c"?: string;
  "Iron_Comments_Capabilities_MSP__c"?: string;
  "Iron_Comments_Checklist__c"?: string;
  "Iron_Comments_Checklist_MSP__c"?: string;
  "Iron_Comments_Contact__c"?: string;
  "Iron_Comments_Contact_MSP__c"?: string;
  "Iron_Comments_Overview_MSP__c"?: string;
  "Iron_Comments_Worker__c"?: string;
  "Iron_Comments_Worker_MSP__c"?: string;
  "Iron_Status_All_Contacts__c"?: string;
  "Iron_Status_Banking__c"?: string;
  "Iron_Status_Capabilities__c"?: string;
  "Iron_Status_Checklist__c"?: string;
  "Iron_Status_Contacts__c"?: string;
  "Iron_Status__c"?: string;
  "Iron_Status_Workers__c"?: string;
  "key_customer_references__c"?: string;
  "Languages_Supported__c"?: string;
  "Lead_Country__c"?: string;
  "LeadId__c"?: string;
  "LeadRecordId__c"?: string;
  "LinkedIn_Employee_Count__c"?: number;
  "LinkedIn_Profile_Link__c"?: string;
  "Linux_Server_Software_Break_Fix__c"?: string;
  "Logged_in_Contact__c"?: string;
  "LTFactor__c"?: number;
  "Masters_Degree__c"?: string;
  "Minimum_Hours__c"?: number;
  "Minimum_Hours_of_Dispatch__c"?: string;
  "Minimum_hours_you_require_for_NBD__c"?: number;
  "Minimum_hours_you_require_for_SBD__c"?: number;
  "Minimum_number_of_hours_you_for_NBD__c"?: string;
  "Minimum_number_of_hours_you_for_SBD__c"?: string;
  "MSP_Required_Countries__c"?: string;
  "Multiple_Location__c"?: boolean;
  "Multiple_Location_Details__c"?: string;
  "Network_Equipment_Break_Fix__c"?: string;
  "Network_Server_Engineer_MS_Windows__c"?: boolean;
  "Network_Server_Tech_Next_Buss_Day_SLA__c"?: boolean;
  "Network_Server_Technician_MS_Windows__c"?: boolean;
  "Network_Srv_Engr_Next_Business_Day_SLA__c"?: boolean;
  "Network_Storage_Engineer_Level_2__c"?: boolean;
  "Network_Storage_Engr_Next_Buss_Day_SLA__c"?: boolean;
  "Next_Business_Day_Service__c"?: boolean;
  "Number_of_Contractors_Field_Technicians__c"?: string;
  "Number_of_Employees_Field_Technicians__c"?: string;
  "Number_of_Employees_Total__c"?: number;
  "Number_of_employees_on_LinkedIn__c"?: number;
  "Number_of_Sub_Contractors_Field_Technic__c"?: string;
  "FS_Dispatch_Management_Overview_Internal__c"?: string;
  "Parent_VCSQDLead__c"?: string;
  "Parent_Lead__c"?: string;
  "PgMO_Milestone__c"?: string;
  "PgMO_Program__c"?: string;
  "PgMO_Project__c"?: string;
  "PgMO_Task_Bank__c"?: string;
  "PgMO_Task_Cap__c"?: string;
  "PgMO_Task_Checklist__c"?: string;
  "PgMO_Task_Contact__c"?: string;
  "PgMO_Task_Overview__c"?: string;
  "PgMO_Task_Worker__c"?: string;
  "Post_Graduation_Date__c"?: Date;
  "PPE_1HR__c"?: number;
  "PPE_2HR__c"?: number;
  "PPE_4HR__c"?: number;
  "PPE_8HR__c"?: number;
  "Preferred_Countries__c"?: string;
  "Primary_CFO_City__c"?: string;
  "Primary_CFO_Country__c"?: string;
  "Primary_CFO_Email__c"?: string;
  "Primary_CFO_First_Name__c"?: string;
  "Primary_CFO_Last_Name__c"?: string;
  "Primary_CFO_Linkedin_Profile_Link__c"?: string;
  "Primary_CFO_Mobile__c"?: string;
  "Primary_CFO_Phone__c"?: string;
  "Primary_CFO_State__c"?: string;
  "Primary_CFO_Street__c"?: string;
  "Primary_CFO_Timezone__c"?: string;
  "Primary_CFO_Title__c"?: string;
  "Primary_CFO_Zip__c"?: string;
  "Professional_and_Cyber_Liability_Details__c"?: string;
  "Project_Manager_Onsite_IT_Project__c"?: boolean;
  "Project_Mgr_Onsite_Next_Business_Day_SLA__c"?: boolean;
  "Provide_Ware_housing_service_for_FRU__c"?: string;
  "Rate_multiplier_for_ticket_After_Hours__c"?: number;
  "Rate_multiplier_for_ticket_Holidays__c"?: number;
  "Rate_multiplier_for_ticket_Weekend__c"?: number;
  "Rate_US_1__c"?: number;
  "Rate_US_10__c"?: number;
  "Rate_US_11__c"?: number;
  "Rate_US_12__c"?: number;
  "Rate_US_13__c"?: number;
  "Rate_US_14__c"?: number;
  "Rate_US_15__c"?: number;
  "Rate_US_16__c"?: number;
  "Rate_US_17__c"?: number;
  "Rate_US_18__c"?: number;
  "Rate_US_19__c"?: number;
  "Rate_US_2__c"?: number;
  "Rate_US_20__c"?: number;
  "Rate_US_21__c"?: number;
  "Rate_US_22__c"?: number;
  "Rate_US_23__c"?: number;
  "Rate_US_3__c"?: number;
  "Rate_US_4__c"?: number;
  "Rate_US_5__c"?: number;
  "Rate_US_6__c"?: number;
  "Rate_US_7__c"?: number;
  "Rate_US_8__c"?: number;
  "Rate_US_9__c"?: number;
  "Reordtypeid__c"?: string;
  "Resume__c"?: string;
  "Sales_Contact_Copied__c"?: boolean;
  "Same_Day_Service__c"?: boolean;
  "Server_HW_Break_Fix__c"?: string;
  "Service_Coverage_Countries__c"?: string;
  "Service_Dispatch_SLA_Priority__c"?: string;
  "Service_Global_Ref__c"?: string;
  "Service_Tax_Registration_number__c"?: string;
  "Service_Technical_Level__c"?: string;
  "Service_type__c"?: string;
  "Site_Guest_Access_Helper__c"?: string;
  "Site_Survey__c"?: string;
  "Skill_Level__c"?: string;
  "Skills__c"?: string;
  "SLA__c"?: string;
  "Source__c"?: string;
  "Specialty__c"?: string;
  "Step_1_Approved__c"?: boolean;
  "Step_2_Approved__c"?: boolean;
  "Storage_Equipment_Break_Fix__c"?: string;
  "Submission_Date__c"?: Date;
  "Lead_Source__c"?: string;
  "Swift_Code__c"?: string;
  "T_M__c"?: number;
  "Talent_Type__c"?: string;
  "Tax_if_Billed_from_to__c"?: number;
  "Tax_if_Billed_from_to_USA__c"?: number;
  "Technical_Contact_City__c"?: string;
  "Technical_Contact_Copied__c"?: boolean;
  "Technical_Contact_Country__c"?: string;
  "Technical_Contact_Email__c"?: string;
  "Technical_Contact_First_Name__c"?: string;
  "Technical_Contact_Last_Name__c"?: string;
  "Technical_Contact_LinkedIn_Profile_Link__c"?: string;
  "Technical_Contact_Mobile__c"?: string;
  "Technical_Contact_Phone__c"?: string;
  "Technical_Contact_State__c"?: string;
  "Technical_Contact_Street__c"?: string;
  "Technical_Contact_Time_Zone__c"?: string;
  "Technical_Contact_Title__c"?: string;
  "Technical_Contact_Zip__c"?: string;
  "Technical_Onboarding__c"?: string;
  "Technology_Supported_Advance_Expertise__c"?: string;
  "Technology_Supported_Basic_Expertise__c"?: string;
  "Technology_Supported_Certified_Staff__c"?: string;
  "timeZoneId__c"?: string;
  "Total_Experience_Months__c"?: number;
  "Training_Dispatch__c"?: string;
  "Types_of_Technical_Resources_Specialty__c"?: string;
  "Vendor__c"?: string;
  "Vendor_Expertise__c"?: string;
  "Vendor_has_VCSQDLead_Ref__c"?: boolean;
  "Vendor_Has_Logged_in_Contact__c"?: boolean;
  "Vendor_Has_PgMO_Program__c"?: boolean;
  "Vendors_Supported_Advance_Expertise__c"?: string;
  "Vendors_Supported_Certified_Staff__c"?: string;
  "Vendor_Status__c"?: string;
  "Vendor_Status_Step2__c"?: string;
  "Vendor_Status_Step3__c"?: string;
  "VOIP_Equipment_Break_Fix__c"?: string;
  "Weekend_Multiplier__c"?: number;
  "what_is_the_best_language_option__c"?: string;
  "Windows_Server_Software_Break_Fix__c"?: string;
  "Workers_Compensation_insurance_Details__c"?: string;
  "cancelled_Cheque_Details__c"?: string;
  "Tax_Identification_Number__c"?: string;
  "Incorporation_Business_Registration_certificate"?: string;
  "GST_VAT_Registration_certificate__c"?: string;
  "service_Tax_Registration__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  vendor?: Account;
}

export class VCSQDLead implements VCSQDLeadInterface {
  "sfdcId": string;
  "Name": string;
  "RecordTypeId": string;
  "ACH_Routing_Number__c": string;
  "AX_Identification_Number__c": string;
  "Account_Created__c": boolean;
  "After_Hours_Multiplier__c": number;
  "Annual_Revenue_Consulting__c": number;
  "Annual_Revenue_Hardware__c": number;
  "Annual_Revenue_Logistics__c": number;
  "Annual_Revenue_Others__c": number;
  "Annual_Revenue_Software__c": number;
  "Annual_Revenue_Technical__c": number;
  "Annual_Revenue__c": number;
  "Approval_Levels_Required__c": string;
  "Approver__c": string;
  "Bachelors_Degree__c": string;
  "Bank_Account_Type__c": string;
  "Bank_Account_number__c": number;
  "Beneficiary_Account_Name__c": string;
  "Beneficiary_Bank_Address_City__c": string;
  "Beneficiary_Bank_Address_Country__c": string;
  "Beneficiary_Bank_Address_State_Province__c": string;
  "Beneficiary_Bank_Address_Street_address__c": string;
  "Beneficiary_Bank_Address_Zip_Postal_Cod__c": string;
  "Beneficiary_Bank_Name__c": string;
  "Billing_Contact_City__c": string;
  "Billing_Contact_Copied__c": boolean;
  "Billing_Contact_Country__c": string;
  "Billing_Contact_Email__c": string;
  "Billing_Contact_First_Name__c": string;
  "Billing_Contact_Last_Name__c": string;
  "Billing_Contact_LinkedIn_Profile_Link__c": string;
  "Billing_Contact_Mobile__c": string;
  "Billing_Contact_Overview__c": string;
  "Technical_Contact_Overview__c": string;
  "current_Field_Service_Dispatch_Volume__c": string;
  "Billing_Contact_Phone__c": string;
  "Billing_Contact_State__c": string;
  "Billing_Contact_Street__c": string;
  "Billing_Contact_Time_Zone__c": string;
  "Billing_Contact_Title__c": string;
  "Billing_Contact_Zip__c": string;
  "Billing_Country__c": string;
  "Birth_Date__c": Date;
  "On_Demand_Technical_Resources_Pricing__c": string;
  "On_Demand_Technical_Resources_Coverage__c": string;
  "Business_Owner_Contact_Copied__c": boolean;
  "CEO_Contact_Copied__c": boolean;
  "CFO_Contact_Copied__c": boolean;
  "Case_Management__c": string;
  "Certifications__c": string;
  "Company_Certifications__c": string;
  "Company_City__c": string;
  "Company_Contact_Title__c": string;
  "Company_Country__c": string;
  "Company_Dispatch_Group_Email__c": string;
  "Company_Email__c": string;
  "Company_Established_Year__c": number;
  "Company_LinkedIn_URL__c": string;
  "Company_Overview__c": string;
  "Company_Phone__c": string;
  "Company_Postalcode__c": string;
  "Company_Reference_code__c": string;
  "Company_State__c": string;
  "Company_Street__c": string;
  "Company_Website__c": string;
  "Company__c": string;
  "Comprehensive_General_Liability_Details__c": string;
  "Comprehensive_automobile_liability_Detai__c": string;
  "Contact_City_CEO__c": string;
  "Contact_City_Field__c": string;
  "Contact_City_Sales__c": string;
  "Contact_City__c": string;
  "Contact_Country_CEO__c": string;
  "Contact_Country_Field__c": string;
  "Contact_Country_Sales__c": string;
  "Contact_Country__c": string;
  "Contact_Email_CEO__c": string;
  "Contact_Email_Field__c": string;
  "Contact_Email_Sales__c": string;
  "Contact_First_Name_CEO__c": string;
  "Contact_First_Name_Field__c": string;
  "Contact_First_Name_Sales__c": string;
  "Contact_First_Name__c": string;
  "Contact_LAst_Name_Field__c": string;
  "Contact_Last_Name_CEO__c": string;
  "Contact_Last_Name_Sales__c": string;
  "Contact_Last_Name__c": string;
  "Contact_LinkedIn_Profile_Link_CEO__c": string;
  "Contact_LinkedIn_Profile_Link_Field__c": string;
  "Contact_LinkedIn_Profile_Link_Sales__c": string;
  "Contact_Mobile_CEO__c": string;
  "Contact_Mobile_Field__c": string;
  "Contact_Mobile_Sales__c": string;
  "Contact_Mobile__c": string;
  "Contact_Overview__c": string;
  "Contact_Phone_CEO__c": string;
  "Contact_Phone_Field__c": string;
  "Contact_Phone_New__c": string;
  "Contact_Phone_Sales__c": string;
  "Contact_Phone__c": string;
  "Contact_State_CEO__c": string;
  "Contact_State_Field__c": string;
  "Contact_State_Sales__c": string;
  "Contact_State__c": string;
  "Contact_Street_CEO__c": string;
  "Contact_Street_Field__c": string;
  "Contact_Street_Sales__c": string;
  "Contact_Street__c": string;
  "Contact_Time_Zone__c": string;
  "Contact_Timezone_CEO__c": string;
  "Contact_Timezone_Sales__c": string;
  "Contact_Title_CEO__c": string;
  "Contact_Title_Field__c": string;
  "Contact_Title_Sales__c": string;
  "Contact_Type__c": string;
  "Contact_Zip_CEO__c": string;
  "Contact_Zip_Field__c": string;
  "Contact_Zip_Sales__c": string;
  "Contact_Zip__c": string;
  "Copy_of_MSA__c": string;
  "Countries_Supported__c": string;
  "Coverage_Hours__c": string;
  "CreatedById": string;
  "CreatedDate": Date;
  "Created_by_Contact__c": string;
  "CurrencyIsoCode": string;
  "Currency_you_desire_to_do_transaction__c": string;
  "Current_Approval_Level__c": string;
  "Current_Employer__c": string;
  "Custom_brand_badging__c": string;
  "Customer_Overview_Field_Services__c": string;
  "Customer_Overview_Key__c": string;
  "DataCenter_Operations_Hardware_Assembler__c": boolean;
  "Data_Center_Assembler_Next_Buss_Day_SLA__c": boolean;
  "Data_Center_Engr_Next_Business_Day_SLA__c": boolean;
  "Data_Center_Facilities_Manager__c": boolean;
  "Data_Center_Facilities_Technician_Level1__c": boolean;
  "Data_Center_Mgr_Next_Business_Day_SLA__c": boolean;
  "Data_Center_Operations_Engineer_Level_1__c": boolean;
  "Data_Center_Technician_Next_Buss_Day_SLA__c": boolean;
  "Decommission_Disposal__c": string;
  "Deployment_Project_Only__c": boolean;
  "Describe_Your_Staffing_Services__c": string;
  "Desktop_Support_Next_Business_Day_SLA__c": boolean;
  "Desktop_Support_Technicians_Level_1__c": boolean;
  "Distance_from_capital_KM__c": number;
  "Do_you_accept_bank_payments_in_EURO_Ye__c": string;
  "Do_you_accept_bank_payments_in_US_Dollar__c": string;
  "Do_you_currently_support_customers__c": string;
  "Do_you_have_Comprehensive_General_Ins__c": string;
  "Do_you_have_Comprehensive_automobile_Ins__c": string;
  "Do_you_have_Employer_s_liability_insuran__c": string;
  "Do_you_have_Professional_and_Cyber_Ins__c": string;
  "Do_you_have_Umbrella_Liability_insurance__c": string;
  "Do_you_have_Worker_s_Compensation_insura__c": string;
  "Do_you_provide_EOR_Services__c": string;
  "Do_you_provide_FSE_in_Selected_Country__c": boolean;
  "Provide_FSE_in_Selected_Country__c": string;
  "Do_you_provide_FTE_in_Selected_Country__c": boolean;
  "Provide_FTE_in_Selected_Country__c": string;
  "Do_you_provide_ITAD_Services__c": string;
  "Do_you_provide_Next_business_day_SLA__c": boolean;
  "Do_you_provide_Next_businessday_SLA_NBD__c": boolean;
  "Do_you_provide_Same_business_day_SLA_SBD__c": boolean;
  "Do_you_provide_Same_business_day_SLASBD__c": string;
  "Do_you_provide_technician__c": string;
  "Employers_liability_insurance_Details__c": string;
  "EUC_DSS_Break_Fi_Fix__c": string;
  "explain_your_policy_Coverage_Details_her__c": string;
  "Field_Contact_Copied__c": boolean;
  "Field_Engineer_4HR_SLA_Rate_US_HR__c": number;
  "Field_Engineer_NBD_SLA_Rate_US_HR__c": number;
  "Field_Project_Manager_Rate_US_HR__c": number;
  "Field_Service_24x7_Onsite_SLA__c": boolean;
  "Form_Type__c": string;
  "FS_Dispatch_Management_Overview_External__c": string;
  "FS_On_HW_Same_Bus_Day_4Hours_SLA_24x7__c": boolean;
  "FS_On_HW_Same_Business_Day_4_Hours_SLA__c": boolean;
  "FS_Onsite_Next_Business_Day_SLA__c": boolean;
  "GDPR_Compliance_Details__c": string;
  "Gender__c": string;
  "Graduation_Date__c": Date;
  "GST_VAT_Registration_Number__c": string;
  "Have_legal_entity_in_Country_Selected__c": string;
  "Have_you_completed_all_the_steps_of_RFI__c": string;
  "Helpdesk_Next_Business_Day_SLA__c": boolean;
  "Help_Desk_Technical_Support_Engineer__c": boolean;
  "Highest_Level_of_Talent__c": string;
  "Holiday_Multiplier__c": number;
  "Hourly_Rate__c": number;
  "IBAN__c": string;
  "If_NOT_US_Dollars_EURO_specify_curren__c": string;
  "Industry__c": string;
  "Initial_Email_Sent__c": boolean;
  "Initiate_Background_Check__c": boolean;
  "Integrate_with_Case_System__c": string;
  "Iron_Comments__c": string;
  "Iron_Comments_All_Contact__c": string;
  "Iron_Comments_All_Contact_MSP__c": string;
  "Iron_Comments_Banking__c": string;
  "Iron_Comments_Banking_MSP__c": string;
  "Iron_Comments_Capabilities__c": string;
  "Iron_Comments_Capabilities_MSP__c": string;
  "Iron_Comments_Checklist__c": string;
  "Iron_Comments_Checklist_MSP__c": string;
  "Iron_Comments_Contact__c": string;
  "Iron_Comments_Contact_MSP__c": string;
  "Iron_Comments_Overview_MSP__c": string;
  "Iron_Comments_Worker__c": string;
  "Iron_Comments_Worker_MSP__c": string;
  "Iron_Status_All_Contacts__c": string;
  "Iron_Status_Banking__c": string;
  "Iron_Status_Capabilities__c": string;
  "Iron_Status_Checklist__c": string;
  "Iron_Status_Contacts__c": string;
  "Iron_Status__c": string;
  "Iron_Status_Workers__c": string;
  "key_customer_references__c": string;
  "Languages_Supported__c": string;
  "Lead_Country__c": string;
  "LeadId__c": string;
  "LeadRecordId__c": string;
  "LinkedIn_Employee_Count__c": number;
  "LinkedIn_Profile_Link__c": string;
  "Linux_Server_Software_Break_Fix__c": string;
  "Logged_in_Contact__c": string;
  "LTFactor__c": number;
  "Masters_Degree__c": string;
  "Minimum_Hours__c": number;
  "Minimum_Hours_of_Dispatch__c": string;
  "Minimum_hours_you_require_for_NBD__c": number;
  "Minimum_hours_you_require_for_SBD__c": number;
  "Minimum_number_of_hours_you_for_NBD__c": string;
  "Minimum_number_of_hours_you_for_SBD__c": string;
  "MSP_Required_Countries__c": string;
  "Multiple_Location__c": boolean;
  "Multiple_Location_Details__c": string;
  "Network_Equipment_Break_Fix__c": string;
  "Network_Server_Engineer_MS_Windows__c": boolean;
  "Network_Server_Tech_Next_Buss_Day_SLA__c": boolean;
  "Network_Server_Technician_MS_Windows__c": boolean;
  "Network_Srv_Engr_Next_Business_Day_SLA__c": boolean;
  "Network_Storage_Engineer_Level_2__c": boolean;
  "Network_Storage_Engr_Next_Buss_Day_SLA__c": boolean;
  "Next_Business_Day_Service__c": boolean;
  "Number_of_Contractors_Field_Technicians__c": string;
  "Number_of_Employees_Field_Technicians__c": string;
  "Number_of_Employees_Total__c": number;
  "Number_of_employees_on_LinkedIn__c": number;
  "Number_of_Sub_Contractors_Field_Technic__c": string;
  "FS_Dispatch_Management_Overview_Internal__c": string;
  "Parent_VCSQDLead__c": string;
  "Parent_Lead__c": string;
  "PgMO_Milestone__c": string;
  "PgMO_Program__c": string;
  "PgMO_Project__c": string;
  "PgMO_Task_Bank__c": string;
  "PgMO_Task_Cap__c": string;
  "PgMO_Task_Checklist__c": string;
  "PgMO_Task_Contact__c": string;
  "PgMO_Task_Overview__c": string;
  "PgMO_Task_Worker__c": string;
  "Post_Graduation_Date__c": Date;
  "PPE_1HR__c": number;
  "PPE_2HR__c": number;
  "PPE_4HR__c": number;
  "PPE_8HR__c": number;
  "Preferred_Countries__c": string;
  "Primary_CFO_City__c": string;
  "Primary_CFO_Country__c": string;
  "Primary_CFO_Email__c": string;
  "Primary_CFO_First_Name__c": string;
  "Primary_CFO_Last_Name__c": string;
  "Primary_CFO_Linkedin_Profile_Link__c": string;
  "Primary_CFO_Mobile__c": string;
  "Primary_CFO_Phone__c": string;
  "Primary_CFO_State__c": string;
  "Primary_CFO_Street__c": string;
  "Primary_CFO_Timezone__c": string;
  "Primary_CFO_Title__c": string;
  "Primary_CFO_Zip__c": string;
  "Professional_and_Cyber_Liability_Details__c": string;
  "Project_Manager_Onsite_IT_Project__c": boolean;
  "Project_Mgr_Onsite_Next_Business_Day_SLA__c": boolean;
  "Provide_Ware_housing_service_for_FRU__c": string;
  "Rate_multiplier_for_ticket_After_Hours__c": number;
  "Rate_multiplier_for_ticket_Holidays__c": number;
  "Rate_multiplier_for_ticket_Weekend__c": number;
  "Rate_US_1__c": number;
  "Rate_US_10__c": number;
  "Rate_US_11__c": number;
  "Rate_US_12__c": number;
  "Rate_US_13__c": number;
  "Rate_US_14__c": number;
  "Rate_US_15__c": number;
  "Rate_US_16__c": number;
  "Rate_US_17__c": number;
  "Rate_US_18__c": number;
  "Rate_US_19__c": number;
  "Rate_US_2__c": number;
  "Rate_US_20__c": number;
  "Rate_US_21__c": number;
  "Rate_US_22__c": number;
  "Rate_US_23__c": number;
  "Rate_US_3__c": number;
  "Rate_US_4__c": number;
  "Rate_US_5__c": number;
  "Rate_US_6__c": number;
  "Rate_US_7__c": number;
  "Rate_US_8__c": number;
  "Rate_US_9__c": number;
  "Reordtypeid__c": string;
  "Resume__c": string;
  "Sales_Contact_Copied__c": boolean;
  "Same_Day_Service__c": boolean;
  "Server_HW_Break_Fix__c": string;
  "Service_Coverage_Countries__c": string;
  "Service_Dispatch_SLA_Priority__c": string;
  "Service_Global_Ref__c": string;
  "Service_Tax_Registration_number__c": string;
  "Service_Technical_Level__c": string;
  "Service_type__c": string;
  "Site_Guest_Access_Helper__c": string;
  "Site_Survey__c": string;
  "Skill_Level__c": string;
  "Skills__c": string;
  "SLA__c": string;
  "Source__c": string;
  "Specialty__c": string;
  "Step_1_Approved__c": boolean;
  "Step_2_Approved__c": boolean;
  "Storage_Equipment_Break_Fix__c": string;
  "Submission_Date__c": Date;
  "Lead_Source__c": string;
  "Swift_Code__c": string;
  "T_M__c": number;
  "Talent_Type__c": string;
  "Tax_if_Billed_from_to__c": number;
  "Tax_if_Billed_from_to_USA__c": number;
  "Technical_Contact_City__c": string;
  "Technical_Contact_Copied__c": boolean;
  "Technical_Contact_Country__c": string;
  "Technical_Contact_Email__c": string;
  "Technical_Contact_First_Name__c": string;
  "Technical_Contact_Last_Name__c": string;
  "Technical_Contact_LinkedIn_Profile_Link__c": string;
  "Technical_Contact_Mobile__c": string;
  "Technical_Contact_Phone__c": string;
  "Technical_Contact_State__c": string;
  "Technical_Contact_Street__c": string;
  "Technical_Contact_Time_Zone__c": string;
  "Technical_Contact_Title__c": string;
  "Technical_Contact_Zip__c": string;
  "Technical_Onboarding__c": string;
  "Technology_Supported_Advance_Expertise__c": string;
  "Technology_Supported_Basic_Expertise__c": string;
  "Technology_Supported_Certified_Staff__c": string;
  "timeZoneId__c": string;
  "Total_Experience_Months__c": number;
  "Training_Dispatch__c": string;
  "Types_of_Technical_Resources_Specialty__c": string;
  "Vendor__c": string;
  "Vendor_Expertise__c": string;
  "Vendor_has_VCSQDLead_Ref__c": boolean;
  "Vendor_Has_Logged_in_Contact__c": boolean;
  "Vendor_Has_PgMO_Program__c": boolean;
  "Vendors_Supported_Advance_Expertise__c": string;
  "Vendors_Supported_Certified_Staff__c": string;
  "Vendor_Status__c": string;
  "Vendor_Status_Step2__c": string;
  "Vendor_Status_Step3__c": string;
  "VOIP_Equipment_Break_Fix__c": string;
  "Weekend_Multiplier__c": number;
  "what_is_the_best_language_option__c": string;
  "Windows_Server_Software_Break_Fix__c": string;
  "Workers_Compensation_insurance_Details__c": string;
  "cancelled_Cheque_Details__c": string;
  "Incorporation_Business_Registration_certificate": string;
  "Tax_Identification_Number__c": string;
  "GST_VAT_Registration_certificate__c": string;
  "service_Tax_Registration__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  vendor: Account;
  constructor(data?: VCSQDLeadInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `VCSQDLead`.
   */
  public static getModelName() {
    return "VCSQDLead";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of VCSQDLead for dynamic purposes.
  **/
  public static factory(data: VCSQDLeadInterface): VCSQDLead{
    return new VCSQDLead(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'VCSQDLead',
      plural: 'VCSQDLeads',
      path: 'VCSQDLeads',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "RecordTypeId": {
          name: 'RecordTypeId',
          type: 'string'
        },
        "ACH_Routing_Number__c": {
          name: 'ACH_Routing_Number__c',
          type: 'string'
        },
        "AX_Identification_Number__c": {
          name: 'AX_Identification_Number__c',
          type: 'string'
        },
        "Account_Created__c": {
          name: 'Account_Created__c',
          type: 'boolean',
          default: false
        },
        "After_Hours_Multiplier__c": {
          name: 'After_Hours_Multiplier__c',
          type: 'number'
        },
        "Annual_Revenue_Consulting__c": {
          name: 'Annual_Revenue_Consulting__c',
          type: 'number'
        },
        "Annual_Revenue_Hardware__c": {
          name: 'Annual_Revenue_Hardware__c',
          type: 'number'
        },
        "Annual_Revenue_Logistics__c": {
          name: 'Annual_Revenue_Logistics__c',
          type: 'number'
        },
        "Annual_Revenue_Others__c": {
          name: 'Annual_Revenue_Others__c',
          type: 'number'
        },
        "Annual_Revenue_Software__c": {
          name: 'Annual_Revenue_Software__c',
          type: 'number'
        },
        "Annual_Revenue_Technical__c": {
          name: 'Annual_Revenue_Technical__c',
          type: 'number'
        },
        "Annual_Revenue__c": {
          name: 'Annual_Revenue__c',
          type: 'number'
        },
        "Approval_Levels_Required__c": {
          name: 'Approval_Levels_Required__c',
          type: 'string'
        },
        "Approver__c": {
          name: 'Approver__c',
          type: 'string'
        },
        "Bachelors_Degree__c": {
          name: 'Bachelors_Degree__c',
          type: 'string'
        },
        "Bank_Account_Type__c": {
          name: 'Bank_Account_Type__c',
          type: 'string'
        },
        "Bank_Account_number__c": {
          name: 'Bank_Account_number__c',
          type: 'number'
        },
        "Beneficiary_Account_Name__c": {
          name: 'Beneficiary_Account_Name__c',
          type: 'string'
        },
        "Beneficiary_Bank_Address_City__c": {
          name: 'Beneficiary_Bank_Address_City__c',
          type: 'string'
        },
        "Beneficiary_Bank_Address_Country__c": {
          name: 'Beneficiary_Bank_Address_Country__c',
          type: 'string'
        },
        "Beneficiary_Bank_Address_State_Province__c": {
          name: 'Beneficiary_Bank_Address_State_Province__c',
          type: 'string'
        },
        "Beneficiary_Bank_Address_Street_address__c": {
          name: 'Beneficiary_Bank_Address_Street_address__c',
          type: 'string'
        },
        "Beneficiary_Bank_Address_Zip_Postal_Cod__c": {
          name: 'Beneficiary_Bank_Address_Zip_Postal_Cod__c',
          type: 'string'
        },
        "Beneficiary_Bank_Name__c": {
          name: 'Beneficiary_Bank_Name__c',
          type: 'string'
        },
        "Billing_Contact_City__c": {
          name: 'Billing_Contact_City__c',
          type: 'string'
        },
        "Billing_Contact_Copied__c": {
          name: 'Billing_Contact_Copied__c',
          type: 'boolean'
        },
        "Billing_Contact_Country__c": {
          name: 'Billing_Contact_Country__c',
          type: 'string'
        },
        "Billing_Contact_Email__c": {
          name: 'Billing_Contact_Email__c',
          type: 'string'
        },
        "Billing_Contact_First_Name__c": {
          name: 'Billing_Contact_First_Name__c',
          type: 'string'
        },
        "Billing_Contact_Last_Name__c": {
          name: 'Billing_Contact_Last_Name__c',
          type: 'string'
        },
        "Billing_Contact_LinkedIn_Profile_Link__c": {
          name: 'Billing_Contact_LinkedIn_Profile_Link__c',
          type: 'string'
        },
        "Billing_Contact_Mobile__c": {
          name: 'Billing_Contact_Mobile__c',
          type: 'string'
        },
        "Billing_Contact_Overview__c": {
          name: 'Billing_Contact_Overview__c',
          type: 'string'
        },
        "Technical_Contact_Overview__c": {
          name: 'Technical_Contact_Overview__c',
          type: 'string'
        },
        "current_Field_Service_Dispatch_Volume__c": {
          name: 'current_Field_Service_Dispatch_Volume__c',
          type: 'string'
        },
        "Billing_Contact_Phone__c": {
          name: 'Billing_Contact_Phone__c',
          type: 'string'
        },
        "Billing_Contact_State__c": {
          name: 'Billing_Contact_State__c',
          type: 'string'
        },
        "Billing_Contact_Street__c": {
          name: 'Billing_Contact_Street__c',
          type: 'string'
        },
        "Billing_Contact_Time_Zone__c": {
          name: 'Billing_Contact_Time_Zone__c',
          type: 'string'
        },
        "Billing_Contact_Title__c": {
          name: 'Billing_Contact_Title__c',
          type: 'string'
        },
        "Billing_Contact_Zip__c": {
          name: 'Billing_Contact_Zip__c',
          type: 'string'
        },
        "Billing_Country__c": {
          name: 'Billing_Country__c',
          type: 'string'
        },
        "Birth_Date__c": {
          name: 'Birth_Date__c',
          type: 'Date'
        },
        "On_Demand_Technical_Resources_Pricing__c": {
          name: 'On_Demand_Technical_Resources_Pricing__c',
          type: 'string'
        },
        "On_Demand_Technical_Resources_Coverage__c": {
          name: 'On_Demand_Technical_Resources_Coverage__c',
          type: 'string'
        },
        "Business_Owner_Contact_Copied__c": {
          name: 'Business_Owner_Contact_Copied__c',
          type: 'boolean'
        },
        "CEO_Contact_Copied__c": {
          name: 'CEO_Contact_Copied__c',
          type: 'boolean'
        },
        "CFO_Contact_Copied__c": {
          name: 'CFO_Contact_Copied__c',
          type: 'boolean'
        },
        "Case_Management__c": {
          name: 'Case_Management__c',
          type: 'string'
        },
        "Certifications__c": {
          name: 'Certifications__c',
          type: 'string'
        },
        "Company_Certifications__c": {
          name: 'Company_Certifications__c',
          type: 'string'
        },
        "Company_City__c": {
          name: 'Company_City__c',
          type: 'string'
        },
        "Company_Contact_Title__c": {
          name: 'Company_Contact_Title__c',
          type: 'string'
        },
        "Company_Country__c": {
          name: 'Company_Country__c',
          type: 'string'
        },
        "Company_Dispatch_Group_Email__c": {
          name: 'Company_Dispatch_Group_Email__c',
          type: 'string'
        },
        "Company_Email__c": {
          name: 'Company_Email__c',
          type: 'string'
        },
        "Company_Established_Year__c": {
          name: 'Company_Established_Year__c',
          type: 'number'
        },
        "Company_LinkedIn_URL__c": {
          name: 'Company_LinkedIn_URL__c',
          type: 'string'
        },
        "Company_Overview__c": {
          name: 'Company_Overview__c',
          type: 'string'
        },
        "Company_Phone__c": {
          name: 'Company_Phone__c',
          type: 'string'
        },
        "Company_Postalcode__c": {
          name: 'Company_Postalcode__c',
          type: 'string'
        },
        "Company_Reference_code__c": {
          name: 'Company_Reference_code__c',
          type: 'string'
        },
        "Company_State__c": {
          name: 'Company_State__c',
          type: 'string'
        },
        "Company_Street__c": {
          name: 'Company_Street__c',
          type: 'string'
        },
        "Company_Website__c": {
          name: 'Company_Website__c',
          type: 'string'
        },
        "Company__c": {
          name: 'Company__c',
          type: 'string'
        },
        "Comprehensive_General_Liability_Details__c": {
          name: 'Comprehensive_General_Liability_Details__c',
          type: 'string'
        },
        "Comprehensive_automobile_liability_Detai__c": {
          name: 'Comprehensive_automobile_liability_Detai__c',
          type: 'string'
        },
        "Contact_City_CEO__c": {
          name: 'Contact_City_CEO__c',
          type: 'string'
        },
        "Contact_City_Field__c": {
          name: 'Contact_City_Field__c',
          type: 'string'
        },
        "Contact_City_Sales__c": {
          name: 'Contact_City_Sales__c',
          type: 'string'
        },
        "Contact_City__c": {
          name: 'Contact_City__c',
          type: 'string'
        },
        "Contact_Country_CEO__c": {
          name: 'Contact_Country_CEO__c',
          type: 'string'
        },
        "Contact_Country_Field__c": {
          name: 'Contact_Country_Field__c',
          type: 'string'
        },
        "Contact_Country_Sales__c": {
          name: 'Contact_Country_Sales__c',
          type: 'string'
        },
        "Contact_Country__c": {
          name: 'Contact_Country__c',
          type: 'string'
        },
        "Contact_Email_CEO__c": {
          name: 'Contact_Email_CEO__c',
          type: 'string'
        },
        "Contact_Email_Field__c": {
          name: 'Contact_Email_Field__c',
          type: 'string'
        },
        "Contact_Email_Sales__c": {
          name: 'Contact_Email_Sales__c',
          type: 'string'
        },
        "Contact_First_Name_CEO__c": {
          name: 'Contact_First_Name_CEO__c',
          type: 'string'
        },
        "Contact_First_Name_Field__c": {
          name: 'Contact_First_Name_Field__c',
          type: 'string'
        },
        "Contact_First_Name_Sales__c": {
          name: 'Contact_First_Name_Sales__c',
          type: 'string'
        },
        "Contact_First_Name__c": {
          name: 'Contact_First_Name__c',
          type: 'string'
        },
        "Contact_LAst_Name_Field__c": {
          name: 'Contact_LAst_Name_Field__c',
          type: 'string'
        },
        "Contact_Last_Name_CEO__c": {
          name: 'Contact_Last_Name_CEO__c',
          type: 'string'
        },
        "Contact_Last_Name_Sales__c": {
          name: 'Contact_Last_Name_Sales__c',
          type: 'string'
        },
        "Contact_Last_Name__c": {
          name: 'Contact_Last_Name__c',
          type: 'string'
        },
        "Contact_LinkedIn_Profile_Link_CEO__c": {
          name: 'Contact_LinkedIn_Profile_Link_CEO__c',
          type: 'string'
        },
        "Contact_LinkedIn_Profile_Link_Field__c": {
          name: 'Contact_LinkedIn_Profile_Link_Field__c',
          type: 'string'
        },
        "Contact_LinkedIn_Profile_Link_Sales__c": {
          name: 'Contact_LinkedIn_Profile_Link_Sales__c',
          type: 'string'
        },
        "Contact_Mobile_CEO__c": {
          name: 'Contact_Mobile_CEO__c',
          type: 'string'
        },
        "Contact_Mobile_Field__c": {
          name: 'Contact_Mobile_Field__c',
          type: 'string'
        },
        "Contact_Mobile_Sales__c": {
          name: 'Contact_Mobile_Sales__c',
          type: 'string'
        },
        "Contact_Mobile__c": {
          name: 'Contact_Mobile__c',
          type: 'string'
        },
        "Contact_Overview__c": {
          name: 'Contact_Overview__c',
          type: 'string'
        },
        "Contact_Phone_CEO__c": {
          name: 'Contact_Phone_CEO__c',
          type: 'string'
        },
        "Contact_Phone_Field__c": {
          name: 'Contact_Phone_Field__c',
          type: 'string'
        },
        "Contact_Phone_New__c": {
          name: 'Contact_Phone_New__c',
          type: 'string'
        },
        "Contact_Phone_Sales__c": {
          name: 'Contact_Phone_Sales__c',
          type: 'string'
        },
        "Contact_Phone__c": {
          name: 'Contact_Phone__c',
          type: 'string'
        },
        "Contact_State_CEO__c": {
          name: 'Contact_State_CEO__c',
          type: 'string'
        },
        "Contact_State_Field__c": {
          name: 'Contact_State_Field__c',
          type: 'string'
        },
        "Contact_State_Sales__c": {
          name: 'Contact_State_Sales__c',
          type: 'string'
        },
        "Contact_State__c": {
          name: 'Contact_State__c',
          type: 'string'
        },
        "Contact_Street_CEO__c": {
          name: 'Contact_Street_CEO__c',
          type: 'string'
        },
        "Contact_Street_Field__c": {
          name: 'Contact_Street_Field__c',
          type: 'string'
        },
        "Contact_Street_Sales__c": {
          name: 'Contact_Street_Sales__c',
          type: 'string'
        },
        "Contact_Street__c": {
          name: 'Contact_Street__c',
          type: 'string'
        },
        "Contact_Time_Zone__c": {
          name: 'Contact_Time_Zone__c',
          type: 'string'
        },
        "Contact_Timezone_CEO__c": {
          name: 'Contact_Timezone_CEO__c',
          type: 'string'
        },
        "Contact_Timezone_Sales__c": {
          name: 'Contact_Timezone_Sales__c',
          type: 'string'
        },
        "Contact_Title_CEO__c": {
          name: 'Contact_Title_CEO__c',
          type: 'string'
        },
        "Contact_Title_Field__c": {
          name: 'Contact_Title_Field__c',
          type: 'string'
        },
        "Contact_Title_Sales__c": {
          name: 'Contact_Title_Sales__c',
          type: 'string'
        },
        "Contact_Type__c": {
          name: 'Contact_Type__c',
          type: 'string'
        },
        "Contact_Zip_CEO__c": {
          name: 'Contact_Zip_CEO__c',
          type: 'string'
        },
        "Contact_Zip_Field__c": {
          name: 'Contact_Zip_Field__c',
          type: 'string'
        },
        "Contact_Zip_Sales__c": {
          name: 'Contact_Zip_Sales__c',
          type: 'string'
        },
        "Contact_Zip__c": {
          name: 'Contact_Zip__c',
          type: 'string'
        },
        "Copy_of_MSA__c": {
          name: 'Copy_of_MSA__c',
          type: 'string'
        },
        "Countries_Supported__c": {
          name: 'Countries_Supported__c',
          type: 'string'
        },
        "Coverage_Hours__c": {
          name: 'Coverage_Hours__c',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "Created_by_Contact__c": {
          name: 'Created_by_Contact__c',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "Currency_you_desire_to_do_transaction__c": {
          name: 'Currency_you_desire_to_do_transaction__c',
          type: 'string'
        },
        "Current_Approval_Level__c": {
          name: 'Current_Approval_Level__c',
          type: 'string'
        },
        "Current_Employer__c": {
          name: 'Current_Employer__c',
          type: 'string'
        },
        "Custom_brand_badging__c": {
          name: 'Custom_brand_badging__c',
          type: 'string'
        },
        "Customer_Overview_Field_Services__c": {
          name: 'Customer_Overview_Field_Services__c',
          type: 'string'
        },
        "Customer_Overview_Key__c": {
          name: 'Customer_Overview_Key__c',
          type: 'string'
        },
        "DataCenter_Operations_Hardware_Assembler__c": {
          name: 'DataCenter_Operations_Hardware_Assembler__c',
          type: 'boolean'
        },
        "Data_Center_Assembler_Next_Buss_Day_SLA__c": {
          name: 'Data_Center_Assembler_Next_Buss_Day_SLA__c',
          type: 'boolean'
        },
        "Data_Center_Engr_Next_Business_Day_SLA__c": {
          name: 'Data_Center_Engr_Next_Business_Day_SLA__c',
          type: 'boolean'
        },
        "Data_Center_Facilities_Manager__c": {
          name: 'Data_Center_Facilities_Manager__c',
          type: 'boolean'
        },
        "Data_Center_Facilities_Technician_Level1__c": {
          name: 'Data_Center_Facilities_Technician_Level1__c',
          type: 'boolean'
        },
        "Data_Center_Mgr_Next_Business_Day_SLA__c": {
          name: 'Data_Center_Mgr_Next_Business_Day_SLA__c',
          type: 'boolean'
        },
        "Data_Center_Operations_Engineer_Level_1__c": {
          name: 'Data_Center_Operations_Engineer_Level_1__c',
          type: 'boolean'
        },
        "Data_Center_Technician_Next_Buss_Day_SLA__c": {
          name: 'Data_Center_Technician_Next_Buss_Day_SLA__c',
          type: 'boolean'
        },
        "Decommission_Disposal__c": {
          name: 'Decommission_Disposal__c',
          type: 'string'
        },
        "Deployment_Project_Only__c": {
          name: 'Deployment_Project_Only__c',
          type: 'boolean'
        },
        "Describe_Your_Staffing_Services__c": {
          name: 'Describe_Your_Staffing_Services__c',
          type: 'string'
        },
        "Desktop_Support_Next_Business_Day_SLA__c": {
          name: 'Desktop_Support_Next_Business_Day_SLA__c',
          type: 'boolean'
        },
        "Desktop_Support_Technicians_Level_1__c": {
          name: 'Desktop_Support_Technicians_Level_1__c',
          type: 'boolean'
        },
        "Distance_from_capital_KM__c": {
          name: 'Distance_from_capital_KM__c',
          type: 'number'
        },
        "Do_you_accept_bank_payments_in_EURO_Ye__c": {
          name: 'Do_you_accept_bank_payments_in_EURO_Ye__c',
          type: 'string'
        },
        "Do_you_accept_bank_payments_in_US_Dollar__c": {
          name: 'Do_you_accept_bank_payments_in_US_Dollar__c',
          type: 'string'
        },
        "Do_you_currently_support_customers__c": {
          name: 'Do_you_currently_support_customers__c',
          type: 'string'
        },
        "Do_you_have_Comprehensive_General_Ins__c": {
          name: 'Do_you_have_Comprehensive_General_Ins__c',
          type: 'string'
        },
        "Do_you_have_Comprehensive_automobile_Ins__c": {
          name: 'Do_you_have_Comprehensive_automobile_Ins__c',
          type: 'string'
        },
        "Do_you_have_Employer_s_liability_insuran__c": {
          name: 'Do_you_have_Employer_s_liability_insuran__c',
          type: 'string'
        },
        "Do_you_have_Professional_and_Cyber_Ins__c": {
          name: 'Do_you_have_Professional_and_Cyber_Ins__c',
          type: 'string'
        },
        "Do_you_have_Umbrella_Liability_insurance__c": {
          name: 'Do_you_have_Umbrella_Liability_insurance__c',
          type: 'string'
        },
        "Do_you_have_Worker_s_Compensation_insura__c": {
          name: 'Do_you_have_Worker_s_Compensation_insura__c',
          type: 'string'
        },
        "Do_you_provide_EOR_Services__c": {
          name: 'Do_you_provide_EOR_Services__c',
          type: 'string'
        },
        "Do_you_provide_FSE_in_Selected_Country__c": {
          name: 'Do_you_provide_FSE_in_Selected_Country__c',
          type: 'boolean'
        },
        "Provide_FSE_in_Selected_Country__c": {
          name: 'Provide_FSE_in_Selected_Country__c',
          type: 'string'
        },
        "Do_you_provide_FTE_in_Selected_Country__c": {
          name: 'Do_you_provide_FTE_in_Selected_Country__c',
          type: 'boolean'
        },
        "Provide_FTE_in_Selected_Country__c": {
          name: 'Provide_FTE_in_Selected_Country__c',
          type: 'string'
        },
        "Do_you_provide_ITAD_Services__c": {
          name: 'Do_you_provide_ITAD_Services__c',
          type: 'string'
        },
        "Do_you_provide_Next_business_day_SLA__c": {
          name: 'Do_you_provide_Next_business_day_SLA__c',
          type: 'boolean'
        },
        "Do_you_provide_Next_businessday_SLA_NBD__c": {
          name: 'Do_you_provide_Next_businessday_SLA_NBD__c',
          type: 'boolean'
        },
        "Do_you_provide_Same_business_day_SLA_SBD__c": {
          name: 'Do_you_provide_Same_business_day_SLA_SBD__c',
          type: 'boolean'
        },
        "Do_you_provide_Same_business_day_SLASBD__c": {
          name: 'Do_you_provide_Same_business_day_SLASBD__c',
          type: 'string'
        },
        "Do_you_provide_technician__c": {
          name: 'Do_you_provide_technician__c',
          type: 'string'
        },
        "Employers_liability_insurance_Details__c": {
          name: 'Employers_liability_insurance_Details__c',
          type: 'string'
        },
        "EUC_DSS_Break_Fi_Fix__c": {
          name: 'EUC_DSS_Break_Fi_Fix__c',
          type: 'string'
        },
        "explain_your_policy_Coverage_Details_her__c": {
          name: 'explain_your_policy_Coverage_Details_her__c',
          type: 'string'
        },
        "Field_Contact_Copied__c": {
          name: 'Field_Contact_Copied__c',
          type: 'boolean'
        },
        "Field_Engineer_4HR_SLA_Rate_US_HR__c": {
          name: 'Field_Engineer_4HR_SLA_Rate_US_HR__c',
          type: 'number'
        },
        "Field_Engineer_NBD_SLA_Rate_US_HR__c": {
          name: 'Field_Engineer_NBD_SLA_Rate_US_HR__c',
          type: 'number'
        },
        "Field_Project_Manager_Rate_US_HR__c": {
          name: 'Field_Project_Manager_Rate_US_HR__c',
          type: 'number'
        },
        "Field_Service_24x7_Onsite_SLA__c": {
          name: 'Field_Service_24x7_Onsite_SLA__c',
          type: 'boolean'
        },
        "Form_Type__c": {
          name: 'Form_Type__c',
          type: 'string'
        },
        "FS_Dispatch_Management_Overview_External__c": {
          name: 'FS_Dispatch_Management_Overview_External__c',
          type: 'string'
        },
        "FS_On_HW_Same_Bus_Day_4Hours_SLA_24x7__c": {
          name: 'FS_On_HW_Same_Bus_Day_4Hours_SLA_24x7__c',
          type: 'boolean'
        },
        "FS_On_HW_Same_Business_Day_4_Hours_SLA__c": {
          name: 'FS_On_HW_Same_Business_Day_4_Hours_SLA__c',
          type: 'boolean'
        },
        "FS_Onsite_Next_Business_Day_SLA__c": {
          name: 'FS_Onsite_Next_Business_Day_SLA__c',
          type: 'boolean'
        },
        "GDPR_Compliance_Details__c": {
          name: 'GDPR_Compliance_Details__c',
          type: 'string'
        },
        "Gender__c": {
          name: 'Gender__c',
          type: 'string'
        },
        "Graduation_Date__c": {
          name: 'Graduation_Date__c',
          type: 'Date'
        },
        "GST_VAT_Registration_Number__c": {
          name: 'GST_VAT_Registration_Number__c',
          type: 'string'
        },
        "Have_legal_entity_in_Country_Selected__c": {
          name: 'Have_legal_entity_in_Country_Selected__c',
          type: 'string'
        },
        "Have_you_completed_all_the_steps_of_RFI__c": {
          name: 'Have_you_completed_all_the_steps_of_RFI__c',
          type: 'string'
        },
        "Helpdesk_Next_Business_Day_SLA__c": {
          name: 'Helpdesk_Next_Business_Day_SLA__c',
          type: 'boolean'
        },
        "Help_Desk_Technical_Support_Engineer__c": {
          name: 'Help_Desk_Technical_Support_Engineer__c',
          type: 'boolean'
        },
        "Highest_Level_of_Talent__c": {
          name: 'Highest_Level_of_Talent__c',
          type: 'string'
        },
        "Holiday_Multiplier__c": {
          name: 'Holiday_Multiplier__c',
          type: 'number'
        },
        "Hourly_Rate__c": {
          name: 'Hourly_Rate__c',
          type: 'number'
        },
        "IBAN__c": {
          name: 'IBAN__c',
          type: 'string'
        },
        "If_NOT_US_Dollars_EURO_specify_curren__c": {
          name: 'If_NOT_US_Dollars_EURO_specify_curren__c',
          type: 'string'
        },
        "Industry__c": {
          name: 'Industry__c',
          type: 'string'
        },
        "Initial_Email_Sent__c": {
          name: 'Initial_Email_Sent__c',
          type: 'boolean'
        },
        "Initiate_Background_Check__c": {
          name: 'Initiate_Background_Check__c',
          type: 'boolean'
        },
        "Integrate_with_Case_System__c": {
          name: 'Integrate_with_Case_System__c',
          type: 'string'
        },
        "Iron_Comments__c": {
          name: 'Iron_Comments__c',
          type: 'string'
        },
        "Iron_Comments_All_Contact__c": {
          name: 'Iron_Comments_All_Contact__c',
          type: 'string'
        },
        "Iron_Comments_All_Contact_MSP__c": {
          name: 'Iron_Comments_All_Contact_MSP__c',
          type: 'string'
        },
        "Iron_Comments_Banking__c": {
          name: 'Iron_Comments_Banking__c',
          type: 'string'
        },
        "Iron_Comments_Banking_MSP__c": {
          name: 'Iron_Comments_Banking_MSP__c',
          type: 'string'
        },
        "Iron_Comments_Capabilities__c": {
          name: 'Iron_Comments_Capabilities__c',
          type: 'string'
        },
        "Iron_Comments_Capabilities_MSP__c": {
          name: 'Iron_Comments_Capabilities_MSP__c',
          type: 'string'
        },
        "Iron_Comments_Checklist__c": {
          name: 'Iron_Comments_Checklist__c',
          type: 'string'
        },
        "Iron_Comments_Checklist_MSP__c": {
          name: 'Iron_Comments_Checklist_MSP__c',
          type: 'string'
        },
        "Iron_Comments_Contact__c": {
          name: 'Iron_Comments_Contact__c',
          type: 'string'
        },
        "Iron_Comments_Contact_MSP__c": {
          name: 'Iron_Comments_Contact_MSP__c',
          type: 'string'
        },
        "Iron_Comments_Overview_MSP__c": {
          name: 'Iron_Comments_Overview_MSP__c',
          type: 'string'
        },
        "Iron_Comments_Worker__c": {
          name: 'Iron_Comments_Worker__c',
          type: 'string'
        },
        "Iron_Comments_Worker_MSP__c": {
          name: 'Iron_Comments_Worker_MSP__c',
          type: 'string'
        },
        "Iron_Status_All_Contacts__c": {
          name: 'Iron_Status_All_Contacts__c',
          type: 'string'
        },
        "Iron_Status_Banking__c": {
          name: 'Iron_Status_Banking__c',
          type: 'string'
        },
        "Iron_Status_Capabilities__c": {
          name: 'Iron_Status_Capabilities__c',
          type: 'string'
        },
        "Iron_Status_Checklist__c": {
          name: 'Iron_Status_Checklist__c',
          type: 'string'
        },
        "Iron_Status_Contacts__c": {
          name: 'Iron_Status_Contacts__c',
          type: 'string'
        },
        "Iron_Status__c": {
          name: 'Iron_Status__c',
          type: 'string'
        },
        "Iron_Status_Workers__c": {
          name: 'Iron_Status_Workers__c',
          type: 'string'
        },
        "key_customer_references__c": {
          name: 'key_customer_references__c',
          type: 'string'
        },
        "Languages_Supported__c": {
          name: 'Languages_Supported__c',
          type: 'string'
        },
        "Lead_Country__c": {
          name: 'Lead_Country__c',
          type: 'string'
        },
        "LeadId__c": {
          name: 'LeadId__c',
          type: 'string'
        },
        "LeadRecordId__c": {
          name: 'LeadRecordId__c',
          type: 'string'
        },
        "LinkedIn_Employee_Count__c": {
          name: 'LinkedIn_Employee_Count__c',
          type: 'number'
        },
        "LinkedIn_Profile_Link__c": {
          name: 'LinkedIn_Profile_Link__c',
          type: 'string'
        },
        "Linux_Server_Software_Break_Fix__c": {
          name: 'Linux_Server_Software_Break_Fix__c',
          type: 'string'
        },
        "Logged_in_Contact__c": {
          name: 'Logged_in_Contact__c',
          type: 'string'
        },
        "LTFactor__c": {
          name: 'LTFactor__c',
          type: 'number'
        },
        "Masters_Degree__c": {
          name: 'Masters_Degree__c',
          type: 'string'
        },
        "Minimum_Hours__c": {
          name: 'Minimum_Hours__c',
          type: 'number'
        },
        "Minimum_Hours_of_Dispatch__c": {
          name: 'Minimum_Hours_of_Dispatch__c',
          type: 'string'
        },
        "Minimum_hours_you_require_for_NBD__c": {
          name: 'Minimum_hours_you_require_for_NBD__c',
          type: 'number'
        },
        "Minimum_hours_you_require_for_SBD__c": {
          name: 'Minimum_hours_you_require_for_SBD__c',
          type: 'number'
        },
        "Minimum_number_of_hours_you_for_NBD__c": {
          name: 'Minimum_number_of_hours_you_for_NBD__c',
          type: 'string'
        },
        "Minimum_number_of_hours_you_for_SBD__c": {
          name: 'Minimum_number_of_hours_you_for_SBD__c',
          type: 'string'
        },
        "MSP_Required_Countries__c": {
          name: 'MSP_Required_Countries__c',
          type: 'string'
        },
        "Multiple_Location__c": {
          name: 'Multiple_Location__c',
          type: 'boolean'
        },
        "Multiple_Location_Details__c": {
          name: 'Multiple_Location_Details__c',
          type: 'string'
        },
        "Network_Equipment_Break_Fix__c": {
          name: 'Network_Equipment_Break_Fix__c',
          type: 'string'
        },
        "Network_Server_Engineer_MS_Windows__c": {
          name: 'Network_Server_Engineer_MS_Windows__c',
          type: 'boolean'
        },
        "Network_Server_Tech_Next_Buss_Day_SLA__c": {
          name: 'Network_Server_Tech_Next_Buss_Day_SLA__c',
          type: 'boolean'
        },
        "Network_Server_Technician_MS_Windows__c": {
          name: 'Network_Server_Technician_MS_Windows__c',
          type: 'boolean'
        },
        "Network_Srv_Engr_Next_Business_Day_SLA__c": {
          name: 'Network_Srv_Engr_Next_Business_Day_SLA__c',
          type: 'boolean'
        },
        "Network_Storage_Engineer_Level_2__c": {
          name: 'Network_Storage_Engineer_Level_2__c',
          type: 'boolean'
        },
        "Network_Storage_Engr_Next_Buss_Day_SLA__c": {
          name: 'Network_Storage_Engr_Next_Buss_Day_SLA__c',
          type: 'boolean'
        },
        "Next_Business_Day_Service__c": {
          name: 'Next_Business_Day_Service__c',
          type: 'boolean'
        },
        "Number_of_Contractors_Field_Technicians__c": {
          name: 'Number_of_Contractors_Field_Technicians__c',
          type: 'string'
        },
        "Number_of_Employees_Field_Technicians__c": {
          name: 'Number_of_Employees_Field_Technicians__c',
          type: 'string'
        },
        "Number_of_Employees_Total__c": {
          name: 'Number_of_Employees_Total__c',
          type: 'number'
        },
        "Number_of_employees_on_LinkedIn__c": {
          name: 'Number_of_employees_on_LinkedIn__c',
          type: 'number'
        },
        "Number_of_Sub_Contractors_Field_Technic__c": {
          name: 'Number_of_Sub_Contractors_Field_Technic__c',
          type: 'string'
        },
        "FS_Dispatch_Management_Overview_Internal__c": {
          name: 'FS_Dispatch_Management_Overview_Internal__c',
          type: 'string'
        },
        "Parent_VCSQDLead__c": {
          name: 'Parent_VCSQDLead__c',
          type: 'string'
        },
        "Parent_Lead__c": {
          name: 'Parent_Lead__c',
          type: 'string'
        },
        "PgMO_Milestone__c": {
          name: 'PgMO_Milestone__c',
          type: 'string'
        },
        "PgMO_Program__c": {
          name: 'PgMO_Program__c',
          type: 'string'
        },
        "PgMO_Project__c": {
          name: 'PgMO_Project__c',
          type: 'string'
        },
        "PgMO_Task_Bank__c": {
          name: 'PgMO_Task_Bank__c',
          type: 'string'
        },
        "PgMO_Task_Cap__c": {
          name: 'PgMO_Task_Cap__c',
          type: 'string'
        },
        "PgMO_Task_Checklist__c": {
          name: 'PgMO_Task_Checklist__c',
          type: 'string'
        },
        "PgMO_Task_Contact__c": {
          name: 'PgMO_Task_Contact__c',
          type: 'string'
        },
        "PgMO_Task_Overview__c": {
          name: 'PgMO_Task_Overview__c',
          type: 'string'
        },
        "PgMO_Task_Worker__c": {
          name: 'PgMO_Task_Worker__c',
          type: 'string'
        },
        "Post_Graduation_Date__c": {
          name: 'Post_Graduation_Date__c',
          type: 'Date'
        },
        "PPE_1HR__c": {
          name: 'PPE_1HR__c',
          type: 'number'
        },
        "PPE_2HR__c": {
          name: 'PPE_2HR__c',
          type: 'number'
        },
        "PPE_4HR__c": {
          name: 'PPE_4HR__c',
          type: 'number'
        },
        "PPE_8HR__c": {
          name: 'PPE_8HR__c',
          type: 'number'
        },
        "Preferred_Countries__c": {
          name: 'Preferred_Countries__c',
          type: 'string'
        },
        "Primary_CFO_City__c": {
          name: 'Primary_CFO_City__c',
          type: 'string'
        },
        "Primary_CFO_Country__c": {
          name: 'Primary_CFO_Country__c',
          type: 'string'
        },
        "Primary_CFO_Email__c": {
          name: 'Primary_CFO_Email__c',
          type: 'string'
        },
        "Primary_CFO_First_Name__c": {
          name: 'Primary_CFO_First_Name__c',
          type: 'string'
        },
        "Primary_CFO_Last_Name__c": {
          name: 'Primary_CFO_Last_Name__c',
          type: 'string'
        },
        "Primary_CFO_Linkedin_Profile_Link__c": {
          name: 'Primary_CFO_Linkedin_Profile_Link__c',
          type: 'string'
        },
        "Primary_CFO_Mobile__c": {
          name: 'Primary_CFO_Mobile__c',
          type: 'string'
        },
        "Primary_CFO_Phone__c": {
          name: 'Primary_CFO_Phone__c',
          type: 'string'
        },
        "Primary_CFO_State__c": {
          name: 'Primary_CFO_State__c',
          type: 'string'
        },
        "Primary_CFO_Street__c": {
          name: 'Primary_CFO_Street__c',
          type: 'string'
        },
        "Primary_CFO_Timezone__c": {
          name: 'Primary_CFO_Timezone__c',
          type: 'string'
        },
        "Primary_CFO_Title__c": {
          name: 'Primary_CFO_Title__c',
          type: 'string'
        },
        "Primary_CFO_Zip__c": {
          name: 'Primary_CFO_Zip__c',
          type: 'string'
        },
        "Professional_and_Cyber_Liability_Details__c": {
          name: 'Professional_and_Cyber_Liability_Details__c',
          type: 'string'
        },
        "Project_Manager_Onsite_IT_Project__c": {
          name: 'Project_Manager_Onsite_IT_Project__c',
          type: 'boolean'
        },
        "Project_Mgr_Onsite_Next_Business_Day_SLA__c": {
          name: 'Project_Mgr_Onsite_Next_Business_Day_SLA__c',
          type: 'boolean'
        },
        "Provide_Ware_housing_service_for_FRU__c": {
          name: 'Provide_Ware_housing_service_for_FRU__c',
          type: 'string'
        },
        "Rate_multiplier_for_ticket_After_Hours__c": {
          name: 'Rate_multiplier_for_ticket_After_Hours__c',
          type: 'number'
        },
        "Rate_multiplier_for_ticket_Holidays__c": {
          name: 'Rate_multiplier_for_ticket_Holidays__c',
          type: 'number'
        },
        "Rate_multiplier_for_ticket_Weekend__c": {
          name: 'Rate_multiplier_for_ticket_Weekend__c',
          type: 'number'
        },
        "Rate_US_1__c": {
          name: 'Rate_US_1__c',
          type: 'number'
        },
        "Rate_US_10__c": {
          name: 'Rate_US_10__c',
          type: 'number'
        },
        "Rate_US_11__c": {
          name: 'Rate_US_11__c',
          type: 'number'
        },
        "Rate_US_12__c": {
          name: 'Rate_US_12__c',
          type: 'number'
        },
        "Rate_US_13__c": {
          name: 'Rate_US_13__c',
          type: 'number'
        },
        "Rate_US_14__c": {
          name: 'Rate_US_14__c',
          type: 'number'
        },
        "Rate_US_15__c": {
          name: 'Rate_US_15__c',
          type: 'number'
        },
        "Rate_US_16__c": {
          name: 'Rate_US_16__c',
          type: 'number'
        },
        "Rate_US_17__c": {
          name: 'Rate_US_17__c',
          type: 'number'
        },
        "Rate_US_18__c": {
          name: 'Rate_US_18__c',
          type: 'number'
        },
        "Rate_US_19__c": {
          name: 'Rate_US_19__c',
          type: 'number'
        },
        "Rate_US_2__c": {
          name: 'Rate_US_2__c',
          type: 'number'
        },
        "Rate_US_20__c": {
          name: 'Rate_US_20__c',
          type: 'number'
        },
        "Rate_US_21__c": {
          name: 'Rate_US_21__c',
          type: 'number'
        },
        "Rate_US_22__c": {
          name: 'Rate_US_22__c',
          type: 'number'
        },
        "Rate_US_23__c": {
          name: 'Rate_US_23__c',
          type: 'number'
        },
        "Rate_US_3__c": {
          name: 'Rate_US_3__c',
          type: 'number'
        },
        "Rate_US_4__c": {
          name: 'Rate_US_4__c',
          type: 'number'
        },
        "Rate_US_5__c": {
          name: 'Rate_US_5__c',
          type: 'number'
        },
        "Rate_US_6__c": {
          name: 'Rate_US_6__c',
          type: 'number'
        },
        "Rate_US_7__c": {
          name: 'Rate_US_7__c',
          type: 'number'
        },
        "Rate_US_8__c": {
          name: 'Rate_US_8__c',
          type: 'number'
        },
        "Rate_US_9__c": {
          name: 'Rate_US_9__c',
          type: 'number'
        },
        "Reordtypeid__c": {
          name: 'Reordtypeid__c',
          type: 'string'
        },
        "Resume__c": {
          name: 'Resume__c',
          type: 'string'
        },
        "Sales_Contact_Copied__c": {
          name: 'Sales_Contact_Copied__c',
          type: 'boolean'
        },
        "Same_Day_Service__c": {
          name: 'Same_Day_Service__c',
          type: 'boolean'
        },
        "Server_HW_Break_Fix__c": {
          name: 'Server_HW_Break_Fix__c',
          type: 'string'
        },
        "Service_Coverage_Countries__c": {
          name: 'Service_Coverage_Countries__c',
          type: 'string'
        },
        "Service_Dispatch_SLA_Priority__c": {
          name: 'Service_Dispatch_SLA_Priority__c',
          type: 'string'
        },
        "Service_Global_Ref__c": {
          name: 'Service_Global_Ref__c',
          type: 'string'
        },
        "Service_Tax_Registration_number__c": {
          name: 'Service_Tax_Registration_number__c',
          type: 'string'
        },
        "Service_Technical_Level__c": {
          name: 'Service_Technical_Level__c',
          type: 'string'
        },
        "Service_type__c": {
          name: 'Service_type__c',
          type: 'string'
        },
        "Site_Guest_Access_Helper__c": {
          name: 'Site_Guest_Access_Helper__c',
          type: 'string'
        },
        "Site_Survey__c": {
          name: 'Site_Survey__c',
          type: 'string'
        },
        "Skill_Level__c": {
          name: 'Skill_Level__c',
          type: 'string'
        },
        "Skills__c": {
          name: 'Skills__c',
          type: 'string'
        },
        "SLA__c": {
          name: 'SLA__c',
          type: 'string'
        },
        "Source__c": {
          name: 'Source__c',
          type: 'string'
        },
        "Specialty__c": {
          name: 'Specialty__c',
          type: 'string'
        },
        "Step_1_Approved__c": {
          name: 'Step_1_Approved__c',
          type: 'boolean'
        },
        "Step_2_Approved__c": {
          name: 'Step_2_Approved__c',
          type: 'boolean'
        },
        "Storage_Equipment_Break_Fix__c": {
          name: 'Storage_Equipment_Break_Fix__c',
          type: 'string'
        },
        "Submission_Date__c": {
          name: 'Submission_Date__c',
          type: 'Date'
        },
        "Lead_Source__c": {
          name: 'Lead_Source__c',
          type: 'string'
        },
        "Swift_Code__c": {
          name: 'Swift_Code__c',
          type: 'string'
        },
        "T_M__c": {
          name: 'T_M__c',
          type: 'number'
        },
        "Talent_Type__c": {
          name: 'Talent_Type__c',
          type: 'string'
        },
        "Tax_if_Billed_from_to__c": {
          name: 'Tax_if_Billed_from_to__c',
          type: 'number'
        },
        "Tax_if_Billed_from_to_USA__c": {
          name: 'Tax_if_Billed_from_to_USA__c',
          type: 'number'
        },
        "Technical_Contact_City__c": {
          name: 'Technical_Contact_City__c',
          type: 'string'
        },
        "Technical_Contact_Copied__c": {
          name: 'Technical_Contact_Copied__c',
          type: 'boolean'
        },
        "Technical_Contact_Country__c": {
          name: 'Technical_Contact_Country__c',
          type: 'string'
        },
        "Technical_Contact_Email__c": {
          name: 'Technical_Contact_Email__c',
          type: 'string'
        },
        "Technical_Contact_First_Name__c": {
          name: 'Technical_Contact_First_Name__c',
          type: 'string'
        },
        "Technical_Contact_Last_Name__c": {
          name: 'Technical_Contact_Last_Name__c',
          type: 'string'
        },
        "Technical_Contact_LinkedIn_Profile_Link__c": {
          name: 'Technical_Contact_LinkedIn_Profile_Link__c',
          type: 'string'
        },
        "Technical_Contact_Mobile__c": {
          name: 'Technical_Contact_Mobile__c',
          type: 'string'
        },
        "Technical_Contact_Phone__c": {
          name: 'Technical_Contact_Phone__c',
          type: 'string'
        },
        "Technical_Contact_State__c": {
          name: 'Technical_Contact_State__c',
          type: 'string'
        },
        "Technical_Contact_Street__c": {
          name: 'Technical_Contact_Street__c',
          type: 'string'
        },
        "Technical_Contact_Time_Zone__c": {
          name: 'Technical_Contact_Time_Zone__c',
          type: 'string'
        },
        "Technical_Contact_Title__c": {
          name: 'Technical_Contact_Title__c',
          type: 'string'
        },
        "Technical_Contact_Zip__c": {
          name: 'Technical_Contact_Zip__c',
          type: 'string'
        },
        "Technical_Onboarding__c": {
          name: 'Technical_Onboarding__c',
          type: 'string'
        },
        "Technology_Supported_Advance_Expertise__c": {
          name: 'Technology_Supported_Advance_Expertise__c',
          type: 'string'
        },
        "Technology_Supported_Basic_Expertise__c": {
          name: 'Technology_Supported_Basic_Expertise__c',
          type: 'string'
        },
        "Technology_Supported_Certified_Staff__c": {
          name: 'Technology_Supported_Certified_Staff__c',
          type: 'string'
        },
        "timeZoneId__c": {
          name: 'timeZoneId__c',
          type: 'string'
        },
        "Total_Experience_Months__c": {
          name: 'Total_Experience_Months__c',
          type: 'number'
        },
        "Training_Dispatch__c": {
          name: 'Training_Dispatch__c',
          type: 'string'
        },
        "Types_of_Technical_Resources_Specialty__c": {
          name: 'Types_of_Technical_Resources_Specialty__c',
          type: 'string'
        },
        "Vendor__c": {
          name: 'Vendor__c',
          type: 'string'
        },
        "Vendor_Expertise__c": {
          name: 'Vendor_Expertise__c',
          type: 'string'
        },
        "Vendor_has_VCSQDLead_Ref__c": {
          name: 'Vendor_has_VCSQDLead_Ref__c',
          type: 'boolean'
        },
        "Vendor_Has_Logged_in_Contact__c": {
          name: 'Vendor_Has_Logged_in_Contact__c',
          type: 'boolean'
        },
        "Vendor_Has_PgMO_Program__c": {
          name: 'Vendor_Has_PgMO_Program__c',
          type: 'boolean'
        },
        "Vendors_Supported_Advance_Expertise__c": {
          name: 'Vendors_Supported_Advance_Expertise__c',
          type: 'string'
        },
        "Vendors_Supported_Certified_Staff__c": {
          name: 'Vendors_Supported_Certified_Staff__c',
          type: 'string'
        },
        "Vendor_Status__c": {
          name: 'Vendor_Status__c',
          type: 'string'
        },
        "Vendor_Status_Step2__c": {
          name: 'Vendor_Status_Step2__c',
          type: 'string'
        },
        "Vendor_Status_Step3__c": {
          name: 'Vendor_Status_Step3__c',
          type: 'string'
        },
        "VOIP_Equipment_Break_Fix__c": {
          name: 'VOIP_Equipment_Break_Fix__c',
          type: 'string'
        },
        "Weekend_Multiplier__c": {
          name: 'Weekend_Multiplier__c',
          type: 'number'
        },
        "what_is_the_best_language_option__c": {
          name: 'what_is_the_best_language_option__c',
          type: 'string'
        },
        "Windows_Server_Software_Break_Fix__c": {
          name: 'Windows_Server_Software_Break_Fix__c',
          type: 'string'
        },
        "Workers_Compensation_insurance_Details__c": {
          name: 'Workers_Compensation_insurance_Details__c',
          type: 'string'
        },
        "cancelled_Cheque_Details__c": {
          name: 'cancelled_Cheque_Details__c',
          type: 'string'
        },
        "GST_VAT_Registration_certificate__c": {
          name: 'GST_VAT_Registration_certificate__c',
          type: 'string'
        },
        "service_Tax_Registration__c": {
          name: 'service_Tax_Registration__c',
          type: 'string'
        },
        "Incorporation_Business_Registration_certificate": {
          name: 'Incorporation_Business_Registration_certificate',
          type: 'string'
        },
        "Tax_Identification_Number__c": {
          name: 'Tax_Identification_Number__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        vendor: {
          name: 'vendor',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'Vendor__c',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
