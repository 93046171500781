import { AppCustomDualListComponent } from './custom-dual-list.component';
import { NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        FormsModule
    ],
    declarations: [
        AppCustomDualListComponent
    ],
    exports: [
        AppCustomDualListComponent
    ]
})
export class CustomDualListModule {
}
