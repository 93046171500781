/* tslint:disable */
import {
  Account,
  Case,
  Project,
  Jobsite,
  Product,
  Job,
  OrderItem,
  RequestFormReceiptEscalation
} from '../index';

declare var Object: any;
export interface OrderInterface {
  "sfdcId": string;
  "AccountId"?: string;
  "ActivatedById"?: string;
  "ActivatedDate"?: Date;
  "Actual_VAT_Tax__c"?: string;
  "Additional_Job_Time_Amount__c"?: string;
  "Already_Invoiced_Amount__c"?: number;
  "Already_Invoiced_Hours__c"?: number;
  "Approved_Timecard_Hours_Overtime__c"?: number;
  "Approved_Timecard_Hours_Standard__c"?: number;
  "Approved_Timecard_Hours_Weekend__c"?: number;
  "Asset__c"?: string;
  "Auto_From_Case__c"?: boolean;
  "Case__c"?: string;
  "Caseid__c"?: string;
  "CompanyAuthorizedById"?: string;
  "ContractId"?: string;
  "Contract_Order_Request__c"?: string;
  "Count_of_Order_Items__c"?: number;
  "CreatedById"?: string;
  "CreatedDate"?: Date;
  "Csum__c"?: string;
  "CurrencyIsoCode"?: string;
  "Custom_Hourly_SubTotal_Auto_PreTax_FTE__c"?: string;
  "Custom_Price_on_Service_Contract__c"?: boolean;
  "CustomerAuthorizedById"?: string;
  "Description"?: string;
  "Dispatch_Automation_Message__c"?: string;
  "Dispatch_Order_Audit_Done__c"?: boolean;
  "EffectiveDate"?: Date;
  "EndDate"?: Date;
  "FTE_Configuration_Type__c"?: string;
  "FTE_Duration__c"?: number;
  "FTE_Monthly_Rate_Custom__c"?: string;
  "FTE_Monthly_Rate__c"?: string;
  "FTE_Overtime_Hourly_Rate_Holiday__c"?: string;
  "FTE_Overtime_Hourly_Rate_Standard__c"?: string;
  "FTE_Utilization_factor__c"?: string;
  "Grand_Total_Total_Frm_Line_Item_Auto__c"?: number;
  "Grand_Total_Total_From_Line_Item__c"?: string;
  "ICC_Case__c"?: string;
  "Iron_PMS_Case_Number__c"?: string;
  "Iron_Purchase_Order_Reference__c"?: string;
  "IsDeleted"?: boolean;
  "IsReductionOrder"?: boolean;
  "Job_Site_Audit_Done__c"?: boolean;
  "Job_Status__c"?: string;
  "Job_Worker_Name__c"?: string;
  "Jobsite_Name__c"?: string;
  "Jobsite__c"?: string;
  "LOTS__c"?: string;
  "LastModifiedById"?: string;
  "LastModifiedDate"?: Date;
  "LastReferencedDate"?: Date;
  "LastViewedDate"?: Date;
  "Last_Approved_Timecard_Amount_Auto__c"?: string;
  "OrderNumber"?: string;
  "Order_Product__c"?: string;
  "Order_Status__c"?: string;
  "Order__c"?: string;
  "OriginalOrderId"?: string;
  "OwnerId"?: string;
  "PO_created__c"?: boolean;
  "PTS__c"?: string;
  "Partner_Case_Number__c"?: string;
  "Pricebook2Id"?: string;
  "Pricelist__c"?: string;
  "Program__c"?: string;
  "ProjectID__c"?: string;
  "Project_Audit_Done__c"?: boolean;
  "Project_Case_Description__c"?: string;
  "Project_Case_Subject__c"?: string;
  "Project_Vendor__c"?: string;
  "Project__c"?: string;
  "Projectnum__c"?: string;
  "Purchase_Order_Audit_Done__c"?: boolean;
  "RecordTypeId"?: string;
  "RecordTypeId__c"?: string;
  "Regenerated__c"?: boolean;
  "Roll_up_Additional_Hours__c"?: number;
  "Roll_up_Additional_Rate__c"?: number;
  "Roll_up_Days_Contract__c"?: number;
  "Roll_up_Discount__c"?: number;
  "Roll_up_FTE_OT_Holiday__c"?: number;
  "Roll_up_FTE_OT_Standard__c"?: number;
  "Roll_up_Helpdesk_Price__c"?: number;
  "Roll_up_List_Price__c"?: number;
  "Roll_up_PPE_Hours__c"?: number;
  "Roll_up_Timecard_s_IRON_Approval_Status__c"?: number;
  "Roll_up_Total__c"?: string;
  "Roll_up_Unit_Price__c"?: number;
  "Roll_up_Unit_Rate_Custom__c"?: string;
  "Roll_up_VAT_Tax__c"?: string;
  "Roll_up_VAT_percent__c"?: number;
  "SKU_Audit_Done__c"?: boolean;
  "SKU_Units__c"?: string;
  "SKU__c"?: string;
  "SO_Grand_Total_Audit_Done__c"?: boolean;
  "SO_Line_Item_Audit_Done__c"?: boolean;
  "SO_VAT_Audit_Done__c"?: boolean;
  "Sales_Order_PMS_Ref__c"?: string;
  "Schedule_Daily__c"?: string;
  "Schedule_Type__c"?: string;
  "Schedule__c"?: string;
  "Service_Contract_Line_Item__c"?: string;
  "Service_Contract__c"?: string;
  "Service_Contract_has_invoice__c"?: boolean;
  "Service_Dispatch_Frequency__c"?: string;
  "Service_Dispatch__c"?: string;
  "SoW_Description_Customer__c"?: string;
  "Special_Instructions__c"?: string;
  "Special_Terms__c"?: string;
  "Status"?: string;
  "StatusCode"?: string;
  "Term_months__c"?: number;
  "Timecard_Received_Final__c"?: string;
  "TotalAmount"?: number;
  "Total_Approved_Timecard_Hours_del__c"?: number;
  "Total_Cost_Customer_Pre_Tax_Auto_FTE__c"?: string;
  "Total_Custom_Cost_Partner_Pre_Tax_FTE__c"?: string;
  "Total_FTE_Hours__c"?: number;
  "Total_From_Line_Items_Auto__c"?: number;
  "Total_Invoice_Amount__c"?: number;
  "Total_Un_Invoiced_Amount__c"?: string;
  "Type"?: string;
  "Update_Order__c"?: boolean;
  "Usage_Unit_Weekly__c"?: string;
  "VAT_Excluded__c"?: boolean;
  "Vendor_Currency__c"?: string;
  "Vendor_Time_Card_Notes_Tasks_Performed__c"?: string;
  "Vendor_Time_Card_Observation_Suggestion__c"?: string;
  "Vendor_Time_Card_Total_Hours_All_Visits__c"?: number;
  "Vendor_Timecard_Cust_Site_Sign_off_Name__c"?: string;
  "Vendor_Timecard_Details_Roll_up__c"?: string;
  "Work_Order__c"?: string;
  "X3PS_Dispatch_Request__c"?: string;
  "id"?: number;
  account?: Account;
  case?: Case;
  project?: Project;
  jobsite?: Jobsite;
  product?: Product;
  vendor?: Account;
  job?: Job;
  orderItems?: OrderItem[];
  requestFormReceiptEscalation?: RequestFormReceiptEscalation[];
}

export class Order implements OrderInterface {
  "sfdcId": string;
  "AccountId": string;
  "ActivatedById": string;
  "ActivatedDate": Date;
  "Actual_VAT_Tax__c": string;
  "Additional_Job_Time_Amount__c": string;
  "Already_Invoiced_Amount__c": number;
  "Already_Invoiced_Hours__c": number;
  "Approved_Timecard_Hours_Overtime__c": number;
  "Approved_Timecard_Hours_Standard__c": number;
  "Approved_Timecard_Hours_Weekend__c": number;
  "Asset__c": string;
  "Auto_From_Case__c": boolean;
  "Case__c": string;
  "Caseid__c": string;
  "CompanyAuthorizedById": string;
  "ContractId": string;
  "Contract_Order_Request__c": string;
  "Count_of_Order_Items__c": number;
  "CreatedById": string;
  "CreatedDate": Date;
  "Csum__c": string;
  "CurrencyIsoCode": string;
  "Custom_Hourly_SubTotal_Auto_PreTax_FTE__c": string;
  "Custom_Price_on_Service_Contract__c": boolean;
  "CustomerAuthorizedById": string;
  "Description": string;
  "Dispatch_Automation_Message__c": string;
  "Dispatch_Order_Audit_Done__c": boolean;
  "EffectiveDate": Date;
  "EndDate": Date;
  "FTE_Configuration_Type__c": string;
  "FTE_Duration__c": number;
  "FTE_Monthly_Rate_Custom__c": string;
  "FTE_Monthly_Rate__c": string;
  "FTE_Overtime_Hourly_Rate_Holiday__c": string;
  "FTE_Overtime_Hourly_Rate_Standard__c": string;
  "FTE_Utilization_factor__c": string;
  "Grand_Total_Total_Frm_Line_Item_Auto__c": number;
  "Grand_Total_Total_From_Line_Item__c": string;
  "ICC_Case__c": string;
  "Iron_PMS_Case_Number__c": string;
  "Iron_Purchase_Order_Reference__c": string;
  "IsDeleted": boolean;
  "IsReductionOrder": boolean;
  "Job_Site_Audit_Done__c": boolean;
  "Job_Status__c": string;
  "Job_Worker_Name__c": string;
  "Jobsite_Name__c": string;
  "Jobsite__c": string;
  "LOTS__c": string;
  "LastModifiedById": string;
  "LastModifiedDate": Date;
  "LastReferencedDate": Date;
  "LastViewedDate": Date;
  "Last_Approved_Timecard_Amount_Auto__c": string;
  "OrderNumber": string;
  "Order_Product__c": string;
  "Order_Status__c": string;
  "Order__c": string;
  "OriginalOrderId": string;
  "OwnerId": string;
  "PO_created__c": boolean;
  "PTS__c": string;
  "Partner_Case_Number__c": string;
  "Pricebook2Id": string;
  "Pricelist__c": string;
  "Program__c": string;
  "ProjectID__c": string;
  "Project_Audit_Done__c": boolean;
  "Project_Case_Description__c": string;
  "Project_Case_Subject__c": string;
  "Project_Vendor__c": string;
  "Project__c": string;
  "Projectnum__c": string;
  "Purchase_Order_Audit_Done__c": boolean;
  "RecordTypeId": string;
  "RecordTypeId__c": string;
  "Regenerated__c": boolean;
  "Roll_up_Additional_Hours__c": number;
  "Roll_up_Additional_Rate__c": number;
  "Roll_up_Days_Contract__c": number;
  "Roll_up_Discount__c": number;
  "Roll_up_FTE_OT_Holiday__c": number;
  "Roll_up_FTE_OT_Standard__c": number;
  "Roll_up_Helpdesk_Price__c": number;
  "Roll_up_List_Price__c": number;
  "Roll_up_PPE_Hours__c": number;
  "Roll_up_Timecard_s_IRON_Approval_Status__c": number;
  "Roll_up_Total__c": string;
  "Roll_up_Unit_Price__c": number;
  "Roll_up_Unit_Rate_Custom__c": string;
  "Roll_up_VAT_Tax__c": string;
  "Roll_up_VAT_percent__c": number;
  "SKU_Audit_Done__c": boolean;
  "SKU_Units__c": string;
  "SKU__c": string;
  "SO_Grand_Total_Audit_Done__c": boolean;
  "SO_Line_Item_Audit_Done__c": boolean;
  "SO_VAT_Audit_Done__c": boolean;
  "Sales_Order_PMS_Ref__c": string;
  "Schedule_Daily__c": string;
  "Schedule_Type__c": string;
  "Schedule__c": string;
  "Service_Contract_Line_Item__c": string;
  "Service_Contract__c": string;
  "Service_Contract_has_invoice__c": boolean;
  "Service_Dispatch_Frequency__c": string;
  "Service_Dispatch__c": string;
  "SoW_Description_Customer__c": string;
  "Special_Instructions__c": string;
  "Special_Terms__c": string;
  "Status": string;
  "StatusCode": string;
  "Term_months__c": number;
  "Timecard_Received_Final__c": string;
  "TotalAmount": number;
  "Total_Approved_Timecard_Hours_del__c": number;
  "Total_Cost_Customer_Pre_Tax_Auto_FTE__c": string;
  "Total_Custom_Cost_Partner_Pre_Tax_FTE__c": string;
  "Total_FTE_Hours__c": number;
  "Total_From_Line_Items_Auto__c": number;
  "Total_Invoice_Amount__c": number;
  "Total_Un_Invoiced_Amount__c": string;
  "Type": string;
  "Update_Order__c": boolean;
  "Usage_Unit_Weekly__c": string;
  "VAT_Excluded__c": boolean;
  "Vendor_Currency__c": string;
  "Vendor_Time_Card_Notes_Tasks_Performed__c": string;
  "Vendor_Time_Card_Observation_Suggestion__c": string;
  "Vendor_Time_Card_Total_Hours_All_Visits__c": number;
  "Vendor_Timecard_Cust_Site_Sign_off_Name__c": string;
  "Vendor_Timecard_Details_Roll_up__c": string;
  "Work_Order__c": string;
  "X3PS_Dispatch_Request__c": string;
  "id": number;
  account: Account;
  case: Case;
  project: Project;
  jobsite: Jobsite;
  product: Product;
  vendor: Account;
  job: Job;
  orderItems: OrderItem[];
  requestFormReceiptEscalation: RequestFormReceiptEscalation[];
  constructor(data?: OrderInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Order`.
   */
  public static getModelName() {
    return "Order";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Order for dynamic purposes.
  **/
  public static factory(data: OrderInterface): Order{
    return new Order(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Order',
      plural: 'Orders',
      path: 'Orders',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "AccountId": {
          name: 'AccountId',
          type: 'string'
        },
        "ActivatedById": {
          name: 'ActivatedById',
          type: 'string'
        },
        "ActivatedDate": {
          name: 'ActivatedDate',
          type: 'Date'
        },
        "Actual_VAT_Tax__c": {
          name: 'Actual_VAT_Tax__c',
          type: 'string'
        },
        "Additional_Job_Time_Amount__c": {
          name: 'Additional_Job_Time_Amount__c',
          type: 'string'
        },
        "Already_Invoiced_Amount__c": {
          name: 'Already_Invoiced_Amount__c',
          type: 'number'
        },
        "Already_Invoiced_Hours__c": {
          name: 'Already_Invoiced_Hours__c',
          type: 'number'
        },
        "Approved_Timecard_Hours_Overtime__c": {
          name: 'Approved_Timecard_Hours_Overtime__c',
          type: 'number'
        },
        "Approved_Timecard_Hours_Standard__c": {
          name: 'Approved_Timecard_Hours_Standard__c',
          type: 'number'
        },
        "Approved_Timecard_Hours_Weekend__c": {
          name: 'Approved_Timecard_Hours_Weekend__c',
          type: 'number'
        },
        "Asset__c": {
          name: 'Asset__c',
          type: 'string'
        },
        "Auto_From_Case__c": {
          name: 'Auto_From_Case__c',
          type: 'boolean'
        },
        "Case__c": {
          name: 'Case__c',
          type: 'string'
        },
        "Caseid__c": {
          name: 'Caseid__c',
          type: 'string'
        },
        "CompanyAuthorizedById": {
          name: 'CompanyAuthorizedById',
          type: 'string'
        },
        "ContractId": {
          name: 'ContractId',
          type: 'string'
        },
        "Contract_Order_Request__c": {
          name: 'Contract_Order_Request__c',
          type: 'string'
        },
        "Count_of_Order_Items__c": {
          name: 'Count_of_Order_Items__c',
          type: 'number'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "Csum__c": {
          name: 'Csum__c',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "Custom_Hourly_SubTotal_Auto_PreTax_FTE__c": {
          name: 'Custom_Hourly_SubTotal_Auto_PreTax_FTE__c',
          type: 'string'
        },
        "Custom_Price_on_Service_Contract__c": {
          name: 'Custom_Price_on_Service_Contract__c',
          type: 'boolean'
        },
        "CustomerAuthorizedById": {
          name: 'CustomerAuthorizedById',
          type: 'string'
        },
        "Description": {
          name: 'Description',
          type: 'string'
        },
        "Dispatch_Automation_Message__c": {
          name: 'Dispatch_Automation_Message__c',
          type: 'string'
        },
        "Dispatch_Order_Audit_Done__c": {
          name: 'Dispatch_Order_Audit_Done__c',
          type: 'boolean'
        },
        "EffectiveDate": {
          name: 'EffectiveDate',
          type: 'Date'
        },
        "EndDate": {
          name: 'EndDate',
          type: 'Date'
        },
        "FTE_Configuration_Type__c": {
          name: 'FTE_Configuration_Type__c',
          type: 'string'
        },
        "FTE_Duration__c": {
          name: 'FTE_Duration__c',
          type: 'number'
        },
        "FTE_Monthly_Rate_Custom__c": {
          name: 'FTE_Monthly_Rate_Custom__c',
          type: 'string'
        },
        "FTE_Monthly_Rate__c": {
          name: 'FTE_Monthly_Rate__c',
          type: 'string'
        },
        "FTE_Overtime_Hourly_Rate_Holiday__c": {
          name: 'FTE_Overtime_Hourly_Rate_Holiday__c',
          type: 'string'
        },
        "FTE_Overtime_Hourly_Rate_Standard__c": {
          name: 'FTE_Overtime_Hourly_Rate_Standard__c',
          type: 'string'
        },
        "FTE_Utilization_factor__c": {
          name: 'FTE_Utilization_factor__c',
          type: 'string'
        },
        "Grand_Total_Total_Frm_Line_Item_Auto__c": {
          name: 'Grand_Total_Total_Frm_Line_Item_Auto__c',
          type: 'number'
        },
        "Grand_Total_Total_From_Line_Item__c": {
          name: 'Grand_Total_Total_From_Line_Item__c',
          type: 'string'
        },
        "ICC_Case__c": {
          name: 'ICC_Case__c',
          type: 'string'
        },
        "Iron_PMS_Case_Number__c": {
          name: 'Iron_PMS_Case_Number__c',
          type: 'string'
        },
        "Iron_Purchase_Order_Reference__c": {
          name: 'Iron_Purchase_Order_Reference__c',
          type: 'string'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "IsReductionOrder": {
          name: 'IsReductionOrder',
          type: 'boolean'
        },
        "Job_Site_Audit_Done__c": {
          name: 'Job_Site_Audit_Done__c',
          type: 'boolean'
        },
        "Job_Status__c": {
          name: 'Job_Status__c',
          type: 'string'
        },
        "Job_Worker_Name__c": {
          name: 'Job_Worker_Name__c',
          type: 'string'
        },
        "Jobsite_Name__c": {
          name: 'Jobsite_Name__c',
          type: 'string'
        },
        "Jobsite__c": {
          name: 'Jobsite__c',
          type: 'string'
        },
        "LOTS__c": {
          name: 'LOTS__c',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "LastReferencedDate": {
          name: 'LastReferencedDate',
          type: 'Date'
        },
        "LastViewedDate": {
          name: 'LastViewedDate',
          type: 'Date'
        },
        "Last_Approved_Timecard_Amount_Auto__c": {
          name: 'Last_Approved_Timecard_Amount_Auto__c',
          type: 'string'
        },
        "OrderNumber": {
          name: 'OrderNumber',
          type: 'string'
        },
        "Order_Product__c": {
          name: 'Order_Product__c',
          type: 'string'
        },
        "Order_Status__c": {
          name: 'Order_Status__c',
          type: 'string'
        },
        "Order__c": {
          name: 'Order__c',
          type: 'string'
        },
        "OriginalOrderId": {
          name: 'OriginalOrderId',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "PO_created__c": {
          name: 'PO_created__c',
          type: 'boolean'
        },
        "PTS__c": {
          name: 'PTS__c',
          type: 'string'
        },
        "Partner_Case_Number__c": {
          name: 'Partner_Case_Number__c',
          type: 'string'
        },
        "Pricebook2Id": {
          name: 'Pricebook2Id',
          type: 'string'
        },
        "Pricelist__c": {
          name: 'Pricelist__c',
          type: 'string'
        },
        "Program__c": {
          name: 'Program__c',
          type: 'string'
        },
        "ProjectID__c": {
          name: 'ProjectID__c',
          type: 'string'
        },
        "Project_Audit_Done__c": {
          name: 'Project_Audit_Done__c',
          type: 'boolean'
        },
        "Project_Case_Description__c": {
          name: 'Project_Case_Description__c',
          type: 'string'
        },
        "Project_Case_Subject__c": {
          name: 'Project_Case_Subject__c',
          type: 'string'
        },
        "Project_Vendor__c": {
          name: 'Project_Vendor__c',
          type: 'string'
        },
        "Project__c": {
          name: 'Project__c',
          type: 'string'
        },
        "Projectnum__c": {
          name: 'Projectnum__c',
          type: 'string'
        },
        "Purchase_Order_Audit_Done__c": {
          name: 'Purchase_Order_Audit_Done__c',
          type: 'boolean'
        },
        "RecordTypeId": {
          name: 'RecordTypeId',
          type: 'string'
        },
        "RecordTypeId__c": {
          name: 'RecordTypeId__c',
          type: 'string'
        },
        "Regenerated__c": {
          name: 'Regenerated__c',
          type: 'boolean'
        },
        "Roll_up_Additional_Hours__c": {
          name: 'Roll_up_Additional_Hours__c',
          type: 'number'
        },
        "Roll_up_Additional_Rate__c": {
          name: 'Roll_up_Additional_Rate__c',
          type: 'number'
        },
        "Roll_up_Days_Contract__c": {
          name: 'Roll_up_Days_Contract__c',
          type: 'number'
        },
        "Roll_up_Discount__c": {
          name: 'Roll_up_Discount__c',
          type: 'number'
        },
        "Roll_up_FTE_OT_Holiday__c": {
          name: 'Roll_up_FTE_OT_Holiday__c',
          type: 'number'
        },
        "Roll_up_FTE_OT_Standard__c": {
          name: 'Roll_up_FTE_OT_Standard__c',
          type: 'number'
        },
        "Roll_up_Helpdesk_Price__c": {
          name: 'Roll_up_Helpdesk_Price__c',
          type: 'number'
        },
        "Roll_up_List_Price__c": {
          name: 'Roll_up_List_Price__c',
          type: 'number'
        },
        "Roll_up_PPE_Hours__c": {
          name: 'Roll_up_PPE_Hours__c',
          type: 'number'
        },
        "Roll_up_Timecard_s_IRON_Approval_Status__c": {
          name: 'Roll_up_Timecard_s_IRON_Approval_Status__c',
          type: 'number'
        },
        "Roll_up_Total__c": {
          name: 'Roll_up_Total__c',
          type: 'string'
        },
        "Roll_up_Unit_Price__c": {
          name: 'Roll_up_Unit_Price__c',
          type: 'number'
        },
        "Roll_up_Unit_Rate_Custom__c": {
          name: 'Roll_up_Unit_Rate_Custom__c',
          type: 'string'
        },
        "Roll_up_VAT_Tax__c": {
          name: 'Roll_up_VAT_Tax__c',
          type: 'string'
        },
        "Roll_up_VAT_percent__c": {
          name: 'Roll_up_VAT_percent__c',
          type: 'number'
        },
        "SKU_Audit_Done__c": {
          name: 'SKU_Audit_Done__c',
          type: 'boolean'
        },
        "SKU_Units__c": {
          name: 'SKU_Units__c',
          type: 'string'
        },
        "SKU__c": {
          name: 'SKU__c',
          type: 'string'
        },
        "SO_Grand_Total_Audit_Done__c": {
          name: 'SO_Grand_Total_Audit_Done__c',
          type: 'boolean'
        },
        "SO_Line_Item_Audit_Done__c": {
          name: 'SO_Line_Item_Audit_Done__c',
          type: 'boolean'
        },
        "SO_VAT_Audit_Done__c": {
          name: 'SO_VAT_Audit_Done__c',
          type: 'boolean'
        },
        "Sales_Order_PMS_Ref__c": {
          name: 'Sales_Order_PMS_Ref__c',
          type: 'string'
        },
        "Schedule_Daily__c": {
          name: 'Schedule_Daily__c',
          type: 'string'
        },
        "Schedule_Type__c": {
          name: 'Schedule_Type__c',
          type: 'string'
        },
        "Schedule__c": {
          name: 'Schedule__c',
          type: 'string'
        },
        "Service_Contract_Line_Item__c": {
          name: 'Service_Contract_Line_Item__c',
          type: 'string'
        },
        "Service_Contract__c": {
          name: 'Service_Contract__c',
          type: 'string'
        },
        "Service_Contract_has_invoice__c": {
          name: 'Service_Contract_has_invoice__c',
          type: 'boolean'
        },
        "Service_Dispatch_Frequency__c": {
          name: 'Service_Dispatch_Frequency__c',
          type: 'string'
        },
        "Service_Dispatch__c": {
          name: 'Service_Dispatch__c',
          type: 'string'
        },
        "SoW_Description_Customer__c": {
          name: 'SoW_Description_Customer__c',
          type: 'string'
        },
        "Special_Instructions__c": {
          name: 'Special_Instructions__c',
          type: 'string'
        },
        "Special_Terms__c": {
          name: 'Special_Terms__c',
          type: 'string'
        },
        "Status": {
          name: 'Status',
          type: 'string'
        },
        "StatusCode": {
          name: 'StatusCode',
          type: 'string'
        },
        "Term_months__c": {
          name: 'Term_months__c',
          type: 'number'
        },
        "Timecard_Received_Final__c": {
          name: 'Timecard_Received_Final__c',
          type: 'string'
        },
        "TotalAmount": {
          name: 'TotalAmount',
          type: 'number'
        },
        "Total_Approved_Timecard_Hours_del__c": {
          name: 'Total_Approved_Timecard_Hours_del__c',
          type: 'number'
        },
        "Total_Cost_Customer_Pre_Tax_Auto_FTE__c": {
          name: 'Total_Cost_Customer_Pre_Tax_Auto_FTE__c',
          type: 'string'
        },
        "Total_Custom_Cost_Partner_Pre_Tax_FTE__c": {
          name: 'Total_Custom_Cost_Partner_Pre_Tax_FTE__c',
          type: 'string'
        },
        "Total_FTE_Hours__c": {
          name: 'Total_FTE_Hours__c',
          type: 'number'
        },
        "Total_From_Line_Items_Auto__c": {
          name: 'Total_From_Line_Items_Auto__c',
          type: 'number'
        },
        "Total_Invoice_Amount__c": {
          name: 'Total_Invoice_Amount__c',
          type: 'number'
        },
        "Total_Un_Invoiced_Amount__c": {
          name: 'Total_Un_Invoiced_Amount__c',
          type: 'string'
        },
        "Type": {
          name: 'Type',
          type: 'string'
        },
        "Update_Order__c": {
          name: 'Update_Order__c',
          type: 'boolean'
        },
        "Usage_Unit_Weekly__c": {
          name: 'Usage_Unit_Weekly__c',
          type: 'string'
        },
        "VAT_Excluded__c": {
          name: 'VAT_Excluded__c',
          type: 'boolean'
        },
        "Vendor_Currency__c": {
          name: 'Vendor_Currency__c',
          type: 'string'
        },
        "Vendor_Time_Card_Notes_Tasks_Performed__c": {
          name: 'Vendor_Time_Card_Notes_Tasks_Performed__c',
          type: 'string'
        },
        "Vendor_Time_Card_Observation_Suggestion__c": {
          name: 'Vendor_Time_Card_Observation_Suggestion__c',
          type: 'string'
        },
        "Vendor_Time_Card_Total_Hours_All_Visits__c": {
          name: 'Vendor_Time_Card_Total_Hours_All_Visits__c',
          type: 'number'
        },
        "Vendor_Timecard_Cust_Site_Sign_off_Name__c": {
          name: 'Vendor_Timecard_Cust_Site_Sign_off_Name__c',
          type: 'string'
        },
        "Vendor_Timecard_Details_Roll_up__c": {
          name: 'Vendor_Timecard_Details_Roll_up__c',
          type: 'string'
        },
        "Work_Order__c": {
          name: 'Work_Order__c',
          type: 'string'
        },
        "X3PS_Dispatch_Request__c": {
          name: 'X3PS_Dispatch_Request__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'AccountId',
          keyTo: 'sfdcId'
        },
        case: {
          name: 'case',
          type: 'Case',
          model: 'Case',
          relationType: 'belongsTo',
                  keyFrom: 'Case__c',
          keyTo: 'sfdcId'
        },
        project: {
          name: 'project',
          type: 'Project',
          model: 'Project',
          relationType: 'belongsTo',
                  keyFrom: 'Project__c',
          keyTo: 'sfdcId'
        },
        jobsite: {
          name: 'jobsite',
          type: 'Jobsite',
          model: 'Jobsite',
          relationType: 'belongsTo',
                  keyFrom: 'Jobsite__c',
          keyTo: 'sfdcId'
        },
        product: {
          name: 'product',
          type: 'Product',
          model: 'Product',
          relationType: 'belongsTo',
                  keyFrom: 'SKU__c',
          keyTo: 'sfdcId'
        },
        vendor: {
          name: 'vendor',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'Project_Vendor__c',
          keyTo: 'sfdcId'
        },
        job: {
          name: 'job',
          type: 'Job',
          model: 'Job',
          relationType: 'belongsTo',
                  keyFrom: 'Service_Dispatch__c',
          keyTo: 'sfdcId'
        },
        orderItems: {
          name: 'orderItems',
          type: 'OrderItem[]',
          model: 'OrderItem',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'OrderId'
        },
        requestFormReceiptEscalation: {
          name: 'requestFormReceiptEscalation',
          type: 'RequestFormReceiptEscalation[]',
          model: 'RequestFormReceiptEscalation',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'Sales_Order__c'
        },
      }
    }
  }
}
