import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PurchaseInvoiceStatusLookupComponent } from './purchase-invoice-status-lookup/purchase-invoice-status-lookup.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule
  ],
  declarations: [PurchaseInvoiceStatusLookupComponent],
  exports: [PurchaseInvoiceStatusLookupComponent]
})
export class PurchaseInvoiceStatusLookupModule { }
