import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServiceContractNumLookupComponent } from './service-contract-num-lookup.component';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgSelectModule
    ],
    declarations: [ServiceContractNumLookupComponent],
    exports: [ServiceContractNumLookupComponent]
})
export class ServiceContractNumLookupModule {
}
