import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonService } from 'shared/services/common.service';

@Component({
  selector: 'app-worker-scheduled-lookup',
  templateUrl: './worker-scheduled-lookup.component.html',
  styleUrls: ['./worker-scheduled-lookup.component.css']
})
export class WorkerScheduledLookupComponent implements OnInit {

  @Input() placeholder = 'Worker Scheduled';
  @Input() set resetData(isClear) {
    this.selectedDate = [];
  }
  @Output() selectedValue: EventEmitter<any> = new EventEmitter;
  selectedDate: any = [];
  max = new Date();

  constructor(private _commonService: CommonService) { }
  ngOnInit() {
    // to show selected date range
    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
    if (preselected && preselected['workerScheduled'] && preselected['workerScheduled'][0]) {
      this.selectedDate = [this._commonService.applyNewTimeZone(preselected['workerScheduled'][0][0], 'YYYY-MM-DDTHH:mm:ss'), this._commonService.applyNewTimeZone(preselected['workerScheduled'][0][1], 'YYYY-MM-DDTHH:mm:ss')];
    }
    // if (this._from && this._from === 'startFromDate') {
    //   if (preselected && preselected['startFromDateOptions'] && preselected['startFromDateOptions'][0]) {
    //     this.selectedDate = [this._commonService.applyNewTimeZone(preselected['startFromDateOptions'][0][0], 'YYYY-MM-DDTHH:mm:ss'), this._commonService.applyNewTimeZone(preselected['startFromDateOptions'][0][1], 'YYYY-MM-DDTHH:mm:ss')];
    //   }
    //   this.max = new Date(3000, 3, 21, 20, 30);
    // } else {

    // }
  }
  onChange() {
    this.selectedValue.emit(this.selectedDate);
  }

  onClearDate() {
    this.selectedDate = [];
    this.selectedValue.emit([]);
  }

}
