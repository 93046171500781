/* tslint:disable */

declare var Object: any;
export interface TrainingPlanInterface {
  "sfdcId"?: string;
  "OwnerId"?: string;
  "IsDeleted"?: boolean;
  "Name"?: string;
  "CurrencyIsoCode"?: string;
  "CreatedDate"?: Date;
  "CreatedById"?: string;
  "LastModifiedDate"?: Date;
  "LastModifiedById"?: string;
  "SystemModstamp"?: Date;
  "LastActivityDate"?: Date;
  "LastViewedDate"?: Date;
  "LastReferencedDate"?: Date;
  "Background_Color__c"?: string;
  "Background_Image_URL__c"?: string;
  "Chatter_Notifications_Enabled__c"?: boolean;
  "Custom_Notification_Message__c"?: number;
  "Description__c"?: string;
  "Enable_Self_Registration__c"?: boolean;
  "End_Date__c"?: Date;
  "Enforce_Sequence__c"?: boolean;
  "Hide_After_End_Date__c"?: boolean;
  "Hide_Chatter_Photos__c"?: boolean;
  "Intro_Video_URL__c"?: string;
  "New_Until__c"?: Date;
  "Optional_Feedback_Comments__c"?: boolean;
  "Self_Registration_Access__c"?: string;
  "Start_Date__c"?: Date;
  "Status__c"?: string;
  "Text_Color__c"?: string;
  "Total_Ratings_Count__c"?: string;
  "Total_Ratings_Sum__c"?: string;
  "Total_Section_Items__c"?: string;
  "Training_Plan_Rating__c"?: number;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class TrainingPlan implements TrainingPlanInterface {
  "sfdcId": string;
  "OwnerId": string;
  "IsDeleted": boolean;
  "Name": string;
  "CurrencyIsoCode": string;
  "CreatedDate": Date;
  "CreatedById": string;
  "LastModifiedDate": Date;
  "LastModifiedById": string;
  "SystemModstamp": Date;
  "LastActivityDate": Date;
  "LastViewedDate": Date;
  "LastReferencedDate": Date;
  "Background_Color__c": string;
  "Background_Image_URL__c": string;
  "Chatter_Notifications_Enabled__c": boolean;
  "Custom_Notification_Message__c": number;
  "Description__c": string;
  "Enable_Self_Registration__c": boolean;
  "End_Date__c": Date;
  "Enforce_Sequence__c": boolean;
  "Hide_After_End_Date__c": boolean;
  "Hide_Chatter_Photos__c": boolean;
  "Intro_Video_URL__c": string;
  "New_Until__c": Date;
  "Optional_Feedback_Comments__c": boolean;
  "Self_Registration_Access__c": string;
  "Start_Date__c": Date;
  "Status__c": string;
  "Text_Color__c": string;
  "Total_Ratings_Count__c": string;
  "Total_Ratings_Sum__c": string;
  "Total_Section_Items__c": string;
  "Training_Plan_Rating__c": number;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: TrainingPlanInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `TrainingPlan`.
   */
  public static getModelName() {
    return "TrainingPlan";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of TrainingPlan for dynamic purposes.
  **/
  public static factory(data: TrainingPlanInterface): TrainingPlan{
    return new TrainingPlan(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'TrainingPlan',
      plural: 'TrainingPlans',
      path: 'TrainingPlans',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "SystemModstamp": {
          name: 'SystemModstamp',
          type: 'Date'
        },
        "LastActivityDate": {
          name: 'LastActivityDate',
          type: 'Date'
        },
        "LastViewedDate": {
          name: 'LastViewedDate',
          type: 'Date'
        },
        "LastReferencedDate": {
          name: 'LastReferencedDate',
          type: 'Date'
        },
        "Background_Color__c": {
          name: 'Background_Color__c',
          type: 'string'
        },
        "Background_Image_URL__c": {
          name: 'Background_Image_URL__c',
          type: 'string'
        },
        "Chatter_Notifications_Enabled__c": {
          name: 'Chatter_Notifications_Enabled__c',
          type: 'boolean'
        },
        "Custom_Notification_Message__c": {
          name: 'Custom_Notification_Message__c',
          type: 'number'
        },
        "Description__c": {
          name: 'Description__c',
          type: 'string'
        },
        "Enable_Self_Registration__c": {
          name: 'Enable_Self_Registration__c',
          type: 'boolean'
        },
        "End_Date__c": {
          name: 'End_Date__c',
          type: 'Date'
        },
        "Enforce_Sequence__c": {
          name: 'Enforce_Sequence__c',
          type: 'boolean'
        },
        "Hide_After_End_Date__c": {
          name: 'Hide_After_End_Date__c',
          type: 'boolean'
        },
        "Hide_Chatter_Photos__c": {
          name: 'Hide_Chatter_Photos__c',
          type: 'boolean'
        },
        "Intro_Video_URL__c": {
          name: 'Intro_Video_URL__c',
          type: 'string'
        },
        "New_Until__c": {
          name: 'New_Until__c',
          type: 'Date'
        },
        "Optional_Feedback_Comments__c": {
          name: 'Optional_Feedback_Comments__c',
          type: 'boolean'
        },
        "Self_Registration_Access__c": {
          name: 'Self_Registration_Access__c',
          type: 'string'
        },
        "Start_Date__c": {
          name: 'Start_Date__c',
          type: 'Date'
        },
        "Status__c": {
          name: 'Status__c',
          type: 'string'
        },
        "Text_Color__c": {
          name: 'Text_Color__c',
          type: 'string'
        },
        "Total_Ratings_Count__c": {
          name: 'Total_Ratings_Count__c',
          type: 'string'
        },
        "Total_Ratings_Sum__c": {
          name: 'Total_Ratings_Sum__c',
          type: 'string'
        },
        "Total_Section_Items__c": {
          name: 'Total_Section_Items__c',
          type: 'string'
        },
        "Training_Plan_Rating__c": {
          name: 'Training_Plan_Rating__c',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
