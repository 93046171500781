import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ProductApi } from 'shared/sdk';
import { debounceTime, switchMap, filter } from 'rxjs/operators';

@Component({
    selector: 'app-default-sku-project-profile-lookup',
    templateUrl: './default-sku-project-profile-lookup.component.html',
    styleUrls: ['./default-sku-project-profile-lookup.component.scss']
  })
  export class DefaultSkuProjectProfileLookupComponent implements OnInit {
  @Input() boxedLayout = false;
  @Input() set resetData(isClear) {
    if (isClear) {
      this.defaultSKUSelected = [];
    }
  }
  defaultSKUahead: EventEmitter<any> = new EventEmitter();
  noDefaultSKU: string;
  defaultSKUOptionList: any[] = [];
  defaultSKUSelected = [];
  @Input() placeholder = 'Default SKU';
  @Input() isdisabled = false;
  @Input() isMultipleSelected = true;
  @Input() bindValue = 'Name';
  @Input() bindLabel = 'Name';

  @Output() getDefaultSKUObj: EventEmitter<any> = new EventEmitter;
  @Input() set preselected(e) {
    if (e) {
      this.defaultSKUSelected = e;
      let reqobj = {
        fields: ['sfdcId', 'Name', 'Description', 'GEO_Metro_Vendor_FSL_code__c', 'ProductCode'],
        where: { sfdcId: this.defaultSKUSelected }
      }
      this._productApi.find(reqobj).subscribe(result => {
        if (result && result.length) {
          this.defaultSKUOptionList = result;
          console.log('check default sku', this.defaultSKUOptionList)
          this.noDefaultSKU = (this.defaultSKUOptionList.length === 0) ? 'No data found.' : '';
        }
      });
    }
  }
  @Input() inGlobalFilter = true;

  

  /**
   * @constructor
   * Represents a TalentTypeLookupComponent.
   */
  constructor(
    private _productApi: ProductApi
  ) {
  }

  ngOnInit() {
    if(this.inGlobalFilter && (!this.defaultSKUSelected || !this.defaultSKUSelected.length)){
      const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
      this.defaultSKUSelected = (preselected && preselected['defaultSKU']) ? preselected['defaultSKU'] : [];
    }
    this.getDefaultSKU();
  }

  getDefaultSKU() {
    this.defaultSKUahead
      .pipe(
        filter(search => search && search.length > 3),
        debounceTime(200),
        switchMap(search => {
          const searchPattern = { like: '%' + search.trim() + '%', options: 'i' };
          return this.findDefaultSKUApi(searchPattern);
        })
      ).subscribe(
        data => {
          if (data && data.length) {
            this.defaultSKUOptionList = data;
          } else {
            this.defaultSKUOptionList = [];
            this.noDefaultSKU = 'No default SKU found.';
          }
        },
        err => {
          console.log('Error fetching default SKU', err.message);
        }
      );
  }

  findDefaultSKUApi(searchText) {
    let whereObj;
    if (this.bindLabel === 'Talent_Type_Name__c') {
      whereObj = { Talent_Type_Name__c: searchText };
    } else {
      whereObj = { Name: searchText };
    }
    return this._productApi.find({
      where: whereObj,
    //   fields: ['id', 'sfdcId', 'Name', 'Talent_Type_Name__c']
      fields: ['sfdcId', 'Name', 'Description', 'GEO_Metro_Vendor_FSL_code__c', 'ProductCode']
    })
  }

  onDefaultSKUChange() {
    this.getDefaultSKUObj.emit(this.defaultSKUSelected);
  }

  clearDefaultSKU() {
    this.defaultSKUSelected = [];
    this.getDefaultSKUObj.emit([]);
  }

}
