import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InitiativeLookupComponent } from './initiative-lookup.component';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule
  ],
  declarations: [InitiativeLookupComponent],
  exports: [InitiativeLookupComponent]
})
export class InitiativeLookupModule { }
