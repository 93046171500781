import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileNameLookupComponent } from './file-name-lookup.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FormsModule
  ],
  declarations: [FileNameLookupComponent],
  exports: [FileNameLookupComponent]
})
export class FileNameLookupModule { }
