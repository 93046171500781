/* tslint:disable */
import {
  Contact,
  RequestFormReceiptTemplate
} from '../index';

declare var Object: any;
export interface PrePostTaskCaseInterface {
  "sfdcId"?: string;
  "CreatedById"?: string;
  "CurrencyIsoCode"?: string;
  "LastModifiedById"?: string;
  "OwnerId"?: string;
  "Name"?: string;
  "CreatedBy__c"?: string;
  "LastModifiedBy__c"?: string;
  "Parent_Task__c"?: string;
  "Pre_Post_Task__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  createdBy?: Contact;
  lastModifiedBy?: Contact;
  parentTask?: RequestFormReceiptTemplate;
  prePostTask?: RequestFormReceiptTemplate;
}

export class PrePostTaskCase implements PrePostTaskCaseInterface {
  "sfdcId": string;
  "CreatedById": string;
  "CurrencyIsoCode": string;
  "LastModifiedById": string;
  "OwnerId": string;
  "Name": string;
  "CreatedBy__c": string;
  "LastModifiedBy__c": string;
  "Parent_Task__c": string;
  "Pre_Post_Task__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  createdBy: Contact;
  lastModifiedBy: Contact;
  parentTask: RequestFormReceiptTemplate;
  prePostTask: RequestFormReceiptTemplate;
  constructor(data?: PrePostTaskCaseInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `PrePostTaskCase`.
   */
  public static getModelName() {
    return "PrePostTaskCase";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of PrePostTaskCase for dynamic purposes.
  **/
  public static factory(data: PrePostTaskCaseInterface): PrePostTaskCase{
    return new PrePostTaskCase(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'PrePostTaskCase',
      plural: 'PrePostTaskCases',
      path: 'PrePostTaskCases',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "CreatedBy__c": {
          name: 'CreatedBy__c',
          type: 'string'
        },
        "LastModifiedBy__c": {
          name: 'LastModifiedBy__c',
          type: 'string'
        },
        "Parent_Task__c": {
          name: 'Parent_Task__c',
          type: 'string'
        },
        "Pre_Post_Task__c": {
          name: 'Pre_Post_Task__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        createdBy: {
          name: 'createdBy',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'CreatedBy__c',
          keyTo: 'sfdcId'
        },
        lastModifiedBy: {
          name: 'lastModifiedBy',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'LastModifiedBy__c',
          keyTo: 'sfdcId'
        },
        parentTask: {
          name: 'parentTask',
          type: 'RequestFormReceiptTemplate',
          model: 'RequestFormReceiptTemplate',
          relationType: 'belongsTo',
                  keyFrom: 'Parent_Task__c',
          keyTo: 'sfdcId'
        },
        prePostTask: {
          name: 'prePostTask',
          type: 'RequestFormReceiptTemplate',
          model: 'RequestFormReceiptTemplate',
          relationType: 'belongsTo',
                  keyFrom: 'Pre_Post_Task__c',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
