import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, AfterContentInit } from '@angular/core';
import { debounceTime, distinctUntilChanged, switchMap, filter, tap, catchError } from 'rxjs/operators';
import { ProjectApi, JobApi, WorkerApi } from '../../../sdk';
import { AppStateService } from 'shared/services/app-state.service';
import { CommonService } from 'shared/services/common.service';
@Component({
    selector: 'app-program-lookup',
    templateUrl: './program-lookup.component.html',
    styleUrls: ['./program-lookup.component.scss']
})
export class ProgramLookupComponent implements OnInit, AfterContentInit {
    userType: any;
    accountWhere: any;
    viewAsInfo: any;
    viewAsPartner: boolean;
    userProjects: any;
    @Input() set resetData(isClear) {
        this.programLoading = false;
        this.programsSelected = this.isMultipleSelected ? [] : null;
        this.accountWhere = null;
    }
    @Output() getProgramObj: EventEmitter<any> = new EventEmitter;
    @Input() isMultipleSelected = true;
    @Input() selectedDefaulProgram;
    @Input() from;
    @Input() disabled = false;
    @Input() set selectedProgram(value) {
        if (value) {
            if (this.isMultipleSelected) {
                this.programsSelected = value instanceof Array ? value : [value];
            } else {
                this.programsSelected = value;
            }
        }
    }
    @Input() fromGlobalFilters = true;
    @Input() isClearable = true;
    @Input() displayName;
    @Input()
    set setProgramObj(params) {
        this.programLoading = false;
        this.programLists = [];
        this.programsSelected = this.isMultipleSelected ? [] : null;
        this.userType = this._appState.getAccessType();
        if (this.userType === 'internal') {
            const whereObj = {
                where: {
                    RecordTypeId: '0121a0000006QJJAA2',
                    Status__c: 'Active',
                    Project__c: { neq: null }
                }
            }
            if (params && params.length) {
                whereObj.where['Account__c'] = { inq: params };
                this.accountWhere = { inq: params };
            } else {
                whereObj.where['Account__c'] = null;
                this.accountWhere = null;
            }
            this.loadProgramsForAccounts(whereObj);

        } else if (this.userType === 'vendor') {
            const appData = JSON.parse(localStorage.getItem('appData'));
            if (appData && appData.user) {
                const whereObj = { where: { Vendor__c: appData.user.AccountId } };
                this.loadVendorProgramsForAccounts(whereObj);
            }
        } else if (this.userType === 'partner') {
            // getting assigned user projects for partner user;
            this._commonService.getUserAssignedProjectList().then(data => {
                this.userProjects = data;
            });
        }
    }

    @Input() boxedLayout = false;
    @Input() set setValueInEditMode(sfdcId) {
        if(sfdcId) {
            this.programsSelected = sfdcId;
            const whereObj = {
                fields: ['sfdcId', 'Name', 'Project__c', 'Account__c'],
                where: {sfdcId: this.programsSelected}
            }
            this._projectApi.find(whereObj).subscribe(res => {
                if(res && res.length) {
                    const obj = {
                        name: this.displayName ? res[0][this.displayName] : (res[0]['Name'] + ' (' + res[0]['Project__c'] + ')'),
                        id: res[0]['sfdcId'],
                        Project__c: res[0]['Project__c'],
                        Account__c: res[0]['Account__c']
                    };
                    this.programLists = [obj];
                }
            });
        }
    }

    public searchInput = new EventEmitter<string>();
    programLoading = false;
    programs: Array<any>;
    programsParents = {};
    programLists = [];
    programsSelected;
    vendorPrograms = [];
    notFoundText: String;

    constructor(
        private _cd: ChangeDetectorRef,
        private _projectApi: ProjectApi,
        private _jobApi: JobApi,
        private _appState: AppStateService,
        private _commonService: CommonService,
        private _workerApi: WorkerApi
    ) {
        // To check if view As PMS mode is activated
        this.viewAsInfo = JSON.parse(localStorage.getItem('viewAsInfo'));
        this.viewAsPartner = (this.viewAsInfo && this.viewAsInfo.viewAsType === 'Partner');
    }
    ngOnInit() {
        // to show selected program
    }

    ngAfterContentInit() {
        if (this.fromGlobalFilters) {
            const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
            this.programsSelected = (preselected && preselected['programs']) ? preselected['programs'] : null;
            this.disabled = (this._appState.getAccessType() === 'partner' || this.viewAsPartner);
        } else {
            if (this.from && this.from === 'analyticsDashboard') {
                if (this.selectedDefaulProgram && this.selectedDefaulProgram.length) {
                    this.programsSelected = this.selectedDefaulProgram;
                } else {
                    this.programsSelected = [];
                }
            }
            this.userType = this._appState.getAccessType();
        }
        this.notFoundText = 'No Programs Found';
        if (this.programsSelected && this.programsSelected.length) {
            this.userType = this._appState.getAccessType();
            if (this.userType === 'internal' || this.userType === 'partner') {
                const whereObj = {
                    where: {
                        sfdcId: { inq: this.isMultipleSelected ? this.programsSelected : [this.programsSelected] }
                    }
                }
                this.loadProgramsForAccounts(whereObj);
            } else {
                const whereObj = {
                    where: {
                        sfdcId: { inq: this.isMultipleSelected ? this.programsSelected : [this.programsSelected] }
                    }
                }
                this.loadVendorProgramsForAccounts(whereObj);
            }
        }
        this.getVendorsites();
    }

    getVendorsites() {
        this.searchInput
            .pipe(
                filter(search => search && search.trim().length > 2),
                debounceTime(200),
                distinctUntilChanged(),
                switchMap(search => this.getProgramList(search))
            ).subscribe(
                data => {
                    this.setProgramData(data);
                },
                err => {
                    console.log(err);
                    this.programLists = [];
                }

            );
    }

    // Method to set query to get program list
    getProgramList(search) {
        let whereFilter = { or : [{Name: { ilike: '%' + search.trim() + '%',"options":"i"}}, {Project__c: { ilike: '%' + search.trim() + '%',"options":"i"}}]};
        let filterObj = {
            fields: ['sfdcId', 'Name', 'Project__c', 'Account__c'],
            order: 'Name ASC'
        }
        if (this.userType === 'internal') {
            const internalFilter = {RecordTypeId: '0121a0000006QJJAA2', Status__c: 'Active', Project__c: { neq: null } };
            if (this.accountWhere) {
                internalFilter['Account__c'] = this.accountWhere;
            }
            filterObj['where'] = {and:[internalFilter, whereFilter]};
        } else if (this.userType === 'vendor') {
            if (this.vendorPrograms && this.vendorPrograms.length) {
                filterObj['where'] = {and: [{ sfdcId: { inq: this.vendorPrograms } }, whereFilter]};
            }
        } else if (this.userType === 'partner') {
            filterObj['where'] = {and: [{ sfdcId: { inq: this.userProjects } }, whereFilter]};
        }
        return this._projectApi.find(filterObj);
    }

    // Load master programs for selected Accounts
    loadProgramsForAccounts(whereObj) {
        this.programLoading = true;
        const filterObj = {
            fields: ['sfdcId', 'Name', 'Project__c', 'Account__c'],
            order: 'Name ASC'
        }
        if (whereObj) {
            filterObj['where'] = whereObj['where'];
        }
        if (this.viewAsPartner && this.viewAsInfo.program) {
            filterObj['where']['sfdcId'] = { inq: [this.viewAsInfo.program.sfdcId] };
        }
        // clears previous program selection
        // this.programsSelected = (this.programsSelected && this.programsSelected.length) ? this.programsSelected : [];

        this._projectApi.find(filterObj).subscribe(
            data => {
                this.setProgramData(data);
                this.programLoading = false;

            },
            err => {
                console.log(err);
                this.programLists = [];
                this.programLoading = false;
            }
        );
    }

    /**
     *
     * @param accountId | load vendor account baised on their assigned AccountId
     */
    async loadVendorProgramsForAccounts(whereObj) {
        whereObj['where']['CreatedDate'] = this._commonService.CreateDateRangeBySubtract(179, 'days');
        const userInfo = this._appState.getAppState().value['user'];
        if (userInfo['userType'] && userInfo['userType']['slug'] !== 'admin') {
            whereObj['where']['Vendorsite__c'] = {inq: await this.getWorkerVendorSite(userInfo)};
        }
        this.programLoading = true;
        this._jobApi.find({
            fields: ['sfdcId', 'Project_SOP__c'],
            where: whereObj['where']
        }).subscribe(
            result => {
                const programIds = [];
                result.forEach(job => {
                    if (job['Project_SOP__c'] && programIds.indexOf(job['Project_SOP__c']) === -1) {
                        programIds.push(job['Project_SOP__c']);
                    }
                });
                this.vendorPrograms = programIds;
                if (programIds.length) {
                    this._projectApi.find({
                        fields: ['sfdcId', 'Name', 'Project__c', 'Account__c'],
                        where: { sfdcId: { inq: programIds } },
                        order: 'Name ASC',
                    }).subscribe(
                        res => {
                            this.setProgramData(res);
                            this.programLoading = false;
                        },
                        err => {
                            console.log(err);
                            this.programLists = [];
                            this.programLoading = false;
                        }
                    );
                } else {
                    this.programLoading = false;
                }

            },
            err => {
                console.log(err);
                this.programLists = [];
                this.programLoading = false;
            }
        );
    }

    getWorkerVendorSite(user): any {
        const vendorsites = [];
        const findObj = {
            fields: ['id', 'sfdcId', 'Contact__c', 'RecordTypeId', 'Vendorsite__c', 'Worker_Type__c'],
            include: [{
                relation: 'workerVendorsite'
            }],
            where: {
                Contact__c: user['sfdcId'],
            },
        }
        return new Promise((resolve, reject) => {
            this._workerApi.find(findObj).subscribe(res => {
                const worker = res[0];
                vendorsites.push(worker['Vendorsite__c']);
                if (worker['workerVendorsite'] && worker['workerVendorsite'].length) {
                    worker['workerVendorsite'].forEach(x => {
                        vendorsites.push(x.Vendorsite__c);
                    });
                }
                resolve(vendorsites);
            }, err => {
                reject();
             })
        })
    }

    setProgramData(data) {
        const filteredPrograms = [];
        data.forEach(p => {
            const obj = {
                name: this.displayName ? p[this.displayName] : (p['Name'] + ' (' + p['Project__c'] + ')'),
                id: p['sfdcId'],
                Project__c: p['Project__c'],
                Account__c: p['Account__c']
            };
            filteredPrograms.push(obj);
        });
        this.programLists = filteredPrograms;
        if (!this.isMultipleSelected && this.programsSelected && this.programsSelected instanceof Array
            && this.programsSelected.length) {
            this.programsSelected = this.programsSelected[0];
        }
        this.notFoundText = (this.programLists.length === 0) ? 'No Programs found' : '';
    }

    onProgramChange() {
        this.getProgramObj.emit(this.programsSelected);
    }

    clearProgram() {
        this.programsSelected = this.isMultipleSelected ? [] : null;
        this.getProgramObj.emit([]);
    }
}
