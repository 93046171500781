import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkOrderCaseIdLookupComponent } from './work-order-case-id-lookup.component';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule
  ],
  declarations: [WorkOrderCaseIdLookupComponent],
  exports: [WorkOrderCaseIdLookupComponent]
})
export class WorkOrderCaseIdLookupModule { }
