/* tslint:disable */
import {
  Department,
  Group,
  Contact
} from '../index';

declare var Object: any;
export interface TeamShiftInterface {
  "Name"?: string;
  "sfdcId"?: string;
  "OwnerId"?: string;
  "LastModifiedById"?: string;
  "CurrencyIsoCode"?: string;
  "CreatedById"?: string;
  "Community__c"?: string;
  "CSQD_Team_Configurator_Relation__c"?: string;
  "Description__c"?: string;
  "IsDefault__c"?: boolean;
  "PgMO_Departments__c"?: string;
  "PgMO_Groups__c"?: string;
  "Shift_Code__c"?: string;
  "Shift_Code_Auto__c"?: string;
  "Team_Shift_Manager__c"?: string;
  "Team_Shift_Name__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  department?: Department;
  group?: Group;
  contact?: Contact;
}

export class TeamShift implements TeamShiftInterface {
  "Name": string;
  "sfdcId": string;
  "OwnerId": string;
  "LastModifiedById": string;
  "CurrencyIsoCode": string;
  "CreatedById": string;
  "Community__c": string;
  "CSQD_Team_Configurator_Relation__c": string;
  "Description__c": string;
  "IsDefault__c": boolean;
  "PgMO_Departments__c": string;
  "PgMO_Groups__c": string;
  "Shift_Code__c": string;
  "Shift_Code_Auto__c": string;
  "Team_Shift_Manager__c": string;
  "Team_Shift_Name__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  department: Department;
  group: Group;
  contact: Contact;
  constructor(data?: TeamShiftInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `TeamShift`.
   */
  public static getModelName() {
    return "TeamShift";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of TeamShift for dynamic purposes.
  **/
  public static factory(data: TeamShiftInterface): TeamShift{
    return new TeamShift(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'TeamShift',
      plural: 'TeamShifts',
      path: 'TeamShifts',
      idName: 'id',
      properties: {
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "Community__c": {
          name: 'Community__c',
          type: 'string'
        },
        "CSQD_Team_Configurator_Relation__c": {
          name: 'CSQD_Team_Configurator_Relation__c',
          type: 'string'
        },
        "Description__c": {
          name: 'Description__c',
          type: 'string'
        },
        "IsDefault__c": {
          name: 'IsDefault__c',
          type: 'boolean'
        },
        "PgMO_Departments__c": {
          name: 'PgMO_Departments__c',
          type: 'string'
        },
        "PgMO_Groups__c": {
          name: 'PgMO_Groups__c',
          type: 'string'
        },
        "Shift_Code__c": {
          name: 'Shift_Code__c',
          type: 'string'
        },
        "Shift_Code_Auto__c": {
          name: 'Shift_Code_Auto__c',
          type: 'string'
        },
        "Team_Shift_Manager__c": {
          name: 'Team_Shift_Manager__c',
          type: 'string'
        },
        "Team_Shift_Name__c": {
          name: 'Team_Shift_Name__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        department: {
          name: 'department',
          type: 'Department',
          model: 'Department',
          relationType: 'belongsTo',
                  keyFrom: 'PgMO_Departments__c',
          keyTo: 'sfdcId'
        },
        group: {
          name: 'group',
          type: 'Group',
          model: 'Group',
          relationType: 'belongsTo',
                  keyFrom: 'PgMO_Groups__c',
          keyTo: 'sfdcId'
        },
        contact: {
          name: 'contact',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Team_Shift_Manager__c',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
