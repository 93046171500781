/* tslint:disable */
import {
  WorkOrder
} from '../index';

declare var Object: any;
export interface RequestorDepartmentInterface {
  "sfdcId"?: string;
  "IsDeleted"?: boolean;
  "OwnerId"?: string;
  "Name"?: string;
  "CreatedById"?: string;
  "CreatedDate"?: Date;
  "CurrencyIsoCode"?: string;
  "LastModifiedById"?: string;
  "LastModifiedDate"?: Date;
  "SystemModstamp"?: Date;
  "Category_Department__c"?: string;
  "Form_Name__c"?: string;
  "PMS_Case__c"?: string;
  "PartnerAccount__c"?: string;
  "Request_Form_Receipt__c"?: string;
  "Requestor_Manager__c"?: string;
  "Requestor__c"?: string;
  "Sub_Category_Group__c"?: string;
  "Work_Order_Item__c"?: string;
  "Work_Order__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  WorkOrder?: WorkOrder;
}

export class RequestorDepartment implements RequestorDepartmentInterface {
  "sfdcId": string;
  "IsDeleted": boolean;
  "OwnerId": string;
  "Name": string;
  "CreatedById": string;
  "CreatedDate": Date;
  "CurrencyIsoCode": string;
  "LastModifiedById": string;
  "LastModifiedDate": Date;
  "SystemModstamp": Date;
  "Category_Department__c": string;
  "Form_Name__c": string;
  "PMS_Case__c": string;
  "PartnerAccount__c": string;
  "Request_Form_Receipt__c": string;
  "Requestor_Manager__c": string;
  "Requestor__c": string;
  "Sub_Category_Group__c": string;
  "Work_Order_Item__c": string;
  "Work_Order__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  WorkOrder: WorkOrder;
  constructor(data?: RequestorDepartmentInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `RequestorDepartment`.
   */
  public static getModelName() {
    return "RequestorDepartment";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of RequestorDepartment for dynamic purposes.
  **/
  public static factory(data: RequestorDepartmentInterface): RequestorDepartment{
    return new RequestorDepartment(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'RequestorDepartment',
      plural: 'RequestorDepartments',
      path: 'RequestorDepartments',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "SystemModstamp": {
          name: 'SystemModstamp',
          type: 'Date'
        },
        "Category_Department__c": {
          name: 'Category_Department__c',
          type: 'string'
        },
        "Form_Name__c": {
          name: 'Form_Name__c',
          type: 'string'
        },
        "PMS_Case__c": {
          name: 'PMS_Case__c',
          type: 'string'
        },
        "PartnerAccount__c": {
          name: 'PartnerAccount__c',
          type: 'string'
        },
        "Request_Form_Receipt__c": {
          name: 'Request_Form_Receipt__c',
          type: 'string'
        },
        "Requestor_Manager__c": {
          name: 'Requestor_Manager__c',
          type: 'string'
        },
        "Requestor__c": {
          name: 'Requestor__c',
          type: 'string'
        },
        "Sub_Category_Group__c": {
          name: 'Sub_Category_Group__c',
          type: 'string'
        },
        "Work_Order_Item__c": {
          name: 'Work_Order_Item__c',
          type: 'string'
        },
        "Work_Order__c": {
          name: 'Work_Order__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        WorkOrder: {
          name: 'WorkOrder',
          type: 'WorkOrder',
          model: 'WorkOrder',
          relationType: 'belongsTo',
                  keyFrom: 'Work_Order__c',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
