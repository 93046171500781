import { Directive, Input, AfterContentChecked, AfterViewInit, ChangeDetectorRef, ElementRef, HostListener, NgZone } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';

@Directive({ selector: '[ngx-double-scroll]' })
export class NgxDoubleScrollDirective implements AfterViewInit {
    private _tableLength: number;
    @Input('ngx-double-scroll')
    set tableLength(tableLength: number) {
        this._tableLength = tableLength;
        this.detectChanges();
    };
    private latestWidth: number;
    constructor(private table: DatatableComponent, public ref: ChangeDetectorRef, private ngZone: NgZone,
        private el: ElementRef) {

    }

    ngAfterViewInit() {
        const elHeader = this.el.nativeElement.querySelector('.datatable-header')
        const elBody = this.el.nativeElement.querySelector('datatable-body');
        elHeader.onscroll = () => {
            elBody.scrollLeft = elHeader.scrollLeft
        }
        elBody.onscroll = () => {
            elHeader.scrollLeft = elBody.scrollLeft
        }
    }

    detectChanges() {
        const elHeader = this.el.nativeElement.querySelector('.datatable-header')
        if (elHeader && elHeader.scrollLeft) {
            elHeader.scrollLeft = 0;
        }
    }
}
