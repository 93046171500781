
import { HttpHeaders, HttpRequest, HttpClient } from '@angular/common/http';
// import { Http, RequestOptions, Headers } from '@angular/http';
import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { user, wizardstep } from '../../models/configureinfo.class';
import { environment } from 'environments/environment';

import { Angular2Csv } from '../../libs/angular2-csv';
import { DataFormatter } from './../../libs/dataFormatter';
import { ExportToExcelService } from '../export-to-excel.service';
import { FavoriteApi } from 'shared/sdk'
import { rejectReason } from 'shared/models/static-list-data.service';
import axios from 'axios';
import * as querystring from 'querystring';
import { Conversation } from '../../../msp/shared/my-work-place/personal-conversation/personal-conversation-adapters/conversation';

@Injectable()
export class SharedService {
    private _requestOptions: any;
    wizardsteparr: wizardstep[];
    userState: BehaviorSubject<any> = new BehaviorSubject<any>({});
    vendorRouting: BehaviorSubject<any> = new BehaviorSubject<any>({});
    dispatchProfile: BehaviorSubject<any> = new BehaviorSubject<any>({});
    selectedProfile: BehaviorSubject<any> = new BehaviorSubject<any>({});
    quoteLineObjs: BehaviorSubject<any> = new BehaviorSubject<any>({});
    filteredQuoteLineObjs: BehaviorSubject<any> = new BehaviorSubject<any>({});
    serviceOption: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    serviceProvider: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    ironsProvider: BehaviorSubject<any> = new BehaviorSubject<any>({});
    allProviders: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    private activewizard = new Subject<number>();
    activewizard$ = this.activewizard.asObservable();
    isLoadProgram: BehaviorSubject<any> = new BehaviorSubject<boolean>(false);
    jobEscalationSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});
    favoriteDirectoryItems: BehaviorSubject<any> = new BehaviorSubject<any>([])
    favoriteGroupItems: BehaviorSubject<any> = new BehaviorSubject<any>([])
    allGroupItems: BehaviorSubject<any> = new BehaviorSubject<any>([])
    allDirectoryItems: BehaviorSubject<any> = new BehaviorSubject<any>([])
    favoriteChannelItems: BehaviorSubject<any> = new BehaviorSubject<any>([])
    allChannelItems: BehaviorSubject<any> = new BehaviorSubject<any>([])
    channelRefresh: BehaviorSubject<any> = new BehaviorSubject<boolean>(false);
    conversationRefresh: BehaviorSubject<any> = new BehaviorSubject<any>({});

    constructor(private http: HttpClient, private _dataFormatter: DataFormatter,
        private _exportToExcelService: ExportToExcelService, private _favoriteApi: FavoriteApi) {
        this.wizardsteparr = [
            { name: 'Our Services', title: 'CSQD Services', link: '/pms/configure' },
            {
                name: 'Program Setup',
                title: 'CSQD program',
                link: '/pms/configure/program'
            },
            {
                name: 'Pricing Quote',
                title: 'CSQD Pricing',
                link: '/pms/configure/pricing'
            },
            {
                name: 'Process & Deliverables',
                title: 'CSQD Instruction',
                link: '/pms/configure/instruction'
            },
            {
                name: 'Schedule & Order',
                title: 'CSQD Schedule',
                link: '/pms/configure/schedule'
            },
            {
                name: 'Confirm Order',
                title: 'CSQD Order',
                link: '/pms/configure/confirm'
            },
            {
                name: 'Manage Order',
                title: 'CSQD Manage Order',
                link: ''
            },
            {
                name: 'Performance Rating',
                title: 'CSQD Performance',
                link: ''
            },
            {
                name: 'Billing',
                title: 'CSQD Order Billing',
                link: ''
            },
            {
                name: 'Closed',
                title: 'CSQD Order Closed',
                link: ''
            }
        ];


    }

    getUserState(): Observable<any> {
        return this.userState.asObservable();
    }
    setUserState(userState: any) {
        this.userState.next(userState);
    }

    getVendorRoutingState(): Observable<any> {
        return this.vendorRouting.asObservable();
    }
    setvendorRoutingState(vendorRouting: any) {
        this.vendorRouting.next(vendorRouting);
    }

    getQuoteLineObj(): Observable<any> {
        return this.quoteLineObjs.asObservable();
    }
    setQuoteLineObj(quoteLineObj: any) {
        this.quoteLineObjs.next(quoteLineObj);
    }

    getFilteredQuoteLineObj(): Observable<any> {
        return this.filteredQuoteLineObjs.asObservable();
    }
    setFilteredQuoteLineObj(quoteLineObj: any) {
        this.filteredQuoteLineObjs.next(quoteLineObj);
    }

    getDispatchProfile(): Observable<any> {
        return this.dispatchProfile.asObservable();
    }
    setDispatchProfile(dispatchProfileData: any) {
        this.dispatchProfile.next(dispatchProfileData);
    }

    getSelectedProfile(): Observable<any> {
        return this.selectedProfile.asObservable();
    }
    setSelectedProfile(selectedProfileData: any) {
        this.selectedProfile.next(selectedProfileData);
    }
    setServiceProvider(serviceProvider: any[]) {
        this.serviceProvider.next(serviceProvider);
    }
    getIronsProvider(): Observable<any> {
        return this.ironsProvider.asObservable();
    }

    setIronsProvider(ironsProvider: any) {
        this.ironsProvider.next(ironsProvider);
    }

    setAllProviders(allProvider: any, reset: boolean) {
        if (reset) {
            this.allProviders.next(allProvider);
        } else {
            const allProviders = this.allProviders.value.concat(allProvider);
            this.allProviders.next(allProviders);
        }
    }

    getAllProviders(): Observable<any> {
        return this.allProviders.asObservable();
    }

    getServiceProvider() {
        return this.serviceProvider.value;
    }

    /*Active wizard Services start here*/
    pushactivewizard(type: number) {
        this.activewizard.next(type);
    }
    /*End here*/
    exportData(data, fileName) {
        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalseparator: '.',
            showLabels: true,
            showTitle: false,
            useBom: false,
            headers: Object.keys(data[0])
        };
        return new Angular2Csv(data, fileName, options);
    }

    exportNgxData(data, columns, filename = 'CSV-File') {
        const data2download = [];
        data.forEach(obj => {
            const resObj = {};
            columns.forEach(element => {
                // Handle case for escaping ACTION column from tables
                if (element.name && element.name.toLowerCase() !== 'action') {
                    // Handle case for exporting only visible columns
                    if (element.visible) {
                        let property = obj[element.prop];
                        // if (element.type === 'date') {
                        //     property = this._dataFormatter.transform(property, element.format);
                        // } else

                        if (element.type === 'percentage') {
                            property = this._dataFormatter.percentageFormate(property, element.format);
                        } else if (element.type === 'currency') {
                            property = this._dataFormatter.currencyFormate(property, element.format);
                        } else if (element.type === 'decimal') {
                            property = this._dataFormatter.decimalFormate(property, element.format);
                        } else if (element.type === 'NA') {
                            property = property ? property : 'x';
                        }
                        resObj[element.name] = (property == null || property === undefined) ? '' : property;
                    }
                }
            });
            data2download.push(resObj);
        });

        if (data2download.length > 0) {
            const options = {
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalseparator: '.',
                showLabels: true,
                showTitle: false,
                useBom: true,
                headers: Object.keys(data2download[0])
            };
            this._exportToExcelService.exportLog(filename, data2download.length);
            return new Angular2Csv(data2download, this.createFileName(filename), options);
        }

    }

    createFileName(exportFileName: string): string {
        const date = new Date();
        return (exportFileName + date.toLocaleDateString() + '_' + date.toLocaleTimeString());
    }

    paramspushactivewizard(params: any) {
        const activeparams = params.toLowerCase();
        let activeId: any;
        switch (activeparams) {
            case 'invited':
                activeId = 4;
                break;
            case 'declined':
                activeId = 5;
                break;
            case 'accepted':
                activeId = 6;
                break;
            case 'assigned':
                activeId = 7;
                break;
            case 'started':
                activeId = 8;
                break;
            case 'pending-approval':
                activeId = 9;
                break;
            case 'invoiced':
                activeId = 10;
                break;
            case 'paid':
                activeId = 11;
                break;
            case 'closed':
                activeId = 12;
                break;
            case 'cancelled':
                activeId = 13;
                break;
        }

        if (activeId) {
            this.pushactivewizard(activeId);
        }
    }

    // Method retrieve distance by using google map
    getDistance(source, destination): Observable<any> {
        const postUrl = 'https://maps.googleapis.com/maps/api/distancematrix/json?origins=' +
            source + '&destinations=' + destination + '&key=' + environment.googleMapKey;
        const headers = new Headers();
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Content-Type', 'application/json; charset=utf-8');
        headers.append('Authorization', environment.googleMapKey);
        this._requestOptions = new HttpRequest('OPTIONS', postUrl, { headers: headers });
        return this.http.get(postUrl, this._requestOptions)
    }

    getIsProgramLoad(): Observable<boolean> {
        return this.isLoadProgram.asObservable();
    }
    setIsProgramLoad(accountLoad: boolean) {
        this.isLoadProgram.next(accountLoad);
    }

/**
* Prepair worker img url
* @param payload In this `workerPickUrl` | `workerPic`
*/
  getProfileImageUrl(worker) {
    let imgpath = environment.baseUrl + '/' + environment.apiVersion;
    if (imgpath.indexOf('localhost') !== -1) {
      imgpath = 'https://den.serviceo.me/api/v1';
    } else {
        imgpath = 'https://den.serviceo.me/api/v1/';
    }
    if (worker.userImage) {
      return `${imgpath}/Containers${worker.userImage}`;
    } else if ((worker.gender && worker.gender === 'Male') || (worker.Gender__c && worker.Gender__c === 'Male')) {
      return `assets/images/male-circle.png`;
    } else if ((worker.gender && worker.gender === 'Female') || (worker.Gender__c && worker.Gender__c === 'Female')) {
      return `assets/images/female-circle.png`;
    } else {
      return `assets/images/computer.png`;
    }
  }
    /**
 * Convert time formate in hrs mins
 * @param info time
 */
  convertToHHMM(secs) {
    // tslint:disable-next-line:radix
    const sec_num = parseInt(secs, 10);
    const hours = Math.floor(sec_num / 3600);
    const minutes = Math.floor(sec_num / 60) % 60;
    let time = hours ? hours + ' h ' : '';
    time += minutes ? minutes + (minutes > 1 ? ' mins' : 'min') : '';
    return time;
  }

  /**
   * convert in to miles
   * @param distance number
   * @returns distance miles
   */
  convertToMiles(distance) {
  const miles = distance ? (distance / 1609.344).toFixed(1) : 0;
  return miles;
  }

  /**
   * Get worker distance form jobsite
   */
  getDistanceTimeSummary(from, to) {
    const _that = this;
    const osmMapKey = JSON.parse(localStorage.getItem('osmMapObjKey')).webApiKey;
    const url = 'https://api.mapbox.com/directions/v5/mapbox/driving-traffic?access_token=' + osmMapKey;
    const lngLat = from.lng + ',' + from.lat + ';' + to.lng + ',' + to.lat;
    return new Promise((resolve, reject) => {
      axios.post(url, querystring.stringify({
        coordinates: lngLat
      }), {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      })
        .then(function (response) {
          const distance = _that.convertToMiles(response.data.routes[0].distance) // distance in miles
          const time = _that.convertToHHMM(response.data.routes[0].duration); // converting in hr and min format
          resolve({ distance: distance, time: time,  routes: response.data.routes[0] })
        }).catch(function (error) {
          console.log('distance api max limit exceeded error', error.response);
          if (error && error.response && error.response.status === 422) {
            reject({ code: error.response.status, data: error.response.data })
          }
        })
    })
  }

    // Method retrieve distance by using google map
    getResumePower(): Observable<any> {
        const postUrl = '/resumePower';
        const headers = new Headers();
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Content-Type', 'application/json; charset=utf-8');
        this._requestOptions = new HttpRequest('OPTIONS', postUrl, { headers: headers });
        return this.http.get(postUrl, this._requestOptions)
    }


    addFavorite(modelId, modelName) {
        return new Promise((resolve, reject) => {
            let obj = { 
                modelName : modelName,
                modelId : modelId
            };
            this._favoriteApi.upsert(obj).subscribe(
                result => {
                    if (result && result['id']) {
                        resolve(result);
                    }
                }, err => {
                    reject(err)
                }
            );
        })
    }

    deleteFavorite(favoriteId) {
        return new Promise((resolve, reject) => {
            this._favoriteApi.deleteById(favoriteId).subscribe(
                result => {
                    if (result && result['count']) {
                        resolve(result);
                    }
                }, err => {
                    reject(err)
                }
            );
        })

    }

    getFavoriteDirectoryItems(): Observable<any> {
        return this.favoriteDirectoryItems.asObservable();
    }
    setFavoriteDirectoryItems(data: any[]) {
        this.favoriteDirectoryItems.next(data);
    }

    getFavoriteGroupItems(): Observable<any> {
        return this.favoriteGroupItems.asObservable();
    }
    setFavoriteGroupItems(data: any) {
        this.favoriteGroupItems.next(data);
    }

    getAllGroups() : Observable<any> {
        return this.allGroupItems.asObservable();
    }

    setAllGroups(data: any) {
        this.allGroupItems.next(data);
    }

    setAllDirectoryItems(data: any) {
        this.allDirectoryItems.next(data);
    }

    getAllDirectoryItems(): Observable<any> {
        return this.allDirectoryItems.asObservable();
    }

    setFavoriteChannelItems(data: any) {
        this.favoriteChannelItems.next(data);
    }

    getFavoriteChannelItems(): Observable<any> {
        return this.favoriteChannelItems.asObservable();
    }

    /**
     * refresh right channels favorites
     * @returns True
    */
    getChannelRefresh(): Observable<any> {
        return this.channelRefresh.asObservable();
    }
    /**
     * set refresh channels
     * @param data True
     */
    setChannelRefresh(data: any) {
        this.channelRefresh.next(data);
    }

    /**
     * refresh conversation details
     * @returns True
    */
    getConversationRefresh(): Observable<any> {
        return this.conversationRefresh.asObservable();
    }
    /**
     * set refresh conversation details
     * @param data True
     */
    setConversationRefresh(data: any) {
        this.conversationRefresh.next(data);
    }
}
