import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ironApprovalStatusValues } from '../../../models/static-list-data.service'

@Component({
  selector: 'app-iron-approval-status-lookup',
  templateUrl: './iron-approval-status-lookup.component.html',
  styleUrls: ['./iron-approval-status-lookup.component.css']
})
export class IronApprovalStatusLookupComponent implements OnInit {

  ironApprovalStatusList = [];
  ironApprovalStatusTypeSelected = [];
  isMultipleSelected = true;

  @Input() set resetData(isClear) {
    if (isClear) {
      this.ironApprovalStatusTypeSelected = [];
    }
  }
  @Output() selectedIronApprovalStatus: EventEmitter<any> = new EventEmitter;

  constructor() { }

  ngOnInit() {
    this.ironApprovalStatusList = ironApprovalStatusValues;
    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
    this.ironApprovalStatusTypeSelected = (preselected && preselected['ironApprovalStatuses']) ? preselected['ironApprovalStatuses'] : '';
  }
  onIronApprovalStatusChange() {
    this.selectedIronApprovalStatus.emit(this.ironApprovalStatusTypeSelected);
  }
  clearIronApprovalStatus() {
    this.ironApprovalStatusTypeSelected = [];
    this.selectedIronApprovalStatus.emit([]);
  }

}
