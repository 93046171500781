import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { InformationToolTipModule } from 'shared/components/search-applicant/common/information-tool-tip/information-tool-tip.module';
import { SendSMSModule } from 'shared/components/send-sms/send-sms.module';
import { ScheduledSmsListComponent } from './scheduled-sms-list.component';
import { JobsRoutingModule } from '../jobs.routing';
import { RouterModule } from '@angular/router';
import { TextNotificationManagerViewPopupModule } from 'shared/components/text-notification-view-popup/text-notification-popup.module';
@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        NgxDatatableModule,
        InformationToolTipModule,
        SendSMSModule,
        TextNotificationManagerViewPopupModule
    ],
    declarations: [
        ScheduledSmsListComponent
    ],
    exports: [
        ScheduledSmsListComponent
    ],
})

export class ScheduledSmsListModule {

}
