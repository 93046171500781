/* tslint:disable */
import {
  RequestFormReceipt,
  Job,
  Task
} from '../index';

declare var Object: any;
export interface RequestFormReceiptLineInterface {
  "sfdcId"?: string;
  "Defective_Item_New_SN__c"?: string;
  "Defective_Item_Original_SN__c"?: string;
  "FRU_Group_Name__c"?: string;
  "FRU_SKU__c"?: string;
  "Name"?: string;
  "New_MAC_Number__c"?: string;
  "Quantity__c"?: number;
  "Request_Form_Receipt__c"?: string;
  "RecordTypeId"?: string;
  "CreatedById"?: string;
  "CreatedDate"?: Date;
  "CurrencyIsoCode"?: string;
  "IsDeleted"?: boolean;
  "LastModifiedById"?: string;
  "LastModifiedDate"?: Date;
  "Job__c"?: string;
  "VMS_Task_Type__c"?: string;
  "Task_Summary__c"?: string;
  "Task_Description__c"?: string;
  "Request_Date__C"?: Date;
  "Customer_Appointment_Date_Time_Schedule__c"?: Date;
  "Requested_By__c"?: string;
  "Problem_Priority__c"?: string;
  "Problem_Date__c"?: Date;
  "Problem_Suggestion__c"?: string;
  "Escalation_Task__c"?: string;
  "ICC_Case__c"?: string;
  "Form_FSE_Problem_Escalation_Task__c"?: boolean;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  requestReceipt?: RequestFormReceipt;
  job?: Job;
  task?: Task;
}

export class RequestFormReceiptLine implements RequestFormReceiptLineInterface {
  "sfdcId": string;
  "Defective_Item_New_SN__c": string;
  "Defective_Item_Original_SN__c": string;
  "FRU_Group_Name__c": string;
  "FRU_SKU__c": string;
  "Name": string;
  "New_MAC_Number__c": string;
  "Quantity__c": number;
  "Request_Form_Receipt__c": string;
  "RecordTypeId": string;
  "CreatedById": string;
  "CreatedDate": Date;
  "CurrencyIsoCode": string;
  "IsDeleted": boolean;
  "LastModifiedById": string;
  "LastModifiedDate": Date;
  "Job__c": string;
  "VMS_Task_Type__c": string;
  "Task_Summary__c": string;
  "Task_Description__c": string;
  "Request_Date__C": Date;
  "Customer_Appointment_Date_Time_Schedule__c": Date;
  "Requested_By__c": string;
  "Problem_Priority__c": string;
  "Problem_Date__c": Date;
  "Problem_Suggestion__c": string;
  "Escalation_Task__c": string;
  "ICC_Case__c": string;
  "Form_FSE_Problem_Escalation_Task__c": boolean;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  requestReceipt: RequestFormReceipt;
  job: Job;
  task: Task;
  constructor(data?: RequestFormReceiptLineInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `RequestFormReceiptLine`.
   */
  public static getModelName() {
    return "RequestFormReceiptLine";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of RequestFormReceiptLine for dynamic purposes.
  **/
  public static factory(data: RequestFormReceiptLineInterface): RequestFormReceiptLine{
    return new RequestFormReceiptLine(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'RequestFormReceiptLine',
      plural: 'RequestFormReceiptLines',
      path: 'RequestFormReceiptLines',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "Defective_Item_New_SN__c": {
          name: 'Defective_Item_New_SN__c',
          type: 'string'
        },
        "Defective_Item_Original_SN__c": {
          name: 'Defective_Item_Original_SN__c',
          type: 'string'
        },
        "FRU_Group_Name__c": {
          name: 'FRU_Group_Name__c',
          type: 'string'
        },
        "FRU_SKU__c": {
          name: 'FRU_SKU__c',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "New_MAC_Number__c": {
          name: 'New_MAC_Number__c',
          type: 'string'
        },
        "Quantity__c": {
          name: 'Quantity__c',
          type: 'number'
        },
        "Request_Form_Receipt__c": {
          name: 'Request_Form_Receipt__c',
          type: 'string'
        },
        "RecordTypeId": {
          name: 'RecordTypeId',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "Job__c": {
          name: 'Job__c',
          type: 'string'
        },
        "VMS_Task_Type__c": {
          name: 'VMS_Task_Type__c',
          type: 'string'
        },
        "Task_Summary__c": {
          name: 'Task_Summary__c',
          type: 'string'
        },
        "Task_Description__c": {
          name: 'Task_Description__c',
          type: 'string'
        },
        "Request_Date__C": {
          name: 'Request_Date__C',
          type: 'Date'
        },
        "Customer_Appointment_Date_Time_Schedule__c": {
          name: 'Customer_Appointment_Date_Time_Schedule__c',
          type: 'Date'
        },
        "Requested_By__c": {
          name: 'Requested_By__c',
          type: 'string'
        },
        "Problem_Priority__c": {
          name: 'Problem_Priority__c',
          type: 'string'
        },
        "Problem_Date__c": {
          name: 'Problem_Date__c',
          type: 'Date'
        },
        "Problem_Suggestion__c": {
          name: 'Problem_Suggestion__c',
          type: 'string'
        },
        "Escalation_Task__c": {
          name: 'Escalation_Task__c',
          type: 'string'
        },
        "ICC_Case__c": {
          name: 'ICC_Case__c',
          type: 'string'
        },
        "Form_FSE_Problem_Escalation_Task__c": {
          name: 'Form_FSE_Problem_Escalation_Task__c',
          type: 'boolean',
          default: false
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        requestReceipt: {
          name: 'requestReceipt',
          type: 'RequestFormReceipt',
          model: 'RequestFormReceipt',
          relationType: 'belongsTo',
                  keyFrom: 'Request_Form_Receipt__c',
          keyTo: 'sfdcId'
        },
        job: {
          name: 'job',
          type: 'Job',
          model: 'Job',
          relationType: 'belongsTo',
                  keyFrom: 'Job__c',
          keyTo: 'sfdcId'
        },
        task: {
          name: 'task',
          type: 'Task',
          model: 'Task',
          relationType: 'belongsTo',
                  keyFrom: 'Job__c',
          keyTo: 'Job_Number__c'
        },
      }
    }
  }
}
