import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { UsersListLookupComponent } from './users-list-lookup.component';
import { AddFieldsModule } from './../../../components/add-fields/add-fields.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgSelectModule,
        AddFieldsModule
    ],
    declarations: [UsersListLookupComponent],
    exports: [UsersListLookupComponent],
})
export class UsersListLookupModule { }
