import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-job-message-email-popup',
  templateUrl: './job-message-email-popup.component.html',
  styleUrls: ['./job-message-email-popup.component.css']
})
export class JobMessageEmailPopupComponent implements OnInit {

  emails = [];
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,3}$";
  emailInputText = new FormGroup({
    email: new FormControl('', [
      Validators.pattern(this.emailPattern)
    ])
  });

  constructor(public dialogRef: MatDialogRef<JobMessageEmailPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

  closePopup(withData: boolean): void {
    if (withData) {
      if (this.emailInputText.get('email').value.trim() &&
        this.emailInputText.get('email').value.trim() !== '' &&
        !this.emailInputText.get('email').errors) {
        this.emails.push(this.emailInputText.get('email').value.trim());
      }
      if (this.emails.length <= 0) {
        return;
      }
      this.dialogRef.close(this.emails);
    } else {
      this.dialogRef.close();
    }
  }

  add(event: any): void {
    if (event && event.code && (event.code === 'Comma' || event.code === 'Enter')) {
      if (event.code === 'Comma') {
        const emailRegExp = new RegExp(this.emailPattern);
        if (emailRegExp.test(this.emailInputText.get('email').value.trim().toString().slice(0, -1))) {
          this.emailInputText.controls.email.setValue(this.emailInputText.get('email').value.trim().toString().slice(0, -1));
        }
      }
      if (this.emailInputText.get('email').errors && this.emailInputText.get('email').errors.pattern) {
        return;
      }
      const value = this.emailInputText.get('email').value.trim();
      this.emailInputText.controls.email.setValue('');
      if (value !== '') {
        this.emails.push(value);
      }
    }
  }

  remove(emailIndex: any): void {
    if (emailIndex >= 0) {
      this.emails.splice(emailIndex, 1);
    }
  }

}
