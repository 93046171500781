import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkerBillingTypeLookupComponent } from './worker-billing-type-lookup.component';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule
  ],
  declarations: [WorkerBillingTypeLookupComponent],
  exports: [WorkerBillingTypeLookupComponent]
})
export class WorkerBillingTypeLookupModule { }
