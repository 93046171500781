import { ActivatedRoute } from '@angular/router';
import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { MessageTypes } from '../../../models/static-list-data.service';
import { MessageService } from '../../../services/message.service';
import { AppStateService } from '../../../services/app-state.service';
import { PreloaderService } from '../../../services/preloader.service';
import { ModalService } from './../../../services/modal.service';
import { CommonService } from './../../../services/common.service';
import { CommentApi } from '../../../sdk/services/custom/Comment';
import { JobOrderItemApi } from '../../../sdk/services/custom/JobOrderItem';
import { environment } from '../../../../environments/environment';
import Quill from 'quill';
import { ImageDrop } from 'quill-image-drop-module';
import { CaseApi } from 'shared/sdk';
import { caseStatusValuesFor3PS,caseStatusValuesForTriage,resolutionCodeValues } from 'shared/models/static-list-data.service';
import { AlertService } from '../../../../shared/services/alert.service';
Quill.register('modules/imageDrop', ImageDrop);

@Component({
    selector: 'app-message-post',
    templateUrl: './message-post.component.html',
    styleUrls: ['./message-post.component.css']
})

export class MessagePostComponent implements OnInit, OnDestroy {
    // Name of the model to which message will be associated
    @Input() modelName: string;
    @Input() workOrderId: string;
    @Input()
    set modelId(e) {
        this._modelId = e;
        this.setFormData();
    }
    @Input() set repostMsg(desc) {
        // this.contentText = '';
        if (desc) {
            this.contentText = desc;
            this.textChange(this.contentText, 'textarea');
        }
    }
    @Input()
    set selectedJob(e) {
        this.selectedItem = e;
        this.jobCase__C = (e && e.Case__c) ? e.Case__c : '';
        this.showDropdownTriageOption = (e.modelName === "Case" && e.isPopup)?  true: false;
        this.showDropdownOption = (e.modelName === "Job" && e.fromSM)? true: false;
        if(this.showDropdownOption)
        {
         this.parentSfdcId = e.job.PMS_Case_Number;
        }
        if(this.showDropdownTriageOption){
            this.parentSfdcId = e.Case__c;
        }
        this.setFormData();
    }
    // Pre-define the message type from MessageTypes list defined in static-list-data service
    @Input() messageType: string;
    @Input() enableEditor = false;
    @Input() showEditorOption = true;
    @Input() showMessageTypes = true;
    @Input() job_status: string;
    @Output() added: EventEmitter<any> = new EventEmitter();
    @Output() uploaded: EventEmitter<any> = new EventEmitter();
    @Output() repostMsgChange: EventEmitter<any> = new EventEmitter();
    roleSub: Subscription;
    contentText = '';
    imgPreview = '';
    messageTypes = MessageTypes;
    showSendMsgOption: Boolean = false;
    showSendMsgOptionBtn: Boolean = true;
    Is_Public__c = false;
    Is_Public_Job__c = false;
    jobCase__C: any;
    errorFlag = false;
    errorMessFlag = true;
    isPublicView = false;
    selectedItem: any;
    editorModules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            // [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
            // [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            // [{ 'font': [] }],
            [{ 'align': [] }],
            // ['clean'],                                         // remove formatting button
            ['link', 'image']                         // link and image, video
        ],
        imageDrop: true
    };
    containerPath = '';
    publicUser: any;
    userRole = '';
    isWorkOrder = false;
    _modelId: any;
    currentUser: any;
    caseId: any;
    selectedChatRoomId: any;
    selectedChatRoom: any;
    disabledpmsPublic = false;
    disabledvmsPublic = false;
    fromJobDetailPage = false;
    docModelName: any;
    docModelId: any;
    accessType: string;
    viewAsPartner: boolean;
    statusId :any;
    caseStatus='';
    parentSfdcId='';
    oldCaseStatus='';
    showDropdown = false;
    showDropdownOption=false;
    showDropdownTriage=false;
    showDropdownTriageOption=false;
    caseStatusValuesFor3PS = [...caseStatusValuesFor3PS];
    caseStatusValuesForTriage =[...caseStatusValuesForTriage];
    resolutionCode='';
    showResolutionDropdown = false;
    resolutionCodeValues = [...resolutionCodeValues]
    isSlaSelected = false;
    constructor(
        private route: ActivatedRoute,
        private _messageService: MessageService,
        public _appState: AppStateService,
        private _commentApi: CommentApi,
        private _commonService: CommonService,
        private _preloader: PreloaderService,
        private _modalService: ModalService,
        private _jobOrderItemApi: JobOrderItemApi,
        private _caseApi: CaseApi,
        private _alertService: AlertService,

    ) {
        if (this.route.snapshot.data['accessType'] && this.route.snapshot.data['accessType'] === 'public') {
            this.isPublicView = true;
        }
        this.accessType = _appState.getAccessType();
        // To check if view as PMS mode is activated.
        if (localStorage.getItem('viewAsInfo')) {
            const viewAsInfo = JSON.parse(localStorage.getItem('viewAsInfo'));
            this.viewAsPartner = (viewAsInfo && viewAsInfo.viewAsType === 'Partner');
            this.accessType = (this.viewAsPartner) ? 'partner' : this.accessType;
        }
    }

    ngOnInit() {
        this.docModelId = this._modelId;
        this.docModelName = this.docModelName;
        // get current user details
        this._appState.getAppState().subscribe(
            data => {
                if (data && data['user']) {
                    this.currentUser = data['user'];
                }
            }, error => {
                console.log(error);
            });

        this._messageService.getchatRoomId().subscribe(res => {
            if (res) {
                if (res.fromJobDetailPage) {
                    this.caseId = res['id'];
                    this.fromJobDetailPage = true;
                } else {
                    this.selectedChatRoom = res;
                    this.selectedChatRoomId = res['idx'];
                    this.caseId = res['id'];
                    this.errorMessFlag = true;
                    this.setMarkPublicMsg();
                }
            }
            if (this.selectedItem && this.selectedItem.modelName !== 'Job' && !this.fromJobDetailPage) {
                this.isWorkOrder = true;
            }
        });
        if(this.selectedChatRoomId===2 || this.selectedChatRoomId===3){
        this._caseApi
        .findOne({
          where: { id: this.caseId },
        })
        .subscribe(
          (result) => {
            if(result['B2B_Case_Close_Code__c']){
                this.resolutionCode = result['B2B_Case_Close_Code__c']
            }
            if((result['Initial_Ticket_Response_Ack_3PS_SLA__c'] === 'Yes' || result['Initial_Ticket_Response_Ack_3PS_SLA__c'] === 'No')&&
            (result['Worker_Assign_Customer_Appointment_PM__c'] === 'Yes' || result['Worker_Assign_Customer_Appointment_PM__c'] === 'No')&&
            (result['Worker_Arrival_On_Site_3PS_SLA_Met__c'] === 'Yes' || result['Worker_Arrival_On_Site_3PS_SLA_Met__c'] === 'No')&&
            (result['Ticket_Resolution_Resolved_3PS_SLA__c'] === 'Yes' || result['Ticket_Resolution_Resolved_3PS_SLA__c'] === 'No')&&
            (result['SLA_Exception_Reason_Code_PMS_Manual__c'] != 'None') && (result['SLA_Exception_Reason_Description_PMS__c'] != 'None')&&
            (result['SLA_Exception_Applicable_PMS_Manual__c'] === 'Yes' || result['Ticket_Resolution_Resolved_3PS_SLA__c'] === 'No'))
            {
                this.isSlaSelected = true;
            }
              this.caseStatus = result['Status'];
              if(this.caseStatus === 'Resolved (Closed)'){
                this.isSlaSelected = true;
              }
              if(this.caseStatus === 'Resolved (Job Closed, PMS Open SLA Paused)')
              {
                  this.showResolutionDropdown =true;
              }
          },
          (err) => {
            console.log(err);
            this._preloader.showPreloader();
          }
        );}
  
    }

    setFormData() {
        this.containerPath = environment.baseUrl + '/' + environment.apiVersion + '/Containers/';
        this.roleSub = this._appState.getJobDetailUserRole().subscribe(role => {
            if (role && role['userRole']) {
                this.publicUser = role;
                this.userRole = role['userRole'];
            }
        })
        // set message type
        if (!this.messageType) {
            this.messageType = 'worker-visit-status';
        }
        this.contentText = '';
        this.repostMsgChange.emit('');
        this.setMarkPublicMsg();
    }

    setMarkPublicMsg() {
        this.showSendMsgOptionBtn = true;
        // const accessType = this._appState.getAccessType();
        if (this.selectedChatRoomId === 1 || this.accessType === 'partner') { // It's select auto pms public
            this.Is_Public__c = true;
            this.disabledpmsPublic = true;
        }else if(this.selectedChatRoomId === 2){
            this.showDropdownTriage = true;
        }
        else if(this.selectedChatRoomId === 3){
            this.showDropdown = true;
        } else if (this.selectedChatRoomId === 5) { // It's select auto vms public
            this.Is_Public_Job__c = true;
            this.disabledvmsPublic = true;
        } else if (this.selectedChatRoomId === 7) { // It's for 3ps sales order chat romm
            this.Is_Public__c = false;
            this.Is_Public_Job__c = false;
            this.showSendMsgOptionBtn = false;
        } else if (this.selectedChatRoomId === 9) { // It's for 3ps purchase order chat room
            this.Is_Public__c = false;
            this.Is_Public_Job__c = false;
            this.showSendMsgOptionBtn = false;
        }
    }

    ngOnDestroy() {
        if (this.roleSub) {
            this.roleSub.unsubscribe();
        }
    }

    onChangeMessageType() {
        this.errorFlag = this.messageType ? false : true;
    }
    isResolved(){   
        if(this.caseStatus === 'Resolved (Job Closed, PMS Open SLA Paused)'){
            this.showResolutionDropdown = true;
        }else{
            this.showResolutionDropdown = false;      
        }
        }
    send(fileContent?) {
        if(this.resolutionCode === 'None'){
            this._alertService.warn('Please Select Resolution Code')

        }
        else{
        if (this.messageType === '') {
            this.errorFlag = true;
        }
        if (!this.errorMessFlag && (this.contentText.length || fileContent.length) && !this.errorFlag) {
            this._preloader.showPreloader();
            const userTypeCheckForJobMsg = (this.currentUser && this.currentUser.accessType === 'vendor') ? true : this.Is_Public_Job__c;
            const param = {
                modelName: this.selectedChatRoomId === 2 || (this.fromJobDetailPage && !userTypeCheckForJobMsg) ? 'Case' : this.modelName,
                modelId: this.selectedChatRoomId === 2 || (this.fromJobDetailPage && !userTypeCheckForJobMsg) ?
                    this.caseId.toString() : this._modelId.toString(),
                messageType: this.messageType,
                Is_Public_Job__c: userTypeCheckForJobMsg,
                Is_Public__c: this.Is_Public__c,
                isPrivate: (this.Is_Public__c || this.Is_Public_Job__c) ? false : true,
                Case_Comment_Body__c: fileContent ? fileContent : this.contentText.split('\n').join('<br>'),
                postByName: '',
                ownerId: '',
                Contact__c: ''
            };
            if (this.isPublicView && this.publicUser && this.publicUser['id']) {
                param['postByName'] = this.publicUser['email'];
                param['ownerId'] = this.publicUser['id'];
                param['Contact__c'] = this.publicUser['profile'] ? this.publicUser['profile']['sfdcId'] : '';
            } else {
                if (this.currentUser && this.currentUser['id']) {
                    param['postByName'] = this.currentUser['email'];
                    param['ownerId'] = this.currentUser['id'];
                    param['Contact__c'] = this.currentUser['sfdcId'];
                }
            }
            // It's for Private (3PS/ICC) chat room
            if (this.selectedChatRoomId === 3 && !param.Is_Public_Job__c) {
                param['modelName'] = 'Case';
                param['modelId'] = this.caseId.toString();
            }
            if (this.selectedChatRoomId === 3){
                param['caseStatus'] = this.caseStatus
                param['resolutionCode'] = this.resolutionCode
                }
            // It's for 3ps sales order chat room
            if (this.selectedChatRoomId === 7) {
                param['modelName'] = 'Case';
                param['modelId'] = this.caseId.toString();
                param['Order__c'] = this.selectedItem.job.Sales_Order__c;
                param['Case_Comment_Body__c'] = 'Billing:' + param['Case_Comment_Body__c'];
                param['commentType'] = 'billing';
            }

            // It's for 3ps purchase order chat room
            if (this.selectedChatRoomId === 9) {
                param['modelName'] = 'Case';
                param['modelId'] = this.caseId.toString();
                param['Work_Order__c'] = this.selectedItem.job.Work_Order__c;
                param['Case_Comment_Body__c'] = 'Billing:' + param['Case_Comment_Body__c'];
                param['commentType'] = 'billing';
            }

            if (this.selectedChatRoomId === 2 || this.selectedChatRoomId === 3 || this.selectedChatRoomId === 5) {
                param['commentType'] = this.selectedChatRoomId === 2 ? 'pmc' :
                    (this.selectedChatRoomId === 3 || this.selectedChatRoomId === 5) ? 'icc' : null;
            }
            if(this.selectedChatRoomId === 3 || this.selectedChatRoomId === 2){
                param['caseStatus'] = this.caseStatus
                param['parentSfdcId']=this.parentSfdcId;
            }
            this._commentApi.sendMessage(param).subscribe(
                result => {
                    if (result) {
                        this.showSendMsgOption = false;
                        if (!fileContent) {
                            this.contentText = '';
                        }
                        this.repostMsgChange.emit('');
                        this.imgPreview = '';
                        this.enableEditor = false;
                        let filterObj = {}
                        // making message checkbox and send button in default mode
                        this.Is_Public_Job__c = false;
                        this.Is_Public__c = false;
                        if (!this.contentText) {
                            this.errorMessFlag = true;
                        }
                        this.setMarkPublicMsg();
                        if (this.currentUser && this.currentUser.accessType === 'internal' && !this.viewAsPartner) {
                            // this.added.emit(result);
                            filterObj = { Case__c: this.jobCase__C }
                        } else {
                            filterObj = {
                                modelName: this.modelName,
                                modelId: this._modelId.toString()
                            }
                        }
                        if (this.currentUser && this.currentUser.accessType !== 'internal') {
                            this._messageService.refetchMessages(filterObj);
                        }
                        // scrollTo(0, 200);
                        if (!this.showMessageTypes) {
                            //  this._alertService.success('Message sent successfully.');
                        }
                    }
                    this._preloader.hidePreloader();
                },
                err => {
                    if (err.statusCode === '413') {
                        err.message = 'Message size too large!';
                    }
                    // this._alertService.error(err.message);
                    // window.scrollTo(0, 0);
                    this._preloader.hidePreloader();
                }
            );
        }
    }
    }

    textChange(e, src) {
        let textLen;
        if (src === 'textarea') {
            textLen = e;
            textLen = textLen.trim();
            this.repostMsgChange.emit(e);
            if (!textLen.length) {
                this.enableEditor = false;
                this.errorMessFlag = true;
            } else {
                this.errorMessFlag = false;
            }
        } else {
            this.enableEditor = true;
            this.errorMessFlag = true;
            const val = e && e.content && e.content.ops;
            if (val) {
                val.forEach(item => {
                    if (item['insert']) {
                        if (typeof item['insert'] === 'object' && Array.isArray(item['insert']) === false) {
                            this.errorMessFlag = false;
                        } else {
                            textLen = item['insert'];
                            textLen = textLen.trim();
                            if (textLen.length) {
                                this.errorMessFlag = false;
                            }
                        }
                    }
                });
            } else {
                textLen = e;
                textLen = textLen.trim();
                if (textLen.length) {
                    this.errorMessFlag = false;
                }
            }
        }
    }

    uploadDocument(modal, size) {
        // It's for sales Order
        if (this.selectedChatRoom && this.selectedChatRoom.orderId && this.selectedChatRoomId === 7) {
            this.docModelId = this.selectedChatRoom.orderId;
            this.docModelName = 'Order';
            this._modalService.open(modal, size);
            // It's for purchase Order
        } else if (this.selectedChatRoom && this.selectedChatRoom.purchaseOrderId && this.selectedChatRoomId === 9) {
            this.docModelId = this.selectedChatRoom.purchaseOrderId;
            this.docModelName = 'PurchaseOrder';
            this._modalService.open(modal, size);
            // It's for Case and JobOrderItem
        } else if ((this.modelName === 'Case' || this.modelName === 'JobOrderItem') && this.workOrderId) {
            this._preloader.showPreloader();
            this._jobOrderItemApi.find({
                where: { Job_Order__c: this.workOrderId },
                fields: ['id', 'sfdcId'],
                skip: 0,
                limit: 20,
            }).subscribe(
                result => {
                    this._preloader.hidePreloader();
                    if (result && result.length) {
                        this.docModelName = 'JobOrderItem';
                        this.docModelId = result[0]['id'];
                        this._modalService.open(modal, size);
                    }
                },
                err => {
                    this._preloader.hidePreloader();
                    console.log('No work order line item found.', err)
                }
            );
            // It's for job
        } else {
            this.docModelId = this._modelId;
            this.docModelName = this.modelName;
            this._modalService.open(modal, size);
        }
    }

    documentUploaded(event) {
        if (event && event.length) {

            // TRIGGERING FILE UPLOADED BEHAVIOUR SUBJECT
            this._commonService.messagePostFileUploaded.next(true);
            this._preloader.showPreloader();
            const editor = 'quill';
            // append description
            const desc = event[0]['description'];
            let contentText = desc ? "<span>" + desc + "</span>" : "";
            let count = 0;
            event.forEach(file => {
                if (file && file.shortUrl) {
                    count++;
                    const shortUrl = file.shortUrl;
                    contentText += "<br/><a href='" + shortUrl + "' target='_blank'>" + shortUrl + "</a>";
                    if (file['fileMeta'] && file['fileMeta']['type'].match('image')) {
                        this.imgPreview = "<img src='" + shortUrl + "' /><br/>";
                    }
                    contentText += (this.imgPreview) ? ("<br/>" + this.imgPreview) : "";
                    // if (count === event.length) {
                    //     this.contentText += (this.imgPreview) ? ('<br/>' + this.imgPreview) : '';
                    //     this.textChange(this.contentText, editor);
                    //     this.uploaded.emit();
                    //     this._preloader.hidePreloader();
                    // }
                }
            });
            this.uploaded.emit();
            this.errorMessFlag = false;
            this.send(contentText);
        }
    }
    openSendMsgOption() {
        this.showSendMsgOption = this.showSendMsgOption ? false : true;
    }
}
