import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UserTypeApi } from 'shared/sdk';
import { AppStateService } from 'shared/services/app-state.service';
import { debounceTime, distinctUntilChanged, switchMap, filter } from 'rxjs/operators';
import { userType } from '../../../../models/static-list-data.service'


@Component({
  selector: 'app-user-type-lookup',
  templateUrl: './user-type-lookup.component.html',
  styleUrls: ['./user-type-lookup.component.css']
})
export class UserTypeLookupComponent implements OnInit {

  @Input() set resetData(isClear) {
    this.UserTypeSelected = [];
  }
  @Input() from;
  @Input() selectLabel = 'label';
  @Input() selectbindValue = 'value';
  @Input() isMultiSelect = 'false';
  @Input() selectedDefaultUserType;
  @Input() set selectUser(e) {
    if (e) {
      this.userTypeList = [];
      this.userTypeList.push(e);
      this.UserTypeSelected = e && e.id;
    }
  }

  @Input() isDisabled = false;
  @Output() getUserTypeObj: EventEmitter<any> = new EventEmitter;

  // vendor User

  noUserType = 'Searching...';
  UsersTypeahead = new EventEmitter<string>();
  userTypeList = [];
  UserTypeSelected = [];
  isMultipleSelected = true;
  maxSelectedItems = 1;

  constructor(
    private _userTypeApi: UserTypeApi
  ) { }

  ngOnInit() {
    this.userTypeList = userType;
    // this.getUsersType();
    if (this.from && this.from === 'analyticsDashboard') {
      if (this.selectedDefaultUserType && this.selectedDefaultUserType.length) {
        this.UserTypeSelected = this.selectedDefaultUserType;
      } else {
        this.UserTypeSelected = [];
      }
    } else {
      const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
      this.UserTypeSelected = (preselected && preselected['userTypeId']) ? preselected['userTypeId'] : '';
    }
    // if (this.UserTypeSelected) {
    //   const obj = { id: this.UserTypeSelected };
    //   this.getUsersTypeList(obj).subscribe(x => {
    //     this.userTypeList = x;
    //     this.noUserType = (this.userTypeList.length === 0) ? 'No User Type found.' : '';
    //   },
    //     err => {
    //       console.log(err);
    //       this.userTypeList = [];
    //       this.noUserType = '';
    //     })
    // }
    // this.onUserTypeChange();
  }

  getUsersType() {
    this.UsersTypeahead.pipe(
      filter(term => term && term.length > 2),
      distinctUntilChanged(),
      debounceTime(200),
      switchMap(term => {
        const searchPattern = { like: '%' + term.trim() + '%', options: 'i' };
        const whereObj = {
          name: searchPattern,
        };
        return this.getUsersTypeList(whereObj);
      })
    ).subscribe(
      x => {
        this.userTypeList = x;
        this.noUserType = (this.userTypeList.length === 0) ? 'No User Type found.' : '';
      },
      err => {
        console.log(err);
        this.userTypeList = [];
        this.noUserType = '';
      }
    );
  }

  onUserTypeChange() {
    this.getUserTypeObj.emit(this.UserTypeSelected);
  }

  clearPrograms() {
    this.UserTypeSelected = [];
    this.getUserTypeObj.emit({});
  }

  getUsersTypeList(whereObj) {
    return this._userTypeApi.find({
      where: whereObj,
      fields: ['name', 'id', 'slug'],
    }).map(res => res);
  }

}
