import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmGovernanceComponent } from './sm-governance.component';
import { HealthCheckModule } from '../health-check/health-check.module';
import { PipeModule } from 'shared/pipe/pipe.module';

@NgModule({
  imports: [
    CommonModule,
    HealthCheckModule,
    PipeModule
  ],
  declarations: [SmGovernanceComponent],
  exports: [SmGovernanceComponent]
})
export class SmGovernanceModule { }
