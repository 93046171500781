import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-information-tool-tip',
  templateUrl: './information-tool-tip.component.html',
  styleUrls: ['./information-tool-tip.component.css']
})
export class InformationToolTipComponent implements OnInit {
  @Input() information: any;
  @Input() width = '200';
  showInfo = false;
  constructor() { }

  ngOnInit() {
  }

}
