/* tslint:disable */
import {
  Department,
  LibraryDetail
} from '../index';

declare var Object: any;
export interface TaskLibraryInterface {
  "sfdcId"?: string;
  "Name"?: string;
  "Owner"?: string;
  "LastModifiedById"?: string;
  "CurrencyIsoCode"?: string;
  "CreatedById"?: string;
  "createdOnServiceo"?: boolean;
  "Description__c"?: string;
  "Duration__c"?: number;
  "Duration_Type__c"?: string;
  "PgMO_Departments__c"?: string;
  "PgMO_Parent_Task_Library__c"?: string;
  "Priority__c"?: string;
  "Request_Type__c"?: string;
  "Sequence_Number__c"?: number;
  "Task_Name__c"?: string;
  "Temporary_Sequence_Number__c"?: number;
  "Type__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  department?: Department;
  subTasks?: TaskLibrary[];
  assignments?: LibraryDetail[];
}

export class TaskLibrary implements TaskLibraryInterface {
  "sfdcId": string;
  "Name": string;
  "Owner": string;
  "LastModifiedById": string;
  "CurrencyIsoCode": string;
  "CreatedById": string;
  "createdOnServiceo": boolean;
  "Description__c": string;
  "Duration__c": number;
  "Duration_Type__c": string;
  "PgMO_Departments__c": string;
  "PgMO_Parent_Task_Library__c": string;
  "Priority__c": string;
  "Request_Type__c": string;
  "Sequence_Number__c": number;
  "Task_Name__c": string;
  "Temporary_Sequence_Number__c": number;
  "Type__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  department: Department;
  subTasks: TaskLibrary[];
  assignments: LibraryDetail[];
  constructor(data?: TaskLibraryInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `TaskLibrary`.
   */
  public static getModelName() {
    return "TaskLibrary";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of TaskLibrary for dynamic purposes.
  **/
  public static factory(data: TaskLibraryInterface): TaskLibrary{
    return new TaskLibrary(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'TaskLibrary',
      plural: 'TaskLibraries',
      path: 'TaskLibraries',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "Owner": {
          name: 'Owner',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "createdOnServiceo": {
          name: 'createdOnServiceo',
          type: 'boolean'
        },
        "Description__c": {
          name: 'Description__c',
          type: 'string'
        },
        "Duration__c": {
          name: 'Duration__c',
          type: 'number'
        },
        "Duration_Type__c": {
          name: 'Duration_Type__c',
          type: 'string'
        },
        "PgMO_Departments__c": {
          name: 'PgMO_Departments__c',
          type: 'string'
        },
        "PgMO_Parent_Task_Library__c": {
          name: 'PgMO_Parent_Task_Library__c',
          type: 'string'
        },
        "Priority__c": {
          name: 'Priority__c',
          type: 'string'
        },
        "Request_Type__c": {
          name: 'Request_Type__c',
          type: 'string'
        },
        "Sequence_Number__c": {
          name: 'Sequence_Number__c',
          type: 'number'
        },
        "Task_Name__c": {
          name: 'Task_Name__c',
          type: 'string'
        },
        "Temporary_Sequence_Number__c": {
          name: 'Temporary_Sequence_Number__c',
          type: 'number'
        },
        "Type__c": {
          name: 'Type__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        department: {
          name: 'department',
          type: 'Department',
          model: 'Department',
          relationType: 'belongsTo',
                  keyFrom: 'PgMO_Departments__c',
          keyTo: 'sfdcId'
        },
        subTasks: {
          name: 'subTasks',
          type: 'TaskLibrary[]',
          model: 'TaskLibrary',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'PgMO_Parent_Task_Library__c'
        },
        assignments: {
          name: 'assignments',
          type: 'LibraryDetail[]',
          model: 'LibraryDetail',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'PgMO_Task_Library__c'
        },
      }
    }
  }
}
