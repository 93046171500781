import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkerInfoComponent } from './worker-info.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [WorkerInfoComponent],
  exports: [WorkerInfoComponent]
})
export class WorkerInfoModule { }
