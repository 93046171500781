import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { smsStatusList } from 'shared/models/static-list-data.service'; 

@Component({
  selector: 'app-sms-status-lookup',
  templateUrl: './sms-status-lookup.component.html',
  styleUrls: ['./sms-status-lookup.component.css']
})
export class SmsStatusLookupComponent implements OnInit {

  @Input() set resetData(isClear) {
    if (isClear) {
      this.smsStatusSelected = [];
    }
  }
  @Input() placeholder = 'SMS Status';
  @Input() isdisabled = false;
  @Input() filterObjValue = 'smsStatus';
  @Input() isMultipleSelected = true;
  @Input() viewType = '';

  @Output() getSmsStatusObj: EventEmitter<any> = new EventEmitter;


  smsStatusOptionList = [];
  smsStatusSelected = [];

  /**
   * @constructor
   * Represents a PriorityLookupComponent.
   */
  constructor() {
  }

  ngOnInit() {
    this.smsStatusOptionList = smsStatusList;
    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
    this.smsStatusSelected = (preselected && preselected[this.filterObjValue]) ? preselected[this.filterObjValue] : '';
  }

  onSmsStatusChange() {
    this.getSmsStatusObj.emit(this.smsStatusSelected);
  }

  clearSmsStatus() {
    this.smsStatusSelected = [];
    this.getSmsStatusObj.emit([]);
  }

}
