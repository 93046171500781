import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectProgramInJobsiteComponent } from './select-program-in-jobsite.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule } from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        NgxDatatableModule,
        FormsModule
    ],
    declarations: [SelectProgramInJobsiteComponent],
    exports: [SelectProgramInJobsiteComponent],
})
export class SelectProgramJobsiteModule { }
