import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TalentTypeApi } from 'shared/sdk';
import { debounceTime, switchMap, filter } from 'rxjs/operators';

@Component({
  selector: 'app-talent-type-lookup',
  templateUrl: './talent-type-lookup.component.html',
  styleUrls: ['./talent-type-lookup.component.scss']
})
export class TalentTypeLookupComponent implements OnInit {
  @Input() boxedLayout = false;
  @Input() set resetData(isClear) {
    if (isClear) {
      this.talentTypeSelected = [];
    }
  }
  talentTypeahead: EventEmitter<any> = new EventEmitter();
  noTalentType: string;
  talentTypeOptionList: any[] = [];
  talentTypeSelected = [];
  @Input() placeholder = 'Talent Type';
  @Input() isdisabled = false;
  @Input() isMultipleSelected = true;
  @Input() bindValue = 'Name';
  @Input() bindLabel = 'Name';

  @Output() getTalentTypeObj: EventEmitter<any> = new EventEmitter;
  @Input() set preselected(e) {
    if (e) {
      this.talentTypeSelected = e;
      let reqobj = {
        fields: ['id', 'sfdcId', 'Name', 'Talent_Type_Name__c'],
        where: { sfdcId: this.talentTypeSelected }
      }
      this._talentTypeApi.find(reqobj).subscribe(result => {
        if (result && result.length) {
          this.talentTypeOptionList = result;
          this.noTalentType = (this.talentTypeOptionList.length === 0) ? 'No data found.' : '';
        }
      });
    }
  }
  @Input() inGlobalFilter = true;
  @Input() preselectedKey = 'talentTypes';
  

  /**
   * @constructor
   * Represents a TalentTypeLookupComponent.
   */
  constructor(
    private _talentTypeApi: TalentTypeApi
  ) {
  }

  ngOnInit() {
    // this.getTalentType('');
    if(this.inGlobalFilter && (!this.talentTypeSelected || !this.talentTypeSelected.length)){
      const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
      this.talentTypeSelected = (preselected && preselected[this.preselectedKey]) ? preselected[this.preselectedKey] : [];
    }
    this.getTalentTypes();
  }

  // getTalentType(whereObj) {
  //   const findObj = {
  //     fields: ['id', 'sfdcId', 'Name', 'Talent_Type_Name__c']
  //   };
  //   if (whereObj) {
  //     findObj['where'] = whereObj;
  //   }
  //   this._talentTypeApi.find(findObj).subscribe(
  //     data => {
  //       if (data && data.length) {
  //         this.talentTypeOptionList = data;
  //       } else {
  //         this.talentTypeOptionList = [];
  //       }
  //     },
  //     err => {
  //       console.log('Error fetching talent types', err.message);
  //     }
  //   );
  // }

  getTalentTypes() {
    this.talentTypeahead
      .pipe(
        filter(search => search && search.length > 2),
        debounceTime(200),
        switchMap(search => {
          const searchPattern = { like: '%' + search.trim() + '%', options: 'i' };
          return this.findTalentTypeApi(searchPattern);
        })
      ).subscribe(
        data => {
          if (data && data.length) {
            this.talentTypeOptionList = data;
          } else {
            this.talentTypeOptionList = [];
            this.noTalentType = 'No talent type found.';
          }
        },
        err => {
          console.log('Error fetching talent types', err.message);
        }
      );
  }

  findTalentTypeApi(searchText) {
    let whereObj;
    if (this.bindLabel === 'Talent_Type_Name__c') {
      whereObj = { Talent_Type_Name__c: searchText };
    } else {
      whereObj = { Name: searchText };
    }
    return this._talentTypeApi.find({
      where: whereObj,
      fields: ['id', 'sfdcId', 'Name', 'Talent_Type_Name__c']
    })
  }

  onTalentTypeChange() {
    this.getTalentTypeObj.emit(this.talentTypeSelected);
  }

  clearTalentType() {
    this.talentTypeSelected = [];
    this.getTalentTypeObj.emit([]);
  }

}
