import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { GroupApi } from 'shared/sdk';
import { filter, distinctUntilChanged, debounceTime, switchMap } from 'rxjs/operators';
import { CommonService } from 'shared/services/common.service';

@Component({
  selector: 'app-team-lookup',
  templateUrl: './team-lookup.component.html',
  styleUrls: ['./team-lookup.component.css']
})
export class TeamLookupComponent implements OnInit {

  @Input() set resetData(isClear) {
    // this.teamsList = [];
    this.teamSelected = [];
  }
  @Input() isMultipleSelected = true;
  @Input() boxedLayout;
  @Input() set selectedTeam(event) {
    if (event && event.length) {
      this.teamsList = [];
      this.teamSelected = event;
      this.getSelectedTeamData();
    }
  }
  @Output() getTeamObj: EventEmitter<any> = new EventEmitter;
  @Input() selectedHelpDeskType = [];
  noTeamFound = 'No Team found.';
  teamTypeahead = new EventEmitter<string>();
  teamsList = [];
  teamSelected = [];
  isLoading = false;

  constructor(
    private _cd: ChangeDetectorRef,
    private _groupApi: GroupApi,
    private _commonService: CommonService
  ) {
    this._commonService.getLeftSideFilters().subscribe(leftFilter => {
      if (leftFilter && leftFilter.selectedTab === 'myTeam') {
        this.teamSelected = leftFilter.selectedData;
        this.getSelectedTeamData();
      }
    })
  }

  ngOnInit() {
    this.getTeams();
    // to show selected user
    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
    if (preselected && preselected['teams'] && preselected['teams'].length) {
    this.teamSelected = (preselected && preselected['teams']) ? preselected['teams'] : '';
    this.getSelectedTeamData();
    }
  }

  getSelectedTeamData() {
    if (this.teamSelected && this.teamSelected.length) {
      this.getUsersList({ sfdcId: { inq: Array.isArray(this.teamSelected) ? this.teamSelected : [this.teamSelected]} }).subscribe(
        x => {
          this.isLoading = false;
          if (x && x.length) {
            x.forEach((element: any) => {
              element.label = element['Department_Group_Code__c'] + ' (' + (element['department'] ?
              element['department']['Name'] : '') + ' - ' + element['Name'] + ') (' + element['Community__c'] + ')'
            })
            this.teamsList = x;

          } else {
            this.teamsList = [];
          }
          this.noTeamFound = (this.teamsList.length === 0) ? 'No Team found.' : '';
        },
        err => {
          this.isLoading = false;
          console.log(err);
          this.teamsList = [];
          this.noTeamFound = '';
        }
      );
    }
    this.getTeamObj.emit(this.teamSelected);
  }

  // get partner's accounts list by name
  getTeams() {
    this.teamsList = [];
    this.teamTypeahead
      .pipe(
        filter(term => term && term.trim().length > 2),
        distinctUntilChanged(),
        debounceTime(200),
        switchMap(term => {
          const searchPattern = { like: '%' + term.trim() + '%', options: 'i' };
          const whereObj = {
            Department_Group_Code__c: searchPattern            
          };
          if(this.selectedHelpDeskType && this.selectedHelpDeskType.length && this.selectedHelpDeskType[0] === 'OKR') {
            whereObj['RecordTypeId']= '0121a000000Vll7AAC';
          }
          return this.getUsersList(whereObj);
        })
      )
      .subscribe(
        x => {
          this.isLoading = false;
          this._cd.markForCheck();
          if (x && x.length) {
            x.forEach((element: any) => {
              element.label = element['Department_Group_Code__c'] + ' (' + (element['department'] ? element['department']['Name'] : '') + ' - ' + element['Name'] + ') (' + element['Community__c'] + ')'
            })
            this.teamsList = x;

          } else {
            this.teamsList = [];
          }
          this.noTeamFound = (this.teamsList.length === 0) ? 'No Team found.' : '';
        },
        err => {
          this.isLoading = false;
          console.log(err);
          this.teamsList = [];
          this.noTeamFound = '';
        }
      );
  }

  onTeamsChange() {
    this.getTeamObj.emit(this.teamSelected);
  }

  clearTeams() {
    this.teamSelected = [];
    this.getTeamObj.emit([]);
  }

  /**
   * 
   * @param whereObj 
   */

  getUsersList(whereObj) {
    this.isLoading = true;
    return this._groupApi.find({
      where: whereObj,
      fields: ['Name', 'id', 'sfdcId', 'Department_Group_Code__c', 'Department__c', 'Community__c'],
      order: 'CreatedDate DESC',
      include: [
        {
          relation: 'department',
          scope: {
            fields: ['sfdcId', 'Name']
          }
        }
      ]
    }).map(res => res);
  }

}
