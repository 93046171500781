/* tslint:disable */

declare var Object: any;
export interface IronAttachmentInterface {
  "sfdcId"?: string;
  "Account_Name__c"?: string;
  "Account_RFI_Form__c"?: string;
  "Account__c"?: string;
  "Attachment_Id__c"?: string;
  "B2B_Page_Load_Counter__c"?: number;
  "B2B_Uploaded_file__c"?: boolean;
  "Case__c"?: string;
  "Category_Id__c"?: string;
  "Category__c"?: string;
  "CreatedDate"?: Date;
  "CreatedbyId"?: string;
  "CurrencyIsoCode"?: string;
  "Customer_PO__c"?: string;
  "Date_Received__c"?: Date;
  "Date_Sent_to_Serviceo__c"?: Date;
  "Date_Uploaded__c"?: Date;
  "Department__c"?: string;
  "Description__c"?: string;
  "Document_Library_File_Id__c"?: string;
  "Error_Message__c"?: string;
  "FB_Task__c"?: string;
  "File_Name__c"?: string;
  "File_Type__c"?: string;
  "Form_Job_Process_Id__c"?: string;
  "Form_Job_Status__c"?: string;
  "Form_Process_Error__c"?: string;
  "Form_Rejected_Reason__c"?: string;
  "ICC_FRU__c"?: string;
  "ICC_FSE__c"?: string;
  "Iron_Attachment_Ref__c"?: string;
  "IsDeleted"?: boolean;
  "Job_FRU__c"?: string;
  "Job_FSE__c"?: string;
  "LastModifiedById"?: string;
  "LastModifiedDate"?: Date;
  "LastReferencedDate"?: Date;
  "LastViewedDate"?: Date;
  "Modal_Name__c"?: string;
  "Model_Id__c"?: string;
  "Name"?: string;
  "OwnerId"?: string;
  "PMS_Case__c"?: string;
  "Password__c"?: string;
  "PgMO_Conversation__c"?: string;
  "PgMO_Milestone_Library__c"?: string;
  "PgMO_Milestones__c"?: string;
  "PgMO_Programs__c"?: string;
  "PgMO_Projects__c"?: string;
  "PgMO_Tasks__c"?: string;
  "Portal_Document_Id__c"?: string;
  "Program__c"?: string;
  "Request_Form_Receipt_Template__c"?: string;
  "Request_Form_Receipt__c"?: string;
  "ScreenShot__c"?: boolean;
  "ServiceO_Document_Link__c"?: string;
  "Short_URL__c"?: string;
  "SizeField__c"?: number;
  "Size__c"?: string;
  "Size_in_KB__c"?: number;
  "SystemModstamp"?: Date;
  "Title_Sub_Category_Id__c"?: string;
  "Title_Sub_Category__c"?: string;
  "Uploaded_By__c"?: string;
  "User_Name__c"?: string;
  "Work_Order_Items__c"?: string;
  "Work_Order__c"?: string;
  "Workflow_Status_Approval_Level__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "Iron_Attachments__c"?: string;
}

export class IronAttachment implements IronAttachmentInterface {
  "sfdcId": string;
  "Account_Name__c": string;
  "Account_RFI_Form__c": string;
  "Account__c": string;
  "Attachment_Id__c": string;
  "B2B_Page_Load_Counter__c": number;
  "B2B_Uploaded_file__c": boolean;
  "Case__c": string;
  "Category_Id__c": string;
  "Category__c": string;
  "CreatedDate": Date;
  "CreatedbyId": string;
  "CurrencyIsoCode": string;
  "Customer_PO__c": string;
  "Date_Received__c": Date;
  "Date_Sent_to_Serviceo__c": Date;
  "Date_Uploaded__c": Date;
  "Department__c": string;
  "Description__c": string;
  "Document_Library_File_Id__c": string;
  "Error_Message__c": string;
  "FB_Task__c": string;
  "File_Name__c": string;
  "File_Type__c": string;
  "Form_Job_Process_Id__c": string;
  "Form_Job_Status__c": string;
  "Form_Process_Error__c": string;
  "Form_Rejected_Reason__c": string;
  "ICC_FRU__c": string;
  "ICC_FSE__c": string;
  "Iron_Attachment_Ref__c": string;
  "IsDeleted": boolean;
  "Job_FRU__c": string;
  "Job_FSE__c": string;
  "LastModifiedById": string;
  "LastModifiedDate": Date;
  "LastReferencedDate": Date;
  "LastViewedDate": Date;
  "Modal_Name__c": string;
  "Model_Id__c": string;
  "Name": string;
  "OwnerId": string;
  "PMS_Case__c": string;
  "Password__c": string;
  "PgMO_Conversation__c": string;
  "PgMO_Milestone_Library__c": string;
  "PgMO_Milestones__c": string;
  "PgMO_Programs__c": string;
  "PgMO_Projects__c": string;
  "PgMO_Tasks__c": string;
  "Portal_Document_Id__c": string;
  "Program__c": string;
  "Request_Form_Receipt_Template__c": string;
  "Request_Form_Receipt__c": string;
  "ScreenShot__c": boolean;
  "ServiceO_Document_Link__c": string;
  "Short_URL__c": string;
  "SizeField__c": number;
  "Size__c": string;
  "Size_in_KB__c": number;
  "SystemModstamp": Date;
  "Title_Sub_Category_Id__c": string;
  "Title_Sub_Category__c": string;
  "Uploaded_By__c": string;
  "User_Name__c": string;
  "Work_Order_Items__c": string;
  "Work_Order__c": string;
  "Workflow_Status_Approval_Level__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  "Iron_Attachments__c": string;
  constructor(data?: IronAttachmentInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `IronAttachment`.
   */
  public static getModelName() {
    return "IronAttachment";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of IronAttachment for dynamic purposes.
  **/
  public static factory(data: IronAttachmentInterface): IronAttachment{
    return new IronAttachment(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'IronAttachment',
      plural: 'IronAttachments',
      path: 'IronAttachments',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "Account_Name__c": {
          name: 'Account_Name__c',
          type: 'string'
        },
        "Account_RFI_Form__c": {
          name: 'Account_RFI_Form__c',
          type: 'string'
        },
        "Account__c": {
          name: 'Account__c',
          type: 'string'
        },
        "Attachment_Id__c": {
          name: 'Attachment_Id__c',
          type: 'string'
        },
        "B2B_Page_Load_Counter__c": {
          name: 'B2B_Page_Load_Counter__c',
          type: 'number'
        },
        "B2B_Uploaded_file__c": {
          name: 'B2B_Uploaded_file__c',
          type: 'boolean'
        },
        "Case__c": {
          name: 'Case__c',
          type: 'string'
        },
        "Category_Id__c": {
          name: 'Category_Id__c',
          type: 'string'
        },
        "Category__c": {
          name: 'Category__c',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "CreatedbyId": {
          name: 'CreatedbyId',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "Customer_PO__c": {
          name: 'Customer_PO__c',
          type: 'string'
        },
        "Date_Received__c": {
          name: 'Date_Received__c',
          type: 'Date'
        },
        "Date_Sent_to_Serviceo__c": {
          name: 'Date_Sent_to_Serviceo__c',
          type: 'Date'
        },
        "Date_Uploaded__c": {
          name: 'Date_Uploaded__c',
          type: 'Date'
        },
        "Department__c": {
          name: 'Department__c',
          type: 'string'
        },
        "Description__c": {
          name: 'Description__c',
          type: 'string'
        },
        "Document_Library_File_Id__c": {
          name: 'Document_Library_File_Id__c',
          type: 'string'
        },
        "Error_Message__c": {
          name: 'Error_Message__c',
          type: 'string'
        },
        "FB_Task__c": {
          name: 'FB_Task__c',
          type: 'string'
        },
        "File_Name__c": {
          name: 'File_Name__c',
          type: 'string'
        },
        "File_Type__c": {
          name: 'File_Type__c',
          type: 'string'
        },
        "Form_Job_Process_Id__c": {
          name: 'Form_Job_Process_Id__c',
          type: 'string'
        },
        "Form_Job_Status__c": {
          name: 'Form_Job_Status__c',
          type: 'string'
        },
        "Form_Process_Error__c": {
          name: 'Form_Process_Error__c',
          type: 'string'
        },
        "Form_Rejected_Reason__c": {
          name: 'Form_Rejected_Reason__c',
          type: 'string'
        },
        "ICC_FRU__c": {
          name: 'ICC_FRU__c',
          type: 'string'
        },
        "ICC_FSE__c": {
          name: 'ICC_FSE__c',
          type: 'string'
        },
        "Iron_Attachment_Ref__c": {
          name: 'Iron_Attachment_Ref__c',
          type: 'string'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "Job_FRU__c": {
          name: 'Job_FRU__c',
          type: 'string'
        },
        "Job_FSE__c": {
          name: 'Job_FSE__c',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "LastReferencedDate": {
          name: 'LastReferencedDate',
          type: 'Date'
        },
        "LastViewedDate": {
          name: 'LastViewedDate',
          type: 'Date'
        },
        "Modal_Name__c": {
          name: 'Modal_Name__c',
          type: 'string'
        },
        "Model_Id__c": {
          name: 'Model_Id__c',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "PMS_Case__c": {
          name: 'PMS_Case__c',
          type: 'string'
        },
        "Password__c": {
          name: 'Password__c',
          type: 'string'
        },
        "PgMO_Conversation__c": {
          name: 'PgMO_Conversation__c',
          type: 'string'
        },
        "PgMO_Milestone_Library__c": {
          name: 'PgMO_Milestone_Library__c',
          type: 'string'
        },
        "PgMO_Milestones__c": {
          name: 'PgMO_Milestones__c',
          type: 'string'
        },
        "PgMO_Programs__c": {
          name: 'PgMO_Programs__c',
          type: 'string'
        },
        "PgMO_Projects__c": {
          name: 'PgMO_Projects__c',
          type: 'string'
        },
        "PgMO_Tasks__c": {
          name: 'PgMO_Tasks__c',
          type: 'string'
        },
        "Portal_Document_Id__c": {
          name: 'Portal_Document_Id__c',
          type: 'string'
        },
        "Program__c": {
          name: 'Program__c',
          type: 'string'
        },
        "Request_Form_Receipt_Template__c": {
          name: 'Request_Form_Receipt_Template__c',
          type: 'string'
        },
        "Request_Form_Receipt__c": {
          name: 'Request_Form_Receipt__c',
          type: 'string'
        },
        "ScreenShot__c": {
          name: 'ScreenShot__c',
          type: 'boolean'
        },
        "ServiceO_Document_Link__c": {
          name: 'ServiceO_Document_Link__c',
          type: 'string'
        },
        "Short_URL__c": {
          name: 'Short_URL__c',
          type: 'string'
        },
        "SizeField__c": {
          name: 'SizeField__c',
          type: 'number'
        },
        "Size__c": {
          name: 'Size__c',
          type: 'string'
        },
        "Size_in_KB__c": {
          name: 'Size_in_KB__c',
          type: 'number'
        },
        "SystemModstamp": {
          name: 'SystemModstamp',
          type: 'Date'
        },
        "Title_Sub_Category_Id__c": {
          name: 'Title_Sub_Category_Id__c',
          type: 'string'
        },
        "Title_Sub_Category__c": {
          name: 'Title_Sub_Category__c',
          type: 'string'
        },
        "Uploaded_By__c": {
          name: 'Uploaded_By__c',
          type: 'string'
        },
        "User_Name__c": {
          name: 'User_Name__c',
          type: 'string'
        },
        "Work_Order_Items__c": {
          name: 'Work_Order_Items__c',
          type: 'string'
        },
        "Work_Order__c": {
          name: 'Work_Order__c',
          type: 'string'
        },
        "Workflow_Status_Approval_Level__c": {
          name: 'Workflow_Status_Approval_Level__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "Iron_Attachments__c": {
          name: 'Iron_Attachments__c',
          type: 'string'
        },
      },
      relations: {
      }
    }
  }
}
