import { Injectable } from "@angular/core";
import { AppStateService } from "shared/services/app-state.service";
import { Adapter } from "./adapter";

export class Document {
    constructor(
        public id: string = "",
        public url: string = ""
    ){}
}

@Injectable({
    providedIn: "root"
})

export class DocumentAdaptor implements Adapter<Document> {
    private _userData: any;
    private _mySfdcId: string = '';
    
    constructor(private _appState: AppStateService) {
        this._userData = this._appState.getAppState();
        this._mySfdcId = this._userData.getValue().user.sfdcId;
    }

    adapt(item: any): Document {
        // console.log('adapt ::: ', item);
        let document = new Document(
            item.id ? item.id: '',
            item.url ? item.url: ''
        )
        return document;
    }


    reverseAdapt(item: Document): any {
      
        return;
    }

}