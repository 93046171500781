/* tslint:disable */
import {
  Contact,
  RequestFormReceipt,
  Task
} from '../index';

declare var Object: any;
export interface RequestFormReceiptEscalationInterface {
  "sfdcId"?: string;
  "Confirm__c"?: boolean;
  "Problem_Date__c"?: Date;
  "Problem_Priority__c"?: string;
  "Problem_Suggestion__c"?: string;
  "Requested_By__c"?: string;
  "Task_Description__c"?: string;
  "Task_Summary__c"?: string;
  "Request_Date__c"?: Date;
  "Request_Form_Receipt__c"?: string;
  "Work_Order__c"?: string;
  "PMS_Case__c"?: string;
  "Work_Order_Item__c"?: string;
  "ICC_Case__c"?: string;
  "Sales_Order__c"?: string;
  "Sales_Invoice__c"?: string;
  "Purchase_Order__c"?: string;
  "Purchase_Invoice__c"?: string;
  "Payment__c"?: string;
  "Processed__c"?: boolean;
  "Escalation_Task__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  Contact?: Contact;
  RequestFormReceipt?: RequestFormReceipt;
  woTask?: Task;
  soTask?: Task;
  poTask?: Task;
  pmsCaseTask?: Task;
  iccCaseTask?: Task;
}

export class RequestFormReceiptEscalation implements RequestFormReceiptEscalationInterface {
  "sfdcId": string;
  "Confirm__c": boolean;
  "Problem_Date__c": Date;
  "Problem_Priority__c": string;
  "Problem_Suggestion__c": string;
  "Requested_By__c": string;
  "Task_Description__c": string;
  "Task_Summary__c": string;
  "Request_Date__c": Date;
  "Request_Form_Receipt__c": string;
  "Work_Order__c": string;
  "PMS_Case__c": string;
  "Work_Order_Item__c": string;
  "ICC_Case__c": string;
  "Sales_Order__c": string;
  "Sales_Invoice__c": string;
  "Purchase_Order__c": string;
  "Purchase_Invoice__c": string;
  "Payment__c": string;
  "Processed__c": boolean;
  "Escalation_Task__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  Contact: Contact;
  RequestFormReceipt: RequestFormReceipt;
  woTask: Task;
  soTask: Task;
  poTask: Task;
  pmsCaseTask: Task;
  iccCaseTask: Task;
  constructor(data?: RequestFormReceiptEscalationInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `RequestFormReceiptEscalation`.
   */
  public static getModelName() {
    return "RequestFormReceiptEscalation";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of RequestFormReceiptEscalation for dynamic purposes.
  **/
  public static factory(data: RequestFormReceiptEscalationInterface): RequestFormReceiptEscalation{
    return new RequestFormReceiptEscalation(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'RequestFormReceiptEscalation',
      plural: 'RequestFormReceiptEscalations',
      path: 'RequestFormReceiptEscalations',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "Confirm__c": {
          name: 'Confirm__c',
          type: 'boolean',
          default: false
        },
        "Problem_Date__c": {
          name: 'Problem_Date__c',
          type: 'Date'
        },
        "Problem_Priority__c": {
          name: 'Problem_Priority__c',
          type: 'string'
        },
        "Problem_Suggestion__c": {
          name: 'Problem_Suggestion__c',
          type: 'string'
        },
        "Requested_By__c": {
          name: 'Requested_By__c',
          type: 'string'
        },
        "Task_Description__c": {
          name: 'Task_Description__c',
          type: 'string'
        },
        "Task_Summary__c": {
          name: 'Task_Summary__c',
          type: 'string'
        },
        "Request_Date__c": {
          name: 'Request_Date__c',
          type: 'Date'
        },
        "Request_Form_Receipt__c": {
          name: 'Request_Form_Receipt__c',
          type: 'string'
        },
        "Work_Order__c": {
          name: 'Work_Order__c',
          type: 'string'
        },
        "PMS_Case__c": {
          name: 'PMS_Case__c',
          type: 'string'
        },
        "Work_Order_Item__c": {
          name: 'Work_Order_Item__c',
          type: 'string'
        },
        "ICC_Case__c": {
          name: 'ICC_Case__c',
          type: 'string'
        },
        "Sales_Order__c": {
          name: 'Sales_Order__c',
          type: 'string'
        },
        "Sales_Invoice__c": {
          name: 'Sales_Invoice__c',
          type: 'string'
        },
        "Purchase_Order__c": {
          name: 'Purchase_Order__c',
          type: 'string'
        },
        "Purchase_Invoice__c": {
          name: 'Purchase_Invoice__c',
          type: 'string'
        },
        "Payment__c": {
          name: 'Payment__c',
          type: 'string'
        },
        "Processed__c": {
          name: 'Processed__c',
          type: 'boolean',
          default: false
        },
        "Escalation_Task__c": {
          name: 'Escalation_Task__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        Contact: {
          name: 'Contact',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Requested_By__c',
          keyTo: 'sfdcId'
        },
        RequestFormReceipt: {
          name: 'RequestFormReceipt',
          type: 'RequestFormReceipt',
          model: 'RequestFormReceipt',
          relationType: 'belongsTo',
                  keyFrom: 'Request_Form_Receipt__c',
          keyTo: 'sfdcId'
        },
        woTask: {
          name: 'woTask',
          type: 'Task',
          model: 'Task',
          relationType: 'belongsTo',
                  keyFrom: 'Work_Order__c',
          keyTo: 'Work_Order_Number__c'
        },
        soTask: {
          name: 'soTask',
          type: 'Task',
          model: 'Task',
          relationType: 'belongsTo',
                  keyFrom: 'Sales_Order__c',
          keyTo: 'Sales_Order__c'
        },
        poTask: {
          name: 'poTask',
          type: 'Task',
          model: 'Task',
          relationType: 'belongsTo',
                  keyFrom: 'Purchase_Order__c',
          keyTo: 'Purchase_Order__c'
        },
        pmsCaseTask: {
          name: 'pmsCaseTask',
          type: 'Task',
          model: 'Task',
          relationType: 'belongsTo',
                  keyFrom: 'PMS_Case__c',
          keyTo: 'PMS_Case_Number__c'
        },
        iccCaseTask: {
          name: 'iccCaseTask',
          type: 'Task',
          model: 'Task',
          relationType: 'belongsTo',
                  keyFrom: 'ICC_Case__c',
          keyTo: 'ICC_Case_Number__c'
        },
      }
    }
  }
}
