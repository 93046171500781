
import { Component, OnInit, Input } from '@angular/core';
import { OrderApi } from 'shared/sdk';
import { CommonService } from 'shared/services/common.service';
import { PreloaderService } from 'shared/services/preloader.service';
@Component({
  selector: 'app-sales-order',
  templateUrl: './sales-order.component.html',
  styleUrls: ['./sales-order.component.css']
})
export class SalesOrderComponent implements OnInit {
  salesOrderItems: any;
  salesLineItems: any;
  isOrderItemAvailable = false;
  showSaleListItems = false;

  @Input()
  set record(e) {
    if (e && e.job && e.job.Sales_Order__c) {
      this._preloaderService.showPreloader();
      this.loadData(e);
    } else {
      this.isOrderItemAvailable = false;
      this.salesOrderItems = null;
    }
  }
  constructor(private _salsOrderApi: OrderApi, private _commonService: CommonService, private _preloaderService: PreloaderService,) { }
  ngOnInit() {
  }
  openSaleListItem() {
    this.showSaleListItems = !this.showSaleListItems;
  }
  loadData(selectedItem) {

    let filterCondition = {};
    if (selectedItem.job) {
      // It's for Case Messaging Console(VMS/Job)
      filterCondition = { sfdcId: selectedItem.job.Sales_Order__c };
    }
    const obj = {
      where: filterCondition,
      skip: 0,
      limit: 1,
      fields: ['id', 'sfdcId', 'OrderNumber', 'Jobsite_Name__c', 'CreatedDate',
        'SKU__c', 'Order_Status__c', 'Roll_up_PPE_Hours__c', 'Roll_up_List_Price__c',
        'Roll_up_Unit_Price__c', 'Roll_up_Additional_Rate__c', 'VAT_Excluded__c', 'Grand_Total_Total_From_Line_Item__c',
        'Pricelist__c', 'AccountId', 'Project__c', 'Case__c'
      ],
      include: [
        {
          relation: 'orderItems',
          scope: {
            fields: ['UnitPrice', 'Quantity', 'Sub_Total_Auto__c', 'VAT_GST_Country__c', 'VAT_GST__c', 'Total_Price_Auto__c',
              'VAT_Tax__c'],
          }
        },
        {
          relation: 'account',
          scope: {
            fields: ['sfdcId', 'Name', 'Default_Pricelist__c', 'AccountId'],
            include: [{
              relation: 'pricelist',
              scope: {
                fields: ['sfdcId', 'Name', '']
              }
            }]
          }
        },
        {
          relation: 'project',
          scope: {
            fields: ['sfdcId', 'Name']
          }
        },
        {
          relation: 'product',
          scope: {
            fields: ['sfdcId', 'Name']
          }
        },
        {
          relation: 'case',
          scope: {
            fields: ['id', 'sfdcId', 'CaseNumber', 'Sales_Order_Reference__c', 'AccountId', 'Project_Vendor_Name__c', 'Project__c',
              'SKU__c']
          }
        },
      ],

    }
    this._salsOrderApi.find(obj).subscribe((result) => {
      if (result && result.length) {
        this.salesOrderItems = result[0];
        if (this.salesOrderItems && this.salesOrderItems.id) {
          this.getSalesOrderDetailsItems(this.salesOrderItems);
          this.formatSalesOrderitems();
          this._preloaderService.hidePreloader();
          this.isOrderItemAvailable = true;
        } else {
          this.isOrderItemAvailable = false;
        }
      } else {
        this.salesOrderItems = null;
        this._preloaderService.hidePreloader();
        this.isOrderItemAvailable = false;
      }
    },
      err => {
        console.log('Error fetching sales order', err.message);
        this._preloaderService.hidePreloader();
      });
  }

  getSalesOrderDetailsItems(lineItems) {
    if (lineItems.orderItems && lineItems.orderItems.length) {
      this.salesLineItems = lineItems.orderItems[0]
    } else {
      this.salesLineItems = null;
    }
  }

  formatSalesOrderitems() {
    if (this.salesOrderItems) {
      if (this.salesOrderItems.CreatedDate) {
        this.salesOrderItems.CreatedDate = this._commonService.dateFormate(this.salesOrderItems.CreatedDate, '', 'MMM D, YYYY');
      }
    }
    if (this.salesOrderItems.VAT_Excluded__c) {
      this.salesOrderItems.VAT_Excluded__c = 'Yes'
    } else {
      this.salesOrderItems.VAT_Excluded__c = 'No'
    }
  }
}
