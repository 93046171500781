/* tslint:disable */

declare var Object: any;
export interface MonthlyPaymentInterface {
  "sfdcId"?: string;
  "CreatedById"?: string;
  "CurrencyIsoCode"?: string;
  "LastModifiedById"?: string;
  "Name"?: string;
  "Approval_Levels_Required__c"?: string;
  "Approver__c"?: string;
  "BillReceipt_Line_Item_Ref__c"?: string;
  "Current_Approval_Level__c"?: string;
  "Date_of_Exchange_Rate__c"?: Date;
  "Days_Payment_Outstanding__c"?: number;
  "Iron_Legal_Entity_Country__c"?: string;
  "Iron_Legal_Entity_Country_Exchange_rate__c"?: number;
  "Iron_Legal_Entity_Currency__c"?: string;
  "Iron_Legal_Entity_Payment_Amount__c"?: number;
  "Amount__c"?: number;
  "Logged_in_Contact__c"?: string;
  "Method_of_Payment__c"?: string;
  "Monthly_Invoice__c"?: string;
  "Monthly_Invoice_has_BillReceipt_Ref__c"?: boolean;
  "Payment_Completed_Date__c"?: Date;
  "Payment_Date__c"?: Date;
  "Payment_Status__c"?: string;
  "Payment_Submitted_Date__c"?: Date;
  "Promissory_Note_Number__c"?: string;
  "Remittance_Number__c"?: string;
  "Reviewer__c"?: string;
  "Source_of_Exchange_Rate__c"?: string;
  "Vendor_Purchase_Receipt_Number__c"?: string;
  "id"?: number;
}

export class MonthlyPayment implements MonthlyPaymentInterface {
  "sfdcId": string;
  "CreatedById": string;
  "CurrencyIsoCode": string;
  "LastModifiedById": string;
  "Name": string;
  "Approval_Levels_Required__c": string;
  "Approver__c": string;
  "BillReceipt_Line_Item_Ref__c": string;
  "Current_Approval_Level__c": string;
  "Date_of_Exchange_Rate__c": Date;
  "Days_Payment_Outstanding__c": number;
  "Iron_Legal_Entity_Country__c": string;
  "Iron_Legal_Entity_Country_Exchange_rate__c": number;
  "Iron_Legal_Entity_Currency__c": string;
  "Iron_Legal_Entity_Payment_Amount__c": number;
  "Amount__c": number;
  "Logged_in_Contact__c": string;
  "Method_of_Payment__c": string;
  "Monthly_Invoice__c": string;
  "Monthly_Invoice_has_BillReceipt_Ref__c": boolean;
  "Payment_Completed_Date__c": Date;
  "Payment_Date__c": Date;
  "Payment_Status__c": string;
  "Payment_Submitted_Date__c": Date;
  "Promissory_Note_Number__c": string;
  "Remittance_Number__c": string;
  "Reviewer__c": string;
  "Source_of_Exchange_Rate__c": string;
  "Vendor_Purchase_Receipt_Number__c": string;
  "id": number;
  constructor(data?: MonthlyPaymentInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `MonthlyPayment`.
   */
  public static getModelName() {
    return "MonthlyPayment";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of MonthlyPayment for dynamic purposes.
  **/
  public static factory(data: MonthlyPaymentInterface): MonthlyPayment{
    return new MonthlyPayment(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'MonthlyPayment',
      plural: 'MonthlyPayments',
      path: 'MonthlyPayments',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "Approval_Levels_Required__c": {
          name: 'Approval_Levels_Required__c',
          type: 'string'
        },
        "Approver__c": {
          name: 'Approver__c',
          type: 'string'
        },
        "BillReceipt_Line_Item_Ref__c": {
          name: 'BillReceipt_Line_Item_Ref__c',
          type: 'string'
        },
        "Current_Approval_Level__c": {
          name: 'Current_Approval_Level__c',
          type: 'string'
        },
        "Date_of_Exchange_Rate__c": {
          name: 'Date_of_Exchange_Rate__c',
          type: 'Date'
        },
        "Days_Payment_Outstanding__c": {
          name: 'Days_Payment_Outstanding__c',
          type: 'number'
        },
        "Iron_Legal_Entity_Country__c": {
          name: 'Iron_Legal_Entity_Country__c',
          type: 'string'
        },
        "Iron_Legal_Entity_Country_Exchange_rate__c": {
          name: 'Iron_Legal_Entity_Country_Exchange_rate__c',
          type: 'number'
        },
        "Iron_Legal_Entity_Currency__c": {
          name: 'Iron_Legal_Entity_Currency__c',
          type: 'string'
        },
        "Iron_Legal_Entity_Payment_Amount__c": {
          name: 'Iron_Legal_Entity_Payment_Amount__c',
          type: 'number'
        },
        "Amount__c": {
          name: 'Amount__c',
          type: 'number'
        },
        "Logged_in_Contact__c": {
          name: 'Logged_in_Contact__c',
          type: 'string'
        },
        "Method_of_Payment__c": {
          name: 'Method_of_Payment__c',
          type: 'string'
        },
        "Monthly_Invoice__c": {
          name: 'Monthly_Invoice__c',
          type: 'string'
        },
        "Monthly_Invoice_has_BillReceipt_Ref__c": {
          name: 'Monthly_Invoice_has_BillReceipt_Ref__c',
          type: 'boolean'
        },
        "Payment_Completed_Date__c": {
          name: 'Payment_Completed_Date__c',
          type: 'Date'
        },
        "Payment_Date__c": {
          name: 'Payment_Date__c',
          type: 'Date'
        },
        "Payment_Status__c": {
          name: 'Payment_Status__c',
          type: 'string'
        },
        "Payment_Submitted_Date__c": {
          name: 'Payment_Submitted_Date__c',
          type: 'Date'
        },
        "Promissory_Note_Number__c": {
          name: 'Promissory_Note_Number__c',
          type: 'string'
        },
        "Remittance_Number__c": {
          name: 'Remittance_Number__c',
          type: 'string'
        },
        "Reviewer__c": {
          name: 'Reviewer__c',
          type: 'string'
        },
        "Source_of_Exchange_Rate__c": {
          name: 'Source_of_Exchange_Rate__c',
          type: 'string'
        },
        "Vendor_Purchase_Receipt_Number__c": {
          name: 'Vendor_Purchase_Receipt_Number__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
