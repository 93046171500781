import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'sliceArray'
})
export class SliceArrayPipe implements PipeTransform {
    transform(array: any, ...args: any[]) {
        if((args[0] !== undefined || args[0] !== null) && (args[1] !== undefined || args[1] !== null))
            return array.slice(args[0], args[1]);
        else   
            return array;
    }
}