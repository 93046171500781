/* tslint:disable */
import {
  Skilling,
  TalentType
} from '../index';

declare var Object: any;
export interface TalentInformationInterface {
  "sfdcId"?: string;
  "Name"?: string;
  "CurrencyIsoCode"?: string;
  "SystemModstamp"?: Date;
  "Worker_Skilling_Profile__c"?: string;
  "Talent_Type__c"?: string;
  "Talent_Level__c"?: string;
  "Coverage_Hours__c"?: string;
  "SLA__c"?: string;
  "IsDeleted"?: boolean;
  "CreatedById"?: string;
  "CreatedDate"?: Date;
  "LastModifiedDate"?: Date;
  "LastModifiedById"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  skilling?: Skilling;
  talentType?: TalentType;
}

export class TalentInformation implements TalentInformationInterface {
  "sfdcId": string;
  "Name": string;
  "CurrencyIsoCode": string;
  "SystemModstamp": Date;
  "Worker_Skilling_Profile__c": string;
  "Talent_Type__c": string;
  "Talent_Level__c": string;
  "Coverage_Hours__c": string;
  "SLA__c": string;
  "IsDeleted": boolean;
  "CreatedById": string;
  "CreatedDate": Date;
  "LastModifiedDate": Date;
  "LastModifiedById": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  skilling: Skilling;
  talentType: TalentType;
  constructor(data?: TalentInformationInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `TalentInformation`.
   */
  public static getModelName() {
    return "TalentInformation";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of TalentInformation for dynamic purposes.
  **/
  public static factory(data: TalentInformationInterface): TalentInformation{
    return new TalentInformation(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'TalentInformation',
      plural: 'TalentInformations',
      path: 'TalentInformations',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "SystemModstamp": {
          name: 'SystemModstamp',
          type: 'Date'
        },
        "Worker_Skilling_Profile__c": {
          name: 'Worker_Skilling_Profile__c',
          type: 'string'
        },
        "Talent_Type__c": {
          name: 'Talent_Type__c',
          type: 'string'
        },
        "Talent_Level__c": {
          name: 'Talent_Level__c',
          type: 'string'
        },
        "Coverage_Hours__c": {
          name: 'Coverage_Hours__c',
          type: 'string'
        },
        "SLA__c": {
          name: 'SLA__c',
          type: 'string'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        skilling: {
          name: 'skilling',
          type: 'Skilling',
          model: 'Skilling',
          relationType: 'belongsTo',
                  keyFrom: 'Worker_Skilling_Profile__c',
          keyTo: 'sfdcId'
        },
        talentType: {
          name: 'talentType',
          type: 'TalentType',
          model: 'TalentType',
          relationType: 'belongsTo',
                  keyFrom: 'Talent_Type__c',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
