import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { GroupApi, Project2Api } from 'shared/sdk';
import { debounceTime, distinctUntilChanged, switchMap, filter } from 'rxjs/operators';

@Component({
  selector: 'app-project-category-lookup',
  templateUrl: './project-category-lookup.component.html',
  styleUrls: ['./project-category-lookup.component.css']
})
export class ProjectCategoryLookupComponent implements OnInit {

  @Input() set resetData(isClear) {
    // this.projectCategoryList = [];
    this.projectCategorySelected = [];
  }
  @Input() isMultipleSelected = true;
  @Input() from = '';
  @Output() getProjectCategoryObj: EventEmitter<any> = new EventEmitter;
  noProjectCategory = 'Searching...';
  projectCategoryTypeahead = new EventEmitter<string>();
  projectCategoryList = [];
  projectCategorySelected = [];
  enableTypeHead = true;

  constructor(
    private _cd: ChangeDetectorRef,
    private _project2Api: Project2Api,
  ) { }

  ngOnInit() {
    this.getProjectCategories();
    // to show selected Project Category
    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
    this.projectCategorySelected = (preselected && preselected['projectCategory']) ? preselected['projectCategory'] : '';
    if (this.from && this.from === 'globalChatReportAnalytics') {
      this.enableTypeHead = false;
      this.getProjectCategoriesListOnLoad();
    } else {
      this.getselectedProjectCategoryData();
    }
  }

  getselectedProjectCategoryData() {
    if (this.projectCategorySelected && this.projectCategorySelected.length && this.enableTypeHead) {
      this.getProjectCategoriesList({ sfdcId: { inq: this.projectCategorySelected } }).subscribe(
        x => {
          if (x && x.length) {
            this.projectCategoryList = x;
            if (this.projectCategoryList[0].ProjectName__c == 'Problem & Change Management') {
              this.projectCategoryList = this.projectCategoryList.filter(proj => proj.ProjectName__c && proj.Name && proj.sfdcId).map(project => {
                return { 'Category__c': 'Problem Management' }
              })
            } else {
              this.projectCategoryList = this.projectCategoryList.filter(proj => proj.Category__c && proj.Sub_Catergory__c && proj.Name && proj.sfdcId).map(project => {
                if (project.Category__c == 'Others') {
                  return { 'Category__c': project.Category__c + '-' + project.Sub_Catergory__c }
                }
                return { 'Category__c': project.Category__c }
              });
            }
          } else {
            this.projectCategoryList = [];
          }
          this.noProjectCategory = (this.projectCategoryList.length === 0) ? 'No Project category found.' : '';
        },
        err => {
          console.log(err);
          this.projectCategoryList = [];
          this.noProjectCategory = '';
        }
      );
    }
    this.getProjectCategoryObj.emit(this.projectCategorySelected);
  }

  // get partner's accounts list by name
  getProjectCategories() {
    if (this.enableTypeHead) {
      this.projectCategoryList = [];
      this.projectCategoryTypeahead
        .pipe(
          filter(term => term && term.trim().length > 2),
          distinctUntilChanged(),
          debounceTime(200),
          switchMap(term => {
            let data = term.split('-');
            let whereObj = {}
            if (data[0].trim().toLowerCase() == 'others') {
              const searchPattern = { like: '%' + data[0].trim() + '%', options: 'i' };
              whereObj = {
                'Category__c': searchPattern,
              };
              if (data.length == 2) {
                const searchPattern2 = { like: '%' + data[1].trim() + '%', options: 'i' };
                whereObj['Sub_Catergory__c'] = searchPattern2;
              }
            } else {
              const searchPattern = { like: '%' + data[0].trim() + '%', options: 'i' };
              whereObj = {
                "or": [{ Category__c: searchPattern }, { ProjectName__c: searchPattern }, { Sub_Catergory__c: searchPattern }]
              }
            }
            return this.getProjectCategoriesList(whereObj);
          })
        )
        .subscribe(
          x => {
            this._cd.markForCheck();
            if (x && x.length) {
              this.projectCategoryList = x;
              if (this.projectCategoryList[0].ProjectName__c == 'Problem & Change Management') {
                // this.projectCategoryList = this.projectCategoryList.filter(proj =>proj.ProjectName__c && proj.Name && proj.sfdcId).map(project => {
                //   return {'Category__c': 'Problem Management'}
                // })
                this.projectCategoryList = [{ 'Category__c': 'Problem Management' }]
              } else if (this.projectCategoryList[0].Category__c == 'Others') {
                let projectSubCategoryList = [];
                projectSubCategoryList.push({ 'Category__c': this.projectCategoryList[0].Category__c + '-' + this.projectCategoryList[0].Sub_Catergory__c });
                for (let i = 1; i < this.projectCategoryList.length - 2; i++) {
                  if (this.projectCategoryList[i + 1]['Sub_Catergory__c'] != this.projectCategoryList[i]['Sub_Catergory__c'] && this.projectCategoryList[i + 1]['Sub_Catergory__c']) {
                    projectSubCategoryList.push({ 'Category__c': this.projectCategoryList[i + 1].Category__c + '-' + this.projectCategoryList[i + 1].Sub_Catergory__c });
                  }
                }
                this.projectCategoryList = projectSubCategoryList;
              } else {
                this.projectCategoryList = [{ 'Category__c': this.projectCategoryList[0].Category__c }];
              }
            } else {
              this.projectCategoryList = [];
            }

            this.noProjectCategory = (this.projectCategoryList.length === 0) ? 'No Project category found.' : '';
          },
          err => {
            console.log(err);
            this.projectCategoryList = [];
            this.noProjectCategory = '';
          }
        );
    }
  }

  getProjectCategoriesListOnLoad() {
    this.projectCategoryList = [];
    const whereObj = {
      and: [
        { Category__c: { neq: null } }, { Category__c: { neq: '' } },
        { Sub_Catergory__c: { neq: null } }, { Sub_Catergory__c: { neq: '' } }
      ]
    }
    this.getProjectCategoriesList(whereObj).subscribe(
      x => {
        this._cd.markForCheck();
        if (x && x.length) {
          let projectCategoryList = [];
          x.forEach((element, index) => {
            if (element['ProjectName__c'] == 'Problem & Change Management') {
              // this.projectCategoryList = this.projectCategoryList.filter(proj =>proj.ProjectName__c && proj.Name && proj.sfdcId).map(project => {
              //   return {'Category__c': 'Problem Management'}
              // })
              if (!projectCategoryList) {
                projectCategoryList.push({ 'Category__c': 'Problem Management' });
              } else if (!this.isUnique(projectCategoryList, 'Problem Management')) {
                projectCategoryList.push({ 'Category__c': 'Problem Management' });
              }
            } else if (element['Category__c'] == 'Others') {
              if (!projectCategoryList.length) {
                projectCategoryList.push({ 'Category__c': element['Category__c'] + '-' + element['Sub_Catergory__c'] });
              } else if (!this.isUnique(projectCategoryList, element['Category__c'] + '-' + element['Sub_Catergory__c'])) {
                projectCategoryList.push({ 'Category__c': element['Category__c'] + '-' + element['Sub_Catergory__c'] });
              }
            } else {
              if (!projectCategoryList) {
                projectCategoryList.push({ 'Category__c': element['Category__c'] });
              } else if (!this.isUnique(projectCategoryList, element['Category__c'])) {
                projectCategoryList.push({ 'Category__c': element['Category__c'] });
              }
            }
          });
          if (!this.isUnique(projectCategoryList, 'Problem Management')) {
            projectCategoryList.push({ 'Category__c': 'Problem Management' });
          }
          this.projectCategoryList = [...this.projectCategoryList, ...projectCategoryList]

        } else {
          this.projectCategoryList = [];
        }
        console.log(this.projectCategoryList)
        this.noProjectCategory = (this.projectCategoryList.length === 0) ? 'No Project category found.' : '';
      },
      err => {
        console.log(err);
        this.projectCategoryList = [];
        this.noProjectCategory = '';
      }
    );
  }

  onProjectCategoryChanged() {
    this.getProjectCategoryObj.emit(this.projectCategorySelected);
  }

  isUnique(array, key) {
    return array.some(function (value) {
      return value.Category__c === key
    })
  }

  clearProjectCategories() {
    this.projectCategorySelected = [];
    this.getProjectCategoryObj.emit([]);
  }

  /**
   * 
   * @param whereObj 
   */

  getProjectCategoriesList(whereObj) {
    return this._project2Api.find({
      where: whereObj,
      fields: ['Category__c', 'Sub_Catergory__c', 'Name', 'id', 'sfdcId', 'ProjectName__c'],
      order: 'Sub_Catergory__c',
    }).map(res => res);
  }

}
