import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PmsGlobalFiltersComponent } from './pms-global-filters.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { LookupModule } from '../lookup/lookup.module';

@NgModule({
  imports: [
    CommonModule,
    NgxDatatableModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule.forRoot(),
    RouterModule,
    NgSelectModule,
    LookupModule
  ],
  exports: [PmsGlobalFiltersComponent],
  declarations: [PmsGlobalFiltersComponent]
})
export class PmsGlobalFiltersModule { }
