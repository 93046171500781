/* tslint:disable */

declare var Object: any;
export interface LocationJobRecruitmentInterface {
  "DisplayName"?: string;
  "Value"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class LocationJobRecruitment implements LocationJobRecruitmentInterface {
  "DisplayName": string;
  "Value": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: LocationJobRecruitmentInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `LocationJobRecruitment`.
   */
  public static getModelName() {
    return "LocationJobRecruitment";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of LocationJobRecruitment for dynamic purposes.
  **/
  public static factory(data: LocationJobRecruitmentInterface): LocationJobRecruitment{
    return new LocationJobRecruitment(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'LocationJobRecruitment',
      plural: 'LocationJobRecruitments',
      path: 'LocationJobRecruitments',
      idName: 'id',
      properties: {
        "DisplayName": {
          name: 'DisplayName',
          type: 'string'
        },
        "Value": {
          name: 'Value',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
