/* tslint:disable */
import {
  Location,
  ReturnOrder,
  ReturnOrderLineItem,
  ProductRequest,
  ProductRequestLineItem,
  Product
} from '../index';

declare var Object: any;
export interface ProductTransferInterface {
  "sfdcId"?: string;
  "CreatedById"?: string;
  "CreatedDate"?: Date;
  "CurrencyIsoCode"?: string;
  "Description"?: string;
  "DestinationLocationId"?: string;
  "ExpectedPickupDate"?: Date;
  "IsDeleted"?: boolean;
  "IsReceived"?: boolean;
  "LastModifiedById"?: string;
  "LastModifiedDate"?: Date;
  "OwnerId"?: string;
  "Product2Id"?: string;
  "ProductRequestId"?: string;
  "ProductRequestLineItemId"?: string;
  "ProductTransferNumber"?: string;
  "QuantityReceived"?: number;
  "QuantitySent"?: number;
  "QuantityUnitOfMeasure"?: string;
  "ReceivedById"?: string;
  "Recieved_By__c"?: string;
  "ReturnOrderId"?: string;
  "ReturnOrderLineItemId"?: string;
  "ShipmentExpectedDeliveryDate"?: Date;
  "ShipmentId"?: string;
  "ShipmentStatus"?: string;
  "ShipmentTrackingNumber"?: string;
  "ShipmentTrackingUrl"?: string;
  "SourceLocationId"?: string;
  "SourceProductItemId"?: string;
  "ShipToLatitude"?: string;
  "Status"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  dlocation?: Location;
  slocation?: Location;
  returnOrder?: ReturnOrder;
  returnOrderLineItem?: ReturnOrderLineItem;
  productRequest?: ProductRequest;
  productRequestLineItem?: ProductRequestLineItem;
  product?: Product;
}

export class ProductTransfer implements ProductTransferInterface {
  "sfdcId": string;
  "CreatedById": string;
  "CreatedDate": Date;
  "CurrencyIsoCode": string;
  "Description": string;
  "DestinationLocationId": string;
  "ExpectedPickupDate": Date;
  "IsDeleted": boolean;
  "IsReceived": boolean;
  "LastModifiedById": string;
  "LastModifiedDate": Date;
  "OwnerId": string;
  "Product2Id": string;
  "ProductRequestId": string;
  "ProductRequestLineItemId": string;
  "ProductTransferNumber": string;
  "QuantityReceived": number;
  "QuantitySent": number;
  "QuantityUnitOfMeasure": string;
  "ReceivedById": string;
  "Recieved_By__c": string;
  "ReturnOrderId": string;
  "ReturnOrderLineItemId": string;
  "ShipmentExpectedDeliveryDate": Date;
  "ShipmentId": string;
  "ShipmentStatus": string;
  "ShipmentTrackingNumber": string;
  "ShipmentTrackingUrl": string;
  "SourceLocationId": string;
  "SourceProductItemId": string;
  "ShipToLatitude": string;
  "Status": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  dlocation: Location;
  slocation: Location;
  returnOrder: ReturnOrder;
  returnOrderLineItem: ReturnOrderLineItem;
  productRequest: ProductRequest;
  productRequestLineItem: ProductRequestLineItem;
  product: Product;
  constructor(data?: ProductTransferInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ProductTransfer`.
   */
  public static getModelName() {
    return "ProductTransfer";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ProductTransfer for dynamic purposes.
  **/
  public static factory(data: ProductTransferInterface): ProductTransfer{
    return new ProductTransfer(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ProductTransfer',
      plural: 'ProductTransfers',
      path: 'ProductTransfers',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "Description": {
          name: 'Description',
          type: 'string'
        },
        "DestinationLocationId": {
          name: 'DestinationLocationId',
          type: 'string'
        },
        "ExpectedPickupDate": {
          name: 'ExpectedPickupDate',
          type: 'Date'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "IsReceived": {
          name: 'IsReceived',
          type: 'boolean'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "Product2Id": {
          name: 'Product2Id',
          type: 'string'
        },
        "ProductRequestId": {
          name: 'ProductRequestId',
          type: 'string'
        },
        "ProductRequestLineItemId": {
          name: 'ProductRequestLineItemId',
          type: 'string'
        },
        "ProductTransferNumber": {
          name: 'ProductTransferNumber',
          type: 'string'
        },
        "QuantityReceived": {
          name: 'QuantityReceived',
          type: 'number'
        },
        "QuantitySent": {
          name: 'QuantitySent',
          type: 'number'
        },
        "QuantityUnitOfMeasure": {
          name: 'QuantityUnitOfMeasure',
          type: 'string'
        },
        "ReceivedById": {
          name: 'ReceivedById',
          type: 'string'
        },
        "Recieved_By__c": {
          name: 'Recieved_By__c',
          type: 'string'
        },
        "ReturnOrderId": {
          name: 'ReturnOrderId',
          type: 'string'
        },
        "ReturnOrderLineItemId": {
          name: 'ReturnOrderLineItemId',
          type: 'string'
        },
        "ShipmentExpectedDeliveryDate": {
          name: 'ShipmentExpectedDeliveryDate',
          type: 'Date'
        },
        "ShipmentId": {
          name: 'ShipmentId',
          type: 'string'
        },
        "ShipmentStatus": {
          name: 'ShipmentStatus',
          type: 'string'
        },
        "ShipmentTrackingNumber": {
          name: 'ShipmentTrackingNumber',
          type: 'string'
        },
        "ShipmentTrackingUrl": {
          name: 'ShipmentTrackingUrl',
          type: 'string'
        },
        "SourceLocationId": {
          name: 'SourceLocationId',
          type: 'string'
        },
        "SourceProductItemId": {
          name: 'SourceProductItemId',
          type: 'string'
        },
        "ShipToLatitude": {
          name: 'ShipToLatitude',
          type: 'string'
        },
        "Status": {
          name: 'Status',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        dlocation: {
          name: 'dlocation',
          type: 'Location',
          model: 'Location',
          relationType: 'belongsTo',
                  keyFrom: 'DestinationLocationId',
          keyTo: 'sfdcId'
        },
        slocation: {
          name: 'slocation',
          type: 'Location',
          model: 'Location',
          relationType: 'belongsTo',
                  keyFrom: 'SourceLocationId',
          keyTo: 'sfdcId'
        },
        returnOrder: {
          name: 'returnOrder',
          type: 'ReturnOrder',
          model: 'ReturnOrder',
          relationType: 'belongsTo',
                  keyFrom: 'ReturnOrderId',
          keyTo: 'sfdcId'
        },
        returnOrderLineItem: {
          name: 'returnOrderLineItem',
          type: 'ReturnOrderLineItem',
          model: 'ReturnOrderLineItem',
          relationType: 'belongsTo',
                  keyFrom: 'ReturnOrderLineItemId',
          keyTo: 'sfdcId'
        },
        productRequest: {
          name: 'productRequest',
          type: 'ProductRequest',
          model: 'ProductRequest',
          relationType: 'belongsTo',
                  keyFrom: 'ProductRequestId',
          keyTo: 'sfdcId'
        },
        productRequestLineItem: {
          name: 'productRequestLineItem',
          type: 'ProductRequestLineItem',
          model: 'ProductRequestLineItem',
          relationType: 'belongsTo',
                  keyFrom: 'ProductRequestLineItemId',
          keyTo: 'sfdcId'
        },
        product: {
          name: 'product',
          type: 'Product',
          model: 'Product',
          relationType: 'belongsTo',
                  keyFrom: 'Product2Id',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
