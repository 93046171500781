import { BehaviorSubject, Observable } from 'rxjs';

export class AppStateService {

    private isAuthenticated = new BehaviorSubject<boolean>(false);
    private navAsVendor = new BehaviorSubject<boolean>(null);
    private navAsPartner = new BehaviorSubject<object>(null);
    private appState = new BehaviorSubject<object>(null);
    private jobDetailUserRole = new BehaviorSubject<object>(null);
    private userSelectedAccount = new BehaviorSubject<object>(null);
    private internalUserSelectedAccount = new BehaviorSubject<string>('');
    private showPaySlipTab = new BehaviorSubject<boolean>(false);
    private isViewAsVendor = new BehaviorSubject<boolean>(false);
    private viewAsVendorObject = new BehaviorSubject<any>(null);
    private globalFilterBackUpObjectCreated = new BehaviorSubject<boolean>(false);

    private selectedAccount = '';
    private accessType = '';
    private homeUrl = '';
    private adminAccessPermission = undefined;
    private userTypeSlug = '';
    private workerType = '';
    private randomNumber = Math.floor(Math.random() * 1000000);
    private pageViewMapData = [];
    private allowedFilePermission = [];
    private manageUser = false;
    /* getter/setter for the adminAccessPermission */
    setAdminAccessPermission(adminAccessPermission: boolean) {
        this.adminAccessPermission = adminAccessPermission;
    }
    getAdminAccessPermission() {
        return this.adminAccessPermission;
    }

    /* getter/setter for the selected accountId */
    setSelectedAccount(accountId: string) {
        this.selectedAccount = accountId;
    }
    getSelectedAccount() {
        return this.selectedAccount;
    }

    /* getter/setter for the user access type */
    setAccessType(accessType: string) {
        this.accessType = accessType;
    }
    getAccessType() {
        return this.accessType;
    }

    /* getter/setter for the user access type */
    setWorkerType(workerType: string) {
        this.workerType = workerType;
    }
    getWorkerType() {
        return this.workerType;
    }

    /* getter/setter to redirect to user's landing page url */
    setHomeUrl(homeUrl: string) {
        this.homeUrl = homeUrl;
    }
    getHomeUrl() {
        return this.homeUrl;
    }

    setAuthState(isLoggedIn: boolean) {
        this.isAuthenticated.next(isLoggedIn);
    }
    getAuthState() {
        return this.isAuthenticated.asObservable();
    }

    setNavAsVendor(account: any) {
        this.navAsVendor.next(account);
    }
    getNavAsVendor() {
        return this.navAsVendor;
    }

    setNavAsPartner(account: any) {
        this.navAsPartner.next(account);
    }
    getNavAsPartner() {
        return this.navAsPartner;
    }

    setAppState(appState: any) {
        this.appState.next(appState);
    }
    getAppState() {
        return this.appState;
    }

    setJobDetailUserRole(jobDetailUserRole: any) {
        this.jobDetailUserRole.next(jobDetailUserRole);
    }
    getJobDetailUserRole() {
        return this.jobDetailUserRole;
    }

    /* getter/setter for the user user type slug*/
    setUserTypeSlug(userTypeSlug: string) {
        this.userTypeSlug = userTypeSlug;
    }
    getUserTypeSlug() {
        return this.userTypeSlug;
    }

    setUserSelectedAccount(userSelectedAccount: any) {
        this.userSelectedAccount.next(userSelectedAccount);
    }
    getUserSelectedAccount() {
        return this.userSelectedAccount;
    }
    setInternalUserSelectedAccount(userSelectedAccount: any) {
        this.internalUserSelectedAccount.next(userSelectedAccount);
    }
    getInternalUserSelectedAccount() {
        return this.internalUserSelectedAccount;
    }
    getTabUniqueId() {
        return 'v1-00120' + this.randomNumber;
    }
    setCookie(name, value, sec = 10) {
        const date = new Date();
        date.setTime(date.getTime() + (sec * 1000));
        const expires = "; expires=" + date.toUTCString();
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }

    getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }
    setPageViewMapData(data) {
        this.pageViewMapData = data;
    }
    getPageViewMapData() {
        return this.pageViewMapData || [];
    }

    /**
     * 
     * @param data 
     * the method will set allowed permission of file
     * to current user
     * i.e read|delete|upload
     */
    setFileManagerPermissions(data: any) {
        this.allowedFilePermission = data;
    }
    /**
     * return allowed permission to current user
     */
    getFileManagerPermissions() {
        return this.allowedFilePermission;
    }

    /**
         * 
         * @param data 
         * the method will set allowed permission of file
         * to current user
         * i.e read|delete|upload
         */
    setmanageUser(data: any) {
        this.manageUser = data || false;
    }
    /**
     * return allowed permission to current user
     */
    getmanageUser() {
        return this.manageUser;
    }

    setShowPaySlipTabState(state: boolean) {
        this.showPaySlipTab.next(state);
    }
    getShowPaySlipTabState() {
        return this.showPaySlipTab;
    }

    getIsVendorViewState() {
        return this.isViewAsVendor;
    }

    setViewAsVendorObject() {
        const viewAsInfo = JSON.parse(localStorage.getItem('viewAsInfo'));
        const isViewAsVendor = (viewAsInfo && viewAsInfo.viewAsType === 'Vendor');
        if(isViewAsVendor) {
            let vendorAccessTypes = {
                accessType: viewAsInfo.accessType,
                userTypeSlug: viewAsInfo.userTypeSlug,
                isMagicLink: viewAsInfo.isMagicLink,
                isNgo: viewAsInfo.isNgo,
                isTest: viewAsInfo.isTest,
                userSfdcId: viewAsInfo.userSfdcId,
                workerType: viewAsInfo.workerType,
                accountId: viewAsInfo.accountId,
                workerSfdcId: viewAsInfo.workerSfdcId,
                firstName: viewAsInfo.firstName,
                lastName: viewAsInfo.lastName,
                userObj: viewAsInfo.userObj
            }
            this.isViewAsVendor.next(true);
            this.viewAsVendorObject.next(vendorAccessTypes);
        } else {
            this.isViewAsVendor.next(false);
            this.viewAsVendorObject.next(null);
        }
    }

    getViewAsVendorObject() {
        return this.viewAsVendorObject;
    }

    /* getter/setter for the globalFilterBackUpObject */
    setGlobalFilterBackUpObjectCreated(state: boolean) {
        this.globalFilterBackUpObjectCreated.next(state);
    }
    getGlobalFilterBackUpObjectCreated() {
        return this.globalFilterBackUpObjectCreated;
    }

    /**get user type id */
    getUserTypeId(){
        const appData = JSON.parse(localStorage.getItem('appData'));
        let userTypeId = (appData && appData.user && appData.user.userTypeId) ? appData.user.userTypeId : '';
        return userTypeId;
    }
}
