import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FleetManagementFeedListItemComponent } from './fleet-management-feed-list-item.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    FleetManagementFeedListItemComponent
  ],
  exports: [
    FleetManagementFeedListItemComponent
  ]
})
export class FleetManagementFeedListItemModule { }
