import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FilesListComponent } from "./files-list.component";
import { PipeModule } from "shared/pipe/pipe.module";
import { FleetManagementFeedListItemModule } from "../fleet-management-feed-list-item/fleet-management-feed-list-item.module";

@NgModule({
    imports: [
        CommonModule,
        PipeModule,
        FleetManagementFeedListItemModule
    ],
    declarations: [
        FilesListComponent
    ],
    exports: [
        FilesListComponent
    ]
})

export class FilesListModule { }