import { Component, OnInit, Input, ElementRef, EventEmitter, Output, OnDestroy, ViewChild } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import { CommonService } from 'shared/services/common.service';
import { ModalService } from 'shared/services/modal.service';
import { PreloaderService } from 'shared/services/preloader.service';
import { DocumentApi } from './../../../../../sdk';
import { AppStateService } from 'shared/services/app-state.service';
import { AlertService } from 'shared/services/alert.service';
import { Subscription } from 'rxjs';
import { GlobalFilterService } from 'shared/services/global-filter.service';
import { fileTypesOpenInBrowser } from 'shared/models/static-list-data.service';
import { UtilityService } from 'shared/services/utility.service';
import { DatePipe } from '@angular/common';

interface CurrentPage {
    count?: number,
    pageSize?: number,
    limit: number,
    offset: number,
}

@Component({
    selector: 'app-file-list-view',
    templateUrl: 'file-list-view.component.html',
    styleUrls: ['file-list-view.component.css']
})

export class FileListViewComponent implements OnInit, OnDestroy {
    @ViewChild('myTable') table: any;
    @Output() loadMorePage: EventEmitter<any> = new EventEmitter;
    @Output() deleteDoc: EventEmitter<any> = new EventEmitter;
    @Output() assginJob: EventEmitter<string> = new EventEmitter;
    @Output() refreshDataTable: EventEmitter<any> = new EventEmitter;
    isMagicLink: any;
    // _modelService: ModalService;
    @Input() set dataConfig(e) {
        if (e && e['data']) {
            this.filteredItems = e['data'].slice();

            if (Object.keys(this.localFilters).length > 0 && this.localFilters.constructor === Object) {
                this.tableData = this.filteredItems.filter(item => {
                    const notMatchingField = Object.keys(this.localFilters).find(key =>
                        this._utilityService.dataTableSearch(item, this.localFilters, key));
                    return !notMatchingField;
                });
            } else {
                this.tableData = e['data'];
            }
            // this.tableData = e['data'];
            this.frozenLeft = (e && e['_frozenLeft'] && (!this.tableData || !this.tableData[0] || !this.tableData[0]['message'])) ? e['_frozenLeft'] : false;
            this.selected = [];
            this.allSelected = false;

            if (!this.frozenLeft && this.tableData && !this.tableData[0]['message']) {
                if (this.tableData && this.tableData.length) {
                    const _that = this;
                    setTimeout(function () { _that.initScroll(); }, 1000);
                }
            }
            this.noRecords = (e['_noRecords']) ? e['_noRecords'] : false;
            const filePermissions = this._appState.getFileManagerPermissions();
            this.deleteOption = filePermissions['delete'] && filePermissions['delete']['allowedSubCategoryList'] || '';
            this.tableData.forEach(element => {
                element['isDeleteAllowed'] = (this.deleteOption && this.deleteOption.indexOf(element.subCategoryId)!==-1) ? true : false; 
            });
        } else {
            this.tableData = [];
        }
        this.loadingIndicator = false;
    }
    @Input() set defaultConfig(e) {
        if (e) {
            this.columns = e['_columns'];
            this.itemsPerPage = e['_itemsPerPage'];
            this.isDocumentDelete = (e['_deleteDoc']) ? true : false;
            if (this.tableData && this.tableData.length) {
                if (this.tableData[0]['message']) {
                    this.columns[1]['width'] = 160;
                } else {
                    this.columns[1]['width'] = 75;
                }
            }
        }
    }
    // type of multidownload type - 1 for zip and 0 for seperate download.
    @Input() multiDownloadType = 1;
    itemsPerBatch = 200;
    @Input() frozenLeft = false;
    @Input() isDocumentAssignable = false;
    itemsPerPage = 25;
    columns: any;
    tableData: any = [];
    containerPath: any;
    noRecords = false;
    isDocumentDelete = false;
    timeZone: any;
    loadingIndicator = true;
    selectedJobIdForAssign: string = '';
    selectedDocumentIds: string[] = [];
    isAssignEnabled: boolean = false;
    selected = [];
    currentDocumentPage: CurrentPage;
    allSelected = false;
    sidebarStateSub: Subscription;
    deleteOption:any;
    docModelName='Program';
    localFilters: any = {};
    filteredItems: any = [];
    datePipe: DatePipe;
    viewAsVendor = false;
    accessType
    constructor(
        private _el: ElementRef,
        private _commonService: CommonService,
        private _modalService: ModalService,
        private _documentApi: DocumentApi,
        private _preloaderService: PreloaderService,
        private _appState: AppStateService,
        private _alertService: AlertService,
        private _globalFilterService: GlobalFilterService,
        private _utilityService: UtilityService,
    ) { }

    ngOnInit() {
        this._commonService.saveCurrentPageAnalytics('File Manager', 'File Manager - List View');
        this.containerPath = environment.baseUrl + '/' + environment.apiVersion + '/Containers/';
         this.accessType = this._appState.getAccessType();
        const appData = JSON.parse(localStorage.getItem('appData'));
        this.isMagicLink = appData.user && appData.user.isMagiclinkTest ? appData.user && appData.user.isMagiclinkTest:null;
        // To check if view as PMS mode is activated.
        if (localStorage.getItem('viewAsInfo')) {
            const viewAsInfo = JSON.parse(localStorage.getItem('viewAsInfo'));
            const viewAsPartner = (viewAsInfo && viewAsInfo.viewAsType === 'Partner');
            this.viewAsVendor = (viewAsInfo && viewAsInfo.viewAsType === 'Vendor') ? true : false ;
            this.accessType = (viewAsPartner) ? 'partner' : this.viewAsVendor ? 'Vendor' : this.accessType;
        }
        this.isAssignEnabled = (this.accessType === 'internal');
        this.currentDocumentPage = { count: 0, pageSize: this.itemsPerPage, limit: this.itemsPerPage, offset: 0 };
        this.sidebarStateSub = this._globalFilterService.getSidebarState().subscribe(isOpened => {
            setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
            }, 250);
        });
    }

    /**
     * Method use to download or view a particular row item or the selected one.
     * @param {json | null} row - if null we call this method for the selected items. 
     * Otherwise for the particular row item
     */
    downloadViewDocuments(row: {} | null) {
        const items = row ? [row] : this.selected;

        const isSingleFile = items.length === 1 ? true : false;
        let files = [];

        for (let file of items) {
            files.push({
                path: this.containerPath + (file.fileMeta.path),
                name: file.fileMeta.name
            });
        }

        // check the multiDownloadType if 1 then download as zip otherwise seperate file
        if (this.multiDownloadType === 1 && !isSingleFile) {
            this._commonService.downloadFilesZip(files);
        } else {
            this._commonService.downloadMultiFiles(files);
        }
    }

    /**
     * Method to download the document.
     * @param doc - the document object that to be download
     * @param type - type to handle the case of open the document in browser new tab.
     * The argument @param type is by default 0, represent download by defalut. 
     */
    downloadDocument = (doc, type = 0) => {
        let fileTypesOpenInBrowserList = fileTypesOpenInBrowser;
        if (type != 0) {
            // doc.fileType
            if (doc && doc.fileMeta && doc.fileMeta.extension in fileTypesOpenInBrowserList) {
                if (doc.shortUrl && doc.shortUrl) {
                    window.open(doc.shortUrl, '_blank');
                    return;
                }
            }
        }
        if (doc) {
            const item = doc;
            let files = [];
            files.push({
                path: this.containerPath + (item.fileMeta.path),
                name: item.fileMeta.name
            });
            this._commonService.downloadMultiFiles(files);
        }
    }

    /**
     * Method to check whether to disable multiselect option or not
     */
    isMultiselectOptionsDisabled() {
        return !(this.selected && this.selected.length > 0);
    }

    topScroll() {
        this.initScroll();
        if (this.tableData && this.tableData.length) {
            setTimeout(function () { this.initScroll(); }, 1000);
        }
    }

    /**
    * to scroll page properly
    */
    initScroll() {
        let elHeader = this._el.nativeElement.querySelector('.datatable-header')
        let elBody = this._el.nativeElement.querySelector('datatable-body');
        elHeader.onscroll = () => {
            elBody.scrollLeft = elHeader.scrollLeft
        }
        elBody.onscroll = () => {
            elHeader.scrollLeft = elBody.scrollLeft
        }
    }

    loadMoreRecords() {
        this.loadMorePage.emit();
    }

    /**
     * Method to detect if an element exist on target array or not, if not then push it on the array.
     * @param targetArray 
     * @param element 
     * @param comparer 
     */
    elementPushIfNotExist(targetArray, element, comparer) {
        let statusExist = false;
        for (var i = 0; i < targetArray.length; i++) {
            if (comparer(targetArray[i], element)) {
                targetArray.splice(i, 1);
                statusExist = true
                return;
            };
        }
        if (!statusExist) {
            targetArray.push(element);
        }
    }

    /**
     * Method to handle the row item select 
     * @param data 
     */
    onRowCheckboxChangeFn(data) {
        // check if the clicked row item is exist or not in the selected one, if not then push otherwise remove
        this.elementPushIfNotExist(this.selected, data, function (e, data) {
            return e.id === data.id;
        });

        // check if all the documents of the page is selected then toggle the allSelected button
        const startIndex = (this.currentDocumentPage.limit * this.currentDocumentPage.offset);
        let endIndex = this.currentDocumentPage.limit * (this.currentDocumentPage.offset + 1);
        endIndex = endIndex > this.tableData.length ? this.tableData.length : endIndex;

        this.allSelected = this.selected.length === (endIndex - startIndex) ? true : false;
    }

    /**
     * Method to handle the change event of the header checkbox.
     * @param data 
     */
    selectAllCurrentPageRows() {
        // toggle the allSelected button
        this.allSelected = !this.allSelected;

        // slice the documents from the list
        if (this.allSelected) {
            // find the start and the end indexes of the documents on the page
            const startIndex = (this.currentDocumentPage.limit * this.currentDocumentPage.offset);
            let endIndex = this.currentDocumentPage.limit * (this.currentDocumentPage.offset + 1);
            endIndex = endIndex > this.tableData.length ? this.tableData.length : endIndex;

            this.selected = this.tableData.slice(startIndex, endIndex);
        } else {
            this.selected = [];
        }
    }

    /**
     * Method to handle the current page of the documents
     * @param {CurrentPage} data 
     */
    setCurrentPage(data) {
        this.selected = [];
        this.allSelected = false;

        this.currentDocumentPage = data;
    }

    /**
     * Method use to delete a particular row item or the selected one.
     * @param {json | null} row - if null we call this method for the selected items. 
     * Otherwise for the particular row item
     */
    deleteDocuments(row: {} | null) {
        let items = row ? [row] : this.selected;
        // console.log(items)
        const docIds = [];
        items = items.map(item => {
            // filter to delete only allowed cat/subcat id
            if(item.isDeleteAllowed){
                docIds.push(item.id)
            }
        });     
        if (docIds.length) {
           this.deleteDocument(docIds)
        }
    }

    /**
     * Method to delete the documents
     * @param docs - array of documents ids
     */
    deleteDocument(docs) {
        let length = docs.length;
        if (confirm(`Are you sure to delete ${length} ${length === 1 ? 'document' : 'documents'}?`)) {
            this._preloaderService.showPreloader();
            this._documentApi.deleteByIds({ ids: docs }).subscribe(
                res => {
                    if (res) {
                        this._alertService.clear();
                        this._alertService.success(
                            'Document(s) deleted successfully.'
                        );
                        this.deleteDoc.emit(res);
                        this.refreshDataTable.emit()
                    }
                    this._preloaderService.hidePreloader();
                },
                err => {
                    this._alertService.error(err.message);
                    this._preloaderService.hidePreloader();
                }
            );
        }
    }
    /**
     * Method use to open the assign document modal and handle the selected documents id
     * @param {string} content 
     */
    openAssignPopUp(content) {
        this.selectedJobIdForAssign = '';
        this.selectedDocumentIds = this.selected.map(item => item.id ? item.id : undefined);
        this._modalService.open(content, 'sm');
    }

    // Set selected job data to jobId property.
    jobDetial(data) {
        this.selectedJobIdForAssign = data;
        if (data === null || data.length === 0) {
            this.selectedJobIdForAssign = '';
        }
    }

    onSort(event) {
        this.selected = [];
        this.allSelected = false;
        this.currentDocumentPage = { count: 0, pageSize: this.itemsPerPage, limit: this.itemsPerPage, offset: 0 };

        // emulate a server request with a timeout
        setTimeout(() => {
            const rows = [...this.tableData];
            const sort = event.sorts[0];
            rows.sort((a, b) => {
                if (!a[sort.prop]) {
                    a[sort.prop] = "";
                }
                if (!b[sort.prop]) {
                    b[sort.prop] = "";
                }
                return (a[sort.prop].localeCompare(b[sort.prop]) * (sort.dir === 'desc' ? -1 : 1));
            });

            this.tableData = rows;
        }, 0);
    }

    // Update document model for selected JOB
    assignJobToDocument() {
        this._preloaderService.showPreloader();
        this._documentApi.assignDocumentsToJob({ ids: this.selectedDocumentIds, jobid: this.selectedJobIdForAssign }).subscribe(
            results => {
                this._preloaderService.hidePreloader();
                this._modalService.closed();
                this.refreshDataTable.emit();
                let successText = this.selectedDocumentIds.length + ' ' + `${this.selectedDocumentIds.length > 1 ? 'documents' : 'document'}` + ' assigned to job';
                this._alertService.success(successText);
            },
            err => {
                console.log(err);
                this._preloaderService.hidePreloader();
                this._alertService.error('Unable to assign job to document at the moment.');
            }
        );
    }

    ngOnDestroy() {
        if (this.sidebarStateSub) {
            this.sidebarStateSub.unsubscribe();
        }
    }

    uploadDocuments(template) {
        this._modalService.open(template, 'lg');
    }
    documentUploaded(doc) {
        console.log(doc)
    }

    // to apply local filters
    applyLocalFilters(event, type) {
        if (type === 'date') {
            if (event.value === '') {
                if (this.localFilters[event.input.id + '_temp']) {
                    delete this.localFilters[event.input.id];
                }
            } else {
                const date = this.transform(event.value).split(',');
                this.localFilters[event.input.id] = date[0];
            }
        } else {
            if (event.target.value === '') {
                delete this.localFilters[event.currentTarget.id];
            } else {
                this.localFilters[event.currentTarget.id] = event.target.value;
            }
        }

        this.tableData = this.filteredItems.filter(item => {
            const notMatchingField = Object.keys(this.localFilters).find(key =>
                this._utilityService.dataTableSearch(item, this.localFilters, key));
            return !notMatchingField;
        });

        this.noRecords = (this.tableData.length < this.itemsPerBatch) ? true : false;
        if (this.table) {
            this.table['offset'] = 0
        }
        // this.setEmptyMessage();
    }

    // to clear local local filters
    clearLocalFilters(col) {
        this.localFilters[col] = ''
        delete this.localFilters[col];
        this.tableData = this.filteredItems.filter(item => {
            const notMatchingField = Object.keys(this.localFilters).find(key =>
                this._utilityService.dataTableSearch(item, this.localFilters, key));
            return !notMatchingField;
        });

        this.noRecords = (this.tableData.length < this.itemsPerBatch) ? true : false;
        if (this.table) {
            this.table['offset'] = 0
        }
        // this.setEmptyMessage();
    }

    transform(value: string, type?) {
        let dateformate: string;
        type ? dateformate = type : dateformate = 'short';
        this.datePipe = new DatePipe('en-US');
        const formateddate = (value ? this.datePipe.transform(value, dateformate) : value);
        return formateddate;
    }
}