/* tslint:disable */

declare var Object: any;
export interface NoteInterface {
  "sfdcId"?: string;
  "CreatedById"?: string;
  "CurrencyIsoCode"?: string;
  "LastModifiedById"?: string;
  "OwnerId"?: string;
  "Name"?: string;
  "Attachment__c"?: string;
  "CreatedBy__c"?: string;
  "LastModifiedBy__c"?: string;
  "Note__c"?: string;
  "Type__c"?: string;
  "User_Type__c"?: string;
  "Reason__c"?: string;
  "SendEmailFlag__c"?: boolean;
  "Timeclock_Manager__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class Note implements NoteInterface {
  "sfdcId": string;
  "CreatedById": string;
  "CurrencyIsoCode": string;
  "LastModifiedById": string;
  "OwnerId": string;
  "Name": string;
  "Attachment__c": string;
  "CreatedBy__c": string;
  "LastModifiedBy__c": string;
  "Note__c": string;
  "Type__c": string;
  "User_Type__c": string;
  "Reason__c": string;
  "SendEmailFlag__c": boolean;
  "Timeclock_Manager__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: NoteInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Note`.
   */
  public static getModelName() {
    return "Note";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Note for dynamic purposes.
  **/
  public static factory(data: NoteInterface): Note{
    return new Note(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Note',
      plural: 'Notes',
      path: 'Notes',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "Attachment__c": {
          name: 'Attachment__c',
          type: 'string'
        },
        "CreatedBy__c": {
          name: 'CreatedBy__c',
          type: 'string'
        },
        "LastModifiedBy__c": {
          name: 'LastModifiedBy__c',
          type: 'string'
        },
        "Note__c": {
          name: 'Note__c',
          type: 'string'
        },
        "Type__c": {
          name: 'Type__c',
          type: 'string'
        },
        "User_Type__c": {
          name: 'User_Type__c',
          type: 'string'
        },
        "Reason__c": {
          name: 'Reason__c',
          type: 'string'
        },
        "SendEmailFlag__c": {
          name: 'SendEmailFlag__c',
          type: 'boolean'
        },
        "Timeclock_Manager__c": {
          name: 'Timeclock_Manager__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
