import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { templateCategory } from '../../../models/static-list-data.service'

@Component({
  selector: 'app-template-sub-category-lookup',
  templateUrl: './template-sub-category-lookup.component.html',
  styleUrls: ['./template-sub-category-lookup.component.css']
})
export class TemplateSubCategoryLookupComponent implements OnInit {

  selectedTemplateCategory: string;
  templateSubCategoryList = [];
  templateSubCategoryTypeSelected = [];
  isMultipleSelected = true;
  maxSelectedItems = 1;

  @Input() set selectedCategoryType(name: string) {
    if (name) {
      this.selectedTemplateCategory = name;
      this.clearTemplateSubCategory();
      this.loadSubCategories();
    }
}
  @Input() set resetData(isClear) {
    if (isClear) {
      this.templateSubCategoryTypeSelected = [];
    }
  };
  @Output() getTemplateSubCategory: EventEmitter<any> = new EventEmitter;

  constructor() { }

  ngOnInit() {
    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
    this.templateSubCategoryTypeSelected = (preselected && preselected['templateSubCategories']) ?
    preselected['templateSubCategories'] : '';
    this.selectedTemplateCategory = (preselected && preselected['templateCategories']) ? preselected['templateCategories'] : '';
    this.loadSubCategories();
  }

  loadSubCategories() {
    if (this.selectedTemplateCategory.length > 0) {
      this.templateSubCategoryList = templateCategory.filter((category) =>
      category.value === this.selectedTemplateCategory[0])[0].subCategory;
      this.templateSubCategoryList = this.templateSubCategoryList.filter((subCat) =>
      (subCat.value !== '' && subCat.value !== undefined && subCat.value !== null));
    } else {
      this.templateSubCategoryList = [];
    }
  }

  onTemplateSubCategoryChange() {
    this.getTemplateSubCategory.emit(this.templateSubCategoryTypeSelected);
  }

  clearTemplateSubCategory() {
    this.templateSubCategoryTypeSelected = [];
    this.getTemplateSubCategory.emit([]);
  }

}

