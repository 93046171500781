import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CommonService } from './../../services/common.service';
import { ModalBoxComponent } from './modal-box.component';
import { FormsModule } from '@angular/forms';
@NgModule({
  imports: [
    CommonModule,
    NgxDatatableModule,
    NgbModule,
    FormsModule
  ],
  declarations: [ModalBoxComponent],
  exports: [ModalBoxComponent],
  providers: [CommonService]
})
export class ModalBoxModule { }
