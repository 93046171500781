import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ServiceTechnicalLevel } from 'shared/models/static-list-data.service';

@Component({
  selector: 'app-service-technical-level-lookup',
  templateUrl: './service-technical-level-lookup.component.html',
  styleUrls: ['./service-technical-level-lookup.component.css']
})
export class ServiceTechnicalLevelLookupComponent implements OnInit {

  @Input() placeholder = 'Service Technical Level';
  @Input() set resetData(isClear) {
      this.onClear(isClear);
  }
  @Output() selectedValue: EventEmitter<any> = new EventEmitter<any>();
  technicalLevelList: any = [];
  selectedTechnicalLevel: any = [];

  constructor() {
  }

  ngOnInit() {
       this.technicalLevelList = ServiceTechnicalLevel;
       const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
       this.selectedTechnicalLevel = (preselected && preselected['technicalLevels']) ? preselected['technicalLevels'] : '';
  }

  onChange() {
      this.selectedValue.emit(this.selectedTechnicalLevel);
  }

  onClear(isClear) {
      this.selectedTechnicalLevel = [];
      if (isClear) {
          this.selectedValue.emit([]);
      }
  }
}
