/* tslint:disable */
import {
  Account,
  RecordType,
  ServiceContract,
  Job,
  InvoiceAdjustment,
  MonthlyPayment,
  MonthlyInvoiceItem
} from '../index';

declare var Object: any;
export interface MonthlyInvoiceInterface {
  "sfdcId"?: string;
  "X1_Hours_minus_Created__c"?: Date;
  "Account_Name__c"?: string;
  "Adjusted_Final_Amount__c"?: number;
  "Adjustment_Notes__c"?: string;
  "Adjustment_Notes_External__c"?: string;
  "Consolidated_Monthly_Invoice_Ref__c"?: string;
  "Conversion_Rate_To_USD__c"?: number;
  "Description__c"?: string;
  "Invoice_Description__c"?: string;
  "Job__c"?: string;
  "Last_Roll_up__c"?: number;
  "Latest_Payment__c"?: string;
  "Month__c"?: string;
  "Monthly_Invoice_currency__c"?: string;
  "Customer__c"?: string;
  "Payable_Due__c"?: number;
  "Rate__c"?: number;
  "Rate_Roll_up__c"?: number;
  "RecordTypeId__c"?: string;
  "Roll_up_Holiday_Overtime_Hours__c"?: number;
  "Roll_up_Short_Hours__c"?: number;
  "Roll_up_Standard_Overtime_Hours__c"?: number;
  "Serial_Number__c"?: string;
  "Service_Contract__c"?: string;
  "Status__c"?: string;
  "Time_Bank_Credit_Amount__c"?: number;
  "Time_Bank_Credit_Days__c"?: number;
  "Total_Amount_Code_Roll_up__c"?: number;
  "Total_Monthly_Amount__c"?: number;
  "Total_Amount_Balance__c"?: number;
  "Total_amount_Paid__c"?: number;
  "Total_Hours_Worked_Roll_up__c"?: number;
  "Total_Invoice_Amount_Roll_up__c"?: number;
  "Total_Lines__c"?: number;
  "Total_Monthly_Amount_Final_Custom__c"?: number;
  "Total_Monthly_Amount_Final__c"?: number;
  "Total_Paid__c"?: number;
  "Total_Roll_up__c"?: number;
  "Account__c"?: string;
  "Year__c"?: string;
  "CreatedById"?: string;
  "CurrencyIsoCode"?: string;
  "LastModifiedById"?: string;
  "Name"?: string;
  "OwnerId"?: string;
  "RecordTypeId"?: string;
  "id"?: number;
  vendor?: Account;
  recordType?: RecordType;
  partner?: Account;
  serviceContract?: ServiceContract;
  jobs?: Job;
  invoiceAdjustment?: InvoiceAdjustment[];
  monthlyPayment?: MonthlyPayment[];
  monthlyInvoiceItem?: MonthlyInvoiceItem[];
}

export class MonthlyInvoice implements MonthlyInvoiceInterface {
  "sfdcId": string;
  "X1_Hours_minus_Created__c": Date;
  "Account_Name__c": string;
  "Adjusted_Final_Amount__c": number;
  "Adjustment_Notes__c": string;
  "Adjustment_Notes_External__c": string;
  "Consolidated_Monthly_Invoice_Ref__c": string;
  "Conversion_Rate_To_USD__c": number;
  "Description__c": string;
  "Invoice_Description__c": string;
  "Job__c": string;
  "Last_Roll_up__c": number;
  "Latest_Payment__c": string;
  "Month__c": string;
  "Monthly_Invoice_currency__c": string;
  "Customer__c": string;
  "Payable_Due__c": number;
  "Rate__c": number;
  "Rate_Roll_up__c": number;
  "RecordTypeId__c": string;
  "Roll_up_Holiday_Overtime_Hours__c": number;
  "Roll_up_Short_Hours__c": number;
  "Roll_up_Standard_Overtime_Hours__c": number;
  "Serial_Number__c": string;
  "Service_Contract__c": string;
  "Status__c": string;
  "Time_Bank_Credit_Amount__c": number;
  "Time_Bank_Credit_Days__c": number;
  "Total_Amount_Code_Roll_up__c": number;
  "Total_Monthly_Amount__c": number;
  "Total_Amount_Balance__c": number;
  "Total_amount_Paid__c": number;
  "Total_Hours_Worked_Roll_up__c": number;
  "Total_Invoice_Amount_Roll_up__c": number;
  "Total_Lines__c": number;
  "Total_Monthly_Amount_Final_Custom__c": number;
  "Total_Monthly_Amount_Final__c": number;
  "Total_Paid__c": number;
  "Total_Roll_up__c": number;
  "Account__c": string;
  "Year__c": string;
  "CreatedById": string;
  "CurrencyIsoCode": string;
  "LastModifiedById": string;
  "Name": string;
  "OwnerId": string;
  "RecordTypeId": string;
  "id": number;
  vendor: Account;
  recordType: RecordType;
  partner: Account;
  serviceContract: ServiceContract;
  jobs: Job;
  invoiceAdjustment: InvoiceAdjustment[];
  monthlyPayment: MonthlyPayment[];
  monthlyInvoiceItem: MonthlyInvoiceItem[];
  constructor(data?: MonthlyInvoiceInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `MonthlyInvoice`.
   */
  public static getModelName() {
    return "MonthlyInvoice";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of MonthlyInvoice for dynamic purposes.
  **/
  public static factory(data: MonthlyInvoiceInterface): MonthlyInvoice{
    return new MonthlyInvoice(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'MonthlyInvoice',
      plural: 'MonthlyInvoices',
      path: 'MonthlyInvoices',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "X1_Hours_minus_Created__c": {
          name: 'X1_Hours_minus_Created__c',
          type: 'Date'
        },
        "Account_Name__c": {
          name: 'Account_Name__c',
          type: 'string'
        },
        "Adjusted_Final_Amount__c": {
          name: 'Adjusted_Final_Amount__c',
          type: 'number'
        },
        "Adjustment_Notes__c": {
          name: 'Adjustment_Notes__c',
          type: 'string'
        },
        "Adjustment_Notes_External__c": {
          name: 'Adjustment_Notes_External__c',
          type: 'string'
        },
        "Consolidated_Monthly_Invoice_Ref__c": {
          name: 'Consolidated_Monthly_Invoice_Ref__c',
          type: 'string'
        },
        "Conversion_Rate_To_USD__c": {
          name: 'Conversion_Rate_To_USD__c',
          type: 'number'
        },
        "Description__c": {
          name: 'Description__c',
          type: 'string'
        },
        "Invoice_Description__c": {
          name: 'Invoice_Description__c',
          type: 'string'
        },
        "Job__c": {
          name: 'Job__c',
          type: 'string'
        },
        "Last_Roll_up__c": {
          name: 'Last_Roll_up__c',
          type: 'number'
        },
        "Latest_Payment__c": {
          name: 'Latest_Payment__c',
          type: 'string'
        },
        "Month__c": {
          name: 'Month__c',
          type: 'string'
        },
        "Monthly_Invoice_currency__c": {
          name: 'Monthly_Invoice_currency__c',
          type: 'string'
        },
        "Customer__c": {
          name: 'Customer__c',
          type: 'string'
        },
        "Payable_Due__c": {
          name: 'Payable_Due__c',
          type: 'number'
        },
        "Rate__c": {
          name: 'Rate__c',
          type: 'number'
        },
        "Rate_Roll_up__c": {
          name: 'Rate_Roll_up__c',
          type: 'number'
        },
        "RecordTypeId__c": {
          name: 'RecordTypeId__c',
          type: 'string'
        },
        "Roll_up_Holiday_Overtime_Hours__c": {
          name: 'Roll_up_Holiday_Overtime_Hours__c',
          type: 'number'
        },
        "Roll_up_Short_Hours__c": {
          name: 'Roll_up_Short_Hours__c',
          type: 'number'
        },
        "Roll_up_Standard_Overtime_Hours__c": {
          name: 'Roll_up_Standard_Overtime_Hours__c',
          type: 'number'
        },
        "Serial_Number__c": {
          name: 'Serial_Number__c',
          type: 'string'
        },
        "Service_Contract__c": {
          name: 'Service_Contract__c',
          type: 'string'
        },
        "Status__c": {
          name: 'Status__c',
          type: 'string'
        },
        "Time_Bank_Credit_Amount__c": {
          name: 'Time_Bank_Credit_Amount__c',
          type: 'number'
        },
        "Time_Bank_Credit_Days__c": {
          name: 'Time_Bank_Credit_Days__c',
          type: 'number'
        },
        "Total_Amount_Code_Roll_up__c": {
          name: 'Total_Amount_Code_Roll_up__c',
          type: 'number'
        },
        "Total_Monthly_Amount__c": {
          name: 'Total_Monthly_Amount__c',
          type: 'number'
        },
        "Total_Amount_Balance__c": {
          name: 'Total_Amount_Balance__c',
          type: 'number'
        },
        "Total_amount_Paid__c": {
          name: 'Total_amount_Paid__c',
          type: 'number'
        },
        "Total_Hours_Worked_Roll_up__c": {
          name: 'Total_Hours_Worked_Roll_up__c',
          type: 'number'
        },
        "Total_Invoice_Amount_Roll_up__c": {
          name: 'Total_Invoice_Amount_Roll_up__c',
          type: 'number'
        },
        "Total_Lines__c": {
          name: 'Total_Lines__c',
          type: 'number'
        },
        "Total_Monthly_Amount_Final_Custom__c": {
          name: 'Total_Monthly_Amount_Final_Custom__c',
          type: 'number'
        },
        "Total_Monthly_Amount_Final__c": {
          name: 'Total_Monthly_Amount_Final__c',
          type: 'number'
        },
        "Total_Paid__c": {
          name: 'Total_Paid__c',
          type: 'number'
        },
        "Total_Roll_up__c": {
          name: 'Total_Roll_up__c',
          type: 'number'
        },
        "Account__c": {
          name: 'Account__c',
          type: 'string'
        },
        "Year__c": {
          name: 'Year__c',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "RecordTypeId": {
          name: 'RecordTypeId',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        vendor: {
          name: 'vendor',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'Account__c',
          keyTo: 'sfdcId'
        },
        recordType: {
          name: 'recordType',
          type: 'RecordType',
          model: 'RecordType',
          relationType: 'belongsTo',
                  keyFrom: 'RecordTypeId',
          keyTo: 'sfdcId'
        },
        partner: {
          name: 'partner',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'Customer__c',
          keyTo: 'sfdcId'
        },
        serviceContract: {
          name: 'serviceContract',
          type: 'ServiceContract',
          model: 'ServiceContract',
          relationType: 'belongsTo',
                  keyFrom: 'Service_Contract__c',
          keyTo: 'sfdcId'
        },
        jobs: {
          name: 'jobs',
          type: 'Job',
          model: 'Job',
          relationType: 'belongsTo',
                  keyFrom: 'Job__c',
          keyTo: 'sfdcId'
        },
        invoiceAdjustment: {
          name: 'invoiceAdjustment',
          type: 'InvoiceAdjustment[]',
          model: 'InvoiceAdjustment',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'Monthly_Invoice__c'
        },
        monthlyPayment: {
          name: 'monthlyPayment',
          type: 'MonthlyPayment[]',
          model: 'MonthlyPayment',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'Monthly_Invoice__c'
        },
        monthlyInvoiceItem: {
          name: 'monthlyInvoiceItem',
          type: 'MonthlyInvoiceItem[]',
          model: 'MonthlyInvoiceItem',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'Monthly_Invoice__c'
        },
      }
    }
  }
}
