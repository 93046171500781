/* tslint:disable */
import {
  Contact,
  JobApplication,
  Account
} from '../index';

declare var Object: any;
export interface JobSubmittalsInterface {
  "sfdcId"?: string;
  "CurrencyIsoCode"?: string;
  "LastModifiedById"?: string;
  "RecordTypeId"?: string;
  "Name"?: string;
  "ts2__Account__c"?: string;
  "ts2__AccountContact__c"?: string;
  "ts2extams__Action_Buttons__c"?: string;
  "ts2__Address__c"?: string;
  "ts2__Available__c"?: Date;
  "ts2__Bill_Rate__c"?: number;
  "ts2__Bonus__c"?: number;
  "ts2__Candidate__c"?: string;
  "ts2__Candidate_Summary__c"?: string;
  "Candidate_Type__c"?: string;
  "ts2__Client__c"?: string;
  "ts2__Client_Submittal_Feedback__c"?: string;
  "ts2__Code__c"?: string;
  "ts2extams__Communication_Buttons__c"?: string;
  "ts2__Contact__c"?: string;
  "ts2__Contact_Email__c"?: string;
  "ts2__Contact_Phone__c"?: string;
  "ts2extams__Control_Buttons__c"?: string;
  "ts2__Conversion_Fee__c"?: number;
  "ts2__Conversion_Fee_Pct__c"?: number;
  "ts2__Conversion_Salary__c"?: number;
  "ts2__Conversion_Terms__c"?: string;
  "ts2__Cover_Sheet_Completed__c"?: boolean;
  "CreatedBy__c"?: string;
  "ts2__Date_Available__c"?: Date;
  "ts2__Email__c"?: string;
  "ts2__Experienc__c"?: string;
  "ts2__Fee__c"?: number;
  "ts2__Fee_Pct__c"?: number;
  "ts2__HiringManager__c"?: string;
  "Hiring_Manager__c"?: string;
  "ts2__Initiate_SRP__c"?: boolean;
  "ts2__Job__c"?: string;
  "CreatedById"?: string;
  "ts2__Job_Status__c"?: string;
  "ts2__Key__c"?: string;
  "LastModifiedBy__c"?: string;
  "ts2__Legacy_SubmittalID__c"?: string;
  "ts2__Mobile__c"?: string;
  "ts2__Notice__c"?: string;
  "ts2__Pay_Rate__c"?: number;
  "ts2__Phone__c"?: string;
  "ts2__Pre_Screen_Completed__c"?: boolean;
  "ts2__PrimaryRecruiter__c"?: string;
  "Primary_Recruiter__c"?: string;
  "ts2__Project__c"?: string;
  "ts2__Rank__c"?: string;
  "Reason__c"?: string;
  "ts2__Reason_for_Submission__c"?: string;
  "ts2__Reject_Detail__c"?: string;
  "ts2__Reject_Reason__c"?: string;
  "ts2__Related_Application__c"?: string;
  "ts2__Resume_Attached__c"?: boolean;
  "ts2extams__Resume_Uploaded__c"?: string;
  "ts2__ReviewProcessed__c"?: Date;
  "ts2__ReviewSent__c"?: Date;
  "ts2__Salary__c"?: number;
  "ts2__SecondaryRecruiter__c"?: string;
  "Secondary_Recruiter__c"?: string;
  "ts2__Status__c"?: string;
  "ts2extams__Substatus__c"?: string;
  "ts2__Title__c"?: string;
  "ts2__Type__c"?: string;
  "id"?: number;
  "Primary_Recruiter__c​"?: string;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  candidateContact?: Contact;
  relatedApplication?: JobApplication;
  account?: Account;
  primaryRecruiter?: Contact;
}

export class JobSubmittals implements JobSubmittalsInterface {
  "sfdcId": string;
  "CurrencyIsoCode": string;
  "LastModifiedById": string;
  "RecordTypeId": string;
  "Name": string;
  "ts2__Account__c": string;
  "ts2__AccountContact__c": string;
  "ts2extams__Action_Buttons__c": string;
  "ts2__Address__c": string;
  "ts2__Available__c": Date;
  "ts2__Bill_Rate__c": number;
  "ts2__Bonus__c": number;
  "ts2__Candidate__c": string;
  "ts2__Candidate_Summary__c": string;
  "Candidate_Type__c": string;
  "ts2__Client__c": string;
  "ts2__Client_Submittal_Feedback__c": string;
  "ts2__Code__c": string;
  "ts2extams__Communication_Buttons__c": string;
  "ts2__Contact__c": string;
  "ts2__Contact_Email__c": string;
  "ts2__Contact_Phone__c": string;
  "ts2extams__Control_Buttons__c": string;
  "ts2__Conversion_Fee__c": number;
  "ts2__Conversion_Fee_Pct__c": number;
  "ts2__Conversion_Salary__c": number;
  "ts2__Conversion_Terms__c": string;
  "ts2__Cover_Sheet_Completed__c": boolean;
  "CreatedBy__c": string;
  "ts2__Date_Available__c": Date;
  "ts2__Email__c": string;
  "ts2__Experienc__c": string;
  "ts2__Fee__c": number;
  "ts2__Fee_Pct__c": number;
  "ts2__HiringManager__c": string;
  "Hiring_Manager__c": string;
  "ts2__Initiate_SRP__c": boolean;
  "ts2__Job__c": string;
  "CreatedById": string;
  "ts2__Job_Status__c": string;
  "ts2__Key__c": string;
  "LastModifiedBy__c": string;
  "ts2__Legacy_SubmittalID__c": string;
  "ts2__Mobile__c": string;
  "ts2__Notice__c": string;
  "ts2__Pay_Rate__c": number;
  "ts2__Phone__c": string;
  "ts2__Pre_Screen_Completed__c": boolean;
  "ts2__PrimaryRecruiter__c": string;
  "Primary_Recruiter__c": string;
  "ts2__Project__c": string;
  "ts2__Rank__c": string;
  "Reason__c": string;
  "ts2__Reason_for_Submission__c": string;
  "ts2__Reject_Detail__c": string;
  "ts2__Reject_Reason__c": string;
  "ts2__Related_Application__c": string;
  "ts2__Resume_Attached__c": boolean;
  "ts2extams__Resume_Uploaded__c": string;
  "ts2__ReviewProcessed__c": Date;
  "ts2__ReviewSent__c": Date;
  "ts2__Salary__c": number;
  "ts2__SecondaryRecruiter__c": string;
  "Secondary_Recruiter__c": string;
  "ts2__Status__c": string;
  "ts2extams__Substatus__c": string;
  "ts2__Title__c": string;
  "ts2__Type__c": string;
  "id": number;
  "Primary_Recruiter__c​": string;
  "createdAt": Date;
  "updatedAt": Date;
  candidateContact: Contact;
  relatedApplication: JobApplication;
  account: Account;
  primaryRecruiter: Contact;
  constructor(data?: JobSubmittalsInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `JobSubmittals`.
   */
  public static getModelName() {
    return "JobSubmittals";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of JobSubmittals for dynamic purposes.
  **/
  public static factory(data: JobSubmittalsInterface): JobSubmittals{
    return new JobSubmittals(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'JobSubmittals',
      plural: 'JobSubmittals',
      path: 'JobSubmittals',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "RecordTypeId": {
          name: 'RecordTypeId',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "ts2__Account__c": {
          name: 'ts2__Account__c',
          type: 'string'
        },
        "ts2__AccountContact__c": {
          name: 'ts2__AccountContact__c',
          type: 'string'
        },
        "ts2extams__Action_Buttons__c": {
          name: 'ts2extams__Action_Buttons__c',
          type: 'string'
        },
        "ts2__Address__c": {
          name: 'ts2__Address__c',
          type: 'string'
        },
        "ts2__Available__c": {
          name: 'ts2__Available__c',
          type: 'Date'
        },
        "ts2__Bill_Rate__c": {
          name: 'ts2__Bill_Rate__c',
          type: 'number'
        },
        "ts2__Bonus__c": {
          name: 'ts2__Bonus__c',
          type: 'number'
        },
        "ts2__Candidate__c": {
          name: 'ts2__Candidate__c',
          type: 'string'
        },
        "ts2__Candidate_Summary__c": {
          name: 'ts2__Candidate_Summary__c',
          type: 'string'
        },
        "Candidate_Type__c": {
          name: 'Candidate_Type__c',
          type: 'string'
        },
        "ts2__Client__c": {
          name: 'ts2__Client__c',
          type: 'string'
        },
        "ts2__Client_Submittal_Feedback__c": {
          name: 'ts2__Client_Submittal_Feedback__c',
          type: 'string'
        },
        "ts2__Code__c": {
          name: 'ts2__Code__c',
          type: 'string'
        },
        "ts2extams__Communication_Buttons__c": {
          name: 'ts2extams__Communication_Buttons__c',
          type: 'string'
        },
        "ts2__Contact__c": {
          name: 'ts2__Contact__c',
          type: 'string'
        },
        "ts2__Contact_Email__c": {
          name: 'ts2__Contact_Email__c',
          type: 'string'
        },
        "ts2__Contact_Phone__c": {
          name: 'ts2__Contact_Phone__c',
          type: 'string'
        },
        "ts2extams__Control_Buttons__c": {
          name: 'ts2extams__Control_Buttons__c',
          type: 'string'
        },
        "ts2__Conversion_Fee__c": {
          name: 'ts2__Conversion_Fee__c',
          type: 'number'
        },
        "ts2__Conversion_Fee_Pct__c": {
          name: 'ts2__Conversion_Fee_Pct__c',
          type: 'number'
        },
        "ts2__Conversion_Salary__c": {
          name: 'ts2__Conversion_Salary__c',
          type: 'number'
        },
        "ts2__Conversion_Terms__c": {
          name: 'ts2__Conversion_Terms__c',
          type: 'string'
        },
        "ts2__Cover_Sheet_Completed__c": {
          name: 'ts2__Cover_Sheet_Completed__c',
          type: 'boolean',
          default: false
        },
        "CreatedBy__c": {
          name: 'CreatedBy__c',
          type: 'string'
        },
        "ts2__Date_Available__c": {
          name: 'ts2__Date_Available__c',
          type: 'Date'
        },
        "ts2__Email__c": {
          name: 'ts2__Email__c',
          type: 'string'
        },
        "ts2__Experienc__c": {
          name: 'ts2__Experienc__c',
          type: 'string'
        },
        "ts2__Fee__c": {
          name: 'ts2__Fee__c',
          type: 'number'
        },
        "ts2__Fee_Pct__c": {
          name: 'ts2__Fee_Pct__c',
          type: 'number'
        },
        "ts2__HiringManager__c": {
          name: 'ts2__HiringManager__c',
          type: 'string'
        },
        "Hiring_Manager__c": {
          name: 'Hiring_Manager__c',
          type: 'string'
        },
        "ts2__Initiate_SRP__c": {
          name: 'ts2__Initiate_SRP__c',
          type: 'boolean',
          default: false
        },
        "ts2__Job__c": {
          name: 'ts2__Job__c',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "ts2__Job_Status__c": {
          name: 'ts2__Job_Status__c',
          type: 'string'
        },
        "ts2__Key__c": {
          name: 'ts2__Key__c',
          type: 'string'
        },
        "LastModifiedBy__c": {
          name: 'LastModifiedBy__c',
          type: 'string'
        },
        "ts2__Legacy_SubmittalID__c": {
          name: 'ts2__Legacy_SubmittalID__c',
          type: 'string'
        },
        "ts2__Mobile__c": {
          name: 'ts2__Mobile__c',
          type: 'string'
        },
        "ts2__Notice__c": {
          name: 'ts2__Notice__c',
          type: 'string'
        },
        "ts2__Pay_Rate__c": {
          name: 'ts2__Pay_Rate__c',
          type: 'number'
        },
        "ts2__Phone__c": {
          name: 'ts2__Phone__c',
          type: 'string'
        },
        "ts2__Pre_Screen_Completed__c": {
          name: 'ts2__Pre_Screen_Completed__c',
          type: 'boolean',
          default: false
        },
        "ts2__PrimaryRecruiter__c": {
          name: 'ts2__PrimaryRecruiter__c',
          type: 'string'
        },
        "Primary_Recruiter__c": {
          name: 'Primary_Recruiter__c',
          type: 'string'
        },
        "ts2__Project__c": {
          name: 'ts2__Project__c',
          type: 'string'
        },
        "ts2__Rank__c": {
          name: 'ts2__Rank__c',
          type: 'string'
        },
        "Reason__c": {
          name: 'Reason__c',
          type: 'string'
        },
        "ts2__Reason_for_Submission__c": {
          name: 'ts2__Reason_for_Submission__c',
          type: 'string'
        },
        "ts2__Reject_Detail__c": {
          name: 'ts2__Reject_Detail__c',
          type: 'string'
        },
        "ts2__Reject_Reason__c": {
          name: 'ts2__Reject_Reason__c',
          type: 'string'
        },
        "ts2__Related_Application__c": {
          name: 'ts2__Related_Application__c',
          type: 'string'
        },
        "ts2__Resume_Attached__c": {
          name: 'ts2__Resume_Attached__c',
          type: 'boolean',
          default: false
        },
        "ts2extams__Resume_Uploaded__c": {
          name: 'ts2extams__Resume_Uploaded__c',
          type: 'string'
        },
        "ts2__ReviewProcessed__c": {
          name: 'ts2__ReviewProcessed__c',
          type: 'Date'
        },
        "ts2__ReviewSent__c": {
          name: 'ts2__ReviewSent__c',
          type: 'Date'
        },
        "ts2__Salary__c": {
          name: 'ts2__Salary__c',
          type: 'number'
        },
        "ts2__SecondaryRecruiter__c": {
          name: 'ts2__SecondaryRecruiter__c',
          type: 'string'
        },
        "Secondary_Recruiter__c": {
          name: 'Secondary_Recruiter__c',
          type: 'string'
        },
        "ts2__Status__c": {
          name: 'ts2__Status__c',
          type: 'string'
        },
        "ts2extams__Substatus__c": {
          name: 'ts2extams__Substatus__c',
          type: 'string'
        },
        "ts2__Title__c": {
          name: 'ts2__Title__c',
          type: 'string'
        },
        "ts2__Type__c": {
          name: 'ts2__Type__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "Primary_Recruiter__c​": {
          name: 'Primary_Recruiter__c​',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        candidateContact: {
          name: 'candidateContact',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'ts2__Candidate__c',
          keyTo: 'sfdcId'
        },
        relatedApplication: {
          name: 'relatedApplication',
          type: 'JobApplication',
          model: 'JobApplication',
          relationType: 'belongsTo',
                  keyFrom: 'ts2__Related_Application__c',
          keyTo: 'sfdcId'
        },
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'ts2__Account__c',
          keyTo: 'sfdcId'
        },
        primaryRecruiter: {
          name: 'primaryRecruiter',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Primary_Recruiter__c​',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
