import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PMSCaseStatusLookupComponent } from './pms-case-status-lookup.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule
  ],
  declarations: [PMSCaseStatusLookupComponent],
  exports: [PMSCaseStatusLookupComponent],
  providers: []
})
export class PMSCaseStatusLookupModule { }
