import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { CommonService } from "shared/services/common.service";
import { expenseTypeValues } from "shared/models/static-list-data.service";

@Component({
  selector: "app-expense-sub-type-lookup",
  templateUrl: "./expense-sub-type-lookup.component.html",
  styleUrls: ["./expense-sub-type-lookup.component.css"],
})
export class ExpenseSubTypeLookupComponent implements OnInit {
  @Input() set resetData(isClear) {
    if (isClear) {
      this.expenseSubTypeSelected = [];
    }
  }
  @Input() placeholder;
  @Input() isMultipleSelected = false;
  @Output() getExpenseSubTypeObj: EventEmitter<any> = new EventEmitter();
  expenseSubTypeSelected;
  expenseSubTypeOptionList = [];
  constructor(private _commonService: CommonService) { }

  ngOnInit() {
    this.getExpenseSubType();
    const preSelected = localStorage.getItem('filterObj');
    if (preSelected && JSON.parse(preSelected) &&
      JSON.parse(preSelected)['expenseSubTypes'] && JSON.parse(preSelected)['expenseSubTypes'].length) {
      this.expenseSubTypeSelected = JSON.parse(preSelected)['expenseSubTypes'][0];
      if (JSON.parse(preSelected)['expenseTypes'] && JSON.parse(preSelected)['expenseTypes'].length) {
        this.getFilterSubTypeOptionList(JSON.parse(preSelected)['expenseTypes'][0]);
      }
    }
  }

  /* to get expense sub type list */
  getExpenseSubType() {
    this._commonService.expenseTypeList.subscribe((result) => {
      this.getFilterSubTypeOptionList(result);
      this.expenseSubTypeSelected = [];
    });
  }

  getFilterSubTypeOptionList(result) {
    let filterExpenseTypeList = expenseTypeValues.filter(
      (item) => item.value === result
    );
    this.expenseSubTypeOptionList =
      filterExpenseTypeList.length != 0
        ? filterExpenseTypeList[0].expenseSubType
        : [];
  }

  onExpenseSubTypeChange() {
    this.getExpenseSubTypeObj.emit(this.expenseSubTypeSelected);
  }

  clearExpenseSubType() {
    this.expenseSubTypeSelected = [];
    this.getExpenseSubTypeObj.emit([]);
  }
}
