import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'slice'
})
export class SliceContentPipe implements PipeTransform {

  transform(value: any, reqlength?: any): any {
    if (value && reqlength) {
      value = value.replace(/<img[^>]*>/g, ' <span class="e-media">Embedded Media</u></span>');
      let sliceString = value.slice(0, reqlength);
      sliceString += value.length > reqlength ? '...' : '';
      return sliceString;
    } else {
      return value;
    }
  }
}
