import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { ApplicationAssessmentApi } from 'shared/sdk/services/custom/ApplicationAssessment';
@Pipe({
    name: 'authImage'
  })
  export class AuthImagePipe implements PipeTransform {
    token = '';
    constructor(
      private http: HttpClient,
      // private auth: AuthService, // our service that provides us with the authorization token
      private _applicationAssessmentApi:ApplicationAssessmentApi
    ) {
      this._applicationAssessmentApi.getSToken().subscribe(res=>{
        if(res && res.access_token){
          this.token = res.access_token ;
        }
      });

    }
  
    async transform(src: string): Promise<any> {
      const token = this.token ; //'00D1a000000Y1MW!ARcAQNzUwxK1snY8gY8YROp1HMYzL9XQ7L2ktfqiEPbi3MM8lpK_KQUqmDMABHSDeSkOhNSMmdhakyXUiA4PSdGpUbBsnbm5';
      const headers = new HttpHeaders({'Authorization': `Bearer ${token}`});
      const imageBlob = await this.http.get(src, {headers, responseType: 'blob'}).toPromise();
      const reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.onloadend = () => resolve(reader.result as string);
        reader.readAsDataURL(imageBlob);
      });     
    }
  
  }