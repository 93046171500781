import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { actualCoverageHourValues } from '../../../models/static-list-data.service'

@Component({
  selector: 'app-actual-coverage-overall-lookup',
  templateUrl: './actual-coverage-overall-lookup.component.html',
  styleUrls: ['./actual-coverage-overall-lookup.component.css']
})
export class ActualCoverageOverallLookupComponent implements OnInit {

  actualCoverageOverAllList = [];
  actualCoverageOverAllSelected = [];
  isMultipleSelected = true;

  @Input() set resetData(isClear) {
    if (isClear) {
      this.actualCoverageOverAllSelected = [];
    }
  }
  @Output() selectedActualCoverageOverAll: EventEmitter<any> = new EventEmitter;

  constructor() { }

  ngOnInit() {
    this.actualCoverageOverAllList = [{ value: 'Overtime', label: 'Overtime' }].concat(actualCoverageHourValues);
    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
    this.actualCoverageOverAllSelected = (preselected && preselected['actualCoverageOverAll']) ? preselected['actualCoverageOverAll'] : '';
  }
  onActualCoverageOverAllChange() {
    this.selectedActualCoverageOverAll.emit(this.actualCoverageOverAllSelected);
  }
  clearActualCoverageOverAll() {
    this.actualCoverageOverAllSelected = [];
    this.selectedActualCoverageOverAll.emit([]);
  }

}
