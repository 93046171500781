import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { PreloaderService } from 'shared/services/preloader.service';
import { ProgramApi } from 'shared/sdk';
import { CommonService } from 'shared/services/common.service';

@Component({
  selector: 'app-select-program-in-jobsite',
  templateUrl: './select-program-in-jobsite.component.html',
  styleUrls: ['./select-program-in-jobsite.component.css']
})
export class SelectProgramInJobsiteComponent implements OnInit {

  //pass this accountsfdcId in recordtypeId in the api call.
  @Input() set accountSfdcId(e) {
    if (e) {
      this.accountSfdcId = e;
    }
  }
  columns: any;
  filterObj = {};
  filterRelation: {};
  filter = {};
  loadingIndicator = false;
  tableData = [];
  order = 'createdAt Desc';
  filteredItems = [];
  @ViewChild('myTable') table: any;
  @Output() finalProgram: EventEmitter<any> = new EventEmitter();
  selectedProgram = {};
  errorMessage = 'Loading..';
  isLoadMore = false;
  response = [];
  finalData: any;
  offset = 0;


  constructor(
    private _loader: PreloaderService,
    private _programApi: ProgramApi,
    private _commonService: CommonService,
  ) { }

  ngOnInit() {
    this.columns = [
      { prop: 'Action', name: 'Action', width: 200, visible: true, sortable: false },
      { prop: 'No.', name: 'No.', width: 200, visible: true, sortable: false },
      { prop: 'programNumber', name: 'Program Number', width: 200, visible: true, sortable: true },
      { prop: 'programName', name: 'Program Name', width: 250, visible: true, sortable: true },
      { prop: 'Date', name: 'Date', width: 250, visible: true, sortable: true }
    ];
    this.getSelectProgramData(0)
  }

  getSelectProgramData(offset: number) {
    this._loader.showPreloader();
    this.filterRelation = {
      where: {
        RecordTypeId: '0121a000000VlrwAAC'
      },
      fields: ['sfdcId', 'Master_Project__c'],
      order: this.order,
      skip: offset,
      include: [{
        relation: 'project',
        scope: {
          fields: ['Project__c', 'Name', 'createdAt']
        }
      }
      ]
    };

    this._programApi.find(this.filterRelation).subscribe(res => {
      this.response = res.map((element) => { return element });
      this.finalData = this.listData(this.response);
      if (this.finalData.length) {
        this.finalData.forEach(c => {
          this.tableData.push(c);
        });
        this.tableData = [...this.tableData];
      }
      this.loadingIndicator = false;
      this._loader.hidePreloader();
      this.setEmptyMessage();
    },
      error => {
        this.errorMessage = error.message;
        this._loader.hidePreloader();
        this.loadingIndicator = false;
      }
    )
  }

  listData(data) {
    if (data.length) {
      const timeFormat = 'MM/D/YYYY';
      data.forEach((item, index) => {
        item['programNumber'] = (item.project.Project__c) ? item.project.Project__c : '',
          item['programName'] = (item.project.Name) ? item.project.Name : '',
          item['Date'] = (item.project.createdAt) ? this._commonService.dateFormate(item.project.createdAt, timeFormat) : '',
          item['No.'] = index + 1
      });
      return data;
    } else {
      return [];
    }
  }

  setEmptyMessage() {
    const msg = 'No data to display';
    if (!this.tableData.length) {
      this.tableData = [{
        'message': msg
      }];
      this.tableData[0][this.columns[0]['prop']] = msg;
    }
    else {
      if (this.tableData[0].hasOwnProperty('message')) {
        this.tableData.shift();
      }
    }
  }

  selectedProgramClicked(selectedRow) {
    this.finalProgram.emit(selectedRow)
  }
}
