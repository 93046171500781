import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { RecruitmentContactLookupComponent } from './recruitment-contact-lookup.component';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule
  ],
  declarations: [RecruitmentContactLookupComponent],
  exports: [RecruitmentContactLookupComponent],
  providers: []
})
export class RecruitmentContactLookupModule { }
