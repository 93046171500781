import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkerScheduledLookupComponent } from './worker-scheduled-lookup.component';
import { FormsModule } from '@angular/forms';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule
  ],
  declarations: [WorkerScheduledLookupComponent],
  exports: [WorkerScheduledLookupComponent]
})
export class WorkerScheduledLookupModule { }
