import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { invoiceStatus } from 'shared/models/static-list-data.service';

@Component({
    selector: 'app-sales-invoice-status-lookup',
    templateUrl: './sales-invoice-status-lookup.component.html',
    styleUrls: ['./sales-invoice-status-lookup.component.css']
})
export class SalesInvoiceStatusLookupComponent implements OnInit {

    @Input() placeholder = 'Sales Invoice Status';
    @Input() set resetData(isClear) {
        this.onClear(isClear);
    }
    @Output() selectedValue: EventEmitter<any> = new EventEmitter;
    invoiceStatus: any = [];
    selectedStatus: any = [];

    constructor() {
    }

    ngOnInit() {
        this.invoiceStatus = invoiceStatus;
        // to show selected Sales invoice status
        const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
        this.selectedStatus = (preselected && preselected['salesInvoiceStatus']) ? preselected['salesInvoiceStatus'] : '';
    }

    /**
    * This function executes whenever there is a change in selected values.
    * This will emit the changed array.
    */
    onChange() {
        this.selectedValue.emit(this.selectedStatus);
    }

    /**
    * This function executes whenever the select input is cleared.
    * This will clear the array and emit an empty array.
    */
    onClear(isClear) {
        this.selectedStatus = [];
        if (isClear) {
            this.selectedValue.emit([]);
        }
    }
}
