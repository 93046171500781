import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkorderDetailComponent } from './workorder-detail.component';
import { JobsiteRouteMapModule } from '../jobsite-routemap/jobsite-routemap.module';
import { PipeModule } from 'shared/pipe/pipe.module';
import { FilesListModule } from '../files-list/files-list.module';
@NgModule({
  imports: [
    CommonModule,
    JobsiteRouteMapModule,
    PipeModule,
    FilesListModule,
  ],
  declarations: [WorkorderDetailComponent],
  exports: [WorkorderDetailComponent],
})
export class WorkorderDetailModule { }
