import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ValueList } from 'shared/models/common';
import { CaseCategoryFilterList, FTECaseCategoryFilterList } from 'shared/models/static-list-data.service';

@Component({
  selector: 'app-case-category-lookup',
  templateUrl: './case-category-lookup.component.html',
  styleUrls: ['./case-category-lookup.component.css']
})
export class CaseCategoryLookupComponent implements OnInit {

  @Input() set resetData(isClear) {
    if (isClear) {
      this.caseCategorySelected = [];
    }
  }
  @Input() page = '';
  @Input() placeholder = 'Case Category';
  @Input() isdisabled = false;
  @Input() isMultipleSelected = true;

  @Output() getCaseCategoryObj: EventEmitter<any> = new EventEmitter;


  caseCategoryOptionList: ValueList[] = [];
  caseCategorySelected: string[] = [];

  /**
   * @constructor
   * Represents a CaseCategoryLookupComponent.
   */
  constructor() {
  }

  ngOnInit() {
    // to show selected case category
    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
    this.caseCategorySelected = (preselected && preselected['caseCategory']) ? preselected['caseCategory'] : '';
    this.caseCategoryOptionList = this.page == 'FTE' ? FTECaseCategoryFilterList : CaseCategoryFilterList;
  }

  onCaseCategoryChange() {
    this.getCaseCategoryObj.emit(this.caseCategorySelected);
  }

  clearCaseCategory() {
    this.caseCategorySelected = [];
    this.getCaseCategoryObj.emit([]);
  }

}
