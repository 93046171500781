import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MinMaxLookupComponent } from './min-max-lookup.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FormsModule
  ],
  declarations: [MinMaxLookupComponent],
  exports: [MinMaxLookupComponent]
})
export class MinMaxLookupModule { }
