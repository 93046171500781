import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { PreloaderService } from 'shared/services/preloader.service';
import { FilterServiceApi, StateApi } from 'shared/sdk';


@Component({
    selector: 'app-state-lookup',
    templateUrl: './state-lookup.component.html',
    styleUrls: ['./state-lookup.component.css']
})
export class StateLookupComponent implements OnInit {
    filteredState: any;
    selectedCountry: any;
    @Input() isClearable = true;
    @Input() isSearchable = true;
    @Input() isMultipleAllowed = true;
    @Input() getGlobalFilterValue = true; // if true it will set the localstorage value by default if there is any.
    @Input() stateName: string; // if value provided this will be selected by default for dropdown, getGlobalFilterValue key must be false.
    @Input() isdisabled = false;
    @Input() isCountryCode = false;
    /**
    * To get Country Name from parent component.
    * Using this country-Name it will search for matching state in Database.
    * @param {String} name - name of country
    */
    @Input() set countryName(name: string) {
        let isName = true;
        if(this.isCountryCode){
            isName = false; 
        }
        if(name){
            this.loadDropdownData(name, isName); 
        }        
    }

    @Input() set resetData(isClear) {
        this.clearFromParent();
    }

    /**
    * To get Country Object in array, from parent component
    * From that object country code will be taken to search for state in Database
    * @param {Array} params - Array of country Object
    */
    @Input() set setCountryObj(params) {
        this.clearFromParent(true);
        this.selectedCountry = params;
        if (this.selectedCountry !== null && this.selectedCountry !== undefined && this.selectedCountry !== '') {
            if (this.selectedCountry && this.selectedCountry[0] && this.selectedCountry[0].Name) {
                this.loadDropdownData(this.selectedCountry[0].Name);
            }
        }
    }
    @Input() boxedLayout;
    @Output() selectedState: EventEmitter<any> = new EventEmitter;
    states: any = [];
    selectedValue;

    constructor(private _preloaderService: PreloaderService,
        private _filterServiceApi: FilterServiceApi, private _stateApi: StateApi) { }

    ngOnInit() {
        if (this.getGlobalFilterValue) {
            const preSelected = localStorage.getItem('filterObj');
            if (preSelected && JSON.parse(preSelected) &&
                JSON.parse(preSelected)['states'] && JSON.parse(preSelected)['states'].length) {
                this.selectedValue = JSON.parse(preSelected)['states'];
            }
        } else if (this.stateName) {
            this.selectedValue = this.stateName;
        }
    }

    onChange() {
        this.selectedState.emit(this.selectedValue);
    }

    loadDropdownData(val, isName?: boolean) {
        // get data for states
        this.selectedValue = '';
        this.states = [];
        this._preloaderService.showPreloader();
        const whereObj = (isName) ? { country_name: val } : { country_code: val } ;
        this._stateApi.find({ where: whereObj }).subscribe(
            data => {
                if (data) {
                    this._preloaderService.hidePreloader();
                    this.filteredState = data;
                    this.states = this.filteredState.map(item => item.name);
                    if (this.stateName && !this.getGlobalFilterValue && this.states.includes(this.stateName)) {
                        this.selectedValue = this.stateName;
                    } else {
                        // add else as on select country, a cross is appearing in the state lookup
                        this.clearFromParent(true);
                    }
                }
            },
            err => {
                console.log('Error fetching states>>', err.message);
            }
        );
    }

    clearFromParent(initiateOnChange?) {
        this.selectedValue = null;
        if (initiateOnChange) {
            this.onChange();
        }
    }
 
}
