import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { ContactLookupComponent } from './contact-lookup.component';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule
  ],
  declarations: [ContactLookupComponent],
  exports: [ContactLookupComponent],
  providers: []
})
export class ContactLookupModule { }
