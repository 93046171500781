/* tslint:disable */
import {
  Account,
  Case,
  Job,
  Milestone,
  Project2,
  WorkOrder,
  Contact,
  Worker,
  Program,
  PurchaseOrder,
  Order,
  Assignment,
  AccessControl,
  Department,
  ConversationRelation,
  Favorite,
  JobApplication,
  ProblemTaskReasonCode,
  ChangeAssignedToGroup,
  ChangeAssignedToVendorWorker,
  AssociatedTask,
  RequestFormReceiptLine
} from '../index';

declare var Object: any;
export interface TaskInterface {
  "sfdcId"?: string;
  "Name"?: string;
  "OwnerId"?: string;
  "LastModifiedById"?: string;
  "CurrencyIsoCode"?: string;
  "CreatedById"?: string;
  "createdOnServiceo"?: boolean;
  "Complete__c"?: number;
  "Case__c"?: string;
  "Containment__c"?: string;
  "Description__c"?: string;
  "Due_Date__c"?: Date;
  "Due_Time__c"?: string;
  "Due_Date_Time__c"?: Date;
  "Duration__c"?: number;
  "Duration_Type__c"?: string;
  "Escalation_Status__c"?: string;
  "Identify_Root_Cause__c"?: string;
  "Job__c"?: string;
  "PgMO_Milestones__c"?: string;
  "PgMO_Parent_Task__c"?: string;
  "PgMO_Projects__c"?: string;
  "Priority__c"?: string;
  "Risk_Status__c"?: string;
  "Send_Email__c"?: boolean;
  "Sequence_Number__c"?: number;
  "Start_Date__c"?: Date;
  "Task_No__c"?: string;
  "Task_Status__c"?: string;
  "Work_Order__c"?: string;
  "RecordTypeId"?: string;
  "Account__c"?: string;
  "Account_RFI_Form__c"?: string;
  "Assigned_to_Team_Shift__c"?: string;
  "Changed_Required__c"?: string;
  "Change_Priority__c"?: string;
  "Completed_Date__c"?: Date;
  "Created_By_Contact__c"?: string;
  "Department_Group__c"?: string;
  "Duration_Hours__c"?: number;
  "Escalation__c"?: string;
  "Escalation_Resolution_Status__c"?: string;
  "Exp_Implementation_Date_for_Change__c"?: Date;
  "Form_Name__c"?: string;
  "How_to_reproduce_Bug__c"?: string;
  "ICC_Case_Number__c"?: string;
  "Intended_Outcome_of_the_Change__c"?: string;
  "Job_Number__c"?: string;
  "Last_Modified_By__c"?: string;
  "Last_Modified_By__c_On_Serviceo"?: string;
  "Logged_In_Contact__c"?: string;
  "Milestone_Library_Id__c"?: string;
  "Milestone_PMS_Case_Number__c"?: string;
  "Parent_Escalated_Task__c"?: string;
  "Parent_Task_Library_Id__c"?: string;
  "Payment__c"?: string;
  "PgMO_Programs__c"?: string;
  "PMC_Case_Number__c"?: string;
  "PMS_Case_Number__c"?: string;
  "Problem_Name__c"?: string;
  "Proposed_ApproachSteps_for_the_Change__c"?: string;
  "Proposed_Change__c"?: string;
  "Purchase_Invoice__c"?: string;
  "Purchase_Order__c"?: string;
  "QuoteManager__c"?: string;
  "Reason_Category__c"?: string;
  "Problem_Resolution_Suggestion_Notes__c"?: string;
  "Reason_Description__c"?: string;
  "Reason_for_Change_Not_Needed__c"?: string;
  "Reason_for_the_Change__c"?: string;
  "Reason_Sub_Category__c"?: string;
  "Related_Record_Type_Name__c"?: string;
  "Task_Type__c"?: string;
  "Related_PMS_Case__c"?: string;
  "Related_Work_Order__c"?: string;
  "Request_Description__c"?: string;
  "Requested_By_Department__c"?: string;
  "Request_Form_Receipt__c"?: string;
  "Request_Form_Receipt_Lines__c"?: string;
  "Requestor__c"?: string;
  "Request_Type__c"?: string;
  "Resolution_Date__c"?: Date;
  "Resolution_Summary__c"?: string;
  "Sales_Invoice__c"?: string;
  "Sales_Order__c"?: string;
  "Screen_Shots_included__c"?: boolean;
  "SLA_Criteria_Met__c"?: string;
  "Source_Category__c"?: string;
  "SLA_Met__c"?: boolean;
  "Source_Department__c"?: string;
  "Source_DepartmentGroup__c"?: string;
  "Source_Task_Deparments__c"?: string;
  "Sub_Category__c"?: string;
  "Task_Completed__c"?: boolean;
  "Task_Library_Id__c"?: string;
  "Task_Name__c"?: string;
  "Time_to_Complete__c"?: number;
  "Weightage__c"?: number;
  "Key_Result_Type_Name_Label__c"?: string;
  "KR_Number_Start__c"?: number;
  "Problem_Type__c"?: string;
  "KR_Percentage_Start__c"?: number;
  "IRON_ExtID__c"?: string;
  "Work_Order_Line_Number__c"?: string;
  "Work_Order_NumberF__c"?: string;
  "Work_Order_Number__c"?: string;
  "Work_Order_Plan_Date__c"?: Date;
  "Deliverables_L5_Due_Date__c"?: Date;
  "Appointment__c"?: string;
  "Appointment_Schedule_Start_Date__c"?: string;
  "Assigned_to_Shift__c"?: string;
  "BillReceipt__c"?: string;
  "Child_Task_Type__c"?: string;
  "Customer_Appointment_Date_Time_Schedule__c"?: string;
  "Customer_Appointment_Schedule_Start_Date__c"?: string;
  "Customer_Appointment_Schedule_Start_Hour__c"?: string;
  "Customer_Appointment_Schedule_Start_Mi__c"?: string;
  "End_Date_Time__c"?: string;
  "Expense_Report_Reference__c"?: string;
  "Icon__c"?: string;
  "Icon_URL__c"?: string;
  "Issue_Category__c"?: string;
  "Key_Results_Rating__c"?: string;
  "Key_Result_Type__c"?: string;
  "KR_Binary_Value__c"?: string;
  "KR_Number_Current__c"?: string;
  "KR_Number_Target__c"?: string;
  "KR_Percentage_Current__c"?: string;
  "KR_Percentage_Target__c"?: number;
  "Objective_Assignment_Group__c"?: string;
  "Project_RecordType__c"?: string;
  "Record_Number__c"?: string;
  "Request_Form_Receipt_Escalation__c"?: string;
  "Start_Date_Time__c"?: Date;
  "Start_Time__c"?: string;
  "Task_Library_Lookup__c"?: string;
  "Worker__c"?: string;
  "WSC_Request_Detail__c"?: string;
  "WSC_Request_Header__c"?: string;
  "Problem_Resolution_Status__c"?: string;
  "SOP_Training_Completed__c"?: string;
  "Application__c"?: string;
  "Interview_Id__c"?: string;
  "Offer_Id__c"?: string;
  "Placement_Id__c"?: string;
  "Submittal_ID__c"?: string;
  "Reference_Object__c"?: string;
  "Stage_Status__c"?: string;
  "Sub_Stage_Status__c"?: string;
  "CreatedDate"?: Date;
  "IsDeleted"?: boolean;
  "LastModifiedDate"?: Date;
  "LastReferencedDate"?: Date;
  "LastViewedDate"?: Date;
  "Worker_Performance_Request_Detail__c"?: string;
  "Worker_Performance_Request_Header__c"?: string;
  "Placement_Task_Type__c"?: string;
  "Placement__c"?: string;
  "Task_Number__c"?: string;
  "Is_Deleted__c"?: boolean;
  "Moved_To_Next_Cycle__c"?: boolean;
  "OKR_Status__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  account?: Account;
  case?: Case;
  job?: Job;
  milestone?: Milestone;
  task?: Task;
  subTasks?: Task[];
  project?: Project2;
  workorder?: WorkOrder;
  relatedWorkOrder?: WorkOrder;
  createdByContact?: Contact;
  updatedOnServiceoByContact?: Contact;
  updatedByContact?: Contact;
  requestorWorker?: Worker;
  program?: Program;
  pmsCase?: Case;
  relatedPmsCase?: Case;
  iccCase?: Case;
  purchaseOrder?: PurchaseOrder;
  salesOrder?: Order;
  assignment?: Assignment[];
  accessControl?: AccessControl[];
  escalatedParentTask?: Task;
  requestByDepartment?: Department;
  loggedInContact?: Contact;
  conversationRelation?: ConversationRelation[];
  favorite?: Favorite[];
  jobApplication?: JobApplication;
  problemReason?: ProblemTaskReasonCode[];
  changeAssignedToGroup?: ChangeAssignedToGroup[];
  changeAssignedToWorker?: ChangeAssignedToVendorWorker[];
  associatedProblemTasks?: AssociatedTask[];
  associatedTask?: AssociatedTask;
  requestFormReceiptLine?: RequestFormReceiptLine;
}

export class Task implements TaskInterface {
  "sfdcId": string;
  "Name": string;
  "OwnerId": string;
  "LastModifiedById": string;
  "CurrencyIsoCode": string;
  "CreatedById": string;
  "createdOnServiceo": boolean;
  "Complete__c": number;
  "Case__c": string;
  "Containment__c": string;
  "Description__c": string;
  "Due_Date__c": Date;
  "Due_Time__c": string;
  "Due_Date_Time__c": Date;
  "Duration__c": number;
  "Duration_Type__c": string;
  "Escalation_Status__c": string;
  "Identify_Root_Cause__c": string;
  "Job__c": string;
  "PgMO_Milestones__c": string;
  "PgMO_Parent_Task__c": string;
  "PgMO_Projects__c": string;
  "Priority__c": string;
  "Risk_Status__c": string;
  "Send_Email__c": boolean;
  "Sequence_Number__c": number;
  "Start_Date__c": Date;
  "Task_No__c": string;
  "Task_Status__c": string;
  "Work_Order__c": string;
  "RecordTypeId": string;
  "Account__c": string;
  "Account_RFI_Form__c": string;
  "Assigned_to_Team_Shift__c": string;
  "Changed_Required__c": string;
  "Change_Priority__c": string;
  "Completed_Date__c": Date;
  "Created_By_Contact__c": string;
  "Department_Group__c": string;
  "Duration_Hours__c": number;
  "Escalation__c": string;
  "Escalation_Resolution_Status__c": string;
  "Exp_Implementation_Date_for_Change__c": Date;
  "Form_Name__c": string;
  "How_to_reproduce_Bug__c": string;
  "ICC_Case_Number__c": string;
  "Intended_Outcome_of_the_Change__c": string;
  "Job_Number__c": string;
  "Last_Modified_By__c": string;
  "Last_Modified_By__c_On_Serviceo": string;
  "Logged_In_Contact__c": string;
  "Milestone_Library_Id__c": string;
  "Milestone_PMS_Case_Number__c": string;
  "Parent_Escalated_Task__c": string;
  "Parent_Task_Library_Id__c": string;
  "Payment__c": string;
  "PgMO_Programs__c": string;
  "PMC_Case_Number__c": string;
  "PMS_Case_Number__c": string;
  "Problem_Name__c": string;
  "Proposed_ApproachSteps_for_the_Change__c": string;
  "Proposed_Change__c": string;
  "Purchase_Invoice__c": string;
  "Purchase_Order__c": string;
  "QuoteManager__c": string;
  "Reason_Category__c": string;
  "Problem_Resolution_Suggestion_Notes__c": string;
  "Reason_Description__c": string;
  "Reason_for_Change_Not_Needed__c": string;
  "Reason_for_the_Change__c": string;
  "Reason_Sub_Category__c": string;
  "Related_Record_Type_Name__c": string;
  "Task_Type__c": string;
  "Related_PMS_Case__c": string;
  "Related_Work_Order__c": string;
  "Request_Description__c": string;
  "Requested_By_Department__c": string;
  "Request_Form_Receipt__c": string;
  "Request_Form_Receipt_Lines__c": string;
  "Requestor__c": string;
  "Request_Type__c": string;
  "Resolution_Date__c": Date;
  "Resolution_Summary__c": string;
  "Sales_Invoice__c": string;
  "Sales_Order__c": string;
  "Screen_Shots_included__c": boolean;
  "SLA_Criteria_Met__c": string;
  "Source_Category__c": string;
  "SLA_Met__c": boolean;
  "Source_Department__c": string;
  "Source_DepartmentGroup__c": string;
  "Source_Task_Deparments__c": string;
  "Sub_Category__c": string;
  "Task_Completed__c": boolean;
  "Task_Library_Id__c": string;
  "Task_Name__c": string;
  "Time_to_Complete__c": number;
  "Weightage__c": number;
  "Key_Result_Type_Name_Label__c": string;
  "KR_Number_Start__c": number;
  "Problem_Type__c": string;
  "KR_Percentage_Start__c": number;
  "IRON_ExtID__c": string;
  "Work_Order_Line_Number__c": string;
  "Work_Order_NumberF__c": string;
  "Work_Order_Number__c": string;
  "Work_Order_Plan_Date__c": Date;
  "Deliverables_L5_Due_Date__c": Date;
  "Appointment__c": string;
  "Appointment_Schedule_Start_Date__c": string;
  "Assigned_to_Shift__c": string;
  "BillReceipt__c": string;
  "Child_Task_Type__c": string;
  "Customer_Appointment_Date_Time_Schedule__c": string;
  "Customer_Appointment_Schedule_Start_Date__c": string;
  "Customer_Appointment_Schedule_Start_Hour__c": string;
  "Customer_Appointment_Schedule_Start_Mi__c": string;
  "End_Date_Time__c": string;
  "Expense_Report_Reference__c": string;
  "Icon__c": string;
  "Icon_URL__c": string;
  "Issue_Category__c": string;
  "Key_Results_Rating__c": string;
  "Key_Result_Type__c": string;
  "KR_Binary_Value__c": string;
  "KR_Number_Current__c": string;
  "KR_Number_Target__c": string;
  "KR_Percentage_Current__c": string;
  "KR_Percentage_Target__c": number;
  "Objective_Assignment_Group__c": string;
  "Project_RecordType__c": string;
  "Record_Number__c": string;
  "Request_Form_Receipt_Escalation__c": string;
  "Start_Date_Time__c": Date;
  "Start_Time__c": string;
  "Task_Library_Lookup__c": string;
  "Worker__c": string;
  "WSC_Request_Detail__c": string;
  "WSC_Request_Header__c": string;
  "Problem_Resolution_Status__c": string;
  "SOP_Training_Completed__c": string;
  "Application__c": string;
  "Interview_Id__c": string;
  "Offer_Id__c": string;
  "Placement_Id__c": string;
  "Submittal_ID__c": string;
  "Reference_Object__c": string;
  "Stage_Status__c": string;
  "Sub_Stage_Status__c": string;
  "CreatedDate": Date;
  "IsDeleted": boolean;
  "LastModifiedDate": Date;
  "LastReferencedDate": Date;
  "LastViewedDate": Date;
  "Worker_Performance_Request_Detail__c": string;
  "Worker_Performance_Request_Header__c": string;
  "Placement_Task_Type__c": string;
  "Placement__c": string;
  "Task_Number__c": string;
  "Is_Deleted__c": boolean;
  "Moved_To_Next_Cycle__c": boolean;
  "OKR_Status__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  account: Account;
  case: Case;
  job: Job;
  milestone: Milestone;
  task: Task;
  subTasks: Task[];
  project: Project2;
  workorder: WorkOrder;
  relatedWorkOrder: WorkOrder;
  createdByContact: Contact;
  updatedOnServiceoByContact: Contact;
  updatedByContact: Contact;
  requestorWorker: Worker;
  program: Program;
  pmsCase: Case;
  relatedPmsCase: Case;
  iccCase: Case;
  purchaseOrder: PurchaseOrder;
  salesOrder: Order;
  assignment: Assignment[];
  accessControl: AccessControl[];
  escalatedParentTask: Task;
  requestByDepartment: Department;
  loggedInContact: Contact;
  conversationRelation: ConversationRelation[];
  favorite: Favorite[];
  jobApplication: JobApplication;
  problemReason: ProblemTaskReasonCode[];
  changeAssignedToGroup: ChangeAssignedToGroup[];
  changeAssignedToWorker: ChangeAssignedToVendorWorker[];
  associatedProblemTasks: AssociatedTask[];
  associatedTask: AssociatedTask;
  constructor(data?: TaskInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Task`.
   */
  public static getModelName() {
    return "Task";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Task for dynamic purposes.
  **/
  public static factory(data: TaskInterface): Task{
    return new Task(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Task',
      plural: 'Tasks',
      path: 'Tasks',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "createdOnServiceo": {
          name: 'createdOnServiceo',
          type: 'boolean'
        },
        "Complete__c": {
          name: 'Complete__c',
          type: 'number'
        },
        "Case__c": {
          name: 'Case__c',
          type: 'string'
        },
        "Containment__c": {
          name: 'Containment__c',
          type: 'string'
        },
        "Description__c": {
          name: 'Description__c',
          type: 'string'
        },
        "Due_Date__c": {
          name: 'Due_Date__c',
          type: 'Date'
        },
        "Due_Time__c": {
          name: 'Due_Time__c',
          type: 'string'
        },
        "Due_Date_Time__c": {
          name: 'Due_Date_Time__c',
          type: 'Date'
        },
        "Duration__c": {
          name: 'Duration__c',
          type: 'number'
        },
        "Duration_Type__c": {
          name: 'Duration_Type__c',
          type: 'string'
        },
        "Escalation_Status__c": {
          name: 'Escalation_Status__c',
          type: 'string'
        },
        "Identify_Root_Cause__c": {
          name: 'Identify_Root_Cause__c',
          type: 'string'
        },
        "Job__c": {
          name: 'Job__c',
          type: 'string'
        },
        "PgMO_Milestones__c": {
          name: 'PgMO_Milestones__c',
          type: 'string'
        },
        "PgMO_Parent_Task__c": {
          name: 'PgMO_Parent_Task__c',
          type: 'string'
        },
        "PgMO_Projects__c": {
          name: 'PgMO_Projects__c',
          type: 'string'
        },
        "Priority__c": {
          name: 'Priority__c',
          type: 'string'
        },
        "Risk_Status__c": {
          name: 'Risk_Status__c',
          type: 'string'
        },
        "Send_Email__c": {
          name: 'Send_Email__c',
          type: 'boolean'
        },
        "Sequence_Number__c": {
          name: 'Sequence_Number__c',
          type: 'number'
        },
        "Start_Date__c": {
          name: 'Start_Date__c',
          type: 'Date'
        },
        "Task_No__c": {
          name: 'Task_No__c',
          type: 'string'
        },
        "Task_Status__c": {
          name: 'Task_Status__c',
          type: 'string'
        },
        "Work_Order__c": {
          name: 'Work_Order__c',
          type: 'string'
        },
        "RecordTypeId": {
          name: 'RecordTypeId',
          type: 'string'
        },
        "Account__c": {
          name: 'Account__c',
          type: 'string'
        },
        "Account_RFI_Form__c": {
          name: 'Account_RFI_Form__c',
          type: 'string'
        },
        "Assigned_to_Team_Shift__c": {
          name: 'Assigned_to_Team_Shift__c',
          type: 'string'
        },
        "Changed_Required__c": {
          name: 'Changed_Required__c',
          type: 'string'
        },
        "Change_Priority__c": {
          name: 'Change_Priority__c',
          type: 'string'
        },
        "Completed_Date__c": {
          name: 'Completed_Date__c',
          type: 'Date'
        },
        "Created_By_Contact__c": {
          name: 'Created_By_Contact__c',
          type: 'string'
        },
        "Department_Group__c": {
          name: 'Department_Group__c',
          type: 'string'
        },
        "Duration_Hours__c": {
          name: 'Duration_Hours__c',
          type: 'number'
        },
        "Escalation__c": {
          name: 'Escalation__c',
          type: 'string'
        },
        "Escalation_Resolution_Status__c": {
          name: 'Escalation_Resolution_Status__c',
          type: 'string'
        },
        "Exp_Implementation_Date_for_Change__c": {
          name: 'Exp_Implementation_Date_for_Change__c',
          type: 'Date'
        },
        "Form_Name__c": {
          name: 'Form_Name__c',
          type: 'string'
        },
        "How_to_reproduce_Bug__c": {
          name: 'How_to_reproduce_Bug__c',
          type: 'string'
        },
        "ICC_Case_Number__c": {
          name: 'ICC_Case_Number__c',
          type: 'string'
        },
        "Intended_Outcome_of_the_Change__c": {
          name: 'Intended_Outcome_of_the_Change__c',
          type: 'string'
        },
        "Job_Number__c": {
          name: 'Job_Number__c',
          type: 'string'
        },
        "Last_Modified_By__c": {
          name: 'Last_Modified_By__c',
          type: 'string'
        },
        "Last_Modified_By__c_On_Serviceo": {
          name: 'Last_Modified_By__c_On_Serviceo',
          type: 'string'
        },
        "Logged_In_Contact__c": {
          name: 'Logged_In_Contact__c',
          type: 'string'
        },
        "Milestone_Library_Id__c": {
          name: 'Milestone_Library_Id__c',
          type: 'string'
        },
        "Milestone_PMS_Case_Number__c": {
          name: 'Milestone_PMS_Case_Number__c',
          type: 'string'
        },
        "Parent_Escalated_Task__c": {
          name: 'Parent_Escalated_Task__c',
          type: 'string'
        },
        "Parent_Task_Library_Id__c": {
          name: 'Parent_Task_Library_Id__c',
          type: 'string'
        },
        "Payment__c": {
          name: 'Payment__c',
          type: 'string'
        },
        "PgMO_Programs__c": {
          name: 'PgMO_Programs__c',
          type: 'string'
        },
        "PMC_Case_Number__c": {
          name: 'PMC_Case_Number__c',
          type: 'string'
        },
        "PMS_Case_Number__c": {
          name: 'PMS_Case_Number__c',
          type: 'string'
        },
        "Problem_Name__c": {
          name: 'Problem_Name__c',
          type: 'string'
        },
        "Proposed_ApproachSteps_for_the_Change__c": {
          name: 'Proposed_ApproachSteps_for_the_Change__c',
          type: 'string'
        },
        "Proposed_Change__c": {
          name: 'Proposed_Change__c',
          type: 'string'
        },
        "Purchase_Invoice__c": {
          name: 'Purchase_Invoice__c',
          type: 'string'
        },
        "Purchase_Order__c": {
          name: 'Purchase_Order__c',
          type: 'string'
        },
        "QuoteManager__c": {
          name: 'QuoteManager__c',
          type: 'string'
        },
        "Reason_Category__c": {
          name: 'Reason_Category__c',
          type: 'string'
        },
        "Problem_Resolution_Suggestion_Notes__c": {
          name: 'Problem_Resolution_Suggestion_Notes__c',
          type: 'string'
        },
        "Reason_Description__c": {
          name: 'Reason_Description__c',
          type: 'string'
        },
        "Reason_for_Change_Not_Needed__c": {
          name: 'Reason_for_Change_Not_Needed__c',
          type: 'string'
        },
        "Reason_for_the_Change__c": {
          name: 'Reason_for_the_Change__c',
          type: 'string'
        },
        "Reason_Sub_Category__c": {
          name: 'Reason_Sub_Category__c',
          type: 'string'
        },
        "Related_Record_Type_Name__c": {
          name: 'Related_Record_Type_Name__c',
          type: 'string'
        },
        "Task_Type__c": {
          name: 'Task_Type__c',
          type: 'string'
        },
        "Related_PMS_Case__c": {
          name: 'Related_PMS_Case__c',
          type: 'string'
        },
        "Related_Work_Order__c": {
          name: 'Related_Work_Order__c',
          type: 'string'
        },
        "Request_Description__c": {
          name: 'Request_Description__c',
          type: 'string'
        },
        "Requested_By_Department__c": {
          name: 'Requested_By_Department__c',
          type: 'string'
        },
        "Request_Form_Receipt__c": {
          name: 'Request_Form_Receipt__c',
          type: 'string'
        },
        "Request_Form_Receipt_Lines__c": {
          name: 'Request_Form_Receipt_Lines__c',
          type: 'string'
        },
        "Requestor__c": {
          name: 'Requestor__c',
          type: 'string'
        },
        "Request_Type__c": {
          name: 'Request_Type__c',
          type: 'string'
        },
        "Resolution_Date__c": {
          name: 'Resolution_Date__c',
          type: 'Date'
        },
        "Resolution_Summary__c": {
          name: 'Resolution_Summary__c',
          type: 'string'
        },
        "Sales_Invoice__c": {
          name: 'Sales_Invoice__c',
          type: 'string'
        },
        "Sales_Order__c": {
          name: 'Sales_Order__c',
          type: 'string'
        },
        "Screen_Shots_included__c": {
          name: 'Screen_Shots_included__c',
          type: 'boolean'
        },
        "SLA_Criteria_Met__c": {
          name: 'SLA_Criteria_Met__c',
          type: 'string'
        },
        "Source_Category__c": {
          name: 'Source_Category__c',
          type: 'string'
        },
        "SLA_Met__c": {
          name: 'SLA_Met__c',
          type: 'boolean'
        },
        "Source_Department__c": {
          name: 'Source_Department__c',
          type: 'string'
        },
        "Source_DepartmentGroup__c": {
          name: 'Source_DepartmentGroup__c',
          type: 'string'
        },
        "Source_Task_Deparments__c": {
          name: 'Source_Task_Deparments__c',
          type: 'string'
        },
        "Sub_Category__c": {
          name: 'Sub_Category__c',
          type: 'string'
        },
        "Task_Completed__c": {
          name: 'Task_Completed__c',
          type: 'boolean'
        },
        "Task_Library_Id__c": {
          name: 'Task_Library_Id__c',
          type: 'string'
        },
        "Task_Name__c": {
          name: 'Task_Name__c',
          type: 'string'
        },
        "Time_to_Complete__c": {
          name: 'Time_to_Complete__c',
          type: 'number'
        },
        "Weightage__c": {
          name: 'Weightage__c',
          type: 'number'
        },
        "Key_Result_Type_Name_Label__c": {
          name: 'Key_Result_Type_Name_Label__c',
          type: 'string'
        },
        "KR_Number_Start__c": {
          name: 'KR_Number_Start__c',
          type: 'number'
        },
        "Problem_Type__c": {
          name: 'Problem_Type__c',
          type: 'string'
        },
        "KR_Percentage_Start__c": {
          name: 'KR_Percentage_Start__c',
          type: 'number'
        },
        "IRON_ExtID__c": {
          name: 'IRON_ExtID__c',
          type: 'string'
        },
        "Work_Order_Line_Number__c": {
          name: 'Work_Order_Line_Number__c',
          type: 'string'
        },
        "Work_Order_NumberF__c": {
          name: 'Work_Order_NumberF__c',
          type: 'string'
        },
        "Work_Order_Number__c": {
          name: 'Work_Order_Number__c',
          type: 'string'
        },
        "Work_Order_Plan_Date__c": {
          name: 'Work_Order_Plan_Date__c',
          type: 'Date'
        },
        "Deliverables_L5_Due_Date__c": {
          name: 'Deliverables_L5_Due_Date__c',
          type: 'Date'
        },
        "Appointment__c": {
          name: 'Appointment__c',
          type: 'string'
        },
        "Appointment_Schedule_Start_Date__c": {
          name: 'Appointment_Schedule_Start_Date__c',
          type: 'string'
        },
        "Assigned_to_Shift__c": {
          name: 'Assigned_to_Shift__c',
          type: 'string'
        },
        "BillReceipt__c": {
          name: 'BillReceipt__c',
          type: 'string'
        },
        "Child_Task_Type__c": {
          name: 'Child_Task_Type__c',
          type: 'string'
        },
        "Customer_Appointment_Date_Time_Schedule__c": {
          name: 'Customer_Appointment_Date_Time_Schedule__c',
          type: 'string'
        },
        "Customer_Appointment_Schedule_Start_Date__c": {
          name: 'Customer_Appointment_Schedule_Start_Date__c',
          type: 'string'
        },
        "Customer_Appointment_Schedule_Start_Hour__c": {
          name: 'Customer_Appointment_Schedule_Start_Hour__c',
          type: 'string'
        },
        "Customer_Appointment_Schedule_Start_Mi__c": {
          name: 'Customer_Appointment_Schedule_Start_Mi__c',
          type: 'string'
        },
        "End_Date_Time__c": {
          name: 'End_Date_Time__c',
          type: 'string'
        },
        "Expense_Report_Reference__c": {
          name: 'Expense_Report_Reference__c',
          type: 'string'
        },
        "Icon__c": {
          name: 'Icon__c',
          type: 'string'
        },
        "Icon_URL__c": {
          name: 'Icon_URL__c',
          type: 'string'
        },
        "Issue_Category__c": {
          name: 'Issue_Category__c',
          type: 'string'
        },
        "Key_Results_Rating__c": {
          name: 'Key_Results_Rating__c',
          type: 'string'
        },
        "Key_Result_Type__c": {
          name: 'Key_Result_Type__c',
          type: 'string'
        },
        "KR_Binary_Value__c": {
          name: 'KR_Binary_Value__c',
          type: 'string'
        },
        "KR_Number_Current__c": {
          name: 'KR_Number_Current__c',
          type: 'string'
        },
        "KR_Number_Target__c": {
          name: 'KR_Number_Target__c',
          type: 'string'
        },
        "KR_Percentage_Current__c": {
          name: 'KR_Percentage_Current__c',
          type: 'string'
        },
        "KR_Percentage_Target__c": {
          name: 'KR_Percentage_Target__c',
          type: 'number'
        },
        "Objective_Assignment_Group__c": {
          name: 'Objective_Assignment_Group__c',
          type: 'string'
        },
        "Project_RecordType__c": {
          name: 'Project_RecordType__c',
          type: 'string'
        },
        "Record_Number__c": {
          name: 'Record_Number__c',
          type: 'string'
        },
        "Request_Form_Receipt_Escalation__c": {
          name: 'Request_Form_Receipt_Escalation__c',
          type: 'string'
        },
        "Start_Date_Time__c": {
          name: 'Start_Date_Time__c',
          type: 'Date'
        },
        "Start_Time__c": {
          name: 'Start_Time__c',
          type: 'string'
        },
        "Task_Library_Lookup__c": {
          name: 'Task_Library_Lookup__c',
          type: 'string'
        },
        "Worker__c": {
          name: 'Worker__c',
          type: 'string'
        },
        "WSC_Request_Detail__c": {
          name: 'WSC_Request_Detail__c',
          type: 'string'
        },
        "WSC_Request_Header__c": {
          name: 'WSC_Request_Header__c',
          type: 'string'
        },
        "Problem_Resolution_Status__c": {
          name: 'Problem_Resolution_Status__c',
          type: 'string'
        },
        "SOP_Training_Completed__c": {
          name: 'SOP_Training_Completed__c',
          type: 'string'
        },
        "Application__c": {
          name: 'Application__c',
          type: 'string'
        },
        "Interview_Id__c": {
          name: 'Interview_Id__c',
          type: 'string'
        },
        "Offer_Id__c": {
          name: 'Offer_Id__c',
          type: 'string'
        },
        "Placement_Id__c": {
          name: 'Placement_Id__c',
          type: 'string'
        },
        "Submittal_ID__c": {
          name: 'Submittal_ID__c',
          type: 'string'
        },
        "Reference_Object__c": {
          name: 'Reference_Object__c',
          type: 'string'
        },
        "Stage_Status__c": {
          name: 'Stage_Status__c',
          type: 'string'
        },
        "Sub_Stage_Status__c": {
          name: 'Sub_Stage_Status__c',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "LastReferencedDate": {
          name: 'LastReferencedDate',
          type: 'Date'
        },
        "LastViewedDate": {
          name: 'LastViewedDate',
          type: 'Date'
        },
        "Worker_Performance_Request_Detail__c": {
          name: 'Worker_Performance_Request_Detail__c',
          type: 'string'
        },
        "Worker_Performance_Request_Header__c": {
          name: 'Worker_Performance_Request_Header__c',
          type: 'string'
        },
        "Placement_Task_Type__c": {
          name: 'Placement_Task_Type__c',
          type: 'string'
        },
        "Placement__c": {
          name: 'Placement__c',
          type: 'string'
        },
        "Task_Number__c": {
          name: 'Task_Number__c',
          type: 'string'
        },
        "Is_Deleted__c": {
          name: 'Is_Deleted__c',
          type: 'boolean'
        },
        "Moved_To_Next_Cycle__c": {
          name: 'Moved_To_Next_Cycle__c',
          type: 'boolean'
        },
        "OKR_Status__c": {
          name: 'OKR_Status__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'Account__c',
          keyTo: 'sfdcId'
        },
        case: {
          name: 'case',
          type: 'Case',
          model: 'Case',
          relationType: 'belongsTo',
                  keyFrom: 'Case__c',
          keyTo: 'sfdcId'
        },
        job: {
          name: 'job',
          type: 'Job',
          model: 'Job',
          relationType: 'belongsTo',
                  keyFrom: 'Job_Number__c',
          keyTo: 'sfdcId'
        },
        milestone: {
          name: 'milestone',
          type: 'Milestone',
          model: 'Milestone',
          relationType: 'belongsTo',
                  keyFrom: 'PgMO_Milestones__c',
          keyTo: 'sfdcId'
        },
        task: {
          name: 'task',
          type: 'Task',
          model: 'Task',
          relationType: 'belongsTo',
                  keyFrom: 'PgMO_Parent_Task__c',
          keyTo: 'sfdcId'
        },
        subTasks: {
          name: 'subTasks',
          type: 'Task[]',
          model: 'Task',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'PgMO_Parent_Task__c'
        },
        project: {
          name: 'project',
          type: 'Project2',
          model: 'Project2',
          relationType: 'belongsTo',
                  keyFrom: 'PgMO_Projects__c',
          keyTo: 'sfdcId'
        },
        workorder: {
          name: 'workorder',
          type: 'WorkOrder',
          model: 'WorkOrder',
          relationType: 'belongsTo',
                  keyFrom: 'Work_Order_Number__c',
          keyTo: 'sfdcId'
        },
        relatedWorkOrder: {
          name: 'relatedWorkOrder',
          type: 'WorkOrder',
          model: 'WorkOrder',
          relationType: 'belongsTo',
                  keyFrom: 'Related_Work_Order__c',
          keyTo: 'sfdcId'
        },
        createdByContact: {
          name: 'createdByContact',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Created_By_Contact__c',
          keyTo: 'sfdcId'
        },
        updatedOnServiceoByContact: {
          name: 'updatedOnServiceoByContact',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Last_Modified_By__c_On_Serviceo',
          keyTo: 'sfdcId'
        },
        updatedByContact: {
          name: 'updatedByContact',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Last_Modified_By__c',
          keyTo: 'sfdcId'
        },
        requestorWorker: {
          name: 'requestorWorker',
          type: 'Worker',
          model: 'Worker',
          relationType: 'belongsTo',
                  keyFrom: 'Requestor__c',
          keyTo: 'sfdcId'
        },
        program: {
          name: 'program',
          type: 'Program',
          model: 'Program',
          relationType: 'belongsTo',
                  keyFrom: 'PgMO_Programs__c',
          keyTo: 'sfdcId'
        },
        pmsCase: {
          name: 'pmsCase',
          type: 'Case',
          model: 'Case',
          relationType: 'belongsTo',
                  keyFrom: 'PMS_Case_Number__c',
          keyTo: 'sfdcId'
        },
        relatedPmsCase: {
          name: 'relatedPmsCase',
          type: 'Case',
          model: 'Case',
          relationType: 'belongsTo',
                  keyFrom: 'Related_PMS_Case__c',
          keyTo: 'sfdcId'
        },
        iccCase: {
          name: 'iccCase',
          type: 'Case',
          model: 'Case',
          relationType: 'belongsTo',
                  keyFrom: 'ICC_Case_Number__c',
          keyTo: 'sfdcId'
        },
        purchaseOrder: {
          name: 'purchaseOrder',
          type: 'PurchaseOrder',
          model: 'PurchaseOrder',
          relationType: 'belongsTo',
                  keyFrom: 'Purchase_Order__c',
          keyTo: 'sfdcId'
        },
        salesOrder: {
          name: 'salesOrder',
          type: 'Order',
          model: 'Order',
          relationType: 'belongsTo',
                  keyFrom: 'Sales_Order__c',
          keyTo: 'sfdcId'
        },
        assignment: {
          name: 'assignment',
          type: 'Assignment[]',
          model: 'Assignment',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'PgMO_Tasks__c'
        },
        accessControl: {
          name: 'accessControl',
          type: 'AccessControl[]',
          model: 'AccessControl',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'PgMO_Tasks__c'
        },
        escalatedParentTask: {
          name: 'escalatedParentTask',
          type: 'Task',
          model: 'Task',
          relationType: 'belongsTo',
                  keyFrom: 'Parent_Escalated_Task__c',
          keyTo: 'sfdcId'
        },
        requestByDepartment: {
          name: 'requestByDepartment',
          type: 'Department',
          model: 'Department',
          relationType: 'belongsTo',
                  keyFrom: 'Requested_By_Department__c',
          keyTo: 'sfdcId'
        },
        loggedInContact: {
          name: 'loggedInContact',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Logged_In_Contact__c',
          keyTo: 'sfdcId'
        },
        conversationRelation: {
          name: 'conversationRelation',
          type: 'ConversationRelation[]',
          model: 'ConversationRelation',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'Related_to_Record_Id__c'
        },
        favorite: {
          name: 'favorite',
          type: 'Favorite[]',
          model: 'Favorite',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'modelId'
        },
        jobApplication: {
          name: 'jobApplication',
          type: 'JobApplication',
          model: 'JobApplication',
          relationType: 'belongsTo',
                  keyFrom: 'Application__c',
          keyTo: 'sfdcId'
        },
        problemReason: {
          name: 'problemReason',
          type: 'ProblemTaskReasonCode[]',
          model: 'ProblemTaskReasonCode',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'PgMO_Tasks__c'
        },
        changeAssignedToGroup: {
          name: 'changeAssignedToGroup',
          type: 'ChangeAssignedToGroup[]',
          model: 'ChangeAssignedToGroup',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'PgMO_Tasks__c'
        },
        changeAssignedToWorker: {
          name: 'changeAssignedToWorker',
          type: 'ChangeAssignedToVendorWorker[]',
          model: 'ChangeAssignedToVendorWorker',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'PgMO_Tasks__c'
        },
        associatedProblemTasks: {
          name: 'associatedProblemTasks',
          type: 'AssociatedTask[]',
          model: 'AssociatedTask',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'PgMO_Problem__c'
        },
        associatedTask: {
          name: 'associatedTask',
          type: 'AssociatedTask',
          model: 'AssociatedTask',
          relationType: 'hasOne',
                  keyFrom: 'sfdcId',
          keyTo: 'Associated_Pgmo_Problem__c'
        },
      }
    }
  }
}
