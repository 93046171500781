import { Component, OnInit, EventEmitter, Output, OnDestroy, Input } from '@angular/core';
import { NotificationService } from '../../../services/notification.service';
import { PreloaderService } from 'shared/services/preloader.service';
import { Observable, Subscription } from 'rxjs';
import { CommonService } from 'shared/services/common.service';
import { AppStateService } from 'shared/services/app-state.service';

@Component({
  selector: 'app-job-feed-list',
  templateUrl: './job-feed-list.component.html',
  styleUrls: ['./job-feed-list.component.css']
})
export class JobFeedListComponent implements OnInit, OnDestroy {

  @Input()
  set filterData(e) {
    if (e) {
      this.jobActivities = [];
      this.page.pageNumber = 1;
      this.jobFilter = e;
      this.accessType = this._appState.getAccessType();
      if (localStorage.getItem('viewAsInfo')) {
        this.viewAsInfo = JSON.parse(localStorage.getItem('viewAsInfo'));
        const viewAsPartner = (this.viewAsInfo && this.viewAsInfo.viewAsType === 'Partner');
        this.accessType = (viewAsPartner) ? 'partner' : this.accessType;
      }
       if (this.accessType === 'partner') {
        this.activityType = 'case_activity';
      }
      this.getFeeds(this.page);
    }
  }
  activityType: any;
  @Output() selectedJob = new EventEmitter();
  activeId: any;
  loadingIndicator = false;
  page = {
    size: 20,
    totalElements: 0,
    totalPages: 0,
    pageNumber: 1
  };
  jobActivities = [];
  private _statusCss = {
    'In-Progress': 'badge-warning',
    Resolved: 'badge-info',
    New: 'badge-danger',
    Closed: 'badge-success'
  };
  isLoadMore = false;
  jobFilter: any;
  private timerSub: Subscription;
  isActivityLoaded = false;
  accessType: any;
  userRelatedProjects: any;
  viewAsInfo: any;
  constructor(
    private _notificationService: NotificationService,
    private _loader: PreloaderService,
    private _commonService: CommonService,
    private _appState: AppStateService
  ) { }

 async ngOnInit() {
    this.accessType = this._appState.getAccessType();
    if (localStorage.getItem('viewAsInfo')) {
      this.viewAsInfo = JSON.parse(localStorage.getItem('viewAsInfo'));
      const viewAsPartner = (this.viewAsInfo && this.viewAsInfo.viewAsType === 'Partner');
      this.accessType = (viewAsPartner) ? 'partner' : this.accessType;
    }
    this._commonService.saveCurrentPageAnalytics('Global Chat', 'Activity Feed')
    // this.timerSub = Observable.timer(0, 20000).subscribe(() => {
    //   this.setPage(this.page)
    // });

    if (this.accessType !== 'partner') {
      // retrieve filters from local storage
      if (localStorage.getItem('filterCondition')) {
        this.jobFilter = JSON.parse(localStorage.getItem('filterCondition'));
      }
      this.setPage(this.page);
    }
  }

  async getFeeds(offset) {
    const whereCondition = {entity_name: 'Job'};
    if (this.activityType === 'case_activity') {
      this.jobFilter['AccountId'] = this.jobFilter.CKSW_BASE__Account__c.inq[0];
      if (this.viewAsInfo && this.viewAsInfo.program && this.viewAsInfo.program.sfdcId) {
        this.jobFilter['Project_SOP__c'] = [this.viewAsInfo.program.sfdcId]
      } else {
        this.jobFilter['Project_SOP__c'] = await this._commonService.getUserAssignedProjectList();
      }
      this.jobFilter['activityType'] = 'case_activity'
      whereCondition.entity_name = 'Case';
    }
    // this.selectFirstRecord();
    this._loader.showPreloader();
    this.loadingIndicator = true;
    this._notificationService.getActivityByJob(offset['pageNumber'], this.page.size, whereCondition, this.jobFilter).then((data: any) => {
        this.isActivityLoaded = true;
        this._loader.hidePreloader();
        this.loadingIndicator = false;
        if (data && data['data']) {
          this.jobActivities = this.isLoadMore ? [...this.jobActivities, ...data['data']] : data['data'];
          this.selectFirstRecord();
          this.getStatusClass();
          this.isLoadMore = this.jobActivities && this.jobActivities.length < data['total'] ? true : false;
          this.page.pageNumber = offset['pageNumber'] + 1;
        } else {
          this.selectedJob.emit({});
          this.isLoadMore = false;
        }
      })
      .catch(err => {
        this._loader.hidePreloader();
        this.loadingIndicator = false;
        console.log(err);
      });
  }

  /**
   * Populate the table with new data based on the page number
   * @param page The page to select
   */
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.pageNumber;
    this.getFeeds(this.page);
  }

  /**
   * set job-status classes
   */
  getStatusClass() {
    this.jobActivities.filter(data => {
      if (
        this._statusCss.hasOwnProperty(
          data['Dispatch_Service_Resolution_Status__c']
        )
      ) {
        data['jobStatusClass'] = this._statusCss[
          data['Dispatch_Service_Resolution_Status__c']
        ];
      } else {
        data['jobStatusClass'] = 'badge-secondary';
      }
      return data;
    });
  }

  /**
   * select first item of list
   */
  selectFirstRecord() {
    if (this.jobActivities.length) {
      this.activeId = this.jobActivities[0].id;
      const emitObj = {
        job: this.jobActivities[0],
        where: { id: this.jobActivities[0].id },
        isActivityLoaded: true
      }
      if (this.accessType === 'partner') {
        delete emitObj.job;
        emitObj['case'] = this.jobActivities[0],
        emitObj['where']['AccountId'] = this.jobFilter.CKSW_BASE__Account__c.inq[0];
        emitObj['where']['Project_SOP__c'] = this.jobFilter.Project_SOP__c;
        emitObj['where']['Type'] = 'activityPerCase';
      }
      this.selectedJob.emit(emitObj);
    } else {
      this.selectedJob.emit({
        isActivityLoaded: false
      });
    }
  }

  onClick(event: Event, item: any, button: string) {
    event.stopPropagation();
    this.activeId = item.id;
    const emitObj = {
      job: item,
      where: { id: item.id }
    }
    if (this.accessType === 'partner') {
      delete emitObj.job;
      emitObj['case'] = item,
      emitObj['where']['AccountId'] = this.jobFilter.CKSW_BASE__Account__c.inq[0];
      emitObj['where']['Project_SOP__c'] = this.jobFilter.Project_SOP__c;
      emitObj['where']['Type'] = 'activityPerCase';
    }
    this.selectedJob.emit(emitObj);
  }

  onRefresh() {
    this.setPage({ pageNumber: 1 });
  }

  unsubscribeTimer() {
    if (this.timerSub) {
      this.timerSub.unsubscribe();
    }
  }

  ngOnDestroy() {
    // this.unsubscribeTimer();
  }
}

