import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isInUse'
})
export class IsInUsePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if(value && value.length){
      return value.filter(ele => ele.isInUse === false);
    }else{
      return value;
    }
  }

}
