/* tslint:disable */

declare var Object: any;
export interface CorrectiveActionRequestLineInterface {
  "sfdcId"?: string;
  "Name"?: string;
  "LastModifiedById"?: string;
  "CurrencyIsoCode"?: string;
  "CreatedById"?: string;
  "Control_Plan__c"?: string;
  "Corrective_Action_Request__c"?: string;
  "Description__c"?: string;
  "Label__c"?: string;
  "Preventive_Actions__c"?: string;
  "Status__c"?: string;
  "Type__c"?: string;
  "Verifications__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class CorrectiveActionRequestLine implements CorrectiveActionRequestLineInterface {
  "sfdcId": string;
  "Name": string;
  "LastModifiedById": string;
  "CurrencyIsoCode": string;
  "CreatedById": string;
  "Control_Plan__c": string;
  "Corrective_Action_Request__c": string;
  "Description__c": string;
  "Label__c": string;
  "Preventive_Actions__c": string;
  "Status__c": string;
  "Type__c": string;
  "Verifications__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: CorrectiveActionRequestLineInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `CorrectiveActionRequestLine`.
   */
  public static getModelName() {
    return "CorrectiveActionRequestLine";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of CorrectiveActionRequestLine for dynamic purposes.
  **/
  public static factory(data: CorrectiveActionRequestLineInterface): CorrectiveActionRequestLine{
    return new CorrectiveActionRequestLine(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'CorrectiveActionRequestLine',
      plural: 'CorrectiveActionRequestLines',
      path: 'CorrectiveActionRequestLines',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "Control_Plan__c": {
          name: 'Control_Plan__c',
          type: 'string'
        },
        "Corrective_Action_Request__c": {
          name: 'Corrective_Action_Request__c',
          type: 'string'
        },
        "Description__c": {
          name: 'Description__c',
          type: 'string'
        },
        "Label__c": {
          name: 'Label__c',
          type: 'string'
        },
        "Preventive_Actions__c": {
          name: 'Preventive_Actions__c',
          type: 'string'
        },
        "Status__c": {
          name: 'Status__c',
          type: 'string'
        },
        "Type__c": {
          name: 'Type__c',
          type: 'string'
        },
        "Verifications__c": {
          name: 'Verifications__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
