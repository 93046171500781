import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { WslAccountStatusLookupComponent } from './wsl-account-status-lookup.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule
  ],
  declarations: [WslAccountStatusLookupComponent],
  exports: [WslAccountStatusLookupComponent]
})
export class WslAccountStatusLookupModule { }
