/* tslint:disable */

declare var Object: any;
export interface TrainingPlanFeedbackInterface {
  "sfdcId"?: string;
  "IsDeleted"?: boolean;
  "Name"?: string;
  "CurrencyIsoCode"?: string;
  "CreatedDate"?: Date;
  "CreatedById"?: string;
  "LastModifiedDate"?: Date;
  "LastModifiedById"?: string;
  "SystemModstamp"?: Date;
  "LastActivityDate"?: Date;
  "LastViewedDate"?: Date;
  "Training_Plan__c"?: string;
  "User__c"?: string;
  "Trainee__c"?: string;
  "Rating__c"?: number;
  "Comments__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class TrainingPlanFeedback implements TrainingPlanFeedbackInterface {
  "sfdcId": string;
  "IsDeleted": boolean;
  "Name": string;
  "CurrencyIsoCode": string;
  "CreatedDate": Date;
  "CreatedById": string;
  "LastModifiedDate": Date;
  "LastModifiedById": string;
  "SystemModstamp": Date;
  "LastActivityDate": Date;
  "LastViewedDate": Date;
  "Training_Plan__c": string;
  "User__c": string;
  "Trainee__c": string;
  "Rating__c": number;
  "Comments__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: TrainingPlanFeedbackInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `TrainingPlanFeedback`.
   */
  public static getModelName() {
    return "TrainingPlanFeedback";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of TrainingPlanFeedback for dynamic purposes.
  **/
  public static factory(data: TrainingPlanFeedbackInterface): TrainingPlanFeedback{
    return new TrainingPlanFeedback(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'TrainingPlanFeedback',
      plural: 'TrainingPlanFeedbacks',
      path: 'TrainingPlanFeedbacks',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "SystemModstamp": {
          name: 'SystemModstamp',
          type: 'Date'
        },
        "LastActivityDate": {
          name: 'LastActivityDate',
          type: 'Date'
        },
        "LastViewedDate": {
          name: 'LastViewedDate',
          type: 'Date'
        },
        "Training_Plan__c": {
          name: 'Training_Plan__c',
          type: 'string'
        },
        "User__c": {
          name: 'User__c',
          type: 'string'
        },
        "Trainee__c": {
          name: 'Trainee__c',
          type: 'string'
        },
        "Rating__c": {
          name: 'Rating__c',
          type: 'number'
        },
        "Comments__c": {
          name: 'Comments__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
