/* tslint:disable */
import {
  Users,
  Pricelist,
  Vendorsite,
  Project,
  Product,
  Contact,
  CountryCode,
  MetroVirtualVendorPool,
  RecordType,
  GeoMessage,
  VCSQDLead,
  GeoPoint
} from '../index';

declare var Object: any;
export interface AccountInterface {
  "sfdcId"?: string;
  "ACH_Routing_Number__c"?: string;
  "AX_Identification_Number__c"?: string;
  "AccountSource"?: string;
  "Account_Code__c"?: string;
  "Account_ID__c"?: string;
  "Account_Inventory_Location__c"?: string;
  "Account_Name__c"?: string;
  "Account_Number__c"?: string;
  "Account_Safe_Id__c"?: string;
  "Account_Tier__c"?: string;
  "Account_Type__c"?: string;
  "Active_FSL__c"?: boolean;
  "Additional_Business_Location_s_Contacts__c"?: string;
  "Address_Verification_Status__c"?: string;
  "Affiliated_with_any_other_Company_as__c"?: string;
  "AnnualRevenue"?: number;
  "Annual_Revenue_Consulting__c"?: number;
  "Annual_Revenue_Hardware__c"?: number;
  "Annual_Revenue_Logistics__c"?: number;
  "Annual_Revenue_Others__c"?: number;
  "Annual_Revenue_Software__c"?: number;
  "Annual_Revenue_Technical__c"?: number;
  "Annual_Revenue__c"?: number;
  "Applicable_SLAs__c"?: string;
  "B2B_FRU_SKU_Identifier__c"?: string;
  "B2B_Integration_Enabled__c"?: boolean;
  "Bank_Account_Number__c"?: string;
  "Bank_Account_Type__c"?: string;
  "Beneficiary_Account_Name__c"?: string;
  "Beneficiary_Bank_Address_City__c"?: string;
  "Beneficiary_Bank_Address_Country__c"?: string;
  "Beneficiary_Bank_Address_State_Province__c"?: string;
  "Beneficiary_Bank_Address_Street_Address__c"?: string;
  "Beneficiary_Bank_Address_Zip_Postal__c"?: string;
  "Beneficiary_Bank_Name__c"?: string;
  "BillingAddress"?: string;
  "BillingCity"?: string;
  "BillingCountry"?: string;
  "BillingGeocodeAccuracy"?: string;
  "BillingLatitude"?: number;
  "BillingLongitude"?: number;
  "BillingPostalCode"?: string;
  "BillingState"?: string;
  "BillingStreet"?: string;
  "Billing_country_Code__c"?: string;
  "Business_Linked_URL__c"?: string;
  "Business_Registration_Number__c"?: string;
  "Business_Size_c__c"?: string;
  "Business_Started_Founded__c"?: number;
  "Business_Type__c"?: string;
  "Case_Type__c"?: string;
  "Certificate_of_Incorporation_Company_Reg__c"?: boolean;
  "Certifications__c"?: string;
  "ChannelProgramLevelName"?: string;
  "ChannelProgramName"?: string;
  "Community_Type__c"?: string;
  "Company_Certifications__c"?: string;
  "Company_Dispatch_Group_Email__c"?: string;
  "Company_Established_Year__c"?: number;
  "Company_Overview__c"?: string;
  "Company_Reference_code__c"?: string;
  "Contact_Email__c"?: string;
  "Contact_Type__c"?: string;
  "Countries_Supported__c"?: string;
  "CreatedById"?: string;
  "CreatedDate"?: Date;
  "Created_updated_from_survey__c"?: boolean;
  "CurrencyIsoCode"?: string;
  "Current_Employer__c"?: string;
  "Customer_Overview_Field_Services__c"?: any;
  "Customer_Overview_Key__c"?: any;
  "DataCenter_Operations_Hardware_Assembler__c"?: boolean;
  "Data_Center_Assembler_Next_Buss_Day_SLA__c"?: boolean;
  "Data_Center_Engr_Next_Business_Day_SLA__c"?: boolean;
  "Data_Center_Facilities_Manager__c"?: boolean;
  "Data_Center_Facilities_Technician_Level1__c"?: boolean;
  "Data_Center_Mgr_Next_Business_Day_SLA__c"?: boolean;
  "Data_Center_Operations_Engineer_Level_1__c"?: boolean;
  "Data_Center_Technician_Next_Buss_Day_SLA__c"?: boolean;
  "Default_Owner_for_Case__c"?: string;
  "Default_Pricelist__c"?: string;
  "Default_SKU__c"?: string;
  "Deployment_Project_Only__c"?: boolean;
  "Describe_Your_Staffing_Services__c"?: string;
  "Description"?: string;
  "Desktop_Support_Next_Business_Day_SLA__c"?: boolean;
  "Desktop_Support_Technicians_Level_1__c"?: boolean;
  "Distance_from_capital_KM__c"?: number;
  "Do_you_accept_bank_payments_in_EURO__c"?: boolean;
  "Do_you_accept_bank_payments_in_US__c"?: boolean;
  "Email_to_Case_Subject__c"?: string;
  "Engagement_Type__c"?: string;
  "FLASH_FSL_Setup__c"?: string;
  "FSE_Network_Coverage_Support_Type__c"?: string;
  "FSL_Location_ID_Backup__c"?: string;
  "FSL_Location_SLA_Supported_Setup_NBD__c"?: string;
  "FSL_Location_Status__c"?: string;
  "FS_Dispatch_Management_Overview_External__c"?: string;
  "FS_Dispatch_Management_Overview_Internal__c"?: string;
  "FS_On_HW_Same_Bus_Day_4Hours_SLA_24x7__c"?: boolean;
  "FS_On_HW_Same_Business_Day_4_Hours_SLA__c"?: boolean;
  "FS_Onsite_Next_Business_Day_SLA__c"?: boolean;
  "Fax"?: string;
  "Field_Engineer_4HR_SLA_Rate_US_HR__c"?: number;
  "Field_Engineer_NBD_SLA_Rate_US_HR__c"?: number;
  "Field_Project_Manager_Rate_US_HR__c"?: number;
  "Field_Service_24x7_Onsite_SLA__c"?: boolean;
  "GST_VAT_Registration_certificate__c"?: boolean;
  "GST_VAT_Registration_Number__c"?: string;
  "Help_Desk_Technical_Support_Engineer__c"?: boolean;
  "Helpdesk_Next_Business_Day_SLA__c"?: boolean;
  "IBAN__c"?: string;
  "If_NOT_US_EURO_specify_currency__c"?: string;
  "Industry"?: string;
  "Industry__c"?: string;
  "Integration_Data_Type__c"?: string;
  "Integration_Service_Name__c"?: string;
  "Invoice_Due_Date_Offset__c"?: number;
  "IsCustomerPortal"?: boolean;
  "IsDeleted"?: boolean;
  "IsLimitedWarranty__c"?: boolean;
  "IsPartner"?: boolean;
  "Jigsaw"?: string;
  "JigsawCompanyId"?: string;
  "Job_Category__c"?: string;
  "Jobsite_Selection__c"?: string;
  "Languages_Supported__c"?: string;
  "LastActivityDate"?: Date;
  "LastModifiedById"?: string;
  "LastModifiedDate"?: Date;
  "LastReferencedDate"?: Date;
  "LastViewedDate"?: Date;
  "LeadId__c"?: string;
  "LinkedIn_Employee_Count__c"?: number;
  "Logo__c"?: string;
  "MasterRecordId"?: string;
  "Master_Project_Name__c"?: string;
  "Master_Project_Status__c"?: string;
  "Master_Project__c"?: string;
  "Minimum_Hours__c"?: number;
  "Multiple_Location_Details__c"?: string;
  "Multiple_Location__c"?: boolean;
  "Name"?: string;
  "Network_Server_Engineer_MS_Windows__c"?: boolean;
  "Network_Server_Tech_Next_Buss_Day_SLA__c"?: boolean;
  "Network_Server_Technician_MS_Windows__c"?: boolean;
  "Network_Srv_Engr_Next_Business_Day_SLA__c"?: boolean;
  "Network_Storage_Engineer_Level_2__c"?: boolean;
  "Network_Storage_Engr_Next_Buss_Day_SLA__c"?: boolean;
  "Next_Business_Day_Service__c"?: boolean;
  "NumberOfEmployees"?: number;
  "Number_of_Contractors_Field_Technicians__c"?: string;
  "Number_of_Employees_Field_Technicians__c"?: string;
  "Number_of_Employees_Total__c"?: number;
  "Number_of_Employees__c"?: string;
  "Number_of_Sub_Contractors_Field_Technic__c"?: string;
  "On_Demand_Technical_Resources_Coverage__c"?: string;
  "On_Demand_Technical_Resources_Pricing__c"?: string;
  "OperatingHoursId"?: string;
  "OwnerId"?: string;
  "ParentId"?: string;
  "Partner_Account__c"?: string;
  "Partner_Account_for_Security__c"?: string;
  "Partner_Manage_Inventory_Vendor_Account__c"?: string;
  "Partner_Pricelist__c"?: string;
  "Partner_Status__c"?: string;
  "Partner_Type__c"?: string;
  "Payment_Term__c"?: string;
  "Phone"?: string;
  "PhotoUrl"?: string;
  "Primary_Contact_Email__c"?: string;
  "Primary_Contact_Name__c"?: string;
  "Primary_Contact_Phone__c"?: string;
  "Primary_Vendor_PPE_Hours_Profile__c"?: string;
  "Process_Type__c"?: string;
  "Project_Manager_Onsite_IT_Project__c"?: boolean;
  "Project_Mgr_Onsite_Next_Business_Day_SLA__c"?: boolean;
  "Rate_US_10__c"?: number;
  "Rate_US_11__c"?: number;
  "Rate_US_12__c"?: number;
  "Rate_US_13__c"?: number;
  "Rate_US_14__c"?: number;
  "Rate_US_15__c"?: number;
  "Rate_US_16__c"?: number;
  "Rate_US_17__c"?: number;
  "Rate_US_18__c"?: number;
  "Rate_US_19__c"?: number;
  "Rate_US_1__c"?: number;
  "Rate_US_20__c"?: number;
  "Rate_US_21__c"?: number;
  "Rate_US_22__c"?: number;
  "Rate_US_23__c"?: number;
  "Rate_US_2__c"?: number;
  "Rate_US_3__c"?: number;
  "Rate_US_4__c"?: number;
  "Rate_US_5__c"?: number;
  "Rate_US_6__c"?: number;
  "Rate_US_7__c"?: number;
  "Rate_US_8__c"?: number;
  "Rate_US_9__c"?: number;
  "RecordTypeID__c"?: string;
  "RecordTypeId"?: string;
  "Record_Id__c"?: string;
  "Region__c"?: string;
  "Regional_FSL_Gateway1__c"?: string;
  "Renewal_Contact_Email__c"?: string;
  "Renewal_Contact_Name__c"?: string;
  "Renewal_Contact_Phone__c"?: string;
  "Renewal_Contact__c"?: string;
  "Researched_website__c"?: string;
  "Same_Day_Service__c"?: boolean;
  "Sample_copy_of_invoice_from_Vendor__c"?: boolean;
  "Scanned_Copy_Cancelled_cheque_fromBank__c"?: boolean;
  "Service_Dispatch_Account_Name__c"?: string;
  "Service_Dispatch_Account_Reference__c"?: string;
  "Service_Global_Ref__c"?: string;
  "Service_Tax_Registration_number__c"?: string;
  "ShippingAddress"?: string;
  "ShippingCity"?: string;
  "ShippingCountry"?: string;
  "ShippingGeocodeAccuracy"?: string;
  "ShippingLatitude"?: number;
  "ShippingLongitude"?: number;
  "ShippingPostalCode"?: string;
  "ShippingState"?: string;
  "ShippingStreet"?: string;
  "Shipping_Address_Concatenated__c"?: string;
  "Shipping_country_Code__c"?: string;
  "SicDesc"?: string;
  "Skill_Level__c"?: string;
  "Source__c"?: string;
  "Standard_Coverage_Message__c"?: string;
  "Standard_Supported_FSL__c"?: string;
  "Standard_Work_Hours__c"?: number;
  "Sub_Inventory_Location__c"?: string;
  "Swift_Code__c"?: string;
  "Sync_Flag__c"?: boolean;
  "SystemModstamp"?: Date;
  "Tax_ID__c"?: string;
  "Tax_Identification_Number_certcopy__c"?: boolean;
  "Technology_Supported_Advance_Expertise__c"?: string;
  "Technology_Supported_Basic_Expertise__c"?: string;
  "Technology_Supported_Certified_Staff__c"?: string;
  "Timesheet_Offset__c"?: string;
  "Type"?: string;
  "Types_of_Technical_Resources_Specialty__c"?: string;
  "Vendor_Certifications__c"?: string;
  "Vendor_Expertise__c"?: string;
  "Vendor_Type__c"?: string;
  "Vendors_Supported_Advance_Expertise__c"?: string;
  "Vendors_Supported_Certified_Staff__c"?: string;
  "WM_User_ID__c"?: string;
  "Webcase_Account_Name__c"?: string;
  "Website"?: string;
  "X3PL_Carrier__c"?: string;
  "X3PL_NBD_Service_Level__c"?: string;
  "X3PS_Account_Type__c"?: string;
  "X3PS_Business_Size__c"?: number;
  "X3PS_Contact_Registration_Status_Type__c"?: string;
  "X3PS_Location_Status__c"?: string;
  "X3rd_Party_Online_VMS__c"?: string;
  "current_Field_Service_Dispatch_Volume__c"?: string;
  "geolocation__Latitude__s"?: number;
  "geolocation__Longitude__s"?: number;
  "geolocation__c"?: GeoPoint;
  "isShippingAddressChanged__c"?: boolean;
  "ts2__Accounts_Payable__c"?: string;
  "ts2__Active__c"?: string;
  "ts2__Business_Unit__c"?: string;
  "ts2__Close_Pct__c"?: number;
  "ts2__Company_Status__c"?: string;
  "ts2__Credit_Limit__c"?: number;
  "ts2__CustomerPriority__c"?: any;
  "ts2__EMC_Certs__c"?: string;
  "ts2__Invoice_Terms__c"?: string;
  "ts2__Legacy_AccountID__c"?: string;
  "ts2__Memo__c"?: string;
  "ts2__NumberofLocations__c"?: number;
  "ts2__Requires_Background_Check__c"?: boolean;
  "ts2__Requires_Drug_Screen__c"?: boolean;
  "ts2__SLAExpirationDate__c"?: Date;
  "ts2__SLASerialNumber__c"?: string;
  "ts2__SLA__c"?: string;
  "ts2__Sent_Contract__c"?: boolean;
  "ts2__Sent_Literature__c"?: boolean;
  "ts2__Skill_Codes__c"?: string;
  "ts2__Source_Note__c"?: string;
  "ts2__Source__c"?: string;
  "ts2__Stage__c"?: string;
  "ts2__Status__c"?: string;
  "ts2__Students__c"?: number;
  "ts2__UpsellOpportunity__c"?: string;
  "ts2__Vendor_Note__c"?: string;
  "ts2__Vendor_Type__c"?: string;
  "vfirst__Adjudication__c"?: string;
  "vfirst__Site_Guest_Access_Helper__c"?: string;
  "vfirst__Stage__c"?: string;
  "vfirst__Verification_Report__c"?: string;
  "vfirst__of_Background_Checks_Ordered__c"?: number;
  "zeitzone__BillingLocalTime__c"?: string;
  "zeitzone__BillingLTFactor__c"?: string;
  "zeitzone__BillingTimeZoneId__c"?: string;
  "zeitzone__ShippingLocalTime__c"?: string;
  "zeitzone__ShippingLTFactor__c"?: number;
  "zeitzone__ShippingTimeZoneId__c"?: string;
  "FileCounter"?: number;
  "FolderLastModifiedOn"?: Date;
  "Vendor_minimum_Hours_Committed__c"?: number;
  "Overall_Vendor_min_Hrs_Geo_Allocated__c"?: number;
  "Remaining_Hours_Geo_Metro_Unallocated__c"?: number;
  "Vendor_Remaining_Hours_Current_Month__c"?: number;
  "Vendor_Assignment_Priority__c"?: string;
  "Signed_copy_of_NDA__c"?: boolean;
  "Signed_copy_of_MSA__c"?: boolean;
  "Umbrella_Liability_ins__c"?: boolean;
  "Cyber_Liability_ins__c"?: boolean;
  "Workers_Compensation_ins__c"?: boolean;
  "Employers_liability_ins__c"?: boolean;
  "automobile_liability_ins__c"?: boolean;
  "General_Liability_ins__c"?: boolean;
  "GDPR_Compliance__c"?: boolean;
  "cancelled_Cheque_of_Bank_Det__c"?: boolean;
  "Tax_Identification_Number_det__c"?: boolean;
  "Incorporation_Business_Registration_certificate_det__c"?:boolean;
  "service_Tax_Registration_det__c"?:boolean;
  "GST_VAT_Registration_certificate_det__c"?:boolean;
  "Primary_VCSQDLead_Ref__c"?: string;
  "Primary_Contact__c"?: string;
  "Primary_Worker_Skilling_Profile__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  account?: Account;
  vendorAccount?: Account;
  fslLocationAccount?: Account;
  fslAccount?: Account;
  vendorPPEHours?: Account;
  supportedFslAccount?: Account;
  users?: Users;
  pricelist?: Pricelist;
  vendorsite?: Vendorsite[];
  program?: Project;
  product?: Product;
  defaultContact?: Contact;
  renewalContact?: Contact;
  primaryContact?: Contact;
  contact?: Contact[];
  countryCode?: CountryCode;
  metroVirtualVendorPool?: MetroVirtualVendorPool[];
  RecordType?: RecordType;
  geoMessage?: GeoMessage;
  vcsqdLead?: VCSQDLead;
  accountRFI?: VCSQDLead;
}

export class Account implements AccountInterface {
  "sfdcId": string;
  "ACH_Routing_Number__c": string;
  "AX_Identification_Number__c": string;
  "AccountSource": string;
  "Account_Code__c": string;
  "Account_ID__c": string;
  "Account_Inventory_Location__c": string;
  "Account_Name__c": string;
  "Account_Number__c": string;
  "Account_Safe_Id__c": string;
  "Account_Tier__c": string;
  "Account_Type__c": string;
  "Active_FSL__c": boolean;
  "Additional_Business_Location_s_Contacts__c": string;
  "Address_Verification_Status__c": string;
  "Affiliated_with_any_other_Company_as__c": string;
  "AnnualRevenue": number;
  "Annual_Revenue_Consulting__c": number;
  "Annual_Revenue_Hardware__c": number;
  "Annual_Revenue_Logistics__c": number;
  "Annual_Revenue_Others__c": number;
  "Annual_Revenue_Software__c": number;
  "Annual_Revenue_Technical__c": number;
  "Annual_Revenue__c": number;
  "Applicable_SLAs__c": string;
  "B2B_FRU_SKU_Identifier__c": string;
  "B2B_Integration_Enabled__c": boolean;
  "Bank_Account_Number__c": string;
  "Bank_Account_Type__c": string;
  "Beneficiary_Account_Name__c": string;
  "Beneficiary_Bank_Address_City__c": string;
  "Beneficiary_Bank_Address_Country__c": string;
  "Beneficiary_Bank_Address_State_Province__c": string;
  "Beneficiary_Bank_Address_Street_Address__c": string;
  "Beneficiary_Bank_Address_Zip_Postal__c": string;
  "Beneficiary_Bank_Name__c": string;
  "BillingAddress": string;
  "BillingCity": string;
  "BillingCountry": string;
  "BillingGeocodeAccuracy": string;
  "BillingLatitude": number;
  "BillingLongitude": number;
  "BillingPostalCode": string;
  "BillingState": string;
  "BillingStreet": string;
  "Billing_country_Code__c": string;
  "Business_Linked_URL__c": string;
  "Business_Registration_Number__c": string;
  "Business_Size_c__c": string;
  "Business_Started_Founded__c": number;
  "Business_Type__c": string;
  "Case_Type__c": string;
  "Certificate_of_Incorporation_Company_Reg__c": boolean;
  "Certifications__c": string;
  "ChannelProgramLevelName": string;
  "ChannelProgramName": string;
  "Community_Type__c": string;
  "Company_Certifications__c": string;
  "Company_Dispatch_Group_Email__c": string;
  "Company_Established_Year__c": number;
  "Company_Overview__c": string;
  "Company_Reference_code__c": string;
  "Contact_Email__c": string;
  "Contact_Type__c": string;
  "Countries_Supported__c": string;
  "CreatedById": string;
  "CreatedDate": Date;
  "Created_updated_from_survey__c": boolean;
  "CurrencyIsoCode": string;
  "Current_Employer__c": string;
  "Customer_Overview_Field_Services__c": any;
  "Customer_Overview_Key__c": any;
  "DataCenter_Operations_Hardware_Assembler__c": boolean;
  "Data_Center_Assembler_Next_Buss_Day_SLA__c": boolean;
  "Data_Center_Engr_Next_Business_Day_SLA__c": boolean;
  "Data_Center_Facilities_Manager__c": boolean;
  "Data_Center_Facilities_Technician_Level1__c": boolean;
  "Data_Center_Mgr_Next_Business_Day_SLA__c": boolean;
  "Data_Center_Operations_Engineer_Level_1__c": boolean;
  "Data_Center_Technician_Next_Buss_Day_SLA__c": boolean;
  "Default_Owner_for_Case__c": string;
  "Default_Pricelist__c": string;
  "Default_SKU__c": string;
  "Deployment_Project_Only__c": boolean;
  "Describe_Your_Staffing_Services__c": string;
  "Description": string;
  "Desktop_Support_Next_Business_Day_SLA__c": boolean;
  "Desktop_Support_Technicians_Level_1__c": boolean;
  "Distance_from_capital_KM__c": number;
  "Do_you_accept_bank_payments_in_EURO__c": boolean;
  "Do_you_accept_bank_payments_in_US__c": boolean;
  "Email_to_Case_Subject__c": string;
  "Engagement_Type__c": string;
  "FLASH_FSL_Setup__c": string;
  "FSE_Network_Coverage_Support_Type__c": string;
  "FSL_Location_ID_Backup__c": string;
  "FSL_Location_SLA_Supported_Setup_NBD__c": string;
  "FSL_Location_Status__c": string;
  "FS_Dispatch_Management_Overview_External__c": string;
  "FS_Dispatch_Management_Overview_Internal__c": string;
  "FS_On_HW_Same_Bus_Day_4Hours_SLA_24x7__c": boolean;
  "FS_On_HW_Same_Business_Day_4_Hours_SLA__c": boolean;
  "FS_Onsite_Next_Business_Day_SLA__c": boolean;
  "Fax": string;
  "Field_Engineer_4HR_SLA_Rate_US_HR__c": number;
  "Field_Engineer_NBD_SLA_Rate_US_HR__c": number;
  "Field_Project_Manager_Rate_US_HR__c": number;
  "Field_Service_24x7_Onsite_SLA__c": boolean;
  "GST_VAT_Registration_certificate__c": boolean;
  "GST_VAT_Registration_Number__c": string;
  "Help_Desk_Technical_Support_Engineer__c": boolean;
  "Helpdesk_Next_Business_Day_SLA__c": boolean;
  "IBAN__c": string;
  "If_NOT_US_EURO_specify_currency__c": string;
  "Industry": string;
  "Industry__c": string;
  "Integration_Data_Type__c": string;
  "Integration_Service_Name__c": string;
  "Invoice_Due_Date_Offset__c": number;
  "IsCustomerPortal": boolean;
  "IsDeleted": boolean;
  "IsLimitedWarranty__c": boolean;
  "IsPartner": boolean;
  "Jigsaw": string;
  "JigsawCompanyId": string;
  "Job_Category__c": string;
  "Jobsite_Selection__c": string;
  "Languages_Supported__c": string;
  "LastActivityDate": Date;
  "LastModifiedById": string;
  "LastModifiedDate": Date;
  "LastReferencedDate": Date;
  "LastViewedDate": Date;
  "LeadId__c": string;
  "LinkedIn_Employee_Count__c": number;
  "Logo__c": string;
  "MasterRecordId": string;
  "Master_Project_Name__c": string;
  "Master_Project_Status__c": string;
  "Master_Project__c": string;
  "Minimum_Hours__c": number;
  "Multiple_Location_Details__c": string;
  "Multiple_Location__c": boolean;
  "Name": string;
  "Network_Server_Engineer_MS_Windows__c": boolean;
  "Network_Server_Tech_Next_Buss_Day_SLA__c": boolean;
  "Network_Server_Technician_MS_Windows__c": boolean;
  "Network_Srv_Engr_Next_Business_Day_SLA__c": boolean;
  "Network_Storage_Engineer_Level_2__c": boolean;
  "Network_Storage_Engr_Next_Buss_Day_SLA__c": boolean;
  "Next_Business_Day_Service__c": boolean;
  "NumberOfEmployees": number;
  "Number_of_Contractors_Field_Technicians__c": string;
  "Number_of_Employees_Field_Technicians__c": string;
  "Number_of_Employees_Total__c": number;
  "Number_of_Employees__c": string;
  "Number_of_Sub_Contractors_Field_Technic__c": string;
  "On_Demand_Technical_Resources_Coverage__c": string;
  "On_Demand_Technical_Resources_Pricing__c": string;
  "OperatingHoursId": string;
  "OwnerId": string;
  "ParentId": string;
  "Partner_Account__c": string;
  "Partner_Account_for_Security__c": string;
  "Partner_Manage_Inventory_Vendor_Account__c": string;
  "Partner_Pricelist__c": string;
  "Partner_Status__c": string;
  "Partner_Type__c": string;
  "Payment_Term__c": string;
  "Phone": string;
  "PhotoUrl": string;
  "Primary_Contact_Email__c": string;
  "Primary_Contact_Name__c": string;
  "Primary_Contact_Phone__c": string;
  "Primary_Vendor_PPE_Hours_Profile__c": string;
  "Process_Type__c": string;
  "Project_Manager_Onsite_IT_Project__c": boolean;
  "Project_Mgr_Onsite_Next_Business_Day_SLA__c": boolean;
  "Rate_US_10__c": number;
  "Rate_US_11__c": number;
  "Rate_US_12__c": number;
  "Rate_US_13__c": number;
  "Rate_US_14__c": number;
  "Rate_US_15__c": number;
  "Rate_US_16__c": number;
  "Rate_US_17__c": number;
  "Rate_US_18__c": number;
  "Rate_US_19__c": number;
  "Rate_US_1__c": number;
  "Rate_US_20__c": number;
  "Rate_US_21__c": number;
  "Rate_US_22__c": number;
  "Rate_US_23__c": number;
  "Rate_US_2__c": number;
  "Rate_US_3__c": number;
  "Rate_US_4__c": number;
  "Rate_US_5__c": number;
  "Rate_US_6__c": number;
  "Rate_US_7__c": number;
  "Rate_US_8__c": number;
  "Rate_US_9__c": number;
  "RecordTypeID__c": string;
  "RecordTypeId": string;
  "Record_Id__c": string;
  "Region__c": string;
  "Regional_FSL_Gateway1__c": string;
  "Renewal_Contact_Email__c": string;
  "Renewal_Contact_Name__c": string;
  "Renewal_Contact_Phone__c": string;
  "Renewal_Contact__c": string;
  "Researched_website__c": string;
  "Same_Day_Service__c": boolean;
  "Sample_copy_of_invoice_from_Vendor__c": boolean;
  "Scanned_Copy_Cancelled_cheque_fromBank__c": boolean;
  "Service_Dispatch_Account_Name__c": string;
  "Service_Dispatch_Account_Reference__c": string;
  "Service_Global_Ref__c": string;
  "Service_Tax_Registration_number__c": string;
  "ShippingAddress": string;
  "ShippingCity": string;
  "ShippingCountry": string;
  "ShippingGeocodeAccuracy": string;
  "ShippingLatitude": number;
  "ShippingLongitude": number;
  "ShippingPostalCode": string;
  "ShippingState": string;
  "ShippingStreet": string;
  "Shipping_Address_Concatenated__c": string;
  "Shipping_country_Code__c": string;
  "SicDesc": string;
  "Skill_Level__c": string;
  "Source__c": string;
  "Standard_Coverage_Message__c": string;
  "Standard_Supported_FSL__c": string;
  "Standard_Work_Hours__c": number;
  "Sub_Inventory_Location__c": string;
  "Swift_Code__c": string;
  "Sync_Flag__c": boolean;
  "SystemModstamp": Date;
  "Tax_ID__c": string;
  "Tax_Identification_Number_certcopy__c": boolean;
  "Technology_Supported_Advance_Expertise__c": string;
  "Technology_Supported_Basic_Expertise__c": string;
  "Technology_Supported_Certified_Staff__c": string;
  "Timesheet_Offset__c": string;
  "Type": string;
  "Types_of_Technical_Resources_Specialty__c": string;
  "Vendor_Certifications__c": string;
  "Vendor_Expertise__c": string;
  "Vendor_Type__c": string;
  "Vendors_Supported_Advance_Expertise__c": string;
  "Vendors_Supported_Certified_Staff__c": string;
  "WM_User_ID__c": string;
  "Webcase_Account_Name__c": string;
  "Website": string;
  "X3PL_Carrier__c": string;
  "X3PL_NBD_Service_Level__c": string;
  "X3PS_Account_Type__c": string;
  "X3PS_Business_Size__c": number;
  "X3PS_Contact_Registration_Status_Type__c": string;
  "X3PS_Location_Status__c": string;
  "X3rd_Party_Online_VMS__c": string;
  "current_Field_Service_Dispatch_Volume__c": string;
  "geolocation__Latitude__s": number;
  "geolocation__Longitude__s": number;
  "geolocation__c": GeoPoint;
  "isShippingAddressChanged__c": boolean;
  "ts2__Accounts_Payable__c": string;
  "ts2__Active__c": string;
  "ts2__Business_Unit__c": string;
  "ts2__Close_Pct__c": number;
  "ts2__Company_Status__c": string;
  "ts2__Credit_Limit__c": number;
  "ts2__CustomerPriority__c": any;
  "ts2__EMC_Certs__c": string;
  "ts2__Invoice_Terms__c": string;
  "ts2__Legacy_AccountID__c": string;
  "ts2__Memo__c": string;
  "ts2__NumberofLocations__c": number;
  "ts2__Requires_Background_Check__c": boolean;
  "ts2__Requires_Drug_Screen__c": boolean;
  "ts2__SLAExpirationDate__c": Date;
  "ts2__SLASerialNumber__c": string;
  "ts2__SLA__c": string;
  "ts2__Sent_Contract__c": boolean;
  "ts2__Sent_Literature__c": boolean;
  "ts2__Skill_Codes__c": string;
  "ts2__Source_Note__c": string;
  "ts2__Source__c": string;
  "ts2__Stage__c": string;
  "ts2__Status__c": string;
  "ts2__Students__c": number;
  "ts2__UpsellOpportunity__c": string;
  "ts2__Vendor_Note__c": string;
  "ts2__Vendor_Type__c": string;
  "vfirst__Adjudication__c": string;
  "vfirst__Site_Guest_Access_Helper__c": string;
  "vfirst__Stage__c": string;
  "vfirst__Verification_Report__c": string;
  "vfirst__of_Background_Checks_Ordered__c": number;
  "zeitzone__BillingLocalTime__c": string;
  "zeitzone__BillingLTFactor__c": string;
  "zeitzone__BillingTimeZoneId__c": string;
  "zeitzone__ShippingLocalTime__c": string;
  "zeitzone__ShippingLTFactor__c": number;
  "zeitzone__ShippingTimeZoneId__c": string;
  "FileCounter": number;
  "FolderLastModifiedOn": Date;
  "Vendor_minimum_Hours_Committed__c": number;
  "Overall_Vendor_min_Hrs_Geo_Allocated__c": number;
  "Remaining_Hours_Geo_Metro_Unallocated__c": number;
  "Vendor_Remaining_Hours_Current_Month__c": number;
  "Vendor_Assignment_Priority__c": string;
  "Signed_copy_of_NDA__c": boolean;
  "Signed_copy_of_MSA__c": boolean;
  "Umbrella_Liability_ins__c": boolean;
  "Cyber_Liability_ins__c": boolean;
  "Workers_Compensation_ins__c": boolean;
  "Employers_liability_ins__c": boolean;
  "automobile_liability_ins__c": boolean;
  "General_Liability_ins__c": boolean;
  "GDPR_Compliance__c": boolean;
  "cancelled_Cheque_of_Bank_Det__c": boolean;
  "Tax_Identification_Number_det__c": boolean;
  "Incorporation_Business_Registration_certificate_det__c": boolean;
  "service_Tax_Registration_det__c":boolean;
  "GST_VAT_Registration_certificate_det__c":boolean;
  "Primary_VCSQDLead_Ref__c": string;
  "Primary_Contact__c": string;
  "Primary_Worker_Skilling_Profile__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  account: Account;
  vendorAccount: Account;
  fslLocationAccount: Account;
  fslAccount: Account;
  vendorPPEHours: Account;
  supportedFslAccount: Account;
  users: Users;
  pricelist: Pricelist;
  vendorsite: Vendorsite[];
  program: Project;
  product: Product;
  defaultContact: Contact;
  renewalContact: Contact;
  primaryContact: Contact;
  contact: Contact[];
  countryCode: CountryCode;
  metroVirtualVendorPool: MetroVirtualVendorPool[];
  RecordType: RecordType;
  geoMessage: GeoMessage;
  vcsqdLead: VCSQDLead;
  accountRFI: VCSQDLead;
  constructor(data?: AccountInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Account`.
   */
  public static getModelName() {
    return "Account";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Account for dynamic purposes.
  **/
  public static factory(data: AccountInterface): Account{
    return new Account(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Account',
      plural: 'Accounts',
      path: 'Accounts',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "ACH_Routing_Number__c": {
          name: 'ACH_Routing_Number__c',
          type: 'string'
        },
        "AX_Identification_Number__c": {
          name: 'AX_Identification_Number__c',
          type: 'string'
        },
        "AccountSource": {
          name: 'AccountSource',
          type: 'string'
        },
        "Account_Code__c": {
          name: 'Account_Code__c',
          type: 'string'
        },
        "Account_ID__c": {
          name: 'Account_ID__c',
          type: 'string'
        },
        "Account_Inventory_Location__c": {
          name: 'Account_Inventory_Location__c',
          type: 'string'
        },
        "Account_Name__c": {
          name: 'Account_Name__c',
          type: 'string'
        },
        "Account_Number__c": {
          name: 'Account_Number__c',
          type: 'string'
        },
        "Account_Safe_Id__c": {
          name: 'Account_Safe_Id__c',
          type: 'string'
        },
        "Account_Tier__c": {
          name: 'Account_Tier__c',
          type: 'string'
        },
        "Account_Type__c": {
          name: 'Account_Type__c',
          type: 'string'
        },
        "Active_FSL__c": {
          name: 'Active_FSL__c',
          type: 'boolean'
        },
        "Additional_Business_Location_s_Contacts__c": {
          name: 'Additional_Business_Location_s_Contacts__c',
          type: 'string'
        },
        "Address_Verification_Status__c": {
          name: 'Address_Verification_Status__c',
          type: 'string'
        },
        "Affiliated_with_any_other_Company_as__c": {
          name: 'Affiliated_with_any_other_Company_as__c',
          type: 'string'
        },
        "AnnualRevenue": {
          name: 'AnnualRevenue',
          type: 'number'
        },
        "Annual_Revenue_Consulting__c": {
          name: 'Annual_Revenue_Consulting__c',
          type: 'number'
        },
        "Annual_Revenue_Hardware__c": {
          name: 'Annual_Revenue_Hardware__c',
          type: 'number'
        },
        "Annual_Revenue_Logistics__c": {
          name: 'Annual_Revenue_Logistics__c',
          type: 'number'
        },
        "Annual_Revenue_Others__c": {
          name: 'Annual_Revenue_Others__c',
          type: 'number'
        },
        "Annual_Revenue_Software__c": {
          name: 'Annual_Revenue_Software__c',
          type: 'number'
        },
        "Annual_Revenue_Technical__c": {
          name: 'Annual_Revenue_Technical__c',
          type: 'number'
        },
        "Annual_Revenue__c": {
          name: 'Annual_Revenue__c',
          type: 'number'
        },
        "Applicable_SLAs__c": {
          name: 'Applicable_SLAs__c',
          type: 'string'
        },
        "B2B_FRU_SKU_Identifier__c": {
          name: 'B2B_FRU_SKU_Identifier__c',
          type: 'string'
        },
        "B2B_Integration_Enabled__c": {
          name: 'B2B_Integration_Enabled__c',
          type: 'boolean'
        },
        "Bank_Account_Number__c": {
          name: 'Bank_Account_Number__c',
          type: 'string'
        },
        "Bank_Account_Type__c": {
          name: 'Bank_Account_Type__c',
          type: 'string'
        },
        "Beneficiary_Account_Name__c": {
          name: 'Beneficiary_Account_Name__c',
          type: 'string'
        },
        "Beneficiary_Bank_Address_City__c": {
          name: 'Beneficiary_Bank_Address_City__c',
          type: 'string'
        },
        "Beneficiary_Bank_Address_Country__c": {
          name: 'Beneficiary_Bank_Address_Country__c',
          type: 'string'
        },
        "Beneficiary_Bank_Address_State_Province__c": {
          name: 'Beneficiary_Bank_Address_State_Province__c',
          type: 'string'
        },
        "Beneficiary_Bank_Address_Street_Address__c": {
          name: 'Beneficiary_Bank_Address_Street_Address__c',
          type: 'string'
        },
        "Beneficiary_Bank_Address_Zip_Postal__c": {
          name: 'Beneficiary_Bank_Address_Zip_Postal__c',
          type: 'string'
        },
        "Beneficiary_Bank_Name__c": {
          name: 'Beneficiary_Bank_Name__c',
          type: 'string'
        },
        "BillingAddress": {
          name: 'BillingAddress',
          type: 'string'
        },
        "BillingCity": {
          name: 'BillingCity',
          type: 'string'
        },
        "BillingCountry": {
          name: 'BillingCountry',
          type: 'string'
        },
        "BillingGeocodeAccuracy": {
          name: 'BillingGeocodeAccuracy',
          type: 'string'
        },
        "BillingLatitude": {
          name: 'BillingLatitude',
          type: 'number'
        },
        "BillingLongitude": {
          name: 'BillingLongitude',
          type: 'number'
        },
        "BillingPostalCode": {
          name: 'BillingPostalCode',
          type: 'string'
        },
        "BillingState": {
          name: 'BillingState',
          type: 'string'
        },
        "BillingStreet": {
          name: 'BillingStreet',
          type: 'string'
        },
        "Billing_country_Code__c": {
          name: 'Billing_country_Code__c',
          type: 'string'
        },
        "Business_Linked_URL__c": {
          name: 'Business_Linked_URL__c',
          type: 'string'
        },
        "Business_Registration_Number__c": {
          name: 'Business_Registration_Number__c',
          type: 'string'
        },
        "Business_Size_c__c": {
          name: 'Business_Size_c__c',
          type: 'string'
        },
        "Business_Started_Founded__c": {
          name: 'Business_Started_Founded__c',
          type: 'number'
        },
        "Business_Type__c": {
          name: 'Business_Type__c',
          type: 'string'
        },
        "Case_Type__c": {
          name: 'Case_Type__c',
          type: 'string'
        },
        "Certificate_of_Incorporation_Company_Reg__c": {
          name: 'Certificate_of_Incorporation_Company_Reg__c',
          type: 'boolean'
        },
        "Certifications__c": {
          name: 'Certifications__c',
          type: 'string'
        },
        "ChannelProgramLevelName": {
          name: 'ChannelProgramLevelName',
          type: 'string'
        },
        "ChannelProgramName": {
          name: 'ChannelProgramName',
          type: 'string'
        },
        "Community_Type__c": {
          name: 'Community_Type__c',
          type: 'string'
        },
        "Company_Certifications__c": {
          name: 'Company_Certifications__c',
          type: 'string'
        },
        "Company_Dispatch_Group_Email__c": {
          name: 'Company_Dispatch_Group_Email__c',
          type: 'string'
        },
        "Company_Established_Year__c": {
          name: 'Company_Established_Year__c',
          type: 'number'
        },
        "Company_Overview__c": {
          name: 'Company_Overview__c',
          type: 'string'
        },
        "Company_Reference_code__c": {
          name: 'Company_Reference_code__c',
          type: 'string'
        },
        "Contact_Email__c": {
          name: 'Contact_Email__c',
          type: 'string'
        },
        "Contact_Type__c": {
          name: 'Contact_Type__c',
          type: 'string'
        },
        "Countries_Supported__c": {
          name: 'Countries_Supported__c',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "Created_updated_from_survey__c": {
          name: 'Created_updated_from_survey__c',
          type: 'boolean',
          default: false
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "Current_Employer__c": {
          name: 'Current_Employer__c',
          type: 'string'
        },
        "Customer_Overview_Field_Services__c": {
          name: 'Customer_Overview_Field_Services__c',
          type: 'any'
        },
        "Customer_Overview_Key__c": {
          name: 'Customer_Overview_Key__c',
          type: 'any'
        },
        "DataCenter_Operations_Hardware_Assembler__c": {
          name: 'DataCenter_Operations_Hardware_Assembler__c',
          type: 'boolean'
        },
        "Data_Center_Assembler_Next_Buss_Day_SLA__c": {
          name: 'Data_Center_Assembler_Next_Buss_Day_SLA__c',
          type: 'boolean'
        },
        "Data_Center_Engr_Next_Business_Day_SLA__c": {
          name: 'Data_Center_Engr_Next_Business_Day_SLA__c',
          type: 'boolean'
        },
        "Data_Center_Facilities_Manager__c": {
          name: 'Data_Center_Facilities_Manager__c',
          type: 'boolean'
        },
        "Data_Center_Facilities_Technician_Level1__c": {
          name: 'Data_Center_Facilities_Technician_Level1__c',
          type: 'boolean'
        },
        "Data_Center_Mgr_Next_Business_Day_SLA__c": {
          name: 'Data_Center_Mgr_Next_Business_Day_SLA__c',
          type: 'boolean'
        },
        "Data_Center_Operations_Engineer_Level_1__c": {
          name: 'Data_Center_Operations_Engineer_Level_1__c',
          type: 'boolean'
        },
        "Data_Center_Technician_Next_Buss_Day_SLA__c": {
          name: 'Data_Center_Technician_Next_Buss_Day_SLA__c',
          type: 'boolean'
        },
        "Default_Owner_for_Case__c": {
          name: 'Default_Owner_for_Case__c',
          type: 'string'
        },
        "Default_Pricelist__c": {
          name: 'Default_Pricelist__c',
          type: 'string'
        },
        "Default_SKU__c": {
          name: 'Default_SKU__c',
          type: 'string'
        },
        "Deployment_Project_Only__c": {
          name: 'Deployment_Project_Only__c',
          type: 'boolean',
          default: false
        },
        "Describe_Your_Staffing_Services__c": {
          name: 'Describe_Your_Staffing_Services__c',
          type: 'string'
        },
        "Description": {
          name: 'Description',
          type: 'string'
        },
        "Desktop_Support_Next_Business_Day_SLA__c": {
          name: 'Desktop_Support_Next_Business_Day_SLA__c',
          type: 'boolean'
        },
        "Desktop_Support_Technicians_Level_1__c": {
          name: 'Desktop_Support_Technicians_Level_1__c',
          type: 'boolean'
        },
        "Distance_from_capital_KM__c": {
          name: 'Distance_from_capital_KM__c',
          type: 'number'
        },
        "Do_you_accept_bank_payments_in_EURO__c": {
          name: 'Do_you_accept_bank_payments_in_EURO__c',
          type: 'boolean'
        },
        "Do_you_accept_bank_payments_in_US__c": {
          name: 'Do_you_accept_bank_payments_in_US__c',
          type: 'boolean'
        },
        "Email_to_Case_Subject__c": {
          name: 'Email_to_Case_Subject__c',
          type: 'string'
        },
        "Engagement_Type__c": {
          name: 'Engagement_Type__c',
          type: 'string'
        },
        "FLASH_FSL_Setup__c": {
          name: 'FLASH_FSL_Setup__c',
          type: 'string'
        },
        "FSE_Network_Coverage_Support_Type__c": {
          name: 'FSE_Network_Coverage_Support_Type__c',
          type: 'string'
        },
        "FSL_Location_ID_Backup__c": {
          name: 'FSL_Location_ID_Backup__c',
          type: 'string'
        },
        "FSL_Location_SLA_Supported_Setup_NBD__c": {
          name: 'FSL_Location_SLA_Supported_Setup_NBD__c',
          type: 'string'
        },
        "FSL_Location_Status__c": {
          name: 'FSL_Location_Status__c',
          type: 'string'
        },
        "FS_Dispatch_Management_Overview_External__c": {
          name: 'FS_Dispatch_Management_Overview_External__c',
          type: 'string'
        },
        "FS_Dispatch_Management_Overview_Internal__c": {
          name: 'FS_Dispatch_Management_Overview_Internal__c',
          type: 'string'
        },
        "FS_On_HW_Same_Bus_Day_4Hours_SLA_24x7__c": {
          name: 'FS_On_HW_Same_Bus_Day_4Hours_SLA_24x7__c',
          type: 'boolean'
        },
        "FS_On_HW_Same_Business_Day_4_Hours_SLA__c": {
          name: 'FS_On_HW_Same_Business_Day_4_Hours_SLA__c',
          type: 'boolean'
        },
        "FS_Onsite_Next_Business_Day_SLA__c": {
          name: 'FS_Onsite_Next_Business_Day_SLA__c',
          type: 'boolean'
        },
        "Fax": {
          name: 'Fax',
          type: 'string'
        },
        "Field_Engineer_4HR_SLA_Rate_US_HR__c": {
          name: 'Field_Engineer_4HR_SLA_Rate_US_HR__c',
          type: 'number'
        },
        "Field_Engineer_NBD_SLA_Rate_US_HR__c": {
          name: 'Field_Engineer_NBD_SLA_Rate_US_HR__c',
          type: 'number'
        },
        "Field_Project_Manager_Rate_US_HR__c": {
          name: 'Field_Project_Manager_Rate_US_HR__c',
          type: 'number'
        },
        "Field_Service_24x7_Onsite_SLA__c": {
          name: 'Field_Service_24x7_Onsite_SLA__c',
          type: 'boolean'
        },
        "GST_VAT_Registration_certificate__c": {
          name: 'GST_VAT_Registration_certificate__c',
          type: 'boolean'
        },
        "GST_VAT_Registration_Number__c": {
          name: 'GST_VAT_Registration_Number__c',
          type: 'string'
        },
        "Help_Desk_Technical_Support_Engineer__c": {
          name: 'Help_Desk_Technical_Support_Engineer__c',
          type: 'boolean'
        },
        "Helpdesk_Next_Business_Day_SLA__c": {
          name: 'Helpdesk_Next_Business_Day_SLA__c',
          type: 'boolean'
        },
        "IBAN__c": {
          name: 'IBAN__c',
          type: 'string'
        },
        "If_NOT_US_EURO_specify_currency__c": {
          name: 'If_NOT_US_EURO_specify_currency__c',
          type: 'string'
        },
        "Industry": {
          name: 'Industry',
          type: 'string'
        },
        "Industry__c": {
          name: 'Industry__c',
          type: 'string'
        },
        "Integration_Data_Type__c": {
          name: 'Integration_Data_Type__c',
          type: 'string'
        },
        "Integration_Service_Name__c": {
          name: 'Integration_Service_Name__c',
          type: 'string'
        },
        "Invoice_Due_Date_Offset__c": {
          name: 'Invoice_Due_Date_Offset__c',
          type: 'number'
        },
        "IsCustomerPortal": {
          name: 'IsCustomerPortal',
          type: 'boolean',
          default: false
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "IsLimitedWarranty__c": {
          name: 'IsLimitedWarranty__c',
          type: 'boolean'
        },
        "IsPartner": {
          name: 'IsPartner',
          type: 'boolean'
        },
        "Jigsaw": {
          name: 'Jigsaw',
          type: 'string'
        },
        "JigsawCompanyId": {
          name: 'JigsawCompanyId',
          type: 'string'
        },
        "Job_Category__c": {
          name: 'Job_Category__c',
          type: 'string'
        },
        "Jobsite_Selection__c": {
          name: 'Jobsite_Selection__c',
          type: 'string'
        },
        "Languages_Supported__c": {
          name: 'Languages_Supported__c',
          type: 'string'
        },
        "LastActivityDate": {
          name: 'LastActivityDate',
          type: 'Date'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "LastReferencedDate": {
          name: 'LastReferencedDate',
          type: 'Date'
        },
        "LastViewedDate": {
          name: 'LastViewedDate',
          type: 'Date'
        },
        "LeadId__c": {
          name: 'LeadId__c',
          type: 'string'
        },
        "LinkedIn_Employee_Count__c": {
          name: 'LinkedIn_Employee_Count__c',
          type: 'number'
        },
        "Logo__c": {
          name: 'Logo__c',
          type: 'string'
        },
        "MasterRecordId": {
          name: 'MasterRecordId',
          type: 'string'
        },
        "Master_Project_Name__c": {
          name: 'Master_Project_Name__c',
          type: 'string'
        },
        "Master_Project_Status__c": {
          name: 'Master_Project_Status__c',
          type: 'string'
        },
        "Master_Project__c": {
          name: 'Master_Project__c',
          type: 'string'
        },
        "Minimum_Hours__c": {
          name: 'Minimum_Hours__c',
          type: 'number'
        },
        "Multiple_Location_Details__c": {
          name: 'Multiple_Location_Details__c',
          type: 'string'
        },
        "Multiple_Location__c": {
          name: 'Multiple_Location__c',
          type: 'boolean'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "Network_Server_Engineer_MS_Windows__c": {
          name: 'Network_Server_Engineer_MS_Windows__c',
          type: 'boolean'
        },
        "Network_Server_Tech_Next_Buss_Day_SLA__c": {
          name: 'Network_Server_Tech_Next_Buss_Day_SLA__c',
          type: 'boolean'
        },
        "Network_Server_Technician_MS_Windows__c": {
          name: 'Network_Server_Technician_MS_Windows__c',
          type: 'boolean'
        },
        "Network_Srv_Engr_Next_Business_Day_SLA__c": {
          name: 'Network_Srv_Engr_Next_Business_Day_SLA__c',
          type: 'boolean'
        },
        "Network_Storage_Engineer_Level_2__c": {
          name: 'Network_Storage_Engineer_Level_2__c',
          type: 'boolean'
        },
        "Network_Storage_Engr_Next_Buss_Day_SLA__c": {
          name: 'Network_Storage_Engr_Next_Buss_Day_SLA__c',
          type: 'boolean'
        },
        "Next_Business_Day_Service__c": {
          name: 'Next_Business_Day_Service__c',
          type: 'boolean'
        },
        "NumberOfEmployees": {
          name: 'NumberOfEmployees',
          type: 'number'
        },
        "Number_of_Contractors_Field_Technicians__c": {
          name: 'Number_of_Contractors_Field_Technicians__c',
          type: 'string'
        },
        "Number_of_Employees_Field_Technicians__c": {
          name: 'Number_of_Employees_Field_Technicians__c',
          type: 'string'
        },
        "Number_of_Employees_Total__c": {
          name: 'Number_of_Employees_Total__c',
          type: 'number'
        },
        "Number_of_Employees__c": {
          name: 'Number_of_Employees__c',
          type: 'string'
        },
        "Number_of_Sub_Contractors_Field_Technic__c": {
          name: 'Number_of_Sub_Contractors_Field_Technic__c',
          type: 'string'
        },
        "On_Demand_Technical_Resources_Coverage__c": {
          name: 'On_Demand_Technical_Resources_Coverage__c',
          type: 'string'
        },
        "On_Demand_Technical_Resources_Pricing__c": {
          name: 'On_Demand_Technical_Resources_Pricing__c',
          type: 'string'
        },
        "OperatingHoursId": {
          name: 'OperatingHoursId',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "ParentId": {
          name: 'ParentId',
          type: 'string'
        },
        "Partner_Account__c": {
          name: 'Partner_Account__c',
          type: 'string'
        },
        "Partner_Account_for_Security__c": {
          name: 'Partner_Account_for_Security__c',
          type: 'string'
        },
        "Partner_Manage_Inventory_Vendor_Account__c": {
          name: 'Partner_Manage_Inventory_Vendor_Account__c',
          type: 'string'
        },
        "Partner_Pricelist__c": {
          name: 'Partner_Pricelist__c',
          type: 'string'
        },
        "Partner_Status__c": {
          name: 'Partner_Status__c',
          type: 'string'
        },
        "Partner_Type__c": {
          name: 'Partner_Type__c',
          type: 'string'
        },
        "Payment_Term__c": {
          name: 'Payment_Term__c',
          type: 'string'
        },
        "Phone": {
          name: 'Phone',
          type: 'string'
        },
        "PhotoUrl": {
          name: 'PhotoUrl',
          type: 'string'
        },
        "Primary_Contact_Email__c": {
          name: 'Primary_Contact_Email__c',
          type: 'string'
        },
        "Primary_Contact_Name__c": {
          name: 'Primary_Contact_Name__c',
          type: 'string'
        },
        "Primary_Contact_Phone__c": {
          name: 'Primary_Contact_Phone__c',
          type: 'string'
        },
        "Primary_Vendor_PPE_Hours_Profile__c": {
          name: 'Primary_Vendor_PPE_Hours_Profile__c',
          type: 'string'
        },
        "Process_Type__c": {
          name: 'Process_Type__c',
          type: 'string'
        },
        "Project_Manager_Onsite_IT_Project__c": {
          name: 'Project_Manager_Onsite_IT_Project__c',
          type: 'boolean'
        },
        "Project_Mgr_Onsite_Next_Business_Day_SLA__c": {
          name: 'Project_Mgr_Onsite_Next_Business_Day_SLA__c',
          type: 'boolean'
        },
        "Rate_US_10__c": {
          name: 'Rate_US_10__c',
          type: 'number'
        },
        "Rate_US_11__c": {
          name: 'Rate_US_11__c',
          type: 'number'
        },
        "Rate_US_12__c": {
          name: 'Rate_US_12__c',
          type: 'number'
        },
        "Rate_US_13__c": {
          name: 'Rate_US_13__c',
          type: 'number'
        },
        "Rate_US_14__c": {
          name: 'Rate_US_14__c',
          type: 'number'
        },
        "Rate_US_15__c": {
          name: 'Rate_US_15__c',
          type: 'number'
        },
        "Rate_US_16__c": {
          name: 'Rate_US_16__c',
          type: 'number'
        },
        "Rate_US_17__c": {
          name: 'Rate_US_17__c',
          type: 'number'
        },
        "Rate_US_18__c": {
          name: 'Rate_US_18__c',
          type: 'number'
        },
        "Rate_US_19__c": {
          name: 'Rate_US_19__c',
          type: 'number'
        },
        "Rate_US_1__c": {
          name: 'Rate_US_1__c',
          type: 'number'
        },
        "Rate_US_20__c": {
          name: 'Rate_US_20__c',
          type: 'number'
        },
        "Rate_US_21__c": {
          name: 'Rate_US_21__c',
          type: 'number'
        },
        "Rate_US_22__c": {
          name: 'Rate_US_22__c',
          type: 'number'
        },
        "Rate_US_23__c": {
          name: 'Rate_US_23__c',
          type: 'number'
        },
        "Rate_US_2__c": {
          name: 'Rate_US_2__c',
          type: 'number'
        },
        "Rate_US_3__c": {
          name: 'Rate_US_3__c',
          type: 'number'
        },
        "Rate_US_4__c": {
          name: 'Rate_US_4__c',
          type: 'number'
        },
        "Rate_US_5__c": {
          name: 'Rate_US_5__c',
          type: 'number'
        },
        "Rate_US_6__c": {
          name: 'Rate_US_6__c',
          type: 'number'
        },
        "Rate_US_7__c": {
          name: 'Rate_US_7__c',
          type: 'number'
        },
        "Rate_US_8__c": {
          name: 'Rate_US_8__c',
          type: 'number'
        },
        "Rate_US_9__c": {
          name: 'Rate_US_9__c',
          type: 'number'
        },
        "RecordTypeID__c": {
          name: 'RecordTypeID__c',
          type: 'string'
        },
        "RecordTypeId": {
          name: 'RecordTypeId',
          type: 'string'
        },
        "Record_Id__c": {
          name: 'Record_Id__c',
          type: 'string'
        },
        "Region__c": {
          name: 'Region__c',
          type: 'string'
        },
        "Regional_FSL_Gateway1__c": {
          name: 'Regional_FSL_Gateway1__c',
          type: 'string'
        },
        "Renewal_Contact_Email__c": {
          name: 'Renewal_Contact_Email__c',
          type: 'string'
        },
        "Renewal_Contact_Name__c": {
          name: 'Renewal_Contact_Name__c',
          type: 'string'
        },
        "Renewal_Contact_Phone__c": {
          name: 'Renewal_Contact_Phone__c',
          type: 'string'
        },
        "Renewal_Contact__c": {
          name: 'Renewal_Contact__c',
          type: 'string'
        },
        "Researched_website__c": {
          name: 'Researched_website__c',
          type: 'string'
        },
        "Same_Day_Service__c": {
          name: 'Same_Day_Service__c',
          type: 'boolean'
        },
        "Sample_copy_of_invoice_from_Vendor__c": {
          name: 'Sample_copy_of_invoice_from_Vendor__c',
          type: 'boolean'
        },
        "Scanned_Copy_Cancelled_cheque_fromBank__c": {
          name: 'Scanned_Copy_Cancelled_cheque_fromBank__c',
          type: 'boolean'
        },
        "Service_Dispatch_Account_Name__c": {
          name: 'Service_Dispatch_Account_Name__c',
          type: 'string'
        },
        "Service_Dispatch_Account_Reference__c": {
          name: 'Service_Dispatch_Account_Reference__c',
          type: 'string'
        },
        "Service_Global_Ref__c": {
          name: 'Service_Global_Ref__c',
          type: 'string'
        },
        "Service_Tax_Registration_number__c": {
          name: 'Service_Tax_Registration_number__c',
          type: 'string'
        },
        "ShippingAddress": {
          name: 'ShippingAddress',
          type: 'string'
        },
        "ShippingCity": {
          name: 'ShippingCity',
          type: 'string'
        },
        "ShippingCountry": {
          name: 'ShippingCountry',
          type: 'string'
        },
        "ShippingGeocodeAccuracy": {
          name: 'ShippingGeocodeAccuracy',
          type: 'string'
        },
        "ShippingLatitude": {
          name: 'ShippingLatitude',
          type: 'number'
        },
        "ShippingLongitude": {
          name: 'ShippingLongitude',
          type: 'number'
        },
        "ShippingPostalCode": {
          name: 'ShippingPostalCode',
          type: 'string'
        },
        "ShippingState": {
          name: 'ShippingState',
          type: 'string'
        },
        "ShippingStreet": {
          name: 'ShippingStreet',
          type: 'string'
        },
        "Shipping_Address_Concatenated__c": {
          name: 'Shipping_Address_Concatenated__c',
          type: 'string'
        },
        "Shipping_country_Code__c": {
          name: 'Shipping_country_Code__c',
          type: 'string'
        },
        "SicDesc": {
          name: 'SicDesc',
          type: 'string'
        },
        "Skill_Level__c": {
          name: 'Skill_Level__c',
          type: 'string'
        },
        "Source__c": {
          name: 'Source__c',
          type: 'string'
        },
        "Standard_Coverage_Message__c": {
          name: 'Standard_Coverage_Message__c',
          type: 'string'
        },
        "Standard_Supported_FSL__c": {
          name: 'Standard_Supported_FSL__c',
          type: 'string'
        },
        "Standard_Work_Hours__c": {
          name: 'Standard_Work_Hours__c',
          type: 'number'
        },
        "Sub_Inventory_Location__c": {
          name: 'Sub_Inventory_Location__c',
          type: 'string'
        },
        "Swift_Code__c": {
          name: 'Swift_Code__c',
          type: 'string'
        },
        "Sync_Flag__c": {
          name: 'Sync_Flag__c',
          type: 'boolean'
        },
        "SystemModstamp": {
          name: 'SystemModstamp',
          type: 'Date'
        },
        "Tax_ID__c": {
          name: 'Tax_ID__c',
          type: 'string'
        },
        "Tax_Identification_Number_certcopy__c": {
          name: 'Tax_Identification_Number_certcopy__c',
          type: 'boolean'
        },
        "Technology_Supported_Advance_Expertise__c": {
          name: 'Technology_Supported_Advance_Expertise__c',
          type: 'string'
        },
        "Technology_Supported_Basic_Expertise__c": {
          name: 'Technology_Supported_Basic_Expertise__c',
          type: 'string'
        },
        "Technology_Supported_Certified_Staff__c": {
          name: 'Technology_Supported_Certified_Staff__c',
          type: 'string'
        },
        "Timesheet_Offset__c": {
          name: 'Timesheet_Offset__c',
          type: 'string'
        },
        "Type": {
          name: 'Type',
          type: 'string'
        },
        "Types_of_Technical_Resources_Specialty__c": {
          name: 'Types_of_Technical_Resources_Specialty__c',
          type: 'string'
        },
        "Vendor_Certifications__c": {
          name: 'Vendor_Certifications__c',
          type: 'string'
        },
        "Vendor_Expertise__c": {
          name: 'Vendor_Expertise__c',
          type: 'string'
        },
        "Vendor_Type__c": {
          name: 'Vendor_Type__c',
          type: 'string'
        },
        "Vendors_Supported_Advance_Expertise__c": {
          name: 'Vendors_Supported_Advance_Expertise__c',
          type: 'string'
        },
        "Vendors_Supported_Certified_Staff__c": {
          name: 'Vendors_Supported_Certified_Staff__c',
          type: 'string'
        },
        "WM_User_ID__c": {
          name: 'WM_User_ID__c',
          type: 'string'
        },
        "Webcase_Account_Name__c": {
          name: 'Webcase_Account_Name__c',
          type: 'string'
        },
        "Website": {
          name: 'Website',
          type: 'string'
        },
        "X3PL_Carrier__c": {
          name: 'X3PL_Carrier__c',
          type: 'string'
        },
        "X3PL_NBD_Service_Level__c": {
          name: 'X3PL_NBD_Service_Level__c',
          type: 'string'
        },
        "X3PS_Account_Type__c": {
          name: 'X3PS_Account_Type__c',
          type: 'string'
        },
        "X3PS_Business_Size__c": {
          name: 'X3PS_Business_Size__c',
          type: 'number'
        },
        "X3PS_Contact_Registration_Status_Type__c": {
          name: 'X3PS_Contact_Registration_Status_Type__c',
          type: 'string'
        },
        "X3PS_Location_Status__c": {
          name: 'X3PS_Location_Status__c',
          type: 'string'
        },
        "X3rd_Party_Online_VMS__c": {
          name: 'X3rd_Party_Online_VMS__c',
          type: 'string'
        },
        "current_Field_Service_Dispatch_Volume__c": {
          name: 'current_Field_Service_Dispatch_Volume__c',
          type: 'string'
        },
        "geolocation__Latitude__s": {
          name: 'geolocation__Latitude__s',
          type: 'number'
        },
        "geolocation__Longitude__s": {
          name: 'geolocation__Longitude__s',
          type: 'number'
        },
        "geolocation__c": {
          name: 'geolocation__c',
          type: 'GeoPoint'
        },
        "isShippingAddressChanged__c": {
          name: 'isShippingAddressChanged__c',
          type: 'boolean'
        },
        "ts2__Accounts_Payable__c": {
          name: 'ts2__Accounts_Payable__c',
          type: 'string'
        },
        "ts2__Active__c": {
          name: 'ts2__Active__c',
          type: 'string'
        },
        "ts2__Business_Unit__c": {
          name: 'ts2__Business_Unit__c',
          type: 'string'
        },
        "ts2__Close_Pct__c": {
          name: 'ts2__Close_Pct__c',
          type: 'number'
        },
        "ts2__Company_Status__c": {
          name: 'ts2__Company_Status__c',
          type: 'string'
        },
        "ts2__Credit_Limit__c": {
          name: 'ts2__Credit_Limit__c',
          type: 'number'
        },
        "ts2__CustomerPriority__c": {
          name: 'ts2__CustomerPriority__c',
          type: 'any'
        },
        "ts2__EMC_Certs__c": {
          name: 'ts2__EMC_Certs__c',
          type: 'string'
        },
        "ts2__Invoice_Terms__c": {
          name: 'ts2__Invoice_Terms__c',
          type: 'string'
        },
        "ts2__Legacy_AccountID__c": {
          name: 'ts2__Legacy_AccountID__c',
          type: 'string'
        },
        "ts2__Memo__c": {
          name: 'ts2__Memo__c',
          type: 'string'
        },
        "ts2__NumberofLocations__c": {
          name: 'ts2__NumberofLocations__c',
          type: 'number'
        },
        "ts2__Requires_Background_Check__c": {
          name: 'ts2__Requires_Background_Check__c',
          type: 'boolean'
        },
        "ts2__Requires_Drug_Screen__c": {
          name: 'ts2__Requires_Drug_Screen__c',
          type: 'boolean'
        },
        "ts2__SLAExpirationDate__c": {
          name: 'ts2__SLAExpirationDate__c',
          type: 'Date'
        },
        "ts2__SLASerialNumber__c": {
          name: 'ts2__SLASerialNumber__c',
          type: 'string'
        },
        "ts2__SLA__c": {
          name: 'ts2__SLA__c',
          type: 'string'
        },
        "ts2__Sent_Contract__c": {
          name: 'ts2__Sent_Contract__c',
          type: 'boolean'
        },
        "ts2__Sent_Literature__c": {
          name: 'ts2__Sent_Literature__c',
          type: 'boolean'
        },
        "ts2__Skill_Codes__c": {
          name: 'ts2__Skill_Codes__c',
          type: 'string'
        },
        "ts2__Source_Note__c": {
          name: 'ts2__Source_Note__c',
          type: 'string'
        },
        "ts2__Source__c": {
          name: 'ts2__Source__c',
          type: 'string'
        },
        "ts2__Stage__c": {
          name: 'ts2__Stage__c',
          type: 'string'
        },
        "ts2__Status__c": {
          name: 'ts2__Status__c',
          type: 'string'
        },
        "ts2__Students__c": {
          name: 'ts2__Students__c',
          type: 'number'
        },
        "ts2__UpsellOpportunity__c": {
          name: 'ts2__UpsellOpportunity__c',
          type: 'string'
        },
        "ts2__Vendor_Note__c": {
          name: 'ts2__Vendor_Note__c',
          type: 'string'
        },
        "ts2__Vendor_Type__c": {
          name: 'ts2__Vendor_Type__c',
          type: 'string'
        },
        "vfirst__Adjudication__c": {
          name: 'vfirst__Adjudication__c',
          type: 'string'
        },
        "vfirst__Site_Guest_Access_Helper__c": {
          name: 'vfirst__Site_Guest_Access_Helper__c',
          type: 'string'
        },
        "vfirst__Stage__c": {
          name: 'vfirst__Stage__c',
          type: 'string'
        },
        "vfirst__Verification_Report__c": {
          name: 'vfirst__Verification_Report__c',
          type: 'string'
        },
        "vfirst__of_Background_Checks_Ordered__c": {
          name: 'vfirst__of_Background_Checks_Ordered__c',
          type: 'number'
        },
        "zeitzone__BillingLocalTime__c": {
          name: 'zeitzone__BillingLocalTime__c',
          type: 'string'
        },
        "zeitzone__BillingLTFactor__c": {
          name: 'zeitzone__BillingLTFactor__c',
          type: 'string'
        },
        "zeitzone__BillingTimeZoneId__c": {
          name: 'zeitzone__BillingTimeZoneId__c',
          type: 'string'
        },
        "zeitzone__ShippingLocalTime__c": {
          name: 'zeitzone__ShippingLocalTime__c',
          type: 'string'
        },
        "zeitzone__ShippingLTFactor__c": {
          name: 'zeitzone__ShippingLTFactor__c',
          type: 'number'
        },
        "zeitzone__ShippingTimeZoneId__c": {
          name: 'zeitzone__ShippingTimeZoneId__c',
          type: 'string'
        },
        "FileCounter": {
          name: 'FileCounter',
          type: 'number'
        },
        "FolderLastModifiedOn": {
          name: 'FolderLastModifiedOn',
          type: 'Date'
        },
        "Vendor_minimum_Hours_Committed__c": {
          name: 'Vendor_minimum_Hours_Committed__c',
          type: 'number'
        },
        "Overall_Vendor_min_Hrs_Geo_Allocated__c": {
          name: 'Overall_Vendor_min_Hrs_Geo_Allocated__c',
          type: 'number'
        },
        "Remaining_Hours_Geo_Metro_Unallocated__c": {
          name: 'Remaining_Hours_Geo_Metro_Unallocated__c',
          type: 'number'
        },
        "Vendor_Remaining_Hours_Current_Month__c": {
          name: 'Vendor_Remaining_Hours_Current_Month__c',
          type: 'number'
        },
        "Vendor_Assignment_Priority__c": {
          name: 'Vendor_Assignment_Priority__c',
          type: 'string'
        },
        "Signed_copy_of_NDA__c": {
          name: 'Signed_copy_of_NDA__c',
          type: 'boolean'
        },
        "Signed_copy_of_MSA__c": {
          name: 'Signed_copy_of_MSA__c',
          type: 'boolean'
        },
        "Umbrella_Liability_ins__c": {
          name: 'Umbrella_Liability_ins__c',
          type: 'boolean'
        },
        "Cyber_Liability_ins__c": {
          name: 'Cyber_Liability_ins__c',
          type: 'boolean'
        },
        "Workers_Compensation_ins__c": {
          name: 'Workers_Compensation_ins__c',
          type: 'boolean'
        },
        "Employers_liability_ins__c": {
          name: 'Employers_liability_ins__c',
          type: 'boolean'
        },
        "automobile_liability_ins__c": {
          name: 'automobile_liability_ins__c',
          type: 'boolean'
        },
        "General_Liability_ins__c": {
          name: 'General_Liability_ins__c',
          type: 'boolean'
        },
        "GDPR_Compliance__c": {
          name: 'GDPR_Compliance__c',
          type: 'boolean'
        },
        "cancelled_Cheque_of_Bank_Det__c": {
          name: 'cancelled_Cheque_of_Bank_Det__c',
          type: 'boolean'
        },
        "GST_VAT_Registration_certificate_det__c": {
          name: 'GST_VAT_Registration_certificate_det__c',
          type: 'boolean'
        },
        "service_Tax_Registration_det__c": {
          name: 'service_Tax_Registration_det__c',
          type: 'boolean'
        },
        "Tax_Identification_Number_det__c": {
          name: 'Tax_Identification_Number_det__c',
          type: 'boolean'
        },
        "Incorporation_Business_Registration_certificate_det__c": {
          name: 'Incorporation_Business_Registration_certificate_det__c',
          type: 'boolean'
        },
        "Primary_VCSQDLead_Ref__c": {
          name: 'Primary_VCSQDLead_Ref__c',
          type: 'string'
        },
        "Primary_Contact__c": {
          name: 'Primary_Contact__c',
          type: 'string'
        },
        "Primary_Worker_Skilling_Profile__c": {
          name: 'Primary_Worker_Skilling_Profile__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'Partner_Account__c',
          keyTo: 'sfdcId'
        },
        vendorAccount: {
          name: 'vendorAccount',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'Partner_Manage_Inventory_Vendor_Account__c',
          keyTo: 'sfdcId'
        },
        fslLocationAccount: {
          name: 'fslLocationAccount',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'FSL_Location_ID_Backup__c',
          keyTo: 'sfdcId'
        },
        fslAccount: {
          name: 'fslAccount',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'Standard_Supported_FSL__c',
          keyTo: 'sfdcId'
        },
        vendorPPEHours: {
          name: 'vendorPPEHours',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'Primary_Vendor_PPE_Hours_Profile__c',
          keyTo: 'sfdcId'
        },
        supportedFslAccount: {
          name: 'supportedFslAccount',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'Standard_Supported_FSL__c',
          keyTo: 'sfdcId'
        },
        users: {
          name: 'users',
          type: 'Users',
          model: 'Users',
          relationType: 'belongsTo',
                  keyFrom: 'CreatedById',
          keyTo: 'sfdcId'
        },
        pricelist: {
          name: 'pricelist',
          type: 'Pricelist',
          model: 'Pricelist',
          relationType: 'belongsTo',
                  keyFrom: 'Default_Pricelist__c',
          keyTo: 'sfdcId'
        },
        vendorsite: {
          name: 'vendorsite',
          type: 'Vendorsite[]',
          model: 'Vendorsite',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'Vendor__c'
        },
        program: {
          name: 'program',
          type: 'Project',
          model: 'Project',
          relationType: 'belongsTo',
                  keyFrom: 'Master_Project__c',
          keyTo: 'sfdcId'
        },
        product: {
          name: 'product',
          type: 'Product',
          model: 'Product',
          relationType: 'belongsTo',
                  keyFrom: 'Default_SKU__c',
          keyTo: 'sfdcId'
        },
        defaultContact: {
          name: 'defaultContact',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Default_Owner_for_Case__c',
          keyTo: 'sfdcId'
        },
        renewalContact: {
          name: 'renewalContact',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Renewal_Contact__c',
          keyTo: 'sfdcId'
        },
        primaryContact: {
          name: 'primaryContact',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Primary_Contact__c',
          keyTo: 'sfdcId'
        },
        contact: {
          name: 'contact',
          type: 'Contact[]',
          model: 'Contact',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'AccountId'
        },
        countryCode: {
          name: 'countryCode',
          type: 'CountryCode',
          model: 'CountryCode',
          relationType: 'belongsTo',
                  keyFrom: 'Region__c',
          keyTo: 'sfdcId'
        },
        metroVirtualVendorPool: {
          name: 'metroVirtualVendorPool',
          type: 'MetroVirtualVendorPool[]',
          model: 'MetroVirtualVendorPool',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'Vendor__c'
        },
        RecordType: {
          name: 'RecordType',
          type: 'RecordType',
          model: 'RecordType',
          relationType: 'belongsTo',
                  keyFrom: 'RecordTypeId',
          keyTo: 'sfdcId'
        },
        geoMessage: {
          name: 'geoMessage',
          type: 'GeoMessage',
          model: 'GeoMessage',
          relationType: 'belongsTo',
                  keyFrom: 'Standard_Coverage_Message__c',
          keyTo: 'sfdcId'
        },
        vcsqdLead: {
          name: 'vcsqdLead',
          type: 'VCSQDLead',
          model: 'VCSQDLead',
          relationType: 'belongsTo',
                  keyFrom: 'sfdcId',
          keyTo: 'Vendor__c'
        },
        accountRFI: {
          name: 'accountRFI',
          type: 'VCSQDLead',
          model: 'VCSQDLead',
          relationType: 'belongsTo',
                  keyFrom: 'Primary_VCSQDLead_Ref__c',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
