/* tslint:disable */
import {
  Account,
  Timecard,
  PurchaseOrder,
  Jobsite,
  Deliverable,
  Vendorsite,
  Appointment,
  Worker,
  Project,
  Case,
  Product,
  TalentType,
  WorkOrder,
  RecordType,
  Workflow,
  WorkflowStatus,
  Order,
  RequestFormReceiptLine,
  Favorite,
  ServiceContract,
  TimeClockManager,
  JobSchedule
} from '../index';

declare var Object: any;
export interface JobInterface {
  "sfdcId"?: string;
  "AccountId__c"?: string;
  "Address_2__c"?: string;
  "Appointment_Call_Notes__c"?: string;
  "Appointment_Plan_Option__c"?: string;
  "Appointment_Schedule_Status_Customer__c"?: string;
  "Appointment_Schedule_Status_Customer_vms__c"?: string;
  "Appointment__c"?: string;
  "Asset_Model__c"?: string;
  "Asset_Name__c"?: string;
  "Asset_Serial__c"?: string;
  "Assign_By_Email__c"?: string;
  "Assignment_ID__c"?: string;
  "Assignments_Fulfilled__c"?: string;
  "B1A_IRON_Help_Desk_Cust_Phone_Recd__c"?: boolean;
  "B2A_IRON_Help_Desk_Cust_Email_Recd__c"?: boolean;
  "B2_IRON_Help_Desk_Contact_Mode__c"?: string;
  "B3_IRON_Help_Desk_Customer_Contacted__c"?: boolean;
  "CKSW_BASE__Account__c"?: string;
  "CKSW_BASE__Appointment_Finish__c"?: Date;
  "CKSW_BASE__Appointment_Grade__c"?: number;
  "CKSW_BASE__Appointment_Start__c"?: Date;
  "CKSW_BASE__Area__c"?: string;
  "CKSW_BASE__Auto_Geocode__c"?: boolean;
  "CKSW_BASE__Auto_Schedule__c"?: boolean;
  "CKSW_BASE__City__c"?: string;
  "CKSW_BASE__Comment__c"?: string;
  "CKSW_BASE__Contact__c"?: string;
  "CKSW_BASE__Country__c"?: string;
  "CKSW_BASE__CustomerPhone__c"?: string;
  "CKSW_BASE__Date__c"?: Date;
  "CKSW_BASE__Day_Of_Week__c"?: string;
  "CKSW_BASE__Description__c"?: string;
  "CKSW_BASE__Due_Date__c"?: Date;
  "CKSW_BASE__Duration_Type__c"?: string;
  "CKSW_BASE__Duration__c"?: number;
  "CKSW_BASE__Early_Start__c"?: Date;
  "Earliest_Start_Date_Permitted__c"?: boolean;
  "CKSW_BASE__Finish__c"?: Date;
  "CKSW_BASE__Gantt_Label__c"?: string;
  "CKSW_BASE__Geolocation__Latitude__s"?: number;
  "CKSW_BASE__Geolocation__Longitude__s"?: number;
  "CKSW_BASE__In_Jeopardy_Reason__c"?: string;
  "CKSW_BASE__In_Jeopardy__c"?: boolean;
  "CKSW_BASE__Incomplete_reason__c"?: string;
  "CKSW_BASE__Location__c"?: string;
  "CKSW_BASE__My_Service__c"?: boolean;
  "CKSW_BASE__Notification_Time__c"?: Date;
  "CKSW_BASE__Notification_Type__c"?: string;
  "CKSW_BASE__Open_Date__c"?: Date;
  "CKSW_BASE__Other_Incomplete_Reason__c"?: string;
  "CKSW_BASE__Pinned__c"?: boolean;
  "CKSW_BASE__Priority__c"?: number;
  "CKSW_BASE__RecurrenceKey__c"?: string;
  "CKSW_BASE__Recurrence__c"?: boolean;
  "CKSW_BASE__Related_Service__c"?: string;
  "CKSW_BASE__Resource__c"?: string;
  "CKSW_BASE__Same_Day__c"?: boolean;
  "CKSW_BASE__Same_Resource__c"?: boolean;
  "CKSW_BASE__Service_Order__c"?: string;
  "CKSW_BASE__Service_Type__c"?: string;
  "CKSW_BASE__Start__c"?: Date;
  "CKSW_BASE__State__c"?: string;
  "CKSW_BASE__Status__c"?: string;
  "CKSW_BASE__Street__c"?: string;
  "CKSW_BASE__Time_Dependency__c"?: string;
  "CKSW_BASE__Tooltip_Text__c"?: string;
  "CKSW_BASE__Total_Travel__c"?: number;
  "CKSW_BASE__Travel_Time_From_Hours__c"?: number;
  "CKSW_BASE__Travel_Time_From__c"?: number;
  "CKSW_BASE__Travel_Time_To_Hours__c"?: number;
  "CKSW_BASE__Travel_Time_To__c"?: number;
  "CKSW_BASE__UpdatedByOptimization__c"?: boolean;
  "CKSW_BASE__Zip__c"?: string;
  "Case_Number__c"?: string;
  "Case__c"?: string;
  "Casenum__c"?: string;
  "Check_out__c"?: boolean;
  "Cloned__c"?: boolean;
  "commentCount"?: number;
  "Communication__c"?: string;
  "Contact_Email__c"?: string;
  "Count_of_Approved_Timecard_Timesheets__c"?: string;
  "CreatedById"?: string;
  "CreatedDate"?: Date;
  "CurrencyIsoCode"?: string;
  "Current_Timeclock__c"?: string;
  "Custom_Field_1__c"?: string;
  "Custom_work_order_instructions__c"?: string;
  "Customer_Appointment_DateTime_Scheduled__c"?: Date;
  "Customer_Appointment_Setup_Required__c"?: string;
  "Customer_Appointment_Start_HRS_Scheduled__c"?: string;
  "Customer_Appointment_Start_Scheduled__c"?: Date;
  "Customer_Apptmnt_Start_Minutes_Scheduled__c"?: string;
  "Customer_Contact_Email__c"?: string;
  "Customer_Contact_Phone__c"?: string;
  "Customer_Service_Type_From_Program__c"?: string;
  "Customer_Service_Type__c"?: string;
  "Customer_Window_End_Date__c"?: Date;
  "Customer_Window_Start_Date__c"?: Date;
  "Deliverable_Accuracy__c"?: string;
  "Deliverable_Status__c"?: string;
  "Deliverables__c"?: string;
  "Delivery_Confirmation__c"?: Date;
  "Dispatch_Service_Resolution_Status__c"?: string;
  "Dispatch_Worker_Name_Text__c"?: string;
  "Dispatch_Worker_Name__c"?: string;
  "Dispatch_Worker_Phone__c"?: string;
  "Does_this_Job_require_New_Equip_Hardware__c"?: string;
  "Does_this_Job_require_Service_Parts__c"?: string;
  "Email_to_Case_Subject__c"?: string;
  "Enable_Dispatch_Email__c"?: boolean;
  "Equipment_Delivery_Date_ETA__c"?: Date;
  "Equipment_Delivery_Time_ETA_Hours__c"?: string;
  "Equipment_Delivery_Time_ETA_Minutes__c"?: string;
  "Equipment_Local_Pickup_Required__c"?: string;
  "Equipment_Tracking__c"?: string;
  "FE_Requested_Arrival_Date_Time__c"?: string;
  "Field_Service_Schedule_ETA_Date_Time__c"?: Date;
  "Field_Service_Schedule_ETA_Date__c"?: Date;
  "Final_Timecard_Received__c"?: number;
  "Final_Timecard_Submitted__c"?: boolean;
  "Followed_Instruction__c"?: string;
  "Generate_Timesheet__c"?: boolean;
  "Group__c"?: string;
  "Hardware_Logistics_From_Project__c"?: string;
  "HasProject__c"?: boolean;
  "HasWO__c"?: boolean;
  "Has_Job_Schedule__c"?: boolean;
  "Has_Work_Order__c"?: boolean;
  "Health_Case_Status__c"?: string;
  "Health_Completion_Status__c"?: string;
  "Health_Customer_req__c"?: string;
  "Health_FMS_Submission_Status__c"?: string;
  "Health_Jobsite_Status__c"?: string;
  "Health_List_Price_Status__c"?: string;
  "Health_Project_Status__c"?: string;
  "Health_Vendor_Status__c"?: string;
  "Health_Worker_Status__c"?: string;
  "Health_custom_Window__c"?: string;
  "IRON_Case_Created_Date_Partner__c"?: Date;
  "IRON_Case_Notes_Vendor_via_VFMS_Email__c"?: string;
  "Invited_Pool_FMS__c"?: string;
  "Invited_Pool_VMS__c"?: string;
  "Invited_Worker__c"?: string;
  "Invited_Workers_Multiple__c"?: string;
  "Iron_Case__c"?: string;
  "Iron_Job_Assistance__c"?: string;
  "Iron_Job_num__c"?: string;
  "IsDeleted"?: boolean;
  "Is_this_FTE_Job__c"?: boolean;
  "JobSite_Local_Instructions__c"?: string;
  "Job_Accepted_Date__c"?: Date;
  "Job_Assigned_Date__c"?: Date;
  "Job_Closed_Date__c"?: Date;
  "Job_Complete__c"?: Date;
  "Job_Completion_Time__c"?: number;
  "Job_Completion_Time_in_hrs__c"?: number;
  "Job_Created__c"?: Date;
  "Job_Cycle_Time__c"?: number;
  "Job_Decline_Date__c"?: Date;
  "Job_Instruction_Details_Long__c"?: string;
  "Job_Instructions_Deliverables_Summary__c"?: string;
  "Job_Instructions_Worker_Specific_Request__c"?: string;
  "Job_Instructions__c"?: string;
  "Job_Invited_Date__c"?: Date;
  "Job_Invoiced_Date__c"?: Date;
  "Job_Paid_Date__c"?: Date;
  "Job_Pending_Approval_Date__c"?: Date;
  "Job_Progress__c"?: string;
  "Job_Schedule__c"?: string;
  "Job_Started_Date__c"?: Date;
  "Job_Status_Internal__c"?: string;
  "Job_Subject__c"?: string;
  "Job_Type__c"?: string;
  "Jobs_Resolution_Status_FSE_Date_Time__c"?: Date;
  "Jobsite_Contact_SDesk_Name_phone_email__c"?: string;
  "Jobsite_Contact_Service_Desk__c"?: string;
  "Jobsite_Contact_TEsc_Name_phone_email__c"?: string;
  "Jobsite_Contact_Technical_Escalation__c"?: string;
  "Jobsite_Name__c"?: string;
  "Jobsite__c"?: string;
  "LastModifiedById"?: string;
  "LastModifiedDate"?: Date;
  "LastReferencedDate"?: Date;
  "LastViewedDate"?: Date;
  "Latest_Monthly_Invoice__c"?: string;
  "Latest_Timecard_Timesheet__c"?: string;
  "Location_Name__c"?: string;
  "Location_Type__c"?: string;
  "Max_Hours_Secondary_Rate__c"?: number;
  "Max_Hours_Units__c"?: number;
  "Name"?: string;
  "Order_Product_Id__c"?: string;
  "Overall_Rating__c"?: string;
  "OwnerId"?: string;
  "Owner_Email__c"?: string;
  "Partner_Case_Error_Description__c"?: string;
  "Partner_Case_Number__c"?: string;
  "Partner_FRU_Sku__c"?: string;
  "Partner_RMA_Number__c"?: string;
  "Pause_Check_in__c"?: boolean;
  "Phone_Scheduling_1st_Attempt_Unreachable__c"?: Date;
  "Phone_Scheduling_2nd_Attempt_Unreachable__c"?: Date;
  "Phone_Scheduling_3rd_Attempt_Unreachable__c"?: Date;
  "Pricing_Type__c"?: string;
  "Professionalism__c"?: string;
  "Project_SOP__c"?: string;
  "Project__c"?: string;
  "Project_num__c"?: string;
  "Purchase_Order_Type__c"?: string;
  "Quality_Rating__c"?: string;
  "Question_to_IRON_Help_Desk__c"?: string;
  "Rack_Kit_Top_of_Switch__c"?: string;
  "Rack_Serial__c"?: string;
  "Rack_Side__c"?: string;
  "Rack_Sub_Position__c"?: string;
  "Rack_Sub_Position_Slot__c"?: string;
  "Rack__c"?: string;
  "Rate__c"?: number;
  "RecordTypeId"?: string;
  "Refresh_Information_from_Case__c"?: boolean;
  "Refresh_Information_from_Project__c"?: boolean;
  "Refresh_Location_Info_From_Case__c"?: boolean;
  "Refresh_information_from_Purchase_Order__c"?: boolean;
  "Regenerate_SO_PO__c"?: boolean;
  "Required_Tools_From_SOP__c"?: string;
  "Resend_Dispatch_Email__c"?: boolean;
  "Resource_Company_Name__c"?: string;
  "Resource_Email__c"?: string;
  "Resource_Name__c"?: string;
  "Resource_Phone__c"?: string;
  "Restart_Check_in__c"?: boolean;
  "Roll_up_Timecard_s_IRON_Approval_Status__c"?: string;
  "Row__c"?: string;
  "SC_EndDate__c"?: Date;
  "SC_StartDate__c"?: Date;
  "SKU_Item_Vendor_Purchase_Order__c"?: string;
  "SKU__c"?: string;
  "SOW_Equipment__c"?: string;
  "SOW_Helpdesk__c"?: string;
  "SOW_Payment_Terms__c"?: string;
  "SOW_Tools__c"?: string;
  "SOW_WO_concat_2__c"?: string;
  "SOW_WO_concat__c"?: string;
  "SOW_Work_Order_Extended__c"?: string;
  "SOW_Work_Order__c"?: string;
  "Sales_Order__c"?: string;
  "Secondary_Rate__c"?: number;
  "Service_Contract__c"?: string;
  "Service_Deliverables_From_Project__c"?: string;
  "Service_Description_From_Project__c"?: string;
  "Service_Dispatch_Comments__c"?: string;
  "Service_Dispatch_SLA_Priority_FrmProgram__c"?: string;
  "Service_Dispatch_SLA_Priority__c"?: string;
  "Service_Parts_Local_Pickup_Required__c"?: string;
  "Service_SOW_Description_Customer__c"?: string;
  "Service_Schedule__c"?: string;
  "shortUrl"?: string;
  "Skills_Specialties__c"?: string;
  "SoW_Desc_Vendor_concat__c"?: string;
  "SoW_Description_For_Vendor_Extended_10__c"?: string;
  "SoW_Description_For_Vendor_Extended_1__c"?: string;
  "SoW_Description_For_Vendor_Extended_2__c"?: string;
  "SoW_Description_For_Vendor_Extended_3__c"?: string;
  "SoW_Description_For_Vendor_Extended_5__c"?: string;
  "SoW_Description_For_Vendor_Extended_6__c"?: string;
  "SoW_Description_For_Vendor_Extended_7__c"?: string;
  "SoW_Description_For_Vendor_Extended_8__c"?: string;
  "SoW_Description_For_Vendor_Extended_9__c"?: string;
  "SoW_Description_Vendor__c"?: string;
  "Special_Instruction_from_PMS_Case_Auto__c"?: string;
  "Special_Instructions_Extended__c"?: string;
  "Special_Instructions__c"?: string;
  "Special_Service_Instructions_Project__c"?: string;
  "Specific_Schedule_Time__c"?: Date;
  "Start_Check_in__c"?: boolean;
  "Statement_of_Work_Description_Combined__c"?: string;
  "Static_Text_Email_Dispatch_DeskMgmt__c"?: string;
  "Static_Text_Email_Header__c"?: string;
  "Static_Text_Email_Job_Instruction__c"?: string;
  "Static_Text_Email_Please_Call_Customer__c"?: string;
  "Static_Text_Email_Technical_Doc_Extended__c"?: string;
  "Static_Text_Email_Technical_Document__c"?: string;
  "Submiited_to_WM__c"?: boolean;
  "Suite__c"?: string;
  "Talent_Level_Program__c"?: string;
  "Talent_Type__c"?: string;
  "Technical_Level__c"?: string;
  "Title__c"?: string;
  "Total_Approved_Timecard_Hours__c"?: number;
  "Total_FTE_Hours__c"?: number;
  "Total_Number_of_Timecards_Submitted__c"?: number;
  "Total_Time_Consume_by_Worker__c"?: number;
  "Training_Documents_From_Project__c"?: string;
  "Type_of_Work__c"?: string;
  "User_Name__c"?: string;
  "Vendor_Contact_for_Job__c"?: string;
  "Vendor_Dispatch_Department_Contact__c"?: string;
  "Vendor_Time_Card_Notes_Tasks_Performed__c"?: string;
  "Vendor_Time_Card_Observation_Suggestion__c"?: string;
  "Vendor_Time_Card_Total_Hours_All_Visits__c"?: number;
  "Vendor_Time_Card_Total_Hours_Per_Visit__c"?: number;
  "Vendor_Timecard_Cust_Site_Sign_off_Name__c"?: string;
  "Vendor_Timecard_Details_Roll_up__c"?: string;
  "Vendor__c"?: string;
  "Vendorsite__c"?: string;
  "View_in_Work_Market__c"?: string;
  "WM_Contact_Id__c"?: string;
  "WM_Get_Flag__c"?: boolean;
  "WM_Status__c"?: string;
  "Work_Order_Number__c"?: string;
  "Work_Order__c"?: string;
  "Worker_Arrival_DateTime_Cust_Requested__c"?: Date;
  "Worker_Arrival_DateTime_Scheduled__c"?: Date;
  "Worker_Arrival_Date_Customer_Requested__c"?: Date;
  "Worker_Arrival_Date_Local_Time_Zone__c"?: string;
  "Worker_Arrival_Date_Scheduled__c"?: Date;
  "Worker_Arrival_Date_Time_Actual__c"?: Date;
  "Worker_Arrival_End_Date_time_Cust_Req__c"?: Date;
  "Worker_Arrival_Hours_Actual__c"?: string;
  "Worker_Arrival_Hours_Customer_Requested__c"?: string;
  "Worker_Arrival_Minute_Customer_Requested__c"?: string;
  "Worker_Arrival_Minutes_Actual__c"?: string;
  "Worker_Arrival_Time_Hours_Scheduled__c"?: string;
  "Worker_Arrival_Time_Minutes_Scheduled__c"?: string;
  "Worker_Departure_Date_Time_Actual__c"?: Date;
  "Worker_End_DateTime_Scheduled__c"?: Date;
  "Worker_End_Date_Scheduled__c"?: Date;
  "Worker_End_Time_Hours_Scheduled__c"?: string;
  "Worker_End_Time_Minutes_Scheduled__c"?: string;
  "Worker_Finish_Date_Time_Actual__c"?: Date;
  "Worker_Finish_Time_Hour_Actual__c"?: string;
  "Worker_Finish_Time_Minutes_Actual__c"?: string;
  "Worker_In_Progress_Start_Hour_Actual__c"?: string;
  "Worker_In_Progress_Start_Minute_Actual__c"?: string;
  "Workmarket_Message__c"?: string;
  "csum__c"?: string;
  "worker_arrived_on_Time__c"?: string;
  "worker_arrived_with_correct_Tools__c"?: string;
  "Worker_InProgress_Start_DateTime_Actual__c"?: Date;
  "stageTracker"?: string;
  "progressTracker"?: string;
  "Created_by_CSD__C"?: boolean;
  "isCancelled"?: boolean;
  "FileCounter"?: number;
  "FolderLastModifiedOn"?: Date;
  "statusTracker"?: string;
  "acknowledge_instruction"?: boolean;
  "pre_visit_checklist"?: boolean;
  "systemTimecard"?: number;
  "submittedTimecard"?: boolean;
  "Generate_Timesheet_Confirm__c"?: boolean;
  "Generate_Timesheet_Skip__c"?: boolean;
  "IPAddress__c"?: string;
  "IPCity__c"?: string;
  "IPCountry__c"?: string;
  "IPRegion__c"?: string;
  "Publish_to_Workmarket__c"?: boolean;
  "Refresh_Job_From_Workmarket__c"?: boolean;
  "icc_recordtype_id"?: string;
  "is_backfill_worker"?: boolean;
  "PMS_Case_Status"?: string;
  "PMS_Case_Number"?: string;
  "Work_Order_Number"?: string;
  "PMC_Case_Number"?: string;
  "hasMessages"?: boolean;
  "Is_Public_Job__c"?: boolean;
  "Dispatch_worker_type"?: string;
  "Final_Timecard_Id"?: number;
  "Is_Final_Timecard_Submitted"?: boolean;
  "MSP_Checkin_Time__c"?: Date;
  "Survey_Template"?: string;
  "Survey_Link"?: string;
  "Survey_Status"?: string;
  "Survey_Created_Date"?: Date;
  "Survey_Due_Date"?: Date;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "Talent_Type_ref__c"?: string;
  "workflowId"?: number;
  "workflowStatusId"?: number;
  vendor?: Account;
  partner?: Account;
  timecard?: Timecard;
  timecards?: Timecard[];
  purchaseOrder?: PurchaseOrder;
  jobsite?: Jobsite;
  deliverable?: Deliverable;
  vendorsite?: Vendorsite;
  appointment?: Appointment;
  worker?: Worker;
  program?: Project;
  project?: Project;
  case?: Case;
  pmcCase?: Case;
  pmsCase?: Case;
  product?: Product;
  talentType?: TalentType;
  workOrder?: WorkOrder;
  RecordType?: RecordType;
  workflow?: Workflow;
  workflowStatus?: WorkflowStatus;
  salesOrder?: Order;
  order?: Order;
  escalationJob?: RequestFormReceiptLine[];
  favorite?: Favorite[];
  serviceContract?: ServiceContract;
  timeClockManager?: TimeClockManager[];
  jobSchedule?: JobSchedule;
}

export class Job implements JobInterface {
  "sfdcId": string;
  "AccountId__c": string;
  "Address_2__c": string;
  "Appointment_Call_Notes__c": string;
  "Appointment_Plan_Option__c": string;
  "Appointment_Schedule_Status_Customer__c": string;
  "Appointment_Schedule_Status_Customer_vms__c": string;
  "Appointment__c": string;
  "Asset_Model__c": string;
  "Asset_Name__c": string;
  "Asset_Serial__c": string;
  "Assign_By_Email__c": string;
  "Assignment_ID__c": string;
  "Assignments_Fulfilled__c": string;
  "B1A_IRON_Help_Desk_Cust_Phone_Recd__c": boolean;
  "B2A_IRON_Help_Desk_Cust_Email_Recd__c": boolean;
  "B2_IRON_Help_Desk_Contact_Mode__c": string;
  "B3_IRON_Help_Desk_Customer_Contacted__c": boolean;
  "CKSW_BASE__Account__c": string;
  "CKSW_BASE__Appointment_Finish__c": Date;
  "CKSW_BASE__Appointment_Grade__c": number;
  "CKSW_BASE__Appointment_Start__c": Date;
  "CKSW_BASE__Area__c": string;
  "CKSW_BASE__Auto_Geocode__c": boolean;
  "CKSW_BASE__Auto_Schedule__c": boolean;
  "CKSW_BASE__City__c": string;
  "CKSW_BASE__Comment__c": string;
  "CKSW_BASE__Contact__c": string;
  "CKSW_BASE__Country__c": string;
  "CKSW_BASE__CustomerPhone__c": string;
  "CKSW_BASE__Date__c": Date;
  "CKSW_BASE__Day_Of_Week__c": string;
  "CKSW_BASE__Description__c": string;
  "CKSW_BASE__Due_Date__c": Date;
  "CKSW_BASE__Duration_Type__c": string;
  "CKSW_BASE__Duration__c": number;
  "CKSW_BASE__Early_Start__c": Date;
  "Earliest_Start_Date_Permitted__c": boolean;
  "CKSW_BASE__Finish__c": Date;
  "CKSW_BASE__Gantt_Label__c": string;
  "CKSW_BASE__Geolocation__Latitude__s": number;
  "CKSW_BASE__Geolocation__Longitude__s": number;
  "CKSW_BASE__In_Jeopardy_Reason__c": string;
  "CKSW_BASE__In_Jeopardy__c": boolean;
  "CKSW_BASE__Incomplete_reason__c": string;
  "CKSW_BASE__Location__c": string;
  "CKSW_BASE__My_Service__c": boolean;
  "CKSW_BASE__Notification_Time__c": Date;
  "CKSW_BASE__Notification_Type__c": string;
  "CKSW_BASE__Open_Date__c": Date;
  "CKSW_BASE__Other_Incomplete_Reason__c": string;
  "CKSW_BASE__Pinned__c": boolean;
  "CKSW_BASE__Priority__c": number;
  "CKSW_BASE__RecurrenceKey__c": string;
  "CKSW_BASE__Recurrence__c": boolean;
  "CKSW_BASE__Related_Service__c": string;
  "CKSW_BASE__Resource__c": string;
  "CKSW_BASE__Same_Day__c": boolean;
  "CKSW_BASE__Same_Resource__c": boolean;
  "CKSW_BASE__Service_Order__c": string;
  "CKSW_BASE__Service_Type__c": string;
  "CKSW_BASE__Start__c": Date;
  "CKSW_BASE__State__c": string;
  "CKSW_BASE__Status__c": string;
  "CKSW_BASE__Street__c": string;
  "CKSW_BASE__Time_Dependency__c": string;
  "CKSW_BASE__Tooltip_Text__c": string;
  "CKSW_BASE__Total_Travel__c": number;
  "CKSW_BASE__Travel_Time_From_Hours__c": number;
  "CKSW_BASE__Travel_Time_From__c": number;
  "CKSW_BASE__Travel_Time_To_Hours__c": number;
  "CKSW_BASE__Travel_Time_To__c": number;
  "CKSW_BASE__UpdatedByOptimization__c": boolean;
  "CKSW_BASE__Zip__c": string;
  "Case_Number__c": string;
  "Case__c": string;
  "Casenum__c": string;
  "Check_out__c": boolean;
  "Cloned__c": boolean;
  "commentCount": number;
  "Communication__c": string;
  "Contact_Email__c": string;
  "Count_of_Approved_Timecard_Timesheets__c": string;
  "CreatedById": string;
  "CreatedDate": Date;
  "CurrencyIsoCode": string;
  "Current_Timeclock__c": string;
  "Custom_Field_1__c": string;
  "Custom_work_order_instructions__c": string;
  "Customer_Appointment_DateTime_Scheduled__c": Date;
  "Customer_Appointment_Setup_Required__c": string;
  "Customer_Appointment_Start_HRS_Scheduled__c": string;
  "Customer_Appointment_Start_Scheduled__c": Date;
  "Customer_Apptmnt_Start_Minutes_Scheduled__c": string;
  "Customer_Contact_Email__c": string;
  "Customer_Contact_Phone__c": string;
  "Customer_Service_Type_From_Program__c": string;
  "Customer_Service_Type__c": string;
  "Customer_Window_End_Date__c": Date;
  "Customer_Window_Start_Date__c": Date;
  "Deliverable_Accuracy__c": string;
  "Deliverable_Status__c": string;
  "Deliverables__c": string;
  "Delivery_Confirmation__c": Date;
  "Dispatch_Service_Resolution_Status__c": string;
  "Dispatch_Worker_Name_Text__c": string;
  "Dispatch_Worker_Name__c": string;
  "Dispatch_Worker_Phone__c": string;
  "Does_this_Job_require_New_Equip_Hardware__c": string;
  "Does_this_Job_require_Service_Parts__c": string;
  "Email_to_Case_Subject__c": string;
  "Enable_Dispatch_Email__c": boolean;
  "Equipment_Delivery_Date_ETA__c": Date;
  "Equipment_Delivery_Time_ETA_Hours__c": string;
  "Equipment_Delivery_Time_ETA_Minutes__c": string;
  "Equipment_Local_Pickup_Required__c": string;
  "Equipment_Tracking__c": string;
  "FE_Requested_Arrival_Date_Time__c": string;
  "Field_Service_Schedule_ETA_Date_Time__c": Date;
  "Field_Service_Schedule_ETA_Date__c": Date;
  "Final_Timecard_Received__c": number;
  "Final_Timecard_Submitted__c": boolean;
  "Followed_Instruction__c": string;
  "Generate_Timesheet__c": boolean;
  "Group__c": string;
  "Hardware_Logistics_From_Project__c": string;
  "HasProject__c": boolean;
  "HasWO__c": boolean;
  "Has_Job_Schedule__c": boolean;
  "Has_Work_Order__c": boolean;
  "Health_Case_Status__c": string;
  "Health_Completion_Status__c": string;
  "Health_Customer_req__c": string;
  "Health_FMS_Submission_Status__c": string;
  "Health_Jobsite_Status__c": string;
  "Health_List_Price_Status__c": string;
  "Health_Project_Status__c": string;
  "Health_Vendor_Status__c": string;
  "Health_Worker_Status__c": string;
  "Health_custom_Window__c": string;
  "IRON_Case_Created_Date_Partner__c": Date;
  "IRON_Case_Notes_Vendor_via_VFMS_Email__c": string;
  "Invited_Pool_FMS__c": string;
  "Invited_Pool_VMS__c": string;
  "Invited_Worker__c": string;
  "Invited_Workers_Multiple__c": string;
  "Iron_Case__c": string;
  "Iron_Job_Assistance__c": string;
  "Iron_Job_num__c": string;
  "IsDeleted": boolean;
  "Is_this_FTE_Job__c": boolean;
  "JobSite_Local_Instructions__c": string;
  "Job_Accepted_Date__c": Date;
  "Job_Assigned_Date__c": Date;
  "Job_Closed_Date__c": Date;
  "Job_Complete__c": Date;
  "Job_Completion_Time__c": number;
  "Job_Completion_Time_in_hrs__c": number;
  "Job_Created__c": Date;
  "Job_Cycle_Time__c": number;
  "Job_Decline_Date__c": Date;
  "Job_Instruction_Details_Long__c": string;
  "Job_Instructions_Deliverables_Summary__c": string;
  "Job_Instructions_Worker_Specific_Request__c": string;
  "Job_Instructions__c": string;
  "Job_Invited_Date__c": Date;
  "Job_Invoiced_Date__c": Date;
  "Job_Paid_Date__c": Date;
  "Job_Pending_Approval_Date__c": Date;
  "Job_Progress__c": string;
  "Job_Schedule__c": string;
  "Job_Started_Date__c": Date;
  "Job_Status_Internal__c": string;
  "Job_Subject__c": string;
  "Job_Type__c": string;
  "Jobs_Resolution_Status_FSE_Date_Time__c": Date;
  "Jobsite_Contact_SDesk_Name_phone_email__c": string;
  "Jobsite_Contact_Service_Desk__c": string;
  "Jobsite_Contact_TEsc_Name_phone_email__c": string;
  "Jobsite_Contact_Technical_Escalation__c": string;
  "Jobsite_Name__c": string;
  "Jobsite__c": string;
  "LastModifiedById": string;
  "LastModifiedDate": Date;
  "LastReferencedDate": Date;
  "LastViewedDate": Date;
  "Latest_Monthly_Invoice__c": string;
  "Latest_Timecard_Timesheet__c": string;
  "Location_Name__c": string;
  "Location_Type__c": string;
  "Max_Hours_Secondary_Rate__c": number;
  "Max_Hours_Units__c": number;
  "Name": string;
  "Order_Product_Id__c": string;
  "Overall_Rating__c": string;
  "OwnerId": string;
  "Owner_Email__c": string;
  "Partner_Case_Error_Description__c": string;
  "Partner_Case_Number__c": string;
  "Partner_FRU_Sku__c": string;
  "Partner_RMA_Number__c": string;
  "Pause_Check_in__c": boolean;
  "Phone_Scheduling_1st_Attempt_Unreachable__c": Date;
  "Phone_Scheduling_2nd_Attempt_Unreachable__c": Date;
  "Phone_Scheduling_3rd_Attempt_Unreachable__c": Date;
  "Pricing_Type__c": string;
  "Professionalism__c": string;
  "Project_SOP__c": string;
  "Project__c": string;
  "Project_num__c": string;
  "Purchase_Order_Type__c": string;
  "Quality_Rating__c": string;
  "Question_to_IRON_Help_Desk__c": string;
  "Rack_Kit_Top_of_Switch__c": string;
  "Rack_Serial__c": string;
  "Rack_Side__c": string;
  "Rack_Sub_Position__c": string;
  "Rack_Sub_Position_Slot__c": string;
  "Rack__c": string;
  "Rate__c": number;
  "RecordTypeId": string;
  "Refresh_Information_from_Case__c": boolean;
  "Refresh_Information_from_Project__c": boolean;
  "Refresh_Location_Info_From_Case__c": boolean;
  "Refresh_information_from_Purchase_Order__c": boolean;
  "Regenerate_SO_PO__c": boolean;
  "Required_Tools_From_SOP__c": string;
  "Resend_Dispatch_Email__c": boolean;
  "Resource_Company_Name__c": string;
  "Resource_Email__c": string;
  "Resource_Name__c": string;
  "Resource_Phone__c": string;
  "Restart_Check_in__c": boolean;
  "Roll_up_Timecard_s_IRON_Approval_Status__c": string;
  "Row__c": string;
  "SC_EndDate__c": Date;
  "SC_StartDate__c": Date;
  "SKU_Item_Vendor_Purchase_Order__c": string;
  "SKU__c": string;
  "SOW_Equipment__c": string;
  "SOW_Helpdesk__c": string;
  "SOW_Payment_Terms__c": string;
  "SOW_Tools__c": string;
  "SOW_WO_concat_2__c": string;
  "SOW_WO_concat__c": string;
  "SOW_Work_Order_Extended__c": string;
  "SOW_Work_Order__c": string;
  "Sales_Order__c": string;
  "Secondary_Rate__c": number;
  "Service_Contract__c": string;
  "Service_Deliverables_From_Project__c": string;
  "Service_Description_From_Project__c": string;
  "Service_Dispatch_Comments__c": string;
  "Service_Dispatch_SLA_Priority_FrmProgram__c": string;
  "Service_Dispatch_SLA_Priority__c": string;
  "Service_Parts_Local_Pickup_Required__c": string;
  "Service_SOW_Description_Customer__c": string;
  "Service_Schedule__c": string;
  "shortUrl": string;
  "Skills_Specialties__c": string;
  "SoW_Desc_Vendor_concat__c": string;
  "SoW_Description_For_Vendor_Extended_10__c": string;
  "SoW_Description_For_Vendor_Extended_1__c": string;
  "SoW_Description_For_Vendor_Extended_2__c": string;
  "SoW_Description_For_Vendor_Extended_3__c": string;
  "SoW_Description_For_Vendor_Extended_5__c": string;
  "SoW_Description_For_Vendor_Extended_6__c": string;
  "SoW_Description_For_Vendor_Extended_7__c": string;
  "SoW_Description_For_Vendor_Extended_8__c": string;
  "SoW_Description_For_Vendor_Extended_9__c": string;
  "SoW_Description_Vendor__c": string;
  "Special_Instruction_from_PMS_Case_Auto__c": string;
  "Special_Instructions_Extended__c": string;
  "Special_Instructions__c": string;
  "Special_Service_Instructions_Project__c": string;
  "Specific_Schedule_Time__c": Date;
  "Start_Check_in__c": boolean;
  "Statement_of_Work_Description_Combined__c": string;
  "Static_Text_Email_Dispatch_DeskMgmt__c": string;
  "Static_Text_Email_Header__c": string;
  "Static_Text_Email_Job_Instruction__c": string;
  "Static_Text_Email_Please_Call_Customer__c": string;
  "Static_Text_Email_Technical_Doc_Extended__c": string;
  "Static_Text_Email_Technical_Document__c": string;
  "Submiited_to_WM__c": boolean;
  "Suite__c": string;
  "Talent_Level_Program__c": string;
  "Talent_Type__c": string;
  "Technical_Level__c": string;
  "Title__c": string;
  "Total_Approved_Timecard_Hours__c": number;
  "Total_FTE_Hours__c": number;
  "Total_Number_of_Timecards_Submitted__c": number;
  "Total_Time_Consume_by_Worker__c": number;
  "Training_Documents_From_Project__c": string;
  "Type_of_Work__c": string;
  "User_Name__c": string;
  "Vendor_Contact_for_Job__c": string;
  "Vendor_Dispatch_Department_Contact__c": string;
  "Vendor_Time_Card_Notes_Tasks_Performed__c": string;
  "Vendor_Time_Card_Observation_Suggestion__c": string;
  "Vendor_Time_Card_Total_Hours_All_Visits__c": number;
  "Vendor_Time_Card_Total_Hours_Per_Visit__c": number;
  "Vendor_Timecard_Cust_Site_Sign_off_Name__c": string;
  "Vendor_Timecard_Details_Roll_up__c": string;
  "Vendor__c": string;
  "Vendorsite__c": string;
  "View_in_Work_Market__c": string;
  "WM_Contact_Id__c": string;
  "WM_Get_Flag__c": boolean;
  "WM_Status__c": string;
  "Work_Order_Number__c": string;
  "Work_Order__c": string;
  "Worker_Arrival_DateTime_Cust_Requested__c": Date;
  "Worker_Arrival_DateTime_Scheduled__c": Date;
  "Worker_Arrival_Date_Customer_Requested__c": Date;
  "Worker_Arrival_Date_Local_Time_Zone__c": string;
  "Worker_Arrival_Date_Scheduled__c": Date;
  "Worker_Arrival_Date_Time_Actual__c": Date;
  "Worker_Arrival_End_Date_time_Cust_Req__c": Date;
  "Worker_Arrival_Hours_Actual__c": string;
  "Worker_Arrival_Hours_Customer_Requested__c": string;
  "Worker_Arrival_Minute_Customer_Requested__c": string;
  "Worker_Arrival_Minutes_Actual__c": string;
  "Worker_Arrival_Time_Hours_Scheduled__c": string;
  "Worker_Arrival_Time_Minutes_Scheduled__c": string;
  "Worker_Departure_Date_Time_Actual__c": Date;
  "Worker_End_DateTime_Scheduled__c": Date;
  "Worker_End_Date_Scheduled__c": Date;
  "Worker_End_Time_Hours_Scheduled__c": string;
  "Worker_End_Time_Minutes_Scheduled__c": string;
  "Worker_Finish_Date_Time_Actual__c": Date;
  "Worker_Finish_Time_Hour_Actual__c": string;
  "Worker_Finish_Time_Minutes_Actual__c": string;
  "Worker_In_Progress_Start_Hour_Actual__c": string;
  "Worker_In_Progress_Start_Minute_Actual__c": string;
  "Workmarket_Message__c": string;
  "csum__c": string;
  "worker_arrived_on_Time__c": string;
  "worker_arrived_with_correct_Tools__c": string;
  "Worker_InProgress_Start_DateTime_Actual__c": Date;
  "stageTracker": string;
  "progressTracker": string;
  "Created_by_CSD__C": boolean;
  "isCancelled": boolean;
  "FileCounter": number;
  "FolderLastModifiedOn": Date;
  "statusTracker": string;
  "acknowledge_instruction": boolean;
  "pre_visit_checklist": boolean;
  "systemTimecard": number;
  "submittedTimecard": boolean;
  "Generate_Timesheet_Confirm__c": boolean;
  "Generate_Timesheet_Skip__c": boolean;
  "IPAddress__c": string;
  "IPCity__c": string;
  "IPCountry__c": string;
  "IPRegion__c": string;
  "Publish_to_Workmarket__c": boolean;
  "Refresh_Job_From_Workmarket__c": boolean;
  "icc_recordtype_id": string;
  "is_backfill_worker": boolean;
  "PMS_Case_Status": string;
  "PMS_Case_Number": string;
  "Work_Order_Number": string;
  "PMC_Case_Number": string;
  "hasMessages": boolean;
  "Is_Public_Job__c": boolean;
  "Dispatch_worker_type": string;
  "Final_Timecard_Id": number;
  "Is_Final_Timecard_Submitted": boolean;
  "MSP_Checkin_Time__c": Date;
  "Survey_Template": string;
  "Survey_Link": string;
  "Survey_Status": string;
  "Survey_Created_Date": Date;
  "Survey_Due_Date": Date;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  "Talent_Type_ref__c": string;
  "workflowId": number;
  "workflowStatusId": number;
  vendor: Account;
  partner: Account;
  timecard: Timecard;
  timecards: Timecard[];
  purchaseOrder: PurchaseOrder;
  jobsite: Jobsite;
  deliverable: Deliverable;
  vendorsite: Vendorsite;
  appointment: Appointment;
  worker: Worker;
  program: Project;
  project: Project;
  case: Case;
  pmcCase: Case;
  pmsCase: Case;
  product: Product;
  talentType: TalentType;
  workOrder: WorkOrder;
  RecordType: RecordType;
  workflow: Workflow;
  workflowStatus: WorkflowStatus;
  salesOrder: Order;
  order: Order;
  escalationJob: RequestFormReceiptLine[];
  favorite: Favorite[];
  serviceContract: ServiceContract;
  timeClockManager: TimeClockManager[];
  jobSchedule: JobSchedule;
  constructor(data?: JobInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Job`.
   */
  public static getModelName() {
    return "Job";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Job for dynamic purposes.
  **/
  public static factory(data: JobInterface): Job{
    return new Job(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Job',
      plural: 'Jobs',
      path: 'Jobs',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "AccountId__c": {
          name: 'AccountId__c',
          type: 'string'
        },
        "Address_2__c": {
          name: 'Address_2__c',
          type: 'string'
        },
        "Appointment_Call_Notes__c": {
          name: 'Appointment_Call_Notes__c',
          type: 'string'
        },
        "Appointment_Plan_Option__c": {
          name: 'Appointment_Plan_Option__c',
          type: 'string'
        },
        "Appointment_Schedule_Status_Customer__c": {
          name: 'Appointment_Schedule_Status_Customer__c',
          type: 'string'
        },
        "Appointment_Schedule_Status_Customer_vms__c": {
          name: 'Appointment_Schedule_Status_Customer_vms__c',
          type: 'string'
        },
        "Appointment__c": {
          name: 'Appointment__c',
          type: 'string'
        },
        "Asset_Model__c": {
          name: 'Asset_Model__c',
          type: 'string'
        },
        "Asset_Name__c": {
          name: 'Asset_Name__c',
          type: 'string'
        },
        "Asset_Serial__c": {
          name: 'Asset_Serial__c',
          type: 'string'
        },
        "Assign_By_Email__c": {
          name: 'Assign_By_Email__c',
          type: 'string'
        },
        "Assignment_ID__c": {
          name: 'Assignment_ID__c',
          type: 'string'
        },
        "Assignments_Fulfilled__c": {
          name: 'Assignments_Fulfilled__c',
          type: 'string'
        },
        "B1A_IRON_Help_Desk_Cust_Phone_Recd__c": {
          name: 'B1A_IRON_Help_Desk_Cust_Phone_Recd__c',
          type: 'boolean'
        },
        "B2A_IRON_Help_Desk_Cust_Email_Recd__c": {
          name: 'B2A_IRON_Help_Desk_Cust_Email_Recd__c',
          type: 'boolean'
        },
        "B2_IRON_Help_Desk_Contact_Mode__c": {
          name: 'B2_IRON_Help_Desk_Contact_Mode__c',
          type: 'string'
        },
        "B3_IRON_Help_Desk_Customer_Contacted__c": {
          name: 'B3_IRON_Help_Desk_Customer_Contacted__c',
          type: 'boolean'
        },
        "CKSW_BASE__Account__c": {
          name: 'CKSW_BASE__Account__c',
          type: 'string'
        },
        "CKSW_BASE__Appointment_Finish__c": {
          name: 'CKSW_BASE__Appointment_Finish__c',
          type: 'Date'
        },
        "CKSW_BASE__Appointment_Grade__c": {
          name: 'CKSW_BASE__Appointment_Grade__c',
          type: 'number'
        },
        "CKSW_BASE__Appointment_Start__c": {
          name: 'CKSW_BASE__Appointment_Start__c',
          type: 'Date'
        },
        "CKSW_BASE__Area__c": {
          name: 'CKSW_BASE__Area__c',
          type: 'string'
        },
        "CKSW_BASE__Auto_Geocode__c": {
          name: 'CKSW_BASE__Auto_Geocode__c',
          type: 'boolean'
        },
        "CKSW_BASE__Auto_Schedule__c": {
          name: 'CKSW_BASE__Auto_Schedule__c',
          type: 'boolean'
        },
        "CKSW_BASE__City__c": {
          name: 'CKSW_BASE__City__c',
          type: 'string'
        },
        "CKSW_BASE__Comment__c": {
          name: 'CKSW_BASE__Comment__c',
          type: 'string'
        },
        "CKSW_BASE__Contact__c": {
          name: 'CKSW_BASE__Contact__c',
          type: 'string'
        },
        "CKSW_BASE__Country__c": {
          name: 'CKSW_BASE__Country__c',
          type: 'string'
        },
        "CKSW_BASE__CustomerPhone__c": {
          name: 'CKSW_BASE__CustomerPhone__c',
          type: 'string'
        },
        "CKSW_BASE__Date__c": {
          name: 'CKSW_BASE__Date__c',
          type: 'Date'
        },
        "CKSW_BASE__Day_Of_Week__c": {
          name: 'CKSW_BASE__Day_Of_Week__c',
          type: 'string'
        },
        "CKSW_BASE__Description__c": {
          name: 'CKSW_BASE__Description__c',
          type: 'string'
        },
        "CKSW_BASE__Due_Date__c": {
          name: 'CKSW_BASE__Due_Date__c',
          type: 'Date'
        },
        "CKSW_BASE__Duration_Type__c": {
          name: 'CKSW_BASE__Duration_Type__c',
          type: 'string'
        },
        "CKSW_BASE__Duration__c": {
          name: 'CKSW_BASE__Duration__c',
          type: 'number'
        },
        "CKSW_BASE__Early_Start__c": {
          name: 'CKSW_BASE__Early_Start__c',
          type: 'Date'
        },
        "Earliest_Start_Date_Permitted__c": {
          name: 'Earliest_Start_Date_Permitted__c',
          type: 'boolean'
        },
        "CKSW_BASE__Finish__c": {
          name: 'CKSW_BASE__Finish__c',
          type: 'Date'
        },
        "CKSW_BASE__Gantt_Label__c": {
          name: 'CKSW_BASE__Gantt_Label__c',
          type: 'string'
        },
        "CKSW_BASE__Geolocation__Latitude__s": {
          name: 'CKSW_BASE__Geolocation__Latitude__s',
          type: 'number'
        },
        "CKSW_BASE__Geolocation__Longitude__s": {
          name: 'CKSW_BASE__Geolocation__Longitude__s',
          type: 'number'
        },
        "CKSW_BASE__In_Jeopardy_Reason__c": {
          name: 'CKSW_BASE__In_Jeopardy_Reason__c',
          type: 'string'
        },
        "CKSW_BASE__In_Jeopardy__c": {
          name: 'CKSW_BASE__In_Jeopardy__c',
          type: 'boolean'
        },
        "CKSW_BASE__Incomplete_reason__c": {
          name: 'CKSW_BASE__Incomplete_reason__c',
          type: 'string'
        },
        "CKSW_BASE__Location__c": {
          name: 'CKSW_BASE__Location__c',
          type: 'string'
        },
        "CKSW_BASE__My_Service__c": {
          name: 'CKSW_BASE__My_Service__c',
          type: 'boolean'
        },
        "CKSW_BASE__Notification_Time__c": {
          name: 'CKSW_BASE__Notification_Time__c',
          type: 'Date'
        },
        "CKSW_BASE__Notification_Type__c": {
          name: 'CKSW_BASE__Notification_Type__c',
          type: 'string'
        },
        "CKSW_BASE__Open_Date__c": {
          name: 'CKSW_BASE__Open_Date__c',
          type: 'Date'
        },
        "CKSW_BASE__Other_Incomplete_Reason__c": {
          name: 'CKSW_BASE__Other_Incomplete_Reason__c',
          type: 'string'
        },
        "CKSW_BASE__Pinned__c": {
          name: 'CKSW_BASE__Pinned__c',
          type: 'boolean'
        },
        "CKSW_BASE__Priority__c": {
          name: 'CKSW_BASE__Priority__c',
          type: 'number'
        },
        "CKSW_BASE__RecurrenceKey__c": {
          name: 'CKSW_BASE__RecurrenceKey__c',
          type: 'string'
        },
        "CKSW_BASE__Recurrence__c": {
          name: 'CKSW_BASE__Recurrence__c',
          type: 'boolean'
        },
        "CKSW_BASE__Related_Service__c": {
          name: 'CKSW_BASE__Related_Service__c',
          type: 'string'
        },
        "CKSW_BASE__Resource__c": {
          name: 'CKSW_BASE__Resource__c',
          type: 'string'
        },
        "CKSW_BASE__Same_Day__c": {
          name: 'CKSW_BASE__Same_Day__c',
          type: 'boolean'
        },
        "CKSW_BASE__Same_Resource__c": {
          name: 'CKSW_BASE__Same_Resource__c',
          type: 'boolean'
        },
        "CKSW_BASE__Service_Order__c": {
          name: 'CKSW_BASE__Service_Order__c',
          type: 'string'
        },
        "CKSW_BASE__Service_Type__c": {
          name: 'CKSW_BASE__Service_Type__c',
          type: 'string'
        },
        "CKSW_BASE__Start__c": {
          name: 'CKSW_BASE__Start__c',
          type: 'Date'
        },
        "CKSW_BASE__State__c": {
          name: 'CKSW_BASE__State__c',
          type: 'string'
        },
        "CKSW_BASE__Status__c": {
          name: 'CKSW_BASE__Status__c',
          type: 'string'
        },
        "CKSW_BASE__Street__c": {
          name: 'CKSW_BASE__Street__c',
          type: 'string'
        },
        "CKSW_BASE__Time_Dependency__c": {
          name: 'CKSW_BASE__Time_Dependency__c',
          type: 'string'
        },
        "CKSW_BASE__Tooltip_Text__c": {
          name: 'CKSW_BASE__Tooltip_Text__c',
          type: 'string'
        },
        "CKSW_BASE__Total_Travel__c": {
          name: 'CKSW_BASE__Total_Travel__c',
          type: 'number'
        },
        "CKSW_BASE__Travel_Time_From_Hours__c": {
          name: 'CKSW_BASE__Travel_Time_From_Hours__c',
          type: 'number'
        },
        "CKSW_BASE__Travel_Time_From__c": {
          name: 'CKSW_BASE__Travel_Time_From__c',
          type: 'number'
        },
        "CKSW_BASE__Travel_Time_To_Hours__c": {
          name: 'CKSW_BASE__Travel_Time_To_Hours__c',
          type: 'number'
        },
        "CKSW_BASE__Travel_Time_To__c": {
          name: 'CKSW_BASE__Travel_Time_To__c',
          type: 'number'
        },
        "CKSW_BASE__UpdatedByOptimization__c": {
          name: 'CKSW_BASE__UpdatedByOptimization__c',
          type: 'boolean'
        },
        "CKSW_BASE__Zip__c": {
          name: 'CKSW_BASE__Zip__c',
          type: 'string'
        },
        "Case_Number__c": {
          name: 'Case_Number__c',
          type: 'string'
        },
        "Case__c": {
          name: 'Case__c',
          type: 'string'
        },
        "Casenum__c": {
          name: 'Casenum__c',
          type: 'string'
        },
        "Check_out__c": {
          name: 'Check_out__c',
          type: 'boolean'
        },
        "Cloned__c": {
          name: 'Cloned__c',
          type: 'boolean'
        },
        "commentCount": {
          name: 'commentCount',
          type: 'number'
        },
        "Communication__c": {
          name: 'Communication__c',
          type: 'string'
        },
        "Contact_Email__c": {
          name: 'Contact_Email__c',
          type: 'string'
        },
        "Count_of_Approved_Timecard_Timesheets__c": {
          name: 'Count_of_Approved_Timecard_Timesheets__c',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "Current_Timeclock__c": {
          name: 'Current_Timeclock__c',
          type: 'string'
        },
        "Custom_Field_1__c": {
          name: 'Custom_Field_1__c',
          type: 'string'
        },
        "Custom_work_order_instructions__c": {
          name: 'Custom_work_order_instructions__c',
          type: 'string'
        },
        "Customer_Appointment_DateTime_Scheduled__c": {
          name: 'Customer_Appointment_DateTime_Scheduled__c',
          type: 'Date'
        },
        "Customer_Appointment_Setup_Required__c": {
          name: 'Customer_Appointment_Setup_Required__c',
          type: 'string'
        },
        "Customer_Appointment_Start_HRS_Scheduled__c": {
          name: 'Customer_Appointment_Start_HRS_Scheduled__c',
          type: 'string'
        },
        "Customer_Appointment_Start_Scheduled__c": {
          name: 'Customer_Appointment_Start_Scheduled__c',
          type: 'Date'
        },
        "Customer_Apptmnt_Start_Minutes_Scheduled__c": {
          name: 'Customer_Apptmnt_Start_Minutes_Scheduled__c',
          type: 'string'
        },
        "Customer_Contact_Email__c": {
          name: 'Customer_Contact_Email__c',
          type: 'string'
        },
        "Customer_Contact_Phone__c": {
          name: 'Customer_Contact_Phone__c',
          type: 'string'
        },
        "Customer_Service_Type_From_Program__c": {
          name: 'Customer_Service_Type_From_Program__c',
          type: 'string'
        },
        "Customer_Service_Type__c": {
          name: 'Customer_Service_Type__c',
          type: 'string'
        },
        "Customer_Window_End_Date__c": {
          name: 'Customer_Window_End_Date__c',
          type: 'Date'
        },
        "Customer_Window_Start_Date__c": {
          name: 'Customer_Window_Start_Date__c',
          type: 'Date'
        },
        "Deliverable_Accuracy__c": {
          name: 'Deliverable_Accuracy__c',
          type: 'string'
        },
        "Deliverable_Status__c": {
          name: 'Deliverable_Status__c',
          type: 'string'
        },
        "Deliverables__c": {
          name: 'Deliverables__c',
          type: 'string'
        },
        "Delivery_Confirmation__c": {
          name: 'Delivery_Confirmation__c',
          type: 'Date'
        },
        "Dispatch_Service_Resolution_Status__c": {
          name: 'Dispatch_Service_Resolution_Status__c',
          type: 'string'
        },
        "Dispatch_Worker_Name_Text__c": {
          name: 'Dispatch_Worker_Name_Text__c',
          type: 'string'
        },
        "Dispatch_Worker_Name__c": {
          name: 'Dispatch_Worker_Name__c',
          type: 'string'
        },
        "Dispatch_Worker_Phone__c": {
          name: 'Dispatch_Worker_Phone__c',
          type: 'string'
        },
        "Does_this_Job_require_New_Equip_Hardware__c": {
          name: 'Does_this_Job_require_New_Equip_Hardware__c',
          type: 'string'
        },
        "Does_this_Job_require_Service_Parts__c": {
          name: 'Does_this_Job_require_Service_Parts__c',
          type: 'string'
        },
        "Email_to_Case_Subject__c": {
          name: 'Email_to_Case_Subject__c',
          type: 'string'
        },
        "Enable_Dispatch_Email__c": {
          name: 'Enable_Dispatch_Email__c',
          type: 'boolean'
        },
        "Equipment_Delivery_Date_ETA__c": {
          name: 'Equipment_Delivery_Date_ETA__c',
          type: 'Date'
        },
        "Equipment_Delivery_Time_ETA_Hours__c": {
          name: 'Equipment_Delivery_Time_ETA_Hours__c',
          type: 'string'
        },
        "Equipment_Delivery_Time_ETA_Minutes__c": {
          name: 'Equipment_Delivery_Time_ETA_Minutes__c',
          type: 'string'
        },
        "Equipment_Local_Pickup_Required__c": {
          name: 'Equipment_Local_Pickup_Required__c',
          type: 'string'
        },
        "Equipment_Tracking__c": {
          name: 'Equipment_Tracking__c',
          type: 'string'
        },
        "FE_Requested_Arrival_Date_Time__c": {
          name: 'FE_Requested_Arrival_Date_Time__c',
          type: 'string'
        },
        "Field_Service_Schedule_ETA_Date_Time__c": {
          name: 'Field_Service_Schedule_ETA_Date_Time__c',
          type: 'Date'
        },
        "Field_Service_Schedule_ETA_Date__c": {
          name: 'Field_Service_Schedule_ETA_Date__c',
          type: 'Date'
        },
        "Final_Timecard_Received__c": {
          name: 'Final_Timecard_Received__c',
          type: 'number'
        },
        "Final_Timecard_Submitted__c": {
          name: 'Final_Timecard_Submitted__c',
          type: 'boolean'
        },
        "Followed_Instruction__c": {
          name: 'Followed_Instruction__c',
          type: 'string'
        },
        "Generate_Timesheet__c": {
          name: 'Generate_Timesheet__c',
          type: 'boolean'
        },
        "Group__c": {
          name: 'Group__c',
          type: 'string'
        },
        "Hardware_Logistics_From_Project__c": {
          name: 'Hardware_Logistics_From_Project__c',
          type: 'string'
        },
        "HasProject__c": {
          name: 'HasProject__c',
          type: 'boolean'
        },
        "HasWO__c": {
          name: 'HasWO__c',
          type: 'boolean'
        },
        "Has_Job_Schedule__c": {
          name: 'Has_Job_Schedule__c',
          type: 'boolean'
        },
        "Has_Work_Order__c": {
          name: 'Has_Work_Order__c',
          type: 'boolean'
        },
        "Health_Case_Status__c": {
          name: 'Health_Case_Status__c',
          type: 'string'
        },
        "Health_Completion_Status__c": {
          name: 'Health_Completion_Status__c',
          type: 'string'
        },
        "Health_Customer_req__c": {
          name: 'Health_Customer_req__c',
          type: 'string'
        },
        "Health_FMS_Submission_Status__c": {
          name: 'Health_FMS_Submission_Status__c',
          type: 'string'
        },
        "Health_Jobsite_Status__c": {
          name: 'Health_Jobsite_Status__c',
          type: 'string'
        },
        "Health_List_Price_Status__c": {
          name: 'Health_List_Price_Status__c',
          type: 'string'
        },
        "Health_Project_Status__c": {
          name: 'Health_Project_Status__c',
          type: 'string'
        },
        "Health_Vendor_Status__c": {
          name: 'Health_Vendor_Status__c',
          type: 'string'
        },
        "Health_Worker_Status__c": {
          name: 'Health_Worker_Status__c',
          type: 'string'
        },
        "Health_custom_Window__c": {
          name: 'Health_custom_Window__c',
          type: 'string'
        },
        "IRON_Case_Created_Date_Partner__c": {
          name: 'IRON_Case_Created_Date_Partner__c',
          type: 'Date'
        },
        "IRON_Case_Notes_Vendor_via_VFMS_Email__c": {
          name: 'IRON_Case_Notes_Vendor_via_VFMS_Email__c',
          type: 'string'
        },
        "Invited_Pool_FMS__c": {
          name: 'Invited_Pool_FMS__c',
          type: 'string'
        },
        "Invited_Pool_VMS__c": {
          name: 'Invited_Pool_VMS__c',
          type: 'string'
        },
        "Invited_Worker__c": {
          name: 'Invited_Worker__c',
          type: 'string'
        },
        "Invited_Workers_Multiple__c": {
          name: 'Invited_Workers_Multiple__c',
          type: 'string'
        },
        "Iron_Case__c": {
          name: 'Iron_Case__c',
          type: 'string'
        },
        "Iron_Job_Assistance__c": {
          name: 'Iron_Job_Assistance__c',
          type: 'string'
        },
        "Iron_Job_num__c": {
          name: 'Iron_Job_num__c',
          type: 'string'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "Is_this_FTE_Job__c": {
          name: 'Is_this_FTE_Job__c',
          type: 'boolean'
        },
        "JobSite_Local_Instructions__c": {
          name: 'JobSite_Local_Instructions__c',
          type: 'string'
        },
        "Job_Accepted_Date__c": {
          name: 'Job_Accepted_Date__c',
          type: 'Date'
        },
        "Job_Assigned_Date__c": {
          name: 'Job_Assigned_Date__c',
          type: 'Date'
        },
        "Job_Closed_Date__c": {
          name: 'Job_Closed_Date__c',
          type: 'Date'
        },
        "Job_Complete__c": {
          name: 'Job_Complete__c',
          type: 'Date'
        },
        "Job_Completion_Time__c": {
          name: 'Job_Completion_Time__c',
          type: 'number'
        },
        "Job_Completion_Time_in_hrs__c": {
          name: 'Job_Completion_Time_in_hrs__c',
          type: 'number'
        },
        "Job_Created__c": {
          name: 'Job_Created__c',
          type: 'Date'
        },
        "Job_Cycle_Time__c": {
          name: 'Job_Cycle_Time__c',
          type: 'number'
        },
        "Job_Decline_Date__c": {
          name: 'Job_Decline_Date__c',
          type: 'Date'
        },
        "Job_Instruction_Details_Long__c": {
          name: 'Job_Instruction_Details_Long__c',
          type: 'string'
        },
        "Job_Instructions_Deliverables_Summary__c": {
          name: 'Job_Instructions_Deliverables_Summary__c',
          type: 'string'
        },
        "Job_Instructions_Worker_Specific_Request__c": {
          name: 'Job_Instructions_Worker_Specific_Request__c',
          type: 'string'
        },
        "Job_Instructions__c": {
          name: 'Job_Instructions__c',
          type: 'string'
        },
        "Job_Invited_Date__c": {
          name: 'Job_Invited_Date__c',
          type: 'Date'
        },
        "Job_Invoiced_Date__c": {
          name: 'Job_Invoiced_Date__c',
          type: 'Date'
        },
        "Job_Paid_Date__c": {
          name: 'Job_Paid_Date__c',
          type: 'Date'
        },
        "Job_Pending_Approval_Date__c": {
          name: 'Job_Pending_Approval_Date__c',
          type: 'Date'
        },
        "Job_Progress__c": {
          name: 'Job_Progress__c',
          type: 'string'
        },
        "Job_Schedule__c": {
          name: 'Job_Schedule__c',
          type: 'string'
        },
        "Job_Started_Date__c": {
          name: 'Job_Started_Date__c',
          type: 'Date'
        },
        "Job_Status_Internal__c": {
          name: 'Job_Status_Internal__c',
          type: 'string'
        },
        "Job_Subject__c": {
          name: 'Job_Subject__c',
          type: 'string'
        },
        "Job_Type__c": {
          name: 'Job_Type__c',
          type: 'string'
        },
        "Jobs_Resolution_Status_FSE_Date_Time__c": {
          name: 'Jobs_Resolution_Status_FSE_Date_Time__c',
          type: 'Date'
        },
        "Jobsite_Contact_SDesk_Name_phone_email__c": {
          name: 'Jobsite_Contact_SDesk_Name_phone_email__c',
          type: 'string'
        },
        "Jobsite_Contact_Service_Desk__c": {
          name: 'Jobsite_Contact_Service_Desk__c',
          type: 'string'
        },
        "Jobsite_Contact_TEsc_Name_phone_email__c": {
          name: 'Jobsite_Contact_TEsc_Name_phone_email__c',
          type: 'string'
        },
        "Jobsite_Contact_Technical_Escalation__c": {
          name: 'Jobsite_Contact_Technical_Escalation__c',
          type: 'string'
        },
        "Jobsite_Name__c": {
          name: 'Jobsite_Name__c',
          type: 'string'
        },
        "Jobsite__c": {
          name: 'Jobsite__c',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "LastReferencedDate": {
          name: 'LastReferencedDate',
          type: 'Date'
        },
        "LastViewedDate": {
          name: 'LastViewedDate',
          type: 'Date'
        },
        "Latest_Monthly_Invoice__c": {
          name: 'Latest_Monthly_Invoice__c',
          type: 'string'
        },
        "Latest_Timecard_Timesheet__c": {
          name: 'Latest_Timecard_Timesheet__c',
          type: 'string'
        },
        "Location_Name__c": {
          name: 'Location_Name__c',
          type: 'string'
        },
        "Location_Type__c": {
          name: 'Location_Type__c',
          type: 'string'
        },
        "Max_Hours_Secondary_Rate__c": {
          name: 'Max_Hours_Secondary_Rate__c',
          type: 'number'
        },
        "Max_Hours_Units__c": {
          name: 'Max_Hours_Units__c',
          type: 'number'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "Order_Product_Id__c": {
          name: 'Order_Product_Id__c',
          type: 'string'
        },
        "Overall_Rating__c": {
          name: 'Overall_Rating__c',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "Owner_Email__c": {
          name: 'Owner_Email__c',
          type: 'string'
        },
        "Partner_Case_Error_Description__c": {
          name: 'Partner_Case_Error_Description__c',
          type: 'string'
        },
        "Partner_Case_Number__c": {
          name: 'Partner_Case_Number__c',
          type: 'string'
        },
        "Partner_FRU_Sku__c": {
          name: 'Partner_FRU_Sku__c',
          type: 'string'
        },
        "Partner_RMA_Number__c": {
          name: 'Partner_RMA_Number__c',
          type: 'string'
        },
        "Pause_Check_in__c": {
          name: 'Pause_Check_in__c',
          type: 'boolean'
        },
        "Phone_Scheduling_1st_Attempt_Unreachable__c": {
          name: 'Phone_Scheduling_1st_Attempt_Unreachable__c',
          type: 'Date'
        },
        "Phone_Scheduling_2nd_Attempt_Unreachable__c": {
          name: 'Phone_Scheduling_2nd_Attempt_Unreachable__c',
          type: 'Date'
        },
        "Phone_Scheduling_3rd_Attempt_Unreachable__c": {
          name: 'Phone_Scheduling_3rd_Attempt_Unreachable__c',
          type: 'Date'
        },
        "Pricing_Type__c": {
          name: 'Pricing_Type__c',
          type: 'string'
        },
        "Professionalism__c": {
          name: 'Professionalism__c',
          type: 'string'
        },
        "Project_SOP__c": {
          name: 'Project_SOP__c',
          type: 'string'
        },
        "Project__c": {
          name: 'Project__c',
          type: 'string'
        },
        "Project_num__c": {
          name: 'Project_num__c',
          type: 'string'
        },
        "Purchase_Order_Type__c": {
          name: 'Purchase_Order_Type__c',
          type: 'string'
        },
        "Quality_Rating__c": {
          name: 'Quality_Rating__c',
          type: 'string'
        },
        "Question_to_IRON_Help_Desk__c": {
          name: 'Question_to_IRON_Help_Desk__c',
          type: 'string'
        },
        "Rack_Kit_Top_of_Switch__c": {
          name: 'Rack_Kit_Top_of_Switch__c',
          type: 'string'
        },
        "Rack_Serial__c": {
          name: 'Rack_Serial__c',
          type: 'string'
        },
        "Rack_Side__c": {
          name: 'Rack_Side__c',
          type: 'string'
        },
        "Rack_Sub_Position__c": {
          name: 'Rack_Sub_Position__c',
          type: 'string'
        },
        "Rack_Sub_Position_Slot__c": {
          name: 'Rack_Sub_Position_Slot__c',
          type: 'string'
        },
        "Rack__c": {
          name: 'Rack__c',
          type: 'string'
        },
        "Rate__c": {
          name: 'Rate__c',
          type: 'number'
        },
        "RecordTypeId": {
          name: 'RecordTypeId',
          type: 'string'
        },
        "Refresh_Information_from_Case__c": {
          name: 'Refresh_Information_from_Case__c',
          type: 'boolean'
        },
        "Refresh_Information_from_Project__c": {
          name: 'Refresh_Information_from_Project__c',
          type: 'boolean'
        },
        "Refresh_Location_Info_From_Case__c": {
          name: 'Refresh_Location_Info_From_Case__c',
          type: 'boolean'
        },
        "Refresh_information_from_Purchase_Order__c": {
          name: 'Refresh_information_from_Purchase_Order__c',
          type: 'boolean'
        },
        "Regenerate_SO_PO__c": {
          name: 'Regenerate_SO_PO__c',
          type: 'boolean'
        },
        "Required_Tools_From_SOP__c": {
          name: 'Required_Tools_From_SOP__c',
          type: 'string'
        },
        "Resend_Dispatch_Email__c": {
          name: 'Resend_Dispatch_Email__c',
          type: 'boolean'
        },
        "Resource_Company_Name__c": {
          name: 'Resource_Company_Name__c',
          type: 'string'
        },
        "Resource_Email__c": {
          name: 'Resource_Email__c',
          type: 'string'
        },
        "Resource_Name__c": {
          name: 'Resource_Name__c',
          type: 'string'
        },
        "Resource_Phone__c": {
          name: 'Resource_Phone__c',
          type: 'string'
        },
        "Restart_Check_in__c": {
          name: 'Restart_Check_in__c',
          type: 'boolean'
        },
        "Roll_up_Timecard_s_IRON_Approval_Status__c": {
          name: 'Roll_up_Timecard_s_IRON_Approval_Status__c',
          type: 'string'
        },
        "Row__c": {
          name: 'Row__c',
          type: 'string'
        },
        "SC_EndDate__c": {
          name: 'SC_EndDate__c',
          type: 'Date'
        },
        "SC_StartDate__c": {
          name: 'SC_StartDate__c',
          type: 'Date'
        },
        "SKU_Item_Vendor_Purchase_Order__c": {
          name: 'SKU_Item_Vendor_Purchase_Order__c',
          type: 'string'
        },
        "SKU__c": {
          name: 'SKU__c',
          type: 'string'
        },
        "SOW_Equipment__c": {
          name: 'SOW_Equipment__c',
          type: 'string'
        },
        "SOW_Helpdesk__c": {
          name: 'SOW_Helpdesk__c',
          type: 'string'
        },
        "SOW_Payment_Terms__c": {
          name: 'SOW_Payment_Terms__c',
          type: 'string'
        },
        "SOW_Tools__c": {
          name: 'SOW_Tools__c',
          type: 'string'
        },
        "SOW_WO_concat_2__c": {
          name: 'SOW_WO_concat_2__c',
          type: 'string'
        },
        "SOW_WO_concat__c": {
          name: 'SOW_WO_concat__c',
          type: 'string'
        },
        "SOW_Work_Order_Extended__c": {
          name: 'SOW_Work_Order_Extended__c',
          type: 'string'
        },
        "SOW_Work_Order__c": {
          name: 'SOW_Work_Order__c',
          type: 'string'
        },
        "Sales_Order__c": {
          name: 'Sales_Order__c',
          type: 'string'
        },
        "Secondary_Rate__c": {
          name: 'Secondary_Rate__c',
          type: 'number'
        },
        "Service_Contract__c": {
          name: 'Service_Contract__c',
          type: 'string'
        },
        "Service_Deliverables_From_Project__c": {
          name: 'Service_Deliverables_From_Project__c',
          type: 'string'
        },
        "Service_Description_From_Project__c": {
          name: 'Service_Description_From_Project__c',
          type: 'string'
        },
        "Service_Dispatch_Comments__c": {
          name: 'Service_Dispatch_Comments__c',
          type: 'string'
        },
        "Service_Dispatch_SLA_Priority_FrmProgram__c": {
          name: 'Service_Dispatch_SLA_Priority_FrmProgram__c',
          type: 'string'
        },
        "Service_Dispatch_SLA_Priority__c": {
          name: 'Service_Dispatch_SLA_Priority__c',
          type: 'string'
        },
        "Service_Parts_Local_Pickup_Required__c": {
          name: 'Service_Parts_Local_Pickup_Required__c',
          type: 'string'
        },
        "Service_SOW_Description_Customer__c": {
          name: 'Service_SOW_Description_Customer__c',
          type: 'string'
        },
        "Service_Schedule__c": {
          name: 'Service_Schedule__c',
          type: 'string'
        },
        "shortUrl": {
          name: 'shortUrl',
          type: 'string'
        },
        "Skills_Specialties__c": {
          name: 'Skills_Specialties__c',
          type: 'string'
        },
        "SoW_Desc_Vendor_concat__c": {
          name: 'SoW_Desc_Vendor_concat__c',
          type: 'string'
        },
        "SoW_Description_For_Vendor_Extended_10__c": {
          name: 'SoW_Description_For_Vendor_Extended_10__c',
          type: 'string'
        },
        "SoW_Description_For_Vendor_Extended_1__c": {
          name: 'SoW_Description_For_Vendor_Extended_1__c',
          type: 'string'
        },
        "SoW_Description_For_Vendor_Extended_2__c": {
          name: 'SoW_Description_For_Vendor_Extended_2__c',
          type: 'string'
        },
        "SoW_Description_For_Vendor_Extended_3__c": {
          name: 'SoW_Description_For_Vendor_Extended_3__c',
          type: 'string'
        },
        "SoW_Description_For_Vendor_Extended_5__c": {
          name: 'SoW_Description_For_Vendor_Extended_5__c',
          type: 'string'
        },
        "SoW_Description_For_Vendor_Extended_6__c": {
          name: 'SoW_Description_For_Vendor_Extended_6__c',
          type: 'string'
        },
        "SoW_Description_For_Vendor_Extended_7__c": {
          name: 'SoW_Description_For_Vendor_Extended_7__c',
          type: 'string'
        },
        "SoW_Description_For_Vendor_Extended_8__c": {
          name: 'SoW_Description_For_Vendor_Extended_8__c',
          type: 'string'
        },
        "SoW_Description_For_Vendor_Extended_9__c": {
          name: 'SoW_Description_For_Vendor_Extended_9__c',
          type: 'string'
        },
        "SoW_Description_Vendor__c": {
          name: 'SoW_Description_Vendor__c',
          type: 'string'
        },
        "Special_Instruction_from_PMS_Case_Auto__c": {
          name: 'Special_Instruction_from_PMS_Case_Auto__c',
          type: 'string'
        },
        "Special_Instructions_Extended__c": {
          name: 'Special_Instructions_Extended__c',
          type: 'string'
        },
        "Special_Instructions__c": {
          name: 'Special_Instructions__c',
          type: 'string'
        },
        "Special_Service_Instructions_Project__c": {
          name: 'Special_Service_Instructions_Project__c',
          type: 'string'
        },
        "Specific_Schedule_Time__c": {
          name: 'Specific_Schedule_Time__c',
          type: 'Date'
        },
        "Start_Check_in__c": {
          name: 'Start_Check_in__c',
          type: 'boolean'
        },
        "Statement_of_Work_Description_Combined__c": {
          name: 'Statement_of_Work_Description_Combined__c',
          type: 'string'
        },
        "Static_Text_Email_Dispatch_DeskMgmt__c": {
          name: 'Static_Text_Email_Dispatch_DeskMgmt__c',
          type: 'string'
        },
        "Static_Text_Email_Header__c": {
          name: 'Static_Text_Email_Header__c',
          type: 'string'
        },
        "Static_Text_Email_Job_Instruction__c": {
          name: 'Static_Text_Email_Job_Instruction__c',
          type: 'string'
        },
        "Static_Text_Email_Please_Call_Customer__c": {
          name: 'Static_Text_Email_Please_Call_Customer__c',
          type: 'string'
        },
        "Static_Text_Email_Technical_Doc_Extended__c": {
          name: 'Static_Text_Email_Technical_Doc_Extended__c',
          type: 'string'
        },
        "Static_Text_Email_Technical_Document__c": {
          name: 'Static_Text_Email_Technical_Document__c',
          type: 'string'
        },
        "Submiited_to_WM__c": {
          name: 'Submiited_to_WM__c',
          type: 'boolean'
        },
        "Suite__c": {
          name: 'Suite__c',
          type: 'string'
        },
        "Talent_Level_Program__c": {
          name: 'Talent_Level_Program__c',
          type: 'string'
        },
        "Talent_Type__c": {
          name: 'Talent_Type__c',
          type: 'string'
        },
        "Technical_Level__c": {
          name: 'Technical_Level__c',
          type: 'string'
        },
        "Title__c": {
          name: 'Title__c',
          type: 'string'
        },
        "Total_Approved_Timecard_Hours__c": {
          name: 'Total_Approved_Timecard_Hours__c',
          type: 'number'
        },
        "Total_FTE_Hours__c": {
          name: 'Total_FTE_Hours__c',
          type: 'number'
        },
        "Total_Number_of_Timecards_Submitted__c": {
          name: 'Total_Number_of_Timecards_Submitted__c',
          type: 'number'
        },
        "Total_Time_Consume_by_Worker__c": {
          name: 'Total_Time_Consume_by_Worker__c',
          type: 'number'
        },
        "Training_Documents_From_Project__c": {
          name: 'Training_Documents_From_Project__c',
          type: 'string'
        },
        "Type_of_Work__c": {
          name: 'Type_of_Work__c',
          type: 'string'
        },
        "User_Name__c": {
          name: 'User_Name__c',
          type: 'string'
        },
        "Vendor_Contact_for_Job__c": {
          name: 'Vendor_Contact_for_Job__c',
          type: 'string'
        },
        "Vendor_Dispatch_Department_Contact__c": {
          name: 'Vendor_Dispatch_Department_Contact__c',
          type: 'string'
        },
        "Vendor_Time_Card_Notes_Tasks_Performed__c": {
          name: 'Vendor_Time_Card_Notes_Tasks_Performed__c',
          type: 'string'
        },
        "Vendor_Time_Card_Observation_Suggestion__c": {
          name: 'Vendor_Time_Card_Observation_Suggestion__c',
          type: 'string'
        },
        "Vendor_Time_Card_Total_Hours_All_Visits__c": {
          name: 'Vendor_Time_Card_Total_Hours_All_Visits__c',
          type: 'number'
        },
        "Vendor_Time_Card_Total_Hours_Per_Visit__c": {
          name: 'Vendor_Time_Card_Total_Hours_Per_Visit__c',
          type: 'number'
        },
        "Vendor_Timecard_Cust_Site_Sign_off_Name__c": {
          name: 'Vendor_Timecard_Cust_Site_Sign_off_Name__c',
          type: 'string'
        },
        "Vendor_Timecard_Details_Roll_up__c": {
          name: 'Vendor_Timecard_Details_Roll_up__c',
          type: 'string'
        },
        "Vendor__c": {
          name: 'Vendor__c',
          type: 'string'
        },
        "Vendorsite__c": {
          name: 'Vendorsite__c',
          type: 'string'
        },
        "View_in_Work_Market__c": {
          name: 'View_in_Work_Market__c',
          type: 'string'
        },
        "WM_Contact_Id__c": {
          name: 'WM_Contact_Id__c',
          type: 'string'
        },
        "WM_Get_Flag__c": {
          name: 'WM_Get_Flag__c',
          type: 'boolean'
        },
        "WM_Status__c": {
          name: 'WM_Status__c',
          type: 'string'
        },
        "Work_Order_Number__c": {
          name: 'Work_Order_Number__c',
          type: 'string'
        },
        "Work_Order__c": {
          name: 'Work_Order__c',
          type: 'string'
        },
        "Worker_Arrival_DateTime_Cust_Requested__c": {
          name: 'Worker_Arrival_DateTime_Cust_Requested__c',
          type: 'Date'
        },
        "Worker_Arrival_DateTime_Scheduled__c": {
          name: 'Worker_Arrival_DateTime_Scheduled__c',
          type: 'Date'
        },
        "Worker_Arrival_Date_Customer_Requested__c": {
          name: 'Worker_Arrival_Date_Customer_Requested__c',
          type: 'Date'
        },
        "Worker_Arrival_Date_Local_Time_Zone__c": {
          name: 'Worker_Arrival_Date_Local_Time_Zone__c',
          type: 'string'
        },
        "Worker_Arrival_Date_Scheduled__c": {
          name: 'Worker_Arrival_Date_Scheduled__c',
          type: 'Date'
        },
        "Worker_Arrival_Date_Time_Actual__c": {
          name: 'Worker_Arrival_Date_Time_Actual__c',
          type: 'Date'
        },
        "Worker_Arrival_End_Date_time_Cust_Req__c": {
          name: 'Worker_Arrival_End_Date_time_Cust_Req__c',
          type: 'Date'
        },
        "Worker_Arrival_Hours_Actual__c": {
          name: 'Worker_Arrival_Hours_Actual__c',
          type: 'string'
        },
        "Worker_Arrival_Hours_Customer_Requested__c": {
          name: 'Worker_Arrival_Hours_Customer_Requested__c',
          type: 'string'
        },
        "Worker_Arrival_Minute_Customer_Requested__c": {
          name: 'Worker_Arrival_Minute_Customer_Requested__c',
          type: 'string'
        },
        "Worker_Arrival_Minutes_Actual__c": {
          name: 'Worker_Arrival_Minutes_Actual__c',
          type: 'string'
        },
        "Worker_Arrival_Time_Hours_Scheduled__c": {
          name: 'Worker_Arrival_Time_Hours_Scheduled__c',
          type: 'string'
        },
        "Worker_Arrival_Time_Minutes_Scheduled__c": {
          name: 'Worker_Arrival_Time_Minutes_Scheduled__c',
          type: 'string'
        },
        "Worker_Departure_Date_Time_Actual__c": {
          name: 'Worker_Departure_Date_Time_Actual__c',
          type: 'Date'
        },
        "Worker_End_DateTime_Scheduled__c": {
          name: 'Worker_End_DateTime_Scheduled__c',
          type: 'Date'
        },
        "Worker_End_Date_Scheduled__c": {
          name: 'Worker_End_Date_Scheduled__c',
          type: 'Date'
        },
        "Worker_End_Time_Hours_Scheduled__c": {
          name: 'Worker_End_Time_Hours_Scheduled__c',
          type: 'string'
        },
        "Worker_End_Time_Minutes_Scheduled__c": {
          name: 'Worker_End_Time_Minutes_Scheduled__c',
          type: 'string'
        },
        "Worker_Finish_Date_Time_Actual__c": {
          name: 'Worker_Finish_Date_Time_Actual__c',
          type: 'Date'
        },
        "Worker_Finish_Time_Hour_Actual__c": {
          name: 'Worker_Finish_Time_Hour_Actual__c',
          type: 'string'
        },
        "Worker_Finish_Time_Minutes_Actual__c": {
          name: 'Worker_Finish_Time_Minutes_Actual__c',
          type: 'string'
        },
        "Worker_In_Progress_Start_Hour_Actual__c": {
          name: 'Worker_In_Progress_Start_Hour_Actual__c',
          type: 'string'
        },
        "Worker_In_Progress_Start_Minute_Actual__c": {
          name: 'Worker_In_Progress_Start_Minute_Actual__c',
          type: 'string'
        },
        "Workmarket_Message__c": {
          name: 'Workmarket_Message__c',
          type: 'string'
        },
        "csum__c": {
          name: 'csum__c',
          type: 'string'
        },
        "worker_arrived_on_Time__c": {
          name: 'worker_arrived_on_Time__c',
          type: 'string'
        },
        "worker_arrived_with_correct_Tools__c": {
          name: 'worker_arrived_with_correct_Tools__c',
          type: 'string'
        },
        "Worker_InProgress_Start_DateTime_Actual__c": {
          name: 'Worker_InProgress_Start_DateTime_Actual__c',
          type: 'Date'
        },
        "stageTracker": {
          name: 'stageTracker',
          type: 'string'
        },
        "progressTracker": {
          name: 'progressTracker',
          type: 'string'
        },
        "Created_by_CSD__C": {
          name: 'Created_by_CSD__C',
          type: 'boolean'
        },
        "isCancelled": {
          name: 'isCancelled',
          type: 'boolean',
          default: false
        },
        "FileCounter": {
          name: 'FileCounter',
          type: 'number'
        },
        "FolderLastModifiedOn": {
          name: 'FolderLastModifiedOn',
          type: 'Date'
        },
        "statusTracker": {
          name: 'statusTracker',
          type: 'string'
        },
        "acknowledge_instruction": {
          name: 'acknowledge_instruction',
          type: 'boolean'
        },
        "pre_visit_checklist": {
          name: 'pre_visit_checklist',
          type: 'boolean'
        },
        "systemTimecard": {
          name: 'systemTimecard',
          type: 'number'
        },
        "submittedTimecard": {
          name: 'submittedTimecard',
          type: 'boolean',
          default: false
        },
        "Generate_Timesheet_Confirm__c": {
          name: 'Generate_Timesheet_Confirm__c',
          type: 'boolean'
        },
        "Generate_Timesheet_Skip__c": {
          name: 'Generate_Timesheet_Skip__c',
          type: 'boolean'
        },
        "IPAddress__c": {
          name: 'IPAddress__c',
          type: 'string'
        },
        "IPCity__c": {
          name: 'IPCity__c',
          type: 'string'
        },
        "IPCountry__c": {
          name: 'IPCountry__c',
          type: 'string'
        },
        "IPRegion__c": {
          name: 'IPRegion__c',
          type: 'string'
        },
        "Publish_to_Workmarket__c": {
          name: 'Publish_to_Workmarket__c',
          type: 'boolean'
        },
        "Refresh_Job_From_Workmarket__c": {
          name: 'Refresh_Job_From_Workmarket__c',
          type: 'boolean'
        },
        "icc_recordtype_id": {
          name: 'icc_recordtype_id',
          type: 'string'
        },
        "is_backfill_worker": {
          name: 'is_backfill_worker',
          type: 'boolean'
        },
        "PMS_Case_Status": {
          name: 'PMS_Case_Status',
          type: 'string'
        },
        "PMS_Case_Number": {
          name: 'PMS_Case_Number',
          type: 'string'
        },
        "Work_Order_Number": {
          name: 'Work_Order_Number',
          type: 'string'
        },
        "PMC_Case_Number": {
          name: 'PMC_Case_Number',
          type: 'string'
        },
        "hasMessages": {
          name: 'hasMessages',
          type: 'boolean'
        },
        "Is_Public_Job__c": {
          name: 'Is_Public_Job__c',
          type: 'boolean'
        },
        "Dispatch_worker_type": {
          name: 'Dispatch_worker_type',
          type: 'string'
        },
        "Final_Timecard_Id": {
          name: 'Final_Timecard_Id',
          type: 'number'
        },
        "Is_Final_Timecard_Submitted": {
          name: 'Is_Final_Timecard_Submitted',
          type: 'boolean'
        },
        "MSP_Checkin_Time__c": {
          name: 'MSP_Checkin_Time__c',
          type: 'Date'
        },
        "Survey_Template": {
          name: 'Survey_Template',
          type: 'string'
        },
        "Survey_Link": {
          name: 'Survey_Link',
          type: 'string'
        },
        "Survey_Status": {
          name: 'Survey_Status',
          type: 'string'
        },
        "Survey_Created_Date": {
          name: 'Survey_Created_Date',
          type: 'Date'
        },
        "Survey_Due_Date": {
          name: 'Survey_Due_Date',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "Talent_Type_ref__c": {
          name: 'Talent_Type_ref__c',
          type: 'string'
        },
        "workflowId": {
          name: 'workflowId',
          type: 'number'
        },
        "workflowStatusId": {
          name: 'workflowStatusId',
          type: 'number'
        },
      },
      relations: {
        vendor: {
          name: 'vendor',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'Vendor__c',
          keyTo: 'sfdcId'
        },
        partner: {
          name: 'partner',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'CKSW_BASE__Account__c',
          keyTo: 'sfdcId'
        },
        timecard: {
          name: 'timecard',
          type: 'Timecard',
          model: 'Timecard',
          relationType: 'belongsTo',
                  keyFrom: 'systemTimecard',
          keyTo: 'id'
        },
        timecards: {
          name: 'timecards',
          type: 'Timecard[]',
          model: 'Timecard',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'Service_Dispatch__c'
        },
        purchaseOrder: {
          name: 'purchaseOrder',
          type: 'PurchaseOrder',
          model: 'PurchaseOrder',
          relationType: 'belongsTo',
                  keyFrom: 'sfdcId',
          keyTo: 'Service_Dispatch__c'
        },
        jobsite: {
          name: 'jobsite',
          type: 'Jobsite',
          model: 'Jobsite',
          relationType: 'belongsTo',
                  keyFrom: 'Jobsite__c',
          keyTo: 'sfdcId'
        },
        deliverable: {
          name: 'deliverable',
          type: 'Deliverable',
          model: 'Deliverable',
          relationType: 'belongsTo',
                  keyFrom: 'Deliverables__c',
          keyTo: 'sfdcId'
        },
        vendorsite: {
          name: 'vendorsite',
          type: 'Vendorsite',
          model: 'Vendorsite',
          relationType: 'belongsTo',
                  keyFrom: 'Vendorsite__c',
          keyTo: 'sfdcId'
        },
        appointment: {
          name: 'appointment',
          type: 'Appointment',
          model: 'Appointment',
          relationType: 'belongsTo',
                  keyFrom: 'Case__c',
          keyTo: 'ICC_Case__c'
        },
        worker: {
          name: 'worker',
          type: 'Worker',
          model: 'Worker',
          relationType: 'belongsTo',
                  keyFrom: 'Dispatch_Worker_Name__c',
          keyTo: 'sfdcId'
        },
        program: {
          name: 'program',
          type: 'Project',
          model: 'Project',
          relationType: 'belongsTo',
                  keyFrom: 'Project_SOP__c',
          keyTo: 'sfdcId'
        },
        project: {
          name: 'project',
          type: 'Project',
          model: 'Project',
          relationType: 'belongsTo',
                  keyFrom: 'Project__c',
          keyTo: 'sfdcId'
        },
        case: {
          name: 'case',
          type: 'Case',
          model: 'Case',
          relationType: 'belongsTo',
                  keyFrom: 'Case__c',
          keyTo: 'sfdcId'
        },
        pmcCase: {
          name: 'pmcCase',
          type: 'Case',
          model: 'Case',
          relationType: 'belongsTo',
                  keyFrom: 'PMC_Case_Number',
          keyTo: 'sfdcId'
        },
        pmsCase: {
          name: 'pmsCase',
          type: 'Case',
          model: 'Case',
          relationType: 'belongsTo',
                  keyFrom: 'PMS_Case_Number',
          keyTo: 'sfdcId'
        },
        product: {
          name: 'product',
          type: 'Product',
          model: 'Product',
          relationType: 'belongsTo',
                  keyFrom: 'SKU__c',
          keyTo: 'sfdcId'
        },
        talentType: {
          name: 'talentType',
          type: 'TalentType',
          model: 'TalentType',
          relationType: 'belongsTo',
                  keyFrom: 'Talent_Type_ref__c',
          keyTo: 'sfdcId'
        },
        workOrder: {
          name: 'workOrder',
          type: 'WorkOrder',
          model: 'WorkOrder',
          relationType: 'belongsTo',
                  keyFrom: 'Work_Order_Number',
          keyTo: 'sfdcId'
        },
        RecordType: {
          name: 'RecordType',
          type: 'RecordType',
          model: 'RecordType',
          relationType: 'belongsTo',
                  keyFrom: 'RecordTypeId',
          keyTo: 'sfdcId'
        },
        workflow: {
          name: 'workflow',
          type: 'Workflow',
          model: 'Workflow',
          relationType: 'belongsTo',
                  keyFrom: 'workflowId',
          keyTo: 'id'
        },
        workflowStatus: {
          name: 'workflowStatus',
          type: 'WorkflowStatus',
          model: 'WorkflowStatus',
          relationType: 'belongsTo',
                  keyFrom: 'workflowStatusId',
          keyTo: 'id'
        },
        salesOrder: {
          name: 'salesOrder',
          type: 'Order',
          model: 'Order',
          relationType: 'belongsTo',
                  keyFrom: 'Sales_Order__c',
          keyTo: 'sfdcId'
        },
        order: {
          name: 'order',
          type: 'Order',
          model: 'Order',
          relationType: 'hasOne',
                  keyFrom: 'sfdcId',
          keyTo: 'Service_Dispatch__c'
        },
        escalationJob: {
          name: 'escalationJob',
          type: 'RequestFormReceiptLine[]',
          model: 'RequestFormReceiptLine',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'Job__c'
        },
        favorite: {
          name: 'favorite',
          type: 'Favorite[]',
          model: 'Favorite',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'modelId'
        },
        serviceContract: {
          name: 'serviceContract',
          type: 'ServiceContract',
          model: 'ServiceContract',
          relationType: 'belongsTo',
                  keyFrom: 'Service_Contract__c',
          keyTo: 'sfdcId'
        },
        timeClockManager: {
          name: 'timeClockManager',
          type: 'TimeClockManager[]',
          model: 'TimeClockManager',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'Job__c'
        },
        jobSchedule: {
          name: 'jobSchedule',
          type: 'JobSchedule',
          model: 'JobSchedule',
          relationType: 'belongsTo',
                  keyFrom: 'Job_Schedule__c',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
