import { Component, OnInit } from '@angular/core';
import { ReleaseNotesApi } from 'shared/sdk';
import { PreloaderService } from 'shared/services/preloader.service';
import { APP_VERSION, BUILD_DATE } from './../../../msp/version';

@Component({
  selector: 'app-release-notes',
  templateUrl: './release-notes.component.html',
  styleUrls: ['./release-notes.component.css']
})
export class ReleaseNotesComponent implements OnInit {

  Version: string = APP_VERSION;
  errorMessage: any;
  buildDate : string;
  bugs: any[] = [];
  improvements: any[] = [];
  newFeatures: any[] = [];
  data: any[] = [];
  constructor(
    private _preloaderService: PreloaderService,
    private _releaseNotesApi: ReleaseNotesApi 
  ) { }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this._preloaderService.showPreloader();
    const reqObj = {
      fields: ['Version', 'types', 'description', 'Orders', 'BuildDate'],
      where: { 'isActive': true }
    }
    this._releaseNotesApi.find(reqObj).subscribe(
      result =>{
        this._preloaderService.hidePreloader();
        if(result && result.length){
          this.data = result;
          this.prepareData(this.data);
        } else {
          this._preloaderService.hidePreloader();
          this.errorMessage = 'No Release Notes available';
        }
      }, 
    err => {
      this.errorMessage = err
    });
  }

  prepareData(data) {
    if (data && data.length) {
      this.buildDate = data[0]['BuildDate'];
      data.forEach((item, index) => {
        if(item.types == 'bugs'){
          this.bugs.push(item);
        }
        else if (item.types == 'improvements'){
          this.improvements.push(item)
        }
        else {
          this.newFeatures.push(item);
        }
      })
    }
  }
}
