
import { Component, OnInit, Input } from '@angular/core';
import { NotificationService } from '../../../services/notification.service';
import { PreloaderService } from 'shared/services/preloader.service';
import { ModalService } from '../../../services/modal.service';
import { CommonService } from 'shared/services/common.service';
import { AppStateService } from 'shared/services/app-state.service';

@Component({
    selector: 'app-feed-list-detail',
    templateUrl: './feed-list-detail.component.html',
    styleUrls: ['./feed-list-detail.component.css']
})

export class FeedListDetailComponent implements OnInit {
    @Input() set filterObj(e) {
        this.accessType = this._appState.getAccessType();
        if (localStorage.getItem('viewAsInfo')) {
            const viewAsInfo = JSON.parse(localStorage.getItem('viewAsInfo'));
            const viewAsPartner = (viewAsInfo && viewAsInfo.viewAsType === 'Partner');
            this.accessType = (viewAsPartner) ? 'partner' : this.accessType;
        }
        this.feeds = [];
        this.page.pageNumber = 0;
        this.page.size = 20;
        this.filterCondition = (e && e['where']) ? e['where'] : '';
        this.job = (e && e['job']) ? e['job'] : '';
        this.case = (e && e['case']) ? e['case'] : '';
        this.showContent = false;
        this.feeds = [];
        if (this.filterCondition) {
            this.getFeeds(this.page);
        }

    }
    @Input() timeZone = '';
    @Input() modelName;
    @Input() saveAnalytics = true;
    loadingIndicator = false;
    errorMessage = '';
    job: any;
    filterCondition: any;
    feeds = [];
    showContent = false;
    ExpandViewTitle: any;
    case: any;
    accessType: any;
    currentExpandViewId: any;
    page = {
        size: 0,
        totalElements: 0,
        totalPages: 0,
        pageNumber: 0
    }
    activity: any; // to open view
    isData = 'No';
    private _statusCss = {
        'In-Progress': 'badge-warning',
        'Resolved': 'badge-info',
        'New': 'badge-danger',
        'Closed': 'badge-success'
    };
    isLoadMore = false;
    constructor(
        private _loader: PreloaderService,
        private _notificationService: NotificationService,
        private _modalService: ModalService,
        private _commonService: CommonService,
        private _appState: AppStateService
    ) {
    }


    ngOnInit() {
        this.accessType = this._appState.getAccessType();
        if (localStorage.getItem('viewAsInfo')) {
            const viewAsInfo = JSON.parse(localStorage.getItem('viewAsInfo'));
            const viewAsPartner = (viewAsInfo && viewAsInfo.viewAsType === 'Partner');
            this.accessType = (viewAsPartner) ? 'partner' : this.accessType;
        }
        if (this.saveAnalytics) {
            this._commonService.saveCurrentPageAnalytics('Global Chat', 'Activity Feed Details')
        }
    }

    getFeeds(offset) {
        this._loader.showPreloader();
        this.loadingIndicator = true;
        this._notificationService.getActivityFeed(offset['pageNumber'] + 1, this.filterCondition)
            .then(data => {
                this._loader.hidePreloader();
                this.loadingIndicator = false;
                if (data && data['data']) {
                    if (data['data'].length > 0) {
                        data['data'].forEach((e, i) => {
                            if (e.created_at) {
                                data['data'][i].created_at = e.created_at + ' Z';
                            }
                        });
                    }
                    this.page.totalElements = data['total'];
                    this.page.totalPages = this.page.totalElements / this.page.size;
                    this.feeds = (this.isLoadMore) ? [...this.feeds, ...data['data']] : data['data'];
                    this.isData = 'Yes';
                    this.getStatusClass();
                    this.isLoadMore = (this.feeds && this.feeds.length < this.page.totalElements) ? true : false;
                    this.page.pageNumber = offset['pageNumber'] + 1;
                } else {
                    this.page.totalElements = 0;
                    this.page.totalPages = 0;
                    this.isData = 'No';
                    this.feeds = [];
                    this.isLoadMore = false;
                }
            }).catch(err => {

            });
    }



    /**
   * Populate the table with new data based on the page number
   * @param page The page to select
   */
    setPage(pageInfo) {
        this.page.pageNumber = pageInfo.pageNumber;
        this.getFeeds(this.page);
    }

    getStatusClass() {
        this.feeds.filter(data => {
            if (this._statusCss.hasOwnProperty(data['Dispatch_Service_Resolution_Status__c'])) {
                data['jobStatusClass'] = this._statusCss[data['Dispatch_Service_Resolution_Status__c']];
            } else {
                data['jobStatusClass'] = 'badge-secondary';
            }
            return data;

        });
    }
    /**
     *
     * @param content
     * @param size
     * @param row
     */
    openDetails(content, size, row) {
        this.ExpandViewTitle = row.title;
        this.currentExpandViewId = row.id;
        this.activity = row;
        this._modalService.open(content, size);
    }
}
