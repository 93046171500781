/* tslint:disable */
import {
  Worker,
  Job
} from '../index';

declare var Object: any;
export interface JobWorkerTrackingInterface {
  "jobSfdcId"?: string;
  "workerName"?: string;
  "workerSfdcId"?: string;
  "worker_Start_Date_Time"?: Date;
  "worker_Arrival_Date_Time"?: Date;
  "startLatitude"?: number;
  "startLongitude"?: number;
  "endLatitude"?: number;
  "endLongitude"?: number;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  worker?: Worker;
  job?: Job;
}

export class JobWorkerTracking implements JobWorkerTrackingInterface {
  "jobSfdcId": string;
  "workerName": string;
  "workerSfdcId": string;
  "worker_Start_Date_Time": Date;
  "worker_Arrival_Date_Time": Date;
  "startLatitude": number;
  "startLongitude": number;
  "endLatitude": number;
  "endLongitude": number;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  worker: Worker;
  job: Job;
  constructor(data?: JobWorkerTrackingInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `JobWorkerTracking`.
   */
  public static getModelName() {
    return "JobWorkerTracking";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of JobWorkerTracking for dynamic purposes.
  **/
  public static factory(data: JobWorkerTrackingInterface): JobWorkerTracking{
    return new JobWorkerTracking(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'JobWorkerTracking',
      plural: 'JobWorkerTrackings',
      path: 'JobWorkerTrackings',
      idName: 'id',
      properties: {
        "jobSfdcId": {
          name: 'jobSfdcId',
          type: 'string'
        },
        "workerName": {
          name: 'workerName',
          type: 'string'
        },
        "workerSfdcId": {
          name: 'workerSfdcId',
          type: 'string'
        },
        "worker_Start_Date_Time": {
          name: 'worker_Start_Date_Time',
          type: 'Date'
        },
        "worker_Arrival_Date_Time": {
          name: 'worker_Arrival_Date_Time',
          type: 'Date'
        },
        "startLatitude": {
          name: 'startLatitude',
          type: 'number'
        },
        "startLongitude": {
          name: 'startLongitude',
          type: 'number'
        },
        "endLatitude": {
          name: 'endLatitude',
          type: 'number'
        },
        "endLongitude": {
          name: 'endLongitude',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        worker: {
          name: 'worker',
          type: 'Worker',
          model: 'Worker',
          relationType: 'belongsTo',
                  keyFrom: 'workerSfdcId',
          keyTo: 'sfdcId'
        },
        job: {
          name: 'job',
          type: 'Job',
          model: 'Job',
          relationType: 'belongsTo',
                  keyFrom: 'jobSfdcId',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
