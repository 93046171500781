import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { AppStateService } from './app-state.service';
import { LoopBackAuth } from '../sdk/services/core/auth.service';
import { AlertService } from './alert.service';
import { environment } from './../../environments/environment';
import * as auth0 from 'auth0-js';
import { UsersApi } from '../sdk/services/custom/Users';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject, Observable, bindNodeCallback, of } from 'rxjs';
import { PreloaderService } from './preloader.service';
import { CommonService } from 'shared/services/common.service';
import { CookieService } from 'ngx-cookie-service';
import { MatDialog } from '@angular/material/dialog';
import { SharedLoginLogComponent } from 'shared/components/shared-login-log/shared-login-log.component';

@Injectable()
export class AuthService {

    private _Auth0 = new auth0.WebAuth({
        clientID: environment.auth.CLIENT_ID,
        domain: environment.auth.CLIENT_DOMAIN,
        responseType: 'id_token token',
        redirectUri: environment.auth.REDIRECT,
        audience: environment.auth.AUDIENCE,
        scope: environment.auth.SCOPE
    });
    // Track whether or not to renew token
    private _authFlag = 'isLoggedIn';
    // Create stream for token
    token$: Observable<string>;
    // Create stream for user profile data
    userProfile$ = new BehaviorSubject<any>(null);
    errorMessage = new BehaviorSubject<string>('');
    errorMessage$ = this.errorMessage.asObservable();
    // Authentication navigation
    onAuthSuccessUrl = '/';
    onAuthFailureUrl = '/login';
    logoutUrl = environment.auth.LOGOUT_URL;
    sharedDialogRef;
    // Create observable of Auth0 parseHash method to gather auth results
    parseHash$ = bindNodeCallback(this._Auth0.parseHash.bind(this._Auth0));
    checkSession$ = bindNodeCallback(this._Auth0.checkSession.bind(this._Auth0));
    appStateData: any;
    refreshSubscription: any;
    expiresAt: number;
    constructor(
        private router: Router,
        private _location: Location,
        private _userApi: UsersApi,
        private _appState: AppStateService,
        private _cookieService: CookieService,
        private _preloaderService: PreloaderService,
        private _commonService: CommonService,
        private _dialog: MatDialog
    ) {
        this.expiresAt = JSON.parse(localStorage.getItem('expiresAt'));
        this.scheduleRenewal();
    }

    login(appName) {
        const that = this;
        // Auth0 authorize request
        // this._Auth0.redirect.loginWithCredentials({
        //     connection: 'Username-Password-Authentication',
        //     username: objUser.email,
        //     password: objUser.password,
        //     scope: 'openid email profile offline_access ' + objUser.app
        // }, function (err, authResult) {
        //     if (err && err.error_description) {
        //         localStorage.setItem('login', null);
        //         that.errorMessage.next(err.error_description);
        //         that._preloaderService.hidePreloader();
        //     }
        // });
        
        this._Auth0.authorize({
            scope: 'openid email profile offline_access ' + appName
        }, function(err, authResult) {
            if (err) {
                if (err && err.error_description) {
                    localStorage.setItem('login', null);
                    that.errorMessage.next(err.error_description);
                    that._preloaderService.hidePreloader();
                }
            } else {
              console.log(authResult);
            }
          });

    }

    openSharedLoginLogPopup(user = null) {
        if (this.sharedDialogRef) return;
        this.sharedDialogRef = this._dialog.open(SharedLoginLogComponent, {
            width: '60%',
            data: {},
            panelClass: 'job-message-email-popup-dialog-container',
            disableClose: true
        });

        this.sharedDialogRef.afterClosed().subscribe(result => {
            if (result && result !== undefined) {
                console.log(result)
                if (user) {
                    this.successRedirect(user);
                }
            }
        });
    }

    successRedirect(user) {
        const redirectUrl = localStorage.getItem("redirectUrl");
        if (redirectUrl) {
          this.onAuthSuccessUrl = redirectUrl;
        }
        const queryParam = user.userinfo['queryParam'] ? user.userinfo['queryParam'] : {};
        if (queryParam && Object.keys(queryParam).length) {
            this.router.navigate([this.onAuthSuccessUrl], { queryParams: queryParam });
        } else {
            this.router.navigateByUrl(this.onAuthSuccessUrl);
        }
    }

    handleLoginCallback(appName) {
        console.log('handlelogincallback')
        this._appState.getAppState().take(1).subscribe(data => {
            this.appStateData = data;
            if (window.location.hash) {
                this.parseHash$().subscribe(
                    authResult => {
                        this._setAuth(authResult);
                        this._userApi.getUserData({
                            email: authResult['idTokenPayload'].email,
                            app: appName
                        }).subscribe(
                            user => {
                                if (user && user.userinfo && !user.userinfo.isActive) {
                                    this.errorMessage.next('User doesn\'t exist');
                                    this.router.navigate([this.onAuthFailureUrl]);
                                } else {
                                    if (!this.appStateData) {
                                        this.appStateData = {}
                                    }
                                    this.setSession(authResult);
                                    this._cookieService.set('$LoopBackSDK$user', JSON.stringify(user.userinfo));
                                    this._cookieService.set('$LoopBackSDK$userId', user.userinfo.id);
                                    this.appStateData.user = user.userinfo;
                                    localStorage.setItem('appData', JSON.stringify(this.appStateData));
                                    localStorage.setItem('accessToken', authResult['accessToken']);
                                    localStorage.setItem('accessType', user.userinfo['accessType']);
                                    localStorage.setItem('userTypeSlug', user.userinfo.userType.slug);
                                    localStorage.setItem('manageUser', (user.userinfo.manageUser) || false);
                                    const docCategory = user.userinfo && user.userinfo.docCategory ? user.userinfo.docCategory : [];
                                    localStorage.setItem('filePermissions', JSON.stringify(docCategory));
                                    this.setAppState({
                                        appData: this.appStateData,
                                        accessType: user.userinfo['accessType'],
                                        workerType: user.userinfo.workers && user.userinfo.workers[0].Worker_Type__c ? user.userinfo.workers[0].Worker_Type__c : '',
                                        workerTypeSub: user.userinfo.workers && user.userinfo.workers[0].Worker_Type_Sub__c ? user.userinfo.workers[0].Worker_Type_Sub__c : '',
                                        userTypeSlug: user.userinfo.userType.slug,
                                        filePermissions: docCategory,
                                        manageUser: user.userinfo.manageUser || false
                                    });

                                    this.onAuthSuccessUrl = user.userinfo['redirectUrl'];
                                    if (this.appStateData && this.appStateData.user && this.appStateData.user.SharedLogin) {
                                        this.openSharedLoginLogPopup(user);
                                    } else {
                                        this.successRedirect(user);
                                    }
    
                                    let firstTimeLogin = this.appStateData['user'] && this.appStateData['user']['lastLogin'] && this.appStateData['user']['lastLogin'].length ? false : true;
                                    let worker_Type = this.appStateData['user'] && this.appStateData['user']['workers'] && this.appStateData['user']['workers'].length ? this.appStateData['user']['workers'][0]['Worker_Type_Sub__c'] : '';
                                    let access_Type = this.appStateData['user'] && this.appStateData['user']['accessType'] && this.appStateData['user']['accessType'].length ? this.appStateData['user']['accessType'] :'';
                                    let filterObj =  JSON.parse(localStorage.getItem('filterObj'));
                                    let accFilter = filterObj['accountCommunities'] && filterObj['accountCommunities'].length ? true : false;

                                    if(firstTimeLogin == true && worker_Type == 'VMSA' && accFilter == false && access_Type == 'vendor'){
                                        this.router.navigate(['/vms/vms-billing-manager'], { queryParams: { activeId: 'accountProfile' } });
                                        
                                    }
                                }
                            },
                            error => {
                                this.router.navigate([this.onAuthFailureUrl]);
                            }
                        )
                    },
                    err => this._handleError(err)
                )
            } else {
                this.router.navigate([this.onAuthFailureUrl], { queryParams: { error: window.location.hash } });
            }
        })
    }

    setAppState(obj) {
        if (obj && obj.appData && obj.accessType) {
            // console.log(obj)
            // console.log('setAppstate===> found')
            this._appState.setAppState(obj.appData);
            this._appState.setAccessType(obj.accessType);
            this._appState.setWorkerType(obj.workerType);
            this._appState.setUserTypeSlug(obj.userTypeSlug);
            this._appState.setFileManagerPermissions(obj.filePermissions);
            this._appState.setmanageUser(obj.manageUser);

            if (!this._appState.getSelectedAccount()) {
                // Need to remove the hardcoded MagicLink Account Id once we do not have any dependency on the same
                if ((obj.accessType === 'internal' && this._location.path().indexOf('pms') !== -1) || (obj.accessType === 'vendor')) {
                    this._appState.setSelectedAccount(obj.appData.user['AccountId']);
                }
                if (obj.accessType === 'partner') {
                    this._appState.setSelectedAccount(obj.appData.user['AccountId']);
                }
                this.scheduleRenewal();
            } else {
                localStorage.clear();
                this._cookieService.deleteAll();
                this.router.navigate(['/login'])
            }
        } else {
            localStorage.clear();
            this._cookieService.deleteAll();
            this.router.navigate(['/login'])
        }

    }
    private _setAuth(authResult) {
        // Observable of token
        this.token$ = of(authResult.accessToken);
        // Emit value for user data subject
        this.userProfile$.next(authResult.idTokenPayload);
        // Set flag in local storage stating this app is logged in
        localStorage.setItem(this._authFlag, JSON.stringify(true));
    }

    get authenticated(): boolean {
        if (JSON.parse(localStorage.getItem('appData')) && JSON.parse(localStorage.getItem('appData'))['user'] &&
            JSON.parse(localStorage.getItem('appData'))['user']['SharedLogin']) {
            this.openSharedLoginLogPopup();
        }
        return JSON.parse(localStorage.getItem(this._authFlag));
    }

    get isRememberMe(): boolean {
        return JSON.parse(localStorage.getItem('rememberMe'));
    }

    logout() {
        // Set authentication status flag in local storage to false
        localStorage.clear();
        sessionStorage.clear();
        // This does a refresh and redirects back to homepage
        // Make sure you have the logout URL in your Auth0
        // Dashboard Application settings in Allowed Logout URLs
        this._Auth0.logout({
            returnTo: this.logoutUrl,
            clientID: environment.auth.CLIENT_ID
        });
    }

    private _handleError(err) {
        if (err.errorDescription) {
            this.errorMessage.next(err.errorDescription);
        } else {
            this.errorMessage.next(err);
        }
        this.router.navigate([this.onAuthFailureUrl]);
    }

    resolve() {
        this.token$ = of(localStorage.getItem('accessToken'));
        const userObj = JSON.parse(localStorage.getItem('appData')).user;
        if (!this._appState.getAppState().observers.length) {
            this._appState.setAppState(JSON.parse(localStorage.getItem('appData')));
        }
        // set account id
        if (!this._appState.getSelectedAccount()) {
            // Need to remove the hardcoded MagicLink Account Id once we do not have any dependency on the same
            if (userObj['accessType'] === 'internal' && this._location.path().indexOf('pms') !== -1) {
                this._appState.setSelectedAccount(userObj['AccountId']);
            }
            if (userObj['accessType'] === 'partner') {
                this._appState.setSelectedAccount(userObj['AccountId']);
            }
        }
        // set access type
        if (!this._appState.getAccessType()) {
            this._appState.setAccessType(userObj['accessType']);
        }
        if (!this._appState.getWorkerType()) {
            this._appState.setWorkerType(userObj['workers'] && userObj['workers'].length ? userObj['workers'][0].Worker_Type__c : '');
        }
        // set home url
        if (!this._appState.getHomeUrl()) {
            this._appState.setHomeUrl(userObj['redirectUrl']);
        }
        // set adminAccessPermission
        if (this._appState.getAdminAccessPermission() === undefined) {
            if (sessionStorage.getItem('adminAccessPermission') !== null) {
                const adminAccessPermission = sessionStorage.getItem('adminAccessPermission') === 'true'
                    ? true : false;
                this._appState.setAdminAccessPermission(adminAccessPermission);
            } else {
                this._userApi.adminAccessPermission().subscribe(
                    res => {
                        let adminAccessPermission = false;
                        if (res) {
                            adminAccessPermission = true;
                            sessionStorage.setItem('adminAccessPermission', 'true');
                        }
                        this._appState.setAdminAccessPermission(adminAccessPermission);
                    },
                    err => {
                        console.log(err);
                    }
                );
            }

        }
        if (!this._appState.getAccessType()) {
            this._appState.setmanageUser(userObj['manageUser']);
        }
        return userObj;
    }

    scheduleRenewal() {
        if (!this.isAuthenticated()) {
            return;
        }
        this.unscheduleRenewal();
        const expiresAt = this.expiresAt;

        const source = Observable.of(expiresAt).flatMap(
            expiresat => {

                const now = Date.now();

                // Use the delay in a timer to
                // run the refresh at the proper time
                return Observable.timer(Math.max(1, expiresat - now + 20));
            });
        this.refreshSubscription = source.subscribe(() => {
            this.renewTokens();
            this.scheduleRenewal();
        });
    }

    public isAuthenticated(): boolean {
        // Check whether the current time is past the
        // access token's expiry time
        return Date.now() < this.expiresAt;
    }

    setSession(authResult) {
        this._setAuth(authResult);
        const expiresAt = (authResult.expiresIn * 1000) + new Date().getTime();
        localStorage.setItem('accessToken', authResult['accessToken']);
        localStorage.setItem('expiresAt', expiresAt.toString());
        this.expiresAt = expiresAt;
        this.scheduleRenewal();
    }

    renewTokens() {
        this._Auth0.checkSession({}, (err, authResult) => {
            if (authResult && authResult.accessToken && authResult.idToken) {
                this.setSession(authResult);
            } else if (err) {
                this.logout();
                console.log(err);
                alert(`Could not get a new token (${err.error}: ${err.error_description}).`);
            }
        });
    }

    public unscheduleRenewal() {
        if (!this.refreshSubscription) {
            return;
        }
        this.refreshSubscription.unsubscribe();
    }
}
