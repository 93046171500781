import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { PageViewMappingApi, UserTypeApi } from 'shared/sdk';
import { AppStateService } from 'shared/services/app-state.service';
import { debounceTime, distinctUntilChanged, switchMap, filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { CommonService } from 'shared/services/common.service';


@Component({
  selector: 'app-page-name-lookup',
  templateUrl: './page-name-lookup.component.html',
  styleUrls: ['./page-name-lookup.component.css']
})
export class PageNameLookupComponent implements OnInit, OnDestroy {

  @Input() set resetData(isClear) {
    this.pageNameSelected = [];
  }
  @Input() from;
  @Input() selectedDefaultPageName;
  @Input() selectLabel = 'PageName';
  @Input() selectbindValue = 'PageName';
  @Input() isMultiSelect = 'false';
  @Input() set selectPageName(e) {
    if (e) {
      this.pageNameList = [];
      this.pageNameList.push(e);
      this.pageNameSelected = e && e.id;
    }
  }
  // @Input() set preSelected(e) {
  //   if (e) {
  //     this.pageNameSelected = e
  //     this.onPageNameChange()
  //   }
  // }
  @Input() isDisabled = false;
  @Input() pageType = "";
  @Input() isMobileSelected = false;
  @Output() getPageNameObj: EventEmitter<any> = new EventEmitter;

  // vendor User

  noPageName = 'Searching...';
  pageNameahead = new EventEmitter<string>();
  pageNameList = [];
  pageNameSelected = [];
  isMultipleSelected = true;
  maxSelectedItems = 1;
  isMobileSubscription: Subscription;
  isMobile = false;

  constructor(
    private _pageViewMappingApi: PageViewMappingApi,
    private _commonService: CommonService,
  ) { }

  ngOnInit() {
    this.isMobileSubscription = this._commonService.isMobileAnalytics.subscribe(mobile => {
      if (mobile) {
        this.isMobile = true;
      }
    })
    this.getPageName();
    if (this.from && this.from === 'analyticsDashboard') {
      if (this.selectedDefaultPageName && this.selectedDefaultPageName.length) {
        this.pageNameSelected = this.selectedDefaultPageName;
      } else {
        this.pageNameSelected = [];
      }
      if (this.isMobileSelected) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    } else {
      const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
      this.pageNameSelected = (preselected && preselected['pageName']) ?
        preselected['pageName'] : '';

    }
    // if (this.pageNameSelected) {
    //   let mobile;
    //   if (this.isMobile) {
    //     mobile = { isMobile: 1 };
    //   } else {
    //     mobile = { or: [{ isMobile: null }, { isMobile: 0 }] }
    //   }
    //   const whereObj = {
    //     and: [
    //       { id: this.pageNameSelected },
    //       mobile
    //     ]

    //   };
    //   this.getPageNameList(whereObj).subscribe(x => {
    //     this.pageNameList = x;
    //     this.noPageName = (this.pageNameList.length === 0) ? 'No Page found.' : '';
    //   },
    //     err => {
    //       console.log(err);
    //       this.pageNameList = [];
    //       this.noPageName = '';
    //     })
    // }
    // this.onPageNameChange();
  }

  getPageName() {
    this.pageNameahead.pipe(
      filter(term => term && term.length > 2),
      distinctUntilChanged(),
      debounceTime(200),
      switchMap(term => {
        let mobile = {};
        let selectedPageType = "web";
        if (this.isMobile) {
          mobile = { isMobile: 1 };
          selectedPageType = 'mobile';
        } else {
          mobile = { isMobile: 0 };
          if(this.from == 'analyticsPageViewReport') {
            if(this.pageType != undefined){
              selectedPageType = (this.pageType.toString() == '') ? 'all' : this.pageType.toString();
            } 
          } 
        }
        let whereCondition = {};
        if(this.from == 'analyticsPageViewReport') {
          whereCondition = { PageName: term.trim(), PageType: selectedPageType};
        } else {
          whereCondition = { PageName: term.trim(), isMobile: this.isMobile, PageType: selectedPageType};
        }
        const whereObj = whereCondition;
        return this.getPageNameList(whereObj);
      })
    ).subscribe(
      x => {
        this.pageNameList = x;
        this.noPageName = (this.pageNameList.length === 0) ? 'No Page found.' : '';
      },
      err => {
        console.log(err);
        this.pageNameList = [];
        this.noPageName = '';
      }
    );
  }

  onPageNameChange() {
    this.getPageNameObj.emit(this.pageNameSelected);
  }

  clearPrograms() {
    this.pageNameSelected = [];
    this.getPageNameObj.emit({});
  }

  getPageNameList(whereObj) {
    return this._pageViewMappingApi.getPageName({where: whereObj }).map(res => res);
  }

  ngOnDestroy() {
    this.isMobileSubscription && this.isMobileSubscription.unsubscribe();
  }

}
