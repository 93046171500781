import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-worker-management',
  templateUrl: './worker-management.component.html',
  styleUrls: ['./worker-management.component.css']
})
export class WorkerManagementComponent implements OnInit {
  @Input() data: any;
  @Input() filterData: any;;
  constructor() { }

  ngOnInit() {
  }
}
