/* tslint:disable */
import {
  Contact,
  Group,
  AssociatedTask
} from '../index';

declare var Object: any;
export interface CorrectiveActionRequestInterface {
  "sfdcId"?: string;
  "Name"?: string;
  "OwnerId"?: string;
  "LastModifiedById"?: string;
  "CurrencyIsoCode"?: string;
  "CreatedById"?: string;
  "RecordTypeId"?: string;
  "Action_Effective__c"?: boolean;
  "Assigned_PgMO_Groups__c"?: string;
  "Assigned_to__c"?: string;
  "Assigned_to_Email__c"?: string;
  "Assigned_to_text__c"?: string;
  "Case__c"?: string;
  "Contact__c"?: string;
  "Customer__c"?: string;
  "Date_Closed__c"?: Date;
  "Implementation_Date__c"?: Date;
  "Implemented_By__c"?: string;
  "Issue_Date__c"?: Date;
  "NCR_CAR_PAR__c"?: string;
  "Quantity_Affected__c"?: string;
  "Rejected__c"?: boolean;
  "Request_Code__c"?: string;
  "Requestor__c"?: string;
  "Requestor_Email__c"?: string;
  "PgMO_Groups__c"?: string;
  "Requestor_Text__c"?: string;
  "Reviewed_By__c"?: string;
  "Reviewed_By_lkp__c"?: string;
  "Reviewed_By_Email__c"?: string;
  "Reviewed_By_Text__c"?: string;
  "Reviwed_PgMO_Groups__c"?: string;
  "Status__c"?: string;
  "Verifications__c"?: string;
  "With_Case__c"?: boolean;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  requestor?: Contact;
  requestorGroup?: Group;
  assignTo?: Contact;
  assignToGroup?: Group;
  reviewedBy?: Contact;
  reviewedByGroup?: Group;
  associatedTasks?: AssociatedTask[];
}

export class CorrectiveActionRequest implements CorrectiveActionRequestInterface {
  "sfdcId": string;
  "Name": string;
  "OwnerId": string;
  "LastModifiedById": string;
  "CurrencyIsoCode": string;
  "CreatedById": string;
  "RecordTypeId": string;
  "Action_Effective__c": boolean;
  "Assigned_PgMO_Groups__c": string;
  "Assigned_to__c": string;
  "Assigned_to_Email__c": string;
  "Assigned_to_text__c": string;
  "Case__c": string;
  "Contact__c": string;
  "Customer__c": string;
  "Date_Closed__c": Date;
  "Implementation_Date__c": Date;
  "Implemented_By__c": string;
  "Issue_Date__c": Date;
  "NCR_CAR_PAR__c": string;
  "Quantity_Affected__c": string;
  "Rejected__c": boolean;
  "Request_Code__c": string;
  "Requestor__c": string;
  "Requestor_Email__c": string;
  "PgMO_Groups__c": string;
  "Requestor_Text__c": string;
  "Reviewed_By__c": string;
  "Reviewed_By_lkp__c": string;
  "Reviewed_By_Email__c": string;
  "Reviewed_By_Text__c": string;
  "Reviwed_PgMO_Groups__c": string;
  "Status__c": string;
  "Verifications__c": string;
  "With_Case__c": boolean;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  requestor: Contact;
  requestorGroup: Group;
  assignTo: Contact;
  assignToGroup: Group;
  reviewedBy: Contact;
  reviewedByGroup: Group;
  associatedTasks: AssociatedTask[];
  constructor(data?: CorrectiveActionRequestInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `CorrectiveActionRequest`.
   */
  public static getModelName() {
    return "CorrectiveActionRequest";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of CorrectiveActionRequest for dynamic purposes.
  **/
  public static factory(data: CorrectiveActionRequestInterface): CorrectiveActionRequest{
    return new CorrectiveActionRequest(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'CorrectiveActionRequest',
      plural: 'CorrectiveActionRequests',
      path: 'CorrectiveActionRequests',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "RecordTypeId": {
          name: 'RecordTypeId',
          type: 'string'
        },
        "Action_Effective__c": {
          name: 'Action_Effective__c',
          type: 'boolean'
        },
        "Assigned_PgMO_Groups__c": {
          name: 'Assigned_PgMO_Groups__c',
          type: 'string'
        },
        "Assigned_to__c": {
          name: 'Assigned_to__c',
          type: 'string'
        },
        "Assigned_to_Email__c": {
          name: 'Assigned_to_Email__c',
          type: 'string'
        },
        "Assigned_to_text__c": {
          name: 'Assigned_to_text__c',
          type: 'string'
        },
        "Case__c": {
          name: 'Case__c',
          type: 'string'
        },
        "Contact__c": {
          name: 'Contact__c',
          type: 'string'
        },
        "Customer__c": {
          name: 'Customer__c',
          type: 'string'
        },
        "Date_Closed__c": {
          name: 'Date_Closed__c',
          type: 'Date'
        },
        "Implementation_Date__c": {
          name: 'Implementation_Date__c',
          type: 'Date'
        },
        "Implemented_By__c": {
          name: 'Implemented_By__c',
          type: 'string'
        },
        "Issue_Date__c": {
          name: 'Issue_Date__c',
          type: 'Date'
        },
        "NCR_CAR_PAR__c": {
          name: 'NCR_CAR_PAR__c',
          type: 'string'
        },
        "Quantity_Affected__c": {
          name: 'Quantity_Affected__c',
          type: 'string'
        },
        "Rejected__c": {
          name: 'Rejected__c',
          type: 'boolean'
        },
        "Request_Code__c": {
          name: 'Request_Code__c',
          type: 'string'
        },
        "Requestor__c": {
          name: 'Requestor__c',
          type: 'string'
        },
        "Requestor_Email__c": {
          name: 'Requestor_Email__c',
          type: 'string'
        },
        "PgMO_Groups__c": {
          name: 'PgMO_Groups__c',
          type: 'string'
        },
        "Requestor_Text__c": {
          name: 'Requestor_Text__c',
          type: 'string'
        },
        "Reviewed_By__c": {
          name: 'Reviewed_By__c',
          type: 'string'
        },
        "Reviewed_By_lkp__c": {
          name: 'Reviewed_By_lkp__c',
          type: 'string'
        },
        "Reviewed_By_Email__c": {
          name: 'Reviewed_By_Email__c',
          type: 'string'
        },
        "Reviewed_By_Text__c": {
          name: 'Reviewed_By_Text__c',
          type: 'string'
        },
        "Reviwed_PgMO_Groups__c": {
          name: 'Reviwed_PgMO_Groups__c',
          type: 'string'
        },
        "Status__c": {
          name: 'Status__c',
          type: 'string'
        },
        "Verifications__c": {
          name: 'Verifications__c',
          type: 'string'
        },
        "With_Case__c": {
          name: 'With_Case__c',
          type: 'boolean'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        requestor: {
          name: 'requestor',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Requestor__c',
          keyTo: 'sfdcId'
        },
        requestorGroup: {
          name: 'requestorGroup',
          type: 'Group',
          model: 'Group',
          relationType: 'belongsTo',
                  keyFrom: 'PgMO_Groups__c',
          keyTo: 'sfdcId'
        },
        assignTo: {
          name: 'assignTo',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Assigned_to__c',
          keyTo: 'sfdcId'
        },
        assignToGroup: {
          name: 'assignToGroup',
          type: 'Group',
          model: 'Group',
          relationType: 'belongsTo',
                  keyFrom: 'Assigned_PgMO_Groups__c',
          keyTo: 'sfdcId'
        },
        reviewedBy: {
          name: 'reviewedBy',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Reviewed_By_lkp__c',
          keyTo: 'sfdcId'
        },
        reviewedByGroup: {
          name: 'reviewedByGroup',
          type: 'Group',
          model: 'Group',
          relationType: 'belongsTo',
                  keyFrom: 'Reviwed_PgMO_Groups__c',
          keyTo: 'sfdcId'
        },
        associatedTasks: {
          name: 'associatedTasks',
          type: 'AssociatedTask[]',
          model: 'AssociatedTask',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'Corrective_Action_Request__c'
        },
      }
    }
  }
}
