import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { wslAccountStatusList } from 'shared/models/static-list-data.service';

@Component({
  selector: 'app-wsl-account-status-lookup',
  templateUrl: './wsl-account-status-lookup.component.html',
  styleUrls: ['./wsl-account-status-lookup.component.css']
})
export class WslAccountStatusLookupComponent implements OnInit {

  @Input() set resetData(isClear) {
    this.clearFromParent();
  }
  @Output() selectedWSLAccountStatus: EventEmitter<any> = new EventEmitter;
  selectedValue;

  wslAccountStatuses = [];

  constructor() { }

  ngOnInit() {
    this.wslAccountStatuses = wslAccountStatusList;
    const preSelected = localStorage.getItem('filterObj');
    if (preSelected && JSON.parse(preSelected) &&
      JSON.parse(preSelected)['wslAccountStatus'] && JSON.parse(preSelected)['wslAccountStatus'].length) {
      this.selectedValue = JSON.parse(preSelected)['wslAccountStatus'];
    }
  }

  onChange() {
    this.selectedWSLAccountStatus.emit(this.selectedValue);
  }

  clearFromParent() {
    this.selectedValue = null;
  }

}
