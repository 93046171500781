import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ReleaseNotesApi } from 'shared/sdk';
import { PreloaderService } from 'shared/services/preloader.service';
import { version } from 'xlsx/types';
import { APP_VERSION } from '../../../../msp/version';

@Component({
  selector: 'app-release-notes-version-lookup',
  templateUrl: './release-notes-version-lookup.component.html',
  styleUrls: ['./release-notes-version-lookup.component.css']
})
export class ReleaseNotesVersionLookupComponent implements OnInit {
  maxSelectedItems = 20;
  @Input() titleTextShow = true;
  @Input() set resetData(isClear) {
    if (isClear) {
      this.valueSelected = [];
    }
  }
  @Input() set myGlobalTaskInbox(event) {
    if (event) {
      this.maxSelectedItems = 1;
    }
  }
  @Input() formName;
  @Input() filterListName;
  @Input() label = 'label';
  @Input() value = 'value';
  @Input() isDisable = 'false';
  @Input() dropdownPosition = 'below';
  @Input() filterObjValue;
  @Input() placeholder;
  @Input() isMultipleSelected = true;
  @Input() boxedLayout = false;
  @Input() notInGlobalFilter = false;
  @Input() set preSelected(val) { 
    this.valueSelected = val;
  };
  @Output() getValueObj: EventEmitter<any> = new EventEmitter;
  dataList = [];
  valueSelected = [];
  requiredAllVersions: boolean = true;
  constructor(
    private _preloaderService: PreloaderService,
    private _releaseNotesApi: ReleaseNotesApi
  ) { }

  ngOnInit() {
    this.getData()
    if(!this.notInGlobalFilter) {
      const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
      if (this.isMultipleSelected) {
        this.valueSelected = (preselected && preselected[this.filterObjValue]) ? preselected[this.filterObjValue] : '';
      } else if (this.formName) {
        this.valueSelected = this.valueSelected;
      } else {
        this.valueSelected = (preselected && preselected[this.filterObjValue]) ? preselected[this.filterObjValue][0] : '';
      }
    }      
  }


  getData() {
    this._preloaderService.showPreloader();
    const reqObj = { 
      requiredAllVersions: this.requiredAllVersions,
      where: { 'Version': { neq: null } },    
    }
    
    this._releaseNotesApi.getReleasenotes(reqObj).subscribe(
      result =>{       
        this._preloaderService.hidePreloader();     
        if (result && result.version && result.version.length) {
          var allVersions = (result.version).map(function (item:any) {
              return item['Version']
          });        
          this.dataList = allVersions.filter((n, i) => {
            return (n && n.trim() && allVersions.indexOf(n) === i)
          });
      } else {
          this.dataList = [];
      }
    }, 
    err => {
      this._preloaderService.hidePreloader();
      //this.errorMessage = err
      this.dataList = [];
    });
  }

  onValueChange() {
    this.getValueObj.emit(this.valueSelected);
  }

  clearValues() {
    this.valueSelected = [];
    this.getValueObj.emit([]);
  }

}
