import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, of } from 'rxjs';
import { UsersApi } from '../../sdk/services/custom/Users';
import { UtilityService } from '../../services/utility.service';
import { SocketService } from '../../services/socket.service';
import { Title } from '@angular/platform-browser';
@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html'
})
export class NotificationComponent implements OnInit {
    pageTitle: any;
    public notificationLists: Array<any>;
    /*Boot-Datatable params */
    tableData = [];
    loadingIndicator = false;
    itemsPerPage = 10;
    isLoadMore = false;
    itemsPerBatch = 200;
    tableDataCount = 0;
    columns: any;
    allColumns: any;
    noRecords = false;
    page = 1;

    orderBy = 'createdAt DESC';
    currentUser = JSON.parse(localStorage.getItem('appData'));
    constructor(
        private _utilityService: UtilityService,
        private _socketService: SocketService,
    ) { }

    ngOnInit() {
        this.getNotification(this.page);

        this.columns = [
            { name: 'Type', prop: 'type', width: 60, visible: true, sortable: false },
            { name: 'Model Name', prop: 'modelName', width: 150, visible: true, sortable: true },
            { name: 'Title', prop: 'title', width: 400, visible: true, sortable: true, showMore: true },
            { name: 'Message', prop: 'message', width: 400, visible: true, sortable: true, showMore: true },
            { name: 'Notification Time', prop: 'createdAt', width: 170, visible: true, sortable: true },
            { name: 'Action', prop: 'isRead', width: 80, visible: true, sortable: false }
        ];
        this.allColumns = this.columns.slice();
        
        this._socketService.getMarkRead().subscribe(data => {
            if(data && data.id) {
                this.tableData.forEach(item => {
                    if(data.id === item.id) {
                        item['isRead'] = true;
                    }
                })
            } else {
                this.tableData.forEach(item => {
                    item['isRead'] = true;
                });
            }
        });
    }

    /**
     *
     * @param page
     */
    getNotification(page) {
        this.loadingIndicator = true;
        this._socketService.getNotificationList(page, this.itemsPerBatch).then(data => {
            const results = this.modifyData(data['data']);
            this.noRecords = (results.length < this.itemsPerBatch) ? true : false;
            if (!this.isLoadMore) {
                this.tableData = (results) ? results : [];
            } else {
                if (results.length) {
                    results.forEach(c => {
                        this.tableData.push(c);
                    });
                    this.tableData = [...this.tableData];
                }
            }
            this.loadingIndicator = false;

        }, error => {
            this.loadingIndicator = false;
        });


    }

    modifyData(data) {
        if (data.length) {
            data.forEach((item) => {
                item['id'] = (item.activity_id) ? item.activity_id : '';
                item['type'] = (item.action_type) ? item.action_type : '';
                item['modelName'] = (item.entity_name) ? item.entity_name.toUpperCase() : '';
                item['title'] = (item.title) ? item.title : '';
                item['message'] = (item.details) ? item.details : '';
                item['createdAt'] = (item.date_created) ? this._utilityService.dateFormate(item.date_created) : '';
                item['isRead'] = item.marked_read ? 1 : 0;
            });
            return data;
        } else {
            return [];
        }
    }
    // 1 -> itemPerBatch
    loadMoreRecords() {
        this.isLoadMore = true;
        if(this.tableData.length === this.page*this.itemsPerBatch) {
            this.page++;
            this.getNotification(this.page);
        }
    }

    toggle(col) {
        col.visible = !col.visible;
    }

    markAsRead(id = null) {
        if (id) {
            this._socketService.readNotification(id);
            return;
        }
        this._socketService.readAllNotification();
    }
}
