import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { customerApprovalStatusValues } from '../../../models/static-list-data.service'

@Component({
  selector: 'app-customer-approval-status-lookup',
  templateUrl: './customer-approval-status-lookup.component.html',
  styleUrls: ['./customer-approval-status-lookup.component.css']
})
export class CustomerApprovalStatusLookupComponent implements OnInit {

  customerApprovalStatusList = [];
  customerApprovalStatusTypeSelected = [];
  isMultipleSelected = true;

  @Input() set resetData(isClear) {
    if (isClear) {
      this.customerApprovalStatusTypeSelected = [];
    }
  }
  @Output() selectedCustomerApprovalStatus: EventEmitter<any> = new EventEmitter;

  constructor() { }

  ngOnInit() {
    this.customerApprovalStatusList = customerApprovalStatusValues;
    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
    this.customerApprovalStatusTypeSelected = (preselected &&
      preselected['customerApprovalStatuses']) ? preselected['customerApprovalStatuses'] : '';
  }
  onCustomerApprovalStatusChange() {
    this.selectedCustomerApprovalStatus.emit(this.customerApprovalStatusTypeSelected);
  }
  clearCustomerApprovalStatus() {
    this.customerApprovalStatusTypeSelected = [];
    this.selectedCustomerApprovalStatus.emit([]);
  }

}
