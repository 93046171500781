/* tslint:disable */
import {
  Account,
  Jobsite,
  Project,
  RequestFormReceiptTemplate
} from '../index';

declare var Object: any;
export interface MyWorkplaceTemplateSchedulerInterface {
  "sfdcId"?: string;
  "OwnerId"?: string;
  "IsDeleted"?: boolean;
  "Name"?: string;
  "CreatedById"?: string;
  "CreatedDate"?: Date;
  "CurrencyIsoCode"?: string;
  "LastModifiedDate"?: Date;
  "LastModifiedById"?: string;
  "LastActivityDate"?: Date;
  "LastViewedDate"?: Date;
  "LastReferencedDate"?: Date;
  "Account__c"?: string;
  "Business_Days__c"?: string;
  "Jobsite__c"?: string;
  "Milestone_Frequency__c"?: string;
  "Partner_Case_Number__c"?: string;
  "Project__c"?: string;
  "Request_Form_Receipt_Template__c"?: string;
  "Task_Frequency__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  account?: Account;
  jobsite?: Jobsite;
  project?: Project;
  requestFormReceiptTemplate?: RequestFormReceiptTemplate;
}

export class MyWorkplaceTemplateScheduler implements MyWorkplaceTemplateSchedulerInterface {
  "sfdcId": string;
  "OwnerId": string;
  "IsDeleted": boolean;
  "Name": string;
  "CreatedById": string;
  "CreatedDate": Date;
  "CurrencyIsoCode": string;
  "LastModifiedDate": Date;
  "LastModifiedById": string;
  "LastActivityDate": Date;
  "LastViewedDate": Date;
  "LastReferencedDate": Date;
  "Account__c": string;
  "Business_Days__c": string;
  "Jobsite__c": string;
  "Milestone_Frequency__c": string;
  "Partner_Case_Number__c": string;
  "Project__c": string;
  "Request_Form_Receipt_Template__c": string;
  "Task_Frequency__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  account: Account;
  jobsite: Jobsite;
  project: Project;
  requestFormReceiptTemplate: RequestFormReceiptTemplate;
  constructor(data?: MyWorkplaceTemplateSchedulerInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `MyWorkplaceTemplateScheduler`.
   */
  public static getModelName() {
    return "MyWorkplaceTemplateScheduler";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of MyWorkplaceTemplateScheduler for dynamic purposes.
  **/
  public static factory(data: MyWorkplaceTemplateSchedulerInterface): MyWorkplaceTemplateScheduler{
    return new MyWorkplaceTemplateScheduler(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'MyWorkplaceTemplateScheduler',
      plural: 'MyWorkplaceTemplateSchedulers',
      path: 'MyWorkplaceTemplateSchedulers',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "LastActivityDate": {
          name: 'LastActivityDate',
          type: 'Date'
        },
        "LastViewedDate": {
          name: 'LastViewedDate',
          type: 'Date'
        },
        "LastReferencedDate": {
          name: 'LastReferencedDate',
          type: 'Date'
        },
        "Account__c": {
          name: 'Account__c',
          type: 'string'
        },
        "Business_Days__c": {
          name: 'Business_Days__c',
          type: 'string'
        },
        "Jobsite__c": {
          name: 'Jobsite__c',
          type: 'string'
        },
        "Milestone_Frequency__c": {
          name: 'Milestone_Frequency__c',
          type: 'string'
        },
        "Partner_Case_Number__c": {
          name: 'Partner_Case_Number__c',
          type: 'string'
        },
        "Project__c": {
          name: 'Project__c',
          type: 'string'
        },
        "Request_Form_Receipt_Template__c": {
          name: 'Request_Form_Receipt_Template__c',
          type: 'string'
        },
        "Task_Frequency__c": {
          name: 'Task_Frequency__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'Account__c',
          keyTo: 'sfdcId'
        },
        jobsite: {
          name: 'jobsite',
          type: 'Jobsite',
          model: 'Jobsite',
          relationType: 'belongsTo',
                  keyFrom: 'Jobsite__c',
          keyTo: 'sfdcId'
        },
        project: {
          name: 'project',
          type: 'Project',
          model: 'Project',
          relationType: 'belongsTo',
                  keyFrom: 'Project__c',
          keyTo: 'sfdcId'
        },
        requestFormReceiptTemplate: {
          name: 'requestFormReceiptTemplate',
          type: 'RequestFormReceiptTemplate',
          model: 'RequestFormReceiptTemplate',
          relationType: 'belongsTo',
                  keyFrom: 'Request_Form_Receipt_Template__c',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
