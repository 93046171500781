import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { groupRecordTypes } from 'shared/models/static-list-data.service';

@Component({
  selector: 'app-group-record-type-lookup',
  templateUrl: './group-record-type-lookup.component.html',
  styleUrls: ['./group-record-type-lookup.component.css']
})
export class GroupRecordTypeLookupComponent implements OnInit {
  groupTypeList = [];
  groupTypeListSelected = [];
  isMultipleSelected = true;

  @Input() set resetData(isClear) {
    if (isClear) {
      this.groupTypeListSelected = [];
    }
  }
  @Output() getGroupType: EventEmitter<any> = new EventEmitter;
  constructor() { }

  ngOnInit() {
    this.groupTypeList = groupRecordTypes;
    const preselected = localStorage.getItem('filterObj') && JSON.parse(localStorage.getItem('filterObj'));
    this.groupTypeListSelected = (preselected && preselected['groupRecordTypes']) ? preselected['groupRecordTypes'] : [];
  }

  onGroupTypeChange() {
    this.getGroupType.emit(this.groupTypeListSelected);
  }

  clearGroupType() {
    this.groupTypeListSelected = [];
    this.getGroupType.emit([]);
  }

}
