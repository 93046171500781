import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { deptRoleAccesses } from 'shared/models/static-list-data.service';

@Component({
  selector: 'app-department-role-access-lookup',
  templateUrl: './department-role-access-lookup.component.html',
  styleUrls: ['./department-role-access-lookup.component.css']
})
export class DepartmentRoleAccessLookupComponent implements OnInit {
  deptRoleAccessList = [];
  deptRoleAccessListSelected = [];
  isMultipleSelected = true;

  @Input() set resetData(isClear) {
    if (isClear) {
      this.deptRoleAccessListSelected = [];
    }
  }
  @Output() getDeptRoleAccess: EventEmitter<any> = new EventEmitter;
  constructor() { }

  ngOnInit() {
    this.deptRoleAccessList = deptRoleAccesses;
    const preselected = localStorage.getItem('filterObj') && JSON.parse(localStorage.getItem('filterObj'));
    this.deptRoleAccessListSelected = (preselected && preselected['departmentRoleAccesses']) || [];
  }
  onDeptRoleAccessChange() {
    this.getDeptRoleAccess.emit(this.deptRoleAccessListSelected);
  }
  clearDeptRoleAccess() {
    this.deptRoleAccessListSelected = [];
    this.getDeptRoleAccess.emit([]);
  }

}

