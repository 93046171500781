import { Component, OnInit, Input } from '@angular/core';
import { PreloaderService } from 'shared/services/preloader.service';
import { AlertService } from 'shared/services/alert.service';
import { CommonService } from 'shared/services/common.service';
import { WorkOrderApi } from 'shared/sdk';
import { AppStateService } from 'shared/services/app-state.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-workorder-detail',
  templateUrl: './workorder-detail.component.html',
  styleUrls: ['./workorder-detail.component.css']
})
export class WorkorderDetailComponent implements OnInit {
  @Input()
  set record(e) {
    this.workorder = {};
    this.woObj = {};
    if (e && e.modelId && e.modelName === 'WorkOrder') {
      this.woId = e.modelId;
      this.loadData(e.modelId);
    }
  }

  woId: any;
  workorder: any;
  woObj: any;
  timeZone = ''; // if timezone need to apply
  loadingIndicator = false;
  job:any;
  userType:any;
  jobObj: any = {};
  workerInfoToggle = false;
  appointmentToggle = false;
  contactInfoToggle = false;
  instructionToggle = false;
  constructor(
    private _preloaderService: PreloaderService,
    private _alertService: AlertService,
    private _commonService: CommonService,
    private _workOrderApi: WorkOrderApi,
    private appState: AppStateService,

  ) {
    this.userType = this.appState.getAccessType();
    // To check if view as PMS mode is activated.
    if (localStorage.getItem('viewAsInfo')) {
      const viewAsInfo = JSON.parse(localStorage.getItem('viewAsInfo'));
      const viewAsPartner = (viewAsInfo && viewAsInfo.viewAsType === 'Partner');
      this.userType = (viewAsPartner) ? 'partner' : this.userType;
    }
   }

  ngOnInit() {

  }

  loadData(modelId) {
    this.loadingIndicator = true;
    this.workorder = {};
    this.woObj = {};
    const reqObj = {
      fields: ['sfdcId', 'id', 'Name', 'Partner_Case_Number__c', 'B2B_Service_Category_Type__c', 'Work_Summary__c', 'Partner__c',
        'Partner_Case_Error_Description__c', 'Type_Facebook_Ticket_Type__c', 'CreatedDate', 'PMS_Case__c', 'Customer_Service_Type_WO__c',
        'Project_SOP__c', 'Jobsite__c', 'Jobsite_Project__c', 'Serviceo_Case_Number__c', 'Request_Form_Receipt__c', 'Work_Order__c',
        'Job_Instructions_Deliverables_Summary__c', 'Job_Instructions_Special_Instructions__c', 'Job_Instructions_Training_Documents__c',
        'Job_Instructions_Required_Tools__c', 'Custom_work_order_instructions__c', 'Appointment__c'],
      where: { sfdcId: modelId },
      include: [
        {
          'relation': 'Case',
          'scope': {
            'fields': ['sfdcId', 'CaseNumber', 'Status', 'PPE_Hours__c']
          }
        },
        {
          'relation': 'partner',
          'scope': {
            'fields': ['sfdcId', 'Name']
          }
        },
        {
          'relation': 'program',
          'scope': {
            'fields': ['sfdcId', 'Name']
          }
        },
        {
          'relation': 'Jobsite',
          'scope': {
            'fields': [
              'Jobsite_ID__c',
              'Street__c',
              'Zip__c',
              'City__c',
              'Abbreviation__c',
              'State__c',
              'Zip_Postal_Code__c',
              'Time_Zone__c',
              'Country__c',
              'geolocation__Latitude__s',
              'geolocation__Longitude__s',
              'sfdcId',
              'Name',
              'Jobsite_Key_Contact__c',
            ],
            'include': {
              'relation': 'jobsiteKeyContact',
              'scope': {
                'fields': ['sfdcId', 'Contact_Name__c']
              }
            }
          }
        },
        {
          'relation': 'JobsiteProject',
          'scope': {
            'fields': ['sfdcId', 'Name']
          }
        }, {
          relation: 'requestFormReceiptEscalation',
          scope: {
            fields: ['id', 'sfdcId', 'Work_Order__c'],
            include: [{
              relation: 'woTask',
              scope: {
                fields: ['id', 'sfdcId', 'Description__c', 'Task_No__c', 'Task_Number__c']
              }
            }]
          }
        },
        {
          'relation': 'appointment',
          'scope': {
            'fields': ['Customer_Appointment_DateTime_Scheduled__c',
              'Worker_Arrival_DateTime_Cust_Requested__c',
              'Worker_Arrival_Date_Customer_Req_End__c',
              'Worker_Arrival_DateTime_Scheduled__c',
              'Worker_Arrival_Date_Time_Actual__c',
              'Customer_Appointment_Start_Scheduled__c',
              'Customer_Appointment_Start_HRS_Scheduled__c',
              'Customer_Apptmnt_Start_Minutes_Scheduled__c',
              'Earliest_Start_Date_Permitted__c',
              'Early_Start__c']
          }
        }
      ]
    };
    
    if(this.userType === 'partner'){
      const jobs = {
        'relation': 'jobs',
        'scope': {
          'fields': ['id', 'sfdcId', 'Jobsite__c', 'Vendorsite__c',
          'CKSW_BASE__City__c', 'CKSW_BASE__Description__c',
          'CKSW_BASE__Country__c','CKSW_BASE__State__c', 'Case__c', 'Dispatch_Worker_Name__c',
          'Dispatch_Worker_Phone__c', 'Job_Status_Internal__c'],
          'include': [
            {
              'relation': 'jobsite',
              'scope': {
                  'fields': ['Jobsite_ID__c', 'Street__c', 'Zip__c', 'City__c', 'Abbreviation__c', 'State__c', 'Zip_Postal_Code__c',
                  'Time_Zone__c', 'Country__c',
                      'geolocation__Latitude__s', 'geolocation__Longitude__s']
              }
          },
          {
              'relation': 'vendorsite',
              'scope': {
                  'fields': ['sfdcId', 'Name', 'geolocation__Latitude__s', 'geolocation__Longitude__s', 'Street__c', 'City__c', 'State__c', 'Zip_Postal_Code__c', 'Country__c']
              }
            },
            {
              'relation': 'worker',
              'scope': {
                'fields': ['sfdcId', 'Name', 'Worker_Type__c', 'Contact__c', 'RecordTypeId', 'Dispatch_Worker_num__c'],
                'include': [
                  {
                    'relation': 'user',
                    'scope': {
                      'fields': ['sfdcId', 'email', 'picture', 'firstname', 'lastname', 'url'],
                      'isMessage': 'true' // added this property to avoid "access" restriction applied in backend user file.
                    }
                  },
                  {
                    'relation': 'contact',
                    'scope': {
                      'fields': ['sfdcId', 'MyHomeMetro_Code__c', 'MailingCountry', 'MailingCity', 'MailingState',
                      'MailingPostalCode', 'MailingStreet', 'Geo_Location__Longitude__s', 'Geo_Location__Latitude__s'],
                      'include': [
                        {
                          'relation': 'geometro',
                          'scope': {
                            'fields': ['sfdcId', 'City__c', 'Country__c']
                          }
                        },
                      ]
                    }
                  }
                ]
              }
            },
            {
              'relation': 'appointment',
              'scope': {
                'fields': ['Customer_Appointment_DateTime_Scheduled__c',
                  'Worker_Arrival_DateTime_Cust_Requested__c',
                  'Worker_Arrival_Date_Customer_Req_End__c',
                  'Worker_Arrival_DateTime_Scheduled__c',
                  'Worker_Arrival_Date_Time_Actual__c',
                  'Customer_Appointment_Start_Scheduled__c',
                  'Customer_Appointment_Start_HRS_Scheduled__c',
                  'Customer_Apptmnt_Start_Minutes_Scheduled__c',
                  'Earliest_Start_Date_Permitted__c',
                  'Early_Start__c']
              }
            },
            {
              'relation': 'case',
              'scope': {
                'fields': ['sfdcId', 'PPE_Hours__c'],
              }
            }
        ]
        }
      }
      reqObj.include.push(jobs);
    }
    this._preloaderService.showPreloader();
    this._workOrderApi.findOne(reqObj).subscribe(res => {
      if (res) {
        this.workorder = res;
        const timeFormat = 'L, LT z';
        this.workorder.CreatedDate = this._commonService.dateFormate(this.workorder.CreatedDate, this.timeZone, timeFormat);
        this._preloaderService.hidePreloader();
        this.loadingIndicator = false;
        const jobList = res['jobs'];
        if(jobList && jobList.length){
          this.job = jobList.find(element => element.jobsite);
          this.jobObj = {};
          const jobinfo = this.modifyJobData(this.job);
          this.jobObj = this.preparejobObj(jobinfo);
        }else{
          this.job = {jobsite: this.workorder.Jobsite};
          this.jobObj = {};
          const jobinfo = this.modifyJobData(null);
          this.jobObj = this.preparejobObj(jobinfo);
        }        
        this.findTaskNumber();
      }
    },
      err => {
        this._preloaderService.hidePreloader();
        this._alertService.error('Something went wrong');
        this.loadingIndicator = false;
      }
    )
  }

  findTaskNumber() {
    if (this.workorder && this.workorder.requestFormReceiptEscalation) {
      this.workorder.escalationNumber = (this.workorder.requestFormReceiptEscalation[0] &&
        this.workorder.requestFormReceiptEscalation[0].woTask && this.workorder.requestFormReceiptEscalation[0].woTask.Task_Number__c) || '';
    }
  }

  modifyJobData(jobinfo) {
    const job = Object.assign({}, jobinfo)
    const timeFormat = 'L, LT z';
    job.StartsIn = '';
    job.WorkerStartsIn = '';
    // prepare jobsite data, pick jobsite timezone from job else pick it from work order.
    if (job.jobsite || this.workorder.Jobsite) {
      job.jobsite = (job.jobsite) ? job.jobsite : this.workorder.Jobsite
      this.timeZone = job.jobsite.Time_Zone__c ? job.jobsite.Time_Zone__c : '';
    }
    // prepare appointment data, if appointment is present in job then ok else pick appointment from from workorer.
    if (job.appointment || this.workorder.appointment) {
      job.appointment = (job.appointment) ? job.appointment : this.workorder.appointment;
      job.StartsIn = job.appointment.Customer_Appointment_DateTime_Scheduled__c;
      job.appointment.Early_Start__c = this._commonService.dateFormate(job.appointment.Early_Start__c, this.timeZone, timeFormat);
      if ((job.case && job.case.PPE_Hours__c) || (this.workorder.Case && this.workorder.Case.PPE_Hours__c)) {
        job.case = (job.case) ? job.case : this.workorder.Case;
        if (job.appointment.Worker_Arrival_DateTime_Cust_Requested__c) {
          job.WorkerStartsIn = job.appointment.Worker_Arrival_DateTime_Cust_Requested__c;
          job.appointment.Worker_Arrival_Date_Customer_Req_End__c = this._commonService.addTime(job.appointment.Worker_Arrival_DateTime_Cust_Requested__c, job.case.PPE_Hours__c, 'h');
        }
        if (job.appointment.Customer_Appointment_DateTime_Scheduled__c) {
          job.appointment.Customer_Appointment_End_DateTime = this._commonService.addTime(job.appointment.Customer_Appointment_DateTime_Scheduled__c, job.case.PPE_Hours__c, 'h');
        }
      }
      job.appointment.Worker_Arrival_DateTime_Cust_Requested__c = this._commonService.dateFormate(
        job.appointment.Worker_Arrival_DateTime_Cust_Requested__c, this.timeZone, timeFormat);
      job.appointment.Customer_Appointment_DateTime_Scheduled__c = this._commonService.dateFormate(
        job.appointment.Customer_Appointment_DateTime_Scheduled__c, this.timeZone, timeFormat);
      job.appointment.Worker_Arrival_Date_Customer_Req_End__c = this._commonService.dateFormate(
        job.appointment.Worker_Arrival_Date_Customer_Req_End__c, this.timeZone, timeFormat);
      job.appointment.Customer_Appointment_End_DateTime = this._commonService.dateFormate(
        job.appointment.Customer_Appointment_End_DateTime, this.timeZone, timeFormat);
    }

    // prepare woker data
    if (job.worker) {
      job.Dispatch_Number = job.worker.Dispatch_Worker_num__c ? job.worker.Dispatch_Worker_num__c : '';
      job.Record_Type = job.worker.Worker_Type__c ? job.worker.Worker_Type__c : '';
      job.Email = job.worker.user && job.worker.user.email ? job.worker.user.email : '';
      let imgpath = environment.baseUrl + '/' + environment.apiVersion;
      if (imgpath.indexOf('localhost') !== -1) {
        imgpath = 'https://den.dev.serviceo.me/api/v1';
      }
      const imageUrl = (job.worker.user && job.worker.user['url'] && job.worker.user['picture']) ?
        imgpath + '/Containers' + job.worker.user['url'] + '' + job.worker.user['picture'] : '';
      job.Profile_Picture = imageUrl;
      job.Worker_Phone = job.Dispatch_Worker_Phone__c ? job.Dispatch_Worker_Phone__c : '';
      job.firstname = job.worker.user && job.worker.user.firstname ? job.worker.user.firstname : '';
      job.lastname = job.worker.user && job.worker.user.lastname ? job.worker.user.lastname : '';
      job.visitStatus = job.Job_Status_Internal__c;
      if (job.worker.contact && job.worker.contact.geometro) {
        job.Country = this._commonService.concatinateStrings(job.worker.contact.geometro.City__c,
          job.worker.contact.geometro.Country__c);
      } else {
        job.Country = '';
      }
    }
    return job;
  }

  preparejobObj(jobinfo) {
    return {
      id: jobinfo.id,
      worker: jobinfo.worker ? jobinfo.worker.Name : '',
      Earliest_Start_Date_Permitted__c: jobinfo.appointment ? jobinfo.appointment.Earliest_Start_Date_Permitted__c : '',
      Early_Start__c: jobinfo.appointment ? jobinfo.appointment.Early_Start__c : '',
      Customer_Appointment_DateTime_Scheduled__c: jobinfo.appointment ? jobinfo.appointment.Customer_Appointment_DateTime_Scheduled__c : '',
      StartsIn: jobinfo.StartsIn,
      Customer_Appointment_End_DateTime: jobinfo.appointment ? jobinfo.appointment.Customer_Appointment_End_DateTime : '',
      PPE_Hours__c: jobinfo.case.PPE_Hours__c,
      Worker_Arrival_DateTime_Cust_Requested__c: jobinfo.appointment ? jobinfo.appointment.Worker_Arrival_DateTime_Cust_Requested__c : '',
      Worker_Arrival_Date_Customer_Req_End__c: jobinfo.appointment ? jobinfo.appointment.Worker_Arrival_Date_Customer_Req_End__c : '',
      WorkerStartsIn: jobinfo.WorkerStartsIn,
      //worker Information
      Profile_Picture: jobinfo.Profile_Picture,
      Dispatch_Number: jobinfo.Dispatch_Number,
      Record_Type: jobinfo.Record_Type,
      Email: jobinfo.Email,
      Worker_Phone: jobinfo.Worker_Phone,
      firstname: jobinfo.firstname,
      lastname: jobinfo.lastname,
      Country: jobinfo.Country,
      visitStatus: jobinfo.Job_Status_Internal__c
    }
  }
}

