import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { DepartmentRoleApi, TaskApi } from 'shared/sdk';
import { PreloaderService } from 'shared/services/preloader.service';
import { DualListComponent } from 'angular-dual-listbox';

@Component({
  selector: 'app-department-role-lookup',
  templateUrl: './department-role-lookup.component.html',
  styleUrls: ['./department-role-lookup.component.css']
})
export class DepartmentRoleLookupComponent implements OnInit {

  keepSorted = true;
  source = [];
  confirmed = [];
  key: any;  // UNIQUE KEY 
  display: any;   // KEY OF THE FIELD TO BE DISPLAYED ON FRON-END
  filter = false; // TO SHOW FILTER
  disabled = false; // TO DISABLE
  whereObj = { Department_Role_Name__c: { neq: null } };
  format = {
    add: 'Add', remove: 'Remove', direction: DualListComponent.LTR, draggable: true, locale: 'undefinded'
  };
  _taskId = '';

  @Input()
  set reset(e) {
    if (e) {
      this.confirmed = [];
    }
  }

  @Input()
  set TaskId(e) {
    if (e) {
      this._taskId = e;
      this.getTeamByTaskId();
    }
  }

  @Input()
  set filterDepartmentRoleObj(params) {
    this.source = [];
    this.confirmed = [];
    this.whereObj = { Department_Role_Name__c: { neq: null } };
    if (params && Object.keys(params).length) {
      if (params['TaskId'] && params['TaskId'].length) {
        this.whereObj['Group_Record_Type__c'] = { inq: params['Group_Record_Type__c'] };
      }
    }
    this.getDepartmentData(this.whereObj)
  }

  @Output() getDepartmentRoleSelected: EventEmitter<any> = new EventEmitter;

  constructor(
    private _taskApi: TaskApi,
    private _departmentRoleApi: DepartmentRoleApi,
    private _preloaderService: PreloaderService,
  ) { }

  ngOnInit() {
    // this.getDepartmentData(this.whereObj);
  }

  getTeamByTaskId() {
    if (this._taskId && this._taskId.length) {
      this._preloaderService.showPreloader();
      this._taskApi.getTeamData({
        taskSfdcId: this._taskId
      }).subscribe(data => {
        if (data) {
          this.key = 'groupId'
          this.display = 'label';
          this.source = data;
          this.confirmed = [];
        }
        this._preloaderService.hidePreloader();
      }, err => {
        this._preloaderService.hidePreloader()
      })
    }
  }


  getDepartmentData(whereObj) {
    this._preloaderService.showPreloader();
    this._departmentRoleApi.find({
      where: whereObj,
      fields: ['id', 'sfdcId', 'Department_Role_Name__c', 'PgMO_Groups__c']
    }).subscribe(data => {
      if (data) {
        this.key = 'id'
        this.display = 'Department_Role_Name__c';
        this.source = data;
        this.confirmed = [];
      }
      this._preloaderService.hidePreloader();
    }, err => {
      this._preloaderService.hidePreloader()
    })
  }
  destinationChange() {
    this.getDepartmentRoleSelected.emit(this.confirmed);
  }
}
