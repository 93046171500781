/* tslint:disable */
import {
  TalentType,
  Contact,
  TalentInformation
} from '../index';

declare var Object: any;
export interface TalentProfileInterface {
  "sfdcId"?: string;
  "IsDeleted"?: boolean;
  "Name"?: string;
  "CurrencyIsoCode"?: string;
  "CreatedDate"?: Date;
  "CreatedById"?: string;
  "LastModifiedDate"?: Date;
  "LastModifiedById"?: string;
  "SystemModstamp"?: Date;
  "LastViewedDate"?: Date;
  "LastReferencedDate"?: Date;
  "Vendor__c"?: string;
  "Certifications__c"?: string;
  "Hourly_Rate__c"?: string;
  "Minimum_Hours__c"?: number;
  "Resource__c"?: string;
  "Skill_Level__c"?: string;
  "Skills__c"?: string;
  "Vendor_NDA_Status_Signed__c"?: string;
  "Specialty__c"?: string;
  "Summary__c"?: string;
  "Hourly_Rate_Virtual__c"?: string;
  "Commercial__c"?: boolean;
  "Worker_Screening_ID__c"?: string;
  "Worker_Drug_Test_Status__c"?: string;
  "Iron_Drug_Test_Report_Date__c"?: Date;
  "Worker_BGV_Report_date__c"?: Date;
  "Residential__c"?: boolean;
  "Government__c"?: boolean;
  "Education__c"?: boolean;
  "Maximum_Travel_Distance__c"?: number;
  "Vacation_On_Hold__c"?: boolean;
  "Languages__c"?: string;
  "Status__c"?: string;
  "Social_Security_Number_SSN__c"?: number;
  "Worker_Project_Site_Onboarding_Done__c"?: number;
  "Worker_Project_Site_Onboarding_NotDone__c"?: number;
  "Worker_Project_Site_Onboarding_Status__c"?: string;
  "Vendor_NDS_Status_Not_Signed__c"?: number;
  "Vendor_NDA_Status__c"?: string;
  "Worker_Project_Training_Status_Done__c"?: number;
  "Worker_Project_Training_Status_Not_don__c"?: number;
  "Worker_Project_Training_Status__c"?: string;
  "Worker_Background_check_Status_Done__c"?: number;
  "Worker_Background_check_Status_NotPass__c"?: number;
  "Worker_Background_check_Status__c"?: string;
  "Count_of_Talent_Information__c"?: number;
  "Worker_e_Verify_Status_Pass__c"?: number;
  "Worker_e_Verify_Status_Notpass__c"?: number;
  "Worker_e_Verify_Status__c"?: string;
  "Worker__c"?: string;
  "Worker_Available__c"?: boolean;
  "Primary_Talent_Type__c"?: string;
  "Worker_BGV_check_Status__c"?: string;
  "Worker_Drug_Test_Report_Date__c"?: Date;
  "other_languages__c"?: string;
  "Iron_Approved__c"?: boolean;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  talentType?: TalentType;
  contact?: Contact;
  talentInformations?: TalentInformation[];
}

export class TalentProfile implements TalentProfileInterface {
  "sfdcId": string;
  "IsDeleted": boolean;
  "Name": string;
  "CurrencyIsoCode": string;
  "CreatedDate": Date;
  "CreatedById": string;
  "LastModifiedDate": Date;
  "LastModifiedById": string;
  "SystemModstamp": Date;
  "LastViewedDate": Date;
  "LastReferencedDate": Date;
  "Vendor__c": string;
  "Certifications__c": string;
  "Hourly_Rate__c": string;
  "Minimum_Hours__c": number;
  "Resource__c": string;
  "Skill_Level__c": string;
  "Skills__c": string;
  "Vendor_NDA_Status_Signed__c": string;
  "Specialty__c": string;
  "Summary__c": string;
  "Hourly_Rate_Virtual__c": string;
  "Commercial__c": boolean;
  "Worker_Screening_ID__c": string;
  "Worker_Drug_Test_Status__c": string;
  "Iron_Drug_Test_Report_Date__c": Date;
  "Worker_BGV_Report_date__c": Date;
  "Residential__c": boolean;
  "Government__c": boolean;
  "Education__c": boolean;
  "Maximum_Travel_Distance__c": number;
  "Vacation_On_Hold__c": boolean;
  "Languages__c": string;
  "Status__c": string;
  "Social_Security_Number_SSN__c": number;
  "Worker_Project_Site_Onboarding_Done__c": number;
  "Worker_Project_Site_Onboarding_NotDone__c": number;
  "Worker_Project_Site_Onboarding_Status__c": string;
  "Vendor_NDS_Status_Not_Signed__c": number;
  "Vendor_NDA_Status__c": string;
  "Worker_Project_Training_Status_Done__c": number;
  "Worker_Project_Training_Status_Not_don__c": number;
  "Worker_Project_Training_Status__c": string;
  "Worker_Background_check_Status_Done__c": number;
  "Worker_Background_check_Status_NotPass__c": number;
  "Worker_Background_check_Status__c": string;
  "Count_of_Talent_Information__c": number;
  "Worker_e_Verify_Status_Pass__c": number;
  "Worker_e_Verify_Status_Notpass__c": number;
  "Worker_e_Verify_Status__c": string;
  "Worker__c": string;
  "Worker_Available__c": boolean;
  "Primary_Talent_Type__c": string;
  "Worker_BGV_check_Status__c": string;
  "Worker_Drug_Test_Report_Date__c": Date;
  "other_languages__c": string;
  "Iron_Approved__c": boolean;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  talentType: TalentType;
  contact: Contact;
  talentInformations: TalentInformation[];
  constructor(data?: TalentProfileInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `TalentProfile`.
   */
  public static getModelName() {
    return "TalentProfile";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of TalentProfile for dynamic purposes.
  **/
  public static factory(data: TalentProfileInterface): TalentProfile{
    return new TalentProfile(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'TalentProfile',
      plural: 'TalentProfiles',
      path: 'TalentProfiles',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "SystemModstamp": {
          name: 'SystemModstamp',
          type: 'Date'
        },
        "LastViewedDate": {
          name: 'LastViewedDate',
          type: 'Date'
        },
        "LastReferencedDate": {
          name: 'LastReferencedDate',
          type: 'Date'
        },
        "Vendor__c": {
          name: 'Vendor__c',
          type: 'string'
        },
        "Certifications__c": {
          name: 'Certifications__c',
          type: 'string'
        },
        "Hourly_Rate__c": {
          name: 'Hourly_Rate__c',
          type: 'string'
        },
        "Minimum_Hours__c": {
          name: 'Minimum_Hours__c',
          type: 'number'
        },
        "Resource__c": {
          name: 'Resource__c',
          type: 'string'
        },
        "Skill_Level__c": {
          name: 'Skill_Level__c',
          type: 'string'
        },
        "Skills__c": {
          name: 'Skills__c',
          type: 'string'
        },
        "Vendor_NDA_Status_Signed__c": {
          name: 'Vendor_NDA_Status_Signed__c',
          type: 'string'
        },
        "Specialty__c": {
          name: 'Specialty__c',
          type: 'string'
        },
        "Summary__c": {
          name: 'Summary__c',
          type: 'string'
        },
        "Hourly_Rate_Virtual__c": {
          name: 'Hourly_Rate_Virtual__c',
          type: 'string'
        },
        "Commercial__c": {
          name: 'Commercial__c',
          type: 'boolean'
        },
        "Worker_Screening_ID__c": {
          name: 'Worker_Screening_ID__c',
          type: 'string'
        },
        "Worker_Drug_Test_Status__c": {
          name: 'Worker_Drug_Test_Status__c',
          type: 'string'
        },
        "Iron_Drug_Test_Report_Date__c": {
          name: 'Iron_Drug_Test_Report_Date__c',
          type: 'Date'
        },
        "Worker_BGV_Report_date__c": {
          name: 'Worker_BGV_Report_date__c',
          type: 'Date'
        },
        "Residential__c": {
          name: 'Residential__c',
          type: 'boolean'
        },
        "Government__c": {
          name: 'Government__c',
          type: 'boolean'
        },
        "Education__c": {
          name: 'Education__c',
          type: 'boolean'
        },
        "Maximum_Travel_Distance__c": {
          name: 'Maximum_Travel_Distance__c',
          type: 'number'
        },
        "Vacation_On_Hold__c": {
          name: 'Vacation_On_Hold__c',
          type: 'boolean'
        },
        "Languages__c": {
          name: 'Languages__c',
          type: 'string'
        },
        "Status__c": {
          name: 'Status__c',
          type: 'string'
        },
        "Social_Security_Number_SSN__c": {
          name: 'Social_Security_Number_SSN__c',
          type: 'number'
        },
        "Worker_Project_Site_Onboarding_Done__c": {
          name: 'Worker_Project_Site_Onboarding_Done__c',
          type: 'number'
        },
        "Worker_Project_Site_Onboarding_NotDone__c": {
          name: 'Worker_Project_Site_Onboarding_NotDone__c',
          type: 'number'
        },
        "Worker_Project_Site_Onboarding_Status__c": {
          name: 'Worker_Project_Site_Onboarding_Status__c',
          type: 'string'
        },
        "Vendor_NDS_Status_Not_Signed__c": {
          name: 'Vendor_NDS_Status_Not_Signed__c',
          type: 'number'
        },
        "Vendor_NDA_Status__c": {
          name: 'Vendor_NDA_Status__c',
          type: 'string'
        },
        "Worker_Project_Training_Status_Done__c": {
          name: 'Worker_Project_Training_Status_Done__c',
          type: 'number'
        },
        "Worker_Project_Training_Status_Not_don__c": {
          name: 'Worker_Project_Training_Status_Not_don__c',
          type: 'number'
        },
        "Worker_Project_Training_Status__c": {
          name: 'Worker_Project_Training_Status__c',
          type: 'string'
        },
        "Worker_Background_check_Status_Done__c": {
          name: 'Worker_Background_check_Status_Done__c',
          type: 'number'
        },
        "Worker_Background_check_Status_NotPass__c": {
          name: 'Worker_Background_check_Status_NotPass__c',
          type: 'number'
        },
        "Worker_Background_check_Status__c": {
          name: 'Worker_Background_check_Status__c',
          type: 'string'
        },
        "Count_of_Talent_Information__c": {
          name: 'Count_of_Talent_Information__c',
          type: 'number'
        },
        "Worker_e_Verify_Status_Pass__c": {
          name: 'Worker_e_Verify_Status_Pass__c',
          type: 'number'
        },
        "Worker_e_Verify_Status_Notpass__c": {
          name: 'Worker_e_Verify_Status_Notpass__c',
          type: 'number'
        },
        "Worker_e_Verify_Status__c": {
          name: 'Worker_e_Verify_Status__c',
          type: 'string'
        },
        "Worker__c": {
          name: 'Worker__c',
          type: 'string'
        },
        "Worker_Available__c": {
          name: 'Worker_Available__c',
          type: 'boolean'
        },
        "Primary_Talent_Type__c": {
          name: 'Primary_Talent_Type__c',
          type: 'string'
        },
        "Worker_BGV_check_Status__c": {
          name: 'Worker_BGV_check_Status__c',
          type: 'string'
        },
        "Worker_Drug_Test_Report_Date__c": {
          name: 'Worker_Drug_Test_Report_Date__c',
          type: 'Date'
        },
        "other_languages__c": {
          name: 'other_languages__c',
          type: 'string'
        },
        "Iron_Approved__c": {
          name: 'Iron_Approved__c',
          type: 'boolean'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        talentType: {
          name: 'talentType',
          type: 'TalentType',
          model: 'TalentType',
          relationType: 'belongsTo',
                  keyFrom: 'Primary_Talent_Type__c',
          keyTo: 'sfdcId'
        },
        contact: {
          name: 'contact',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Resource__c',
          keyTo: 'sfdcId'
        },
        talentInformations: {
          name: 'talentInformations',
          type: 'TalentInformation[]',
          model: 'TalentInformation',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'Worker_Skilling_Profile__c'
        },
      }
    }
  }
}
