import { NguiMapModule } from '@ngui/map';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobSiteRouteMapComponent } from './jobsite-routemap.component';
import { environment } from 'environments/environment';
@NgModule({
  imports: [
    CommonModule,
    NguiMapModule.forRoot({
      apiUrl:
        'https://maps.google.com/maps/api/js?key=' + environment.googleMapKey,
    }),
  ],
  declarations: [JobSiteRouteMapComponent],
  exports: [JobSiteRouteMapComponent],
})
export class JobsiteRouteMapModule {}
