import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TemplateLibraryTypeLookupComponent } from './template-library-type-lookup.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule
  ],
  declarations: [TemplateLibraryTypeLookupComponent],
  exports: [TemplateLibraryTypeLookupComponent]
})
export class TemplateLibraryTypeLookupModule { }
