/* tslint:disable */

declare var Object: any;
export interface LearningVersionInterface {
  "sfdcId"?: string;
  "IsDeleted"?: boolean;
  "Name"?: string;
  "CurrencyIsoCode"?: string;
  "CreatedDate"?: Date;
  "CreatedById"?: string;
  "LastModifiedDate"?: Date;
  "LastModifiedById"?: string;
  "SystemModstamp"?: Date;
  "LastActivityDate"?: Date;
  "Learning__c"?: string;
  "Learning_Content_ID__c"?: string;
  "Quiz__c"?: string;
  "Task__c"?: number;
  "Training_Resource__c"?: string;
  "Type__c"?: string;
  "Version__c"?: number;
  "Version_selection_behavior__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class LearningVersion implements LearningVersionInterface {
  "sfdcId": string;
  "IsDeleted": boolean;
  "Name": string;
  "CurrencyIsoCode": string;
  "CreatedDate": Date;
  "CreatedById": string;
  "LastModifiedDate": Date;
  "LastModifiedById": string;
  "SystemModstamp": Date;
  "LastActivityDate": Date;
  "Learning__c": string;
  "Learning_Content_ID__c": string;
  "Quiz__c": string;
  "Task__c": number;
  "Training_Resource__c": string;
  "Type__c": string;
  "Version__c": number;
  "Version_selection_behavior__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: LearningVersionInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `LearningVersion`.
   */
  public static getModelName() {
    return "LearningVersion";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of LearningVersion for dynamic purposes.
  **/
  public static factory(data: LearningVersionInterface): LearningVersion{
    return new LearningVersion(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'LearningVersion',
      plural: 'LearningVersions',
      path: 'LearningVersions',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "SystemModstamp": {
          name: 'SystemModstamp',
          type: 'Date'
        },
        "LastActivityDate": {
          name: 'LastActivityDate',
          type: 'Date'
        },
        "Learning__c": {
          name: 'Learning__c',
          type: 'string'
        },
        "Learning_Content_ID__c": {
          name: 'Learning_Content_ID__c',
          type: 'string'
        },
        "Quiz__c": {
          name: 'Quiz__c',
          type: 'string'
        },
        "Task__c": {
          name: 'Task__c',
          type: 'number'
        },
        "Training_Resource__c": {
          name: 'Training_Resource__c',
          type: 'string'
        },
        "Type__c": {
          name: 'Type__c',
          type: 'string'
        },
        "Version__c": {
          name: 'Version__c',
          type: 'number'
        },
        "Version_selection_behavior__c": {
          name: 'Version_selection_behavior__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
