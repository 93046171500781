/* tslint:disable */

declare var Object: any;
export interface ReferenceInterface {
  "sfdcId"?: string;
  "ts2__Candidate__c"?: string;
  "CreatedById"?: string;
  "CurrencyIsoCode"?: string;
  "LastModifiedById"?: string;
  "Name"?: string;
  "ts2__City__c"?: string;
  "ts2__Company__c"?: string;
  "ts2__Description__c"?: string;
  "ts2__Email__c"?: string;
  "ts2__End_Date__c"?: Date;
  "ts2__Job_Order__c"?: string;
  "ts2__Legacy_ReferenceID__c"?: string;
  "ts2__Name__c"?: string;
  "ts2__Phone__c"?: string;
  "JSPresent__PullOut_Quote__c"?: string;
  "ts2__Reference__c"?: string;
  "ts2__Related_Organization__c"?: string;
  "ts2__Role_Title__c"?: string;
  "ts2__Start_Date__c"?: Date;
  "ts2__State__c"?: string;
  "ts2__Status__c"?: string;
  "ts2__Type__c"?: string;
  "ts2__Years_Known__c"?: number;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class Reference implements ReferenceInterface {
  "sfdcId": string;
  "ts2__Candidate__c": string;
  "CreatedById": string;
  "CurrencyIsoCode": string;
  "LastModifiedById": string;
  "Name": string;
  "ts2__City__c": string;
  "ts2__Company__c": string;
  "ts2__Description__c": string;
  "ts2__Email__c": string;
  "ts2__End_Date__c": Date;
  "ts2__Job_Order__c": string;
  "ts2__Legacy_ReferenceID__c": string;
  "ts2__Name__c": string;
  "ts2__Phone__c": string;
  "JSPresent__PullOut_Quote__c": string;
  "ts2__Reference__c": string;
  "ts2__Related_Organization__c": string;
  "ts2__Role_Title__c": string;
  "ts2__Start_Date__c": Date;
  "ts2__State__c": string;
  "ts2__Status__c": string;
  "ts2__Type__c": string;
  "ts2__Years_Known__c": number;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: ReferenceInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Reference`.
   */
  public static getModelName() {
    return "Reference";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Reference for dynamic purposes.
  **/
  public static factory(data: ReferenceInterface): Reference{
    return new Reference(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Reference',
      plural: 'References',
      path: 'References',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "ts2__Candidate__c": {
          name: 'ts2__Candidate__c',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "ts2__City__c": {
          name: 'ts2__City__c',
          type: 'string'
        },
        "ts2__Company__c": {
          name: 'ts2__Company__c',
          type: 'string'
        },
        "ts2__Description__c": {
          name: 'ts2__Description__c',
          type: 'string'
        },
        "ts2__Email__c": {
          name: 'ts2__Email__c',
          type: 'string'
        },
        "ts2__End_Date__c": {
          name: 'ts2__End_Date__c',
          type: 'Date'
        },
        "ts2__Job_Order__c": {
          name: 'ts2__Job_Order__c',
          type: 'string'
        },
        "ts2__Legacy_ReferenceID__c": {
          name: 'ts2__Legacy_ReferenceID__c',
          type: 'string'
        },
        "ts2__Name__c": {
          name: 'ts2__Name__c',
          type: 'string'
        },
        "ts2__Phone__c": {
          name: 'ts2__Phone__c',
          type: 'string'
        },
        "JSPresent__PullOut_Quote__c": {
          name: 'JSPresent__PullOut_Quote__c',
          type: 'string'
        },
        "ts2__Reference__c": {
          name: 'ts2__Reference__c',
          type: 'string'
        },
        "ts2__Related_Organization__c": {
          name: 'ts2__Related_Organization__c',
          type: 'string'
        },
        "ts2__Role_Title__c": {
          name: 'ts2__Role_Title__c',
          type: 'string'
        },
        "ts2__Start_Date__c": {
          name: 'ts2__Start_Date__c',
          type: 'Date'
        },
        "ts2__State__c": {
          name: 'ts2__State__c',
          type: 'string'
        },
        "ts2__Status__c": {
          name: 'ts2__Status__c',
          type: 'string'
        },
        "ts2__Type__c": {
          name: 'ts2__Type__c',
          type: 'string'
        },
        "ts2__Years_Known__c": {
          name: 'ts2__Years_Known__c',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
