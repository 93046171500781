import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { IronApprovalStatusLookupComponent } from './iron-approval-status-lookup.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgSelectModule
    ],
    declarations: [IronApprovalStatusLookupComponent],
    exports: [IronApprovalStatusLookupComponent]
})
export class IronApprovalStatusLookupModule { }
