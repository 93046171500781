import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { slaGroupNameValues } from "shared/models/static-list-data.service";

@Component({
  selector: "app-sla-group-name-lookup",
  templateUrl: "./sla-group-name-lookup.component.html",
  styleUrls: ["./sla-group-name-lookup.component.css"],
})
export class SlaGroupNameLookupComponent implements OnInit {

  @Input() set resetData(isClear) {
    if (isClear) {
      this.slaGroupSelected = [];
    }
  }
  @Output() selectedSlaGroup: EventEmitter<any> = new EventEmitter();

  slaGroupList = [];
  slaGroupSelected = [];
  isMultipleSelected = true;
  

  constructor() {}

  ngOnInit() {
    this.slaGroupList = slaGroupNameValues;
    const preselected = localStorage.getItem("filterObj")
      ? JSON.parse(localStorage.getItem("filterObj"))
      : "";
    this.slaGroupSelected =
      preselected && preselected["slaGroup"] ? preselected["slaGroup"] : "";
  }

  onSlaGroupChange() {
    this.selectedSlaGroup.emit(this.slaGroupSelected);
  }
  
  clearSlaGroup() {
    this.slaGroupSelected = [];
    this.selectedSlaGroup.emit([]);
  }
}
