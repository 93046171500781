import { AppStateService } from 'shared/services/app-state.service';
import { UtilityService } from 'shared/services/utility.service';
import { Component, OnInit, ViewChild, Input, AfterViewInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { JobApi } from 'shared/sdk';
import { PreloaderService } from 'shared/services/preloader.service';
import { DatePipe } from '@angular/common';
import { forkJoin, Subscription } from 'rxjs';
import { CommonService } from 'shared/services/common.service';
import { Router } from '@angular/router';
import { ExportToExcelService } from 'shared/services/export-to-excel.service';
import { GlobalFilterService } from 'shared/services/global-filter.service';
import { BaseVendorViewComponent } from 'msp/shared/base-vendor-view/base-vendor-view.component';

@Component({
  selector: 'app-time-clock-manager-list',
  templateUrl: './time-clock-manager-list.component.html',
  styleUrls: ['./time-clock-manager-list.component.css']
})
export class TimeClockManagerListComponent extends BaseVendorViewComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() childFilters: EventEmitter<any> = new EventEmitter;
  @Input()
  set globalFilterData(e) {
    if (e) {
      this.setFiltersAndLoadData();
    }
  }
  @Input() checkInOutData: any;
  @Input() isCheckInCheckOut = false;
  internalUserFilters = [
    'accounts',
    'vendorAccounts',
    'countries',
    'serviceContractNumbers'
  ]
  enableFilters = [
    'dateOptions',
    'jobSites',
    'jobs',
    'workerNames'
  ];
  isLoading: boolean;
  timesLoadedMore: number;
  itemsPerBatch = 200;
  itemsPerPage = 25;
  tableData: any[];
  noRecords: boolean;
  allItems: any[];
  filterObj = {};
  columns: any;
  datePipe: any;
  totalCount: number;
  globalFilters: any = {};
  whereCondition: any;
  accessType: string;
  frozenLeft = true;
  @ViewChild('myTable') table: any;
  sidebarStateSub: Subscription;

  isVmsAdminUser = false;

  constructor(private _loader: PreloaderService,
    private _job: JobApi,
    private _utilityService: UtilityService,
    private _commonService: CommonService,
    private router: Router,
    private _exportToExcelService: ExportToExcelService,
    private _appStateService: AppStateService,
    private _globalFilterService: GlobalFilterService
  ) { 
    super(_appStateService);
  }

  ngOnInit() {
    this._commonService.saveCurrentPageAnalytics('Billing Manager - Contract Jobs', 'Job Level View');
    this.sidebarStateSub = this._globalFilterService.getSidebarState().subscribe(isOpened => {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 0);
    })
    this.accessType = this._appStateService.getAccessType();
    if ((!this.viewAsVendor && this.accessType === 'internal') && !(this.checkInOutData && this.checkInOutData.serviceContract)) {
      this.enableFilters = this.enableFilters.concat(this.internalUserFilters);
    } 
    // else if (this.checkInOutData && this.checkInOutData.serviceContract) {
    //   this.enableFilters.push('serviceContractNumbers');
    // }
    let userTypeSlug = localStorage.getItem('userTypeSlug');
    if ((!this.viewAsVendor && this.accessType === "vendor" && userTypeSlug === "admin") || (this.viewAsVendor && this.vendorAccessTypes.userTypeSlug === 'admin')) {
      this.isVmsAdminUser = true;
    }
    if (window.innerWidth < 768) {
      this.frozenLeft = false;
    }
    this.columnInitialize();
    this.setFiltersAndLoadData();
  }

  ngAfterViewInit() {
    this.childFilters.emit(this.enableFilters);
  }

  columnInitialize() {
    this.columns = [
      {
        prop: '##Action',
        name: 'View Time Clock Entries',
        visible: true,
        width: 200,
        type: 'static',
        frozenLeft: this.frozenLeft
      },
      {
        prop: 'Iron_Job_num__c',
        name: 'Job Number',
        visible: true,
        width: 160,
        type: 'string',
        sortable: true,
        frozenLeft: this.frozenLeft
      },
      // {
      //   prop: 'serviceContractNumber',
      //   name: 'Service Contract#',
      //   visible: !this.isVmsAdminUser,
      //   width: 150,
      //   type: 'string',
      //   sortable: true,
      //   frozenLeft: this.frozenLeft
      // },
      {
        prop: 'serviceContractName',
        name: 'Service Contract Name',
        visible: !this.isVmsAdminUser && !this.isCheckInCheckOut,
        width: 180,
        type: 'string',
        sortable: true,
        frozenLeft: false,
        showMore: true
      },
      {
        prop: 'checkInOutNum',
        name: 'No of Check IN/OUT',
        visible: true,
        width: 160,
        type: 'string',
        sortable: true,
        frozenLeft: false
      },
      {
        prop: 'accountName',
        name: 'Account',
        visible: !this.isVmsAdminUser && !this.isCheckInCheckOut,
        width: 180,
        type: 'string',
        sortable: true,
        frozenLeft: false,
        showMore: true
      },
      {
        prop: 'Case_Number__c',
        name: 'Case Number',
        width: 140,
        visible: !this.isVmsAdminUser && !this.isCheckInCheckOut,
        type: 'string',
        sortable: true,
        frozenLeft: false
      },
      {
        prop: 'workerName',
        name: 'Worker Name',
        width: 200,
        visible: true,
        type: 'string',
        sortable: true
      },
      {
        prop: 'vendorName',
        name: 'Vendor Name',
        width: 200,
        type: 'string',
        visible: true,
        sortable: true
      },
      {
        prop: 'jobsiteName',
        name: 'Jobsite',
        width: 300,
        type: 'string',
        visible: true,
        sortable: true,
        showMore: true
      },
      {
        prop: 'jobsiteProjects',
        name: 'Jobsite Project',
        width: 180,
        type: 'string',
        visible: !this.isVmsAdminUser && !this.isCheckInCheckOut,
        sortable: true,
        showMore: true
      },
      {
        prop: 'CKSW_BASE__Status__c',
        name: 'Job Status',
        width: 180,
        type: 'string',
        visible: true,
        sortable: true
      },
      {
        prop: 'programName',
        name: 'Program',
        type: 'string',
        width: 180,
        visible: !this.isVmsAdminUser && !this.isCheckInCheckOut,
        sortable: true,
        showMore: true
      }
    ];
    this.hideColumnFromCheckInOut();

  }

  hideColumnFromCheckInOut() {
    if ((this.accessType === 'vendor' || this.viewAsVendor) && this.checkInOutData && this.checkInOutData.serviceContract) {
      const hiddenColumn = ['workerName', 'accountName', 'Case_Number__c', 'jobsiteProjects', 'programName'];
      this.columns = this.columns.filter(col => !hiddenColumn.includes(col.prop));
    }
  }

  getData() {
    this.isLoading = true;
    this.timesLoadedMore = 0;
    this._loader.showPreloader();
    forkJoin(this.getJobs(0), this.getJobCount()).subscribe(
      ([jobs, count]: any) => {
        this.tableData = this.prepareJobData(jobs);
        this.totalCount = count;
        this.noRecords = jobs.length < this.itemsPerBatch ? true : false;
        this.allItems = this.tableData.slice();
        this.filterData();
      },
      (error) => {
        this.isLoading = false;
        this._loader.hidePreloader();
      },
      () => {
        this.isLoading = false;
        this._loader.hidePreloader();
      }
    );
  }

  getJobs(offset: number) {
    const findObj = {
      order: 'createdAt Desc',
      source: 'timeclock-manager',
      filter: this.whereCondition,
      limit: this.itemsPerBatch,
      skip: offset,
    };
    if (this.isCheckInCheckOut) {
      findObj['isCheckInCheckOut'] = true;
    }
    return this._job.getJobDispatchList(findObj);
  }

  getJobCount() {
    const findObj = {
      filter: this.whereCondition,
      mode: 'count',
    }
    if (this.isCheckInCheckOut) {
      findObj['isCheckInCheckOut'] = true;
    }
    return this._job.getJobDispatchList(findObj);
  }

  filterData(event?, type?) {
    if (event) {
      if (type === 'date') {
        if (event.value === '') {
          if (this.filterObj[event.input.id + '_temp']) {
            delete this.filterObj[event.input.id];
          }
        } else {
          const date = this.transform(event.value).split(',');
          this.filterObj[event.input.id] = date[0];
        }
      } else {
        if (event.target.value === '') {
          delete this.filterObj[event.currentTarget.id];
        } else {
          this.filterObj[event.currentTarget.id] = event.target.value;
        }
      }
    }
    this.tableData = this.allItems.filter((item) => {
      const notMatchingField = Object.keys(this.filterObj).find((key) =>
        this._utilityService.dataTableSearch(item, this.filterObj, key)
      );
      return !notMatchingField;
    });
    if (this.table) {
      this.table['offset'] = 0;
    }
    this.setEmptyMessage();
  }

  transform(value: string, type?) {
    let dateformate: string;
    type ? (dateformate = type) : (dateformate = 'short');
    this.datePipe = new DatePipe('en-US');
    const formateddate = value
      ? this.datePipe.transform(value, dateformate)
      : value;
    return formateddate;
  }

  setEmptyMessage() {
    const msg = 'No data to display';
    if (!this.tableData.length) {
      this.tableData = [
        {
          message: msg,
        },
      ];
      this.tableData[0][this.columns[0]['prop']] = msg;
    } else {
      if (this.tableData[0].hasOwnProperty('message')) {
        this.tableData.shift();
      }
    }
  }

  prepareJobData(data) {
    const jobData = [];
    if (data) {
      let obj;
      data.forEach((item, index) => {
        obj = {
          'sfdcId': item.sfdcId ? item.sfdcId : '',
          'Name': item.Name || '',
          'Iron_Job_num__c': item.Iron_Job_num__c || '',
          'CKSW_BASE__Status__c': item.CKSW_BASE__Status__c || '',
          'programName': (item.program && item.program.Name) || '',
          'vendorName': (item.vendor && item.vendor.Name) || '',
          'jobsiteName': (item.jobsite && item.jobsite.Name) || '',
          'workerName': (item.worker && item.worker.Name) || '',
          'accountName': (item.partner && item.partner.Name) || '',
          'Case_Number__c': item.Case_Number__c || '',
          'serviceContractName': (item.serviceContract && item.serviceContract.Name) || '',
          'checkInOutNum': (item.timeClockManager && item.timeClockManager.length) || 0,
          'serviceContractNumber': (item.serviceContract && item.serviceContract.Service_Contract_Number__c) || '',
          'jobsiteProjects': (item.jobsite && item.jobsite.JobsiteProjects && item.jobsite.JobsiteProjects.Project
            && item.jobsite.JobsiteProjects.Project.Name) || ''
        };
        jobData.push(obj);
      });
    }
    return jobData;
  }

  clearSearch(col) {
    this.filterObj[col] = '';
    delete this.filterObj[col];
    this.tableData = this.allItems.filter((item) => {
      const notMatchingField = Object.keys(this.filterObj).find((key) =>
        this._utilityService.dataTableSearch(item, this.filterObj, key)
      );
      return !notMatchingField;
    });
    this.table.offset = 0;
    this.setEmptyMessage();
  }
  loadMoreRecords() {
    this._loader.showPreloader();
    this.timesLoadedMore += 1;
    this.getJobs(this.itemsPerBatch * this.timesLoadedMore).subscribe(
      (data) => {
        const currentData = this.prepareJobData(data);
        currentData.forEach((c) => {
          this.allItems.push(c);
        });
        this.tableData = [...this.allItems];
        this.allItems = this.tableData.slice();
        this.filterData();
      },
      (error) => {
        this._loader.hidePreloader();
      },
      () => {
        this._loader.hidePreloader();
      }
    );
  }

  setFiltersAndLoadData() {
    this.whereCondition = { RecordTypeId: '0121a0000006QniAAE' };
    if (this.checkInOutData && this.checkInOutData.serviceContract) {
      // this.whereCondition['Service_Contract__c'] = this.checkInOutData.serviceContract.sfdcId;
      this.whereCondition['Dispatch_Worker_Name__c'] = this.checkInOutData.jobs.Dispatch_Worker_Name__c;
    }
    if (window.innerWidth > 768) {
      this.globalFilters = this._commonService.getGlobalFilter(this.enableFilters);
      if (this.globalFilters && Object.keys(this.globalFilters).length) {
        if (this.globalFilters.CountryCode) {
          this.globalFilters['CKSW_BASE__Country__c'] = this.globalFilters.CountryCode;
          delete this.globalFilters.CountryCode;
        }
        this.whereCondition = { ...this.whereCondition, ...this.globalFilters };
      }
    }
    this.getData();
  }

  // on click of link in table
  redirectToDetails(data) {
    const params: any = {};
    params.activeId = 'submitted';
    params.ironJobnumber = data.Iron_Job_num__c;
    params.jobnumber = data.Name;
    let url;
    if (this.checkInOutData && this.checkInOutData.jobs) {
      params.isCheckInOut = true;
      url = 'app/workforce-checkin-checkout/time-clock-entries/'
    } else {
      const path = (this.viewAsVendor || this.accessType === 'vendor') ? '/vms' : '/pms';
      url = path + '/vms-billing-manager/time-clock-entries/';
    }
    this.router.navigate([url], { queryParams: params });
  }

  export() {
    this._exportToExcelService.exportToExcel(this.tableData, this.columns, 'Time-clock-manager-list')
  }

  ngOnDestroy() {
    this.sidebarStateSub.unsubscribe();
  }
}
