// src/app/auth/token.interceptor.ts
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { AuthService } from './authentication.service';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class InterceptorService implements HttpInterceptor {
  constructor(private auth: AuthService, private _cookieService: CookieService) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // @NOTE: If you have some endpoints that are public
    // and do not need Authorization header, implement logic
    // here to accommodate that and conditionally let public
    // requests pass through based on your requirements
    if (this.auth.token$) {
      return this.auth.token$
        .pipe(
          mergeMap(token => {
            let tokenReq;
            if (token && req.url.indexOf('getuserdata') === -1) {
              // Check for if request is not the survey forms
              if (req.url.indexOf('RestResource') === -1) {
                let reqHeader = {
                  setHeaders: { Authorization: `Bearer ${token}`, userId: this._cookieService.get('$LoopBackSDK$userId') }
                };
                const viewAsInfo = JSON.parse(localStorage.getItem('viewAsInfo'));
                const isViewAsVendor = (viewAsInfo && viewAsInfo.viewAsType === 'Vendor');
                if(isViewAsVendor && viewAsInfo.userSfdcId) {
                  reqHeader['setHeaders']['vendoruserid'] = viewAsInfo.userSfdcId
                }
                tokenReq = req.clone(reqHeader);
              } else {
                tokenReq = req.clone({
                });
              }
            } else {
              tokenReq = req.clone({
                setHeaders: { Authorization: `Bearer ${token}` }
              });
            }
            if (localStorage.getItem('isLoggedIn') !== 'true') {
              this.auth.logout();
            }
            return next.handle(tokenReq);
          })
        );
    } else {
      const tokenReq = req.clone({
      });
      return next.handle(tokenReq)
    }
  }
}
