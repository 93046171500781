import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, Input, ChangeDetectorRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { PreloaderService } from '../../../../../services/preloader.service';
import { AlertService } from '../../../../../services/alert.service';
import { CommonService } from 'shared/services/common.service';
import { AppStateService } from '../../../../../services/app-state.service';
import * as accessRight from '../job-detail.access';
import { JobApi } from '../../../../../sdk';

@Component({
    selector: 'job-detail-worker-status',
    templateUrl: './job-detail-worker-status.component.html',
    styleUrls: ['./job-detail-worker-status.component.css']
})

export class JobDetailWorkerStatusComponent implements OnInit, OnDestroy {

    @Input() jobId?: string;

    @Input() timeZone: any;
    @Output() uploaded: EventEmitter<any> = new EventEmitter();
    @Input() jobObjInput?: any;
    job: any;
    errorMessage = '';
    formAppointmentStatus: FormGroup;
    Worker_Departure_Date_Time_Actual__c: any;
    Worker_Arrival_Date_Time_Actual__c: any;
    Worker_InProgress_Start_DateTime_Actual__c: any;
    Worker_Finish_Date_Time_Actual__c: any;
    minArrivalDate: any;
    minProgressDate: any;
    minFinishDate: any;
    roleSub: Subscription;
    isPublic = false;
    userRole: any;
    mode: any;
    jobObj = {
        Worker_Departure_Date_Time_Actual__c: null,
        Worker_Arrival_Date_Time_Actual__c: null,
        Worker_InProgress_Start_DateTime_Actual__c: null,
        Worker_Finish_Date_Time_Actual__c: null
    };
    isSubmitDisabled = true;
    activeTabs = ['Visit', 'Timecard', 'Message'];

    constructor(
        private route: ActivatedRoute,
        private _fb: FormBuilder,
        private _preloaderService: PreloaderService,
        private _jobApi: JobApi,
        private _alertService: AlertService,
        private _appState: AppStateService,
        private _cd: ChangeDetectorRef,
        private _commonService: CommonService
    ) {
        if (this.route.snapshot.data['accessType'] && this.route.snapshot.data['accessType'] === 'public') {
            this.isPublic = true;
        }
    }
    ngOnInit() {
        this._commonService.saveCurrentPageAnalytics('Job Manager Console - Details', 'Worker Status');
    }
    ngOnChanges() {
        this.roleSub = this._appState.getJobDetailUserRole().subscribe(role => {
            if (role && role['userRole']) {
                this.userRole = role['userRole'];
                this.mode = accessRight.access[role['userRole']]['worker-status'];
            }
            if (this.jobId) {
                this.loadData(this.jobId);
            } else if (this.jobObjInput) {
                this.prepareJobResult(JSON.parse(this.jobObjInput));
            } else {
                this.errorMessage = 'No details to display.';
            }
        })
    }

    ngOnDestroy() {
        if (this.roleSub) {
            this.roleSub.unsubscribe();
        }
    }

    loadData(jobId) {
        this._preloaderService.showPreloader();
        this.errorMessage = '';
        const reqObj = {
            'where': { 'sfdcId': jobId },
            'fields': ['sfdcId', 'id', 'Service_Parts_Local_Pickup_Required__c', 'Worker_Departure_Date_Time_Actual__c',
                'Worker_Arrival_Date_Time_Actual__c', 'Worker_InProgress_Start_DateTime_Actual__c', 'Worker_Finish_Date_Time_Actual__c',
                'Deliverables__c', 'Deliverable_Status__c', 'Case__c', 'Job_Status_Internal__c', 'workflowStatusId',
                'Is_Final_Timecard_Submitted'],
            'include': [
                {
                    'relation': 'deliverable',
                    'scope': {
                        'fields': ['Name', 'Deliverable_Type__c', 'File_upload_required__c', 'Description__c',
                            'Instructions__c']
                    }
                }
            ]
        };
        this._jobApi.getJobDetailsById(reqObj).subscribe(
            result => {
                this.prepareJobResult(result);
            },
            error => {
                this._preloaderService.hidePreloader();
                this.errorMessage = error.message;
            }
        );
    }

    prepareJobResult(result) {
        if (result && result.id) {
            this.job = result;
            this.setFormValue();
        } else {
            this.errorMessage = 'No details to display.';
        }
        this._preloaderService.hidePreloader();
    }

    setFormValue() {
        this.jobObj.Worker_Departure_Date_Time_Actual__c = this.job.Worker_Departure_Date_Time_Actual__c ?
            this.job.Worker_Departure_Date_Time_Actual__c : null;
        this.jobObj.Worker_Arrival_Date_Time_Actual__c = this.job.Worker_Arrival_Date_Time_Actual__c ?
            this.job.Worker_Arrival_Date_Time_Actual__c : null;
        this.jobObj.Worker_InProgress_Start_DateTime_Actual__c = this.job.Worker_InProgress_Start_DateTime_Actual__c ?
            this.job.Worker_InProgress_Start_DateTime_Actual__c : null;
        this.jobObj.Worker_Finish_Date_Time_Actual__c = this.job.Worker_Finish_Date_Time_Actual__c ?
            this.job.Worker_Finish_Date_Time_Actual__c : null;

        this.Worker_Departure_Date_Time_Actual__c = this.job.Worker_Departure_Date_Time_Actual__c ?
            this._commonService.applyNewTimeZone(this.job.Worker_Departure_Date_Time_Actual__c, this.timeZone) : null;
        this.Worker_Arrival_Date_Time_Actual__c = this.job.Worker_Arrival_Date_Time_Actual__c ?
            this._commonService.applyNewTimeZone(this.job.Worker_Arrival_Date_Time_Actual__c, this.timeZone) : null;
        this.Worker_InProgress_Start_DateTime_Actual__c = this.job.Worker_InProgress_Start_DateTime_Actual__c ?
            this._commonService.applyNewTimeZone(this.job.Worker_InProgress_Start_DateTime_Actual__c, this.timeZone) : null;
        this.Worker_Finish_Date_Time_Actual__c = this.job.Worker_Finish_Date_Time_Actual__c ?
            this._commonService.applyNewTimeZone(this.job.Worker_Finish_Date_Time_Actual__c, this.timeZone) : null;

        this.minArrivalDate = this.Worker_Departure_Date_Time_Actual__c;
        this.minProgressDate = this.Worker_Arrival_Date_Time_Actual__c;
        this.minFinishDate = this.Worker_InProgress_Start_DateTime_Actual__c;
        this.checkValues();
    }

    updateAppointmentStatus() {
        const obj = this.jobObj;
        for (const key in this.mode['job']) {
            if (this.mode['job'][key]['write'] === false && obj[key]) {
                delete obj[key]
            }
        }
        this._preloaderService.showPreloader();
        this._jobApi.patchAttributes(this.job.id, obj).subscribe(
            result => {
                this.loadData(this.jobId);
                this._alertService.success('Job Appointment has been updated successfully.');
                window.scrollTo(0, 0);
            },
            err => {
                this._preloaderService.hidePreloader();
                this._alertService.error(err.message);
            }
        );
    }

    updateAppointmentStatusReset() {
        this.setFormValue();
    }

    onDateChange(e, field) {
        const newValue = e.value;
        const newValueStr = newValue.toISOString();
        const tranformedValue = this._commonService.setNewTimeZone(newValue, this.timeZone);
        if (field === 'Worker_Departure_Date_Time_Actual__c') {
            this.jobObj.Worker_Departure_Date_Time_Actual__c = tranformedValue;
            this.Worker_Departure_Date_Time_Actual__c = newValueStr;
            if ((this.Worker_Arrival_Date_Time_Actual__c) &&
                newValue > new Date(this.Worker_Arrival_Date_Time_Actual__c)) {
                this.Worker_Arrival_Date_Time_Actual__c = null;
                this.jobObj.Worker_Arrival_Date_Time_Actual__c = null;
            }
            if ((this.Worker_InProgress_Start_DateTime_Actual__c) &&
                newValue > new Date(this.Worker_InProgress_Start_DateTime_Actual__c)) {
                this.Worker_InProgress_Start_DateTime_Actual__c = null;
                this.jobObj.Worker_InProgress_Start_DateTime_Actual__c = null;
            }
            if ((this.Worker_Finish_Date_Time_Actual__c) &&
                newValue > new Date(this.Worker_Finish_Date_Time_Actual__c)) {
                this.Worker_Finish_Date_Time_Actual__c = null;
                this.jobObj.Worker_Finish_Date_Time_Actual__c = null;
            }
            this.minArrivalDate = newValue;
            this.minProgressDate = (this.Worker_Departure_Date_Time_Actual__c) ?
                this.Worker_Departure_Date_Time_Actual__c : newValue;
            this.minFinishDate = (this.Worker_InProgress_Start_DateTime_Actual__c) ?
                this.Worker_Departure_Date_Time_Actual__c : newValue;

        } else if (field === 'Worker_Arrival_Date_Time_Actual__c') {
            this.jobObj.Worker_Arrival_Date_Time_Actual__c = tranformedValue;
            this.Worker_Arrival_Date_Time_Actual__c = newValueStr;
            if (newValue > new Date(this.Worker_InProgress_Start_DateTime_Actual__c)) {
                this.Worker_InProgress_Start_DateTime_Actual__c = null;
                this.jobObj.Worker_InProgress_Start_DateTime_Actual__c = null;
            }
            if (newValue > new Date(this.Worker_Finish_Date_Time_Actual__c)) {
                this.Worker_Finish_Date_Time_Actual__c = null;
                this.jobObj.Worker_Finish_Date_Time_Actual__c = null;
            }
            this.minProgressDate = newValue;
            this.minFinishDate = (this.Worker_InProgress_Start_DateTime_Actual__c) ?
                this.Worker_Departure_Date_Time_Actual__c : newValue;
        } else if (field === 'Worker_InProgress_Start_DateTime_Actual__c') {
            this.jobObj.Worker_InProgress_Start_DateTime_Actual__c = tranformedValue;
            this.Worker_InProgress_Start_DateTime_Actual__c = newValueStr;
            if (newValue > new Date(this.Worker_Finish_Date_Time_Actual__c)) {
                this.Worker_Finish_Date_Time_Actual__c = null;
                this.jobObj.Worker_Finish_Date_Time_Actual__c = null;
            }
            this.minFinishDate = newValue
        } else if (field === 'Worker_Finish_Date_Time_Actual__c') {
            this.Worker_Finish_Date_Time_Actual__c = newValueStr;
            this.jobObj.Worker_Finish_Date_Time_Actual__c = tranformedValue;
        }

        this.checkValues();
        this._cd.detectChanges();
    }

    fileUploaded() {
        this.uploaded.emit();
    }

    checkValues() {
        this.isSubmitDisabled = true;
        if (this.jobObj.Worker_Departure_Date_Time_Actual__c || this.jobObj.Worker_Arrival_Date_Time_Actual__c ||
            this.jobObj.Worker_InProgress_Start_DateTime_Actual__c || this.jobObj.Worker_Finish_Date_Time_Actual__c) {
            this.isSubmitDisabled = false;
        }
    }

    onTabBtnClick(tab) {
        let index = this.activeTabs.indexOf(tab);
        if (index !== -1) {
            this.activeTabs.splice(index, 1);
        } else {
            this.activeTabs.push(tab);
        }
    }
}
