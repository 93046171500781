/* tslint:disable */
import {
  Appointment,
  Account,
  Contact,
  Deliverable,
  GeoMetro,
  Jobsite,
  Case,
  Product,
  Project,
  TalentType,
  CountryCode,
  Asset,
  Milestone,
  Program,
  Project2,
  Task,
  ServiceContract,
  VCSQDLead
} from '../index';

declare var Object: any;
export interface QuoteManagerInterface {
  "sfdcId"?: string;
  "CreatedById"?: string;
  "LastModifiedById"?: string;
  "Name"?: string;
  "RecordTypeId"?: string;
  "Acknowledgement_Sent__c"?: string;
  "Actual_Shipping_Date__c"?: Date;
  "Appointment__c"?: string;
  "Asset_Serial_Number__c"?: string;
  "Automation_Status__c"?: string;
  "Billing_Country__c"?: string;
  "Billing_Type__c"?: string;
  "Bill_To_Party__c"?: string;
  "Carrier_Instructions__c"?: string;
  "Carrier_Scac__c"?: string;
  "City__c"?: string;
  "Committed_Shipping_Date__c"?: Date;
  "Copy_of_MSA__c"?: string;
  "Country__c"?: string;
  "Created_By__c"?: string;
  "Create_ICC_Case__c"?: boolean;
  "Currency_you_desire_to_do_transaction__c"?: string;
  "Current_Status__c"?: string;
  "Customer_Contact__c"?: string;
  "Customer_PO_Number__c"?: string;
  "Customs_Broker__c"?: string;
  "Decommission_Disposal__c"?: string;
  "Default_Quote__c"?: boolean;
  "Deliverable__c"?: string;
  "Delivery_Date__c"?: Date;
  "Description__c"?: string;
  "Discount_Amount__c"?: number;
  "Dispatch_SLA_Priority__c"?: string;
  "Do_you_have_legal_entity_in_USA__c"?: string;
  "Do_you_provide_EOR_Services__c"?: string;
  "Do_you_provide_FSE_in_Selected_Country__c"?: boolean;
  "Provide_FSE_in_Selected_Country__c"?: string;
  "Do_you_provide_FTE_in_Selected_Country__c"?: boolean;
  "Provide_FTE_in_Selected_Country__c"?: string;
  "Do_you_provide_ITAD_Services__c"?: string;
  "Do_you_provide_Next_business_day_SLA__c"?: boolean;
  "Do_you_provide_Next_businessday_SLA_NBD__c"?: boolean;
  "Do_you_provide_Same_business_day_SLASBD__c"?: string;
  "Do_you_provide_Same_business_day_SLA_SBD__c"?: boolean;
  "End_User__c"?: string;
  "EOR_Party__c"?: string;
  "EUC_DSS_Break_Fi_Fix__c"?: string;
  "Expected_Shipping_Date__c"?: Date;
  "Extended_Amount_Excl_Tax__c"?: number;
  "FTE_Configuration_Type__c"?: string;
  "GEO_Metro__c"?: string;
  "Have_legal_entity_in_Country_Selected__c"?: string;
  "Have_you_completed_all_the_steps_of_RFI__c"?: string;
  "Health_Appointment_status__c"?: string;
  "Health_Pricelist_Status__c"?: string;
  "Health_SOP_Profile_Status__c"?: string;
  "Highest_Level_of_Talent__c"?: string;
  "ICC_Case_Created__c"?: boolean;
  "Importer_IOR__c"?: string;
  "incoterm__c"?: string;
  "Instructions__c"?: string;
  "Insurance_Party__c"?: string;
  "International_Shipment__c"?: string;
  "Inventory_Pool__c"?: string;
  "IOR_Party__c"?: string;
  "Iron_Comments__c"?: string;
  "Iron_Comments_MSP__c"?: string;
  "Iron_Quote_Number__c"?: string;
  "Iron_Sales_Order__c"?: string;
  "Job_Instructions_Deliverables_Summary__c"?: string;
  "Job_Instructions_Required_Tools__c"?: string;
  "Job_Instructions_Service_Deliverables__c"?: string;
  "Job_Instructions_Special_Instructions__c"?: string;
  "Job_Instructions_Training_Documents__c"?: string;
  "Job_Schedule__c"?: string;
  "Jobsite__c"?: string;
  "Last_Modified_By__c"?: string;
  "Lead_Country__c"?: string;
  "Line_Total_Sales_Price__c"?: string;
  "Linux_Server_Software_Break_Fix__c"?: string;
  "Logged_in_Contact__c"?: string;
  "Logic_App_Run_ID__c"?: string;
  "Minimum_hours_you_require_for_NBD__c"?: number;
  "Minimum_hours_you_require_for_SBD__c"?: number;
  "Minimum_number_of_hours_you_for_NBD__c"?: string;
  "Minimum_number_of_hours_you_for_SBD__c"?: string;
  "Network_Equipment_Break_Fix__c"?: string;
  "OpsChain_Order_Created_Date__c"?: Date;
  "OpsChain_Order_Number__c"?: string;
  "Order_Confirmation__c"?: string;
  "Order_Sent_to_Lots__c"?: boolean;
  "Parent_Quote__c"?: string;
  "Parent_VCSQDLead__c"?: string;
  "Partner__c"?: string;
  "Partner_Case_Number__c"?: string;
  "Partner_Order_Notes__c"?: string;
  "Partner_PO_Number__c"?: string;
  "Partner_Pricelist_from_SOP_Auto__c"?: string;
  "Partner_Reference_Number__c"?: string;
  "Payment_Term__c"?: string;
  "PgMO_Milestone__c"?: string;
  "PgMO_Program__c"?: string;
  "PgMO_Project__c"?: string;
  "PgMO_Task__c"?: string;
  "JPC_Case__c"?: string;
  "PMS_Case__c"?: string;
  "Primary_SKU__c"?: string;
  "Project_SOP__c"?: string;
  "Program_Reference_Code__c"?: string;
  "Jobsite_Project__c"?: string;
  "Project_Profile__c"?: string;
  "Provide_Ware_housing_service_for_FRU__c"?: string;
  "Quote_Date__c"?: Date;
  "Quote_Expiration_Date__c"?: Date;
  "Quote_Manager_Version__c"?: string;
  "Quote_Name__c"?: string;
  "Quote_Notes_External__c"?: string;
  "Quote_Notes_Internal__c"?: string;
  "Quote_Number__c"?: number;
  "Quote_Select__c"?: boolean;
  "Quote_Status__c"?: string;
  "Quote_Total_Gross_Profit__c"?: string;
  "Quote_Total_Sale_Price__c"?: string;
  "Quote_Total_SalesPrice__c"?: number;
  "Quote_Version__c"?: string;
  "Rate_multiplier_for_ticket_After_Hours__c"?: number;
  "Rate_multiplier_for_ticket_Holidays__c"?: number;
  "Rate_multiplier_for_ticket_Weekend__c"?: number;
  "Region__c"?: string;
  "Reordtypeid__c"?: string;
  "Requested_Shipping_Date__c"?: Date;
  "Sales_Contact_Email__c"?: string;
  "Sales_Contact_Name__c"?: string;
  "Sales_Contact_Phone__c"?: string;
  "Schedule__c"?: string;
  "Schedule_Type_Weekly_Monthly__c"?: string;
  "Send_to_OpsChain__c"?: boolean;
  "Server_HW_Break_Fix__c"?: string;
  "Service_Contract__c"?: string;
  "Service_Contract_Job_Schedule_Count__c"?: number;
  "Service_Dispatch_Frequency__c"?: string;
  "Service_Engineer_Technical_Level__c"?: string;
  "Service_Engineer_Talent_Type__c"?: string;
  "Service_Location__c"?: string;
  "Service_Type__c"?: string;
  "Ship_Mode__c"?: string;
  "Ship_Partial__c"?: string;
  "Ship_To_Party__c"?: string;
  "Site_Survey__c"?: string;
  "Sold_To_Party__c"?: string;
  "Special_Terms__c"?: string;
  "Standard_Project_on_Program__c"?: boolean;
  "State__c"?: string;
  "Status__c"?: string;
  "Storage_Equipment_Break_Fix__c"?: string;
  "Street__c"?: string;
  "SubTotal_Discount__c"?: number;
  "SubTotal_Tax__c"?: number;
  "Tax_if_Billed_from_to__c"?: number;
  "Tax_if_Billed_from_to_USA__c"?: number;
  "Tax__c"?: number;
  "Tax_Amount__c"?: number;
  "Template_Description__c"?: string;
  "Template_Name__c"?: string;
  "Tentative_Shipping_Date__c"?: Date;
  "Term_months__c"?: number;
  "Total_Amount__c"?: number;
  "VAT_Payee__c"?: string;
  "VAT_payee_account__c"?: string;
  "Vendor_Has_Logged_in_Contact__c"?: boolean;
  "Vendor_Override__c"?: string;
  "Vendor_Status__c"?: string;
  "VOIP_Equipment_Break_Fix__c"?: string;
  "what_is_the_best_language_option__c"?: string;
  "Windows_Server_Software_Break_Fix__c"?: string;
  "Work_Description__c"?: string;
  "Special_Instructions__c"?: string;
  "Work_Order_End_Date_Time__c"?: Date;
  "Work_Order_Start_Date_Time__c"?: Date;
  "Work_Summary__c"?: string;
  "Zip_Postal_Code__c"?: string;
  "CurrencyIsoCode"?: string;
  "hasQuoteLineManagerRecords"?: boolean;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  appointment?: Appointment;
  account?: Account;
  contact?: Contact;
  customerContact?: Contact;
  deliverable?: Deliverable;
  endUser?: Account;
  geoMetro?: GeoMetro;
  importerIOR?: Account;
  Jobsites?: Jobsite[];
  lastModifiedBy?: Contact;
  loggedInContact?: Contact;
  partner?: Account;
  JPCCase?: Case;
  PMSCase?: Case;
  PrimarySKU?: Product;
  ProjectSOP?: Project;
  JobsiteProject?: Project;
  ProjectProfile?: Project;
  talentType?: TalentType;
  shipToParty?: Account;
  soldToParty?: Account;
  vendorOverride?: Account;
  countryCode?: CountryCode;
  asset?: Asset;
  milestone?: Milestone;
  program?: Program;
  project?: Project2;
  task?: Task;
  ServiceContract?: ServiceContract;
  parentVCSQDLead?: VCSQDLead;
}

export class QuoteManager implements QuoteManagerInterface {
  "sfdcId": string;
  "CreatedById": string;
  "LastModifiedById": string;
  "Name": string;
  "RecordTypeId": string;
  "Acknowledgement_Sent__c": string;
  "Actual_Shipping_Date__c": Date;
  "Appointment__c": string;
  "Asset_Serial_Number__c": string;
  "Automation_Status__c": string;
  "Billing_Country__c": string;
  "Billing_Type__c": string;
  "Bill_To_Party__c": string;
  "Carrier_Instructions__c": string;
  "Carrier_Scac__c": string;
  "City__c": string;
  "Committed_Shipping_Date__c": Date;
  "Copy_of_MSA__c": string;
  "Country__c": string;
  "Created_By__c": string;
  "Create_ICC_Case__c": boolean;
  "Currency_you_desire_to_do_transaction__c": string;
  "Current_Status__c": string;
  "Customer_Contact__c": string;
  "Customer_PO_Number__c": string;
  "Customs_Broker__c": string;
  "Decommission_Disposal__c": string;
  "Default_Quote__c": boolean;
  "Deliverable__c": string;
  "Delivery_Date__c": Date;
  "Description__c": string;
  "Discount_Amount__c": number;
  "Dispatch_SLA_Priority__c": string;
  "Do_you_have_legal_entity_in_USA__c": string;
  "Do_you_provide_EOR_Services__c": string;
  "Do_you_provide_FSE_in_Selected_Country__c": boolean;
  "Provide_FSE_in_Selected_Country__c": string;
  "Do_you_provide_FTE_in_Selected_Country__c": boolean;
  "Provide_FTE_in_Selected_Country__c": string;
  "Do_you_provide_ITAD_Services__c": string;
  "Do_you_provide_Next_business_day_SLA__c": boolean;
  "Do_you_provide_Next_businessday_SLA_NBD__c": boolean;
  "Do_you_provide_Same_business_day_SLASBD__c": string;
  "Do_you_provide_Same_business_day_SLA_SBD__c": boolean;
  "End_User__c": string;
  "EOR_Party__c": string;
  "EUC_DSS_Break_Fi_Fix__c": string;
  "Expected_Shipping_Date__c": Date;
  "Extended_Amount_Excl_Tax__c": number;
  "FTE_Configuration_Type__c": string;
  "GEO_Metro__c": string;
  "Have_legal_entity_in_Country_Selected__c": string;
  "Have_you_completed_all_the_steps_of_RFI__c": string;
  "Health_Appointment_status__c": string;
  "Health_Pricelist_Status__c": string;
  "Health_SOP_Profile_Status__c": string;
  "Highest_Level_of_Talent__c": string;
  "ICC_Case_Created__c": boolean;
  "Importer_IOR__c": string;
  "incoterm__c": string;
  "Instructions__c": string;
  "Insurance_Party__c": string;
  "International_Shipment__c": string;
  "Inventory_Pool__c": string;
  "IOR_Party__c": string;
  "Iron_Comments__c": string;
  "Iron_Comments_MSP__c": string;
  "Iron_Quote_Number__c": string;
  "Iron_Sales_Order__c": string;
  "Job_Instructions_Deliverables_Summary__c": string;
  "Job_Instructions_Required_Tools__c": string;
  "Job_Instructions_Service_Deliverables__c": string;
  "Job_Instructions_Special_Instructions__c": string;
  "Job_Instructions_Training_Documents__c": string;
  "Job_Schedule__c": string;
  "Jobsite__c": string;
  "Last_Modified_By__c": string;
  "Lead_Country__c": string;
  "Line_Total_Sales_Price__c": string;
  "Linux_Server_Software_Break_Fix__c": string;
  "Logged_in_Contact__c": string;
  "Logic_App_Run_ID__c": string;
  "Minimum_hours_you_require_for_NBD__c": number;
  "Minimum_hours_you_require_for_SBD__c": number;
  "Minimum_number_of_hours_you_for_NBD__c": string;
  "Minimum_number_of_hours_you_for_SBD__c": string;
  "Network_Equipment_Break_Fix__c": string;
  "OpsChain_Order_Created_Date__c": Date;
  "OpsChain_Order_Number__c": string;
  "Order_Confirmation__c": string;
  "Order_Sent_to_Lots__c": boolean;
  "Parent_Quote__c": string;
  "Parent_VCSQDLead__c": string;
  "Partner__c": string;
  "Partner_Case_Number__c": string;
  "Partner_Order_Notes__c": string;
  "Partner_PO_Number__c": string;
  "Partner_Pricelist_from_SOP_Auto__c": string;
  "Partner_Reference_Number__c": string;
  "Payment_Term__c": string;
  "PgMO_Milestone__c": string;
  "PgMO_Program__c": string;
  "PgMO_Project__c": string;
  "PgMO_Task__c": string;
  "JPC_Case__c": string;
  "PMS_Case__c": string;
  "Primary_SKU__c": string;
  "Project_SOP__c": string;
  "Program_Reference_Code__c": string;
  "Jobsite_Project__c": string;
  "Project_Profile__c": string;
  "Provide_Ware_housing_service_for_FRU__c": string;
  "Quote_Date__c": Date;
  "Quote_Expiration_Date__c": Date;
  "Quote_Manager_Version__c": string;
  "Quote_Name__c": string;
  "Quote_Notes_External__c": string;
  "Quote_Notes_Internal__c": string;
  "Quote_Number__c": number;
  "Quote_Select__c": boolean;
  "Quote_Status__c": string;
  "Quote_Total_Gross_Profit__c": string;
  "Quote_Total_Sale_Price__c": string;
  "Quote_Total_SalesPrice__c": number;
  "Quote_Version__c": string;
  "Rate_multiplier_for_ticket_After_Hours__c": number;
  "Rate_multiplier_for_ticket_Holidays__c": number;
  "Rate_multiplier_for_ticket_Weekend__c": number;
  "Region__c": string;
  "Reordtypeid__c": string;
  "Requested_Shipping_Date__c": Date;
  "Sales_Contact_Email__c": string;
  "Sales_Contact_Name__c": string;
  "Sales_Contact_Phone__c": string;
  "Schedule__c": string;
  "Schedule_Type_Weekly_Monthly__c": string;
  "Send_to_OpsChain__c": boolean;
  "Server_HW_Break_Fix__c": string;
  "Service_Contract__c": string;
  "Service_Contract_Job_Schedule_Count__c": number;
  "Service_Dispatch_Frequency__c": string;
  "Service_Engineer_Technical_Level__c": string;
  "Service_Engineer_Talent_Type__c": string;
  "Service_Location__c": string;
  "Service_Type__c": string;
  "Ship_Mode__c": string;
  "Ship_Partial__c": string;
  "Ship_To_Party__c": string;
  "Site_Survey__c": string;
  "Sold_To_Party__c": string;
  "Special_Terms__c": string;
  "Standard_Project_on_Program__c": boolean;
  "State__c": string;
  "Status__c": string;
  "Storage_Equipment_Break_Fix__c": string;
  "Street__c": string;
  "SubTotal_Discount__c": number;
  "SubTotal_Tax__c": number;
  "Tax_if_Billed_from_to__c": number;
  "Tax_if_Billed_from_to_USA__c": number;
  "Tax__c": number;
  "Tax_Amount__c": number;
  "Template_Description__c": string;
  "Template_Name__c": string;
  "Tentative_Shipping_Date__c": Date;
  "Term_months__c": number;
  "Total_Amount__c": number;
  "VAT_Payee__c": string;
  "VAT_payee_account__c": string;
  "Vendor_Has_Logged_in_Contact__c": boolean;
  "Vendor_Override__c": string;
  "Vendor_Status__c": string;
  "VOIP_Equipment_Break_Fix__c": string;
  "what_is_the_best_language_option__c": string;
  "Windows_Server_Software_Break_Fix__c": string;
  "Work_Description__c": string;
  "Special_Instructions__c": string;
  "Work_Order_End_Date_Time__c": Date;
  "Work_Order_Start_Date_Time__c": Date;
  "Work_Summary__c": string;
  "Zip_Postal_Code__c": string;
  "CurrencyIsoCode": string;
  "hasQuoteLineManagerRecords": boolean;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  appointment: Appointment;
  account: Account;
  contact: Contact;
  customerContact: Contact;
  deliverable: Deliverable;
  endUser: Account;
  geoMetro: GeoMetro;
  importerIOR: Account;
  Jobsites: Jobsite[];
  lastModifiedBy: Contact;
  loggedInContact: Contact;
  partner: Account;
  JPCCase: Case;
  PMSCase: Case;
  PrimarySKU: Product;
  ProjectSOP: Project;
  JobsiteProject: Project;
  ProjectProfile: Project;
  talentType: TalentType;
  shipToParty: Account;
  soldToParty: Account;
  vendorOverride: Account;
  countryCode: CountryCode;
  asset: Asset;
  milestone: Milestone;
  program: Program;
  project: Project2;
  task: Task;
  ServiceContract: ServiceContract;
  parentVCSQDLead: VCSQDLead;
  constructor(data?: QuoteManagerInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `QuoteManager`.
   */
  public static getModelName() {
    return "QuoteManager";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of QuoteManager for dynamic purposes.
  **/
  public static factory(data: QuoteManagerInterface): QuoteManager{
    return new QuoteManager(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'QuoteManager',
      plural: 'QuoteManagers',
      path: 'QuoteManagers',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "RecordTypeId": {
          name: 'RecordTypeId',
          type: 'string'
        },
        "Acknowledgement_Sent__c": {
          name: 'Acknowledgement_Sent__c',
          type: 'string'
        },
        "Actual_Shipping_Date__c": {
          name: 'Actual_Shipping_Date__c',
          type: 'Date'
        },
        "Appointment__c": {
          name: 'Appointment__c',
          type: 'string'
        },
        "Asset_Serial_Number__c": {
          name: 'Asset_Serial_Number__c',
          type: 'string'
        },
        "Automation_Status__c": {
          name: 'Automation_Status__c',
          type: 'string'
        },
        "Billing_Country__c": {
          name: 'Billing_Country__c',
          type: 'string'
        },
        "Billing_Type__c": {
          name: 'Billing_Type__c',
          type: 'string'
        },
        "Bill_To_Party__c": {
          name: 'Bill_To_Party__c',
          type: 'string'
        },
        "Carrier_Instructions__c": {
          name: 'Carrier_Instructions__c',
          type: 'string'
        },
        "Carrier_Scac__c": {
          name: 'Carrier_Scac__c',
          type: 'string'
        },
        "City__c": {
          name: 'City__c',
          type: 'string'
        },
        "Committed_Shipping_Date__c": {
          name: 'Committed_Shipping_Date__c',
          type: 'Date'
        },
        "Copy_of_MSA__c": {
          name: 'Copy_of_MSA__c',
          type: 'string'
        },
        "Country__c": {
          name: 'Country__c',
          type: 'string'
        },
        "Created_By__c": {
          name: 'Created_By__c',
          type: 'string'
        },
        "Create_ICC_Case__c": {
          name: 'Create_ICC_Case__c',
          type: 'boolean'
        },
        "Currency_you_desire_to_do_transaction__c": {
          name: 'Currency_you_desire_to_do_transaction__c',
          type: 'string'
        },
        "Current_Status__c": {
          name: 'Current_Status__c',
          type: 'string'
        },
        "Customer_Contact__c": {
          name: 'Customer_Contact__c',
          type: 'string'
        },
        "Customer_PO_Number__c": {
          name: 'Customer_PO_Number__c',
          type: 'string'
        },
        "Customs_Broker__c": {
          name: 'Customs_Broker__c',
          type: 'string'
        },
        "Decommission_Disposal__c": {
          name: 'Decommission_Disposal__c',
          type: 'string'
        },
        "Default_Quote__c": {
          name: 'Default_Quote__c',
          type: 'boolean'
        },
        "Deliverable__c": {
          name: 'Deliverable__c',
          type: 'string'
        },
        "Delivery_Date__c": {
          name: 'Delivery_Date__c',
          type: 'Date'
        },
        "Description__c": {
          name: 'Description__c',
          type: 'string'
        },
        "Discount_Amount__c": {
          name: 'Discount_Amount__c',
          type: 'number'
        },
        "Dispatch_SLA_Priority__c": {
          name: 'Dispatch_SLA_Priority__c',
          type: 'string'
        },
        "Do_you_have_legal_entity_in_USA__c": {
          name: 'Do_you_have_legal_entity_in_USA__c',
          type: 'string'
        },
        "Do_you_provide_EOR_Services__c": {
          name: 'Do_you_provide_EOR_Services__c',
          type: 'string'
        },
        "Do_you_provide_FSE_in_Selected_Country__c": {
          name: 'Do_you_provide_FSE_in_Selected_Country__c',
          type: 'boolean'
        },
        "Provide_FSE_in_Selected_Country__c": {
          name: 'Provide_FSE_in_Selected_Country__c',
          type: 'string'
        },
        "Do_you_provide_FTE_in_Selected_Country__c": {
          name: 'Do_you_provide_FTE_in_Selected_Country__c',
          type: 'boolean'
        },
        "Provide_FTE_in_Selected_Country__c": {
          name: 'Provide_FTE_in_Selected_Country__c',
          type: 'string'
        },
        "Do_you_provide_ITAD_Services__c": {
          name: 'Do_you_provide_ITAD_Services__c',
          type: 'string'
        },
        "Do_you_provide_Next_business_day_SLA__c": {
          name: 'Do_you_provide_Next_business_day_SLA__c',
          type: 'boolean'
        },
        "Do_you_provide_Next_businessday_SLA_NBD__c": {
          name: 'Do_you_provide_Next_businessday_SLA_NBD__c',
          type: 'boolean'
        },
        "Do_you_provide_Same_business_day_SLASBD__c": {
          name: 'Do_you_provide_Same_business_day_SLASBD__c',
          type: 'string'
        },
        "Do_you_provide_Same_business_day_SLA_SBD__c": {
          name: 'Do_you_provide_Same_business_day_SLA_SBD__c',
          type: 'boolean'
        },
        "End_User__c": {
          name: 'End_User__c',
          type: 'string'
        },
        "EOR_Party__c": {
          name: 'EOR_Party__c',
          type: 'string'
        },
        "EUC_DSS_Break_Fi_Fix__c": {
          name: 'EUC_DSS_Break_Fi_Fix__c',
          type: 'string'
        },
        "Expected_Shipping_Date__c": {
          name: 'Expected_Shipping_Date__c',
          type: 'Date'
        },
        "Extended_Amount_Excl_Tax__c": {
          name: 'Extended_Amount_Excl_Tax__c',
          type: 'number'
        },
        "FTE_Configuration_Type__c": {
          name: 'FTE_Configuration_Type__c',
          type: 'string'
        },
        "GEO_Metro__c": {
          name: 'GEO_Metro__c',
          type: 'string'
        },
        "Have_legal_entity_in_Country_Selected__c": {
          name: 'Have_legal_entity_in_Country_Selected__c',
          type: 'string'
        },
        "Have_you_completed_all_the_steps_of_RFI__c": {
          name: 'Have_you_completed_all_the_steps_of_RFI__c',
          type: 'string'
        },
        "Health_Appointment_status__c": {
          name: 'Health_Appointment_status__c',
          type: 'string'
        },
        "Health_Pricelist_Status__c": {
          name: 'Health_Pricelist_Status__c',
          type: 'string'
        },
        "Health_SOP_Profile_Status__c": {
          name: 'Health_SOP_Profile_Status__c',
          type: 'string'
        },
        "Highest_Level_of_Talent__c": {
          name: 'Highest_Level_of_Talent__c',
          type: 'string'
        },
        "ICC_Case_Created__c": {
          name: 'ICC_Case_Created__c',
          type: 'boolean'
        },
        "Importer_IOR__c": {
          name: 'Importer_IOR__c',
          type: 'string'
        },
        "incoterm__c": {
          name: 'incoterm__c',
          type: 'string'
        },
        "Instructions__c": {
          name: 'Instructions__c',
          type: 'string'
        },
        "Insurance_Party__c": {
          name: 'Insurance_Party__c',
          type: 'string'
        },
        "International_Shipment__c": {
          name: 'International_Shipment__c',
          type: 'string'
        },
        "Inventory_Pool__c": {
          name: 'Inventory_Pool__c',
          type: 'string'
        },
        "IOR_Party__c": {
          name: 'IOR_Party__c',
          type: 'string'
        },
        "Iron_Comments__c": {
          name: 'Iron_Comments__c',
          type: 'string'
        },
        "Iron_Comments_MSP__c": {
          name: 'Iron_Comments_MSP__c',
          type: 'string'
        },
        "Iron_Quote_Number__c": {
          name: 'Iron_Quote_Number__c',
          type: 'string'
        },
        "Iron_Sales_Order__c": {
          name: 'Iron_Sales_Order__c',
          type: 'string'
        },
        "Job_Instructions_Deliverables_Summary__c": {
          name: 'Job_Instructions_Deliverables_Summary__c',
          type: 'string'
        },
        "Job_Instructions_Required_Tools__c": {
          name: 'Job_Instructions_Required_Tools__c',
          type: 'string'
        },
        "Job_Instructions_Service_Deliverables__c": {
          name: 'Job_Instructions_Service_Deliverables__c',
          type: 'string'
        },
        "Job_Instructions_Special_Instructions__c": {
          name: 'Job_Instructions_Special_Instructions__c',
          type: 'string'
        },
        "Job_Instructions_Training_Documents__c": {
          name: 'Job_Instructions_Training_Documents__c',
          type: 'string'
        },
        "Job_Schedule__c": {
          name: 'Job_Schedule__c',
          type: 'string'
        },
        "Jobsite__c": {
          name: 'Jobsite__c',
          type: 'string'
        },
        "Last_Modified_By__c": {
          name: 'Last_Modified_By__c',
          type: 'string'
        },
        "Lead_Country__c": {
          name: 'Lead_Country__c',
          type: 'string'
        },
        "Line_Total_Sales_Price__c": {
          name: 'Line_Total_Sales_Price__c',
          type: 'string'
        },
        "Linux_Server_Software_Break_Fix__c": {
          name: 'Linux_Server_Software_Break_Fix__c',
          type: 'string'
        },
        "Logged_in_Contact__c": {
          name: 'Logged_in_Contact__c',
          type: 'string'
        },
        "Logic_App_Run_ID__c": {
          name: 'Logic_App_Run_ID__c',
          type: 'string'
        },
        "Minimum_hours_you_require_for_NBD__c": {
          name: 'Minimum_hours_you_require_for_NBD__c',
          type: 'number'
        },
        "Minimum_hours_you_require_for_SBD__c": {
          name: 'Minimum_hours_you_require_for_SBD__c',
          type: 'number'
        },
        "Minimum_number_of_hours_you_for_NBD__c": {
          name: 'Minimum_number_of_hours_you_for_NBD__c',
          type: 'string'
        },
        "Minimum_number_of_hours_you_for_SBD__c": {
          name: 'Minimum_number_of_hours_you_for_SBD__c',
          type: 'string'
        },
        "Network_Equipment_Break_Fix__c": {
          name: 'Network_Equipment_Break_Fix__c',
          type: 'string'
        },
        "OpsChain_Order_Created_Date__c": {
          name: 'OpsChain_Order_Created_Date__c',
          type: 'Date'
        },
        "OpsChain_Order_Number__c": {
          name: 'OpsChain_Order_Number__c',
          type: 'string'
        },
        "Order_Confirmation__c": {
          name: 'Order_Confirmation__c',
          type: 'string'
        },
        "Order_Sent_to_Lots__c": {
          name: 'Order_Sent_to_Lots__c',
          type: 'boolean'
        },
        "Parent_Quote__c": {
          name: 'Parent_Quote__c',
          type: 'string'
        },
        "Parent_VCSQDLead__c": {
          name: 'Parent_VCSQDLead__c',
          type: 'string'
        },
        "Partner__c": {
          name: 'Partner__c',
          type: 'string'
        },
        "Partner_Case_Number__c": {
          name: 'Partner_Case_Number__c',
          type: 'string'
        },
        "Partner_Order_Notes__c": {
          name: 'Partner_Order_Notes__c',
          type: 'string'
        },
        "Partner_PO_Number__c": {
          name: 'Partner_PO_Number__c',
          type: 'string'
        },
        "Partner_Pricelist_from_SOP_Auto__c": {
          name: 'Partner_Pricelist_from_SOP_Auto__c',
          type: 'string'
        },
        "Partner_Reference_Number__c": {
          name: 'Partner_Reference_Number__c',
          type: 'string'
        },
        "Payment_Term__c": {
          name: 'Payment_Term__c',
          type: 'string'
        },
        "PgMO_Milestone__c": {
          name: 'PgMO_Milestone__c',
          type: 'string'
        },
        "PgMO_Program__c": {
          name: 'PgMO_Program__c',
          type: 'string'
        },
        "PgMO_Project__c": {
          name: 'PgMO_Project__c',
          type: 'string'
        },
        "PgMO_Task__c": {
          name: 'PgMO_Task__c',
          type: 'string'
        },
        "JPC_Case__c": {
          name: 'JPC_Case__c',
          type: 'string'
        },
        "PMS_Case__c": {
          name: 'PMS_Case__c',
          type: 'string'
        },
        "Primary_SKU__c": {
          name: 'Primary_SKU__c',
          type: 'string'
        },
        "Project_SOP__c": {
          name: 'Project_SOP__c',
          type: 'string'
        },
        "Program_Reference_Code__c": {
          name: 'Program_Reference_Code__c',
          type: 'string'
        },
        "Jobsite_Project__c": {
          name: 'Jobsite_Project__c',
          type: 'string'
        },
        "Project_Profile__c": {
          name: 'Project_Profile__c',
          type: 'string'
        },
        "Provide_Ware_housing_service_for_FRU__c": {
          name: 'Provide_Ware_housing_service_for_FRU__c',
          type: 'string'
        },
        "Quote_Date__c": {
          name: 'Quote_Date__c',
          type: 'Date'
        },
        "Quote_Expiration_Date__c": {
          name: 'Quote_Expiration_Date__c',
          type: 'Date'
        },
        "Quote_Manager_Version__c": {
          name: 'Quote_Manager_Version__c',
          type: 'string'
        },
        "Quote_Name__c": {
          name: 'Quote_Name__c',
          type: 'string'
        },
        "Quote_Notes_External__c": {
          name: 'Quote_Notes_External__c',
          type: 'string'
        },
        "Quote_Notes_Internal__c": {
          name: 'Quote_Notes_Internal__c',
          type: 'string'
        },
        "Quote_Number__c": {
          name: 'Quote_Number__c',
          type: 'number'
        },
        "Quote_Select__c": {
          name: 'Quote_Select__c',
          type: 'boolean'
        },
        "Quote_Status__c": {
          name: 'Quote_Status__c',
          type: 'string'
        },
        "Quote_Total_Gross_Profit__c": {
          name: 'Quote_Total_Gross_Profit__c',
          type: 'string'
        },
        "Quote_Total_Sale_Price__c": {
          name: 'Quote_Total_Sale_Price__c',
          type: 'string'
        },
        "Quote_Total_SalesPrice__c": {
          name: 'Quote_Total_SalesPrice__c',
          type: 'number'
        },
        "Quote_Version__c": {
          name: 'Quote_Version__c',
          type: 'string'
        },
        "Rate_multiplier_for_ticket_After_Hours__c": {
          name: 'Rate_multiplier_for_ticket_After_Hours__c',
          type: 'number'
        },
        "Rate_multiplier_for_ticket_Holidays__c": {
          name: 'Rate_multiplier_for_ticket_Holidays__c',
          type: 'number'
        },
        "Rate_multiplier_for_ticket_Weekend__c": {
          name: 'Rate_multiplier_for_ticket_Weekend__c',
          type: 'number'
        },
        "Region__c": {
          name: 'Region__c',
          type: 'string'
        },
        "Reordtypeid__c": {
          name: 'Reordtypeid__c',
          type: 'string'
        },
        "Requested_Shipping_Date__c": {
          name: 'Requested_Shipping_Date__c',
          type: 'Date'
        },
        "Sales_Contact_Email__c": {
          name: 'Sales_Contact_Email__c',
          type: 'string'
        },
        "Sales_Contact_Name__c": {
          name: 'Sales_Contact_Name__c',
          type: 'string'
        },
        "Sales_Contact_Phone__c": {
          name: 'Sales_Contact_Phone__c',
          type: 'string'
        },
        "Schedule__c": {
          name: 'Schedule__c',
          type: 'string'
        },
        "Schedule_Type_Weekly_Monthly__c": {
          name: 'Schedule_Type_Weekly_Monthly__c',
          type: 'string'
        },
        "Send_to_OpsChain__c": {
          name: 'Send_to_OpsChain__c',
          type: 'boolean'
        },
        "Server_HW_Break_Fix__c": {
          name: 'Server_HW_Break_Fix__c',
          type: 'string'
        },
        "Service_Contract__c": {
          name: 'Service_Contract__c',
          type: 'string'
        },
        "Service_Contract_Job_Schedule_Count__c": {
          name: 'Service_Contract_Job_Schedule_Count__c',
          type: 'number'
        },
        "Service_Dispatch_Frequency__c": {
          name: 'Service_Dispatch_Frequency__c',
          type: 'string'
        },
        "Service_Engineer_Technical_Level__c": {
          name: 'Service_Engineer_Technical_Level__c',
          type: 'string'
        },
        "Service_Engineer_Talent_Type__c": {
          name: 'Service_Engineer_Talent_Type__c',
          type: 'string'
        },
        "Service_Location__c": {
          name: 'Service_Location__c',
          type: 'string'
        },
        "Service_Type__c": {
          name: 'Service_Type__c',
          type: 'string'
        },
        "Ship_Mode__c": {
          name: 'Ship_Mode__c',
          type: 'string'
        },
        "Ship_Partial__c": {
          name: 'Ship_Partial__c',
          type: 'string'
        },
        "Ship_To_Party__c": {
          name: 'Ship_To_Party__c',
          type: 'string'
        },
        "Site_Survey__c": {
          name: 'Site_Survey__c',
          type: 'string'
        },
        "Sold_To_Party__c": {
          name: 'Sold_To_Party__c',
          type: 'string'
        },
        "Special_Terms__c": {
          name: 'Special_Terms__c',
          type: 'string'
        },
        "Standard_Project_on_Program__c": {
          name: 'Standard_Project_on_Program__c',
          type: 'boolean'
        },
        "State__c": {
          name: 'State__c',
          type: 'string'
        },
        "Status__c": {
          name: 'Status__c',
          type: 'string'
        },
        "Storage_Equipment_Break_Fix__c": {
          name: 'Storage_Equipment_Break_Fix__c',
          type: 'string'
        },
        "Street__c": {
          name: 'Street__c',
          type: 'string'
        },
        "SubTotal_Discount__c": {
          name: 'SubTotal_Discount__c',
          type: 'number'
        },
        "SubTotal_Tax__c": {
          name: 'SubTotal_Tax__c',
          type: 'number'
        },
        "Tax_if_Billed_from_to__c": {
          name: 'Tax_if_Billed_from_to__c',
          type: 'number'
        },
        "Tax_if_Billed_from_to_USA__c": {
          name: 'Tax_if_Billed_from_to_USA__c',
          type: 'number'
        },
        "Tax__c": {
          name: 'Tax__c',
          type: 'number'
        },
        "Tax_Amount__c": {
          name: 'Tax_Amount__c',
          type: 'number'
        },
        "Template_Description__c": {
          name: 'Template_Description__c',
          type: 'string'
        },
        "Template_Name__c": {
          name: 'Template_Name__c',
          type: 'string'
        },
        "Tentative_Shipping_Date__c": {
          name: 'Tentative_Shipping_Date__c',
          type: 'Date'
        },
        "Term_months__c": {
          name: 'Term_months__c',
          type: 'number'
        },
        "Total_Amount__c": {
          name: 'Total_Amount__c',
          type: 'number'
        },
        "VAT_Payee__c": {
          name: 'VAT_Payee__c',
          type: 'string'
        },
        "VAT_payee_account__c": {
          name: 'VAT_payee_account__c',
          type: 'string'
        },
        "Vendor_Has_Logged_in_Contact__c": {
          name: 'Vendor_Has_Logged_in_Contact__c',
          type: 'boolean'
        },
        "Vendor_Override__c": {
          name: 'Vendor_Override__c',
          type: 'string'
        },
        "Vendor_Status__c": {
          name: 'Vendor_Status__c',
          type: 'string'
        },
        "VOIP_Equipment_Break_Fix__c": {
          name: 'VOIP_Equipment_Break_Fix__c',
          type: 'string'
        },
        "what_is_the_best_language_option__c": {
          name: 'what_is_the_best_language_option__c',
          type: 'string'
        },
        "Windows_Server_Software_Break_Fix__c": {
          name: 'Windows_Server_Software_Break_Fix__c',
          type: 'string'
        },
        "Work_Description__c": {
          name: 'Work_Description__c',
          type: 'string'
        },
        "Special_Instructions__c": {
          name: 'Special_Instructions__c',
          type: 'string'
        },
        "Work_Order_End_Date_Time__c": {
          name: 'Work_Order_End_Date_Time__c',
          type: 'Date'
        },
        "Work_Order_Start_Date_Time__c": {
          name: 'Work_Order_Start_Date_Time__c',
          type: 'Date'
        },
        "Work_Summary__c": {
          name: 'Work_Summary__c',
          type: 'string'
        },
        "Zip_Postal_Code__c": {
          name: 'Zip_Postal_Code__c',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "hasQuoteLineManagerRecords": {
          name: 'hasQuoteLineManagerRecords',
          type: 'boolean'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        appointment: {
          name: 'appointment',
          type: 'Appointment',
          model: 'Appointment',
          relationType: 'belongsTo',
                  keyFrom: 'Appointment__c',
          keyTo: 'sfdcId'
        },
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'Bill_To_Party__c',
          keyTo: 'sfdcId'
        },
        contact: {
          name: 'contact',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Created_By__c',
          keyTo: 'sfdcId'
        },
        customerContact: {
          name: 'customerContact',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Customer_Contact__c',
          keyTo: 'sfdcId'
        },
        deliverable: {
          name: 'deliverable',
          type: 'Deliverable',
          model: 'Deliverable',
          relationType: 'belongsTo',
                  keyFrom: 'Deliverable__c',
          keyTo: 'sfdcId'
        },
        endUser: {
          name: 'endUser',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'End_User__c',
          keyTo: 'sfdcId'
        },
        geoMetro: {
          name: 'geoMetro',
          type: 'GeoMetro',
          model: 'GeoMetro',
          relationType: 'belongsTo',
                  keyFrom: 'GEO_Metro__c',
          keyTo: 'sfdcId'
        },
        importerIOR: {
          name: 'importerIOR',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'Importer_IOR__c',
          keyTo: 'sfdcId'
        },
        Jobsites: {
          name: 'Jobsites',
          type: 'Jobsite[]',
          model: 'Jobsite',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'Jobsite__c'
        },
        lastModifiedBy: {
          name: 'lastModifiedBy',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Last_Modified_By__c',
          keyTo: 'sfdcId'
        },
        loggedInContact: {
          name: 'loggedInContact',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Logged_in_Contact__c',
          keyTo: 'sfdcId'
        },
        partner: {
          name: 'partner',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'Partner__c',
          keyTo: 'sfdcId'
        },
        JPCCase: {
          name: 'JPCCase',
          type: 'Case',
          model: 'Case',
          relationType: 'belongsTo',
                  keyFrom: 'JPC_Case__c',
          keyTo: 'sfdcId'
        },
        PMSCase: {
          name: 'PMSCase',
          type: 'Case',
          model: 'Case',
          relationType: 'belongsTo',
                  keyFrom: 'PMS_Case__c',
          keyTo: 'sfdcId'
        },
        PrimarySKU: {
          name: 'PrimarySKU',
          type: 'Product',
          model: 'Product',
          relationType: 'belongsTo',
                  keyFrom: 'Primary_SKU__c',
          keyTo: 'sfdcId'
        },
        ProjectSOP: {
          name: 'ProjectSOP',
          type: 'Project',
          model: 'Project',
          relationType: 'belongsTo',
                  keyFrom: 'Project_SOP__c',
          keyTo: 'sfdcId'
        },
        JobsiteProject: {
          name: 'JobsiteProject',
          type: 'Project',
          model: 'Project',
          relationType: 'belongsTo',
                  keyFrom: 'Jobsite_Project__c',
          keyTo: 'sfdcId'
        },
        ProjectProfile: {
          name: 'ProjectProfile',
          type: 'Project',
          model: 'Project',
          relationType: 'belongsTo',
                  keyFrom: 'Project_Profile__c',
          keyTo: 'sfdcId'
        },
        talentType: {
          name: 'talentType',
          type: 'TalentType',
          model: 'TalentType',
          relationType: 'belongsTo',
                  keyFrom: 'Service_Engineer_Talent_Type__c',
          keyTo: 'sfdcId'
        },
        shipToParty: {
          name: 'shipToParty',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'Ship_To_Party__c',
          keyTo: 'sfdcId'
        },
        soldToParty: {
          name: 'soldToParty',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'Sold_To_Party__c',
          keyTo: 'sfdcId'
        },
        vendorOverride: {
          name: 'vendorOverride',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'Vendor_Override__c',
          keyTo: 'sfdcId'
        },
        countryCode: {
          name: 'countryCode',
          type: 'CountryCode',
          model: 'CountryCode',
          relationType: 'belongsTo',
                  keyFrom: 'Lead_Country__c',
          keyTo: 'Country__c'
        },
        asset: {
          name: 'asset',
          type: 'Asset',
          model: 'Asset',
          relationType: 'belongsTo',
                  keyFrom: 'Asset_Serial_Number__c',
          keyTo: 'sfdcId'
        },
        milestone: {
          name: 'milestone',
          type: 'Milestone',
          model: 'Milestone',
          relationType: 'belongsTo',
                  keyFrom: 'PgMO_Milestone__c',
          keyTo: 'sfdcId'
        },
        program: {
          name: 'program',
          type: 'Program',
          model: 'Program',
          relationType: 'belongsTo',
                  keyFrom: 'PgMO_Program__c',
          keyTo: 'sfdcId'
        },
        project: {
          name: 'project',
          type: 'Project2',
          model: 'Project2',
          relationType: 'belongsTo',
                  keyFrom: 'PgMO_Project__c',
          keyTo: 'sfdcId'
        },
        task: {
          name: 'task',
          type: 'Task',
          model: 'Task',
          relationType: 'belongsTo',
                  keyFrom: 'PgMO_Task__c',
          keyTo: 'sfdcId'
        },
        ServiceContract: {
          name: 'ServiceContract',
          type: 'ServiceContract',
          model: 'ServiceContract',
          relationType: 'belongsTo',
                  keyFrom: 'Service_Contract__c',
          keyTo: 'sfdcId'
        },
        parentVCSQDLead: {
          name: 'parentVCSQDLead',
          type: 'VCSQDLead',
          model: 'VCSQDLead',
          relationType: 'belongsTo',
                  keyFrom: 'Parent_VCSQDLead__c',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
