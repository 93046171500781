import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as StaticData from 'shared/models/static-list-data.service';
import { DualListComponent } from 'angular-dual-listbox';

@Component({
    selector: 'app-static-data-lookup',
    templateUrl: './static-data-lookup.component.html',
    styleUrls: ['./static-data-lookup.component.css']
})
export class StaticDataLookupComponent implements OnInit {

    @Input() lookupTitle = null;
    @Input() placeholder = null;
    @Input() dataListName = null;
    @Input() globalFilterKey = null;
    @Input() isMultipleAllowed = false;
    @Input() bindLabel = 'label';
    @Input() bindValue = 'value';
    @Input() closeOnSelect = true;
    @Input() hideAlreadySelected = false;
    @Input() dropdownPosition = 'below';
    @Input() searchable = false;
    @Input() set resetData(isClear) {
        this.onClear(isClear);
    }
    @Input() isDualListType = false;
    @Input() clearable = false;
    @Output() selectedValue: EventEmitter<any> = new EventEmitter;
    dataList: any = [];
    @Input() selectedItems: any = [];
    format = {
        add: 'Add', remove: 'Remove', direction: DualListComponent.LTR, draggable: true, locale: 'undefined'
    }
    @Input() set preSelectedValue(value: string) {
        if (typeof value !== 'undefined') {
            this.selectedItems = value;
        }
    }
    @Input() isRadioButtonType = false;
    @Input() disabled = false;
    @Input() isStaticData = true;
    @Input() dataSource: Array<any>;
    @Input() boxedLayout = false;

    constructor() {
    }

    ngOnInit() {
        //For Dual list preselect option we have to set the datalist without having the selected items in the list
        const allListData = this.dataSource && this.dataSource.length ?
            this.dataSource : this.isStaticData ?
                [...StaticData[this.dataListName]] : this.dataSource;
        if (this.isDualListType && this.selectedItems && this.selectedItems.length > 0) {
            this.removeDuplicates(allListData, this.selectedItems);
        }
        if (allListData) {
            this.dataList = [...allListData];
        }
        // to find and show value that is already present in global filter related to this lookup
        if (this.globalFilterKey) {
            const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
            this.selectedItems = (preselected && preselected[this.globalFilterKey]) ? preselected[this.globalFilterKey] : null;
        }
    }

    //removing duplicates from source of the Dual list as per destination
    removeDuplicates(a, b) {
        var i = a.length;
        while (i--) {
            for (var j of b) {
                if (a[i] && a[i].value === j.value) {
                    a.splice(i, 1);
                }
            }
        }
    }

    /**
     * This function executes whenever there is a change in selected values.
     * This will emit the changed array.
    */
    onChange(values?) {
        if (this.isDualListType) {
            this.selectedValue.emit(values);
        } else if (this.isRadioButtonType) {
            const checkedOptionValue = values && values.target && values.target.value;
            this.selectedValue.emit(checkedOptionValue);
        } else {
            this.selectedValue.emit(this.selectedItems);
        }
    }

    /**
     * This function executes whenever the select input is cleared.
     * This will clear the array and emit an empty array.
    */
    onClear(isClear) {
        this.selectedItems = [];
        if (isClear) {
            this.selectedValue.emit([]);
        }
    }
}
