import { Component, OnInit, Input } from '@angular/core';
import { CaseApi } from 'shared/sdk';
import { PreloaderService } from 'shared/services/preloader.service';
import { AppStateService } from 'shared/services/app-state.service';
import { ModalService } from 'shared/services/modal.service';

@Component({
  selector: 'app-receipt-tab',
  templateUrl: './receipt-tab.component.html',
  styleUrls: ['./receipt-tab.component.css']
})
export class ReceiptTabComponent implements OnInit {

  @Input() set caseData(e) {
    if (e) {
        this._caseData = e;
        this._caseId = e.pmsCaseSfdcId;
        this.getCaseDetails();
    }
  }
  _caseData : any;
  _caseId : any;
  result: any;
  name: any;
  jobsiteContactData: any = {};
  receiptTabFields = true;
  accessType: any;
  quantity: any;
  constructor(
    private _preloaderService: PreloaderService,
    private _appState: AppStateService,
    private _caseApi: CaseApi,
    private _modalService: ModalService,
  ) {}

  ngOnInit() {
    this.accessType = this._appState.getAccessType();
    // To check if view as PMS mode is activated.
    if (localStorage.getItem('viewAsInfo')) {
      const viewAsInfo = JSON.parse(localStorage.getItem('viewAsInfo'));
      const viewAsPartner = (viewAsInfo && viewAsInfo.viewAsType === 'Partner');
      this.accessType = (viewAsPartner) ? 'partner' : this.accessType;
    }
  }
  getCaseDetails() {
    this._preloaderService.showPreloader();
    this._caseApi.findOne({
        where: { sfdcId: this._caseId },
        fields: ['id', 'sfdcId','CaseNumber','Case_Reference_Number__c','Dispatch_SLA_Priority__c',
        'Coverage_Hours__c','Asset_Name__c','Partner_Account__c', 'Project_SOP__c',
        'Customer_Service_Type__c','Duration_of_Service_Call_in_Hours__c',
        'Asset_Serial_Number__c','Asset_Serial_Number_Label__c','Asset__c','Job_Order_Item__c',
        'RecordTypeId','SKU__c','Project__c','AccountId','Job_Order__c','Jobsite__c','Subject','Talent_Type_ref__c',
        'Service_Technical_Level__c','Custom_Service_Type__c','Case_Record_Type_Id__c','Record_Id__c',
        'ParentId','PPE_Hours__c'],
        include: [{
            relation: 'Account',
            scope: {
                fields: ['sfdcId', 'Name']
            }
        },
        {
          relation: 'ICC_Case',
          scope: {
              fields: ['sfdcId', 'CaseNumber']
          }
        },
        {
          relation: 'project',
          scope: {
            fields: ['Project__c','Asset_Tag_Number__c','Asset_Serial_Number__c','Name',
            'Project_Standard__c'],
            include : [{
              relation: 'jobsites',
              scope: [{
                fields :['sfdcId','Name']
              }]
            }, {
              relation: 'JobsiteProjectes',
              scope: [{
                fields : ['sfdcId', 'Project__c', 'Name']
              }]
            }]
          }
        },
        {
          relation: 'talentType',
          scope: {
            fields: ['Talent_Type_Name__c']
          }
        },
        {
          relation: 'jobOrderItem',
          scope: {
            fields: ['Rack__c','Rack_Side__c','Rack_Serial__c','Row__c']
          }
        },
        {
            relation: 'program',
            scope: {
                fields: ['sfdcId','ProgramName__c', 'Name','Master_Project__c'],
            }
        },
        {
            relation: 'Jobsite',
            scope: {
                fields: ['Jobsite_ID__c', 'Name','Jobsite_Status__c','Jobsite_Approval_Status__c',
                'Country__c','State__c','City__c','Street__c','sfdcId','Zip__c','Time_Zone__c',
                'Type__c','Jobsite_Key_Contact__c','Is_Monday_Holiday__c','Is_Tuesday_Holiday__c',
                'Is_Wednesday_Holiday__c','Is_Thursday_Holiday__c','Is_Friday_Holiday__c','Is_Saturday_Holiday__c',
                'Is_Sunday_Holiday__c','Monday_start_Time__c','Tuesday_start_Time__c','Wednesday_start_Time__c',
                'Thursday_start_Time__c','Friday_start_Time__c','Saturday_Start_Time__c','Sunday_Start_Time__c',
                'Monday_End_Time__c','Tuesday_End_Time__c','Wednesday_End_Time__c','Thursday_End_Time__c',
                'Friday_End_Time__c','Saturday_End_Time__c','Sunday_End_Time__c'],
                include: [{
                  relation: 'jobsiteContacts',
                  scope: {
                    fields: ['Jobsite_Contact_Type__c','Contact__c','Email'],
                    include: [{
                      relation: 'contact',
                      scope:{
                        fields: ['Contact_Name__c','Email','Phone']
                      }
                      
                    }]
                  }
                },
                {
                  relation: 'jobsiteKeyContact',
                  scope: {
                    fields: ['Name','Email','Contact_Name__c','Phone']
                  }
                }]
            }
        },
        {
          relation: 'RecordType',
          scope: {
            fields: ['Name','Description','SobjectType']
          }
        },
        {
            relation: 'workOrder',
            scope: {
                fields: ['sfdcId','Request_Form_Receipt__c','Name','Job_Order__c','Case_Event_Priority_Level__c'],
                include: [{
                  relation: 'jobOrderItems',
                  scope: {
                    fields: ['sfdcId', 'SKU__c', 'Order_Quantity__c'],
                    include:[{
                      relation: 'Product',
                      scope: { fields: ['Name']}
                    }]
                  }
                },
                {
                  relation: 'RequestFormReceipt',
                  scope: {
                    fields: ['Account_Name__c','Name','Request_Form_Receipt__c', 'sfdcId'],
                    include:[{
                      relation: 'ironCustomField',
                      scope: {
                        fields: ['Name','Priority_Facebook_Priority__c', 'Partner_Case_Log_Available_Facebook_IML__c', 'Partner_Case_Log_Available_Facebook_AHS__c' , 'B2B_Original_Message__c']
                      }
                    }]
                  }
                  
                  
                }
              ]
            }
        }]
    }).subscribe( 
        res => {
            if(res) {
                this.result = res;
                this._preloaderService.hidePreloader();
                if (this.result.workOrder && this.result.workOrder.jobOrderItems && this.result.workOrder.jobOrderItems.length) {
                  this.quantity = this.result.workOrder.jobOrderItems[0].Order_Quantity__c;
                }
                if(this.result.workOrder && this.result.workOrder.jobOrderItems && this.result.workOrder.jobOrderItems.length && this.result.workOrder.jobOrderItems[0].Product && this.result.workOrder.jobOrderItems[0].Product.Name) {
                  this.name = this.result.workOrder.jobOrderItems[0].Product.Name;
                }
                this.prepareJobsiteContactData(this.result);
            }
        }, err => {
            console.log(err);
            this._preloaderService.hidePreloader();
        });
  }

  onHeaderButtonClick(button, modal, size) {
    if (button === 'Jobsite') {
      this._modalService.open(modal, size);
    } else {
      this._modalService.open(modal, size);
    }
  }

  prepareJobsiteContactData(data) {
    this.jobsiteContactData.case = {
        sfdcId: data.sfdcId,
        CaseNumber: data.CaseNumber
        
    };
    this.jobsiteContactData.Account = {};
    if(data.Account) {
      this.jobsiteContactData.Account = {
        Name: data.Account.Name
      }
    }
    this.jobsiteContactData.Jobsite = {};
    if (data.Jobsite) {
        this.jobsiteContactData.Jobsite = {
            sfdcId: data.Jobsite.sfdcId,
            Name: data.Jobsite.Name,
            Street__c: data.Jobsite.Street__c,
            City__c: data.Jobsite.City__c,
            State__c: data.Jobsite.State__c,
            Country__c: data.Jobsite.Country__c,
            Zip__c: data.Jobsite.Zip__c,
            Time_Zone__c: data.Jobsite.Time_Zone__c,
            Jobsite_ID__c: data.Jobsite.Jobsite_ID__c,
            Type__c: data.Jobsite.Type__c,
            Jobsite_Approval_Status__c: data.Jobsite.Jobsite_Approval_Status__c,
            Jobsite_Status__c: data.Jobsite.Jobsite_Status__c,
            Is_Monday_Holiday__c: data.Jobsite.Is_Monday_Holiday__c,
            Is_Tuesday_Holiday__c: data.Jobsite.Is_Tuesday_Holiday__c,
            Is_Wednesday_Holiday__c: data.Jobsite.Is_Wednesday_Holiday__c,
            Is_Thursday_Holiday__c: data.Jobsite.Is_Thursday_Holiday__c,
            Is_Friday_Holiday__c: data.Jobsite.Is_Friday_Holiday__c,
            Is_Saturday_Holiday__c: data.Jobsite.Is_Saturday_Holiday__c,
            Is_Sunday_Holiday__c: data.Jobsite.Is_Sunday_Holiday__c,
            Monday_start_Time__c: data.Jobsite.Monday_start_Time__c,
            Tuesday_start_Time__c: data.Jobsite.Tuesday_start_Time__c,
            Wednesday_start_Time__c: data.Jobsite.Wednesday_start_Time__c,
            Thursday_start_Time__c: data.Jobsite.Tuesday_start_Time__c,
            Friday_start_Time__c: data.Jobsite.Friday_start_Time__c,
            Saturday_Start_Time__c: data.Jobsite.Saturday_Start_Time__c,
            Sunday_Start_Time__c: data.Jobsite.Sunday_Start_Time__c,
            Monday_End_Time__c: data.Jobsite.Monday_End_Time__c,
            Tuesday_End_Time__c: data.Jobsite.Tuesday_End_Time__c,
            Wednesday_End_Time__c: data.Jobsite.Wednesday_End_Time__c,
            Thursday_End_Time__c: data.Jobsite.Thursday_End_Time__c,
            Friday_End_Time__c: data.Jobsite.Friday_End_Time__c,
            Saturday_End_Time__c: data.Jobsite.Saturday_End_Time__c,
            Sunday_End_Time__c: data.Jobsite.Saturday_End_Time__c,
        }
    }
    this.jobsiteContactData.jobsiteKeyContacts = {};
    if(data.Jobsite && data.Jobsite.jobsiteKeyContact) {
      this.jobsiteContactData.jobsiteKeyContacts = {
        Name: data.Jobsite.jobsiteKeyContact.Name,
        Phone: data.Jobsite.jobsiteKeyContact.Phone,
        Email: data.Jobsite.jobsiteKeyContact.Email,
        Contact_Name__c: data.Jobsite.jobsiteKeyContact.Contact_Name__c
      }
    };
    this.jobsiteContactData.jobsiteContacts = [];
    if (data.Jobsite && data.Jobsite.jobsiteContacts && data.Jobsite.jobsiteContacts.length) {
        data.Jobsite.jobsiteContacts.forEach(item => {
            if (item.contact) {
                item.contact.Jobsite_Contact_Type__c = item.Jobsite_Contact_Type__c;
                this.jobsiteContactData.jobsiteContacts.push(item.contact);
            }
        });
    }
    this.jobsiteContactData.ICC_Case = {};
    if (data.ICC_Case && data.ICC_Case.length) {
        this.jobsiteContactData.ICC_Case = {
            sfdcId: data.ICC_Case[0].sfdcId,
            CaseNumber: data.ICC_Case[0].CaseNumber
        }
    }
    this.jobsiteContactData.order = {};
    if (data.order) {
        this.jobsiteContactData.order = {
            sfdcId: data.order.sfdcId,
            OrderNumber: data.order.OrderNumber
        }
    }
    this.jobsiteContactData.purchaseOrder = {};
    if (data.purchaseOrder) {
        this.jobsiteContactData.purchaseOrder = {
            sfdcId: data.purchaseOrder.sfdcId,
            WorkOrderNumber: data.purchaseOrder.WorkOrderNumber
        }
    }
    this.jobsiteContactData.Job = {};
    if (data.Job) {
        this.jobsiteContactData.Job = {
            id: data.Job.id,
            sfdcId: data.Job.sfdcId,
            Iron_Job_num__c: data.Job.Iron_Job_num__c
        };
    }
    this.jobsiteContactData.workOrder = {};
    if (data.workOrder) {
        this.jobsiteContactData.workOrder = data.workOrder;
    }
}
}
