/* tslint:disable */

declare var Object: any;
export interface StandardQuestionInterface {
  "sfdcId"?: string;
  "CreatedById"?: string;
  "LastModifiedById"?: string;
  "OwnerId"?: string;
  "CurrencyIsoCode"?: string;
  "Name"?: string;
  "ts2__ContactField__c"?: string;
  "Education_Time_c__c"?: number;
  "Education_Years__c"?: number;
  "Employed__c"?: string;
  "ts2__Large_Question__c"?: string;
  "ts2__Question__c"?: string;
  "ts2__Question_Layout__c"?: string;
  "ts2__Question_Size__c"?: string;
  "Reason_For_switch__c"?: string;
  "ts2__Required_By_Applicant__c"?: boolean;
  "ts2__Rich_Text_Question__c"?: string;
  "ts2__TempDate__c"?: Date;
  "ts2__TempDateTime__c"?: Date;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class StandardQuestion implements StandardQuestionInterface {
  "sfdcId": string;
  "CreatedById": string;
  "LastModifiedById": string;
  "OwnerId": string;
  "CurrencyIsoCode": string;
  "Name": string;
  "ts2__ContactField__c": string;
  "Education_Time_c__c": number;
  "Education_Years__c": number;
  "Employed__c": string;
  "ts2__Large_Question__c": string;
  "ts2__Question__c": string;
  "ts2__Question_Layout__c": string;
  "ts2__Question_Size__c": string;
  "Reason_For_switch__c": string;
  "ts2__Required_By_Applicant__c": boolean;
  "ts2__Rich_Text_Question__c": string;
  "ts2__TempDate__c": Date;
  "ts2__TempDateTime__c": Date;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: StandardQuestionInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `StandardQuestion`.
   */
  public static getModelName() {
    return "StandardQuestion";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of StandardQuestion for dynamic purposes.
  **/
  public static factory(data: StandardQuestionInterface): StandardQuestion{
    return new StandardQuestion(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'StandardQuestion',
      plural: 'StandardQuestions',
      path: 'StandardQuestions',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "ts2__ContactField__c": {
          name: 'ts2__ContactField__c',
          type: 'string'
        },
        "Education_Time_c__c": {
          name: 'Education_Time_c__c',
          type: 'number'
        },
        "Education_Years__c": {
          name: 'Education_Years__c',
          type: 'number'
        },
        "Employed__c": {
          name: 'Employed__c',
          type: 'string'
        },
        "ts2__Large_Question__c": {
          name: 'ts2__Large_Question__c',
          type: 'string'
        },
        "ts2__Question__c": {
          name: 'ts2__Question__c',
          type: 'string'
        },
        "ts2__Question_Layout__c": {
          name: 'ts2__Question_Layout__c',
          type: 'string'
        },
        "ts2__Question_Size__c": {
          name: 'ts2__Question_Size__c',
          type: 'string'
        },
        "Reason_For_switch__c": {
          name: 'Reason_For_switch__c',
          type: 'string'
        },
        "ts2__Required_By_Applicant__c": {
          name: 'ts2__Required_By_Applicant__c',
          type: 'boolean',
          default: false
        },
        "ts2__Rich_Text_Question__c": {
          name: 'ts2__Rich_Text_Question__c',
          type: 'string'
        },
        "ts2__TempDate__c": {
          name: 'ts2__TempDate__c',
          type: 'Date'
        },
        "ts2__TempDateTime__c": {
          name: 'ts2__TempDateTime__c',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
