/* tslint:disable */
import {
  Contact,
  Worker,
  MyWorkLeaveRequest,
  MyWorkLeaveType2ApprovalSetting
} from '../index';

declare var Object: any;
export interface MyWorkLeaveApprovalInterface {
  "sfdcId"?: string;
  "OwnerId"?: string;
  "IsDeleted"?: boolean;
  "Name"?: string;
  "CreatedById"?: string;
  "CreatedDate"?: Date;
  "CurrencyIsoCode"?: string;
  "LastModifiedDate"?: Date;
  "LastModifiedById"?: string;
  "LastActivityDate"?: Date;
  "LastViewedDate"?: Date;
  "LastReferencedDate"?: Date;
  "Approver_Notes__c"?: string;
  "Created_By_Contact__c"?: string;
  "Leave_Approval_Requirement__c"?: string;
  "Leave_Approval_Status__c"?: string;
  "Leave_Approval_Updated_Datetime__c"?: Date;
  "Leave_Approver__c"?: string;
  "Leave_Request_ID__c"?: string;
  "Leave_Type2Approval_ID__c"?: string;
  "Modified_By_Contact__c"?: string;
  "Reject_Reason__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  contact?: Contact;
  worker?: Worker;
  modifiedByContact?: Contact;
  myWorkLeaveRequest?: MyWorkLeaveRequest;
  myWorkLeaveType2ApprovalSetting?: MyWorkLeaveType2ApprovalSetting;
}

export class MyWorkLeaveApproval implements MyWorkLeaveApprovalInterface {
  "sfdcId": string;
  "OwnerId": string;
  "IsDeleted": boolean;
  "Name": string;
  "CreatedById": string;
  "CreatedDate": Date;
  "CurrencyIsoCode": string;
  "LastModifiedDate": Date;
  "LastModifiedById": string;
  "LastActivityDate": Date;
  "LastViewedDate": Date;
  "LastReferencedDate": Date;
  "Approver_Notes__c": string;
  "Created_By_Contact__c": string;
  "Leave_Approval_Requirement__c": string;
  "Leave_Approval_Status__c": string;
  "Leave_Approval_Updated_Datetime__c": Date;
  "Leave_Approver__c": string;
  "Leave_Request_ID__c": string;
  "Leave_Type2Approval_ID__c": string;
  "Modified_By_Contact__c": string;
  "Reject_Reason__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  contact: Contact;
  worker: Worker;
  modifiedByContact: Contact;
  myWorkLeaveRequest: MyWorkLeaveRequest;
  myWorkLeaveType2ApprovalSetting: MyWorkLeaveType2ApprovalSetting;
  constructor(data?: MyWorkLeaveApprovalInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `MyWorkLeaveApproval`.
   */
  public static getModelName() {
    return "MyWorkLeaveApproval";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of MyWorkLeaveApproval for dynamic purposes.
  **/
  public static factory(data: MyWorkLeaveApprovalInterface): MyWorkLeaveApproval{
    return new MyWorkLeaveApproval(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'MyWorkLeaveApproval',
      plural: 'MyWorkLeaveApprovals',
      path: 'MyWorkLeaveApprovals',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "LastActivityDate": {
          name: 'LastActivityDate',
          type: 'Date'
        },
        "LastViewedDate": {
          name: 'LastViewedDate',
          type: 'Date'
        },
        "LastReferencedDate": {
          name: 'LastReferencedDate',
          type: 'Date'
        },
        "Approver_Notes__c": {
          name: 'Approver_Notes__c',
          type: 'string'
        },
        "Created_By_Contact__c": {
          name: 'Created_By_Contact__c',
          type: 'string'
        },
        "Leave_Approval_Requirement__c": {
          name: 'Leave_Approval_Requirement__c',
          type: 'string'
        },
        "Leave_Approval_Status__c": {
          name: 'Leave_Approval_Status__c',
          type: 'string'
        },
        "Leave_Approval_Updated_Datetime__c": {
          name: 'Leave_Approval_Updated_Datetime__c',
          type: 'Date'
        },
        "Leave_Approver__c": {
          name: 'Leave_Approver__c',
          type: 'string'
        },
        "Leave_Request_ID__c": {
          name: 'Leave_Request_ID__c',
          type: 'string'
        },
        "Leave_Type2Approval_ID__c": {
          name: 'Leave_Type2Approval_ID__c',
          type: 'string'
        },
        "Modified_By_Contact__c": {
          name: 'Modified_By_Contact__c',
          type: 'string'
        },
        "Reject_Reason__c": {
          name: 'Reject_Reason__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        contact: {
          name: 'contact',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Created_By_Contact__c',
          keyTo: 'sfdcId'
        },
        worker: {
          name: 'worker',
          type: 'Worker',
          model: 'Worker',
          relationType: 'belongsTo',
                  keyFrom: 'Leave_Approver__c',
          keyTo: 'sfdcId'
        },
        modifiedByContact: {
          name: 'modifiedByContact',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Modified_By_Contact__c',
          keyTo: 'sfdcId'
        },
        myWorkLeaveRequest: {
          name: 'myWorkLeaveRequest',
          type: 'MyWorkLeaveRequest',
          model: 'MyWorkLeaveRequest',
          relationType: 'belongsTo',
                  keyFrom: 'Leave_Request_ID__c',
          keyTo: 'sfdcId'
        },
        myWorkLeaveType2ApprovalSetting: {
          name: 'myWorkLeaveType2ApprovalSetting',
          type: 'MyWorkLeaveType2ApprovalSetting',
          model: 'MyWorkLeaveType2ApprovalSetting',
          relationType: 'belongsTo',
                  keyFrom: 'Leave_Type2Approval_ID__c',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
