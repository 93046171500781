import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { projectTypeValues, limitedProjectTypeValues } from '../../../models/static-list-data.service';
import { TaskApi } from 'shared/sdk';

@Component({
  selector: 'app-project-type-lookup',
  templateUrl: './project-type-lookup.component.html',
  styleUrls: ['./project-type-lookup.component.css']
})
export class ProjectTypeLookupComponent implements OnInit {

  @Input() set resetData(isClear) {
    if (isClear && !this.helpDeskProject.length) {
      this.projectTypeSelected = [];
    }
  }
  @Input() placeholder = 'Project Type';
  @Input() selectedDefaultProject;
  // @Input() isdisabled = false;
  @Input() isMultipleSelected = true;
  @Input() maxSelectedItems;
  @Input() viewType = '';
  @Input() source;
  @Input() from;
  @Input() set selectedConversatonProjType(e) {
    if (e) {
      this.selectedTask = e;
      this.getProjectType();
    } else {
      this.selectedTask = '';
    }
  }
  @Input() set selectedHelpDeskType(e) {
    if (e) {
      this.helpDeskProject = e;
    } else {
      this.helpDeskProject = [];
    }
  }
  @Input() set selectedSingleHelpDeskType(e) {
    if (e) {
      this.singleHelpDeskProject = e;
    }
  }
  @Input() set disableProjectType(e) {
    if (e) {
      this.disabled = e;
    } else {
      this.disabled = false;
    }
  }
  @Output() selectedProjectType: EventEmitter<any> = new EventEmitter;
  @Output() selectedInitialProjectType: EventEmitter<any> = new EventEmitter;
  selectedTask: any;
  projectTypeOptionList = [];
  projectTypeSelected = [];
  helpDeskProject = [];
  singleHelpDeskProject: any;
  disabled = false;
  constructor(
    private _taskAPI: TaskApi
  ) { }

  ngOnInit() {
    if (this.source === 'globalTaskConversation') {
      this.projectTypeOptionList = limitedProjectTypeValues;
    } else {
      this.projectTypeOptionList = projectTypeValues;
    }
    console.log('globalTaskConv>>>', this.source);
    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
    if (this.source === 'teamMembers') {
      this.projectTypeSelected = (preselected && preselected['teamProjectTypes']) ? preselected['teamProjectTypes'] : '';
    } else if (this.from && this.from === 'analyticsDashboard') {
      if (this.selectedDefaultProject && this.selectedDefaultProject.length) {
        this.projectTypeSelected = this.selectedDefaultProject;
      } else {
        this.projectTypeSelected = [];
      }
    }  else {
      this.projectTypeSelected = (preselected && preselected['projectTypes']) ?
       (preselected['projectTypes'].includes('RecruitmentManager') ? ['RecruitmentManager'] : preselected['projectTypes']) : '';
    }
    if (this.singleHelpDeskProject) {
      this.projectTypeSelected = this.singleHelpDeskProject;
      this.selectedInitialProjectType.emit(this.projectTypeSelected);
    } else if (this.selectedTask) {
      this.getProjectType();
    }
    if (this.helpDeskProject && this.helpDeskProject.length) {
      this.projectTypeSelected = this.helpDeskProject;
    }
    this.onProjectTypeChange();
  }

  getProjectType() {
    this._taskAPI.find({
      where: this._setWhereCondition(),
      fields: ['id', 'sfdcId', 'PgMO_Projects__c'],
      include: [
        {
          relation: 'project',
          scope: {
            fields: ['id', 'sfdcId', 'Project_Type__c']
          }
        }]
    }).subscribe(
      data => {
        if (data && data.length) {
          this.projectTypeSelected = data[0]['project'] && data[0]['project'].Project_Type__c;
          this.selectedInitialProjectType.emit(this.projectTypeSelected);
        }
      },
      err => {
        console.log('Error fetching Task', err.message);
      }
    );
  }

  private _setWhereCondition() {
    return this.selectedTask && this.selectedTask.id ?
    { id: this.selectedTask.id } : { sfdcId: this.selectedTask.sfdcId };
  }

  onProjectTypeChange() {
    this.selectedProjectType.emit(this.projectTypeSelected);
  }

  clearProjectType() {
    this.projectTypeSelected = [];
    this.selectedProjectType.emit('');
  }

}
