import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaskTypeLookupComponent } from './task-type-lookup.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule
  ],
  declarations: [TaskTypeLookupComponent],
  exports: [TaskTypeLookupComponent]
})
export class TaskTypeLookupModule { }
