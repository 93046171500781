import { Component, OnInit, Input } from '@angular/core';
import { CaseApi } from 'shared/sdk';
import { CommonService } from 'shared/services/common.service';
import { PreloaderService } from 'shared/services/preloader.service';
import { AppStateService } from 'shared/services/app-state.service';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.css']
})
export class StatusComponent implements OnInit {

  @Input() caseData;

  statusData: any = {
    'Job Summary': [],
    'Worker Information': [],
    'Appointment Information': [],
    'Contact Information': [],
  }
  jobSummary = [];
  workerInformation = [];
  appointmentInformation = [];
  contactInformation = [];
  error: String = '';
  timeZone = '';
  accessType: any;

  constructor(
    private _caseApi: CaseApi,
    private _commonService: CommonService,
    private _preloaderService: PreloaderService,
    private _appState: AppStateService
  ) { }

  ngOnInit() {
    this.accessType = this._appState.getAccessType();
    // To check if view as PMS mode is activated.
    if (localStorage.getItem('viewAsInfo')) {
      const viewAsInfo = JSON.parse(localStorage.getItem('viewAsInfo'));
      const viewAsPartner = (viewAsInfo && viewAsInfo.viewAsType === 'Partner');
      this.accessType = (viewAsPartner) ? 'partner' : this.accessType;
    }
    this.setUpStatusStructure();
    if (this.caseData) { this.getStatusData() }
  }
  setUpStatusStructure = (data?) => {
    this.statusData = {
      'Job Summary': [],
      'Worker Information': [],
      'Appointment Information': [],
      'Contact Information': [],
    }
    let contactDetial = [];
    const timeFormat = 'L, LT z';
    // JOB SUMMARY
    this.statusData['Job Summary'].push({
      'name': 'Job Dispatch Status',
      'value': data && data.Job && data.Job.Job_Status_Internal__c || ''
    });
    this.statusData['Job Summary'].push({
      'name': 'Worker Expertise Type',
      'value': data && data.Job && data.Job.Technical_Level__c || ''
    });

    // WORKER INFORMATION
    this.statusData['Worker Information'].push({
      'name': 'Worker Name',
      'value': data && data.worker && data.worker.Name || ''
    });
    this.statusData['Worker Information'].push({
      'name': 'Worker Phone',
      'value': data && data.worker && data.worker.Work_Phone_Number__c || ''
    });
    this.statusData['Worker Information'].push({
      'name': 'Worker Visit Status',
      'value': data && data.Job && data.Job.Job_Status_Internal__c || ''
    });

    // APPOINTMENT INFORMATION
    this.statusData['Appointment Information'].push(
      {
        'name':
          'Worker Arrival DateTime (Cust Requested)',
        'value': data && data.appointment && data.appointment[0].Worker_Arrival_DateTime_Cust_Requested__c && this._commonService.dateFormate(
          data.appointment[0].Worker_Arrival_DateTime_Cust_Requested__c, this.timeZone, timeFormat) || ''
      });
    this.statusData['Appointment Information'].push(
      {
        'name':
          'Customer Appointment DateTime(Scheduled)',
        'value': data && data.appointment && data.appointment[0].Customer_Appointment_DateTime_Scheduled__c && this._commonService.dateFormate(
          data.appointment[0].Customer_Appointment_DateTime_Scheduled__c, this.timeZone, timeFormat) || ''
      });
    this.statusData['Appointment Information'].push(
      {
        'name':
          'Worker Arrival DateTime (Scheduled)',
        'value': data && data.appointment && data.appointment[0] && data.appointment[0].Worker_Arrival_DateTime_Scheduled__c
          && this._commonService.dateFormate(data.appointment[0].Worker_Arrival_DateTime_Scheduled__c, this.timeZone, timeFormat) || ''
      });
    this.statusData['Appointment Information'].push(
      {
        'name':
          'Worker End DateTime (Scheduled)',
        'value': data && data.appointment && data.appointment[0] && data.appointment[0].Worker_End_DateTime_Scheduled__c &&
          this._commonService.dateFormate(data.appointment[0].Worker_End_DateTime_Scheduled__c, this.timeZone, timeFormat) || ''
      });
    this.statusData['Appointment Information'].push(
      {
        'name':
          'Worker Arrival Date/Time (Actual)',
        'value': data && data.appointment && data.appointment[0] && data.appointment[0].Worker_Arrival_Date_Time_Actual__c &&
          this._commonService.dateFormate(data.appointment[0].Worker_Arrival_Date_Time_Actual__c, this.timeZone, timeFormat) || ''
      });
    this.statusData['Appointment Information'].push(
      {
        'name':
          'Worker Finish Date/Time (Actual)',
        'value': data && data.appointment && data.appointment[0] && data.appointment[0].Worker_Finish_Date_Time_Actual__c &&
          this._commonService.dateFormate(data.appointment[0].Worker_Finish_Date_Time_Actual__c, this.timeZone, timeFormat) || ''
      });
    this.statusData['Appointment Information'].push(
      {
        'name':
          'Worker InProgress Start DateTime(Actual)',
        'value': data && data.appointment && data.appointment[0] && data.appointment[0].Worker_InProgress_Start_DateTime_Actual__c &&
          this._commonService.dateFormate(data.appointment[0].Worker_InProgress_Start_DateTime_Actual__c, this.timeZone, timeFormat) || ''
      });

      if (this.accessType !== 'partner') {
        this.statusData['Appointment Information'].push(
          {
            'name':
              'Worker_Departure_Date_Time_Actual',
            'value': data && data.appointment && data.appointment[0] && data.appointment[0].Worker_Departure_Date_Time_Actual__c &&
              this._commonService.dateFormate(data.appointment[0].Worker_Departure_Date_Time_Actual__c, this.timeZone, timeFormat) || ''
          });
      }
    // CONTACT INFORMATION
    if (data && data.Job && data.Job.Jobsite_Contact_SDesk_Name_phone_email__c) {
      contactDetial = data.Job.Jobsite_Contact_SDesk_Name_phone_email__c.split(';')
    }
    this.statusData['Contact Information'].push({
      'name': 'Customer Name',
      'value': data && data.Job && data.Job.Location_Name__c || ''
    });
    this.statusData['Contact Information'].push({
      'name': 'Customer Phone',
      'value': data && data.Job && data.Job.Customer_Contact_Phone__c || ''
    });
    this.statusData['Contact Information'].push({
      'name': 'Customer Helpdesk Phone',
      'value': '(001)408-940-0940'
    });

    this.jobSummary = this.statusData['Job Summary']
    this.appointmentInformation = this.statusData['Appointment Information']
    this.workerInformation = this.statusData['Worker Information']
    this.contactInformation = this.statusData['Contact Information']

  }

  getStatusData = () => {
    if (this.caseData.iccCaseSfdcId) {
      this._preloaderService.showPreloader();
      this._caseApi.find({
        where: { 'sfdcId': this.caseData.iccCaseSfdcId },
        fields: ['id', 'sfdcId', 'Appointment__c', 'Dispatch_Worker__c', 'Created_by_Contact__c', 'Service_Dispatch__c', 'Jobsite__c'],
        include: [
          {
            relation: 'appointment',
            scope: {
              fields: [
                'id', 'sfdcId', 'Worker_Arrival_DateTime_Cust_Requested__c',
                'Customer_Appointment_DateTime_Scheduled__c', 'Worker_Arrival_DateTime_Scheduled__c',
                'Worker_End_DateTime_Scheduled__c', 'Worker_Arrival_Date_Time_Actual__c',
                'Worker_Finish_Date_Time_Actual__c', 'Worker_InProgress_Start_DateTime_Actual__c',
                'Worker_Departure_Date_Time_Actual__c'
              ]
            }
          }, {
            relation: 'worker',
            scope: {
              fields: ['id', 'sfdcId', 'Name', 'Work_Phone_Number__c']
            }
          }, {
            relation: 'contact',
            scope: {
              fields: ['id', 'sfdcId', 'Contact_Full_Name__c', 'Phone']
            }
          }, {
            relation: 'Job',
            scope: {
              fields: ['id', 'sfdcId', 'Dispatch_Service_Resolution_Status__c',
                'Technical_Level__c', 'Job_Status_Internal__c',
                'Created_by_Contact__c',
                'Jobsite_Contact_SDesk_Name_phone_email__c',
                'Location_Name__c', 'Customer_Contact_Phone__c']
            }
          }, {
            relation: 'Jobsite',
            scope: {
              fields: ['id', 'sfdcId', 'Time_Zone__c']
            }
          }
        ]
      }).subscribe(statusData => {
        this._preloaderService.hidePreloader();
        if (statusData && statusData.length) {
          this.timeZone = statusData[0]['Jobsite'] && statusData[0]['Jobsite'].Time_Zone__c || '';
          this.setUpStatusStructure(statusData[0])
          // this.statusData = tempData;
        } else {

          this.error = 'No Data Found.'
        }
      }, err => {
        this._preloaderService.hidePreloader();
      })
    } else if (this.caseData.pmsCaseSfdcId) {
      this._preloaderService.showPreloader();
      this._caseApi.find({
        where: { 'sfdcId': this.caseData.pmsCaseSfdcId },
        fields: ['id', 'sfdcId', 'Job_Order__c'],
        include: [
          {
            relation: 'workOrder',
            scope: {
              fields: ['id', 'sfdcId', 'Name', 'Appointment__c', 'Created_by_Contact__c', 'Jobsite__c'],
              include: [
                {
                  relation: 'appointment',
                  scope: {
                    fields: [
                      'id', 'sfdcId', 'Worker_Arrival_DateTime_Cust_Requested__c',
                      'Customer_Appointment_DateTime_Scheduled__c', 'Worker_Arrival_DateTime_Scheduled__c',
                      'Worker_End_DateTime_Scheduled__c', 'Worker_Arrival_Date_Time_Actual__c',
                      'Worker_Finish_Date_Time_Actual__c', 'Worker_InProgress_Start_DateTime_Actual__c',
                      'Worker_Departure_Date_Time_Actual__c'
                    ]
                  }
                }, {
                  relation: 'contact',
                  scope: {
                    fields: ['id', 'sfdcId', 'Contact_Full_Name__c', 'Phone']
                  }
                }, {
                  relation: 'Jobsite',
                  scope: {
                    fields: ['id', 'sfdcId', 'Time_Zone__c']
                  }
                }
              ]
            }
          }
        ]
      }).subscribe(statusData => {
        this._preloaderService.hidePreloader();
        if (statusData && statusData.length) {
          this.timeZone = statusData[0]['workOrder'] &&
            statusData[0]['workOrder']['Jobsite'] && statusData[0]['workOrder']['Jobsite'].Time_Zone__c || '';
          statusData[0]['appointment'] = statusData[0]['workOrder'] && statusData[0]['workOrder'].appointment
          this.setUpStatusStructure(statusData[0])
          // this.statusData = tempData;
        } else {

          this.error = 'No Data Found.'
        }
      }, err => {
        this._preloaderService.hidePreloader();
      })
    }
  }



}
