/* tslint:disable */
import {
  Department,
  Worker,
  Group
} from '../index';

declare var Object: any;
export interface DepartmentRoleInterface {
  "sfdcId"?: string;
  "Access__c"?: string;
  "Community__c"?: string;
  "CreatedById"?: string;
  "CreatedDate"?: Date;
  "CurrencyIsoCode"?: string;
  "Contact_Id__c"?: string;
  "Department_Group_Code__c"?: string;
  "Department_Role_Name__c"?: string;
  "Group_Community__c"?: string;
  "Group_Record_Type__c"?: string;
  "IsDeleted"?: boolean;
  "LastModifiedById"?: string;
  "LastModifiedDate"?: Date;
  "Member_Role__c"?: string;
  "Membership__c"?: string;
  "Name"?: string;
  "PgMO_Channels__c"?: string;
  "PgMO_Departments__c"?: string;
  "PgMO_Groups__c"?: string;
  "RecordTypeId"?: string;
  "Worker__c"?: string;
  "CreatedBy__c"?: string;
  "LastModifiedBy__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  department?: Department;
  worker?: Worker;
  group?: Group;
}

export class DepartmentRole implements DepartmentRoleInterface {
  "sfdcId": string;
  "Access__c": string;
  "Community__c": string;
  "CreatedById": string;
  "CreatedDate": Date;
  "CurrencyIsoCode": string;
  "Contact_Id__c": string;
  "Department_Group_Code__c": string;
  "Department_Role_Name__c": string;
  "Group_Community__c": string;
  "Group_Record_Type__c": string;
  "IsDeleted": boolean;
  "LastModifiedById": string;
  "LastModifiedDate": Date;
  "Member_Role__c": string;
  "Membership__c": string;
  "Name": string;
  "PgMO_Channels__c": string;
  "PgMO_Departments__c": string;
  "PgMO_Groups__c": string;
  "RecordTypeId": string;
  "Worker__c": string;
  "CreatedBy__c": string;
  "LastModifiedBy__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  department: Department;
  worker: Worker;
  group: Group;
  constructor(data?: DepartmentRoleInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `DepartmentRole`.
   */
  public static getModelName() {
    return "DepartmentRole";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of DepartmentRole for dynamic purposes.
  **/
  public static factory(data: DepartmentRoleInterface): DepartmentRole{
    return new DepartmentRole(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'DepartmentRole',
      plural: 'DepartmentRoles',
      path: 'DepartmentRoles',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "Access__c": {
          name: 'Access__c',
          type: 'string'
        },
        "Community__c": {
          name: 'Community__c',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "Contact_Id__c": {
          name: 'Contact_Id__c',
          type: 'string'
        },
        "Department_Group_Code__c": {
          name: 'Department_Group_Code__c',
          type: 'string'
        },
        "Department_Role_Name__c": {
          name: 'Department_Role_Name__c',
          type: 'string'
        },
        "Group_Community__c": {
          name: 'Group_Community__c',
          type: 'string'
        },
        "Group_Record_Type__c": {
          name: 'Group_Record_Type__c',
          type: 'string'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "Member_Role__c": {
          name: 'Member_Role__c',
          type: 'string'
        },
        "Membership__c": {
          name: 'Membership__c',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "PgMO_Channels__c": {
          name: 'PgMO_Channels__c',
          type: 'string'
        },
        "PgMO_Departments__c": {
          name: 'PgMO_Departments__c',
          type: 'string'
        },
        "PgMO_Groups__c": {
          name: 'PgMO_Groups__c',
          type: 'string'
        },
        "RecordTypeId": {
          name: 'RecordTypeId',
          type: 'string'
        },
        "Worker__c": {
          name: 'Worker__c',
          type: 'string'
        },
        "CreatedBy__c": {
          name: 'CreatedBy__c',
          type: 'string'
        },
        "LastModifiedBy__c": {
          name: 'LastModifiedBy__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        department: {
          name: 'department',
          type: 'Department',
          model: 'Department',
          relationType: 'belongsTo',
                  keyFrom: 'PgMO_Departments__c',
          keyTo: 'sfdcId'
        },
        worker: {
          name: 'worker',
          type: 'Worker',
          model: 'Worker',
          relationType: 'belongsTo',
                  keyFrom: 'Worker__c',
          keyTo: 'sfdcId'
        },
        group: {
          name: 'group',
          type: 'Group',
          model: 'Group',
          relationType: 'belongsTo',
                  keyFrom: 'PgMO_Groups__c',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
