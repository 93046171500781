/* tslint:disable */
import {
  Contact,
  JobOrder
} from '../index';

declare var Object: any;
export interface ApplicantPreviewInterface {
  "uuid"?: string;
  "filters"?: string;
  "jobBoardName"?: string;
  "recruiterId"?: string;
  "jobOrderId"?: string;
  "previewContainer"?: Array<any>;
  "previewFilter"?: any;
  "isDeleted"?: boolean;
  "isError"?: boolean;
  "previewError"?: string;
  "searchDateTime"?: Date;
  "totalCount"?: number;
  "id"?: any;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  recruiter?: Contact;
  jobOrder?: JobOrder;
}

export class ApplicantPreview implements ApplicantPreviewInterface {
  "uuid": string;
  "filters": string;
  "jobBoardName": string;
  "recruiterId": string;
  "jobOrderId": string;
  "previewContainer": Array<any>;
  "previewFilter": any;
  "isDeleted": boolean;
  "isError": boolean;
  "previewError": string;
  "searchDateTime": Date;
  "totalCount": number;
  "id": any;
  "createdAt": Date;
  "updatedAt": Date;
  recruiter: Contact;
  jobOrder: JobOrder;
  constructor(data?: ApplicantPreviewInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ApplicantPreview`.
   */
  public static getModelName() {
    return "ApplicantPreview";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ApplicantPreview for dynamic purposes.
  **/
  public static factory(data: ApplicantPreviewInterface): ApplicantPreview{
    return new ApplicantPreview(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ApplicantPreview',
      plural: 'ApplicantPreviews',
      path: 'ApplicantPreviews',
      idName: 'id',
      properties: {
        "uuid": {
          name: 'uuid',
          type: 'string'
        },
        "filters": {
          name: 'filters',
          type: 'string'
        },
        "jobBoardName": {
          name: 'jobBoardName',
          type: 'string'
        },
        "recruiterId": {
          name: 'recruiterId',
          type: 'string'
        },
        "jobOrderId": {
          name: 'jobOrderId',
          type: 'string'
        },
        "previewContainer": {
          name: 'previewContainer',
          type: 'Array&lt;any&gt;'
        },
        "previewFilter": {
          name: 'previewFilter',
          type: 'any'
        },
        "isDeleted": {
          name: 'isDeleted',
          type: 'boolean',
          default: false
        },
        "isError": {
          name: 'isError',
          type: 'boolean',
          default: false
        },
        "previewError": {
          name: 'previewError',
          type: 'string'
        },
        "searchDateTime": {
          name: 'searchDateTime',
          type: 'Date'
        },
        "totalCount": {
          name: 'totalCount',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        recruiter: {
          name: 'recruiter',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'recruiterId',
          keyTo: 'sfdcId'
        },
        jobOrder: {
          name: 'jobOrder',
          type: 'JobOrder',
          model: 'JobOrder',
          relationType: 'belongsTo',
                  keyFrom: 'jobOrderId',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
