import { JobOrderApi } from './../../../sdk/services/custom/JobOrder';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { debounceTime, distinctUntilChanged, switchMap, filter } from 'rxjs/operators';


@Component({
    selector: 'app-city-lookup',
    templateUrl: './city-lookup.component.html',
    styleUrls: ['./city-lookup.component.css']
})
export class CityLookupComponent implements OnInit {

    @Input()
    set resetData(isClear) {
        this.clearFromParent();
    }

    @Input() placeholder = 'City';
    @Input() isdisabled;

    @Input()
    set isMultipleSelected(e) {
        this._multipleSelect = e;
        this.maxSelectedItems = e ? 20 : 1;
    }
    @Input() isClearable = true;
    @Input() isMultipleAllowed = true;
    @Input() set setStateManagerObj(params){
        this.cityOptionList = [];
        let states = [];
        this.clearFromParent(true);
        if(params && params.length){
            states = params.map(function (item:any) {
                return item['stateName'];
            }); 
            this.whereObj = {State__c:{inq:states}};
            this.loadCityBasedOnState(this.whereObj);
        }
    }
    @Input() boxedLayout;
    @Output() getCityObj: EventEmitter<any> = new EventEmitter;
    public searchInput = new EventEmitter<string>();

    _multipleSelect = true;
    maxSelectedItems = 20;

    cityOptionList = [];
    citySelectedList = [];
    whereObj = {};    
    constructor(
        private _jobOrderApi: JobOrderApi) {
    }

    ngOnInit() {
        const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
        if (preselected && preselected['cityList'] && preselected['cityList'].length) {
            this.citySelectedList = preselected['cityList'];
            this.onChange();
            const whereObj = {};
            whereObj['City__c'] = { inq: this.citySelectedList };
            const filterObj = {
                where: whereObj,
                fields: ['sfdcId', 'City__c'],
                order: 'CreatedDate asc',
                limit: 200
            }
            this._jobOrderApi.find(filterObj).subscribe(data => {
                if (data && data.length) {                    
                    var allCities = data.map(function (item:any) {
                        return item['City__c']
                    });
                    this.cityOptionList = allCities.filter((n, i) => allCities.indexOf(n) === i);
                } else {
                    this.cityOptionList = [];
                }
            }, error => {
                this.cityOptionList = [];
                console.log(error);
            })
        }
        this.getCityObj.emit(this.citySelectedList);
        this.getCityList();
    }

    getCityList() {
        this.searchInput
            .pipe(
                filter(search => search && search.trim().length > 2),
                debounceTime(200),
                distinctUntilChanged(),
                switchMap(search =>
                    this.getCities(this.whereObj, search)
                )
            ).subscribe(
                data => {
                    if (data && data.length) {
                        var allCities = data.map(function (item:any) {
                            return item['City__c']
                        });
                        this.cityOptionList = allCities.filter((n, i) => allCities.indexOf(n) === i);
                    } else {
                        this.cityOptionList = [];
                    }
                },
                err => {
                    console.log(err);
                    this.cityOptionList = [];
                }
            );
    }


    getCities(whereObj , search) {
        if(search){
            whereObj['City__c'] =  { like: '%' + search.trim() + '%', options: 'i' };
        }
        const filterObj = {
            where: whereObj,
            fields: ['sfdcId', 'City__c'],
            order: 'CreatedDate desc',
            group: 'City__c',
            limit: 200
        }
        return this._jobOrderApi.find(filterObj);
    }

    onChange() {
        this.getCityObj.emit(this.citySelectedList);
    }

    clearCity() {
        this.citySelectedList = [];
        this.getCityObj.emit([]);
    }

    clearFromParent(initiateOnChange?) {
        this.citySelectedList = null;
        if (initiateOnChange) {
          this.onChange();
        }
    }

    loadCityBasedOnState(whereObj){
        this.getCities(whereObj,'').subscribe(
            data => {
                if (data && data.length) {
                    var allCities = data.map(function (item:any) {
                        return item['City__c']
                    });
                    this.cityOptionList = allCities.filter((n, i) => allCities.indexOf(n) === i);
                } else {
                    this.cityOptionList = [];
                }
            },
            err => {
                console.log(err);
                this.cityOptionList = [];
            }
        );
    }
}
