/* tslint:disable */
import {
  Project,
  Jobsite,
  Job,
  Account,
  Worker,
  Users,
  Appointment,
  PurchaseOrder,
  Product,
  Contact,
  TalentType,
  WorkOrder,
  JobOrderItem,
  RecordType,
  Order,
  RequestFormReceipt,
  Asset,
  Comment,
  Favorite,
  ServiceContract,
  RequestFormReceiptEscalation
} from '../index';

declare var Object: any;
export interface CaseInterface {
  "sfdcId"?: string;
  "AccountId"?: string;
  "Account_Currency__c"?: string;
  "Account_Name_Text__c"?: string;
  "Account_Name_for_Sharing__c"?: string;
  "Activity_Type__c"?: string;
  "Actual_Finish__c"?: Date;
  "Actual_start__c"?: Date;
  "Appointment_Plan_Option__c"?: string;
  "Appointment_Schedule_Information__c"?: string;
  "Appointment_Schedule_Start_Minute__c"?: string;
  "Appointment_Schedule_Start_hour__c"?: string;
  "Appointment_Schedule_Status_Customer__c"?: string;
  "Appointment__c"?: string;
  "AssetId"?: string;
  "Asset_Address_Does_Not_Match__c"?: boolean;
  "Asset_Description__c"?: string;
  "Asset_Manufacture_Date__c"?: Date;
  "Asset_Name__c"?: string;
  "Asset_Receive_Confirmation_Return_Date__c"?: string;
  "Asset_Receive_Confirmation_Return_Time__c"?: string;
  "Asset_Serial_Number_Label__c"?: string;
  "Asset_Serial_Number__c"?: string;
  "Asset__c"?: string;
  "Auto_Jobsite_Done__c"?: boolean;
  "B1A_IRON_Help_Desk_Cust_Phone_Recd__c"?: boolean;
  "B2A_IRON_Help_Desk_Cust_Email_Recd__c"?: boolean;
  "B2B_Response_Status__c"?: string;
  "B2_IRON_Help_Desk_Contact_Mode__c"?: string;
  "B3_IRON_Help_Desk_Customer_Contacted__c"?: boolean;
  "Billing_Type__c"?: string;
  "CKSW_BASE__EngineeringReqNumber__c"?: string;
  "CKSW_BASE__PotentialLiability__c"?: string;
  "CKSW_BASE__Product__c"?: string;
  "CKSW_BASE__SLAViolation__c"?: string;
  "CSR_Occupancy__c"?: Date;
  "Carrier_Name__c"?: string;
  "Carrier_Tracking_Number__c"?: string;
  "CaseNumber"?: string;
  "Case_AGE_CAL_Days__c"?: number;
  "Case_Action_Type__c"?: string;
  "Case_Comment__c"?: string;
  "Case_Comments__c"?: string;
  "Case_Created_Date__c"?: Date;
  "Case_Event_Priority_Level__c"?: string;
  "Case_Manager_Comments_Log__c"?: string;
  "Case_Record_Type_Id__c"?: string;
  "Case_Reference_Number__c"?: string;
  "Case_Summary__c"?: string;
  "Case_resolve_at_the_first_time_Dispatch__c"?: number;
  "Case_resolve_at_the_second_time_Dispatch__c"?: number;
  "Cloned__c"?: boolean;
  "ClosedDate"?: Date;
  "Closed_To__c"?: string;
  "Comments"?: string;
  "CommunityId"?: string;
  "ContactEmail"?: string;
  "ContactFax"?: string;
  "ContactId"?: string;
  "ContactMobile"?: string;
  "ContactPhone"?: string;
  "Contact_Email_CustomerSite_TE__c"?: string;
  "Contact_Email__c"?: string;
  "Contact_Name_CustomerSite_TE__c"?: string;
  "Contact_Phone_CustomerSite_TE__c"?: string;
  "Contact_Phone__c"?: string;
  "Contract_Order_Request__c"?: string;
  "Contract_Reference_Number__c"?: string;
  "Contract_Service_Level_Label__c"?: string;
  "Contract_Service_Status_Label__c"?: string;
  "Contract_Support_Status__c"?: string;
  "Contract__c"?: string;
  "Count_of_Jobsite_contacts_Tech_Escal__c"?: number;
  "Count_of_Jobsite_contacts_sDesk__c"?: number;
  "Count_of_Sales_Order_Items__c"?: number;
  "Coverage_Hours__c"?: string;
  "CreatedById"?: string;
  "CreatedDate"?: Date;
  "Created_Date_GMT__c"?: string;
  "Created_by_Contact__c"?: string;
  "CreatorFullPhotoUrl"?: string;
  "CreatorName"?: string;
  "CreatorSmallPhotoUrl"?: string;
  "CurrencyIsoCode"?: string;
  "Custom_Contract_Reference_Number__c"?: string;
  "Custom_FSL_Location_Details__c"?: string;
  "Custom_FSL_Location_ID_Default__c"?: string;
  "Custom_FSL_Location_SLA_Supported_Setup__c"?: string;
  "Custom_FSL_Location_Setup_SLA_Supported__c"?: string;
  "Custom_Service_Type__c"?: string;
  "Custom_SupportStatus__c"?: string;
  "Custom_X3PL_Vendor_Name__c"?: string;
  "Custom_work_order_instructions__c"?: string;
  "CustomerAppointment_Schedule_StartDate__c"?: Date;
  "Customer_Priority__c"?: string;
  "Customer_Service_Type__c"?: string;
  "Customer_Window_End_Date__c"?: Date;
  "Customer_Window_Start_Date__c"?: Date;
  "Customer_will_return_this_product__c"?: boolean;
  "Customersitefse_Case__c"?: boolean;
  "Default_WSL_Location_Details__c"?: string;
  "Delivery_Confirmation_Custom_POD_Signat__c"?: string;
  "Delivery_Confirmation_DateTime_GMT__c"?: Date;
  "Delivery_Confirmation_Date_GMT__c"?: string;
  "Delivery_Confirmation_Date_Local__c"?: string;
  "Delivery_Confirmation_Date_Time__c"?: string;
  "Delivery_Confirmation_Local__c"?: string;
  "Delivery_Confirmation_Time_GMT__c"?: string;
  "Delivery_Confirmation_Time_Local__c"?: string;
  "Description"?: string;
  "Dispatch_Automation_Error_Message__c"?: string;
  "Dispatch_Forward_If_needed__c"?: boolean;
  "Dispatch_Only__c"?: boolean;
  "Dispatch_SLA_Priority__c"?: string;
  "Dispatch_Service_Resolution_Status__c"?: string;
  "Dispatch_Status__c"?: string;
  "Dispatch_To_Email_Verification_Audit__c"?: boolean;
  "Dispatch_Worker_Name__c"?: string;
  "Dispatch_Worker_Phone__c"?: string;
  "Dispatch_Worker__c"?: string;
  "Dispatchable__c"?: boolean;
  "Do_Not_Dispatch_FE__c"?: boolean;
  "Do_not_Call__c"?: boolean;
  "Duration_of_Service_Call_in_Hours__c"?: string;
  "Early_Start__c"?: boolean;
  "Exclude_in_Metrices__c"?: boolean;
  "Expected_Delivery_Date_Time__c"?: string;
  "Expected_Delivery_ETA_Date_GMT__c"?: string;
  "Expected_Delivery_ETA_Date_Local__c"?: string;
  "Expected_Delivery_ETA_Time_GMT__c"?: string;
  "Expected_Delivery_ETA_Time_Local__c"?: string;
  "Expected_Finish__c"?: Date;
  "FA_Required__c"?: boolean;
  "FA_Return_To_Address__c"?: string;
  "FA_Return_to_Location__c"?: string;
  "FA_Return_to__c"?: boolean;
  "FE2_Actual_Arrival_Date_Time__c"?: string;
  "FE2_Actual_Arrival_GMT__c"?: string;
  "FE2_Actual_Arrival_Local_Date_Time__c"?: string;
  "FE2_Delivered_Departure_Date_Time__c"?: string;
  "FE2_Delivered_Departure_GMT__c"?: string;
  "FE2_Dispatch_ID__c"?: string;
  "FE2_Estimated_On_Site_ETA_Date_Time__c"?: string;
  "FE2_Estimated_On_Site_ETA_GMT__c"?: string;
  "FE2_Estimated_On_Site_ETA_Local_Date_Ti__c"?: string;
  "FE2_Onsite_SLA_Comments__c"?: string;
  "FE2_Phone_Number__c"?: string;
  "FE_AM_PM__c"?: string;
  "FE_Actual_Arrival_Date_Time__c"?: string;
  "FE_Actual_Arrival_GMT__c"?: string;
  "FE_Actual_Arrival_Local_Date_Time__c"?: string;
  "FE_Arrival_Date_OutPut__c"?: Date;
  "FE_Arrival_Date_Time__c"?: string;
  "FE_Arrival_Date__c"?: Date;
  "FE_Arrival_Time_OutPut__c"?: string;
  "FE_Arrival_Time__c"?: string;
  "FE_Delivered_Departure_Date_Time__c"?: string;
  "FE_Delivered_Departure_GMT__c"?: string;
  "FE_Departure__c"?: string;
  "FE_Email__c"?: string;
  "FE_Estimated_On_Site_ETA_Date_Time__c"?: string;
  "FE_Estimated_On_Site_ETA_GMT__c"?: string;
  "FE_ID_Number__c"?: string;
  "FE_Name__c"?: string;
  "FE_Phone_Number__c"?: string;
  "FE_Requested_Arrival_Date_Time__c"?: string;
  "FE_SLA_Met__c"?: boolean;
  "FRU_SKU__c"?: string;
  "FSL_Location_Details__c"?: string;
  "FSL_Location_ID_Default__c"?: string;
  "FSL_Location_Readiness__c"?: string;
  "FSL_Location_SLA_Supported_Setup__c"?: string;
  "FSL_Location_Setup_SLA_Supported__c"?: string;
  "FTE_Configuration_Type__c"?: string;
  "Field_Service_Schedule_ETA_Date_Time__c"?: Date;
  "Field_Service_Schedule_ETA_Date__c"?: Date;
  "First_Response__c"?: Date;
  "First_response_CT__c"?: boolean;
  "GEO_Metro__c"?: string;
  "HasCommentsUnreadByOwner"?: boolean;
  "HasSelfServiceComments"?: boolean;
  "Has_Appointment__c"?: boolean;
  "Health_Asset_Sku_Comparison__c"?: string;
  "Health_Custom_Window__c"?: string;
  "Health_Customer_Pricelist_Status__c"?: string;
  "Health_Dispatch_Status__c"?: string;
  "Health_Jobsite_Status__c"?: string;
  "Health_OrderItem_Status__c"?: string;
  "Health_Pricelist_Status__c"?: string;
  "Health_Program_Pricelist__c"?: string;
  "Health_Project_status__c"?: string;
  "Health_SDesk_Contact__c"?: string;
  "Health_SKU_Price_Status__c"?: string;
  "Health_SKU_Price__c"?: boolean;
  "Health_SKU_Status__c"?: string;
  "Health_SO_Status__c"?: string;
  "Health_TEsc_Contact__c"?: string;
  "Health_Transaction_Status__c"?: string;
  "Health_Vendor_Status__c"?: string;
  "How_to_reproduce_Bug__c"?: string;
  "ICC_Case_Closed_Date_Time__c"?: Date;
  "ICC_Case_Created_Date_Time__c"?: Date;
  "IRON_Case_Notes_for_Partner__c"?: string;
  "IRON_Custom_Contract__c"?: string;
  "IRON_ICC_Case_Number__c"?: string;
  "IRON_Local_Service_Center_Email_Portal__c"?: boolean;
  "IRON_PMS_Case_Number__c"?: string;
  "IRON_Project_Reference__c"?: string;
  "IRON_RMA_Required__c"?: string;
  "IRON_RMA_Ticket__c"?: string;
  "Iron_Case_Notes_Internal__c"?: string;
  "Iron_Case_Number__c"?: string;
  "Iron_Reason_for_the_error__c"?: string;
  "Iron_Service_Contract__c"?: string;
  "IsClosed"?: boolean;
  "IsDeleted"?: boolean;
  "IsEscalated"?: boolean;
  "IsPartArrivalTimeModified__c"?: boolean;
  "Is_this_FSR_Order__c"?: boolean;
  "Issue_Start_Date_Input__c"?: Date;
  "Issue_Start_Date__c"?: Date;
  "Issue_Start_Time__c"?: string;
  "Item_Quantity__c"?: string;
  "Item_SKU_ID__c"?: string;
  "Item_Scrap_Plan__c"?: string;
  "Item_Serial_Number__c"?: string;
  "Item_Summary__c"?: string;
  "Job_Order_Item__c"?: string;
  "Job_Order__c"?: string;
  "Job_Start_Override__c"?: Date;
  "Jobsite_Country_Matches_Project_Country__c"?: boolean;
  "Jobsite_Country__c"?: string;
  "Jobsite__c"?: string;
  "Last_Modified_By__c"?: string;
  "LastModifiedById"?: string;
  "LastModifiedDate"?: Date;
  "LastReferencedDate"?: Date;
  "LastViewedDate"?: Date;
  "Last_Modified_minus_one_hour__c"?: Date;
  "Milestone__c"?: string;
  "Model_Number_Label__c"?: string;
  "Model_Number__c"?: string;
  "Modified_Date_GMT__c"?: string;
  "NRSSD__c"?: boolean;
  "New_Case_Email_Alert_Sent__c"?: boolean;
  "New_FSL_Location_Map_Registration_Req__c"?: boolean;
  "OEM_RMA_Case_Number__c"?: string;
  "Onsite_SLA_Comments__c"?: string;
  "Origin"?: string;
  "Original_Email__c"?: string;
  "OwnerId"?: string;
  "PMS_Case_Cycle_Time__c"?: number;
  "PPE_Hours__c"?: string;
  "ParentId"?: string;
  "Part_AM_PM__c"?: string;
  "Part_Arrival_Date_OutPut__c"?: Date;
  "Part_Arrival_Date_Time__c"?: string;
  "Part_Arrival_Date__c"?: Date;
  "Part_Arrival_Time_OutPut__c"?: string;
  "Part_Arrival_Time__c"?: string;
  "Part_Sch_Request_Date_and_Time__c"?: string;
  "PartnerPortelCase__c"?: boolean;
  "Partner_Account__c"?: string;
  "Partner_Account_for_Sharing__c"?: string;
  "Partner_Asset_Item__c"?: string;
  "Partner_Case_Error_Description__c"?: string;
  "Partner_Case_Number__c"?: string;
  "Partner_FRU_Sku__c"?: string;
  "Partner_Pricelist_ID_from_SOP_Auto__c"?: string;
  "Partner_RMA_Number__c"?: string;
  "Parts_Requested_Arrival_Date_GMT__c"?: Date;
  "Parts_Requested_Arrival_Date_Time_GMT__c"?: string;
  "Parts_Requested_Arrival_Date_Time__c"?: string;
  "Parts_Requested_Arrival_Time_GMT__c"?: string;
  "Perform_Post_Dispatch_Actions_FTE__c"?: boolean;
  "Phone_Scheduling_1st_Attempt_Unreachable__c"?: Date;
  "Phone_Scheduling_2nd_Attempt_Unreachable__c"?: Date;
  "Phone_Scheduling_3rd_Attempt_Unreachable__c"?: Date;
  "Pricelist_on_Program_of_Project__c"?: string;
  "Priority"?: string;
  "Project_Error__c"?: boolean;
  "Project_SOP__c"?: string;
  "Project_Vendor_Name__c"?: string;
  "Project__c"?: string;
  "Purchase_Order_Currency__c"?: string;
  "Purchase_Order_Reference__c"?: string;
  "Re_calc_Sch_Parts_Req_Arr_Date_Time__c"?: string;
  "Reason"?: string;
  "Reason_for_Failure__c"?: string;
  "Recent_Case_Comment__c"?: string;
  "RecordTypeId"?: string;
  "Record_Id__c"?: string;
  "is_backfill_worker"?: boolean;
  "Recovery_Carrier_Name__c"?: string;
  "Recovery_Carrier_Tracking_Number__c"?: string;
  "Regenerate_PO__c"?: boolean;
  "Regenerate_SO__c"?: boolean;
  "Request_Description__c"?: string;
  "Requested_Schedule_Time__c"?: string;
  "Requested_Schedule__c"?: string;
  "Return_FSL_Address__c"?: string;
  "Return_FSL_Contact__c"?: string;
  "Return_FSL_Country__c"?: string;
  "Return_FSL_Location_ID__c"?: string;
  "Return_FSL_Phone__c"?: string;
  "Return_Item_Quantity__c"?: string;
  "Return_Item_SKU_ID__c"?: string;
  "Return_Item_Serial_Number__c"?: string;
  "SKU__c"?: string;
  "SLA_3PL_ContractRequired__c"?: Date;
  "SLA_3PL_FRU_ARRIVALPOD__c"?: Date;
  "SLA_3PL_Met__c"?: string;
  "SLA_3PS_Contract_Required__c"?: Date;
  "SLA_3PS_FE_Arrival__c"?: Date;
  "SLA_3PS_Met_Formula__c"?: string;
  "SLA_3PS_Met_Override__c"?: boolean;
  "SLA_3PS_Met__c"?: string;
  "SLA_Delivery_Target_Date_Time__c"?: string;
  "SLA_Delivery_Target_SLA_Date_GMT__c"?: string;
  "SLA_Delivery_Target_SLA_Date_Local__c"?: string;
  "SLA_Delivery_Target_SLA_Time_GMT__c"?: string;
  "SLA_Delivery_Target_SLA_Time_Local__c"?: string;
  "SLA_Met_3PL_Override__c"?: boolean;
  "SLA_Met_3PL__c"?: boolean;
  "SLA_Met__c"?: boolean;
  "SLA_Scheduled_Override_based_End_Date__c"?: Date;
  "SLA_Term_based_End_Date_Time__c"?: Date;
  "SLA_is_OK__c"?: boolean;
  "SR_ID_Number__c"?: string;
  "SR_Summary__c"?: string;
  "SSSSecond_FE_Arrival_Date_Time__c"?: string;
  "SSS_FE_Arrival_Date_Time__c"?: string;
  "SS_Case_ID__c"?: string;
  "SS_Case_Number__c"?: string;
  "SS_Dispatch_ID__c"?: string;
  "SS_Sales_Order_ID__c"?: string;
  "SS_Sales_Order_Number__c"?: string;
  "Sales_Order_Created__c"?: boolean;
  "Sales_Order_Reference__c"?: string;
  "Salesorder_To_be_Created__c"?: boolean;
  "Sch_Parts_Requested_Arrival_DateTimeGMT__c"?: Date;
  "Schedule_FE_Requested_Arrival__c"?: string;
  "Schedule_Type_Weekly_Monthly__c"?: string;
  "Schedule__c"?: string;
  "Second_FE_AM_PM__c"?: string;
  "Second_FE_Arrival_Date_Time__c"?: string;
  "Second_FE_Arrival_Date__c"?: string;
  "Second_FE_Arrival_Time__c"?: string;
  "Second_FE_Departure__c"?: string;
  "Second_FE_Email__c"?: string;
  "Second_FE_ID_Number__c"?: string;
  "Second_FE_Name__c"?: string;
  "Second_Onsite_Needed__c"?: boolean;
  "Second_Service_Date__c"?: string;
  "Send_Manual_Service_Dispatch__c"?: boolean;
  "Send_to_SSS__c"?: boolean;
  "Service_Category_Type__c"?: string;
  "Service_Contract__c"?: string;
  "Service_Date__c"?: string;
  "Service_Dispatch_Frequency__c"?: string;
  "Service_Dispatch__c"?: string;
  "Service_Level_Satisfaction__c"?: number;
  "Service_SLA_3PL_FRU_POD__c"?: number;
  "Service_SLA_3PS_FE_Arrival__c"?: number;
  "Service_Technical_Level__c"?: string;
  "Service_Type__c"?: string;
  "ShipToEmailAddress__c"?: string;
  "Ship_Confirmation_Date_GMT__c"?: string;
  "Ship_Confirmation_Date_Local__c"?: string;
  "Ship_Confirmation_Date_Time__c"?: string;
  "Ship_Confirmation_Local__c"?: Date;
  "Ship_Confirmation_Time_GMT__c"?: string;
  "Ship_Confirmation_Time_Local__c"?: string;
  "Ship_To_Contact_Email_Address__c"?: string;
  "Ship_to_City__c"?: string;
  "Ship_to_Company_Name__c"?: string;
  "Ship_to_Contact_Name__c"?: string;
  "Ship_to_Contact_Phone_Number__c"?: string;
  "Ship_to_Country__c"?: string;
  "Ship_to_Postal__c"?: string;
  "Ship_to_State__c"?: string;
  "Ship_to_Street_1__c"?: string;
  "Ship_to_Street__c"?: string;
  "Shipper_Country__c"?: string;
  "Shipping_Address__c"?: string;
  "Software_Version__c"?: string;
  "SourceId"?: string;
  "Special_Instruction_from_PMS_Case_Auto__c"?: string;
  "Special_Terms__c"?: string;
  "Status"?: string;
  "Subject"?: string;
  "Subject_for_PMS_Email__c"?: string;
  "SuppliedCompany"?: string;
  "SuppliedEmail"?: string;
  "SuppliedName"?: string;
  "SuppliedPhone"?: string;
  "SupportStatus__c"?: string;
  "SystemModstamp"?: Date;
  "Talent_Type__c"?: string;
  "Talent_Type_ref__c"?: string;
  "Tax_ID__c"?: string;
  "Term_months__c"?: number;
  "TimeZone_Calculation_Message__c"?: string;
  "TimeZone_Id__c"?: string;
  "Time_Taken_to_Response__c"?: number;
  "Total_FTE_Hours__c"?: number;
  "Type"?: string;
  "Type_Facebook_Ticket_Type__c"?: string;
  "User_Name__c"?: string;
  "Vendor_Time_Card_Notes_Tasks_Performed__c"?: string;
  "Vendor_Time_Card_Observation_Suggestion__c"?: string;
  "Vendor_Time_Card_Total_Hours_All_Visits__c"?: number;
  "Vendor_Time_Card_Total_Hours_Per_Visit__c"?: number;
  "Vendor_Timecard_Cust_Site_Sign_off_Name__c"?: string;
  "Vendor_Timecard_Details_Roll_up__c"?: string;
  "Vendor__c"?: string;
  "WSL_Location_ID_Default__c"?: string;
  "WSL_Location_Readiness__c"?: string;
  "WSL_Location_Setup_SLA_Supported__c"?: string;
  "Warranty_Description__c"?: string;
  "Warranty_Type__c"?: string;
  "Work_Order_Summary__c"?: string;
  "Work_Order_Vendor__c"?: string;
  "Worker_Arrival_DateTime_Cust_Requested__c"?: Date;
  "Worker_Requested_Arrival_Date_Time__c"?: string;
  "X3PL_Case_Reference_Number__c"?: string;
  "X3PL_Case_Status__c"?: string;
  "X3PL_FSL_Location_Gateway__c"?: string;
  "X3PL_Parts_Not_Required__c"?: boolean;
  "X3PL_Vendor_Name_FSL__c"?: string;
  "X3PL_Vendor_Name__c"?: string;
  "X3PL_Vendor_Special_Instructions__c"?: string;
  "X3PL_WSL_Location_Gateway__c"?: string;
  "X3PS_Case_Status__c"?: string;
  "X3PS_Vendor_Special_Instructions__c"?: string;
  "Ticket_Resolution_Resolved_3PS_SLA__c"?: string;
  "SLA_Exception_Applicable_PMS_Manual__c"?: string;
  "B2B_Case_Close_Code__c"?: string;
  "PMS_Case_Status_B2B_Read__c"?: string;
  "PMS_Case_Status_B2B_Write__c"?: string;
  "Serviceo_Case_Number__c"?: string;
  "PMC_Case_Number"?: string;
  "isFRU"?: boolean;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  program?: Project;
  Jobsite?: Jobsite;
  Job?: Job;
  Account?: Account;
  project?: Project;
  case?: Case;
  worker?: Worker;
  users?: Users;
  ICC_Case?: Case[];
  appointment?: Appointment[];
  pmsAppointment?: Appointment;
  purchaseOrder?: PurchaseOrder;
  product?: Product;
  contact?: Contact;
  talentType?: TalentType;
  workOrder?: WorkOrder;
  jobOrderItem?: JobOrderItem;
  RecordType?: RecordType;
  order?: Order;
  requestFormReceipt?: RequestFormReceipt;
  asset?: Asset;
  comment?: Comment[];
  favorite?: Favorite[];
  serviceContract?: ServiceContract;
  escalationICC?: RequestFormReceiptEscalation[];
  escalationPMS?: RequestFormReceiptEscalation[];
}

export class Case implements CaseInterface {
  "sfdcId": string;
  "AccountId": string;
  "Account_Currency__c": string;
  "Account_Name_Text__c": string;
  "Account_Name_for_Sharing__c": string;
  "Activity_Type__c": string;
  "Actual_Finish__c": Date;
  "Actual_start__c": Date;
  "Appointment_Plan_Option__c": string;
  "Appointment_Schedule_Information__c": string;
  "Appointment_Schedule_Start_Minute__c": string;
  "Appointment_Schedule_Start_hour__c": string;
  "Appointment_Schedule_Status_Customer__c": string;
  "Appointment__c": string;
  "AssetId": string;
  "Asset_Address_Does_Not_Match__c": boolean;
  "Asset_Description__c": string;
  "Asset_Manufacture_Date__c": Date;
  "Asset_Name__c": string;
  "Asset_Receive_Confirmation_Return_Date__c": string;
  "Asset_Receive_Confirmation_Return_Time__c": string;
  "Asset_Serial_Number_Label__c": string;
  "Asset_Serial_Number__c": string;
  "Asset__c": string;
  "Auto_Jobsite_Done__c": boolean;
  "B1A_IRON_Help_Desk_Cust_Phone_Recd__c": boolean;
  "B2A_IRON_Help_Desk_Cust_Email_Recd__c": boolean;
  "B2B_Response_Status__c": string;
  "B2_IRON_Help_Desk_Contact_Mode__c": string;
  "B3_IRON_Help_Desk_Customer_Contacted__c": boolean;
  "Billing_Type__c": string;
  "CKSW_BASE__EngineeringReqNumber__c": string;
  "CKSW_BASE__PotentialLiability__c": string;
  "CKSW_BASE__Product__c": string;
  "CKSW_BASE__SLAViolation__c": string;
  "CSR_Occupancy__c": Date;
  "Carrier_Name__c": string;
  "Carrier_Tracking_Number__c": string;
  "CaseNumber": string;
  "Case_AGE_CAL_Days__c": number;
  "Case_Action_Type__c": string;
  "Case_Comment__c": string;
  "Case_Comments__c": string;
  "Case_Created_Date__c": Date;
  "Case_Event_Priority_Level__c": string;
  "Case_Manager_Comments_Log__c": string;
  "Case_Record_Type_Id__c": string;
  "Case_Reference_Number__c": string;
  "Case_Summary__c": string;
  "Case_resolve_at_the_first_time_Dispatch__c": number;
  "Case_resolve_at_the_second_time_Dispatch__c": number;
  "Cloned__c": boolean;
  "ClosedDate": Date;
  "Closed_To__c": string;
  "Comments": string;
  "CommunityId": string;
  "ContactEmail": string;
  "ContactFax": string;
  "ContactId": string;
  "ContactMobile": string;
  "ContactPhone": string;
  "Contact_Email_CustomerSite_TE__c": string;
  "Contact_Email__c": string;
  "Contact_Name_CustomerSite_TE__c": string;
  "Contact_Phone_CustomerSite_TE__c": string;
  "Contact_Phone__c": string;
  "Contract_Order_Request__c": string;
  "Contract_Reference_Number__c": string;
  "Contract_Service_Level_Label__c": string;
  "Contract_Service_Status_Label__c": string;
  "Contract_Support_Status__c": string;
  "Contract__c": string;
  "Count_of_Jobsite_contacts_Tech_Escal__c": number;
  "Count_of_Jobsite_contacts_sDesk__c": number;
  "Count_of_Sales_Order_Items__c": number;
  "Coverage_Hours__c": string;
  "CreatedById": string;
  "CreatedDate": Date;
  "Created_Date_GMT__c": string;
  "Created_by_Contact__c": string;
  "CreatorFullPhotoUrl": string;
  "CreatorName": string;
  "CreatorSmallPhotoUrl": string;
  "CurrencyIsoCode": string;
  "Custom_Contract_Reference_Number__c": string;
  "Custom_FSL_Location_Details__c": string;
  "Custom_FSL_Location_ID_Default__c": string;
  "Custom_FSL_Location_SLA_Supported_Setup__c": string;
  "Custom_FSL_Location_Setup_SLA_Supported__c": string;
  "Custom_Service_Type__c": string;
  "Custom_SupportStatus__c": string;
  "Custom_X3PL_Vendor_Name__c": string;
  "Custom_work_order_instructions__c": string;
  "CustomerAppointment_Schedule_StartDate__c": Date;
  "Customer_Priority__c": string;
  "Customer_Service_Type__c": string;
  "Customer_Window_End_Date__c": Date;
  "Customer_Window_Start_Date__c": Date;
  "Customer_will_return_this_product__c": boolean;
  "Customersitefse_Case__c": boolean;
  "Default_WSL_Location_Details__c": string;
  "Delivery_Confirmation_Custom_POD_Signat__c": string;
  "Delivery_Confirmation_DateTime_GMT__c": Date;
  "Delivery_Confirmation_Date_GMT__c": string;
  "Delivery_Confirmation_Date_Local__c": string;
  "Delivery_Confirmation_Date_Time__c": string;
  "Delivery_Confirmation_Local__c": string;
  "Delivery_Confirmation_Time_GMT__c": string;
  "Delivery_Confirmation_Time_Local__c": string;
  "Description": string;
  "Dispatch_Automation_Error_Message__c": string;
  "Dispatch_Forward_If_needed__c": boolean;
  "Dispatch_Only__c": boolean;
  "Dispatch_SLA_Priority__c": string;
  "Dispatch_Service_Resolution_Status__c": string;
  "Dispatch_Status__c": string;
  "Dispatch_To_Email_Verification_Audit__c": boolean;
  "Dispatch_Worker_Name__c": string;
  "Dispatch_Worker_Phone__c": string;
  "Dispatch_Worker__c": string;
  "Dispatchable__c": boolean;
  "Do_Not_Dispatch_FE__c": boolean;
  "Do_not_Call__c": boolean;
  "Duration_of_Service_Call_in_Hours__c": string;
  "Early_Start__c": boolean;
  "Exclude_in_Metrices__c": boolean;
  "Expected_Delivery_Date_Time__c": string;
  "Expected_Delivery_ETA_Date_GMT__c": string;
  "Expected_Delivery_ETA_Date_Local__c": string;
  "Expected_Delivery_ETA_Time_GMT__c": string;
  "Expected_Delivery_ETA_Time_Local__c": string;
  "Expected_Finish__c": Date;
  "FA_Required__c": boolean;
  "FA_Return_To_Address__c": string;
  "FA_Return_to_Location__c": string;
  "FA_Return_to__c": boolean;
  "FE2_Actual_Arrival_Date_Time__c": string;
  "FE2_Actual_Arrival_GMT__c": string;
  "FE2_Actual_Arrival_Local_Date_Time__c": string;
  "FE2_Delivered_Departure_Date_Time__c": string;
  "FE2_Delivered_Departure_GMT__c": string;
  "FE2_Dispatch_ID__c": string;
  "FE2_Estimated_On_Site_ETA_Date_Time__c": string;
  "FE2_Estimated_On_Site_ETA_GMT__c": string;
  "FE2_Estimated_On_Site_ETA_Local_Date_Ti__c": string;
  "FE2_Onsite_SLA_Comments__c": string;
  "FE2_Phone_Number__c": string;
  "FE_AM_PM__c": string;
  "FE_Actual_Arrival_Date_Time__c": string;
  "FE_Actual_Arrival_GMT__c": string;
  "FE_Actual_Arrival_Local_Date_Time__c": string;
  "FE_Arrival_Date_OutPut__c": Date;
  "FE_Arrival_Date_Time__c": string;
  "FE_Arrival_Date__c": Date;
  "FE_Arrival_Time_OutPut__c": string;
  "FE_Arrival_Time__c": string;
  "FE_Delivered_Departure_Date_Time__c": string;
  "FE_Delivered_Departure_GMT__c": string;
  "FE_Departure__c": string;
  "FE_Email__c": string;
  "FE_Estimated_On_Site_ETA_Date_Time__c": string;
  "FE_Estimated_On_Site_ETA_GMT__c": string;
  "FE_ID_Number__c": string;
  "FE_Name__c": string;
  "FE_Phone_Number__c": string;
  "FE_Requested_Arrival_Date_Time__c": string;
  "FE_SLA_Met__c": boolean;
  "FRU_SKU__c": string;
  "FSL_Location_Details__c": string;
  "FSL_Location_ID_Default__c": string;
  "FSL_Location_Readiness__c": string;
  "FSL_Location_SLA_Supported_Setup__c": string;
  "FSL_Location_Setup_SLA_Supported__c": string;
  "FTE_Configuration_Type__c": string;
  "Field_Service_Schedule_ETA_Date_Time__c": Date;
  "Field_Service_Schedule_ETA_Date__c": Date;
  "First_Response__c": Date;
  "First_response_CT__c": boolean;
  "GEO_Metro__c": string;
  "HasCommentsUnreadByOwner": boolean;
  "HasSelfServiceComments": boolean;
  "Has_Appointment__c": boolean;
  "Health_Asset_Sku_Comparison__c": string;
  "Health_Custom_Window__c": string;
  "Health_Customer_Pricelist_Status__c": string;
  "Health_Dispatch_Status__c": string;
  "Health_Jobsite_Status__c": string;
  "Health_OrderItem_Status__c": string;
  "Health_Pricelist_Status__c": string;
  "Health_Program_Pricelist__c": string;
  "Health_Project_status__c": string;
  "Health_SDesk_Contact__c": string;
  "Health_SKU_Price_Status__c": string;
  "Health_SKU_Price__c": boolean;
  "Health_SKU_Status__c": string;
  "Health_SO_Status__c": string;
  "Health_TEsc_Contact__c": string;
  "Health_Transaction_Status__c": string;
  "Health_Vendor_Status__c": string;
  "How_to_reproduce_Bug__c": string;
  "ICC_Case_Closed_Date_Time__c": Date;
  "ICC_Case_Created_Date_Time__c": Date;
  "IRON_Case_Notes_for_Partner__c": string;
  "IRON_Custom_Contract__c": string;
  "IRON_ICC_Case_Number__c": string;
  "IRON_Local_Service_Center_Email_Portal__c": boolean;
  "IRON_PMS_Case_Number__c": string;
  "IRON_Project_Reference__c": string;
  "IRON_RMA_Required__c": string;
  "IRON_RMA_Ticket__c": string;
  "Iron_Case_Notes_Internal__c": string;
  "Iron_Case_Number__c": string;
  "Iron_Reason_for_the_error__c": string;
  "Iron_Service_Contract__c": string;
  "IsClosed": boolean;
  "IsDeleted": boolean;
  "IsEscalated": boolean;
  "IsPartArrivalTimeModified__c": boolean;
  "Is_this_FSR_Order__c": boolean;
  "Issue_Start_Date_Input__c": Date;
  "Issue_Start_Date__c": Date;
  "Issue_Start_Time__c": string;
  "Item_Quantity__c": string;
  "Item_SKU_ID__c": string;
  "Item_Scrap_Plan__c": string;
  "Item_Serial_Number__c": string;
  "Item_Summary__c": string;
  "Job_Order_Item__c": string;
  "Job_Order__c": string;
  "Job_Start_Override__c": Date;
  "Jobsite_Country_Matches_Project_Country__c": boolean;
  "Jobsite_Country__c": string;
  "Jobsite__c": string;
  "Last_Modified_By__c": string;
  "LastModifiedById": string;
  "LastModifiedDate": Date;
  "LastReferencedDate": Date;
  "LastViewedDate": Date;
  "Last_Modified_minus_one_hour__c": Date;
  "Milestone__c": string;
  "Model_Number_Label__c": string;
  "Model_Number__c": string;
  "Modified_Date_GMT__c": string;
  "NRSSD__c": boolean;
  "New_Case_Email_Alert_Sent__c": boolean;
  "New_FSL_Location_Map_Registration_Req__c": boolean;
  "OEM_RMA_Case_Number__c": string;
  "Onsite_SLA_Comments__c": string;
  "Origin": string;
  "Original_Email__c": string;
  "OwnerId": string;
  "PMS_Case_Cycle_Time__c": number;
  "PPE_Hours__c": string;
  "ParentId": string;
  "Part_AM_PM__c": string;
  "Part_Arrival_Date_OutPut__c": Date;
  "Part_Arrival_Date_Time__c": string;
  "Part_Arrival_Date__c": Date;
  "Part_Arrival_Time_OutPut__c": string;
  "Part_Arrival_Time__c": string;
  "Part_Sch_Request_Date_and_Time__c": string;
  "PartnerPortelCase__c": boolean;
  "Partner_Account__c": string;
  "Partner_Account_for_Sharing__c": string;
  "Partner_Asset_Item__c": string;
  "Partner_Case_Error_Description__c": string;
  "Partner_Case_Number__c": string;
  "Partner_FRU_Sku__c": string;
  "Partner_Pricelist_ID_from_SOP_Auto__c": string;
  "Partner_RMA_Number__c": string;
  "Parts_Requested_Arrival_Date_GMT__c": Date;
  "Parts_Requested_Arrival_Date_Time_GMT__c": string;
  "Parts_Requested_Arrival_Date_Time__c": string;
  "Parts_Requested_Arrival_Time_GMT__c": string;
  "Perform_Post_Dispatch_Actions_FTE__c": boolean;
  "Phone_Scheduling_1st_Attempt_Unreachable__c": Date;
  "Phone_Scheduling_2nd_Attempt_Unreachable__c": Date;
  "Phone_Scheduling_3rd_Attempt_Unreachable__c": Date;
  "Pricelist_on_Program_of_Project__c": string;
  "Priority": string;
  "Project_Error__c": boolean;
  "Project_SOP__c": string;
  "Project_Vendor_Name__c": string;
  "Project__c": string;
  "Purchase_Order_Currency__c": string;
  "Purchase_Order_Reference__c": string;
  "Re_calc_Sch_Parts_Req_Arr_Date_Time__c": string;
  "Reason": string;
  "Reason_for_Failure__c": string;
  "Recent_Case_Comment__c": string;
  "RecordTypeId": string;
  "Record_Id__c": string;
  "is_backfill_worker": boolean;
  "Recovery_Carrier_Name__c": string;
  "Recovery_Carrier_Tracking_Number__c": string;
  "Regenerate_PO__c": boolean;
  "Regenerate_SO__c": boolean;
  "Request_Description__c": string;
  "Requested_Schedule_Time__c": string;
  "Requested_Schedule__c": string;
  "Return_FSL_Address__c": string;
  "Return_FSL_Contact__c": string;
  "Return_FSL_Country__c": string;
  "Return_FSL_Location_ID__c": string;
  "Return_FSL_Phone__c": string;
  "Return_Item_Quantity__c": string;
  "Return_Item_SKU_ID__c": string;
  "Return_Item_Serial_Number__c": string;
  "SKU__c": string;
  "SLA_3PL_ContractRequired__c": Date;
  "SLA_3PL_FRU_ARRIVALPOD__c": Date;
  "SLA_3PL_Met__c": string;
  "SLA_3PS_Contract_Required__c": Date;
  "SLA_3PS_FE_Arrival__c": Date;
  "SLA_3PS_Met_Formula__c": string;
  "SLA_3PS_Met_Override__c": boolean;
  "SLA_3PS_Met__c": string;
  "SLA_Delivery_Target_Date_Time__c": string;
  "SLA_Delivery_Target_SLA_Date_GMT__c": string;
  "SLA_Delivery_Target_SLA_Date_Local__c": string;
  "SLA_Delivery_Target_SLA_Time_GMT__c": string;
  "SLA_Delivery_Target_SLA_Time_Local__c": string;
  "SLA_Met_3PL_Override__c": boolean;
  "SLA_Met_3PL__c": boolean;
  "SLA_Met__c": boolean;
  "SLA_Scheduled_Override_based_End_Date__c": Date;
  "SLA_Term_based_End_Date_Time__c": Date;
  "SLA_is_OK__c": boolean;
  "SR_ID_Number__c": string;
  "SR_Summary__c": string;
  "SSSSecond_FE_Arrival_Date_Time__c": string;
  "SSS_FE_Arrival_Date_Time__c": string;
  "SS_Case_ID__c": string;
  "SS_Case_Number__c": string;
  "SS_Dispatch_ID__c": string;
  "SS_Sales_Order_ID__c": string;
  "SS_Sales_Order_Number__c": string;
  "Sales_Order_Created__c": boolean;
  "Sales_Order_Reference__c": string;
  "Salesorder_To_be_Created__c": boolean;
  "Sch_Parts_Requested_Arrival_DateTimeGMT__c": Date;
  "Schedule_FE_Requested_Arrival__c": string;
  "Schedule_Type_Weekly_Monthly__c": string;
  "Schedule__c": string;
  "Second_FE_AM_PM__c": string;
  "Second_FE_Arrival_Date_Time__c": string;
  "Second_FE_Arrival_Date__c": string;
  "Second_FE_Arrival_Time__c": string;
  "Second_FE_Departure__c": string;
  "Second_FE_Email__c": string;
  "Second_FE_ID_Number__c": string;
  "Second_FE_Name__c": string;
  "Second_Onsite_Needed__c": boolean;
  "Second_Service_Date__c": string;
  "Send_Manual_Service_Dispatch__c": boolean;
  "Send_to_SSS__c": boolean;
  "Service_Category_Type__c": string;
  "Service_Contract__c": string;
  "Service_Date__c": string;
  "Service_Dispatch_Frequency__c": string;
  "Service_Dispatch__c": string;
  "Service_Level_Satisfaction__c": number;
  "Service_SLA_3PL_FRU_POD__c": number;
  "Service_SLA_3PS_FE_Arrival__c": number;
  "Service_Technical_Level__c": string;
  "Service_Type__c": string;
  "ShipToEmailAddress__c": string;
  "Ship_Confirmation_Date_GMT__c": string;
  "Ship_Confirmation_Date_Local__c": string;
  "Ship_Confirmation_Date_Time__c": string;
  "Ship_Confirmation_Local__c": Date;
  "Ship_Confirmation_Time_GMT__c": string;
  "Ship_Confirmation_Time_Local__c": string;
  "Ship_To_Contact_Email_Address__c": string;
  "Ship_to_City__c": string;
  "Ship_to_Company_Name__c": string;
  "Ship_to_Contact_Name__c": string;
  "Ship_to_Contact_Phone_Number__c": string;
  "Ship_to_Country__c": string;
  "Ship_to_Postal__c": string;
  "Ship_to_State__c": string;
  "Ship_to_Street_1__c": string;
  "Ship_to_Street__c": string;
  "Shipper_Country__c": string;
  "Shipping_Address__c": string;
  "Software_Version__c": string;
  "SourceId": string;
  "Special_Instruction_from_PMS_Case_Auto__c": string;
  "Special_Terms__c": string;
  "Status": string;
  "Subject": string;
  "Subject_for_PMS_Email__c": string;
  "SuppliedCompany": string;
  "SuppliedEmail": string;
  "SuppliedName": string;
  "SuppliedPhone": string;
  "SupportStatus__c": string;
  "SystemModstamp": Date;
  "Talent_Type__c": string;
  "Talent_Type_ref__c": string;
  "Tax_ID__c": string;
  "Term_months__c": number;
  "TimeZone_Calculation_Message__c": string;
  "TimeZone_Id__c": string;
  "Time_Taken_to_Response__c": number;
  "Total_FTE_Hours__c": number;
  "Type": string;
  "Type_Facebook_Ticket_Type__c": string;
  "User_Name__c": string;
  "Vendor_Time_Card_Notes_Tasks_Performed__c": string;
  "Vendor_Time_Card_Observation_Suggestion__c": string;
  "Vendor_Time_Card_Total_Hours_All_Visits__c": number;
  "Vendor_Time_Card_Total_Hours_Per_Visit__c": number;
  "Vendor_Timecard_Cust_Site_Sign_off_Name__c": string;
  "Vendor_Timecard_Details_Roll_up__c": string;
  "Vendor__c": string;
  "WSL_Location_ID_Default__c": string;
  "WSL_Location_Readiness__c": string;
  "WSL_Location_Setup_SLA_Supported__c": string;
  "Warranty_Description__c": string;
  "Warranty_Type__c": string;
  "Work_Order_Summary__c": string;
  "Work_Order_Vendor__c": string;
  "Worker_Arrival_DateTime_Cust_Requested__c": Date;
  "Worker_Requested_Arrival_Date_Time__c": string;
  "X3PL_Case_Reference_Number__c": string;
  "X3PL_Case_Status__c": string;
  "X3PL_FSL_Location_Gateway__c": string;
  "X3PL_Parts_Not_Required__c": boolean;
  "X3PL_Vendor_Name_FSL__c": string;
  "X3PL_Vendor_Name__c": string;
  "X3PL_Vendor_Special_Instructions__c": string;
  "X3PL_WSL_Location_Gateway__c": string;
  "X3PS_Case_Status__c": string;
  "X3PS_Vendor_Special_Instructions__c": string;
  "Ticket_Resolution_Resolved_3PS_SLA__c": string;
  "SLA_Exception_Applicable_PMS_Manual__c": string;
  "B2B_Case_Close_Code__c": string;
  "PMS_Case_Status_B2B_Read__c": string;
  "PMS_Case_Status_B2B_Write__c": string;
  "Serviceo_Case_Number__c": string;
  "PMC_Case_Number": string;
  "isFRU": boolean;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  program: Project;
  Jobsite: Jobsite;
  Job: Job;
  Account: Account;
  project: Project;
  case: Case;
  worker: Worker;
  users: Users;
  ICC_Case: Case[];
  appointment: Appointment[];
  pmsAppointment: Appointment;
  purchaseOrder: PurchaseOrder;
  product: Product;
  contact: Contact;
  talentType: TalentType;
  workOrder: WorkOrder;
  jobOrderItem: JobOrderItem;
  RecordType: RecordType;
  order: Order;
  requestFormReceipt: RequestFormReceipt;
  asset: Asset;
  comment: Comment[];
  favorite: Favorite[];
  serviceContract: ServiceContract;
  escalationICC: RequestFormReceiptEscalation[];
  escalationPMS: RequestFormReceiptEscalation[];
  constructor(data?: CaseInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Case`.
   */
  public static getModelName() {
    return "Case";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Case for dynamic purposes.
  **/
  public static factory(data: CaseInterface): Case{
    return new Case(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Case',
      plural: 'Cases',
      path: 'Cases',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "AccountId": {
          name: 'AccountId',
          type: 'string'
        },
        "Account_Currency__c": {
          name: 'Account_Currency__c',
          type: 'string'
        },
        "Account_Name_Text__c": {
          name: 'Account_Name_Text__c',
          type: 'string'
        },
        "Account_Name_for_Sharing__c": {
          name: 'Account_Name_for_Sharing__c',
          type: 'string'
        },
        "Activity_Type__c": {
          name: 'Activity_Type__c',
          type: 'string'
        },
        "Actual_Finish__c": {
          name: 'Actual_Finish__c',
          type: 'Date'
        },
        "Actual_start__c": {
          name: 'Actual_start__c',
          type: 'Date'
        },
        "Appointment_Plan_Option__c": {
          name: 'Appointment_Plan_Option__c',
          type: 'string'
        },
        "Appointment_Schedule_Information__c": {
          name: 'Appointment_Schedule_Information__c',
          type: 'string'
        },
        "Appointment_Schedule_Start_Minute__c": {
          name: 'Appointment_Schedule_Start_Minute__c',
          type: 'string'
        },
        "Appointment_Schedule_Start_hour__c": {
          name: 'Appointment_Schedule_Start_hour__c',
          type: 'string'
        },
        "Appointment_Schedule_Status_Customer__c": {
          name: 'Appointment_Schedule_Status_Customer__c',
          type: 'string'
        },
        "Appointment__c": {
          name: 'Appointment__c',
          type: 'string'
        },
        "AssetId": {
          name: 'AssetId',
          type: 'string'
        },
        "Asset_Address_Does_Not_Match__c": {
          name: 'Asset_Address_Does_Not_Match__c',
          type: 'boolean'
        },
        "Asset_Description__c": {
          name: 'Asset_Description__c',
          type: 'string'
        },
        "Asset_Manufacture_Date__c": {
          name: 'Asset_Manufacture_Date__c',
          type: 'Date'
        },
        "Asset_Name__c": {
          name: 'Asset_Name__c',
          type: 'string'
        },
        "Asset_Receive_Confirmation_Return_Date__c": {
          name: 'Asset_Receive_Confirmation_Return_Date__c',
          type: 'string'
        },
        "Asset_Receive_Confirmation_Return_Time__c": {
          name: 'Asset_Receive_Confirmation_Return_Time__c',
          type: 'string'
        },
        "Asset_Serial_Number_Label__c": {
          name: 'Asset_Serial_Number_Label__c',
          type: 'string'
        },
        "Asset_Serial_Number__c": {
          name: 'Asset_Serial_Number__c',
          type: 'string'
        },
        "Asset__c": {
          name: 'Asset__c',
          type: 'string'
        },
        "Auto_Jobsite_Done__c": {
          name: 'Auto_Jobsite_Done__c',
          type: 'boolean'
        },
        "B1A_IRON_Help_Desk_Cust_Phone_Recd__c": {
          name: 'B1A_IRON_Help_Desk_Cust_Phone_Recd__c',
          type: 'boolean'
        },
        "B2A_IRON_Help_Desk_Cust_Email_Recd__c": {
          name: 'B2A_IRON_Help_Desk_Cust_Email_Recd__c',
          type: 'boolean'
        },
        "B2B_Response_Status__c": {
          name: 'B2B_Response_Status__c',
          type: 'string'
        },
        "B2_IRON_Help_Desk_Contact_Mode__c": {
          name: 'B2_IRON_Help_Desk_Contact_Mode__c',
          type: 'string'
        },
        "B3_IRON_Help_Desk_Customer_Contacted__c": {
          name: 'B3_IRON_Help_Desk_Customer_Contacted__c',
          type: 'boolean'
        },
        "Billing_Type__c": {
          name: 'Billing_Type__c',
          type: 'string'
        },
        "CKSW_BASE__EngineeringReqNumber__c": {
          name: 'CKSW_BASE__EngineeringReqNumber__c',
          type: 'string'
        },
        "CKSW_BASE__PotentialLiability__c": {
          name: 'CKSW_BASE__PotentialLiability__c',
          type: 'string'
        },
        "CKSW_BASE__Product__c": {
          name: 'CKSW_BASE__Product__c',
          type: 'string'
        },
        "CKSW_BASE__SLAViolation__c": {
          name: 'CKSW_BASE__SLAViolation__c',
          type: 'string'
        },
        "CSR_Occupancy__c": {
          name: 'CSR_Occupancy__c',
          type: 'Date'
        },
        "Carrier_Name__c": {
          name: 'Carrier_Name__c',
          type: 'string'
        },
        "Carrier_Tracking_Number__c": {
          name: 'Carrier_Tracking_Number__c',
          type: 'string'
        },
        "CaseNumber": {
          name: 'CaseNumber',
          type: 'string'
        },
        "Case_AGE_CAL_Days__c": {
          name: 'Case_AGE_CAL_Days__c',
          type: 'number'
        },
        "Case_Action_Type__c": {
          name: 'Case_Action_Type__c',
          type: 'string'
        },
        "Case_Comment__c": {
          name: 'Case_Comment__c',
          type: 'string'
        },
        "Case_Comments__c": {
          name: 'Case_Comments__c',
          type: 'string'
        },
        "Case_Created_Date__c": {
          name: 'Case_Created_Date__c',
          type: 'Date'
        },
        "Case_Event_Priority_Level__c": {
          name: 'Case_Event_Priority_Level__c',
          type: 'string'
        },
        "Case_Manager_Comments_Log__c": {
          name: 'Case_Manager_Comments_Log__c',
          type: 'string'
        },
        "Case_Record_Type_Id__c": {
          name: 'Case_Record_Type_Id__c',
          type: 'string'
        },
        "Case_Reference_Number__c": {
          name: 'Case_Reference_Number__c',
          type: 'string'
        },
        "Case_Summary__c": {
          name: 'Case_Summary__c',
          type: 'string'
        },
        "Case_resolve_at_the_first_time_Dispatch__c": {
          name: 'Case_resolve_at_the_first_time_Dispatch__c',
          type: 'number'
        },
        "Case_resolve_at_the_second_time_Dispatch__c": {
          name: 'Case_resolve_at_the_second_time_Dispatch__c',
          type: 'number'
        },
        "Cloned__c": {
          name: 'Cloned__c',
          type: 'boolean'
        },
        "ClosedDate": {
          name: 'ClosedDate',
          type: 'Date'
        },
        "Closed_To__c": {
          name: 'Closed_To__c',
          type: 'string'
        },
        "Comments": {
          name: 'Comments',
          type: 'string'
        },
        "CommunityId": {
          name: 'CommunityId',
          type: 'string'
        },
        "ContactEmail": {
          name: 'ContactEmail',
          type: 'string'
        },
        "ContactFax": {
          name: 'ContactFax',
          type: 'string'
        },
        "ContactId": {
          name: 'ContactId',
          type: 'string'
        },
        "ContactMobile": {
          name: 'ContactMobile',
          type: 'string'
        },
        "ContactPhone": {
          name: 'ContactPhone',
          type: 'string'
        },
        "Contact_Email_CustomerSite_TE__c": {
          name: 'Contact_Email_CustomerSite_TE__c',
          type: 'string'
        },
        "Contact_Email__c": {
          name: 'Contact_Email__c',
          type: 'string'
        },
        "Contact_Name_CustomerSite_TE__c": {
          name: 'Contact_Name_CustomerSite_TE__c',
          type: 'string'
        },
        "Contact_Phone_CustomerSite_TE__c": {
          name: 'Contact_Phone_CustomerSite_TE__c',
          type: 'string'
        },
        "Contact_Phone__c": {
          name: 'Contact_Phone__c',
          type: 'string'
        },
        "Contract_Order_Request__c": {
          name: 'Contract_Order_Request__c',
          type: 'string'
        },
        "Contract_Reference_Number__c": {
          name: 'Contract_Reference_Number__c',
          type: 'string'
        },
        "Contract_Service_Level_Label__c": {
          name: 'Contract_Service_Level_Label__c',
          type: 'string'
        },
        "Contract_Service_Status_Label__c": {
          name: 'Contract_Service_Status_Label__c',
          type: 'string'
        },
        "Contract_Support_Status__c": {
          name: 'Contract_Support_Status__c',
          type: 'string'
        },
        "Contract__c": {
          name: 'Contract__c',
          type: 'string'
        },
        "Count_of_Jobsite_contacts_Tech_Escal__c": {
          name: 'Count_of_Jobsite_contacts_Tech_Escal__c',
          type: 'number'
        },
        "Count_of_Jobsite_contacts_sDesk__c": {
          name: 'Count_of_Jobsite_contacts_sDesk__c',
          type: 'number'
        },
        "Count_of_Sales_Order_Items__c": {
          name: 'Count_of_Sales_Order_Items__c',
          type: 'number'
        },
        "Coverage_Hours__c": {
          name: 'Coverage_Hours__c',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "Created_Date_GMT__c": {
          name: 'Created_Date_GMT__c',
          type: 'string'
        },
        "Created_by_Contact__c": {
          name: 'Created_by_Contact__c',
          type: 'string'
        },
        "CreatorFullPhotoUrl": {
          name: 'CreatorFullPhotoUrl',
          type: 'string'
        },
        "CreatorName": {
          name: 'CreatorName',
          type: 'string'
        },
        "CreatorSmallPhotoUrl": {
          name: 'CreatorSmallPhotoUrl',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "Custom_Contract_Reference_Number__c": {
          name: 'Custom_Contract_Reference_Number__c',
          type: 'string'
        },
        "Custom_FSL_Location_Details__c": {
          name: 'Custom_FSL_Location_Details__c',
          type: 'string'
        },
        "Custom_FSL_Location_ID_Default__c": {
          name: 'Custom_FSL_Location_ID_Default__c',
          type: 'string'
        },
        "Custom_FSL_Location_SLA_Supported_Setup__c": {
          name: 'Custom_FSL_Location_SLA_Supported_Setup__c',
          type: 'string'
        },
        "Custom_FSL_Location_Setup_SLA_Supported__c": {
          name: 'Custom_FSL_Location_Setup_SLA_Supported__c',
          type: 'string'
        },
        "Custom_Service_Type__c": {
          name: 'Custom_Service_Type__c',
          type: 'string'
        },
        "Custom_SupportStatus__c": {
          name: 'Custom_SupportStatus__c',
          type: 'string'
        },
        "Custom_X3PL_Vendor_Name__c": {
          name: 'Custom_X3PL_Vendor_Name__c',
          type: 'string'
        },
        "Custom_work_order_instructions__c": {
          name: 'Custom_work_order_instructions__c',
          type: 'string'
        },
        "CustomerAppointment_Schedule_StartDate__c": {
          name: 'CustomerAppointment_Schedule_StartDate__c',
          type: 'Date'
        },
        "Customer_Priority__c": {
          name: 'Customer_Priority__c',
          type: 'string'
        },
        "Customer_Service_Type__c": {
          name: 'Customer_Service_Type__c',
          type: 'string'
        },
        "Customer_Window_End_Date__c": {
          name: 'Customer_Window_End_Date__c',
          type: 'Date'
        },
        "Customer_Window_Start_Date__c": {
          name: 'Customer_Window_Start_Date__c',
          type: 'Date'
        },
        "Customer_will_return_this_product__c": {
          name: 'Customer_will_return_this_product__c',
          type: 'boolean'
        },
        "Customersitefse_Case__c": {
          name: 'Customersitefse_Case__c',
          type: 'boolean'
        },
        "Default_WSL_Location_Details__c": {
          name: 'Default_WSL_Location_Details__c',
          type: 'string'
        },
        "Delivery_Confirmation_Custom_POD_Signat__c": {
          name: 'Delivery_Confirmation_Custom_POD_Signat__c',
          type: 'string'
        },
        "Delivery_Confirmation_DateTime_GMT__c": {
          name: 'Delivery_Confirmation_DateTime_GMT__c',
          type: 'Date'
        },
        "Delivery_Confirmation_Date_GMT__c": {
          name: 'Delivery_Confirmation_Date_GMT__c',
          type: 'string'
        },
        "Delivery_Confirmation_Date_Local__c": {
          name: 'Delivery_Confirmation_Date_Local__c',
          type: 'string'
        },
        "Delivery_Confirmation_Date_Time__c": {
          name: 'Delivery_Confirmation_Date_Time__c',
          type: 'string'
        },
        "Delivery_Confirmation_Local__c": {
          name: 'Delivery_Confirmation_Local__c',
          type: 'string'
        },
        "Delivery_Confirmation_Time_GMT__c": {
          name: 'Delivery_Confirmation_Time_GMT__c',
          type: 'string'
        },
        "Delivery_Confirmation_Time_Local__c": {
          name: 'Delivery_Confirmation_Time_Local__c',
          type: 'string'
        },
        "Description": {
          name: 'Description',
          type: 'string'
        },
        "Dispatch_Automation_Error_Message__c": {
          name: 'Dispatch_Automation_Error_Message__c',
          type: 'string'
        },
        "Dispatch_Forward_If_needed__c": {
          name: 'Dispatch_Forward_If_needed__c',
          type: 'boolean'
        },
        "Dispatch_Only__c": {
          name: 'Dispatch_Only__c',
          type: 'boolean'
        },
        "Dispatch_SLA_Priority__c": {
          name: 'Dispatch_SLA_Priority__c',
          type: 'string'
        },
        "Dispatch_Service_Resolution_Status__c": {
          name: 'Dispatch_Service_Resolution_Status__c',
          type: 'string'
        },
        "Dispatch_Status__c": {
          name: 'Dispatch_Status__c',
          type: 'string'
        },
        "Dispatch_To_Email_Verification_Audit__c": {
          name: 'Dispatch_To_Email_Verification_Audit__c',
          type: 'boolean'
        },
        "Dispatch_Worker_Name__c": {
          name: 'Dispatch_Worker_Name__c',
          type: 'string'
        },
        "Dispatch_Worker_Phone__c": {
          name: 'Dispatch_Worker_Phone__c',
          type: 'string'
        },
        "Dispatch_Worker__c": {
          name: 'Dispatch_Worker__c',
          type: 'string'
        },
        "Dispatchable__c": {
          name: 'Dispatchable__c',
          type: 'boolean'
        },
        "Do_Not_Dispatch_FE__c": {
          name: 'Do_Not_Dispatch_FE__c',
          type: 'boolean'
        },
        "Do_not_Call__c": {
          name: 'Do_not_Call__c',
          type: 'boolean'
        },
        "Duration_of_Service_Call_in_Hours__c": {
          name: 'Duration_of_Service_Call_in_Hours__c',
          type: 'string'
        },
        "Early_Start__c": {
          name: 'Early_Start__c',
          type: 'boolean'
        },
        "Exclude_in_Metrices__c": {
          name: 'Exclude_in_Metrices__c',
          type: 'boolean'
        },
        "Expected_Delivery_Date_Time__c": {
          name: 'Expected_Delivery_Date_Time__c',
          type: 'string'
        },
        "Expected_Delivery_ETA_Date_GMT__c": {
          name: 'Expected_Delivery_ETA_Date_GMT__c',
          type: 'string'
        },
        "Expected_Delivery_ETA_Date_Local__c": {
          name: 'Expected_Delivery_ETA_Date_Local__c',
          type: 'string'
        },
        "Expected_Delivery_ETA_Time_GMT__c": {
          name: 'Expected_Delivery_ETA_Time_GMT__c',
          type: 'string'
        },
        "Expected_Delivery_ETA_Time_Local__c": {
          name: 'Expected_Delivery_ETA_Time_Local__c',
          type: 'string'
        },
        "Expected_Finish__c": {
          name: 'Expected_Finish__c',
          type: 'Date'
        },
        "FA_Required__c": {
          name: 'FA_Required__c',
          type: 'boolean'
        },
        "FA_Return_To_Address__c": {
          name: 'FA_Return_To_Address__c',
          type: 'string'
        },
        "FA_Return_to_Location__c": {
          name: 'FA_Return_to_Location__c',
          type: 'string'
        },
        "FA_Return_to__c": {
          name: 'FA_Return_to__c',
          type: 'boolean'
        },
        "FE2_Actual_Arrival_Date_Time__c": {
          name: 'FE2_Actual_Arrival_Date_Time__c',
          type: 'string'
        },
        "FE2_Actual_Arrival_GMT__c": {
          name: 'FE2_Actual_Arrival_GMT__c',
          type: 'string'
        },
        "FE2_Actual_Arrival_Local_Date_Time__c": {
          name: 'FE2_Actual_Arrival_Local_Date_Time__c',
          type: 'string'
        },
        "FE2_Delivered_Departure_Date_Time__c": {
          name: 'FE2_Delivered_Departure_Date_Time__c',
          type: 'string'
        },
        "FE2_Delivered_Departure_GMT__c": {
          name: 'FE2_Delivered_Departure_GMT__c',
          type: 'string'
        },
        "FE2_Dispatch_ID__c": {
          name: 'FE2_Dispatch_ID__c',
          type: 'string'
        },
        "FE2_Estimated_On_Site_ETA_Date_Time__c": {
          name: 'FE2_Estimated_On_Site_ETA_Date_Time__c',
          type: 'string'
        },
        "FE2_Estimated_On_Site_ETA_GMT__c": {
          name: 'FE2_Estimated_On_Site_ETA_GMT__c',
          type: 'string'
        },
        "FE2_Estimated_On_Site_ETA_Local_Date_Ti__c": {
          name: 'FE2_Estimated_On_Site_ETA_Local_Date_Ti__c',
          type: 'string'
        },
        "FE2_Onsite_SLA_Comments__c": {
          name: 'FE2_Onsite_SLA_Comments__c',
          type: 'string'
        },
        "FE2_Phone_Number__c": {
          name: 'FE2_Phone_Number__c',
          type: 'string'
        },
        "FE_AM_PM__c": {
          name: 'FE_AM_PM__c',
          type: 'string'
        },
        "FE_Actual_Arrival_Date_Time__c": {
          name: 'FE_Actual_Arrival_Date_Time__c',
          type: 'string'
        },
        "FE_Actual_Arrival_GMT__c": {
          name: 'FE_Actual_Arrival_GMT__c',
          type: 'string'
        },
        "FE_Actual_Arrival_Local_Date_Time__c": {
          name: 'FE_Actual_Arrival_Local_Date_Time__c',
          type: 'string'
        },
        "FE_Arrival_Date_OutPut__c": {
          name: 'FE_Arrival_Date_OutPut__c',
          type: 'Date'
        },
        "FE_Arrival_Date_Time__c": {
          name: 'FE_Arrival_Date_Time__c',
          type: 'string'
        },
        "FE_Arrival_Date__c": {
          name: 'FE_Arrival_Date__c',
          type: 'Date'
        },
        "FE_Arrival_Time_OutPut__c": {
          name: 'FE_Arrival_Time_OutPut__c',
          type: 'string'
        },
        "FE_Arrival_Time__c": {
          name: 'FE_Arrival_Time__c',
          type: 'string'
        },
        "FE_Delivered_Departure_Date_Time__c": {
          name: 'FE_Delivered_Departure_Date_Time__c',
          type: 'string'
        },
        "FE_Delivered_Departure_GMT__c": {
          name: 'FE_Delivered_Departure_GMT__c',
          type: 'string'
        },
        "FE_Departure__c": {
          name: 'FE_Departure__c',
          type: 'string'
        },
        "FE_Email__c": {
          name: 'FE_Email__c',
          type: 'string'
        },
        "FE_Estimated_On_Site_ETA_Date_Time__c": {
          name: 'FE_Estimated_On_Site_ETA_Date_Time__c',
          type: 'string'
        },
        "FE_Estimated_On_Site_ETA_GMT__c": {
          name: 'FE_Estimated_On_Site_ETA_GMT__c',
          type: 'string'
        },
        "FE_ID_Number__c": {
          name: 'FE_ID_Number__c',
          type: 'string'
        },
        "FE_Name__c": {
          name: 'FE_Name__c',
          type: 'string'
        },
        "FE_Phone_Number__c": {
          name: 'FE_Phone_Number__c',
          type: 'string'
        },
        "FE_Requested_Arrival_Date_Time__c": {
          name: 'FE_Requested_Arrival_Date_Time__c',
          type: 'string'
        },
        "FE_SLA_Met__c": {
          name: 'FE_SLA_Met__c',
          type: 'boolean'
        },
        "FRU_SKU__c": {
          name: 'FRU_SKU__c',
          type: 'string'
        },
        "FSL_Location_Details__c": {
          name: 'FSL_Location_Details__c',
          type: 'string'
        },
        "FSL_Location_ID_Default__c": {
          name: 'FSL_Location_ID_Default__c',
          type: 'string'
        },
        "FSL_Location_Readiness__c": {
          name: 'FSL_Location_Readiness__c',
          type: 'string'
        },
        "FSL_Location_SLA_Supported_Setup__c": {
          name: 'FSL_Location_SLA_Supported_Setup__c',
          type: 'string'
        },
        "FSL_Location_Setup_SLA_Supported__c": {
          name: 'FSL_Location_Setup_SLA_Supported__c',
          type: 'string'
        },
        "FTE_Configuration_Type__c": {
          name: 'FTE_Configuration_Type__c',
          type: 'string'
        },
        "Field_Service_Schedule_ETA_Date_Time__c": {
          name: 'Field_Service_Schedule_ETA_Date_Time__c',
          type: 'Date'
        },
        "Field_Service_Schedule_ETA_Date__c": {
          name: 'Field_Service_Schedule_ETA_Date__c',
          type: 'Date'
        },
        "First_Response__c": {
          name: 'First_Response__c',
          type: 'Date'
        },
        "First_response_CT__c": {
          name: 'First_response_CT__c',
          type: 'boolean'
        },
        "GEO_Metro__c": {
          name: 'GEO_Metro__c',
          type: 'string'
        },
        "HasCommentsUnreadByOwner": {
          name: 'HasCommentsUnreadByOwner',
          type: 'boolean'
        },
        "HasSelfServiceComments": {
          name: 'HasSelfServiceComments',
          type: 'boolean'
        },
        "Has_Appointment__c": {
          name: 'Has_Appointment__c',
          type: 'boolean'
        },
        "Health_Asset_Sku_Comparison__c": {
          name: 'Health_Asset_Sku_Comparison__c',
          type: 'string'
        },
        "Health_Custom_Window__c": {
          name: 'Health_Custom_Window__c',
          type: 'string'
        },
        "Health_Customer_Pricelist_Status__c": {
          name: 'Health_Customer_Pricelist_Status__c',
          type: 'string'
        },
        "Health_Dispatch_Status__c": {
          name: 'Health_Dispatch_Status__c',
          type: 'string'
        },
        "Health_Jobsite_Status__c": {
          name: 'Health_Jobsite_Status__c',
          type: 'string'
        },
        "Health_OrderItem_Status__c": {
          name: 'Health_OrderItem_Status__c',
          type: 'string'
        },
        "Health_Pricelist_Status__c": {
          name: 'Health_Pricelist_Status__c',
          type: 'string'
        },
        "Health_Program_Pricelist__c": {
          name: 'Health_Program_Pricelist__c',
          type: 'string'
        },
        "Health_Project_status__c": {
          name: 'Health_Project_status__c',
          type: 'string'
        },
        "Health_SDesk_Contact__c": {
          name: 'Health_SDesk_Contact__c',
          type: 'string'
        },
        "Health_SKU_Price_Status__c": {
          name: 'Health_SKU_Price_Status__c',
          type: 'string'
        },
        "Health_SKU_Price__c": {
          name: 'Health_SKU_Price__c',
          type: 'boolean'
        },
        "Health_SKU_Status__c": {
          name: 'Health_SKU_Status__c',
          type: 'string'
        },
        "Health_SO_Status__c": {
          name: 'Health_SO_Status__c',
          type: 'string'
        },
        "Health_TEsc_Contact__c": {
          name: 'Health_TEsc_Contact__c',
          type: 'string'
        },
        "Health_Transaction_Status__c": {
          name: 'Health_Transaction_Status__c',
          type: 'string'
        },
        "Health_Vendor_Status__c": {
          name: 'Health_Vendor_Status__c',
          type: 'string'
        },
        "How_to_reproduce_Bug__c": {
          name: 'How_to_reproduce_Bug__c',
          type: 'string'
        },
        "ICC_Case_Closed_Date_Time__c": {
          name: 'ICC_Case_Closed_Date_Time__c',
          type: 'Date'
        },
        "ICC_Case_Created_Date_Time__c": {
          name: 'ICC_Case_Created_Date_Time__c',
          type: 'Date'
        },
        "IRON_Case_Notes_for_Partner__c": {
          name: 'IRON_Case_Notes_for_Partner__c',
          type: 'string'
        },
        "IRON_Custom_Contract__c": {
          name: 'IRON_Custom_Contract__c',
          type: 'string'
        },
        "IRON_ICC_Case_Number__c": {
          name: 'IRON_ICC_Case_Number__c',
          type: 'string'
        },
        "IRON_Local_Service_Center_Email_Portal__c": {
          name: 'IRON_Local_Service_Center_Email_Portal__c',
          type: 'boolean'
        },
        "IRON_PMS_Case_Number__c": {
          name: 'IRON_PMS_Case_Number__c',
          type: 'string'
        },
        "IRON_Project_Reference__c": {
          name: 'IRON_Project_Reference__c',
          type: 'string'
        },
        "IRON_RMA_Required__c": {
          name: 'IRON_RMA_Required__c',
          type: 'string'
        },
        "IRON_RMA_Ticket__c": {
          name: 'IRON_RMA_Ticket__c',
          type: 'string'
        },
        "Iron_Case_Notes_Internal__c": {
          name: 'Iron_Case_Notes_Internal__c',
          type: 'string'
        },
        "Iron_Case_Number__c": {
          name: 'Iron_Case_Number__c',
          type: 'string'
        },
        "Iron_Reason_for_the_error__c": {
          name: 'Iron_Reason_for_the_error__c',
          type: 'string'
        },
        "Iron_Service_Contract__c": {
          name: 'Iron_Service_Contract__c',
          type: 'string'
        },
        "IsClosed": {
          name: 'IsClosed',
          type: 'boolean'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "IsEscalated": {
          name: 'IsEscalated',
          type: 'boolean'
        },
        "IsPartArrivalTimeModified__c": {
          name: 'IsPartArrivalTimeModified__c',
          type: 'boolean'
        },
        "Is_this_FSR_Order__c": {
          name: 'Is_this_FSR_Order__c',
          type: 'boolean'
        },
        "Issue_Start_Date_Input__c": {
          name: 'Issue_Start_Date_Input__c',
          type: 'Date'
        },
        "Issue_Start_Date__c": {
          name: 'Issue_Start_Date__c',
          type: 'Date'
        },
        "Issue_Start_Time__c": {
          name: 'Issue_Start_Time__c',
          type: 'string'
        },
        "Item_Quantity__c": {
          name: 'Item_Quantity__c',
          type: 'string'
        },
        "Item_SKU_ID__c": {
          name: 'Item_SKU_ID__c',
          type: 'string'
        },
        "Item_Scrap_Plan__c": {
          name: 'Item_Scrap_Plan__c',
          type: 'string'
        },
        "Item_Serial_Number__c": {
          name: 'Item_Serial_Number__c',
          type: 'string'
        },
        "Item_Summary__c": {
          name: 'Item_Summary__c',
          type: 'string'
        },
        "Job_Order_Item__c": {
          name: 'Job_Order_Item__c',
          type: 'string'
        },
        "Job_Order__c": {
          name: 'Job_Order__c',
          type: 'string'
        },
        "Job_Start_Override__c": {
          name: 'Job_Start_Override__c',
          type: 'Date'
        },
        "Jobsite_Country_Matches_Project_Country__c": {
          name: 'Jobsite_Country_Matches_Project_Country__c',
          type: 'boolean'
        },
        "Jobsite_Country__c": {
          name: 'Jobsite_Country__c',
          type: 'string'
        },
        "Jobsite__c": {
          name: 'Jobsite__c',
          type: 'string'
        },
        "Last_Modified_By__c": {
          name: 'Last_Modified_By__c',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "LastReferencedDate": {
          name: 'LastReferencedDate',
          type: 'Date'
        },
        "LastViewedDate": {
          name: 'LastViewedDate',
          type: 'Date'
        },
        "Last_Modified_minus_one_hour__c": {
          name: 'Last_Modified_minus_one_hour__c',
          type: 'Date'
        },
        "Milestone__c": {
          name: 'Milestone__c',
          type: 'string'
        },
        "Model_Number_Label__c": {
          name: 'Model_Number_Label__c',
          type: 'string'
        },
        "Model_Number__c": {
          name: 'Model_Number__c',
          type: 'string'
        },
        "Modified_Date_GMT__c": {
          name: 'Modified_Date_GMT__c',
          type: 'string'
        },
        "NRSSD__c": {
          name: 'NRSSD__c',
          type: 'boolean'
        },
        "New_Case_Email_Alert_Sent__c": {
          name: 'New_Case_Email_Alert_Sent__c',
          type: 'boolean'
        },
        "New_FSL_Location_Map_Registration_Req__c": {
          name: 'New_FSL_Location_Map_Registration_Req__c',
          type: 'boolean'
        },
        "OEM_RMA_Case_Number__c": {
          name: 'OEM_RMA_Case_Number__c',
          type: 'string'
        },
        "Onsite_SLA_Comments__c": {
          name: 'Onsite_SLA_Comments__c',
          type: 'string'
        },
        "Origin": {
          name: 'Origin',
          type: 'string'
        },
        "Original_Email__c": {
          name: 'Original_Email__c',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "PMS_Case_Cycle_Time__c": {
          name: 'PMS_Case_Cycle_Time__c',
          type: 'number'
        },
        "PPE_Hours__c": {
          name: 'PPE_Hours__c',
          type: 'string'
        },
        "ParentId": {
          name: 'ParentId',
          type: 'string'
        },
        "Part_AM_PM__c": {
          name: 'Part_AM_PM__c',
          type: 'string'
        },
        "Part_Arrival_Date_OutPut__c": {
          name: 'Part_Arrival_Date_OutPut__c',
          type: 'Date'
        },
        "Part_Arrival_Date_Time__c": {
          name: 'Part_Arrival_Date_Time__c',
          type: 'string'
        },
        "Part_Arrival_Date__c": {
          name: 'Part_Arrival_Date__c',
          type: 'Date'
        },
        "Part_Arrival_Time_OutPut__c": {
          name: 'Part_Arrival_Time_OutPut__c',
          type: 'string'
        },
        "Part_Arrival_Time__c": {
          name: 'Part_Arrival_Time__c',
          type: 'string'
        },
        "Part_Sch_Request_Date_and_Time__c": {
          name: 'Part_Sch_Request_Date_and_Time__c',
          type: 'string'
        },
        "PartnerPortelCase__c": {
          name: 'PartnerPortelCase__c',
          type: 'boolean'
        },
        "Partner_Account__c": {
          name: 'Partner_Account__c',
          type: 'string'
        },
        "Partner_Account_for_Sharing__c": {
          name: 'Partner_Account_for_Sharing__c',
          type: 'string'
        },
        "Partner_Asset_Item__c": {
          name: 'Partner_Asset_Item__c',
          type: 'string'
        },
        "Partner_Case_Error_Description__c": {
          name: 'Partner_Case_Error_Description__c',
          type: 'string'
        },
        "Partner_Case_Number__c": {
          name: 'Partner_Case_Number__c',
          type: 'string'
        },
        "Partner_FRU_Sku__c": {
          name: 'Partner_FRU_Sku__c',
          type: 'string'
        },
        "Partner_Pricelist_ID_from_SOP_Auto__c": {
          name: 'Partner_Pricelist_ID_from_SOP_Auto__c',
          type: 'string'
        },
        "Partner_RMA_Number__c": {
          name: 'Partner_RMA_Number__c',
          type: 'string'
        },
        "Parts_Requested_Arrival_Date_GMT__c": {
          name: 'Parts_Requested_Arrival_Date_GMT__c',
          type: 'Date'
        },
        "Parts_Requested_Arrival_Date_Time_GMT__c": {
          name: 'Parts_Requested_Arrival_Date_Time_GMT__c',
          type: 'string'
        },
        "Parts_Requested_Arrival_Date_Time__c": {
          name: 'Parts_Requested_Arrival_Date_Time__c',
          type: 'string'
        },
        "Parts_Requested_Arrival_Time_GMT__c": {
          name: 'Parts_Requested_Arrival_Time_GMT__c',
          type: 'string'
        },
        "Perform_Post_Dispatch_Actions_FTE__c": {
          name: 'Perform_Post_Dispatch_Actions_FTE__c',
          type: 'boolean'
        },
        "Phone_Scheduling_1st_Attempt_Unreachable__c": {
          name: 'Phone_Scheduling_1st_Attempt_Unreachable__c',
          type: 'Date'
        },
        "Phone_Scheduling_2nd_Attempt_Unreachable__c": {
          name: 'Phone_Scheduling_2nd_Attempt_Unreachable__c',
          type: 'Date'
        },
        "Phone_Scheduling_3rd_Attempt_Unreachable__c": {
          name: 'Phone_Scheduling_3rd_Attempt_Unreachable__c',
          type: 'Date'
        },
        "Pricelist_on_Program_of_Project__c": {
          name: 'Pricelist_on_Program_of_Project__c',
          type: 'string'
        },
        "Priority": {
          name: 'Priority',
          type: 'string'
        },
        "Project_Error__c": {
          name: 'Project_Error__c',
          type: 'boolean'
        },
        "Project_SOP__c": {
          name: 'Project_SOP__c',
          type: 'string'
        },
        "Project_Vendor_Name__c": {
          name: 'Project_Vendor_Name__c',
          type: 'string'
        },
        "Project__c": {
          name: 'Project__c',
          type: 'string'
        },
        "Purchase_Order_Currency__c": {
          name: 'Purchase_Order_Currency__c',
          type: 'string'
        },
        "Purchase_Order_Reference__c": {
          name: 'Purchase_Order_Reference__c',
          type: 'string'
        },
        "Re_calc_Sch_Parts_Req_Arr_Date_Time__c": {
          name: 'Re_calc_Sch_Parts_Req_Arr_Date_Time__c',
          type: 'string'
        },
        "Reason": {
          name: 'Reason',
          type: 'string'
        },
        "Reason_for_Failure__c": {
          name: 'Reason_for_Failure__c',
          type: 'string'
        },
        "Recent_Case_Comment__c": {
          name: 'Recent_Case_Comment__c',
          type: 'string'
        },
        "RecordTypeId": {
          name: 'RecordTypeId',
          type: 'string'
        },
        "Record_Id__c": {
          name: 'Record_Id__c',
          type: 'string'
        },
        "is_backfill_worker": {
          name: 'is_backfill_worker',
          type: 'boolean'
        },
        "Recovery_Carrier_Name__c": {
          name: 'Recovery_Carrier_Name__c',
          type: 'string'
        },
        "Recovery_Carrier_Tracking_Number__c": {
          name: 'Recovery_Carrier_Tracking_Number__c',
          type: 'string'
        },
        "Regenerate_PO__c": {
          name: 'Regenerate_PO__c',
          type: 'boolean'
        },
        "Regenerate_SO__c": {
          name: 'Regenerate_SO__c',
          type: 'boolean'
        },
        "Request_Description__c": {
          name: 'Request_Description__c',
          type: 'string'
        },
        "Requested_Schedule_Time__c": {
          name: 'Requested_Schedule_Time__c',
          type: 'string'
        },
        "Requested_Schedule__c": {
          name: 'Requested_Schedule__c',
          type: 'string'
        },
        "Return_FSL_Address__c": {
          name: 'Return_FSL_Address__c',
          type: 'string'
        },
        "Return_FSL_Contact__c": {
          name: 'Return_FSL_Contact__c',
          type: 'string'
        },
        "Return_FSL_Country__c": {
          name: 'Return_FSL_Country__c',
          type: 'string'
        },
        "Return_FSL_Location_ID__c": {
          name: 'Return_FSL_Location_ID__c',
          type: 'string'
        },
        "Return_FSL_Phone__c": {
          name: 'Return_FSL_Phone__c',
          type: 'string'
        },
        "Return_Item_Quantity__c": {
          name: 'Return_Item_Quantity__c',
          type: 'string'
        },
        "Return_Item_SKU_ID__c": {
          name: 'Return_Item_SKU_ID__c',
          type: 'string'
        },
        "Return_Item_Serial_Number__c": {
          name: 'Return_Item_Serial_Number__c',
          type: 'string'
        },
        "SKU__c": {
          name: 'SKU__c',
          type: 'string'
        },
        "SLA_3PL_ContractRequired__c": {
          name: 'SLA_3PL_ContractRequired__c',
          type: 'Date'
        },
        "SLA_3PL_FRU_ARRIVALPOD__c": {
          name: 'SLA_3PL_FRU_ARRIVALPOD__c',
          type: 'Date'
        },
        "SLA_3PL_Met__c": {
          name: 'SLA_3PL_Met__c',
          type: 'string'
        },
        "SLA_3PS_Contract_Required__c": {
          name: 'SLA_3PS_Contract_Required__c',
          type: 'Date'
        },
        "SLA_3PS_FE_Arrival__c": {
          name: 'SLA_3PS_FE_Arrival__c',
          type: 'Date'
        },
        "SLA_3PS_Met_Formula__c": {
          name: 'SLA_3PS_Met_Formula__c',
          type: 'string'
        },
        "SLA_3PS_Met_Override__c": {
          name: 'SLA_3PS_Met_Override__c',
          type: 'boolean'
        },
        "SLA_3PS_Met__c": {
          name: 'SLA_3PS_Met__c',
          type: 'string'
        },
        "SLA_Delivery_Target_Date_Time__c": {
          name: 'SLA_Delivery_Target_Date_Time__c',
          type: 'string'
        },
        "SLA_Delivery_Target_SLA_Date_GMT__c": {
          name: 'SLA_Delivery_Target_SLA_Date_GMT__c',
          type: 'string'
        },
        "SLA_Delivery_Target_SLA_Date_Local__c": {
          name: 'SLA_Delivery_Target_SLA_Date_Local__c',
          type: 'string'
        },
        "SLA_Delivery_Target_SLA_Time_GMT__c": {
          name: 'SLA_Delivery_Target_SLA_Time_GMT__c',
          type: 'string'
        },
        "SLA_Delivery_Target_SLA_Time_Local__c": {
          name: 'SLA_Delivery_Target_SLA_Time_Local__c',
          type: 'string'
        },
        "SLA_Met_3PL_Override__c": {
          name: 'SLA_Met_3PL_Override__c',
          type: 'boolean'
        },
        "SLA_Met_3PL__c": {
          name: 'SLA_Met_3PL__c',
          type: 'boolean'
        },
        "SLA_Met__c": {
          name: 'SLA_Met__c',
          type: 'boolean'
        },
        "SLA_Scheduled_Override_based_End_Date__c": {
          name: 'SLA_Scheduled_Override_based_End_Date__c',
          type: 'Date'
        },
        "SLA_Term_based_End_Date_Time__c": {
          name: 'SLA_Term_based_End_Date_Time__c',
          type: 'Date'
        },
        "SLA_is_OK__c": {
          name: 'SLA_is_OK__c',
          type: 'boolean'
        },
        "SR_ID_Number__c": {
          name: 'SR_ID_Number__c',
          type: 'string'
        },
        "SR_Summary__c": {
          name: 'SR_Summary__c',
          type: 'string'
        },
        "SSSSecond_FE_Arrival_Date_Time__c": {
          name: 'SSSSecond_FE_Arrival_Date_Time__c',
          type: 'string'
        },
        "SSS_FE_Arrival_Date_Time__c": {
          name: 'SSS_FE_Arrival_Date_Time__c',
          type: 'string'
        },
        "SS_Case_ID__c": {
          name: 'SS_Case_ID__c',
          type: 'string'
        },
        "SS_Case_Number__c": {
          name: 'SS_Case_Number__c',
          type: 'string'
        },
        "SS_Dispatch_ID__c": {
          name: 'SS_Dispatch_ID__c',
          type: 'string'
        },
        "SS_Sales_Order_ID__c": {
          name: 'SS_Sales_Order_ID__c',
          type: 'string'
        },
        "SS_Sales_Order_Number__c": {
          name: 'SS_Sales_Order_Number__c',
          type: 'string'
        },
        "Sales_Order_Created__c": {
          name: 'Sales_Order_Created__c',
          type: 'boolean'
        },
        "Sales_Order_Reference__c": {
          name: 'Sales_Order_Reference__c',
          type: 'string'
        },
        "Salesorder_To_be_Created__c": {
          name: 'Salesorder_To_be_Created__c',
          type: 'boolean'
        },
        "Sch_Parts_Requested_Arrival_DateTimeGMT__c": {
          name: 'Sch_Parts_Requested_Arrival_DateTimeGMT__c',
          type: 'Date'
        },
        "Schedule_FE_Requested_Arrival__c": {
          name: 'Schedule_FE_Requested_Arrival__c',
          type: 'string'
        },
        "Schedule_Type_Weekly_Monthly__c": {
          name: 'Schedule_Type_Weekly_Monthly__c',
          type: 'string'
        },
        "Schedule__c": {
          name: 'Schedule__c',
          type: 'string'
        },
        "Second_FE_AM_PM__c": {
          name: 'Second_FE_AM_PM__c',
          type: 'string'
        },
        "Second_FE_Arrival_Date_Time__c": {
          name: 'Second_FE_Arrival_Date_Time__c',
          type: 'string'
        },
        "Second_FE_Arrival_Date__c": {
          name: 'Second_FE_Arrival_Date__c',
          type: 'string'
        },
        "Second_FE_Arrival_Time__c": {
          name: 'Second_FE_Arrival_Time__c',
          type: 'string'
        },
        "Second_FE_Departure__c": {
          name: 'Second_FE_Departure__c',
          type: 'string'
        },
        "Second_FE_Email__c": {
          name: 'Second_FE_Email__c',
          type: 'string'
        },
        "Second_FE_ID_Number__c": {
          name: 'Second_FE_ID_Number__c',
          type: 'string'
        },
        "Second_FE_Name__c": {
          name: 'Second_FE_Name__c',
          type: 'string'
        },
        "Second_Onsite_Needed__c": {
          name: 'Second_Onsite_Needed__c',
          type: 'boolean'
        },
        "Second_Service_Date__c": {
          name: 'Second_Service_Date__c',
          type: 'string'
        },
        "Send_Manual_Service_Dispatch__c": {
          name: 'Send_Manual_Service_Dispatch__c',
          type: 'boolean'
        },
        "Send_to_SSS__c": {
          name: 'Send_to_SSS__c',
          type: 'boolean'
        },
        "Service_Category_Type__c": {
          name: 'Service_Category_Type__c',
          type: 'string'
        },
        "Service_Contract__c": {
          name: 'Service_Contract__c',
          type: 'string'
        },
        "Service_Date__c": {
          name: 'Service_Date__c',
          type: 'string'
        },
        "Service_Dispatch_Frequency__c": {
          name: 'Service_Dispatch_Frequency__c',
          type: 'string'
        },
        "Service_Dispatch__c": {
          name: 'Service_Dispatch__c',
          type: 'string'
        },
        "Service_Level_Satisfaction__c": {
          name: 'Service_Level_Satisfaction__c',
          type: 'number'
        },
        "Service_SLA_3PL_FRU_POD__c": {
          name: 'Service_SLA_3PL_FRU_POD__c',
          type: 'number'
        },
        "Service_SLA_3PS_FE_Arrival__c": {
          name: 'Service_SLA_3PS_FE_Arrival__c',
          type: 'number'
        },
        "Service_Technical_Level__c": {
          name: 'Service_Technical_Level__c',
          type: 'string'
        },
        "Service_Type__c": {
          name: 'Service_Type__c',
          type: 'string'
        },
        "ShipToEmailAddress__c": {
          name: 'ShipToEmailAddress__c',
          type: 'string'
        },
        "Ship_Confirmation_Date_GMT__c": {
          name: 'Ship_Confirmation_Date_GMT__c',
          type: 'string'
        },
        "Ship_Confirmation_Date_Local__c": {
          name: 'Ship_Confirmation_Date_Local__c',
          type: 'string'
        },
        "Ship_Confirmation_Date_Time__c": {
          name: 'Ship_Confirmation_Date_Time__c',
          type: 'string'
        },
        "Ship_Confirmation_Local__c": {
          name: 'Ship_Confirmation_Local__c',
          type: 'Date'
        },
        "Ship_Confirmation_Time_GMT__c": {
          name: 'Ship_Confirmation_Time_GMT__c',
          type: 'string'
        },
        "Ship_Confirmation_Time_Local__c": {
          name: 'Ship_Confirmation_Time_Local__c',
          type: 'string'
        },
        "Ship_To_Contact_Email_Address__c": {
          name: 'Ship_To_Contact_Email_Address__c',
          type: 'string'
        },
        "Ship_to_City__c": {
          name: 'Ship_to_City__c',
          type: 'string'
        },
        "Ship_to_Company_Name__c": {
          name: 'Ship_to_Company_Name__c',
          type: 'string'
        },
        "Ship_to_Contact_Name__c": {
          name: 'Ship_to_Contact_Name__c',
          type: 'string'
        },
        "Ship_to_Contact_Phone_Number__c": {
          name: 'Ship_to_Contact_Phone_Number__c',
          type: 'string'
        },
        "Ship_to_Country__c": {
          name: 'Ship_to_Country__c',
          type: 'string'
        },
        "Ship_to_Postal__c": {
          name: 'Ship_to_Postal__c',
          type: 'string'
        },
        "Ship_to_State__c": {
          name: 'Ship_to_State__c',
          type: 'string'
        },
        "Ship_to_Street_1__c": {
          name: 'Ship_to_Street_1__c',
          type: 'string'
        },
        "Ship_to_Street__c": {
          name: 'Ship_to_Street__c',
          type: 'string'
        },
        "Shipper_Country__c": {
          name: 'Shipper_Country__c',
          type: 'string'
        },
        "Shipping_Address__c": {
          name: 'Shipping_Address__c',
          type: 'string'
        },
        "Software_Version__c": {
          name: 'Software_Version__c',
          type: 'string'
        },
        "SourceId": {
          name: 'SourceId',
          type: 'string'
        },
        "Special_Instruction_from_PMS_Case_Auto__c": {
          name: 'Special_Instruction_from_PMS_Case_Auto__c',
          type: 'string'
        },
        "Special_Terms__c": {
          name: 'Special_Terms__c',
          type: 'string'
        },
        "Status": {
          name: 'Status',
          type: 'string'
        },
        "Subject": {
          name: 'Subject',
          type: 'string'
        },
        "Subject_for_PMS_Email__c": {
          name: 'Subject_for_PMS_Email__c',
          type: 'string'
        },
        "SuppliedCompany": {
          name: 'SuppliedCompany',
          type: 'string'
        },
        "SuppliedEmail": {
          name: 'SuppliedEmail',
          type: 'string'
        },
        "SuppliedName": {
          name: 'SuppliedName',
          type: 'string'
        },
        "SuppliedPhone": {
          name: 'SuppliedPhone',
          type: 'string'
        },
        "SupportStatus__c": {
          name: 'SupportStatus__c',
          type: 'string'
        },
        "SystemModstamp": {
          name: 'SystemModstamp',
          type: 'Date'
        },
        "Talent_Type__c": {
          name: 'Talent_Type__c',
          type: 'string'
        },
        "Talent_Type_ref__c": {
          name: 'Talent_Type_ref__c',
          type: 'string'
        },
        "Tax_ID__c": {
          name: 'Tax_ID__c',
          type: 'string'
        },
        "Term_months__c": {
          name: 'Term_months__c',
          type: 'number'
        },
        "TimeZone_Calculation_Message__c": {
          name: 'TimeZone_Calculation_Message__c',
          type: 'string'
        },
        "TimeZone_Id__c": {
          name: 'TimeZone_Id__c',
          type: 'string'
        },
        "Time_Taken_to_Response__c": {
          name: 'Time_Taken_to_Response__c',
          type: 'number'
        },
        "Total_FTE_Hours__c": {
          name: 'Total_FTE_Hours__c',
          type: 'number'
        },
        "Type": {
          name: 'Type',
          type: 'string'
        },
        "Type_Facebook_Ticket_Type__c": {
          name: 'Type_Facebook_Ticket_Type__c',
          type: 'string'
        },
        "User_Name__c": {
          name: 'User_Name__c',
          type: 'string'
        },
        "Vendor_Time_Card_Notes_Tasks_Performed__c": {
          name: 'Vendor_Time_Card_Notes_Tasks_Performed__c',
          type: 'string'
        },
        "Vendor_Time_Card_Observation_Suggestion__c": {
          name: 'Vendor_Time_Card_Observation_Suggestion__c',
          type: 'string'
        },
        "Vendor_Time_Card_Total_Hours_All_Visits__c": {
          name: 'Vendor_Time_Card_Total_Hours_All_Visits__c',
          type: 'number'
        },
        "Vendor_Time_Card_Total_Hours_Per_Visit__c": {
          name: 'Vendor_Time_Card_Total_Hours_Per_Visit__c',
          type: 'number'
        },
        "Vendor_Timecard_Cust_Site_Sign_off_Name__c": {
          name: 'Vendor_Timecard_Cust_Site_Sign_off_Name__c',
          type: 'string'
        },
        "Vendor_Timecard_Details_Roll_up__c": {
          name: 'Vendor_Timecard_Details_Roll_up__c',
          type: 'string'
        },
        "Vendor__c": {
          name: 'Vendor__c',
          type: 'string'
        },
        "WSL_Location_ID_Default__c": {
          name: 'WSL_Location_ID_Default__c',
          type: 'string'
        },
        "WSL_Location_Readiness__c": {
          name: 'WSL_Location_Readiness__c',
          type: 'string'
        },
        "WSL_Location_Setup_SLA_Supported__c": {
          name: 'WSL_Location_Setup_SLA_Supported__c',
          type: 'string'
        },
        "Warranty_Description__c": {
          name: 'Warranty_Description__c',
          type: 'string'
        },
        "Warranty_Type__c": {
          name: 'Warranty_Type__c',
          type: 'string'
        },
        "Work_Order_Summary__c": {
          name: 'Work_Order_Summary__c',
          type: 'string'
        },
        "Work_Order_Vendor__c": {
          name: 'Work_Order_Vendor__c',
          type: 'string'
        },
        "Worker_Arrival_DateTime_Cust_Requested__c": {
          name: 'Worker_Arrival_DateTime_Cust_Requested__c',
          type: 'Date'
        },
        "Worker_Requested_Arrival_Date_Time__c": {
          name: 'Worker_Requested_Arrival_Date_Time__c',
          type: 'string'
        },
        "X3PL_Case_Reference_Number__c": {
          name: 'X3PL_Case_Reference_Number__c',
          type: 'string'
        },
        "X3PL_Case_Status__c": {
          name: 'X3PL_Case_Status__c',
          type: 'string'
        },
        "X3PL_FSL_Location_Gateway__c": {
          name: 'X3PL_FSL_Location_Gateway__c',
          type: 'string'
        },
        "X3PL_Parts_Not_Required__c": {
          name: 'X3PL_Parts_Not_Required__c',
          type: 'boolean'
        },
        "X3PL_Vendor_Name_FSL__c": {
          name: 'X3PL_Vendor_Name_FSL__c',
          type: 'string'
        },
        "X3PL_Vendor_Name__c": {
          name: 'X3PL_Vendor_Name__c',
          type: 'string'
        },
        "X3PL_Vendor_Special_Instructions__c": {
          name: 'X3PL_Vendor_Special_Instructions__c',
          type: 'string'
        },
        "X3PL_WSL_Location_Gateway__c": {
          name: 'X3PL_WSL_Location_Gateway__c',
          type: 'string'
        },
        "X3PS_Case_Status__c": {
          name: 'X3PS_Case_Status__c',
          type: 'string'
        },
        "X3PS_Vendor_Special_Instructions__c": {
          name: 'X3PS_Vendor_Special_Instructions__c',
          type: 'string'
        },
        "Ticket_Resolution_Resolved_3PS_SLA__c": {
          name: 'Ticket_Resolution_Resolved_3PS_SLA__c',
          type: 'string'
        },
        "SLA_Exception_Applicable_PMS_Manual__c": {
          name: 'SLA_Exception_Applicable_PMS_Manual__c',
          type: 'string'
        },
        "B2B_Case_Close_Code__c": {
          name: 'B2B_Case_Close_Code__c',
          type: 'string'
        },
        "PMS_Case_Status_B2B_Read__c": {
          name: 'PMS_Case_Status_B2B_Read__c',
          type: 'string'
        },
        "PMS_Case_Status_B2B_Write__c": {
          name: 'PMS_Case_Status_B2B_Write__c',
          type: 'string'
        },
        "Serviceo_Case_Number__c": {
          name: 'Serviceo_Case_Number__c',
          type: 'string'
        },
        "PMC_Case_Number": {
          name: 'PMC_Case_Number',
          type: 'string'
        },
        "isFRU": {
          name: 'isFRU',
          type: 'boolean'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        program: {
          name: 'program',
          type: 'Project',
          model: 'Project',
          relationType: 'belongsTo',
                  keyFrom: 'Project_SOP__c',
          keyTo: 'sfdcId'
        },
        Jobsite: {
          name: 'Jobsite',
          type: 'Jobsite',
          model: 'Jobsite',
          relationType: 'belongsTo',
                  keyFrom: 'Jobsite__c',
          keyTo: 'sfdcId'
        },
        Job: {
          name: 'Job',
          type: 'Job',
          model: 'Job',
          relationType: 'belongsTo',
                  keyFrom: 'Service_Dispatch__c',
          keyTo: 'sfdcId'
        },
        Account: {
          name: 'Account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'AccountId',
          keyTo: 'sfdcId'
        },
        project: {
          name: 'project',
          type: 'Project',
          model: 'Project',
          relationType: 'belongsTo',
                  keyFrom: 'Project__c',
          keyTo: 'sfdcId'
        },
        case: {
          name: 'case',
          type: 'Case',
          model: 'Case',
          relationType: 'belongsTo',
                  keyFrom: 'ParentId',
          keyTo: 'sfdcId'
        },
        worker: {
          name: 'worker',
          type: 'Worker',
          model: 'Worker',
          relationType: 'belongsTo',
                  keyFrom: 'Dispatch_Worker__c',
          keyTo: 'sfdcId'
        },
        users: {
          name: 'users',
          type: 'Users',
          model: 'Users',
          relationType: 'belongsTo',
                  keyFrom: 'CreatedById',
          keyTo: 'sfdcId'
        },
        ICC_Case: {
          name: 'ICC_Case',
          type: 'Case[]',
          model: 'Case',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'ParentId'
        },
        appointment: {
          name: 'appointment',
          type: 'Appointment[]',
          model: 'Appointment',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'ICC_Case__c'
        },
        pmsAppointment: {
          name: 'pmsAppointment',
          type: 'Appointment',
          model: 'Appointment',
          relationType: 'belongsTo',
                  keyFrom: 'sfdcId',
          keyTo: 'PMS_Case__c'
        },
        purchaseOrder: {
          name: 'purchaseOrder',
          type: 'PurchaseOrder',
          model: 'PurchaseOrder',
          relationType: 'belongsTo',
                  keyFrom: 'Purchase_Order_Reference__c',
          keyTo: 'sfdcId'
        },
        product: {
          name: 'product',
          type: 'Product',
          model: 'Product',
          relationType: 'belongsTo',
                  keyFrom: 'SKU__c',
          keyTo: 'sfdcId'
        },
        contact: {
          name: 'contact',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Created_by_Contact__c',
          keyTo: 'sfdcId'
        },
        talentType: {
          name: 'talentType',
          type: 'TalentType',
          model: 'TalentType',
          relationType: 'belongsTo',
                  keyFrom: 'Talent_Type_ref__c',
          keyTo: 'sfdcId'
        },
        workOrder: {
          name: 'workOrder',
          type: 'WorkOrder',
          model: 'WorkOrder',
          relationType: 'belongsTo',
                  keyFrom: 'Job_Order__c',
          keyTo: 'sfdcId'
        },
        jobOrderItem: {
          name: 'jobOrderItem',
          type: 'JobOrderItem',
          model: 'JobOrderItem',
          relationType: 'belongsTo',
                  keyFrom: 'Job_Order_Item__c',
          keyTo: 'sfdcId'
        },
        RecordType: {
          name: 'RecordType',
          type: 'RecordType',
          model: 'RecordType',
          relationType: 'belongsTo',
                  keyFrom: 'RecordTypeId',
          keyTo: 'sfdcId'
        },
        order: {
          name: 'order',
          type: 'Order',
          model: 'Order',
          relationType: 'belongsTo',
                  keyFrom: 'Sales_Order_Reference__c',
          keyTo: 'sfdcId'
        },
        requestFormReceipt: {
          name: 'requestFormReceipt',
          type: 'RequestFormReceipt',
          model: 'RequestFormReceipt',
          relationType: 'hasOne',
                  keyFrom: 'sfdcId',
          keyTo: 'Case__c'
        },
        asset: {
          name: 'asset',
          type: 'Asset',
          model: 'Asset',
          relationType: 'belongsTo',
                  keyFrom: 'AssetId',
          keyTo: 'sfdcId'
        },
        comment: {
          name: 'comment',
          type: 'Comment[]',
          model: 'Comment',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'Case__c'
        },
        favorite: {
          name: 'favorite',
          type: 'Favorite[]',
          model: 'Favorite',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'caseId'
        },
        serviceContract: {
          name: 'serviceContract',
          type: 'ServiceContract',
          model: 'ServiceContract',
          relationType: 'belongsTo',
                  keyFrom: 'Service_Contract__c',
          keyTo: 'sfdcId'
        },
        escalationICC: {
          name: 'escalationICC',
          type: 'RequestFormReceiptEscalation[]',
          model: 'RequestFormReceiptEscalation',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'ICC_Case__c'
        },
        escalationPMS: {
          name: 'escalationPMS',
          type: 'RequestFormReceiptEscalation[]',
          model: 'RequestFormReceiptEscalation',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'PMS_Case__c'
        },
      }
    }
  }
}
