import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GeoMetroApi } from 'shared/sdk';
import { ServiceTerritoryApi } from 'shared/sdk/services/custom/ServiceTerritory';
import { CommonService } from 'shared/services/common.service';


@Component({
  selector: 'app-service-territory-lookup',
  templateUrl: './service-territory-lookup.component.html',
  styleUrls: ['./service-territory-lookup.component.css']
})
export class ServiceTerritoryLookupComponent implements OnInit {

  cities = [];
  serviceTerritory: any = []
  selectedValue;
  @Input() set setServiceTerritoryObj(e) {
    console.log('AppliedFilterValue>>>', e)
    if (e && e.countries && e.countries.length) {
        this._countries = e.countries;
    } else {
      this._countries = [];
    }
    if (e && e.states && e.states.length) {
        this._states = e.states;
    } else {
      this._states = [];
    }
    if ((this._countries && this._countries.length) || (this._states && this._states.length)) {
      this.getServiceTeritoryList()
    }
  }
  @Input() set resetData(isClear) {
    this.clearFromParent();
  }
  @Output() selectedServiceTerritory: EventEmitter<any> = new EventEmitter;
  _countries;
  _states

  constructor(private _geoMetroApi: GeoMetroApi,
    private _commonService: CommonService,
    private _serviceTerritoryApi: ServiceTerritoryApi,
  ) { }

  ngOnInit() {
  }

  getServiceTeritoryList() {
    const whereObj = {}
    this.selectedValue = '';
    this.cities = [];
    if (this._countries && this._countries.length) {
      whereObj['Country_Code__c'] = { inq: this.createArrayToIn(this._countries)};
    }
    if (this._states && this._states.length) {
      whereObj['StateManager__c'] = { inq: this.createArrayToIn(this._states)};
    }
    this._geoMetroApi.find({where: whereObj}).subscribe(response => {
      this.modifyServiceTeritoryListData(response);
    }, err => {
      console.log(err);
    });
  }

  modifyServiceTeritoryListData(data) {
    const geoMetroSfdcIds = [...Array.from(new Set(data.map(item => item.sfdcId)))];
    if (this.cities) {
    this._serviceTerritoryApi.find({where: {GEO_Metro__c: { inq: geoMetroSfdcIds}}})
    .subscribe(data => {
        this.cities = data.map(data => data['Name']);
        this.selectedValue = this.cities;
        this.serviceTerritory = this.cities;
      })
    } else {
      this.serviceTerritory = [];
      this.clearFromParent(true);
    }
  }

  onChange() {
    this.selectedServiceTerritory.emit(this.selectedValue);
  }

  clearFromParent(initiateOnChange?) {
    this.selectedValue = null;
  }

  createArrayToIn(data) {
    return data.map(x => x.sfdcId);
  }
}
