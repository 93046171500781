import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs/Rx';
import { Subject } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { NotificationSocketService } from './notification-socket.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SocketService {

    _dataTitle: string;
    _notificationURL: string;
    _activityURL: string;

    private totalUnreadNotification = new BehaviorSubject<number>(0);
    private notificationHeaderList = new Subject<any>();
    private markReadList = new Subject<any>();
    notificationCount: number;
    currentUser = {};

    MAX_NOTIFICATION_LIST = 10;

    constructor(
        private _titleService: Title,
        private _notificationSocketService: NotificationSocketService,
        private _http: HttpClient,
    ) {
        this.currentUser = JSON.parse(localStorage.getItem('appData'));
        let url = environment.notificationUrl;
        this._notificationURL = url + '/notifications';
        this._activityURL = url + '/activity';
    }

    /**
    * Function to set the title of the webapplication.
    * @param unReadCount
    * @param dataTitle
    */
    setPageTitle(unReadCount, dataTitle = 'ServiceO') {
        this.getCountNotification().subscribe(count => {
            const notificatonCounterTitle = ((count > 0) ? '(' + count + ') Notifications | ' : '');
            this._dataTitle = (dataTitle) ? (notificatonCounterTitle + '' + dataTitle) : notificatonCounterTitle;
            this._titleService.setTitle(this._dataTitle);
        })
        const notificatonCounterTitle = ((unReadCount > 0) ? '(' + unReadCount + ') Notifications | ' : '');
        this._dataTitle = (dataTitle) ? (notificatonCounterTitle + '' + dataTitle) : notificatonCounterTitle;
        this._titleService.setTitle(this._dataTitle);
    }

    /**
     * Function to set the count of the notification
    * @param dataTitle
    */
    setCountNotification(dataTitle = null) {
        this.setPageTitle(0, dataTitle);
        this.countNotification().then((countResponse: number) => {
            if (countResponse && countResponse['length'] && countResponse[0].count) {
                this.notificationCount = Number(countResponse[0].count);
                this.totalUnreadNotification.next(this.notificationCount);
            }
        });
    }

    /***
     * use to show total unread count notification
     */
    getCountNotification(): Observable<number> {
        return this.totalUnreadNotification.asObservable();
    }


    /**
     * Functon to set the notification list
     * @param condition
     */
    setHeaderNotifications() {
        this.getNotificationList().then((notificationList: any) => {
            let notificationListData = [];
            if (notificationList && notificationList.data) {
                notificationListData = notificationList.data.length > 0 ? notificationList.data : [];
            }
            this._notificationSocketService.getMessage().subscribe((result: any) => {
                if (result && result['payload']) {
                    this.fetchNotificationAndRestructure(result['payload']).then(data => {
                        // increment count notification
                        this.notificationCount += 1;
                        this.totalUnreadNotification.next(this.notificationCount);
                        notificationListData.unshift(data);
                        if (notificationListData.length > this.MAX_NOTIFICATION_LIST) {
                            notificationListData = notificationListData.splice(0, this.MAX_NOTIFICATION_LIST);
                        }
                        this.notificationHeaderList.next(notificationListData);
                        this.pushNotification(data['title']);
                    })
                }
            });
            this.notificationHeaderList.next(notificationListData);
        });
    }

    /**
     * to get notification list top header
     */
    getHeaderNotifications(): Observable<any> {
        return this.notificationHeaderList.asObservable();
    }

    /**
     * Function to get the count of unread notifications
     */
    countNotification() {
        return new Promise((resolve, reject) => {
            this._http.get(this._notificationURL + '/unread-count').subscribe(
                data => resolve(data),
                err => reject(err)
            );
        });
    }

    /**
     * Function to get the notification list
     * @param condition
    */
    getNotificationList(page = 1, limit = this.MAX_NOTIFICATION_LIST) {
        return new Promise((resolve, reject) => {
            this._http.post(this._notificationURL + '/filter?page=' + page + '&limit=' + limit, {}).subscribe(
                data => {
                    resolve(data)
                },
                err => {
                    reject(err)
                }
            );
        });
    }

    /**
     * Function handle to read all the notifications
     * @param condition
     * @param viewUrl
     */
    readAllNotification() {
        return new Promise((resolve, reject) => {
            this._http.get(this._notificationURL + '/mark-read-all').subscribe(
                data => {
                    this.notificationCount = 0;
                    this.totalUnreadNotification.next(this.notificationCount);
                    this.markReadList.next({});
                    resolve(data)
                },
                err => {
                    reject(err)
                }
            );
        });
    }

    /**
     * Function to read a particular notification
     * @param id It's Db Id.
     */
    readNotification(id) {
        return new Promise((resolve, reject) => {
            this._http.get(this._notificationURL + '/mark-read?id=' + id).subscribe(
                data => {
                    this.notificationCount -= 1;
                    this.totalUnreadNotification.next(this.notificationCount);
                    this.markReadList.next({ id });
                    resolve(data)
                },
                err => reject(err)
            );
        });
    }

    /**
    * to get all marked list
    */
    getMarkRead(): Observable<any> {
        return this.markReadList.asObservable();
    }

    /**
     * Function to get the activity detail and restructure payload object
     * @param element 
     * @returns 
     */
    fetchNotificationAndRestructure(element) {
        return new Promise((resolve, reject) => {
            if (element && element.id) {
                this.getActivityById(element.id).then((activityData: any) => {
                    if (activityData && activityData.length) {
                        let notificationData = this.restructureNotificationData(activityData[0]);
                        resolve(notificationData);
                    }
                })
            }
        });
    }

    /**
     * Function to restructure the notification payload
     * @param notificationPayload 
     * @returns 
     */
    restructureNotificationData(notificationPayload) {
        return {
            "id": notificationPayload.id,
            "marked_read": notificationPayload['marked_read'] || false,
            "notification_type": notificationPayload.notification_type,
            "action_type": notificationPayload.action_type,
            "actor_type": notificationPayload.actor_type,
            "priority": (notificationPayload.priority == 'BKFL' || notificationPayload.priority == 'NOBKFL') ? notificationPayload.priority : notificationPayload.priority.substring(0, 2),
            "title": notificationPayload.title,
            "details": notificationPayload.details,
            "entity_id": notificationPayload.entity_id,
            "entity_name": notificationPayload.entity_name,
            "sfdc_id": notificationPayload.sfdc_id,
            "date_created": notificationPayload.date_created || new Date()
        };
    }

    /**
     * Function to get an activity
     * @param activityId
     */
    getActivityById(activityId) {
        return new Promise((resolve, reject) => {
            this._http.get(this._activityURL + '/' + activityId).subscribe(
                data => {
                    resolve(data)
                },
                err => {
                    reject(err)
                }
            );
        });
    }

    /**
     * Function to create new browser push notification
     * @param message 
     * @returns 
     */
    pushNotification(message = "A new notification!") {
        if (!("Notification" in window)) {
            console.log("Browser doesn't support Notification.");
            return;
        }

        if ((Notification as any).permission === "granted") {
            new Notification(message);
        } else if ((Notification as any).permission !== "denied") {
            Notification.requestPermission().then(function (permission) {
                if (permission === "granted") {
                    new Notification(message);
                }
            });
        }
    }
}
