import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { UpdateAppointmentComponent } from './update-appointment.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';

@NgModule({
    imports: [
        CommonModule,
        NgbModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule
    ],
    declarations: [UpdateAppointmentComponent],
    exports: [UpdateAppointmentComponent],
})
export class UpdateAppointmentModule { }
