import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { UploadOutput, UploadInput, UploadFile, UploaderOptions } from 'ngx-uploader';
import { AlertService } from '../../../services/alert.service';
import { ModalService } from '../../../services/modal.service';
import { environment } from '../../../../environments/environment';
import { PreloaderService } from '../../../services/preloader.service';
import { CommonService } from '../../../services/common.service';
import { AppStateService } from 'shared/services/app-state.service';
import { UsersApi } from 'shared/sdk'
@Component({
  selector: 'app-profile-picture',
  templateUrl: './upload-picture.component.html',
  styleUrls: ['./upload-picture.component.css'],
})
export class UploadPictureComponent implements OnInit {

  @Input() multiUser;
  @Input() newUser;
  // event emmitted to user-left-panel component
  @Output('pictureUploaded') pictureUploaded = new EventEmitter<any>();
  error: string;
  imagePreview: any;
  files: UploadFile[];
  // input events, we use this to emit data to ngx-uploader
  uploadInput: EventEmitter<UploadInput>;
  dragOver: boolean;
  options: UploaderOptions;
  currentUser = JSON.parse(localStorage.getItem('appData'));
  imageChangedEvent: any = '';
  formData = new FormData();
  isImageChanged = false;
  accessType: any;
  userData: any;
  hidePopUP = false;
  constructor(
    private _appState: AppStateService,
    private _alertService: AlertService,
    private _modalService: ModalService,
    private _preloaderService: PreloaderService,
    private _commonService: CommonService,
    private _usersApi: UsersApi
  ) {
    this.options = { concurrency: 1, allowedContentTypes: ['image/jpg', 'image/jpeg', 'image/png'] };
    this.files = []; // local uploading files array
    this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
  }

  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem('appData'))
    this.error = '';
    this.accessType = this._appState.getAccessType();
    this._commonService.getUserProfile().subscribe(userProfile => {
      this.imagePreview = userProfile['isProfile'] ? userProfile['isProfile'] : '';
      this.isImageChanged = false;
    });
  }
  onUploadOutput(output: UploadOutput): void {
    switch (output.type) {
      case 'allAddedToQueue':
        // uncomment this if you want to auto upload files when added
        // const event: UploadInput = {
        //   type: 'uploadAll',
        //   url: '/upload',
        //   method: 'POST',
        //   data: { foo: 'bar' }
        // };
        // this.uploadInput.emit(event);
        break;
      case 'addedToQueue':
        this.error = '';
        if (typeof output.file !== 'undefined') {
          this.files = [];
          this.previewImage(output.file.nativeFile).then(response => {
            this.imagePreview = response; // The image preview
            this.files[0] = output.file;
          });
        }
        break;
      case 'rejected':
        console.log(output.file.name + ' rejected');
        this.error = 'Selected file format not allowed.';
        this.imagePreview = '';
        this.files = [];
        break;
      case 'done':
        const msg = output.file.response;
        const progress = this.files[0].progress.data.percentage;
        if (!msg.error && msg.status.success && progress === 100) {
          // close modal after successfully upload
          setTimeout(() => {
            // to update local storage beacuse page got refreshed while seleting vendor 
            if (!this.multiUser) {
              const localAppData = JSON.parse(localStorage.getItem('appData'));
              localAppData['user']['picture'] = msg.status.success.usr['picture'];
              localAppData['user']['profileImage'] = msg.status.success.usr['profileImage'];
              localStorage.setItem('appData', JSON.stringify(localAppData));
              // need to emit data to auto-update user-left page
              this.pictureUploaded.emit(msg.status.success.usr);
              this._alertService.success(msg.status.success.message);
            } else {
              this.pictureUploaded.emit(msg);
              this._alertService.success('Your profile picture has been saved successfully.')
            }
            this._modalService.closed();
          }, 1000);
        } else {
          this.error = msg.error.message;
        }
        this._preloaderService.hidePreloader();
        break;
    }
  }

  // to prview uploaded image
  previewImage(file: any) {
    const fileReader = new FileReader();
    return new Promise(resolve => {
      fileReader.readAsDataURL(file);
      fileReader.onload = function (e: any) {
        resolve(e.target.result);
      };
    });
  }

  // on file upload
  startUpload(): void {
    this._preloaderService.showPreloader();
    const event: UploadInput = {
      type: 'uploadFile',
      file: this.files[0],
      url: '',
      method: 'POST'
    };
    if (this.multiUser) {
      event.url = environment.baseUrl +
        '/' +
        environment.apiVersion +
        '/Users/usersProfileUpload?access_token=' + localStorage.getItem('accessToken') + '&newUserId=' + (this.newUser && this.newUser.id || '');
    } else {
      event.url = environment.baseUrl +
        '/' +
        environment.apiVersion +
        '/Users/upload?access_token=' + localStorage.getItem('accessToken');
      // event = {
      //   type: 'uploadFile',
      //   file: this.files[0],
      //   url:
      //     environment.baseUrl +
      //     '/' +
      //     environment.apiVersion +
      //     '/Users/upload?access_token=' + localStorage.getItem('accessToken'),
      //   method: 'POST'
      // };
    }
    this.uploadInput.emit(event);
  }

  // close modal on click because let-c and let-d cant be use inot child component of modal
  closeModal() {
    if(this.hidePopUP) {
      // save on users table 
      this._preloaderService.showPreloader();
      this._usersApi.patchAttributes(this.userData.user.id, {skipProfilePic: true}).subscribe(res => {
        console.log('res on update', res)
        this.userData.user.skipProfilePic = true;
        localStorage.setItem('appData', JSON.stringify(this.userData))
        this._preloaderService.hidePreloader();
        this._modalService.closed();
      }, error => {
        this._preloaderService.hidePreloader();
        this._modalService.closed();
      })
      // close modal
    } else {
      this._modalService.closed();
    }    
  }

  /**
   * 
   * @param event to display image with croping option.
   */
  fileChangeEvent(event): void {
    this.files = [];
    this.imageChangedEvent = event;
    this.isImageChanged = true;
  }

  /**
   * 
   * @param image get croped image and replace ngx-uploader files with new croped one.
   */
  imageCropped(image: string) {
    this.imagePreview = '';
    const fileName = this.files[0]['name'].split('.')[0];
    const files = this.dataURLtoFile(image, fileName);
    this.files[0].nativeFile = files;
  }

  imageLoaded() {
    // show cropper
  }

  loadImageFailed() {
    // show message
  }

  /**
   * 
   * @param dataurl 
   * @param filename 
   * return file to upload
   */
  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.base64 ? dataurl.base64.split(',') : dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const fileType = mime.split('/')[1];
    filename = filename + '.' + fileType;
    return new File([u8arr], filename, { type: mime || 'image/png' });
  }
}
