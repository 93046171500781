/* tslint:disable */
import {
  TrainingPlan,
  Contact
} from '../index';

declare var Object: any;
export interface TrainingPlanAssignmentInterface {
  "sfdcId"?: string;
  "OwnerId"?: string;
  "IsDeleted"?: boolean;
  "Name"?: string;
  "CurrencyIsoCode"?: string;
  "CreatedDate"?: Date;
  "CreatedById"?: string;
  "LastModifiedDate"?: Date;
  "LastModifiedById"?: string;
  "LastActivityDate"?: Date;
  "Community_Id__c"?: string;
  "Completed_On__c"?: Date;
  "Last_Updated__c"?: Date;
  "Status__c"?: string;
  "Training_Plan__c"?: string;
  "User__c"?: string;
  "Trainee__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  trainingPlan?: TrainingPlan;
  trainee?: Contact;
}

export class TrainingPlanAssignment implements TrainingPlanAssignmentInterface {
  "sfdcId": string;
  "OwnerId": string;
  "IsDeleted": boolean;
  "Name": string;
  "CurrencyIsoCode": string;
  "CreatedDate": Date;
  "CreatedById": string;
  "LastModifiedDate": Date;
  "LastModifiedById": string;
  "LastActivityDate": Date;
  "Community_Id__c": string;
  "Completed_On__c": Date;
  "Last_Updated__c": Date;
  "Status__c": string;
  "Training_Plan__c": string;
  "User__c": string;
  "Trainee__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  trainingPlan: TrainingPlan;
  trainee: Contact;
  constructor(data?: TrainingPlanAssignmentInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `TrainingPlanAssignment`.
   */
  public static getModelName() {
    return "TrainingPlanAssignment";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of TrainingPlanAssignment for dynamic purposes.
  **/
  public static factory(data: TrainingPlanAssignmentInterface): TrainingPlanAssignment{
    return new TrainingPlanAssignment(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'TrainingPlanAssignment',
      plural: 'TrainingPlanAssignments',
      path: 'TrainingPlanAssignments',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "LastActivityDate": {
          name: 'LastActivityDate',
          type: 'Date'
        },
        "Community_Id__c": {
          name: 'Community_Id__c',
          type: 'string'
        },
        "Completed_On__c": {
          name: 'Completed_On__c',
          type: 'Date'
        },
        "Last_Updated__c": {
          name: 'Last_Updated__c',
          type: 'Date'
        },
        "Status__c": {
          name: 'Status__c',
          type: 'string'
        },
        "Training_Plan__c": {
          name: 'Training_Plan__c',
          type: 'string'
        },
        "User__c": {
          name: 'User__c',
          type: 'string'
        },
        "Trainee__c": {
          name: 'Trainee__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        trainingPlan: {
          name: 'trainingPlan',
          type: 'TrainingPlan',
          model: 'TrainingPlan',
          relationType: 'belongsTo',
                  keyFrom: 'Training_Plan__c',
          keyTo: 'sfdcId'
        },
        trainee: {
          name: 'trainee',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Trainee__c',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
