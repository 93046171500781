import { Component, Input, ElementRef, OnChanges } from '@angular/core';
import { Project2Api } from 'shared/sdk';
@Component({
    selector: 'read-more',
    template: `
        <span [attr.title]="isTitleShow ? title : '' " [innerHTML]="currentText"></span>
        <span [class.hidden]="hideToggle" (click)="toggleView()" *ngIf="!isCollapsed">
            <i class="fa fa-angle-up pointer"></i>
        </span>
        <span [class.hidden]="hideToggle" (click)="toggleView()" *ngIf="isCollapsed">
            <i class="fa fa-angle-down pointer"></i>
        </span>
    `,
    styles: [`
        .hidden {
            display: none;
        }
        .pointer {
            cursor: pointer;
        }
    `]
})

export class ReadMoreComponent implements OnChanges {
    @Input() frmDB: any;
    @Input() isTitleShow: boolean = true;
    @Input() text: string;
    @Input() maxLength: number = 100;
    currentText: string;
    hideToggle: boolean = true;
    title: string;

    public isCollapsed: boolean = true;

    constructor(private elementRef: ElementRef,
        private _project2Api: Project2Api) {

    }
    async toggleView() {
        //to handle more data wich fetch on readmore click
        if(this.frmDB){
           const text = await this.getDataFromDB(this.frmDB);
           this.text = text.toString();
        }
        this.isCollapsed = !this.isCollapsed;
        this.determineView();
    }
    determineView() {
        if (!this.text || this.text.length <= this.maxLength) {
            this.currentText = this.text;
            this.isCollapsed = false;
            this.hideToggle = true;
            this.title = null;
            return;
        }
        this.hideToggle = false;
        if (this.isCollapsed == true) {
            this.currentText = this.text.substring(0, this.maxLength) + "...";
            this.title = this.text.replace(/<br\/>/g, '\n').replace(/<br>/g, '\n');
        } else if (this.isCollapsed == false) {
            this.currentText = this.text;
            this.title = null;
        }
    }

    ngOnChanges() {
        this.hideToggle = true;
        this.isCollapsed = true;
        this.determineView();
    }

    getDataFromDB(e) {
        if (e.prop === 'projTeams') {
            return new Promise((resolve, reject) => {
                this._project2Api.programGrp({ sfdcId: e.sfdcId }).subscribe(res => {
                const pTeams = res;
                console.log(this.getListInfo(pTeams, 'programGroups'))
                  resolve(this.getListInfo(pTeams, 'programGroups'));
                }, error => {
                  reject();
                })
            })
        } else if (e.prop === 'projMemRoles') {
            return new Promise((resolve, reject) => {
                this._project2Api.memberRole({ sfdcId: e.sfdcId }).subscribe(res => {
                const pTeams = res;
                  resolve(this.getListInfo(pTeams, 'memberRoles'));
                }, error => {
                  reject();
                })
            });
        }
    }
    getListInfo(arr, listName) {
        let displayList = '';
        if (arr && listName) {
          let index = 1;
          arr.forEach(ele => {
            if (listName === 'programGroups' && ele  && ele.Department_Group_Code__c) {
              displayList += (`${index++}. ${ele.Department_Group_Code__c}<br/>`);
            } else if (listName === 'memberRoles' && ele  && ele.wName) {
              displayList += (`${index++}. ${ele.wName}${(ele.Role__c ? ` - (${ele.Role__c})` : '')}<br/>`);
            }
          })
        }
        return displayList;
      }
}