import { Component, OnInit, Input, ChangeDetectorRef, AfterViewChecked, Output, EventEmitter,ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { PlatformLocation, Location } from '@angular/common';

import { AlertService } from '../../../services/alert.service';
import { ModalService } from '../../../services/modal.service';
import { PreloaderService } from '../../../services/preloader.service';
import { CommonService } from '../../../services/common.service';

import { UsersApi } from '../../../sdk/services/custom/Users';
import { ContactApi } from 'shared/sdk/services/custom/Contact';
import { countryIsdCode, timechangeReasons } from 'shared/models/static-list-data.service';
import { t } from '@angular/core/src/render3';
import { Observable, timer } from 'rxjs';
@Component({
    selector: 'app-resetpassword',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.css']
})

export class ResetPasswordComponent implements OnInit {

    recoverForm: FormGroup; user: any;
    error: string;
    URISegment: string;
    otpForm: FormGroup;
    mobileNumber = "";
    showPhone = false;
    phoneNumber: string;
    phoneWithIsd: string;
    existingNumber: string;
    otpData: any;
    OtpSucessfull = false;
    enteredOtpValues: any;
    appStateData: any;
    countryIsdCode = [...countryIsdCode];
    timerDone = false;
    obsTimer: Observable<number> = timer(1000, 1000);
    allowTime = 0;
    otpTimer: any;
    resentOtp = true;
    OtpResent = false;
    otpCount = 0;
    constructor(
        private fb: FormBuilder,
        private usersApi: UsersApi,
        private alertService: AlertService,
        private platformLocation: PlatformLocation,
        private _location: Location,
        private _preloaderService: PreloaderService,
        private router: Router,
        private _contactApi: ContactApi,
        private elementRef: ElementRef
    ) {
        // logout when loggedIn
        if (this._location.path().indexOf('reset') !== -1) {
            this.usersApi.logout();
        }
        this.URISegment = (platformLocation as any).location.origin;
    }

    ngOnInit() {
        this.buildForms();
    }
    resetPasswordOtp() {
        this._preloaderService.showPreloader();
        this.usersApi
          .sendOtpUser({
            verifyOtp: true,
            enteredOtp: this.otpForm.value.otp,
            email: this.enteredOtpValues.email,
          })
          .subscribe(
            (data) => {
              if (data.userinfo.success) {
                this._preloaderService.hidePreloader();
                this.alertService.success("OTP Verified Successfully!");
                this.appStateData = {};
                this.appStateData.email = this.enteredOtpValues.email;
                this.appStateData.id = this.enteredOtpValues.id;
                this.appStateData.fromMobile = true;
                this.appStateData.otpValue = this.otpForm.value.otp
                localStorage.setItem("appData", JSON.stringify(this.appStateData));
                this.router.navigate(["/reset-password"]);
              } else {
                this._preloaderService.hidePreloader();
                this.alertService.error("Invalid OTP!");
              }
            },
            (err) => {
              console.log(err);
              this._preloaderService.hidePreloader();
            }
          );
      }
    // resetPassword() {
    //     this._preloaderService.showPreloader();
    //     this.usersApi.resetPassword({ 'email': this.recoverForm.value.username, 'URISegment': this.URISegment }).
    //         subscribe(data => {
    //             this.alertService.success('Email has been sent to ' + this.recoverForm.value.username);
    //             this._preloaderService.hidePreloader();
    //         }, err => {
    //             if (err && !err.message) {
    //                 err = { message: err };
    //             }
    //             if (err.code === 'EMAIL_NOT_FOUND') {
    //                 err.message = 'This email is not register with us.';
    //             }
    //             this.alertService.error(err.message);
    //             this._preloaderService.hidePreloader();
    //         });
    // }
    resetPassword() {
        this._preloaderService.showPreloader();
        if (!this.showPhone) {
          this.usersApi
            .resetPassword({
              email: this.recoverForm.value.username,
              URISegment: this.URISegment,
            })
            .subscribe(
              (data) => {
                this.alertService.success(
                  "Email has been sent to " + this.recoverForm.value.username
                );
                this._preloaderService.hidePreloader();
              },
              (err) => {
                if (err && !err.message) {
                  err = { message: err };
                }
                if (err.code === "EMAIL_NOT_FOUND") {
                  err.message = "This email is not register with us.";
                }
                this.alertService.error(err.message);
                this._preloaderService.hidePreloader();
              }
            );
        } else {
          if(!this.recoverForm.value.mobileNumber){
            this.alertService.error(
                "Phone Number is required!"
              );
              this._preloaderService.hidePreloader();
          }else{
          if (this.recoverForm.value.countryIsdCode.length > 0 && !this.OtpResent) {
            this.phoneNumber = this.recoverForm.value.mobileNumber.toString();
            this.phoneWithIsd =
              this.recoverForm.value.countryIsdCode +
              "XXXXXX" +
              this.phoneNumber.slice(-4);
            this.usersApi
              .sendOtpUser({
                mobileNumber: this.recoverForm.value.mobileNumber,
                isdCode: this.recoverForm.value.countryIsdCode,
                URISegment: this.URISegment,
              })
              .subscribe(
                (data) => {            
                  if (data.userinfo) {
                      this.otpCount += 1;
                  if (this.otpCount == 2) {
                    this.OtpResent = true;
                  }
                  this.allowTime = 60;
                  if (this.resentOtp) {
                    this.otpTimer = this.obsTimer.subscribe(() => {
                      this.allowTime -= 1;
                      if (this.allowTime == 0) {
                        this.timerDone = true;
                        this.otpTimer.unsubscribe();
                        this.resentOtp = false;
                      }
                    });
                  }
                    this.alertService.success(
                      "Otp has been sent to " + this.recoverForm.value.mobileNumber
                    );
                    this.OtpSucessfull = true;
                    this.mobileNumber = data.userinfo.mobileNumber;
                    this.enteredOtpValues = data.userinfo;
                    this._preloaderService.hidePreloader();
                  } else {
                    this.alertService.error(
                      "This Phone Number is not registered with us."
                    );
                    this._preloaderService.hidePreloader();
                  }
                },
                (err) => {
                    console.log("1")
                  if (err && !err.message) {
                    err = { message: err };
                  }
                  this.alertService.error(
                    "This Phone Number is not registered with us."
                  );
                  this._preloaderService.hidePreloader();
    
                }
              );
          } else {
            if (this.OtpResent) {
              this.alertService.error("Already Resent OTP!!");
              this._preloaderService.hidePreloader();
            } else {
              this.alertService.error("Please select your country code");
              this._preloaderService.hidePreloader();
            }
          }
        }}
      }
      showEmailDiv() {
        var ChkEmail = document.getElementById("contactChoice1").id;
        if (ChkEmail === "contactChoice1") {
          this.showPhone = false;
        }
      }
      showPhoneDiv() {
        var ChkPhone = document.getElementById("contactChoice2").id;
        if (ChkPhone === "contactChoice2") {
          this.showPhone = true;
        }
      }
    buildForms() {
        this.recoverForm = this.fb.group({
          username: ["", [Validators.required, Validators.email]],
          url: [this.URISegment],
          contactChoice: ["email"],
          mobileNumber: ["", [Validators.required]],
          countryIsdCode: ["", [Validators.required]],
        });
        this.otpForm = this.fb.group({
          otp: ["", [Validators.required]],
          url: [this.URISegment],
        });
        this.error = "";
    }
}

@Component({
    selector: 'app-forgotpassword',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./reset-password.component.css']
})

export class ForgotPasswordComponent implements OnInit, AfterViewChecked {

    @Input() modelName: string;
    changePasswordForm: FormGroup; user: any; access_token: any;
    error: string;
    passwordRegex: any = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/;
    currentUser = JSON.parse(localStorage.getItem('appData'));
    constructor(
        private fb: FormBuilder,
        private usersApi: UsersApi,
        private alertService: AlertService,
        private route: ActivatedRoute,
        private _modalService: ModalService,
        private _location: Location,
        private _cdRef: ChangeDetectorRef,
        private _preloaderService: PreloaderService,
        private commonService: CommonService
    ) {
        this.modelName = 'promtPassword';
        // logout when loggedIn
        if (this._location.path().indexOf('reset') !== -1) {
            this.usersApi.logout();
            this.modelName = 'changePassword';
        }
    }

    ngAfterViewChecked() {
        if (!this._cdRef['destroyed']) {
            this._cdRef.detectChanges();
        }
    }

    ngOnInit() {
        this.buildForms();
    }

    changePassword() {
        // if (this.changePasswordForm.value.npassword) {
        //     this._preloaderService.showPreloader();
        //     this.usersApi.changePasswordAuth0({ userId: this.currentUser['user']['id'], email: this.currentUser['user']['email'], password: this.changePasswordForm.value.npassword }).subscribe(data => {
        //         this.alertService.success('Password has been reset successfully.');
        //         this._preloaderService.hidePreloader();
        //     }, err => {
        //         this.alertService.error(err.message);
        //         this._preloaderService.hidePreloader();
        //     });
        // }
        if(this.currentUser != null){
            this._preloaderService.showPreloader();
            this.usersApi.changePasswordAuth0Otp({
              matchOldPassword: false,
              userId: this.currentUser.id,
              email: this.currentUser.email,
              password: this.changePasswordForm.value.npassword,
              enteredOtp : this.currentUser.otpValue
            }).subscribe(data => {
              if (data && data.user_id) {
                localStorage.removeItem('appData');
                this.alertService.success('Password has been reset successfully.');
                this.changePasswordForm.reset();
                this._preloaderService.hidePreloader();
              }
            }, err => {
              this.alertService.error(err.message);
              this._preloaderService.hidePreloader();
            });
        }
       else{
        this.route.queryParams.subscribe(params => {
            if (!params['access_token']) {
                this.alertService.error('Access Token is required');
            } else {
                this._preloaderService.showPreloader();
                this.usersApi.updatePasswordFromToken(params['access_token'], this.changePasswordForm.value.npassword).subscribe(data => {
                    this.alertService.success('Password has been reset successfully.');
                    this.changePasswordForm.reset();
                    this._preloaderService.hidePreloader();
                }, err => {
                    this.alertService.error(err.message);
                    this._preloaderService.hidePreloader();
                });
            }
        });
    }}

    buildForms() {
        this.changePasswordForm = this.fb.group({
            npassword: [null, [Validators.required, Validators.minLength(8), Validators.pattern(this.passwordRegex)]],
            cpassword: [null, [Validators.required, Validators.minLength(8), Validators.pattern(this.passwordRegex)]]
        }, { validator: this.passwordMatch('npassword', 'cpassword') });

        this.error = '';
    }

    passwordMatch(passwordKey: string, passwordConfirmationKey: string) {
        return (group: FormGroup) => {
            const passwordInput = group.controls[passwordKey],
                passwordConfirmationInput = group.controls[passwordConfirmationKey];
            if ((passwordInput.value && passwordInput.value.length > 0) ||
                (passwordConfirmationInput.value && passwordConfirmationInput.value.length > 0)) {
                if (passwordInput.value !== passwordConfirmationInput.value) {
                    return passwordConfirmationInput.setErrors({ notEquivalent: true }), passwordInput.setErrors({ notEquivalent: true })
                } if (passwordInput.value.length < 8 || passwordConfirmationInput.value.length < 8) {
                    return passwordConfirmationInput.setErrors({ notEquivalent: true }), passwordInput.setErrors({ notEquivalent: true })
                } else {
                    return passwordConfirmationInput.setErrors(null), passwordInput.setErrors(null);
                }
            } else {
                return passwordConfirmationInput.setErrors({ notEquivalent: true }), passwordInput.setErrors({ notEquivalent: true })
            }
        }
    }

    promtPassword() {
        if (this.modelName && this.currentUser['user']['id']) {
            this._preloaderService.showPreloader();
            this.usersApi.changePasswordAuth0({
                promptPasswordChange: true,
                userId: this.currentUser['user']['id'],
                email: this.currentUser['user']['email'],
                password: this.changePasswordForm.value.npassword
            }).subscribe(data => {
                this.alertService.success('Password has been reset successfully.');
                this._preloaderService.hidePreloader();
                this._modalService.closed();
            }, err => {
                this.alertService.error(err.message);
                this._preloaderService.hidePreloader();
            });
        }
    }

    // closeModal
    skipModal() {
        this.usersApi.patchAttributes(this.currentUser['user']['id'], { promptPasswordChange: 0 }).subscribe(
            result => {
                if (result && result['id']) {
                    this.commonService.setUserProfile(result);
                }
            },
            error => {
                console.log(error);
            }
        );
        this._modalService.closed();
    }

    closeModal() {
        this._modalService.closed();
    }
}


