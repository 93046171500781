/* tslint:disable */
import {
  PageViewMapping,
  PageViewTitle
} from '../index';

declare var Object: any;
export interface PageViewGroupInterface {
  "GroupName"?: string;
  "GroupCode"?: string;
  "isMasterGroup"?: boolean;
  "MasterGroupId"?: number;
  "order_num"?: number;
  "isActive"?: boolean;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  pageViewMapping?: PageViewMapping[];
  masterGroup?: PageViewGroup;
  pageViewTitle?: PageViewTitle[];
}

export class PageViewGroup implements PageViewGroupInterface {
  "GroupName": string;
  "GroupCode": string;
  "isMasterGroup": boolean;
  "MasterGroupId": number;
  "order_num": number;
  "isActive": boolean;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  pageViewMapping: PageViewMapping[];
  masterGroup: PageViewGroup;
  pageViewTitle: PageViewTitle[];
  constructor(data?: PageViewGroupInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `PageViewGroup`.
   */
  public static getModelName() {
    return "PageViewGroup";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of PageViewGroup for dynamic purposes.
  **/
  public static factory(data: PageViewGroupInterface): PageViewGroup{
    return new PageViewGroup(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'PageViewGroup',
      plural: 'PageViewGroups',
      path: 'PageViewGroups',
      idName: 'id',
      properties: {
        "GroupName": {
          name: 'GroupName',
          type: 'string'
        },
        "GroupCode": {
          name: 'GroupCode',
          type: 'string'
        },
        "isMasterGroup": {
          name: 'isMasterGroup',
          type: 'boolean'
        },
        "MasterGroupId": {
          name: 'MasterGroupId',
          type: 'number'
        },
        "order_num": {
          name: 'order_num',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        pageViewMapping: {
          name: 'pageViewMapping',
          type: 'PageViewMapping[]',
          model: 'PageViewMapping',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'pageViewGroupId'
        },
        masterGroup: {
          name: 'masterGroup',
          type: 'PageViewGroup',
          model: 'PageViewGroup',
          relationType: 'belongsTo',
                  keyFrom: 'MasterGroupId',
          keyTo: 'id'
        },
        pageViewTitle: {
          name: 'pageViewTitle',
          type: 'PageViewTitle[]',
          model: 'PageViewTitle',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'pageGroupId'
        },
      }
    }
  }
}
