import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TaskAccountLookupComponent } from './task-account-lookup.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule
  ],
  declarations: [TaskAccountLookupComponent],
  exports: [TaskAccountLookupComponent]
})
export class TaskAccountLookupModule { }
