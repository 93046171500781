import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SalesInvoiceStatusLookupComponent } from './sales-invoice-status-lookup/sales-invoice-status-lookup.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule
  ],
  declarations: [SalesInvoiceStatusLookupComponent],
  exports: [SalesInvoiceStatusLookupComponent]
})
export class SalesInvoiceStatusLookupModule { }
