import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReleaseNotesComponent } from './release-notes.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [ReleaseNotesComponent],
  exports: [ReleaseNotesComponent]
})
export class ReleaseNotesModule { }
