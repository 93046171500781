import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FleetManagementActivityFeedListComponent } from './fleet-management-activity-feed-list.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [FleetManagementActivityFeedListComponent],
  exports: [
    FleetManagementActivityFeedListComponent
  ]
})
export class FleetManagementActivityFeedListModule { }
