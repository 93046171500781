import { Component, OnInit, Input } from '@angular/core';
import { WorkflowApi } from 'shared/sdk';
import { PreloaderService } from 'shared/services/preloader.service';
import { AlertService } from 'shared/services/alert.service';
import { AppStateService } from 'shared/services/app-state.service';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.css']
})
export class ProgressBarComponent implements OnInit {
  @Input()
  set modelData(e) {
    this.workflowData = {};
    this.wfStages = [];
    if (e && e.modelId && e.modelName === 'job') {
      if (e.workflowStatusId === 6) {
        this.isDeclined = true;
      }
      this.loadData(e);
    } else if (e && e.page) {
      if (e.page === 'order-details') {
        this.view = 'order-details';
        this.wfStages = this.prepareCaseProgressBar(e);
      } else if (e.page === 'job-detail') {
        this.view = 'job-detail';
        this.wfStages = this.prepareJobDetailProgressBar(e);
      }
    }
  }

  workflowData: any;
  wfStages: Array<any>;
  isCancelled: false;
  userType = '';
  isDeclined = false;
  view = '';

  constructor(
    private _wfApi: WorkflowApi,
    private _appState: AppStateService,
    private _preloaderService: PreloaderService,
    private _alertService: AlertService
  ) { }

  ngOnInit() {
    this.userType = this._appState.getAccessType();
  }

  loadData(e) {
    const filter = {
      modelId: e.modelId,
      modelName: e.modelName,
      id: e.workflowId,
      workflowStatusId: e.workflowStatusId,
      noButton: true
    }
    this._preloaderService.showPreloader();
    this._wfApi.getWorkflowData(filter).subscribe(result => {
      // Fetching workflow stages.
      if (result && result['workflow'] && result['workflow']['stages']) {
        this.wfStages = result['workflow']['stages'].filter(stage => {
          // If stage is hidden || If stage ID is for 'Declined' stage and current status is not set to declined || 
          // If job is not cancelled, don't display cancelled stage
          let excludeStage = false;
          if (stage.isHiddenStage || (stage.id === 3 && e.workflowStatusId !== 4) || (stage.id === 12 && !e.isCancelled)) {
            excludeStage = true;
          }
          stage.class = 'disabled';

          if (stage.status === 'In-Progress') {
            stage.class = 'inProgress';
          } else if (stage.status === 'Pending') {
            stage.class = 'disabled';
          } else if (stage.status === 'Completed') {
            stage.class = 'completed';
          }
          // If this job has no workflow history, set the first stage as active
          if (result['history'] && result['history'].length === 0 && stage.titleMSP && stage.titleMSP.toLowerCase() === 'invited') {
            stage.class = 'inProgress';
          }
          // Showing title on the basis of user type
          switch (this.userType) {
            case 'internal': stage.title = stage.titleMSP;
              break;
            case 'partner': stage.title = stage.titlePMS;
              break;
            case 'vendor': stage.title = stage.titleVMS;
              break;
          }
          if (!excludeStage) {
            return stage;
          }
        });
        this._preloaderService.hidePreloader();
      } else {
        this._preloaderService.hidePreloader();
        this._alertService.error('Workflow Progress Bar Couldn\'t loaded')
      }

    });
  }

  prepareCaseProgressBar(data) {
    let wfStages = [
      { title: 'Work Orders', class: 'disabled' },
      { title: 'Cases', class: 'disabled' },
      { title: 'Job', class: 'disabled' },
      { title: 'Assigned', class: 'disabled' },
      { title: 'Started', class: 'disabled' },
      { title: 'Delivered', class: 'disabled' },
      { title: 'Completed', class: 'disabled' },
      { title: 'Invoiced', class: 'disabled' },
      { title: 'Paid', class: 'disabled' },
      { title: 'Closed', class: 'disabled' },
      { title: 'Cancelled', class: 'disabled' }
    ];
    // check for work order
    if (data.workOrder && Object.keys(data.workOrder).length) {
      wfStages = this.setStageActive(0, 0, wfStages);
    }
    // check for case
    if (data.modelId) {
      wfStages = this.setStageActive(1, 1, wfStages);
    }
    // check for job
    if (data.job && Object.keys(data.job).length) {
      wfStages = this.setStageActive(2, 2, wfStages);
    }
    // when job cancelled
    if (data.job.Job_Status_Internal__c == 'Cancelled' || data.job.Job_Status_Internal__c == 'Decline') {
      this.isDeclined = true;
      wfStages = this.setStageActive(10, 10, wfStages);
    }
    // when job assigned
    if (data.job.Job_Status_Internal__c == 'Assigned') {
      wfStages = this.setStageActive(3, 3, wfStages);
    }
    // when job Started
    if (data.job.Job_Status_Internal__c == 'Started') {
      wfStages = this.setStageActive(3, 4, wfStages);
    }
    // when job Delivered
    if (data.job.Job_Status_Internal__c == 'Delivered') {
      wfStages = this.setStageActive(3, 5, wfStages);
    }
    // when job Completed
    if (data.job.Job_Status_Internal__c == 'Completed') {
      wfStages = this.setStageActive(3, 6, wfStages);
    }
    // when job Invoiced
    if (data.job.Job_Status_Internal__c == 'Invoiced') {
      wfStages = this.setStageActive(3, 7, wfStages);
    }
    // when job Paid
    if (data.job.Job_Status_Internal__c == 'Paid') {
      wfStages = this.setStageActive(3, 8, wfStages);
    }
    // when job Closed
    if (data.job.Job_Status_Internal__c == 'Closed') {
      wfStages = this.setStageActive(3, 9, wfStages);
    }

    return wfStages;
  }

  setStageActive(start, end, data) {
    for (let i = start; i <= end; i++) {
      data[i].class = 'completed';
    }
    return data;
  }

  prepareJobDetailProgressBar(data) {
    let wfStages = [
      { title: 'Contract', value: 'SC - ', class: 'inProgress' },
      { title: 'Work Order', value: 'WO - ', class: 'inProgress' },
      { title: 'ICC Case', value: 'Case - ', class: 'inProgress' },
      { title: 'Job', value: 'Job - ', class: 'inProgress' },
      { title: 'Timesheet', value: 'Time - ', class: 'inProgress' },
      { title: 'Invoice', value: 'Invoice - ', class: 'inProgress' },
      { title: 'Payment', value: 'Payment Made', class: 'inProgress' },
      { title: 'Cancelled', value: 'Job - Cancelled', class: 'inProgress' },
      { title: 'Closed', value: 'Job - Closed', class: 'inProgress' }
    ];
    // set workorder value
    if (data.workOrder && data.workOrder.Name) {
      wfStages[1].class = 'completed';
      wfStages[1].value += data.workOrder.Name;
    }
    // set icc case value
    if (data.iccCase && data.iccCase.CaseNumber) {
      wfStages[2].class = 'completed';
      wfStages[2].value += data.iccCase.CaseNumber;
    }
    // set job value
    if (data.job && data.job.Name) {
      wfStages[3].class = 'completed';
      wfStages[3].value += data.job.Name;
    }
    // set timesheet value
    if (data.job && data.job.submittedTimecard) {
      wfStages[4].class = 'completed';
      wfStages[4].value += (data.job.timecard && data.job.timecard.Name) ? data.job.timecard.Name : '';
    }
    // set invoice value
    if (data.job && data.job.Job_Invoiced_Date__c) {
      wfStages[5].class = 'completed';
    }
    // set payment value
    if (data.job && data.job.Job_Paid_Date__c) {
      wfStages[6].class = 'completed';
    }
    // set cancelled value
    if (data.job && (data.job.Job_Decline_Date__c || data.job.Job_Status_Internal__c == 'Decline')) {
      wfStages[7].class = 'completed';
    }
    // set closed value
    if (data.job && (data.job.Job_Closed_Date__c || data.job.Job_Status_Internal__c == 'Closed')) {
      wfStages[8].class = 'completed';
    }

    return wfStages;
  }
}
