import { GlobalFilterService } from './../../../services/global-filter.service';
import { FavoriteApi, CommentApi } from './../../../sdk';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PreloaderService } from 'shared/services/preloader.service';
import { AppStateService } from 'shared/services/app-state.service';
import { MessageService } from './../../../services/message.service';
import { CommonService } from 'shared/services/common.service';
import { hiddenGlobalFiltersWo } from 'shared/models/static-list-data.service';
import { environment } from 'environments/environment';
import { GlobalChatService } from 'shared/services/global-chat.service';
import { BaseVendorViewComponent } from 'msp/shared/base-vendor-view/base-vendor-view.component';
@Component({
  selector: 'app-job-message-console',
  templateUrl: './job-message-console.component.html',
  styleUrls: ['./job-message-console.component.css']
})
export class JobMessageConsoleComponent extends BaseVendorViewComponent implements OnInit {
  @Input() hidePadding = false;
  @Input() enableGC3Tabs: boolean;
  @Input() set appliedFilter(e) {
    this.filterData = e;
    this.searchCaseMessages(e);
  }
  @Input() set enableMWPTabs(e) {
    if (e) {
      this.mwpTabs = e;
      this.mwpSubTabSet();
    }
  };
  @Output() onSelectedItemChange: EventEmitter<any> = new EventEmitter;
  @Output() onEnableFilterChange: EventEmitter<any> = new EventEmitter;
  selectedItem: any;
  showFilter: Boolean = false;
  showWorkOrder: Boolean = true; // to show default open
  showJobDetail: Boolean = false;
  messageObj: any;
  refreshFavList = false;
  activityFilterObj = {};
  showActivityDetail = false;
  filterData: any;
  isFilterApplied = false;
  disabledFilter: Boolean = true;
  disabledJob: Boolean = true;
  disabledWorkorder: Boolean = true;
  // activeTab = 'caseTab';
  activeTab = 'caseWoTab';
  sidebarMenuOpened = false;
  notificationObject = {};
  selectedWorkorder: any;
  accessType: any;
  userType: any;
  counterValues = {};
  enableFilters;
  visibleMenuOptions = [];
  currentMenuOption = 'work-order';
  hiddenGlobalFilter = [...hiddenGlobalFiltersWo];
  hideActivityFeed: any;
  hideNotification: any;
  mwpTabs: any;
  isAdminPartner = false;
  // userRelatedProjects: any;

  constructor(
    private _loader: PreloaderService,
    private _favoriteApi: FavoriteApi,
    private _commentApi: CommentApi,
    private _appState: AppStateService,
    private _router: Router,
    private _messageService: MessageService,
    private _globalFilters: GlobalFilterService,
    private _commonService: CommonService,
    private _globalChatService: GlobalChatService
  ) {
    super(_appState);
    this.hideActivityFeed = environment.hideActivityFeed;
    this.hideNotification = environment.hideNotification;
    this.accessType = this._appState.getAccessType();
    // To check if view as PMS mode is activated.
    if (localStorage.getItem('viewAsInfo')) {
      const viewAsInfo = JSON.parse(localStorage.getItem('viewAsInfo'));
      const viewAsPartner = (viewAsInfo && viewAsInfo.viewAsType === 'Partner');
      this.isAdminPartner =(viewAsInfo && viewAsInfo.viewAsType === 'Partner' && viewAsInfo.isAdmin)
      this.accessType = (viewAsPartner) ? 'partner' : this.accessType;
    }

    if (this.accessType !== 'partner') {
      this.visibleMenuOptions = ['work-order', 'job-status', 'filter'];
    } else {
      this.visibleMenuOptions = ['work-order', 'filter'];
    }
  }

  caseTitle() {
    return this.enableGC3Tabs ? 'All Cases List' :
    'Case Messaging Console(' + (this.accessType === 'partner' ? 'PMS' : 'MSP') + '/WO)';
  }

  favoriteTitle() {
    return this.enableGC3Tabs ? 'My Favorite Cases' :
    'Favorites(' + (this.accessType === 'partner' ? 'PMS' : 'MSP') + '/WO)';
  }

  async ngOnInit() {
    // this.userRelatedProjects = await this._commonService.getUserAssignedProjectList();
    this.userType = this._appState.getUserTypeSlug();
    this.isFilterApplied = false;
    this.showWorkOrder = true;
    this.currentMenuOption = (!this.viewAsVendor && this.accessType === 'internal') || this.accessType === 'partner' ? 'work-order' : 'job-status';
    this.enableFilters = this._globalChatService.setChatEnableFilters(!this.viewAsVendor ? this.accessType: this.vendorAccessTypes.accessType, !this.viewAsVendor ? this.userType: this.vendorAccessTypes.userTypeSlug);
    this._passEnableFiltersInGC3();
    this.mwpSubTabSet();
    this.filterData = this._commonService.getGlobalFilter(this.enableFilters);
    this.getCount(['FaviorateAll', 'FaviorateJob']);
    // this._messageService.getCountCall().subscribe(newMsg => {
    //   if (newMsg) {
    //     this.getCount(['MSPWO', 'VMSJob']);
    //   }
    // });
    this.saveAnalyticsData(this.activeTab);
  }

  mwpSubTabSet() {
    if (this.mwpTabs) {
      this.selectedItem = {};
      this._loader.showPreloader();
      switch (this.mwpTabs) {
        case 'pmc':
          this.activeTab = 'programPMCConsoleTab';
          break;
        case 'icc':
          this.activeTab = 'caseWoTab';
          break;
        case 'billing':
          this.activeTab = 'billingSOPOConsole';
          break;
      }
      if (this.activeTab === 'programPMCConsoleTab') {
        this.enableFilters = this._globalChatService.setProgramPMCConsoleFilter();
      } else if (this.activeTab === 'billingSOPOConsole') {
        this.enableFilters = this._globalChatService.setBillingSOPOConsoleFilter();
      }
    }
  }
  private _passEnableFiltersInGC3() {
    if (this.enableGC3Tabs) {
      const menuOptions = {
        visibleMenuOptions: this.visibleMenuOptions,
        enableFilters: this.enableFilters
      }
      this.onEnableFilterChange.emit(menuOptions);
    }
  }

  disableFilter() {
    console.log('called click method on tab click')
  }

  chnageFilter( event ) {
    console.log('called tab change method', event)
  }

  /**
 *
 * @param e selected list item object
 */
  onSelectedChange(e) {
    this.selectedWorkorder = {};
    this.showFilter = false;
    this.showWorkOrder = (!this.viewAsVendor && this.accessType === 'internal') ? true : false; // default workorder case of internal
    this.showJobDetail = (!this.viewAsVendor && this.accessType === 'internal') ? false : true;

    if (e.job && e.job.Work_Order_Number) {
      e['workOrder'] = { sfdcId: e.job.Work_Order_Number };
    }
    this.selectedItem = e;
    if (e.workOrder && e.workOrder.sfdcId) {
      this.selectedWorkorder = { modelId: e.workOrder.sfdcId, modelName: 'WorkOrder' };
    }
    const jobWorkOrderObject = {
      selectedJob: e,
      selectedWorkOrder: this.selectedWorkorder
    }
    this.onSelectedItemChange.emit(jobWorkOrderObject);
  }

  /**
   *
   * @param e update fav object
   */
  onfavChange(e) {
    if (e.favoriteId) {
      this.deleteFavorite(e);
    } else {
      this.addFavorite(e);
    }
  }

  addFavorite(item) {
    this._loader.showPreloader();
    this._favoriteApi.upsert({
      modelId: item.modelId,
      modelName: item.modelName
    }).subscribe(
      result => {
        this._loader.hidePreloader();
        if (result && result['id']) {
          item.favoriteId = result['id'];
        }
        this.getCount(['FaviorateAll', 'FaviorateJob']);
      }, err => {
        this._loader.hidePreloader();
      }
    );
  }

  deleteFavorite(item) {
    this._loader.showPreloader();
    this.refreshFavList = false;
    this._favoriteApi.deleteById(item.favoriteId).subscribe(
      result => {
        this._loader.hidePreloader();
        if (result && result['count']) {
          item.favoriteId = '';
          this.refreshFavList = true;
        }
        this.getCount(['FaviorateAll', 'FaviorateJob']);
      }, err => {
        this._loader.hidePreloader();
      }
    );
  }

  onJobSelect(event) {
    this.showActivityDetail = event.isActivityLoaded ? true : false;
    if (event) {
      this.showActivityDetail = true;
    }
    if (this.showActivityDetail) {
      if (this.accessType !== 'partner') {
        this.selectedItem = { ...(event && event.job), modelId: event && event.job && event.job.entity_id, modelName: 'Job', job: event.job };
      } else {
        this.selectedItem = { ...(event && event.case), modelId: event && event.case && event.case.entity_id, modelName: 'Case', case: event.case };
      }
      this.activityFilterObj = event;
    }
    this.showFilter = false;
    this.showWorkOrder = false;
    this.showJobDetail = true;
  }

  onNotificationSelect(event) {
    this.notificationObject = event;
  }

  /**
  * to open search filter box
  */
  openFilterBox() {
    this.showFilter = true;
    this.showWorkOrder = false;
    this.showJobDetail = false;
  }
  /**
    * to close search filter box
    */
  closeFilterBox(mode = 'filter') {
    this.showWorkOrder = (mode === 'workorder') ? false : true;
    this.showJobDetail = (mode === 'job') ? false : true;
    this.showFilter = (mode === 'filter') ? false : true;
  }

  searchCaseMessages(e) {
    this.selectedItem = {};
    this.filterData = e;
    this.isFilterApplied = Object.keys(e).length ? true : false;
  }

  onApplyingSavedFilter(filterStatus) {
    this.isFilterApplied = filterStatus;
  }

  onTabChange(tab) {
    this._loader.showPreloader();
    this.activityFilterObj = {};
    if (tab.nextId === 'createCase' && this.accessType === 'partner') {
      tab.preventDefault();
      this._loader.showPreloader();
      this._router.navigateByUrl('/pms/custom-case/create/fse');
      return;
    }
    this.activeTab = tab.nextId;
    this.selectedItem = {};
    this.selectedWorkorder = {};
    this.enableFilters = [];
    this.currentMenuOption = (!this.viewAsVendor && this.accessType === 'internal') || this.accessType === 'partner' ? 'work-order' : 'job-status';
    if (tab.nextId === 'caseWoTab' || tab.nextId === 'taskCaseTab') {
      this.visibleMenuOptions = ['work-order', 'filter'];
      if (!this.viewAsVendor && this.accessType === 'internal') {
        this.visibleMenuOptions.push('job-status');
        this.enableFilters = this._globalChatService.setInternalAccessEnableFilters();
      } else {
        this.enableFilters = this._globalChatService.setChatEnableFilters(!this.viewAsVendor ? this.accessType : this.vendorAccessTypes.accessType, !this.viewAsVendor ? this.userType: this.vendorAccessTypes.userTypeSlug);
      }
      if(tab.nextId === 'caseWoTab')
      {
        this.enableFilters.push('regions');
      }
    } else if (tab.nextId === 'myCaseWoTab') {
      this.visibleMenuOptions = ['work-order', 'job-status'];
    } else if (tab.nextId === 'caseTab') {
      this.enableFilters = this._globalChatService.setOtherAccessEnableFilters(!this.viewAsVendor ? this.accessType : this.vendorAccessTypes.accessType, !this.viewAsVendor ? this.userType: this.vendorAccessTypes.userTypeSlug).concat(['messageType', 'regions']);
      this.visibleMenuOptions = ['work-order', 'job-status', 'filter'];
    } else if (tab.nextId === 'favouriteTab') {
      this.visibleMenuOptions = ['work-order', 'job-status'];
    } else if (tab.nextId === 'favouriteWoTab') {
      this.visibleMenuOptions = ['work-order'];
      if (!this.viewAsVendor && this.accessType === 'internal') {
        this.visibleMenuOptions.push('job-status');
      }
    } else if (tab.nextId === 'activityTab') {
      this.enableFilters = this._globalChatService.setOtherAccessEnableFilters(!this.viewAsVendor ? this.accessType : this.vendorAccessTypes.accessType, !this.viewAsVendor ? this.userType: this.vendorAccessTypes.userTypeSlug);
      if (this.accessType === 'partner') {
        this.visibleMenuOptions = ['work-order'];
      } else {
        this.visibleMenuOptions = ['work-order', 'filter'];
      }
    } else if (tab.nextId === 'notificationTab') {
      this.enableFilters = this._globalChatService.setOtherAccessEnableFilters(!this.viewAsVendor ? this.accessType : this.vendorAccessTypes.accessType, !this.viewAsVendor ? this.userType: this.vendorAccessTypes.userTypeSlug);
      if (this.accessType === 'partner') {
        this.visibleMenuOptions = ['work-order'];
      } else {
        this.visibleMenuOptions = ['work-order', 'filter'];
      }
    } else if (tab.nextId === 'workOrderTab') {
      this.enableFilters = this._globalChatService.setChatCommonEnableFilters().concat([
        'caseTypes',
        'pmcCaseNumbers'
      ]);
      this.visibleMenuOptions = ['work-order', 'job-status', 'filter'];
    } else if (tab.nextId === 'esclationsTab') {
      this.enableFilters = this._globalChatService.setChatCommonEnableFilters().concat([
        'caseTypes',
        'jobs',
        'pmcCaseNumbers',
        'escalationType',
        'messageType',
        'regions'
      ]);
      this.visibleMenuOptions = ['work-order', 'job-status', 'filter'];
    } else if (tab.nextId === 'programPMCConsoleTab') {
      this.enableFilters = this._globalChatService.setProgramPMCConsoleFilter();
      this.visibleMenuOptions = ['work-order', 'job-status', 'filter'];
    } else if (tab.nextId === 'billingSOPOConsole') {
      this.enableFilters = this._globalChatService.setBillingSOPOConsoleFilter();
      this.visibleMenuOptions = ['work-order', 'job-status', 'filter'];
    }
    this._passEnableFiltersInGC3();
    this.filterData = this._commonService.getGlobalFilter(this.enableFilters);
    // if (this.accessType === 'partner') {
    //   this.filterData.activityType = 'case_activity';
    //   this.filterData.Project_SOP__c = this.userRelatedProjects;
    // }
    if (this.activeTab) {
      this.saveAnalyticsData(this.activeTab);
    }
  }

  // TO CHECK IF SELECTEDITEM IS PRESENT
  isSelectedItemPresent = () => {
    if (this.selectedItem && typeof this.selectedItem === 'object') {
      if (Object.keys(this.selectedItem).length > 0) {
        return true;
      }
    }
    return false;
  }

  filterApplied(event) {
    this._globalFilters.setFilters({ clicked: true, data: event });
  }

  isRightMenuOpened(isOpened) {
    this.sidebarMenuOpened = isOpened;
    this._globalFilters.setSidebarState(isOpened);
  }

  getCount(modes) {
    let modesArr;
    if (modes === 'All') {
      modesArr = ['FaviorateAll', 'FaviorateJob']; // modesArr = ['MSPWO', 'VMSJob', 'FaviorateAll', 'FaviorateJob'];
    } else {
      modesArr = modes;
    }
    const filter = { modes: modesArr }
   // this._commentApi.getCount(filter).subscribe(
    //  res => {
        // if (res && res.MSPWO > -1) {
        //   this.counterValues['MSPWO'] = res.MSPWO;
        // }
        // if (res && res.VMSJob > -1) {
        //   this.counterValues['VMSJob'] = res.VMSJob;
        // }
      //  if (res && res.FaviorateAll > -1) {
      //    this.counterValues['FaviorateAll'] = res.FaviorateAll;
      //  }
      //  if (res && res.FaviorateJob > -1) {
     //     this.counterValues['FaviorateJob'] = res.FaviorateJob;
     //   }
      // },
      // err => {
      //   console.log(err)
      // });
  }

  saveAnalyticsData(name) {
    if (name) {
      switch (name) {
        case 'caseWoTab':
          this._commonService.saveCurrentPageAnalytics('Global Chat', 'Case Messaging Console')
          break;
        case 'caseTab':
          this._commonService.saveCurrentPageAnalytics('Global Chat', 'Case Messaging Console(VMS/Job)')
          break;
        case 'workOrderTab':
          this._commonService.saveCurrentPageAnalytics('Global Chat - WO/Job Console', 'Work Order List')
          break;
        case 'favouriteWoTab':
          this._commonService.saveCurrentPageAnalytics('Global Chat', 'Favorites')
          break;
        case 'favouriteTab':
          this._commonService.saveCurrentPageAnalytics('Global Chat', 'Favorites(VMS/Job)')
          break;
        // case 'activityTab':
        //   this._commonService.saveCurrentPageAnalytics('Global Chat', 'Activity Feed')
        //   break;
        case 'esclationsTab':
          this._commonService.saveCurrentPageAnalytics('Global Chat - WO/Job Console', 'Escalations')
          break;
        case 'notificationTab':
          this._commonService.saveCurrentPageAnalytics('Global Chat', 'Notifications')
          break;
        case 'programPMCConsoleTab':
          this._commonService.saveCurrentPageAnalytics('Global Chat - Program Console', 'Program (PMC) Console')
          break;
        case 'billingSOPOConsole':
          this._commonService.saveCurrentPageAnalytics('Global Chat - Billing (SO/PO) Console', 'Billing (SO/PO) Console')
          break;
      }
    }
  }
}
