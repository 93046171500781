import { OnInit, Component, Input, EventEmitter, Output } from '@angular/core';
import { AppStateService } from 'shared/services/app-state.service';
import { CommonService } from 'shared/services/common.service';
import { GlobalFilterService } from 'shared/services/global-filter.service';

@Component({
  selector: 'app-date-closed-lookup',
  templateUrl: './date-closed-lookup.component.html',
  styleUrls: ['./date-closed-lookup.component.css']
})
export class DateClosedLookupComponent implements OnInit {
  @Input() placeholder = 'Created Date';
  @Input() set resetData(isClear) {
    this.selectedDate = [];
  }
  @Output() selectedValue: EventEmitter<any> = new EventEmitter;
  selectedDate: any = [];
  _from = '';
  max = new Date();
  min: any;
  showMin = false;
  constructor(private _commonService: CommonService, private _appState: AppStateService, private _globalFilterService: GlobalFilterService) { }

  ngOnInit() {
    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
    if (preselected && preselected['dateClosed'] && preselected['dateClosed'][0]) {
      this.selectedDate = [this._commonService.applyNewTimeZone(preselected['dateClosed'][0][0], 'YYYY-MM-DDTHH:mm:ss'), this._commonService.applyNewTimeZone(preselected['dateClosed'][0][1], 'YYYY-MM-DDTHH:mm:ss')];
    }
    const userType = this._appState.getAccessType();
    if (userType && userType !== 'internal') {
      this.getMinimumDateValue();
      this.showMin = true;
    }
  }


  onChange() {
    this.selectedValue.emit(this.selectedDate);
  }

  onClearDate() {
    this.selectedDate = [];
    this.selectedValue.emit([]);
  }

  async getMinimumDateValue(setSelectedDate?) {
    const defaultDate = await this._globalFilterService.setFiltersDate(180);
    const startDate = [this._commonService.applyNewTimeZone(defaultDate['dateOptions'][0][0], 'YYYY-MM-DDTHH:mm:ss'), this._commonService.applyNewTimeZone(defaultDate['dateOptions'][0][1], 'YYYY-MM-DDTHH:mm:ss')];
    this.min = startDate[0];
  }

}
