import { Component, OnInit, Input } from '@angular/core';
import { Angulartics2 } from 'angulartics2';
import { AppStateService } from 'shared/services/app-state.service';
import { ModalService } from './../../../../services/modal.service';
import { UsersApi } from '../../../../sdk/services/custom/Users';

@Component({
    selector: 'user-info',
    templateUrl: './user-info.component.html',
    styleUrls: ['./user-info.component.css']
})
export class UserInfoComponent implements OnInit {

    @Input() accountId: string;
    @Input() jobId: string;
    @Input() jobNumber: string;
    @Input() worker: string;
    emailPattern = /\S+@\S+\.\S+/;
    error = '';
    userDetail: { userName: string; email: string; };
    namePattern: RegExp = /^([a-zA-Z][a-zA-Z\s]*)$/;

    constructor(
        private _modalService: ModalService,
        private _service: UsersApi,
        private _appStateService: AppStateService,
        private _angulartics2: Angulartics2
    ) { }

    ngOnInit() {
        this.userDetail = {
            userName: '',
            email: ''
        };
    }

    setPublicUserInfo(form: object) {
        if (!form['invalid']) {
            const detail = {
                'email': this.userDetail.email,
                'name': this.userDetail.userName,
                'accountId': this.accountId,
                'worker': this.worker,
                'model': 'Job',
                'modelId': this.jobId,
                'modelDisplayField': this.jobNumber
            }
            this._angulartics2.eventTrack.next({
                action: 'Public-Url-Job',
                properties: {
                    jobNumber: this.jobNumber,
                    username: this.userDetail.email,
                    isLoggedin: false
                },
            });
            this._service.checkUserStatus(detail).subscribe(res => {
                if (res && res['userRole']) {
                    this._appStateService.setJobDetailUserRole(res);
                }
            }, error => {
                console.log(error);
            })
            this._modalService.closed();
        }
    }
}

