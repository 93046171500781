import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';


import { ProjectTypeLookupComponent } from './project-type-lookup.component';

@NgModule({
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule
  ],
  declarations: [ProjectTypeLookupComponent],
  exports: [ProjectTypeLookupComponent],
})
export class ProjectTypeLookupModule { }
