/* tslint:disable */
import {
  Account,
  Jobsite,
  Address
} from '../index';

declare var Object: any;
export interface LocationInterface {
  "sfdcId"?: string;
  "CloseDate"?: Date;
  "ConstructionEndDate"?: Date;
  "ConstructionStartDate"?: Date;
  "CreatedById"?: string;
  "CreatedDate"?: Date;
  "Description"?: string;
  "DrivingDirections"?: string;
  "IsInventoryLocation"?: boolean;
  "LastModifiedById"?: string;
  "LastModifiedDate"?: Date;
  "Location"?: string;
  "LocationLevel"?: number;
  "Name"?: string;
  "LocationType"?: string;
  "IsMobile"?: boolean;
  "OpenDate"?: Date;
  "OwnerId"?: string;
  "ParentLocationId"?: string;
  "PossessionDate"?: Date;
  "RemodelEndDate"?: Date;
  "RemodelStartDate"?: Date;
  "RootLocationId"?: string;
  "TimeZone"?: string;
  "VisitorAddressId"?: string;
  "X3PL_FSL_Location__c"?: string;
  "X3PL_WSL_Location_Gateway__c"?: string;
  "X3PL_Carrier__c"?: string;
  "X3PL_Jobsite_Project_Vendor__c"?: string;
  "X3PL_NBD_Service_Level__c"?: string;
  "X3PL_Vendor__c"?: string;
  "Account_Inventory_Location__c"?: string;
  "Active_FSL__c"?: boolean;
  "FLASH_FSL_Setup__c"?: string;
  "Integration_Service_Name__c"?: string;
  "Jobsite__c"?: string;
  "Partner_Account__c"?: string;
  "Vendor_Type__c"?: string;
  "FSL_Location_SLA_Supported_Setup_NBD__c"?: string;
  "Account_ID__c"?: string;
  "FSL_Location_Status__c"?: string;
  "Sub_Inventory_Location__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  partnerAccount?: Account;
  vendor?: Account;
  jobsite?: Jobsite;
  address?: Address;
}

export class Location implements LocationInterface {
  "sfdcId": string;
  "CloseDate": Date;
  "ConstructionEndDate": Date;
  "ConstructionStartDate": Date;
  "CreatedById": string;
  "CreatedDate": Date;
  "Description": string;
  "DrivingDirections": string;
  "IsInventoryLocation": boolean;
  "LastModifiedById": string;
  "LastModifiedDate": Date;
  "Location": string;
  "LocationLevel": number;
  "Name": string;
  "LocationType": string;
  "IsMobile": boolean;
  "OpenDate": Date;
  "OwnerId": string;
  "ParentLocationId": string;
  "PossessionDate": Date;
  "RemodelEndDate": Date;
  "RemodelStartDate": Date;
  "RootLocationId": string;
  "TimeZone": string;
  "VisitorAddressId": string;
  "X3PL_FSL_Location__c": string;
  "X3PL_WSL_Location_Gateway__c": string;
  "X3PL_Carrier__c": string;
  "X3PL_Jobsite_Project_Vendor__c": string;
  "X3PL_NBD_Service_Level__c": string;
  "X3PL_Vendor__c": string;
  "Account_Inventory_Location__c": string;
  "Active_FSL__c": boolean;
  "FLASH_FSL_Setup__c": string;
  "Integration_Service_Name__c": string;
  "Jobsite__c": string;
  "Partner_Account__c": string;
  "Vendor_Type__c": string;
  "FSL_Location_SLA_Supported_Setup_NBD__c": string;
  "Account_ID__c": string;
  "FSL_Location_Status__c": string;
  "Sub_Inventory_Location__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  partnerAccount: Account;
  vendor: Account;
  jobsite: Jobsite;
  address: Address;
  constructor(data?: LocationInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Location`.
   */
  public static getModelName() {
    return "Location";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Location for dynamic purposes.
  **/
  public static factory(data: LocationInterface): Location{
    return new Location(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Location',
      plural: 'Locations',
      path: 'Locations',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "CloseDate": {
          name: 'CloseDate',
          type: 'Date'
        },
        "ConstructionEndDate": {
          name: 'ConstructionEndDate',
          type: 'Date'
        },
        "ConstructionStartDate": {
          name: 'ConstructionStartDate',
          type: 'Date'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "Description": {
          name: 'Description',
          type: 'string'
        },
        "DrivingDirections": {
          name: 'DrivingDirections',
          type: 'string'
        },
        "IsInventoryLocation": {
          name: 'IsInventoryLocation',
          type: 'boolean'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "Location": {
          name: 'Location',
          type: 'string'
        },
        "LocationLevel": {
          name: 'LocationLevel',
          type: 'number'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "LocationType": {
          name: 'LocationType',
          type: 'string'
        },
        "IsMobile": {
          name: 'IsMobile',
          type: 'boolean'
        },
        "OpenDate": {
          name: 'OpenDate',
          type: 'Date'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "ParentLocationId": {
          name: 'ParentLocationId',
          type: 'string'
        },
        "PossessionDate": {
          name: 'PossessionDate',
          type: 'Date'
        },
        "RemodelEndDate": {
          name: 'RemodelEndDate',
          type: 'Date'
        },
        "RemodelStartDate": {
          name: 'RemodelStartDate',
          type: 'Date'
        },
        "RootLocationId": {
          name: 'RootLocationId',
          type: 'string'
        },
        "TimeZone": {
          name: 'TimeZone',
          type: 'string'
        },
        "VisitorAddressId": {
          name: 'VisitorAddressId',
          type: 'string'
        },
        "X3PL_FSL_Location__c": {
          name: 'X3PL_FSL_Location__c',
          type: 'string'
        },
        "X3PL_WSL_Location_Gateway__c": {
          name: 'X3PL_WSL_Location_Gateway__c',
          type: 'string'
        },
        "X3PL_Carrier__c": {
          name: 'X3PL_Carrier__c',
          type: 'string'
        },
        "X3PL_Jobsite_Project_Vendor__c": {
          name: 'X3PL_Jobsite_Project_Vendor__c',
          type: 'string'
        },
        "X3PL_NBD_Service_Level__c": {
          name: 'X3PL_NBD_Service_Level__c',
          type: 'string'
        },
        "X3PL_Vendor__c": {
          name: 'X3PL_Vendor__c',
          type: 'string'
        },
        "Account_Inventory_Location__c": {
          name: 'Account_Inventory_Location__c',
          type: 'string'
        },
        "Active_FSL__c": {
          name: 'Active_FSL__c',
          type: 'boolean'
        },
        "FLASH_FSL_Setup__c": {
          name: 'FLASH_FSL_Setup__c',
          type: 'string'
        },
        "Integration_Service_Name__c": {
          name: 'Integration_Service_Name__c',
          type: 'string'
        },
        "Jobsite__c": {
          name: 'Jobsite__c',
          type: 'string'
        },
        "Partner_Account__c": {
          name: 'Partner_Account__c',
          type: 'string'
        },
        "Vendor_Type__c": {
          name: 'Vendor_Type__c',
          type: 'string'
        },
        "FSL_Location_SLA_Supported_Setup_NBD__c": {
          name: 'FSL_Location_SLA_Supported_Setup_NBD__c',
          type: 'string'
        },
        "Account_ID__c": {
          name: 'Account_ID__c',
          type: 'string'
        },
        "FSL_Location_Status__c": {
          name: 'FSL_Location_Status__c',
          type: 'string'
        },
        "Sub_Inventory_Location__c": {
          name: 'Sub_Inventory_Location__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        partnerAccount: {
          name: 'partnerAccount',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'Partner_Account__c',
          keyTo: 'sfdcId'
        },
        vendor: {
          name: 'vendor',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'X3PL_Vendor__c',
          keyTo: 'sfdcId'
        },
        jobsite: {
          name: 'jobsite',
          type: 'Jobsite',
          model: 'Jobsite',
          relationType: 'belongsTo',
                  keyFrom: 'Jobsite__c',
          keyTo: 'sfdcId'
        },
        address: {
          name: 'address',
          type: 'Address',
          model: 'Address',
          relationType: 'belongsTo',
                  keyFrom: 'VisitorAddressId',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
