import { CustomDualListModule } from 'shared/components/custom-dual-list/custom-dual-list.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { ConfirmDialogModule } from '../confirm-dialog/confirm-dialog.module';
import { SharedModule } from '../shared.module';
import { DirectivesModule } from '../../directives/directives.module';

import { NgSelectModule } from '@ng-select/ng-select';
import { GlobalTalentPoolLookupModule } from 'shared/components/lookup/global-talent-pool-lookup/global-talent-pool-lookup.module';
import { StateLookupModule } from 'shared/components/lookup/state-lookup/state-lookup.module';
import { StateManagerLookupModule } from 'shared/components/lookup/state-manager-lookup/state-manager-lookup.module';
import { AddTalentInformationComponent } from './add-talent-information.component';
import { AlertService } from '../../services/alert.service';
// import { PreloaderService } from '../../services/preloader.service';
import { ModalService } from '../../services/modal.service';
import { PreloaderModule } from '../preloader/preloader.module';

import { AlertModule } from '../alert/alert.module';
@NgModule({
    imports: [
        CommonModule,
        NgbModule,
        FormsModule,
        SharedModule,
        ReactiveFormsModule,
        ConfirmDialogModule,
        NgxDatatableModule,
        NgSelectModule,
        DirectivesModule,
        PreloaderModule,
        AlertModule,
        CustomDualListModule,
        GlobalTalentPoolLookupModule,
        StateLookupModule,
        StateManagerLookupModule,
    ],
    declarations: [
        AddTalentInformationComponent
    ],
    // providers: [
    //     AlertService,
    // // PreloaderService,
    //     ModalService
    // ],
    schemas: [],
    exports: [
        AddTalentInformationComponent
    ]
})
export class AddTalentInformationModule { }
