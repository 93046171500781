/* tslint:disable */

declare var Object: any;
export interface EducationHistoryInterface {
  "sfdcId"?: string;
  "ts2__Complete__c"?: boolean;
  "ts2__Contact__c"?: string;
  "ts2__DegreePicklist__c"?: string;
  "ts2__Degree__c"?: string;
  "ts2__GraduationDate__c"?: Date;
  "ts2__Graduation_Year__c"?: string;
  "ts2__Major__c"?: string;
  "ts2__Parsed__c"?: boolean;
  "ts2__School__c"?: string;
  "ts2__Name__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class EducationHistory implements EducationHistoryInterface {
  "sfdcId": string;
  "ts2__Complete__c": boolean;
  "ts2__Contact__c": string;
  "ts2__DegreePicklist__c": string;
  "ts2__Degree__c": string;
  "ts2__GraduationDate__c": Date;
  "ts2__Graduation_Year__c": string;
  "ts2__Major__c": string;
  "ts2__Parsed__c": boolean;
  "ts2__School__c": string;
  "ts2__Name__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: EducationHistoryInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `EducationHistory`.
   */
  public static getModelName() {
    return "EducationHistory";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of EducationHistory for dynamic purposes.
  **/
  public static factory(data: EducationHistoryInterface): EducationHistory{
    return new EducationHistory(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'EducationHistory',
      plural: 'EducationHistories',
      path: 'EducationHistories',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "ts2__Complete__c": {
          name: 'ts2__Complete__c',
          type: 'boolean'
        },
        "ts2__Contact__c": {
          name: 'ts2__Contact__c',
          type: 'string'
        },
        "ts2__DegreePicklist__c": {
          name: 'ts2__DegreePicklist__c',
          type: 'string'
        },
        "ts2__Degree__c": {
          name: 'ts2__Degree__c',
          type: 'string'
        },
        "ts2__GraduationDate__c": {
          name: 'ts2__GraduationDate__c',
          type: 'Date'
        },
        "ts2__Graduation_Year__c": {
          name: 'ts2__Graduation_Year__c',
          type: 'string'
        },
        "ts2__Major__c": {
          name: 'ts2__Major__c',
          type: 'string'
        },
        "ts2__Parsed__c": {
          name: 'ts2__Parsed__c',
          type: 'boolean'
        },
        "ts2__School__c": {
          name: 'ts2__School__c',
          type: 'string'
        },
        "ts2__Name__c": {
          name: 'ts2__Name__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
