/* tslint:disable */
import {
  Contact
} from '../index';

declare var Object: any;
export interface WSCRequestHeaderInterface {
  "sfdcId": string;
  "CreatedBy"?: string;
  "CurrencyIsoCode"?: string;
  "LastModifiedBy"?: string;
  "Owner"?: string;
  "Name"?: string;
  "Appointment_Calendar_End_Datetime__c"?: Date;
  "Appointment_Calendar_End_Week__c"?: number;
  "Appointment_Calendar_End_Year__c"?: number;
  "Appointment_Calendar_Start_Datetime__c"?: Date;
  "Appointment_Calendar_Start_Week__c"?: number;
  "Appointment_Calendar_Start_Year__c"?: number;
  "APVP_Profile_Attach_Optional__c"?: string;
  "Auto_and_Request_Date_Check__c"?: boolean;
  "Auto_Create_Latest_Request__c"?: Date;
  "Completed__c"?: Date;
  "Country__c"?: string;
  "Duration__c"?: number;
  "End_Date_Week__c"?: string;
  "Expire_Request__c"?: Date;
  "Geo_Metro__c"?: string;
  "IRON_ExtID__c"?: string;
  "Jobsite__c"?: string;
  "Partner_Account__c"?: string;
  "PMS_Case_Number__c"?: string;
  "Priority__c"?: string;
  "Program__c"?: string;
  "Region__c"?: string;
  "Request_Created_By__c"?: string;
  "Request_Created_Datetime__c"?: Date;
  "Request_Form__c"?: string;
  "Request_Modified_By__c"?: string;
  "Request_Priority__c"?: string;
  "Request_Status__c"?: string;
  "Request_Type__c"?: string;
  "Service_Coverage_Type__c"?: string;
  "Service_Engineer_Talent_Level__c"?: string;
  "Service_Engineer_Talent_Type__c"?: string;
  "Service_Territory__c"?: string;
  "Service_Type__c"?: string;
  "Start_Date_Formula__c"?: Date;
  "Start_Date_Week__c"?: string;
  "State__c"?: string;
  "Task__c"?: string;
  "WAR_Link_to_WMS__c"?: string;
  "Worker_Availability_Auto_Create__c"?: string;
  "Work_Order_Line_Number__c"?: string;
  "Work_Order_Number__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  contact?: Contact;
  wscRequestHeader?: WSCRequestHeader;
}

export class WSCRequestHeader implements WSCRequestHeaderInterface {
  "sfdcId": string;
  "CreatedBy": string;
  "CurrencyIsoCode": string;
  "LastModifiedBy": string;
  "Owner": string;
  "Name": string;
  "Appointment_Calendar_End_Datetime__c": Date;
  "Appointment_Calendar_End_Week__c": number;
  "Appointment_Calendar_End_Year__c": number;
  "Appointment_Calendar_Start_Datetime__c": Date;
  "Appointment_Calendar_Start_Week__c": number;
  "Appointment_Calendar_Start_Year__c": number;
  "APVP_Profile_Attach_Optional__c": string;
  "Auto_and_Request_Date_Check__c": boolean;
  "Auto_Create_Latest_Request__c": Date;
  "Completed__c": Date;
  "Country__c": string;
  "Duration__c": number;
  "End_Date_Week__c": string;
  "Expire_Request__c": Date;
  "Geo_Metro__c": string;
  "IRON_ExtID__c": string;
  "Jobsite__c": string;
  "Partner_Account__c": string;
  "PMS_Case_Number__c": string;
  "Priority__c": string;
  "Program__c": string;
  "Region__c": string;
  "Request_Created_By__c": string;
  "Request_Created_Datetime__c": Date;
  "Request_Form__c": string;
  "Request_Modified_By__c": string;
  "Request_Priority__c": string;
  "Request_Status__c": string;
  "Request_Type__c": string;
  "Service_Coverage_Type__c": string;
  "Service_Engineer_Talent_Level__c": string;
  "Service_Engineer_Talent_Type__c": string;
  "Service_Territory__c": string;
  "Service_Type__c": string;
  "Start_Date_Formula__c": Date;
  "Start_Date_Week__c": string;
  "State__c": string;
  "Task__c": string;
  "WAR_Link_to_WMS__c": string;
  "Worker_Availability_Auto_Create__c": string;
  "Work_Order_Line_Number__c": string;
  "Work_Order_Number__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  contact: Contact;
  wscRequestHeader: WSCRequestHeader;
  constructor(data?: WSCRequestHeaderInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `WSCRequestHeader`.
   */
  public static getModelName() {
    return "WSCRequestHeader";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of WSCRequestHeader for dynamic purposes.
  **/
  public static factory(data: WSCRequestHeaderInterface): WSCRequestHeader{
    return new WSCRequestHeader(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'WSCRequestHeader',
      plural: 'WSCRequestHeaders',
      path: 'WSCRequestHeaders',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "CreatedBy": {
          name: 'CreatedBy',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "LastModifiedBy": {
          name: 'LastModifiedBy',
          type: 'string'
        },
        "Owner": {
          name: 'Owner',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "Appointment_Calendar_End_Datetime__c": {
          name: 'Appointment_Calendar_End_Datetime__c',
          type: 'Date'
        },
        "Appointment_Calendar_End_Week__c": {
          name: 'Appointment_Calendar_End_Week__c',
          type: 'number'
        },
        "Appointment_Calendar_End_Year__c": {
          name: 'Appointment_Calendar_End_Year__c',
          type: 'number'
        },
        "Appointment_Calendar_Start_Datetime__c": {
          name: 'Appointment_Calendar_Start_Datetime__c',
          type: 'Date'
        },
        "Appointment_Calendar_Start_Week__c": {
          name: 'Appointment_Calendar_Start_Week__c',
          type: 'number'
        },
        "Appointment_Calendar_Start_Year__c": {
          name: 'Appointment_Calendar_Start_Year__c',
          type: 'number'
        },
        "APVP_Profile_Attach_Optional__c": {
          name: 'APVP_Profile_Attach_Optional__c',
          type: 'string'
        },
        "Auto_and_Request_Date_Check__c": {
          name: 'Auto_and_Request_Date_Check__c',
          type: 'boolean'
        },
        "Auto_Create_Latest_Request__c": {
          name: 'Auto_Create_Latest_Request__c',
          type: 'Date'
        },
        "Completed__c": {
          name: 'Completed__c',
          type: 'Date'
        },
        "Country__c": {
          name: 'Country__c',
          type: 'string'
        },
        "Duration__c": {
          name: 'Duration__c',
          type: 'number'
        },
        "End_Date_Week__c": {
          name: 'End_Date_Week__c',
          type: 'string'
        },
        "Expire_Request__c": {
          name: 'Expire_Request__c',
          type: 'Date'
        },
        "Geo_Metro__c": {
          name: 'Geo_Metro__c',
          type: 'string'
        },
        "IRON_ExtID__c": {
          name: 'IRON_ExtID__c',
          type: 'string'
        },
        "Jobsite__c": {
          name: 'Jobsite__c',
          type: 'string'
        },
        "Partner_Account__c": {
          name: 'Partner_Account__c',
          type: 'string'
        },
        "PMS_Case_Number__c": {
          name: 'PMS_Case_Number__c',
          type: 'string'
        },
        "Priority__c": {
          name: 'Priority__c',
          type: 'string'
        },
        "Program__c": {
          name: 'Program__c',
          type: 'string'
        },
        "Region__c": {
          name: 'Region__c',
          type: 'string'
        },
        "Request_Created_By__c": {
          name: 'Request_Created_By__c',
          type: 'string'
        },
        "Request_Created_Datetime__c": {
          name: 'Request_Created_Datetime__c',
          type: 'Date'
        },
        "Request_Form__c": {
          name: 'Request_Form__c',
          type: 'string'
        },
        "Request_Modified_By__c": {
          name: 'Request_Modified_By__c',
          type: 'string'
        },
        "Request_Priority__c": {
          name: 'Request_Priority__c',
          type: 'string'
        },
        "Request_Status__c": {
          name: 'Request_Status__c',
          type: 'string'
        },
        "Request_Type__c": {
          name: 'Request_Type__c',
          type: 'string'
        },
        "Service_Coverage_Type__c": {
          name: 'Service_Coverage_Type__c',
          type: 'string'
        },
        "Service_Engineer_Talent_Level__c": {
          name: 'Service_Engineer_Talent_Level__c',
          type: 'string'
        },
        "Service_Engineer_Talent_Type__c": {
          name: 'Service_Engineer_Talent_Type__c',
          type: 'string'
        },
        "Service_Territory__c": {
          name: 'Service_Territory__c',
          type: 'string'
        },
        "Service_Type__c": {
          name: 'Service_Type__c',
          type: 'string'
        },
        "Start_Date_Formula__c": {
          name: 'Start_Date_Formula__c',
          type: 'Date'
        },
        "Start_Date_Week__c": {
          name: 'Start_Date_Week__c',
          type: 'string'
        },
        "State__c": {
          name: 'State__c',
          type: 'string'
        },
        "Task__c": {
          name: 'Task__c',
          type: 'string'
        },
        "WAR_Link_to_WMS__c": {
          name: 'WAR_Link_to_WMS__c',
          type: 'string'
        },
        "Worker_Availability_Auto_Create__c": {
          name: 'Worker_Availability_Auto_Create__c',
          type: 'string'
        },
        "Work_Order_Line_Number__c": {
          name: 'Work_Order_Line_Number__c',
          type: 'string'
        },
        "Work_Order_Number__c": {
          name: 'Work_Order_Number__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        contact: {
          name: 'contact',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Request_Created_By__c',
          keyTo: 'sfdcId'
        },
        wscRequestHeader: {
          name: 'wscRequestHeader',
          type: 'WSCRequestHeader',
          model: 'WSCRequestHeader',
          relationType: 'belongsTo',
                  keyFrom: 'WAR_Link_to_WMS__c',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
