import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterAssignments'
})
export class FilterAssignmentsPipe implements PipeTransform {

  transform(items: any, forWorker?: boolean): any {
    if (!items) {
      return items;
    }    
    //ForWorker is the flag to determine whether we need assignments with worker or without worker.
    if(forWorker){
      return items.filter(item => Boolean(item['worker']));
    }else{
      return items.filter(item => !Boolean(item['worker']));
    }    
  }
}