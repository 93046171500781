import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { JobTypesFilterList } from 'shared/models/static-list-data.service';
import { PmsIccPmcRecordTypeMappingApi, RecordTypeApi } from 'shared/sdk/index';
import { debounceTime, distinctUntilChanged, switchMap, filter } from 'rxjs/operators';

@Component({
  selector: 'app-job-type-lookup',
  templateUrl: './job-type-lookup.component.html',
  styleUrls: ['./job-type-lookup.component.css']
})
export class JobTypeLookupComponent implements OnInit {
  @Output() getJobTypeObj: EventEmitter<any> = new EventEmitter;
  @Input() placeholder = 'Job Type';
  @Input() isdisabled = false;
  @Input() isMultipleSelected = true;
  @Input() set resetData(isClear) {
    if (isClear) {
      this.jobTypeSelected = [];
    }
  }
  @Input() viewType = '';

  jobTypeList: Array<any>;
  jobType = 'Searching...';
  jobTypeahead = new EventEmitter<string>();
  jobTypeSelected = [];
  filterJobType: any = {};
  jobRecordType: any = {};


  constructor(private _pmsIccPmcRecordTypeMappingApi: PmsIccPmcRecordTypeMappingApi,
    private _recordTypeApi: RecordTypeApi) {

  }

  ngOnInit() {
    this.getJobTypeInfo();
    this.jobTypeList = JobTypesFilterList;
    // to show selected job types

    const selectFilter = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
    if (selectFilter && selectFilter['jobTypes']) {
      this.jobTypeSelected = selectFilter['jobTypes'];
    }
  }

  onJobTypeChange() {
    const recordTypeIds = this.getJobTypeFilterCondition(this.jobTypeSelected);
    this.getJobTypeObj.emit({ jobTypes: this.jobTypeSelected, recordTypeIds: recordTypeIds });
  }

  clearJobType() {
    this.jobTypeSelected = [];
    this.getJobTypeObj.emit({});
  }

  /**
   * getJobTypeInfo
   */
  getJobTypeInfo() {
    const reqObj = {
      'where': {
        'IsICC__c': true
      },
      'fields': ['sfdcId', 'RecordTypes__c', 'IsICC__c', 'X3PL__c', 'IsFTE__c', 'IsHMS__c'],
      'include': [
        {
          'relation': 'recordtype'
        }]
    };
    this._pmsIccPmcRecordTypeMappingApi.find(reqObj).subscribe((filterList: any) => {
      let fte = [];
      let fru = [];
      let fse = [];
      for (let zz = 0; zz < filterList.length; zz++) {
        const el = filterList[zz];
        if (el.IsFTE__c == true) {
          fte.push(el.recordtype.sfdcId);
        } else if (el.X3PL__c == true) {
          fru.push(el.recordtype.sfdcId);
        } else if (!el.X3PL__c && !el.IsFTE__c && !el.IsHMS__c) {
          fse.push(el.recordtype.sfdcId);
        }

      }
      this.filterJobType['fte'] = fte;
      this.filterJobType['fru'] = fru;
      this.filterJobType['fse'] = fse;

    }, error => {

    });
    // const reqObj2 = {
    //   'where': {
    //     'SobjectType': 'CKSW_BASE__Service__c'
    //   },
    //   'fields': ['sfdcId', 'Name']
    // };
    // this._recordTypeApi.find(reqObj2).subscribe((jobTypeList: any) => {
    //   jobTypeList.forEach(element => {
    //     switch (element.Name) {
    //       case "FTE Dispatch":
    //         this.jobRecordType['fte'] = element.sfdcId;
    //         break;
    //       case "FSE Dispatch":
    //         this.jobRecordType['fse'] = element.sfdcId;
    //         break;
    //       case "FRU Dispatch":
    //         this.jobRecordType['fru'] = element.sfdcId;
    //         break;
    //     }
    //   });
    // });
  }

  /**
   * 
   * @param filterCondition | prepair job typs filter
   */
  getJobTypeFilterCondition(jobTypes) {
    let q = [];
    jobTypes.forEach(element => {
      switch (element) {
        case '0':
          q.push({ or: [{ 'icc_recordtype_id': { inq: this.filterJobType.fse } }, { 'is_backfill_worker': true }] });
          break;
        case '1':
          q.push({ 'icc_recordtype_id': { inq: this.filterJobType.fte } });
          break;
        case '2':
          q.push({ 'icc_recordtype_id': { inq: this.filterJobType.fse } });
          break;
        case '3':
          q.push({ and: [{ 'icc_recordtype_id': { inq: this.filterJobType.fte } }, { 'is_backfill_worker': true }] });
          break;
        case '4':
           q.push({ and: [{ 'icc_recordtype_id': { inq:this.filterJobType.fte } },
           { or: [{ 'is_backfill_worker':false }, { 'is_backfill_worker':null }, { 'is_backfill_worker':'' }]  }] });
          break;
        case '5':
          q.push({ 'icc_recordtype_id': { inq: this.filterJobType.fru } });
          break;
      }
    });
    return q;
  }
}
