import { Component, OnInit, EventEmitter, Output, OnDestroy, Input } from '@angular/core';
import { NotificationService } from '../../../services/notification.service';
import { PreloaderService } from 'shared/services/preloader.service';
import { Observable, Subscription } from 'rxjs';
import { CommonService } from 'shared/services/common.service';
import { AppStateService } from 'shared/services/app-state.service';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.css']
})

export class NotificationListComponent implements OnInit, OnDestroy {
  @Output() selectedNotification = new EventEmitter();
  @Input()
  set filterData(e) {
    if (e) {
      this.notifications = [];
      this.isLoadMore = false;
      this.page.pageNumber = 1;
      this.notificationFilter = e;
      this.selectedNotification.emit(false);
      this.getFeeds(this.page);
    }
  }
@Input() saveAnalytics = true;
  activeId: any;
  loadingIndicator = false;
  page = {
    size: 20,
    totalElements: 0,
    totalPages: 0,
    pageNumber: 1
  }
  notificationFilter: any;

  notifications = [];

  private _statusCss = {
    p1: 'p1',
    p2: 'p2',
    p3: 'p3',
    p4: 'p4',
    p5: 'p5',
    p6: 'p6'
  };
  isLoadMore = false;
  userId: any;
  private timerSub: Subscription;
  accessType: any;
  constructor(
    private _notificationService: NotificationService,
    private _loader: PreloaderService,
    private _commonService: CommonService,
    private _appState: AppStateService,
  ) {

  }

  ngOnInit() {
    this.accessType = this._appState.getAccessType();
    if (localStorage.getItem('viewAsInfo')) {
      const viewAsInfo = JSON.parse(localStorage.getItem('viewAsInfo'));
      const viewAsPartner = (viewAsInfo && viewAsInfo.viewAsType === 'Partner');
      this.accessType = (viewAsPartner) ? 'partner' : this.accessType;
    }
    this.userId = JSON.parse(localStorage.getItem('appData')).user.contact.UserId;
    if (this.saveAnalytics) {
      this._commonService.saveCurrentPageAnalytics('Global Chat', 'Notification List')
    }
    if (localStorage.getItem("filterCondition")) {
      this.notificationFilter = JSON.parse(localStorage.getItem("filterCondition"));
    }
    this.setPage(this.page)
  }

  getFeeds(offset) {
    if (this.page.pageNumber === 1) {
      this._loader.showPreloader();
    }
    this.loadingIndicator = true;
    const whereCondition = {
      entity_name: 'Job',
      userInfo : { id : this.userId },
      accessType : this.accessType
    }
    this._notificationService.getNotifications(
      offset['pageNumber'],
        this.page.size,
        whereCondition,
        this.notificationFilter
    ).
      then((data: any) => {
        this._loader.hidePreloader();
        this.loadingIndicator = false;
        if (data && data['data']) {
          
          this.notifications = [...this.notifications, ...data['data']];
          this.isLoadMore = (this.notifications && this.notifications.length < data['total']) ? true : false;
          if (this.page.pageNumber === 1) {
            this.selectFirstRecord();
          }
          this.page.pageNumber = offset['pageNumber'] + 1;
          if(data.total == 0 || data.total == "0"){
            this.selectedNotification.emit(false);
          }
        } else {
          this.isLoadMore = false;
          this.selectedNotification.emit(false);
        }

      }).catch((err: any) => {
        console.log('Error!  ', err);
      })
  }

  /**
 * Populate the table with new data based on the page number
 * @param page The page to select
 */
  setPage(pageInfo) {
    this.isLoadMore = false;
    this.page.pageNumber = pageInfo.pageNumber;
    this.getFeeds(this.page);
  }

  /**
  * select first item of list
  */
  selectFirstRecord() {
    if (this.notifications.length) {
      this.activeId = 0;
      this.selectedNotification.emit(this.notifications[0]);
    }
  }

  onClick(event: Event, item: any, button: string, index:number) {
    event.stopPropagation();
    this.activeId = index;
    this.selectedNotification.emit(item);
    this.markReadById(item);
  }

  onRefresh() {
    this.setPage({ pageNumber: 1 })
  }

  unsubscribeTimer() {
    if (this.timerSub) {
      this.timerSub.unsubscribe();
    }
  }

  ngOnDestroy() {
    // this.unsubscribeTimer();
  }

  getPriorityClass(priority: string = "") {
    priority = (priority && priority.length && priority.trim()) ? priority.trim().slice(0, 2).toLowerCase() : ''
    if (this._statusCss.hasOwnProperty(priority)) {
      return this._statusCss[priority];
    } else {
      return "default-priority";
    }
  }

  markAllRead() {
    this._loader.showPreloader();
    this._notificationService.readAll()
      .then(res => {
        this.notifications.forEach((e, i) => {
          this.notifications[i]['marked_read'] = true;
        });
        this._loader.hidePreloader();
      }).catch(err => {
        this._loader.hidePreloader();
        console.log(err);
      });
  }

  markReadById(item: any) {
    this._notificationService.readByactivityId(item.activity_id)
      .then(res => {
        item.marked_read = true;
      }).catch(err => {
        console.log(err);
      });
  }

  onloadMoreChange() {
    if (this.isLoadMore) {
      this.setPage(this.page);
    }
  }
}