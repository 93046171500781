/* tslint:disable */
import {
  Account,
  Timecard,
  Payment,
  Job,
  PurchaseOrder,
  Contact,
  RecordType,
  Order,
  ServiceContract,
  MonthlyInvoice,
  InvoiceAdjustment
} from '../index';

declare var Object: any;
export interface InvoiceInterface {
  "sfdcId": string;
  "Account__c"?: string;
  "Actual_VAT_Tax__c"?: number;
  "Adjustment_Amount__c"?: number;
  "Allow_Adjustment__c"?: boolean;
  "Amount__c"?: number;
  "Amount_overridden_by_Adjustment__c"?: boolean;
  "Balance__c"?: number;
  "Count_of_Order_Items__c"?: number;
  "CreatedById"?: string;
  "CreatedDate"?: Date;
  "CurrencyIsoCode"?: string;
  "Custom_PPE_Hour_s_Price_PO__c"?: number;
  "Custom_PPE_Hour_s_Price_SO__c"?: number;
  "Due_Date__c"?: Date;
  "FTE_Fixed_Hourly__c"?: boolean;
  "FTE_Monthly_Rate__c"?: number;
  "FTE_Partner_Monthly_Rate_Custom__c"?: number;
  "FTE_Vendor_Monthly_Rate_Custom__c"?: number;
  "Has_Monthly_Invoice__c"?: boolean;
  "Has_Overtime__c"?: boolean;
  "Hourly_PO__c"?: boolean;
  "Hourly_SO__c"?: boolean;
  "IRON_ICC_Case_Number__c"?: string;
  "Invoice_Created_Date_Time__c"?: Date;
  "Invoice_Date__c"?: Date;
  "Invoice_Notes__c"?: string;
  "Invoice_Offset__c"?: number;
  "Iron_Job__c"?: string;
  "Iron_Legal_Entity_Country__c"?: string;
  "Iron_Legal_Entity_Currency__c"?: string;
  "Iron_PMS_Case_Number__c"?: string;
  "Iron_Purchase_Order_Reference__c"?: string;
  "IsDeleted"?: boolean;
  "Job_Number__c"?: string;
  "Job_Status__c"?: string;
  "Job_Worker__c"?: string;
  "Job__c"?: string;
  "Job_has_Job_Schedule__c"?: boolean;
  "Jobsite_Name__c"?: string;
  "LastActivityDate"?: Date;
  "LastModifiedById"?: string;
  "LastModifiedDate"?: Date;
  "LastReferencedDate"?: Date;
  "LastViewedDate"?: Date;
  "Last_Adjustment_Notes_External__c"?: string;
  "Last_Adjustment_Notes_MSP_Internal__c"?: string;
  "Last_Approved_Timecard_Amount__c"?: number;
  "Last_Invoice_Adjustment_Audit_Tracker__c"?: string;
  "Latest_Payment__c"?: string;
  "Monthly_Invoice_Item__c"?: string;
  "Monthly_Invoice__c"?: string;
  "Name"?: string;
  "Overtime_Amount__c"?: number;
  "PO_Additional_Hours_Price_Roll_up__c"?: number;
  "PO_Additional_Hours_Price__c"?: number;
  "PO_Already_Invoiced_Amount__c"?: number;
  "PO_Already_Invoiced_Hours__c"?: number;
  "PO_Amount__c"?: number;
  "PO_FTE_Hours__c"?: number;
  "PO_List_Price__c"?: number;
  "PO_Number__c"?: string;
  "PO_PPE_Hours_Price__c"?: number;
  "PO_PPE_Hours__c"?: number;
  "PO_Total_Approved_timesheet_Hours__c"?: number;
  "Partner_Case_Number__c"?: string;
  "Payment_Term__c"?: number;
  "Program__c"?: string;
  "Purchase_Invoice__c"?: string;
  "Purchase_Order_Start_Date__c"?: Date;
  "Purchase_Order__c"?: string;
  "Purchase_Order_on_Job__c"?: string;
  "Ready_for_Approval__c"?: boolean;
  "Ready_for_Review__c"?: boolean;
  "RecordTypeId"?: string;
  "Recordtypeid__c"?: string;
  "Reviewer__c"?: string;
  "Roll_up_Discount__c"?: string;
  "Roll_up_Timecard_s_IRON_Approval_Status__c"?: string;
  "Roll_up_VAT__c"?: number;
  "Roll_up_Vat_Tax__c"?: number;
  "Roll_up_helpdesk_Price__c"?: number;
  "SC__c"?: string;
  "SKU__c"?: string;
  "SLA_Coverage_Hours__c"?: string;
  "SO_Additional_Hours_Price__c"?: number;
  "SO_Additional_Hours__c"?: number;
  "SO_Already_Invoiced_Amount__c"?: number;
  "SO_Already_Invoiced_Hours__c"?: number;
  "SO_FTE_Hours__c"?: number;
  "SO_Last_Approved_Timecard_Hours_OT__c"?: number;
  "SO_Last_Approved_Timecard_Hours_Std__c"?: number;
  "SO_Last_Approved_Timecard_Hours_Weekend__c"?: number;
  "SO_PPE_Hours_Price__c"?: number;
  "SO_PPE_Hours__c"?: number;
  "SO_Roll_up_Additional_Rate__c"?: number;
  "SO_Roll_up_PPE_Hours__c"?: number;
  "SO_Roll_up_Unit_Rate__c"?: number;
  "Sale_Order__c"?: string;
  "Sales_Invoice__c"?: string;
  "Sales_Order_Amount__c"?: number;
  "Sales_Order_End_Date__c"?: Date;
  "Sales_Order_Number__c"?: string;
  "Sales_Order_PMS__c"?: string;
  "Sales_Order_Start_Date__c"?: Date;
  "Service_Contract__c"?: string;
  "Status__c"?: string;
  "Talent_Type__c"?: string;
  "Timecard_Received_Final__c"?: string;
  "Timecard_Timesheet__c"?: string;
  "Timesheet_Month__c"?: string;
  "Timesheet_Year__c"?: string;
  "Total_After_Hours__c"?: number;
  "Total_Hours_Invoiced__c"?: number;
  "Total_Hours_Worked__c"?: number;
  "Total_Paid__c"?: number;
  "Total_Standard_Hours_Per_Visit__c"?: number;
  "Total_Timecard_Hours_No_offset__c"?: number;
  "Total_Timesheet_Hours__c"?: number;
  "Total_Un_Invoiced_Amount__c"?: number;
  "Total_Visit_Hours__c"?: number;
  "Total_Weekend_Hours__c"?: number;
  "Vat_Calc__c"?: number;
  "Vendor_Purchase_Receipt_Number__c"?: string;
  "Vendor_Time_Card_Notes_Tasks_Performed__c"?: string;
  "Vendor_Time_Card_Observation_Suggestion__c"?: string;
  "Vendor_Time_Card_Total_Hours_All_Visits__c"?: number;
  "Vendor_Timecard_Actual_In__c"?: string;
  "Vendor_Timecard_Actual_Out__c"?: string;
  "Vendor_Timecard_Cust_Site_Sign_off_Name__c"?: string;
  "Vendor_Timecard_Details_Roll_up_Auto__c"?: string;
  "Vendor_Timecard_Details_Roll_up__c"?: string;
  "Vendor__c"?: string;
  "Work_Order_Gross_Margin_Analysis__c"?: string;
  "Worker_on_the_Job__c"?: string;
  "Profit_Loss_Amount__c"?: number;
  "Profit_Loss__c"?: number;
  "Conversion_Rate_To_USD__c"?: number;
  "Invoice_Total_USD__c"?: number;
  "Billing_Code_Calculation_Description__c"?: string;
  "Billing_Calculation_Formula_Description__c"?: string;
  "Billing_Code_Name_MSP__c"?: string;
  "Not_to_be_Billed_to_Customer__c"?: boolean;
  "Current_Approval_Level__c"?: string;
  "Approval_Levels_Required__c"?: string;
  "id"?: number;
  vendor?: Account;
  timecard?: Timecard;
  payments?: Payment[];
  jobs?: Job;
  purchaseOrder?: PurchaseOrder;
  contact?: Contact;
  recordType?: RecordType;
  saleOrder?: Order;
  serviceContract?: ServiceContract;
  monthlyInvoice?: MonthlyInvoice;
  invoiceAdjustment?: InvoiceAdjustment[];
}

export class Invoice implements InvoiceInterface {
  "sfdcId": string;
  "Account__c": string;
  "Actual_VAT_Tax__c": number;
  "Adjustment_Amount__c": number;
  "Allow_Adjustment__c": boolean;
  "Amount__c": number;
  "Amount_overridden_by_Adjustment__c": boolean;
  "Balance__c": number;
  "Count_of_Order_Items__c": number;
  "CreatedById": string;
  "CreatedDate": Date;
  "CurrencyIsoCode": string;
  "Custom_PPE_Hour_s_Price_PO__c": number;
  "Custom_PPE_Hour_s_Price_SO__c": number;
  "Due_Date__c": Date;
  "FTE_Fixed_Hourly__c": boolean;
  "FTE_Monthly_Rate__c": number;
  "FTE_Partner_Monthly_Rate_Custom__c": number;
  "FTE_Vendor_Monthly_Rate_Custom__c": number;
  "Has_Monthly_Invoice__c": boolean;
  "Has_Overtime__c": boolean;
  "Hourly_PO__c": boolean;
  "Hourly_SO__c": boolean;
  "IRON_ICC_Case_Number__c": string;
  "Invoice_Created_Date_Time__c": Date;
  "Invoice_Date__c": Date;
  "Invoice_Notes__c": string;
  "Invoice_Offset__c": number;
  "Iron_Job__c": string;
  "Iron_Legal_Entity_Country__c": string;
  "Iron_Legal_Entity_Currency__c": string;
  "Iron_PMS_Case_Number__c": string;
  "Iron_Purchase_Order_Reference__c": string;
  "IsDeleted": boolean;
  "Job_Number__c": string;
  "Job_Status__c": string;
  "Job_Worker__c": string;
  "Job__c": string;
  "Job_has_Job_Schedule__c": boolean;
  "Jobsite_Name__c": string;
  "LastActivityDate": Date;
  "LastModifiedById": string;
  "LastModifiedDate": Date;
  "LastReferencedDate": Date;
  "LastViewedDate": Date;
  "Last_Adjustment_Notes_External__c": string;
  "Last_Adjustment_Notes_MSP_Internal__c": string;
  "Last_Approved_Timecard_Amount__c": number;
  "Last_Invoice_Adjustment_Audit_Tracker__c": string;
  "Latest_Payment__c": string;
  "Monthly_Invoice_Item__c": string;
  "Monthly_Invoice__c": string;
  "Name": string;
  "Overtime_Amount__c": number;
  "PO_Additional_Hours_Price_Roll_up__c": number;
  "PO_Additional_Hours_Price__c": number;
  "PO_Already_Invoiced_Amount__c": number;
  "PO_Already_Invoiced_Hours__c": number;
  "PO_Amount__c": number;
  "PO_FTE_Hours__c": number;
  "PO_List_Price__c": number;
  "PO_Number__c": string;
  "PO_PPE_Hours_Price__c": number;
  "PO_PPE_Hours__c": number;
  "PO_Total_Approved_timesheet_Hours__c": number;
  "Partner_Case_Number__c": string;
  "Payment_Term__c": number;
  "Program__c": string;
  "Purchase_Invoice__c": string;
  "Purchase_Order_Start_Date__c": Date;
  "Purchase_Order__c": string;
  "Purchase_Order_on_Job__c": string;
  "Ready_for_Approval__c": boolean;
  "Ready_for_Review__c": boolean;
  "RecordTypeId": string;
  "Recordtypeid__c": string;
  "Reviewer__c": string;
  "Roll_up_Discount__c": string;
  "Roll_up_Timecard_s_IRON_Approval_Status__c": string;
  "Roll_up_VAT__c": number;
  "Roll_up_Vat_Tax__c": number;
  "Roll_up_helpdesk_Price__c": number;
  "SC__c": string;
  "SKU__c": string;
  "SLA_Coverage_Hours__c": string;
  "SO_Additional_Hours_Price__c": number;
  "SO_Additional_Hours__c": number;
  "SO_Already_Invoiced_Amount__c": number;
  "SO_Already_Invoiced_Hours__c": number;
  "SO_FTE_Hours__c": number;
  "SO_Last_Approved_Timecard_Hours_OT__c": number;
  "SO_Last_Approved_Timecard_Hours_Std__c": number;
  "SO_Last_Approved_Timecard_Hours_Weekend__c": number;
  "SO_PPE_Hours_Price__c": number;
  "SO_PPE_Hours__c": number;
  "SO_Roll_up_Additional_Rate__c": number;
  "SO_Roll_up_PPE_Hours__c": number;
  "SO_Roll_up_Unit_Rate__c": number;
  "Sale_Order__c": string;
  "Sales_Invoice__c": string;
  "Sales_Order_Amount__c": number;
  "Sales_Order_End_Date__c": Date;
  "Sales_Order_Number__c": string;
  "Sales_Order_PMS__c": string;
  "Sales_Order_Start_Date__c": Date;
  "Service_Contract__c": string;
  "Status__c": string;
  "Talent_Type__c": string;
  "Timecard_Received_Final__c": string;
  "Timecard_Timesheet__c": string;
  "Timesheet_Month__c": string;
  "Timesheet_Year__c": string;
  "Total_After_Hours__c": number;
  "Total_Hours_Invoiced__c": number;
  "Total_Hours_Worked__c": number;
  "Total_Paid__c": number;
  "Total_Standard_Hours_Per_Visit__c": number;
  "Total_Timecard_Hours_No_offset__c": number;
  "Total_Timesheet_Hours__c": number;
  "Total_Un_Invoiced_Amount__c": number;
  "Total_Visit_Hours__c": number;
  "Total_Weekend_Hours__c": number;
  "Vat_Calc__c": number;
  "Vendor_Purchase_Receipt_Number__c": string;
  "Vendor_Time_Card_Notes_Tasks_Performed__c": string;
  "Vendor_Time_Card_Observation_Suggestion__c": string;
  "Vendor_Time_Card_Total_Hours_All_Visits__c": number;
  "Vendor_Timecard_Actual_In__c": string;
  "Vendor_Timecard_Actual_Out__c": string;
  "Vendor_Timecard_Cust_Site_Sign_off_Name__c": string;
  "Vendor_Timecard_Details_Roll_up_Auto__c": string;
  "Vendor_Timecard_Details_Roll_up__c": string;
  "Vendor__c": string;
  "Work_Order_Gross_Margin_Analysis__c": string;
  "Worker_on_the_Job__c": string;
  "Profit_Loss_Amount__c": number;
  "Profit_Loss__c": number;
  "Conversion_Rate_To_USD__c": number;
  "Invoice_Total_USD__c": number;
  "Billing_Code_Calculation_Description__c": string;
  "Billing_Calculation_Formula_Description__c": string;
  "Billing_Code_Name_MSP__c": string;
  "Not_to_be_Billed_to_Customer__c": boolean;
  "Current_Approval_Level__c": string;
  "Approval_Levels_Required__c": string;
  "id": number;
  vendor: Account;
  timecard: Timecard;
  payments: Payment[];
  jobs: Job;
  purchaseOrder: PurchaseOrder;
  contact: Contact;
  recordType: RecordType;
  saleOrder: Order;
  serviceContract: ServiceContract;
  monthlyInvoice: MonthlyInvoice;
  invoiceAdjustment: InvoiceAdjustment[];
  constructor(data?: InvoiceInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Invoice`.
   */
  public static getModelName() {
    return "Invoice";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Invoice for dynamic purposes.
  **/
  public static factory(data: InvoiceInterface): Invoice{
    return new Invoice(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Invoice',
      plural: 'Invoices',
      path: 'Invoices',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "Account__c": {
          name: 'Account__c',
          type: 'string'
        },
        "Actual_VAT_Tax__c": {
          name: 'Actual_VAT_Tax__c',
          type: 'number'
        },
        "Adjustment_Amount__c": {
          name: 'Adjustment_Amount__c',
          type: 'number'
        },
        "Allow_Adjustment__c": {
          name: 'Allow_Adjustment__c',
          type: 'boolean'
        },
        "Amount__c": {
          name: 'Amount__c',
          type: 'number'
        },
        "Amount_overridden_by_Adjustment__c": {
          name: 'Amount_overridden_by_Adjustment__c',
          type: 'boolean'
        },
        "Balance__c": {
          name: 'Balance__c',
          type: 'number'
        },
        "Count_of_Order_Items__c": {
          name: 'Count_of_Order_Items__c',
          type: 'number'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "Custom_PPE_Hour_s_Price_PO__c": {
          name: 'Custom_PPE_Hour_s_Price_PO__c',
          type: 'number'
        },
        "Custom_PPE_Hour_s_Price_SO__c": {
          name: 'Custom_PPE_Hour_s_Price_SO__c',
          type: 'number'
        },
        "Due_Date__c": {
          name: 'Due_Date__c',
          type: 'Date'
        },
        "FTE_Fixed_Hourly__c": {
          name: 'FTE_Fixed_Hourly__c',
          type: 'boolean'
        },
        "FTE_Monthly_Rate__c": {
          name: 'FTE_Monthly_Rate__c',
          type: 'number'
        },
        "FTE_Partner_Monthly_Rate_Custom__c": {
          name: 'FTE_Partner_Monthly_Rate_Custom__c',
          type: 'number'
        },
        "FTE_Vendor_Monthly_Rate_Custom__c": {
          name: 'FTE_Vendor_Monthly_Rate_Custom__c',
          type: 'number'
        },
        "Has_Monthly_Invoice__c": {
          name: 'Has_Monthly_Invoice__c',
          type: 'boolean'
        },
        "Has_Overtime__c": {
          name: 'Has_Overtime__c',
          type: 'boolean'
        },
        "Hourly_PO__c": {
          name: 'Hourly_PO__c',
          type: 'boolean'
        },
        "Hourly_SO__c": {
          name: 'Hourly_SO__c',
          type: 'boolean'
        },
        "IRON_ICC_Case_Number__c": {
          name: 'IRON_ICC_Case_Number__c',
          type: 'string'
        },
        "Invoice_Created_Date_Time__c": {
          name: 'Invoice_Created_Date_Time__c',
          type: 'Date'
        },
        "Invoice_Date__c": {
          name: 'Invoice_Date__c',
          type: 'Date'
        },
        "Invoice_Notes__c": {
          name: 'Invoice_Notes__c',
          type: 'string'
        },
        "Invoice_Offset__c": {
          name: 'Invoice_Offset__c',
          type: 'number'
        },
        "Iron_Job__c": {
          name: 'Iron_Job__c',
          type: 'string'
        },
        "Iron_Legal_Entity_Country__c": {
          name: 'Iron_Legal_Entity_Country__c',
          type: 'string'
        },
        "Iron_Legal_Entity_Currency__c": {
          name: 'Iron_Legal_Entity_Currency__c',
          type: 'string'
        },
        "Iron_PMS_Case_Number__c": {
          name: 'Iron_PMS_Case_Number__c',
          type: 'string'
        },
        "Iron_Purchase_Order_Reference__c": {
          name: 'Iron_Purchase_Order_Reference__c',
          type: 'string'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "Job_Number__c": {
          name: 'Job_Number__c',
          type: 'string'
        },
        "Job_Status__c": {
          name: 'Job_Status__c',
          type: 'string'
        },
        "Job_Worker__c": {
          name: 'Job_Worker__c',
          type: 'string'
        },
        "Job__c": {
          name: 'Job__c',
          type: 'string'
        },
        "Job_has_Job_Schedule__c": {
          name: 'Job_has_Job_Schedule__c',
          type: 'boolean'
        },
        "Jobsite_Name__c": {
          name: 'Jobsite_Name__c',
          type: 'string'
        },
        "LastActivityDate": {
          name: 'LastActivityDate',
          type: 'Date'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "LastReferencedDate": {
          name: 'LastReferencedDate',
          type: 'Date'
        },
        "LastViewedDate": {
          name: 'LastViewedDate',
          type: 'Date'
        },
        "Last_Adjustment_Notes_External__c": {
          name: 'Last_Adjustment_Notes_External__c',
          type: 'string'
        },
        "Last_Adjustment_Notes_MSP_Internal__c": {
          name: 'Last_Adjustment_Notes_MSP_Internal__c',
          type: 'string'
        },
        "Last_Approved_Timecard_Amount__c": {
          name: 'Last_Approved_Timecard_Amount__c',
          type: 'number'
        },
        "Last_Invoice_Adjustment_Audit_Tracker__c": {
          name: 'Last_Invoice_Adjustment_Audit_Tracker__c',
          type: 'string'
        },
        "Latest_Payment__c": {
          name: 'Latest_Payment__c',
          type: 'string'
        },
        "Monthly_Invoice_Item__c": {
          name: 'Monthly_Invoice_Item__c',
          type: 'string'
        },
        "Monthly_Invoice__c": {
          name: 'Monthly_Invoice__c',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "Overtime_Amount__c": {
          name: 'Overtime_Amount__c',
          type: 'number'
        },
        "PO_Additional_Hours_Price_Roll_up__c": {
          name: 'PO_Additional_Hours_Price_Roll_up__c',
          type: 'number'
        },
        "PO_Additional_Hours_Price__c": {
          name: 'PO_Additional_Hours_Price__c',
          type: 'number'
        },
        "PO_Already_Invoiced_Amount__c": {
          name: 'PO_Already_Invoiced_Amount__c',
          type: 'number'
        },
        "PO_Already_Invoiced_Hours__c": {
          name: 'PO_Already_Invoiced_Hours__c',
          type: 'number'
        },
        "PO_Amount__c": {
          name: 'PO_Amount__c',
          type: 'number'
        },
        "PO_FTE_Hours__c": {
          name: 'PO_FTE_Hours__c',
          type: 'number'
        },
        "PO_List_Price__c": {
          name: 'PO_List_Price__c',
          type: 'number'
        },
        "PO_Number__c": {
          name: 'PO_Number__c',
          type: 'string'
        },
        "PO_PPE_Hours_Price__c": {
          name: 'PO_PPE_Hours_Price__c',
          type: 'number'
        },
        "PO_PPE_Hours__c": {
          name: 'PO_PPE_Hours__c',
          type: 'number'
        },
        "PO_Total_Approved_timesheet_Hours__c": {
          name: 'PO_Total_Approved_timesheet_Hours__c',
          type: 'number'
        },
        "Partner_Case_Number__c": {
          name: 'Partner_Case_Number__c',
          type: 'string'
        },
        "Payment_Term__c": {
          name: 'Payment_Term__c',
          type: 'number'
        },
        "Program__c": {
          name: 'Program__c',
          type: 'string'
        },
        "Purchase_Invoice__c": {
          name: 'Purchase_Invoice__c',
          type: 'string'
        },
        "Purchase_Order_Start_Date__c": {
          name: 'Purchase_Order_Start_Date__c',
          type: 'Date'
        },
        "Purchase_Order__c": {
          name: 'Purchase_Order__c',
          type: 'string'
        },
        "Purchase_Order_on_Job__c": {
          name: 'Purchase_Order_on_Job__c',
          type: 'string'
        },
        "Ready_for_Approval__c": {
          name: 'Ready_for_Approval__c',
          type: 'boolean'
        },
        "Ready_for_Review__c": {
          name: 'Ready_for_Review__c',
          type: 'boolean'
        },
        "RecordTypeId": {
          name: 'RecordTypeId',
          type: 'string'
        },
        "Recordtypeid__c": {
          name: 'Recordtypeid__c',
          type: 'string'
        },
        "Reviewer__c": {
          name: 'Reviewer__c',
          type: 'string'
        },
        "Roll_up_Discount__c": {
          name: 'Roll_up_Discount__c',
          type: 'string'
        },
        "Roll_up_Timecard_s_IRON_Approval_Status__c": {
          name: 'Roll_up_Timecard_s_IRON_Approval_Status__c',
          type: 'string'
        },
        "Roll_up_VAT__c": {
          name: 'Roll_up_VAT__c',
          type: 'number'
        },
        "Roll_up_Vat_Tax__c": {
          name: 'Roll_up_Vat_Tax__c',
          type: 'number'
        },
        "Roll_up_helpdesk_Price__c": {
          name: 'Roll_up_helpdesk_Price__c',
          type: 'number'
        },
        "SC__c": {
          name: 'SC__c',
          type: 'string'
        },
        "SKU__c": {
          name: 'SKU__c',
          type: 'string'
        },
        "SLA_Coverage_Hours__c": {
          name: 'SLA_Coverage_Hours__c',
          type: 'string'
        },
        "SO_Additional_Hours_Price__c": {
          name: 'SO_Additional_Hours_Price__c',
          type: 'number'
        },
        "SO_Additional_Hours__c": {
          name: 'SO_Additional_Hours__c',
          type: 'number'
        },
        "SO_Already_Invoiced_Amount__c": {
          name: 'SO_Already_Invoiced_Amount__c',
          type: 'number'
        },
        "SO_Already_Invoiced_Hours__c": {
          name: 'SO_Already_Invoiced_Hours__c',
          type: 'number'
        },
        "SO_FTE_Hours__c": {
          name: 'SO_FTE_Hours__c',
          type: 'number'
        },
        "SO_Last_Approved_Timecard_Hours_OT__c": {
          name: 'SO_Last_Approved_Timecard_Hours_OT__c',
          type: 'number'
        },
        "SO_Last_Approved_Timecard_Hours_Std__c": {
          name: 'SO_Last_Approved_Timecard_Hours_Std__c',
          type: 'number'
        },
        "SO_Last_Approved_Timecard_Hours_Weekend__c": {
          name: 'SO_Last_Approved_Timecard_Hours_Weekend__c',
          type: 'number'
        },
        "SO_PPE_Hours_Price__c": {
          name: 'SO_PPE_Hours_Price__c',
          type: 'number'
        },
        "SO_PPE_Hours__c": {
          name: 'SO_PPE_Hours__c',
          type: 'number'
        },
        "SO_Roll_up_Additional_Rate__c": {
          name: 'SO_Roll_up_Additional_Rate__c',
          type: 'number'
        },
        "SO_Roll_up_PPE_Hours__c": {
          name: 'SO_Roll_up_PPE_Hours__c',
          type: 'number'
        },
        "SO_Roll_up_Unit_Rate__c": {
          name: 'SO_Roll_up_Unit_Rate__c',
          type: 'number'
        },
        "Sale_Order__c": {
          name: 'Sale_Order__c',
          type: 'string'
        },
        "Sales_Invoice__c": {
          name: 'Sales_Invoice__c',
          type: 'string'
        },
        "Sales_Order_Amount__c": {
          name: 'Sales_Order_Amount__c',
          type: 'number'
        },
        "Sales_Order_End_Date__c": {
          name: 'Sales_Order_End_Date__c',
          type: 'Date'
        },
        "Sales_Order_Number__c": {
          name: 'Sales_Order_Number__c',
          type: 'string'
        },
        "Sales_Order_PMS__c": {
          name: 'Sales_Order_PMS__c',
          type: 'string'
        },
        "Sales_Order_Start_Date__c": {
          name: 'Sales_Order_Start_Date__c',
          type: 'Date'
        },
        "Service_Contract__c": {
          name: 'Service_Contract__c',
          type: 'string'
        },
        "Status__c": {
          name: 'Status__c',
          type: 'string'
        },
        "Talent_Type__c": {
          name: 'Talent_Type__c',
          type: 'string'
        },
        "Timecard_Received_Final__c": {
          name: 'Timecard_Received_Final__c',
          type: 'string'
        },
        "Timecard_Timesheet__c": {
          name: 'Timecard_Timesheet__c',
          type: 'string'
        },
        "Timesheet_Month__c": {
          name: 'Timesheet_Month__c',
          type: 'string'
        },
        "Timesheet_Year__c": {
          name: 'Timesheet_Year__c',
          type: 'string'
        },
        "Total_After_Hours__c": {
          name: 'Total_After_Hours__c',
          type: 'number'
        },
        "Total_Hours_Invoiced__c": {
          name: 'Total_Hours_Invoiced__c',
          type: 'number'
        },
        "Total_Hours_Worked__c": {
          name: 'Total_Hours_Worked__c',
          type: 'number'
        },
        "Total_Paid__c": {
          name: 'Total_Paid__c',
          type: 'number'
        },
        "Total_Standard_Hours_Per_Visit__c": {
          name: 'Total_Standard_Hours_Per_Visit__c',
          type: 'number'
        },
        "Total_Timecard_Hours_No_offset__c": {
          name: 'Total_Timecard_Hours_No_offset__c',
          type: 'number'
        },
        "Total_Timesheet_Hours__c": {
          name: 'Total_Timesheet_Hours__c',
          type: 'number'
        },
        "Total_Un_Invoiced_Amount__c": {
          name: 'Total_Un_Invoiced_Amount__c',
          type: 'number'
        },
        "Total_Visit_Hours__c": {
          name: 'Total_Visit_Hours__c',
          type: 'number'
        },
        "Total_Weekend_Hours__c": {
          name: 'Total_Weekend_Hours__c',
          type: 'number'
        },
        "Vat_Calc__c": {
          name: 'Vat_Calc__c',
          type: 'number'
        },
        "Vendor_Purchase_Receipt_Number__c": {
          name: 'Vendor_Purchase_Receipt_Number__c',
          type: 'string'
        },
        "Vendor_Time_Card_Notes_Tasks_Performed__c": {
          name: 'Vendor_Time_Card_Notes_Tasks_Performed__c',
          type: 'string'
        },
        "Vendor_Time_Card_Observation_Suggestion__c": {
          name: 'Vendor_Time_Card_Observation_Suggestion__c',
          type: 'string'
        },
        "Vendor_Time_Card_Total_Hours_All_Visits__c": {
          name: 'Vendor_Time_Card_Total_Hours_All_Visits__c',
          type: 'number'
        },
        "Vendor_Timecard_Actual_In__c": {
          name: 'Vendor_Timecard_Actual_In__c',
          type: 'string'
        },
        "Vendor_Timecard_Actual_Out__c": {
          name: 'Vendor_Timecard_Actual_Out__c',
          type: 'string'
        },
        "Vendor_Timecard_Cust_Site_Sign_off_Name__c": {
          name: 'Vendor_Timecard_Cust_Site_Sign_off_Name__c',
          type: 'string'
        },
        "Vendor_Timecard_Details_Roll_up_Auto__c": {
          name: 'Vendor_Timecard_Details_Roll_up_Auto__c',
          type: 'string'
        },
        "Vendor_Timecard_Details_Roll_up__c": {
          name: 'Vendor_Timecard_Details_Roll_up__c',
          type: 'string'
        },
        "Vendor__c": {
          name: 'Vendor__c',
          type: 'string'
        },
        "Work_Order_Gross_Margin_Analysis__c": {
          name: 'Work_Order_Gross_Margin_Analysis__c',
          type: 'string'
        },
        "Worker_on_the_Job__c": {
          name: 'Worker_on_the_Job__c',
          type: 'string'
        },
        "Profit_Loss_Amount__c": {
          name: 'Profit_Loss_Amount__c',
          type: 'number'
        },
        "Profit_Loss__c": {
          name: 'Profit_Loss__c',
          type: 'number'
        },
        "Conversion_Rate_To_USD__c": {
          name: 'Conversion_Rate_To_USD__c',
          type: 'number'
        },
        "Invoice_Total_USD__c": {
          name: 'Invoice_Total_USD__c',
          type: 'number'
        },
        "Billing_Code_Calculation_Description__c": {
          name: 'Billing_Code_Calculation_Description__c',
          type: 'string'
        },
        "Billing_Calculation_Formula_Description__c": {
          name: 'Billing_Calculation_Formula_Description__c',
          type: 'string'
        },
        "Billing_Code_Name_MSP__c": {
          name: 'Billing_Code_Name_MSP__c',
          type: 'string'
        },
        "Not_to_be_Billed_to_Customer__c": {
          name: 'Not_to_be_Billed_to_Customer__c',
          type: 'boolean'
        },
        "Current_Approval_Level__c": {
          name: 'Current_Approval_Level__c',
          type: 'string'
        },
        "Approval_Levels_Required__c": {
          name: 'Approval_Levels_Required__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        vendor: {
          name: 'vendor',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'Account__c',
          keyTo: 'sfdcId'
        },
        timecard: {
          name: 'timecard',
          type: 'Timecard',
          model: 'Timecard',
          relationType: 'belongsTo',
                  keyFrom: 'Timecard_Timesheet__c',
          keyTo: 'sfdcId'
        },
        payments: {
          name: 'payments',
          type: 'Payment[]',
          model: 'Payment',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'Invoice__c'
        },
        jobs: {
          name: 'jobs',
          type: 'Job',
          model: 'Job',
          relationType: 'belongsTo',
                  keyFrom: 'Job_Number__c',
          keyTo: 'Iron_Job_num__c'
        },
        purchaseOrder: {
          name: 'purchaseOrder',
          type: 'PurchaseOrder',
          model: 'PurchaseOrder',
          relationType: 'belongsTo',
                  keyFrom: 'Purchase_Order__c',
          keyTo: 'sfdcId'
        },
        contact: {
          name: 'contact',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Reviewer__c',
          keyTo: 'sfdcId'
        },
        recordType: {
          name: 'recordType',
          type: 'RecordType',
          model: 'RecordType',
          relationType: 'belongsTo',
                  keyFrom: 'RecordTypeId',
          keyTo: 'sfdcId'
        },
        saleOrder: {
          name: 'saleOrder',
          type: 'Order',
          model: 'Order',
          relationType: 'belongsTo',
                  keyFrom: 'Sale_Order__c',
          keyTo: 'sfdcId'
        },
        serviceContract: {
          name: 'serviceContract',
          type: 'ServiceContract',
          model: 'ServiceContract',
          relationType: 'belongsTo',
                  keyFrom: 'Service_Contract__c',
          keyTo: 'sfdcId'
        },
        monthlyInvoice: {
          name: 'monthlyInvoice',
          type: 'MonthlyInvoice',
          model: 'MonthlyInvoice',
          relationType: 'belongsTo',
                  keyFrom: 'Monthly_Invoice__c',
          keyTo: 'sfdcId'
        },
        invoiceAdjustment: {
          name: 'invoiceAdjustment',
          type: 'InvoiceAdjustment[]',
          model: 'InvoiceAdjustment',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'Invoice__c'
        },
      }
    }
  }
}
