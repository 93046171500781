import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmployeeTypeLookupComponent } from './employee-type-lookup.component';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
  ],
  declarations: [EmployeeTypeLookupComponent],
  exports: [EmployeeTypeLookupComponent]
})
export class EmployeeTypeLookupModule { }
