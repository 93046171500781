import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { AppTaskStatusStaticLookupComponent } from './app-task-status-static-lookup.component';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule
  ],
  declarations: [AppTaskStatusStaticLookupComponent],
  exports: [AppTaskStatusStaticLookupComponent]
})
export class TaskStatusStaticLookupModule { }
