import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { DocumentApi, UsersApi } from "shared/sdk";
import { AlertService } from "shared/services/alert.service";
import { CommonService } from "shared/services/common.service";
import { PreloaderService } from "shared/services/preloader.service";
import { UtilityService } from "shared/services/utility.service";

@Component({
  selector: "app-files-list",
  templateUrl: "./files-list.component.html",
  styleUrls: ["./files-list.component.css"]
})
export class FilesListComponent implements OnInit, OnDestroy {

  @Input() jobId;
  @Input() cardView = false;
  filters: any;
  deleteDoc;
  docsLoaded = false;
  categories = [
    {
      name: "Files (For Workers)",
      accessType: "internal",
      documents: []
    },
    {
      name: "Files (From Workers)",
      accessType: "vendor",
      documents: []
    }
  ];
  fileExtensionsPreviewable = ['jpg', 'png', 'jpeg'];

  documentData = [];
  cardViewData = [];
  loadMore = false;
  fileUploadedSubscription: Subscription;

  constructor(
    private _documentApi: DocumentApi,
    private _userApi: UsersApi,
    private utilityService: UtilityService,
    private _preloaderService: PreloaderService,
    private _alertService: AlertService,
    private _commonService: CommonService,
  ) { }

  ngOnInit() {
    if (this.cardView) {
      this.getDocumentData();
    } else {
      this.filters = {
        modelName: "Job",
        modelId: this.jobId,
        isDeleted: 0
      };
      this.getDocuments();
    }
    this.fileUploadedSubscription = this._commonService.messagePostFileUploaded.subscribe(uploaded => {
      if (uploaded) {
        this.reloadFiles();
      }
    })
  }

  getDocuments() {
    this._preloaderService.showPreloader();
    const reqObj = {
      order: "createdAt desc",
      skip: 0,
      where: this.filters,
      include: [{ relation: "owner", scope: { fields: ["accessType"], isMessage: true } }]
    };
    this._documentApi.find(reqObj).subscribe(
      documents => {
        this.docsLoaded = true;
        if (documents.length) {
          documents.forEach((doc: any) => {
            // to check if document belongs to the logged in user
            if (doc.ownerId) {
              // filter vendor user files
              if (doc.owner && doc.owner.accessType && doc.owner.accessType == 'vendor') {
                this.categories[1].documents.push(doc);
              } else {
                this.categories[0].documents.push(doc);
              }
            }
          });
        } else {
          console.log("no documents for this job id");
        }
        this._preloaderService.hidePreloader();
      },
      err => {
        this.docsLoaded = true;
        console.log(err);
        this._preloaderService.hidePreloader();
      }
    );
  }

  downloadFile(filePath) {
    this.utilityService.downloadFile(filePath);
  }

  canPreviewFile(fileExtension) {
    return this.fileExtensionsPreviewable.indexOf(fileExtension.toLowerCase()) !== -1;
  }

  getDocumentData(offset = 0) {
    if (offset > 0 && offset < this.documentData.length) {
      return;
    }
    this._preloaderService.showPreloader();
    const limit = 199;
    const reqObj = {
      "viewType": 'listView',
      "order": 'createdAt DESC',
      "limit": limit,
      'skip': offset,
    }
    reqObj["where"] = {
      "modelName": 'Job',
      "isDeleted": { neq: 1 },
      "modelId": { inq: this.jobId }
    }
    this._documentApi.getDocumentForCategory(reqObj).subscribe(res => {
      this.documentData = res;
      this.loadMore = res.length === limit;
      this.formatCardViewData();
      this._preloaderService.hidePreloader();
    }, err => {
      console.log(err);
      this._preloaderService.hidePreloader();
    });
  }

  loadMoreCardViewData() {
    this.getDocumentData(this.cardViewData.length);
  }

  formatCardViewData() {
    this.cardViewData = [];
    this.documentData.forEach(doc => {
      this.cardViewData.push({
        id: doc.id,
        size: doc.size ? (Math.floor(doc.size / 1024) + ' KB') : '0 KB',
        name: doc.Name,
        expanded: false,
        createdAt: this.utilityService.dateFormate(doc.createdAt),
        fileMeta: doc.fileMeta,
        uuid: doc.uuid || '',
        data: [
          {
            label: 'Uploaded By : ',
            value: doc.uploadedBy
          },
          {
            label: 'Account : ',
            value: doc.accountName
          },
          {
            label: 'Title : ',
            value: doc.category
          },
          {
            label: 'Description : ',
            value: doc.description
          },
          {
            label: 'ICC Case : ',
            value: doc.iccCaseNumber
          },
          {
            label: 'PMS Case : ',
            value: doc.pmsCaseNumber
          },
          {
            label: 'Partner Case : ',
            value: doc.partnerCaseNumber
          },
          {
            label: 'Program : ',
            value: doc.programName
          },
          {
            label: 'Jobsite : ',
            value: doc.JobsiteName
          },
          {
            label: 'Job : ',
            value: doc.jobNumber
          }
        ]
      });
    });
  }

  initiateDeleteDocumet = (doc) => {
    if (doc && doc.id && this.deleteDoc !== doc.id) {
      this.deleteDoc = doc.id;
    } else {
      this.deleteDoc = '';
    }
  }
  confirmDelete = (doc) => {
    if (doc === 'cancel') {
      this.deleteDoc = '';
    } else {
      if (doc) {
        this._preloaderService.showPreloader();
        this._documentApi.deleteByIds({ ids: [doc.id] }).subscribe(
          res => {
            if (res) {
              this._alertService.clear();
              this._alertService.success(
                'Document(s) deleted successfully.'
              );
              this.reloadFiles();
            }
            this._preloaderService.hidePreloader();
            this.deleteDoc = '';
          },
          err => {
            this.deleteDoc = '';
            this._alertService.error(err.message);
            this._preloaderService.hidePreloader();
          }
        );
      }
    }
  }

  expandDocumet(doc) {
    this.cardViewData.forEach(item => {
      if (item.id === doc.id) {
        item.expanded = !item.expanded;
      } else {
        item.expanded = false;
      }
    });
  }

  reloadFiles() {
    this.documentData = [];
    this.getDocumentData();
  }

  ngOnDestroy() {
    this.fileUploadedSubscription && this.fileUploadedSubscription.unsubscribe();
  }

}
