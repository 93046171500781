import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { VendorsiteLoopupComponent } from './vendorsite-loopup.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule
  ],
  declarations: [VendorsiteLoopupComponent],
  exports: [VendorsiteLoopupComponent],
})
export class VendorsiteLoopupModule { }
