import { JobMessageEmailPopupComponent } from './../job-message-email-popup/job-message-email-popup.component';
import { MessageModule } from './../../../../components/message/message.module';
import { PipeModule } from './../../../../pipe/pipe.module';
import { JobMessageDetailsComponent } from 'shared/views/global-chat/job-message-console/job-message-details/job-message-details.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { MatDialogModule } from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    PipeModule,
    MessageModule,
    InfiniteScrollModule,
    NgxDatatableModule,
    FormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MatDialogModule,
    ReactiveFormsModule,
  ],
  declarations: [JobMessageDetailsComponent, JobMessageEmailPopupComponent],
  exports: [JobMessageDetailsComponent, JobMessageEmailPopupComponent],
  entryComponents: [JobMessageEmailPopupComponent]
})
export class JobMessageDetailsModule { }
