import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { PreloaderService } from 'shared/services/preloader.service';
import { StateManagerApi } from 'shared/sdk';

@Component({
  selector: 'app-state-manager-lookup',
  templateUrl: './state-manager-lookup.component.html',
  styleUrls: ['./state-manager-lookup.component.css']
})
export class StateManagerLookupComponent implements OnInit {

  @Input() isClearable = true;
  @Input() isSearchable = true;
  @Input() isMultipleAllowed = true;
  @Input() getGlobalFilterValue = true; // if true it will set the localstorage value by default if there is any.
  @Input() stateName: string; // if value provided this will be selected by default for dropdown, getGlobalFilterValue key must be false.
  @Input() fromGlobalFilter = false;
  // if value = true => pass selectedStateName is sfdcId
  @Input() isSfdcIdPass = false;
  @Input() set selectedStateName(val) {
    if (this.isSfdcIdPass && this.filteredState && this.filteredState.length) {
      // val is sfdcId
      let value = this.filteredState.find(i => i.sfdcId == val);
      this.stateName = value ? value['State_Name__c'] : '';
    } else {
      this.stateName = val;
    }
  };
  @Input() isdisabled = false;

  @Input() returnCustomState = false;

  /**
  * To get Country Name from parent component.
  * Using this country-Name it will search for matching state in Database.
  * @param {String} name - name of country
  */
  @Input() set countryName(name: string) {
    if (name) {
      this.loadDropdownData(name);
    }else{
      this.selectedValue = ""
    }
  }

  @Input() set resetData(isClear) {
    if(isClear){
      this.clearFromParent();
    }
    
  }

  noStateFound = '';

  /**
  * To get Country Object in array, from parent component
  * From that object country code will be taken to search for state in Database
  * @param {Array} params - Array of country Object
  */
  @Input() set setCountryObj(params) {
    this.clearFromParent(true);
    this.selectedCountry = params;
    this.filteredState = [];
    this.states = [];
    if (this.selectedCountry !== null && this.selectedCountry !== undefined && this.selectedCountry !== '') {
      if (this.selectedCountry && this.selectedCountry.length && this.selectedCountry[0].Name && !this.fromGlobalFilter) {
        this.loadDropdownData(this.selectedCountry[0].Name);
      } else  if (this.selectedCountry && this.selectedCountry.length && this.fromGlobalFilter) {
        const countrySfdcIdS = this.selectedCountry.map(item => item.sfdcId);
         this.loadDropdownData(countrySfdcIdS);
      }
    }
  }
  @Output() selectedState: EventEmitter<any> = new EventEmitter;

  filteredState: any;
  selectedCountry: any;

  states: any = [];
  selectedValue;

  constructor(
    private _preloaderService: PreloaderService,
    private _stateManagerApi: StateManagerApi
  ) { }

  ngOnInit() {
    let preSelected = localStorage.getItem('filterObj');
    preSelected = JSON.parse(preSelected)
    if (this.getGlobalFilterValue && !this.fromGlobalFilter) {
      if (preSelected && preSelected['states'] && preSelected['states'].length) {
        this.selectedValue = JSON.parse(preSelected)['states'];
      }
    } else if (this.fromGlobalFilter && preSelected && preSelected['statesManager'] && preSelected['statesManager'].length) {
      this.selectedValue = preSelected['statesManager'].map(item => item.stateName);
      this.selectedState.emit(preSelected['statesManager']);
    }
   // countries
   if (preSelected && preSelected['countries'] && this.fromGlobalFilter) {
    // tslint:disable-next-line:max-line-length
    const countrySfdcIdS =  Array.isArray(preSelected['countries']) ?  preSelected['countries'].map(item => item.sfdcId) : preSelected['countries']['sfdcId'] ;
    this.loadDropdownData(countrySfdcIdS);
   } 
  }

  onChange(values) {
    if (this.returnCustomState) {
      const stateNames = Array.isArray(this.selectedValue) ? this.selectedValue : [this.selectedValue]
      const value = this.filteredState && this.filteredState.find(i => stateNames.includes(i.State_Name__c));
      if (value && !this.fromGlobalFilter) {
        this.selectedState.emit({ stateName: this.selectedValue, sfdcId: value.sfdcId });
      } else  if (value && this.fromGlobalFilter) {
        const stateArr = [];
        this.filteredState.forEach(i => {
          if (stateNames.includes(i.State_Name__c) && this.isSfdcIdPass) {
            stateArr.push({ stateName: i.State_Name__c, sfdcId: i.sfdcId })
          }else if (stateNames.includes(i.State_Name__c) && !this.isSfdcIdPass) {
            //when we need state as text value rather than sfdc
            stateArr.push({ stateName: i.State_Name__c, sfdcId: i.State_Name__c })
          }
        });
        this.selectedState.emit(stateArr);
      } else {
        this.selectedState.emit(this.selectedValue);
      }
    } else {
      this.selectedState.emit(this.selectedValue);
    }
  }

  loadDropdownData(val, isName = false) {
    // get data for states
    if(!this.fromGlobalFilter) {
    this.selectedValue = '';
    }
    this.states = [];
    let whereObj;
    this._preloaderService.showPreloader();
    if(Array.isArray(val)) {
       whereObj = { Country_Code__c: {inq : val }};
    } else {
       whereObj = (isName) ? { Country__c: val } : { Country_Code__c: val };
    }
    this._stateManagerApi.find({ where: whereObj }).subscribe(
      data => {
        this._preloaderService.hidePreloader();
        if (data && data.length) {
          this.filteredState = data;
          this.states = this.filteredState.map(item => item.State_Name__c);
          if (this.stateName && this.isSfdcIdPass && this.filteredState && this.filteredState.length) {
            let value = this.filteredState.find(i => i.sfdcId == this.stateName);
            this.stateName = value ? value['State_Name__c'] : '';
          }
          if (this.stateName && !this.getGlobalFilterValue && this.states.includes(this.stateName)) {
            this.selectedValue = this.stateName;
          } else if( !this.fromGlobalFilter) {
            // add else as on select country, a cross is appearing in the state lookup
            this.clearFromParent(true);
          }
          this.noStateFound = '';
        } else {
          this.selectedValue = '';
          this.states = [];
          this.noStateFound = 'No state found.';
        }
      },
      err => {
        console.log('Error fetching states>>', err.message);
      }
    );
  }

  clearFromParent(initiateOnChange?) {
    this.selectedValue = null;
    if (initiateOnChange) {
      this.onChange(this.selectedValue);
    }
  }


}
