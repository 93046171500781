import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class SurveyService {
    httpOptions: {
        headers?: HttpHeaders,
        responseType?: 'json'
    }
    getTemplateURL = environment.surveyURL + '/RestResource/GetTemplate';
    getStatusURL = environment.surveyURL + '/RestResource/FormStatus';
    createTicketURL = environment.surveyURL + '/RestResource/TicketCreate';

    headers: HttpHeaders;
    constructor(private _http: HttpClient) {
        this.headers = new HttpHeaders()
            .set('content-type', 'application/json')
            .set('Access-Control-Allow-Methods', 'GET,POST,OPTIONS,DELETE,PUT')
            .set('token', environment.surveyToken)
            .set('Access-Control-Allow-Origin', '*');
        this.httpOptions = { headers: this.headers, responseType: 'json' };
    }

    createTicket(data): Observable<any> {
        return this._http.post<any>(this.createTicketURL, data, this.httpOptions);
    }

    getTemplate(): Observable<any> {
        return this._http.get<any>(this.getTemplateURL, this.httpOptions);
    }

    getFormStatus(data): Observable<any> {
        return this._http.post<any>(this.getStatusURL, data, this.httpOptions);
    }
}

