import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { radiusList } from 'shared/models/static-list-data.service';

@Component({
  selector: 'app-radius-lookup',
  templateUrl: './radius-lookup.component.html',
  styleUrls: ['./radius-lookup.component.css']
})
export class RadiusLookupComponent implements OnInit {

  @Input() set resetData(isClear) {
    this.clearFromParent();
  }
  @Output() selectedRadius: EventEmitter<any> = new EventEmitter;
  selectedValue;
  selectedUnit = 'miles';

  radiusList = [];

  constructor() { }

  ngOnInit() {
    this.radiusList = radiusList;
    const preSelected = localStorage.getItem('filterObj');
    if (preSelected && JSON.parse(preSelected) &&
      JSON.parse(preSelected)['radius'] && JSON.parse(preSelected)['radius'].length) {
      this.selectedValue = JSON.parse(preSelected)['radius'][0].split(' ')[0];
      this.selectedUnit = JSON.parse(preSelected)['radius'][0].split(' ')[1];
    }
  }

  onChange() {
    this.selectedRadius.emit(this.selectedValue + ' ' + this.selectedUnit);
  }

  clearFromParent() {
    this.selectedValue = null;
  }

}
