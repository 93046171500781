import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class WorkflowService {
    // used to show current job status without refreshing page
    private status = new Subject<number>();
    status$ = this.status.asObservable();

    constructor() {
    }

    setWorkFlowStatusId(statusId) {
        this.status.next(statusId);
    }

    getWorkFlowStatusId() {
        return this.status$;
    }
}
