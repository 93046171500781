/* tslint:disable */
import {
  PageViewGroup,
  PageViewMapping
} from '../index';

declare var Object: any;
export interface PageViewTitleInterface {
  "PageName"?: string;
  "PageNum"?: string;
  "pageGroupId"?: number;
  "pageViewMappingId"?: number;
  "order_num"?: number;
  "isActive"?: boolean;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  pageGroup?: PageViewGroup;
  pageGViewMapping?: PageViewMapping;
}

export class PageViewTitle implements PageViewTitleInterface {
  "PageName": string;
  "PageNum": string;
  "pageGroupId": number;
  "pageViewMappingId": number;
  "order_num": number;
  "isActive": boolean;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  pageGroup: PageViewGroup;
  pageGViewMapping: PageViewMapping;
  constructor(data?: PageViewTitleInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `PageViewTitle`.
   */
  public static getModelName() {
    return "PageViewTitle";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of PageViewTitle for dynamic purposes.
  **/
  public static factory(data: PageViewTitleInterface): PageViewTitle{
    return new PageViewTitle(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'PageViewTitle',
      plural: 'PageViewTitles',
      path: 'PageViewTitles',
      idName: 'id',
      properties: {
        "PageName": {
          name: 'PageName',
          type: 'string'
        },
        "PageNum": {
          name: 'PageNum',
          type: 'string'
        },
        "pageGroupId": {
          name: 'pageGroupId',
          type: 'number'
        },
        "pageViewMappingId": {
          name: 'pageViewMappingId',
          type: 'number'
        },
        "order_num": {
          name: 'order_num',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        pageGroup: {
          name: 'pageGroup',
          type: 'PageViewGroup',
          model: 'PageViewGroup',
          relationType: 'belongsTo',
                  keyFrom: 'pageGroupId',
          keyTo: 'id'
        },
        pageGViewMapping: {
          name: 'pageGViewMapping',
          type: 'PageViewMapping',
          model: 'PageViewMapping',
          relationType: 'belongsTo',
                  keyFrom: 'pageViewMappingId',
          keyTo: 'id'
        },
      }
    }
  }
}
