import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: 'app-job-progress-update-tracker',
  templateUrl: './job-progress-update-tracker.component.html',
  styleUrls: ['./job-progress-update-tracker.component.scss']
})
export class JobStatusUpdateTrackerComponent implements OnInit {

  @Input() statusTrackerText;
  statusTracker: any;
  @Input() open = false;
  @Input() isJobDetail = false;
  ngOnInit() {
  }
  ngOnChanges() {
    this.prepareTracker();
  }
  prepareTracker() {
    let statusTracker = (this.statusTrackerText === null) ? {} : JSON.parse(this.statusTrackerText);
    this.statusTracker = Object.keys(statusTracker).map(key => statusTracker[key]);
    this.statusTracker.forEach((item, index) => {
      let sliced = item.label.slice(1)
      let splitted = sliced.split(") ");
      item['step'] = splitted['0'];
      item['newLabel'] = splitted['1'];
    });
  }
}