import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { JobOrderTitleLookupComponent } from './job-order-title-lookup.component';

@NgModule({
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule
  ],
  declarations: [JobOrderTitleLookupComponent],
  exports: [JobOrderTitleLookupComponent]
})

export class JobOrderTitleLookupModule { }