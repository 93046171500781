import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-order-type-lookup',
  templateUrl: './order-type-lookup.component.html',
  styleUrls: ['./order-type-lookup.component.css']
})
export class OrderTypeLookupComponent implements OnInit {

  @Input() page: string;
  @Input() set resetData(isClear) {
    this.clearFromParent();
  }
  @Output() selectedOrderTypes: EventEmitter<any> = new EventEmitter;
  selectedValue;

  orderTypes = [
    { 'sfdcId': 'Cases', 'Name': 'Cases' },
    { 'sfdcId': 'Work Orders', 'Name': 'Work Orders' },
  ];

  constructor() { }

  ngOnInit() {
    if (this.page === 'Feed') {
      this.orderTypes.push({ 'sfdcId': 'Jobs', 'Name': 'Jobs' });
    }
    const preSelected = localStorage.getItem('filterObj');
    if (preSelected && JSON.parse(preSelected) &&
      JSON.parse(preSelected)['orderTypes'] && JSON.parse(preSelected)['orderTypes'].length) {
      this.selectedValue = JSON.parse(preSelected)['orderTypes'];
    }
  }

  onChange() {
    this.selectedOrderTypes.emit(this.selectedValue);
  }

  clearFromParent() {
    this.selectedValue = null;
  }
}
