import { worker } from 'cluster';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs/Rx';
import * as io from 'socket.io-client';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class GpsSocketService {
  socket: any;
  isConnected = false;
  private gpsUpdate = new Subject<any>();
  private allWorkerGPSUpdate = new Subject<any>();
  constructor() {
  }

  connectSocket(workerId?) {
    const token = localStorage.getItem('accessToken');
    const url = environment.rtUrl + '/gps';
    console.log(url);
    const socket = io(url, {
      transports: ['websocket'], upgrade: false
    });
    socket.on('connect', () => {
      socket.emit('authenticate', {
        token: token
      }).on('authenticated', () => {
        this.socket = socket;  // Assign connected socket
        this.isConnected = true;
        console.log('gps-socket-auth ', this.isConnected)
        if (workerId) {
          // Subscribe selected In-Route worker gps data
          socket.emit('subscribe_gps', workerId);
        } else {
          // Subscribe all worker In-Route worker gps data
          socket.emit('subscribe_getallworker_location');
        }
      }).on('unauthorized', function (msg) {
        // console.log('socket-unauthorized: ' + JSON.stringify(msg.data));
        const err = msg && msg.data &&  msg.data.type || 'socket-unauthorized'
        throw new Error(err);
      }).on('gps_update', data => {
        // set selected worker data in `gpsUpdate` subject
        console.log('gps_update', data)
        this.gpsUpdate.next(data);
      }).on('all_worker_gps_update', data => {
        // set all worker data in `allWorkerGPSUpdate` subject
      //  console.log('all_worker_gps_update', data)
        this.allWorkerGPSUpdate.next(data);
      });
    }).on('disconnect', reason => {
      this.isConnected = false;
      console.log('discount ', reason);
      if (reason === 'io server disconnect') {
        console.log(reason);
        socket.connect();
      }
    });
  }
  /**
   * Get selected In-Route worker location
   */
  getWorkerGpsLog(): Observable<any> {
    return this.gpsUpdate.asObservable();
  }

  /**
   * Get all In-Route worker Location
   */
  getAllWorkerGpsLog(): Observable<any> {
    return this.allWorkerGPSUpdate.asObservable();
  }

  /**
   * Unsubscribe worker gps
   * @param workerId selected worker sfdcId
   * @param allWorkerConnected If all worker force view open then it's true.
   */
  workerDiscount(workerId, allWorkerConnected = false) {
    if (this.isConnected) {
      this.socket.emit('unsubscribe_gps', workerId);
      if (!allWorkerConnected) {
        this.socketDiscount()
      }
    }
  }

  /**
   * Unsubscribe all worker gps
   */
  allWorkerDiscount() {
    if (this.isConnected) {
      this.socket.emit('unsubscribe_getallworker_location');
      this.socketDiscount();
    }
  }

  /**
   * Discount GPS Socket
   */
  socketDiscount() {
    this.socket.disconnect();
  }

}
