/* tslint:disable */
import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { SDKModels } from './SDKModels';
import { BaseLoopBackApi } from '../core/base.service';
import { LoopBackConfig } from '../../lb.config';
import { LoopBackAuth } from '../core/auth.service';
import { LoopBackFilter,  } from '../../models/BaseModels';
import { ErrorHandler } from '../core/error.service';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApplicationAssessment } from '../../models/ApplicationAssessment';
import { SocketConnection } from '../../sockets/socket.connections';


/**
 * Api services for the `ApplicationAssessment` model.
 */
@Injectable()
export class ApplicationAssessmentApi extends BaseLoopBackApi {

  constructor(
    @Inject(HttpClient) protected http: HttpClient,
    @Inject(SocketConnection) protected connection: SocketConnection,
    @Inject(SDKModels) protected models: SDKModels,
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
  ) {
    super(http,  connection,  models, auth, errorHandler);
  }

  /**
   * Patch an existing model instance or insert a new one into the data source.
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - Model instance data
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ApplicationAssessment` object.)
   * </em>
   */
  public patchOrCreate(data: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "PATCH";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/ApplicationAssessments";
    let _routeParams: any = {};
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   * @param {any} id ApplicationAssessment id
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - An object of model property name/value pairs
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ApplicationAssessment` object.)
   * </em>
   */
  public patchAttributes(id: any, data: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "PATCH";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/ApplicationAssessments/:id";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * This method will return Application Assesments according to assesments types and quizes of each assesment.
   *
   * @param {string} applicationId 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ApplicationAssessment` object.)
   * </em>
   */
  public getApplicationAssesments(applicationId: any, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/ApplicationAssessments/assesments";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof applicationId !== 'undefined' && applicationId !== null) _urlParams.applicationId = applicationId;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * This method will fetch the list of questions for the specific quiz assigned to user.
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ApplicationAssessment` object.)
   * </em>
   */
  public getQuizQuestions(req: any, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/ApplicationAssessments/getQuizQuestions";
    let _routeParams: any = {};
    let _postBody: any = {
      req: req
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }
   /**
   * This method will return token of salesforce.
   *
   * @param {string} arg 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `JobAssessment` object.)
   * </em>
   */
    public getSToken(arg: any = {}, customHeaders?: Function): Observable<any> {
      let _method: string = "GET";
      let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
      "/ApplicationAssessments/getSToken";
      let _routeParams: any = {};
      let _postBody: any = {};
      let _urlParams: any = {};
      if (typeof arg !== 'undefined' && arg !== null) _urlParams.arg = arg;
      let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
      return result;
    }

   /**
     * Invite Candidate for technical test assesment.
     *
     * @param {object} data Request data.
     *
     * This method expects a subset of model properties as request parameters.
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `ApplicationAssessment` object.)
     * </em>
     */
    public inviteForTechnicalAssesment(req: any = {}, customHeaders?: Function): Observable<any> {
      let _method: string = "POST";
      let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
      "/ApplicationAssessments/inviteForTechnicalAssesment";
      let _routeParams: any = {};
      let _postBody: any = {
        req: req
      };
      let _urlParams: any = {};
      let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
      return result;
    } 

  /**
       * get technical test result.
       *
       * @param {object} data Request data.
       *
       * This method expects a subset of model properties as request parameters.
       *
       * @returns {object} An empty reference that will be
       *   populated with the actual data once the response is returned
       *   from the server.
       *
       * <em>
       * (The remote method definition does not provide any description.
       * This usually means the response is a `ApplicationAssessment` object.)
       * </em>
       */
  public getTechnicalTestDomResult(req: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
      "/ApplicationAssessments/getTechnicalTestDomResult";
    let _routeParams: any = {};
    let _postBody: any = {
      req: req
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ApplicationAssessment`.
   */
  public getModelName() {
    return "ApplicationAssessment";
  }
}
