import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { WorkerTypeSub } from 'shared/models/static-list-data.service';


@Component({
  selector: 'app-worker-type-sub-lookup',
  templateUrl: './worker-type-sub-lookup.component.html',
  styleUrls: ['./worker-type-sub-lookup.component.css']
})
export class WorkerTypeSubLookupComponent implements OnInit {

  
  @Input() placeholder = 'Worker Type Sub';
  @Input() set resetData(isClear) {
      this.onClear(isClear);
  }
  @Output() selectedValue: EventEmitter<any> = new EventEmitter<any>();
  workerTypeSubList: any = [];
  selectedWorkerTypeSub: any = [];


  constructor() { }

  ngOnInit() {
    this.workerTypeSubList = WorkerTypeSub;
       const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
       setTimeout(() => {
        this.selectedWorkerTypeSub = (preselected && preselected['workerTypeSub']) ? preselected['workerTypeSub'] : '';
      },100);
  }
  onChange() {
    this.selectedValue.emit(this.selectedWorkerTypeSub);
  }

  onClear(isClear) {
      this.selectedWorkerTypeSub = [];
      if (isClear) {
          this.selectedValue.emit([]);
      }
  }

}
