/* tslint:disable */

declare var Object: any;
export interface MaintenanceScheduleInterface {
  "maintenanceType"?: string;
  "subject"?: string;
  "impactedService"?: string;
  "startDate"?: Date;
  "endDate"?: Date;
  "description"?: string;
  "messageSummary"?: string;
  "questionText"?: string;
  "questionValue"?: string;
  "isActive"?: boolean;
  "isEnabled"?: boolean;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class MaintenanceSchedule implements MaintenanceScheduleInterface {
  "maintenanceType": string;
  "subject": string;
  "impactedService": string;
  "startDate": Date;
  "endDate": Date;
  "description": string;
  "messageSummary": string;
  "questionText": string;
  "questionValue": string;
  "isActive": boolean;
  "isEnabled": boolean;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: MaintenanceScheduleInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `MaintenanceSchedule`.
   */
  public static getModelName() {
    return "MaintenanceSchedule";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of MaintenanceSchedule for dynamic purposes.
  **/
  public static factory(data: MaintenanceScheduleInterface): MaintenanceSchedule{
    return new MaintenanceSchedule(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'MaintenanceSchedule',
      plural: 'MaintenanceSchedules',
      path: 'MaintenanceSchedules',
      idName: 'id',
      properties: {
        "maintenanceType": {
          name: 'maintenanceType',
          type: 'string'
        },
        "subject": {
          name: 'subject',
          type: 'string'
        },
        "impactedService": {
          name: 'impactedService',
          type: 'string'
        },
        "startDate": {
          name: 'startDate',
          type: 'Date'
        },
        "endDate": {
          name: 'endDate',
          type: 'Date'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "messageSummary": {
          name: 'messageSummary',
          type: 'string'
        },
        "questionText": {
          name: 'questionText',
          type: 'string'
        },
        "questionValue": {
          name: 'questionValue',
          type: 'string'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean'
        },
        "isEnabled": {
          name: 'isEnabled',
          type: 'boolean'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
