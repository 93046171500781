import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxUploaderModule } from 'ngx-uploader';
import { PipeModule } from '../../pipe/pipe.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DocumentUploadComponent } from './document-upload.component';
import { FileUploadComponent } from './file-upload-messenger-chat/file-upload.component';
import { DocumentListComponent } from './document-list.component';

import { ShareFileModalComponent } from '../../views/filemanager/file-browser/share-file-modal/share-file-modal.component';
import { DetailModalComponent } from '../../views/filemanager/file-browser/detail-modal/detail-modal.component'
import { FileListComponent } from '../../views/filemanager/file-list/file-list.component';

import { FileListViewComponent } from '../../views/filemanager/file-browser/views/common/file-list-view.component';
import { DepartmentViewComponent } from '../../views/filemanager/file-browser/views/department-view/department-view.component';
import { JobsiteViewComponent } from '../../views/filemanager/file-browser/views/jobsite-view/jobsite-view.component';
import { FolderAndFileViewComponent } from '../../views/filemanager/file-browser/views/common/folder-and-files-view.component';
import { CategoryAndTitleViewComponent } from '../../views/filemanager/file-browser/views/common/category-and-title-view.component';
import { FileManagerListCardComponent } from '../../views/filemanager/file-browser/views/common/file-manager-list-card.component';
import { JobLookupModule } from '../../../shared/components/lookup/job-lookup/job-lookup.module';
import { DirectivesModule } from 'shared/directives/directives.module';
import { GlobalFilterService } from 'shared/services/global-filter.service';
import { InlineDocumentUploadComponent } from './inline-document-upload/inline-document-upload.component';
import { DocumentCardListComponent } from './document-card-list/document-card-list.component';
import { ToolbarModule } from '../toolbar/toolbar.module';


@NgModule({
    declarations: [
        DocumentUploadComponent,
        FileUploadComponent,
        DocumentListComponent,
        FileListViewComponent,
        CategoryAndTitleViewComponent,
        FileManagerListCardComponent,
        DepartmentViewComponent,
        JobsiteViewComponent,
        FolderAndFileViewComponent,
        ShareFileModalComponent,
        DetailModalComponent,
        FileListComponent,
        InlineDocumentUploadComponent,
        DocumentCardListComponent
    ],
    imports: [
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgxUploaderModule,
        NgbModule,
        PipeModule,
        NgxDatatableModule,
        NgSelectModule,
        JobLookupModule,
        DirectivesModule,
        ToolbarModule
    ],
    exports: [
        DocumentUploadComponent,
        FileUploadComponent,
        DocumentListComponent,
        FileListViewComponent,
        CategoryAndTitleViewComponent,
        FileManagerListCardComponent,
        DepartmentViewComponent,
        JobsiteViewComponent,
        FolderAndFileViewComponent,
        ShareFileModalComponent,
        DetailModalComponent,
        FileListComponent,
        InlineDocumentUploadComponent,
        DocumentCardListComponent
    ],
    providers: [GlobalFilterService]
})

export class DocumentModule { }
