import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard, ActiveUserAuthGuard } from './../shared/services/auth-guard.service';
import { AuthService } from './../shared/services/authentication.service';
import { PagesError404Component } from '../shared/views/error/404/pages-error-404/pages-error-404.component';
import { PagesError403Component } from '../shared/views/error/403/pages-error-403/pages-error-403.component';
import { ResetPasswordComponent, ForgotPasswordComponent } from './../shared/views/users/reset-password/reset-password.component';
import { DownloadsComponent } from './../shared/views/downloads/downloads.component';
import { JobDetailComponent } from '../shared/views/vms/jobs/job-detail/job-detail.component';
import { CallbackComponent } from '../shared/views/callback/callback.component';
import { PublicCsatFeedbackComponent } from 'shared/components/public-csat-feedback/public-csat-feedback.component';
import { PublicCsatFeedbackFormComponent } from 'shared/components/public-csat-feedback/public-csat-feedback-form/public-csat-feedback-form.component';
import { PublicCaqComponent } from 'shared/components/public-caq/public-caq.component';

const defaultTitle = ' - ServiceO'

export const appRoutes: Routes = [
    {
        path: 'pms',
        canActivate: [AuthGuard],
        loadChildren: './pms/pms-community.module#PMSModule',
        resolve: {
            currentUser: AuthService
        },
        data: {
            title: 'Dashboard ' + defaultTitle,
            permissions: ['partner']
        }
    },
    {
        path: 'vms',
        canActivate: [AuthGuard],
        loadChildren: './vms/vms-community.module#VMSModule',
        resolve: {
            currentUser: AuthService
        },
        data: {
            title: 'Dashboard ' + defaultTitle,
            permissions: ['vendor']
        }
    },
    {
        path: 'callback',
        component: CallbackComponent,
        data: {
            appName: 'community',
        }
    },
    {
        path: 'login',
        canActivate: [ActiveUserAuthGuard],
        loadChildren: '../shared/views/authentication/authentication.module#AuthenticationModule',
        data: {
            appName: 'community',
        }
    },
    {
        path: 'public/jobs/:id',
        component: JobDetailComponent,
        data: {
            accessType: 'public',
            title: 'Jobs ' + defaultTitle
        }
    },
    /*{
        path: '',
        component: PublicCsatFeedbackComponent,
        children: [
            {
              path: 'public/csat/:csatId',
              component: PublicCsatFeedbackFormComponent,
              data: {
                accessType: 'public',
                title: 'Public CSAT FEEDBACK ' + defaultTitle
            }
            },
            {
              path: '**',
              redirectTo: '/page-not-found'
            }
          ],
    },*/
    {
        path: 'reset',
        canActivate: [ActiveUserAuthGuard],
        component: ResetPasswordComponent
    },
    {
        path: 'reset-password',
        canActivate: [ActiveUserAuthGuard],
        component: ForgotPasswordComponent,
        data: {
            title: 'Reset Password ' + defaultTitle,
            permissions: ['partner', 'vendor']
        }
    },
    {
        path: 'app',
        loadChildren: './common/common-community.module#CommonModule',
        canActivate: [AuthGuard],
        resolve: {
            currentUser: AuthService
        },
        data: {
            title: 'Common ' + defaultTitle,
            permissions: ['partner', 'vendor']
        }
    },
    {
        path: 'downloads/:documentId',
        canActivate: [AuthGuard],
        component: DownloadsComponent,
        resolve: {
            currentUser: AuthService
        },
        data: {
            title: 'Downloads ' + defaultTitle,
            permissions: ['partner', 'vendor']
        }
    },
    {
        path: 'dwn/:documentId',
        canActivate: [AuthGuard],
        component: DownloadsComponent,
        resolve: {
            currentUser: AuthService
        },
        data: {
            title: 'Downloads ' + defaultTitle,
            permissions: ['internal']
        }
    },
    {
        path: 'public/caq/:id',
        component: PublicCaqComponent
    },
    {
        path: 'public/caq/:jobId/:appId',
        component: PublicCaqComponent,
        data: {
            title: 'Job Openings ' + defaultTitle
        }
    },
    {
        path: 'page-not-found',
        component: PagesError404Component
    },
    {
        path: 'pages-error-403',
        component: PagesError403Component

    },
    {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full'
    },
    {
        path: '**',
        redirectTo: 'page-not-found'
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes)
    ],
    exports: [
        RouterModule
    ],
})

export class AppRoutingModule {
}
