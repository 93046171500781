import { GlobalFilterService } from './../../../services/global-filter.service';
import {
  Component,
  OnInit,
  ViewChild,
  Input,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import { WorkOrderApi, FavoriteApi } from 'shared/sdk';
import { PreloaderService } from 'shared/services/preloader.service';
import { UtilityService } from 'shared/services/utility.service';
import { forkJoin, Subscription } from 'rxjs';
import { ModalService } from 'shared/services/modal.service';
import { CommonService } from 'shared/services/common.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-work-order-list',
  templateUrl: './work-order-list.component.html',
  styleUrls: ['./work-order-list.component.css'],
})
export class WorkOrderListComponent implements OnInit, OnChanges, OnDestroy {
  tableData: any;
  itemsPerPage = 25;
  itemsPerBatch = 100;
  columns: any;
  filterObj = {};
  filterCondition: any;
  allItems: any;
  noRecords = false;
  isLoadMore = false;
  totalCount = 0;
  selectedWOMSG: any;
  selectedWorkOrderJobs: any;
  userId: any;
  isLoading = true;
  pmsRecordTypes: any;
  datePipe: DatePipe;
  sidebarStateSub: Subscription;
  timesLoadedMore: number;
  @ViewChild('myTable') table: any;

  @Input() globalFilter;
  @Input() enableFilters: any = [];

  @Output() favUpdated = new EventEmitter<any>();

  constructor(
    private _workOrder: WorkOrderApi,
    private _loader: PreloaderService,
    private _utilityService: UtilityService,
    private _favoriteApi: FavoriteApi,
    private _modalService: ModalService,
    private _commonService: CommonService,
    private _globalFilterService: GlobalFilterService
  ) { }

  ngOnInit() {
    this._commonService.saveCurrentPageAnalytics('Global Chat - Program Console', 'Worker Order List')
    this.columnInitialize();
    if (localStorage.getItem('filterCondition')) {
      this.filterCondition = JSON.parse(
        localStorage.getItem('filterCondition')
      );
    }
    this.sidebarStateSub = this._globalFilterService
      .getSidebarState()
      .subscribe((isOpened) => {
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        }, 100);
      });
    this.pmsRecordTypes =
      this._commonService.pmsRecordType &&
      this._commonService.pmsRecordType['all'];
    this.filterCondition = this._commonService.getGlobalFilter(this.enableFilters);
    this._commonService.getUserProfile().subscribe(res => {
      this.userId = res.id;
      this.getData();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.globalFilter && !changes.globalFilter.firstChange) {
      this.tableData = [];
      this.filterCondition = changes.globalFilter.currentValue;
      this.getData();
    }
  }

  getData() {
    this.isLoading = true;
    this.timesLoadedMore = 0;
    this._loader.showPreloader();
    forkJoin(this.getWorkOrders(0), this.getTotalWorkOrderCount()).subscribe(
      ([data, countObj]) => {
        const currentData = this.transformData(data);
        this.tableData = currentData;
        this.totalCount = countObj.count;
        this.noRecords = data.length < this.itemsPerBatch ? true : false;
        this.allItems = this.tableData.slice();
        this.filterData();
      },
      (error) => {
        if (error.message && error.message === 'noRecordFound') {
          this.tableData = [];
          this.totalCount = 0;
          this.noRecords =
            this.tableData.length < this.itemsPerBatch ? true : false;
          this.allItems = [];
          this.setEmptyMessage();
          this.isLoading = false;
        }
        this._loader.hidePreloader();
      },
      () => {
        this.isLoading = false;
        this._loader.hidePreloader();
      }
    );
  }

  getWorkOrders(offset: number) {
    const findObj = {
      order: 'createdAt Desc',
      fields: [
        'id',
        'sfdcId',
        'Name',
        'Status__c',
        'Created_by_Contact__c',
        'JPC_Case__c',
        'createdAt',
        'PMS_Case__c',
        'Has_Backfill_Worker__c',
      ],
      include: [
        {
          relation: 'Case',
          scope: {
            fields: [
              'sfdcId',
              'CaseNumber',
              'Status',
              'ParentId',
              'RecordTypeId',
              'Partner_Case_Number__c',
              'Case_Summary__c',
              'Project_SOP__c',
              'Jobsite__c',
              'Dispatch_SLA_Priority__c',
              'createdAt',
              'PPE_Hours__c',
              'isFRU'
            ],
            include: [
              {
                relation: 'ICC_Case',
                scope: {
                  fields: [
                    'sfdcId',
                    'Partner_Case_Number__c',
                    'Status',
                    'ParentId',
                    'CaseNumber',
                    'Iron_Case_Number__c',
                    'Service_Dispatch__c',
                  ],
                  include: [
                    {
                      relation: 'Job',
                      scope: {
                        fields: [
                          'id',
                          'sfdcId',
                          'Dispatch_Service_Resolution_Status__c',
                          'Job_Status_Internal__c',
                          'Iron_Job_num__c',
                          'Vendor__c',
                          'Dispatch_Worker_Name__c',
                          'Jobsite__c',
                          'Case__c',
                        ],
                        include: [
                          {
                            relation: 'vendor',
                            scope: {
                              fields: ['Name'],
                            },
                          },
                          {
                            relation: 'worker',
                            scope: {
                              fields: ['Name', 'Work_Phone_Number__c'],
                            },
                          },
                          {
                            relation: 'jobsite',
                            scope: {
                              fields: ['Name'],
                            },
                          },
                          {
                            relation: 'appointment',
                            scope: {
                              fields: [
                                'Worker_Arrival_DateTime_Cust_Requested__c',
                                'Early_Start__c',
                                'Earliest_Start_Date_Permitted__c'
                              ],
                            },
                          },
                          {
                            relation: 'favorite',
                            scope: {
                              fields: ['id', 'modelId', 'modelName'],
                              where: { modelName: 'Job', userId: this.userId },
                            },
                          }
                        ],
                      },
                    },
                  ],
                },
              },
              {
                relation: 'program',
                scope: {
                  fields: ['Name'],
                },
              },
              {
                relation: 'pmsAppointment',
                scope: {
                  fields: ['Early_Start__c', 'Worker_Arrival_DateTime_Cust_Requested__c', 'Earliest_Start_Date_Permitted__c'],
                }
              },
              {
                relation: 'Jobsite',
                scope: {
                  fields: ['Name']
                }
              }
            ],
            where: { RecordTypeId: { inq: this.pmsRecordTypes } },
          },
        },
        {
          relation: 'jpcCase',
          scope: {
            fields: ['id', 'sfdcId', 'CaseNumber', 'Status'],
            include: [
              {
                relation: 'favorite',
                scope: {
                  fields: ['id', 'modelId', 'modelName'],
                  where: { modelName: 'Case', userId: this.userId },
                },
              },
            ],
          },
        },
        // {
        //   relation: 'contact',
        //   scope: {
        //     fields: ['sfdcId', 'FirstName', 'LastName'],
        //     include: [{
        //       relation: 'users',
        //       scope: {
        //         fields: ['sfdcId', 'firstname', 'lastname']
        //       }
        //     }]
        //   }
        // }
      ],
      where: { Name: { neq: null } },
      limit: this.itemsPerBatch,
      skip: offset,
    };

    if (this.filterCondition && Object.keys(this.filterCondition).length) {
      findObj['chatWoTabFilter'] = this.filterCondition;
    }

    return this._workOrder.find(findObj);
  }

  getTotalWorkOrderCount() {
    let findObj: any = { Name: { neq: null } };
    if (this.filterCondition && Object.keys(this.filterCondition).length) {
      findObj = { chatWoTabFilter: this.filterCondition };
    }
    return this._workOrder.count(findObj);
  }

  transformData(data) {
    return data.map((wo: any) => ({
      id: wo.id,
      sfdcId: wo.sfdcId,
      hasBackFillWorker: wo.Has_Backfill_Worker__c,
      Name: wo.Name + '/' + wo.Status__c,
      RecordTypeId: wo.Case && wo.Case.RecordTypeId,
      isFRU: wo.Case && wo.Case.isFRU,
      PMS_Case: wo.Case && wo.Case.CaseNumber + '/' + wo.Case.Status,
      ICC_Cases:
        wo.Case &&
        wo.Case.ICC_Case &&
        wo.Case.ICC_Case.map((i) => i.CaseNumber + '/' + i.Status),
      Jobs:
        wo.Case &&
        wo.Case.ICC_Case &&
        wo.Case.ICC_Case.map(
          (i) =>
            i.Job && i.Job.Iron_Job_num__c + '/' + i.Job.Job_Status_Internal__c
        ),
      JobId:
        wo.Case &&
        wo.Case.ICC_Case &&
        wo.Case.ICC_Case.map((i) => i.Job && i.Job.id),
      JobsSfdc:
        wo.Case &&
        wo.Case.ICC_Case &&
        wo.Case.ICC_Case.map((i) => i.Job && i.Job.sfdcId),
      JobsResolution:
        wo.Case &&
        wo.Case.ICC_Case &&
        wo.Case.ICC_Case.map(
          (i) => i.Job && i.Job.Dispatch_Service_Resolution_Status__c
        ),
      CreatedById: wo.CreatedById,
      '##Action': true,
      jpcCase: wo.jpcCase,
      Case: wo.Case,
      JPC_Case__c: wo.JPC_Case__c,
      PMS_Case__c: wo.PMS_Case__c,
      favoriteId:
        (wo.Case && wo.Case.ICC_Case && wo.Case.ICC_Case[0] && wo.Case.ICC_Case[0].Job && wo.Case.ICC_Case[0].Job.favorite &&
          wo.Case.ICC_Case[0].Job.favorite[0] && wo.Case.ICC_Case[0].Job.favorite[0].id) ||
        (wo.jpcCase && wo.jpcCase.favorite && wo.jpcCase.favorite.length && wo.jpcCase.favorite[0].id) || '',
      CasePartnerReference: (wo.Case && wo.Case.Partner_Case_Number__c) || '',
      CaseSummary: wo.Case && wo.Case.Case_Summary__c,
      ProgramName: wo.Case && wo.Case.program && wo.Case.program.Name,
      JobsiteName:
        wo.Case &&
          wo.Case.ICC_Case && wo.Case.ICC_Case.some(i => i.Job) ?
          wo.Case.ICC_Case.map(
            (i) => i.Job && i.Job.jobsite && i.Job.jobsite.Name
          ) : [wo.Case && wo.Case.Jobsite && wo.Case.Jobsite.Name],
      CasePriority: wo.Case && wo.Case.Dispatch_SLA_Priority__c,
      VendorName:
        wo.Case &&
        wo.Case.ICC_Case &&
        wo.Case.ICC_Case.map((i) => i.Job && i.Job.vendor && i.Job.vendor.Name),
      WorkerName:
        wo.Case &&
        wo.Case.ICC_Case &&
        wo.Case.ICC_Case.map((i) => {
          if (i.Job && i.Job.worker) {
            return (
              (i.Job.worker.Name ? i.Job.worker.Name : ' ') +
              (i.Job.worker.Work_Phone_Number__c
                ? ' / ' + i.Job.worker.Work_Phone_Number__c
                : '')
            );
          }
        }),
      AppointmentCustomerRequested:
        wo.Case &&
          wo.Case.ICC_Case && wo.Case.ICC_Case.some(i => i.Job) ?
          wo.Case.ICC_Case.map((i) =>
            i.Job &&
              i.Job.appointment &&
              i.Job.appointment.Worker_Arrival_DateTime_Cust_Requested__c
              ? this.transform(
                i.Job.appointment.Worker_Arrival_DateTime_Cust_Requested__c
              )
              : ''
          ).join(' ') : [(wo.Case && wo.Case.pmsAppointment && wo.Case.pmsAppointment.Worker_Arrival_DateTime_Cust_Requested__c) ?
            this.transform(wo.Case.pmsAppointment.Worker_Arrival_DateTime_Cust_Requested__c) : ''],
      CasePostedDate:
        wo.Case && wo.Case.createdAt ? this.transform(wo.Case.createdAt) : '',
      CaseEventDuration: wo.Case && wo.Case.PPE_Hours__c,
      AppointmentEarlyStartDate: wo.Case &&
        wo.Case.ICC_Case && wo.Case.ICC_Case.some(i => i.Job) ?
        wo.Case.ICC_Case.map((i) =>
          i.Job &&
            i.Job.appointment &&
            i.Job.appointment.Early_Start__c && i.Job.appointment.Earliest_Start_Date_Permitted__c
            ? this.transform(
              i.Job.appointment.Early_Start__c
            )
            : ''
        ).join(' ') : [(wo.Case && wo.Case.pmsAppointment && wo.Case.pmsAppointment.Early_Start__c &&
          wo.Case.pmsAppointment.Earliest_Start_Date_Permitted__c) ?
          this.transform(wo.Case.pmsAppointment.Early_Start__c) : ''],
    }));
  }

  columnInitialize() {
    this.columns = [
      {
        prop: '##Action',
        name: 'Action',
        visible: true,
        width: 155,
        type: 'static',
        frozenLeft: true,
      },
      {
        prop: 'PMS_Case',
        name: 'PMS Case ID (Status)',
        visible: true,
        width: 215,
        type: 'combinedString',
        sortable: true,
        frozenLeft: true,
      },
      {
        prop: 'Name',
        name: 'WO Case ID (Status)',
        visible: true,
        width: 215,
        type: 'combinedString',
        sortable: true,
        frozenLeft: true,
      },
      {
        prop: 'ICC_Cases',
        name: 'ICC Case ID',
        visible: true,
        width: 250,
        type: 'array',
        sortable: true,
        frozenLeft: false,
      },
      {
        prop: 'Jobs',
        name: 'Job ID (Status)',
        visible: true,
        width: 250,
        type: 'array',
        sortable: true,
        frozenLeft: false,
      },
      {
        prop: 'JobsResolution',
        name: 'Job Resolution',
        visible: true,
        width: 250,
        type: 'array',
        sortable: true,
        frozenLeft: false,
      },
      {
        prop: 'CasePartnerReference',
        name: 'Partner Reference (Case / PO)',
        width: 215,
        visible: true,
        type: 'string',
        sortable: true,
        frozenLeft: false,
      },
      {
        prop: 'CaseSummary',
        name: 'Case Summary',
        width: 250,
        visible: true,
        type: 'string',
        sortable: true,
        showMore: true,
      },
      {
        prop: 'ProgramName',
        name: 'Program Name',
        width: 250,
        type: 'string',
        visible: true,
        sortable: true,
      },
      {
        prop: 'JobsiteName',
        name: 'Jobsite Location',
        width: 200,
        type: 'array',
        visible: true,
        sortable: true,
      },
      {
        prop: 'CasePriority',
        name: 'Priority',
        width: 200,
        type: 'string',
        visible: true,
        sortable: true,
      },
      {
        prop: 'VendorName',
        name: 'Vendor Name',
        width: 200,
        type: 'array',
        visible: true,
        sortable: true,
      },
      {
        name: 'Worker Name / Phone',
        prop: 'WorkerName',
        type: 'array',
        width: 200,
        visible: true,
        sortable: true,
      },
      {
        name: 'Customer Requested',
        prop: 'AppointmentCustomerRequested',
        width: 200,
        visible: true,
        sortable: true,
        type: 'date',
        format: 'short',
      },
      {
        prop: 'CasePostedDate',
        name: 'Date Posted (PMS Case)',
        width: 220,
        visible: true,
        sortable: true,
        type: 'date',
        format: 'short',
      },
      {
        prop: 'CaseEventDuration',
        name: 'Event Duration',
        type: 'string',
        width: 200,
        visible: true,
        sortable: true,
      },
      {
        name: 'Early Start Date Permitted',
        prop: 'AppointmentEarlyStartDate',
        width: 250,
        visible: true,
        sortable: true,
        type: 'date',
        format: 'short',
      },

      // {
      //     prop: '',
      //     name: 'Created By',
      //     visible: true,
      //     width: 250,
      //     sortable: true,
      //     frozenLeft: false
      //   }
    ];
  }

  filterData(event?, type?) {
    if (event) {
      if (type === 'date') {
        if (event.value === '') {
          if (this.filterObj[event.input.id + '_temp']) {
            delete this.filterObj[event.input.id];
          }
        } else {
          const date = this.transform(event.value).split(',');
          this.filterObj[event.input.id] = date[0];
        }
      } else {
        if (event.target.value === '') {
          delete this.filterObj[event.currentTarget.id];
        } else {
          this.filterObj[event.currentTarget.id] = event.target.value;
        }
      }
    }
    this.tableData = this.allItems.filter((item) => {
      const notMatchingField = Object.keys(this.filterObj).find((key) =>
        this._utilityService.dataTableSearch(item, this.filterObj, key)
      );
      return !notMatchingField;
    });
    if (this.table) {
      this.table['offset'] = 0;
    }
    this.setEmptyMessage();
  }

  setEmptyMessage() {
    const msg = 'No data to display';
    if (!this.tableData.length) {
      this.tableData = [
        {
          message: msg,
        },
      ];
      this.tableData[0][this.columns[0]['prop']] = msg;
    } else {
      if (this.tableData[0].hasOwnProperty('message')) {
        this.tableData.shift();
      }
    }
  }

  clearSearch(col) {
    this.filterObj[col] = '';
    delete this.filterObj[col];
    this.tableData = this.allItems.filter((item) => {
      const notMatchingField = Object.keys(this.filterObj).find((key) =>
        this._utilityService.dataTableSearch(item, this.filterObj, key)
      );
      return !notMatchingField;
    });
    this.table.offset = 0;
    this.setEmptyMessage();
  }

  loadMoreRecords() {
    this.timesLoadedMore += 1;
    this.getWorkOrders(this.itemsPerBatch * this.timesLoadedMore).subscribe(
      (data) => {
        const currentData = this.transformData(data);
        currentData.forEach((c) => {
          this.allItems.push(c);
        });
        this.tableData = [...this.allItems];
        this.allItems = this.tableData.slice();
        this.filterData();
      },
      (error) => {
        this._loader.hidePreloader();
      },
      () => {
        this._loader.hidePreloader();
      }
    );
  }

  onfavChange(e) {
    e['modelId'] =
      e.JobId && e.JobId.length ? e.JobId[0] : e.jpcCase && e.jpcCase.id;
    e['modelName'] = e.JobId && e.JobId.length ? 'Job' : 'Case';
    this.favUpdated.emit(e);
  }

  openGlobalChatRoom(item, modal, size) {
    const msgObject = {};
    const workOrder = JSON.stringify(item);
    msgObject['modelId'] = item.jpcCase && item.jpcCase.id;
    msgObject['modelName'] = 'Case';
    msgObject['Case__c'] = item.jpcCase && item.jpcCase.sfdcId;
    msgObject['count'] = 0;
    msgObject['workOrder'] = JSON.parse(workOrder);
    msgObject['workOrder']['Name'] = msgObject['workOrder']['Name']
      ? msgObject['workOrder']['Name'].split('/')[0]
      : '';
    msgObject['isPopup'] = true;
    if (item.JobsSfdc && item.JobsSfdc.length) {
      this.selectedWorkOrderJobs = item.JobsSfdc;
    } else {
      this.selectedWorkOrderJobs = null;
    }
    this.selectedWOMSG = msgObject;
    this._modalService.open(modal, size);
  }

  transform(value: string, type?) {
    let dateformate: string;
    type ? (dateformate = type) : (dateformate = 'short');
    this.datePipe = new DatePipe('en-US');
    const formateddate = value
      ? this.datePipe.transform(value, dateformate)
      : value;
    return formateddate;
  }

  ngOnDestroy() {
    if (this.sidebarStateSub) {
      this.sidebarStateSub.unsubscribe();
    }
  }
}
