
import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'noRejected'
  })
  export class NoRejectedPipe implements PipeTransform {
  
    transform(noOfInterview ,noOfRejected) {
      if (noOfInterview > 0) {
        return parseInt(noOfRejected) + ' (' + (parseInt(noOfRejected) / parseInt(noOfInterview) * 100).toFixed(2) + '%)';
      } else {
        return parseInt(noOfRejected) + ' (0.00%)';
      }
    }
  
  }