import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { truncate } from 'fs';
import { conversationSourceValues } from '../../../models/static-list-data.service';

@Component({
  selector: 'app-conversation-source-lookup',
  templateUrl: './conversation-source-lookup.component.html',
  styleUrls: ['./conversation-source-lookup.component.css']
})
export class ConversationSourceLookupComponent implements OnInit {
  ConversationSourceList = [];
  conversationSourceSelected = [];
  isMultipleSelected = true;

  @Input() set resetData(isClear) {
    if (isClear) {
      this.conversationSourceSelected = [];
    }
  }
  @Input() maxSelectedItems = 1;
  @Output() selectedConversationSource: EventEmitter<any> = new EventEmitter;


  constructor() { }

  ngOnInit() {
    this.ConversationSourceList = conversationSourceValues;
    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : ''
    this.conversationSourceSelected = (preselected &&
      preselected['conversationSource']) ? preselected['conversationSource'] : [''];
  }

  onConversationSourceChange() {
    this.selectedConversationSource.emit(this.conversationSourceSelected);
  }

  clearConversationSource() {
    this.conversationSourceSelected = [];
    this.selectedConversationSource.emit([]);
  }


}
