/* tslint:disable */
import {
  MilestoneLibrary,
  TaskLibrary
} from '../index';

declare var Object: any;
export interface MilestoneTaskLibraryMapInterface {
  "sfdcId"?: string;
  "Name"?: string;
  "CurrencyIsoCode"?: string;
  "CreatedById"?: string;
  "LastModifiedById"?: string;
  "PgMO_Milestone_Library__c"?: string;
  "PgMO_Task_Library__c"?: string;
  "Sequence_Number__c"?: number;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  milestone?: MilestoneLibrary;
  task?: TaskLibrary;
}

export class MilestoneTaskLibraryMap implements MilestoneTaskLibraryMapInterface {
  "sfdcId": string;
  "Name": string;
  "CurrencyIsoCode": string;
  "CreatedById": string;
  "LastModifiedById": string;
  "PgMO_Milestone_Library__c": string;
  "PgMO_Task_Library__c": string;
  "Sequence_Number__c": number;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  milestone: MilestoneLibrary;
  task: TaskLibrary;
  constructor(data?: MilestoneTaskLibraryMapInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `MilestoneTaskLibraryMap`.
   */
  public static getModelName() {
    return "MilestoneTaskLibraryMap";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of MilestoneTaskLibraryMap for dynamic purposes.
  **/
  public static factory(data: MilestoneTaskLibraryMapInterface): MilestoneTaskLibraryMap{
    return new MilestoneTaskLibraryMap(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'MilestoneTaskLibraryMap',
      plural: 'MilestoneTaskLibraryMaps',
      path: 'MilestoneTaskLibraryMaps',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "PgMO_Milestone_Library__c": {
          name: 'PgMO_Milestone_Library__c',
          type: 'string'
        },
        "PgMO_Task_Library__c": {
          name: 'PgMO_Task_Library__c',
          type: 'string'
        },
        "Sequence_Number__c": {
          name: 'Sequence_Number__c',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        milestone: {
          name: 'milestone',
          type: 'MilestoneLibrary',
          model: 'MilestoneLibrary',
          relationType: 'belongsTo',
                  keyFrom: 'PgMO_Milestone_Library__c',
          keyTo: 'sfdcId'
        },
        task: {
          name: 'task',
          type: 'TaskLibrary',
          model: 'TaskLibrary',
          relationType: 'belongsTo',
                  keyFrom: 'PgMO_Task_Library__c',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
