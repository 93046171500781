import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { invoiceStatus } from 'shared/models/static-list-data.service';

@Component({
  selector: 'app-purchase-invoice-status-lookup',
  templateUrl: './purchase-invoice-status-lookup.component.html',
  styleUrls: ['./purchase-invoice-status-lookup.component.css']
})
export class PurchaseInvoiceStatusLookupComponent implements OnInit {

  @Input() placeholder = 'Sales Invoice Status';
  @Input() set resetData(isClear) {
      this.onClear(isClear);
  }
  @Output() selectedValue: EventEmitter<any> = new EventEmitter;
  purchaseStatus: any = [];
  selectedStatus: any = [];

  constructor() {
  }

  ngOnInit() {
      this.purchaseStatus = invoiceStatus;
      // to show selected Purchase invoice status
      const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
      this.selectedStatus = (preselected && preselected['purchaseInvoiceStatus']) ? preselected['purchaseInvoiceStatus'] : '';
  }

  /**
   * This function executes whenever there is a change in selected values.
   * This will emit the changed array.
  */
  onChange() {
      this.selectedValue.emit(this.selectedStatus);
  }

  /**
   * This function executes whenever the select input is cleared.
   * This will clear the array and emit an empty array.
  */
  onClear(isClear) {
      this.selectedStatus = [];
      if (isClear) {
          this.selectedValue.emit([]);
      }
  }
}

