/* tslint:disable */
import {
  Contact
} from '../index';

declare var Object: any;
export interface BusinessUnitInterface {
  "sfdcId"?: string;
  "CreatedById"?: string;
  "CreatedDate"?: Date;
  "CurrencyIsoCode"?: string;
  "IsDeleted"?: boolean;
  "LastModifiedById"?: string;
  "LastModifiedDate"?: Date;
  "Name"?: string;
  "Business_Unit_Name__c"?: string;
  "Business_Unit_Code__c"?: string;
  "Created_By__c"?: string;
  "Description__c"?: string;
  "Incorporation_Date__c"?: Date;
  "Last_Modified_By__c"?: string;
  "Status__c"?: string;
  "CreatedBy__c"?: string;
  "LastModifiedBy__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  createdBy?: Contact;
  lastModifiedBy?: Contact;
}

export class BusinessUnit implements BusinessUnitInterface {
  "sfdcId": string;
  "CreatedById": string;
  "CreatedDate": Date;
  "CurrencyIsoCode": string;
  "IsDeleted": boolean;
  "LastModifiedById": string;
  "LastModifiedDate": Date;
  "Name": string;
  "Business_Unit_Name__c": string;
  "Business_Unit_Code__c": string;
  "Created_By__c": string;
  "Description__c": string;
  "Incorporation_Date__c": Date;
  "Last_Modified_By__c": string;
  "Status__c": string;
  "CreatedBy__c": string;
  "LastModifiedBy__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  createdBy: Contact;
  lastModifiedBy: Contact;
  constructor(data?: BusinessUnitInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `BusinessUnit`.
   */
  public static getModelName() {
    return "BusinessUnit";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of BusinessUnit for dynamic purposes.
  **/
  public static factory(data: BusinessUnitInterface): BusinessUnit{
    return new BusinessUnit(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'BusinessUnit',
      plural: 'BusinessUnits',
      path: 'BusinessUnits',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "Business_Unit_Name__c": {
          name: 'Business_Unit_Name__c',
          type: 'string'
        },
        "Business_Unit_Code__c": {
          name: 'Business_Unit_Code__c',
          type: 'string'
        },
        "Created_By__c": {
          name: 'Created_By__c',
          type: 'string'
        },
        "Description__c": {
          name: 'Description__c',
          type: 'string'
        },
        "Incorporation_Date__c": {
          name: 'Incorporation_Date__c',
          type: 'Date'
        },
        "Last_Modified_By__c": {
          name: 'Last_Modified_By__c',
          type: 'string'
        },
        "Status__c": {
          name: 'Status__c',
          type: 'string'
        },
        "CreatedBy__c": {
          name: 'CreatedBy__c',
          type: 'string'
        },
        "LastModifiedBy__c": {
          name: 'LastModifiedBy__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        createdBy: {
          name: 'createdBy',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Created_By__c',
          keyTo: 'sfdcId'
        },
        lastModifiedBy: {
          name: 'lastModifiedBy',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Last_Modified_By__c',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
