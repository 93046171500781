export function googleAnalyticsHeadScripts(gTag_code) {
    const head = document.getElementsByTagName('head')[0];

    const googleAnalyticsFirstScript = document.createElement('script');
    googleAnalyticsFirstScript.async = true;
    googleAnalyticsFirstScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + gTag_code;

    const googleAnalyticsSecondScript =  document.createElement('script');
    googleAnalyticsSecondScript.innerHTML = '    window.dataLayer = window.dataLayer || [];\n' +
        '    function gtag(){dataLayer.push(arguments);}\n' +
        '    gtag(\'js\', new Date());\n' +
        '\n' +
        '    gtag(\'config\', \'' + gTag_code + '\');';

    head.insertBefore(googleAnalyticsSecondScript, head.firstChild);
    head.insertBefore(googleAnalyticsFirstScript, head.firstChild);
}

export function googleAnalytics(url, gTag_code) {
    gtag('config', gTag_code, { 'page_path': url });
}