/* tslint:disable */

declare var Object: any;
export interface JobOpportunityInterface {
  "Name"?: string;
  "sfdcId"?: string;
  "OwnerId"?: string;
  "LastModifiedById"?: string;
  "CurrencyIsoCode"?: string;
  "CreatedById"?: string;
  "RecordTypeId"?: string;
  "jsjo__Bonus__c"?: string;
  "jsjo__Burden_Pct__c"?: number;
  "jsjo__Business_Unit__c"?: string;
  "jsjo__CDate__c"?: Date;
  "jsjo__Chance_this_search_will_be_filled__c"?: number;
  "jsjo__Chatter_Hash_Tags__c"?: string;
  "jsjo__Clearance__c"?: boolean;
  "jsjo__Clearance_Type__c"?: string;
  "jsjo__Account__c"?: string;
  "jsjo__Closed_Reason__c"?: string;
  "jsjo__Close_Pct__c"?: number;
  "jsjo__Contact__c"?: string;
  "jsjo__Contract__c"?: string;
  "jsjo__Conversion_Date__c"?: Date;
  "jsjo__Conversion_Fee_Pct__c"?: number;
  "jsjo__Created_Date__c"?: Date;
  "jsjo__Date_Closed__c"?: Date;
  "jsjo__Date_Filled__c"?: Date;
  "jsjo__Date_Posted__c"?: Date;
  "jsjo__Days_Open__c"?: number;
  "jsjo__Department__c"?: string;
  "jsjo__Description__c"?: string;
  "jsjo__Disable_Apply_with_Indeed__c"?: boolean;
  "jsjo__Disable_EEO__c"?: boolean;
  "jsjo__Employment_Type__c"?: string;
  "jsjo__Estimated_End_Date__c"?: Date;
  "jsjo__Estimated_Margin__c"?: number;
  "jsjo__Estimated_Start_Date__c"?: Date;
  "jsjo__User__c"?: string;
  "jsjo__Expiration_Date__c"?: Date;
  "jsjo__Expires__c"?: boolean;
  "jsjo__Facility__c"?: string;
  "jsjo__Fee_Pct__c"?: number;
  "jsjo__First_Year_Salary__c"?: number;
  "jsjo__Forecasted_Revenue__c"?: number;
  "jsjo__FTE__c"?: number;
  "jsjo__Manager__c"?: string;
  "jsjo__Internal_Notes__c"?: string;
  "jsjo__Internal_Passing_Score__c"?: number;
  "jsjo__Internal_Question_Set__c"?: string;
  "jsjo__Job_Advertisement__c"?: string;
  "jsjo__Job_Description__c"?: string;
  "jsjo__Job_Family__c"?: string;
  "jsjo__Job_Function__c"?: string;
  "jsjo__Job_Opportunity_Mapping__c"?: string;
  "jsjo__Job_Rank__c"?: string;
  "jsjo__Job_Tag__c"?: string;
  "jsjo__Last_Modified_Date_for_S_Control__c"?: Date;
  "jsjo__Localized_Job_Description__c"?: string;
  "jsjo__Localized_Job_Title__c"?: string;
  "jsjo__Location__c"?: string;
  "jsjo__Loss_Reason__c"?: string;
  "jsjo__Lost_Business_To__c"?: string;
  "jsjo__Margin__c"?: number;
  "jsjo__Markup__c"?: boolean;
  "jsjo__Markup_Pay__c"?: number;
  "jsjo__Max_Bill_Rate__c"?: number;
  "jsjo__Max_Conversion_Salary__c"?: number;
  "jsjo__Max_Pay_Rate__c"?: number;
  "jsjo__Max_Salary__c"?: number;
  "jsjo__Metro__c"?: string;
  "jsjo__Min_Bill_Rate__c"?: number;
  "jsjo__Min_Conversion_Salary__c"?: number;
  "jsjo__Min_Pay_Rate__c"?: number;
  "jsjo__Min_Salary__c"?: number;
  "jsjo__Openings__c"?: number;
  "jsjo__Order__c"?: string;
  "jsjo__Passing_Score__c"?: number;
  "jsjo__referral_bonus__c"?: string;
  "jsjo__Percentage_Fee__c"?: number;
  "jsjo__Per_Diem__c"?: boolean;
  "jsjo__Per_Diem_Bill_Rate__c"?: number;
  "jsjo__Per_Diem_Diff_Pct__c"?: number;
  "jsjo__Per_Diem_Pay_Rate__c"?: number;
  "jsjo__Posted_to_Prospects__c"?: boolean;
  "jsjo__Post_Job__c"?: boolean;
  "jsjo__Post_to_Chatter__c"?: boolean;
  "jsjo__Recruiter__c"?: string;
  "jsjo__Priority__c"?: string;
  "jsjo__Project__c"?: string;
  "jsjo__Question_Set__c"?: string;
  "jsjo__Screening_Requirements__c"?: string;
  "jsjo__Search__c"?: number;
  "jsjo__Search_Type__c"?: string;
  "jsjo__Secondary_Recruiter__c"?: string;
  "jsjo__Shared__c"?: boolean;
  "jsjo__Shift__c"?: string;
  "jsjo__Short_Description__c"?: string;
  "jsjo__Skill_Codes__c"?: string;
  "jsjo__Source__c"?: string;
  "jsjo__Specific_Requirements__c"?: string;
  "jsjo__Stage__c"?: string;
  "jsjo__Status__c"?: string;
  "jsjo__Target_Base_Salary__c"?: number;
  "jsjo__Text_Description__c"?: string;
  "jsjo__Travel__c"?: number;
  "jsjo__Waitlisted__c"?: boolean;
  "jsjo__Win_Probability__c"?: number;
  "jsjo__Work_Class__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class JobOpportunity implements JobOpportunityInterface {
  "Name": string;
  "sfdcId": string;
  "OwnerId": string;
  "LastModifiedById": string;
  "CurrencyIsoCode": string;
  "CreatedById": string;
  "RecordTypeId": string;
  "jsjo__Bonus__c": string;
  "jsjo__Burden_Pct__c": number;
  "jsjo__Business_Unit__c": string;
  "jsjo__CDate__c": Date;
  "jsjo__Chance_this_search_will_be_filled__c": number;
  "jsjo__Chatter_Hash_Tags__c": string;
  "jsjo__Clearance__c": boolean;
  "jsjo__Clearance_Type__c": string;
  "jsjo__Account__c": string;
  "jsjo__Closed_Reason__c": string;
  "jsjo__Close_Pct__c": number;
  "jsjo__Contact__c": string;
  "jsjo__Contract__c": string;
  "jsjo__Conversion_Date__c": Date;
  "jsjo__Conversion_Fee_Pct__c": number;
  "jsjo__Created_Date__c": Date;
  "jsjo__Date_Closed__c": Date;
  "jsjo__Date_Filled__c": Date;
  "jsjo__Date_Posted__c": Date;
  "jsjo__Days_Open__c": number;
  "jsjo__Department__c": string;
  "jsjo__Description__c": string;
  "jsjo__Disable_Apply_with_Indeed__c": boolean;
  "jsjo__Disable_EEO__c": boolean;
  "jsjo__Employment_Type__c": string;
  "jsjo__Estimated_End_Date__c": Date;
  "jsjo__Estimated_Margin__c": number;
  "jsjo__Estimated_Start_Date__c": Date;
  "jsjo__User__c": string;
  "jsjo__Expiration_Date__c": Date;
  "jsjo__Expires__c": boolean;
  "jsjo__Facility__c": string;
  "jsjo__Fee_Pct__c": number;
  "jsjo__First_Year_Salary__c": number;
  "jsjo__Forecasted_Revenue__c": number;
  "jsjo__FTE__c": number;
  "jsjo__Manager__c": string;
  "jsjo__Internal_Notes__c": string;
  "jsjo__Internal_Passing_Score__c": number;
  "jsjo__Internal_Question_Set__c": string;
  "jsjo__Job_Advertisement__c": string;
  "jsjo__Job_Description__c": string;
  "jsjo__Job_Family__c": string;
  "jsjo__Job_Function__c": string;
  "jsjo__Job_Opportunity_Mapping__c": string;
  "jsjo__Job_Rank__c": string;
  "jsjo__Job_Tag__c": string;
  "jsjo__Last_Modified_Date_for_S_Control__c": Date;
  "jsjo__Localized_Job_Description__c": string;
  "jsjo__Localized_Job_Title__c": string;
  "jsjo__Location__c": string;
  "jsjo__Loss_Reason__c": string;
  "jsjo__Lost_Business_To__c": string;
  "jsjo__Margin__c": number;
  "jsjo__Markup__c": boolean;
  "jsjo__Markup_Pay__c": number;
  "jsjo__Max_Bill_Rate__c": number;
  "jsjo__Max_Conversion_Salary__c": number;
  "jsjo__Max_Pay_Rate__c": number;
  "jsjo__Max_Salary__c": number;
  "jsjo__Metro__c": string;
  "jsjo__Min_Bill_Rate__c": number;
  "jsjo__Min_Conversion_Salary__c": number;
  "jsjo__Min_Pay_Rate__c": number;
  "jsjo__Min_Salary__c": number;
  "jsjo__Openings__c": number;
  "jsjo__Order__c": string;
  "jsjo__Passing_Score__c": number;
  "jsjo__referral_bonus__c": string;
  "jsjo__Percentage_Fee__c": number;
  "jsjo__Per_Diem__c": boolean;
  "jsjo__Per_Diem_Bill_Rate__c": number;
  "jsjo__Per_Diem_Diff_Pct__c": number;
  "jsjo__Per_Diem_Pay_Rate__c": number;
  "jsjo__Posted_to_Prospects__c": boolean;
  "jsjo__Post_Job__c": boolean;
  "jsjo__Post_to_Chatter__c": boolean;
  "jsjo__Recruiter__c": string;
  "jsjo__Priority__c": string;
  "jsjo__Project__c": string;
  "jsjo__Question_Set__c": string;
  "jsjo__Screening_Requirements__c": string;
  "jsjo__Search__c": number;
  "jsjo__Search_Type__c": string;
  "jsjo__Secondary_Recruiter__c": string;
  "jsjo__Shared__c": boolean;
  "jsjo__Shift__c": string;
  "jsjo__Short_Description__c": string;
  "jsjo__Skill_Codes__c": string;
  "jsjo__Source__c": string;
  "jsjo__Specific_Requirements__c": string;
  "jsjo__Stage__c": string;
  "jsjo__Status__c": string;
  "jsjo__Target_Base_Salary__c": number;
  "jsjo__Text_Description__c": string;
  "jsjo__Travel__c": number;
  "jsjo__Waitlisted__c": boolean;
  "jsjo__Win_Probability__c": number;
  "jsjo__Work_Class__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: JobOpportunityInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `JobOpportunity`.
   */
  public static getModelName() {
    return "JobOpportunity";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of JobOpportunity for dynamic purposes.
  **/
  public static factory(data: JobOpportunityInterface): JobOpportunity{
    return new JobOpportunity(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'JobOpportunity',
      plural: 'JobOpportunities',
      path: 'JobOpportunities',
      idName: 'id',
      properties: {
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "RecordTypeId": {
          name: 'RecordTypeId',
          type: 'string'
        },
        "jsjo__Bonus__c": {
          name: 'jsjo__Bonus__c',
          type: 'string'
        },
        "jsjo__Burden_Pct__c": {
          name: 'jsjo__Burden_Pct__c',
          type: 'number'
        },
        "jsjo__Business_Unit__c": {
          name: 'jsjo__Business_Unit__c',
          type: 'string'
        },
        "jsjo__CDate__c": {
          name: 'jsjo__CDate__c',
          type: 'Date'
        },
        "jsjo__Chance_this_search_will_be_filled__c": {
          name: 'jsjo__Chance_this_search_will_be_filled__c',
          type: 'number'
        },
        "jsjo__Chatter_Hash_Tags__c": {
          name: 'jsjo__Chatter_Hash_Tags__c',
          type: 'string'
        },
        "jsjo__Clearance__c": {
          name: 'jsjo__Clearance__c',
          type: 'boolean'
        },
        "jsjo__Clearance_Type__c": {
          name: 'jsjo__Clearance_Type__c',
          type: 'string'
        },
        "jsjo__Account__c": {
          name: 'jsjo__Account__c',
          type: 'string'
        },
        "jsjo__Closed_Reason__c": {
          name: 'jsjo__Closed_Reason__c',
          type: 'string'
        },
        "jsjo__Close_Pct__c": {
          name: 'jsjo__Close_Pct__c',
          type: 'number'
        },
        "jsjo__Contact__c": {
          name: 'jsjo__Contact__c',
          type: 'string'
        },
        "jsjo__Contract__c": {
          name: 'jsjo__Contract__c',
          type: 'string'
        },
        "jsjo__Conversion_Date__c": {
          name: 'jsjo__Conversion_Date__c',
          type: 'Date'
        },
        "jsjo__Conversion_Fee_Pct__c": {
          name: 'jsjo__Conversion_Fee_Pct__c',
          type: 'number'
        },
        "jsjo__Created_Date__c": {
          name: 'jsjo__Created_Date__c',
          type: 'Date'
        },
        "jsjo__Date_Closed__c": {
          name: 'jsjo__Date_Closed__c',
          type: 'Date'
        },
        "jsjo__Date_Filled__c": {
          name: 'jsjo__Date_Filled__c',
          type: 'Date'
        },
        "jsjo__Date_Posted__c": {
          name: 'jsjo__Date_Posted__c',
          type: 'Date'
        },
        "jsjo__Days_Open__c": {
          name: 'jsjo__Days_Open__c',
          type: 'number'
        },
        "jsjo__Department__c": {
          name: 'jsjo__Department__c',
          type: 'string'
        },
        "jsjo__Description__c": {
          name: 'jsjo__Description__c',
          type: 'string'
        },
        "jsjo__Disable_Apply_with_Indeed__c": {
          name: 'jsjo__Disable_Apply_with_Indeed__c',
          type: 'boolean'
        },
        "jsjo__Disable_EEO__c": {
          name: 'jsjo__Disable_EEO__c',
          type: 'boolean'
        },
        "jsjo__Employment_Type__c": {
          name: 'jsjo__Employment_Type__c',
          type: 'string'
        },
        "jsjo__Estimated_End_Date__c": {
          name: 'jsjo__Estimated_End_Date__c',
          type: 'Date'
        },
        "jsjo__Estimated_Margin__c": {
          name: 'jsjo__Estimated_Margin__c',
          type: 'number'
        },
        "jsjo__Estimated_Start_Date__c": {
          name: 'jsjo__Estimated_Start_Date__c',
          type: 'Date'
        },
        "jsjo__User__c": {
          name: 'jsjo__User__c',
          type: 'string'
        },
        "jsjo__Expiration_Date__c": {
          name: 'jsjo__Expiration_Date__c',
          type: 'Date'
        },
        "jsjo__Expires__c": {
          name: 'jsjo__Expires__c',
          type: 'boolean'
        },
        "jsjo__Facility__c": {
          name: 'jsjo__Facility__c',
          type: 'string'
        },
        "jsjo__Fee_Pct__c": {
          name: 'jsjo__Fee_Pct__c',
          type: 'number'
        },
        "jsjo__First_Year_Salary__c": {
          name: 'jsjo__First_Year_Salary__c',
          type: 'number'
        },
        "jsjo__Forecasted_Revenue__c": {
          name: 'jsjo__Forecasted_Revenue__c',
          type: 'number'
        },
        "jsjo__FTE__c": {
          name: 'jsjo__FTE__c',
          type: 'number'
        },
        "jsjo__Manager__c": {
          name: 'jsjo__Manager__c',
          type: 'string'
        },
        "jsjo__Internal_Notes__c": {
          name: 'jsjo__Internal_Notes__c',
          type: 'string'
        },
        "jsjo__Internal_Passing_Score__c": {
          name: 'jsjo__Internal_Passing_Score__c',
          type: 'number'
        },
        "jsjo__Internal_Question_Set__c": {
          name: 'jsjo__Internal_Question_Set__c',
          type: 'string'
        },
        "jsjo__Job_Advertisement__c": {
          name: 'jsjo__Job_Advertisement__c',
          type: 'string'
        },
        "jsjo__Job_Description__c": {
          name: 'jsjo__Job_Description__c',
          type: 'string'
        },
        "jsjo__Job_Family__c": {
          name: 'jsjo__Job_Family__c',
          type: 'string'
        },
        "jsjo__Job_Function__c": {
          name: 'jsjo__Job_Function__c',
          type: 'string'
        },
        "jsjo__Job_Opportunity_Mapping__c": {
          name: 'jsjo__Job_Opportunity_Mapping__c',
          type: 'string'
        },
        "jsjo__Job_Rank__c": {
          name: 'jsjo__Job_Rank__c',
          type: 'string'
        },
        "jsjo__Job_Tag__c": {
          name: 'jsjo__Job_Tag__c',
          type: 'string'
        },
        "jsjo__Last_Modified_Date_for_S_Control__c": {
          name: 'jsjo__Last_Modified_Date_for_S_Control__c',
          type: 'Date'
        },
        "jsjo__Localized_Job_Description__c": {
          name: 'jsjo__Localized_Job_Description__c',
          type: 'string'
        },
        "jsjo__Localized_Job_Title__c": {
          name: 'jsjo__Localized_Job_Title__c',
          type: 'string'
        },
        "jsjo__Location__c": {
          name: 'jsjo__Location__c',
          type: 'string'
        },
        "jsjo__Loss_Reason__c": {
          name: 'jsjo__Loss_Reason__c',
          type: 'string'
        },
        "jsjo__Lost_Business_To__c": {
          name: 'jsjo__Lost_Business_To__c',
          type: 'string'
        },
        "jsjo__Margin__c": {
          name: 'jsjo__Margin__c',
          type: 'number'
        },
        "jsjo__Markup__c": {
          name: 'jsjo__Markup__c',
          type: 'boolean'
        },
        "jsjo__Markup_Pay__c": {
          name: 'jsjo__Markup_Pay__c',
          type: 'number'
        },
        "jsjo__Max_Bill_Rate__c": {
          name: 'jsjo__Max_Bill_Rate__c',
          type: 'number'
        },
        "jsjo__Max_Conversion_Salary__c": {
          name: 'jsjo__Max_Conversion_Salary__c',
          type: 'number'
        },
        "jsjo__Max_Pay_Rate__c": {
          name: 'jsjo__Max_Pay_Rate__c',
          type: 'number'
        },
        "jsjo__Max_Salary__c": {
          name: 'jsjo__Max_Salary__c',
          type: 'number'
        },
        "jsjo__Metro__c": {
          name: 'jsjo__Metro__c',
          type: 'string'
        },
        "jsjo__Min_Bill_Rate__c": {
          name: 'jsjo__Min_Bill_Rate__c',
          type: 'number'
        },
        "jsjo__Min_Conversion_Salary__c": {
          name: 'jsjo__Min_Conversion_Salary__c',
          type: 'number'
        },
        "jsjo__Min_Pay_Rate__c": {
          name: 'jsjo__Min_Pay_Rate__c',
          type: 'number'
        },
        "jsjo__Min_Salary__c": {
          name: 'jsjo__Min_Salary__c',
          type: 'number'
        },
        "jsjo__Openings__c": {
          name: 'jsjo__Openings__c',
          type: 'number'
        },
        "jsjo__Order__c": {
          name: 'jsjo__Order__c',
          type: 'string'
        },
        "jsjo__Passing_Score__c": {
          name: 'jsjo__Passing_Score__c',
          type: 'number'
        },
        "jsjo__referral_bonus__c": {
          name: 'jsjo__referral_bonus__c',
          type: 'string'
        },
        "jsjo__Percentage_Fee__c": {
          name: 'jsjo__Percentage_Fee__c',
          type: 'number'
        },
        "jsjo__Per_Diem__c": {
          name: 'jsjo__Per_Diem__c',
          type: 'boolean'
        },
        "jsjo__Per_Diem_Bill_Rate__c": {
          name: 'jsjo__Per_Diem_Bill_Rate__c',
          type: 'number'
        },
        "jsjo__Per_Diem_Diff_Pct__c": {
          name: 'jsjo__Per_Diem_Diff_Pct__c',
          type: 'number'
        },
        "jsjo__Per_Diem_Pay_Rate__c": {
          name: 'jsjo__Per_Diem_Pay_Rate__c',
          type: 'number'
        },
        "jsjo__Posted_to_Prospects__c": {
          name: 'jsjo__Posted_to_Prospects__c',
          type: 'boolean'
        },
        "jsjo__Post_Job__c": {
          name: 'jsjo__Post_Job__c',
          type: 'boolean'
        },
        "jsjo__Post_to_Chatter__c": {
          name: 'jsjo__Post_to_Chatter__c',
          type: 'boolean'
        },
        "jsjo__Recruiter__c": {
          name: 'jsjo__Recruiter__c',
          type: 'string'
        },
        "jsjo__Priority__c": {
          name: 'jsjo__Priority__c',
          type: 'string'
        },
        "jsjo__Project__c": {
          name: 'jsjo__Project__c',
          type: 'string'
        },
        "jsjo__Question_Set__c": {
          name: 'jsjo__Question_Set__c',
          type: 'string'
        },
        "jsjo__Screening_Requirements__c": {
          name: 'jsjo__Screening_Requirements__c',
          type: 'string'
        },
        "jsjo__Search__c": {
          name: 'jsjo__Search__c',
          type: 'number'
        },
        "jsjo__Search_Type__c": {
          name: 'jsjo__Search_Type__c',
          type: 'string'
        },
        "jsjo__Secondary_Recruiter__c": {
          name: 'jsjo__Secondary_Recruiter__c',
          type: 'string'
        },
        "jsjo__Shared__c": {
          name: 'jsjo__Shared__c',
          type: 'boolean'
        },
        "jsjo__Shift__c": {
          name: 'jsjo__Shift__c',
          type: 'string'
        },
        "jsjo__Short_Description__c": {
          name: 'jsjo__Short_Description__c',
          type: 'string'
        },
        "jsjo__Skill_Codes__c": {
          name: 'jsjo__Skill_Codes__c',
          type: 'string'
        },
        "jsjo__Source__c": {
          name: 'jsjo__Source__c',
          type: 'string'
        },
        "jsjo__Specific_Requirements__c": {
          name: 'jsjo__Specific_Requirements__c',
          type: 'string'
        },
        "jsjo__Stage__c": {
          name: 'jsjo__Stage__c',
          type: 'string'
        },
        "jsjo__Status__c": {
          name: 'jsjo__Status__c',
          type: 'string'
        },
        "jsjo__Target_Base_Salary__c": {
          name: 'jsjo__Target_Base_Salary__c',
          type: 'number'
        },
        "jsjo__Text_Description__c": {
          name: 'jsjo__Text_Description__c',
          type: 'string'
        },
        "jsjo__Travel__c": {
          name: 'jsjo__Travel__c',
          type: 'number'
        },
        "jsjo__Waitlisted__c": {
          name: 'jsjo__Waitlisted__c',
          type: 'boolean'
        },
        "jsjo__Win_Probability__c": {
          name: 'jsjo__Win_Probability__c',
          type: 'number'
        },
        "jsjo__Work_Class__c": {
          name: 'jsjo__Work_Class__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
