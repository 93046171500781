import { CommonService } from './../../services/common.service';
import { NotificationService } from './../../services/notification.service';
import { PreloaderService } from './../../services/preloader.service';
import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-check-in-check-out-activity-feed',
  templateUrl: './check-in-check-out-activity-feed.component.html',
  styleUrls: ['./check-in-check-out-activity-feed.component.css']
})
export class CheckInCheckOutActivityFeedComponent implements OnInit {

  @Input() set refreshChild(e) {
    if (e) {
      // set timeout for getting activity feed list
      setTimeout(() => {
        this.getActivityFeedList();
      }, 1000);
    }
  }

  @Input() set jobInfo(e) {
    if (e) {
      this.workerJobInfo = e;
      this._loader.showPreloader();
      this.getActivityFeedList();
    }
  };

  pageNo = 1;
  perPageSize = 10;
  total = 0;
  lastPage = 1;
  isHTML= false;

  imgpath = environment.baseUrl + '/' + environment.apiVersion;

  workerJobInfo: any;
  activityFeeds = [];

  constructor(private _notificationService: NotificationService, private _loader: PreloaderService,
    private _commonService: CommonService) { }

  ngOnInit() {
  }

  getActivityFeedList(pageNo = 1) {
    if (this.workerJobInfo && this.workerJobInfo.jobs) {
      this._notificationService
        .getActivityFeed(pageNo, {
          'id': this.workerJobInfo.jobs.id
        }, this.perPageSize).then((res: any) => {
          this._loader.hidePreloader();
          if (res && res['data']) {
            this.modifyData(res['data']);
            this.lastPage = res['lastPage'];
            this.total = res['total'];
            this.pageNo = res['page'];
          }
        })
        .catch(err => {
          this._loader.hidePreloader();
          console.log(err);
        });
    } else {
      this._loader.hidePreloader();
    }
  }

  modifyData(listData) {
    listData.forEach(feed => {
      this.isHTML = false;
      if (feed['details']) {
        this.isHTML = /<(?=.*? .*?\/ ?>|br|hr|input|!--|wbr)[a-z]+.*?>|<([a-z]+).*?<\/\1>/i.test(feed['details'])
        feed['isHtml'] = this.isHTML;
        feed['details'] = feed['details'].replace(/<img .*?>/g, '');
      }
      if (this._commonService && this.workerJobInfo && this.workerJobInfo.jobs && this.workerJobInfo.jobs.jobsite) {
        feed['created_at'] += ' Z';
      }
      feed['context'] = feed['context'];
      feed['profileImageUrl'] = this.getProfileImageUrl(feed);
    });
    this.activityFeeds = listData;
  }

  /**
   * Prepair worker img url
   * @param payload In this `workerPickUrl` | `profileImage`
   */
  getProfileImageUrl(feed) {
    if (this.imgpath.indexOf('localhost') !== -1) {
      this.imgpath = 'https://den.dev.serviceo.me/api/v1';
    }
    if (feed && feed.actor_name && feed.actor_name === 'auto') {
      return `assets/images/computer.png`;
    }
    if (feed && feed.context && feed.context.user && feed.context.user.userProfileUrl && feed.context.user.userProfileImage) {
      return `${this.imgpath}/Containers${feed.context.user.userProfileUrl}${feed.context.user.userProfileImage}`;
    } else {
      return `assets/images/male-circle.png`;
    }
  }

  previousPage() {
    if (this.pageNo === 1) {
      return;
    }
    this._loader.showPreloader();
    this.getActivityFeedList(this.pageNo - 1);
  }

  nextPage() {
    if (this.pageNo === this.lastPage) {
      return;
    }
    this._loader.showPreloader();
    this.getActivityFeedList(this.pageNo + 1);
  }

}
