
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { WorkerTrackerMapComponent } from './worker-tracker-map.component';
import { MessageModule } from './../message/message.module';
import { WorkerTrackerService } from './../../services/worker-tracker.service';
import { RequestFormReceiptsModule } from './../request-form-receipts/request-form-receipts.module';

@NgModule({
  imports: [
    NgbModule,
    CommonModule,
    MessageModule,
    RequestFormReceiptsModule
  ],
  exports: [WorkerTrackerMapComponent],
  declarations: [WorkerTrackerMapComponent],
  providers: [WorkerTrackerService]
})
export class WorkerTrackerMapModule { }
