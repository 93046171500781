/* tslint:disable */

declare var Object: any;
export interface JobOffersInterface {
  "sfdcId"?: string;
  "CreatedById"?: string;
  "CurrencyIsoCode"?: string;
  "LastModifiedById"?: string;
  "RecordTypeId"?: string;
  "Name"?: string;
  "ts2extams__Action_Buttons__c"?: string;
  "ts2__Address__c"?: string;
  "ts2__Benefits__c"?: string;
  "ts2__Bonus__c"?: number;
  "Candidate_Type__c"?: string;
  "ts2__Candidate__c"?: string;
  "ts2__Client__c"?: string;
  "ts2__Code__c"?: string;
  "ts2extams__Communication_Buttons__c"?: string;
  "Confirmed_Start_Date_HR_Head__c"?: Date;
  "ts2__Contact__c"?: string;
  "ts2__Client_Contact__c"?: string;
  "ts2__Contact_Email__c"?: string;
  "ts2__Contact_Phone__c"?: string;
  "ts2extams__Control_Buttons__c"?: string;
  "CreatedBy__c"?: string;
  "ts2__Date_Available__c"?: Date;
  "ts2__Email__c"?: string;
  "ts2__Experience_Summary__c"?: string;
  "ts2__HiringManager__c"?: string;
  "Hiring_Manager__c"?: string;
  "ts2__Hourly__c"?: number;
  "ts2__Job__c"?: string;
  "ts2__Job_Status__c"?: string;
  "LastModifiedBy__c"?: string;
  "ts2__Legacy_OfferID__c"?: string;
  "ts2__Meals__c"?: number;
  "ts2__Mileage__c"?: number;
  "ts2__Mobile__c"?: string;
  "Offered_Title__c"?: string;
  "ts2__Phone__c"?: string;
  "ts2__PrimaryRecruiter__c"?: string;
  "Primary_Recruiter__c"?: string;
  "ts2__Project__c"?: string;
  "Reason__c"?: string;
  "ts2__Reject_Detail__c"?: string;
  "ts2__Reject_Reason__c"?: string;
  "ts2__Related_Application__c"?: string;
  "ts2extams__Resume_Uploaded__c"?: string;
  "ts2__Salary__c"?: number;
  "ts2__SecondaryRecruiter__c"?: string;
  "Secondary_Recruiter__c"?: string;
  "ts2__Status__c"?: string;
  "ts2extams__Substatus__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class JobOffers implements JobOffersInterface {
  "sfdcId": string;
  "CreatedById": string;
  "CurrencyIsoCode": string;
  "LastModifiedById": string;
  "RecordTypeId": string;
  "Name": string;
  "ts2extams__Action_Buttons__c": string;
  "ts2__Address__c": string;
  "ts2__Benefits__c": string;
  "ts2__Bonus__c": number;
  "Candidate_Type__c": string;
  "ts2__Candidate__c": string;
  "ts2__Client__c": string;
  "ts2__Code__c": string;
  "ts2extams__Communication_Buttons__c": string;
  "Confirmed_Start_Date_HR_Head__c": Date;
  "ts2__Contact__c": string;
  "ts2__Client_Contact__c": string;
  "ts2__Contact_Email__c": string;
  "ts2__Contact_Phone__c": string;
  "ts2extams__Control_Buttons__c": string;
  "CreatedBy__c": string;
  "ts2__Date_Available__c": Date;
  "ts2__Email__c": string;
  "ts2__Experience_Summary__c": string;
  "ts2__HiringManager__c": string;
  "Hiring_Manager__c": string;
  "ts2__Hourly__c": number;
  "ts2__Job__c": string;
  "ts2__Job_Status__c": string;
  "LastModifiedBy__c": string;
  "ts2__Legacy_OfferID__c": string;
  "ts2__Meals__c": number;
  "ts2__Mileage__c": number;
  "ts2__Mobile__c": string;
  "Offered_Title__c": string;
  "ts2__Phone__c": string;
  "ts2__PrimaryRecruiter__c": string;
  "Primary_Recruiter__c": string;
  "ts2__Project__c": string;
  "Reason__c": string;
  "ts2__Reject_Detail__c": string;
  "ts2__Reject_Reason__c": string;
  "ts2__Related_Application__c": string;
  "ts2extams__Resume_Uploaded__c": string;
  "ts2__Salary__c": number;
  "ts2__SecondaryRecruiter__c": string;
  "Secondary_Recruiter__c": string;
  "ts2__Status__c": string;
  "ts2extams__Substatus__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: JobOffersInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `JobOffers`.
   */
  public static getModelName() {
    return "JobOffers";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of JobOffers for dynamic purposes.
  **/
  public static factory(data: JobOffersInterface): JobOffers{
    return new JobOffers(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'JobOffers',
      plural: 'JobOffers',
      path: 'JobOffers',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "RecordTypeId": {
          name: 'RecordTypeId',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "ts2extams__Action_Buttons__c": {
          name: 'ts2extams__Action_Buttons__c',
          type: 'string'
        },
        "ts2__Address__c": {
          name: 'ts2__Address__c',
          type: 'string'
        },
        "ts2__Benefits__c": {
          name: 'ts2__Benefits__c',
          type: 'string'
        },
        "ts2__Bonus__c": {
          name: 'ts2__Bonus__c',
          type: 'number'
        },
        "Candidate_Type__c": {
          name: 'Candidate_Type__c',
          type: 'string'
        },
        "ts2__Candidate__c": {
          name: 'ts2__Candidate__c',
          type: 'string'
        },
        "ts2__Client__c": {
          name: 'ts2__Client__c',
          type: 'string'
        },
        "ts2__Code__c": {
          name: 'ts2__Code__c',
          type: 'string'
        },
        "ts2extams__Communication_Buttons__c": {
          name: 'ts2extams__Communication_Buttons__c',
          type: 'string'
        },
        "Confirmed_Start_Date_HR_Head__c": {
          name: 'Confirmed_Start_Date_HR_Head__c',
          type: 'Date'
        },
        "ts2__Contact__c": {
          name: 'ts2__Contact__c',
          type: 'string'
        },
        "ts2__Client_Contact__c": {
          name: 'ts2__Client_Contact__c',
          type: 'string'
        },
        "ts2__Contact_Email__c": {
          name: 'ts2__Contact_Email__c',
          type: 'string'
        },
        "ts2__Contact_Phone__c": {
          name: 'ts2__Contact_Phone__c',
          type: 'string'
        },
        "ts2extams__Control_Buttons__c": {
          name: 'ts2extams__Control_Buttons__c',
          type: 'string'
        },
        "CreatedBy__c": {
          name: 'CreatedBy__c',
          type: 'string'
        },
        "ts2__Date_Available__c": {
          name: 'ts2__Date_Available__c',
          type: 'Date'
        },
        "ts2__Email__c": {
          name: 'ts2__Email__c',
          type: 'string'
        },
        "ts2__Experience_Summary__c": {
          name: 'ts2__Experience_Summary__c',
          type: 'string'
        },
        "ts2__HiringManager__c": {
          name: 'ts2__HiringManager__c',
          type: 'string'
        },
        "Hiring_Manager__c": {
          name: 'Hiring_Manager__c',
          type: 'string'
        },
        "ts2__Hourly__c": {
          name: 'ts2__Hourly__c',
          type: 'number'
        },
        "ts2__Job__c": {
          name: 'ts2__Job__c',
          type: 'string'
        },
        "ts2__Job_Status__c": {
          name: 'ts2__Job_Status__c',
          type: 'string'
        },
        "LastModifiedBy__c": {
          name: 'LastModifiedBy__c',
          type: 'string'
        },
        "ts2__Legacy_OfferID__c": {
          name: 'ts2__Legacy_OfferID__c',
          type: 'string'
        },
        "ts2__Meals__c": {
          name: 'ts2__Meals__c',
          type: 'number'
        },
        "ts2__Mileage__c": {
          name: 'ts2__Mileage__c',
          type: 'number'
        },
        "ts2__Mobile__c": {
          name: 'ts2__Mobile__c',
          type: 'string'
        },
        "Offered_Title__c": {
          name: 'Offered_Title__c',
          type: 'string'
        },
        "ts2__Phone__c": {
          name: 'ts2__Phone__c',
          type: 'string'
        },
        "ts2__PrimaryRecruiter__c": {
          name: 'ts2__PrimaryRecruiter__c',
          type: 'string'
        },
        "Primary_Recruiter__c": {
          name: 'Primary_Recruiter__c',
          type: 'string'
        },
        "ts2__Project__c": {
          name: 'ts2__Project__c',
          type: 'string'
        },
        "Reason__c": {
          name: 'Reason__c',
          type: 'string'
        },
        "ts2__Reject_Detail__c": {
          name: 'ts2__Reject_Detail__c',
          type: 'string'
        },
        "ts2__Reject_Reason__c": {
          name: 'ts2__Reject_Reason__c',
          type: 'string'
        },
        "ts2__Related_Application__c": {
          name: 'ts2__Related_Application__c',
          type: 'string'
        },
        "ts2extams__Resume_Uploaded__c": {
          name: 'ts2extams__Resume_Uploaded__c',
          type: 'string'
        },
        "ts2__Salary__c": {
          name: 'ts2__Salary__c',
          type: 'number'
        },
        "ts2__SecondaryRecruiter__c": {
          name: 'ts2__SecondaryRecruiter__c',
          type: 'string'
        },
        "Secondary_Recruiter__c": {
          name: 'Secondary_Recruiter__c',
          type: 'string'
        },
        "ts2__Status__c": {
          name: 'ts2__Status__c',
          type: 'string'
        },
        "ts2extams__Substatus__c": {
          name: 'ts2extams__Substatus__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
