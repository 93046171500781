/* tslint:disable */
import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { SDKModels } from './SDKModels';
import { BaseLoopBackApi } from '../core/base.service';
import { LoopBackConfig } from '../../lb.config';
import { LoopBackAuth } from '../core/auth.service';
import { LoopBackFilter,  } from '../../models/BaseModels';
import { ErrorHandler } from '../core/error.service';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { SMSService } from '../../models/SMSService';
import { SocketConnection } from '../../sockets/socket.connections';


/**
 * Api services for the `SMSService` model.
 */
@Injectable()
export class SMSServiceApi extends BaseLoopBackApi {

  constructor(
    @Inject(HttpClient) protected http: HttpClient,
    @Inject(SocketConnection) protected connection: SocketConnection,
    @Inject(SDKModels) protected models: SDKModels,
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
  ) {
    super(http,  connection,  models, auth, errorHandler);
  }

  /**
   * Test Send SMS
   *
   * @param {object} data Request data.
   *
   *  - `req` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `SMSService` object.)
   * </em>
   */
  public testSMSSend(req: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/SMSServices/testSMSSend";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof req !== 'undefined' && req !== null) _urlParams.req = req;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Test Number Validity
   *
   * @param {object} data Request data.
   *
   *  - `req` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `SMSService` object.)
   * </em>
   */
  public testNumberValidity(req: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/SMSServices/testNumberValidity";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof req !== 'undefined' && req !== null) _urlParams.req = req;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Test Number Validity
   *
   * @param {object} data Request data.
   *
   *  - `req` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `SMSService` object.)
   * </em>
   */
  public requestPhoneVerification(req: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/SMSServices/requestPhoneVerification";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof req !== 'undefined' && req !== null) _urlParams.req = req;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Test Number Validity
   *
   * @param {object} data Request data.
   *
   *  - `req` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `SMSService` object.)
   * </em>
   */
  public verifyPhoneToken(req: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/SMSServices/verifyPhoneToken";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof req !== 'undefined' && req !== null) _urlParams.req = req;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Update SMS Status from twilio callback
   *
   * @param {object} data Request data.
   *
   *  - `req` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `SMSService` object.)
   * </em>
   */
  public smsStatus(req: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/SMSServices/smsStatus";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof req !== 'undefined' && req !== null) _urlParams.req = req;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * send sms messsage to salesforce
   *
   * @param {object} data Request data.
   *
   *  - `phoneNumber` – `{string}` - 
   *
   *  - `message` – `{string}` - 
   *
   *  - `messageType` – `{string}` - 
   *
   *  - `linkUrl` – `{string}` - 
   *
   *  - `acceptUrl` – `{string}` - 
   *
   *  - `rejectUrl` – `{string}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `SMSService` object.)
   * </em>
   */
  public sendSMSMessageSF(phoneNumber: any, message: any, messageType: any, linkUrl: any = {}, acceptUrl: any = {}, rejectUrl: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/SMSServices/sendSMSMessageSF";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof phoneNumber !== 'undefined' && phoneNumber !== null) _urlParams.phoneNumber = phoneNumber;
    if (typeof message !== 'undefined' && message !== null) _urlParams.message = message;
    if (typeof messageType !== 'undefined' && messageType !== null) _urlParams.messageType = messageType;
    if (typeof linkUrl !== 'undefined' && linkUrl !== null) _urlParams.linkUrl = linkUrl;
    if (typeof acceptUrl !== 'undefined' && acceptUrl !== null) _urlParams.acceptUrl = acceptUrl;
    if (typeof rejectUrl !== 'undefined' && rejectUrl !== null) _urlParams.rejectUrl = rejectUrl;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * send sms messsage to salesforce
   *
   * @param {object} data Request data.
   *
   *  - `phoneNumbers` – `{string}` - 
   *
   *  - `message` – `{string}` - 
   *
   *  - `messageType` – `{string}` - 
   *
   *  - `linkUrl` – `{string}` - 
   *
   *  - `acceptUrl` – `{string}` - 
   *
   *  - `rejectUrl` – `{string}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `SMSService` object.)
   * </em>
   */
  public sendMultipleSMSMessageSF(phoneNumbers: any, message: any, messageType: any, linkUrl: any = {}, acceptUrl: any = {}, rejectUrl: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/SMSServices/sendMultipleSMSMessageSF";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof phoneNumbers !== 'undefined' && phoneNumbers !== null) _urlParams.phoneNumbers = phoneNumbers;
    if (typeof message !== 'undefined' && message !== null) _urlParams.message = message;
    if (typeof messageType !== 'undefined' && messageType !== null) _urlParams.messageType = messageType;
    if (typeof linkUrl !== 'undefined' && linkUrl !== null) _urlParams.linkUrl = linkUrl;
    if (typeof acceptUrl !== 'undefined' && acceptUrl !== null) _urlParams.acceptUrl = acceptUrl;
    if (typeof rejectUrl !== 'undefined' && rejectUrl !== null) _urlParams.rejectUrl = rejectUrl;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `SMSService`.
   */
  public getModelName() {
    return "SMSService";
  }
}
