/* tslint:disable */

declare var Object: any;
export interface QuestionsInterface {
  "sfdcId"?: string;
  "ts2__Legacy_QuestionID__c"?: string;
  "ts2__IsMulti__c"?: boolean;
  "ts2__Optional__c"?: boolean;
  "ts2__Order__c"?: number;
  "ts2__Question__c"?: string;
  "ts2__Question_Set__c"?: string;
  "ts2__Question_Size__c"?: string;
  "ts2__Rich_Text_Question__c"?: string;
  "ts2__IsTxt__c"?: boolean;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class Questions implements QuestionsInterface {
  "sfdcId": string;
  "ts2__Legacy_QuestionID__c": string;
  "ts2__IsMulti__c": boolean;
  "ts2__Optional__c": boolean;
  "ts2__Order__c": number;
  "ts2__Question__c": string;
  "ts2__Question_Set__c": string;
  "ts2__Question_Size__c": string;
  "ts2__Rich_Text_Question__c": string;
  "ts2__IsTxt__c": boolean;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: QuestionsInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Questions`.
   */
  public static getModelName() {
    return "Questions";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Questions for dynamic purposes.
  **/
  public static factory(data: QuestionsInterface): Questions{
    return new Questions(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Questions',
      plural: 'Questions',
      path: 'Questions',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "ts2__Legacy_QuestionID__c": {
          name: 'ts2__Legacy_QuestionID__c',
          type: 'string'
        },
        "ts2__IsMulti__c": {
          name: 'ts2__IsMulti__c',
          type: 'boolean'
        },
        "ts2__Optional__c": {
          name: 'ts2__Optional__c',
          type: 'boolean'
        },
        "ts2__Order__c": {
          name: 'ts2__Order__c',
          type: 'number'
        },
        "ts2__Question__c": {
          name: 'ts2__Question__c',
          type: 'string'
        },
        "ts2__Question_Set__c": {
          name: 'ts2__Question_Set__c',
          type: 'string'
        },
        "ts2__Question_Size__c": {
          name: 'ts2__Question_Size__c',
          type: 'string'
        },
        "ts2__Rich_Text_Question__c": {
          name: 'ts2__Rich_Text_Question__c',
          type: 'string'
        },
        "ts2__IsTxt__c": {
          name: 'ts2__IsTxt__c',
          type: 'boolean'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
