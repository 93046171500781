import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-user-deduplication-table',
  templateUrl: './user-deduplication-table.component.html',
  styleUrls: ['./user-deduplication-table.component.css']
})
export class UserDeduplicationTableComponent implements OnInit {

  @Input() duplicateWorkerIds;

  @Output() closeModal: EventEmitter<any> = new EventEmitter;
  @Output() continueToSave: EventEmitter<any> = new EventEmitter;

  constructor() { }

  ngOnInit() {
  }

  continueToSaveTrigger() {
    this.continueToSave.next(true);
  }
  closeModalTrigger() {
    this.closeModal.next();
  }

}
