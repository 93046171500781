import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as StaticData from 'shared/models/static-list-data.service';

@Component({
  selector: 'app-static-list-lookup',
  templateUrl: './static-list-lookup.component.html',
  styleUrls: ['./static-list-lookup.component.css']
})
export class StaticListLookupComponent implements OnInit {
  maxSelectedItems = 20;
  @Input() titleTextShow = false;
  @Input() set resetData(isClear) {
    if (isClear) {
      this.valueSelected = [];
    }
  }

  @Input() set myGlobalTaskInbox(event) {
    if (event) {
      this.maxSelectedItems = 1;
    }
  }
  @Input() formName;

  @Input() filterListName;
  @Input() label = 'label';
  @Input() value = 'value';
  @Input() isDisable = 'false';
  @Input() dropdownPosition = 'below';
  @Input() filterObjValue;
  @Input() placeholder;
  @Input() isMultipleSelected = true;
  @Input() boxedLayout = false;
  @Input() notInGlobalFilter = false;
  @Input() set preSelected(val) { 
    this.valueSelected = val;
  };
  @Input() clearable = true;
  @Input() isEdit: boolean = false;
  @Output() getValueObj: EventEmitter<any> = new EventEmitter;

  dataList = [];
  valueSelected = [];

  constructor() { }

  ngOnInit() {
    this.dataList = this.isEdit ? [...StaticData[this.filterListName]].filter(data => Boolean(data.value)) : [...StaticData[this.filterListName]];
    if(!this.notInGlobalFilter) {
      const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
      if (this.isMultipleSelected) {
        this.valueSelected = (preselected && preselected[this.filterObjValue]) ? preselected[this.filterObjValue] : '';
      } else if (this.formName) {
        this.valueSelected = this.valueSelected;
      } else {
        this.valueSelected = (preselected && preselected[this.filterObjValue]) ? preselected[this.filterObjValue][0] : '';
      }
    }    
  }

  onValueChange() {
    this.getValueObj.emit(this.valueSelected);
  }

  clearValues() {
    this.valueSelected = [];
    this.getValueObj.emit([]);
  }

}
