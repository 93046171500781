import { ActivatedRoute } from '@angular/router';
import { AlertService } from './../../../../services/alert.service';
import { GlobalFilterService } from './../../../../services/global-filter.service';
import { FavoriteApi } from './../../../../sdk/services/custom/Favorite';
import { MessageService } from '../../../../services/message.service';
import { AppStateService } from '../../../../services/app-state.service';
import { CommentApi } from '../../../../sdk/services/custom/Comment';
import { CommonService } from 'shared/services/common.service';
import { PreloaderService } from '../../../../services/preloader.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { hiddenGlobalFiltersWo } from 'shared/models/static-list-data.service';
import { MessageSocketService } from 'shared/services/message-socket.service'
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'app-specific-global-chat',
  templateUrl: './specific-global-chat.component.html',
  styleUrls: ['./specific-global-chat.component.css'],
})
export class SpecificGlobalChatComponent implements OnInit, OnDestroy {
  type: string;
  filterObj: any = {};
  createdAt = null;
  timerSub: any;
  filterCondition: boolean;
  limit = 100;
  accessType: any;
  listData: any[] = [];
  pinnedData: any[] = [];
  activeId: any;
  errorMessage: string;
  isLoadMore: boolean;
  defaultToggleView = true; // default 5 line
  refreshFavList: boolean;
  selectedItem: any;
  selectedWorkorder: {};
  visibleMenuOptions = ['work-order', 'job-status', 'filter'];
  currentMenuOption = 'work-order';
  sidebarMenuOpened = false;
  PINNED_MESSAGE_ID: string;
  teamFilter: any;
  selectedIndex: number;
  activeTab = 'caseMessageTab';
  enableFilters = [
    'dateOptions',
    'accounts',
    'customers',
    'programs',
    'jobSites',
    'regions',
    'caseTypes',
    'statgeStatus',
    'priority',
    'pmsCaseStatus',
    'pmsCaseNumbers',
    'pmcCaseNumbers'
  ]
  autoSelected = true;
  currentTab: string;
  private messageSubscription: Subscription;

  private _gotNewMessagesFromSocket = false;
  private _ticker;

  constructor(
    private _loader: PreloaderService,
    private _commonService: CommonService,
    private _commentApi: CommentApi,
    private _appState: AppStateService,
    private _messageService: MessageService,
    private _favoriteApi: FavoriteApi,
    private _globalFilters: GlobalFilterService,
    private _alertService: AlertService,
    private activateRoute: ActivatedRoute,
    private messageSocketService: MessageSocketService
  ) { }

  initInterval() {
    let that = this;
    if(!this._ticker){
      this._ticker = setInterval(function () {
        // your code below
        if(that._gotNewMessagesFromSocket){
          that.loadListData();
          that._gotNewMessagesFromSocket = false;
        }
      }, 120000);
    }
  }

  ngOnInit() {
    this.initInterval();
    this.accessType = this._appState.getAccessType();
    this.activateRoute.params.subscribe(params => {
      this.defaultToggleView = true;
      // this._messageService.setMsgViewType(this.defaultToggleView);
      this.teamFilter = params['name'];
      if(this.teamFilter === 'pmc') {
          this._commonService.saveCurrentPageAnalytics('Global Chat - Program Console', 'Program (PMC) Console')
      } else {
       this._commonService.saveCurrentPageAnalytics('Global Chat - Billing (SO/PO) Console', 'Billing (SO/PO) Console')
      }

      this.currentMenuOption = 'work-order';
      this.PINNED_MESSAGE_ID = this.teamFilter === 'pmc' ? 'PINNED_PMC_MESSAGE_ID' : (this.teamFilter === 'billing' ?
        'PINNED_BILLING_MESSAGE_ID' : 'PINNED_ICC_MESSAGE_ID');
      if (this.teamFilter === 'billing') {
        this.enableFilters = [
          'dateOptions',
          'accounts',
          'customers',
          'vendorAccounts',
          'programs',
          'jobSites',
          'statgeStatus',
          'talentTypes',
          'jobs',
          'jobStatus',
          'jobTypes',
          'vendorSites',
          'caseCategory',
          'priority',
          'geoMetro',
          'workerNames',
          'pmsCaseStatus',
          'pmsCaseNumbers',
          //'teamShifts'
        ];
      }
      // to load already selected filters from storage
      this.filterCondition = this._commonService.getGlobalFilter(this.enableFilters);
      if (this.filterCondition['Customer__c']) {
        if (this.filterCondition['CKSW_BASE__Account__c']) {
          this.filterCondition['CKSW_BASE__Account__c']['inq'] = this.filterCondition['CKSW_BASE__Account__c']['inq'].concat(this.filterCondition['Customer__c']['inq']);
        } else {
          this.filterCondition['CKSW_BASE__Account__c'] = this.filterCondition['Customer__c'];
        }
        delete this.filterCondition['Customer__c'];
      }
      this.loadListData();
      this.getRealtimeMessage();
    });
  }

  loadListData() {
    // if (this.type === 'favourite') {
    //   this.filterObj['listType'] = 'FavJob';
    // }
    this.createdAt = '';
    this.unsubscribeTimer();
    delete this.filterObj['createdAt'];
    this._loader.showPreloader();
    this.subscribeJob(0, false);
    /*
    this.timerSub = Observable.timer(0, 30000).subscribe(() => {
      this.subscribeJob(0, false);
    });
    */
  }

  /**load Job message console list data */
  subscribeJob(skip, loadMore) {
    if (this.createdAt && !loadMore) {
      this.filterObj['createdAt'] = { gte: this.createdAt };
      // this._messageService.setCountCall(true);
    } else {
      this._loader.showPreloader();
    }
    if(this.teamFilter === 'escalations'){
      delete this.filterObj['modelName'];
      delete this.filterObj['commentType'];
      this.filterObj['listType'] = 'Escalation';

    }else{
      delete this.filterObj['listType'];
      this.filterObj['modelName'] = 'Job';
      
    }
    
    if (
      this.filterCondition &&
      this.filterCondition['Iron_Case_Number__c'] !== undefined
    ) {
      this.filterObj['Iron_Case_Number__c'] = this.filterCondition[
        'Iron_Case_Number__c'
      ];
    } else {
      delete this.filterObj['Iron_Case_Number__c'];
    }

    if (this.teamFilter === 'pmc' || this.teamFilter === 'billing' || this.teamFilter === 'icc') {
      this.filterObj['commentType'] = this.teamFilter;
    }

    const filters = {
      where: this.filterObj,
      limit: this.limit,
      skip: skip,
      enableWorkOrderChat: this.teamFilter !== 'billing',
      enableChatRoom: true
    };
    if (this.filterCondition && Object.keys(this.filterCondition).length) {
      delete this.filterCondition['Iron_Case_Number__c'];
      const preFilterObj = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : {};
      if (this.filterCondition['Jobsite__c'] && this.filterCondition['Jobsite__c']['inq'] &&
        preFilterObj['geoMetroJobSite'] && preFilterObj['geoMetroJobSite'].length && !this.enableFilters.includes('geoMetro')) {
        let jobSite = this.filterCondition['Jobsite__c']['inq'].filter(Boolean).filter(element => !preFilterObj['geoMetroJobSite'].includes(element));
        const localFilterCondition = this.filterCondition;
        if (jobSite && jobSite.length) {
          localFilterCondition['Jobsite__c']['inq'] = jobSite;
        } else {
          delete localFilterCondition['Jobsite__c'];
        }
        filters['jobFilter'] = localFilterCondition;
      } else {
        filters['jobFilter'] = this.filterCondition;
      }
    }
    // if (this.type === 'favourite') {
    //   delete filters['jobFilter'];
    // }

    this._commentApi.getDistinctByType(filters).subscribe(
      (_data) => {
        if (this.filterObj['createdAt']) {
          this.unshiftDataInList(_data);
        } else {
          this.listData = [..._data];
          
          if (this.autoSelected) {
            this.selectFirstRecord();
          } else if(!this.autoSelected) {
             // get new index selected active id
            try {
              const index = this.listData.findIndex(item => item._id === this.activeId);  
              this.selectedIndex = index; //selectedIndex
            } catch (error) {
              console.log('Error while getting index of current selected element in list ', error)
            }
          }

          if (
            this.filterCondition &&
            Object.keys(this.filterCondition).length === 1 &&
            this.filterCondition['Iron_Job_num__c']
          ) {
            this.isLoadMore = false;
          } else {
            // this.isLoadMore = _data.length < this.limit ? false : true;
            this.isLoadMore = _data.length > 0 ? true : false;
          }
          // if (this.type === 'all') {
          this.removePinnedRecords();
          // }
        }
        this.createdAt = new Date();
        this._loader.hidePreloader();
      },
      (err) => {
        this._loader.hidePreloader();
      }
    );
  }

  unshiftDataInList(_data, isLoadMore?) {
    _data.forEach((_newItem) => {
      let index;
      if (
        !this.listData.some((item, i) => {
          index = i;
          return item.modelId === _newItem.modelId;
        })
      ) {
        let jobFoundInPinned = false;
        this.pinnedData.forEach((pinnedItem) => {
          if (
            pinnedItem.modelId === _newItem.modelId &&
            pinnedItem.modelName === _newItem.modelName
          ) {
            pinnedItem['last_postByName'] = _newItem['last_postByName'];
            pinnedItem['last_createdAt'] = _newItem['last_createdAt'];
            pinnedItem['last_message'] = _newItem['last_message'];
            pinnedItem['count'] += _newItem['count'];
            pinnedItem['unread_comments'] += _newItem['unread_comments'];
            jobFoundInPinned = true;
            this._messageService.setNewMessageJob(_newItem);
          }
        });
        if (!jobFoundInPinned) {
          if (isLoadMore) {
            this.listData.push(_newItem);
          } else {
            this.listData.unshift(_newItem);
          }
        }
      } else {
        this.listData[index]['last_postByName'] = _newItem['last_postByName'];
        this.listData[index]['last_createdAt'] = _newItem['last_createdAt'];
        this.listData[index]['last_message'] = _newItem['last_message'];
        this.listData[index]['count'] += _newItem['count'];
        this.listData[index]['unread_comments'] += _newItem['unread_comments'];
        this._messageService.setNewMessageJob(_newItem);
      }
    });
  }

  /**
   * select first item of list
   */
  selectFirstRecord() {
    if (this.listData.length) {
      this.activeId = this.listData[0]._id;
      this.selectedIndex = 0; //selectedIndex
      this.listData[0].unread_comments = 0;

      this.listData[0]['teamFilter'] = this.teamFilter ? this.teamFilter : null;
      this.selectedItem = this.listData[0];
      this.onClick(null, this.selectedItem, 'list');
    } else {
      this.selectedItem = {};
      this.selectedWorkorder = {};
      this.errorMessage = 'No message found';
    }
  }

  /**
   * Change list view
   */
  toggleListView() {
    this.defaultToggleView = !this.defaultToggleView;
    // this._messageService.setMsgViewType(this.defaultToggleView);
  }

  /**
   *
   * @param e load more data
   */
  onloadMoreChange(e) {
    this._loader.showPreloader();
    delete this.filterObj['createdAt'];
    this.filterObj['modelName'] = 'Job';
    if (
      this.filterCondition &&
      this.filterCondition['Iron_Case_Number__c'] !== undefined
    ) {
      this.filterObj['Iron_Case_Number__c'] = this.filterCondition[
        'Iron_Case_Number__c'
      ];
    } else {
      delete this.filterObj['Iron_Case_Number__c'];
    }
    const filters = {
      where: this.filterObj,
      limit: this.limit,
      skip: this.listData.length,
      enableWorkOrderChat: this.teamFilter !== 'billing',
      enableChatRoom: true
    };
    if (this.filterCondition && Object.keys(this.filterCondition).length) {
      delete this.filterCondition['Iron_Case_Number__c'];
      filters['jobFilter'] = this.filterCondition;
    }
    // if (this.type === 'favourite') {
    //   delete filters['jobFilter'];
    // }
    this._commentApi.getDistinctByType(filters).subscribe(
      (_data) => {
        this.unshiftDataInList(_data, true);
        this._loader.hidePreloader();
      },
      (err) => {
        this._loader.hidePreloader();
      }
    );
  }

  /**
   *
   * @param e update fav object
   */
  handleFavChange(e) {
    if (e.favoriteId) {
      this.deleteFavorite(e);
    } else {
      this.addFavorite(e);
    }
  }

  addFavorite(item) {
    this._loader.showPreloader();
    this._favoriteApi
      .upsert({
        modelId: item.modelId,
        modelName: item.modelName,
      })
      .subscribe(
        (result) => {
          this._loader.hidePreloader();
          if (result && result['id']) {
            item.favoriteId = result['id'];
          }
          // this.getCount(['FaviorateAll', 'FaviorateJob']);
        },
        (err) => {
          this._loader.hidePreloader();
        }
      );
  }

  deleteFavorite(item) {
    this._loader.showPreloader();
    this.refreshFavList = false;
    this._favoriteApi.deleteById(item.favoriteId).subscribe(
      (result) => {
        this._loader.hidePreloader();
        if (result && result['count']) {
          item.favoriteId = '';
          this.refreshFavList = true;
        }
        // this.getCount(['FaviorateAll', 'FaviorateJob']);
      },
      (err) => {
        this._loader.hidePreloader();
      }
    );
  }

  onClick(event, item, button) {
    if (button === 'fav') {
      this.handleFavChange(event);
    } else {
      this.activeId = item._id;
      item.unread_comments = 0;
      this.selectedWorkorder = {};
      this.selectedIndex = this.getIndexOfSelected(this.activeId); 
      // this.showFilter = false;
      // this.showWorkOrder = (this.accessType === 'internal') ? true : false; // default workorder case of internal
      // this.showJobDetail = (this.accessType === 'internal') ? false : true;

      if (item.job && item.job.Work_Order_Number) {
        item['workOrder'] = { sfdcId: item.job.Work_Order_Number };
      }
      item['teamFilter'] = this.teamFilter ? this.teamFilter : null;
      this.selectedItem = item;
      if (item.workOrder && item.workOrder.sfdcId) {
        this.selectedWorkorder = { modelId: item.workOrder.sfdcId, modelName: 'WorkOrder' };
      }
    }
  }

  onTabChange(tab) {
    this.activeTab = tab.nextId;
    const filterIndex = this.enableFilters.findIndex(filter => filter == 'regions');
    this.currentMenuOption = 'work-order';
    if (tab.nextId === 'workOrderTab') {
      this.unsubscribeTimer();
      this.currentTab = 'workOrderTab';
      this.selectedItem = {};
      this.selectedWorkorder = {};
      if(this.enableFilters[filterIndex])
        this.enableFilters.splice(filterIndex, 1);
    } else {
      if(!this.enableFilters[filterIndex])
        this.enableFilters.push('regions');
      this.currentTab = 'caseMessageTab';
      this.loadListData();
    }
  }

  isRightMenuOpened(isOpened) {
    this.sidebarMenuOpened = isOpened;
    this._globalFilters.setSidebarState(isOpened);
  }

  searchCaseMessages(filterObj) {
    this.filterCondition = filterObj;
    if (this.filterCondition['Customer__c']) {
      if (this.filterCondition['CKSW_BASE__Account__c']) {
        this.filterCondition['CKSW_BASE__Account__c']['inq'] = this.filterCondition['CKSW_BASE__Account__c']['inq'].concat(this.filterCondition['Customer__c']['inq']);
      } else {
        this.filterCondition['CKSW_BASE__Account__c'] = this.filterCondition['Customer__c'];
      }
      delete this.filterCondition['Customer__c'];
    }
    if (this.currentTab !== 'workOrderTab') {
      this.loadListData();
    }
  }

  /**
  * Pin or unpin action has been performed
  */
  onPinAction(jobData) {
    // if (this.type !== 'all') {
    //   return;
    // }
    const pinnedLocalData = localStorage.getItem(this.PINNED_MESSAGE_ID) ? JSON.parse(localStorage.getItem(this.PINNED_MESSAGE_ID)) : [];
    let alreadyPinnedData = false;
    pinnedLocalData.forEach((item, index) => {
      if (jobData && (item.id === jobData.modelId && item.modelName === jobData.modelName)) {
        pinnedLocalData.splice(index, 1);
        this.pinnedData.splice(this.pinnedData.indexOf(jobData), 1);
        localStorage.setItem(this.PINNED_MESSAGE_ID, JSON.stringify(pinnedLocalData));
        alreadyPinnedData = true;
      }
    })
    if (alreadyPinnedData) {
      if (Object.keys(this.filterCondition).length) {
        delete this.filterObj['createdAt'];
        this.createdAt = null;
        this.subscribeJob(0, false);
      } else {
        this.listData.push(jobData);
        this.sortListData();
      }
      return;
    }
    if (pinnedLocalData && pinnedLocalData.length === 2) {
      return this._alertService.error('You can not pin more than two items.');
    }

    const data = {
      id: jobData.modelId,
      modelName: jobData.modelName
    }

    if (jobData.job) {
      data['Case__c'] = jobData.job.Case__c;
      data['PMC_Case_Number'] = jobData.job.PMC_Case_Number;
    } else if (jobData.workOrder) {
      data['Case__c'] = jobData.Case__c;
      data['PMC_Case_Number'] = jobData.workOrder.JPC_Case__c;
    }
    pinnedLocalData.push(data)
    localStorage.setItem(this.PINNED_MESSAGE_ID, JSON.stringify(pinnedLocalData));
    const pinnedData = this.listData.splice(this.listData.findIndex(item =>
      (item.modelId === jobData.modelId && item.modelName === jobData.modelName)), 1);
    if (pinnedData && pinnedData.length) {
      this.pinnedData.unshift(pinnedData[0]);
    }
  }

  /**
   * Get the data of pinned jobs
   */
  getPinnedJobs() {
    const localStoragePinnedData = JSON.parse(localStorage.getItem(this.PINNED_MESSAGE_ID));
    const case_c = [];
    localStoragePinnedData.forEach(item => {
      case_c.push(item.Case__c);
      if (item.PMC_Case_Number) {
        case_c.push(item.PMC_Case_Number);
      }
    })
    const filters = {
      where: {
        'Case__c': { '$in': case_c }
      },
      limit: this.limit,
      skip: 0,
      enableWorkOrderChat: this.teamFilter !== 'billing',
      enableChatRoom: true
    };
    this._commentApi.getDistinctByType(filters).subscribe(_data => {
      this.pinnedData = _data;
    }, err => {
      console.log(err);
    })
  }

  /**
  * Remove all the pinned messages from current message list and push them in pinned data list
  */
  removePinnedRecords() {
    this.pinnedData = [];
    if (localStorage.getItem(this.PINNED_MESSAGE_ID)) {
      const localStoragePinnedData = JSON.parse(localStorage.getItem(this.PINNED_MESSAGE_ID));
      localStoragePinnedData.forEach(item => {
        this.listData.forEach((data, index) => {
          if (data && (data.modelId === item.id && data.modelName === item.modelName)) {
            const pinnedData = this.listData.splice(index, 1);
            if (pinnedData && pinnedData.length) {
              this.pinnedData.unshift(pinnedData[0]);
            }
          }
        })
      })
      if (this.pinnedData.length !== localStoragePinnedData.length) {
        this.getPinnedJobs();
      }
    }
  }

  sortListData() {
    this.listData.sort((a, b) => {
      const bDateTime = new Date(Date.parse(b.last_createdAt));
      const aDateTime = new Date(Date.parse(a.last_createdAt));
      return bDateTime.getTime() - aDateTime.getTime();
    })
  }

  unsubscribeTimer() {
    if (this.timerSub) {
      this.timerSub.unsubscribe();
    }
  }

  getRealtimeMessage() {    
    // console.log('Fetching the message from socket.');
    let that = this;
    this.messageSubscription = this._messageService.getNewMessageJob().subscribe(result => {      
      // const date = new Date(result.payload.createdAt)
      // date.setSeconds(date.getSeconds() - 1)
      // this.createdAt = date.toISOString(); // add message created date 
      // this.subscribeJob(0, false);
      result = result[0];
      console.log('++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++');
      this._commonService.logger('RESULT HHHHHHHHHHHHH', result);
      this.selectedIndex = this.getIndexOfSelected(this.activeId);
      const filterMatched = this.checkifDataFilterMatch(result);
      let MessageIndex = this.checkIfCardExists(result);
      
      if(MessageIndex != null && filterMatched) {
        this.updateListData(MessageIndex, result);
        this.sortListData();
      } else if(MessageIndex == null && filterMatched) {
        this.autoSelected = false;
        // this.loadListData();
        that._gotNewMessagesFromSocket = true;
        this._commonService.logger('INDEX NOT FOUND BUT FILTER MATCHED.');
      } 
      
    })
  }

  // check if list has the card for incoming message.
  checkIfCardExists(incomingMessage) {
    let indexOfCard = null;
    this.listData.filter(function (e, i) {
      if( typeof e.context !== 'undefined' && e.context !== null) {
        if (typeof incomingMessage.context.jobId !== 'undefined' && typeof e.context.jobId !== 'undefined' && incomingMessage.context.jobId !== null && e.context.jobId === incomingMessage.context.jobId) {
          indexOfCard = i;
        } else if (
          typeof e.context.workOrderId !== 'undefined' &&
          typeof incomingMessage.context.workOrderId !== 'undefined' && 
          e.context.workOrderId === incomingMessage.context.workOrderId) {
          indexOfCard = i;
        }
      } 
      if (e.modelName == incomingMessage.modelName && e.modelId == incomingMessage.modelId) {
        indexOfCard = i;
      }
    });
    return indexOfCard;
  }
  getIndexOfSelected(id) {
    let index = null;
    this.listData.filter(function (e, i) {
      if(e._id === id){
        index = i;
      }
    });
    return index;
  }
  updateListData(index, data) {
    this.listData[index]['last_message'] = data.Case_Comment_Body__c;
    this.listData[index]['last_postByName'] = data.postByName;
    this.listData[index]['last_postByName'] = data.postByName;
    this.listData[index]['count'] = this.listData[index]['count'] + 1;
    // console.log("Active card ID, ", this.selectedIndex, ' :: INDEX :: ', index);
    if(this.selectedIndex !== index) {
      this.listData[index]['unread_comments'] = this.listData[index]['unread_comments'] + 1;
    }
    this.listData[index]['last_createdAt'] = data.createdAt;
  }
  checkifDataFilterMatch(data) {
    let filterMatched = true;
    const messageFilter = data.filters;
    console.log(this.filterObj);
    if (Object.keys(this.filterObj).length) {
      // CreatedAt Filter
      if (typeof this.filterObj.CreatedDate !== 'undefined') {
        filterMatched = this.checkDateInRange(this.filterObj.CreatedDate.between, data.createdAt)
      }
      // Partner Account filter
      if (typeof this.filterObj.CKSW_BASE__Account__c !== 'undefined') {
        filterMatched = this.filterObj.CKSW_BASE__Account__c.inq.includes(messageFilter.partnerAccount);
      }
      // Program filter 
      if (typeof this.filterObj.Project_SOP__c !== 'undefined') {
        filterMatched = this.filterObj.Project_SOP__c.inq.includes(messageFilter.projectId);
      }
      // Jobsite Location filter 
      if (typeof this.filterObj.Jobsite__c !== 'undefined') {
        filterMatched = this.filterObj.Jobsite__c.inq.includes(data.jobSiteId);
      }
      // Priority 
      if (typeof this.filterObj.Service_Dispatch_SLA_Priority__c !== 'undefined') {
        filterMatched = this.filterObj.Service_Dispatch_SLA_Priority__c.inq.includes(messageFilter.Service_Dispatch_SLA_Priority__c);
      }
      // PMS Case status
      if (typeof this.filterObj.PMS_Case_Status !== 'undefined') {
        filterMatched = this.filterObj.PMS_Case_Status.inq.includes(messageFilter.PMSCaseStatus);
      }
      // PMS Case number
      if (typeof this.filterObj.PMS_Case_Number !== 'undefined') {
        filterMatched = this.filterObj.PMS_Case_Number.inq.includes(messageFilter.PMS_Case_Number);
      }
      // PMC Case number
      if (typeof this.filterObj.PMC_Case_Number !== 'undefined') {
        filterMatched = this.filterObj.PMC_Case_Number.inq.includes(messageFilter.PMS_Case_Number);
      }
      // JOB filters

      // Vendor filter
      if (typeof this.filterObj.Vendor__c !== 'undefined') {
        filterMatched = this.filterObj.Vendor__c.inq.includes(messageFilter.Vendor__c);
      }
      // Stage status
      if (typeof this.filterObj.Dispatch_Service_Resolution_Status__c !== 'undefined') {
        filterMatched = this.filterObj.Dispatch_Service_Resolution_Status__c.inq.includes(messageFilter.Dispatch_Service_Resolution_Status__c);
      }
      //region filter
      if (typeof this.filterObj.Region !== 'undefined') {
        filterMatched = this.filterObj.Region.inq.includes(messageFilter.Region);
      }
      // Talent_type
      if (typeof this.filterObj.Talent_Type__c !== 'undefined') {
        filterMatched = this.filterObj.Talent_Type__c.inq.includes(messageFilter.Talent_Type__c);
      }
      // JOB number
      if (typeof this.filterObj.Iron_Job_num__c !== 'undefined') {
        filterMatched = this.filterObj.Iron_Job_num__c.inq.includes(messageFilter.Iron_Job_num__c);
      }
      // job status
      if (typeof this.filterObj.Job_Status_Internal__c !== 'undefined') {
        filterMatched = this.filterObj.Job_Status_Internal__c.inq.includes(messageFilter.Job_Status_Internal__c);
      }
      // Vendor site
      if (typeof this.filterObj.Vendorsite__c !== 'undefined') {
        filterMatched = this.filterObj.Vendorsite__c.inq.includes(messageFilter.Vendorsite__c);
      }
      // Priority JOB
      if (typeof this.filterObj.Service_Dispatch_SLA_Priority__c !== 'undefined') {
        filterMatched = this.filterObj.Service_Dispatch_SLA_Priority__c.inq.includes(messageFilter.Service_Dispatch_SLA_Priority__c);
      }
      // Geo metro
      if (typeof this.filterObj.Jobsite__c !== 'undefined') {
        filterMatched = this.filterObj.Jobsite__c.inq.includes(messageFilter.Jobsite__c);
      }
      // Worker 
      if (typeof this.filterObj.Dispatch_Worker_Name__c !== 'undefined') {
        filterMatched = this.filterObj.Dispatch_Worker_Name__c.inq.includes(messageFilter.Dispatch_Worker_Name__c);
      }
      // pmc window
      if (typeof this.filterObj.commentType !== 'undefined' && this.filterObj.commentType === 'pmc') {
        filterMatched = data.commentType === 'pmc' ? true : false;
      }
      // billing window
      if (typeof this.filterObj.commentType !== 'undefined' && this.filterObj.commentType === 'billing') {
        filterMatched = data.commentType === 'billing' ? true : false;
      }

    }
    console.log('Has filter matched ', filterMatched);
    return filterMatched;
  }

  checkDateInRange(range, date) {
    const minDate = new Date(range[0]);
    const maxDate = new Date(range[1]);
    date = new Date(date);
    if (date > minDate && date < maxDate) {
      return true;
    }
    return true;
  }

  ngOnDestroy() {
    if(this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }
    if(this._ticker){
      clearInterval(this._ticker);
    }
  }

}
