/* tslint:disable */

declare var Object: any;
export interface PayslipManagerInterface {
  "sfdcId"?: string;
  "Contractor__c"?: string;
  "Average_Rate__c"?: string;
  "Start__c"?: Date;
  "Ending__c"?: Date;
  "Payment_Date__c"?: Date;
  "First_Name__c"?: string;
  "Last_Name__c"?: string;
  "Name"?: string;
  "Hours__c"?: number;
  "Rate__c"?: number;
  "Adjustment_Amount__c"?: number;
  "Total__c"?: number;
  "Wire__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class PayslipManager implements PayslipManagerInterface {
  "sfdcId": string;
  "Contractor__c": string;
  "Average_Rate__c": string;
  "Start__c": Date;
  "Ending__c": Date;
  "Payment_Date__c": Date;
  "First_Name__c": string;
  "Last_Name__c": string;
  "Name": string;
  "Hours__c": number;
  "Rate__c": number;
  "Adjustment_Amount__c": number;
  "Total__c": number;
  "Wire__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: PayslipManagerInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `PayslipManager`.
   */
  public static getModelName() {
    return "PayslipManager";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of PayslipManager for dynamic purposes.
  **/
  public static factory(data: PayslipManagerInterface): PayslipManager{
    return new PayslipManager(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'PayslipManager',
      plural: 'PayslipManagers',
      path: 'PayslipManagers',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "Contractor__c": {
          name: 'Contractor__c',
          type: 'string'
        },
        "Average_Rate__c": {
          name: 'Average_Rate__c',
          type: 'string'
        },
        "Start__c": {
          name: 'Start__c',
          type: 'Date'
        },
        "Ending__c": {
          name: 'Ending__c',
          type: 'Date'
        },
        "Payment_Date__c": {
          name: 'Payment_Date__c',
          type: 'Date'
        },
        "First_Name__c": {
          name: 'First_Name__c',
          type: 'string'
        },
        "Last_Name__c": {
          name: 'Last_Name__c',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "Hours__c": {
          name: 'Hours__c',
          type: 'number'
        },
        "Rate__c": {
          name: 'Rate__c',
          type: 'number'
        },
        "Adjustment_Amount__c": {
          name: 'Adjustment_Amount__c',
          type: 'number'
        },
        "Total__c": {
          name: 'Total__c',
          type: 'number'
        },
        "Wire__c": {
          name: 'Wire__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
