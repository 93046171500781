import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-message-sent-lookup',
  templateUrl: './message-sent-lookup.component.html',
  styleUrls: ['./message-sent-lookup.component.css']
})
export class MessageSentLookupComponent implements OnInit {

  @Input() set resetData(isClear) {
    if (isClear) {
      this.messageSentTo = '';
    }
  }
  @Input() placeholder = 'Message Sent To';
  @Input() isdisabled = false;
  @Input() filterObjValue = 'messageSentTo';
  @Input() viewType = '';

  @Output() getMessageSentVal: EventEmitter<any> = new EventEmitter;

  messageSentTo = '';

  constructor() {
  }

  ngOnInit() {
    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
    this.messageSentTo = (preselected && preselected[this.filterObjValue]) ? preselected[this.filterObjValue] : '';
  }

  onMessageSentToChange() {
    this.getMessageSentVal.emit(this.messageSentTo);
  }

  clearMessageSentto() {
    this.messageSentTo = '';
    this.getMessageSentVal.emit('');
  }

}
