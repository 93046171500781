import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgSelectModule } from '@ng-select/ng-select';
import { GlobalFiltersComponent } from './global-filters.component';
import { LookupModule } from 'shared/components/lookup/lookup.module';

@NgModule({
    declarations: [
        GlobalFiltersComponent
    ],
    imports: [
        CommonModule,
        NgxDatatableModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule.forRoot(),
        RouterModule,
        NgSelectModule,
        LookupModule
    ],
    exports: [
        GlobalFiltersComponent
    ],
    schemas: []
})

export class GlobalFiltersModule { }
