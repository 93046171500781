import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubCategoryLookupComponent } from './sub-category-lookup.component';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule
  ],
  declarations: [SubCategoryLookupComponent],
  exports: [SubCategoryLookupComponent]
})
export class SubCategoryLookupModule { }
