import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilesTabComponent } from './files-tab.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { PipeModule } from 'shared/pipe/pipe.module';



@NgModule({
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    PipeModule
  ],
  declarations: [FilesTabComponent],
  exports: [FilesTabComponent]
})
export class ServiceManagerSidebarFilesModule { }
