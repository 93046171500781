import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { smsTypeList } from 'shared/models/static-list-data.service'; 

@Component({
  selector: 'app-sms-type-lookup',
  templateUrl: './sms-type-lookup.component.html',
  styleUrls: ['./sms-type-lookup.component.css']
})
export class SmsTypeLookupComponent implements OnInit {

  @Input() set resetData(isClear) {
    if (isClear) {
      this.smsTypeSelected = [];
    }
  }
  @Input() placeholder = 'SMS Type';
  @Input() isdisabled = false;
  @Input() filterObjValue = 'smsType';
  @Input() isMultipleSelected = true;
  @Input() viewType = '';

  @Output() getSmsTypeObj: EventEmitter<any> = new EventEmitter;


  smsTypeOptionList = [];
  smsTypeSelected = [];

  /**
   * @constructor
   * Represents a PriorityLookupComponent.
   */
  constructor() {
  }

  ngOnInit() {
    this.smsTypeOptionList = smsTypeList;
    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
    this.smsTypeSelected = (preselected && preselected[this.filterObjValue]) ? preselected[this.filterObjValue] : '';
  }

  onSmsTypeChange() {
    this.getSmsTypeObj.emit(this.smsTypeSelected);
  }

  clearSmsType() {
    this.smsTypeSelected = [];
    this.getSmsTypeObj.emit([]);
  }

}
