/* tslint:disable */

declare var Object: any;
export interface JobBoardSetupInterface {
  "Name"?: string;
  "sfdcId"?: string;
  "OwnerId"?: string;
  "LastModifiedById"?: string;
  "CurrencyIsoCode"?: string;
  "CreatedById"?: string;
  "ts2__Address_Validation__c"?: string;
  "ts2__AddThis_Username__c"?: string;
  "ts2__Allow_Register_Only__c"?: boolean;
  "ts2__Apply_Referral_Immediately__c"?: boolean;
  "ts2__AutoAssociate__c"?: boolean;
  "ts2__Available_Languages__c"?: string;
  "ts2__ConfirmationMessage__c"?: string;
  "ts2__Default_Language__c"?: string;
  "ts2__Disabled__c"?: boolean;
  "ts2__DisableMobileJobBoard__c"?: boolean;
  "ts2__Display_Privacy_Statement__c"?: boolean;
  "ts2__EEO_Text__c"?: string;
  "ts2__Employee_Account__c"?: string;
  "ts2__Enable_AddThis__c"?: boolean;
  "ts2__Enable_Employee_Status_Check__c"?: boolean;
  "ts2__Enable_Lead_Tracking__c"?: boolean;
  "ts2__Enable_Submission_Feedback__c"?: boolean;
  "ts2__Gender__c"?: boolean;
  "ts2__HideContactMethod__c"?: boolean;
  "ts2__HideCoverLetter__c"?: boolean;
  "ts2__HideEmailThisJob__c"?: boolean;
  "ts2__HideMobile__c"?: boolean;
  "ts2__HidePhone__c"?: boolean;
  "ts2__HideResumeBuilder__c"?: boolean;
  "ts2__HideResumePaste__c"?: boolean;
  "ts2__HideResumePreviouslyUploaded__c"?: boolean;
  "ts2__HideResumeUpload__c"?: boolean;
  "ts2__HideSourceQuestion__c"?: boolean;
  "ts2__Hide_Standard_EEO_Disclaimer__c"?: boolean;
  "ts2__HomePage__c"?: string;
  "ts2__IndeededFeedCity__c"?: string;
  "ts2__IndeededFeedCountry__c"?: string;
  "ts2__IndeededFeedDescription__c"?: string;
  "ts2__IndeededFeedPostalCode__c"?: string;
  "ts2__IndeededFeedState__c"?: string;
  "ts2__Internal_Application_Process__c"?: string;
  "ts2__ApplicationsDisplayMode__c"?: string;
  "ts2__Job_Board_Type__c"?: string;
  "ts2__JobDescription1__c"?: string;
  "ts2__JobDescription10__c"?: string;
  "ts2__JobDescription2__c"?: string;
  "ts2__JobDescription3__c"?: string;
  "ts2__JobDescription4__c"?: string;
  "ts2__JobDescription5__c"?: string;
  "ts2__JobDescription6__c"?: string;
  "ts2__JobDescription7__c"?: string;
  "ts2__JobDescription8__c"?: string;
  "ts2__JobDescription9__c"?: string;
  "ts2__Jobs_Per_Page__c"?: string;
  "ts2__Jobs_Per_Page_XML__c"?: string;
  "ts2__LocalizedJobDescription__c"?: string;
  "ts2__LocalizedJobName__c"?: string;
  "ts2__Make_EEO_Fields_Required__c"?: boolean;
  "ts2__Max_Number_Open_Referrals__c"?: number;
  "ts2__Max_Number_Referrals_Per_Contact__c"?: number;
  "ts2__Max_search_agents__c"?: string;
  "ts2__Primary__c"?: boolean;
  "ts2__Privacy_Policy_Statement__c"?: string;
  "ts2__Question1__c"?: string;
  "ts2__Question10__c"?: string;
  "ts2__Question11__c"?: string;
  "ts2__Question12__c"?: string;
  "ts2__Question13__c"?: string;
  "ts2__Question14__c"?: string;
  "ts2__Question15__c"?: string;
  "ts2__Question16__c"?: string;
  "ts2__Question17__c"?: string;
  "ts2__Question18__c"?: string;
  "ts2__Question19__c"?: string;
  "ts2__Question2__c"?: string;
  "ts2__Question20__c"?: string;
  "ts2__Question3__c"?: string;
  "ts2__Question4__c"?: string;
  "ts2__Question5__c"?: string;
  "ts2__Question6__c"?: string;
  "ts2__Question7__c"?: string;
  "ts2__Question8__c"?: string;
  "ts2__Question9__c"?: string;
  "ts2__Race__c"?: boolean;
  "ts2__Referral_Expiration_Days__c"?: number;
  "ts2__Register_Process__c"?: string;
  "ts2__Require_Submission_Feedback__c"?: boolean;
  "ts2__Resume_Required__c"?: boolean;
  "ts2__Resume_Submission_Allowed__c"?: boolean;
  "ts2__SearchByKeyword__c"?: boolean;
  "ts2__SearchCriteria1__c"?: string;
  "ts2__SearchCriteria2__c"?: string;
  "ts2__SearchCriteria3__c"?: string;
  "ts2__SearchCriteria4__c"?: string;
  "ts2__SearchCriteria5__c"?: string;
  "ts2__SearchResult1__c"?: string;
  "ts2__SearchResult2__c"?: string;
  "ts2__SearchResult3__c"?: string;
  "ts2__SearchResult4__c"?: string;
  "ts2__Show_Search_Only__c"?: boolean;
  "ts2__SiteName__c"?: string;
  "ts2__SiteURL__c"?: string;
  "ts2__Source_Tracking_for_External_Referrals__c"?: string;
  "ts2__Source_Tracking_for_Internal_Referrals__c"?: string;
  "ts2__Summarize1__c"?: string;
  "ts2__Summarize2__c"?: string;
  "ts2__Summarize3__c"?: string;
  "ts2__Summarize4__c"?: string;
  "ts2__Summarize5__c"?: string;
  "ts2__Validate_Email__c"?: boolean;
  "ts2__Verification_Email_Template__c"?: string;
  "ts2__Veteran__c"?: boolean;
  "ts2__Enable_Referral_Email_Notification__c"?: boolean;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class JobBoardSetup implements JobBoardSetupInterface {
  "Name": string;
  "sfdcId": string;
  "OwnerId": string;
  "LastModifiedById": string;
  "CurrencyIsoCode": string;
  "CreatedById": string;
  "ts2__Address_Validation__c": string;
  "ts2__AddThis_Username__c": string;
  "ts2__Allow_Register_Only__c": boolean;
  "ts2__Apply_Referral_Immediately__c": boolean;
  "ts2__AutoAssociate__c": boolean;
  "ts2__Available_Languages__c": string;
  "ts2__ConfirmationMessage__c": string;
  "ts2__Default_Language__c": string;
  "ts2__Disabled__c": boolean;
  "ts2__DisableMobileJobBoard__c": boolean;
  "ts2__Display_Privacy_Statement__c": boolean;
  "ts2__EEO_Text__c": string;
  "ts2__Employee_Account__c": string;
  "ts2__Enable_AddThis__c": boolean;
  "ts2__Enable_Employee_Status_Check__c": boolean;
  "ts2__Enable_Lead_Tracking__c": boolean;
  "ts2__Enable_Submission_Feedback__c": boolean;
  "ts2__Gender__c": boolean;
  "ts2__HideContactMethod__c": boolean;
  "ts2__HideCoverLetter__c": boolean;
  "ts2__HideEmailThisJob__c": boolean;
  "ts2__HideMobile__c": boolean;
  "ts2__HidePhone__c": boolean;
  "ts2__HideResumeBuilder__c": boolean;
  "ts2__HideResumePaste__c": boolean;
  "ts2__HideResumePreviouslyUploaded__c": boolean;
  "ts2__HideResumeUpload__c": boolean;
  "ts2__HideSourceQuestion__c": boolean;
  "ts2__Hide_Standard_EEO_Disclaimer__c": boolean;
  "ts2__HomePage__c": string;
  "ts2__IndeededFeedCity__c": string;
  "ts2__IndeededFeedCountry__c": string;
  "ts2__IndeededFeedDescription__c": string;
  "ts2__IndeededFeedPostalCode__c": string;
  "ts2__IndeededFeedState__c": string;
  "ts2__Internal_Application_Process__c": string;
  "ts2__ApplicationsDisplayMode__c": string;
  "ts2__Job_Board_Type__c": string;
  "ts2__JobDescription1__c": string;
  "ts2__JobDescription10__c": string;
  "ts2__JobDescription2__c": string;
  "ts2__JobDescription3__c": string;
  "ts2__JobDescription4__c": string;
  "ts2__JobDescription5__c": string;
  "ts2__JobDescription6__c": string;
  "ts2__JobDescription7__c": string;
  "ts2__JobDescription8__c": string;
  "ts2__JobDescription9__c": string;
  "ts2__Jobs_Per_Page__c": string;
  "ts2__Jobs_Per_Page_XML__c": string;
  "ts2__LocalizedJobDescription__c": string;
  "ts2__LocalizedJobName__c": string;
  "ts2__Make_EEO_Fields_Required__c": boolean;
  "ts2__Max_Number_Open_Referrals__c": number;
  "ts2__Max_Number_Referrals_Per_Contact__c": number;
  "ts2__Max_search_agents__c": string;
  "ts2__Primary__c": boolean;
  "ts2__Privacy_Policy_Statement__c": string;
  "ts2__Question1__c": string;
  "ts2__Question10__c": string;
  "ts2__Question11__c": string;
  "ts2__Question12__c": string;
  "ts2__Question13__c": string;
  "ts2__Question14__c": string;
  "ts2__Question15__c": string;
  "ts2__Question16__c": string;
  "ts2__Question17__c": string;
  "ts2__Question18__c": string;
  "ts2__Question19__c": string;
  "ts2__Question2__c": string;
  "ts2__Question20__c": string;
  "ts2__Question3__c": string;
  "ts2__Question4__c": string;
  "ts2__Question5__c": string;
  "ts2__Question6__c": string;
  "ts2__Question7__c": string;
  "ts2__Question8__c": string;
  "ts2__Question9__c": string;
  "ts2__Race__c": boolean;
  "ts2__Referral_Expiration_Days__c": number;
  "ts2__Register_Process__c": string;
  "ts2__Require_Submission_Feedback__c": boolean;
  "ts2__Resume_Required__c": boolean;
  "ts2__Resume_Submission_Allowed__c": boolean;
  "ts2__SearchByKeyword__c": boolean;
  "ts2__SearchCriteria1__c": string;
  "ts2__SearchCriteria2__c": string;
  "ts2__SearchCriteria3__c": string;
  "ts2__SearchCriteria4__c": string;
  "ts2__SearchCriteria5__c": string;
  "ts2__SearchResult1__c": string;
  "ts2__SearchResult2__c": string;
  "ts2__SearchResult3__c": string;
  "ts2__SearchResult4__c": string;
  "ts2__Show_Search_Only__c": boolean;
  "ts2__SiteName__c": string;
  "ts2__SiteURL__c": string;
  "ts2__Source_Tracking_for_External_Referrals__c": string;
  "ts2__Source_Tracking_for_Internal_Referrals__c": string;
  "ts2__Summarize1__c": string;
  "ts2__Summarize2__c": string;
  "ts2__Summarize3__c": string;
  "ts2__Summarize4__c": string;
  "ts2__Summarize5__c": string;
  "ts2__Validate_Email__c": boolean;
  "ts2__Verification_Email_Template__c": string;
  "ts2__Veteran__c": boolean;
  "ts2__Enable_Referral_Email_Notification__c": boolean;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: JobBoardSetupInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `JobBoardSetup`.
   */
  public static getModelName() {
    return "JobBoardSetup";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of JobBoardSetup for dynamic purposes.
  **/
  public static factory(data: JobBoardSetupInterface): JobBoardSetup{
    return new JobBoardSetup(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'JobBoardSetup',
      plural: 'JobBoardSetups',
      path: 'JobBoardSetups',
      idName: 'id',
      properties: {
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "ts2__Address_Validation__c": {
          name: 'ts2__Address_Validation__c',
          type: 'string'
        },
        "ts2__AddThis_Username__c": {
          name: 'ts2__AddThis_Username__c',
          type: 'string'
        },
        "ts2__Allow_Register_Only__c": {
          name: 'ts2__Allow_Register_Only__c',
          type: 'boolean'
        },
        "ts2__Apply_Referral_Immediately__c": {
          name: 'ts2__Apply_Referral_Immediately__c',
          type: 'boolean'
        },
        "ts2__AutoAssociate__c": {
          name: 'ts2__AutoAssociate__c',
          type: 'boolean'
        },
        "ts2__Available_Languages__c": {
          name: 'ts2__Available_Languages__c',
          type: 'string'
        },
        "ts2__ConfirmationMessage__c": {
          name: 'ts2__ConfirmationMessage__c',
          type: 'string'
        },
        "ts2__Default_Language__c": {
          name: 'ts2__Default_Language__c',
          type: 'string'
        },
        "ts2__Disabled__c": {
          name: 'ts2__Disabled__c',
          type: 'boolean'
        },
        "ts2__DisableMobileJobBoard__c": {
          name: 'ts2__DisableMobileJobBoard__c',
          type: 'boolean'
        },
        "ts2__Display_Privacy_Statement__c": {
          name: 'ts2__Display_Privacy_Statement__c',
          type: 'boolean'
        },
        "ts2__EEO_Text__c": {
          name: 'ts2__EEO_Text__c',
          type: 'string'
        },
        "ts2__Employee_Account__c": {
          name: 'ts2__Employee_Account__c',
          type: 'string'
        },
        "ts2__Enable_AddThis__c": {
          name: 'ts2__Enable_AddThis__c',
          type: 'boolean'
        },
        "ts2__Enable_Employee_Status_Check__c": {
          name: 'ts2__Enable_Employee_Status_Check__c',
          type: 'boolean'
        },
        "ts2__Enable_Lead_Tracking__c": {
          name: 'ts2__Enable_Lead_Tracking__c',
          type: 'boolean'
        },
        "ts2__Enable_Submission_Feedback__c": {
          name: 'ts2__Enable_Submission_Feedback__c',
          type: 'boolean'
        },
        "ts2__Gender__c": {
          name: 'ts2__Gender__c',
          type: 'boolean'
        },
        "ts2__HideContactMethod__c": {
          name: 'ts2__HideContactMethod__c',
          type: 'boolean'
        },
        "ts2__HideCoverLetter__c": {
          name: 'ts2__HideCoverLetter__c',
          type: 'boolean'
        },
        "ts2__HideEmailThisJob__c": {
          name: 'ts2__HideEmailThisJob__c',
          type: 'boolean'
        },
        "ts2__HideMobile__c": {
          name: 'ts2__HideMobile__c',
          type: 'boolean'
        },
        "ts2__HidePhone__c": {
          name: 'ts2__HidePhone__c',
          type: 'boolean'
        },
        "ts2__HideResumeBuilder__c": {
          name: 'ts2__HideResumeBuilder__c',
          type: 'boolean'
        },
        "ts2__HideResumePaste__c": {
          name: 'ts2__HideResumePaste__c',
          type: 'boolean'
        },
        "ts2__HideResumePreviouslyUploaded__c": {
          name: 'ts2__HideResumePreviouslyUploaded__c',
          type: 'boolean'
        },
        "ts2__HideResumeUpload__c": {
          name: 'ts2__HideResumeUpload__c',
          type: 'boolean'
        },
        "ts2__HideSourceQuestion__c": {
          name: 'ts2__HideSourceQuestion__c',
          type: 'boolean'
        },
        "ts2__Hide_Standard_EEO_Disclaimer__c": {
          name: 'ts2__Hide_Standard_EEO_Disclaimer__c',
          type: 'boolean'
        },
        "ts2__HomePage__c": {
          name: 'ts2__HomePage__c',
          type: 'string'
        },
        "ts2__IndeededFeedCity__c": {
          name: 'ts2__IndeededFeedCity__c',
          type: 'string'
        },
        "ts2__IndeededFeedCountry__c": {
          name: 'ts2__IndeededFeedCountry__c',
          type: 'string'
        },
        "ts2__IndeededFeedDescription__c": {
          name: 'ts2__IndeededFeedDescription__c',
          type: 'string'
        },
        "ts2__IndeededFeedPostalCode__c": {
          name: 'ts2__IndeededFeedPostalCode__c',
          type: 'string'
        },
        "ts2__IndeededFeedState__c": {
          name: 'ts2__IndeededFeedState__c',
          type: 'string'
        },
        "ts2__Internal_Application_Process__c": {
          name: 'ts2__Internal_Application_Process__c',
          type: 'string'
        },
        "ts2__ApplicationsDisplayMode__c": {
          name: 'ts2__ApplicationsDisplayMode__c',
          type: 'string'
        },
        "ts2__Job_Board_Type__c": {
          name: 'ts2__Job_Board_Type__c',
          type: 'string'
        },
        "ts2__JobDescription1__c": {
          name: 'ts2__JobDescription1__c',
          type: 'string'
        },
        "ts2__JobDescription10__c": {
          name: 'ts2__JobDescription10__c',
          type: 'string'
        },
        "ts2__JobDescription2__c": {
          name: 'ts2__JobDescription2__c',
          type: 'string'
        },
        "ts2__JobDescription3__c": {
          name: 'ts2__JobDescription3__c',
          type: 'string'
        },
        "ts2__JobDescription4__c": {
          name: 'ts2__JobDescription4__c',
          type: 'string'
        },
        "ts2__JobDescription5__c": {
          name: 'ts2__JobDescription5__c',
          type: 'string'
        },
        "ts2__JobDescription6__c": {
          name: 'ts2__JobDescription6__c',
          type: 'string'
        },
        "ts2__JobDescription7__c": {
          name: 'ts2__JobDescription7__c',
          type: 'string'
        },
        "ts2__JobDescription8__c": {
          name: 'ts2__JobDescription8__c',
          type: 'string'
        },
        "ts2__JobDescription9__c": {
          name: 'ts2__JobDescription9__c',
          type: 'string'
        },
        "ts2__Jobs_Per_Page__c": {
          name: 'ts2__Jobs_Per_Page__c',
          type: 'string'
        },
        "ts2__Jobs_Per_Page_XML__c": {
          name: 'ts2__Jobs_Per_Page_XML__c',
          type: 'string'
        },
        "ts2__LocalizedJobDescription__c": {
          name: 'ts2__LocalizedJobDescription__c',
          type: 'string'
        },
        "ts2__LocalizedJobName__c": {
          name: 'ts2__LocalizedJobName__c',
          type: 'string'
        },
        "ts2__Make_EEO_Fields_Required__c": {
          name: 'ts2__Make_EEO_Fields_Required__c',
          type: 'boolean'
        },
        "ts2__Max_Number_Open_Referrals__c": {
          name: 'ts2__Max_Number_Open_Referrals__c',
          type: 'number'
        },
        "ts2__Max_Number_Referrals_Per_Contact__c": {
          name: 'ts2__Max_Number_Referrals_Per_Contact__c',
          type: 'number'
        },
        "ts2__Max_search_agents__c": {
          name: 'ts2__Max_search_agents__c',
          type: 'string'
        },
        "ts2__Primary__c": {
          name: 'ts2__Primary__c',
          type: 'boolean'
        },
        "ts2__Privacy_Policy_Statement__c": {
          name: 'ts2__Privacy_Policy_Statement__c',
          type: 'string'
        },
        "ts2__Question1__c": {
          name: 'ts2__Question1__c',
          type: 'string'
        },
        "ts2__Question10__c": {
          name: 'ts2__Question10__c',
          type: 'string'
        },
        "ts2__Question11__c": {
          name: 'ts2__Question11__c',
          type: 'string'
        },
        "ts2__Question12__c": {
          name: 'ts2__Question12__c',
          type: 'string'
        },
        "ts2__Question13__c": {
          name: 'ts2__Question13__c',
          type: 'string'
        },
        "ts2__Question14__c": {
          name: 'ts2__Question14__c',
          type: 'string'
        },
        "ts2__Question15__c": {
          name: 'ts2__Question15__c',
          type: 'string'
        },
        "ts2__Question16__c": {
          name: 'ts2__Question16__c',
          type: 'string'
        },
        "ts2__Question17__c": {
          name: 'ts2__Question17__c',
          type: 'string'
        },
        "ts2__Question18__c": {
          name: 'ts2__Question18__c',
          type: 'string'
        },
        "ts2__Question19__c": {
          name: 'ts2__Question19__c',
          type: 'string'
        },
        "ts2__Question2__c": {
          name: 'ts2__Question2__c',
          type: 'string'
        },
        "ts2__Question20__c": {
          name: 'ts2__Question20__c',
          type: 'string'
        },
        "ts2__Question3__c": {
          name: 'ts2__Question3__c',
          type: 'string'
        },
        "ts2__Question4__c": {
          name: 'ts2__Question4__c',
          type: 'string'
        },
        "ts2__Question5__c": {
          name: 'ts2__Question5__c',
          type: 'string'
        },
        "ts2__Question6__c": {
          name: 'ts2__Question6__c',
          type: 'string'
        },
        "ts2__Question7__c": {
          name: 'ts2__Question7__c',
          type: 'string'
        },
        "ts2__Question8__c": {
          name: 'ts2__Question8__c',
          type: 'string'
        },
        "ts2__Question9__c": {
          name: 'ts2__Question9__c',
          type: 'string'
        },
        "ts2__Race__c": {
          name: 'ts2__Race__c',
          type: 'boolean'
        },
        "ts2__Referral_Expiration_Days__c": {
          name: 'ts2__Referral_Expiration_Days__c',
          type: 'number'
        },
        "ts2__Register_Process__c": {
          name: 'ts2__Register_Process__c',
          type: 'string'
        },
        "ts2__Require_Submission_Feedback__c": {
          name: 'ts2__Require_Submission_Feedback__c',
          type: 'boolean'
        },
        "ts2__Resume_Required__c": {
          name: 'ts2__Resume_Required__c',
          type: 'boolean'
        },
        "ts2__Resume_Submission_Allowed__c": {
          name: 'ts2__Resume_Submission_Allowed__c',
          type: 'boolean'
        },
        "ts2__SearchByKeyword__c": {
          name: 'ts2__SearchByKeyword__c',
          type: 'boolean'
        },
        "ts2__SearchCriteria1__c": {
          name: 'ts2__SearchCriteria1__c',
          type: 'string'
        },
        "ts2__SearchCriteria2__c": {
          name: 'ts2__SearchCriteria2__c',
          type: 'string'
        },
        "ts2__SearchCriteria3__c": {
          name: 'ts2__SearchCriteria3__c',
          type: 'string'
        },
        "ts2__SearchCriteria4__c": {
          name: 'ts2__SearchCriteria4__c',
          type: 'string'
        },
        "ts2__SearchCriteria5__c": {
          name: 'ts2__SearchCriteria5__c',
          type: 'string'
        },
        "ts2__SearchResult1__c": {
          name: 'ts2__SearchResult1__c',
          type: 'string'
        },
        "ts2__SearchResult2__c": {
          name: 'ts2__SearchResult2__c',
          type: 'string'
        },
        "ts2__SearchResult3__c": {
          name: 'ts2__SearchResult3__c',
          type: 'string'
        },
        "ts2__SearchResult4__c": {
          name: 'ts2__SearchResult4__c',
          type: 'string'
        },
        "ts2__Show_Search_Only__c": {
          name: 'ts2__Show_Search_Only__c',
          type: 'boolean'
        },
        "ts2__SiteName__c": {
          name: 'ts2__SiteName__c',
          type: 'string'
        },
        "ts2__SiteURL__c": {
          name: 'ts2__SiteURL__c',
          type: 'string'
        },
        "ts2__Source_Tracking_for_External_Referrals__c": {
          name: 'ts2__Source_Tracking_for_External_Referrals__c',
          type: 'string'
        },
        "ts2__Source_Tracking_for_Internal_Referrals__c": {
          name: 'ts2__Source_Tracking_for_Internal_Referrals__c',
          type: 'string'
        },
        "ts2__Summarize1__c": {
          name: 'ts2__Summarize1__c',
          type: 'string'
        },
        "ts2__Summarize2__c": {
          name: 'ts2__Summarize2__c',
          type: 'string'
        },
        "ts2__Summarize3__c": {
          name: 'ts2__Summarize3__c',
          type: 'string'
        },
        "ts2__Summarize4__c": {
          name: 'ts2__Summarize4__c',
          type: 'string'
        },
        "ts2__Summarize5__c": {
          name: 'ts2__Summarize5__c',
          type: 'string'
        },
        "ts2__Validate_Email__c": {
          name: 'ts2__Validate_Email__c',
          type: 'boolean'
        },
        "ts2__Verification_Email_Template__c": {
          name: 'ts2__Verification_Email_Template__c',
          type: 'string'
        },
        "ts2__Veteran__c": {
          name: 'ts2__Veteran__c',
          type: 'boolean'
        },
        "ts2__Enable_Referral_Email_Notification__c": {
          name: 'ts2__Enable_Referral_Email_Notification__c',
          type: 'boolean'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
