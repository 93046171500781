import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { VendorAccountLookupComponent } from './vendor-account-lookup.component';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule
  ],
  declarations: [VendorAccountLookupComponent],
  exports: [VendorAccountLookupComponent],
  providers: []
})
export class VendorAccountLookupModule { }
