/* tslint:disable */
import {
  IronAttachment,
  Account,
  Project,
  Contact
} from '../index';

declare var Object: any;
export interface SOWDetailInterface {
  "sfdcId"?: string;
  "Name"?: string;
  "Account__c"?: string;
  "Project__c"?: string;
  "Start_Date__c"?: Date;
  "End_Date__c"?: Date;
  "Description__c"?: string;
  "Type__c"?: string;
  "Created_By__c"?: string;
  "Last_Modified_By__c"?: string;
  "LastModifiedDate"?: Date;
  "LastModifiedById"?: string;
  "CreatedDate"?: Date;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  attachments?: IronAttachment;
  account?: Account;
  project?: Project;
  createdBy?: Contact;
  lastModifiedBy?: Contact;
}

export class SOWDetail implements SOWDetailInterface {
  "sfdcId": string;
  "Name": string;
  "Account__c": string;
  "Project__c": string;
  "Start_Date__c": Date;
  "End_Date__c": Date;
  "Description__c": string;
  "Type__c": string;
  "Created_By__c": string;
  "Last_Modified_By__c": string;
  "LastModifiedDate": Date;
  "LastModifiedById": string;
  "CreatedDate": Date;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  attachments: IronAttachment;
  account: Account;
  project: Project;
  createdBy: Contact;
  lastModifiedBy: Contact;
  constructor(data?: SOWDetailInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `SOWDetail`.
   */
  public static getModelName() {
    return "SOWDetail";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of SOWDetail for dynamic purposes.
  **/
  public static factory(data: SOWDetailInterface): SOWDetail{
    return new SOWDetail(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'SOWDetail',
      plural: 'SOWDetails',
      path: 'SOWDetails',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "Account__c": {
          name: 'Account__c',
          type: 'string'
        },
        "Project__c": {
          name: 'Project__c',
          type: 'string'
        },
        "Start_Date__c": {
          name: 'Start_Date__c',
          type: 'Date'
        },
        "End_Date__c": {
          name: 'End_Date__c',
          type: 'Date'
        },
        "Description__c": {
          name: 'Description__c',
          type: 'string'
        },
        "Type__c": {
          name: 'Type__c',
          type: 'string'
        },
        "Created_By__c": {
          name: 'Created_By__c',
          type: 'string'
        },
        "Last_Modified_By__c": {
          name: 'Last_Modified_By__c',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        attachments: {
          name: 'attachments',
          type: 'IronAttachment',
          model: 'IronAttachment',
          relationType: 'belongsTo',
                  keyFrom: 'sfdcId',
          keyTo: 'SOW_DETAIL__c'
        },
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'Account__c',
          keyTo: 'sfdcId'
        },
        project: {
          name: 'project',
          type: 'Project',
          model: 'Project',
          relationType: 'belongsTo',
                  keyFrom: 'Project__c',
          keyTo: 'sfdcId'
        },
        createdBy: {
          name: 'createdBy',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Created_By__c',
          keyTo: 'sfdcId'
        },
        lastModifiedBy: {
          name: 'lastModifiedBy',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Last_Modified_By__c',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
