/* tslint:disable */
import {
  JobApplication,
  JobOrder,
  Contact,
  Skill,
  JobSubmittals
} from '../index';

declare var Object: any;
export interface JobAssessmentInterface {
  "sfdcId"?: string;
  "OwnerId"?: string;
  "IsDeleted"?: boolean;
  "Name"?: string;
  "CreatedById"?: string;
  "CreatedDate"?: Date;
  "CurrencyIsoCode"?: string;
  "LastModifiedDate"?: Date;
  "LastModifiedById"?: string;
  "ts2__Application__c"?: string;
  "ts2__Comment__c"?: string;
  "ts2__Contact__c"?: string;
  "ts2__Job_Order__c"?: string;
  "ts2__Like__c"?: number;
  "ts2__Primary__c"?: boolean;
  "ts2__Rating__c"?: number;
  "ts2__Skill__c"?: string;
  "ts2__Submittal__c"?: string;
  "ts2__User__c"?: string;
  "IRON_ExtID__c"?: string;
  "JSPresent__PresentationReviewerURL__c"?: string;
  "JSPresent__Presentation_List__c"?: string;
  "JSPresent__Presentation_Reviewer__c"?: string;
  "JSPresent__Status__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  jobApplication?: JobApplication;
  jobOrder?: JobOrder;
  contact?: Contact;
  presentationReviewer?: Contact;
  skill?: Skill;
  submittal?: JobSubmittals;
}

export class JobAssessment implements JobAssessmentInterface {
  "sfdcId": string;
  "OwnerId": string;
  "IsDeleted": boolean;
  "Name": string;
  "CreatedById": string;
  "CreatedDate": Date;
  "CurrencyIsoCode": string;
  "LastModifiedDate": Date;
  "LastModifiedById": string;
  "ts2__Application__c": string;
  "ts2__Comment__c": string;
  "ts2__Contact__c": string;
  "ts2__Job_Order__c": string;
  "ts2__Like__c": number;
  "ts2__Primary__c": boolean;
  "ts2__Rating__c": number;
  "ts2__Skill__c": string;
  "ts2__Submittal__c": string;
  "ts2__User__c": string;
  "IRON_ExtID__c": string;
  "JSPresent__PresentationReviewerURL__c": string;
  "JSPresent__Presentation_List__c": string;
  "JSPresent__Presentation_Reviewer__c": string;
  "JSPresent__Status__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  jobApplication: JobApplication;
  jobOrder: JobOrder;
  contact: Contact;
  presentationReviewer: Contact;
  skill: Skill;
  submittal: JobSubmittals;
  constructor(data?: JobAssessmentInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `JobAssessment`.
   */
  public static getModelName() {
    return "JobAssessment";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of JobAssessment for dynamic purposes.
  **/
  public static factory(data: JobAssessmentInterface): JobAssessment{
    return new JobAssessment(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'JobAssessment',
      plural: 'JobAssessments',
      path: 'JobAssessments',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "ts2__Application__c": {
          name: 'ts2__Application__c',
          type: 'string'
        },
        "ts2__Comment__c": {
          name: 'ts2__Comment__c',
          type: 'string'
        },
        "ts2__Contact__c": {
          name: 'ts2__Contact__c',
          type: 'string'
        },
        "ts2__Job_Order__c": {
          name: 'ts2__Job_Order__c',
          type: 'string'
        },
        "ts2__Like__c": {
          name: 'ts2__Like__c',
          type: 'number'
        },
        "ts2__Primary__c": {
          name: 'ts2__Primary__c',
          type: 'boolean'
        },
        "ts2__Rating__c": {
          name: 'ts2__Rating__c',
          type: 'number'
        },
        "ts2__Skill__c": {
          name: 'ts2__Skill__c',
          type: 'string'
        },
        "ts2__Submittal__c": {
          name: 'ts2__Submittal__c',
          type: 'string'
        },
        "ts2__User__c": {
          name: 'ts2__User__c',
          type: 'string'
        },
        "IRON_ExtID__c": {
          name: 'IRON_ExtID__c',
          type: 'string'
        },
        "JSPresent__PresentationReviewerURL__c": {
          name: 'JSPresent__PresentationReviewerURL__c',
          type: 'string'
        },
        "JSPresent__Presentation_List__c": {
          name: 'JSPresent__Presentation_List__c',
          type: 'string'
        },
        "JSPresent__Presentation_Reviewer__c": {
          name: 'JSPresent__Presentation_Reviewer__c',
          type: 'string'
        },
        "JSPresent__Status__c": {
          name: 'JSPresent__Status__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        jobApplication: {
          name: 'jobApplication',
          type: 'JobApplication',
          model: 'JobApplication',
          relationType: 'belongsTo',
                  keyFrom: 'ts2__Application__c',
          keyTo: 'sfdcId'
        },
        jobOrder: {
          name: 'jobOrder',
          type: 'JobOrder',
          model: 'JobOrder',
          relationType: 'belongsTo',
                  keyFrom: 'ts2__Job_Order__c',
          keyTo: 'sfdcId'
        },
        contact: {
          name: 'contact',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'ts2__Contact__c',
          keyTo: 'sfdcId'
        },
        presentationReviewer: {
          name: 'presentationReviewer',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'JSPresent__Presentation_Reviewer__c',
          keyTo: 'sfdcId'
        },
        skill: {
          name: 'skill',
          type: 'Skill',
          model: 'Skill',
          relationType: 'belongsTo',
                  keyFrom: 'ts2__Skill__c',
          keyTo: 'sfdcId'
        },
        submittal: {
          name: 'submittal',
          type: 'JobSubmittals',
          model: 'JobSubmittals',
          relationType: 'belongsTo',
                  keyFrom: 'ts2__Submittal__c',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
