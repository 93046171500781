/* tslint:disable */

declare var Object: any;
export interface EngagementLinkInterface {
  "sfdcId"?: string;
  "ts2__Account__c"?: string;
  "CreatedById"?: string;
  "CurrencyIsoCode"?: string;
  "Name"?: string;
  "LastModifiedById"?: string;
  "ts2__Application__c"?: string;
  "ts2__Engagement_Program__c"?: string;
  "ts2__Job__c"?: string;
  "ts2__Link_type__c"?: string;
  "ts2__Offer__c"?: string;
  "ts2__Placement__c"?: string;
  "ts2__Short_List__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class EngagementLink implements EngagementLinkInterface {
  "sfdcId": string;
  "ts2__Account__c": string;
  "CreatedById": string;
  "CurrencyIsoCode": string;
  "Name": string;
  "LastModifiedById": string;
  "ts2__Application__c": string;
  "ts2__Engagement_Program__c": string;
  "ts2__Job__c": string;
  "ts2__Link_type__c": string;
  "ts2__Offer__c": string;
  "ts2__Placement__c": string;
  "ts2__Short_List__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: EngagementLinkInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `EngagementLink`.
   */
  public static getModelName() {
    return "EngagementLink";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of EngagementLink for dynamic purposes.
  **/
  public static factory(data: EngagementLinkInterface): EngagementLink{
    return new EngagementLink(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'EngagementLink',
      plural: 'EngagementLinks',
      path: 'EngagementLinks',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "ts2__Account__c": {
          name: 'ts2__Account__c',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "ts2__Application__c": {
          name: 'ts2__Application__c',
          type: 'string'
        },
        "ts2__Engagement_Program__c": {
          name: 'ts2__Engagement_Program__c',
          type: 'string'
        },
        "ts2__Job__c": {
          name: 'ts2__Job__c',
          type: 'string'
        },
        "ts2__Link_type__c": {
          name: 'ts2__Link_type__c',
          type: 'string'
        },
        "ts2__Offer__c": {
          name: 'ts2__Offer__c',
          type: 'string'
        },
        "ts2__Placement__c": {
          name: 'ts2__Placement__c',
          type: 'string'
        },
        "ts2__Short_List__c": {
          name: 'ts2__Short_List__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
