/* tslint:disable */

declare var Object: any;
export interface MeetingMinutesInterface {
  "sfdcId"?: string;
  "CreatedById"?: string;
  "CreatedDate"?: Date;
  "CurrencyIsoCode"?: string;
  "LastModifiedById"?: string;
  "Name"?: string;
  "OwnerId"?: string;
  "IRON_ExtID__c"?: string;
  "Due_Date__c"?: Date;
  "Key_Result__c"?: string;
  "Meeting__c"?: string;
  "Minute_Assigned_To__c"?: string;
  "Minute_Details__c"?: string;
  "Minute_Note__c"?: string;
  "Minute_Status__c"?: string;
  "Minute_Taken_By__c"?: string;
  "Minute_Type__c"?: string;
  "Task__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class MeetingMinutes implements MeetingMinutesInterface {
  "sfdcId": string;
  "CreatedById": string;
  "CreatedDate": Date;
  "CurrencyIsoCode": string;
  "LastModifiedById": string;
  "Name": string;
  "OwnerId": string;
  "IRON_ExtID__c": string;
  "Due_Date__c": Date;
  "Key_Result__c": string;
  "Meeting__c": string;
  "Minute_Assigned_To__c": string;
  "Minute_Details__c": string;
  "Minute_Note__c": string;
  "Minute_Status__c": string;
  "Minute_Taken_By__c": string;
  "Minute_Type__c": string;
  "Task__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: MeetingMinutesInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `MeetingMinutes`.
   */
  public static getModelName() {
    return "MeetingMinutes";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of MeetingMinutes for dynamic purposes.
  **/
  public static factory(data: MeetingMinutesInterface): MeetingMinutes{
    return new MeetingMinutes(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'MeetingMinutes',
      plural: 'MeetingMinutes',
      path: 'MeetingMinutes',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "IRON_ExtID__c": {
          name: 'IRON_ExtID__c',
          type: 'string'
        },
        "Due_Date__c": {
          name: 'Due_Date__c',
          type: 'Date'
        },
        "Key_Result__c": {
          name: 'Key_Result__c',
          type: 'string'
        },
        "Meeting__c": {
          name: 'Meeting__c',
          type: 'string'
        },
        "Minute_Assigned_To__c": {
          name: 'Minute_Assigned_To__c',
          type: 'string'
        },
        "Minute_Details__c": {
          name: 'Minute_Details__c',
          type: 'string'
        },
        "Minute_Note__c": {
          name: 'Minute_Note__c',
          type: 'string'
        },
        "Minute_Status__c": {
          name: 'Minute_Status__c',
          type: 'string'
        },
        "Minute_Taken_By__c": {
          name: 'Minute_Taken_By__c',
          type: 'string'
        },
        "Minute_Type__c": {
          name: 'Minute_Type__c',
          type: 'string'
        },
        "Task__c": {
          name: 'Task__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
