import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActualEndTimeCoverageLookupComponent } from './actual-end-time-coverage-lookup.component';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule
  ],
  declarations: [ActualEndTimeCoverageLookupComponent],
  exports: [ActualEndTimeCoverageLookupComponent]
})
export class ActualEndTimeCoverageLookupModule { }
