import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';

import { MilestoneLookupComponent } from './milestone-lookup.component';

@NgModule({
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule
  ],
  declarations: [MilestoneLookupComponent],
  exports: [MilestoneLookupComponent]
})
export class MilestoneLookupModule { }
