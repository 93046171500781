/* tslint:disable */
import {
  Account,
  Pricelist,
  Jobsite,
  JobsiteProjects,
  GeoMetro,
  TalentType,
  QuoteManager,
  RecordType,
  GlobalTalentPool
} from '../index';

declare var Object: any;
export interface ProjectInterface {
  "sfdcId"?: string;
  "APVP_Group_Number__c"?: string;
  "APVP_Record_Count__c"?: string;
  "Account__c"?: string;
  "Customer_Service_Type__c"?: string;
  "Deadline__c"?: Date;
  "Description__c"?: string;
  "Duration__c"?: number;
  "Field_Service_Program_Type__c"?: string;
  "Jobsite_Contact_Email_Service_Desk__c"?: string;
  "Jobsite_Contact_Email_Technical_Esc__c"?: string;
  "Jobsite_Contact_Name_Service_Desk__c"?: string;
  "Jobsite_Contact_Name_Technical_Esc__c"?: string;
  "Jobsite_Contact_Phone_Service_Desk__c"?: string;
  "Jobsite_Contact_Phone_Technical_Esc__c"?: string;
  "Jobsite_Contact_Selection__c"?: string;
  "Kick_off__c"?: Date;
  "Name"?: string;
  "Partner_Name_Text__c"?: string;
  "Partner_Pricelist__c"?: string;
  "Program_Activation__c"?: string;
  "Progress__c"?: string;
  "Project_Standard__c"?: string;
  "Project__c"?: string;
  "RecordTypeId"?: string;
  "Resource_Pool_Type_Used_for_backfill__c"?: string;
  "Service_Dispatch_SLA_Priority__c"?: string;
  "Service_Technical_Level__c"?: string;
  "SoW_Equipment_Tracking_Vendor__c"?: string;
  "Status__c"?: string;
  "Vendor_Pricelist__c"?: string;
  "Vendor_Type__c"?: string;
  "CreatedDate"?: Date;
  "Service_Description__c"?: string;
  "Special_Service_Instructions__c"?: string;
  "SOW_Description_Customer_Long__c"?: string;
  "Required_Tools__c"?: string;
  "Talent_Type__c"?: string;
  "SLA__c"?: string;
  "GEO_Country__c"?: string;
  "Product_Id__c"?: string;
  "Master_Project__c"?: string;
  "Vendor__c"?: string;
  "Project_Profile__c"?: string;
  "PPE_Hours__c"?: string;
  "Customer_Service_Type_Master__c"?: string;
  "Talent_Type_From_Profile__c"?: string;
  "Service_Dispatch_SLA_Priority_Master__c"?: string;
  "Service_Technical_Level_Master__c"?: string;
  "Project_Routing_Profile__c"?: string;
  "Global_Talent_Pool__c"?: string;
  "Community_Type__c"?: string;
  "Distribution_Type__c"?: string;
  "temp_jobsite_sfdcId"?: string;
  "FileCounter"?: number;
  "FolderLastModifiedOn"?: Date;
  "Count_of_Workers__c"?: string;
  "CreatedById"?: string;
  "CurrencyIsoCode"?: string;
  "Customer_PPE_Hours_For_2NBD__c"?: string;
  "Customer_PPE_Hours_For_3NBD__c"?: string;
  "Customer_PPE_Hours_For_5NBD__c"?: string;
  "Customer_PPE_Hours_For_NBD__c"?: string;
  "Customer_PPE_Hours_For_SBD6HR__c"?: string;
  "Customer_PPE_Hours_for_SBD_4HR__c"?: string;
  "GEO_Code__c"?: string;
  "GEO_Country_Code_Standard_Project_Auto__c"?: string;
  "GEO_Metro_Count__c"?: string;
  "Health_Pricelist_Status__c"?: string;
  "Health_Pricelist_Type__c"?: string;
  "Health_Project_Profile_Status__c"?: string;
  "Health_Project_status__c"?: string;
  "Health_Routing_Status__c"?: string;
  "Health_SOP_Pricelist_Status__c"?: string;
  "Health_SOP_Program_Status__c"?: string;
  "Health_Standard_Pricelist__c"?: string;
  "Health_Vendor_Status__c"?: string;
  "IsDeleted"?: boolean;
  "LastModifiedById"?: string;
  "LastModifiedDate"?: Date;
  "NumDays__c"?: number;
  "Number_of_Incomplete_Milestones_Auto__c"?: string;
  "OwnerId"?: string;
  "PMS_to_VMS_Auto_Push_Local_Jobsite__c"?: boolean;
  "PPE_Hours_on_Master_Project__c"?: number;
  "Partner_Pricelist_from_SOP_Auto__c"?: string;
  "Populate_APVP__c"?: boolean;
  "Project_Safe_Id__c"?: string;
  "Push_PMS_Message_to_VMS__c"?: boolean;
  "Refresh__c"?: boolean;
  "Route_From_APVP__c"?: boolean;
  "SoW_Description_For_Customer__c"?: string;
  "SoW_Equipment_Tracking_From_Master__c"?: string;
  "Status_Image_OpenVComplete__c"?: string;
  "Status_Img_OpenLateBlocked__c"?: string;
  "Status_TasksOpenBlockedLate__c"?: string;
  "Status_TasksOpenComplete__c"?: string;
  "Total_Complete_Task_Count_Auto__c"?: string;
  "Total_Exp_Budget_from_Milestones_Auto__c"?: string;
  "Total_Expense__c"?: string;
  "Total_Hours_Budget_from_Milestones_Auto__c"?: string;
  "Total_Hours__c"?: string;
  "Total_Late_Task_Count_Auto__c"?: string;
  "Total_Open_Task_Count_Auto__c"?: string;
  "Vendor_NDA_Status__c"?: string;
  "Worker_Background_check_Status__c"?: string;
  "Worker_Drug_Test_Status__c"?: string;
  "Worker_Program_Used_for_backfill__c"?: string;
  "Worker_Project_Site_Onboarding_Status__c"?: string;
  "Worker_Project_Training_Status__c"?: string;
  "Worker_e_Verify_Status__c"?: string;
  "Pool_General_Health_Rating__c"?: string;
  "SLA_Group_Name__c"?: string;
  "GEO_Country_Code__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  StandardProject?: Project;
  Partner?: Account;
  PartnerPricelist?: Pricelist;
  VendorPricelist?: Pricelist;
  Vendor?: Account;
  childProject?: Project[];
  masterProject?: Project;
  projectProfile?: Project;
  projectRoutingProfile?: Project;
  jobsites?: Jobsite[];
  JobsiteProjectes?: JobsiteProjects[];
  GeoMetro?: GeoMetro;
  TalentType?: TalentType;
  QuoteManagers?: QuoteManager[];
  RecordType?: RecordType;
  globalTalentPool?: GlobalTalentPool;
}

export class Project implements ProjectInterface {
  "sfdcId": string;
  "APVP_Group_Number__c": string;
  "APVP_Record_Count__c": string;
  "Account__c": string;
  "Customer_Service_Type__c": string;
  "Deadline__c": Date;
  "Description__c": string;
  "Duration__c": number;
  "Field_Service_Program_Type__c": string;
  "Jobsite_Contact_Email_Service_Desk__c": string;
  "Jobsite_Contact_Email_Technical_Esc__c": string;
  "Jobsite_Contact_Name_Service_Desk__c": string;
  "Jobsite_Contact_Name_Technical_Esc__c": string;
  "Jobsite_Contact_Phone_Service_Desk__c": string;
  "Jobsite_Contact_Phone_Technical_Esc__c": string;
  "Jobsite_Contact_Selection__c": string;
  "Kick_off__c": Date;
  "Name": string;
  "Partner_Name_Text__c": string;
  "Partner_Pricelist__c": string;
  "Program_Activation__c": string;
  "Progress__c": string;
  "Project_Standard__c": string;
  "Project__c": string;
  "RecordTypeId": string;
  "Resource_Pool_Type_Used_for_backfill__c": string;
  "Service_Dispatch_SLA_Priority__c": string;
  "Service_Technical_Level__c": string;
  "SoW_Equipment_Tracking_Vendor__c": string;
  "Status__c": string;
  "Vendor_Pricelist__c": string;
  "Vendor_Type__c": string;
  "CreatedDate": Date;
  "Service_Description__c": string;
  "Special_Service_Instructions__c": string;
  "SOW_Description_Customer_Long__c": string;
  "Required_Tools__c": string;
  "Talent_Type__c": string;
  "SLA__c": string;
  "GEO_Country__c": string;
  "Product_Id__c": string;
  "Master_Project__c": string;
  "Vendor__c": string;
  "Project_Profile__c": string;
  "PPE_Hours__c": string;
  "Customer_Service_Type_Master__c": string;
  "Talent_Type_From_Profile__c": string;
  "Service_Dispatch_SLA_Priority_Master__c": string;
  "Service_Technical_Level_Master__c": string;
  "Project_Routing_Profile__c": string;
  "Global_Talent_Pool__c": string;
  "Community_Type__c": string;
  "Distribution_Type__c": string;
  "temp_jobsite_sfdcId": string;
  "FileCounter": number;
  "FolderLastModifiedOn": Date;
  "Count_of_Workers__c": string;
  "CreatedById": string;
  "CurrencyIsoCode": string;
  "Customer_PPE_Hours_For_2NBD__c": string;
  "Customer_PPE_Hours_For_3NBD__c": string;
  "Customer_PPE_Hours_For_5NBD__c": string;
  "Customer_PPE_Hours_For_NBD__c": string;
  "Customer_PPE_Hours_For_SBD6HR__c": string;
  "Customer_PPE_Hours_for_SBD_4HR__c": string;
  "GEO_Code__c": string;
  "GEO_Country_Code_Standard_Project_Auto__c": string;
  "GEO_Metro_Count__c": string;
  "Health_Pricelist_Status__c": string;
  "Health_Pricelist_Type__c": string;
  "Health_Project_Profile_Status__c": string;
  "Health_Project_status__c": string;
  "Health_Routing_Status__c": string;
  "Health_SOP_Pricelist_Status__c": string;
  "Health_SOP_Program_Status__c": string;
  "Health_Standard_Pricelist__c": string;
  "Health_Vendor_Status__c": string;
  "IsDeleted": boolean;
  "LastModifiedById": string;
  "LastModifiedDate": Date;
  "NumDays__c": number;
  "Number_of_Incomplete_Milestones_Auto__c": string;
  "OwnerId": string;
  "PMS_to_VMS_Auto_Push_Local_Jobsite__c": boolean;
  "PPE_Hours_on_Master_Project__c": number;
  "Partner_Pricelist_from_SOP_Auto__c": string;
  "Populate_APVP__c": boolean;
  "Project_Safe_Id__c": string;
  "Push_PMS_Message_to_VMS__c": boolean;
  "Refresh__c": boolean;
  "Route_From_APVP__c": boolean;
  "SoW_Description_For_Customer__c": string;
  "SoW_Equipment_Tracking_From_Master__c": string;
  "Status_Image_OpenVComplete__c": string;
  "Status_Img_OpenLateBlocked__c": string;
  "Status_TasksOpenBlockedLate__c": string;
  "Status_TasksOpenComplete__c": string;
  "Total_Complete_Task_Count_Auto__c": string;
  "Total_Exp_Budget_from_Milestones_Auto__c": string;
  "Total_Expense__c": string;
  "Total_Hours_Budget_from_Milestones_Auto__c": string;
  "Total_Hours__c": string;
  "Total_Late_Task_Count_Auto__c": string;
  "Total_Open_Task_Count_Auto__c": string;
  "Vendor_NDA_Status__c": string;
  "Worker_Background_check_Status__c": string;
  "Worker_Drug_Test_Status__c": string;
  "Worker_Program_Used_for_backfill__c": string;
  "Worker_Project_Site_Onboarding_Status__c": string;
  "Worker_Project_Training_Status__c": string;
  "Worker_e_Verify_Status__c": string;
  "Pool_General_Health_Rating__c": string;
  "SLA_Group_Name__c": string;
  "GEO_Country_Code__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  StandardProject: Project;
  Partner: Account;
  PartnerPricelist: Pricelist;
  VendorPricelist: Pricelist;
  Vendor: Account;
  childProject: Project[];
  masterProject: Project;
  projectProfile: Project;
  projectRoutingProfile: Project;
  jobsites: Jobsite[];
  JobsiteProjectes: JobsiteProjects[];
  GeoMetro: GeoMetro;
  TalentType: TalentType;
  QuoteManagers: QuoteManager[];
  RecordType: RecordType;
  globalTalentPool: GlobalTalentPool;
  constructor(data?: ProjectInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Project`.
   */
  public static getModelName() {
    return "Project";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Project for dynamic purposes.
  **/
  public static factory(data: ProjectInterface): Project{
    return new Project(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Project',
      plural: 'Projects',
      path: 'Projects',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "APVP_Group_Number__c": {
          name: 'APVP_Group_Number__c',
          type: 'string'
        },
        "APVP_Record_Count__c": {
          name: 'APVP_Record_Count__c',
          type: 'string'
        },
        "Account__c": {
          name: 'Account__c',
          type: 'string'
        },
        "Customer_Service_Type__c": {
          name: 'Customer_Service_Type__c',
          type: 'string'
        },
        "Deadline__c": {
          name: 'Deadline__c',
          type: 'Date'
        },
        "Description__c": {
          name: 'Description__c',
          type: 'string'
        },
        "Duration__c": {
          name: 'Duration__c',
          type: 'number'
        },
        "Field_Service_Program_Type__c": {
          name: 'Field_Service_Program_Type__c',
          type: 'string'
        },
        "Jobsite_Contact_Email_Service_Desk__c": {
          name: 'Jobsite_Contact_Email_Service_Desk__c',
          type: 'string'
        },
        "Jobsite_Contact_Email_Technical_Esc__c": {
          name: 'Jobsite_Contact_Email_Technical_Esc__c',
          type: 'string'
        },
        "Jobsite_Contact_Name_Service_Desk__c": {
          name: 'Jobsite_Contact_Name_Service_Desk__c',
          type: 'string'
        },
        "Jobsite_Contact_Name_Technical_Esc__c": {
          name: 'Jobsite_Contact_Name_Technical_Esc__c',
          type: 'string'
        },
        "Jobsite_Contact_Phone_Service_Desk__c": {
          name: 'Jobsite_Contact_Phone_Service_Desk__c',
          type: 'string'
        },
        "Jobsite_Contact_Phone_Technical_Esc__c": {
          name: 'Jobsite_Contact_Phone_Technical_Esc__c',
          type: 'string'
        },
        "Jobsite_Contact_Selection__c": {
          name: 'Jobsite_Contact_Selection__c',
          type: 'string'
        },
        "Kick_off__c": {
          name: 'Kick_off__c',
          type: 'Date'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "Partner_Name_Text__c": {
          name: 'Partner_Name_Text__c',
          type: 'string'
        },
        "Partner_Pricelist__c": {
          name: 'Partner_Pricelist__c',
          type: 'string'
        },
        "Program_Activation__c": {
          name: 'Program_Activation__c',
          type: 'string'
        },
        "Progress__c": {
          name: 'Progress__c',
          type: 'string'
        },
        "Project_Standard__c": {
          name: 'Project_Standard__c',
          type: 'string'
        },
        "Project__c": {
          name: 'Project__c',
          type: 'string'
        },
        "RecordTypeId": {
          name: 'RecordTypeId',
          type: 'string'
        },
        "Resource_Pool_Type_Used_for_backfill__c": {
          name: 'Resource_Pool_Type_Used_for_backfill__c',
          type: 'string'
        },
        "Service_Dispatch_SLA_Priority__c": {
          name: 'Service_Dispatch_SLA_Priority__c',
          type: 'string'
        },
        "Service_Technical_Level__c": {
          name: 'Service_Technical_Level__c',
          type: 'string'
        },
        "SoW_Equipment_Tracking_Vendor__c": {
          name: 'SoW_Equipment_Tracking_Vendor__c',
          type: 'string'
        },
        "Status__c": {
          name: 'Status__c',
          type: 'string'
        },
        "Vendor_Pricelist__c": {
          name: 'Vendor_Pricelist__c',
          type: 'string'
        },
        "Vendor_Type__c": {
          name: 'Vendor_Type__c',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "Service_Description__c": {
          name: 'Service_Description__c',
          type: 'string'
        },
        "Special_Service_Instructions__c": {
          name: 'Special_Service_Instructions__c',
          type: 'string'
        },
        "SOW_Description_Customer_Long__c": {
          name: 'SOW_Description_Customer_Long__c',
          type: 'string'
        },
        "Required_Tools__c": {
          name: 'Required_Tools__c',
          type: 'string'
        },
        "Talent_Type__c": {
          name: 'Talent_Type__c',
          type: 'string'
        },
        "SLA__c": {
          name: 'SLA__c',
          type: 'string'
        },
        "GEO_Country__c": {
          name: 'GEO_Country__c',
          type: 'string'
        },
        "Product_Id__c": {
          name: 'Product_Id__c',
          type: 'string'
        },
        "Master_Project__c": {
          name: 'Master_Project__c',
          type: 'string'
        },
        "Vendor__c": {
          name: 'Vendor__c',
          type: 'string'
        },
        "Project_Profile__c": {
          name: 'Project_Profile__c',
          type: 'string'
        },
        "PPE_Hours__c": {
          name: 'PPE_Hours__c',
          type: 'string'
        },
        "Customer_Service_Type_Master__c": {
          name: 'Customer_Service_Type_Master__c',
          type: 'string'
        },
        "Talent_Type_From_Profile__c": {
          name: 'Talent_Type_From_Profile__c',
          type: 'string'
        },
        "Service_Dispatch_SLA_Priority_Master__c": {
          name: 'Service_Dispatch_SLA_Priority_Master__c',
          type: 'string'
        },
        "Service_Technical_Level_Master__c": {
          name: 'Service_Technical_Level_Master__c',
          type: 'string'
        },
        "Project_Routing_Profile__c": {
          name: 'Project_Routing_Profile__c',
          type: 'string'
        },
        "Global_Talent_Pool__c": {
          name: 'Global_Talent_Pool__c',
          type: 'string'
        },
        "Community_Type__c": {
          name: 'Community_Type__c',
          type: 'string'
        },
        "Distribution_Type__c": {
          name: 'Distribution_Type__c',
          type: 'string'
        },
        "temp_jobsite_sfdcId": {
          name: 'temp_jobsite_sfdcId',
          type: 'string'
        },
        "FileCounter": {
          name: 'FileCounter',
          type: 'number'
        },
        "FolderLastModifiedOn": {
          name: 'FolderLastModifiedOn',
          type: 'Date'
        },
        "Count_of_Workers__c": {
          name: 'Count_of_Workers__c',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "Customer_PPE_Hours_For_2NBD__c": {
          name: 'Customer_PPE_Hours_For_2NBD__c',
          type: 'string'
        },
        "Customer_PPE_Hours_For_3NBD__c": {
          name: 'Customer_PPE_Hours_For_3NBD__c',
          type: 'string'
        },
        "Customer_PPE_Hours_For_5NBD__c": {
          name: 'Customer_PPE_Hours_For_5NBD__c',
          type: 'string'
        },
        "Customer_PPE_Hours_For_NBD__c": {
          name: 'Customer_PPE_Hours_For_NBD__c',
          type: 'string'
        },
        "Customer_PPE_Hours_For_SBD6HR__c": {
          name: 'Customer_PPE_Hours_For_SBD6HR__c',
          type: 'string'
        },
        "Customer_PPE_Hours_for_SBD_4HR__c": {
          name: 'Customer_PPE_Hours_for_SBD_4HR__c',
          type: 'string'
        },
        "GEO_Code__c": {
          name: 'GEO_Code__c',
          type: 'string'
        },
        "GEO_Country_Code_Standard_Project_Auto__c": {
          name: 'GEO_Country_Code_Standard_Project_Auto__c',
          type: 'string'
        },
        "GEO_Metro_Count__c": {
          name: 'GEO_Metro_Count__c',
          type: 'string'
        },
        "Health_Pricelist_Status__c": {
          name: 'Health_Pricelist_Status__c',
          type: 'string'
        },
        "Health_Pricelist_Type__c": {
          name: 'Health_Pricelist_Type__c',
          type: 'string'
        },
        "Health_Project_Profile_Status__c": {
          name: 'Health_Project_Profile_Status__c',
          type: 'string'
        },
        "Health_Project_status__c": {
          name: 'Health_Project_status__c',
          type: 'string'
        },
        "Health_Routing_Status__c": {
          name: 'Health_Routing_Status__c',
          type: 'string'
        },
        "Health_SOP_Pricelist_Status__c": {
          name: 'Health_SOP_Pricelist_Status__c',
          type: 'string'
        },
        "Health_SOP_Program_Status__c": {
          name: 'Health_SOP_Program_Status__c',
          type: 'string'
        },
        "Health_Standard_Pricelist__c": {
          name: 'Health_Standard_Pricelist__c',
          type: 'string'
        },
        "Health_Vendor_Status__c": {
          name: 'Health_Vendor_Status__c',
          type: 'string'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "NumDays__c": {
          name: 'NumDays__c',
          type: 'number'
        },
        "Number_of_Incomplete_Milestones_Auto__c": {
          name: 'Number_of_Incomplete_Milestones_Auto__c',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "PMS_to_VMS_Auto_Push_Local_Jobsite__c": {
          name: 'PMS_to_VMS_Auto_Push_Local_Jobsite__c',
          type: 'boolean'
        },
        "PPE_Hours_on_Master_Project__c": {
          name: 'PPE_Hours_on_Master_Project__c',
          type: 'number'
        },
        "Partner_Pricelist_from_SOP_Auto__c": {
          name: 'Partner_Pricelist_from_SOP_Auto__c',
          type: 'string'
        },
        "Populate_APVP__c": {
          name: 'Populate_APVP__c',
          type: 'boolean'
        },
        "Project_Safe_Id__c": {
          name: 'Project_Safe_Id__c',
          type: 'string'
        },
        "Push_PMS_Message_to_VMS__c": {
          name: 'Push_PMS_Message_to_VMS__c',
          type: 'boolean'
        },
        "Refresh__c": {
          name: 'Refresh__c',
          type: 'boolean'
        },
        "Route_From_APVP__c": {
          name: 'Route_From_APVP__c',
          type: 'boolean'
        },
        "SoW_Description_For_Customer__c": {
          name: 'SoW_Description_For_Customer__c',
          type: 'string'
        },
        "SoW_Equipment_Tracking_From_Master__c": {
          name: 'SoW_Equipment_Tracking_From_Master__c',
          type: 'string'
        },
        "Status_Image_OpenVComplete__c": {
          name: 'Status_Image_OpenVComplete__c',
          type: 'string'
        },
        "Status_Img_OpenLateBlocked__c": {
          name: 'Status_Img_OpenLateBlocked__c',
          type: 'string'
        },
        "Status_TasksOpenBlockedLate__c": {
          name: 'Status_TasksOpenBlockedLate__c',
          type: 'string'
        },
        "Status_TasksOpenComplete__c": {
          name: 'Status_TasksOpenComplete__c',
          type: 'string'
        },
        "Total_Complete_Task_Count_Auto__c": {
          name: 'Total_Complete_Task_Count_Auto__c',
          type: 'string'
        },
        "Total_Exp_Budget_from_Milestones_Auto__c": {
          name: 'Total_Exp_Budget_from_Milestones_Auto__c',
          type: 'string'
        },
        "Total_Expense__c": {
          name: 'Total_Expense__c',
          type: 'string'
        },
        "Total_Hours_Budget_from_Milestones_Auto__c": {
          name: 'Total_Hours_Budget_from_Milestones_Auto__c',
          type: 'string'
        },
        "Total_Hours__c": {
          name: 'Total_Hours__c',
          type: 'string'
        },
        "Total_Late_Task_Count_Auto__c": {
          name: 'Total_Late_Task_Count_Auto__c',
          type: 'string'
        },
        "Total_Open_Task_Count_Auto__c": {
          name: 'Total_Open_Task_Count_Auto__c',
          type: 'string'
        },
        "Vendor_NDA_Status__c": {
          name: 'Vendor_NDA_Status__c',
          type: 'string'
        },
        "Worker_Background_check_Status__c": {
          name: 'Worker_Background_check_Status__c',
          type: 'string'
        },
        "Worker_Drug_Test_Status__c": {
          name: 'Worker_Drug_Test_Status__c',
          type: 'string'
        },
        "Worker_Program_Used_for_backfill__c": {
          name: 'Worker_Program_Used_for_backfill__c',
          type: 'string'
        },
        "Worker_Project_Site_Onboarding_Status__c": {
          name: 'Worker_Project_Site_Onboarding_Status__c',
          type: 'string'
        },
        "Worker_Project_Training_Status__c": {
          name: 'Worker_Project_Training_Status__c',
          type: 'string'
        },
        "Worker_e_Verify_Status__c": {
          name: 'Worker_e_Verify_Status__c',
          type: 'string'
        },
        "Pool_General_Health_Rating__c": {
          name: 'Pool_General_Health_Rating__c',
          type: 'string'
        },
        "SLA_Group_Name__c": {
          name: 'SLA_Group_Name__c',
          type: 'string'
        },
        "GEO_Country_Code__c": {
          name: 'GEO_Country_Code__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        StandardProject: {
          name: 'StandardProject',
          type: 'Project',
          model: 'Project',
          relationType: 'belongsTo',
                  keyFrom: 'Project_Standard__c',
          keyTo: 'sfdcId'
        },
        Partner: {
          name: 'Partner',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'Account__c',
          keyTo: 'sfdcId'
        },
        PartnerPricelist: {
          name: 'PartnerPricelist',
          type: 'Pricelist',
          model: 'Pricelist',
          relationType: 'belongsTo',
                  keyFrom: 'Partner_Pricelist__c',
          keyTo: 'sfdcId'
        },
        VendorPricelist: {
          name: 'VendorPricelist',
          type: 'Pricelist',
          model: 'Pricelist',
          relationType: 'belongsTo',
                  keyFrom: 'Vendor_Pricelist__c',
          keyTo: 'sfdcId'
        },
        Vendor: {
          name: 'Vendor',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'Vendor__c',
          keyTo: 'sfdcId'
        },
        childProject: {
          name: 'childProject',
          type: 'Project[]',
          model: 'Project',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'Master_Project__c'
        },
        masterProject: {
          name: 'masterProject',
          type: 'Project',
          model: 'Project',
          relationType: 'belongsTo',
                  keyFrom: 'Master_Project__c',
          keyTo: 'sfdcId'
        },
        projectProfile: {
          name: 'projectProfile',
          type: 'Project',
          model: 'Project',
          relationType: 'belongsTo',
                  keyFrom: 'Project_Profile__c',
          keyTo: 'sfdcId'
        },
        projectRoutingProfile: {
          name: 'projectRoutingProfile',
          type: 'Project',
          model: 'Project',
          relationType: 'belongsTo',
                  keyFrom: 'Project_Routing_Profile__c',
          keyTo: 'sfdcId'
        },
        jobsites: {
          name: 'jobsites',
          type: 'Jobsite[]',
          model: 'Jobsite',
          relationType: 'hasMany',
          modelThrough: 'JobsiteProjects',
          keyThrough: 'jobsiteId',
          keyFrom: 'id',
          keyTo: 'Project__c'
        },
        JobsiteProjectes: {
          name: 'JobsiteProjectes',
          type: 'JobsiteProjects[]',
          model: 'JobsiteProjects',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'Project__c'
        },
        GeoMetro: {
          name: 'GeoMetro',
          type: 'GeoMetro',
          model: 'GeoMetro',
          relationType: 'belongsTo',
                  keyFrom: 'GEO_Code__c',
          keyTo: 'sfdcId'
        },
        TalentType: {
          name: 'TalentType',
          type: 'TalentType',
          model: 'TalentType',
          relationType: 'belongsTo',
                  keyFrom: 'Talent_Type__c',
          keyTo: 'sfdcId'
        },
        QuoteManagers: {
          name: 'QuoteManagers',
          type: 'QuoteManager[]',
          model: 'QuoteManager',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'Project_SOP__c'
        },
        RecordType: {
          name: 'RecordType',
          type: 'RecordType',
          model: 'RecordType',
          relationType: 'belongsTo',
                  keyFrom: 'RecordTypeId',
          keyTo: 'sfdcId'
        },
        globalTalentPool: {
          name: 'globalTalentPool',
          type: 'GlobalTalentPool',
          model: 'GlobalTalentPool',
          relationType: 'belongsTo',
                  keyFrom: 'Global_Talent_Pool__c',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
