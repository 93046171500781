/* tslint:disable */
import {
  Worker,
  Project
} from '../index';

declare var Object: any;
export interface ApvpDistributionListWorkersInterface {
  "LastModifiedBy"?: string;
  "CreatedById"?: string;
  "Name"?: string;
  "sfdcId"?: string;
  "CurrencyIsoCode"?: string;
  "Project_Worker_Rating__c"?: number;
  "Rating_Weighted_Average_Auto__c"?: number;
  "Routing_Profile__c"?: string;
  "Worker__c"?: string;
  "Worker_Account__c"?: string;
  "Worker_Contact_Type__c"?: string;
  "Worker_Name__c"?: string;
  "Worker_Registration_Status__c"?: string;
  "CreatedDate"?: Date;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  worker?: Worker;
  routingProfile?: Project;
}

export class ApvpDistributionListWorkers implements ApvpDistributionListWorkersInterface {
  "LastModifiedBy": string;
  "CreatedById": string;
  "Name": string;
  "sfdcId": string;
  "CurrencyIsoCode": string;
  "Project_Worker_Rating__c": number;
  "Rating_Weighted_Average_Auto__c": number;
  "Routing_Profile__c": string;
  "Worker__c": string;
  "Worker_Account__c": string;
  "Worker_Contact_Type__c": string;
  "Worker_Name__c": string;
  "Worker_Registration_Status__c": string;
  "CreatedDate": Date;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  worker: Worker;
  routingProfile: Project;
  constructor(data?: ApvpDistributionListWorkersInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ApvpDistributionListWorkers`.
   */
  public static getModelName() {
    return "ApvpDistributionListWorkers";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ApvpDistributionListWorkers for dynamic purposes.
  **/
  public static factory(data: ApvpDistributionListWorkersInterface): ApvpDistributionListWorkers{
    return new ApvpDistributionListWorkers(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ApvpDistributionListWorkers',
      plural: 'ApvpDistributionListWorkers',
      path: 'ApvpDistributionListWorkers',
      idName: 'id',
      properties: {
        "LastModifiedBy": {
          name: 'LastModifiedBy',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "Project_Worker_Rating__c": {
          name: 'Project_Worker_Rating__c',
          type: 'number'
        },
        "Rating_Weighted_Average_Auto__c": {
          name: 'Rating_Weighted_Average_Auto__c',
          type: 'number'
        },
        "Routing_Profile__c": {
          name: 'Routing_Profile__c',
          type: 'string'
        },
        "Worker__c": {
          name: 'Worker__c',
          type: 'string'
        },
        "Worker_Account__c": {
          name: 'Worker_Account__c',
          type: 'string'
        },
        "Worker_Contact_Type__c": {
          name: 'Worker_Contact_Type__c',
          type: 'string'
        },
        "Worker_Name__c": {
          name: 'Worker_Name__c',
          type: 'string'
        },
        "Worker_Registration_Status__c": {
          name: 'Worker_Registration_Status__c',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        worker: {
          name: 'worker',
          type: 'Worker',
          model: 'Worker',
          relationType: 'belongsTo',
                  keyFrom: 'Worker__c',
          keyTo: 'sfdcId'
        },
        routingProfile: {
          name: 'routingProfile',
          type: 'Project',
          model: 'Project',
          relationType: 'belongsTo',
                  keyFrom: 'Routing_Profile__c',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
