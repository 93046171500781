import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { category } from '../../../models/static-list-data.service'

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {

  categoryList = [];
  categorySelected = [];
  isMultipleSelected = true;

  @Input() set resetData(isClear) {
    if (isClear) {
      this.categorySelected = [];
    }
  }

  @Output() selectedCategory: EventEmitter<any> = new EventEmitter;

  constructor() { }

  ngOnInit() {
    this.categoryList = category;
    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
    this.categorySelected = (preselected &&
      preselected['category']) ? preselected['category'] : '';
  }
  onCategoryChange() {
    this.selectedCategory.emit(this.categorySelected);
  }
  clearCategory() {
    this.categorySelected = [];
    this.selectedCategory.emit([]);
  }

}
