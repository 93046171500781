import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { actualCoverageHourValues } from '../../../models/static-list-data.service';

@Component({
  selector: 'app-actual-coverage-hour-lookup',
  templateUrl: './actual-coverage-hour-lookup.component.html',
  styleUrls: ['./actual-coverage-hour-lookup.component.css']
})
export class ActualCoverageHourLookupComponent implements OnInit {

  actualCoverageHourList = [];
  actualCoverageHourTypeSelected = [];
  isMultipleSelected = true;

  @Input() set resetData(isClear) {
    if (isClear) {
      this.actualCoverageHourTypeSelected = [];
    }
  }
  @Input() filterObjValue = 'actualCoverageHours';
  @Output() selectedActualCoverageHour: EventEmitter<any> = new EventEmitter;

  constructor() { }

  ngOnInit() {
    this.actualCoverageHourList = actualCoverageHourValues;
    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
    this.actualCoverageHourTypeSelected = (preselected && preselected[this.filterObjValue]) ? preselected[this.filterObjValue] : '';
  }
  onActualCoverageHourChange() {
    this.selectedActualCoverageHour.emit(this.actualCoverageHourTypeSelected);
  }
  clearActualCoverageHour() {
    this.actualCoverageHourTypeSelected = [];
    this.selectedActualCoverageHour.emit([]);
  }
}
