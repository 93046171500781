/* tslint:disable */

declare var Object: any;
export interface AchievementInterface {
  "sfdcId"?: string;
  "OwnerId"?: string;
  "IsDeleted"?: boolean;
  "Name"?: string;
  "CurrencyIsoCode"?: string;
  "CreatedDate"?: Date;
  "CreatedById"?: string;
  "LastModifiedDate"?: Date;
  "LastModifiedById"?: string;
  "LastActivityDate"?: Date;
  "LastViewedDate"?: Date;
  "LastReferencedDate"?: Date;
  "Achievement_Category__c"?: string;
  "Acquired_Image__c"?: string;
  "Eligible_Image__c"?: string;
  "Expiration_Date__c"?: Date;
  "Hidden__c"?: boolean;
  "Is_Internal__c"?: boolean;
  "Override_Completed_Status__c"?: string;
  "Override_URL__c"?: string;
  "Type__c"?: string;
  "Work_Badge_Giver__c"?: string;
  "Work_Badge_Message__c"?: string;
  "Work_Badge_Id__c"?: string;
  "Work_Badge_Image_URL__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class Achievement implements AchievementInterface {
  "sfdcId": string;
  "OwnerId": string;
  "IsDeleted": boolean;
  "Name": string;
  "CurrencyIsoCode": string;
  "CreatedDate": Date;
  "CreatedById": string;
  "LastModifiedDate": Date;
  "LastModifiedById": string;
  "LastActivityDate": Date;
  "LastViewedDate": Date;
  "LastReferencedDate": Date;
  "Achievement_Category__c": string;
  "Acquired_Image__c": string;
  "Eligible_Image__c": string;
  "Expiration_Date__c": Date;
  "Hidden__c": boolean;
  "Is_Internal__c": boolean;
  "Override_Completed_Status__c": string;
  "Override_URL__c": string;
  "Type__c": string;
  "Work_Badge_Giver__c": string;
  "Work_Badge_Message__c": string;
  "Work_Badge_Id__c": string;
  "Work_Badge_Image_URL__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: AchievementInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Achievement`.
   */
  public static getModelName() {
    return "Achievement";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Achievement for dynamic purposes.
  **/
  public static factory(data: AchievementInterface): Achievement{
    return new Achievement(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Achievement',
      plural: 'Achievements',
      path: 'Achievements',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "LastActivityDate": {
          name: 'LastActivityDate',
          type: 'Date'
        },
        "LastViewedDate": {
          name: 'LastViewedDate',
          type: 'Date'
        },
        "LastReferencedDate": {
          name: 'LastReferencedDate',
          type: 'Date'
        },
        "Achievement_Category__c": {
          name: 'Achievement_Category__c',
          type: 'string'
        },
        "Acquired_Image__c": {
          name: 'Acquired_Image__c',
          type: 'string'
        },
        "Eligible_Image__c": {
          name: 'Eligible_Image__c',
          type: 'string'
        },
        "Expiration_Date__c": {
          name: 'Expiration_Date__c',
          type: 'Date'
        },
        "Hidden__c": {
          name: 'Hidden__c',
          type: 'boolean'
        },
        "Is_Internal__c": {
          name: 'Is_Internal__c',
          type: 'boolean'
        },
        "Override_Completed_Status__c": {
          name: 'Override_Completed_Status__c',
          type: 'string'
        },
        "Override_URL__c": {
          name: 'Override_URL__c',
          type: 'string'
        },
        "Type__c": {
          name: 'Type__c',
          type: 'string'
        },
        "Work_Badge_Giver__c": {
          name: 'Work_Badge_Giver__c',
          type: 'string'
        },
        "Work_Badge_Message__c": {
          name: 'Work_Badge_Message__c',
          type: 'string'
        },
        "Work_Badge_Id__c": {
          name: 'Work_Badge_Id__c',
          type: 'string'
        },
        "Work_Badge_Image_URL__c": {
          name: 'Work_Badge_Image_URL__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
