/* tslint:disable */

declare var Object: any;
export interface ResponseInterface {
  "sfdcId"?: string;
  "OwnerId"?: string;
  "IsDeleted"?: boolean;
  "CreatedDate"?: Date;
  "CreatedById"?: string;
  "CurrencyIsoCode"?: string;
  "LastModifiedDate"?: Date;
  "LastModifiedById"?: string;
  "Name"?: string;
  "SystemModstamp"?: Date;
  "LastActivityDate"?: Date;
  "LastViewedDate"?: Date;
  "LastReferencedDate"?: Date;
  "Boolean_Response__c"?: string;
  "Contact__c"?: string;
  "Correct_Response__c"?: boolean;
  "Learning_Assignment__c"?: string;
  "Number_Response__c"?: number;
  "Question_Type__c"?: string;
  "Question__c"?: string;
  "Response_Answer_Sequence_Label__c"?: string;
  "Response_Answer_Sequence_Long__c"?: string;
  "Response_Text__c"?: string;
  "Response_Text_Long__c"?: string;
  "Score__c"?: number;
  "Shared_Learning_Assignment__c"?: string;
  "Trainee__c"?: string;
  "CreatedBy__c"?: string;
  "Application_Assesment__c"?: string;
  "Answer_Notes__c"?: string;
  "Comments__c"?: string;
  "Interview_Feedback_Form__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class Response implements ResponseInterface {
  "sfdcId": string;
  "OwnerId": string;
  "IsDeleted": boolean;
  "CreatedDate": Date;
  "CreatedById": string;
  "CurrencyIsoCode": string;
  "LastModifiedDate": Date;
  "LastModifiedById": string;
  "Name": string;
  "SystemModstamp": Date;
  "LastActivityDate": Date;
  "LastViewedDate": Date;
  "LastReferencedDate": Date;
  "Boolean_Response__c": string;
  "Contact__c": string;
  "Correct_Response__c": boolean;
  "Learning_Assignment__c": string;
  "Number_Response__c": number;
  "Question_Type__c": string;
  "Question__c": string;
  "Response_Answer_Sequence_Label__c": string;
  "Response_Answer_Sequence_Long__c": string;
  "Response_Text__c": string;
  "Response_Text_Long__c": string;
  "Score__c": number;
  "Shared_Learning_Assignment__c": string;
  "Trainee__c": string;
  "CreatedBy__c": string;
  "Application_Assesment__c": string;
  "Answer_Notes__c": string;
  "Comments__c": string;
  "Interview_Feedback_Form__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: ResponseInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Response`.
   */
  public static getModelName() {
    return "Response";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Response for dynamic purposes.
  **/
  public static factory(data: ResponseInterface): Response{
    return new Response(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Response',
      plural: 'Responses',
      path: 'Responses',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "SystemModstamp": {
          name: 'SystemModstamp',
          type: 'Date'
        },
        "LastActivityDate": {
          name: 'LastActivityDate',
          type: 'Date'
        },
        "LastViewedDate": {
          name: 'LastViewedDate',
          type: 'Date'
        },
        "LastReferencedDate": {
          name: 'LastReferencedDate',
          type: 'Date'
        },
        "Boolean_Response__c": {
          name: 'Boolean_Response__c',
          type: 'string'
        },
        "Contact__c": {
          name: 'Contact__c',
          type: 'string'
        },
        "Correct_Response__c": {
          name: 'Correct_Response__c',
          type: 'boolean'
        },
        "Learning_Assignment__c": {
          name: 'Learning_Assignment__c',
          type: 'string'
        },
        "Number_Response__c": {
          name: 'Number_Response__c',
          type: 'number'
        },
        "Question_Type__c": {
          name: 'Question_Type__c',
          type: 'string'
        },
        "Question__c": {
          name: 'Question__c',
          type: 'string'
        },
        "Response_Answer_Sequence_Label__c": {
          name: 'Response_Answer_Sequence_Label__c',
          type: 'string'
        },
        "Response_Answer_Sequence_Long__c": {
          name: 'Response_Answer_Sequence_Long__c',
          type: 'string'
        },
        "Response_Text__c": {
          name: 'Response_Text__c',
          type: 'string'
        },
        "Response_Text_Long__c": {
          name: 'Response_Text_Long__c',
          type: 'string'
        },
        "Score__c": {
          name: 'Score__c',
          type: 'number'
        },
        "Shared_Learning_Assignment__c": {
          name: 'Shared_Learning_Assignment__c',
          type: 'string'
        },
        "Trainee__c": {
          name: 'Trainee__c',
          type: 'string'
        },
        "CreatedBy__c": {
          name: 'CreatedBy__c',
          type: 'string'
        },
        "Application_Assesment__c": {
          name: 'Application_Assesment__c',
          type: 'string'
        },
        "Answer_Notes__c": {
          name: 'Answer_Notes__c',
          type: 'string'
        },
        "Comments__c": {
          name: 'Comments__c',
          type: 'string'
        },
        "Interview_Feedback_Form__c": {
          name: 'Interview_Feedback_Form__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
