import { OnInit, Component, Input, EventEmitter, Output } from '@angular/core';
import { CommonService } from 'shared/services/common.service';

@Component({
  selector: 'app-date-lookup',
  templateUrl: './date-lookup.component.html',
  styleUrls: ['./date-lookup.component.css']
})
export class DateLookupComponent implements OnInit {
  DateOpt: any;

  @Input() placeholder = 'Start Date (From)';
  @Input() set resetData(isClear) {
    this.selectedDate = {};
  }
  @Input() set setDate(params) {
    this.DateOpt = params;
    console.log('check Date', this.DateOpt);
    if (this.DateOpt !== null && this.DateOpt !== undefined && this.DateOpt !== '') {
    }
}



  @Output() selectedValue: EventEmitter<any> = new EventEmitter;
  selectedDate: any = {};
  // max = new Date();

  constructor(private _commonService: CommonService) { }
  ngOnInit() {
    // to show selected date range
    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
    if (this.DateOpt === 'DateTo') {
      if (preselected && preselected['dateTo'] && preselected['dateTo'][0]) {
        this.selectedDate = this._commonService.applyNewTimeZone(preselected['dateTo'][0][0], 'YYYY-MM-DDTHH:mm:ss');
      }
    } else {
    if (preselected && preselected['dateFrom'] && preselected['dateFrom'][0]) {
      this.selectedDate = this._commonService.applyNewTimeZone(preselected['dateFrom'][0][0], 'YYYY-MM-DDTHH:mm:ss');
    }
  }
  }
  onChange() {
    this.selectedValue.emit(this.selectedDate);
  }

  onClearDate() {
    this.selectedDate = {};
    this.selectedValue.emit([]);
  }

  checkDateSelected() {
    if (this.selectedDate && Date.parse(this.selectedDate).toString().length) {
      return true;
    }
    return false;
  }

}
