import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-service-manager-summary',
  templateUrl: './service-manager-summary.component.html',
  styleUrls: ['./service-manager-summary.component.css']
})
export class ServiceManagerSummaryComponent implements OnInit {

  @Input() caseData;

  selectedTab: String = 'status';

  constructor() { }

  ngOnInit() {
  }

  selectMenu = (menu) => {
    if (menu) { this.selectedTab = menu };
  }

}
