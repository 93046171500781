import { map } from 'rxjs/operators';
import { Component, OnInit, Output, EventEmitter, Input, ChangeDetectorRef } from '@angular/core';
import { AppStateService } from 'shared/services/app-state.service';
import { CommonService } from 'shared/services/common.service';
import { PMSCaseStatus } from 'shared/models/static-list-data.service';

@Component({
  selector: 'app-pms-global-filters',
  templateUrl: './pms-global-filters.component.html',
  styleUrls: ['./pms-global-filters.component.css']
})
export class PmsGlobalFiltersComponent implements OnInit {
  @Output() closeFilter = new EventEmitter();
  @Output() searchGlobalFilter = new EventEmitter();
  @Input() displayFooter = false;
  @Input() page: string;
  _enableFilters = [];
  @Input()
  set enableFilters(e) {
    if (e) {
      this.loadFilters = true;
      this._enableFilters = e;
      if (localStorage.getItem("filterObj")) {
        this.filterObj = JSON.parse(localStorage.getItem("filterObj"));
        this.setTotalFilterCount();
      }
    }
  }


  loadFilters = true;
  // to show count per selected option
  countSelected = {};
  userType: any;
  isClear: any;
  filterCondition = {};
  setPartnerAccountObj: any;
  setProgramObj: any;
  setJobsiteProgramObj: any;
  setJobsiteAccountObj: any;
  jobIds: any;
  stageStatuses: any;
  setWorkerObj: any;
  setVendorsiteObj: any
  totalFilterCount = 0;
  filterObj = {
    countries: [],
    regions: [],
    orderTypes: [],
    accounts: [],
    vendorAccounts: [],
    programs: [],
    projectTypes: [],
    teamProjectTypes: [],
    projects: [],
    milestones: [],
    tasks: [],
    taskStatuses: [],
    taskRequestors: [],
    taskTypes: [],
    talentTypes: [],
    statgeStatus: [],
    jobs: [],
    jobSites: [],
    jobTypes: [],
    caseTypes: [],
    recordTypeIds: [],
    caseCategory: [],
    vendorSites: [],
    priority: [],
    jobStatus: [],
    geoMetro: [],
    geoMetroJobSite: [],
    workerNames: [],
    dateOptions: [],
    pmsCaseStatus: [],
    teamShifts: [],
    teamShiftUsers: [],
    pmsCaseNumbers: [],
    pmcCaseNumbers: [],
    escalationType: [],
    taskAccounts: [],
    employeeType: [],
    ironApprovalStatuses: [],
    customerApprovalStatuses: [],
    actualCoverageHours: [],
    actualEndTimeCoverages: [],
    actualCoverageOveralls: [],
    workerBillingTypes: [],
    serviceContractNumbers: [],
    serviceContractCategory: [],
    purchaseInvoiceStatus: [],
    divisions: [],
    departments: [],
    departmentRoleAccesses: [],
    pgmo_departments: [],
    expenseTypes: [],
    expenseSubTypes: [],
    paymentTypes: [],
    contractCategory: [],
    accountCommunities: [],
    states: [],
    activityType: [],
    taskPriorities: [],
    users: [],
    teams: [],
    teamTypes: [],
    dateFrom: [],
    dateTo: [],
    startFromDateOptions: [],
    customerDateOptions: [],
    customers: [],
    subCategories: [],
    workOrderCaseIds: [],
    templateName: [],
    templateCategories: [],
    templateSubCategories: [],
    apvpGroup: [],
    communityType: [],
    slaGroup: [],
    wslAccountStatus: [],
    address: [],
    workerStatus: [],
    radius: [],
    driving_distance: [],
    salary: [],
    gtpResourceType: [],
    gtpPoolAccessType: [],
    gtpMemberType: [],
    gtpCommunityType: [],
    backFillWorkerService: [],
    displayCoverageType: [],
    vendorSubTypeCommunity: [],
    covrageHoursAccountType: [],
    slaAccountType: [],
    geoCode: [],
    city: [],
    vendorStatusType: [],
    mvvpDisplayType: [],
    workerTalentTypes: [],
    slaLabourCloudType: [],
    coverHoursMvvp: [],
    priceListCoverageHours: [],
    priceListTechnicalLevel: [],
    priceListSLA: [],
    servicetalentTypes: [],
  };
  initialLoad = false;
  constructor(private _appState: AppStateService,
    private _commonService: CommonService,
    private _cd: ChangeDetectorRef) { }

  ngOnInit() {
    this.initialLoad = !this.initialLoad;
    this.userType = this._appState.getAccessType();
    if (this.userType === 'vendor') {
      this.setPartnerAccountObj = this._appState.getSelectedAccount();
    } else {
      this.setPartnerAccountObj = '';
    }
    if (localStorage.getItem("filterObj")) {
      this.filterObj = JSON.parse(localStorage.getItem("filterObj"));
      this.setTotalFilterCount();
    }
  }

  /**
   * hit on apply filter
   */
  filterData() {
    if (this.filterObj && Object.keys(this.filterObj).length) {
      // set local storage for mantain filter page selected
      localStorage.setItem("filterObj", JSON.stringify(this.filterObj));
      this.setTotalFilterCount();
      // bake whereObj 
      this.prepareFilterObj(this.filterObj);
    } else {
      // localStorage.setItem('filterObj', JSON.stringify(this.filterObj));
      // this.searchGlobalFilter.emit({});
    }

  }
  /**
   * 
   * @param filterObj | bake all stuff to search data
   */
  prepareFilterObj(filterObj) {
    let filterCondition = {};
    // if (localStorage.getItem('filterCondition')
    //   && JSON.parse(localStorage.getItem('filterCondition'))
    //   && Object.keys(JSON.parse(localStorage.getItem('filterCondition'))).length) {
    //   filterCondition = JSON.parse(localStorage.getItem('filterCondition'));
    // }
    if (filterObj.dateOptions && filterObj.dateOptions[0] && this._enableFilters.indexOf("dateOptions") > -1) {
      const startDate = filterObj.dateOptions[0];
      filterCondition["CreatedDate"] = {
        between: [startDate[0], startDate[1]],
      };
    }
    if (filterObj.contractCategory && filterObj.contractCategory.length && this._enableFilters.indexOf("contractCategory") > -1) {
      filterCondition['ContractCategory'] = { inq: filterObj.contractCategory };
    }
    if (filterObj.orderTypes && filterObj.orderTypes.length && this._enableFilters.indexOf("orderTypes") > -1) {
      filterCondition['OrderType'] = { inq: filterObj.orderTypes.map(item => item.sfdcId) };
    }
    if (filterObj.regions && filterObj.regions.length && this._enableFilters.indexOf("regions") > -1) {
      filterCondition['Region'] = { inq: filterObj.regions.map(item => item.sfdcId) };
    }
    if (filterObj.countries && filterObj.countries.length && this._enableFilters.indexOf("countries") > -1) {
      filterCondition['CountryCode'] = { inq: filterObj.countries.map(item => item.Name) };
    }
    if (filterObj.accounts && filterObj.accounts.length && this._enableFilters.indexOf("accounts") > -1) {
      filterCondition['CKSW_BASE__Account__c'] = { inq: filterObj.accounts };
    }
    if (filterObj.programs && filterObj.programs.length && this._enableFilters.indexOf("programs") > -1) {
      filterCondition['Project_SOP__c'] = { inq: filterObj.programs };
    }
    if (filterObj.jobSites && filterObj.jobSites.length && this._enableFilters.indexOf("jobSites") > -1) {
      filterCondition['Jobsite__c'] = { inq: filterObj.jobSites };
    }
    if (filterObj.caseCategory && filterObj.caseCategory.length && this._enableFilters.indexOf("caseCategory") > -1) {
      filterCondition['RecordTypeId'] = { inq: filterObj.caseCategory };
    }
    if (filterObj.workOrderCaseIds && filterObj.workOrderCaseIds.length && this._enableFilters.indexOf("workOrderCaseIds") > -1) {
      filterCondition['Job_Order__c'] = { inq: filterObj.workOrderCaseIds };
    }
    // It's use for Team Shift
    // if (filterObj.teamShifts && filterObj.teamShiftUsers.length) {
    //   let workerSfdcIds = [];
    //   workerSfdcIds = workerSfdcIds.concat(filterObj.workerNames, filterObj.teamShiftUsers);
    //   workerSfdcIds = Array.from(new Set(workerSfdcIds));
    //   filterCondition['Dispatch_Worker_Name__c'] = { inq: workerSfdcIds };
    // }

    if (filterObj.pmsCaseStatus && filterObj.pmsCaseStatus.length && this._enableFilters.indexOf("pmsCaseStatus") > -1) {
      filterCondition['PMS_Case_Status'] = { inq: filterObj.pmsCaseStatus };
    }
    /// direct search from case table pmsCaseNumbers
    if (filterObj.pmsCaseNumbers && filterObj.pmsCaseNumbers.length && this._enableFilters.indexOf("pmsCaseNumbers") > -1) {
      filterCondition["PMS_Case_Number"] = { inq: filterObj.pmsCaseNumbers };
    }
    if (filterObj.jobs && filterObj.jobs.length && this._enableFilters.indexOf("jobs") > -1) {
      filterCondition["Iron_Job_num__c"] = { inq: filterObj.jobs };
    }

    // if (filterObj.talentTypes && filterObj.talentTypes.length && this._enableFilters.indexOf('talentTypes') > -1) {
    //   filterCondition['Talent_Type__c'] = { inq: filterObj.talentTypes };
    // }

    if (filterObj.priceListCoverageHours && filterObj.priceListCoverageHours.length && this._enableFilters.indexOf('priceListCoverageHours') > -1) {
      filterCondition['Coverage_Hours__c'] = { inq: filterObj.priceListCoverageHours };
    }

    if (filterObj.priceListTechnicalLevel && filterObj.priceListTechnicalLevel.length && this._enableFilters.indexOf('priceListTechnicalLevel') > -1) {
      filterCondition['Service_Technical_Level__c'] = { inq: filterObj.priceListTechnicalLevel };
    }

    if (filterObj.priceListSLA && filterObj.priceListSLA.length && this._enableFilters.indexOf('priceListSLA') > -1) {
      filterCondition['SLA__c'] = { inq: filterObj.priceListSLA };
    }

    if (filterObj.servicetalentTypes && filterObj.servicetalentTypes.length && this._enableFilters.indexOf('servicetalentTypes') > -1) {
      filterCondition['Service_Talent_Type__c'] = { inq: filterObj.priceListSLA };
    }

    // set local storage for mantain search filter on page changed
    this._commonService.setGlobalFilter(filterObj);
    // localStorage.setItem('filterCondition', JSON.stringify(filterCondition));
    // applied search hit api 
    this.searchGlobalFilter.emit(filterCondition);
  }

  /**
   * 
   * @param fields 
   * return total applied filter
   */
  setTotalFilterCount() {
    this.totalFilterCount = 0;
    const savedFilterLookups = JSON.parse(localStorage.getItem('filterObj'));
    if (savedFilterLookups && Object.keys(savedFilterLookups).length) {
      if (this._enableFilters && this._enableFilters.length) {
        this._enableFilters.forEach((f) => {
          this.totalFilterCount += savedFilterLookups[f] && savedFilterLookups[f].length ? savedFilterLookups[f].length : 0;
        });
      }
    }
  }

  /**
   * to close main component
   * global filters
   */
  closeNav() {
    this.closeFilter.emit();
  }

  /**
   *
   * @param event
   * field: CreatedDate
   */
  setCreatedDateFilter(selectedRange, from?, cb?) {
    // date is an array, from date
    if (selectedRange && selectedRange.length) {
      // logic for the same day
      // if (!(selectedRange[0] - selectedRange[1])) {
      //   selectedRange[1].setHours(23, 59, 59);
      // }
      let _dateFrom =
        selectedRange && selectedRange[0] ? selectedRange[0].setSeconds(0) : "";
      let _dateTo = "";
      if (selectedRange && selectedRange[1]) {
        selectedRange[1].setHours(23);
        selectedRange[1].setMinutes(59);
        selectedRange[1].setSeconds(59);
        _dateTo = selectedRange[1].getTime();
      }
      if (_dateFrom && _dateTo) {
        const timeZoneDate = this._commonService.getDateInterval(
          _dateFrom,
          _dateTo,
          "",
          "YYYY-MM-DDTHH:mm:ssZZ"
        );
        let dateFrom = timeZoneDate["start"];
        let dateTo = timeZoneDate["end"];
        // in case of reset, date will be an empty array
        if (from && from === 'startFromDate') {
          dateFrom = dateFrom ? dateFrom.substring(0, 10) : '';
          dateTo = dateTo ? dateTo.substring(0, 10) : '';
          if (dateFrom && dateTo) {
            this.filterObj['startFromDateOptions'] = [{ 0: dateFrom, 1: dateTo }];
          } else if (dateFrom) {
            this.filterObj['startFromDateOptions'] = [{ 0: dateFrom }];
          } else if (dateTo) {
            this.filterObj['startFromDateOptions'] = [{ 1: dateTo }];
          }
        } else {
          if (dateFrom && dateTo) {
            this.filterObj['dateOptions'] = [{ 0: dateFrom, 1: dateTo }];
          } else if (dateFrom) {
            this.filterObj['dateOptions'] = [{ 0: dateFrom }];
          } else if (dateTo) {
            this.filterObj['dateOptions'] = [{ 1: dateTo }];
          }
        }
      }
      if (cb) {
        cb();
      }
    } else {
      if (from && from === 'startFromDate') {
        this.filterObj['startFromDateOptions'] = [];
      } else {
        this.filterObj['dateOptions'] = [];
      }
      if (cb) {
        cb();
      }
    }
  }

  /**
   * 
   * @param event | pass selected account
   * field : CKSW_BASE__Account__c
   */
  setPartnerAccountFilter(event) {
    this.filterObj['accounts'] = event;
    this.setProgramObj = event;
    this.setJobsiteAccountObj = event;
    this._cd.detectChanges();
    // if (event && event.length === 0) {
    if (this.initialLoad) {
      this.initialLoad = !this.initialLoad;
    } else {
      this.filterObj['jobSites'] = [];
      this.filterObj['programs'] = [];
    }
    // }
  }

  /**
   * 
   * @param event | pass selected program
   * field : Project_SOP__c
   */
  setProgramFilter(event) {
    this.filterObj['programs'] = event;
    this.setJobsiteProgramObj = event;
    // if (event && event.length === 0) {
    this.filterObj['jobSites'] = [];
    // }
  }

  /**
   * 
   * @param jobsite | pass selected jobsite
   * field : Jobsite__c
   */
  setJobsiteFilter(event) {
    this.filterObj['jobSites'] = event;
    // this.setJobsiteObj = event;
  }
  /**
   *
   * @param jobIds | pass selected jobids
   * field : Iron_Job_num__c
   */

  setJobIdsFilter(event) {
    this.filterObj["jobs"] = event;
  }
  /**
   * 
   * @param event 
   * field : PMS_Case_Status
   */
  setPMSCaseStatusFilter(event) {
    if (event[0] === 'All') {
      const val = [];
      PMSCaseStatus.forEach(elem => {
        if (elem.value !== 'All') {
          val.push(elem.value)
        }
      })

      this.filterObj['pmsCaseStatus'] = val;
    } else {
      this.filterObj['pmsCaseStatus'] = event;
    }
  }

  setPMSCaseNumIdsFilter(event) {
    this.filterObj["pmsCaseNumbers"] = event;
  }

  setWOCaseIdsFilter(event) {
    this.filterObj["workOrderCaseIds"] = event;
  }
  /**
   * 
   * @param event 
   * field : RecordTypeId
   */
  setCaseCategoryFilter(event) {
    this.filterObj['caseCategory'] = event;
  }
  /**
   * 
   * @param event | team shift will be goes with worker
   * field: Dispatch_Worker_Name__c
   */
  // setTeamShiftFilter(event) {
  //   this.filterObj['teamShifts'] = event && event['teamShifts'] ? event['teamShifts'] : [];
  //   this.filterObj['teamShiftUsers'] = event && event['teamShiftUsers'] ? event['teamShiftUsers'] : [];
  // }

  selectedRegion(event) {
    this.filterObj['regions'] = event;
  }


  selectedCountry(event) {
    this.filterObj['countries'] = event;
  }

  selectedOrderTypes(event) {
    this.filterObj['orderTypes'] = event;
  }

  selectedContractCategory(event) {
    this.filterObj['contractCategory'] = event;
  }

   /**
   *
   * @param event
   * field : Service_Talent_Type__c
   */
  setServiceTalentTypeFilter(event) {
    this.filterObj['servicetalentTypes'] = event;
  }
  
  /**
   *
   * @param event
   * field : Coverage_Hours__c
   */
  setCoverageHoursPriceList(event) {
    this.filterObj['priceListCoverageHours'] = event;
  }

  /**
   *
   * @param event
   * field : Service_Technical_Level__c
   */
  setTechnicalLevelPriceList(event) {
    this.filterObj['priceListTechnicalLevel'] = event;
  }


  /**
   *
   * @param event
   * field : SLA__c
   */
  setSLAPriceList(event) {
    this.filterObj['priceListSLA'] = event;
  }

  /**`
   * reset all component data 
   * reset filterConditions
   */
  resetData() {
    this.isClear = new Date().getTime();
    this.filterCondition = {};
    this.countSelected = {};
    this.totalFilterCount = (this.userType === 'partner' && this.page !== 'pricelist') ? 1 : 0;
    this.filterObj = {
      countries: [],
      regions: [],
      orderTypes: [],
      accounts: this.userType === 'partner' ? this.filterObj.accounts : [],
      vendorAccounts: [],
      programs: [],
      projectTypes: [],
      teamProjectTypes: [],
      projects: [],
      milestones: [],
      tasks: [],
      taskStatuses: [],
      taskRequestors: [],
      taskTypes: [],
      talentTypes: [],
      statgeStatus: [],
      jobs: [],
      jobSites: [],
      jobTypes: [],
      caseTypes: [],
      recordTypeIds: [],
      caseCategory: [],
      vendorSites: [],
      priority: [],
      jobStatus: [],
      geoMetro: [],
      geoMetroJobSite: [],
      workerNames: [],
      dateOptions: [],
      pmsCaseStatus: [],
      teamShifts: [],
      teamShiftUsers: [],
      pmsCaseNumbers: [],
      pmcCaseNumbers: [],
      escalationType: [],
      taskAccounts: [],
      employeeType: [],
      ironApprovalStatuses: [],
      customerApprovalStatuses: [],
      actualCoverageHours: [],
      actualEndTimeCoverages: [],
      actualCoverageOveralls: [],
      workerBillingTypes: [],
      serviceContractNumbers: [],
      serviceContractCategory: [],
      purchaseInvoiceStatus: [],
      divisions: [],
      departments: [],
      departmentRoleAccesses: [],
      pgmo_departments: [],
      expenseTypes: [],
      expenseSubTypes: [],
      paymentTypes: [],
      contractCategory: [],
      accountCommunities: [],
      states: [],
      activityType: this.userType === 'partner' ? this.filterObj.activityType : [],
      taskPriorities: [],
      users: [],
      teams: [],
      teamTypes: [],
      dateFrom: [],
      dateTo: [],
      startFromDateOptions: [],
      customerDateOptions: [],
      customers: [],
      subCategories: [],
      workOrderCaseIds: [],
      templateName: [],
      templateCategories: [],
      templateSubCategories: [],
      apvpGroup: [],
      communityType: [],
      slaGroup: [],
      wslAccountStatus: [],
      address: [],
      workerStatus: [],
      radius: [],
      driving_distance: [],
      salary: [],
      gtpResourceType: [],
      gtpPoolAccessType: [],
      gtpMemberType: [],
      gtpCommunityType: [],
      backFillWorkerService: [],
      displayCoverageType: [],
      vendorSubTypeCommunity: [],
      covrageHoursAccountType: [],
      slaAccountType: [],
      geoCode: [],
      city: [],
      vendorStatusType: [],
      mvvpDisplayType: [],
      workerTalentTypes: [],
      slaLabourCloudType: [],
      coverHoursMvvp: [],
      priceListCoverageHours: [],
      priceListTechnicalLevel: [],
      priceListSLA: [],
      servicetalentTypes: [],
    }
  }

}
