import { RightSidebarModule } from './../../../components/right-sidebar/right-sidebar.module';
import { PipeModule } from './../../../pipe/pipe.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { JobMessageConsoleComponent } from './../job-message-console/job-message-console.component';
import { MessageModule } from './../../../components/message/message.module';
import { FeedModule } from './../../../components/feed/feed.module';
import { JobDataCardModule } from './../../../components/job-data-card/job-data-card.module'
import { GlobalFiltersModule } from './../../../components/global-filters/global-filters.module';
import { JobStatusModule } from 'shared/components/job-status/job-status.module';
import { WorkorderDetailModule } from 'shared/components/workorder-detail/workorder-detail.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NotificationModule } from './../../../components/notification/notification.module';
import { WorkOrderListComponent } from './../work-order-list/work-order-list.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RequestFormReceiptsModule } from 'shared/components/request-form-receipts/request-form-receipts.module';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { SpecificGlobalChatComponent } from './../job-message-console/specific-global-chat/specific-global-chat.component';
import { MatDialogModule } from '@angular/material/dialog';
import { JobMessageListModule } from './../job-message-console/job-message-list/job-message-list.module';
import { JobMessageDetailsModule } from './../job-message-console/job-message-details/job-message-details.module';
@NgModule({
  imports: [
    CommonModule,
    NgbModule.forRoot(),
    PipeModule,
    MessageModule,
    FeedModule,
    GlobalFiltersModule,
    JobDataCardModule,
    JobStatusModule,
    InfiniteScrollModule,
    NotificationModule,
    WorkorderDetailModule,
    NgxDatatableModule,
    FormsModule,
    RequestFormReceiptsModule,
    RightSidebarModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MatDialogModule,
    ReactiveFormsModule,
    JobMessageListModule,
    JobMessageDetailsModule
  ],
  declarations: [
    JobMessageConsoleComponent,
    WorkOrderListComponent,
    SpecificGlobalChatComponent
  ],
  exports: [ JobMessageConsoleComponent,    WorkOrderListComponent,    SpecificGlobalChatComponent, JobMessageListModule,    JobMessageDetailsModule]
})
export class JobMessageConsoleModule { }
