import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckInCheckOutActivityFeedComponent } from './check-in-check-out-activity-feed.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    CheckInCheckOutActivityFeedComponent
  ],
  exports: [
    CheckInCheckOutActivityFeedComponent
  ]
})
export class CheckInCheckOutActivityFeedModule { }
