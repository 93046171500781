import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DocumentCategoryApi } from 'shared/sdk';


@Component({
  selector: 'app-document-category-lookup',
  templateUrl: './document-category-lookup.component.html',
  styleUrls: ['./document-category-lookup.component.css']
})
export class DocumentCategoryLookupComponent implements OnInit {

  @Input() page: string;
  
  @Input() set resetData(isClear) {
      this.clearFromParent();
  }
  @Output() getDocumentCategoryObj: EventEmitter<any> = new EventEmitter;
  selectedValue;
  DocumentCategory: any = [];


  constructor(
    private _documentCategoryApi: DocumentCategoryApi
  ) { }

  ngOnInit() {
    this.loadData();
    const preSelected = localStorage.getItem('filterObj');
    if (preSelected && JSON.parse(preSelected) &&
        JSON.parse(preSelected)['documentCategory'] && JSON.parse(preSelected)['documentCategory'].length) {
        this.selectedValue = JSON.parse(preSelected)['documentCategory'];
    }
  }

  loadData() {
    const req = {
        fields: ['id', 'title'],
        order: 'title'
    }
    this._documentCategoryApi.find(req).subscribe(data => {
        if (data && data.length) {
            this.DocumentCategory = data;
        }
    }, error => {
        console.log(error.message);
    })
}

onChange() {
  this.getDocumentCategoryObj.emit(this.selectedValue);
}

clearFromParent() {
  this.selectedValue = null;
}


}
