import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PcUserCountLookupComponent } from './pc-user-count-lookup.component';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule
  ],
  declarations: [PcUserCountLookupComponent],
  exports:[PcUserCountLookupComponent]
})
export class PcUserCountLookupModule { }
