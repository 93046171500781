import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AddFieldsComponent } from 'shared/components/add-fields/add-fields.component';
@Component({
  selector: 'app-users-list-lookup',
  templateUrl: './users-list-lookup.component.html',
  styleUrls: ['./users-list-lookup.component.css'],
})
export class UsersListLookupComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() name;
  @Input() type = 'user_internal';
  @Output() setFormValue: EventEmitter<any> = new EventEmitter;
  @ViewChild('addFields') addFieldComponent: AddFieldsComponent;

  inputOption = [{
    'type': 'input',
    'label': 'User',
    'name': null,
    'col_type': null,
    'required': true
  }];
  userInternal = [];

  constructor() {
  }

  ngOnInit() {
    this.inputOption[0].name = this.name;
    this.inputOption[0].col_type = this.type;
  }

  setUserValue(e) {
      this.setFormValue.emit(e)
  }

  setFocus() {
    this.addFieldComponent.setFocusInputBox();
  }

}
