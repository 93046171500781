import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { LeaveStatusTypeLookupComponent } from './leave-status-type-lookup.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
  ],
  declarations: [LeaveStatusTypeLookupComponent],
  exports: [LeaveStatusTypeLookupComponent]
})
export class LeaveStatusTypeLookupModule { }
