import { CommonService } from './../../../services/common.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-service-manager-feed-item',
  templateUrl: './service-manager-feed-item.component.html',
  styleUrls: ['./service-manager-feed-item.component.css']
})
export class ServiceManagerFeedItemComponent implements OnInit {

  constructor(private _commonService: CommonService) { }

  @Input() data: any;
  @Input() caseNumber = '';

  ngOnInit() {
  }

  getTimeFormated(data) {
    if (data && data.created_at && data.created_at !== '') {
      const formatedTime = this._commonService.dateFormate(data.created_at, '', 'h:mm A z');
      return formatedTime;
    } else {
      return '';
    }
  }
}
