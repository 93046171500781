import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { fileTypesOpenInBrowser } from 'shared/models/static-list-data.service';
import { DocumentApi } from 'shared/sdk';
import { AlertService } from 'shared/services/alert.service';
import { CommonService } from 'shared/services/common.service';
import { PreloaderService } from 'shared/services/preloader.service';

@Component({
  selector: 'app-document-card-list',
  templateUrl: './document-card-list.component.html',
  styleUrls: ['./document-card-list.component.css']
})
export class DocumentCardListComponent implements OnInit {


  @Input() refreshFiles: any;
  @Input() isDeleteAllow = false;
  @Input() modelName;
  @Input() set modelId(id) {
    this._modelId = id;
    if(this._modelId) {
      this.getDocuments();
    }
  };
  errorMessage = 'No document(s) found.';
  deleteDoc: String = '';
  containerPath = '';
  _documentList = [];
  _modelId;
  constructor(
    private _documentApi: DocumentApi,
    private _alertService: AlertService,
    private _commonService: CommonService,
    private _preloaderService: PreloaderService,

  ) { }

  ngOnInit() {
    this.containerPath = environment.baseUrl + '/' + environment.apiVersion + '/Containers/';
    this.getDocuments();
    if(this.refreshFiles) {
      this.refreshFiles.subscribe(response => {
        if (response) {
            this.getDocuments();
        }
    });
    }
  }

  getDocuments() {
    if (this.modelName && this._modelId) {
      let filters = {
        modelName: this.modelName,
        modelId: this._modelId,
        isDeleted: 0
      };
      const reqObj = {
        order: "createdAt desc",
        skip: 0,
        where: filters,
      };
      this._documentApi.find(reqObj).subscribe(
        documents => {
          if (documents.length) {
            this._documentList = documents;
          } else {
            console.log("no documents for this timecard");
          }
        },
        err => {
          console.log(err);
        }
      );
    } else {
      this._documentList = [];
    }
  }


  initiateDeleteDocumet = (doc) => {
    if (doc && doc.id && this.deleteDoc !== doc.id) {
      this.deleteDoc = doc.id;
    } else {
      this.deleteDoc = '';
    }
  }

  /**
   * Method to download the document.
   * @param doc - the document object that to be download
   * @param type - type to handle the case of open the document in browser new tab.
   * The argument @param type is by default 0, represent download by defalut. 
   */
  downloadDocument = (doc, type = 0) => {
    let fileTypesOpenInBrowserList = fileTypesOpenInBrowser;
    if (type != 0) {
      // doc.fileType
      if (doc && doc.fileMeta && doc.fileMeta.extension in fileTypesOpenInBrowserList) {
        if (doc.shortUrl && doc.shortUrl) {
          window.open(doc.shortUrl, '_blank');
          return;
        }
      }
    }
    if (doc && doc.fileMeta) {
      const item = doc;
      let files = [];
      files.push({
        path: this.containerPath + (item.fileMeta.path),
        name: item.fileMeta.name
      });
      this._commonService.downloadMultiFiles(files);
    } else {
      this._alertService.error('Not able to download: Something went wrong!')
    }
  }

  confirmDelete = (doc) => {
    if (doc === 'cancel') {
      this.deleteDoc = '';
    } else {
      if (doc) {
        this._preloaderService.showPreloader();
        this._documentApi.deleteByIds({ ids: [doc.id] }).subscribe(
          res => {
            if (res) {
              this._alertService.clear();
              this._alertService.success(
                'Document deleted successfully.'
              );
              if (this._documentList && this._documentList.length) {
                this._documentList = this._documentList.filter(item => item.id != doc.id);
              }
            }
            this._preloaderService.hidePreloader();
            this.deleteDoc = '';
          },
          err => {
            this.deleteDoc = '';
            this._alertService.error(err.message);
            this._preloaderService.hidePreloader();
          }
        );
      }
    }
  }

}
