import { SiteVisitReportApi } from './../../../../sdk/services/custom/SiteVisitReport';
import { JobWorkerRatingApi, VendorRatingApi } from 'shared/sdk';
import { PreloaderService } from './../../../../services/preloader.service';
import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { forkJoin } from 'rxjs';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-vendor-worker-rating',
  templateUrl: './vendor-worker-rating.component.html',
  styleUrls: ['./vendor-worker-rating.component.css']
})
export class VendorWorkerRatingComponent implements OnInit {
  submitted= false;
   @Input() set jobSfdcId(e) {
    if (e && e.length) {
      this.jobId = e;
      // this.getAllListData();
    }
  }
   @Input() set timeCard(e) {
    if (e) {
      this.workerId = e.Worker__c ? e.Worker__c : '';
      this.vednorId = e.Worker__c ? e.Vendor__c : '';
      this.timecardId = e.sfdcId ? e.sfdcId : '';
      this.getAllListData();
    }
   };
   @Input() siteVisitRatingId: any;
   @Input() activeTabs: any;
   @Output() onActiveTab: EventEmitter<any> = new EventEmitter<boolean>();
   @Output() onRatingFromControl: EventEmitter<any> = new EventEmitter<boolean>();
   iccRating = {
    id: null,
    Quality__c: null,
    Professionalism__c : null,
    Communication__c : null,
    Worker_Arrival_On_Time__c : null,
    Value__c : null,
    Review__c : null,
    ICC_Comment__c: null,
    v_Quality__c : null,
    v_Professionalism__c : null,
    v_Communication__c : null,
    v_Value__c : null,
    v_Review__c : null,
    v_ICC_Comment__c : null
   }
   pgmoRating = {
    id: null,
    Quality_PGMO__c: null,
    Professionalism_PGMO__c: null,
    Communication_PGMO__c: null,
    Worker_Arrival_On_Time_PGMO__c: null,
    Value_PGMO__c: null,
    Review_PGMO__c: null,
    PGMO_Comment__c: null,
    v_Quality_PGMO__c: null,
    v_Professionalism_PGMO__c: null,
    v_Communication_PGMO__c: null,
    v_Value_PGMO__c: null,
    v_Review_PGMO__c: null,
    v_PGMO_Comment__c: null
   }
   siteVisitRating = {
    id: null,
   }
  ratingStatus = ['Unstatisfied', 'Satisfied', 'Excellent'];
  ratingNum = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  jobId: string;
  workerId: any;
  vednorId: any;
  timecardId: any;
  rating = 0;

  constructor(
    private _jobWorkerRatingApi: JobWorkerRatingApi,
    private _vendorRatingApi: VendorRatingApi,
    private _loader: PreloaderService,
  ) { }

  ngOnInit() {
  }

  /* Calling WorkerRating & Vendor Rating Api's simultaneously */
  getAllListData() {
   // this._loader.showPreloader();
    forkJoin(this.getJobWorkerRatingListing(), this.getVendorRatingListing()).subscribe(
      ([jobWorkerRatingRes, vendorRatingRes]: any) => {
        if (jobWorkerRatingRes && jobWorkerRatingRes.length) {
          this.setWorkerRatingValues(jobWorkerRatingRes[0]);
         }
         if (vendorRatingRes && vendorRatingRes.length) {
          this.setVendorRatingValues(vendorRatingRes[0]);
         }
         if (this.siteVisitRatingId) {
          this.siteVisitRating['id'] = this.siteVisitRatingId;
         }
        //  this.onddlChanges();
       },
      (error) => {
        console.log(error);
        this._loader.hidePreloader();
      }
    );
  }

  /*Function returning Worker Rating Data*/
  getJobWorkerRatingListing() {
    const filterObj = {
     where: { Job__c: this.jobId, Worker__c: this.workerId  },
     fields: [
        'id',
        'sfdcId',
        'Quality__c',
        'Professionalism__c',
        'Communication__c',
        'Worker_Arrival_On_Time__c',
        'Value__c',
        'Review__c',
        'Quality_PGMO__c',
        'Professionalism_PGMO__c',
        'Communication_PGMO__c',
        'Worker_Arrival_On_Time_PGMO__c',
        'Value_PGMO__c',
        'Review_PGMO__c',
        'ICC_Comment__c',
        'PGMO_Comment__c'
      ],
    };
    return this._jobWorkerRatingApi.find(filterObj);
  }

  /*Function returning Vendor Rating Data*/
  getVendorRatingListing() {
    const filterObj = {
      where: { Job__c: this.jobId, Account__c: this.vednorId },
      fields: [
        'id',
        'sfdcId',
        'Quality__c',
        'Professionalism__c',
        'Communication__c',
        'Value__c',
        'Review__c',
        'Quality_PGMO__c',
        'Professionalism_PGMO__c',
        'Communication_PGMO__c',
        'Value_PGMO__c',
        'Review_PGMO__c',
        'PGMO_Comment__c',
        'ICC_Comment__c'
      ],
    };
    return this._vendorRatingApi.find(filterObj);
  }

  /*Set Prefilled values*/
  setWorkerRatingValues(workerRatingData) {
    workerRatingData.Review__c = (workerRatingData.Review__c && workerRatingData.Review__c === 'Default Rating created') ? '' : workerRatingData.Review__c
    this.iccRating['workerRatingId'] = (workerRatingData.id) ? workerRatingData.id : null;
    this.iccRating['Quality__c'] = (workerRatingData.Quality__c) ? workerRatingData.Quality__c : null;
    this.iccRating['Professionalism__c'] = (workerRatingData.Professionalism__c) ? workerRatingData.Professionalism__c : null;
    this.iccRating['Communication__c'] = (workerRatingData.Communication__c) ? workerRatingData.Communication__c : null;
    this.iccRating['Worker_Arrival_On_Time__c'] = (workerRatingData.Worker_Arrival_On_Time__c) ? workerRatingData.Worker_Arrival_On_Time__c : null ;
    this.iccRating['Value__c'] = (workerRatingData.Value__c) ? workerRatingData.Value__c : null;
    this.iccRating['Review__c'] = (workerRatingData.Review__c) ? workerRatingData.Review__c : null;
    this.iccRating['ICC_Comment__c'] = (workerRatingData.ICC_Comment__c)? workerRatingData.ICC_Comment__c : null;
    this.pgmoRating['Quality_PGMO__c'] = (workerRatingData.Quality_PGMO__c) ? workerRatingData.Quality_PGMO__c : null;
    this.pgmoRating['Professionalism_PGMO__c'] = (workerRatingData.Professionalism_PGMO__c) ? workerRatingData.Professionalism_PGMO__c : null;
    this.pgmoRating['Communication_PGMO__c'] = (workerRatingData.Communication_PGMO__c) ? workerRatingData.Communication_PGMO__c : null;
    this.pgmoRating['Worker_Arrival_On_Time_PGMO__c'] = (workerRatingData.Worker_Arrival_On_Time_PGMO__c) ? workerRatingData.Worker_Arrival_On_Time_PGMO__c : null;
    this.pgmoRating['Value_PGMO__c'] = (workerRatingData.Value_PGMO__c) ? workerRatingData.Value_PGMO__c : null;
    this.pgmoRating['Review_PGMO__c'] = (workerRatingData.Review_PGMO__c) ? workerRatingData.Review_PGMO__c : null;
    this.pgmoRating['PGMO_Comment__c'] = (workerRatingData.PGMO_Comment__c) ? workerRatingData.PGMO_Comment__c : null;
  }

  setVendorRatingValues(vendorRatingData) {
    this.pgmoRating['vendorRatingId'] = (vendorRatingData.id) ? vendorRatingData.id : null;
    this.iccRating['v_Quality__c'] = (vendorRatingData.Quality__c) ? vendorRatingData.Quality__c : null;
    this.iccRating['v_Professionalism__c'] = (vendorRatingData.Professionalism__c) ? vendorRatingData.Professionalism__c : null;
    this.iccRating['v_Communication__c'] = (vendorRatingData.Communication__c) ? vendorRatingData.Communication__c : null;
    this.iccRating['v_Value__c'] = (vendorRatingData.Value__c) ? vendorRatingData.Value__c : null;
    this.iccRating['v_Review__c'] = (vendorRatingData.Review__c) ? vendorRatingData.Review__c : null;
    this.iccRating['v_ICC_Comment__c'] = (vendorRatingData.ICC_Comment__c)? vendorRatingData.ICC_Comment__c : null;
    this.pgmoRating['v_Quality_PGMO__c'] = (vendorRatingData.Quality_PGMO__c) ? vendorRatingData.Quality_PGMO__c : null;
    this.pgmoRating['v_Professionalism_PGMO__c'] = (vendorRatingData.Professionalism_PGMO__c) ? vendorRatingData.Professionalism_PGMO__c : null;
    this.pgmoRating['v_Communication_PGMO__c'] = (vendorRatingData.Communication_PGMO__c) ? vendorRatingData.Communication_PGMO__c : null;
    this.pgmoRating['v_Value_PGMO__c'] = (vendorRatingData.Value_PGMO__c) ? vendorRatingData.Value_PGMO__c : null;
    this.pgmoRating['v_Review_PGMO__c'] = (vendorRatingData.Review_PGMO__c) ? vendorRatingData.Review_PGMO__c : null;
    this.pgmoRating['v_PGMO_Comment__c'] = (vendorRatingData.PGMO_Comment__c) ? vendorRatingData.PGMO_Comment__c : null;
  }

  isNumber(evt) {
    evt = (evt) ? evt : window.event;
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode === 8 || charCode === 9) {
      return true;
    }
    return !((charCode > 31 && charCode < 48) || charCode > 57);
  }

  checkPer(e) {
      // tslint:disable-next-line:radix
      if (e && e.target.value && parseInt(e.target.value) > 100) {
        e.target.value = '';
      }
  }


  onTabBtnClick(tab) {
    this.onActiveTab.emit(tab);
  }

  // onddlChanges(e?: any) {
    //   // tslint:disable-next-line:radix
    //   if (this.iccRating.Review__c && parseInt(this.iccRating.Review__c) > 100) {
    //     this.iccRating.Review__c = 100;
    //     // tslint:disable-next-line:radix
    //   } else if (this.iccRating.v_Review__c && parseInt(this.iccRating.v_Review__c) > 100) {
    //     this.iccRating.v_Review__c = 100;
    //     // tslint:disable-next-line:radix
    //   } else if (this.pgmoRating.Review_PGMO__c && parseInt(this.pgmoRating.Review_PGMO__c) > 100) {
    //     this.pgmoRating.Review_PGMO__c = 100;
    //     // tslint:disable-next-line:radix
    //   } else if (this.pgmoRating.v_Review_PGMO__c && parseInt(this.pgmoRating.v_Review_PGMO__c) > 100) {
    //     this.pgmoRating.v_Review_PGMO__c = 100;
    //   }
    // this.onRatingFromControl.emit({ iccRating: this.iccRating, pgmoRating: this.pgmoRating, siteVisitRating: this.siteVisitRating });
  // }

  startSave(f){ 
    this.submitted=true;
    if(f.valid && this.submitted){   
    this.onRatingFromControl.emit({ iccRating: this.iccRating, pgmoRating: this.pgmoRating, siteVisitRating: this.siteVisitRating });
  }}

}
