import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextNotificationPopupComponent } from './text-notification-popup.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [TextNotificationPopupComponent],
  exports: [TextNotificationPopupComponent],
})
export class TextNotificationManagerViewPopupModule { }
