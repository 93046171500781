/* tslint:disable */

declare var Object: any;
export interface MonthlyInvoiceItemInterface {
  "sfdcId"?: string;
  "CreatedById"?: string;
  "CurrencyIsoCode"?: string;
  "LastModifiedById"?: string;
  "Name"?: string;
  "RecordTypeId"?: string;
  "X1_Hours_minus_Created__c"?: Date;
  "Actual_VAT_Tax__c"?: number;
  "Amount__c"?: number;
  "Amount_Calc__c"?: number;
  "Balance_Invoice_Amount__c"?: number;
  "Billing_Days__c"?: number;
  "Consolidated_Monthly_Invoice_Created__c"?: boolean;
  "Description__c"?: string;
  "Due_Date__c"?: Date;
  "First_Day_for_Calculation__c"?: Date;
  "First_Day_of_Invoice_Month__c"?: Date;
  "Has_Holiday_Overtime__c"?: boolean;
  "Has_Job_Ref__c"?: boolean;
  "Has_Overtime__c"?: boolean;
  "Holiday_Overtime_Hours__c"?: number;
  "Holiday_Overtime_Hours_updated__c"?: number;
  "Hours_Worked__c"?: number;
  "Hours_Worked_for_Roll_up__c"?: number;
  "Incurred_Date__c"?: Date;
  "Invoice__c"?: string;
  "Invoice_Amount_Paid__c"?: number;
  "Invoice_available__c"?: boolean;
  "Invoice_has_Job_with_SC__c"?: boolean;
  "Invoice_has_Job_with_SO__c"?: boolean;
  "Invoice_has_Job_with_WO__c"?: boolean;
  "Invoice_Notes__c"?: string;
  "Invoice_Number__c"?: string;
  "Iron_PMS_Case_Number__c"?: string;
  "Job_on_Invoice_has_Vendor__c"?: boolean;
  "Last_Day_for_Calculation__c"?: Date;
  "Last_Day_of_Invoice_Month__c"?: Date;
  "Monthly_Invoice__c"?: string;
  "Month_Number_Auto__c"?: number;
  "Notes__c"?: string;
  "Sales_Order_Amount__c"?: number;
  "Partner_Case_Number__c"?: string;
  "Rate_for_Roll_up__c"?: number;
  "Refresh__c"?: boolean;
  "Sales_Order__c"?: string;
  "Service_Contract_Reference_Available__c"?: boolean;
  "Short_Hours_Updated__c"?: number;
  "Standard_Overtime_Hours__c"?: number;
  "Standard_Overtime_Hours_Updated__c"?: number;
  "Total_Price_After_VAT_Auto__c"?: number;
  "Total_Price_Before_VAT_Auto__c"?: number;
  "Total_Price_for_Roll_up__c"?: number;
  "Total_Short_Worked_Hours__c"?: number;
  "Type__c"?: string;
  "Sales_Order_Unit_Price_HMS__c"?: number;
  "Sales_Order_Unit_Rate__c"?: number;
  "Unit_Rate_Custom__c"?: number;
  "VAT__c"?: number;
  "Vendor_Time_Card_Time_in_Actual__c"?: Date;
  "Worker_Name__c"?: string;
  "id"?: number;
}

export class MonthlyInvoiceItem implements MonthlyInvoiceItemInterface {
  "sfdcId": string;
  "CreatedById": string;
  "CurrencyIsoCode": string;
  "LastModifiedById": string;
  "Name": string;
  "RecordTypeId": string;
  "X1_Hours_minus_Created__c": Date;
  "Actual_VAT_Tax__c": number;
  "Amount__c": number;
  "Amount_Calc__c": number;
  "Balance_Invoice_Amount__c": number;
  "Billing_Days__c": number;
  "Consolidated_Monthly_Invoice_Created__c": boolean;
  "Description__c": string;
  "Due_Date__c": Date;
  "First_Day_for_Calculation__c": Date;
  "First_Day_of_Invoice_Month__c": Date;
  "Has_Holiday_Overtime__c": boolean;
  "Has_Job_Ref__c": boolean;
  "Has_Overtime__c": boolean;
  "Holiday_Overtime_Hours__c": number;
  "Holiday_Overtime_Hours_updated__c": number;
  "Hours_Worked__c": number;
  "Hours_Worked_for_Roll_up__c": number;
  "Incurred_Date__c": Date;
  "Invoice__c": string;
  "Invoice_Amount_Paid__c": number;
  "Invoice_available__c": boolean;
  "Invoice_has_Job_with_SC__c": boolean;
  "Invoice_has_Job_with_SO__c": boolean;
  "Invoice_has_Job_with_WO__c": boolean;
  "Invoice_Notes__c": string;
  "Invoice_Number__c": string;
  "Iron_PMS_Case_Number__c": string;
  "Job_on_Invoice_has_Vendor__c": boolean;
  "Last_Day_for_Calculation__c": Date;
  "Last_Day_of_Invoice_Month__c": Date;
  "Monthly_Invoice__c": string;
  "Month_Number_Auto__c": number;
  "Notes__c": string;
  "Sales_Order_Amount__c": number;
  "Partner_Case_Number__c": string;
  "Rate_for_Roll_up__c": number;
  "Refresh__c": boolean;
  "Sales_Order__c": string;
  "Service_Contract_Reference_Available__c": boolean;
  "Short_Hours_Updated__c": number;
  "Standard_Overtime_Hours__c": number;
  "Standard_Overtime_Hours_Updated__c": number;
  "Total_Price_After_VAT_Auto__c": number;
  "Total_Price_Before_VAT_Auto__c": number;
  "Total_Price_for_Roll_up__c": number;
  "Total_Short_Worked_Hours__c": number;
  "Type__c": string;
  "Sales_Order_Unit_Price_HMS__c": number;
  "Sales_Order_Unit_Rate__c": number;
  "Unit_Rate_Custom__c": number;
  "VAT__c": number;
  "Vendor_Time_Card_Time_in_Actual__c": Date;
  "Worker_Name__c": string;
  "id": number;
  constructor(data?: MonthlyInvoiceItemInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `MonthlyInvoiceItem`.
   */
  public static getModelName() {
    return "MonthlyInvoiceItem";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of MonthlyInvoiceItem for dynamic purposes.
  **/
  public static factory(data: MonthlyInvoiceItemInterface): MonthlyInvoiceItem{
    return new MonthlyInvoiceItem(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'MonthlyInvoiceItem',
      plural: 'MonthlyInvoiceItems',
      path: 'MonthlyInvoiceItems',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "RecordTypeId": {
          name: 'RecordTypeId',
          type: 'string'
        },
        "X1_Hours_minus_Created__c": {
          name: 'X1_Hours_minus_Created__c',
          type: 'Date'
        },
        "Actual_VAT_Tax__c": {
          name: 'Actual_VAT_Tax__c',
          type: 'number'
        },
        "Amount__c": {
          name: 'Amount__c',
          type: 'number'
        },
        "Amount_Calc__c": {
          name: 'Amount_Calc__c',
          type: 'number'
        },
        "Balance_Invoice_Amount__c": {
          name: 'Balance_Invoice_Amount__c',
          type: 'number'
        },
        "Billing_Days__c": {
          name: 'Billing_Days__c',
          type: 'number'
        },
        "Consolidated_Monthly_Invoice_Created__c": {
          name: 'Consolidated_Monthly_Invoice_Created__c',
          type: 'boolean'
        },
        "Description__c": {
          name: 'Description__c',
          type: 'string'
        },
        "Due_Date__c": {
          name: 'Due_Date__c',
          type: 'Date'
        },
        "First_Day_for_Calculation__c": {
          name: 'First_Day_for_Calculation__c',
          type: 'Date'
        },
        "First_Day_of_Invoice_Month__c": {
          name: 'First_Day_of_Invoice_Month__c',
          type: 'Date'
        },
        "Has_Holiday_Overtime__c": {
          name: 'Has_Holiday_Overtime__c',
          type: 'boolean'
        },
        "Has_Job_Ref__c": {
          name: 'Has_Job_Ref__c',
          type: 'boolean'
        },
        "Has_Overtime__c": {
          name: 'Has_Overtime__c',
          type: 'boolean'
        },
        "Holiday_Overtime_Hours__c": {
          name: 'Holiday_Overtime_Hours__c',
          type: 'number'
        },
        "Holiday_Overtime_Hours_updated__c": {
          name: 'Holiday_Overtime_Hours_updated__c',
          type: 'number'
        },
        "Hours_Worked__c": {
          name: 'Hours_Worked__c',
          type: 'number'
        },
        "Hours_Worked_for_Roll_up__c": {
          name: 'Hours_Worked_for_Roll_up__c',
          type: 'number'
        },
        "Incurred_Date__c": {
          name: 'Incurred_Date__c',
          type: 'Date'
        },
        "Invoice__c": {
          name: 'Invoice__c',
          type: 'string'
        },
        "Invoice_Amount_Paid__c": {
          name: 'Invoice_Amount_Paid__c',
          type: 'number'
        },
        "Invoice_available__c": {
          name: 'Invoice_available__c',
          type: 'boolean'
        },
        "Invoice_has_Job_with_SC__c": {
          name: 'Invoice_has_Job_with_SC__c',
          type: 'boolean'
        },
        "Invoice_has_Job_with_SO__c": {
          name: 'Invoice_has_Job_with_SO__c',
          type: 'boolean'
        },
        "Invoice_has_Job_with_WO__c": {
          name: 'Invoice_has_Job_with_WO__c',
          type: 'boolean'
        },
        "Invoice_Notes__c": {
          name: 'Invoice_Notes__c',
          type: 'string'
        },
        "Invoice_Number__c": {
          name: 'Invoice_Number__c',
          type: 'string'
        },
        "Iron_PMS_Case_Number__c": {
          name: 'Iron_PMS_Case_Number__c',
          type: 'string'
        },
        "Job_on_Invoice_has_Vendor__c": {
          name: 'Job_on_Invoice_has_Vendor__c',
          type: 'boolean'
        },
        "Last_Day_for_Calculation__c": {
          name: 'Last_Day_for_Calculation__c',
          type: 'Date'
        },
        "Last_Day_of_Invoice_Month__c": {
          name: 'Last_Day_of_Invoice_Month__c',
          type: 'Date'
        },
        "Monthly_Invoice__c": {
          name: 'Monthly_Invoice__c',
          type: 'string'
        },
        "Month_Number_Auto__c": {
          name: 'Month_Number_Auto__c',
          type: 'number'
        },
        "Notes__c": {
          name: 'Notes__c',
          type: 'string'
        },
        "Sales_Order_Amount__c": {
          name: 'Sales_Order_Amount__c',
          type: 'number'
        },
        "Partner_Case_Number__c": {
          name: 'Partner_Case_Number__c',
          type: 'string'
        },
        "Rate_for_Roll_up__c": {
          name: 'Rate_for_Roll_up__c',
          type: 'number'
        },
        "Refresh__c": {
          name: 'Refresh__c',
          type: 'boolean'
        },
        "Sales_Order__c": {
          name: 'Sales_Order__c',
          type: 'string'
        },
        "Service_Contract_Reference_Available__c": {
          name: 'Service_Contract_Reference_Available__c',
          type: 'boolean'
        },
        "Short_Hours_Updated__c": {
          name: 'Short_Hours_Updated__c',
          type: 'number'
        },
        "Standard_Overtime_Hours__c": {
          name: 'Standard_Overtime_Hours__c',
          type: 'number'
        },
        "Standard_Overtime_Hours_Updated__c": {
          name: 'Standard_Overtime_Hours_Updated__c',
          type: 'number'
        },
        "Total_Price_After_VAT_Auto__c": {
          name: 'Total_Price_After_VAT_Auto__c',
          type: 'number'
        },
        "Total_Price_Before_VAT_Auto__c": {
          name: 'Total_Price_Before_VAT_Auto__c',
          type: 'number'
        },
        "Total_Price_for_Roll_up__c": {
          name: 'Total_Price_for_Roll_up__c',
          type: 'number'
        },
        "Total_Short_Worked_Hours__c": {
          name: 'Total_Short_Worked_Hours__c',
          type: 'number'
        },
        "Type__c": {
          name: 'Type__c',
          type: 'string'
        },
        "Sales_Order_Unit_Price_HMS__c": {
          name: 'Sales_Order_Unit_Price_HMS__c',
          type: 'number'
        },
        "Sales_Order_Unit_Rate__c": {
          name: 'Sales_Order_Unit_Rate__c',
          type: 'number'
        },
        "Unit_Rate_Custom__c": {
          name: 'Unit_Rate_Custom__c',
          type: 'number'
        },
        "VAT__c": {
          name: 'VAT__c',
          type: 'number'
        },
        "Vendor_Time_Card_Time_in_Actual__c": {
          name: 'Vendor_Time_Card_Time_in_Actual__c',
          type: 'Date'
        },
        "Worker_Name__c": {
          name: 'Worker_Name__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
