import { AdminModule } from './../../../admin/admin.module';
import { LookupModule } from './../../../../components/lookup/lookup.module';
import { CustomDualListModule } from 'shared/components/custom-dual-list/custom-dual-list.module';
import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddWorkerComponent } from './add-worker.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { AlertModule } from 'shared/components/alert/alert.module';
import { PreloaderModule } from 'shared/components/preloader/preloader.module';
import { ConfirmDialogModule } from 'shared/components/confirm-dialog/confirm-dialog.module';
import { StateManagerLookupModule } from 'shared/components/lookup/state-manager-lookup/state-manager-lookup.module';
import { UsersModule } from 'shared/views/users/users.module';
import { CandidateTypeModule } from 'shared/components/candidate-type/candidate-type.module';
import { UserDeduplicationTableModule } from 'shared/components/user-deduplication-table/user-deduplication-table.module';
import { AddTalentInformationModule } from 'shared/components/add-talent-information/add-talent-information.module';
@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CustomDualListModule,
    LookupModule,
    NgSelectModule,
    // AdminModule,
    AlertModule,
    PreloaderModule,
    ConfirmDialogModule,
    StateManagerLookupModule,
    // UsersModule,
    CandidateTypeModule,
     UserDeduplicationTableModule,
    AddTalentInformationModule,
  ],
  declarations: [AddWorkerComponent],
  exports: [AddWorkerComponent]
})
export class AddWorkerModule { }
