/* tslint:disable */

declare var Object: any;
export interface JobAlertEmailTrackingInterface {
  "Name"?: string;
  "JO_ID__c"?: string;
  "Vendor__c"?: string;
  "Contact__c"?: string;
  "Type__c"?: string;
  "Link_is_Opened__c"?: string;
  "Link_First_Accesed_Date_Time__c"?: string;
  "Link_Latest_Accesed_Date_Time__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class JobAlertEmailTracking implements JobAlertEmailTrackingInterface {
  "Name": string;
  "JO_ID__c": string;
  "Vendor__c": string;
  "Contact__c": string;
  "Type__c": string;
  "Link_is_Opened__c": string;
  "Link_First_Accesed_Date_Time__c": string;
  "Link_Latest_Accesed_Date_Time__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: JobAlertEmailTrackingInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `JobAlertEmailTracking`.
   */
  public static getModelName() {
    return "JobAlertEmailTracking";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of JobAlertEmailTracking for dynamic purposes.
  **/
  public static factory(data: JobAlertEmailTrackingInterface): JobAlertEmailTracking{
    return new JobAlertEmailTracking(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'JobAlertEmailTracking',
      plural: 'JobAlertEmailTrackings',
      path: 'JobAlertEmailTrackings',
      idName: 'id',
      properties: {
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "JO_ID__c": {
          name: 'JO_ID__c',
          type: 'string'
        },
        "Vendor__c": {
          name: 'Vendor__c',
          type: 'string'
        },
        "Contact__c": {
          name: 'Contact__c',
          type: 'string'
        },
        "Type__c": {
          name: 'Type__c',
          type: 'string'
        },
        "Link_is_Opened__c": {
          name: 'Link_is_Opened__c',
          type: 'string'
        },
        "Link_First_Accesed_Date_Time__c": {
          name: 'Link_First_Accesed_Date_Time__c',
          type: 'string'
        },
        "Link_Latest_Accesed_Date_Time__c": {
          name: 'Link_Latest_Accesed_Date_Time__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
