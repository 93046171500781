import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { SubCategory } from 'shared/models/static-list-data.service';

@Component({
  selector: 'app-sub-category-lookup',
  templateUrl: './sub-category-lookup.component.html',
  styleUrls: ['./sub-category-lookup.component.css']
})
export class SubCategoryLookupComponent implements OnInit {
  //selectedAttributes: any;
  subCategoryList = [];
  subCategorySelected = [];
  isMultipleSelected = true;

  @Input() set resetData(isClear) {
    if (isClear) {
      this.subCategorySelected = [];
    }
  }
  @Output() selectedSubCategory: EventEmitter<any> = new EventEmitter;

  constructor() { }

  ngOnInit() {
    // this.selectedAttributes = [
    //   { label: '2BD', value: '2BD' }
    // ]
    this.subCategoryList = SubCategory;
    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
    this.subCategorySelected = (preselected && preselected['subCategories']) ? preselected['subCategories'] : [];
    this.onSubCategoryChange();
  }

  onSubCategoryChange() {
    this.selectedSubCategory.emit(this.subCategorySelected);
  }
  clearSubCategory() {
    this.subCategorySelected = [];
    this.selectedSubCategory.emit([]);
  }

}