import { Injectable } from '@angular/core';
import { Observable, Subscription, Subject, BehaviorSubject } from 'rxjs';

import { CommentApi } from './../sdk/services/custom/Comment';
import { ActivatedRoute } from '@angular/router';
import { MessageSocketService } from './message-socket.service';
import { AppStateService } from './app-state.service';

import { id } from '@swimlane/ngx-datatable/release/utils';
@Injectable()
export class MessageService {
  private messages: any = new Subject<any>();
  private newMessagesJob: any = new Subject<any>();
  private updateChatRoomCounts: any = new Subject<any>();
  private selectedChatRoom = new BehaviorSubject<any>(null);
  private openMsgIndex = new BehaviorSubject<any>(true);
  // private countCall: any = new Subject<any>();
  private createdAt: Date;
  private timerSub: Subscription;
  _currentUser;
  currentFilter: any = false;
  accessType: any;
  constructor(
    private _commentApi: CommentApi,
    private route: ActivatedRoute,
    private messageSocketService: MessageSocketService,
    private _appState: AppStateService
  ) {
    this._currentUser = JSON.parse(localStorage.getItem('appData'));
    this.getMessageFromSocket();
    this.accessType = this._appState.getAccessType();
    console.log('access type..', this.accessType);
  }

  getMessages(filter) {
    this.unSubscribeMessageSub();
    this.createdAt = null;
    // first param is to emit value after 0 second and
    // second param is to emit value after every 10 seconds
    this.refetchMessages(filter);
    //this.timerSub = Observable.timer(0, 10000).subscribe(() => this.refetchMessages(filter));
    return this.messages.asObservable();
  }

  refetchMessages(filter) {
    if (this.createdAt) {
      filter['createdAt'] = { gt: this.createdAt };
    }
    this.currentFilter = filter;
    // this.createdAt = new Date();
    this._commentApi.find({
      'where': filter,
      'include': [
        {
          'relation': 'contact',
          'scope': {
            'fields': ['id', 'UserId', 'sfdcId', 'Gender__c'],
            'include': {
              'relation': 'users',
              'scope': {
                'fields': ['id', 'sfdcId', 'firstname', 'lastname', 'profileImage', 'url', 'accessType', 'userTypeId'],
                'isMessage': 'true'
              }
            }
          }
        },
        {
          relation: 'owner',
          scope: {
            fields: [
              'id',
              'sfdcId',
              'profileImage',
              'firstName',
              'lastName',
              'url',
              'accessType'
            ]
          }
        }
      ]
    }).subscribe(
      results => {
        results = this.applyPostedBy(results);
        if (results && results.length) {
          this.createdAt = results[results.length - 1]['createdAt'];
        }

        this.messages.next(results);
        if (results && results.length && filter && filter['createdAt']) {
          this.newMessagesJob.next(results);
        }
      },
      error => {
        console.log('Could not load messages.');
      }
    );
  }

  unSubscribeMessageSub() {
    if (this.timerSub) {
      this.timerSub.unsubscribe();
    }
  }

  setMsgReadKey(e) {
    const columnName = this._currentUser.accessType === "vendor" ? "readByVMS" : "readByMSP";
    let readStatus = "false";
    if (e[columnName]) {
      e[columnName].forEach(element => {
        if (element.id === this._currentUser.id) {
          readStatus = "true";
        }
      });
    }

    return readStatus;
  }


  setNewMessageJob(modelId) {
    this.newMessagesJob.next(modelId);
  }

  getNewMessageJob(): Observable<any> {
    return this.newMessagesJob.asObservable();
  }

  setchatRoomId(roomId) {
    this.selectedChatRoom.next(roomId);
  }

  getchatRoomId(): Observable<any> {
    return this.selectedChatRoom.asObservable();
  }

  // setMsgViewType(listView) {
  //   this.openMsgIndex.next(listView);
  // }

  // getMsgViewType(): Observable<any> {
  //   return this.openMsgIndex.asObservable();
  // }
  updateRoomCount(): Observable<any> {
    return this.updateChatRoomCounts.asObservable();
  }

  // setCountCall(isCall) {
  //     this.countCall.next(isCall);
  // }

  // getCountCall(): Observable<any> {
  //     return this.countCall.asObservable();
  // }
  getMessageFromSocket() {

    this.messageSocketService.getMessage().subscribe(result => {
      try {
        result = this.applyPostedBy([result['payload']])[0];
        if (this.accessType === 'vendor' && result.Is_Public_Job__c === false) {
          return false;
        }
        let isMessageTabOpen: boolean = true;
        if (typeof this.currentFilter.createdAt !== 'undefined') {
          delete this.currentFilter.createdAt;
        }

        for (const key in this.currentFilter) {
          let processed = false;
          if (key === 'Case__c' && typeof this.currentFilter[key].inq === 'object') {
            if (!this.currentFilter[key].inq.includes(result.Case__c)) {
              isMessageTabOpen = false;
              break;
            }
            processed = true;
          }

          if (!processed && (!result.hasOwnProperty(key) || result[key] !== this.currentFilter[key])) {
            isMessageTabOpen = false;
            break;
          }
        }
        if (isMessageTabOpen == false && this.currentFilter['modelName'] === "Case" && typeof this.currentFilter['or'] !== 'undefined') {
          const first = this.currentFilter['or'][0];
          if (typeof first['and'] !== 'undefined') {
            const andFirst = result.Case__c === first['and'][0].Case__c ? true : false;
            const andSecound = typeof result.contact !== 'undefined' ? true : false;
            if (andFirst && andSecound) {
              isMessageTabOpen = true;
            }
          }
          if (isMessageTabOpen === false) {
            const sec = typeof this.currentFilter['or'] !== 'undefined' && typeof this.currentFilter['or'][1] !== 'undefined' ? this.currentFilter['or'][1] : false;
            if (sec && typeof sec['Case__c'].inq !== 'undefined' && sec['Case__c'].inq.includes(result.Case__c)) {
              isMessageTabOpen = true;
            }
          }
        }
        /**
           * Work Order filter check.
           */
        if (isMessageTabOpen === false) {
          if (
            typeof this.currentFilter['or'] !== 'undefined' &&
            typeof this.currentFilter['or'][0] !== 'undefined'
            &&
            (
              (
                typeof this.currentFilter['or'][0].Case__c != 'undefined'
                && typeof result.Case__c != 'undefined'
                && this.currentFilter['or'][0].Case__c === result.Case__c
              )
              ||
              (
                typeof this.currentFilter['or'][1].Case__c !== 'undefined'
                && typeof result.Case__c
                && this.currentFilter['or'][1].Case__c === result.Case__c
              )

            )
          ) {
            isMessageTabOpen = true;
          }

          if (isMessageTabOpen === false) {
            if (
              typeof this.currentFilter['or'] !== 'undefined' &&
              typeof this.currentFilter['or'][1] !== 'undefined' &&
              typeof this.currentFilter['or'][1].inq !== 'undefined' &&
              typeof result.Case__c !== 'undefined' &&
              this.currentFilter['or'][1].inq.includes(result.Case__c)
            ) {
              isMessageTabOpen = true;
            }
          }
        }
        const contactId = typeof result.contact !== 'undefined' ? result.contact.sfdcId : '-098-'
        const messageKey = JSON.stringify({
          'msg': result.Case_Comment_Body__c.replace(/[^a-zA-Z0-9]/g, '')
          , 'contact': contactId, 'ownerId': result.ownerId
        }) + this._appState.getTabUniqueId();

        const alreadyProcessed = this._appState.getCookie(messageKey);
        if (alreadyProcessed == null) {
          if (isMessageTabOpen) {
            console.log('=================================ADD==========================');
            this.messages.next([result]);
            this._appState.setCookie(messageKey, new Date().getTime().toString(), 45);
            const tabOpenKey = `tab_open_for_id_${result.id}_` + this._appState.getTabUniqueId();
            this._appState.setCookie(tabOpenKey, new Date().getTime().toString(), 45);
          }
          const countMessageKey = messageKey + '-count';
          const countUpdated = this._appState.getCookie(countMessageKey);
          if (countUpdated == null) {
            console.log('Count not updated...');
            result.messageKey = countMessageKey;
            this.updateChatRoomCounts.next(result);

          }
          /**
           * Send update list sub
           */
          const listMessageKey = messageKey + '-listupdated';
          const listUpdated = this._appState.getCookie(listMessageKey);
          if (listUpdated == null) {
            this._appState.setCookie(listMessageKey, new Date().getTime().toString(), 45);
            result.isFromMessageService = true;
            this.newMessagesJob.next([result]);
          }
        } else {
          console.log('Message Already processed');
        }
      } catch (error) {
        console.log('Error while processing the message.', error)
      }
    });

  }
  applyPostedBy(results) {
    results.map(e => {
      if (e['contact'] && e['contact']['users']) {
        const owner = e['contact']['users'];
        e['owner'] = {
          accessType: owner.accessType ? owner.accessType : '',
          userTypeId: owner.userTypeId ? owner.userTypeId : '',
          firstname: owner.firstname ? owner.firstname : '',
          id: owner.id ? owner.id : '',
          lastname: owner.lastname ? owner.lastname : '',
          profileImage: owner.profileImage ? owner.profileImage : '',
          sfdcId: owner.sfdcId ? owner.sfdcId : '',
          url: owner.url ? owner.url : '',
          gender: e['contact'].Gender__c
        }
        e['isRead'] = (owner.id && (owner.id != this._currentUser.id)) ? this.setMsgReadKey(e) : "true"
      }else if(e['UnregisterEmail__c']){
        e['postByName'] = e['UnregisterEmail__c'];
        e['isRead'] = this.setMsgReadKey(e);

      } else {
        e['postByName'] = e['postByName'] ? e['postByName'] : "Auto";
        e['isRead'] = this.setMsgReadKey(e);
      }
      e['postByName'] = (e['owner'] && e['owner'].firstname) ? e['owner'].firstname + ' ' + e['owner'].lastname : e['postByName'];

    })
    return results;
  }


}

