import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { PreloaderService } from 'shared/services/preloader.service';
import { GlobalTalentPoolApi } from 'shared/sdk';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-global-talent-pool-lookup',
  templateUrl: './global-talent-pool-lookup.component.html',
  styleUrls: ['./global-talent-pool-lookup.component.css']
})
export class GlobalTalentPoolLookupComponent implements OnInit {

  @Input() lookupTitle = null;
  @Input() placeholder = null;
  @Input() isMultipleAllowed = false;
  @Input() bindLabel = 'label';
  @Input() bindValue = 'sfdcId';
  @Input() closeOnSelect = true;
  @Input() hideAlreadySelected = false;
  @Input() dropdownPosition = 'below';
  @Input() searchable = false;
  @Input() clearable = false;
  @Input() set resetData(isClear) {
    this.onClear(isClear);
  }
  @Input() set preSelected(sfdcId) {
    this.loadPoolData(sfdcId);
  }
  @Input() disabled = false;
  @Output() selectedValue: EventEmitter<any> = new EventEmitter;
  selectedItems: any;
  dataList: any = [];

  constructor(private _preloaderService: PreloaderService, private _poolApi: GlobalTalentPoolApi) {
  }

  ngOnInit() {
    
  }

  /**
   * This function will set selected value if provided from parent component.
  */
  setPreSelectedValue(sfdcId) {
    if (this.dataList.length && sfdcId) {
      this.selectedItems = sfdcId;
    } else {
      this.selectedItems = null;
    }
  }

  /**
   * This function call the API to load data for global talent pool
  */
  loadPoolData(sfdcId?: string) {
    this._preloaderService.showPreloader();
    this._poolApi.find().subscribe(
      data => {
        if (data) {
          this._preloaderService.hidePreloader();
          this.dataList = this.addExtraFields(data);
          this.setPreSelectedValue(sfdcId);
        }
      },
      err => {
        console.log('Error fetching Global Talent Pool List >> ', err.message);
      }
    );
  }

  /**
   * This function will add the Label field to every object in list of pool.
   * Label Field is used for creating detailed Name for the record.
  */
  addExtraFields(data: any[]) {
    return data.map((responseObject) => {
      responseObject['label'] = `${responseObject['Pool_Name__c']} (${responseObject['Name']})`;
      return responseObject;
    })
  }

  /**
   * This function executes whenever there is a change in selected values.
   * This will emit the changed array.
  */
  onChange() {
    this.selectedValue.emit(this.selectedItems);
  }

  /**
   * This function executes whenever the select input is cleared.
   * This will clear the array and emit an empty array.
  */
  onClear(isClear) {
    this.selectedItems = (this.isMultipleAllowed) ? [] : null;
    if (isClear) {
      this.selectedValue.emit((this.isMultipleAllowed) ? [] : null);
    }
  }
}
