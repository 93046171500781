import { SharedService } from './../../services/pms/shared.services';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { ModalService } from '../../services/modal.service'
import { MessageService } from 'shared/services/message.service';
import { AppStateService } from 'shared/services/app-state.service';
import { Subscription } from 'rxjs';
import { WorkflowService } from 'shared/services/workflow.service';

@Component({
  selector: 'app-job-data-card',
  templateUrl: './job-data-card.component.html',
  styleUrls: ['./job-data-card.component.css']
})
export class JobDataCardComponent implements OnInit, OnDestroy {
  @Input()
  set jobData(e) {
    this._jobData = e;
    this.setWorkFlowIconClass(false);
  }
  @Input()
  set active(e) {
    this._isActive = e;
  }
  @Input()
  set isPinned(e) {
    this._isPinned = e;
  }
  @Input() rhsPage;
  @Input() isPinningEnabled = false;
  @Input() isFavEnabled = true;
  @Input() set showFullDetail(e) {
    if (typeof e === 'boolean') {
      this._listViewType = e;
      this._closelistIcon = !e;
    }
  }
  @Input() showCaseIcon = false;

  @Output() pinned: EventEmitter<any> = new EventEmitter<any>();  
  @Output() favUpdated: EventEmitter<any> = new EventEmitter();
 
  private _statusCss = {
    delivered: 'delivered',
    completed: 'completed',
    started: 'started',
    resolved: 'resolved',
    closed: 'closed',
    cancelled: 'cancelled',
    declined: 'declined',
    invoiced: 'invoiced',
    paid: 'paid',
    invited: 'invited',
    assigned: 'assigned',
    accepted: 'accepted',
    new: 'new',
    'in-progress': 'in-progress',
  };
  _jobData: any;
  _isActive: Boolean;
  _isPinned: Boolean = false;
  _listViewType = true;
  _closelistIcon = false;
  accessType: string;
  wfChangeSubscription: Subscription;
  constructor(
    public _commonService: CommonService,
    public _modalService: ModalService,
    public _messageService: MessageService,
    private _workflowService: WorkflowService,
    public _appState: AppStateService,
    private _sharedService: SharedService
  ) {
    this.accessType = this._appState.getAccessType();
    this.subscribeJobEscalation()
    // To check if view as PMS mode is activated.
    if (localStorage.getItem('viewAsInfo')) {
      const viewAsInfo = JSON.parse(localStorage.getItem('viewAsInfo'));
      const viewAsPartner = (viewAsInfo && viewAsInfo.viewAsType === 'Partner');
      this.accessType = (viewAsPartner) ? 'partner' : this.accessType;
  }
   }

  ngOnInit() {
    // this._messageService.getMsgListType().subscribe(res => this._listViewType = res);
    // this._messageService.getMsgViewType().subscribe(res => {
    //   if (typeof res === 'boolean') {
    //     this._listViewType = res;
    //     this._closelistIcon = false;
    //   } else {
    //     // this._listViewType = (res === this._jobData._id) ? false : true;
    //     // this._closelistIcon = (res === this._jobData._id) ? true : false;
    //   }
    // });
    this._jobData.last_postByName = this._jobData.last_postByName ? this._jobData.last_postByName :
    this._jobData.unregister_Email ? this._jobData.unregister_Email : 'Auto';

    this.wfChangeSubscription = this._workflowService.getWorkFlowStatusId().subscribe((statusId: any) => {
       if (this._isActive && statusId) {
          this._jobData.job.workflowStatusId = statusId;
          this.setWorkFlowIconClass(true);
       }
    });
  }

  getPartnerCaseNumber(number) {
    if (number && number.length > 7) {
      return 'XX' + number.substr(number.length - 7)
    } else {
      return number ? 'XX' + number : '';
    }
  }

  getStatusClass(status: string = '') {
    status = status.toLowerCase();
    if (this._statusCss.hasOwnProperty(status)) {
      return this._statusCss[status];
    } else {
      return '';
    }
  }

  onfavUpdated(event) {
    event.stopPropagation();
    this.favUpdated.emit(this._jobData);
  }

  messagePinned() {
    event.stopPropagation();
    this.pinned.emit(this._jobData);
  }
  openProblemEscalation(modal, event) {
    event.stopPropagation();
    this._modalService.open(modal, 'lg');
  }

  onClickDetailsToggle(event, action) {
    event.stopPropagation();
    if (action === 'open') {
      this._closelistIcon = true;
      // this._messageService.setMsgViewType(this._jobData._id);
      this._listViewType = false;
    } else {
      this._listViewType = true;
      this._closelistIcon = false;
    }
  }

  getJobsiteName(val) {
    if (val) {
      const startIndex = val.indexOf('(');
      const endIndex = val.indexOf(')', startIndex);
      let editedStr = val.substring(startIndex - 1, endIndex+1);
      editedStr = val.replace(editedStr,'');
      return editedStr;
    }
  }

  setWorkFlowIconClass(isLiveChange) {
    if (this._jobData && this._jobData.job && this._jobData.job.workflowStatusId) {
      const job =  this._jobData.job;
      switch (job.workflowStatusId) {
        case 6: job.workflowStatusClass = 'decline';
                if (isLiveChange) {
                  job.Job_Status_Internal__c = 'Decline';
                }
                break;
        case 9: job.workflowStatusClass = 'active';
                if (isLiveChange) {
                  job.Job_Status_Internal__c = 'Accepted';
                }
                break;
        case 12: job.workflowStatusClass = '';
                if (isLiveChange) {
                  job.Job_Status_Internal__c = 'Assigned';
                }
                break;
        case 15: job.workflowStatusClass = 'en-route';
          break;
        case 16: job.workflowStatusClass = 'arrived';
          break;
        case 17: job.workflowStatusClass = 'workstarted';
                if (isLiveChange) {
                  job.Job_Status_Internal__c = 'Started';
                }
          break;
        case 18: job.workflowStatusClass = 'checked-out';
          break;
        case 20: job.workflowStatusClass = '';
                if (isLiveChange) {
                  job.Job_Status_Internal__c = 'Completed';
                }
                break;
        case 25: job.workflowStatusClass = '';
                if (isLiveChange) {
                  job.Job_Status_Internal__c = 'Invoiced';
                }
                break;
        case 27: job.workflowStatusClass = '';
                if (isLiveChange) {
                  job.Job_Status_Internal__c = 'Paid';
                }
                break;
        case 32: job.workflowStatusClass = '';
                if (isLiveChange) {
                  job.Job_Status_Internal__c = 'Closed';
                }
                break;
        default: job.workflowStatusClass =  '';
      }
    }
  }

  escalationOnItemHandler(e) {
    if(this._jobData && this._jobData.job) {
      this._jobData.job.isEscalatedJob = true;
    }
  }

   /**
   * Update excalation in worker list LHS
   */
  subscribeJobEscalation() {
    this._sharedService.jobEscalationSubject.subscribe(job => {
      if (job && job.id && this._jobData && this._jobData.job &&  this._jobData.job.id  == job.id) {
        this._jobData.job.isEscalatedJob = true;
      }
    })
  }

  ngOnDestroy() {
    if (this.wfChangeSubscription) {
      this.wfChangeSubscription.unsubscribe();
    }
  }
}
