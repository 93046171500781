/* tslint:disable */

declare var Object: any;
export interface TrainingPlanSectionAssignmentInterface {
  "sfdcId"?: string;
  "OwnerId"?: string;
  "IsDeleted"?: boolean;
  "Name"?: string;
  "CurrencyIsoCode"?: string;
  "CreatedDate"?: Date;
  "CreatedById"?: string;
  "LastModifiedDate"?: Date;
  "LastModifiedById"?: string;
  "LastActivityDate"?: Date;
  "Community_Id__c"?: string;
  "Completed_On__c"?: Date;
  "Status__c"?: string;
  "Training_Plan_Assignment__c"?: string;
  "Training_Plan_Section__c"?: string;
  "User__c"?: string;
  "Trainee__c"?: string;
  "Learning_End_DateTime__c"?: Date;
  "Learning_Start_DateTime__c"?: Date;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class TrainingPlanSectionAssignment implements TrainingPlanSectionAssignmentInterface {
  "sfdcId": string;
  "OwnerId": string;
  "IsDeleted": boolean;
  "Name": string;
  "CurrencyIsoCode": string;
  "CreatedDate": Date;
  "CreatedById": string;
  "LastModifiedDate": Date;
  "LastModifiedById": string;
  "LastActivityDate": Date;
  "Community_Id__c": string;
  "Completed_On__c": Date;
  "Status__c": string;
  "Training_Plan_Assignment__c": string;
  "Training_Plan_Section__c": string;
  "User__c": string;
  "Trainee__c": string;
  "Learning_End_DateTime__c": Date;
  "Learning_Start_DateTime__c": Date;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: TrainingPlanSectionAssignmentInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `TrainingPlanSectionAssignment`.
   */
  public static getModelName() {
    return "TrainingPlanSectionAssignment";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of TrainingPlanSectionAssignment for dynamic purposes.
  **/
  public static factory(data: TrainingPlanSectionAssignmentInterface): TrainingPlanSectionAssignment{
    return new TrainingPlanSectionAssignment(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'TrainingPlanSectionAssignment',
      plural: 'TrainingPlanSectionAssignments',
      path: 'TrainingPlanSectionAssignments',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "LastActivityDate": {
          name: 'LastActivityDate',
          type: 'Date'
        },
        "Community_Id__c": {
          name: 'Community_Id__c',
          type: 'string'
        },
        "Completed_On__c": {
          name: 'Completed_On__c',
          type: 'Date'
        },
        "Status__c": {
          name: 'Status__c',
          type: 'string'
        },
        "Training_Plan_Assignment__c": {
          name: 'Training_Plan_Assignment__c',
          type: 'string'
        },
        "Training_Plan_Section__c": {
          name: 'Training_Plan_Section__c',
          type: 'string'
        },
        "User__c": {
          name: 'User__c',
          type: 'string'
        },
        "Trainee__c": {
          name: 'Trainee__c',
          type: 'string'
        },
        "Learning_End_DateTime__c": {
          name: 'Learning_End_DateTime__c',
          type: 'Date'
        },
        "Learning_Start_DateTime__c": {
          name: 'Learning_Start_DateTime__c',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
