/* tslint:disable */
import {
  Contact
} from '../index';

declare var Object: any;
export interface CustomPricebookEntryInterface {
  "X4HR_Service_Area__c"?: string;
  "IsActive__c"?: boolean;
  "Additional_Countries_of_Origin__c"?: string;
  "Additional_Hours_T_M_AFTH_Multiplier__c"?: number;
  "Additional_Hours_T_M_AFTH_Price__c"?: number;
  "Additional_Hours_T_M_HLDY_Multiplier__c"?: number;
  "Additional_Hours_T_M_WKND_Multiplier__c"?: number;
  "Additional_Hours_T_M_Holiday_Price__c"?: number;
  "Additional_Hours_T_M_Standard_Cost__c"?: number;
  "Additional_Hours_T_M_Standard_Price__c"?: number;
  "Additional_Hours_T_M_Weekend_Price__c"?: number;
  "PPE_2HR_Standard_Cost__c"?: number;
  "City__c"?: string;
  "City_Zone__c"?: string;
  "Country__c"?: string;
  "Country_Zone__c"?: string;
  "FTE_Daily_Rate_Standard__c"?: number;
  "Half_Day_Rate__c"?: number;
  "FTE_Daily_Rate_Holiday__c"?: number;
  "FTE_Daily_Rate_Overtime__c"?: number;
  "FTE_Monthly_Rate__c"?: number;
  "Helpdesk_Dispatch_Fee__c"?: number;
  "HMS_Daily_Price__c"?: number;
  "Iron_Discount__c"?: number;
  "Is_FSE__c"?: boolean;
  "Item_Value_Defective__c"?: number;
  "Item_Value_Refurbished__c"?: number;
  "Item_Value_Used__c"?: number;
  "Item_Value_New_Sale_value_Not_std__c"?: number;
  "UnitPrice__c"?: number;
  "Minimum_Hours_of_Dispatch__c"?: string;
  "Modified_By__c"?: string;
  "Modified_DateTime__c"?: Date;
  "NBD_Service_Area__c"?: string;
  "PPE_1HR_Standard_Cost__c"?: number;
  "PPE_1HR_Standard_Price__c"?: number;
  "PPE_2HR_Standard_Price__c"?: number;
  "PPE_3HR_Standard_Cost__c"?: number;
  "PPE_3HR_Standard_Price__c"?: number;
  "PPE_4HR_Standard_Cost__c"?: number;
  "PPE_4HR_Standard_Price__c"?: number;
  "PPE_5HR_Standard_Price__c"?: number;
  "PPE_6HR_Standard_Price__c"?: number;
  "PPE_7HR_Standard_Price__c"?: number;
  "PPE_8HR_Standard_Cost__c"?: number;
  "PPE_8HR_Standard_Price__c"?: number;
  "Pricebook2__c"?: string;
  "Product2__c"?: string;
  "Product_Service_Cost__c"?: number;
  "ProductCode__c"?: string;
  "Province__c"?: string;
  "Service_Description__c"?: string;
  "Service_ID__c"?: string;
  "Standard_Hours_T_M_Multiplier__c"?: number;
  "StandardPrice__c"?: number;
  "TAX_Inclusive__c"?: boolean;
  "UseStandardPrice__c"?: boolean;
  "Agency_Cost__c"?: number;
  "Zip__c"?: string;
  "id"?: number;
  Contact?: Contact;
}

export class CustomPricebookEntry implements CustomPricebookEntryInterface {
  "X4HR_Service_Area__c": string;
  "IsActive__c": boolean;
  "Additional_Countries_of_Origin__c": string;
  "Additional_Hours_T_M_AFTH_Multiplier__c": number;
  "Additional_Hours_T_M_AFTH_Price__c": number;
  "Additional_Hours_T_M_HLDY_Multiplier__c": number;
  "Additional_Hours_T_M_WKND_Multiplier__c": number;
  "Additional_Hours_T_M_Holiday_Price__c": number;
  "Additional_Hours_T_M_Standard_Cost__c": number;
  "Additional_Hours_T_M_Standard_Price__c": number;
  "Additional_Hours_T_M_Weekend_Price__c": number;
  "PPE_2HR_Standard_Cost__c": number;
  "City__c": string;
  "City_Zone__c": string;
  "Country__c": string;
  "Country_Zone__c": string;
  "FTE_Daily_Rate_Standard__c": number;
  "Half_Day_Rate__c": number;
  "FTE_Daily_Rate_Holiday__c": number;
  "FTE_Daily_Rate_Overtime__c": number;
  "FTE_Monthly_Rate__c": number;
  "Helpdesk_Dispatch_Fee__c": number;
  "HMS_Daily_Price__c": number;
  "Iron_Discount__c": number;
  "Is_FSE__c": boolean;
  "Item_Value_Defective__c": number;
  "Item_Value_Refurbished__c": number;
  "Item_Value_Used__c": number;
  "Item_Value_New_Sale_value_Not_std__c": number;
  "UnitPrice__c": number;
  "Minimum_Hours_of_Dispatch__c": string;
  "Modified_By__c": string;
  "Modified_DateTime__c": Date;
  "NBD_Service_Area__c": string;
  "PPE_1HR_Standard_Cost__c": number;
  "PPE_1HR_Standard_Price__c": number;
  "PPE_2HR_Standard_Price__c": number;
  "PPE_3HR_Standard_Cost__c": number;
  "PPE_3HR_Standard_Price__c": number;
  "PPE_4HR_Standard_Cost__c": number;
  "PPE_4HR_Standard_Price__c": number;
  "PPE_5HR_Standard_Price__c": number;
  "PPE_6HR_Standard_Price__c": number;
  "PPE_7HR_Standard_Price__c": number;
  "PPE_8HR_Standard_Cost__c": number;
  "PPE_8HR_Standard_Price__c": number;
  "Pricebook2__c": string;
  "Product2__c": string;
  "Product_Service_Cost__c": number;
  "ProductCode__c": string;
  "Province__c": string;
  "Service_Description__c": string;
  "Service_ID__c": string;
  "Standard_Hours_T_M_Multiplier__c": number;
  "StandardPrice__c": number;
  "TAX_Inclusive__c": boolean;
  "UseStandardPrice__c": boolean;
  "Agency_Cost__c": number;
  "Zip__c": string;
  "id": number;
  Contact: Contact;
  constructor(data?: CustomPricebookEntryInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `CustomPricebookEntry`.
   */
  public static getModelName() {
    return "CustomPricebookEntry";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of CustomPricebookEntry for dynamic purposes.
  **/
  public static factory(data: CustomPricebookEntryInterface): CustomPricebookEntry{
    return new CustomPricebookEntry(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'CustomPricebookEntry',
      plural: 'CustomPricebookEntries',
      path: 'CustomPricebookEntries',
      idName: 'id',
      properties: {
        "X4HR_Service_Area__c": {
          name: 'X4HR_Service_Area__c',
          type: 'string'
        },
        "IsActive__c": {
          name: 'IsActive__c',
          type: 'boolean'
        },
        "Additional_Countries_of_Origin__c": {
          name: 'Additional_Countries_of_Origin__c',
          type: 'string'
        },
        "Additional_Hours_T_M_AFTH_Multiplier__c": {
          name: 'Additional_Hours_T_M_AFTH_Multiplier__c',
          type: 'number'
        },
        "Additional_Hours_T_M_AFTH_Price__c": {
          name: 'Additional_Hours_T_M_AFTH_Price__c',
          type: 'number'
        },
        "Additional_Hours_T_M_HLDY_Multiplier__c": {
          name: 'Additional_Hours_T_M_HLDY_Multiplier__c',
          type: 'number'
        },
        "Additional_Hours_T_M_WKND_Multiplier__c": {
          name: 'Additional_Hours_T_M_WKND_Multiplier__c',
          type: 'number'
        },
        "Additional_Hours_T_M_Holiday_Price__c": {
          name: 'Additional_Hours_T_M_Holiday_Price__c',
          type: 'number'
        },
        "Additional_Hours_T_M_Standard_Cost__c": {
          name: 'Additional_Hours_T_M_Standard_Cost__c',
          type: 'number'
        },
        "Additional_Hours_T_M_Standard_Price__c": {
          name: 'Additional_Hours_T_M_Standard_Price__c',
          type: 'number'
        },
        "Additional_Hours_T_M_Weekend_Price__c": {
          name: 'Additional_Hours_T_M_Weekend_Price__c',
          type: 'number'
        },
        "PPE_2HR_Standard_Cost__c": {
          name: 'PPE_2HR_Standard_Cost__c',
          type: 'number'
        },
        "City__c": {
          name: 'City__c',
          type: 'string'
        },
        "City_Zone__c": {
          name: 'City_Zone__c',
          type: 'string'
        },
        "Country__c": {
          name: 'Country__c',
          type: 'string'
        },
        "Country_Zone__c": {
          name: 'Country_Zone__c',
          type: 'string'
        },
        "FTE_Daily_Rate_Standard__c": {
          name: 'FTE_Daily_Rate_Standard__c',
          type: 'number'
        },
        "Half_Day_Rate__c": {
          name: 'Half_Day_Rate__c',
          type: 'number'
        },
        "FTE_Daily_Rate_Holiday__c": {
          name: 'FTE_Daily_Rate_Holiday__c',
          type: 'number'
        },
        "FTE_Daily_Rate_Overtime__c": {
          name: 'FTE_Daily_Rate_Overtime__c',
          type: 'number'
        },
        "FTE_Monthly_Rate__c": {
          name: 'FTE_Monthly_Rate__c',
          type: 'number'
        },
        "Helpdesk_Dispatch_Fee__c": {
          name: 'Helpdesk_Dispatch_Fee__c',
          type: 'number'
        },
        "HMS_Daily_Price__c": {
          name: 'HMS_Daily_Price__c',
          type: 'number'
        },
        "Iron_Discount__c": {
          name: 'Iron_Discount__c',
          type: 'number'
        },
        "Is_FSE__c": {
          name: 'Is_FSE__c',
          type: 'boolean'
        },
        "Item_Value_Defective__c": {
          name: 'Item_Value_Defective__c',
          type: 'number'
        },
        "Item_Value_Refurbished__c": {
          name: 'Item_Value_Refurbished__c',
          type: 'number'
        },
        "Item_Value_Used__c": {
          name: 'Item_Value_Used__c',
          type: 'number'
        },
        "Item_Value_New_Sale_value_Not_std__c": {
          name: 'Item_Value_New_Sale_value_Not_std__c',
          type: 'number'
        },
        "UnitPrice__c": {
          name: 'UnitPrice__c',
          type: 'number'
        },
        "Minimum_Hours_of_Dispatch__c": {
          name: 'Minimum_Hours_of_Dispatch__c',
          type: 'string'
        },
        "Modified_By__c": {
          name: 'Modified_By__c',
          type: 'string'
        },
        "Modified_DateTime__c": {
          name: 'Modified_DateTime__c',
          type: 'Date'
        },
        "NBD_Service_Area__c": {
          name: 'NBD_Service_Area__c',
          type: 'string'
        },
        "PPE_1HR_Standard_Cost__c": {
          name: 'PPE_1HR_Standard_Cost__c',
          type: 'number'
        },
        "PPE_1HR_Standard_Price__c": {
          name: 'PPE_1HR_Standard_Price__c',
          type: 'number'
        },
        "PPE_2HR_Standard_Price__c": {
          name: 'PPE_2HR_Standard_Price__c',
          type: 'number'
        },
        "PPE_3HR_Standard_Cost__c": {
          name: 'PPE_3HR_Standard_Cost__c',
          type: 'number'
        },
        "PPE_3HR_Standard_Price__c": {
          name: 'PPE_3HR_Standard_Price__c',
          type: 'number'
        },
        "PPE_4HR_Standard_Cost__c": {
          name: 'PPE_4HR_Standard_Cost__c',
          type: 'number'
        },
        "PPE_4HR_Standard_Price__c": {
          name: 'PPE_4HR_Standard_Price__c',
          type: 'number'
        },
        "PPE_5HR_Standard_Price__c": {
          name: 'PPE_5HR_Standard_Price__c',
          type: 'number'
        },
        "PPE_6HR_Standard_Price__c": {
          name: 'PPE_6HR_Standard_Price__c',
          type: 'number'
        },
        "PPE_7HR_Standard_Price__c": {
          name: 'PPE_7HR_Standard_Price__c',
          type: 'number'
        },
        "PPE_8HR_Standard_Cost__c": {
          name: 'PPE_8HR_Standard_Cost__c',
          type: 'number'
        },
        "PPE_8HR_Standard_Price__c": {
          name: 'PPE_8HR_Standard_Price__c',
          type: 'number'
        },
        "Pricebook2__c": {
          name: 'Pricebook2__c',
          type: 'string'
        },
        "Product2__c": {
          name: 'Product2__c',
          type: 'string'
        },
        "Product_Service_Cost__c": {
          name: 'Product_Service_Cost__c',
          type: 'number'
        },
        "ProductCode__c": {
          name: 'ProductCode__c',
          type: 'string'
        },
        "Province__c": {
          name: 'Province__c',
          type: 'string'
        },
        "Service_Description__c": {
          name: 'Service_Description__c',
          type: 'string'
        },
        "Service_ID__c": {
          name: 'Service_ID__c',
          type: 'string'
        },
        "Standard_Hours_T_M_Multiplier__c": {
          name: 'Standard_Hours_T_M_Multiplier__c',
          type: 'number'
        },
        "StandardPrice__c": {
          name: 'StandardPrice__c',
          type: 'number'
        },
        "TAX_Inclusive__c": {
          name: 'TAX_Inclusive__c',
          type: 'boolean'
        },
        "UseStandardPrice__c": {
          name: 'UseStandardPrice__c',
          type: 'boolean'
        },
        "Agency_Cost__c": {
          name: 'Agency_Cost__c',
          type: 'number'
        },
        "Zip__c": {
          name: 'Zip__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        Contact: {
          name: 'Contact',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Modified_By__c',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
