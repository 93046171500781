/* tslint:disable */
import {
  Account,
  Case,
  Location,
  WorkOrder,
  JobOrderItem,
  Pricelist,
  Jobsite,
  Job,
  Asset,
  Product
} from '../index';

declare var Object: any;
export interface ProductRequestLineItemInterface {
  "sfdcId"?: string;
  "AccountId"?: string;
  "Aging__c"?: number;
  "Asset__c"?: string;
  "Carrier_Name__c"?: string;
  "Carrier_Tracking_Number__c"?: string;
  "CaseId"?: string;
  "Contact_Email__c"?: string;
  "CreatedById"?: string;
  "CreatedDate"?: Date;
  "CurrencyIsoCode"?: string;
  "Delivered__c"?: boolean;
  "Delivery_Confirmation_POD_Signature__c"?: string;
  "Delivery_Date__c"?: string;
  "Description"?: string;
  "DestinationLocationId"?: string;
  "FRU_Item_Description__c"?: string;
  "FRU_MAC_Number_New_Item__c"?: string;
  "FRU_Receive_Date_from_FSL_to_Iron__c"?: Date;
  "FRU_SerialNumber__c"?: string;
  "FRU_Serial_Number_New_Item__c"?: string;
  "FRU_Ship_from_Vendor_Date__c"?: Date;
  "FRU_Ship_to_Vendor_Date__c"?: Date;
  "Fifth_notification_53_days__c"?: Date;
  "First_notification_2_days__c"?: Date;
  "Fourth_notification_23_days__c"?: Date;
  "ICC_Case__c"?: string;
  "IRON_Price__c"?: number;
  "IRON_RMA_Ticket__c"?: string;
  "Inventory_Missing__c"?: boolean;
  "IsDeleted"?: boolean;
  "Job__c"?: string;
  "Jobsite__c"?: string;
  "LastModifiedById"?: string;
  "LastModifiedDate"?: Date;
  "List_Price_Partner__c"?: number;
  "List_Price_Vendor__c"?: number;
  "Location_Warehouse_Setup_Status__c"?: string;
  "MPN_Feature_Code__c"?: string;
  "NeedByDate"?: Date;
  "On_hold__c"?: boolean;
  "PMS_Case__c"?: string;
  "ParentId"?: string;
  "Partner_Pricelist_Auto__c"?: string;
  "Product2Id"?: string;
  "ProductRequestLineItemNumber"?: string;
  "Product_Item_Inventory_Status__c"?: string;
  "Product_Item_Stock_Status__c"?: string;
  "QuantityRequested"?: number;
  "QuantityUnitOfMeasure"?: string;
  "Quantity_Out_Of_Stock__c"?: boolean;
  "RecordTypeId"?: string;
  "Return_FRU_Serial_Number__c"?: string;
  "Return_Tracking_to_Vendor__c"?: string;
  "Returns_outside_of_normal_process__c"?: boolean;
  "Second_notification_9_days__c"?: Date;
  "ShipToAddress"?: string;
  "ShipToCity"?: string;
  "ShipToCountry"?: string;
  "ShipToGeocodeAccuracy"?: string;
  "ShipToLatitude"?: string;
  "ShipToLongitude"?: string;
  "ShipToPostalCode"?: string;
  "ShipToState"?: string;
  "ShipToStreet"?: string;
  "ShipmentType"?: string;
  "Shipped__c"?: boolean;
  "Sixth_notification_83_days__c"?: Date;
  "SourceLocationId"?: string;
  "Source_Location_Missing__c"?: boolean;
  "Status"?: string;
  "Vendor_Pricelist_Auto__c"?: string;
  "Vendor_RMA__c"?: string;
  "Vendor__c"?: string;
  "WSL_Sub_Inventory_Code__c"?: string;
  "WorkOrderId"?: string;
  "WorkOrderLineItemId"?: string;
  "Work_Order_Items__c"?: string;
  "id"?: number;
  "Recovery_Case__c"?: string;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  account?: Account;
  case?: Case;
  dlocation?: Location;
  slocation?: Location;
  workOrder?: WorkOrder;
  workOrderItem?: JobOrderItem;
  vendorPriceBook?: Pricelist;
  vendorAccount?: Account;
  recoveryCase?: Case;
  pmsCase?: Case;
  iccCase?: Case;
  partnerPriceBook?: Pricelist;
  jobsite?: Jobsite;
  job?: Job;
  asset?: Asset;
  product?: Product;
}

export class ProductRequestLineItem implements ProductRequestLineItemInterface {
  "sfdcId": string;
  "AccountId": string;
  "Aging__c": number;
  "Asset__c": string;
  "Carrier_Name__c": string;
  "Carrier_Tracking_Number__c": string;
  "CaseId": string;
  "Contact_Email__c": string;
  "CreatedById": string;
  "CreatedDate": Date;
  "CurrencyIsoCode": string;
  "Delivered__c": boolean;
  "Delivery_Confirmation_POD_Signature__c": string;
  "Delivery_Date__c": string;
  "Description": string;
  "DestinationLocationId": string;
  "FRU_Item_Description__c": string;
  "FRU_MAC_Number_New_Item__c": string;
  "FRU_Receive_Date_from_FSL_to_Iron__c": Date;
  "FRU_SerialNumber__c": string;
  "FRU_Serial_Number_New_Item__c": string;
  "FRU_Ship_from_Vendor_Date__c": Date;
  "FRU_Ship_to_Vendor_Date__c": Date;
  "Fifth_notification_53_days__c": Date;
  "First_notification_2_days__c": Date;
  "Fourth_notification_23_days__c": Date;
  "ICC_Case__c": string;
  "IRON_Price__c": number;
  "IRON_RMA_Ticket__c": string;
  "Inventory_Missing__c": boolean;
  "IsDeleted": boolean;
  "Job__c": string;
  "Jobsite__c": string;
  "LastModifiedById": string;
  "LastModifiedDate": Date;
  "List_Price_Partner__c": number;
  "List_Price_Vendor__c": number;
  "Location_Warehouse_Setup_Status__c": string;
  "MPN_Feature_Code__c": string;
  "NeedByDate": Date;
  "On_hold__c": boolean;
  "PMS_Case__c": string;
  "ParentId": string;
  "Partner_Pricelist_Auto__c": string;
  "Product2Id": string;
  "ProductRequestLineItemNumber": string;
  "Product_Item_Inventory_Status__c": string;
  "Product_Item_Stock_Status__c": string;
  "QuantityRequested": number;
  "QuantityUnitOfMeasure": string;
  "Quantity_Out_Of_Stock__c": boolean;
  "RecordTypeId": string;
  "Return_FRU_Serial_Number__c": string;
  "Return_Tracking_to_Vendor__c": string;
  "Returns_outside_of_normal_process__c": boolean;
  "Second_notification_9_days__c": Date;
  "ShipToAddress": string;
  "ShipToCity": string;
  "ShipToCountry": string;
  "ShipToGeocodeAccuracy": string;
  "ShipToLatitude": string;
  "ShipToLongitude": string;
  "ShipToPostalCode": string;
  "ShipToState": string;
  "ShipToStreet": string;
  "ShipmentType": string;
  "Shipped__c": boolean;
  "Sixth_notification_83_days__c": Date;
  "SourceLocationId": string;
  "Source_Location_Missing__c": boolean;
  "Status": string;
  "Vendor_Pricelist_Auto__c": string;
  "Vendor_RMA__c": string;
  "Vendor__c": string;
  "WSL_Sub_Inventory_Code__c": string;
  "WorkOrderId": string;
  "WorkOrderLineItemId": string;
  "Work_Order_Items__c": string;
  "id": number;
  "Recovery_Case__c": string;
  "createdAt": Date;
  "updatedAt": Date;
  account: Account;
  case: Case;
  dlocation: Location;
  slocation: Location;
  workOrder: WorkOrder;
  workOrderItem: JobOrderItem;
  vendorPriceBook: Pricelist;
  vendorAccount: Account;
  recoveryCase: Case;
  pmsCase: Case;
  iccCase: Case;
  partnerPriceBook: Pricelist;
  jobsite: Jobsite;
  job: Job;
  asset: Asset;
  product: Product;
  constructor(data?: ProductRequestLineItemInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ProductRequestLineItem`.
   */
  public static getModelName() {
    return "ProductRequestLineItem";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ProductRequestLineItem for dynamic purposes.
  **/
  public static factory(data: ProductRequestLineItemInterface): ProductRequestLineItem{
    return new ProductRequestLineItem(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ProductRequestLineItem',
      plural: 'ProductRequestLineItems',
      path: 'ProductRequestLineItems',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "AccountId": {
          name: 'AccountId',
          type: 'string'
        },
        "Aging__c": {
          name: 'Aging__c',
          type: 'number'
        },
        "Asset__c": {
          name: 'Asset__c',
          type: 'string'
        },
        "Carrier_Name__c": {
          name: 'Carrier_Name__c',
          type: 'string'
        },
        "Carrier_Tracking_Number__c": {
          name: 'Carrier_Tracking_Number__c',
          type: 'string'
        },
        "CaseId": {
          name: 'CaseId',
          type: 'string'
        },
        "Contact_Email__c": {
          name: 'Contact_Email__c',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "Delivered__c": {
          name: 'Delivered__c',
          type: 'boolean'
        },
        "Delivery_Confirmation_POD_Signature__c": {
          name: 'Delivery_Confirmation_POD_Signature__c',
          type: 'string'
        },
        "Delivery_Date__c": {
          name: 'Delivery_Date__c',
          type: 'string'
        },
        "Description": {
          name: 'Description',
          type: 'string'
        },
        "DestinationLocationId": {
          name: 'DestinationLocationId',
          type: 'string'
        },
        "FRU_Item_Description__c": {
          name: 'FRU_Item_Description__c',
          type: 'string'
        },
        "FRU_MAC_Number_New_Item__c": {
          name: 'FRU_MAC_Number_New_Item__c',
          type: 'string'
        },
        "FRU_Receive_Date_from_FSL_to_Iron__c": {
          name: 'FRU_Receive_Date_from_FSL_to_Iron__c',
          type: 'Date'
        },
        "FRU_SerialNumber__c": {
          name: 'FRU_SerialNumber__c',
          type: 'string'
        },
        "FRU_Serial_Number_New_Item__c": {
          name: 'FRU_Serial_Number_New_Item__c',
          type: 'string'
        },
        "FRU_Ship_from_Vendor_Date__c": {
          name: 'FRU_Ship_from_Vendor_Date__c',
          type: 'Date'
        },
        "FRU_Ship_to_Vendor_Date__c": {
          name: 'FRU_Ship_to_Vendor_Date__c',
          type: 'Date'
        },
        "Fifth_notification_53_days__c": {
          name: 'Fifth_notification_53_days__c',
          type: 'Date'
        },
        "First_notification_2_days__c": {
          name: 'First_notification_2_days__c',
          type: 'Date'
        },
        "Fourth_notification_23_days__c": {
          name: 'Fourth_notification_23_days__c',
          type: 'Date'
        },
        "ICC_Case__c": {
          name: 'ICC_Case__c',
          type: 'string'
        },
        "IRON_Price__c": {
          name: 'IRON_Price__c',
          type: 'number'
        },
        "IRON_RMA_Ticket__c": {
          name: 'IRON_RMA_Ticket__c',
          type: 'string'
        },
        "Inventory_Missing__c": {
          name: 'Inventory_Missing__c',
          type: 'boolean'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "Job__c": {
          name: 'Job__c',
          type: 'string'
        },
        "Jobsite__c": {
          name: 'Jobsite__c',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "List_Price_Partner__c": {
          name: 'List_Price_Partner__c',
          type: 'number'
        },
        "List_Price_Vendor__c": {
          name: 'List_Price_Vendor__c',
          type: 'number'
        },
        "Location_Warehouse_Setup_Status__c": {
          name: 'Location_Warehouse_Setup_Status__c',
          type: 'string'
        },
        "MPN_Feature_Code__c": {
          name: 'MPN_Feature_Code__c',
          type: 'string'
        },
        "NeedByDate": {
          name: 'NeedByDate',
          type: 'Date'
        },
        "On_hold__c": {
          name: 'On_hold__c',
          type: 'boolean'
        },
        "PMS_Case__c": {
          name: 'PMS_Case__c',
          type: 'string'
        },
        "ParentId": {
          name: 'ParentId',
          type: 'string'
        },
        "Partner_Pricelist_Auto__c": {
          name: 'Partner_Pricelist_Auto__c',
          type: 'string'
        },
        "Product2Id": {
          name: 'Product2Id',
          type: 'string'
        },
        "ProductRequestLineItemNumber": {
          name: 'ProductRequestLineItemNumber',
          type: 'string'
        },
        "Product_Item_Inventory_Status__c": {
          name: 'Product_Item_Inventory_Status__c',
          type: 'string'
        },
        "Product_Item_Stock_Status__c": {
          name: 'Product_Item_Stock_Status__c',
          type: 'string'
        },
        "QuantityRequested": {
          name: 'QuantityRequested',
          type: 'number'
        },
        "QuantityUnitOfMeasure": {
          name: 'QuantityUnitOfMeasure',
          type: 'string'
        },
        "Quantity_Out_Of_Stock__c": {
          name: 'Quantity_Out_Of_Stock__c',
          type: 'boolean'
        },
        "RecordTypeId": {
          name: 'RecordTypeId',
          type: 'string'
        },
        "Return_FRU_Serial_Number__c": {
          name: 'Return_FRU_Serial_Number__c',
          type: 'string'
        },
        "Return_Tracking_to_Vendor__c": {
          name: 'Return_Tracking_to_Vendor__c',
          type: 'string'
        },
        "Returns_outside_of_normal_process__c": {
          name: 'Returns_outside_of_normal_process__c',
          type: 'boolean'
        },
        "Second_notification_9_days__c": {
          name: 'Second_notification_9_days__c',
          type: 'Date'
        },
        "ShipToAddress": {
          name: 'ShipToAddress',
          type: 'string'
        },
        "ShipToCity": {
          name: 'ShipToCity',
          type: 'string'
        },
        "ShipToCountry": {
          name: 'ShipToCountry',
          type: 'string'
        },
        "ShipToGeocodeAccuracy": {
          name: 'ShipToGeocodeAccuracy',
          type: 'string'
        },
        "ShipToLatitude": {
          name: 'ShipToLatitude',
          type: 'string'
        },
        "ShipToLongitude": {
          name: 'ShipToLongitude',
          type: 'string'
        },
        "ShipToPostalCode": {
          name: 'ShipToPostalCode',
          type: 'string'
        },
        "ShipToState": {
          name: 'ShipToState',
          type: 'string'
        },
        "ShipToStreet": {
          name: 'ShipToStreet',
          type: 'string'
        },
        "ShipmentType": {
          name: 'ShipmentType',
          type: 'string'
        },
        "Shipped__c": {
          name: 'Shipped__c',
          type: 'boolean'
        },
        "Sixth_notification_83_days__c": {
          name: 'Sixth_notification_83_days__c',
          type: 'Date'
        },
        "SourceLocationId": {
          name: 'SourceLocationId',
          type: 'string'
        },
        "Source_Location_Missing__c": {
          name: 'Source_Location_Missing__c',
          type: 'boolean'
        },
        "Status": {
          name: 'Status',
          type: 'string'
        },
        "Vendor_Pricelist_Auto__c": {
          name: 'Vendor_Pricelist_Auto__c',
          type: 'string'
        },
        "Vendor_RMA__c": {
          name: 'Vendor_RMA__c',
          type: 'string'
        },
        "Vendor__c": {
          name: 'Vendor__c',
          type: 'string'
        },
        "WSL_Sub_Inventory_Code__c": {
          name: 'WSL_Sub_Inventory_Code__c',
          type: 'string'
        },
        "WorkOrderId": {
          name: 'WorkOrderId',
          type: 'string'
        },
        "WorkOrderLineItemId": {
          name: 'WorkOrderLineItemId',
          type: 'string'
        },
        "Work_Order_Items__c": {
          name: 'Work_Order_Items__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "Recovery_Case__c": {
          name: 'Recovery_Case__c',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'AccountId',
          keyTo: 'sfdcId'
        },
        case: {
          name: 'case',
          type: 'Case',
          model: 'Case',
          relationType: 'belongsTo',
                  keyFrom: 'CaseId',
          keyTo: 'sfdcId'
        },
        dlocation: {
          name: 'dlocation',
          type: 'Location',
          model: 'Location',
          relationType: 'belongsTo',
                  keyFrom: 'DestinationLocationId',
          keyTo: 'sfdcId'
        },
        slocation: {
          name: 'slocation',
          type: 'Location',
          model: 'Location',
          relationType: 'belongsTo',
                  keyFrom: 'SourceLocationId',
          keyTo: 'sfdcId'
        },
        workOrder: {
          name: 'workOrder',
          type: 'WorkOrder',
          model: 'WorkOrder',
          relationType: 'belongsTo',
                  keyFrom: 'WorkOrderId',
          keyTo: 'sfdcId'
        },
        workOrderItem: {
          name: 'workOrderItem',
          type: 'JobOrderItem',
          model: 'JobOrderItem',
          relationType: 'belongsTo',
                  keyFrom: 'WorkOrderLineItemId',
          keyTo: 'sfdcId'
        },
        vendorPriceBook: {
          name: 'vendorPriceBook',
          type: 'Pricelist',
          model: 'Pricelist',
          relationType: 'belongsTo',
                  keyFrom: 'Vendor_Pricelist_Auto__c',
          keyTo: 'sfdcId'
        },
        vendorAccount: {
          name: 'vendorAccount',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'Vendor__c',
          keyTo: 'sfdcId'
        },
        recoveryCase: {
          name: 'recoveryCase',
          type: 'Case',
          model: 'Case',
          relationType: 'belongsTo',
                  keyFrom: 'Recovery_Case__c',
          keyTo: 'sfdcId'
        },
        pmsCase: {
          name: 'pmsCase',
          type: 'Case',
          model: 'Case',
          relationType: 'belongsTo',
                  keyFrom: 'PMS_Case__c',
          keyTo: 'sfdcId'
        },
        iccCase: {
          name: 'iccCase',
          type: 'Case',
          model: 'Case',
          relationType: 'belongsTo',
                  keyFrom: 'ICC_Case__c',
          keyTo: 'sfdcId'
        },
        partnerPriceBook: {
          name: 'partnerPriceBook',
          type: 'Pricelist',
          model: 'Pricelist',
          relationType: 'belongsTo',
                  keyFrom: 'Partner_Pricelist_Auto__c',
          keyTo: 'sfdcId'
        },
        jobsite: {
          name: 'jobsite',
          type: 'Jobsite',
          model: 'Jobsite',
          relationType: 'belongsTo',
                  keyFrom: 'Jobsite__c',
          keyTo: 'sfdcId'
        },
        job: {
          name: 'job',
          type: 'Job',
          model: 'Job',
          relationType: 'belongsTo',
                  keyFrom: 'Job__c',
          keyTo: 'sfdcId'
        },
        asset: {
          name: 'asset',
          type: 'Asset',
          model: 'Asset',
          relationType: 'belongsTo',
                  keyFrom: 'Asset__c',
          keyTo: 'sfdcId'
        },
        product: {
          name: 'product',
          type: 'Product',
          model: 'Product',
          relationType: 'belongsTo',
                  keyFrom: 'Product2Id',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
