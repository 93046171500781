import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-health-check',
  templateUrl: './health-check.component.html',
  styleUrls: ['./health-check.component.css']
})
export class HealthCheckComponent implements OnInit {
  @Input() customClass = '';
  @Input()
  set checks(e) {
    if (e && e.length) {
      this.data = e;
      // this.formatDate(this.data);
    }
  }
  @Input() type = 0;
  data: any;
  constructor() { }

  ngOnInit() {
  }

  // formatDate(data) {
  //   if (data) {
  //     data.forEach(ele => {
  //       if (ele.value && ele.value && ele.value.match('<img src=(.*)/>')) {
  //         const regex = /<img.*?src="(.*?)"/;
  //         const src = regex.exec(ele.value) ? regex.exec(ele.value)[1] : '';
  //         url.split('/').pop()
  //         ele['imgSrc'] = 'assets' + src;
  //       }
  //     });
  //     console.log(data);
  //   }
  // }
}
