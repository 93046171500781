import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { NotificationComponent } from './notification.component';
import { NotificationHeaderComponent } from './notification-header.component';
import { NotificationListComponent } from './notifications-list/notification-list.component';
import { NotificationDetailComponent } from './notification-detail/notification-detail.component';
import { UtilityService } from '../../services/utility.service';
import { SharedService } from '../../services/pms/shared.services';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ReadMoreModule } from '../read-more/read-more.module';

 
@NgModule({
    declarations: [
        NotificationComponent,
        NotificationHeaderComponent,
        NotificationListComponent,
        NotificationDetailComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        NgxDatatableModule,
        InfiniteScrollModule,
        ReadMoreModule
    ],
    exports: [
        NotificationComponent,
        NotificationHeaderComponent,
        NotificationListComponent,
        NotificationDetailComponent
    ],
    schemas: [],
    providers: [
        UtilityService,
        SharedService
    ]
})

export class NotificationModule { }
