/* tslint:disable */
import {
  Worker,
  Account,
  WSCRequestHeader
} from '../index';

declare var Object: any;
export interface WSCRequestDetailInterface {
  "sfdcId": string;
  "CreatedBy"?: string;
  "CurrencyIsoCode"?: string;
  "LastModifiedBy"?: string;
  "Name"?: string;
  "Completed__c"?: Date;
  "IRON_ExtID__c"?: string;
  "Request_Modified_By__c"?: string;
  "Request_Priority__c"?: string;
  "Retain_Worker__c"?: boolean;
  "Vendor__c"?: string;
  "Worker__c"?: string;
  "Worker_Allocated__c"?: boolean;
  "Worker_Response_Status__c"?: string;
  "Worker_Type_Backfill__c"?: boolean;
  "WSC_Request_Number__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  worker?: Worker;
  vendor?: Account;
  wscRequestHeader?: WSCRequestHeader;
}

export class WSCRequestDetail implements WSCRequestDetailInterface {
  "sfdcId": string;
  "CreatedBy": string;
  "CurrencyIsoCode": string;
  "LastModifiedBy": string;
  "Name": string;
  "Completed__c": Date;
  "IRON_ExtID__c": string;
  "Request_Modified_By__c": string;
  "Request_Priority__c": string;
  "Retain_Worker__c": boolean;
  "Vendor__c": string;
  "Worker__c": string;
  "Worker_Allocated__c": boolean;
  "Worker_Response_Status__c": string;
  "Worker_Type_Backfill__c": boolean;
  "WSC_Request_Number__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  worker: Worker;
  vendor: Account;
  wscRequestHeader: WSCRequestHeader;
  constructor(data?: WSCRequestDetailInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `WSCRequestDetail`.
   */
  public static getModelName() {
    return "WSCRequestDetail";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of WSCRequestDetail for dynamic purposes.
  **/
  public static factory(data: WSCRequestDetailInterface): WSCRequestDetail{
    return new WSCRequestDetail(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'WSCRequestDetail',
      plural: 'WSCRequestDetails',
      path: 'WSCRequestDetails',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "CreatedBy": {
          name: 'CreatedBy',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "LastModifiedBy": {
          name: 'LastModifiedBy',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "Completed__c": {
          name: 'Completed__c',
          type: 'Date'
        },
        "IRON_ExtID__c": {
          name: 'IRON_ExtID__c',
          type: 'string'
        },
        "Request_Modified_By__c": {
          name: 'Request_Modified_By__c',
          type: 'string'
        },
        "Request_Priority__c": {
          name: 'Request_Priority__c',
          type: 'string'
        },
        "Retain_Worker__c": {
          name: 'Retain_Worker__c',
          type: 'boolean'
        },
        "Vendor__c": {
          name: 'Vendor__c',
          type: 'string'
        },
        "Worker__c": {
          name: 'Worker__c',
          type: 'string'
        },
        "Worker_Allocated__c": {
          name: 'Worker_Allocated__c',
          type: 'boolean'
        },
        "Worker_Response_Status__c": {
          name: 'Worker_Response_Status__c',
          type: 'string'
        },
        "Worker_Type_Backfill__c": {
          name: 'Worker_Type_Backfill__c',
          type: 'boolean'
        },
        "WSC_Request_Number__c": {
          name: 'WSC_Request_Number__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        worker: {
          name: 'worker',
          type: 'Worker',
          model: 'Worker',
          relationType: 'belongsTo',
                  keyFrom: 'Worker__c',
          keyTo: 'sfdcId'
        },
        vendor: {
          name: 'vendor',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'Vendor__c',
          keyTo: 'sfdcId'
        },
        wscRequestHeader: {
          name: 'wscRequestHeader',
          type: 'WSCRequestHeader',
          model: 'WSCRequestHeader',
          relationType: 'belongsTo',
                  keyFrom: 'WSC_Request_Number__c',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
