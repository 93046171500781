/* tslint:disable */
import {
  Department,
  IronAttachment,
  LibraryDetail,
  MilestoneTaskLibraryMap
} from '../index';

declare var Object: any;
export interface MilestoneLibraryInterface {
  "sfdcId"?: string;
  "CreatedById"?: string;
  "CurrencyIsoCode"?: string;
  "LastModifiedById"?: string;
  "OwnerId"?: string;
  "Name"?: string;
  "Activity_type__c"?: string;
  "Description__c"?: string;
  "Duration__c"?: number;
  "Duration_Type__c"?: string;
  "Form_Name__c"?: string;
  "Form_Name_Selected__c"?: string;
  "Iron_Attachments__c"?: string;
  "Library_Type_Selected__c"?: string;
  "Milestone_Name__c"?: string;
  "PgMO_Departments__c"?: string;
  "Sequence_Number__c"?: number;
  "Short_URL__c"?: string;
  "Temporary_Sequence_Number__c"?: number;
  "Type__c"?: string;
  "createdOnServiceo"?: boolean;
  "isDeleted"?: boolean;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  department?: Department;
  attachments?: IronAttachment[];
  assignments?: LibraryDetail[];
  milestoneTaskLibraryMap?: MilestoneTaskLibraryMap[];
}

export class MilestoneLibrary implements MilestoneLibraryInterface {
  "sfdcId": string;
  "CreatedById": string;
  "CurrencyIsoCode": string;
  "LastModifiedById": string;
  "OwnerId": string;
  "Name": string;
  "Activity_type__c": string;
  "Description__c": string;
  "Duration__c": number;
  "Duration_Type__c": string;
  "Form_Name__c": string;
  "Form_Name_Selected__c": string;
  "Iron_Attachments__c": string;
  "Library_Type_Selected__c": string;
  "Milestone_Name__c": string;
  "PgMO_Departments__c": string;
  "Sequence_Number__c": number;
  "Short_URL__c": string;
  "Temporary_Sequence_Number__c": number;
  "Type__c": string;
  "createdOnServiceo": boolean;
  "isDeleted": boolean;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  department: Department;
  attachments: IronAttachment[];
  assignments: LibraryDetail[];
  milestoneTaskLibraryMap: MilestoneTaskLibraryMap[];
  constructor(data?: MilestoneLibraryInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `MilestoneLibrary`.
   */
  public static getModelName() {
    return "MilestoneLibrary";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of MilestoneLibrary for dynamic purposes.
  **/
  public static factory(data: MilestoneLibraryInterface): MilestoneLibrary{
    return new MilestoneLibrary(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'MilestoneLibrary',
      plural: 'MilestoneLibraries',
      path: 'MilestoneLibraries',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "Activity_type__c": {
          name: 'Activity_type__c',
          type: 'string'
        },
        "Description__c": {
          name: 'Description__c',
          type: 'string'
        },
        "Duration__c": {
          name: 'Duration__c',
          type: 'number'
        },
        "Duration_Type__c": {
          name: 'Duration_Type__c',
          type: 'string'
        },
        "Form_Name__c": {
          name: 'Form_Name__c',
          type: 'string'
        },
        "Form_Name_Selected__c": {
          name: 'Form_Name_Selected__c',
          type: 'string'
        },
        "Iron_Attachments__c": {
          name: 'Iron_Attachments__c',
          type: 'string'
        },
        "Library_Type_Selected__c": {
          name: 'Library_Type_Selected__c',
          type: 'string'
        },
        "Milestone_Name__c": {
          name: 'Milestone_Name__c',
          type: 'string'
        },
        "PgMO_Departments__c": {
          name: 'PgMO_Departments__c',
          type: 'string'
        },
        "Sequence_Number__c": {
          name: 'Sequence_Number__c',
          type: 'number'
        },
        "Short_URL__c": {
          name: 'Short_URL__c',
          type: 'string'
        },
        "Temporary_Sequence_Number__c": {
          name: 'Temporary_Sequence_Number__c',
          type: 'number'
        },
        "Type__c": {
          name: 'Type__c',
          type: 'string'
        },
        "createdOnServiceo": {
          name: 'createdOnServiceo',
          type: 'boolean'
        },
        "isDeleted": {
          name: 'isDeleted',
          type: 'boolean'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        department: {
          name: 'department',
          type: 'Department',
          model: 'Department',
          relationType: 'belongsTo',
                  keyFrom: 'PgMO_Departments__c',
          keyTo: 'sfdcId'
        },
        attachments: {
          name: 'attachments',
          type: 'IronAttachment[]',
          model: 'IronAttachment',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'Iron_Attachments__c'
        },
        assignments: {
          name: 'assignments',
          type: 'LibraryDetail[]',
          model: 'LibraryDetail',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'PgMO_Milestone_Library__c'
        },
        milestoneTaskLibraryMap: {
          name: 'milestoneTaskLibraryMap',
          type: 'MilestoneTaskLibraryMap[]',
          model: 'MilestoneTaskLibraryMap',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'PgMO_Milestone_Library__c'
        },
      }
    }
  }
}
