/* tslint:disable */

declare var Object: any;
export interface SkillingVerifyInterface {
  "sfdcId"?: string;
  "CreatedById"?: string;
  "CurrencyIsoCode"?: string;
  "Name"?: string;
  "LastModifiedById"?: string;
  "Document_Upload_Required__c"?: boolean;
  "Government_ID__c"?: string;
  "Government_ID_Type__c"?: string;
  "ID_Details__c"?: string;
  "Identity_Expiry_Date__c"?: Date;
  "Identity_Issue_Date__c"?: Date;
  "Identity_Upload_Date__c"?: Date;
  "ID_Status__c"?: string;
  "Iron_Approved__c"?: boolean;
  "Resource_Worker__c"?: string;
  "Skilling__c"?: string;
  "Verification_Details__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class SkillingVerify implements SkillingVerifyInterface {
  "sfdcId": string;
  "CreatedById": string;
  "CurrencyIsoCode": string;
  "Name": string;
  "LastModifiedById": string;
  "Document_Upload_Required__c": boolean;
  "Government_ID__c": string;
  "Government_ID_Type__c": string;
  "ID_Details__c": string;
  "Identity_Expiry_Date__c": Date;
  "Identity_Issue_Date__c": Date;
  "Identity_Upload_Date__c": Date;
  "ID_Status__c": string;
  "Iron_Approved__c": boolean;
  "Resource_Worker__c": string;
  "Skilling__c": string;
  "Verification_Details__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: SkillingVerifyInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `SkillingVerify`.
   */
  public static getModelName() {
    return "SkillingVerify";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of SkillingVerify for dynamic purposes.
  **/
  public static factory(data: SkillingVerifyInterface): SkillingVerify{
    return new SkillingVerify(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'SkillingVerify',
      plural: 'SkillingVerifies',
      path: 'SkillingVerifies',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "Document_Upload_Required__c": {
          name: 'Document_Upload_Required__c',
          type: 'boolean'
        },
        "Government_ID__c": {
          name: 'Government_ID__c',
          type: 'string'
        },
        "Government_ID_Type__c": {
          name: 'Government_ID_Type__c',
          type: 'string'
        },
        "ID_Details__c": {
          name: 'ID_Details__c',
          type: 'string'
        },
        "Identity_Expiry_Date__c": {
          name: 'Identity_Expiry_Date__c',
          type: 'Date'
        },
        "Identity_Issue_Date__c": {
          name: 'Identity_Issue_Date__c',
          type: 'Date'
        },
        "Identity_Upload_Date__c": {
          name: 'Identity_Upload_Date__c',
          type: 'Date'
        },
        "ID_Status__c": {
          name: 'ID_Status__c',
          type: 'string'
        },
        "Iron_Approved__c": {
          name: 'Iron_Approved__c',
          type: 'boolean'
        },
        "Resource_Worker__c": {
          name: 'Resource_Worker__c',
          type: 'string'
        },
        "Skilling__c": {
          name: 'Skilling__c',
          type: 'string'
        },
        "Verification_Details__c": {
          name: 'Verification_Details__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
