import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, EventEmitter, Input, Output, ChangeDetectorRef, Pipe, PipeTransform, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Subscription, Observable } from 'rxjs';
import * as moment from 'moment-timezone';
import * as accessRight from '../job-detail.access';

import {
    JobStatusInternalValues, DispatchServiceResolutionStatuses, VisitIncompleteReasonCodes, AppointmentScheduleCustomStatuses, WorkerAppointmentConfirmation, ReasonForDeviation
} from '../../../../../models/static-list-data.service';
import { PreloaderService } from '../../../../../services/preloader.service';
import { AlertService } from '../../../../../services/alert.service';
import { ModalService } from '../../../../../services/modal.service';
import { CommonService } from './../../../../../services/common.service';
import { AppStateService } from '../../../../../services/app-state.service';

import { JobApi, AppointmentApi, WorkerApi, ApvpWorkersVmsApi  } from '../../../../../sdk';


@Component({
    selector: 'job-detail-manager',
    templateUrl: './job-detail-manager.component.html',
    styleUrls: ['./job-detail-manager.component.css']
})

export class JobDetailManagerComponent implements OnInit, OnDestroy {

    @Input() jobId?: string;
    @Input() timeZone: any;
    @Input() jobObj?: any;
    @Input() projectProfileSfdcId;


    @Output() updateData: EventEmitter<any> = new EventEmitter;
    @Output() uploaded: EventEmitter<any> = new EventEmitter();
    private roleSub: Subscription;
    job: any;
    mode: any;
    errorMessage = '';
    userDetail: any;
    minArrivalDate: any;
    minProgressDate: any;
    minFinishDate: any;
    defaultWorkerArrivalDateTIme: Date;
    defaultCurrentDateTime:Date = new Date();
    min2ndPhoneScheduleDate: any;
    min3rdPhoneScheduleDate: any;
    // JOB DISPATCH: PROGRESS STATUS
    formJobStatus: FormGroup;
    JobStatusInternalValues = JobStatusInternalValues;
    DispatchServiceResolutionStatuses = DispatchServiceResolutionStatuses;
    AppointmentScheduleCustomStatuses = AppointmentScheduleCustomStatuses;
    // JOB DISPATCH: INCOMPLETE
    formJobIncomplete: FormGroup;
    VisitIncompleteReasonCodes = VisitIncompleteReasonCodes;
    workerAppointmentConfirmation = WorkerAppointmentConfirmation;
    // Customer Appointment Status: Schedule Setup (Call Customer Now)
    formAppointmentStatus: FormGroup;
    // Customer Appointment Schedule (Call Customer Now)
    formAppointmentCustomer: FormGroup;
    // Worker Appointment Setup
    formAppointmentWorker: FormGroup;
    workersTypeahead = new EventEmitter<string>();
    workers: any;
    // Visit Status
    formVisitStatus: FormGroup;
    // Add Worker modal
    formAssignWorker: FormGroup;
    formAddWorker: FormGroup;
    error: string;
    contactRegex = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
    emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    // Disabling Add new worker
    enableAddWorker: any = false;
    isSelected: boolean;
    userRole: any;
    isPublic = false;
    Dispatch_Worker_Phone = '';
    Work_Phone_Number__c = '';
    Dispatch_Worker_Name_Text = '';
    jobForm: any = {};
    enableAppointmentSetup = false;
    PPE_Hours = 0;
    jobLoaded: Boolean;
    showOtherChangeReason:boolean;
    Customer_Appointment_DateTime_Updated:any;
    // workerSubscription: Subscription;

    activeTabs = ['ManagerJobStatusPanel', 'ManagerAppointmentPanel', 'ManagerJobIncompletePanel', 'SendManagerMessagePanel'];
    reasonForDifferentCustReq: boolean = false;

    private _statusCss = {
        'Same As Customer Requested': 'resolved',
        'Different from Customer Request': 'cancelled',
        'Pending': 'in-progress',
    };
    accessType: any = '';
    ReasonForDeviation = ReasonForDeviation;
    viewAsVendor: any;
    viewAsVendorSfdcId =''
    Worker_Arrival_DateTime_Cust_Requested__c: any;
    Worker_Arrival_Date_Customer_Req_End__c: any;

    constructor(
        private route: ActivatedRoute,
        private _cd: ChangeDetectorRef,
        private fb: FormBuilder,
        private _preloaderService: PreloaderService,
        private _alertService: AlertService,
        private _appState: AppStateService,
        private _modalService: ModalService,
        private _jobApi: JobApi,
        private _workerApi: WorkerApi,
        private _apvpWorkersVmsApi: ApvpWorkersVmsApi,
        private _appointmentApi: AppointmentApi,
        private _commonService: CommonService
    ) {
        if (this.route.snapshot.data['accessType'] && this.route.snapshot.data['accessType'] === 'public') {
            this.isPublic = true;
        }
    }

    ngOnInit() {
        const appData = JSON.parse(localStorage.getItem('appData'));
        this.accessType = (appData.user && appData.user.accessType) ? appData.user.accessType : '';
        this._commonService.saveCurrentPageAnalytics('Job Manager Console - Details', 'Manager');
        // this.initialize();
    }

    ngOnChanges() {
        this._appState.getViewAsVendorObject().subscribe((res: any) => {
            if(res && res['accessType']) {
                this.viewAsVendor = (res['accessType'] === 'vendor') ? true : false;
                this.viewAsVendorSfdcId = res['userSfdcId'];
                this.initialize();
            } else {
              this.viewAsVendor = false;
               this.initialize();
            }
        })
    }

    ngOnDestroy() {
        if (this.roleSub) {
            this.roleSub.unsubscribe();
        }
    }

    initialize() {
        this.roleSub = this._appState.getJobDetailUserRole().subscribe(role => {
            if (role && role['userRole']) {
                this.userRole = role['userRole'];
                this.mode = accessRight.access[this.userRole].manager;
            }

            if (this.jobId) {
                this.jobLoaded = false;
                this.loadData(this.jobId);
            } else if (this.jobObj) {
                this.prepareJobResult(JSON.parse(this.jobObj));

            } else {
                this.errorMessage = 'No details to display.';
            }
        })

    }



    loadData(jobId) {
        this._preloaderService.showPreloader();
        this.errorMessage = '';
        let reqObj = {
            'where': { 'sfdcId': jobId },
            'fields': ['sfdcId', 'id', 'Job_Status_Internal__c', 'Dispatch_Service_Resolution_Status__c', 'RecordTypeId',
                'Customer_Appointment_Setup_Required__c', 'Service_Parts_Local_Pickup_Required__c', 'Case__c',
                'Appointment_Schedule_Status_Customer__c', 'Appointment_Schedule_Status_Customer_vms__c',
                'Phone_Scheduling_1st_Attempt_Unreachable__c', 'Phone_Scheduling_2nd_Attempt_Unreachable__c',
                'Phone_Scheduling_3rd_Attempt_Unreachable__c', 'Appointment_Call_Notes__c', 'Customer_Appointment_DateTime_Scheduled__c',
                'Customer_Appointment_Start_Scheduled__c', 'Worker_Arrival_DateTime_Scheduled__c', 'Worker_End_DateTime_Scheduled__c',
                'Dispatch_Worker_Name__c', 'Dispatch_Worker_Phone__c', 'Special_Instruction_from_PMS_Case_Auto__c', 'Project_SOP__c',
                'CKSW_BASE__Incomplete_reason__c', 'CKSW_BASE__Other_Incomplete_Reason__c', 'Vendorsite__c', 'Vendor__c',
                'Dispatch_Worker_Name_Text__c', 'Appointment__c', 'workflowStatusId', 'Is_Final_Timecard_Submitted'],
            'include': [
                {
                    'relation': 'appointment',
                    'scope': {
                        'fields': ['id', 'sfdcId', 'Customer_Appointment_Setup_Required__c', 'Worker_Arrival_DateTime_Cust_Requested__c',
                            'Worker_Arrival_Date_Customer_Req_End__c', 'Customer_Appointment_DateTime_Scheduled__c',
                            'Customer_Appointment_Start_Scheduled__c', 'Worker_Arrival_DateTime_Scheduled__c',
                            'Worker_Departure_Date_Time_Actual__c', 'Worker_End_DateTime_Scheduled__c',
                            'Worker_InProgress_Start_DateTime_Actual__c', 'Worker_Finish_Date_Time_Actual__c',
                            'Worker_Arrival_Date_Time_Actual__c', 'Earliest_Start_Date_Permitted__c', 'Early_Start__c', ' ICC_Case__c', 'Customer_Appointment_Change_Reason_Codes__c', 'Same_as_Customer_Requested__c', 'Appointment_Status__c', 'Worker_Apptmnt_Customer_Approval_Status__c','Reason_Customer__c']
                    }
                },
                {
                    'relation': 'case',
                    'scope': {
                        'fields': ['id', 'sfdcId', 'PPE_Hours__c']
                    }
                },
                {
                    'relation': 'worker',
                    'scope': {
                        'fields': ['id', 'sfdcId', 'Name', 'Dispatch_Worker_num__c', 'Work_Phone_Number__c', 'Vendorsite__c']
                    }
                },
                {
                    'relation': 'program',
                    'scope': {
                        'fields': ['sfdcId', 'Program_Requires_Registered_Workers__c']
                    }
                }
            ]
        };
        if(this.viewAsVendor && this.viewAsVendorSfdcId){
            reqObj['viewAsVendor'] = true;
            reqObj['viewAsVendorSfdcId'] = this.viewAsVendorSfdcId;
        }
        this._jobApi.getJobDetailsById(reqObj).subscribe(
            result => {

                this.prepareJobResult(result);
                if (!result.appointment && result.Appointment__c) {
                    this.getAppointmentData(result.Appointment__c);
                }
                this._preloaderService.hidePreloader();
            },
            error => {
                this.errorMessage = error.message;
                this._preloaderService.hidePreloader();
            }
        );
    }
    getAppointmentData(res) {
        this._appointmentApi.find({
            'where': { 'sfdcId': res },
            'fields': ['id', 'sfdcId', 'Customer_Appointment_Setup_Required__c', 'Worker_Arrival_DateTime_Cust_Requested__c',
                'Worker_Arrival_Date_Customer_Req_End__c', 'Customer_Appointment_DateTime_Scheduled__c',
                'Customer_Appointment_Start_Scheduled__c', 'Worker_Arrival_DateTime_Scheduled__c',
                'Worker_Departure_Date_Time_Actual__c', 'Worker_End_DateTime_Scheduled__c',
                'Worker_InProgress_Start_DateTime_Actual__c', 'Worker_Finish_Date_Time_Actual__c',
                'Worker_Arrival_Date_Time_Actual__c', 'Earliest_Start_Date_Permitted__c', 'Early_Start__c', 'Customer_Appointment_Change_Reason_Codes__c', 'Same_as_Customer_Requested__c', 'Appointment_Status__c', 'Worker_Apptmnt_Customer_Approval_Status__c','Reason_Customer__c']
        }).subscribe((result) => {
            this.prepareJobResult(result);
        },
            error => {
                this.errorMessage = error.message;
            })
    }


    prepareJobResult(result) {
        if (result && Object.keys(result).length > 0) {
            this.job = result;
            if (this.job && this.job.appointment && this.job.appointment.Customer_Appointment_Setup_Required__c) {
                this.enableAppointmentSetup = this.job.appointment.Customer_Appointment_Setup_Required__c === 1 ? true : false;
            }
            this.PPE_Hours = (this.job && this.job.case && this.job.case.PPE_Hours__c) ? this.job.case.PPE_Hours__c : 0;
            if (this.job.appointment) {
                const timeFormat = 'L LT';
                this.Worker_Arrival_DateTime_Cust_Requested__c = this.job.appointment.Worker_Arrival_DateTime_Cust_Requested__c ? this.job.appointment.Worker_Arrival_DateTime_Cust_Requested__c : '';
                this.Worker_Arrival_Date_Customer_Req_End__c = new Date(this._commonService.addTime(this.job.appointment.Worker_Arrival_DateTime_Cust_Requested__c, this.PPE_Hours, 'h')).toISOString();
                this.job.appointment.Worker_Arrival_Date_Customer_Req_End__c =
                    this.job.appointment.Worker_Arrival_DateTime_Cust_Requested__c ?
                        this._commonService.convertTimeZone(
                            this._commonService.addTime(
                                this.job.appointment.Worker_Arrival_DateTime_Cust_Requested__c, this.PPE_Hours, 'h'),
                            this.timeZone, timeFormat) : '';

                this.job.appointment.Worker_Arrival_DateTime_Cust_Requested__c =
                    this.job.appointment.Worker_Arrival_DateTime_Cust_Requested__c ?
                        this._commonService.convertTimeZone(
                            this.job.appointment.Worker_Arrival_DateTime_Cust_Requested__c, this.timeZone, timeFormat) : '';

                this.Customer_Appointment_DateTime_Updated= this._commonService.dateFormate(this.job.appointment.Worker_Arrival_DateTime_Scheduled__c, this.timeZone,timeFormat);            
            }
            //  ;
            this.buildForm();
            this.searchWorkerNames();
        } else {
            this.jobLoaded = true;
            this.errorMessage = 'No details to display.';
        }
        this._preloaderService.hidePreloader();
    }

    buildForm() {

        // JOB DISPATCH: INCOMPLETE
        this.formJobIncomplete = this.fb.group({
            // tslint:disable-next-line:max-line-length
            CKSW_BASE__Incomplete_reason__c: [{ value: this.job.CKSW_BASE__Incomplete_reason__c, disabled: !this.mode.job.CKSW_BASE__Incomplete_reason__c.write }],
            CKSW_BASE__Other_Incomplete_Reason__c: [{ value: this.job.CKSW_BASE__Other_Incomplete_Reason__c, disabled: !this.mode.job.CKSW_BASE__Other_Incomplete_Reason__c.write }],
        });

        // Customer Appointment Status: Schedule Setup (Call Customer Now)
        this.formAppointmentStatus = new FormGroup({
            Appointment_Schedule_Status_Customer__c: new FormControl({
                value: this.job.Appointment_Schedule_Status_Customer__c,
                disabled: !this.mode.job.Appointment_Schedule_Status_Customer__c.write
            }),
            Appointment_Schedule_Status_Customer_vms__c: new FormControl({
                value: this.job.Appointment_Schedule_Status_Customer_vms__c,
                disabled: !this.mode.job.Appointment_Schedule_Status_Customer_vms__c.write
            }),
            Phone_Scheduling_1st_Attempt_Unreachable__c: new FormControl({
                value: this.job.Phone_Scheduling_1st_Attempt_Unreachable__c,
                disabled: !this.mode.job.Phone_Scheduling_1st_Attempt_Unreachable__c.write
            }),
            Phone_Scheduling_2nd_Attempt_Unreachable__c: new FormControl({
                value: this.job.Phone_Scheduling_2nd_Attempt_Unreachable__c,
                disabled: !this.mode.job.Phone_Scheduling_2nd_Attempt_Unreachable__c.write
            }),
            Phone_Scheduling_3rd_Attempt_Unreachable__c: new FormControl({
                value: this.job.Phone_Scheduling_3rd_Attempt_Unreachable__c,
                disabled: !this.mode.job.Phone_Scheduling_3rd_Attempt_Unreachable__c.write
            }),

            Appointment_Call_Notes__c: new FormControl({
                value: this.job.Appointment_Call_Notes__c,
                disabled: !this.mode.job.Appointment_Call_Notes__c.write
            }),
        });

        this.jobForm.Phone_Scheduling_1st_Attempt_Unreachable__c =
            this._commonService.applyNewTimeZone(this.job.Phone_Scheduling_1st_Attempt_Unreachable__c, this.timeZone);
        this.jobForm.Phone_Scheduling_2nd_Attempt_Unreachable__c =
            this._commonService.applyNewTimeZone(this.job.Phone_Scheduling_2nd_Attempt_Unreachable__c, this.timeZone);
        this.jobForm.Phone_Scheduling_3rd_Attempt_Unreachable__c =
            this._commonService.applyNewTimeZone(this.job.Phone_Scheduling_3rd_Attempt_Unreachable__c, this.timeZone);
        this.jobForm.Appointment_Schedule_Status_Customer_vms__c =
            this._commonService.applyNewTimeZone(this.job.Appointment_Schedule_Status_Customer_vms__c, this.timeZone);

        this.min2ndPhoneScheduleDate = this.jobForm.Phone_Scheduling_1st_Attempt_Unreachable__c
        this.min3rdPhoneScheduleDate = this.jobForm.Phone_Scheduling_2nd_Attempt_Unreachable__c

            // Customer Appointment Schedule (Call Customer Now)
            ;
        const Customer_Appointment_DateTime_Scheduled =
            this.job.appointment && this.job.appointment.Customer_Appointment_DateTime_Scheduled__c ?
                this.job.appointment.Customer_Appointment_DateTime_Scheduled__c : null;

        const Customer_Appointment_Start_Scheduled = this.job.appointment &&
            this.job.appointment.Customer_Appointment_DateTime_Scheduled__c ?
            this._commonService.addTime(Customer_Appointment_DateTime_Scheduled, this.PPE_Hours, 'h') : null;

        this.formAppointmentCustomer = this.fb.group({
            Customer_Appointment_DateTime_Scheduled__c: new FormControl({
                value: Customer_Appointment_DateTime_Scheduled,
                disabled: !this.mode.appointment.Customer_Appointment_DateTime_Scheduled__c.write
            }),
            Customer_Appointment_Start_Scheduled__c: new FormControl({
                value: Customer_Appointment_Start_Scheduled,
                disabled: !this.mode.appointment.Customer_Appointment_Start_Scheduled__c.write
            })
        });
        ;
        this.jobForm.Customer_Appointment_DateTime_Scheduled__c =
            this._commonService.applyNewTimeZone(Customer_Appointment_DateTime_Scheduled, this.timeZone);
        this.jobForm.Customer_Appointment_Start_Scheduled__c =
            this._commonService.applyNewTimeZone(Customer_Appointment_Start_Scheduled, this.timeZone);

        // Worker Appointment Setup
        const Worker_Arrival_DateTime_Scheduled = this.job.appointment && this.job.appointment.Worker_Arrival_DateTime_Scheduled__c ?
            this.job.appointment.Worker_Arrival_DateTime_Scheduled__c : null;
        const Reason_for_different_from_customer_reqst = this.job.appointment && this.job.appointment.Customer_Appointment_Change_Reason_Codes__c ?
            this.job.appointment.Customer_Appointment_Change_Reason_Codes__c : '';
        const Same_as_Customer_Requested__c = this.job.appointment && (this.job.appointment.Same_as_Customer_Requested__c !== null && this.job.appointment.Same_as_Customer_Requested__c !== '') ?
            this.job.appointment.Same_as_Customer_Requested__c : true;
        const Appointment_Status__c = this.job.appointment && this.job.appointment.Appointment_Status__c ?
            this.job.appointment.Appointment_Status__c : 'Pending';
        const appointMentApproval = this.job.appointment && this.job.appointment.Worker_Apptmnt_Customer_Approval_Status__c ?
            this.job.appointment.Worker_Apptmnt_Customer_Approval_Status__c : 'Pending';
        const Reason_Customer_reqst = this.job.appointment && this.job.appointment.Reason_Customer__c ?
            this.job.appointment.Reason_Customer__c : '';    

        const Worker_End_DateTime_Scheduled = this.job.appointment && this.job.appointment.Worker_Arrival_DateTime_Scheduled__c ?
            this._commonService.addTime(this.job.appointment.Worker_Arrival_DateTime_Scheduled__c, this.PPE_Hours, 'h') : null;

        this.formAppointmentWorker = this.fb.group({
            Worker_Arrival_DateTime_Scheduled__c: new FormControl({
                value: Worker_Arrival_DateTime_Scheduled, disabled: !this.mode.appointment.Worker_Arrival_DateTime_Scheduled__c.write
            }),
            Worker_End_DateTime_Scheduled__c: new FormControl({
                value: Worker_End_DateTime_Scheduled, disabled: !this.mode.appointment.Worker_End_DateTime_Scheduled__c.write
            }),
            // Reason_for_different_from_customer_reqst__c: [{
            //     value: Reason_for_different_from_customer_reqst
            // }],
            Customer_Appointment_Change_Reason_Codes__c: new FormControl({
                value: Reason_for_different_from_customer_reqst, disabled: false
            }),
            Reason_Customer__c : new FormControl({
                value: Reason_Customer_reqst , disabled:false
            }),
            Same_as_Customer_Requested__c: new FormControl({
                value: Same_as_Customer_Requested__c, disabled: false
            }),
            Appointment_Status__c: new FormControl({
                value: Appointment_Status__c, disabled: false
            }),
            Worker_Apptmnt_Customer_Approval_Status__c: new FormControl({
                value: appointMentApproval, disabled: false
            }),
            Dispatch_Worker_Name__c: [{
                value: (this.job.Dispatch_Worker_Name__c) ? this.job.Dispatch_Worker_Name__c : '--none--',
                disabled: !this.mode.job.Dispatch_Worker_Name__c.write
            }, [Validators.required]],
            // Dispatch_Worker_Phone__c: new FormControl({
            //     value: null,
            //     disabled: true
            // })
        });
        // this.Work_Phone_Number__c = this.job.Dispatch_Worker_Name__c && this.job.Dispatch_Worker_Phone__c ? this.job.Dispatch_Worker_Phone__c : '';
        this.Work_Phone_Number__c = this.job.worker && this.job.worker.Work_Phone_Number__c ? this.job.worker.Work_Phone_Number__c : '';

        this.jobForm.Worker_Arrival_DateTime_Scheduled__c =
            this._commonService.applyNewTimeZone(Worker_Arrival_DateTime_Scheduled, this.timeZone);
        this.jobForm.Customer_Appointment_Change_Reason_Codes__c = Reason_for_different_from_customer_reqst;
        this.jobForm.Reason_Customer__c= Reason_Customer_reqst;
        if (this.jobForm.Customer_Appointment_Change_Reason_Codes__c ==='Other'){
            this.showOtherChangeReason=true;
        } else {
            this.showOtherChangeReason=false;
        }  
        this.jobForm.Worker_End_DateTime_Scheduled__c =
            this._commonService.applyNewTimeZone(Worker_End_DateTime_Scheduled, this.timeZone);

        // Form Customer Appointment Schedule
        if (this.job && this.job.appointment && this.job.appointment.Earliest_Start_Date_Permitted__c) {
            const Early_Start = this.job.appointment.Early_Start__c ? this.job.appointment.Early_Start__c : null;
            this.jobForm.Early_Start__c = this._commonService.applyNewTimeZone(Early_Start, this.timeZone);
        }

        // Visit Status
        const Worker_Departure_Date_Time_Actual =
            this.job.appointment && this.job.appointment.Worker_Departure_Date_Time_Actual__c ?
                this.job.appointment.Worker_Departure_Date_Time_Actual__c : null;
        const Worker_Arrival_Date_Time_Actual =
            this.job.appointment && this.job.appointment.Worker_Arrival_Date_Time_Actual__c ?
                this.job.appointment.Worker_Arrival_Date_Time_Actual__c : null;
        const Worker_InProgress_Start_DateTime_Actual =
            this.job.appointment && this.job.appointment.Worker_InProgress_Start_DateTime_Actual__c ?
                this.job.appointment.Worker_InProgress_Start_DateTime_Actual__c : null;
        const Worker_Finish_Date_Time_Actual =
            this.job.appointment && this.job.appointment.Worker_Finish_Date_Time_Actual__c ?
                this.job.appointment.Worker_Finish_Date_Time_Actual__c : null;

        this.formVisitStatus = this.fb.group({
            Worker_Departure_Date_Time_Actual__c: new FormControl({
                value: Worker_Departure_Date_Time_Actual, disabled: !this.mode.appointment.Worker_Departure_Date_Time_Actual__c.write
            }),
            Worker_Arrival_Date_Time_Actual__c: new FormControl({
                value: Worker_Arrival_Date_Time_Actual, disabled: !this.mode.appointment.Worker_Arrival_Date_Time_Actual__c.write
            }),
            Worker_InProgress_Start_DateTime_Actual__c: new FormControl({
                value: Worker_InProgress_Start_DateTime_Actual,
                disabled: !this.mode.appointment.Worker_InProgress_Start_DateTime_Actual__c.write
            }),
            Worker_Finish_Date_Time_Actual__c: new FormControl({
                value: Worker_Finish_Date_Time_Actual, disabled: !this.mode.appointment.Worker_Finish_Date_Time_Actual__c.write
            }),
        });
        this.jobForm.Worker_Departure_Date_Time_Actual__c =
            this._commonService.applyNewTimeZone(Worker_Departure_Date_Time_Actual, this.timeZone);
        this.jobForm.Worker_Arrival_Date_Time_Actual__c =
            this._commonService.applyNewTimeZone(Worker_Arrival_Date_Time_Actual, this.timeZone);
        this.jobForm.Worker_InProgress_Start_DateTime_Actual__c =
            this._commonService.applyNewTimeZone(Worker_InProgress_Start_DateTime_Actual, this.timeZone);
        this.jobForm.Worker_Finish_Date_Time_Actual__c =
            this._commonService.applyNewTimeZone(Worker_Finish_Date_Time_Actual, this.timeZone);

        this.minArrivalDate = this.jobForm.Worker_Departure_Date_Time_Actual__c;
        this.minProgressDate = this.jobForm.Worker_Arrival_Date_Time_Actual__c;
        this.minFinishDate = this.jobForm.Worker_Finish_Date_Time_Actual__c;
        this.defaultWorkerArrivalDateTIme = (this.job && this.job.appointment && this.job.appointment.Worker_Arrival_DateTime_Cust_Requested__c) ? new Date(this.job.appointment.Worker_Arrival_DateTime_Cust_Requested__c) : new Date();

        // Worker Name Form
        if (this.job && this.job.Dispatch_Worker_Name__c && this.job.Vendorsite__c) {
            if (this.job.worker && this.job.worker.Vendorsite__c &&
                this.job.worker.Vendorsite__c === this.job.Vendorsite__c) {
                this.isSelected = true;
                this.formAppointmentWorker.controls['Dispatch_Worker_Name__c'].setValue(this.job.Dispatch_Worker_Name__c);
                // this.formAppointmentWorker.controls['Dispatch_Worker_Phone__c']
                //     .setValue(this.getWorkerPhoneNumber(this.job.Dispatch_Worker_Name__c));
                this.Work_Phone_Number__c = this.job.worker.Work_Phone_Number__c;
                this.Dispatch_Worker_Name_Text = this.job.Dispatch_Worker_Name_Text___c;
            }
        }

        // Add Worker modal
        // tslint:disable-next-line:max-line-length
        this.formAssignWorker = this.fb.group({
            Dispatch_Worker_Name__c: [{ value: this.job.Dispatch_Worker_Name__c, disabled: !this.mode.job.Dispatch_Worker_Name__c.write }],
            // Dispatch_Worker_Phone__c: [{
            //     value: this.getWorkerPhoneNumber(this.job.Dispatch_Worker_Name__c),
            //     disabled: true
            // }]
        });
        this.formAddWorker = this.fb.group({
            firstname: ['', [Validators.required]],
            lastname: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.pattern(this.emailRegex)]],
            Work_Phone_Number__c: [{ value: '', disabled: !this.mode.job.Dispatch_Worker_Phone.write },
            [Validators.required, Validators.pattern(this.contactRegex)]],
            Vendorsite__c: [this.job.Vendorsite__c],
            accessType: 'vendor', /// will be replace when confirm
            Available__c: 1
        });
        // this.jobLoaded = true;
    }

    searchWorkerNames() {
        this.workers = [];
        if(this.projectProfileSfdcId && this.projectProfileSfdcId.length){ 
            this._apvpWorkersVmsApi.getAllApvpWorkersList({
                where: { Project_Routing_Profile__c: this.projectProfileSfdcId, 
                    Vendor_Account_Id: this.job.Vendor__c,
                    Requires_Registered_Workers__c: this.job.program && this.job.program.Program_Requires_Registered_Workers__c ? 
                    this.job.program.Program_Requires_Registered_Workers__c: false
                 },
            }).subscribe(
                res => {
                    this.jobLoaded = true;
                    let workerList = [];
                    for (const [key, value] of Object.entries(res)) {
                        let w = {
                            Contact__c: value['workerContactId'],
                            Email__c: value['workerEmail'],
                            Name: value['ServiceoUserId'] && value['ServiceoUserIsActive'] ?  value['workerName'] :  value['workerName'] + '(Not Serviceo User)',
                            Work_Phone_Number__c: value['workerPhone'],
                            is_Facebook__c: value['workerIsFacebook'],
                            sfdcId: value['workerSfdcId']
                        }
                        workerList.push(w)
                      }
                    this.workers = workerList;
                },
                err => {
                    this.jobLoaded = true;
                    console.log(err);
                }
            );
        } else {
            this.jobLoaded = true;
        }
    }
    // searchWorkerNames() {
    //     this.workers = [];
    //     if(this.projectProfileSfdcId && this.projectProfileSfdcId.length){ 
    //         this._apvpWorkersVmsApi.find({
    //             fields: ['APVP_Accounts_VMS__c', 'Project_Routing_Profile__c', 'Worker__c', 'Project_Worker_Rating__c', 'Worker_Registration_Status__c'],
    //             where: { Project_Routing_Profile__c: this.projectProfileSfdcId,  Onboarding_Status__c: 'Completed', Status_Worker__c: 'Approved' },
    //             include: [
    //                 {
    //                 relation: 'worker',
    //                 scope: {
    //                     fields: ['Name', 'id', 'sfdcId', 'Work_Phone_Number__c', 'Contact__c', 'is_Facebook__c', 'Email__c'],
    //                     where: {'Blacklisted__c': false},
    //                     include: [{
    //                         relation: 'user',
    //                         scope: {
    //                             'fields': ['id', 'sfdcId', 'isActive']
    //                         }
    //                     }]
    //                 }
    //                 }
    //             ],
    //         }).subscribe(
    //             res => {
    //                 this.jobLoaded = true;
    //                 let workerList = []
    //                 res.forEach(e => {
    //                     if(e && e['worker']){
    //                         let w = {
    //                             Contact__c: e['worker']['Contact__c'],
    //                             Email__c: e['worker']['Email__c'],
    //                             Name: e['worker']['user'] && e['worker']['user']['isActive'] ?  e['worker']['Name'] :  e['worker']['Name'] + '(Not Serviceo User)',
    //                             Work_Phone_Number__c: e['worker']['Work_Phone_Number__c'],
    //                             is_Facebook__c: e['worker']['is_Facebook__c'],
    //                             sfdcId: e['worker']['sfdcId']
    //                         }
    //                         workerList.push(w)
    //                     }
    //                 })
    //                 this.workers = workerList;
    //             },
    //             err => {
    //                 this.jobLoaded = true;
    //                 console.log(err);
    //             }
    //         );
    //     } else {
    //         this.jobLoaded = true;
    //     }
    // }


    updateJob(action: string, phoneField) {
        let values;
        let setWorkflowStatusId = 0;
        const message = 'Job has been updated successfully';
        if (action === 'job-incomplete') {
            values = this.formJobIncomplete.value;
            for (const key in this.mode.job) {
                if (this.mode.job[key]['write'] === false && values[key]) {
                    delete values[key]
                }
            }
        } else if (action === 'appointment-status') {
            values = this.formAppointmentStatus.value;
            for (const key in this.mode.job) {
                if (this.mode.job[key]['write'] === false && values[key]) {
                    delete values[key]
                }
            }
        } else if (action === 'appointment-worker') {
            if (!this.Work_Phone_Number__c || !phoneField.valid) {
                return;
            }

            /* check for worker name selection should be valid */
            if (this.formAppointmentWorker.get('Dispatch_Worker_Name__c').value === "--none--") {
                return;
            }

            // set variable #values
            if (this.formAppointmentWorker.valid && this.formAppointmentWorker.value &&
                this.formAppointmentWorker.value.Dispatch_Worker_Name__c &&
                this.Work_Phone_Number__c && phoneField.valid) {
                // clone
                values = JSON.parse(JSON.stringify(this.formAppointmentWorker.value));
                values['Dispatch_Worker_Phone__c'] = this.Work_Phone_Number__c;
                values['Dispatch_Worker_Name_Text__c'] = this.Dispatch_Worker_Name_Text;
            }

            // updateAppointment only call, when we have the respective value changes
            if (this.formAppointmentWorker.valid && this.formAppointmentWorker.value &&
                this.formAppointmentWorker.value.Worker_Arrival_DateTime_Scheduled__c &&
                this.formAppointmentWorker.value.Worker_End_DateTime_Scheduled__c) {
                if (this.getFormValue('Worker_Apptmnt_Customer_Approval_Status__c') === 'Rejected' && this.job && this.job.workflowStatusId > 12) {
                    setWorkflowStatusId = 12;
                }
                this.updateAppointment(action);
            }
            values['Worker_Arrival_DateTime_Cust_Requested__c'] = this.Worker_Arrival_DateTime_Cust_Requested__c;
            values['Worker_Arrival_Date_Customer_Req_End__c'] = this.Worker_Arrival_Date_Customer_Req_End__c;
            values['jobId'] = this.jobId;

            delete values['Worker_Arrival_DateTime_Scheduled__c'];
            delete values['Worker_End_DateTime_Scheduled__c']

            for (const key in this.mode.appointment) {
                if (this.mode.appointment[key]['write'] === false && values[key]) {
                    delete values[key]
                }
            }

        }


        // update job details
        if (values) {
            this._preloaderService.showPreloader();
            const apiMethodToSubscribe$ = action === 'appointment-worker' ? this._jobApi.assignWorker({ values, id: this.job.id, workflowStatusId: setWorkflowStatusId ? setWorkflowStatusId : '' }) :
                this._jobApi.patchAttributes(this.job.id, values);
            apiMethodToSubscribe$.subscribe(
                result => {                    
                    this._preloaderService.hidePreloader();
                    if(result.error){
                        this._alertService.error(result.error.message);
                    } else {
                        this._alertService.success(message);
                        this.updateData.emit('sidebar');
                    }  
                    window.scrollTo(0, 0);
                },
                err => {
                    this._preloaderService.hidePreloader();
                    this._alertService.error(err.message);
                    window.scrollTo(0, 0);
                }
            );
        }
    }

    updateAppointment(action) {
        let values = null;
        let message = 'Appointment has been updated successfully';
        if (action === 'appointment-customer') {
            values = this.formAppointmentCustomer.value;
        } else if (action === 'appointment-worker') {
            values = this.formAppointmentWorker.value;
            // delete Dispatch_Worker_Phone__c and Dispatch_Worker_Name_Text__c
            delete values['Work_Phone_Number__c'];
            delete values['Dispatch_Worker_Name_Text__c'];
        } else if (action === 'visit-status') {
            values = this.formVisitStatus.value;
        }
        for (const key in this.mode.appointment) {
            if (this.mode.appointment[key]['write'] === false && values[key]) {
                delete values[key]
            }
        }
        // update appointment details
        if (values && this.job.appointment) {
            this._preloaderService.showPreloader();
            this._appointmentApi.patchAttributes(this.job.appointment.id, values).subscribe(
                result => {
                    this._preloaderService.hidePreloader();
                    this._alertService.success(message);
                    this.updateData.emit('sidebar');
                    window.scrollTo(0, 0);
                },
                err => {
                    this._preloaderService.hidePreloader();
                    this._alertService.error(err.message);
                    window.scrollTo(0, 0);
                }
            );
        } else {
            message = 'Failed to update appointment.';
            this._alertService.error(message);
            window.scrollTo(0, 0);
        }
    }

    addTime(val: any, duration: any, unit: string) {
        const m = moment(val);
        const newDate = m.add(duration, unit);
        return newDate;
    }

    open(action, content, size) {
        if (action === 'Add Worker') {
            this.error = '';
            this._modalService.open(content, size);
        }
    }

    assignWorker() {
        this.error = '';
        const newWorker = this.formAssignWorker.controls['Dispatch_Worker_Name__c'].value;
        if (newWorker) {
            // this.formAppointmentWorker.controls['Dispatch_Worker_Name__c'].setValue(newWorker);
            this.Work_Phone_Number__c = newWorker;
            this._modalService.closed();
        } else {
            this.error = 'Please select worker.'
        }
    }

    addWorker() {
        this.error = '';
        this._workerApi.createWorker(this.formAddWorker.value).subscribe(
            result => {
                if (result.Worker.id) {
                    this._modalService.closed();
                    this._alertService.success('Worker has been added successfully.');
                    window.scrollTo(0, 0);
                    this.workers.push({
                        id: result['Worker']['id'],
                        Name: result['Worker']['Name']
                    });
                    this.formAddWorker.reset();
                } else {
                    this.error = 'Worker added successfully, please expect the latency time of 15 mins.'
                    // this.error = result.Worker.data.error.message;
                }
            },
            err => {
                this.error = err.message;
            }
        );
    }

    getPhone(data, workerForm) {
        let selectedWorker = '';
        workerForm['submitted'] = false;
        if (data) {
            const worker = data.target.value;
            this.workers.filter(item => {
                if (item.sfdcId === worker) {
                    selectedWorker = item;
                }
            })
            this.Work_Phone_Number__c = selectedWorker['Work_Phone_Number__c'];
            // this.formAppointmentWorker.controls['Dispatch_Worker_Phone__c'].setValue(this.getWorkerPhoneNumber(worker));
            this.Dispatch_Worker_Name_Text = selectedWorker['Name'];

            // !selectedWorker['Work_Phone_Number__c'] && data.target.value ? this.formAppointmentWorker.controls['Dispatch_Worker_Phone__c']
            // .enable() : this.formAppointmentWorker.controls['Dispatch_Worker_Phone__c'].disable();
        } else {
            this.isSelected = false;
            // this.formAppointmentWorker.controls['Dispatch_Worker_Phone__c'].setValue(selectedWorker);
            this.Work_Phone_Number__c = selectedWorker;
        }
    }

    // getWorkerPhoneNumber(sfdcId: string) {
    //     const selectedWorker = this.workers.find(i => i.sfdcId === sfdcId);
    //     return selectedWorker && selectedWorker.Work_Phone_Number__c;
    // }

    // clearPhone() {
    //     this.formAppointmentWorker.controls['Dispatch_Worker_Phone__c'].setValue('');
    // }

    onDateChange(e, field, source) {
        const timeFormat = 'L LT';
        const newValue = e.value;
        const newValueStr = newValue.toISOString();
        const tranformedValue = this._commonService.setNewTimeZone(newValue, this.timeZone);
        const convertedValue = this._commonService.dateFormate(newValue, '', timeFormat);
        switch (source) {
            case 'Appointment-Status': {
                this.formAppointmentStatus.markAsDirty();
                if (field === 'Phone_Scheduling_1st_Attempt_Unreachable__c') {
                    this.formAppointmentStatus.value.Phone_Scheduling_1st_Attempt_Unreachable__c = tranformedValue;
                    this.jobForm.Phone_Scheduling_1st_Attempt_Unreachable__c = newValueStr;
                    if ((this.jobForm.Phone_Scheduling_2nd_Attempt_Unreachable__c) &&
                        newValue > new Date(this.jobForm.Phone_Scheduling_2nd_Attempt_Unreachable__c)) {
                        this.jobForm.Phone_Scheduling_2nd_Attempt_Unreachable__c = null;
                        this.formAppointmentStatus.value.Phone_Scheduling_2nd_Attempt_Unreachable__c = null;
                    }
                    if ((this.jobForm.Phone_Scheduling_3rd_Attempt_Unreachable__c) &&
                        newValue > new Date(this.jobForm.Phone_Scheduling_3rd_Attempt_Unreachable__c)) {
                        this.jobForm.Phone_Scheduling_3rd_Attempt_Unreachable__c = null;
                        this.formAppointmentStatus.value.Phone_Scheduling_3rd_Attempt_Unreachable__c = null;
                    }
                    this.min2ndPhoneScheduleDate = newValue;
                    this.min3rdPhoneScheduleDate = (this.jobForm.Phone_Scheduling_2nd_Attempt_Unreachable__c) ?
                        this.jobForm.Phone_Scheduling_2nd_Attempt_Unreachable__c : newValue;
                } else if (field === 'Phone_Scheduling_2nd_Attempt_Unreachable__c') {
                    this.formAppointmentStatus.value.Phone_Scheduling_2nd_Attempt_Unreachable__c = tranformedValue;
                    this.jobForm.Phone_Scheduling_2nd_Attempt_Unreachable__c = newValueStr;
                    if ((this.jobForm.Phone_Scheduling_3rd_Attempt_Unreachable__c) &&
                        newValue > new Date(this.jobForm.Phone_Scheduling_3rd_Attempt_Unreachable__c)) {
                        this.jobForm.Phone_Scheduling_3rd_Attempt_Unreachable__c = null;
                        this.formAppointmentStatus.value.Phone_Scheduling_3rd_Attempt_Unreachable__c = null;
                    }
                    this.min3rdPhoneScheduleDate = newValue;
                } else if (field === 'Phone_Scheduling_3rd_Attempt_Unreachable__c') {
                    this.formAppointmentStatus.value.Phone_Scheduling_3rd_Attempt_Unreachable__c = tranformedValue;
                    this.jobForm.Phone_Scheduling_3rd_Attempt_Unreachable__c = newValueStr;
                } else if( field == 'Appointment_Schedule_Status_Customer_vms__c') {
                    this.formAppointmentStatus.value.Appointment_Schedule_Status_Customer_vms__c = tranformedValue;
                    this.jobForm.Appointment_Schedule_Status_Customer_vms__c = newValueStr;
                }
                break;
            }
            case 'Appointment': {
                this.formAppointmentCustomer.markAsDirty();
                if (field === 'Customer_Appointment_DateTime_Scheduled__c') {
                    this.formAppointmentCustomer.value.Customer_Appointment_DateTime_Scheduled__c = tranformedValue;
                    this.jobForm.Customer_Appointment_DateTime_Scheduled__c = newValueStr;

                    // Adding PPE Hours for getting End Date
                    this.formAppointmentCustomer.value.Customer_Appointment_Start_Scheduled__c =
                        this._commonService.addTime(tranformedValue, this.PPE_Hours, 'h');
                    this.jobForm.Customer_Appointment_Start_Scheduled__c =
                        this._commonService.addTime(newValueStr, this.PPE_Hours, 'h');

                }
                break;
            }
            case 'Worker-Appointment': {
                this.formAppointmentWorker.markAsDirty();
                if (field === 'Worker_Arrival_DateTime_Scheduled__c') {
                    if (this.job && this.job.appointment && this.job.appointment.Worker_Arrival_DateTime_Cust_Requested__c === convertedValue) {
                        this.reasonForDifferentCustReq = false;
                        this.setFormValue(this.formAppointmentWorker, 'Same_as_Customer_Requested__c', true);
                        this.setFormValue(this.formAppointmentWorker, 'Appointment_Status__c', 'Same As Customer Requested');
                        this.disableControl('Customer_Appointment_Change_Reason_Codes__c');
                        this.clearFormFields(['Customer_Appointment_Change_Reason_Codes__c'])
                    } else {
                        this.setFormValue(this.formAppointmentWorker, 'Same_as_Customer_Requested__c', false);
                        // this.setFormValue(this.formAppointmentWorker, 'Appointment_Status__c', 'Different from Customer Request');
                        this.setFormValue(this.formAppointmentWorker, 'Appointment_Status__c', 'Awaiting Customer Approval');
                        this.setFormValue(this.formAppointmentWorker, 'Worker_Apptmnt_Customer_Approval_Status__c', 'Pending');
                        this.reasonForDifferentCustReq = true;
                        this.enableControl('Customer_Appointment_Change_Reason_Codes__c');
                    }
                    this.formAppointmentWorker.controls.Worker_Arrival_DateTime_Scheduled__c.setValue(tranformedValue);
                    this.jobForm.Worker_Arrival_DateTime_Scheduled__c = newValueStr;
                    // Adding PPE Hours for getting End Date
                    this.formAppointmentWorker.controls.Worker_End_DateTime_Scheduled__c.setValue(
                        this._commonService.addTime(tranformedValue, this.PPE_Hours, 'h'));
                    this.jobForm.Worker_End_DateTime_Scheduled__c =
                        this._commonService.addTime(newValueStr, this.PPE_Hours, 'h');
                }
                break;
            }
            case 'Visit-Status': {
                this.formVisitStatus.markAsDirty();
                if (field === 'Worker_Departure_Date_Time_Actual__c') {
                    this.formVisitStatus.value.Worker_Departure_Date_Time_Actual__c = tranformedValue;
                    this.jobForm.Worker_Departure_Date_Time_Actual__c = newValueStr;
                    if ((this.jobForm.Worker_Arrival_Date_Time_Actual__c) &&
                        newValue > new Date(this.jobForm.Worker_Arrival_Date_Time_Actual__c)) {
                        this.jobForm.Worker_Arrival_Date_Time_Actual__c = null;
                        this.formVisitStatus.value.Worker_Arrival_Date_Time_Actual__c = null;
                    }
                    if ((this.jobForm.Worker_InProgress_Start_DateTime_Actual__c) &&
                        newValue > new Date(this.jobForm.Worker_InProgress_Start_DateTime_Actual__c)) {
                        this.jobForm.Worker_InProgress_Start_DateTime_Actual__c = null;
                        this.formVisitStatus.value.Worker_InProgress_Start_DateTime_Actual__c = null;
                    }
                    if ((this.jobForm.Worker_Finish_Date_Time_Actual__c) &&
                        newValue > new Date(this.jobForm.Worker_Finish_Date_Time_Actual__c)) {
                        this.jobForm.Worker_Finish_Date_Time_Actual__c = null;
                        this.formVisitStatus.value.Worker_Finish_Date_Time_Actual__c = null;
                    }
                    this.minArrivalDate = newValue;
                    this.minProgressDate = (this.jobForm.Worker_Departure_Date_Time_Actual__c) ?
                        this.jobForm.Worker_Departure_Date_Time_Actual__c : newValue;
                    this.minFinishDate = (this.jobForm.Worker_InProgress_Start_DateTime_Actual__c) ?
                        this.jobForm.Worker_Departure_Date_Time_Actual__c : newValue;

                } else if (field === 'Worker_Arrival_Date_Time_Actual__c') {
                    this.formVisitStatus.value.Worker_Arrival_Date_Time_Actual__c = tranformedValue;
                    this.jobForm.Worker_Arrival_Date_Time_Actual__c = newValueStr;
                    if (newValue > new Date(this.jobForm.Worker_InProgress_Start_DateTime_Actual__c)) {
                        this.jobForm.Worker_InProgress_Start_DateTime_Actual__c = null;
                        this.formVisitStatus.value.Worker_InProgress_Start_DateTime_Actual__c = null;
                    }
                    if (newValue > new Date(this.jobForm.Worker_Finish_Date_Time_Actual__c)) {
                        this.jobForm.Worker_Finish_Date_Time_Actual__c = null;
                        this.formVisitStatus.value.Worker_Finish_Date_Time_Actual__c = null;
                    }
                    this.minProgressDate = newValue;
                    this.minFinishDate = (this.jobForm.Worker_InProgress_Start_DateTime_Actual__c) ?
                        this.jobForm.Worker_Departure_Date_Time_Actual__c : newValue;
                } else if (field === 'Worker_InProgress_Start_DateTime_Actual__c') {
                    this.formVisitStatus.value.Worker_InProgress_Start_DateTime_Actual__c = tranformedValue;
                    this.jobForm.Worker_InProgress_Start_DateTime_Actual__c = newValueStr;
                    if (newValue > new Date(this.jobForm.Worker_Finish_Date_Time_Actual__c)) {
                        this.jobForm.Worker_Finish_Date_Time_Actual__c = null;
                        this.formVisitStatus.value.Worker_Finish_Date_Time_Actual__c = null;
                    }
                    this.minFinishDate = newValue
                } else if (field === 'Worker_Finish_Date_Time_Actual__c') {
                    this.jobForm.Worker_Finish_Date_Time_Actual__c = newValueStr;
                    this.formVisitStatus.value.Worker_Finish_Date_Time_Actual__c = tranformedValue;
                }
                break;
            }
        }
        this._cd.detectChanges();
    }
    onPhoneNumberUp(e) {
        this.formAppointmentWorker.markAsDirty();
    }

    fileUploaded() {
        this.uploaded.emit();
    }

    onTabBtnClick(tab) {
        let index = this.activeTabs.indexOf(tab);
        if (index !== -1) {
            this.activeTabs.splice(index, 1);
        } else {
            this.activeTabs.push(tab);
        }
    }

    disableControl(field) {
        if (this.formAppointmentWorker && this.formAppointmentWorker.controls && this.formAppointmentWorker.controls[field]) {
            this.formAppointmentWorker.controls[field].clearValidators();
            this.formAppointmentWorker.controls[field].updateValueAndValidity();
            // this.formAppointmentWorker.controls[field].setValue('');
        }
    }

    enableControl(field, validations?) {
        if (this.formAppointmentWorker && this.formAppointmentWorker.controls && this.formAppointmentWorker.controls[field]) {
            const userTypeValidator = Validators.compose([Validators.required]);
            this.formAppointmentWorker.controls[field].setValidators(userTypeValidator);
            this.formAppointmentWorker.controls[field].updateValueAndValidity();
        }
    }

    clearFormFields(fields) {
        fields.forEach(element => {
            this.formAppointmentWorker.controls[element].setValue('');
            if (element === 'Customer_Appointment_Change_Reason_Codes__c') {
                // this.setFormValue(this.jobForm, 'Reason_for_different_from_customer_reqst__c', '')
                this.jobForm.Customer_Appointment_Change_Reason_Codes__c = '';
            }
        });
    }

    setFormValue(form, field, value) {
        form.controls[field].setValue(value);
    }
    setApprovalFormValue(form, field, event) {
        let value = event.target.value;
        this.formAppointmentWorker.markAsDirty();
        if (value && value === 'Approved') {
            this.setFormValue(this.formAppointmentWorker, 'Appointment_Status__c', 'Different from Customer Request');
        } else if (value && value === 'Rejected') {
            this.setFormValue(this.formAppointmentWorker, 'Appointment_Status__c', 'Pending');
        }
        this.formAppointmentWorker.controls[field].setValue(value);
    }

    getFormValue(field) {
        return this.formAppointmentWorker.controls[field].value;
    }
    getFormAppointment(field) {
        return this.job.appointment && this.job.appointment[field] || 'Pending'
    }

    getPriorityClass(field) {
        const status = this.getFormAppointment(field);
        if (this._statusCss.hasOwnProperty(status)) {
            return this._statusCss[status];
        } else {
            return 'in-progress';
        }
    }

    checkIfApprovalReq(): boolean {
        const status = this.job.appointment && this.job.appointment['Appointment_Status__c'] || 'Pending'
        if (status && status === 'Awaiting Customer Approval' || this.job.appointment.Worker_Apptmnt_Customer_Approval_Status__c == 'Pending') {
            return true;
        }
        return false;
        // return true;
    }

    onChangeReasonCode(val) {
        if (val === 'Other') {
          this.showOtherChangeReason = true;
          this.enableControl('Reason_Customer__c');
        } else {
          this.showOtherChangeReason = false;
          this.disableControl('Reason_Customer__c');
          this.formAppointmentWorker.controls['Reason_Customer__c'].setValue('');
          this.job.appointment.Reason_Customer__c  = this.formAppointmentWorker.value.Reason_Customer__c;
        }
      }

    onClearDate(e,field) {
        this.formAppointmentStatus.markAsDirty();
        this.formAppointmentStatus.value[field] = null;
        this.jobForm[field] = null;
    }
}
