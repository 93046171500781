import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { TimecardDetailComponent } from './timecard-detail.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { NgSelectModule } from '@ng-select/ng-select';
import { JobLookupModule } from 'shared/components/lookup/job-lookup/job-lookup.module';
import { VendorAccountLookupModule } from 'shared/components/lookup/vendor-account/vendor-account-lookup.module';
import { WorkerLookupModule } from 'shared/components/lookup/worker-lookup/worker-lookup.module';
import { RightSidebarModule } from 'shared/components/right-sidebar/right-sidebar.module';
import { DocumentModule } from 'shared/components/document/document.module';
import { PreloaderModule } from 'shared/components/preloader/preloader.module';
import { AlertModule } from 'shared/components/alert/alert.module';
import { JobScheduleModule } from 'shared/components/job-schedule/job-schedule.module';
import { FeedModule } from 'shared/components/feed/feed.module';
import { ProductLookupModule } from 'shared/components/lookup/product-lookup/product-lookup.module';
import { VendorWorkerRatingComponent } from './vendor-worker-rating/vendor-worker-rating.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgSelectModule,
    JobLookupModule,
    VendorAccountLookupModule,
    WorkerLookupModule,
    DocumentModule,
    PreloaderModule,
    AlertModule,
    JobScheduleModule,
    FeedModule,
    ProductLookupModule,
    NgbModule
  ],
  declarations: [TimecardDetailComponent, VendorWorkerRatingComponent],
  exports: [TimecardDetailComponent, VendorWorkerRatingComponent]
})
export class TimeCardDetailComModule { }
