import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SharedLoginLogApi } from 'shared/sdk';
import { PreloaderService } from 'shared/services/preloader.service';

@Component({
  selector: 'app-shared-login-log',
  templateUrl: './shared-login-log.component.html',
  styleUrls: ['./shared-login-log.component.css']
})
export class SharedLoginLogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
    private _dialogRef: MatDialogRef<SharedLoginLogComponent>,
    private _sharedLoginLogApi: SharedLoginLogApi, private _loader: PreloaderService) { }

  emailPattern = /\S+@\S+\.\S+/;
  phonePattern = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

  sharedLoginLogForm: FormGroup = new FormGroup({
    FirstName: new FormControl(null, [Validators.required, this.noWhitespaceValidator]),
    LastName: new FormControl(null),
    Phone: new FormControl(null, [Validators.required, Validators.pattern(this.phonePattern)]),
    Email: new FormControl(null, [Validators.required, Validators.pattern(this.emailPattern)])
  });
  saving = false;

  ngOnInit() {
  }

  saveDetails() {
    this.saving = true;
    this._sharedLoginLogApi.create(this.sharedLoginLogForm.value).subscribe(reply => {
      this.saving = false;
      let appData = JSON.parse(localStorage.getItem('appData'));
      appData['user']['SharedLogin'] = false;
      localStorage.setItem('appData', JSON.stringify(appData));
      this._dialogRef.close({
        data: reply
      });
    }, err => {
      this.saving = false;
      console.log(err);
    });
  }

  isNumber(evt) {
    evt = (evt) ? evt : window.event;
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode === 8 || charCode === 9) {
      return true;
    }
    return !((charCode > 31 && charCode < 48) || charCode > 57);
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

}
