import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs/Rx';
import { Router } from '@angular/router';
import * as io from 'socket.io-client';
import { Subject } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { AlertService } from './alert.service';
import { environment } from '../../environments/environment';
import { UsersApi } from '../sdk/services/custom/Users';
import { UserAlertApi } from '../sdk/services/custom/UserAlert';


@Injectable()
export class MessageSocketService {
    socket: any;
    socketUrl = '';
    isStarted: boolean = false;
    private nextMessage = new Subject<any>();


    constructor(
        private _router: Router,
        private _alertService: AlertService,
        private _titleService: Title,
        private _userAlertApi: UserAlertApi,
        private _usersApi: UsersApi
    ) {


    }
    initializeSocket(){
        if(!this.isStarted) {
            this.isStarted = true;
            this.connectSocketMessage();
        }else{
            console.log('Socket connected already....');
        }
    }

    connectSocketMessage() {
        console.log('connecting the messaging  socket.');
        const token = localStorage.getItem('accessToken');
        const url = environment.rtUrl + '/presence';
        const socket = io(url, {
            transports: ['websocket'], upgrade: false
        });
        socket.on('connect', () => {
            socket.emit('authenticate', {
                token: token
            }).on('authenticated', function () {
                this.socket = socket;  // Assign connected socket
                console.log('socket-authenticated!!', socket.connected);
            }).on('unauthorized', function (msg) {
                console.log("socket-unauthorized: " + JSON.stringify(msg.data));
                throw new Error(msg.data.type);
            }).on('newMessage', data => { 
                console.log('IN SOKCET SERVICE<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<',data);
                this.nextMessage.next(data);
            });
        }).on('disconnect', reason => {
            this.isStarted = false;
            console.log('discount ', reason);
            if (reason === 'io server disconnect') {
                console.log(reason);
                socket.connect();
            }
        });
        
    }
    getMessage(): Observable<any> {
        return this.nextMessage.asObservable();
    }

}
