/* tslint:disable */
import {
  Account,
  Worker,
  Users,
  Jobsite,
  CountryCode,
  GeoMetro,
  Skilling,
  Department,
  StateManager
} from '../index';

declare var Object: any;
export interface ContactInterface {
  "UserId"?: number;
  "sfdcId"?: string;
  "AccountId"?: string;
  "Active__c"?: boolean;
  "Advanced_System_User__c"?: boolean;
  "AssistantName"?: string;
  "AssistantPhone"?: string;
  "Bachelors_Degree__c"?: string;
  "Birthdate"?: Date;
  "CKSW_BASE__Languages__c"?: string;
  "CKSW_BASE__Level__c"?: string;
  "Candidate_Id_Ref__c"?: string;
  "Contact_Full_Name__c"?: string;
  "Contact_Name__c"?: string;
  "Contact_Overview__c"?: string;
  "Contact_Time_Zone__c"?: string;
  "Contact_Type__c"?: string;
  "ContractorHourly__c"?: string;
  "Country_Code__c"?: string;
  "CreatedById"?: string;
  "CreatedDate"?: Date;
  "CurrencyIsoCode"?: string;
  "Current_Employer__c"?: string;
  "Department"?: string;
  "Description"?: string;
  "Disable_PMS_Portal_Login__c"?: boolean;
  "Education_Years__c"?: number;
  "Email"?: string;
  "EmailBouncedDate"?: Date;
  "EmailBouncedReason"?: string;
  "Email__c"?: string;
  "Employed__c"?: string;
  "Enable_App_Login__c"?: boolean;
  "Enable_Inventory_Update__c"?: boolean;
  "ExperienceYrs__c"?: number;
  "Fax"?: string;
  "FirstName"?: string;
  "GEO_Code__c"?: string;
  "Gender__c"?: string;
  "Graduation_Date__c"?: Date;
  "HomePhone"?: string;
  "IsDeleted"?: boolean;
  "IsEmailBounced"?: boolean;
  "Jigsaw"?: string;
  "JigsawContactId"?: string;
  "Job_Opportunity__c"?: string;
  "Languages_Known__c"?: string;
  "LastActivityDate"?: Date;
  "LastCURequestDate"?: Date;
  "LastCUUpdateDate"?: Date;
  "LastLogin__c"?: Date;
  "LastModifiedById"?: string;
  "LastModifiedBy__c"?: string;
  "LastModifiedDate"?: Date;
  "LastName"?: string;
  "LastReferencedDate"?: Date;
  "LastViewedDate"?: Date;
  "LeadId__c"?: string;
  "LeadSource"?: string;
  "LinkedIn_Profile_Link__c"?: string;
  "List_of_Locations__c"?: string;
  "LocationList__c"?: string;
  "MSP_Portal_User_Name__c"?: string;
  "MailingAddress"?: string;
  "MailingCity"?: string;
  "MailingCountry"?: string;
  "MailingGeocodeAccuracy"?: string;
  "MailingLatitude"?: number;
  "MailingLongitude"?: number;
  "MailingPostalCode"?: string;
  "MailingState"?: string;
  "MailingStreet"?: string;
  "MasterRecordId"?: string;
  "Masters_Degree__c"?: string;
  "MobilePhone"?: string;
  "MyHomeMetro_Code__c"?: string;
  "Name"?: string;
  "OtherAddress"?: string;
  "OtherCity"?: string;
  "OtherCountry"?: string;
  "OtherGeocodeAccuracy"?: string;
  "OtherLatitude"?: number;
  "OtherLongitude"?: number;
  "OtherPhone"?: string;
  "OtherPostalCode"?: string;
  "OtherState"?: string;
  "OtherStreet"?: string;
  "OwnerId"?: string;
  "PMS_User_Name__c"?: string;
  "Partner_Account__c"?: string;
  "Password__c"?: string;
  "Phone"?: string;
  "PhotoUrl"?: string;
  "Post_Graduation_Date__c"?: Date;
  "Reason_For_switching__c"?: string;
  "RecordTypeId"?: string;
  "Record_Id__c"?: string;
  "ReportsToId"?: string;
  "Resource_Id__c"?: string;
  "Resume__c"?: string;
  "Role__c"?: string;
  "Salutation"?: string;
  "Skilling__c"?: string;
  "Standard_System_User__c"?: boolean;
  "State__c"?: string;
  "Strengths_Weaknesses__c"?: string;
  "Title"?: string;
  "Total_Experience_Months__c"?: number;
  "Total_Work_Exp__c"?: number;
  "User_Id__c"?: string;
  "User_Type__c"?: string;
  "Username__c"?: string;
  "WM_Contact_Id__c"?: string;
  "WM_User_ID__c"?: string;
  "sendLoginInfo__c"?: boolean;
  "ts2__Action__c"?: string;
  "ts2__Active_Search__c"?: boolean;
  "ts2__Asst_Email__c"?: string;
  "ts2__Best_Fit__c"?: string;
  "ts2__Business_Telephone_Number__c"?: string;
  "ts2__Business_Unit__c"?: string;
  "ts2__Candidate_Source__c"?: string;
  "ts2__Certifications__c"?: string;
  "ts2__Contact_Method__c"?: string;
  "ts2__Contact_Tags__c"?: string;
  "ts2__Credentials_Private_Key__c"?: string;
  "ts2__Current_Hourly__c"?: number;
  "ts2__Current_Salary__c"?: number;
  "ts2__Date_Available__c"?: Date;
  "ts2__Desired_Hourly__c"?: number;
  "ts2__Desired_Salary__c"?: number;
  "ts2__DischargeStatus__c"?: string;
  "ts2__Doc_Id__c"?: string;
  "ts2__EEO_Disabled_Received__c"?: string;
  "ts2__EEO_Disabled__c"?: string;
  "ts2__EEO_Gender_Received__c"?: string;
  "ts2__EEO_Gender__c"?: string;
  "ts2__EEO_Populate__c"?: boolean;
  "ts2__EEO_Race_Received__c"?: string;
  "ts2__EEO_Race__c"?: string;
  "ts2__EEO_Veteran_Status_Received__c"?: string;
  "ts2__EEO_Veteran_Status__c"?: string;
  "ts2__EduDegreeDate1__c"?: Date;
  "ts2__EduDegreeDate2__c"?: Date;
  "ts2__EduDegreeMajor1__c"?: string;
  "ts2__EduDegreeMajor2__c"?: string;
  "ts2__EduDegreeMinor1__c"?: string;
  "ts2__EduDegreeMinor2__c"?: string;
  "ts2__EduDegreeName1__c"?: string;
  "ts2__EduDegreeName2__c"?: string;
  "ts2__EduDegreeType1__c"?: string;
  "ts2__EduDegreeType2__c"?: string;
  "ts2__EduOtherHonors1__c"?: string;
  "ts2__EduOtherHonors2__c"?: string;
  "ts2__EduacademicHonors1__c"?: string;
  "ts2__EduacademicHonors2__c"?: string;
  "ts2__Education_School_1__c"?: string;
  "ts2__Education_School_2__c"?: string;
  "ts2__Education__c"?: string;
  "ts2__Edudocumentation1__c"?: string;
  "ts2__Edudocumentation2__c"?: string;
  "ts2__EduhonorsProgram1__c"?: string;
  "ts2__EduhonorsProgram2__c"?: string;
  "ts2__EduschoolTypes1__c"?: string;
  "ts2__EduschoolTypes2__c"?: string;
  "ts2__Emp_PositionHistory_positiontype1__c"?: string;
  "ts2__Emp_PositionHistory_positiontype2__c"?: string;
  "ts2__Employer1CategoryCode__c"?: string;
  "ts2__Employer1Description__c"?: string;
  "ts2__Employer1EndDate__c"?: Date;
  "ts2__Employer1StartDate__c"?: Date;
  "ts2__Employer1Title__c"?: string;
  "ts2__Employer2Description__c"?: string;
  "ts2__Employer2EndDate__c"?: Date;
  "ts2__Employer2StartDate__c"?: Date;
  "ts2__Employer2Title__c"?: string;
  "ts2__Employer2_Category_Code__c"?: string;
  "ts2__Employer3Description__c"?: string;
  "ts2__Employer3EndDate__c"?: Date;
  "ts2__Employer3StartDate__c"?: Date;
  "ts2__Employer3Title__c"?: string;
  "ts2__Employer3_Category_Code__c"?: string;
  "ts2__EmployerOrgName_1__c"?: string;
  "ts2__EmployerOrgName_2__c"?: string;
  "ts2__EmployerOrgName_3__c"?: string;
  "ts2__Executive_summary__c"?: string;
  "ts2__Experience_Summary__c"?: string;
  "ts2__Facebook_Profile__c"?: string;
  "ts2__Firstname__c"?: string;
  "ts2__Geo_Location_Passed__c"?: boolean;
  "ts2__Hard_Rating__c"?: string;
  "ts2__Hard_Skills__c"?: string;
  "ts2__HasEmailBounced__c"?: boolean;
  "ts2__Hide_Photo__c"?: boolean;
  "ts2__Home_Telephone_Number__c"?: string;
  "ts2__I9_Received__c"?: boolean;
  "ts2__Internet_Email_Address__c"?: string;
  "ts2__JobNotificationsSummary__c"?: number;
  "ts2__Job_Type__c"?: string;
  "ts2__Jobs_Applied_For__c"?: string;
  "ts2__LUID__c"?: string;
  "ts2__Language_Comments_1__c"?: string;
  "ts2__Language_Comments_2__c"?: string;
  "ts2__Language_Read_1__c"?: string;
  "ts2__Language_Read_2__c"?: string;
  "ts2__Language_Speak_1__c"?: string;
  "ts2__Language_Speak_2__c"?: string;
  "ts2__Language_Write_1__c"?: string;
  "ts2__Language_Write_2__c"?: string;
  "ts2__Languages__c"?: string;
  "ts2__Lastname__c"?: string;
  "ts2__Latitude__c"?: number;
  "ts2__Legacy_ContactID__c"?: string;
  "ts2__Legacy_DocumentID__c"?: string;
  "ts2__Level__c"?: string;
  "ts2__License_or_Certification_Credential_Type__c"?: string;
  "ts2__License_or_Certification_Description_1__c"?: string;
  "ts2__License_or_Certification_Description_2__c"?: string;
  "ts2__License_or_Certification_Name_1__c"?: string;
  "ts2__License_or_Certification_Name_2__c"?: string;
  "ts2__LinkedIn_Profile__c"?: string;
  "ts2__Login_Password__c"?: string;
  "ts2__Longitude__c"?: number;
  "ts2__MailingCountryText__c"?: string;
  "ts2__MailingStateText__c"?: string;
  "ts2__Match__c"?: number;
  "ts2__Memo__c"?: string;
  "ts2__Middlename__c"?: string;
  "ts2__Military_History_Type__c"?: string;
  "ts2__Mobile_Telephone_Number__c"?: string;
  "ts2__Notice__c"?: string;
  "ts2__Opt_In_to_Job_Alerts__c"?: boolean;
  "ts2__People_Status__c"?: string;
  "ts2__Picture_Id__c"?: string;
  "ts2__Picture__c"?: string;
  "ts2__Preferred_Name__c"?: string;
  "ts2__Qualification_Summary__c"?: string;
  "ts2__Reference_1__c"?: string;
  "ts2__Reference_2__c"?: string;
  "ts2__Referral_Lookup__c"?: string;
  "ts2__ReferrerEmailMessage__c"?: string;
  "ts2__ReferrerEmailSubject__c"?: string;
  "ts2__Relocated__c"?: boolean;
  "ts2__Relocation_Date__c"?: Date;
  "ts2__Resume_Last_Updated__c"?: Date;
  "ts2__SSN1__c"?: string;
  "ts2__SSN2__c"?: string;
  "ts2__SSN3__c"?: string;
  "ts2__Search_Social_Profiles__c"?: string;
  "ts2__Second_Best_Fit__c"?: string;
  "ts2__Security_Credential_1__c"?: string;
  "ts2__Security_Credential_Description_1__c"?: string;
  "ts2__Security_Credential_Type_1__c"?: string;
  "ts2__SessionKey__c"?: string;
  "ts2__Shift__c"?: string;
  "ts2__Skill_Codes__c"?: string;
  "ts2__Social_Security__c"?: string;
  "ts2__Soft_Rating__c"?: string;
  "ts2__Soft_Skills__c"?: number;
  "ts2__Source_Notes__c"?: string;
  "ts2__Source__c"?: string;
  "ts2__Taxonomy_Last_12_Months__c"?: string;
  "ts2__Taxonomy_Qualifications__c"?: string;
  "ts2__Taxonomy_Skills__c"?: string;
  "ts2__Taxonomy_Work_History_12_24__c"?: string;
  "ts2__Taxonomy_Work_History_24_36__c"?: string;
  "ts2__Taxonomy_Work_History_36_48__c"?: string;
  "ts2__Text_Resume__c"?: string;
  "ts2__Total_Months_Employed__c"?: number;
  "ts2__Travel_Desired__c"?: number;
  "ts2__Twitter_Profile__c"?: string;
  "ts2__Verified_Date__c"?: Date;
  "ts2__Verified_Key__c"?: string;
  "ts2__Verified__c"?: boolean;
  "ts2__W4_Received__c"?: boolean;
  "ts2__Years_of_Experience__c"?: number;
  "ts2__iPaper_Key__c"?: string;
  "ts2rb__Languages__c"?: string;
  "ts2rb__Level__c"?: string;
  "vfirst__Adjudication__c"?: string;
  "vfirst__Initiate_Background_Check__c"?: boolean;
  "vfirst__Site_Guest_Access_Helper__c"?: string;
  "vfirst__Stage__c"?: string;
  "vfirst__Verification_Report_m__c"?: string;
  "vfirst__of_Background_Checks_Ordered__c"?: number;
  "zeitzone__MailingLTFactor__c"?: number;
  "zeitzone__MailingLocalTime__c"?: string;
  "zeitzone__MailingTimeZoneId__c"?: string;
  "zeitzone__OtherLTFactor__c"?: number;
  "zeitzone__OtherLocalTime__c"?: string;
  "zeitzone__otherTimeZoneId__c"?: string;
  "Candidate_Salary_Term__c"?: string;
  "Count_of_Primary_Email__c"?: number;
  "Health_MSP_Email__c"?: string;
  "Health_Portal_Access__c"?: string;
  "MSP_Internal_Email_verified__c"?: boolean;
  "Portal_Access_Email__c"?: boolean;
  "Full_Account_Name__c"?: string;
  "Send_RFI_Email__c"?: boolean;
  "isMailingAddressChanged__c"?: boolean;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  account?: Account;
  worker?: Worker[];
  users?: Users;
  jobsite?: Jobsite;
  countryCode?: CountryCode;
  geometro?: GeoMetro;
  skillings?: Skilling[];
  department?: Department;
  stateManager?: StateManager;
}

export class Contact implements ContactInterface {
  "UserId": number;
  "sfdcId": string;
  "AccountId": string;
  "Active__c": boolean;
  "Advanced_System_User__c": boolean;
  "AssistantName": string;
  "AssistantPhone": string;
  "Bachelors_Degree__c": string;
  "Birthdate": Date;
  "CKSW_BASE__Languages__c": string;
  "CKSW_BASE__Level__c": string;
  "Candidate_Id_Ref__c": string;
  "Contact_Full_Name__c": string;
  "Contact_Name__c": string;
  "Contact_Overview__c": string;
  "Contact_Time_Zone__c": string;
  "Contact_Type__c": string;
  "ContractorHourly__c": string;
  "Country_Code__c": string;
  "CreatedById": string;
  "CreatedDate": Date;
  "CurrencyIsoCode": string;
  "Current_Employer__c": string;
  "Department": string;
  "Description": string;
  "Disable_PMS_Portal_Login__c": boolean;
  "Education_Years__c": number;
  "Email": string;
  "EmailBouncedDate": Date;
  "EmailBouncedReason": string;
  "Email__c": string;
  "Employed__c": string;
  "Enable_App_Login__c": boolean;
  "Enable_Inventory_Update__c": boolean;
  "ExperienceYrs__c": number;
  "Fax": string;
  "FirstName": string;
  "GEO_Code__c": string;
  "Gender__c": string;
  "Graduation_Date__c": Date;
  "HomePhone": string;
  "IsDeleted": boolean;
  "IsEmailBounced": boolean;
  "Jigsaw": string;
  "JigsawContactId": string;
  "Job_Opportunity__c": string;
  "Languages_Known__c": string;
  "LastActivityDate": Date;
  "LastCURequestDate": Date;
  "LastCUUpdateDate": Date;
  "LastLogin__c": Date;
  "LastModifiedById": string;
  "LastModifiedBy__c": string;
  "LastModifiedDate": Date;
  "LastName": string;
  "LastReferencedDate": Date;
  "LastViewedDate": Date;
  "LeadId__c": string;
  "LeadSource": string;
  "LinkedIn_Profile_Link__c": string;
  "List_of_Locations__c": string;
  "LocationList__c": string;
  "MSP_Portal_User_Name__c": string;
  "MailingAddress": string;
  "MailingCity": string;
  "MailingCountry": string;
  "MailingGeocodeAccuracy": string;
  "MailingLatitude": number;
  "MailingLongitude": number;
  "MailingPostalCode": string;
  "MailingState": string;
  "MailingStreet": string;
  "MasterRecordId": string;
  "Masters_Degree__c": string;
  "MobilePhone": string;
  "MyHomeMetro_Code__c": string;
  "Name": string;
  "OtherAddress": string;
  "OtherCity": string;
  "OtherCountry": string;
  "OtherGeocodeAccuracy": string;
  "OtherLatitude": number;
  "OtherLongitude": number;
  "OtherPhone": string;
  "OtherPostalCode": string;
  "OtherState": string;
  "OtherStreet": string;
  "OwnerId": string;
  "PMS_User_Name__c": string;
  "Partner_Account__c": string;
  "Password__c": string;
  "Phone": string;
  "PhotoUrl": string;
  "Post_Graduation_Date__c": Date;
  "Reason_For_switching__c": string;
  "RecordTypeId": string;
  "Record_Id__c": string;
  "ReportsToId": string;
  "Resource_Id__c": string;
  "Resume__c": string;
  "Role__c": string;
  "Salutation": string;
  "Skilling__c": string;
  "Standard_System_User__c": boolean;
  "State__c": string;
  "Strengths_Weaknesses__c": string;
  "Title": string;
  "Total_Experience_Months__c": number;
  "Total_Work_Exp__c": number;
  "User_Id__c": string;
  "User_Type__c": string;
  "Username__c": string;
  "WM_Contact_Id__c": string;
  "WM_User_ID__c": string;
  "sendLoginInfo__c": boolean;
  "ts2__Action__c": string;
  "ts2__Active_Search__c": boolean;
  "ts2__Asst_Email__c": string;
  "ts2__Best_Fit__c": string;
  "ts2__Business_Telephone_Number__c": string;
  "ts2__Business_Unit__c": string;
  "ts2__Candidate_Source__c": string;
  "ts2__Certifications__c": string;
  "ts2__Contact_Method__c": string;
  "ts2__Contact_Tags__c": string;
  "ts2__Credentials_Private_Key__c": string;
  "ts2__Current_Hourly__c": number;
  "ts2__Current_Salary__c": number;
  "ts2__Date_Available__c": Date;
  "ts2__Desired_Hourly__c": number;
  "ts2__Desired_Salary__c": number;
  "ts2__DischargeStatus__c": string;
  "ts2__Doc_Id__c": string;
  "ts2__EEO_Disabled_Received__c": string;
  "ts2__EEO_Disabled__c": string;
  "ts2__EEO_Gender_Received__c": string;
  "ts2__EEO_Gender__c": string;
  "ts2__EEO_Populate__c": boolean;
  "ts2__EEO_Race_Received__c": string;
  "ts2__EEO_Race__c": string;
  "ts2__EEO_Veteran_Status_Received__c": string;
  "ts2__EEO_Veteran_Status__c": string;
  "ts2__EduDegreeDate1__c": Date;
  "ts2__EduDegreeDate2__c": Date;
  "ts2__EduDegreeMajor1__c": string;
  "ts2__EduDegreeMajor2__c": string;
  "ts2__EduDegreeMinor1__c": string;
  "ts2__EduDegreeMinor2__c": string;
  "ts2__EduDegreeName1__c": string;
  "ts2__EduDegreeName2__c": string;
  "ts2__EduDegreeType1__c": string;
  "ts2__EduDegreeType2__c": string;
  "ts2__EduOtherHonors1__c": string;
  "ts2__EduOtherHonors2__c": string;
  "ts2__EduacademicHonors1__c": string;
  "ts2__EduacademicHonors2__c": string;
  "ts2__Education_School_1__c": string;
  "ts2__Education_School_2__c": string;
  "ts2__Education__c": string;
  "ts2__Edudocumentation1__c": string;
  "ts2__Edudocumentation2__c": string;
  "ts2__EduhonorsProgram1__c": string;
  "ts2__EduhonorsProgram2__c": string;
  "ts2__EduschoolTypes1__c": string;
  "ts2__EduschoolTypes2__c": string;
  "ts2__Emp_PositionHistory_positiontype1__c": string;
  "ts2__Emp_PositionHistory_positiontype2__c": string;
  "ts2__Employer1CategoryCode__c": string;
  "ts2__Employer1Description__c": string;
  "ts2__Employer1EndDate__c": Date;
  "ts2__Employer1StartDate__c": Date;
  "ts2__Employer1Title__c": string;
  "ts2__Employer2Description__c": string;
  "ts2__Employer2EndDate__c": Date;
  "ts2__Employer2StartDate__c": Date;
  "ts2__Employer2Title__c": string;
  "ts2__Employer2_Category_Code__c": string;
  "ts2__Employer3Description__c": string;
  "ts2__Employer3EndDate__c": Date;
  "ts2__Employer3StartDate__c": Date;
  "ts2__Employer3Title__c": string;
  "ts2__Employer3_Category_Code__c": string;
  "ts2__EmployerOrgName_1__c": string;
  "ts2__EmployerOrgName_2__c": string;
  "ts2__EmployerOrgName_3__c": string;
  "ts2__Executive_summary__c": string;
  "ts2__Experience_Summary__c": string;
  "ts2__Facebook_Profile__c": string;
  "ts2__Firstname__c": string;
  "ts2__Geo_Location_Passed__c": boolean;
  "ts2__Hard_Rating__c": string;
  "ts2__Hard_Skills__c": string;
  "ts2__HasEmailBounced__c": boolean;
  "ts2__Hide_Photo__c": boolean;
  "ts2__Home_Telephone_Number__c": string;
  "ts2__I9_Received__c": boolean;
  "ts2__Internet_Email_Address__c": string;
  "ts2__JobNotificationsSummary__c": number;
  "ts2__Job_Type__c": string;
  "ts2__Jobs_Applied_For__c": string;
  "ts2__LUID__c": string;
  "ts2__Language_Comments_1__c": string;
  "ts2__Language_Comments_2__c": string;
  "ts2__Language_Read_1__c": string;
  "ts2__Language_Read_2__c": string;
  "ts2__Language_Speak_1__c": string;
  "ts2__Language_Speak_2__c": string;
  "ts2__Language_Write_1__c": string;
  "ts2__Language_Write_2__c": string;
  "ts2__Languages__c": string;
  "ts2__Lastname__c": string;
  "ts2__Latitude__c": number;
  "ts2__Legacy_ContactID__c": string;
  "ts2__Legacy_DocumentID__c": string;
  "ts2__Level__c": string;
  "ts2__License_or_Certification_Credential_Type__c": string;
  "ts2__License_or_Certification_Description_1__c": string;
  "ts2__License_or_Certification_Description_2__c": string;
  "ts2__License_or_Certification_Name_1__c": string;
  "ts2__License_or_Certification_Name_2__c": string;
  "ts2__LinkedIn_Profile__c": string;
  "ts2__Login_Password__c": string;
  "ts2__Longitude__c": number;
  "ts2__MailingCountryText__c": string;
  "ts2__MailingStateText__c": string;
  "ts2__Match__c": number;
  "ts2__Memo__c": string;
  "ts2__Middlename__c": string;
  "ts2__Military_History_Type__c": string;
  "ts2__Mobile_Telephone_Number__c": string;
  "ts2__Notice__c": string;
  "ts2__Opt_In_to_Job_Alerts__c": boolean;
  "ts2__People_Status__c": string;
  "ts2__Picture_Id__c": string;
  "ts2__Picture__c": string;
  "ts2__Preferred_Name__c": string;
  "ts2__Qualification_Summary__c": string;
  "ts2__Reference_1__c": string;
  "ts2__Reference_2__c": string;
  "ts2__Referral_Lookup__c": string;
  "ts2__ReferrerEmailMessage__c": string;
  "ts2__ReferrerEmailSubject__c": string;
  "ts2__Relocated__c": boolean;
  "ts2__Relocation_Date__c": Date;
  "ts2__Resume_Last_Updated__c": Date;
  "ts2__SSN1__c": string;
  "ts2__SSN2__c": string;
  "ts2__SSN3__c": string;
  "ts2__Search_Social_Profiles__c": string;
  "ts2__Second_Best_Fit__c": string;
  "ts2__Security_Credential_1__c": string;
  "ts2__Security_Credential_Description_1__c": string;
  "ts2__Security_Credential_Type_1__c": string;
  "ts2__SessionKey__c": string;
  "ts2__Shift__c": string;
  "ts2__Skill_Codes__c": string;
  "ts2__Social_Security__c": string;
  "ts2__Soft_Rating__c": string;
  "ts2__Soft_Skills__c": number;
  "ts2__Source_Notes__c": string;
  "ts2__Source__c": string;
  "ts2__Taxonomy_Last_12_Months__c": string;
  "ts2__Taxonomy_Qualifications__c": string;
  "ts2__Taxonomy_Skills__c": string;
  "ts2__Taxonomy_Work_History_12_24__c": string;
  "ts2__Taxonomy_Work_History_24_36__c": string;
  "ts2__Taxonomy_Work_History_36_48__c": string;
  "ts2__Text_Resume__c": string;
  "ts2__Total_Months_Employed__c": number;
  "ts2__Travel_Desired__c": number;
  "ts2__Twitter_Profile__c": string;
  "ts2__Verified_Date__c": Date;
  "ts2__Verified_Key__c": string;
  "ts2__Verified__c": boolean;
  "ts2__W4_Received__c": boolean;
  "ts2__Years_of_Experience__c": number;
  "ts2__iPaper_Key__c": string;
  "ts2rb__Languages__c": string;
  "ts2rb__Level__c": string;
  "vfirst__Adjudication__c": string;
  "vfirst__Initiate_Background_Check__c": boolean;
  "vfirst__Site_Guest_Access_Helper__c": string;
  "vfirst__Stage__c": string;
  "vfirst__Verification_Report_m__c": string;
  "vfirst__of_Background_Checks_Ordered__c": number;
  "zeitzone__MailingLTFactor__c": number;
  "zeitzone__MailingLocalTime__c": string;
  "zeitzone__MailingTimeZoneId__c": string;
  "zeitzone__OtherLTFactor__c": number;
  "zeitzone__OtherLocalTime__c": string;
  "zeitzone__otherTimeZoneId__c": string;
  "Candidate_Salary_Term__c": string;
  "Count_of_Primary_Email__c": number;
  "Health_MSP_Email__c": string;
  "Health_Portal_Access__c": string;
  "MSP_Internal_Email_verified__c": boolean;
  "Portal_Access_Email__c": boolean;
  "Full_Account_Name__c": string;
  "Send_RFI_Email__c": boolean;
  "isMailingAddressChanged__c": boolean;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  account: Account;
  worker: Worker[];
  users: Users;
  jobsite: Jobsite;
  countryCode: CountryCode;
  geometro: GeoMetro;
  skillings: Skilling[];
  department: Department;
  stateManager: StateManager;
  constructor(data?: ContactInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Contact`.
   */
  public static getModelName() {
    return "Contact";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Contact for dynamic purposes.
  **/
  public static factory(data: ContactInterface): Contact{
    return new Contact(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Contact',
      plural: 'Contacts',
      path: 'Contacts',
      idName: 'id',
      properties: {
        "UserId": {
          name: 'UserId',
          type: 'number'
        },
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "AccountId": {
          name: 'AccountId',
          type: 'string'
        },
        "Active__c": {
          name: 'Active__c',
          type: 'boolean'
        },
        "Advanced_System_User__c": {
          name: 'Advanced_System_User__c',
          type: 'boolean',
          default: false
        },
        "AssistantName": {
          name: 'AssistantName',
          type: 'string'
        },
        "AssistantPhone": {
          name: 'AssistantPhone',
          type: 'string'
        },
        "Bachelors_Degree__c": {
          name: 'Bachelors_Degree__c',
          type: 'string'
        },
        "Birthdate": {
          name: 'Birthdate',
          type: 'Date'
        },
        "CKSW_BASE__Languages__c": {
          name: 'CKSW_BASE__Languages__c',
          type: 'string'
        },
        "CKSW_BASE__Level__c": {
          name: 'CKSW_BASE__Level__c',
          type: 'string'
        },
        "Candidate_Id_Ref__c": {
          name: 'Candidate_Id_Ref__c',
          type: 'string'
        },
        "Contact_Full_Name__c": {
          name: 'Contact_Full_Name__c',
          type: 'string'
        },
        "Contact_Name__c": {
          name: 'Contact_Name__c',
          type: 'string'
        },
        "Contact_Overview__c": {
          name: 'Contact_Overview__c',
          type: 'string'
        },
        "Contact_Time_Zone__c": {
          name: 'Contact_Time_Zone__c',
          type: 'string'
        },
        "Contact_Type__c": {
          name: 'Contact_Type__c',
          type: 'string'
        },
        "ContractorHourly__c": {
          name: 'ContractorHourly__c',
          type: 'string'
        },
        "Country_Code__c": {
          name: 'Country_Code__c',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "Current_Employer__c": {
          name: 'Current_Employer__c',
          type: 'string'
        },
        "Department": {
          name: 'Department',
          type: 'string'
        },
        "Description": {
          name: 'Description',
          type: 'string'
        },
        "Disable_PMS_Portal_Login__c": {
          name: 'Disable_PMS_Portal_Login__c',
          type: 'boolean'
        },
        "Education_Years__c": {
          name: 'Education_Years__c',
          type: 'number'
        },
        "Email": {
          name: 'Email',
          type: 'string'
        },
        "EmailBouncedDate": {
          name: 'EmailBouncedDate',
          type: 'Date'
        },
        "EmailBouncedReason": {
          name: 'EmailBouncedReason',
          type: 'string'
        },
        "Email__c": {
          name: 'Email__c',
          type: 'string'
        },
        "Employed__c": {
          name: 'Employed__c',
          type: 'string'
        },
        "Enable_App_Login__c": {
          name: 'Enable_App_Login__c',
          type: 'boolean',
          default: false
        },
        "Enable_Inventory_Update__c": {
          name: 'Enable_Inventory_Update__c',
          type: 'boolean',
          default: false
        },
        "ExperienceYrs__c": {
          name: 'ExperienceYrs__c',
          type: 'number'
        },
        "Fax": {
          name: 'Fax',
          type: 'string'
        },
        "FirstName": {
          name: 'FirstName',
          type: 'string'
        },
        "GEO_Code__c": {
          name: 'GEO_Code__c',
          type: 'string'
        },
        "Gender__c": {
          name: 'Gender__c',
          type: 'string'
        },
        "Graduation_Date__c": {
          name: 'Graduation_Date__c',
          type: 'Date'
        },
        "HomePhone": {
          name: 'HomePhone',
          type: 'string'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "IsEmailBounced": {
          name: 'IsEmailBounced',
          type: 'boolean'
        },
        "Jigsaw": {
          name: 'Jigsaw',
          type: 'string'
        },
        "JigsawContactId": {
          name: 'JigsawContactId',
          type: 'string'
        },
        "Job_Opportunity__c": {
          name: 'Job_Opportunity__c',
          type: 'string'
        },
        "Languages_Known__c": {
          name: 'Languages_Known__c',
          type: 'string'
        },
        "LastActivityDate": {
          name: 'LastActivityDate',
          type: 'Date'
        },
        "LastCURequestDate": {
          name: 'LastCURequestDate',
          type: 'Date'
        },
        "LastCUUpdateDate": {
          name: 'LastCUUpdateDate',
          type: 'Date'
        },
        "LastLogin__c": {
          name: 'LastLogin__c',
          type: 'Date'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "LastModifiedBy__c": {
          name: 'LastModifiedBy__c',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "LastName": {
          name: 'LastName',
          type: 'string'
        },
        "LastReferencedDate": {
          name: 'LastReferencedDate',
          type: 'Date'
        },
        "LastViewedDate": {
          name: 'LastViewedDate',
          type: 'Date'
        },
        "LeadId__c": {
          name: 'LeadId__c',
          type: 'string'
        },
        "LeadSource": {
          name: 'LeadSource',
          type: 'string'
        },
        "LinkedIn_Profile_Link__c": {
          name: 'LinkedIn_Profile_Link__c',
          type: 'string'
        },
        "List_of_Locations__c": {
          name: 'List_of_Locations__c',
          type: 'string'
        },
        "LocationList__c": {
          name: 'LocationList__c',
          type: 'string'
        },
        "MSP_Portal_User_Name__c": {
          name: 'MSP_Portal_User_Name__c',
          type: 'string'
        },
        "MailingAddress": {
          name: 'MailingAddress',
          type: 'string'
        },
        "MailingCity": {
          name: 'MailingCity',
          type: 'string'
        },
        "MailingCountry": {
          name: 'MailingCountry',
          type: 'string'
        },
        "MailingGeocodeAccuracy": {
          name: 'MailingGeocodeAccuracy',
          type: 'string'
        },
        "MailingLatitude": {
          name: 'MailingLatitude',
          type: 'number'
        },
        "MailingLongitude": {
          name: 'MailingLongitude',
          type: 'number'
        },
        "MailingPostalCode": {
          name: 'MailingPostalCode',
          type: 'string'
        },
        "MailingState": {
          name: 'MailingState',
          type: 'string'
        },
        "MailingStreet": {
          name: 'MailingStreet',
          type: 'string'
        },
        "MasterRecordId": {
          name: 'MasterRecordId',
          type: 'string'
        },
        "Masters_Degree__c": {
          name: 'Masters_Degree__c',
          type: 'string'
        },
        "MobilePhone": {
          name: 'MobilePhone',
          type: 'string'
        },
        "MyHomeMetro_Code__c": {
          name: 'MyHomeMetro_Code__c',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "OtherAddress": {
          name: 'OtherAddress',
          type: 'string'
        },
        "OtherCity": {
          name: 'OtherCity',
          type: 'string'
        },
        "OtherCountry": {
          name: 'OtherCountry',
          type: 'string'
        },
        "OtherGeocodeAccuracy": {
          name: 'OtherGeocodeAccuracy',
          type: 'string'
        },
        "OtherLatitude": {
          name: 'OtherLatitude',
          type: 'number'
        },
        "OtherLongitude": {
          name: 'OtherLongitude',
          type: 'number'
        },
        "OtherPhone": {
          name: 'OtherPhone',
          type: 'string'
        },
        "OtherPostalCode": {
          name: 'OtherPostalCode',
          type: 'string'
        },
        "OtherState": {
          name: 'OtherState',
          type: 'string'
        },
        "OtherStreet": {
          name: 'OtherStreet',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "PMS_User_Name__c": {
          name: 'PMS_User_Name__c',
          type: 'string'
        },
        "Partner_Account__c": {
          name: 'Partner_Account__c',
          type: 'string'
        },
        "Password__c": {
          name: 'Password__c',
          type: 'string'
        },
        "Phone": {
          name: 'Phone',
          type: 'string'
        },
        "PhotoUrl": {
          name: 'PhotoUrl',
          type: 'string'
        },
        "Post_Graduation_Date__c": {
          name: 'Post_Graduation_Date__c',
          type: 'Date'
        },
        "Reason_For_switching__c": {
          name: 'Reason_For_switching__c',
          type: 'string'
        },
        "RecordTypeId": {
          name: 'RecordTypeId',
          type: 'string'
        },
        "Record_Id__c": {
          name: 'Record_Id__c',
          type: 'string'
        },
        "ReportsToId": {
          name: 'ReportsToId',
          type: 'string'
        },
        "Resource_Id__c": {
          name: 'Resource_Id__c',
          type: 'string'
        },
        "Resume__c": {
          name: 'Resume__c',
          type: 'string'
        },
        "Role__c": {
          name: 'Role__c',
          type: 'string'
        },
        "Salutation": {
          name: 'Salutation',
          type: 'string'
        },
        "Skilling__c": {
          name: 'Skilling__c',
          type: 'string'
        },
        "Standard_System_User__c": {
          name: 'Standard_System_User__c',
          type: 'boolean',
          default: false
        },
        "State__c": {
          name: 'State__c',
          type: 'string'
        },
        "Strengths_Weaknesses__c": {
          name: 'Strengths_Weaknesses__c',
          type: 'string'
        },
        "Title": {
          name: 'Title',
          type: 'string'
        },
        "Total_Experience_Months__c": {
          name: 'Total_Experience_Months__c',
          type: 'number'
        },
        "Total_Work_Exp__c": {
          name: 'Total_Work_Exp__c',
          type: 'number'
        },
        "User_Id__c": {
          name: 'User_Id__c',
          type: 'string'
        },
        "User_Type__c": {
          name: 'User_Type__c',
          type: 'string'
        },
        "Username__c": {
          name: 'Username__c',
          type: 'string'
        },
        "WM_Contact_Id__c": {
          name: 'WM_Contact_Id__c',
          type: 'string'
        },
        "WM_User_ID__c": {
          name: 'WM_User_ID__c',
          type: 'string'
        },
        "sendLoginInfo__c": {
          name: 'sendLoginInfo__c',
          type: 'boolean',
          default: false
        },
        "ts2__Action__c": {
          name: 'ts2__Action__c',
          type: 'string'
        },
        "ts2__Active_Search__c": {
          name: 'ts2__Active_Search__c',
          type: 'boolean',
          default: false
        },
        "ts2__Asst_Email__c": {
          name: 'ts2__Asst_Email__c',
          type: 'string'
        },
        "ts2__Best_Fit__c": {
          name: 'ts2__Best_Fit__c',
          type: 'string'
        },
        "ts2__Business_Telephone_Number__c": {
          name: 'ts2__Business_Telephone_Number__c',
          type: 'string'
        },
        "ts2__Business_Unit__c": {
          name: 'ts2__Business_Unit__c',
          type: 'string'
        },
        "ts2__Candidate_Source__c": {
          name: 'ts2__Candidate_Source__c',
          type: 'string'
        },
        "ts2__Certifications__c": {
          name: 'ts2__Certifications__c',
          type: 'string'
        },
        "ts2__Contact_Method__c": {
          name: 'ts2__Contact_Method__c',
          type: 'string'
        },
        "ts2__Contact_Tags__c": {
          name: 'ts2__Contact_Tags__c',
          type: 'string'
        },
        "ts2__Credentials_Private_Key__c": {
          name: 'ts2__Credentials_Private_Key__c',
          type: 'string'
        },
        "ts2__Current_Hourly__c": {
          name: 'ts2__Current_Hourly__c',
          type: 'number'
        },
        "ts2__Current_Salary__c": {
          name: 'ts2__Current_Salary__c',
          type: 'number'
        },
        "ts2__Date_Available__c": {
          name: 'ts2__Date_Available__c',
          type: 'Date'
        },
        "ts2__Desired_Hourly__c": {
          name: 'ts2__Desired_Hourly__c',
          type: 'number'
        },
        "ts2__Desired_Salary__c": {
          name: 'ts2__Desired_Salary__c',
          type: 'number'
        },
        "ts2__DischargeStatus__c": {
          name: 'ts2__DischargeStatus__c',
          type: 'string'
        },
        "ts2__Doc_Id__c": {
          name: 'ts2__Doc_Id__c',
          type: 'string'
        },
        "ts2__EEO_Disabled_Received__c": {
          name: 'ts2__EEO_Disabled_Received__c',
          type: 'string'
        },
        "ts2__EEO_Disabled__c": {
          name: 'ts2__EEO_Disabled__c',
          type: 'string'
        },
        "ts2__EEO_Gender_Received__c": {
          name: 'ts2__EEO_Gender_Received__c',
          type: 'string'
        },
        "ts2__EEO_Gender__c": {
          name: 'ts2__EEO_Gender__c',
          type: 'string'
        },
        "ts2__EEO_Populate__c": {
          name: 'ts2__EEO_Populate__c',
          type: 'boolean'
        },
        "ts2__EEO_Race_Received__c": {
          name: 'ts2__EEO_Race_Received__c',
          type: 'string'
        },
        "ts2__EEO_Race__c": {
          name: 'ts2__EEO_Race__c',
          type: 'string'
        },
        "ts2__EEO_Veteran_Status_Received__c": {
          name: 'ts2__EEO_Veteran_Status_Received__c',
          type: 'string'
        },
        "ts2__EEO_Veteran_Status__c": {
          name: 'ts2__EEO_Veteran_Status__c',
          type: 'string'
        },
        "ts2__EduDegreeDate1__c": {
          name: 'ts2__EduDegreeDate1__c',
          type: 'Date'
        },
        "ts2__EduDegreeDate2__c": {
          name: 'ts2__EduDegreeDate2__c',
          type: 'Date'
        },
        "ts2__EduDegreeMajor1__c": {
          name: 'ts2__EduDegreeMajor1__c',
          type: 'string'
        },
        "ts2__EduDegreeMajor2__c": {
          name: 'ts2__EduDegreeMajor2__c',
          type: 'string'
        },
        "ts2__EduDegreeMinor1__c": {
          name: 'ts2__EduDegreeMinor1__c',
          type: 'string'
        },
        "ts2__EduDegreeMinor2__c": {
          name: 'ts2__EduDegreeMinor2__c',
          type: 'string'
        },
        "ts2__EduDegreeName1__c": {
          name: 'ts2__EduDegreeName1__c',
          type: 'string'
        },
        "ts2__EduDegreeName2__c": {
          name: 'ts2__EduDegreeName2__c',
          type: 'string'
        },
        "ts2__EduDegreeType1__c": {
          name: 'ts2__EduDegreeType1__c',
          type: 'string'
        },
        "ts2__EduDegreeType2__c": {
          name: 'ts2__EduDegreeType2__c',
          type: 'string'
        },
        "ts2__EduOtherHonors1__c": {
          name: 'ts2__EduOtherHonors1__c',
          type: 'string'
        },
        "ts2__EduOtherHonors2__c": {
          name: 'ts2__EduOtherHonors2__c',
          type: 'string'
        },
        "ts2__EduacademicHonors1__c": {
          name: 'ts2__EduacademicHonors1__c',
          type: 'string'
        },
        "ts2__EduacademicHonors2__c": {
          name: 'ts2__EduacademicHonors2__c',
          type: 'string'
        },
        "ts2__Education_School_1__c": {
          name: 'ts2__Education_School_1__c',
          type: 'string'
        },
        "ts2__Education_School_2__c": {
          name: 'ts2__Education_School_2__c',
          type: 'string'
        },
        "ts2__Education__c": {
          name: 'ts2__Education__c',
          type: 'string'
        },
        "ts2__Edudocumentation1__c": {
          name: 'ts2__Edudocumentation1__c',
          type: 'string'
        },
        "ts2__Edudocumentation2__c": {
          name: 'ts2__Edudocumentation2__c',
          type: 'string'
        },
        "ts2__EduhonorsProgram1__c": {
          name: 'ts2__EduhonorsProgram1__c',
          type: 'string'
        },
        "ts2__EduhonorsProgram2__c": {
          name: 'ts2__EduhonorsProgram2__c',
          type: 'string'
        },
        "ts2__EduschoolTypes1__c": {
          name: 'ts2__EduschoolTypes1__c',
          type: 'string'
        },
        "ts2__EduschoolTypes2__c": {
          name: 'ts2__EduschoolTypes2__c',
          type: 'string'
        },
        "ts2__Emp_PositionHistory_positiontype1__c": {
          name: 'ts2__Emp_PositionHistory_positiontype1__c',
          type: 'string'
        },
        "ts2__Emp_PositionHistory_positiontype2__c": {
          name: 'ts2__Emp_PositionHistory_positiontype2__c',
          type: 'string'
        },
        "ts2__Employer1CategoryCode__c": {
          name: 'ts2__Employer1CategoryCode__c',
          type: 'string'
        },
        "ts2__Employer1Description__c": {
          name: 'ts2__Employer1Description__c',
          type: 'string'
        },
        "ts2__Employer1EndDate__c": {
          name: 'ts2__Employer1EndDate__c',
          type: 'Date'
        },
        "ts2__Employer1StartDate__c": {
          name: 'ts2__Employer1StartDate__c',
          type: 'Date'
        },
        "ts2__Employer1Title__c": {
          name: 'ts2__Employer1Title__c',
          type: 'string'
        },
        "ts2__Employer2Description__c": {
          name: 'ts2__Employer2Description__c',
          type: 'string'
        },
        "ts2__Employer2EndDate__c": {
          name: 'ts2__Employer2EndDate__c',
          type: 'Date'
        },
        "ts2__Employer2StartDate__c": {
          name: 'ts2__Employer2StartDate__c',
          type: 'Date'
        },
        "ts2__Employer2Title__c": {
          name: 'ts2__Employer2Title__c',
          type: 'string'
        },
        "ts2__Employer2_Category_Code__c": {
          name: 'ts2__Employer2_Category_Code__c',
          type: 'string'
        },
        "ts2__Employer3Description__c": {
          name: 'ts2__Employer3Description__c',
          type: 'string'
        },
        "ts2__Employer3EndDate__c": {
          name: 'ts2__Employer3EndDate__c',
          type: 'Date'
        },
        "ts2__Employer3StartDate__c": {
          name: 'ts2__Employer3StartDate__c',
          type: 'Date'
        },
        "ts2__Employer3Title__c": {
          name: 'ts2__Employer3Title__c',
          type: 'string'
        },
        "ts2__Employer3_Category_Code__c": {
          name: 'ts2__Employer3_Category_Code__c',
          type: 'string'
        },
        "ts2__EmployerOrgName_1__c": {
          name: 'ts2__EmployerOrgName_1__c',
          type: 'string'
        },
        "ts2__EmployerOrgName_2__c": {
          name: 'ts2__EmployerOrgName_2__c',
          type: 'string'
        },
        "ts2__EmployerOrgName_3__c": {
          name: 'ts2__EmployerOrgName_3__c',
          type: 'string'
        },
        "ts2__Executive_summary__c": {
          name: 'ts2__Executive_summary__c',
          type: 'string'
        },
        "ts2__Experience_Summary__c": {
          name: 'ts2__Experience_Summary__c',
          type: 'string'
        },
        "ts2__Facebook_Profile__c": {
          name: 'ts2__Facebook_Profile__c',
          type: 'string'
        },
        "ts2__Firstname__c": {
          name: 'ts2__Firstname__c',
          type: 'string'
        },
        "ts2__Geo_Location_Passed__c": {
          name: 'ts2__Geo_Location_Passed__c',
          type: 'boolean'
        },
        "ts2__Hard_Rating__c": {
          name: 'ts2__Hard_Rating__c',
          type: 'string'
        },
        "ts2__Hard_Skills__c": {
          name: 'ts2__Hard_Skills__c',
          type: 'string'
        },
        "ts2__HasEmailBounced__c": {
          name: 'ts2__HasEmailBounced__c',
          type: 'boolean'
        },
        "ts2__Hide_Photo__c": {
          name: 'ts2__Hide_Photo__c',
          type: 'boolean'
        },
        "ts2__Home_Telephone_Number__c": {
          name: 'ts2__Home_Telephone_Number__c',
          type: 'string'
        },
        "ts2__I9_Received__c": {
          name: 'ts2__I9_Received__c',
          type: 'boolean'
        },
        "ts2__Internet_Email_Address__c": {
          name: 'ts2__Internet_Email_Address__c',
          type: 'string'
        },
        "ts2__JobNotificationsSummary__c": {
          name: 'ts2__JobNotificationsSummary__c',
          type: 'number'
        },
        "ts2__Job_Type__c": {
          name: 'ts2__Job_Type__c',
          type: 'string'
        },
        "ts2__Jobs_Applied_For__c": {
          name: 'ts2__Jobs_Applied_For__c',
          type: 'string'
        },
        "ts2__LUID__c": {
          name: 'ts2__LUID__c',
          type: 'string'
        },
        "ts2__Language_Comments_1__c": {
          name: 'ts2__Language_Comments_1__c',
          type: 'string'
        },
        "ts2__Language_Comments_2__c": {
          name: 'ts2__Language_Comments_2__c',
          type: 'string'
        },
        "ts2__Language_Read_1__c": {
          name: 'ts2__Language_Read_1__c',
          type: 'string'
        },
        "ts2__Language_Read_2__c": {
          name: 'ts2__Language_Read_2__c',
          type: 'string'
        },
        "ts2__Language_Speak_1__c": {
          name: 'ts2__Language_Speak_1__c',
          type: 'string'
        },
        "ts2__Language_Speak_2__c": {
          name: 'ts2__Language_Speak_2__c',
          type: 'string'
        },
        "ts2__Language_Write_1__c": {
          name: 'ts2__Language_Write_1__c',
          type: 'string'
        },
        "ts2__Language_Write_2__c": {
          name: 'ts2__Language_Write_2__c',
          type: 'string'
        },
        "ts2__Languages__c": {
          name: 'ts2__Languages__c',
          type: 'string'
        },
        "ts2__Lastname__c": {
          name: 'ts2__Lastname__c',
          type: 'string'
        },
        "ts2__Latitude__c": {
          name: 'ts2__Latitude__c',
          type: 'number'
        },
        "ts2__Legacy_ContactID__c": {
          name: 'ts2__Legacy_ContactID__c',
          type: 'string'
        },
        "ts2__Legacy_DocumentID__c": {
          name: 'ts2__Legacy_DocumentID__c',
          type: 'string'
        },
        "ts2__Level__c": {
          name: 'ts2__Level__c',
          type: 'string'
        },
        "ts2__License_or_Certification_Credential_Type__c": {
          name: 'ts2__License_or_Certification_Credential_Type__c',
          type: 'string'
        },
        "ts2__License_or_Certification_Description_1__c": {
          name: 'ts2__License_or_Certification_Description_1__c',
          type: 'string'
        },
        "ts2__License_or_Certification_Description_2__c": {
          name: 'ts2__License_or_Certification_Description_2__c',
          type: 'string'
        },
        "ts2__License_or_Certification_Name_1__c": {
          name: 'ts2__License_or_Certification_Name_1__c',
          type: 'string'
        },
        "ts2__License_or_Certification_Name_2__c": {
          name: 'ts2__License_or_Certification_Name_2__c',
          type: 'string'
        },
        "ts2__LinkedIn_Profile__c": {
          name: 'ts2__LinkedIn_Profile__c',
          type: 'string'
        },
        "ts2__Login_Password__c": {
          name: 'ts2__Login_Password__c',
          type: 'string'
        },
        "ts2__Longitude__c": {
          name: 'ts2__Longitude__c',
          type: 'number'
        },
        "ts2__MailingCountryText__c": {
          name: 'ts2__MailingCountryText__c',
          type: 'string'
        },
        "ts2__MailingStateText__c": {
          name: 'ts2__MailingStateText__c',
          type: 'string'
        },
        "ts2__Match__c": {
          name: 'ts2__Match__c',
          type: 'number'
        },
        "ts2__Memo__c": {
          name: 'ts2__Memo__c',
          type: 'string'
        },
        "ts2__Middlename__c": {
          name: 'ts2__Middlename__c',
          type: 'string'
        },
        "ts2__Military_History_Type__c": {
          name: 'ts2__Military_History_Type__c',
          type: 'string'
        },
        "ts2__Mobile_Telephone_Number__c": {
          name: 'ts2__Mobile_Telephone_Number__c',
          type: 'string'
        },
        "ts2__Notice__c": {
          name: 'ts2__Notice__c',
          type: 'string'
        },
        "ts2__Opt_In_to_Job_Alerts__c": {
          name: 'ts2__Opt_In_to_Job_Alerts__c',
          type: 'boolean'
        },
        "ts2__People_Status__c": {
          name: 'ts2__People_Status__c',
          type: 'string'
        },
        "ts2__Picture_Id__c": {
          name: 'ts2__Picture_Id__c',
          type: 'string'
        },
        "ts2__Picture__c": {
          name: 'ts2__Picture__c',
          type: 'string'
        },
        "ts2__Preferred_Name__c": {
          name: 'ts2__Preferred_Name__c',
          type: 'string'
        },
        "ts2__Qualification_Summary__c": {
          name: 'ts2__Qualification_Summary__c',
          type: 'string'
        },
        "ts2__Reference_1__c": {
          name: 'ts2__Reference_1__c',
          type: 'string'
        },
        "ts2__Reference_2__c": {
          name: 'ts2__Reference_2__c',
          type: 'string'
        },
        "ts2__Referral_Lookup__c": {
          name: 'ts2__Referral_Lookup__c',
          type: 'string'
        },
        "ts2__ReferrerEmailMessage__c": {
          name: 'ts2__ReferrerEmailMessage__c',
          type: 'string'
        },
        "ts2__ReferrerEmailSubject__c": {
          name: 'ts2__ReferrerEmailSubject__c',
          type: 'string'
        },
        "ts2__Relocated__c": {
          name: 'ts2__Relocated__c',
          type: 'boolean'
        },
        "ts2__Relocation_Date__c": {
          name: 'ts2__Relocation_Date__c',
          type: 'Date'
        },
        "ts2__Resume_Last_Updated__c": {
          name: 'ts2__Resume_Last_Updated__c',
          type: 'Date'
        },
        "ts2__SSN1__c": {
          name: 'ts2__SSN1__c',
          type: 'string'
        },
        "ts2__SSN2__c": {
          name: 'ts2__SSN2__c',
          type: 'string'
        },
        "ts2__SSN3__c": {
          name: 'ts2__SSN3__c',
          type: 'string'
        },
        "ts2__Search_Social_Profiles__c": {
          name: 'ts2__Search_Social_Profiles__c',
          type: 'string'
        },
        "ts2__Second_Best_Fit__c": {
          name: 'ts2__Second_Best_Fit__c',
          type: 'string'
        },
        "ts2__Security_Credential_1__c": {
          name: 'ts2__Security_Credential_1__c',
          type: 'string'
        },
        "ts2__Security_Credential_Description_1__c": {
          name: 'ts2__Security_Credential_Description_1__c',
          type: 'string'
        },
        "ts2__Security_Credential_Type_1__c": {
          name: 'ts2__Security_Credential_Type_1__c',
          type: 'string'
        },
        "ts2__SessionKey__c": {
          name: 'ts2__SessionKey__c',
          type: 'string'
        },
        "ts2__Shift__c": {
          name: 'ts2__Shift__c',
          type: 'string'
        },
        "ts2__Skill_Codes__c": {
          name: 'ts2__Skill_Codes__c',
          type: 'string'
        },
        "ts2__Social_Security__c": {
          name: 'ts2__Social_Security__c',
          type: 'string'
        },
        "ts2__Soft_Rating__c": {
          name: 'ts2__Soft_Rating__c',
          type: 'string'
        },
        "ts2__Soft_Skills__c": {
          name: 'ts2__Soft_Skills__c',
          type: 'number'
        },
        "ts2__Source_Notes__c": {
          name: 'ts2__Source_Notes__c',
          type: 'string'
        },
        "ts2__Source__c": {
          name: 'ts2__Source__c',
          type: 'string'
        },
        "ts2__Taxonomy_Last_12_Months__c": {
          name: 'ts2__Taxonomy_Last_12_Months__c',
          type: 'string'
        },
        "ts2__Taxonomy_Qualifications__c": {
          name: 'ts2__Taxonomy_Qualifications__c',
          type: 'string'
        },
        "ts2__Taxonomy_Skills__c": {
          name: 'ts2__Taxonomy_Skills__c',
          type: 'string'
        },
        "ts2__Taxonomy_Work_History_12_24__c": {
          name: 'ts2__Taxonomy_Work_History_12_24__c',
          type: 'string'
        },
        "ts2__Taxonomy_Work_History_24_36__c": {
          name: 'ts2__Taxonomy_Work_History_24_36__c',
          type: 'string'
        },
        "ts2__Taxonomy_Work_History_36_48__c": {
          name: 'ts2__Taxonomy_Work_History_36_48__c',
          type: 'string'
        },
        "ts2__Text_Resume__c": {
          name: 'ts2__Text_Resume__c',
          type: 'string'
        },
        "ts2__Total_Months_Employed__c": {
          name: 'ts2__Total_Months_Employed__c',
          type: 'number'
        },
        "ts2__Travel_Desired__c": {
          name: 'ts2__Travel_Desired__c',
          type: 'number'
        },
        "ts2__Twitter_Profile__c": {
          name: 'ts2__Twitter_Profile__c',
          type: 'string'
        },
        "ts2__Verified_Date__c": {
          name: 'ts2__Verified_Date__c',
          type: 'Date'
        },
        "ts2__Verified_Key__c": {
          name: 'ts2__Verified_Key__c',
          type: 'string'
        },
        "ts2__Verified__c": {
          name: 'ts2__Verified__c',
          type: 'boolean'
        },
        "ts2__W4_Received__c": {
          name: 'ts2__W4_Received__c',
          type: 'boolean'
        },
        "ts2__Years_of_Experience__c": {
          name: 'ts2__Years_of_Experience__c',
          type: 'number'
        },
        "ts2__iPaper_Key__c": {
          name: 'ts2__iPaper_Key__c',
          type: 'string'
        },
        "ts2rb__Languages__c": {
          name: 'ts2rb__Languages__c',
          type: 'string'
        },
        "ts2rb__Level__c": {
          name: 'ts2rb__Level__c',
          type: 'string'
        },
        "vfirst__Adjudication__c": {
          name: 'vfirst__Adjudication__c',
          type: 'string'
        },
        "vfirst__Initiate_Background_Check__c": {
          name: 'vfirst__Initiate_Background_Check__c',
          type: 'boolean'
        },
        "vfirst__Site_Guest_Access_Helper__c": {
          name: 'vfirst__Site_Guest_Access_Helper__c',
          type: 'string'
        },
        "vfirst__Stage__c": {
          name: 'vfirst__Stage__c',
          type: 'string'
        },
        "vfirst__Verification_Report_m__c": {
          name: 'vfirst__Verification_Report_m__c',
          type: 'string'
        },
        "vfirst__of_Background_Checks_Ordered__c": {
          name: 'vfirst__of_Background_Checks_Ordered__c',
          type: 'number'
        },
        "zeitzone__MailingLTFactor__c": {
          name: 'zeitzone__MailingLTFactor__c',
          type: 'number'
        },
        "zeitzone__MailingLocalTime__c": {
          name: 'zeitzone__MailingLocalTime__c',
          type: 'string'
        },
        "zeitzone__MailingTimeZoneId__c": {
          name: 'zeitzone__MailingTimeZoneId__c',
          type: 'string'
        },
        "zeitzone__OtherLTFactor__c": {
          name: 'zeitzone__OtherLTFactor__c',
          type: 'number'
        },
        "zeitzone__OtherLocalTime__c": {
          name: 'zeitzone__OtherLocalTime__c',
          type: 'string'
        },
        "zeitzone__otherTimeZoneId__c": {
          name: 'zeitzone__otherTimeZoneId__c',
          type: 'string'
        },
        "Candidate_Salary_Term__c": {
          name: 'Candidate_Salary_Term__c',
          type: 'string'
        },
        "Count_of_Primary_Email__c": {
          name: 'Count_of_Primary_Email__c',
          type: 'number'
        },
        "Health_MSP_Email__c": {
          name: 'Health_MSP_Email__c',
          type: 'string'
        },
        "Health_Portal_Access__c": {
          name: 'Health_Portal_Access__c',
          type: 'string'
        },
        "MSP_Internal_Email_verified__c": {
          name: 'MSP_Internal_Email_verified__c',
          type: 'boolean'
        },
        "Portal_Access_Email__c": {
          name: 'Portal_Access_Email__c',
          type: 'boolean'
        },
        "Full_Account_Name__c": {
          name: 'Full_Account_Name__c',
          type: 'string'
        },
        "Send_RFI_Email__c": {
          name: 'Send_RFI_Email__c',
          type: 'boolean'
        },
        "isMailingAddressChanged__c": {
          name: 'isMailingAddressChanged__c',
          type: 'boolean'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'AccountId',
          keyTo: 'sfdcId'
        },
        worker: {
          name: 'worker',
          type: 'Worker[]',
          model: 'Worker',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'Contact__c'
        },
        users: {
          name: 'users',
          type: 'Users',
          model: 'Users',
          relationType: 'belongsTo',
                  keyFrom: 'UserId',
          keyTo: 'id'
        },
        jobsite: {
          name: 'jobsite',
          type: 'Jobsite',
          model: 'Jobsite',
          relationType: 'belongsTo',
                  keyFrom: 'sfdcId',
          keyTo: 'Jobsite_Key_Contact__c'
        },
        countryCode: {
          name: 'countryCode',
          type: 'CountryCode',
          model: 'CountryCode',
          relationType: 'belongsTo',
                  keyFrom: 'Country_Code__c',
          keyTo: 'sfdcId'
        },
        geometro: {
          name: 'geometro',
          type: 'GeoMetro',
          model: 'GeoMetro',
          relationType: 'belongsTo',
                  keyFrom: 'MyHomeMetro_Code__c',
          keyTo: 'sfdcId'
        },
        skillings: {
          name: 'skillings',
          type: 'Skilling[]',
          model: 'Skilling',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'Resource__c'
        },
        department: {
          name: 'department',
          type: 'Department',
          model: 'Department',
          relationType: 'belongsTo',
                  keyFrom: 'Department',
          keyTo: 'sfdcId'
        },
        stateManager: {
          name: 'stateManager',
          type: 'StateManager',
          model: 'StateManager',
          relationType: 'belongsTo',
                  keyFrom: 'State__c',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
