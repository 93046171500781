import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { GeoMetroApi } from 'shared/sdk';

@Component({
  selector: 'app-address-search-lookup',
  templateUrl: './address-search-lookup.component.html',
  styleUrls: ['./address-search-lookup.component.css']
})
export class AddressSearchLookupComponent implements OnInit {

  @Input() set resetData(isClear) {
    // this.geoMetros = [];
    // this.geoMetroList = [];
    this.selectedGeoMetro = [];
  }
  @Input() set placeholder(e) {
    if (e) {
      this.typeToSearchText = 'Type to Search ' + e;
    } else {
      this.typeToSearchText = 'Type to Search Geo Code';
    }
  }
  @Input() key = 'geoCode'

  @Output() getGeoMetroObj: EventEmitter<any> = new EventEmitter;
  @Input() isMultipleAllowed = true;
  @Input() UseInGlobalFilter = true;
  // if value provided this will be selected by default for dropdown, UseInGlobalFilter key must be false.
  @Input() set selectedGeoMetroId(id) {
    if (id && this.UseInGlobalFilter == false) {
      this.selectedGeoMetro = id;
      let whereObj = {};
      whereObj['sfdcId'] = this.selectedGeoMetro;
      this.getGeoMetro(whereObj, '').subscribe((result: any) => {
        if (result && result.length) {
          this.geoMetros = result;
          if (this.geoMetros.length) {
            this.prepareData();
          }
        }
      });
    }
  }
  notFoundText = 'No Data Found';
  geoMetroTypeahead = new EventEmitter<string>();
  geoMetros: any = [];
  selectedGeoMetro = [];
  typeToSearchText;

  // @Input() set resetData(isClear) {
  //   this.selectedValue = null;
  // }

  @Output() inputAddress: EventEmitter<any> = new EventEmitter;
  selectedValue;

  constructor(private _geoMetro: GeoMetroApi) { }

  ngOnInit() {
    this.getGeoMetros({});

    // const preSelected = localStorage.getItem('filterObj');
    // if (preSelected && JSON.parse(preSelected) &&
    //   JSON.parse(preSelected)['address'] && JSON.parse(preSelected)['address'].length) {
    //   this.selectedValue = JSON.parse(preSelected)['address'][0];
    // }

    if (this.UseInGlobalFilter) {
      const selectFilter = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
      if (selectFilter && selectFilter[this.key] && Array.isArray(selectFilter[this.key]) && selectFilter[this.key].length) {
        this.selectedGeoMetro = this.isMultipleAllowed ? selectFilter[this.key] : selectFilter[this.key][0];
        const whereObj = { sfdcId: { inq: this.isMultipleAllowed ? this.selectedGeoMetro : [this.selectedGeoMetro] } };
        this.getGeoMetro(whereObj, '').subscribe((result: any) => {
          if (result && result.length) {
            this.geoMetros = result;
            this.notFoundText = (this.geoMetros.length === 0) ? 'No data found.' : '';
            if (this.geoMetros.length) {
              this.prepareData();
            }
          }
        });
      }
    }
  }

  getGeoMetros(whereObj) {
    this.geoMetros = [];
    this.geoMetroTypeahead.pipe(
      distinctUntilChanged(),
      debounceTime(200),
      switchMap(term =>
        (term && term.trim().length > 2) ? this.getGeoMetro(whereObj, term) : this.geoMetros = []
      )
    ).subscribe(result => {
      this.geoMetros = result;
      this.notFoundText = (this.geoMetros.length === 0) ? 'No data found.' : '';
      if (this.geoMetros.length) {
        this.prepareData();
      }
    }, err => {
      console.log(err);
      this.geoMetros = [];
      this.notFoundText = '';
    });
  }

  prepareData() {
    this.geoMetros.forEach(element => {
      if (this.key === 'city') {
        element['Name'] = element['City__c']
      }
      else if (this.key === 'address') {
        element['Name'] = element['City__c'] + ' ' + element['Street__c']
      }
    });
  }

  getGeoMetro(whereObj, term) {
    let orArray = [];
    if (this.key === 'geoCode') {
      orArray = [
        { Name: { like: '%' + term.trim() + '%', options: 'i' } },
        { City__c: { like: '%' + term.trim() + '%', options: 'i' } },
        { Street__c: { like: '%' + term.trim() + '%', options: 'i' } }
      ];
    } else {
      orArray = [
        { City__c: { like: '%' + term.trim() + '%', options: 'i' } },
        { Street__c: { like: '%' + term.trim() + '%', options: 'i' } }
      ];
    }
    return this._geoMetro.find({
      fields: ['Name', 'id', 'sfdcId', 'City__c', 'Country__c', 'Postal_Zip_Code__c', 'Street__c'],
      where: {
        and: [
          {
            or: orArray
          },
          whereObj
        ]
      },
      order: 'createdAt DESC',
      limit: 200,
    });
  }

  onChange() {
    this.getGeoMetroObj.emit(this.selectedGeoMetro);
  }

  clearGeoMetro() {
    this.selectedGeoMetro = [];
    this.getGeoMetroObj.emit([]);
  }
}
