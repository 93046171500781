import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ModalService } from './../../../services/modal.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RequestFormReceiptApi, JobApi } from '../../../sdk'
import { PreloaderService } from '../../../services/preloader.service';
import { AlertService } from '../../../services/alert.service';
import { CommonService } from '../../../services/common.service'
import { SharedService } from 'shared/services/pms/shared.services';

@Component({
  selector: 'app-fse-related-forms',
  templateUrl: './fse-related-forms.component.html',
  styleUrls: ['./fse-related-forms.component.css']
})
export class FseRelatedFormsComponent implements OnInit {
  @Input() jobId = '';
  @Input() jobNum = '';
  // Pass sfdcId of job in array to prelisting jobs and disable search
  @Input() jobIds = [];
  @Input() formType: string;
  @Input() clearable = true;

  @Output() onSuccess: EventEmitter<boolean> = new EventEmitter;

  pageTitle = 'FSE Related';
  error = '';
  jobList: any;
  formFseRelatedForm: FormGroup;
  noFountText = 'Type to search job';
  user: any;
  searchable = true;
  existEscalationMsg: any;
  frbTypeList = [{
    label: 'Billing',
    value: 'billing'
  },
  {
    label: 'Escalation',
    value: 'escalation'
  }];
  priorityOption = [{
    label: 'Very Critical (P0)',
    value: 'Very Critical (P0)'
  },
  {
    label: 'Critical (P1)',
    value: 'Critical (P1)'
  },
  {
    label: 'High (P2)',
    value: 'High (P2)'
  },
  {
    label: 'Medium (P3)',
    value: 'Medium (P3)'
  },
  {
    label: 'Low (P4)',
    value: 'Low (P4)'
  },
  {
    label: 'Very Low (P5)',
    value: 'Very Low (P5)'
  }];
  constructor(
    private _fb: FormBuilder,
    private _modalService: ModalService,
    private _commonService: CommonService,
    private _preloaderService: PreloaderService,
    private _alertService: AlertService,
    private _requestFormReceipt: RequestFormReceiptApi,
    private _jobApi: JobApi,
    private _sharedService: SharedService
  ) { }

  ngOnInit() {
    this.existEscalationMsg = '';
    this.jobList = []
    if (this.jobIds && this.jobIds.length) {
      this.searchable = false;
      this.getJobList({ sfdcId: { inq: this.jobIds } });
    }
    if (this.formType === 'escalation') {
      this.getTaskInfo();
      this.pageTitle = 'Escalation Form – Create Remediation Task Request';
      this.buildForm();
    } else {
      this.pageTitle = 'Escalation Form – Create Billing Task Request';
      this.buildForm();
    }
    if (this._commonService.getUserProfile()) {
      this._commonService.getUserProfile().subscribe(userProfile => {
        this.user = userProfile
      }, error => {
        console.log(error);
      });
    }
  }
  getTaskInfo() {
    if (this.jobId) {
      this._preloaderService.showPreloader();
      const reqObj = {
        fields: ['sfdcId', 'id', 'Iron_Job_num__c'],
        include: [
          {
            relation: 'escalationJob',
            scope: {
              fields: ['sfdcId', 'Job__c', 'Requested_By__c'],
              where: { 'Requested_By__c': { 'neq': null } },
              skip: 0,
              limit: 1,
              include: [
                {
                  relation: 'task',
                  scope: {
                    fields: ['sfdcId', 'Task_No__c', 'Task_Number__c'],
                  }
                }
              ],
            }
          }
        ]
      };
      this._jobApi.findById(this.jobId, reqObj).subscribe(res => {
        this._preloaderService.hidePreloader();
        if (res && res['escalationJob'] && res['escalationJob'].length &&
          res['escalationJob'][0]['task'] && res['escalationJob'][0]['task']['Task_Number__c']) {
          this.existEscalationMsg = `Job (${res['Iron_Job_num__c']}) has been already Escalated as
          (${res['escalationJob'][0]['task']['Task_Number__c']})`;
        }
      }, err => {
        this._preloaderService.hidePreloader();
      });
    }

  }



  buildForm() {
    this.formFseRelatedForm = this._fb.group({
      jobId: [{ value: null, disabled: false }, Validators.required],
      description: [{ value: null, disabled: false }, Validators.required],
      summary: [{ value: null, disabled: false }, Validators.required],
      type: [{ value: null, disabled: false }, Validators.required],
      priority: [{ value: 'Medium (P3)', disabled: false }],
      suggestion: [{ value: null, disabled: false }],
      fileUpload: [{ value: null, disabled: false }]
    });
    this.setFormValue();
  }

  setFormValue() {
    if (this.jobId) {
      this.formFseRelatedForm.controls['jobId'].setValue(this.jobId);
      this.formFseRelatedForm.controls['jobId'].disable();
    }
    if (this.formType) {
      this.formFseRelatedForm.controls['type'].setValue(this.formType);
      this.formFseRelatedForm.controls['type'].disable();
      if (this.formType === 'billing') {
        this.formFseRelatedForm.controls['priority'].setValue(null);
        this.formFseRelatedForm.controls['priority'].disable();
        this.formFseRelatedForm.controls['suggestion'].disable();
        this.formFseRelatedForm.controls['fileUpload'].disable();
      }
    }
  }

  getJobList(filter) {
    const reqObj = {
      'fields': ['sfdcId', 'id', 'Iron_Job_num__c', 'RecordTypeId', 'Vendor__c', 'Dispatch_Service_Resolution_Status__c',
        'Customer_Service_Type_From_Program__c', 'Service_Dispatch_SLA_Priority__c', 'Technical_Level__c', 'CKSW_BASE__Account__c'],
      'where': filter,
      'include': [
        {
          'relation': 'vendor',
          'scope': {
            'fields': ['sfdcId', 'Name']
          }
        },
        {
          'relation': 'partner',
          'scope': {
            'fields': ['sfdcId', 'Name']
          }
        }
      ],
      limit: 20,
      order: 'id DESC'
    };
    this._jobApi.find(reqObj).subscribe(
      result => {
        this.jobList = result;
        if (!this.jobList.length) {
          this.noFountText = 'No Record Found';
        }
      }, err => {
        this._alertService.error(err.message, true);
      });
  }
  serachJobs(e) {
    if (e && e.term && e.term.length > 2) {
      if (this.user && this.user.accessType !== 'internal') {
        const filter = {
          'Vendor__c': this.user.AccountId,
          'Iron_Job_num__c': { like: '%' + e.term.trim() + '%', options: 'i' }
        }
        this.getJobList(filter);
      } else {
        const filter = { 'Iron_Job_num__c': { like: '%' + e.term.trim() + '%', options: 'i' } }
        this.getJobList(filter);
      }
    }
  }
  clearSearch() {
    this.jobList = [];
    this.jobId = '';
    this.noFountText = 'Type to search job';
  }

  saveFSERelatedEscalation() {
    this.error = '';
    const reqObj = this.formFseRelatedForm.getRawValue();
    if (this.formFseRelatedForm.valid) {
      this._preloaderService.showPreloader();
      this._requestFormReceipt.newLineItem(reqObj).subscribe(
        data => {
          if (data) {
            this._preloaderService.hidePreloader();
            if (!data.error) {
              this.onSuccess.emit(true);
              this._modalService.closed();
              this._alertService.success(this.pageTitle + ' Task generated successfully.');
              this._sharedService.jobEscalationSubject.next({ 'id': this.jobId })
            } else {
              this.error = data.message;
              // this._alertService.error(data.message);
            }

          } else {
            this.error = 'Unable to create ' + this.pageTitle + ' Task.';
            this._preloaderService.hidePreloader();
          }
        },
        err => {
          this._alertService.error('Something Went Wrong.');
          this._preloaderService.hidePreloader();
          this._modalService.closed();
          this.error = err.message;
        }
      );
    } else {
      this.error = 'Please Fill all required fields';
    }
  }

  closeForm() {
    this._modalService.closed();
  }

}
