/* tslint:disable */

declare var Object: any;
export interface CustomerCsatInterface {
  "sfdcId"?: string;
  "CreatedById"?: string;
  "CurrencyIsoCode"?: string;
  "Name"?: string;
  "LastModifiedById"?: string;
  "OwnerId"?: string;
  "Account__c"?: string;
  "Customer_Delivery_Manager__c"?: string;
  "Customer_Email_Ack_Date__c"?: Date;
  "Customer_PM__c"?: string;
  "Date_Email_Sent__c"?: Date;
  "ETA__c"?: Date;
  "First_Follow_up__c"?: Date;
  "ISG_PM__c"?: string;
  "ISG_SDM__c"?: string;
  "Link_to_Quiz__c"?: string;
  "OK_To_Reach_Customer_for_CSAT_PgMO__c"?: boolean;
  "Program__c"?: string;
  "Quarter__c"?: number;
  "Second_Follow_up__c"?: Date;
  "Third_Follow_up__c"?: Date;
  "Year__c"?: number;
  "Overall_CSAT_Status__c"?: number;
  "Overall_CSAT_Sta__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class CustomerCsat implements CustomerCsatInterface {
  "sfdcId": string;
  "CreatedById": string;
  "CurrencyIsoCode": string;
  "Name": string;
  "LastModifiedById": string;
  "OwnerId": string;
  "Account__c": string;
  "Customer_Delivery_Manager__c": string;
  "Customer_Email_Ack_Date__c": Date;
  "Customer_PM__c": string;
  "Date_Email_Sent__c": Date;
  "ETA__c": Date;
  "First_Follow_up__c": Date;
  "ISG_PM__c": string;
  "ISG_SDM__c": string;
  "Link_to_Quiz__c": string;
  "OK_To_Reach_Customer_for_CSAT_PgMO__c": boolean;
  "Program__c": string;
  "Quarter__c": number;
  "Second_Follow_up__c": Date;
  "Third_Follow_up__c": Date;
  "Year__c": number;
  "Overall_CSAT_Status__c": number;
  "Overall_CSAT_Sta__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: CustomerCsatInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `CustomerCsat`.
   */
  public static getModelName() {
    return "CustomerCsat";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of CustomerCsat for dynamic purposes.
  **/
  public static factory(data: CustomerCsatInterface): CustomerCsat{
    return new CustomerCsat(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'CustomerCsat',
      plural: 'CustomerCsats',
      path: 'CustomerCsats',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "Account__c": {
          name: 'Account__c',
          type: 'string'
        },
        "Customer_Delivery_Manager__c": {
          name: 'Customer_Delivery_Manager__c',
          type: 'string'
        },
        "Customer_Email_Ack_Date__c": {
          name: 'Customer_Email_Ack_Date__c',
          type: 'Date'
        },
        "Customer_PM__c": {
          name: 'Customer_PM__c',
          type: 'string'
        },
        "Date_Email_Sent__c": {
          name: 'Date_Email_Sent__c',
          type: 'Date'
        },
        "ETA__c": {
          name: 'ETA__c',
          type: 'Date'
        },
        "First_Follow_up__c": {
          name: 'First_Follow_up__c',
          type: 'Date'
        },
        "ISG_PM__c": {
          name: 'ISG_PM__c',
          type: 'string'
        },
        "ISG_SDM__c": {
          name: 'ISG_SDM__c',
          type: 'string'
        },
        "Link_to_Quiz__c": {
          name: 'Link_to_Quiz__c',
          type: 'string'
        },
        "OK_To_Reach_Customer_for_CSAT_PgMO__c": {
          name: 'OK_To_Reach_Customer_for_CSAT_PgMO__c',
          type: 'boolean'
        },
        "Program__c": {
          name: 'Program__c',
          type: 'string'
        },
        "Quarter__c": {
          name: 'Quarter__c',
          type: 'number'
        },
        "Second_Follow_up__c": {
          name: 'Second_Follow_up__c',
          type: 'Date'
        },
        "Third_Follow_up__c": {
          name: 'Third_Follow_up__c',
          type: 'Date'
        },
        "Year__c": {
          name: 'Year__c',
          type: 'number'
        },
        "Overall_CSAT_Status__c": {
          name: 'Overall_CSAT_Status__c',
          type: 'number'
        },
        "Overall_CSAT_Sta__c": {
          name: 'Overall_CSAT_Sta__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
