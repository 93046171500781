import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';

import { TaskLibraryLookupComponent } from './task-library-lookup.component';

@NgModule({
    imports: [
        CommonModule,
        NgSelectModule,
        FormsModule
    ],
    declarations: [TaskLibraryLookupComponent],
    exports: [TaskLibraryLookupComponent]
})
export class TaskLibraryLookupModule { }
