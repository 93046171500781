import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-file-name-lookup',
  templateUrl: './file-name-lookup.component.html',
  styleUrls: ['./file-name-lookup.component.css']
})
export class FileNameLookupComponent implements OnInit {

  @Output() getFileName: EventEmitter<any> = new EventEmitter;
  @Input() set resetData(isClear) {
    if (isClear) {
      this.fileName = '';
    }
  }
  fileName;

  constructor() { }

  ngOnInit() {
    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
    if (preselected && preselected['fileNames'] && preselected['fileNames'].length) {
      this.fileName = preselected['fileNames'][0];
    }
  }

  fileNameChanged() {
    this.getFileName.emit(this.fileName.toString().trim().length ? this.fileName.toString().trim() : []);
  }

  clearName() {
    this.fileName = '';
    this.fileNameChanged();
  }

}
