/* tslint:disable */
import {
  Users,
  Vendorsite,
  Job,
  Contact,
  CountryCode,
  GeoMetro,
  RecordType,
  Skilling,
  ProjectWorker,
  GlobalTalentPool,
  Assignment,
  DepartmentRole,
  MemberRole,
  WorkerShift,
  ServiceContract,
  WorkerVendorsite
} from '../index';

declare var Object: any;
export interface WorkerInterface {
  "Name"?: string;
  "sfdcId"?: string;
  "Available__c"?: boolean;
  "Contact__c": string;
  "Candidate__c": string;
  "CreatedById"?: string;
  "CreatedDate"?: Date;
  "Dispatch_Worker_num__c"?: string;
  "Work_Phone_Number__c"?: string;
  "Mobile_Phone_Number__c"?: string;
  "Primary_Worker_Skilling_Profile__c"?: string;
  "IsDeleted"?: boolean;
  "RecordTypeId"?: string;
  "Vendorsite__c"?: string;
  "Worker_Type__c"?: string;
  "WM_Worker_Id__c"?: string;
  "WM_Profile_Link__c"?: string;
  "WM_Account_Creation_Date__c"?: Date;
  "Worker_Rating__c"?: string;
  "GEO_Code__c"?: string;
  "Global_Shift_Equivalent_Schedule_GSES__c"?: string;
  "Regional_Role__c"?: string;
  "Functional_Role__c"?: string;
  "Functional_Role_Level__c"?: string;
  "Account_Name__c"?: string;
  "Country_Code__c"?: string;
  "CurrencyIsoCode"?: string;
  "Email__c"?: string;
  "LastModifiedById"?: string;
  "LastModifiedDate"?: Date;
  "LastReferencedDate"?: Date;
  "LastViewedDate"?: Date;
  "State_Code__c"?: string;
  "Worker_Billing_Type__c"?: string;
  "Job_Rating_Count__c"?: number;
  "Communication_Rating_Average__c"?: number;
  "Deliverables_Rating_Average__c"?: number;
  "On_Time_Rating_Average__c"?: number;
  "Professionalism_Rating_Average__c"?: number;
  "Quality_Rating_Average__c"?: number;
  "Satisfaction_Rating_Average__c"?: number;
  "Personal_Email_ID__c"?: string;
  "Worker_City__c"?: string;
  "Enable_Send_SMS__c"?: boolean;
  "Primary_Global_Talent_Pool__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  user?: Users;
  createdBy?: Users;
  vendorsite?: Vendorsite;
  jobs?: Job[];
  contact?: Contact;
  countryCode?: CountryCode;
  geoMetro?: GeoMetro;
  recordType?: RecordType;
  skilling?: Skilling;
  projectWorker?: ProjectWorker[];
  globalTalentPool?: GlobalTalentPool;
  assignment?: Assignment[];
  departmentRoles?: DepartmentRole[];
  memberRoles?: MemberRole[];
  workershift?: WorkerShift;
  serviceContract?: ServiceContract[];
  workerVendorsite?: WorkerVendorsite[];
}

export class Worker implements WorkerInterface {
  "Name": string;
  "sfdcId": string;
  "Available__c": boolean;
  "Contact__c": string;
  "Candidate__c": string;
  "CreatedById": string;
  "CreatedDate": Date;
  "Dispatch_Worker_num__c": string;
  "Work_Phone_Number__c": string;
  "Mobile_Phone_Number__c": string;
  "Primary_Worker_Skilling_Profile__c": string;
  "IsDeleted": boolean;
  "RecordTypeId": string;
  "Vendorsite__c": string;
  "Worker_Type__c": string;
  "WM_Worker_Id__c": string;
  "WM_Profile_Link__c": string;
  "WM_Account_Creation_Date__c": Date;
  "Worker_Rating__c": string;
  "GEO_Code__c": string;
  "Global_Shift_Equivalent_Schedule_GSES__c": string;
  "Regional_Role__c": string;
  "Functional_Role__c": string;
  "Functional_Role_Level__c": string;
  "Account_Name__c": string;
  "Country_Code__c": string;
  "CurrencyIsoCode": string;
  "Email__c": string;
  "LastModifiedById": string;
  "LastModifiedDate": Date;
  "LastReferencedDate": Date;
  "LastViewedDate": Date;
  "State_Code__c": string;
  "Worker_Billing_Type__c": string;
  "Job_Rating_Count__c": number;
  "Communication_Rating_Average__c": number;
  "Deliverables_Rating_Average__c": number;
  "On_Time_Rating_Average__c": number;
  "Professionalism_Rating_Average__c": number;
  "Quality_Rating_Average__c": number;
  "Satisfaction_Rating_Average__c": number;
  "Personal_Email_ID__c": string;
  "Worker_City__c": string;
  "Enable_Send_SMS__c": boolean;
  "Primary_Global_Talent_Pool__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  user: Users;
  createdBy: Users;
  vendorsite: Vendorsite;
  jobs: Job[];
  contact: Contact;
  countryCode: CountryCode;
  geoMetro: GeoMetro;
  recordType: RecordType;
  skilling: Skilling;
  projectWorker: ProjectWorker[];
  globalTalentPool: GlobalTalentPool;
  assignment: Assignment[];
  departmentRoles: DepartmentRole[];
  memberRoles: MemberRole[];
  workershift: WorkerShift;
  serviceContract: ServiceContract[];
  workerVendorsite: WorkerVendorsite[];
  constructor(data?: WorkerInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Worker`.
   */
  public static getModelName() {
    return "Worker";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Worker for dynamic purposes.
  **/
  public static factory(data: WorkerInterface): Worker{
    return new Worker(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Worker',
      plural: 'Workers',
      path: 'Workers',
      idName: 'id',
      properties: {
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "Available__c": {
          name: 'Available__c',
          type: 'boolean'
        },
        "Contact__c": {
          name: 'Contact__c',
          type: 'string'
        },
        "Candidate__c": {
          name: 'Candidate__c',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "Dispatch_Worker_num__c": {
          name: 'Dispatch_Worker_num__c',
          type: 'string'
        },
        "Work_Phone_Number__c": {
          name: 'Work_Phone_Number__c',
          type: 'string'
        },
        "Mobile_Phone_Number__c": {
          name: 'Mobile_Phone_Number__c',
          type: 'string'
        },
        "Primary_Worker_Skilling_Profile__c": {
          name: 'Primary_Worker_Skilling_Profile__c',
          type: 'string'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "RecordTypeId": {
          name: 'RecordTypeId',
          type: 'string'
        },
        "Vendorsite__c": {
          name: 'Vendorsite__c',
          type: 'string'
        },
        "Worker_Type__c": {
          name: 'Worker_Type__c',
          type: 'string'
        },
        "WM_Worker_Id__c": {
          name: 'WM_Worker_Id__c',
          type: 'string'
        },
        "WM_Profile_Link__c": {
          name: 'WM_Profile_Link__c',
          type: 'string'
        },
        "WM_Account_Creation_Date__c": {
          name: 'WM_Account_Creation_Date__c',
          type: 'Date'
        },
        "Worker_Rating__c": {
          name: 'Worker_Rating__c',
          type: 'string'
        },
        "GEO_Code__c": {
          name: 'GEO_Code__c',
          type: 'string'
        },
        "Global_Shift_Equivalent_Schedule_GSES__c": {
          name: 'Global_Shift_Equivalent_Schedule_GSES__c',
          type: 'string'
        },
        "Regional_Role__c": {
          name: 'Regional_Role__c',
          type: 'string'
        },
        "Functional_Role__c": {
          name: 'Functional_Role__c',
          type: 'string'
        },
        "Functional_Role_Level__c": {
          name: 'Functional_Role_Level__c',
          type: 'string'
        },
        "Account_Name__c": {
          name: 'Account_Name__c',
          type: 'string'
        },
        "Country_Code__c": {
          name: 'Country_Code__c',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "Email__c": {
          name: 'Email__c',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "LastReferencedDate": {
          name: 'LastReferencedDate',
          type: 'Date'
        },
        "LastViewedDate": {
          name: 'LastViewedDate',
          type: 'Date'
        },
        "State_Code__c": {
          name: 'State_Code__c',
          type: 'string'
        },
        "Worker_Billing_Type__c": {
          name: 'Worker_Billing_Type__c',
          type: 'string'
        },
        "Job_Rating_Count__c": {
          name: 'Job_Rating_Count__c',
          type: 'number'
        },
        "Communication_Rating_Average__c": {
          name: 'Communication_Rating_Average__c',
          type: 'number'
        },
        "Deliverables_Rating_Average__c": {
          name: 'Deliverables_Rating_Average__c',
          type: 'number'
        },
        "On_Time_Rating_Average__c": {
          name: 'On_Time_Rating_Average__c',
          type: 'number'
        },
        "Professionalism_Rating_Average__c": {
          name: 'Professionalism_Rating_Average__c',
          type: 'number'
        },
        "Quality_Rating_Average__c": {
          name: 'Quality_Rating_Average__c',
          type: 'number'
        },
        "Satisfaction_Rating_Average__c": {
          name: 'Satisfaction_Rating_Average__c',
          type: 'number'
        },
        "Personal_Email_ID__c": {
          name: 'Personal_Email_ID__c',
          type: 'string'
        },
        "Worker_City__c": {
          name: 'Worker_City__c',
          type: 'string'
        },
        "Enable_Send_SMS__c": {
          name: 'Enable_Send_SMS__c',
          type: 'boolean'
        },
        "Primary_Global_Talent_Pool__c": {
          name: 'Primary_Global_Talent_Pool__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        user: {
          name: 'user',
          type: 'Users',
          model: 'Users',
          relationType: 'belongsTo',
                  keyFrom: 'Contact__c',
          keyTo: 'sfdcId'
        },
        createdBy: {
          name: 'createdBy',
          type: 'Users',
          model: 'Users',
          relationType: 'belongsTo',
                  keyFrom: 'CreatedById',
          keyTo: 'sfdcId'
        },
        vendorsite: {
          name: 'vendorsite',
          type: 'Vendorsite',
          model: 'Vendorsite',
          relationType: 'belongsTo',
                  keyFrom: 'Vendorsite__c',
          keyTo: 'sfdcId'
        },
        jobs: {
          name: 'jobs',
          type: 'Job[]',
          model: 'Job',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'Dispatch_Worker_Name__c'
        },
        contact: {
          name: 'contact',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Contact__c',
          keyTo: 'sfdcId'
        },
        countryCode: {
          name: 'countryCode',
          type: 'CountryCode',
          model: 'CountryCode',
          relationType: 'belongsTo',
                  keyFrom: 'Country_Code__c',
          keyTo: 'Name'
        },
        geoMetro: {
          name: 'geoMetro',
          type: 'GeoMetro',
          model: 'GeoMetro',
          relationType: 'belongsTo',
                  keyFrom: 'GEO_Code__c',
          keyTo: 'sfdcId'
        },
        recordType: {
          name: 'recordType',
          type: 'RecordType',
          model: 'RecordType',
          relationType: 'belongsTo',
                  keyFrom: 'RecordTypeId',
          keyTo: 'sfdcId'
        },
        skilling: {
          name: 'skilling',
          type: 'Skilling',
          model: 'Skilling',
          relationType: 'belongsTo',
                  keyFrom: 'Primary_Worker_Skilling_Profile__c',
          keyTo: 'sfdcId'
        },
        projectWorker: {
          name: 'projectWorker',
          type: 'ProjectWorker[]',
          model: 'ProjectWorker',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'Worker__c'
        },
        globalTalentPool: {
          name: 'globalTalentPool',
          type: 'GlobalTalentPool',
          model: 'GlobalTalentPool',
          relationType: 'belongsTo',
                  keyFrom: 'Primary_Global_Talent_Pool__c',
          keyTo: 'sfdcId'
        },
        assignment: {
          name: 'assignment',
          type: 'Assignment[]',
          model: 'Assignment',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'Worker__c'
        },
        departmentRoles: {
          name: 'departmentRoles',
          type: 'DepartmentRole[]',
          model: 'DepartmentRole',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'Worker__c'
        },
        memberRoles: {
          name: 'memberRoles',
          type: 'MemberRole[]',
          model: 'MemberRole',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'Member__c'
        },
        workershift: {
          name: 'workershift',
          type: 'WorkerShift',
          model: 'WorkerShift',
          relationType: 'belongsTo',
                  keyFrom: 'Global_Shift_Equivalent_Schedule_GSES__c',
          keyTo: 'sfdcId'
        },
        serviceContract: {
          name: 'serviceContract',
          type: 'ServiceContract[]',
          model: 'ServiceContract',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'Worker__c'
        },
        workerVendorsite: {
          name: 'workerVendorsite',
          type: 'WorkerVendorsite[]',
          model: 'WorkerVendorsite',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'Worker__c'
        },
      }
    }
  }
}
