import { Component, EventEmitter, OnInit, Input, Output, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalService } from '../../../../../services/modal.service';
import { AlertService } from '../../../../../services/alert.service';
import { PreloaderService } from '../../../../../services/preloader.service';
import { ProductApi, JobOrderItemApi } from '../../../../../sdk';
import { element } from 'protractor';


@Component({
    selector: 'app-add-fru-item',
    templateUrl: './add-fru-item.component.html',
    styleUrls: ['./add-fru-item.component.css']
})
export class AddFruItemComponent implements OnInit {
    @Input() partnerAccount: string;
    @Input() workOrderId: string;
    @Input() jobId: string;
    @Input() jobOrderItemId: string;
    @Output() updateList: EventEmitter<any> = new EventEmitter();

    workersTypeahead = new EventEmitter<string>();
    products: any = [];
    jobOrderItemData: any;
    error: any;
    newForm: false;
    formAddFruItem: FormGroup;
    jobData: any;
    setReadOnlyOriginalProduct = true;
    setReadOnlyNewProduct = false;
    sameFRUInfoCheckValue = false;
    hideProductSelection = false;

    constructor(
        private _modalService: ModalService,
        private _preloaderService: PreloaderService,
        private _productApi: ProductApi,
        private _jobOrderItemApi: JobOrderItemApi,
        private _fb: FormBuilder,
        private _alertService: AlertService
    ) { }

    ngOnInit() {
        this.buildForm();
        if (this.partnerAccount && this.workOrderId) {
            this.getProductData(this.partnerAccount);
        } else if (this.workOrderId) {
            this.hideProductSelection = true;
            this.enableOriginalProductForm();
            this.error = 'Partner Account not available';
        } else {
            this.error = 'Work Order is not available. FRU Item can not be Added.';
        }
    }

    enableOriginalProductForm() {
        this.formAddFruItem.controls['originalProductName'].enable();
        this.formAddFruItem.controls['originalIronFruSku'].enable();
        this.formAddFruItem.controls['originalPartnerFruSku'].enable();
        this.formAddFruItem.controls['originalMfgSku'].enable();
        this.formAddFruItem.controls['originalSkuDescription'].enable();
        this.formAddFruItem.controls['originalFruSN'].enable();
        this.setReadOnlyOriginalProduct = false;
    }

    getJobOrderItemDetail(jobOrderItemId) {
        this._jobOrderItemApi.findById(jobOrderItemId, {
            fields: ['id', 'sfdcId', 'Order_Quantity__c', 'Defective_Item_Original_SN__c', 'Defective_Item_New_SN__c',
                'New_MAC_Number__c', 'FRU_Notes_Internal__c', 'IRON_FRU_SKU_Text__c', 'Partner_FRU_SKU_PN_Original_Text__c',
                'Manufacture_MPN_Text__c', 'FRU_Description_Text__c', 'IRON_FRU_SKU_New__c', 'Partner_FRU_Sku_New__c',
                'Iron_Product_Type__c', 'Job_Order__c', 'Job__c', 'Partner_Account__c', 'SKU__c', 'Manufacture_MPN_Text_New__c',
                'FRU_Description_New_Text__c']
        }).subscribe(data => {
            if (data) {
                this.jobOrderItemData = data;
                this.setFormValues();
            }
        },
            err => {
                console.log(err);
                this.error = err;
            });
    }

    getProductData(partnerAccount) {
        this._productApi.find({
            where: { 'Partner_Account__c': partnerAccount },
            fields: ['sfdcId', 'Name', 'Description', 'Partner_FRU_Sku__c', 'Supplier_SKU__c', 'Manufacturer_Part_Description__c']
        }).subscribe(data => {
            if (data) {
                this.products = data;
                if (this.jobOrderItemId) {

                    this.getJobOrderItemDetail(this.jobOrderItemId);
                } else {
                    this.enableOriginalProductForm();
                }
            }
        }, err => {
            console.log(err);
            this.error = err;
        });
    }

    buildForm() {
        this.formAddFruItem = this._fb.group({
            originalProductName: [{ value: null, disabled: true }],
            originalIronFruSku: [{ value: null, disabled: true }],
            originalPartnerFruSku: [{ value: null, disabled: true }],
            originalMfgSku: [{ value: null, disabled: true }],
            originalSkuDescription: [{ value: null, disabled: true }],
            originalFruSN: [null],

            sameFRUInfoCheck: [{ value: null, disabled: false }],

            newProductName: [null],
            newIronFruSku: [{ value: null, disabled: false }],
            newPartnerFruSku: [{ value: null, disabled: false }, Validators.required],
            newMfgSku: [{ value: null, disabled: false }],
            newSkuDescription: [{ value: null, disabled: false }],
            newFruSN: [null],
            newFruMAC: [null],
            fruNotesInternal: [null]
        });
    }

    saveFruItem() {
        if (this.formAddFruItem.valid && this.formAddFruItem.value) {
            this.error = '';
            const data = this.formAddFruItem.value;

            const jobOrderItem = {
                IRON_FRU_SKU_Text__c: data.originalIronFruSku,
                Partner_FRU_SKU_PN_Original_Text__c: data.originalPartnerFruSku,
                Manufacture_MPN_Text__c: data.originalMfgSku,
                FRU_Description_Text__c: data.originalSkuDescription,
                Defective_Item_Original_SN__c: data.originalFruSN,

                IRON_FRU_SKU_New__c: data.newIronFruSku,
                Partner_FRU_Sku_New__c: data.newPartnerFruSku,

                Manufacture_MPN_Text_New__c: data.newMfgSku,
                FRU_Description_New_Text__c: data.newSkuDescription,
                Defective_Item_New_SN__c: data.newFruSN,
                New_MAC_Number__c: data.newFruMAC,
                FRU_Notes_Internal__c: data.fruNotesInternal,
                Job_Order__c: this.workOrderId,
                Order_Quantity__c: 1,
                Iron_Product_Type__c: 'Field Services (Non Dispatch Parts/3rd Party FRU'
            };
            this._preloaderService.showPreloader();
            if (this.jobOrderItemId) {
                this._jobOrderItemApi.patchAttributes(this.jobOrderItemId, jobOrderItem).subscribe(
                    result => {
                        this._preloaderService.hidePreloader();
                        this._alertService.success('FRU Item Updated');
                        this.updateList.emit();
                        this._modalService.closed();
                    },
                    err => {
                        this._preloaderService.hidePreloader();
                        this._alertService.error(err.message);
                        this._modalService.closed();
                    }
                );
            } else {
                Object.assign(jobOrderItem, { Job_Order__c: this.workOrderId })
                //Job_Order__c":"0WO1a000000GoeDGAS" add job order c for new cases
                this._jobOrderItemApi.upsert(jobOrderItem).subscribe(
                    result => {
                        this._preloaderService.hidePreloader();
                        this._alertService.success('New FRU Item has been created.');
                        this.updateList.emit();
                        this._modalService.closed();
                    },
                    err => {
                        this._preloaderService.hidePreloader();
                        this._alertService.error(err.message);
                        this._modalService.closed();
                    }
                );
            }
        } else {
            this.error = 'Please fill complete form.';
            return;
        }
    }

    setNewItemDetail(data) {
        if (data && data.sfdcId) {
            this.products.filter(item => {
                if (item.sfdcId === data.sfdcId) {
                    if (this.formAddFruItem.value.sameFRUInfoCheck) {
                        this.formAddFruItem.controls['newProductName'].disable();
                    }
                    this.formAddFruItem.controls['newIronFruSku'].setValue(item['Name']);
                    this.formAddFruItem.controls['newPartnerFruSku'].setValue(item['Partner_FRU_Sku__c']);
                    this.formAddFruItem.controls['newMfgSku'].setValue(item['Supplier_SKU__c']);
                    this.formAddFruItem.controls['newSkuDescription'].setValue(item['Description']);
                    this.formAddFruItem.controls['newProductName'].setValue(item['sfdcId']);
                    this.setReadOnlyNewProduct = true;
                }
            });
        } else {
            this.setReadOnlyNewProduct = false;
        }
    }

    setOriginalItemDetail(data) {
        if (data && data.sfdcId) {
            this.products.filter(item => {
                if (item.sfdcId === data.sfdcId) {
                    this.formAddFruItem.controls['originalIronFruSku'].setValue(item['Name']);
                    this.formAddFruItem.controls['originalPartnerFruSku'].setValue(item['Partner_FRU_Sku__c']);
                    this.formAddFruItem.controls['originalMfgSku'].setValue(item['Supplier_SKU__c']);
                    this.formAddFruItem.controls['originalSkuDescription'].setValue(item['Description']);
                    this.formAddFruItem.controls['originalProductName'].setValue(item['sfdcId']);
                    this.setReadOnlyOriginalProduct = true;
                }
            });
        } else {
            this.setReadOnlyOriginalProduct = false;
        }
        this.copyOriginalFRU();
    }

    setFormValues() {
        if (this.jobOrderItemData) {
            const data = this.jobOrderItemData;
            // Enabling original FRU item edit if IRON FRU SKU is generic

            if (data.IRON_FRU_SKU_Text__c === '01t2L000001MSKY') {
                this.enableOriginalProductForm();
                this.setReadOnlyOriginalProduct = false;
            }

            this.setReadOnlyNewProduct = false;
            this.formAddFruItem.controls['newProductName'].enable();
            this.formAddFruItem.controls['newProductName'].setValue(null);

            this.formAddFruItem.controls['originalIronFruSku'].setValue(data.IRON_FRU_SKU_Text__c);
            this.formAddFruItem.controls['originalPartnerFruSku'].setValue(data.Partner_FRU_SKU_PN_Original_Text__c);
            this.formAddFruItem.controls['originalMfgSku'].setValue(data.Manufacture_MPN_Text__c);
            this.formAddFruItem.controls['originalSkuDescription'].setValue(data.FRU_Description_Text__c);
            this.formAddFruItem.controls['originalFruSN'].setValue(data.Defective_Item_Original_SN__c);

            this.formAddFruItem.controls['sameFRUInfoCheck'].setValue(this.sameFRUInfoCheckValue);

            this.formAddFruItem.controls['newIronFruSku'].setValue(data.IRON_FRU_SKU_New__c);
            this.formAddFruItem.controls['newPartnerFruSku'].setValue(data.Partner_FRU_Sku_New__c);
            this.formAddFruItem.controls['newMfgSku'].setValue(data.Manufacture_MPN_Text_New__c);
            this.formAddFruItem.controls['newSkuDescription'].setValue(data.FRU_Description_New_Text__c);
            this.formAddFruItem.controls['newFruSN'].setValue(data.Defective_Item_New_SN__c);
            this.formAddFruItem.controls['newFruMAC'].setValue(data.New_MAC_Number__c);
            this.formAddFruItem.controls['fruNotesInternal'].setValue(data.FRU_Notes_Internal__c);
        }
    }

    resetFRUForm() {
        this.sameFRUInfoCheckValue = false;
        this.formAddFruItem.reset();
        this.setFormValues();
        this.setOriginalItemDetail(null);
    }

    close() {
        this._modalService.closed();
    }

    serachFruItemFn(term: string, item: any) {
        term = term.toLocaleLowerCase();
        let returnValue = false;
        if (item && item.Name && item.Name.toLocaleLowerCase().indexOf(term) > -1) {
            returnValue = true;
        }
        if (item && item.Partner_FRU_Sku__c && item.Partner_FRU_Sku__c.toLocaleLowerCase().indexOf(term) > -1) {
            returnValue = true;
        }
        if (item && item.Supplier_SKU__c && item.Supplier_SKU__c.toLocaleLowerCase().indexOf(term) > -1) {
            returnValue = true;
        }
        if (item && item.Description && item.Description.toLocaleLowerCase().indexOf(term) > -1) {
            returnValue = true;
        }
        return returnValue;
    }

    copyOriginalFRU() {
        const data = this.formAddFruItem.value;
        if (data.sameFRUInfoCheck) {
            if (this.jobOrderItemData && this.jobOrderItemData.IRON_FRU_SKU_Text__c !== '01t2L000001MSKY') {
                this.formAddFruItem.controls['newIronFruSku'].setValue(this.jobOrderItemData.IRON_FRU_SKU_Text__c);
                this.formAddFruItem.controls['newPartnerFruSku'].setValue(this.jobOrderItemData.Partner_FRU_SKU_PN_Original_Text__c);
                this.formAddFruItem.controls['newMfgSku'].setValue(this.jobOrderItemData.Manufacture_MPN_Text__c);
                this.formAddFruItem.controls['newSkuDescription'].setValue(this.jobOrderItemData.FRU_Description_Text__c);

            } else {
                this.formAddFruItem.controls['newIronFruSku'].setValue(data['originalIronFruSku']);
                this.formAddFruItem.controls['newPartnerFruSku'].setValue(data['originalPartnerFruSku']);
                this.formAddFruItem.controls['newMfgSku'].setValue(data['originalMfgSku']);
                this.formAddFruItem.controls['newSkuDescription'].setValue(data['originalSkuDescription']);
                this.formAddFruItem.controls['newProductName'].setValue(data['originalProductName']);
            }
            this.setReadOnlyNewProduct = true;
            this.formAddFruItem.controls['newProductName'].disable();
        } else {
            this.setReadOnlyNewProduct = false;
            this.formAddFruItem.controls['newProductName'].enable();
            this.setNewItemDetail(data.sameFRUInfoCheck);
        }
    }
}
