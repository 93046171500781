import { SpecificGlobalChatComponent } from './job-message-console/specific-global-chat/specific-global-chat.component';


import { Routes, RouterModule } from '@angular/router';
import { GlobalChatComponent } from './global-chat.component';
import { JobMessageConsoleComponent } from './job-message-console/job-message-console.component';

const routes: Routes = [
  {
    path: '',
    component: GlobalChatComponent,
    children: [
      {
        path: '',
        component: JobMessageConsoleComponent
      },
      {
        path: 'team/:name',
        component: SpecificGlobalChatComponent
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'page-not-found'
  }
];

export const GlobalChatRoutes = RouterModule.forChild(routes);
