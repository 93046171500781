import { StateManagerLookupModule } from './state-manager-lookup/state-manager-lookup.module';
import { WorkOrderCaseIdLookupModule } from './work-order-case-id-lookup/work-order-case-id-lookup.module';
import { CustomerLookupModule } from './customer-lookup/customer-lookup.module';
import { PaymentTypeLookupModule } from './payment-type-lookup/payment-type-lookup.module';
import { DepartmentLookupModule } from './department-lookup/department-lookup.module';
import { PgmoDepartmentLookupModule } from './pgmo-department-lookup/pgmo-department-lookup.module';

import { DivisionLookupModule } from './division-lookup/division-lookup.module';
import { WorkerBillingTypeLookupModule } from './worker-billing-type-lookup/worker-billing-type-lookup.module';
import { ActualEndTimeCoverageLookupModule } from './actual-end-time-coverage-lookup/actual-end-time-coverage-lookup.module';
import { ActualCoverageOverallLookupModule } from './actual-coverage-overall-lookup/actual-coverage-overall-lookup.module';
import { ProjectTypeLookupModule } from './project-type-lookup/project-type-lookup.module';
import { ProjectLookupModule } from './project-lookup/project-lookup.module';
import { OrderTypeLookupModule } from './order-type-lookup/order-type-lookup.module';
import { CountryLookupModule } from './country-lookup/country-lookup.module';
import { RegionLookupModule } from './region-lookup/region-lookup.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { AccountLookupModule } from './account-lookup/account-lookup.module';
import { VendorAccountLookupModule } from './vendor-account/vendor-account-lookup.module';
import { ProgramLookupModule } from './program-lookup/program-lookup.module';
import { JobsiteLookupModule } from './jobsite-lookup/jobsite-lookup.module';
import { JobLookupModule } from './job-lookup/job-lookup.module';
import { StageStatusLookupModule } from './stage-status-lookup/stage-status-lookup.module';
import { CaseCategoryLookupModule } from './case-category-lookup/case-category-lookup.module';
import { JobTypeLookupModule } from './job-type-lookup/job-type-lookup.module';
import { PriorityLookupModule } from './priority-lookup/priority-lookup.module';
import { GeoMetroLookupModule } from './geo-metro-lookup/geo-metro-lookup.module';
import { VendorsiteLoopupModule } from './vendorsite-loopup/vendorsite-loopup.module';
import { TeamShiftLookupModule } from './team-shift-lookup/team-shift-lookup.module';
import { PMSCaseStatusLookupModule } from './pms-case-status-lookup/pms-case-status-lookup.module';
import { DateRangeLookupModule } from './date-range-lookup/date-range-lookup.module';
import { TalentTypeLookupModule } from './talent-type-lookup/talent-type-lookup.module';
import { WorkerLookupModule } from './worker-lookup/worker-lookup.module';
import { PmsCaseNumLookupModule } from './pms-case-num-lookup/pms-case-num-lookup.module';
import { JobStatusLookupModule } from './job-status-lookup/job-status-lookup.module';
import { CaseTypeLookupModule } from './case-type-lookup/case-type-lookup.module';
import { PmcCaseNumLookupModule } from './pmc-case-num-lookup/pmc-case-num-lookup.module';
import { TaskLookupModule } from './task-lookup/task-lookup.module';
import { MilestoneLookupModule } from './milestone-lookup/milestone-lookup.module';
import { EscalationTypeLookupModule } from './escalation-type-lookup/escalation-type-lookup.module';
import { DepartmentRoleLookupModule } from './department-role-lookup/department-role-lookup.module';
import { AngularDualListBoxModule } from 'angular-dual-listbox';
import { TaskAccountLookupModule } from './task-account-lookup/task-account-lookup.module';
import { TaskTypeLookupModule } from './task-type-lookup/task-type-lookup.module';
import { TaskRequestorLookupModule } from './task-requestor-lookup/task-requestor-lookup.module';

import { TaskPriorityLookupModule } from './task-priority-lookup/task-priority-lookup.module';
import { TaskStatusLookupModule } from './task-status-lookup/task-status-lookup.module';
import { IronApprovalStatusLookupModule } from './iron-approval-status-lookup/iron-approval-status-lookup.module';
import { CustomerApprovalStatusLookupModule } from './customer-approval-status-lookup/customer-approval-status-lookup.module';
import { ActualCoverageHourLookupModule } from './actual-coverage-hour-lookup/actual-coverage-hour-lookup.module';
import { EmployeeTypeLookupModule } from './employee-type-lookup/employee-type-lookup.module';
import { UsersListLookupModule } from './users-list-lookup/users-list-lookup.module';
import { ExpenseTypeLookupModule } from './expense-type-lookup/expense-type-lookup.module';
import { ExpenseSubTypeLookupModule } from './expense-sub-type-lookup/expense-sub-type-lookup.module';
import { ContractCategoryLookupModule } from './contract-category-lookup/contract-category-lookup.module';
import { ServiceContractNumLookupModule } from './service-contract-num-lookup/service-contract-num-lookup.module';
import { SalesInvoiceStatusLookupModule } from './sales-invoice-status-lookup/sales-invoice-status-lookup.module';
import { PurchaseInvoiceStatusLookupModule } from './purchase-invoice-status-lookup/purchase-invoice-status-lookup.module';
import { Project2LookupModule } from './project2-lookup/project2-lookup.module';
import { AccountCommunityLookupModule } from './account-community-lookup/account-community-lookup.module';
import { StateLookupModule } from './state-lookup/state-lookup.module';
import { DefaultSkuLookupModule } from './default-sku-lookup/default-sku-lookup.module';
import { UserLookupModule } from './user-lookup/user-lookup.module';
import { TeamLookupModule } from './team-lookup/team-lookup.module';
import { DateLookupModule } from './date-lookup/date-lookup.module';
import { ActivityTypeLookupModule } from './activity-type-lookup/activity-type-lookup.module';
import { ServiceContractCategoryLookupModule } from './service-contract-category-lookup/service-contract-category-lookup.module';
import { StaticDataLookupModule } from './static-data-lookup/static-data-lookup.module';
import { CustomerRequestedLookupModule } from './customer-requested-lookup/customer-requested-lookup.module';
import { TeamTypeLookupModule } from './team-type-lookup/team-type-lookup.module';
import { DepartmentRoleAccessLookupModule } from './department-role-access-lookup/department-role-access-lookup.module';
import { SubCategoryLookupModule } from './sub-category-lookup/sub-category-lookup.module';
import { GroupRecordTypeLookupModule } from './group-record-type-lookup/group-record-type-lookup.module';
import { ApproverLookupModule } from './approver-lookup/approver-lookup.module';
import { WorkerTypeLookupModule } from './worker-type-lookup/worker-type-lookup.module';
import { WorkerTypeSubLookupModule } from './worker-type-sub-lookup/worker-type-sub-lookup.module';

import { IsBillableLookupModule } from './is-billable-lookup/is-billable-lookup.module';
import { CategoryModule } from './category/category.module';
import { ServiceTechnicalLevelLookupModule } from './service-technical-level-lookup/service-technical-level-lookup.module';
import { TaskLibraryLookupModule } from './task-library-lookup/task-library-lookup.module';
import { TemplateCategoryLookupModule } from './template-category-lookup/template-category-lookup.module';
import { TemplateSubCategoryLookupModule } from './template-sub-category-lookup/template-sub-category-lookup.module';
import { TemplateNameLookupModule } from './template-name-lookup/template-name-lookup.module';
import { TemplateLibraryTypeLookupModule } from './template-library-type-lookup/template-library-type-lookup.module';
import { MilestoneLibraryLookupModule } from './milestone-library-lookup/milestone-library-lookup.module';
import { PurchaseOrderLookupModule } from './purchase-order-lookup/purchase-order-lookup.module';
import { ApvpGroupNumberLookupModule } from './apvp-group-number-lookup/apvp-group-number-lookup.module';
import { CommunityTypeLookupModule } from './community-type-lookup/community-type-lookup.module';
import { SlaGroupNameLookupModule } from './sla-group-name-lookup/sla-group-name-lookup.module';
import { WslAccountStatusLookupModule } from './wsl-account-status-lookup/wsl-account-status-lookup.module';
import { WslAccountLookupModule } from './wsl-account-lookup/wsl-account-lookup.module';
import { AddressSearchLookupModule } from './address-search-lookup/address-search-lookup.module';
import { WorkerStatusLookupModule } from './worker-status-lookup/worker-status-lookup.module';
import { RadiusLookupModule } from './radius-lookup/radius-lookup.module';
import { RangeSliderLookupModule } from './range-slider-lookup/range-slider-lookup.module';
import { StaticListLookupModule } from './static-list-lookup/static-list-lookup.module';
import { ServiceTerritoryLookupComponent } from './service-territory-lookup/service-territory-lookup.component';
import { WorkerScheduledLookupModule } from './worker-scheduled-lookup/worker-scheduled-lookup.module';
import { DateClosedLookupModule } from './date-closed-lookup/date-closed-lookup.module';
import { JobOrderLookupModule } from './job-order-lookup/job-order-lookup.module';
import { TaskStatusStaticLookupModule } from './app-task-status-static-lookup/app-task-status-static-lookup.module';
import { TaxonomiesLookupModule } from './taxonomies-lookup/taxonomies-lookup.module';
import { TaxonomiesSkillLookupModule } from './taxonomies-skill-lookup/taxonomies-skill-lookup.module';
import { JobApplicationLookupModule } from './job-application-lookup/job-application-lookup.module';
import { UserTypeLookupModule } from './user-type-lookup/user-type-lookup.module';
import { PageNameLookupModule } from './page-name-lookup/page-name-lookup.module';
import { ServiceTechnicalTalentTypeLookupModule } from './service-technical-talent-type-lookup/service-technical-talent-type-lookup.module';
import { IsTestUserLookupModule } from './is-TestUser-lookup/is-TestUser-lookup.module';
import { MinMaxLookupModule } from './min-max-lookup/min-max-lookup.module';
import { MemberLookupModule } from './member-lookup/member.module';
import { LocationLookupModule } from './location-lookup/location-lookup.module';
import { ProjectCategoryLookupModule } from './project-category-lookup/project-category-lookup.module';
import { IsActiveUserLookupModule } from './is-ActiveUser-lookup/is-ActiveUser-lookup.module';
import { UniqueUserLookupModule } from './unique-user-lookup/unique-user-lookup.module';
import { CityLookupModule } from './city-lookup/city-lookup.module';
import { JobOrderTitleLookupModule } from './job-order-title-lookup/job-order-title-lookup.module';
import { UsersLookUpModule } from './users-look-up/users-look-up.module';
import { ContactAccountLookupModule } from './contact-account-lookup/contact-account-lookup.module';
import { DocumentCategoryLookupModule } from './document-category-lookup/document-category-lookup.module';
import { PageViewGroupLookupModule } from './page-view-group-lookup/page-view-group-lookup.module'
import { ServiceoLoginLookupModule } from './serviceo-login-lookup/serviceo-login-lookup.module';
import { PeriodLookupModule } from './period-lookup/period-lookup.module';
import { ObjectiveLookupModule } from './objective-lookup/objective-lookup.module';
import { KeyResultLookupModule } from './key-result-lookup/key-result-lookup.module';
import { InitiativeLookupModule } from './initiative-lookup/initiative-lookup.module';
import { YearLookupModule } from './year-lookup/year-lookup.module';
import { ExcludeMagiclinkLookupModule } from './exclude-magiclink-lookup/exclude-magiclink-lookup.module';
import { FileNameLookupModule } from './file-name-lookup/file-name-lookup.module';
import { JobSfdcidLookupComponent } from './job-sfdcid-lookup/job-sfdcid-lookup.component';
import { IncludeTestAccountLookupModule } from './include-test-account-lookup/include-test-account-lookup.module';
import { MilestoneTaskLookupModule } from './milestone-task-lookup/milestone-task-lookup.module';
import { FormNameLookupModule } from './form-name-lookup/form-name-lookup.module';
import { WorkOrderRangeLookupModule } from './work-order-range-lookup/work-order-range-lookup.module';
import { ContactLookupModule } from './contact-lookup/contact-lookup.module';
import { BusinessUnitLookupModule } from './business-unit-lookup/business-unit-lookup.module';
import { RecruitmentContactLookupModule } from './recruitment-contact-lookup/recruitment-contact-lookup.module';
import { PcUserCountLookupModule } from './pc-user-count-lookup/pc-user-count-lookup.module';
import { ReleaseNotesVersionLookupModule } from './release-notes-version-lookup/release-notes-version-lookup.module';
import { LeaveStatusTypeLookupModule } from './leave-status-type-lookup/leave-status-type-lookup.module';
import { TimeChangeRequestLookupModule } from './time-change-request-lookup/time-change-request-lookup.module';
import { PageTypeLookupModule } from './page-type-lookup/page-type-lookup.module';
import { WeeklyDateRangeLookupModule } from './weekly-date-range-lookup/weekly-date-range-lookup.module';
import { ConversationSourceLookupModule } from './conversation-source-lookup/conversation-source-lookup.module';
import { ParentLocationLookupModule } from './parent-location-lookup/parent-location-lookup.module';
import { DefaultSKUProjectProfileLookupModule } from './default-sku-project-profile-lookup/default-sku-project-profile-lookup.module';
import { WorkerEmailLookupModule } from './worker-email-lookup/worker-email-lookup.module';
import { CustomerRequestRangeLookupModule } from './customer-request-range-lookup/customer-request-range-lookup.module';
import { SmsTypeLookupModule } from './sms-type-lookup/sms-type-lookup.module';
import { SmsStatusLookupModule } from './sms-status-lookup/sms-status-lookup.module';
import { MessageSentLookupModule } from './message-sent-lookup/message-sent-lookup.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    AccountLookupModule,
    VendorAccountLookupModule,
    ProgramLookupModule,
    JobsiteLookupModule,
    StageStatusLookupModule,
    CaseCategoryLookupModule,
    JobTypeLookupModule,
    PriorityLookupModule,
    JobLookupModule,
    StageStatusLookupModule,
    GeoMetroLookupModule,
    VendorsiteLoopupModule,
    TeamShiftLookupModule,
    PMSCaseStatusLookupModule,
    DateRangeLookupModule,
    TalentTypeLookupModule,
    WorkerLookupModule,
    PmsCaseNumLookupModule,
    JobStatusLookupModule,
    RegionLookupModule,
    CountryLookupModule,
    CaseTypeLookupModule,
    PmcCaseNumLookupModule,
    TaskLookupModule,
    MilestoneLookupModule,
    ProjectLookupModule,
    ProjectTypeLookupModule,
    EscalationTypeLookupModule,
    AngularDualListBoxModule,
    DepartmentRoleLookupModule,
    DepartmentRoleAccessLookupModule,
    TaskAccountLookupModule,
    TaskTypeLookupModule,
    TaskRequestorLookupModule,
    TaskPriorityLookupModule,
    TaskStatusLookupModule,
    IronApprovalStatusLookupModule,
    CustomerApprovalStatusLookupModule,
    ActualCoverageHourLookupModule,
    EmployeeTypeLookupModule,
    ActualCoverageOverallLookupModule,
    ActualEndTimeCoverageLookupModule,
    WorkerBillingTypeLookupModule,
    UsersListLookupModule,
    ExpenseTypeLookupModule,
    ExpenseSubTypeLookupModule,
    ContractCategoryLookupModule,
    ServiceContractNumLookupModule,
    DivisionLookupModule,
    DepartmentLookupModule,
    PgmoDepartmentLookupModule,
    PaymentTypeLookupModule,
    Project2LookupModule,
    AccountCommunityLookupModule,
    StateLookupModule,
    DefaultSkuLookupModule,
    ActivityTypeLookupModule,
    CustomerRequestedLookupModule,
    UserLookupModule,
    TeamLookupModule,
    TeamTypeLookupModule,
    DateLookupModule,
    ServiceContractCategoryLookupModule,
    StaticDataLookupModule,
    CustomerLookupModule,
    SubCategoryLookupModule,
    GroupRecordTypeLookupModule,
    ApproverLookupModule,
    MilestoneLibraryLookupModule,
    TemplateLibraryTypeLookupModule,
    WorkOrderCaseIdLookupModule,
    WorkerTypeLookupModule,
    WorkerTypeSubLookupModule,
    CategoryModule,
    IsBillableLookupModule,
    ServiceTechnicalLevelLookupModule,
    TaskLibraryLookupModule,
    TemplateCategoryLookupModule,
    TemplateSubCategoryLookupModule,
    TemplateNameLookupModule,
    PurchaseOrderLookupModule,
    ApvpGroupNumberLookupModule,
    CommunityTypeLookupModule,
    SlaGroupNameLookupModule,
    WslAccountStatusLookupModule,
    WslAccountLookupModule,
    AddressSearchLookupModule,
    WorkerStatusLookupModule,
    RadiusLookupModule,
    StaticListLookupModule,
    WorkerScheduledLookupModule,
    DateClosedLookupModule,
    StateManagerLookupModule,
    JobOrderLookupModule,
    TaskStatusStaticLookupModule,
    JobApplicationLookupModule,
    UserTypeLookupModule,
    PageNameLookupModule,
    ServiceTechnicalTalentTypeLookupModule,
    IsTestUserLookupModule,
    IsActiveUserLookupModule,
    MinMaxLookupModule,
    MemberLookupModule,
    LocationLookupModule,
    ProjectCategoryLookupModule,
    UniqueUserLookupModule,
    UsersLookUpModule,
    ContactAccountLookupModule,
    DocumentCategoryLookupModule,
    PageViewGroupLookupModule,
    ServiceoLoginLookupModule,
    PeriodLookupModule,
    ObjectiveLookupModule,
    KeyResultLookupModule,
    InitiativeLookupModule,
    YearLookupModule,
    ExcludeMagiclinkLookupModule,
    FileNameLookupModule,
    IncludeTestAccountLookupModule,
    MilestoneTaskLookupModule,
    FormNameLookupModule,
    WorkOrderRangeLookupModule,
    ContactLookupModule,
    BusinessUnitLookupModule,
    RecruitmentContactLookupModule,
    PcUserCountLookupModule,
    ReleaseNotesVersionLookupModule,
    LeaveStatusTypeLookupModule,
    TimeChangeRequestLookupModule,
    PageTypeLookupModule,
    WeeklyDateRangeLookupModule,
    ConversationSourceLookupModule,
    DefaultSKUProjectProfileLookupModule,
    ParentLocationLookupModule,
    CustomerRequestRangeLookupModule,
    SmsTypeLookupModule,
    SmsStatusLookupModule,
    MessageSentLookupModule
  ],
  exports: [
    AccountLookupModule,
    VendorAccountLookupModule,
    ProgramLookupModule,
    JobsiteLookupModule,
    StageStatusLookupModule,
    CaseCategoryLookupModule,
    JobTypeLookupModule,
    PriorityLookupModule,
    JobLookupModule,
    StageStatusLookupModule,
    GeoMetroLookupModule,
    VendorsiteLoopupModule,
    TeamShiftLookupModule,
    PMSCaseStatusLookupModule,
    DateRangeLookupModule,
    TalentTypeLookupModule,
    WorkerLookupModule,
    PmsCaseNumLookupModule,
    JobStatusLookupModule,
    RegionLookupModule,
    CountryLookupModule,
    OrderTypeLookupModule,
    CaseTypeLookupModule,
    PmcCaseNumLookupModule,
    TaskLookupModule,
    MilestoneLookupModule,
    ProjectLookupModule,
    ProjectTypeLookupModule,
    EscalationTypeLookupModule,
    AngularDualListBoxModule,
    DepartmentRoleLookupModule,
    DepartmentRoleAccessLookupModule,
    TaskAccountLookupModule,
    TaskTypeLookupModule,
    TaskRequestorLookupModule,
    TaskPriorityLookupModule,
    TaskStatusLookupModule,
    IronApprovalStatusLookupModule,
    CustomerApprovalStatusLookupModule,
    ActualCoverageHourLookupModule,
    EmployeeTypeLookupModule,
    ActualCoverageOverallLookupModule,
    ActualEndTimeCoverageLookupModule,
    WorkerBillingTypeLookupModule,
    UsersListLookupModule,
    ExpenseTypeLookupModule,
    ExpenseSubTypeLookupModule,
    ContractCategoryLookupModule,
    ServiceContractNumLookupModule,
    SalesInvoiceStatusLookupModule,
    PurchaseInvoiceStatusLookupModule,
    DivisionLookupModule,
    DepartmentLookupModule,
    PgmoDepartmentLookupModule,
    PaymentTypeLookupModule,
    Project2LookupModule,
    AccountCommunityLookupModule,
    StateLookupModule,
    DefaultSkuLookupModule,
    ActivityTypeLookupModule,
    CustomerRequestedLookupModule,
    UserLookupModule,
    TeamLookupModule,
    TeamTypeLookupModule,
    DateLookupModule,
    ServiceContractCategoryLookupModule,
    StaticDataLookupModule,
    CustomerLookupModule,
    SubCategoryLookupModule,
    GroupRecordTypeLookupModule,
    ApproverLookupModule,
    MilestoneLibraryLookupModule,
    TemplateLibraryTypeLookupModule,
    WorkOrderCaseIdLookupModule,
    WorkerTypeLookupModule,
    WorkerTypeSubLookupModule,
    CategoryModule,
    IsBillableLookupModule,
    ServiceTechnicalLevelLookupModule,
    TaskLibraryLookupModule,
    TemplateCategoryLookupModule,
    TemplateSubCategoryLookupModule,
    TemplateNameLookupModule,
    PurchaseOrderLookupModule,
    ApvpGroupNumberLookupModule,
    CommunityTypeLookupModule,
    SlaGroupNameLookupModule,
    WslAccountStatusLookupModule,
    WslAccountLookupModule,
    AddressSearchLookupModule,
    WorkerStatusLookupModule,
    RadiusLookupModule,
    RangeSliderLookupModule,
    StaticListLookupModule,
    ServiceTerritoryLookupComponent,
    WorkerScheduledLookupModule,
    DateClosedLookupModule,
    StateManagerLookupModule,
    JobOrderLookupModule,
    TaskStatusStaticLookupModule,
    TaxonomiesLookupModule,
    TaxonomiesSkillLookupModule,
    JobApplicationLookupModule,
    UserTypeLookupModule,
    PageNameLookupModule,
    ServiceTechnicalTalentTypeLookupModule,
    IsTestUserLookupModule,
    IsActiveUserLookupModule,
    MinMaxLookupModule,
    MemberLookupModule,
    LocationLookupModule,
    ProjectCategoryLookupModule,
    UniqueUserLookupModule,
    CityLookupModule,
    JobOrderTitleLookupModule,
    UsersLookUpModule,
    ContactAccountLookupModule,
    DocumentCategoryLookupModule,
    PageViewGroupLookupModule,
    ServiceoLoginLookupModule,
    PeriodLookupModule,
    ObjectiveLookupModule,
    KeyResultLookupModule,
    InitiativeLookupModule,
    YearLookupModule,
    ExcludeMagiclinkLookupModule,
    FileNameLookupModule,
    IncludeTestAccountLookupModule,
    JobSfdcidLookupComponent,
    MilestoneTaskLookupModule,
    FormNameLookupModule,
    WorkOrderRangeLookupModule,
    ContactLookupModule,
    BusinessUnitLookupModule,
    RecruitmentContactLookupModule,
    PcUserCountLookupModule,
    ReleaseNotesVersionLookupModule,
    LeaveStatusTypeLookupModule,
    TimeChangeRequestLookupModule,
    PageTypeLookupModule,
    WeeklyDateRangeLookupModule,
    ConversationSourceLookupModule,
    WorkerEmailLookupModule,
    CustomerRequestRangeLookupModule,
    SmsTypeLookupModule,
    SmsStatusLookupModule,
    MessageSentLookupModule
  ],
  providers: [],
  declarations: [ServiceTerritoryLookupComponent, JobSfdcidLookupComponent]
})
export class LookupModule { }
