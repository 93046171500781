import { AppStateService } from 'shared/services/app-state.service';

export class BaseVendorViewComponent {

  vendorAccessTypes: any;
  viewAsVendor = false;

  constructor(
    private _appService: AppStateService,
  ) { 
    this._appService.getViewAsVendorObject().subscribe((res: any) => {
      if(res && res['accessType']) {
          this.vendorAccessTypes = res;
          this.viewAsVendor = true;
      } else {
        this.viewAsVendor = false;
        this.vendorAccessTypes = null;
      }
    })
  }

}
