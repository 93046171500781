import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TimecardApi, JobApi, DocumentApi } from 'shared/sdk';
import { PreloaderService } from 'shared/services/preloader.service';
import { AlertService } from 'shared/services/alert.service';
import { CommonService } from 'shared/services/common.service';
import { GlobalFilterService } from 'shared/services/global-filter.service';
import { AppStateService } from 'shared/services/app-state.service';
import { ModalService } from 'shared/services/modal.service';
import { BehaviorSubject, Subject, forkJoin } from 'rxjs';
import { MobileOS } from '../../models/static-list-data.service'
import * as moment from 'moment';


@Component({
  selector: 'app-add-new-timesheet',
  templateUrl: './timesheet-add-new.component.html',
  styleUrls: ['./timesheet-add-new.component.css']
})
export class TimesheetAddNewComponent implements OnInit {

  mobileOs = [...MobileOS];
  fromcheckInOut = false;
  fromBillingManager = false;
  @Input() timecardId: any;
  @Input() timeSheetDetails: any;
  @Output() apiCallEvent: EventEmitter<any> = new EventEmitter<boolean>();
  @Output() refreshTimeSheetList: EventEmitter<any> = new EventEmitter();
  activeTabs = ['timesheetcardInformation', 'approvalInformation', 'timeInformation', 'taskInformation', 'Files'];
  selectedTimecard: any;
  maxDate = new Date();
  timeCardData: any = {};
  invoiceData = [];
  ironApprovalStatus = ['In-Progress', 'Submitted', 'Approved', 'Rejected', 'Cancelled', 'Not Submitted']
  customerApprovalStatus = ['Not Submitted', 'Submitted', 'Approved', 'Rejected'];
  actualCoverageHrs = ['9H5D', 'AFTH', 'HLDY', 'WKND'];
  actualEndTimeCoverage = ['9H5D', 'AFTH', 'HLDY', 'WKND'];

  timeInformationData = [
    {
      'Name': 'Monday',
      'actualHours': '',
      'scheduledHours': '',
      'scheduledInHoliday': false,
      'publicHoliday': false,
    },
    {
      'Name': 'Tuesday',
      'actualHours': '',
      'scheduledHours': '',
      'scheduledInHoliday': false,
      'publicHoliday': false,
    },
    {
      'Name': 'Wednesday',
      'actualHours': '',
      'scheduledHours': '',
      'scheduledInHoliday': false,
      'publicHoliday': false,
    },
    {
      'Name': 'Thursday',
      'actualHours': '',
      'scheduledHours': '',
      'scheduledInHoliday': false,
      'publicHoliday': false,
    },
    {
      'Name': 'Friday',
      'actualHours': '',
      'scheduledHours': '',
      'scheduledInHoliday': false,
      'publicHoliday': false,
    },
    {
      'Name': 'Saturday',
      'actualHours': '',
      'scheduledHours': '',
      'scheduledInHoliday': false,
      'publicHoliday': false,
    },
    {
      'Name': 'Sunday',
      'actualHours': '',
      'scheduledHours': '',
      'scheduledInHoliday': false,
      'publicHoliday': false,
    }
  ]
  // timesheetYears = ['2017', '2018', '2019', '2020', '2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030'];
  timesheetMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October',
    'November', 'December'];
  selectedCity = '';
  approvalStatus: any = 'Submitted';
  selectedCustAppStatus: any = '';
  selectedactualConverage: any = '';
  selectedactualEndTime: any = '';
  timeCardStartDate: any;
  timeCardEndDate: any;
  timeCardIncurredDate: any;
  vendorTimeIn: any;
  vendorTimeOut: any;
  Customer_Site_Signoff_Name__c = '';
  Vendor_Time_Card_Notes_Tasks_Performed__c = '';
  Any_Observation_or_Suggestions_Notes__c = '';
  Equipment_Make_Model_Performed_on__c = '';
  Equipment_Serial_Performed_on__c = '';
  Worker_Mobile_OS_Android_iOS__c = '';
  timeZone = '';
  timeFormat = 'L, LT z';
  timecardType: '';
  sub: any;
  sidebarMenuOpened: any;
  submittedTimecard = false;
  fileName: '';
  showErrorMsg = false;
  uploadStarts = false;
  jobOrderError = '';
  visibleMenuOptions = ['filter'];
  filterData: any;
  isFilterApplied = false;
  recordTypeIds: any;
  isTimeSheet: any;
  typeOfTimeLog: string;
  workerArrivalCustRqst: any;
  normalHours: any;
  AFTHhours: any;
  selectedTimesheetYear = '';
  selectedTimesheetMonth = '';
  selectedJob = '';
  userTypeSlug: string;
  accessType: string;
  // isLoading = true;
  minTimeOutDate: any;
  defaultCheckOutTime: any;
  refreshFiles: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isRefreshList = false;    // to refresh the timecard history table after updating timecard
  getTimeCardData: any = {};
  error = '';
  ratingObject: any;
  viewRating = false;
  jobSfdcid: string;
  jobId: string;
  Assignment_ID__c: string;
  errorMsg = '';
  loggedInContact = '';
  viewAsVendor = false;
  selectedDate: any;
  selectedMonth: string;
  maxEndDate: any;
  recentJobs: any;
  workerSfdcId: any;
  documentModelId: any = '';
  workOderStartDate: any;
  workOderEndDate: any;
  filteredJobArr: any;
  selectedJobId: any;
  timeSheetId: any;
  @Input() set setData(data: any) {
    if (data && Object.keys(data).length) {
      this.timeSheetId = data.id;
      this.timeCardStartDate = moment(data.Timesheet_Start_Week__c).toISOString();
      this.timeCardData['Timesheet_Start_Date__c'] = moment(data.Timesheet_Start_Week__c).format('YYYY-MM-DD');
      this.timeCardEndDate = moment(data.Timesheet_End_Week__c).toISOString();
      this.timeCardData['Timesheet_End_Date__c'] = moment(data.Timesheet_End_Week__c).format('YYYY-MM-DD');
      this.timeCardData.Monday_Task_Performed__c = data.Monday_Task_Performed__c;
      this.timeCardData.Tuesday_Tasks_Performed__c = data.Tuesday_Tasks_Performed__c;
      this.timeCardData.Wednesday_Tasks_Performed__c = data.Wednesday_Tasks_Performed__c;
      this.timeCardData.Thursday_Tasks_Performed__c = data.Thursday_Tasks_Performed__c;
      this.timeCardData.Friday_Tasks_Performed__c = data.Friday_Tasks_Performed__c;
      this.timeCardData.Saturday_Tasks_Performed__c = data.Saturday_Tasks_Performed__c;
      this.timeCardData.Sunday_Tasks_Performed__c = data.Sunday_Tasks_Performed__c;
      this.selectedTimesheetYear = data.timesheet_year;
      this.selectedTimesheetMonth = data.timesheet_month;
      this.selectedJob = data.job;

      if (this.timeInformationData && this.timeInformationData.length) {

        this.timeInformationData.forEach((val, index) => {
          if (index == 0) {
            val.actualHours = data[`${val.Name}_Hours__c`];
          }
          else {
            val.actualHours = data[`${val.Name}_Hours_Actual__c`];
          }
        })
      }

      console.log('data', data);
      this.getDocuments(this.timeSheetId);
    }
  }
  download = [];

  constructor(
    private _timecardApi: TimecardApi,
    private _preloaderService: PreloaderService,
    private _alertService: AlertService,
    private _commonService: CommonService,
    private _modalService: ModalService,
    private _jobApi: JobApi,
    private _appState: AppStateService,
    private _modelService: ModalService,
    private _documentApi: DocumentApi
  ) { }

  ngOnInit() {

    const appData = this._appState.getAppState();
    this.loggedInContact = appData && appData.value && appData.value['user'] &&
      appData.value['user'].sfdcId ? appData.value['user'].sfdcId : '';
    this.workerSfdcId = appData.value['user'].workers[0].sfdcId

    this.userTypeSlug = this._appState.getUserTypeSlug();
    this.accessType = this._appState.getAccessType();

    this.getRecentJobs(this.workerSfdcId);

    const viewAsInfo = JSON.parse(localStorage.getItem('viewAsInfo'));
    this.viewAsVendor = (viewAsInfo && viewAsInfo.viewAsType === 'Vendor') ? true : false;
    // if (this.timeSheetId) {
    //   this.selectStartDateFilter({ value: new Date(this.timeCardData['Timesheet_Start_Date__c']) });
    // }
  }

  getRecentJobs(workerSfdcId) {
    this._preloaderService.showPreloader();
    this._jobApi.find({
      where: {
        Dispatch_Worker_Name__c: workerSfdcId
      },
      fields: ['sfdcId', 'Iron_Job_num__c','Work_Order_Number'],
      include: [
        {
          relation: 'workOrder',
          scope: {
						fields: ['sfdcId', 'Work_Order_Start_Date__c', 'Work_Order_End_Date__c'],
          }
        }
      ],
      order: 'CreatedDate DESC'
    }).subscribe(res => {
      if (res && res.length) {
        this.recentJobs = res;

        //For edit timesheet
        if (this.selectedJob) {
          const jobRecord = this.recentJobs.find(job => job.Iron_Job_num__c == this.selectedJob);
          if (jobRecord)
            this.selectedJobId = jobRecord.sfdcId;
        }
      }
      this._preloaderService.hidePreloader();
      // this._alertService.success('Timecard updated successfully.')
    }, err => {
      this._preloaderService.hidePreloader();
      // this._alertService.error((err && err.msg) ? err.msg : err);
    })
  }

  onTabBtnClick(tab) {
    const index = this.activeTabs.indexOf(tab);
    if (index !== -1) {
      this.activeTabs.splice(index, 1);
    } else {
      this.activeTabs.push(tab);
    }
  }

  disableSaveOfTimesheet() {
    if(this.errorMsg != ''){
      return true;
    }
    if (this.timeCardStartDate && this.selectedTimesheetYear && this.selectedTimesheetMonth && this.selectedJob) {
      return false;
    }
    return true;
  }

  actualHoursMinMax(ele){
    if(ele.value != ""){
      if(parseInt(ele.target.value) < parseInt(ele.target.min)){
        ele.target.value = ele.target.min;
      }
      if (parseInt(ele.target.value) > parseInt(ele.target.max)) {
        ele.target.value = ele.target.max;
      }
    }
  }

  startSave(status: string) {
    this.saveTimesheetData(status);
  }

  openvendor(modal, size) {
    this._modelService.open(modal, size);
  }

  selectStartDateFilter(event) {
    if (event.value) {
      this.selectedDate = event.value;
      this.maxEndDate = this._commonService.addTime(this.selectedDate, 6, 'd', true).toISOString();
      this.timeCardEndDate = this.maxEndDate;
      this.selectedTimesheetYear = this.selectedDate.getFullYear();
      this.selectedMonth = this.timesheetMonths[this.selectedDate.getMonth()];
      this.selectedTimesheetMonth = this.selectedMonth;

      this.timeCardData['Timesheet_End_Date__c'] = moment(this.maxEndDate).format('YYYY-MM-DD');
      this.timeCardData['Timesheet_Start_Date__c'] = moment(this.selectedDate).format('YYYY-MM-DD');
      const {selectedJob, selectedJobId} = this.getJobWithSfdcId(this.timeCardData['Timesheet_Start_Date__c']);
      this.selectedJobId = selectedJobId;
      this.selectedJob = selectedJob;
    } else {
      this.timeCardData['Timesheet_Start_Date__c'] = this.timeCardStartDate;
    }
  }

  getJobWithSfdcId(startDate) {
    this.filteredJobArr = this.recentJobs.filter(ele => {
      if (ele && ele['workOrder']) {
        return moment(ele['workOrder']['Work_Order_Start_Date__c']).format('YYYY-MM-DD') <= startDate
          && moment(ele['workOrder']['Work_Order_End_Date__c']).format('YYYY-MM-DD') >= startDate;
      }
    })
    if (this.filteredJobArr.length > 0) {
      return {selectedJob: this.filteredJobArr[0]['Iron_Job_num__c'], selectedJobId: this.filteredJobArr[0]['sfdcId']};
    }
    return {selectedJob: '', selectedJobId: ''};
  }

  clearStartDateValue() {
    this.timeCardStartDate = '';
    this.selectedJob = '';
    this.clearEndDateValue();
  }

  clearEndDateValue() {
    this.timeCardEndDate = '';
  }

  saveTimesheetData(status: string) {
    const req = {};
    const timeCard: any = {};
    this.timeCardData['Status__c'] = status;
    this.timeCardData['Customer_Approval_Status__c'] = this.selectedCustAppStatus;
    this.timeCardData['Logged_in_Contact__c'] = this.loggedInContact;
    this.timeCardData['Service_Dispatch__c'] = this.selectedJobId;
    this.timeCardData.Customer_Site_Signoff_Name__c = this.Customer_Site_Signoff_Name__c;
    this.timeCardData.Any_Observation_or_Suggestions_Notes__c = this.Any_Observation_or_Suggestions_Notes__c;
    this.timeInformationData.forEach(day => {
      switch (day.Name) {
        case 'Monday':
          this.timeCardData.Monday_Hours__c = day.actualHours;
          break;
        case 'Tuesday':
          this.timeCardData.Tuesday_Hours_Actual__c = day.actualHours;
          break;
        case 'Wednesday':
          this.timeCardData.Wednesday_Hours_Actual__c = day.actualHours;
          break;
        case 'Thursday':
          this.timeCardData.Thursday_Hours_Actual__c = day.actualHours;
          break;
        case 'Friday':
          this.timeCardData.Friday_Hours_Actual__c = day.actualHours;
          break;
        case 'Saturday':
          this.timeCardData.Saturday_Hours_Actual__c = day.actualHours;
          break;
        case 'Sunday':
          this.timeCardData.Sunday_Hours_Actual__c = day.actualHours;
          break;
      }
    });

    this.timeCardData['Timesheet_Year__c'] = this.selectedTimesheetYear;
    this.timeCardData['Timesheet_Month__c'] = this.selectedTimesheetMonth;
    Object.assign(timeCard, this.timeCardData);
    req['timecard'] = timeCard;
    const appData = this._appState.getAppState();
    delete req['timecard'].job;
    delete req['timecard'].vendor;
    delete req['timecard'].worker;
    delete req['timecard'].Business_Start_Time__c;
    delete req['timecard'].recordType;
    this.saveTimecardData(req);
  }

  saveTimecardData(req) {
    this._preloaderService.showPreloader();
    let requestObj: any;
    req.timecard.RecordTypeId = '0121a0000006QnsAAE';
    req.timecard.Worker__c = this.workerSfdcId;
    req.timecard.Incurred_Date__c = new Date();
    this.checkNullCondition(req);
    const startDate = moment(this.timeCardData['Timesheet_Start_Date__c']);
    const endDate = moment(this.timeCardData['Timesheet_End_Date__c']);
    const startMonth = startDate.month();
    const endMonth = endDate.month();
    if (startMonth != endMonth) {
      const startDateOfMonth = moment(endDate, 'YYYY-MM-DD').startOf('month').format('YYYY-MM-DD');
      const startDayOfMonth = moment(startDateOfMonth).day();
      delete req.timecard['Timesheet_End_Date__c'];
      requestObj = [{ ...req.timecard }];
      requestObj.push({ ...req.timecard });
      this.cleanupRequestObj(requestObj[1], 1, startDayOfMonth);
      this.cleanupRequestObj(requestObj[0], startDayOfMonth, 7);
      requestObj[1].Timesheet_Month__c = this.timesheetMonths[endMonth];
      const { selectedJob, selectedJobId } = this.getJobWithSfdcId(startDateOfMonth);
      requestObj[1].Service_Dispatch__c = selectedJobId;
    } else {
      delete req.timecard['Timesheet_End_Date__c'];
      requestObj = req.timecard;
    }
    this.apiCallEvent.emit(true);
    let whereObj = {};
    if (Array.isArray(requestObj)) {
      whereObj = {
        where: {
          or: [{
            Timesheet_Start_Date__c: requestObj[0].Timesheet_Start_Date__c,
            Service_Dispatch__c: requestObj[0].Service_Dispatch__c
          }, {
            Timesheet_Start_Date__c: requestObj[1].Timesheet_Start_Date__c,
            Service_Dispatch__c: requestObj[1].Service_Dispatch__c
          }],
          sfdcId: { neq: null }
        }
      }
    } else {
      whereObj = {
        where: {
          Timesheet_Start_Date__c: requestObj.Timesheet_Start_Date__c,
          Service_Dispatch__c: requestObj.Service_Dispatch__c,
          sfdcId: { neq: null }
        }
      }
    }
    if (this.timeSheetId)
      this.createTimesheet(requestObj);
    else {
      this._timecardApi.find(whereObj).subscribe(timesheet => {
        if (timesheet && timesheet.length) {
          if (Array.isArray(requestObj) && timesheet.length == 1) {
            const filterData = requestObj.filter(x => {
              return (x.Timesheet_Start_Date__c != timesheet[0]['Timesheet_Start_Date__c']) &&
                (x.Service_Dispatch__c != timesheet[0]['Service_Dispatch__c']);
            });
            this.createTimesheet(filterData[0]);
          } else {
            setTimeout(() => this._alertService.error("Timesheet already exists for the selected week!"), 0);
            this._preloaderService.hidePreloader();
            this.closeModal();
          }
        } else {
          this.createTimesheet(requestObj);
        }
      })
    }
  }

  createTimesheet(requestObj) {
    // this._preloaderService.showPreloader();
    if (this.timeSheetId) {
      if (Array.isArray(requestObj)) {
        forkJoin(this._timecardApi.patchAttributes(this.timeSheetId, requestObj[0]), this._timecardApi.patchAttributes(this.timeSheetId, requestObj[1])).subscribe(([res1 ,res2]) => {
          if (res1 && res2) {
            this.documentModelId = res1['id'];
            this.uploadStarts = true;
          }
          if (!this.fileName) {
            this.refreshTimeSheetList.emit();
            setTimeout(() => this._alertService.success('Timesheet Updated.'), 0);
            this._preloaderService.hidePreloader();
            this.closeModal();
          }
          else if (this.fileName && this.download && this.download.length) {
            this.deleteExistingFile(this.download[0].id);
          }
          // document.querySelector('.modal-body').scrollTo(0, 0);
          // this._alertService.success('Timesheet Updated.')
          this.isRefreshList = true;
        }, err => {
          this._preloaderService.hidePreloader();
          setTimeout(() => this._alertService.error("Timesheet already exists for the selected week!"), 0);
          this.closeModal();
        })
      }
      else {
        this._timecardApi.patchAttributes(this.timeSheetId, requestObj).subscribe(res => {
          this._preloaderService.hidePreloader();
          if (res) {
            this.documentModelId = res['id'];
            this.uploadStarts = true;
          }
          if (!this.fileName) {
            this.refreshTimeSheetList.emit();
            setTimeout(() => this._alertService.success('Timesheet Updated.'), 0);
            this._preloaderService.hidePreloader();
            this.closeModal();
          }
          else if (this.fileName && this.download && this.download.length) {
            this.deleteExistingFile(this.download[0].id);
          }
          // document.querySelector('.modal-body').scrollTo(0, 0);
          // this._alertService.success('Timesheet Updated.')
          this.isRefreshList = true;
        }, err => {
          this._preloaderService.hidePreloader();
          setTimeout(() => this._alertService.error("Timesheet already exists for the selected week!"), 0);
          this.closeModal();
        });
      }
    }
    else {
      this._timecardApi.create(requestObj).subscribe(res => {
        // this._preloaderService.hidePreloader();
        if (res) {
          this.documentModelId = res[0] && res[0]['id'] ? res[0]['id'] : res['id'];
          setTimeout(() => {
            this.findTimesheet();
            // this._alertService.success('New Timesheet Created.')
            // this._preloaderService.hidePreloader();
            // this.refreshTimeSheetList.emit();
            // this.closeModal();
          }, 16000)  
          // this.uploadStarts = true;
        }
        else {
          this._preloaderService.hidePreloader();
          this._alertService.error("Can not create timesheet!");
        }
        this.isRefreshList = true;
      }, err => {
        this.closeModal();
        this._alertService.error("Timesheet already exists for the selected week!");
      })
    } 
  }

  findTimesheet() {
    this._timecardApi.findById(this.documentModelId).subscribe(
      res => {
        if (res && res['sfdcId']) {
          this.uploadStarts = true;
          if (!this.fileName) {
            setTimeout(() => {
              this._preloaderService.hidePreloader();
              this.refreshTimeSheetList.emit();  
              this._alertService.success('New Timesheet Created.');
              this.closeModal();
            }, 1000);
          }
          this.isRefreshList = true;
        }
        else {
          setTimeout(() => {
            this._preloaderService.hidePreloader();
            this.refreshTimeSheetList.emit();  
            this._alertService.error("Can not create timesheet!");
            this.closeModal();
          }, 1000);
        }
      },
      err => {
        console.log(err);
        this._preloaderService.hidePreloader();
        setTimeout(() => this._alertService.error("Can not create timesheet!"), 0);
        this.closeModal();
      }
    );
  }

  deleteExistingFile(documentId) {
    this._documentApi.deleteById(documentId).subscribe(
      res => {
        if (res) {
          if (res && res['count']) {
            console.log('file deleted');

          }
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  cleanupRequestObj(req, start, end) {
    if (end == 7) {
      delete req.Sunday_Hours_Actual__c;
      delete req.Sunday_Tasks_Performed__c;
    }
    for (let i = start; i < end; i++) {
      switch (i) {
        case 1:
          delete req.Monday_Hours__c;
          delete req.Monday_Task_Performed__c;
          break;
        case 2:
          delete req.Tuesday_Hours_Actual__c;
          delete req.Tuesday_Tasks_Performed__c;
          break;
        case 3:
          delete req.Wednesday_Hours_Actual__c;
          delete req.Wednesday_Tasks_Performed__c;
          break;
        case 4:
          delete req.Thursday_Hours_Actual__c;
          delete req.Thursday_Tasks_Performed__c;
          break;
        case 5:
          delete req.Friday_Hours_Actual__c;
          delete req.Friday_Tasks_Performed__c;
          break;
        case 6:
          delete req.Saturday_Hours_Actual__c;
          delete req.Saturday_Tasks_Performed__c;
          break;
      }
    }
  }

  /**  This method for check is added as while timecard history fields are **/
  /*  autoupdating and creating history for null value changes so we are deleting this fields when we are not updating these fields */
  private checkNullCondition(req) {
    if (this.getTimeCardData['Is_Monday_Public_Holiday__c'] === null && req['timecard'].Is_Monday_Public_Holiday__c === false) {
      delete req['timecard'].Is_Monday_Public_Holiday__c;
    }
    if (this.getTimeCardData['Is_Tuesday_Public_Holiday__c'] === null && req['timecard'].Is_Tuesday_Public_Holiday__c === false) {
      delete req['timecard'].Is_Tuesday_Public_Holiday__c;
    }
    if (this.getTimeCardData['Is_Wednesday_Public_Holiday__c'] === null && req['timecard'].Is_Wednesday_Public_Holiday__c === false) {
      delete req['timecard'].Is_Wednesday_Public_Holiday__c;
    }
    if (this.getTimeCardData['Is_Thursday_Public_Holiday__c'] === null && req['timecard'].Is_Thursday_Public_Holiday__c === false) {
      delete req['timecard'].Is_Thursday_Public_Holiday__c;
    }
    if (this.getTimeCardData['Is_Friday_Public_Holiday__c'] === null && req['timecard'].Is_Friday_Public_Holiday__c === false) {
      delete req['timecard'].Is_Friday_Public_Holiday__c;
    }
    if (this.getTimeCardData['Is_Saturday_Public_Holiday__c'] === null && req['timecard'].Is_Saturday_Public_Holiday__c === false) {
      delete req['timecard'].Is_Saturday_Public_Holiday__c;
    }
    if (this.getTimeCardData['Is_Sunday_Public_Holiday__c'] === null && req['timecard'].Is_Sunday_Public_Holiday__c === false) {
      delete req['timecard'].Is_Sunday_Public_Holiday__c;
    }
    if (this.getTimeCardData['Is_Monday_Holiday_in_Jobsite__c'] === null && req['timecard'].Is_Monday_Holiday_in_Jobsite__c === false) {
      delete req['timecard'].Is_Monday_Holiday_in_Jobsite__c;
    }
    if (this.getTimeCardData['Is_Tuesday_Holiday_in_Jobsite__c'] === null && req['timecard'].Is_Tuesday_Holiday_in_Jobsite__c === false) {
      delete req['timecard'].Is_Tuesday_Holiday_in_Jobsite__c;
    }
    if (this.getTimeCardData['Is_Wednesday_Holiday_in_Jobsite__c'] === null && req['timecard'].Is_Wednesday_Holiday_in_Jobsite__c === false) {
      delete req['timecard'].Is_Wednesday_Holiday_in_Jobsite__c;
    }
    if (this.getTimeCardData['Is_Thursday_Holiday_in_Jobsite__c'] === null && req['timecard'].Is_Thursday_Holiday_in_Jobsite__c === false) {
      delete req['timecard'].Is_Thursday_Holiday_in_Jobsite__c;
    }
    if (this.getTimeCardData['Is_Friday_Holiday_in_Jobsite__c'] === null && req['timecard'].Is_Friday_Holiday_in_Jobsite__c === false) {
      delete req['timecard'].Is_Friday_Holiday_in_Jobsite__c;
    }
    if (this.getTimeCardData['Is_Saturday_Holiday_in_Jobsite__c'] === null && req['timecard'].Is_Saturday_Holiday_in_Jobsite__c === false) {
      delete req['timecard'].Is_Saturday_Holiday_in_Jobsite__c;
    }
    if (this.getTimeCardData['Is_Sunday_Holiday_in_Jobsite__c'] === null && req['timecard'].Is_Sunday_Holiday_in_Jobsite__c === false) {
      delete req['timecard'].Is_Sunday_Holiday_in_Jobsite__c;
    }
    if (this.getTimeCardData['Monday_Hours__c'] === null && req['timecard'].Monday_Hours__c === 0) {
      delete req['timecard'].Monday_Hours__c;
    }
    if (this.getTimeCardData['Tuesday_Hours_Actual__c'] === null && req['timecard'].Tuesday_Hours_Actual__c === 0) {
      delete req['timecard'].Tuesday_Hours_Actual__c;
    }
    if (this.getTimeCardData['Wednesday_Hours_Actual__c'] === null && req['timecard'].Wednesday_Hours_Actual__c === 0) {
      delete req['timecard'].Wednesday_Hours_Actual__c;
    }
    if (this.getTimeCardData['Thursday_Hours_Actual__c'] === null && req['timecard'].Thursday_Hours_Actual__c === 0) {
      delete req['timecard'].Thursday_Hours_Actual__c;
    }
    if (this.getTimeCardData['Friday_Hours_Actual__c'] === null && req['timecard'].Friday_Hours_Actual__c === 0) {
      delete req['timecard'].Friday_Hours_Actual__c;
    }
    if (this.getTimeCardData['Saturday_Hours_Actual__c'] === null && req['timecard'].Saturday_Hours_Actual__c === 0) {
      delete req['timecard'].Saturday_Hours_Actual__c;
    }
    if (this.getTimeCardData['Sunday_Hours_Actual__c'] === null && req['timecard'].Sunday_Hours_Actual__c === 0) {
      delete req['timecard'].Sunday_Hours_Actual__c;
    }
    return req['timecard'];
  }

  uploadDocument(modal, size) {
    this._modelService.open(modal, size);
  }

  documentUploaded(doc) {
    this.refreshFiles.next(true);
    // setTimeout(() => {
    setTimeout(() => {
      this._preloaderService.hidePreloader();
      this.refreshTimeSheetList.emit();
      this._alertService.success(this.timeSheetId ? 'Timesheet Updated.' : 'New Timesheet Created.');
      this.closeModal();
    }, 1000);
    // }, 14000)  
  }
    

  closeModal() {
    this._modalService.closed();
  }

  setFileAdded(event) {
    this.fileName = event.name;
    this.showErrorMsg = false;
  }

  setDocumentUploaded(event) {
    this.fileName = event.name;
    this.showErrorMsg = false;
    console.log(event)
  }

  startDateFilter = (d: Date): boolean => {
    const day = d.getDay();
    // Select start day as Monday only.
    return day === 1;
  }

  d(event) {
    this.saveTimesheetData('Submitted');
  }

  getDocuments(modelID) {
    // this._preLoaderService.showPreloader();
    this._documentApi.find({
      where: {
        modelName: 'Timecard',
        modelId: modelID,
        categoryId: 19
      },
    }).subscribe(
      results => {
        if (results && results.length > 0)
          this.download = this.modifyData(results);
        // this._preLoaderService.hidePreloader();
      },
      err => {
        this._alertService.error((err && err.message) ? err.message : err);
        // this._preLoaderService.hidePreloader();
      }
    );
  }

  modifyData(data) {
    if (data.length) {
      data.forEach((item) => {
        this.tmpModify(item);
      });
      return data;
    } else {
      return [];
    }
  }

  tmpModify(item) {
    item['id'] = (item.id) ? item.id : '';
    item['Name'] = (item && item.fileMeta.name) ? item.fileMeta.name : '';
    item['subCategory'] = (item && item.subCategory) ? item.subCategory.title : '';
    item['uploadedBy'] = item.uploadedBy;
    item['fileMeta'] = (item && item.fileMeta) ? item.fileMeta : '';
    item['shortUrl'] = (item && item.shortUrl) ? item.shortUrl : '';
    item['description'] = (item && item.description) ? item.description : '';
    return item;
  }

}
