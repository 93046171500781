/* tslint:disable */
import {
  RecordType
} from '../index';

declare var Object: any;
export interface GlobalTalentPoolInterface {
  "Name"?: string;
  "sfdcId"?: string;
  "OwnerId"?: string;
  "IsDeleted"?: boolean;
  "CurrencyIsoCode"?: string;
  "RecordTypeId"?: string;
  "CreatedById"?: string;
  "LastModifiedById"?: string;
  "Billing_Legal_Relationship_US_Ownership__c"?: string;
  "Billing_Legal_Relationship_USA_Taxes__c"?: string;
  "Community_Type__c"?: string;
  "FMS_Provider__c"?: string;
  "Management_Type__c"?: string;
  "Ownership__c"?: string;
  "Pool_Access_Type__c"?: string;
  "Pool_Name__c"?: string;
  "Resource_Source__c"?: string;
  "Resource_Type__c"?: string;
  "Source__c"?: string;
  "Status__c"?: string;
  "Total_Members__c"?: string;
  "Vetting_Type_Employee__c"?: string;
  "Vetting_Type_Freelancers__c"?: string;
  "WM_Pool_Id__c"?: string;
  "Certifications__c"?: string;
  "Cost__c"?: number;
  "Coverage_Hours__c"?: string;
  "CreatedBy__c"?: string;
  "Expenses__c"?: string;
  "Hours_count__c"?: number;
  "Insurance__c"?: string;
  "Job__c"?: number;
  "Job_Count__c"?: number;
  "Last_ModifiedBy__c"?: string;
  "Legal_Agreement__c"?: string;
  "Pricelist_negotiation__c"?: string;
  "Quality_Rating__c"?: string;
  "Recordtypeid__c"?: string;
  "Service_Technical_Level__c"?: string;
  "SLA__c"?: string;
  "Talent_Type__c"?: string;
  "Tools__c"?: string;
  "Training__c"?: string;
  "Travel_Expenses__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  recordType?: RecordType;
}

export class GlobalTalentPool implements GlobalTalentPoolInterface {
  "Name": string;
  "sfdcId": string;
  "OwnerId": string;
  "IsDeleted": boolean;
  "CurrencyIsoCode": string;
  "RecordTypeId": string;
  "CreatedById": string;
  "LastModifiedById": string;
  "Billing_Legal_Relationship_US_Ownership__c": string;
  "Billing_Legal_Relationship_USA_Taxes__c": string;
  "Community_Type__c": string;
  "FMS_Provider__c": string;
  "Management_Type__c": string;
  "Ownership__c": string;
  "Pool_Access_Type__c": string;
  "Pool_Name__c": string;
  "Resource_Source__c": string;
  "Resource_Type__c": string;
  "Source__c": string;
  "Status__c": string;
  "Total_Members__c": string;
  "Vetting_Type_Employee__c": string;
  "Vetting_Type_Freelancers__c": string;
  "WM_Pool_Id__c": string;
  "Certifications__c": string;
  "Cost__c": number;
  "Coverage_Hours__c": string;
  "CreatedBy__c": string;
  "Expenses__c": string;
  "Hours_count__c": number;
  "Insurance__c": string;
  "Job__c": number;
  "Job_Count__c": number;
  "Last_ModifiedBy__c": string;
  "Legal_Agreement__c": string;
  "Pricelist_negotiation__c": string;
  "Quality_Rating__c": string;
  "Recordtypeid__c": string;
  "Service_Technical_Level__c": string;
  "SLA__c": string;
  "Talent_Type__c": string;
  "Tools__c": string;
  "Training__c": string;
  "Travel_Expenses__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  recordType: RecordType;
  constructor(data?: GlobalTalentPoolInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `GlobalTalentPool`.
   */
  public static getModelName() {
    return "GlobalTalentPool";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of GlobalTalentPool for dynamic purposes.
  **/
  public static factory(data: GlobalTalentPoolInterface): GlobalTalentPool{
    return new GlobalTalentPool(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'GlobalTalentPool',
      plural: 'GlobalTalentPools',
      path: 'GlobalTalentPools',
      idName: 'id',
      properties: {
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "RecordTypeId": {
          name: 'RecordTypeId',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "Billing_Legal_Relationship_US_Ownership__c": {
          name: 'Billing_Legal_Relationship_US_Ownership__c',
          type: 'string'
        },
        "Billing_Legal_Relationship_USA_Taxes__c": {
          name: 'Billing_Legal_Relationship_USA_Taxes__c',
          type: 'string'
        },
        "Community_Type__c": {
          name: 'Community_Type__c',
          type: 'string'
        },
        "FMS_Provider__c": {
          name: 'FMS_Provider__c',
          type: 'string'
        },
        "Management_Type__c": {
          name: 'Management_Type__c',
          type: 'string'
        },
        "Ownership__c": {
          name: 'Ownership__c',
          type: 'string'
        },
        "Pool_Access_Type__c": {
          name: 'Pool_Access_Type__c',
          type: 'string'
        },
        "Pool_Name__c": {
          name: 'Pool_Name__c',
          type: 'string'
        },
        "Resource_Source__c": {
          name: 'Resource_Source__c',
          type: 'string'
        },
        "Resource_Type__c": {
          name: 'Resource_Type__c',
          type: 'string'
        },
        "Source__c": {
          name: 'Source__c',
          type: 'string'
        },
        "Status__c": {
          name: 'Status__c',
          type: 'string'
        },
        "Total_Members__c": {
          name: 'Total_Members__c',
          type: 'string'
        },
        "Vetting_Type_Employee__c": {
          name: 'Vetting_Type_Employee__c',
          type: 'string'
        },
        "Vetting_Type_Freelancers__c": {
          name: 'Vetting_Type_Freelancers__c',
          type: 'string'
        },
        "WM_Pool_Id__c": {
          name: 'WM_Pool_Id__c',
          type: 'string'
        },
        "Certifications__c": {
          name: 'Certifications__c',
          type: 'string'
        },
        "Cost__c": {
          name: 'Cost__c',
          type: 'number'
        },
        "Coverage_Hours__c": {
          name: 'Coverage_Hours__c',
          type: 'string'
        },
        "CreatedBy__c": {
          name: 'CreatedBy__c',
          type: 'string'
        },
        "Expenses__c": {
          name: 'Expenses__c',
          type: 'string'
        },
        "Hours_count__c": {
          name: 'Hours_count__c',
          type: 'number'
        },
        "Insurance__c": {
          name: 'Insurance__c',
          type: 'string'
        },
        "Job__c": {
          name: 'Job__c',
          type: 'number'
        },
        "Job_Count__c": {
          name: 'Job_Count__c',
          type: 'number'
        },
        "Last_ModifiedBy__c": {
          name: 'Last_ModifiedBy__c',
          type: 'string'
        },
        "Legal_Agreement__c": {
          name: 'Legal_Agreement__c',
          type: 'string'
        },
        "Pricelist_negotiation__c": {
          name: 'Pricelist_negotiation__c',
          type: 'string'
        },
        "Quality_Rating__c": {
          name: 'Quality_Rating__c',
          type: 'string'
        },
        "Recordtypeid__c": {
          name: 'Recordtypeid__c',
          type: 'string'
        },
        "Service_Technical_Level__c": {
          name: 'Service_Technical_Level__c',
          type: 'string'
        },
        "SLA__c": {
          name: 'SLA__c',
          type: 'string'
        },
        "Talent_Type__c": {
          name: 'Talent_Type__c',
          type: 'string'
        },
        "Tools__c": {
          name: 'Tools__c',
          type: 'string'
        },
        "Training__c": {
          name: 'Training__c',
          type: 'string'
        },
        "Travel_Expenses__c": {
          name: 'Travel_Expenses__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        recordType: {
          name: 'recordType',
          type: 'RecordType',
          model: 'RecordType',
          relationType: 'belongsTo',
                  keyFrom: 'RecordTypeId',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
