import { OnInit, Component, Input, HostBinding, EventEmitter, Output } from '@angular/core';
import { DispatchServiceResolutionStatuses } from 'shared/models/static-list-data.service';

@Component({
    selector: 'app-stage-status-lookup',
    templateUrl: './stage-status-lookup.component.html',
    styleUrls: ['./stage-status-lookup.component.css']
})
export class StageStatusLookupComponent implements OnInit {
    @Input() set resetData(isClear) {
        this.stageStatusesSelected = [];
    }
    @Input() stageStatusObj;
    @Input() isMultiSelect = true;
    @Input() placeholder;
    @Output() stageStatusChange: EventEmitter<any> = new EventEmitter();
    stageStatuses;
    stageStatusesSelected;

    constructor() {
        // this.stageStatuses = DispatchServiceResolutionStatuses;
        // this.stageStatusesSelected = [];
    }
    ngOnInit() {
        // to show statge status
        const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
        this.stageStatusesSelected = (preselected && preselected['statgeStatus']) ?
            preselected['statgeStatus'] : [];
        this.stageStatuses = DispatchServiceResolutionStatuses;

    }

    clearStageStatus() {
        this.stageStatusesSelected = [];
        this.stageStatusChange.emit([]);
    }

    onStageStatusChange() {
        this.stageStatusChange.emit(this.stageStatusesSelected);
    }

}
