import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ActualCoverageHourLookupComponent } from './actual-coverage-hour-lookup.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgSelectModule
    ],
    declarations: [ActualCoverageHourLookupComponent],
    exports: [ActualCoverageHourLookupComponent]
})
export class ActualCoverageHourLookupModule { }
