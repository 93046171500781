import { WorkerApi } from './../../../sdk/services/custom/Worker';
import { PreloaderService } from './../../../services/preloader.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { workerBillingTypeValues } from '../../../models/static-list-data.service'

@Component({
  selector: 'app-worker-billing-type-lookup',
  templateUrl: './worker-billing-type-lookup.component.html',
  styleUrls: ['./worker-billing-type-lookup.component.css']
})
export class WorkerBillingTypeLookupComponent implements OnInit {

  workerBillingTypeList = [];
  workerBillingTypeSelected = [];
  isMultipleSelected = true;

  @Input() set resetData(isClear) {
    if (isClear) {
      this.workerBillingTypeSelected = [];
    }
  }
  @Output() selectedWorkerBillingType: EventEmitter<any> = new EventEmitter;

  constructor(private _loader: PreloaderService,
    private _workerApi: WorkerApi) { }

  ngOnInit() {
    this.workerBillingTypeList = workerBillingTypeValues;
    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
    if (preselected && preselected['workerBillingTypes'] && preselected['workerBillingTypes'].ids) {
      this._loader.showPreloader();
      this._workerApi.find({
        fields: ['Worker_Billing_Type__c'],
        where: { sfdcId: { inq: preselected['workerBillingTypes'].ids } }
      }).subscribe(reply => {
        this._loader.hidePreloader();
        if (reply && reply.length) {
          this.workerBillingTypeSelected = reply.map(item => item['Worker_Billing_Type__c']);
        }
      }, error => {
        this._loader.hidePreloader();
        console.log(error);
      })
    }
  }
  onWorkerBillingTypeChange() {
    this._loader.showPreloader();
    this._workerApi.find({
      fields: ['sfdcId'],
      where: { Worker_Billing_Type__c: { inq: this.workerBillingTypeSelected } }
    }).subscribe(reply => {
      this._loader.hidePreloader();
      const sfdcIds = reply.map(item => item['sfdcId']);
      this.selectedWorkerBillingType.emit({ ids: sfdcIds, selectedCount: this.workerBillingTypeSelected.length });
    }, error => {
      this._loader.hidePreloader();
      console.log(error);
    })
  }
  clearWorkerBillingType() {
    this.workerBillingTypeSelected = [];
    this.selectedWorkerBillingType.emit([]);
  }

}
