/* tslint:disable */
import {
  RecordType,
  WorkOrder,
  Skilling,
  Worker,
  Timecard,
  Product,
  Pricelist,
  Case,
  JobOrderItem,
  Job,
  ServiceContract
} from '../index';

declare var Object: any;
export interface JobScheduleInterface {
  "sfdcId"?: string;
  "CreatedById"?: string;
  "LastModifiedById"?: string;
  "Name"?: string;
  "CurrencyIsoCode"?: string;
  "RecordTypeId"?: string;
  "CreatedDate"?: Date;
  "Auto_Calculate_End_Times__c"?: boolean;
  "Break_Duration_Minutes__c"?: number;
  "Calculation_Frequency_Daily_Weekly__c"?: string;
  "Custom_Schedule__c"?: string;
  "Days_Week_Schedule__c"?: number;
  "Duration_of_contract_Month__c"?: string;
  "Duration_of_Contract_Year__c"?: string;
  "Temp_Calc1__c"?: string;
  "Friday_Business_End_Time__c"?: string;
  "Friday_Business_Start_Time__c"?: string;
  "Friday_Hours__c"?: string;
  "Friday_Hours_Auto__c"?: number;
  "Friday_Schedule__c"?: string;
  "ICC_Case__c"?: string;
  "Invoice__c"?: string;
  "Is_Holiday_Friday__c"?: boolean;
  "Is_Holiday_Monday__c"?: boolean;
  "Is_Holiday_Saturday__c"?: boolean;
  "Is_Holiday_Sunday__c"?: boolean;
  "Is_Holiday_Thursday__c"?: boolean;
  "Is_Holiday_Tuesday__c"?: boolean;
  "Is_Holiday_Wednesday__c"?: boolean;
  "Job__c"?: string;
  "Job_Order__c"?: string;
  "Job_Start_Date_Tentative_Exact__c"?: Date;
  "Monday_Business_End_Time__c"?: string;
  "Business_Start_Time__c"?: string;
  "Monday_Hours__c"?: string;
  "Monday_Hours_Auto__c"?: number;
  "Monday_Schedule__c"?: string;
  "Other_Instructions__c"?: string;
  "PMS_Case__c"?: string;
  "Pricelist__c"?: string;
  "Product__c"?: string;
  "Recordtypeid__c"?: string;
  "Temp_Calc__c"?: string;
  "Saturday_Business_End_Time__c"?: string;
  "Saturday_Business_Start_Time__c"?: string;
  "Saturday_Hours__c"?: string;
  "Saturday_Hours_Auto__c"?: number;
  "Saturday_Schedule__c"?: string;
  "Schedule__c"?: string;
  "Service_Contract__c"?: string;
  "Standard_Shift_End_Time__c"?: string;
  "Standard_Shift_Start_Time__c"?: string;
  "Status__c"?: string;
  "Sunday_Business_End_Time__c"?: string;
  "Sunday_Business_Start_Time__c"?: string;
  "Sunday_Hours__c"?: string;
  "Sunday_Hours_Auto__c"?: number;
  "Sunday_Schedule__c"?: string;
  "Temp_Time_Calc__c"?: string;
  "Thursday_Business_End_Time__c"?: string;
  "Thursday_Business_Start_Time__c"?: string;
  "Thursday_Hours__c"?: string;
  "Thursday_Hours_Auto__c"?: number;
  "Thursday_Schedule__c"?: string;
  "Timecard_Timesheet__c"?: string;
  "Total_Weekly_Hours__c"?: number;
  "Tuesday_Business_End_Time__c"?: string;
  "Tuesday_Business_Start_Time__c"?: string;
  "Tuesday_Hours__c"?: string;
  "Tuesday_Hours_Auto__c"?: number;
  "Tuesday_Schedule__c"?: string;
  "Wednesday_Business_End_Time__c"?: string;
  "Wednesday_Business_Start_Time__c"?: string;
  "Wednesday_Hours__c"?: string;
  "Wednesday_Hours_Auto__c"?: number;
  "Wednesday_Schedule__c"?: string;
  "Worker__c"?: string;
  "Worker_Schedule_Type__c"?: string;
  "Worker_Skilling_Profile__c"?: string;
  "Work_Order__c"?: string;
  "Yearly_Hours__c"?: string;
  "Monday_Business_End_Time_Formula__c"?: string;
  "Monday_Business_Start_Time_Formula__c"?: string;
  "Tuesday_Business_End_Time_Formula__c"?: string;
  "Tuesday_Business_Start_Time_Formula__c"?: string;
  "Wednesday_Business_End_Time_Formula__c"?: string;
  "Wednesday_Business_Start_Time_Formula__c"?: string;
  "Thursday_Business_End_Time_Formula__c"?: string;
  "Thursday_Business_Start_Time_Formula__c"?: string;
  "Friday_Business_End_Time_Formula__c"?: string;
  "Friday_Business_Start_Time_Formula__c"?: string;
  "Saturday_Business_End_Time_Formula__c"?: string;
  "Saturday_Business_Start_Time_Formula__c"?: string;
  "Sunday_Business_Start_Time_Formula__c"?: string;
  "Sunday_Business_End_Time_Formula__c"?: string;
  "Standard_Shift_End_Time_Formula__c"?: string;
  "Standard_Shift_Start_Time_Formula__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  recordType?: RecordType;
  workOrder?: WorkOrder;
  skilling?: Skilling;
  worker?: Worker;
  timecard?: Timecard;
  product?: Product;
  pricelist?: Pricelist;
  pmsCase?: Case;
  jobOrder?: JobOrderItem;
  job?: Job;
  serviceContract?: ServiceContract;
}

export class JobSchedule implements JobScheduleInterface {
  "sfdcId": string;
  "CreatedById": string;
  "LastModifiedById": string;
  "Name": string;
  "CurrencyIsoCode": string;
  "RecordTypeId": string;
  "CreatedDate": Date;
  "Auto_Calculate_End_Times__c": boolean;
  "Break_Duration_Minutes__c": number;
  "Calculation_Frequency_Daily_Weekly__c": string;
  "Custom_Schedule__c": string;
  "Days_Week_Schedule__c": number;
  "Duration_of_contract_Month__c": string;
  "Duration_of_Contract_Year__c": string;
  "Temp_Calc1__c": string;
  "Friday_Business_End_Time__c": string;
  "Friday_Business_Start_Time__c": string;
  "Friday_Hours__c": string;
  "Friday_Hours_Auto__c": number;
  "Friday_Schedule__c": string;
  "ICC_Case__c": string;
  "Invoice__c": string;
  "Is_Holiday_Friday__c": boolean;
  "Is_Holiday_Monday__c": boolean;
  "Is_Holiday_Saturday__c": boolean;
  "Is_Holiday_Sunday__c": boolean;
  "Is_Holiday_Thursday__c": boolean;
  "Is_Holiday_Tuesday__c": boolean;
  "Is_Holiday_Wednesday__c": boolean;
  "Job__c": string;
  "Job_Order__c": string;
  "Job_Start_Date_Tentative_Exact__c": Date;
  "Monday_Business_End_Time__c": string;
  "Business_Start_Time__c": string;
  "Monday_Hours__c": string;
  "Monday_Hours_Auto__c": number;
  "Monday_Schedule__c": string;
  "Other_Instructions__c": string;
  "PMS_Case__c": string;
  "Pricelist__c": string;
  "Product__c": string;
  "Recordtypeid__c": string;
  "Temp_Calc__c": string;
  "Saturday_Business_End_Time__c": string;
  "Saturday_Business_Start_Time__c": string;
  "Saturday_Hours__c": string;
  "Saturday_Hours_Auto__c": number;
  "Saturday_Schedule__c": string;
  "Schedule__c": string;
  "Service_Contract__c": string;
  "Standard_Shift_End_Time__c": string;
  "Standard_Shift_Start_Time__c": string;
  "Status__c": string;
  "Sunday_Business_End_Time__c": string;
  "Sunday_Business_Start_Time__c": string;
  "Sunday_Hours__c": string;
  "Sunday_Hours_Auto__c": number;
  "Sunday_Schedule__c": string;
  "Temp_Time_Calc__c": string;
  "Thursday_Business_End_Time__c": string;
  "Thursday_Business_Start_Time__c": string;
  "Thursday_Hours__c": string;
  "Thursday_Hours_Auto__c": number;
  "Thursday_Schedule__c": string;
  "Timecard_Timesheet__c": string;
  "Total_Weekly_Hours__c": number;
  "Tuesday_Business_End_Time__c": string;
  "Tuesday_Business_Start_Time__c": string;
  "Tuesday_Hours__c": string;
  "Tuesday_Hours_Auto__c": number;
  "Tuesday_Schedule__c": string;
  "Wednesday_Business_End_Time__c": string;
  "Wednesday_Business_Start_Time__c": string;
  "Wednesday_Hours__c": string;
  "Wednesday_Hours_Auto__c": number;
  "Wednesday_Schedule__c": string;
  "Worker__c": string;
  "Worker_Schedule_Type__c": string;
  "Worker_Skilling_Profile__c": string;
  "Work_Order__c": string;
  "Yearly_Hours__c": string;
  "Monday_Business_End_Time_Formula__c": string;
  "Monday_Business_Start_Time_Formula__c": string;
  "Tuesday_Business_End_Time_Formula__c": string;
  "Tuesday_Business_Start_Time_Formula__c": string;
  "Wednesday_Business_End_Time_Formula__c": string;
  "Wednesday_Business_Start_Time_Formula__c": string;
  "Thursday_Business_End_Time_Formula__c": string;
  "Thursday_Business_Start_Time_Formula__c": string;
  "Friday_Business_End_Time_Formula__c": string;
  "Friday_Business_Start_Time_Formula__c": string;
  "Saturday_Business_End_Time_Formula__c": string;
  "Saturday_Business_Start_Time_Formula__c": string;
  "Sunday_Business_Start_Time_Formula__c": string;
  "Sunday_Business_End_Time_Formula__c": string;
  "Standard_Shift_End_Time_Formula__c": string;
  "Standard_Shift_Start_Time_Formula__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  recordType: RecordType;
  workOrder: WorkOrder;
  skilling: Skilling;
  worker: Worker;
  timecard: Timecard;
  product: Product;
  pricelist: Pricelist;
  pmsCase: Case;
  jobOrder: JobOrderItem;
  job: Job;
  serviceContract: ServiceContract;
  constructor(data?: JobScheduleInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `JobSchedule`.
   */
  public static getModelName() {
    return "JobSchedule";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of JobSchedule for dynamic purposes.
  **/
  public static factory(data: JobScheduleInterface): JobSchedule{
    return new JobSchedule(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'JobSchedule',
      plural: 'JobSchedules',
      path: 'JobSchedules',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "RecordTypeId": {
          name: 'RecordTypeId',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "Auto_Calculate_End_Times__c": {
          name: 'Auto_Calculate_End_Times__c',
          type: 'boolean'
        },
        "Break_Duration_Minutes__c": {
          name: 'Break_Duration_Minutes__c',
          type: 'number'
        },
        "Calculation_Frequency_Daily_Weekly__c": {
          name: 'Calculation_Frequency_Daily_Weekly__c',
          type: 'string'
        },
        "Custom_Schedule__c": {
          name: 'Custom_Schedule__c',
          type: 'string'
        },
        "Days_Week_Schedule__c": {
          name: 'Days_Week_Schedule__c',
          type: 'number'
        },
        "Duration_of_contract_Month__c": {
          name: 'Duration_of_contract_Month__c',
          type: 'string'
        },
        "Duration_of_Contract_Year__c": {
          name: 'Duration_of_Contract_Year__c',
          type: 'string'
        },
        "Temp_Calc1__c": {
          name: 'Temp_Calc1__c',
          type: 'string'
        },
        "Friday_Business_End_Time__c": {
          name: 'Friday_Business_End_Time__c',
          type: 'string'
        },
        "Friday_Business_Start_Time__c": {
          name: 'Friday_Business_Start_Time__c',
          type: 'string'
        },
        "Friday_Hours__c": {
          name: 'Friday_Hours__c',
          type: 'string'
        },
        "Friday_Hours_Auto__c": {
          name: 'Friday_Hours_Auto__c',
          type: 'number'
        },
        "Friday_Schedule__c": {
          name: 'Friday_Schedule__c',
          type: 'string'
        },
        "ICC_Case__c": {
          name: 'ICC_Case__c',
          type: 'string'
        },
        "Invoice__c": {
          name: 'Invoice__c',
          type: 'string'
        },
        "Is_Holiday_Friday__c": {
          name: 'Is_Holiday_Friday__c',
          type: 'boolean'
        },
        "Is_Holiday_Monday__c": {
          name: 'Is_Holiday_Monday__c',
          type: 'boolean'
        },
        "Is_Holiday_Saturday__c": {
          name: 'Is_Holiday_Saturday__c',
          type: 'boolean'
        },
        "Is_Holiday_Sunday__c": {
          name: 'Is_Holiday_Sunday__c',
          type: 'boolean'
        },
        "Is_Holiday_Thursday__c": {
          name: 'Is_Holiday_Thursday__c',
          type: 'boolean'
        },
        "Is_Holiday_Tuesday__c": {
          name: 'Is_Holiday_Tuesday__c',
          type: 'boolean'
        },
        "Is_Holiday_Wednesday__c": {
          name: 'Is_Holiday_Wednesday__c',
          type: 'boolean'
        },
        "Job__c": {
          name: 'Job__c',
          type: 'string'
        },
        "Job_Order__c": {
          name: 'Job_Order__c',
          type: 'string'
        },
        "Job_Start_Date_Tentative_Exact__c": {
          name: 'Job_Start_Date_Tentative_Exact__c',
          type: 'Date'
        },
        "Monday_Business_End_Time__c": {
          name: 'Monday_Business_End_Time__c',
          type: 'string'
        },
        "Business_Start_Time__c": {
          name: 'Business_Start_Time__c',
          type: 'string'
        },
        "Monday_Hours__c": {
          name: 'Monday_Hours__c',
          type: 'string'
        },
        "Monday_Hours_Auto__c": {
          name: 'Monday_Hours_Auto__c',
          type: 'number'
        },
        "Monday_Schedule__c": {
          name: 'Monday_Schedule__c',
          type: 'string'
        },
        "Other_Instructions__c": {
          name: 'Other_Instructions__c',
          type: 'string'
        },
        "PMS_Case__c": {
          name: 'PMS_Case__c',
          type: 'string'
        },
        "Pricelist__c": {
          name: 'Pricelist__c',
          type: 'string'
        },
        "Product__c": {
          name: 'Product__c',
          type: 'string'
        },
        "Recordtypeid__c": {
          name: 'Recordtypeid__c',
          type: 'string'
        },
        "Temp_Calc__c": {
          name: 'Temp_Calc__c',
          type: 'string'
        },
        "Saturday_Business_End_Time__c": {
          name: 'Saturday_Business_End_Time__c',
          type: 'string'
        },
        "Saturday_Business_Start_Time__c": {
          name: 'Saturday_Business_Start_Time__c',
          type: 'string'
        },
        "Saturday_Hours__c": {
          name: 'Saturday_Hours__c',
          type: 'string'
        },
        "Saturday_Hours_Auto__c": {
          name: 'Saturday_Hours_Auto__c',
          type: 'number'
        },
        "Saturday_Schedule__c": {
          name: 'Saturday_Schedule__c',
          type: 'string'
        },
        "Schedule__c": {
          name: 'Schedule__c',
          type: 'string'
        },
        "Service_Contract__c": {
          name: 'Service_Contract__c',
          type: 'string'
        },
        "Standard_Shift_End_Time__c": {
          name: 'Standard_Shift_End_Time__c',
          type: 'string'
        },
        "Standard_Shift_Start_Time__c": {
          name: 'Standard_Shift_Start_Time__c',
          type: 'string'
        },
        "Status__c": {
          name: 'Status__c',
          type: 'string'
        },
        "Sunday_Business_End_Time__c": {
          name: 'Sunday_Business_End_Time__c',
          type: 'string'
        },
        "Sunday_Business_Start_Time__c": {
          name: 'Sunday_Business_Start_Time__c',
          type: 'string'
        },
        "Sunday_Hours__c": {
          name: 'Sunday_Hours__c',
          type: 'string'
        },
        "Sunday_Hours_Auto__c": {
          name: 'Sunday_Hours_Auto__c',
          type: 'number'
        },
        "Sunday_Schedule__c": {
          name: 'Sunday_Schedule__c',
          type: 'string'
        },
        "Temp_Time_Calc__c": {
          name: 'Temp_Time_Calc__c',
          type: 'string'
        },
        "Thursday_Business_End_Time__c": {
          name: 'Thursday_Business_End_Time__c',
          type: 'string'
        },
        "Thursday_Business_Start_Time__c": {
          name: 'Thursday_Business_Start_Time__c',
          type: 'string'
        },
        "Thursday_Hours__c": {
          name: 'Thursday_Hours__c',
          type: 'string'
        },
        "Thursday_Hours_Auto__c": {
          name: 'Thursday_Hours_Auto__c',
          type: 'number'
        },
        "Thursday_Schedule__c": {
          name: 'Thursday_Schedule__c',
          type: 'string'
        },
        "Timecard_Timesheet__c": {
          name: 'Timecard_Timesheet__c',
          type: 'string'
        },
        "Total_Weekly_Hours__c": {
          name: 'Total_Weekly_Hours__c',
          type: 'number'
        },
        "Tuesday_Business_End_Time__c": {
          name: 'Tuesday_Business_End_Time__c',
          type: 'string'
        },
        "Tuesday_Business_Start_Time__c": {
          name: 'Tuesday_Business_Start_Time__c',
          type: 'string'
        },
        "Tuesday_Hours__c": {
          name: 'Tuesday_Hours__c',
          type: 'string'
        },
        "Tuesday_Hours_Auto__c": {
          name: 'Tuesday_Hours_Auto__c',
          type: 'number'
        },
        "Tuesday_Schedule__c": {
          name: 'Tuesday_Schedule__c',
          type: 'string'
        },
        "Wednesday_Business_End_Time__c": {
          name: 'Wednesday_Business_End_Time__c',
          type: 'string'
        },
        "Wednesday_Business_Start_Time__c": {
          name: 'Wednesday_Business_Start_Time__c',
          type: 'string'
        },
        "Wednesday_Hours__c": {
          name: 'Wednesday_Hours__c',
          type: 'string'
        },
        "Wednesday_Hours_Auto__c": {
          name: 'Wednesday_Hours_Auto__c',
          type: 'number'
        },
        "Wednesday_Schedule__c": {
          name: 'Wednesday_Schedule__c',
          type: 'string'
        },
        "Worker__c": {
          name: 'Worker__c',
          type: 'string'
        },
        "Worker_Schedule_Type__c": {
          name: 'Worker_Schedule_Type__c',
          type: 'string'
        },
        "Worker_Skilling_Profile__c": {
          name: 'Worker_Skilling_Profile__c',
          type: 'string'
        },
        "Work_Order__c": {
          name: 'Work_Order__c',
          type: 'string'
        },
        "Yearly_Hours__c": {
          name: 'Yearly_Hours__c',
          type: 'string'
        },
        "Monday_Business_End_Time_Formula__c": {
          name: 'Monday_Business_End_Time_Formula__c',
          type: 'string'
        },
        "Monday_Business_Start_Time_Formula__c": {
          name: 'Monday_Business_Start_Time_Formula__c',
          type: 'string'
        },
        "Tuesday_Business_End_Time_Formula__c": {
          name: 'Tuesday_Business_End_Time_Formula__c',
          type: 'string'
        },
        "Tuesday_Business_Start_Time_Formula__c": {
          name: 'Tuesday_Business_Start_Time_Formula__c',
          type: 'string'
        },
        "Wednesday_Business_End_Time_Formula__c": {
          name: 'Wednesday_Business_End_Time_Formula__c',
          type: 'string'
        },
        "Wednesday_Business_Start_Time_Formula__c": {
          name: 'Wednesday_Business_Start_Time_Formula__c',
          type: 'string'
        },
        "Thursday_Business_End_Time_Formula__c": {
          name: 'Thursday_Business_End_Time_Formula__c',
          type: 'string'
        },
        "Thursday_Business_Start_Time_Formula__c": {
          name: 'Thursday_Business_Start_Time_Formula__c',
          type: 'string'
        },
        "Friday_Business_End_Time_Formula__c": {
          name: 'Friday_Business_End_Time_Formula__c',
          type: 'string'
        },
        "Friday_Business_Start_Time_Formula__c": {
          name: 'Friday_Business_Start_Time_Formula__c',
          type: 'string'
        },
        "Saturday_Business_End_Time_Formula__c": {
          name: 'Saturday_Business_End_Time_Formula__c',
          type: 'string'
        },
        "Saturday_Business_Start_Time_Formula__c": {
          name: 'Saturday_Business_Start_Time_Formula__c',
          type: 'string'
        },
        "Sunday_Business_Start_Time_Formula__c": {
          name: 'Sunday_Business_Start_Time_Formula__c',
          type: 'string'
        },
        "Sunday_Business_End_Time_Formula__c": {
          name: 'Sunday_Business_End_Time_Formula__c',
          type: 'string'
        },
        "Standard_Shift_End_Time_Formula__c": {
          name: 'Standard_Shift_End_Time_Formula__c',
          type: 'string'
        },
        "Standard_Shift_Start_Time_Formula__c": {
          name: 'Standard_Shift_Start_Time_Formula__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        recordType: {
          name: 'recordType',
          type: 'RecordType',
          model: 'RecordType',
          relationType: 'belongsTo',
                  keyFrom: 'RecordTypeId',
          keyTo: 'sfdcId'
        },
        workOrder: {
          name: 'workOrder',
          type: 'WorkOrder',
          model: 'WorkOrder',
          relationType: 'belongsTo',
                  keyFrom: 'Work_Order__c',
          keyTo: 'sfdcId'
        },
        skilling: {
          name: 'skilling',
          type: 'Skilling',
          model: 'Skilling',
          relationType: 'belongsTo',
                  keyFrom: 'Worker_Skilling_Profile__c',
          keyTo: 'sfdcId'
        },
        worker: {
          name: 'worker',
          type: 'Worker',
          model: 'Worker',
          relationType: 'belongsTo',
                  keyFrom: 'Worker__c',
          keyTo: 'sfdcId'
        },
        timecard: {
          name: 'timecard',
          type: 'Timecard',
          model: 'Timecard',
          relationType: 'belongsTo',
                  keyFrom: 'Timecard_Timesheet__c',
          keyTo: 'sfdcId'
        },
        product: {
          name: 'product',
          type: 'Product',
          model: 'Product',
          relationType: 'belongsTo',
                  keyFrom: 'Product__c',
          keyTo: 'sfdcId'
        },
        pricelist: {
          name: 'pricelist',
          type: 'Pricelist',
          model: 'Pricelist',
          relationType: 'belongsTo',
                  keyFrom: 'Pricelist__c',
          keyTo: 'sfdcId'
        },
        pmsCase: {
          name: 'pmsCase',
          type: 'Case',
          model: 'Case',
          relationType: 'belongsTo',
                  keyFrom: 'PMS_Case__c',
          keyTo: 'sfdcId'
        },
        jobOrder: {
          name: 'jobOrder',
          type: 'JobOrderItem',
          model: 'JobOrderItem',
          relationType: 'belongsTo',
                  keyFrom: 'Job_Order__c',
          keyTo: 'sfdcId'
        },
        job: {
          name: 'job',
          type: 'Job',
          model: 'Job',
          relationType: 'belongsTo',
                  keyFrom: 'Job__c',
          keyTo: 'sfdcId'
        },
        serviceContract: {
          name: 'serviceContract',
          type: 'ServiceContract',
          model: 'ServiceContract',
          relationType: 'belongsTo',
                  keyFrom: 'Service_Contract__c',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
