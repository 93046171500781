import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IsBillableLookupComponent } from './is-billable-lookup.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule
  ],
  declarations: [IsBillableLookupComponent],
  exports: [IsBillableLookupComponent]
})
export class IsBillableLookupModule { }
