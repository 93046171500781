import { Component, OnInit, Input, EventEmitter, ViewChild, ElementRef, Output } from '@angular/core';

@Component({
  selector: 'app-custom-dual-list',
  templateUrl: './custom-dual-list.component.html',
  styleUrls: ['./custom-dual-list.component.css']
})

export class AppCustomDualListComponent implements OnInit {
  sourceCopy: any[] = [];
  destinationCopy: any[] = [];

  @Input() disabled = false;
  @Input() title;
  @Input() titleCssType = 0;
  @Input() bindValue;
  @Input() bindLabel;
  @Input() clearDestination = false;
  @Input() customClass: boolean = false;
  @Input() set destination(d) {
    this.destinationCopy = [...d];
    this.arrangeLanguageList();
  }
  @Input() set source(s) {
    this.sourceCopy = [...s];
    this.arrangeLanguageList();
    /*Commented below code as when we have all source data in destination and 
     source is empty then its creating issue by empting its destination too
     for Edit Part or data preselection part*/
    // if(this.sourceCopy && this.sourceCopy.length === 0){
    //   this.destinationCopy = [];
    // }
  }
  @Output() onSelectionChange: EventEmitter<any> = new EventEmitter;
  @ViewChild('listBox') listBox: ElementRef;
  selectedItems = { toAdd: [], toRemove: [] };
  ngOnInit() {
  }

  arrangeLanguageList() {
    if (this.sourceCopy && this.sourceCopy.length && this.destinationCopy && this.destinationCopy.length) {
      this.destinationCopy.forEach(element => {
        if (this.bindValue) {
          if (this.sourceCopy.filter(item => item[this.bindValue] === element[this.bindValue]).length > 0) {
            this.sourceCopy.splice(this.sourceCopy.findIndex(findElement => findElement[this.bindValue] === element[this.bindValue]), 1);
          }
        } else {
          if (this.sourceCopy.filter(item => item.value === element.value).length > 0) {
            this.sourceCopy.splice(this.sourceCopy.findIndex(findElement => findElement.value === element.value), 1);
          }
        }
      });
    }
    // this.selectionChange();
  }

  addItems() {
    if (this.selectedItems && this.selectedItems.toAdd.length) {
      this.destinationCopy = this.destinationCopy.concat(this.selectedItems.toAdd);
      this.selectedItems.toAdd.forEach(element => {
        this.sourceCopy.splice(this.sourceCopy.indexOf(element), 1);
      });
    }
    this.selectionChange();
  }

  removeItems() {
    if (this.selectedItems && this.selectedItems.toRemove.length) {
      this.sourceCopy = this.sourceCopy.concat(this.selectedItems.toRemove);
      this.selectedItems.toRemove.forEach(element => {
        this.destinationCopy.splice(this.destinationCopy.indexOf(element), 1);
      });
    }
    this.selectionChange();
  }

  selectionChange() {
    this.selectedItems = { toAdd: [], toRemove: [] };
    this.onSelectionChange.emit(this.bindValue ? this.destinationCopy.map(i => i[this.bindValue]) : this.destinationCopy);
  }

  listbox_move(direction) {
    const listbox = this.listBox.nativeElement;
    const selIndex = listbox.selectedIndex;

    if (selIndex === -1) {
      alert('Please select an option to move.');
      return false;
    }

    let increment = -1;
    if (direction === 'up') {
      increment = -1;
    } else {
      increment = 1;
    }

    if ((selIndex + increment) < 0 ||
      (selIndex + increment) > (listbox.options.length - 1)) {
      return;
    }

    const selValue = listbox.options[selIndex].value;
    const selText = listbox.options[selIndex].text;
    listbox.options[selIndex].value = listbox.options[selIndex + increment].value
    listbox.options[selIndex].text = listbox.options[selIndex + increment].text

    listbox.options[selIndex + increment].value = selValue;
    listbox.options[selIndex + increment].text = selText;

    listbox.selectedIndex = selIndex + increment;
  }
}
