import { Component, OnInit, Input } from '@angular/core';
import { NotificationService } from 'shared/services/notification.service';
import { PreloaderService } from 'shared/services/preloader.service';
import { environment } from 'environments/environment';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-task-conversation-activity-feed',
  templateUrl: './task-conversation-activity-feed.component.html',
  styleUrls: ['./task-conversation-activity-feed.component.css']
})
export class TaskConversationActivityFeedComponent implements OnInit {

  imgpath: any;
  activityData = [];

  _selectedItem: any;

  page = {
    size: 10,
    totalElements: 0,
    pageNumber: 1
  };
  currentPage = 0;
  pageEvent: PageEvent;
  @Input() task;
  @Input() globalChat;
  @Input()
  set selectedTask(e) {
    this._selectedItem = e;
    this.page.pageNumber = 1;
    this.page.totalElements = 0;
    this.currentPage = 0;
    this.activityData = [];

    if (e && e.task && e.task.sfdcId) {
      this.getFeeds(this.page);
    } else {
      this._selectedItem = null;
    }
  }

  constructor(private _notificationService: NotificationService,
    private _loader: PreloaderService) { }

  ngOnInit() {
    this.setPage(this.page);
    this.imgpath = environment.baseUrl + '/' + environment.apiVersion;
  }

  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.pageNumber;
 //   this.getFeeds(this.page);
  }

  getFeeds(offset) {
    const getProfileImageUrl = (item) => {
      if (this.imgpath.indexOf('localhost') !== -1) {
        this.imgpath = 'https://den.dev.serviceo.me/api/v1';
      }
      if (item) {
        if (item.userProfileImage && item.userProfileUrl) {
          return `${this.imgpath}/Containers${item.userProfileUrl}${item.userProfileImage}`;
        } else if (item.gender) {
          if (item.gender === 'Male') {
            return `assets/images/male-circle.png`;
          } else if (item.gender === 'Female') {
            return `assets/images/female-circle.png`;
          }
        } else {
          return `assets/images/computer.png`;
        }
      } else {
        return `assets/images/computer.png`;
      }
    };

    let whereObj = { 'taskId': this._selectedItem && this._selectedItem.task && this._selectedItem.task ?
                      this._selectedItem.task.sfdcId : (this.task ? this.task.sfdcId : '') };
    this._loader.showPreloader();
    this._notificationService
      .getTaskActivityFeed(offset["pageNumber"], whereObj).then((res: any) => {
        this._loader.hidePreloader();
        if (res && res['data']) {
          res['data'].map(item => {
            if (item && item['user']) {
              item['user']['profileImg'] = getProfileImageUrl(item['user']);
            }
          });
          this.activityData = res['data'];
          this.page.totalElements = res['totalCount'] ? res['totalCount'] : 0;
        }
      })
      .catch(err => {
        this._loader.hidePreloader();
        console.log(err);
      });
  }

  getServerData(event: PageEvent) {
    this.currentPage = event.pageIndex;
    this.page.pageNumber = this.currentPage + 1;
    this.getFeeds(this.page);
  }
}
