import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Options } from 'ng5-slider';

@Component({
  selector: 'app-range-slider-lookup',
  templateUrl: './range-slider-lookup.component.html',
  styleUrls: ['./range-slider-lookup.component.css']
})
export class RangeSliderLookupComponent implements OnInit {

  @Input() set resetData(isClear) {
    if (isClear) {
      this.selectedValue.min = this.options.floor;
      this.selectedValue.max = this.options.floor;
    }
  }

  @Input() set rangeData(e) {
    this.canInput = e.canInput;
    if (e.min) {
      this.selectedValue.min = e.min;
      this.selectedValue.max = e.min;
      this.options.floor = e.min;
    }
    if (e.max) {
      this.options.ceil = e.max;
    }
    if (e.label) {
      this.title = e.label;
    }
    if (e.key) {
      this.key = e.key;
    }
    if (e.stepValue) {
      this.options.step = e.stepValue;
    }
  }

  @Output() getSelectedRange: EventEmitter<any> = new EventEmitter;

  title = '';
  key = 'range';
  canInput = false;

  selectedValue = {
    min: 0,
    max: 0
  }

  options: Options = {
    floor: 0,
    ceil: 0,
    step: 1
  };

  constructor() { }

  ngOnInit() {
    const preSelected = localStorage.getItem('filterObj');
    if (preSelected && JSON.parse(preSelected) &&
      JSON.parse(preSelected)[this.key] && JSON.parse(preSelected)[this.key].length) {
      this.selectedValue = JSON.parse(preSelected)[this.key][0];
    }
  }

  rangeChange(e) {
    this.selectedValue.max = e.highValue;
    this.selectedValue.min = e.value;
    this.getSelectedRange.emit(this.selectedValue);
  }

}
