/* tslint:disable */
import {
  TimeClockManager,
  TimeChangeRequest
} from '../index';

declare var Object: any;
export interface TimeBreakInterface {
  "sfdcId"?: string;
  "Name"?: string;
  "CreatedById"?: string;
  "LastModifiedById"?: string;
  "CurrencyIsoCode"?: string;
  "End_Break__c"?: Date;
  "Start_Break__c"?: Date;
  "Timeclock_Entry__c"?: string;
  "Total_Time_on_Break_Minutes__c"?: number;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  timeClockManager?: TimeClockManager;
  timeChangeRequests?: TimeChangeRequest[];
}

export class TimeBreak implements TimeBreakInterface {
  "sfdcId": string;
  "Name": string;
  "CreatedById": string;
  "LastModifiedById": string;
  "CurrencyIsoCode": string;
  "End_Break__c": Date;
  "Start_Break__c": Date;
  "Timeclock_Entry__c": string;
  "Total_Time_on_Break_Minutes__c": number;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  timeClockManager: TimeClockManager;
  timeChangeRequests: TimeChangeRequest[];
  constructor(data?: TimeBreakInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `TimeBreak`.
   */
  public static getModelName() {
    return "TimeBreak";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of TimeBreak for dynamic purposes.
  **/
  public static factory(data: TimeBreakInterface): TimeBreak{
    return new TimeBreak(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'TimeBreak',
      plural: 'TimeBreaks',
      path: 'TimeBreaks',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "End_Break__c": {
          name: 'End_Break__c',
          type: 'Date'
        },
        "Start_Break__c": {
          name: 'Start_Break__c',
          type: 'Date'
        },
        "Timeclock_Entry__c": {
          name: 'Timeclock_Entry__c',
          type: 'string'
        },
        "Total_Time_on_Break_Minutes__c": {
          name: 'Total_Time_on_Break_Minutes__c',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        timeClockManager: {
          name: 'timeClockManager',
          type: 'TimeClockManager',
          model: 'TimeClockManager',
          relationType: 'belongsTo',
                  keyFrom: 'Timeclock_Entry__c',
          keyTo: 'sfdcId'
        },
        timeChangeRequests: {
          name: 'timeChangeRequests',
          type: 'TimeChangeRequest[]',
          model: 'TimeChangeRequest',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'Time_Break__c'
        },
      }
    }
  }
}
