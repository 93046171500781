/* tslint:disable */
import {
  GeoMetro,
  TalentType,
  Contact
} from '../index';

declare var Object: any;
export interface JobSearchAlertInterface {
  "sfdcId"?: string;
  "CreatedById"?: string;
  "CurrencyIsoCode"?: string;
  "Name"?: string;
  "LastModifiedById"?: string;
  "Coverage_Hours__c"?: string;
  "Created_by__c"?: string;
  "Email__c"?: string;
  "GEO_Code__c"?: string;
  "Job_Alert_Name__c"?: string;
  "Modified_By__c"?: string;
  "SLA__c"?: string;
  "Status__c"?: string;
  "Talent_Type__c"?: string;
  "Technical_Level__c"?: string;
  "Valid_Until__c"?: Date;
  "Vendor__c"?: string;
  "createdOnServiceo"?: boolean;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  geoMetro?: GeoMetro;
  talentType?: TalentType;
  createdByContact?: Contact;
  vendorContact?: Contact;
}

export class JobSearchAlert implements JobSearchAlertInterface {
  "sfdcId": string;
  "CreatedById": string;
  "CurrencyIsoCode": string;
  "Name": string;
  "LastModifiedById": string;
  "Coverage_Hours__c": string;
  "Created_by__c": string;
  "Email__c": string;
  "GEO_Code__c": string;
  "Job_Alert_Name__c": string;
  "Modified_By__c": string;
  "SLA__c": string;
  "Status__c": string;
  "Talent_Type__c": string;
  "Technical_Level__c": string;
  "Valid_Until__c": Date;
  "Vendor__c": string;
  "createdOnServiceo": boolean;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  geoMetro: GeoMetro;
  talentType: TalentType;
  createdByContact: Contact;
  vendorContact: Contact;
  constructor(data?: JobSearchAlertInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `JobSearchAlert`.
   */
  public static getModelName() {
    return "JobSearchAlert";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of JobSearchAlert for dynamic purposes.
  **/
  public static factory(data: JobSearchAlertInterface): JobSearchAlert{
    return new JobSearchAlert(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'JobSearchAlert',
      plural: 'JobSearchAlerts',
      path: 'JobSearchAlerts',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "Coverage_Hours__c": {
          name: 'Coverage_Hours__c',
          type: 'string'
        },
        "Created_by__c": {
          name: 'Created_by__c',
          type: 'string'
        },
        "Email__c": {
          name: 'Email__c',
          type: 'string'
        },
        "GEO_Code__c": {
          name: 'GEO_Code__c',
          type: 'string'
        },
        "Job_Alert_Name__c": {
          name: 'Job_Alert_Name__c',
          type: 'string'
        },
        "Modified_By__c": {
          name: 'Modified_By__c',
          type: 'string'
        },
        "SLA__c": {
          name: 'SLA__c',
          type: 'string'
        },
        "Status__c": {
          name: 'Status__c',
          type: 'string'
        },
        "Talent_Type__c": {
          name: 'Talent_Type__c',
          type: 'string'
        },
        "Technical_Level__c": {
          name: 'Technical_Level__c',
          type: 'string'
        },
        "Valid_Until__c": {
          name: 'Valid_Until__c',
          type: 'Date'
        },
        "Vendor__c": {
          name: 'Vendor__c',
          type: 'string'
        },
        "createdOnServiceo": {
          name: 'createdOnServiceo',
          type: 'boolean'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        geoMetro: {
          name: 'geoMetro',
          type: 'GeoMetro',
          model: 'GeoMetro',
          relationType: 'belongsTo',
                  keyFrom: 'GEO_Code__c',
          keyTo: 'sfdcId'
        },
        talentType: {
          name: 'talentType',
          type: 'TalentType',
          model: 'TalentType',
          relationType: 'belongsTo',
                  keyFrom: 'Talent_Type__c',
          keyTo: 'sfdcId'
        },
        createdByContact: {
          name: 'createdByContact',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Created_by__c',
          keyTo: 'sfdcId'
        },
        vendorContact: {
          name: 'vendorContact',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Vendor__c',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
