/* tslint:disable */
import {
  RecordType,
  Worker,
  Jobsite,
  Job,
  Users,
  Project,
  Case,
  WorkOrder,
  Account,
  TalentType,
  Product,
  JobSchedule
} from '../index';

declare var Object: any;
export interface ServiceContractInterface {
  "sfdcId"?: string;
  "AccountId"?: string;
  "Account_Code_A100001__c"?: string;
  "Account_Name__c"?: string;
  "ActivationDate"?: Date;
  "Actual_Shipping_Date__c"?: Date;
  "Additional_Hours_T_M_Price_Partner__c"?: number;
  "Additional_Hours_T_M_Price_Vendor__c"?: number;
  "Annual_Invoice__c"?: string;
  "ApprovalStatus"?: string;
  "Approved_Customer_Schedule_Count__c"?: number;
  "Approved_Worker_Schedule_Count__c"?: number;
  "Asset_Contract_Type__c"?: string;
  "Asset_Serial_Number__c"?: string;
  "Asset_Status__c"?: string;
  "Asset__c"?: string;
  "Carrier_Instructions__c"?: string;
  "Carrier_Scac__c"?: string;
  "ContactId"?: string;
  "Contact_Name_at_Customer_Site__c"?: string;
  "Contact_Type__c"?: string;
  "ContractNumber"?: string;
  "Contract_Advance_Pay_End_Date__c"?: Date;
  "Contract_Advance_Pay_Start_Date__c"?: Date;
  "Contract_New_Registration_Number__c"?: string;
  "Contract_New_Registration_Reference_ID__c"?: string;
  "Contract_Order_Request_Active__c"?: string;
  "Contract_Ownership__c"?: string;
  "Contract_Reference_LOTS__c"?: string;
  "Contract_Renewal_Registration_End_Date__c"?: Date;
  "Contract_Renewal_Registration_Start_Dat__c"?: Date;
  "Contract_Stage__c"?: string;
  "Count_of_Secondary_Worker__c"?: number;
  "Count_of_Service_Contract_Line_Item__c"?: number;
  "Coverage_Order_Prepaid_Date__c"?: Date;
  "Create_Change_Request_Order__c"?: boolean;
  "Create_ICC_Case__c"?: boolean;
  "Create_Partner_Request__c"?: boolean;
  "Create_Work_Order__c"?: boolean;
  "CreatedById"?: string;
  "CreatedDate"?: Date;
  "Created_Automatically__c"?: boolean;
  "CurrencyIsoCode"?: string;
  "Custom_Partner_Pricing__c"?: boolean;
  "Custom_Price_Version_Created_Partner__c"?: boolean;
  "Custom_Price_Version_Created_Vendor__c"?: boolean;
  "Custom_Price_Version_Partner__c"?: string;
  "Custom_Price_Version_Vendor__c"?: string;
  "Custom_Vendor_Pricing_Backfill__c"?: boolean;
  "Custom_Vendor_Pricing__c"?: boolean;
  "Customer_Account__c"?: string;
  "Customer_PO_Date__c"?: Date;
  "Customer_PO__c"?: string;
  "Customer_Po_Line__c"?: string;
  "Customer_Pricelist__c"?: string;
  "Customer_SO_Number__c"?: string;
  "Customer_Schedule_Friday_Hours__c"?: number;
  "Customer_Schedule_Monday_Hours__c"?: number;
  "Customer_Schedule_Saturday_Hours__c"?: number;
  "Customer_Schedule_Sunday_Hours__c"?: number;
  "Customer_Schedule_Thursday_Hours__c"?: number;
  "Customer_Schedule_Tuesday_Hours__c"?: number;
  "Customer_Schedule_Wednesday_Hours__c"?: number;
  "Customs_Broker__c"?: string;
  "Daily_Average__c"?: number;
  "Daily_Rate__c"?: number;
  "Daily_Standard_Work_Hours__c"?: number;
  "Days_Week_Schedule__c"?: number;
  "Days_left_on_Contract__c"?: number;
  "Delivery_Date__c"?: Date;
  "Description"?: string;
  "Discount"?: number;
  "EOR_Party__c"?: string;
  "EndDate"?: Date;
  "End_User__c"?: string;
  "FTE_Configuration_Type__c"?: string;
  "FTE_Hours_Monthly__c"?: number;
  "FTE_Monthly_Rate_Partner__c"?: number;
  "FTE_Monthly_Rate_Vendor_Backfill__c"?: number;
  "FTE_Monthly_Rate_Vendor__c"?: number;
  "FTE_Unit_Count__c"?: number;
  "Final_Total_After_Tax__c"?: number;
  "Generate_Name__c"?: boolean;
  "GrandTotal"?: number;
  "Health_Account_and_Pricelist__c"?: string;
  "Health_Asset_Account__c"?: string;
  "Health_Asset__c"?: string;
  "Health_Backfill__c"?: string;
  "Health_HMS_SKU__c"?: string;
  "Health_Jobsite_Address__c"?: boolean;
  "Health_Jobsite__c"?: string;
  "Health_Price__c"?: string;
  "Health_Pricelist_Type__c"?: string;
  "Health_SKU__c"?: string;
  "Health_SOP_Pricelist_Status__c"?: string;
  "Health_SOP_Program_Status__c"?: string;
  "Health_Schedule_Match__c"?: string;
  "Health_Standard_Pricelist__c"?: string;
  "Health_Start_End_Date__c"?: string;
  "Health_Talent_Type__c"?: string;
  "Health_WO__c"?: string;
  "Helpdesk_Dispatch_Fee_Partner__c"?: number;
  "Helpdesk_Dispatch_Fee_Vendor_Backfill__c"?: number;
  "Helpdesk_Dispatch_Fee_Vendor__c"?: number;
  "ICC_Case_Created__c"?: boolean;
  "IOR_Party__c"?: string;
  "IRON_Partner_Customer_Order_Status__c"?: string;
  "Importer_IOR__c"?: string;
  "Incoterm__c"?: string;
  "Initiate_Contract_update__c"?: boolean;
  "Instructions__c"?: string;
  "Insurance_Party__c"?: string;
  "Inventory_Pool__c"?: string;
  "Iron_Service_Contract__c"?: string;
  "IsDeleted"?: boolean;
  "JPC_Case__c"?: string;
  "Job_Application__c"?: string;
  "Job_Instruction_Service_Description__c"?: string;
  "Job_Instructions_Description__c"?: string;
  "Job_Instructions_Required_Tools__c"?: string;
  "Job_Instructions_Service_Deliverables__c"?: string;
  "Job_Instructions_Special_Instruction__c"?: string;
  "Job_Instructions_Summary__c"?: string;
  "Job_Instructions_Training_Documents__c"?: string;
  "Job_Instructions_Worker_Specific_Req__c"?: string;
  "Job_Order__c"?: string;
  "Job_Reference_Auto__c"?: string;
  "Job_Schedule_count__c"?: number;
  "Jobsite_Project_Project__c"?: string;
  "Jobsite__c"?: string;
  "LastModifiedById"?: string;
  "LastModifiedDate"?: Date;
  "LastReferencedDate"?: Date;
  "LastViewedDate"?: Date;
  "Last_Contract_Order_Change_Date__c"?: Date;
  "Last_Day_of_the_Contract_Start_Month__c"?: Date;
  "Last_Invoice_Date__c"?: Date;
  "Last_Monthly_Invoice_Date__c"?: Date;
  "Last_date_for_WO__c"?: Date;
  "LineItemCount"?: number;
  "List_Price__c"?: number;
  "Migrated__c"?: boolean;
  "Monthly_Rate__c"?: number;
  "Name"?: string;
  "New_Order_Request_Raised__c"?: boolean;
  "Overall_Health_Good__c"?: boolean;
  "Overall_Health_Good_for_SO__c"?: boolean;
  "Overall_Health_Status__c"?: boolean;
  "OwnerId"?: string;
  "Owner_Expiration_Notice__c"?: string;
  "PMS_Case__c"?: string;
  "PO_Line_Number__c"?: string;
  "PPE_1HR_Standard_Price_Partner__c"?: number;
  "PPE_1HR_Standard_Price_Vendor_Backfill__c"?: number;
  "PPE_1HR_Standard_Price_Vendor__c"?: number;
  "PPE_2HR_Standard_Price_Partner__c"?: number;
  "PPE_2HR_Standard_Price_Vendor_Backfill__c"?: number;
  "PPE_2HR_Standard_Price_Vendor__c"?: number;
  "PPE_3HR_Standard_Price_Partner__c"?: number;
  "PPE_3HR_Standard_Price_Vendor_Backfill__c"?: number;
  "PPE_3HR_Standard_Price_Vendor__c"?: number;
  "PPE_4HR_Standard_Price_Partner__c"?: number;
  "PPE_4HR_Standard_Price_Vendor_Backfill__c"?: number;
  "PPE_4HR_Standard_Price_Vendor__c"?: number;
  "PPE_8HR_Standard_Price_Partner__c"?: number;
  "PPE_8HR_Standard_Price_Vendor_Backfill__c"?: number;
  "PPE_8HR_Standard_Price_Vendor__c"?: number;
  "Partner_Account_Name__c"?: string;
  "Partner_Account__c"?: string;
  "Partner_Currency__c"?: string;
  "Partner_Customer_Registration_Status__c"?: string;
  "Partner_FTE_Hourly_Rate_Holiday__c"?: number;
  "Partner_FTE_Hourly_Rate_Overtime__c"?: number;
  "Partner_Order_Notes__c"?: string;
  "Partner_PO_Number__c"?: string;
  "Partner_Pricelist__c"?: string;
  "Partner_Reference_Number__c"?: string;
  "Partner_Renewal_Contract_PO__c"?: string;
  "Partner_Status__c"?: string;
  "Payment_Option__c"?: string;
  "Payment_Term__c"?: string;
  "Pricebook2Id"?: string;
  "Pricing_Coverage_Model_Period__c"?: string;
  "Primary_Job_Schedule__c"?: string;
  "Product_Backfill__c"?: string;
  "Product_Primary__c"?: string;
  "Product_Code__c"?: string;
  "Program__c"?: string;
  "Purchase_Order__c"?: string;
  "Quantity__c"?: number;
  "RecordId__c"?: string;
  "RecordTypeId"?: string;
  "RecordTypeId__c"?: string;
  "Reference_Contract__c"?: string;
  "Reference_Number__c"?: string;
  "Renewal_Contact_Email__c"?: string;
  "Renewal_Contact_Phone__c"?: string;
  "Roll_Profile_Count_Primary__c"?: number;
  "SCId__c"?: string;
  "Sales_Order_Active__c"?: string;
  "Schedule_Type_Weekly_Monthly__c"?: string;
  "Schedule__c"?: string;
  "Service_Contract_End_Date__c"?: Date;
  "Service_Contract_Number__c"?: string;
  "Service_Contract_Start_Date__c"?: Date;
  "Service_Description__c"?: string;
  "Service_Dispatch_Frequency__c"?: string;
  "Service_Level_OLD__c"?: string;
  "Service_Name__c"?: string;
  "Service_SKU__c"?: string;
  "Ship_Date__c"?: Date;
  "Ship_Mode__c"?: string;
  "Ship_Partial__c"?: string;
  "Ship_To_Party__c"?: string;
  "Sold_To_Party__c"?: string;
  "ShippingAddress"?: string;
  "SpecialTerms"?: string;
  "StartDate"?: Date;
  "Status"?: string;
  "Sub_Inventory_Location__c"?: string;
  "Sub_Total_Auto__c"?: number;
  "Subtotal"?: number;
  "Support_Plan_Type__c"?: string;
  "Support_Registration_Type__c"?: string;
  "Talent_Type__c"?: string;
  "Tax"?: number;
  "Term"?: number;
  "Time_Bank__c"?: string;
  "TotalPrice"?: number;
  "Total_Contract_Hours__c"?: number;
  "Total_Planned_Hours__c"?: number;
  "Total_Price_Customer_PostVAT_Rollup__c"?: number;
  "Total_Price__c"?: number;
  "Total_Weekly_Hours_Customer_Schedule__c"?: number;
  "Total_Weekly_Hours_Worker_Schedule__c"?: number;
  "Total_Work_Weeks__c"?: number;
  "Total_Yearly_Hours__c"?: number;
  "Unit_Price_FTE_Daily_Rate__c"?: number;
  "VAT_Excluded__c"?: boolean;
  "VAT_GST_Country__c"?: string;
  "VAT_GST__c"?: number;
  "VAT_Payee__c"?: string;
  "Vendor_Backfill__c"?: string;
  "Vendor_FTE_Hourly_Rate_Holiday_Backfill__c"?: number;
  "Vendor_FTE_Hourly_Rate_Holiday__c"?: number;
  "Vendor_FTE_Hourly_Rate_Overtime_Backfill__c"?: number;
  "Vendor_FTE_Hourly_Rate_Overtime__c"?: number;
  "Vendor_Pricelist_Backfill__c"?: string;
  "Vendor_Pricelist__c"?: string;
  "Vendor_Primary__c"?: string;
  "Weekly_Rate__c"?: number;
  "Work_Description__c"?: string;
  "Work_Order_Created__c"?: boolean;
  "Work_Order__c"?: string;
  "Work_Summary__c"?: string;
  "Worker_Schedule_Friday_Hours__c"?: number;
  "Worker_Schedule_Monday_Hours__c"?: number;
  "Worker_Schedule_Saturday_Hours__c"?: number;
  "Worker_Schedule_Sunday_Hours__c"?: number;
  "Worker_Schedule_Thursday_Hours__c"?: number;
  "Worker_Schedule_Tuesday_Hours__c"?: number;
  "Worker_Schedule_Wednesday_Hours__c"?: number;
  "Worker__c"?: string;
  "X3PL_FSL_Location_Default__c"?: string;
  "X3PL_FSL_Location_Gateway__c"?: string;
  "X3PL_Vendor__c"?: string;
  "X3PS_Vendor_Contract_End_Date__c"?: Date;
  "X3PS_Vendor_Contract_Reference_Number__c"?: string;
  "X3PS_Vendor_Contract_Start_Date__c"?: Date;
  "X3PS_Vendor_Contract_Terms__c"?: number;
  "X3PS_Vendor__c"?: string;
  "Region"?: string;
  "CountryCode"?: string;
  "PMS_Case_Status"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  recordType?: RecordType;
  worker?: Worker;
  jobsite?: Jobsite;
  jobs?: Job[];
  createdBy?: Users;
  program?: Project;
  pmsCase?: Case;
  workOrder?: WorkOrder;
  vendor?: Account;
  talentType?: TalentType;
  product?: Product;
  jobSchedule?: JobSchedule[];
}

export class ServiceContract implements ServiceContractInterface {
  "sfdcId": string;
  "AccountId": string;
  "Account_Code_A100001__c": string;
  "Account_Name__c": string;
  "ActivationDate": Date;
  "Actual_Shipping_Date__c": Date;
  "Additional_Hours_T_M_Price_Partner__c": number;
  "Additional_Hours_T_M_Price_Vendor__c": number;
  "Annual_Invoice__c": string;
  "ApprovalStatus": string;
  "Approved_Customer_Schedule_Count__c": number;
  "Approved_Worker_Schedule_Count__c": number;
  "Asset_Contract_Type__c": string;
  "Asset_Serial_Number__c": string;
  "Asset_Status__c": string;
  "Asset__c": string;
  "Carrier_Instructions__c": string;
  "Carrier_Scac__c": string;
  "ContactId": string;
  "Contact_Name_at_Customer_Site__c": string;
  "Contact_Type__c": string;
  "ContractNumber": string;
  "Contract_Advance_Pay_End_Date__c": Date;
  "Contract_Advance_Pay_Start_Date__c": Date;
  "Contract_New_Registration_Number__c": string;
  "Contract_New_Registration_Reference_ID__c": string;
  "Contract_Order_Request_Active__c": string;
  "Contract_Ownership__c": string;
  "Contract_Reference_LOTS__c": string;
  "Contract_Renewal_Registration_End_Date__c": Date;
  "Contract_Renewal_Registration_Start_Dat__c": Date;
  "Contract_Stage__c": string;
  "Count_of_Secondary_Worker__c": number;
  "Count_of_Service_Contract_Line_Item__c": number;
  "Coverage_Order_Prepaid_Date__c": Date;
  "Create_Change_Request_Order__c": boolean;
  "Create_ICC_Case__c": boolean;
  "Create_Partner_Request__c": boolean;
  "Create_Work_Order__c": boolean;
  "CreatedById": string;
  "CreatedDate": Date;
  "Created_Automatically__c": boolean;
  "CurrencyIsoCode": string;
  "Custom_Partner_Pricing__c": boolean;
  "Custom_Price_Version_Created_Partner__c": boolean;
  "Custom_Price_Version_Created_Vendor__c": boolean;
  "Custom_Price_Version_Partner__c": string;
  "Custom_Price_Version_Vendor__c": string;
  "Custom_Vendor_Pricing_Backfill__c": boolean;
  "Custom_Vendor_Pricing__c": boolean;
  "Customer_Account__c": string;
  "Customer_PO_Date__c": Date;
  "Customer_PO__c": string;
  "Customer_Po_Line__c": string;
  "Customer_Pricelist__c": string;
  "Customer_SO_Number__c": string;
  "Customer_Schedule_Friday_Hours__c": number;
  "Customer_Schedule_Monday_Hours__c": number;
  "Customer_Schedule_Saturday_Hours__c": number;
  "Customer_Schedule_Sunday_Hours__c": number;
  "Customer_Schedule_Thursday_Hours__c": number;
  "Customer_Schedule_Tuesday_Hours__c": number;
  "Customer_Schedule_Wednesday_Hours__c": number;
  "Customs_Broker__c": string;
  "Daily_Average__c": number;
  "Daily_Rate__c": number;
  "Daily_Standard_Work_Hours__c": number;
  "Days_Week_Schedule__c": number;
  "Days_left_on_Contract__c": number;
  "Delivery_Date__c": Date;
  "Description": string;
  "Discount": number;
  "EOR_Party__c": string;
  "EndDate": Date;
  "End_User__c": string;
  "FTE_Configuration_Type__c": string;
  "FTE_Hours_Monthly__c": number;
  "FTE_Monthly_Rate_Partner__c": number;
  "FTE_Monthly_Rate_Vendor_Backfill__c": number;
  "FTE_Monthly_Rate_Vendor__c": number;
  "FTE_Unit_Count__c": number;
  "Final_Total_After_Tax__c": number;
  "Generate_Name__c": boolean;
  "GrandTotal": number;
  "Health_Account_and_Pricelist__c": string;
  "Health_Asset_Account__c": string;
  "Health_Asset__c": string;
  "Health_Backfill__c": string;
  "Health_HMS_SKU__c": string;
  "Health_Jobsite_Address__c": boolean;
  "Health_Jobsite__c": string;
  "Health_Price__c": string;
  "Health_Pricelist_Type__c": string;
  "Health_SKU__c": string;
  "Health_SOP_Pricelist_Status__c": string;
  "Health_SOP_Program_Status__c": string;
  "Health_Schedule_Match__c": string;
  "Health_Standard_Pricelist__c": string;
  "Health_Start_End_Date__c": string;
  "Health_Talent_Type__c": string;
  "Health_WO__c": string;
  "Helpdesk_Dispatch_Fee_Partner__c": number;
  "Helpdesk_Dispatch_Fee_Vendor_Backfill__c": number;
  "Helpdesk_Dispatch_Fee_Vendor__c": number;
  "ICC_Case_Created__c": boolean;
  "IOR_Party__c": string;
  "IRON_Partner_Customer_Order_Status__c": string;
  "Importer_IOR__c": string;
  "Incoterm__c": string;
  "Initiate_Contract_update__c": boolean;
  "Instructions__c": string;
  "Insurance_Party__c": string;
  "Inventory_Pool__c": string;
  "Iron_Service_Contract__c": string;
  "IsDeleted": boolean;
  "JPC_Case__c": string;
  "Job_Application__c": string;
  "Job_Instruction_Service_Description__c": string;
  "Job_Instructions_Description__c": string;
  "Job_Instructions_Required_Tools__c": string;
  "Job_Instructions_Service_Deliverables__c": string;
  "Job_Instructions_Special_Instruction__c": string;
  "Job_Instructions_Summary__c": string;
  "Job_Instructions_Training_Documents__c": string;
  "Job_Instructions_Worker_Specific_Req__c": string;
  "Job_Order__c": string;
  "Job_Reference_Auto__c": string;
  "Job_Schedule_count__c": number;
  "Jobsite_Project_Project__c": string;
  "Jobsite__c": string;
  "LastModifiedById": string;
  "LastModifiedDate": Date;
  "LastReferencedDate": Date;
  "LastViewedDate": Date;
  "Last_Contract_Order_Change_Date__c": Date;
  "Last_Day_of_the_Contract_Start_Month__c": Date;
  "Last_Invoice_Date__c": Date;
  "Last_Monthly_Invoice_Date__c": Date;
  "Last_date_for_WO__c": Date;
  "LineItemCount": number;
  "List_Price__c": number;
  "Migrated__c": boolean;
  "Monthly_Rate__c": number;
  "Name": string;
  "New_Order_Request_Raised__c": boolean;
  "Overall_Health_Good__c": boolean;
  "Overall_Health_Good_for_SO__c": boolean;
  "Overall_Health_Status__c": boolean;
  "OwnerId": string;
  "Owner_Expiration_Notice__c": string;
  "PMS_Case__c": string;
  "PO_Line_Number__c": string;
  "PPE_1HR_Standard_Price_Partner__c": number;
  "PPE_1HR_Standard_Price_Vendor_Backfill__c": number;
  "PPE_1HR_Standard_Price_Vendor__c": number;
  "PPE_2HR_Standard_Price_Partner__c": number;
  "PPE_2HR_Standard_Price_Vendor_Backfill__c": number;
  "PPE_2HR_Standard_Price_Vendor__c": number;
  "PPE_3HR_Standard_Price_Partner__c": number;
  "PPE_3HR_Standard_Price_Vendor_Backfill__c": number;
  "PPE_3HR_Standard_Price_Vendor__c": number;
  "PPE_4HR_Standard_Price_Partner__c": number;
  "PPE_4HR_Standard_Price_Vendor_Backfill__c": number;
  "PPE_4HR_Standard_Price_Vendor__c": number;
  "PPE_8HR_Standard_Price_Partner__c": number;
  "PPE_8HR_Standard_Price_Vendor_Backfill__c": number;
  "PPE_8HR_Standard_Price_Vendor__c": number;
  "Partner_Account_Name__c": string;
  "Partner_Account__c": string;
  "Partner_Currency__c": string;
  "Partner_Customer_Registration_Status__c": string;
  "Partner_FTE_Hourly_Rate_Holiday__c": number;
  "Partner_FTE_Hourly_Rate_Overtime__c": number;
  "Partner_Order_Notes__c": string;
  "Partner_PO_Number__c": string;
  "Partner_Pricelist__c": string;
  "Partner_Reference_Number__c": string;
  "Partner_Renewal_Contract_PO__c": string;
  "Partner_Status__c": string;
  "Payment_Option__c": string;
  "Payment_Term__c": string;
  "Pricebook2Id": string;
  "Pricing_Coverage_Model_Period__c": string;
  "Primary_Job_Schedule__c": string;
  "Product_Backfill__c": string;
  "Product_Primary__c": string;
  "Product_Code__c": string;
  "Program__c": string;
  "Purchase_Order__c": string;
  "Quantity__c": number;
  "RecordId__c": string;
  "RecordTypeId": string;
  "RecordTypeId__c": string;
  "Reference_Contract__c": string;
  "Reference_Number__c": string;
  "Renewal_Contact_Email__c": string;
  "Renewal_Contact_Phone__c": string;
  "Roll_Profile_Count_Primary__c": number;
  "SCId__c": string;
  "Sales_Order_Active__c": string;
  "Schedule_Type_Weekly_Monthly__c": string;
  "Schedule__c": string;
  "Service_Contract_End_Date__c": Date;
  "Service_Contract_Number__c": string;
  "Service_Contract_Start_Date__c": Date;
  "Service_Description__c": string;
  "Service_Dispatch_Frequency__c": string;
  "Service_Level_OLD__c": string;
  "Service_Name__c": string;
  "Service_SKU__c": string;
  "Ship_Date__c": Date;
  "Ship_Mode__c": string;
  "Ship_Partial__c": string;
  "Ship_To_Party__c": string;
  "Sold_To_Party__c": string;
  "ShippingAddress": string;
  "SpecialTerms": string;
  "StartDate": Date;
  "Status": string;
  "Sub_Inventory_Location__c": string;
  "Sub_Total_Auto__c": number;
  "Subtotal": number;
  "Support_Plan_Type__c": string;
  "Support_Registration_Type__c": string;
  "Talent_Type__c": string;
  "Tax": number;
  "Term": number;
  "Time_Bank__c": string;
  "TotalPrice": number;
  "Total_Contract_Hours__c": number;
  "Total_Planned_Hours__c": number;
  "Total_Price_Customer_PostVAT_Rollup__c": number;
  "Total_Price__c": number;
  "Total_Weekly_Hours_Customer_Schedule__c": number;
  "Total_Weekly_Hours_Worker_Schedule__c": number;
  "Total_Work_Weeks__c": number;
  "Total_Yearly_Hours__c": number;
  "Unit_Price_FTE_Daily_Rate__c": number;
  "VAT_Excluded__c": boolean;
  "VAT_GST_Country__c": string;
  "VAT_GST__c": number;
  "VAT_Payee__c": string;
  "Vendor_Backfill__c": string;
  "Vendor_FTE_Hourly_Rate_Holiday_Backfill__c": number;
  "Vendor_FTE_Hourly_Rate_Holiday__c": number;
  "Vendor_FTE_Hourly_Rate_Overtime_Backfill__c": number;
  "Vendor_FTE_Hourly_Rate_Overtime__c": number;
  "Vendor_Pricelist_Backfill__c": string;
  "Vendor_Pricelist__c": string;
  "Vendor_Primary__c": string;
  "Weekly_Rate__c": number;
  "Work_Description__c": string;
  "Work_Order_Created__c": boolean;
  "Work_Order__c": string;
  "Work_Summary__c": string;
  "Worker_Schedule_Friday_Hours__c": number;
  "Worker_Schedule_Monday_Hours__c": number;
  "Worker_Schedule_Saturday_Hours__c": number;
  "Worker_Schedule_Sunday_Hours__c": number;
  "Worker_Schedule_Thursday_Hours__c": number;
  "Worker_Schedule_Tuesday_Hours__c": number;
  "Worker_Schedule_Wednesday_Hours__c": number;
  "Worker__c": string;
  "X3PL_FSL_Location_Default__c": string;
  "X3PL_FSL_Location_Gateway__c": string;
  "X3PL_Vendor__c": string;
  "X3PS_Vendor_Contract_End_Date__c": Date;
  "X3PS_Vendor_Contract_Reference_Number__c": string;
  "X3PS_Vendor_Contract_Start_Date__c": Date;
  "X3PS_Vendor_Contract_Terms__c": number;
  "X3PS_Vendor__c": string;
  "Region": string;
  "CountryCode": string;
  "PMS_Case_Status": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  recordType: RecordType;
  worker: Worker;
  jobsite: Jobsite;
  jobs: Job[];
  createdBy: Users;
  program: Project;
  pmsCase: Case;
  workOrder: WorkOrder;
  vendor: Account;
  talentType: TalentType;
  product: Product;
  jobSchedule: JobSchedule[];
  constructor(data?: ServiceContractInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ServiceContract`.
   */
  public static getModelName() {
    return "ServiceContract";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ServiceContract for dynamic purposes.
  **/
  public static factory(data: ServiceContractInterface): ServiceContract{
    return new ServiceContract(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ServiceContract',
      plural: 'ServiceContracts',
      path: 'ServiceContracts',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "AccountId": {
          name: 'AccountId',
          type: 'string'
        },
        "Account_Code_A100001__c": {
          name: 'Account_Code_A100001__c',
          type: 'string'
        },
        "Account_Name__c": {
          name: 'Account_Name__c',
          type: 'string'
        },
        "ActivationDate": {
          name: 'ActivationDate',
          type: 'Date'
        },
        "Actual_Shipping_Date__c": {
          name: 'Actual_Shipping_Date__c',
          type: 'Date'
        },
        "Additional_Hours_T_M_Price_Partner__c": {
          name: 'Additional_Hours_T_M_Price_Partner__c',
          type: 'number'
        },
        "Additional_Hours_T_M_Price_Vendor__c": {
          name: 'Additional_Hours_T_M_Price_Vendor__c',
          type: 'number'
        },
        "Annual_Invoice__c": {
          name: 'Annual_Invoice__c',
          type: 'string'
        },
        "ApprovalStatus": {
          name: 'ApprovalStatus',
          type: 'string'
        },
        "Approved_Customer_Schedule_Count__c": {
          name: 'Approved_Customer_Schedule_Count__c',
          type: 'number'
        },
        "Approved_Worker_Schedule_Count__c": {
          name: 'Approved_Worker_Schedule_Count__c',
          type: 'number'
        },
        "Asset_Contract_Type__c": {
          name: 'Asset_Contract_Type__c',
          type: 'string'
        },
        "Asset_Serial_Number__c": {
          name: 'Asset_Serial_Number__c',
          type: 'string'
        },
        "Asset_Status__c": {
          name: 'Asset_Status__c',
          type: 'string'
        },
        "Asset__c": {
          name: 'Asset__c',
          type: 'string'
        },
        "Carrier_Instructions__c": {
          name: 'Carrier_Instructions__c',
          type: 'string'
        },
        "Carrier_Scac__c": {
          name: 'Carrier_Scac__c',
          type: 'string'
        },
        "ContactId": {
          name: 'ContactId',
          type: 'string'
        },
        "Contact_Name_at_Customer_Site__c": {
          name: 'Contact_Name_at_Customer_Site__c',
          type: 'string'
        },
        "Contact_Type__c": {
          name: 'Contact_Type__c',
          type: 'string'
        },
        "ContractNumber": {
          name: 'ContractNumber',
          type: 'string'
        },
        "Contract_Advance_Pay_End_Date__c": {
          name: 'Contract_Advance_Pay_End_Date__c',
          type: 'Date'
        },
        "Contract_Advance_Pay_Start_Date__c": {
          name: 'Contract_Advance_Pay_Start_Date__c',
          type: 'Date'
        },
        "Contract_New_Registration_Number__c": {
          name: 'Contract_New_Registration_Number__c',
          type: 'string'
        },
        "Contract_New_Registration_Reference_ID__c": {
          name: 'Contract_New_Registration_Reference_ID__c',
          type: 'string'
        },
        "Contract_Order_Request_Active__c": {
          name: 'Contract_Order_Request_Active__c',
          type: 'string'
        },
        "Contract_Ownership__c": {
          name: 'Contract_Ownership__c',
          type: 'string'
        },
        "Contract_Reference_LOTS__c": {
          name: 'Contract_Reference_LOTS__c',
          type: 'string'
        },
        "Contract_Renewal_Registration_End_Date__c": {
          name: 'Contract_Renewal_Registration_End_Date__c',
          type: 'Date'
        },
        "Contract_Renewal_Registration_Start_Dat__c": {
          name: 'Contract_Renewal_Registration_Start_Dat__c',
          type: 'Date'
        },
        "Contract_Stage__c": {
          name: 'Contract_Stage__c',
          type: 'string'
        },
        "Count_of_Secondary_Worker__c": {
          name: 'Count_of_Secondary_Worker__c',
          type: 'number'
        },
        "Count_of_Service_Contract_Line_Item__c": {
          name: 'Count_of_Service_Contract_Line_Item__c',
          type: 'number'
        },
        "Coverage_Order_Prepaid_Date__c": {
          name: 'Coverage_Order_Prepaid_Date__c',
          type: 'Date'
        },
        "Create_Change_Request_Order__c": {
          name: 'Create_Change_Request_Order__c',
          type: 'boolean'
        },
        "Create_ICC_Case__c": {
          name: 'Create_ICC_Case__c',
          type: 'boolean'
        },
        "Create_Partner_Request__c": {
          name: 'Create_Partner_Request__c',
          type: 'boolean'
        },
        "Create_Work_Order__c": {
          name: 'Create_Work_Order__c',
          type: 'boolean'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "Created_Automatically__c": {
          name: 'Created_Automatically__c',
          type: 'boolean'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "Custom_Partner_Pricing__c": {
          name: 'Custom_Partner_Pricing__c',
          type: 'boolean'
        },
        "Custom_Price_Version_Created_Partner__c": {
          name: 'Custom_Price_Version_Created_Partner__c',
          type: 'boolean'
        },
        "Custom_Price_Version_Created_Vendor__c": {
          name: 'Custom_Price_Version_Created_Vendor__c',
          type: 'boolean'
        },
        "Custom_Price_Version_Partner__c": {
          name: 'Custom_Price_Version_Partner__c',
          type: 'string'
        },
        "Custom_Price_Version_Vendor__c": {
          name: 'Custom_Price_Version_Vendor__c',
          type: 'string'
        },
        "Custom_Vendor_Pricing_Backfill__c": {
          name: 'Custom_Vendor_Pricing_Backfill__c',
          type: 'boolean'
        },
        "Custom_Vendor_Pricing__c": {
          name: 'Custom_Vendor_Pricing__c',
          type: 'boolean'
        },
        "Customer_Account__c": {
          name: 'Customer_Account__c',
          type: 'string'
        },
        "Customer_PO_Date__c": {
          name: 'Customer_PO_Date__c',
          type: 'Date'
        },
        "Customer_PO__c": {
          name: 'Customer_PO__c',
          type: 'string'
        },
        "Customer_Po_Line__c": {
          name: 'Customer_Po_Line__c',
          type: 'string'
        },
        "Customer_Pricelist__c": {
          name: 'Customer_Pricelist__c',
          type: 'string'
        },
        "Customer_SO_Number__c": {
          name: 'Customer_SO_Number__c',
          type: 'string'
        },
        "Customer_Schedule_Friday_Hours__c": {
          name: 'Customer_Schedule_Friday_Hours__c',
          type: 'number'
        },
        "Customer_Schedule_Monday_Hours__c": {
          name: 'Customer_Schedule_Monday_Hours__c',
          type: 'number'
        },
        "Customer_Schedule_Saturday_Hours__c": {
          name: 'Customer_Schedule_Saturday_Hours__c',
          type: 'number'
        },
        "Customer_Schedule_Sunday_Hours__c": {
          name: 'Customer_Schedule_Sunday_Hours__c',
          type: 'number'
        },
        "Customer_Schedule_Thursday_Hours__c": {
          name: 'Customer_Schedule_Thursday_Hours__c',
          type: 'number'
        },
        "Customer_Schedule_Tuesday_Hours__c": {
          name: 'Customer_Schedule_Tuesday_Hours__c',
          type: 'number'
        },
        "Customer_Schedule_Wednesday_Hours__c": {
          name: 'Customer_Schedule_Wednesday_Hours__c',
          type: 'number'
        },
        "Customs_Broker__c": {
          name: 'Customs_Broker__c',
          type: 'string'
        },
        "Daily_Average__c": {
          name: 'Daily_Average__c',
          type: 'number'
        },
        "Daily_Rate__c": {
          name: 'Daily_Rate__c',
          type: 'number'
        },
        "Daily_Standard_Work_Hours__c": {
          name: 'Daily_Standard_Work_Hours__c',
          type: 'number'
        },
        "Days_Week_Schedule__c": {
          name: 'Days_Week_Schedule__c',
          type: 'number'
        },
        "Days_left_on_Contract__c": {
          name: 'Days_left_on_Contract__c',
          type: 'number'
        },
        "Delivery_Date__c": {
          name: 'Delivery_Date__c',
          type: 'Date'
        },
        "Description": {
          name: 'Description',
          type: 'string'
        },
        "Discount": {
          name: 'Discount',
          type: 'number'
        },
        "EOR_Party__c": {
          name: 'EOR_Party__c',
          type: 'string'
        },
        "EndDate": {
          name: 'EndDate',
          type: 'Date'
        },
        "End_User__c": {
          name: 'End_User__c',
          type: 'string'
        },
        "FTE_Configuration_Type__c": {
          name: 'FTE_Configuration_Type__c',
          type: 'string'
        },
        "FTE_Hours_Monthly__c": {
          name: 'FTE_Hours_Monthly__c',
          type: 'number'
        },
        "FTE_Monthly_Rate_Partner__c": {
          name: 'FTE_Monthly_Rate_Partner__c',
          type: 'number'
        },
        "FTE_Monthly_Rate_Vendor_Backfill__c": {
          name: 'FTE_Monthly_Rate_Vendor_Backfill__c',
          type: 'number'
        },
        "FTE_Monthly_Rate_Vendor__c": {
          name: 'FTE_Monthly_Rate_Vendor__c',
          type: 'number'
        },
        "FTE_Unit_Count__c": {
          name: 'FTE_Unit_Count__c',
          type: 'number'
        },
        "Final_Total_After_Tax__c": {
          name: 'Final_Total_After_Tax__c',
          type: 'number'
        },
        "Generate_Name__c": {
          name: 'Generate_Name__c',
          type: 'boolean'
        },
        "GrandTotal": {
          name: 'GrandTotal',
          type: 'number'
        },
        "Health_Account_and_Pricelist__c": {
          name: 'Health_Account_and_Pricelist__c',
          type: 'string'
        },
        "Health_Asset_Account__c": {
          name: 'Health_Asset_Account__c',
          type: 'string'
        },
        "Health_Asset__c": {
          name: 'Health_Asset__c',
          type: 'string'
        },
        "Health_Backfill__c": {
          name: 'Health_Backfill__c',
          type: 'string'
        },
        "Health_HMS_SKU__c": {
          name: 'Health_HMS_SKU__c',
          type: 'string'
        },
        "Health_Jobsite_Address__c": {
          name: 'Health_Jobsite_Address__c',
          type: 'boolean'
        },
        "Health_Jobsite__c": {
          name: 'Health_Jobsite__c',
          type: 'string'
        },
        "Health_Price__c": {
          name: 'Health_Price__c',
          type: 'string'
        },
        "Health_Pricelist_Type__c": {
          name: 'Health_Pricelist_Type__c',
          type: 'string'
        },
        "Health_SKU__c": {
          name: 'Health_SKU__c',
          type: 'string'
        },
        "Health_SOP_Pricelist_Status__c": {
          name: 'Health_SOP_Pricelist_Status__c',
          type: 'string'
        },
        "Health_SOP_Program_Status__c": {
          name: 'Health_SOP_Program_Status__c',
          type: 'string'
        },
        "Health_Schedule_Match__c": {
          name: 'Health_Schedule_Match__c',
          type: 'string'
        },
        "Health_Standard_Pricelist__c": {
          name: 'Health_Standard_Pricelist__c',
          type: 'string'
        },
        "Health_Start_End_Date__c": {
          name: 'Health_Start_End_Date__c',
          type: 'string'
        },
        "Health_Talent_Type__c": {
          name: 'Health_Talent_Type__c',
          type: 'string'
        },
        "Health_WO__c": {
          name: 'Health_WO__c',
          type: 'string'
        },
        "Helpdesk_Dispatch_Fee_Partner__c": {
          name: 'Helpdesk_Dispatch_Fee_Partner__c',
          type: 'number'
        },
        "Helpdesk_Dispatch_Fee_Vendor_Backfill__c": {
          name: 'Helpdesk_Dispatch_Fee_Vendor_Backfill__c',
          type: 'number'
        },
        "Helpdesk_Dispatch_Fee_Vendor__c": {
          name: 'Helpdesk_Dispatch_Fee_Vendor__c',
          type: 'number'
        },
        "ICC_Case_Created__c": {
          name: 'ICC_Case_Created__c',
          type: 'boolean'
        },
        "IOR_Party__c": {
          name: 'IOR_Party__c',
          type: 'string'
        },
        "IRON_Partner_Customer_Order_Status__c": {
          name: 'IRON_Partner_Customer_Order_Status__c',
          type: 'string'
        },
        "Importer_IOR__c": {
          name: 'Importer_IOR__c',
          type: 'string'
        },
        "Incoterm__c": {
          name: 'Incoterm__c',
          type: 'string'
        },
        "Initiate_Contract_update__c": {
          name: 'Initiate_Contract_update__c',
          type: 'boolean'
        },
        "Instructions__c": {
          name: 'Instructions__c',
          type: 'string'
        },
        "Insurance_Party__c": {
          name: 'Insurance_Party__c',
          type: 'string'
        },
        "Inventory_Pool__c": {
          name: 'Inventory_Pool__c',
          type: 'string'
        },
        "Iron_Service_Contract__c": {
          name: 'Iron_Service_Contract__c',
          type: 'string'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "JPC_Case__c": {
          name: 'JPC_Case__c',
          type: 'string'
        },
        "Job_Application__c": {
          name: 'Job_Application__c',
          type: 'string'
        },
        "Job_Instruction_Service_Description__c": {
          name: 'Job_Instruction_Service_Description__c',
          type: 'string'
        },
        "Job_Instructions_Description__c": {
          name: 'Job_Instructions_Description__c',
          type: 'string'
        },
        "Job_Instructions_Required_Tools__c": {
          name: 'Job_Instructions_Required_Tools__c',
          type: 'string'
        },
        "Job_Instructions_Service_Deliverables__c": {
          name: 'Job_Instructions_Service_Deliverables__c',
          type: 'string'
        },
        "Job_Instructions_Special_Instruction__c": {
          name: 'Job_Instructions_Special_Instruction__c',
          type: 'string'
        },
        "Job_Instructions_Summary__c": {
          name: 'Job_Instructions_Summary__c',
          type: 'string'
        },
        "Job_Instructions_Training_Documents__c": {
          name: 'Job_Instructions_Training_Documents__c',
          type: 'string'
        },
        "Job_Instructions_Worker_Specific_Req__c": {
          name: 'Job_Instructions_Worker_Specific_Req__c',
          type: 'string'
        },
        "Job_Order__c": {
          name: 'Job_Order__c',
          type: 'string'
        },
        "Job_Reference_Auto__c": {
          name: 'Job_Reference_Auto__c',
          type: 'string'
        },
        "Job_Schedule_count__c": {
          name: 'Job_Schedule_count__c',
          type: 'number'
        },
        "Jobsite_Project_Project__c": {
          name: 'Jobsite_Project_Project__c',
          type: 'string'
        },
        "Jobsite__c": {
          name: 'Jobsite__c',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "LastReferencedDate": {
          name: 'LastReferencedDate',
          type: 'Date'
        },
        "LastViewedDate": {
          name: 'LastViewedDate',
          type: 'Date'
        },
        "Last_Contract_Order_Change_Date__c": {
          name: 'Last_Contract_Order_Change_Date__c',
          type: 'Date'
        },
        "Last_Day_of_the_Contract_Start_Month__c": {
          name: 'Last_Day_of_the_Contract_Start_Month__c',
          type: 'Date'
        },
        "Last_Invoice_Date__c": {
          name: 'Last_Invoice_Date__c',
          type: 'Date'
        },
        "Last_Monthly_Invoice_Date__c": {
          name: 'Last_Monthly_Invoice_Date__c',
          type: 'Date'
        },
        "Last_date_for_WO__c": {
          name: 'Last_date_for_WO__c',
          type: 'Date'
        },
        "LineItemCount": {
          name: 'LineItemCount',
          type: 'number'
        },
        "List_Price__c": {
          name: 'List_Price__c',
          type: 'number'
        },
        "Migrated__c": {
          name: 'Migrated__c',
          type: 'boolean'
        },
        "Monthly_Rate__c": {
          name: 'Monthly_Rate__c',
          type: 'number'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "New_Order_Request_Raised__c": {
          name: 'New_Order_Request_Raised__c',
          type: 'boolean'
        },
        "Overall_Health_Good__c": {
          name: 'Overall_Health_Good__c',
          type: 'boolean'
        },
        "Overall_Health_Good_for_SO__c": {
          name: 'Overall_Health_Good_for_SO__c',
          type: 'boolean'
        },
        "Overall_Health_Status__c": {
          name: 'Overall_Health_Status__c',
          type: 'boolean'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "Owner_Expiration_Notice__c": {
          name: 'Owner_Expiration_Notice__c',
          type: 'string'
        },
        "PMS_Case__c": {
          name: 'PMS_Case__c',
          type: 'string'
        },
        "PO_Line_Number__c": {
          name: 'PO_Line_Number__c',
          type: 'string'
        },
        "PPE_1HR_Standard_Price_Partner__c": {
          name: 'PPE_1HR_Standard_Price_Partner__c',
          type: 'number'
        },
        "PPE_1HR_Standard_Price_Vendor_Backfill__c": {
          name: 'PPE_1HR_Standard_Price_Vendor_Backfill__c',
          type: 'number'
        },
        "PPE_1HR_Standard_Price_Vendor__c": {
          name: 'PPE_1HR_Standard_Price_Vendor__c',
          type: 'number'
        },
        "PPE_2HR_Standard_Price_Partner__c": {
          name: 'PPE_2HR_Standard_Price_Partner__c',
          type: 'number'
        },
        "PPE_2HR_Standard_Price_Vendor_Backfill__c": {
          name: 'PPE_2HR_Standard_Price_Vendor_Backfill__c',
          type: 'number'
        },
        "PPE_2HR_Standard_Price_Vendor__c": {
          name: 'PPE_2HR_Standard_Price_Vendor__c',
          type: 'number'
        },
        "PPE_3HR_Standard_Price_Partner__c": {
          name: 'PPE_3HR_Standard_Price_Partner__c',
          type: 'number'
        },
        "PPE_3HR_Standard_Price_Vendor_Backfill__c": {
          name: 'PPE_3HR_Standard_Price_Vendor_Backfill__c',
          type: 'number'
        },
        "PPE_3HR_Standard_Price_Vendor__c": {
          name: 'PPE_3HR_Standard_Price_Vendor__c',
          type: 'number'
        },
        "PPE_4HR_Standard_Price_Partner__c": {
          name: 'PPE_4HR_Standard_Price_Partner__c',
          type: 'number'
        },
        "PPE_4HR_Standard_Price_Vendor_Backfill__c": {
          name: 'PPE_4HR_Standard_Price_Vendor_Backfill__c',
          type: 'number'
        },
        "PPE_4HR_Standard_Price_Vendor__c": {
          name: 'PPE_4HR_Standard_Price_Vendor__c',
          type: 'number'
        },
        "PPE_8HR_Standard_Price_Partner__c": {
          name: 'PPE_8HR_Standard_Price_Partner__c',
          type: 'number'
        },
        "PPE_8HR_Standard_Price_Vendor_Backfill__c": {
          name: 'PPE_8HR_Standard_Price_Vendor_Backfill__c',
          type: 'number'
        },
        "PPE_8HR_Standard_Price_Vendor__c": {
          name: 'PPE_8HR_Standard_Price_Vendor__c',
          type: 'number'
        },
        "Partner_Account_Name__c": {
          name: 'Partner_Account_Name__c',
          type: 'string'
        },
        "Partner_Account__c": {
          name: 'Partner_Account__c',
          type: 'string'
        },
        "Partner_Currency__c": {
          name: 'Partner_Currency__c',
          type: 'string'
        },
        "Partner_Customer_Registration_Status__c": {
          name: 'Partner_Customer_Registration_Status__c',
          type: 'string'
        },
        "Partner_FTE_Hourly_Rate_Holiday__c": {
          name: 'Partner_FTE_Hourly_Rate_Holiday__c',
          type: 'number'
        },
        "Partner_FTE_Hourly_Rate_Overtime__c": {
          name: 'Partner_FTE_Hourly_Rate_Overtime__c',
          type: 'number'
        },
        "Partner_Order_Notes__c": {
          name: 'Partner_Order_Notes__c',
          type: 'string'
        },
        "Partner_PO_Number__c": {
          name: 'Partner_PO_Number__c',
          type: 'string'
        },
        "Partner_Pricelist__c": {
          name: 'Partner_Pricelist__c',
          type: 'string'
        },
        "Partner_Reference_Number__c": {
          name: 'Partner_Reference_Number__c',
          type: 'string'
        },
        "Partner_Renewal_Contract_PO__c": {
          name: 'Partner_Renewal_Contract_PO__c',
          type: 'string'
        },
        "Partner_Status__c": {
          name: 'Partner_Status__c',
          type: 'string'
        },
        "Payment_Option__c": {
          name: 'Payment_Option__c',
          type: 'string'
        },
        "Payment_Term__c": {
          name: 'Payment_Term__c',
          type: 'string'
        },
        "Pricebook2Id": {
          name: 'Pricebook2Id',
          type: 'string'
        },
        "Pricing_Coverage_Model_Period__c": {
          name: 'Pricing_Coverage_Model_Period__c',
          type: 'string'
        },
        "Primary_Job_Schedule__c": {
          name: 'Primary_Job_Schedule__c',
          type: 'string'
        },
        "Product_Backfill__c": {
          name: 'Product_Backfill__c',
          type: 'string'
        },
        "Product_Primary__c": {
          name: 'Product_Primary__c',
          type: 'string'
        },
        "Product_Code__c": {
          name: 'Product_Code__c',
          type: 'string'
        },
        "Program__c": {
          name: 'Program__c',
          type: 'string'
        },
        "Purchase_Order__c": {
          name: 'Purchase_Order__c',
          type: 'string'
        },
        "Quantity__c": {
          name: 'Quantity__c',
          type: 'number'
        },
        "RecordId__c": {
          name: 'RecordId__c',
          type: 'string'
        },
        "RecordTypeId": {
          name: 'RecordTypeId',
          type: 'string'
        },
        "RecordTypeId__c": {
          name: 'RecordTypeId__c',
          type: 'string'
        },
        "Reference_Contract__c": {
          name: 'Reference_Contract__c',
          type: 'string'
        },
        "Reference_Number__c": {
          name: 'Reference_Number__c',
          type: 'string'
        },
        "Renewal_Contact_Email__c": {
          name: 'Renewal_Contact_Email__c',
          type: 'string'
        },
        "Renewal_Contact_Phone__c": {
          name: 'Renewal_Contact_Phone__c',
          type: 'string'
        },
        "Roll_Profile_Count_Primary__c": {
          name: 'Roll_Profile_Count_Primary__c',
          type: 'number'
        },
        "SCId__c": {
          name: 'SCId__c',
          type: 'string'
        },
        "Sales_Order_Active__c": {
          name: 'Sales_Order_Active__c',
          type: 'string'
        },
        "Schedule_Type_Weekly_Monthly__c": {
          name: 'Schedule_Type_Weekly_Monthly__c',
          type: 'string'
        },
        "Schedule__c": {
          name: 'Schedule__c',
          type: 'string'
        },
        "Service_Contract_End_Date__c": {
          name: 'Service_Contract_End_Date__c',
          type: 'Date'
        },
        "Service_Contract_Number__c": {
          name: 'Service_Contract_Number__c',
          type: 'string'
        },
        "Service_Contract_Start_Date__c": {
          name: 'Service_Contract_Start_Date__c',
          type: 'Date'
        },
        "Service_Description__c": {
          name: 'Service_Description__c',
          type: 'string'
        },
        "Service_Dispatch_Frequency__c": {
          name: 'Service_Dispatch_Frequency__c',
          type: 'string'
        },
        "Service_Level_OLD__c": {
          name: 'Service_Level_OLD__c',
          type: 'string'
        },
        "Service_Name__c": {
          name: 'Service_Name__c',
          type: 'string'
        },
        "Service_SKU__c": {
          name: 'Service_SKU__c',
          type: 'string'
        },
        "Ship_Date__c": {
          name: 'Ship_Date__c',
          type: 'Date'
        },
        "Ship_Mode__c": {
          name: 'Ship_Mode__c',
          type: 'string'
        },
        "Ship_Partial__c": {
          name: 'Ship_Partial__c',
          type: 'string'
        },
        "Ship_To_Party__c": {
          name: 'Ship_To_Party__c',
          type: 'string'
        },
        "Sold_To_Party__c": {
          name: 'Sold_To_Party__c',
          type: 'string'
        },
        "ShippingAddress": {
          name: 'ShippingAddress',
          type: 'string'
        },
        "SpecialTerms": {
          name: 'SpecialTerms',
          type: 'string'
        },
        "StartDate": {
          name: 'StartDate',
          type: 'Date'
        },
        "Status": {
          name: 'Status',
          type: 'string'
        },
        "Sub_Inventory_Location__c": {
          name: 'Sub_Inventory_Location__c',
          type: 'string'
        },
        "Sub_Total_Auto__c": {
          name: 'Sub_Total_Auto__c',
          type: 'number'
        },
        "Subtotal": {
          name: 'Subtotal',
          type: 'number'
        },
        "Support_Plan_Type__c": {
          name: 'Support_Plan_Type__c',
          type: 'string'
        },
        "Support_Registration_Type__c": {
          name: 'Support_Registration_Type__c',
          type: 'string'
        },
        "Talent_Type__c": {
          name: 'Talent_Type__c',
          type: 'string'
        },
        "Tax": {
          name: 'Tax',
          type: 'number'
        },
        "Term": {
          name: 'Term',
          type: 'number'
        },
        "Time_Bank__c": {
          name: 'Time_Bank__c',
          type: 'string'
        },
        "TotalPrice": {
          name: 'TotalPrice',
          type: 'number'
        },
        "Total_Contract_Hours__c": {
          name: 'Total_Contract_Hours__c',
          type: 'number'
        },
        "Total_Planned_Hours__c": {
          name: 'Total_Planned_Hours__c',
          type: 'number'
        },
        "Total_Price_Customer_PostVAT_Rollup__c": {
          name: 'Total_Price_Customer_PostVAT_Rollup__c',
          type: 'number'
        },
        "Total_Price__c": {
          name: 'Total_Price__c',
          type: 'number'
        },
        "Total_Weekly_Hours_Customer_Schedule__c": {
          name: 'Total_Weekly_Hours_Customer_Schedule__c',
          type: 'number'
        },
        "Total_Weekly_Hours_Worker_Schedule__c": {
          name: 'Total_Weekly_Hours_Worker_Schedule__c',
          type: 'number'
        },
        "Total_Work_Weeks__c": {
          name: 'Total_Work_Weeks__c',
          type: 'number'
        },
        "Total_Yearly_Hours__c": {
          name: 'Total_Yearly_Hours__c',
          type: 'number'
        },
        "Unit_Price_FTE_Daily_Rate__c": {
          name: 'Unit_Price_FTE_Daily_Rate__c',
          type: 'number'
        },
        "VAT_Excluded__c": {
          name: 'VAT_Excluded__c',
          type: 'boolean'
        },
        "VAT_GST_Country__c": {
          name: 'VAT_GST_Country__c',
          type: 'string'
        },
        "VAT_GST__c": {
          name: 'VAT_GST__c',
          type: 'number'
        },
        "VAT_Payee__c": {
          name: 'VAT_Payee__c',
          type: 'string'
        },
        "Vendor_Backfill__c": {
          name: 'Vendor_Backfill__c',
          type: 'string'
        },
        "Vendor_FTE_Hourly_Rate_Holiday_Backfill__c": {
          name: 'Vendor_FTE_Hourly_Rate_Holiday_Backfill__c',
          type: 'number'
        },
        "Vendor_FTE_Hourly_Rate_Holiday__c": {
          name: 'Vendor_FTE_Hourly_Rate_Holiday__c',
          type: 'number'
        },
        "Vendor_FTE_Hourly_Rate_Overtime_Backfill__c": {
          name: 'Vendor_FTE_Hourly_Rate_Overtime_Backfill__c',
          type: 'number'
        },
        "Vendor_FTE_Hourly_Rate_Overtime__c": {
          name: 'Vendor_FTE_Hourly_Rate_Overtime__c',
          type: 'number'
        },
        "Vendor_Pricelist_Backfill__c": {
          name: 'Vendor_Pricelist_Backfill__c',
          type: 'string'
        },
        "Vendor_Pricelist__c": {
          name: 'Vendor_Pricelist__c',
          type: 'string'
        },
        "Vendor_Primary__c": {
          name: 'Vendor_Primary__c',
          type: 'string'
        },
        "Weekly_Rate__c": {
          name: 'Weekly_Rate__c',
          type: 'number'
        },
        "Work_Description__c": {
          name: 'Work_Description__c',
          type: 'string'
        },
        "Work_Order_Created__c": {
          name: 'Work_Order_Created__c',
          type: 'boolean'
        },
        "Work_Order__c": {
          name: 'Work_Order__c',
          type: 'string'
        },
        "Work_Summary__c": {
          name: 'Work_Summary__c',
          type: 'string'
        },
        "Worker_Schedule_Friday_Hours__c": {
          name: 'Worker_Schedule_Friday_Hours__c',
          type: 'number'
        },
        "Worker_Schedule_Monday_Hours__c": {
          name: 'Worker_Schedule_Monday_Hours__c',
          type: 'number'
        },
        "Worker_Schedule_Saturday_Hours__c": {
          name: 'Worker_Schedule_Saturday_Hours__c',
          type: 'number'
        },
        "Worker_Schedule_Sunday_Hours__c": {
          name: 'Worker_Schedule_Sunday_Hours__c',
          type: 'number'
        },
        "Worker_Schedule_Thursday_Hours__c": {
          name: 'Worker_Schedule_Thursday_Hours__c',
          type: 'number'
        },
        "Worker_Schedule_Tuesday_Hours__c": {
          name: 'Worker_Schedule_Tuesday_Hours__c',
          type: 'number'
        },
        "Worker_Schedule_Wednesday_Hours__c": {
          name: 'Worker_Schedule_Wednesday_Hours__c',
          type: 'number'
        },
        "Worker__c": {
          name: 'Worker__c',
          type: 'string'
        },
        "X3PL_FSL_Location_Default__c": {
          name: 'X3PL_FSL_Location_Default__c',
          type: 'string'
        },
        "X3PL_FSL_Location_Gateway__c": {
          name: 'X3PL_FSL_Location_Gateway__c',
          type: 'string'
        },
        "X3PL_Vendor__c": {
          name: 'X3PL_Vendor__c',
          type: 'string'
        },
        "X3PS_Vendor_Contract_End_Date__c": {
          name: 'X3PS_Vendor_Contract_End_Date__c',
          type: 'Date'
        },
        "X3PS_Vendor_Contract_Reference_Number__c": {
          name: 'X3PS_Vendor_Contract_Reference_Number__c',
          type: 'string'
        },
        "X3PS_Vendor_Contract_Start_Date__c": {
          name: 'X3PS_Vendor_Contract_Start_Date__c',
          type: 'Date'
        },
        "X3PS_Vendor_Contract_Terms__c": {
          name: 'X3PS_Vendor_Contract_Terms__c',
          type: 'number'
        },
        "X3PS_Vendor__c": {
          name: 'X3PS_Vendor__c',
          type: 'string'
        },
        "Region": {
          name: 'Region',
          type: 'string'
        },
        "CountryCode": {
          name: 'CountryCode',
          type: 'string'
        },
        "PMS_Case_Status": {
          name: 'PMS_Case_Status',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        recordType: {
          name: 'recordType',
          type: 'RecordType',
          model: 'RecordType',
          relationType: 'belongsTo',
                  keyFrom: 'RecordTypeId',
          keyTo: 'sfdcId'
        },
        worker: {
          name: 'worker',
          type: 'Worker',
          model: 'Worker',
          relationType: 'belongsTo',
                  keyFrom: 'Worker__c',
          keyTo: 'sfdcId'
        },
        jobsite: {
          name: 'jobsite',
          type: 'Jobsite',
          model: 'Jobsite',
          relationType: 'belongsTo',
                  keyFrom: 'Jobsite__c',
          keyTo: 'sfdcId'
        },
        jobs: {
          name: 'jobs',
          type: 'Job[]',
          model: 'Job',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'Service_Contract__c'
        },
        createdBy: {
          name: 'createdBy',
          type: 'Users',
          model: 'Users',
          relationType: 'belongsTo',
                  keyFrom: 'CreatedById',
          keyTo: 'sfdcId'
        },
        program: {
          name: 'program',
          type: 'Project',
          model: 'Project',
          relationType: 'belongsTo',
                  keyFrom: 'Program__c',
          keyTo: 'sfdcId'
        },
        pmsCase: {
          name: 'pmsCase',
          type: 'Case',
          model: 'Case',
          relationType: 'belongsTo',
                  keyFrom: 'PMS_Case__c',
          keyTo: 'sfdcId'
        },
        workOrder: {
          name: 'workOrder',
          type: 'WorkOrder',
          model: 'WorkOrder',
          relationType: 'belongsTo',
                  keyFrom: 'Work_Order__c',
          keyTo: 'sfdcId'
        },
        vendor: {
          name: 'vendor',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'Vendor_Primary__c',
          keyTo: 'sfdcId'
        },
        talentType: {
          name: 'talentType',
          type: 'TalentType',
          model: 'TalentType',
          relationType: 'belongsTo',
                  keyFrom: 'Talent_Type__c',
          keyTo: 'sfdcId'
        },
        product: {
          name: 'product',
          type: 'Product',
          model: 'Product',
          relationType: 'belongsTo',
                  keyFrom: 'Product_Primary__c',
          keyTo: 'sfdcId'
        },
        jobSchedule: {
          name: 'jobSchedule',
          type: 'JobSchedule[]',
          model: 'JobSchedule',
          relationType: 'hasMany',
                  keyFrom: 'Primary_Job_Schedule__c',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
