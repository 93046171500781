import { NgModule } from "@angular/core";
import { CustomerVisitPlanComponent } from "./customer-visit-plan.component";
import { CommonModule } from "@angular/common";
import { NguiMapModule } from "@ngui/map";
import { environment } from "environments/environment";
import { MapService } from "shared/services/map.service";

@NgModule({
    imports: [
        CommonModule,
        NguiMapModule.forRoot({ apiUrl: 'https://maps.google.com/maps/api/js?key=' + environment.googleMapKey })
    ],
    declarations: [
        CustomerVisitPlanComponent
    ],
    exports: [
        CustomerVisitPlanComponent
    ]
})
export class CustomerVisitPlanModule { }