import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobDetailSideMenuComponent } from './job-detail-sidemenu.component';
import { JobSummaryModule } from '../job-summary/job-summary.module';
import { TimecardModule } from '../timecard/timecard.module';
import { DocumentModule } from '../document/document.module';
import { SelectWorkerModule } from '../select-worker/select-worker.module';
import { UpdateAppointmentModule } from '../update-appointment/update-appointment.module';
import { PipeModule } from 'shared/pipe/pipe.module';
import { JobDetailInstructionsModule } from '../../views/vms/jobs/job-detail/job-detail-instructions/job-detail-instructions.module';
import { FilesListModule } from '../files-list/files-list.module';
import { JobStatusUpdateTrackerModule } from '../job-progress-update-tracker/job-progress-update-tracker.module';
import { CustomerVisitPlanModule } from '../customer-visit-plan/customer-visit-plan.module';
import { NguiMapModule } from '@ngui/map'; // Google nguimap integration
import { environment } from 'environments/environment';
import { WorkflowButtonsModule } from 'shared/components/workflow-buttons/workflow-buttons.module'
import { WorkerTrackerMapModule } from './../worker-tracker-map/worker-tracker-map.module';

@NgModule({
    imports: [
        CommonModule,
        JobSummaryModule,
        DocumentModule,
        TimecardModule,
        SelectWorkerModule,
        UpdateAppointmentModule,
        NguiMapModule.forRoot({ apiUrl: 'https://maps.google.com/maps/api/js?key=' + environment.googleMapKey }),

        PipeModule,
        JobDetailInstructionsModule,
        FilesListModule,
        JobStatusUpdateTrackerModule,
        CustomerVisitPlanModule,
        WorkflowButtonsModule,
        WorkerTrackerMapModule
    ],
    declarations: [JobDetailSideMenuComponent],
    exports: [JobDetailSideMenuComponent],
})
export class jobDetailSidemenu { }
