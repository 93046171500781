import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { DateClosedLookupComponent } from './date-closed-lookup.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule
  ],
  declarations: [DateClosedLookupComponent],
  exports: [DateClosedLookupComponent],
  providers: []
})
export class DateClosedLookupModule { }
