import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { departmentList } from 'shared/models/static-list-data.service';
import { DepartmentApi } from 'shared/sdk';
@Component({
  selector: 'app-department-lookup',
  templateUrl: './department-lookup.component.html',
  styleUrls: ['./department-lookup.component.css']
})
export class DepartmentLookupComponent implements OnInit {

  @Input() set resetData(isClear) {
    this.clearFromParent();
  }
  @Output() selectedDepartment: EventEmitter<any> = new EventEmitter;
  selectedValue;

  departments:any = [];
  @Input() boxedLayout;

  constructor(
    private _departmentApi: DepartmentApi,
    private router : Router
    ) { }

    async ngOnInit() {
    if(this.router.url.indexOf('/app/msp-marketplace-recruitment-jobs') !== -1){
      this.departments = await this.getDepartment();
    }else{
      this.departments = departmentList;
    }
    
    const preSelected = localStorage.getItem('filterObj');
    if (preSelected && JSON.parse(preSelected) &&
      JSON.parse(preSelected)['departments'] && JSON.parse(preSelected)['departments'].length) {
      this.selectedValue = JSON.parse(preSelected)['departments'];
    }
  }

  onChange() {
    this.selectedDepartment.emit(this.selectedValue);
  }

  clearFromParent() {
    this.selectedValue = null;
  }

 getDepartment(){
    return new Promise((resolve, reject) => {
      const department = []
      const findObj = {
         fields: ['Department_Name__c','sfdcId'],
         order : 'Department_Name__c ASC'
      }
      this._departmentApi.find(findObj).subscribe(results => {
        results.forEach(item => {
          let obj = {
            Name: item['Department_Name__c'],
            sfdcId: item['sfdcId']
          };
          department.push(obj);
        });
        resolve(department);
      }, error => {
          reject();
      })
  });
  }

}
