/* tslint:disable */
import {
  Contact,
  LearningQuestion
} from '../index';

declare var Object: any;
export interface QuestionLineInterface {
  "sfdcId"?: string;
  "IsDeleted"?: boolean;
  "Name"?: string;
  "CurrencyIsoCode"?: string;
  "CreatedDate"?: Date;
  "CreatedById"?: string;
  "LastModifiedDate"?: Date;
  "LastModifiedById"?: string;
  "LastActivityDate"?: Date;
  "Quiz__c"?: string;
  "Description__c"?: string;
  "Order__c"?: number;
  "Total_Questions__c"?: number;
  "CreatedBy__c"?: string;
  "Last_ModifiedBy__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  contact?: Contact;
  learningQuestion?: LearningQuestion[];
}

export class QuestionLine implements QuestionLineInterface {
  "sfdcId": string;
  "IsDeleted": boolean;
  "Name": string;
  "CurrencyIsoCode": string;
  "CreatedDate": Date;
  "CreatedById": string;
  "LastModifiedDate": Date;
  "LastModifiedById": string;
  "LastActivityDate": Date;
  "Quiz__c": string;
  "Description__c": string;
  "Order__c": number;
  "Total_Questions__c": number;
  "CreatedBy__c": string;
  "Last_ModifiedBy__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  contact: Contact;
  learningQuestion: LearningQuestion[];
  constructor(data?: QuestionLineInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `QuestionLine`.
   */
  public static getModelName() {
    return "QuestionLine";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of QuestionLine for dynamic purposes.
  **/
  public static factory(data: QuestionLineInterface): QuestionLine{
    return new QuestionLine(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'QuestionLine',
      plural: 'QuestionLines',
      path: 'QuestionLines',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "LastActivityDate": {
          name: 'LastActivityDate',
          type: 'Date'
        },
        "Quiz__c": {
          name: 'Quiz__c',
          type: 'string'
        },
        "Description__c": {
          name: 'Description__c',
          type: 'string'
        },
        "Order__c": {
          name: 'Order__c',
          type: 'number'
        },
        "Total_Questions__c": {
          name: 'Total_Questions__c',
          type: 'number'
        },
        "CreatedBy__c": {
          name: 'CreatedBy__c',
          type: 'string'
        },
        "Last_ModifiedBy__c": {
          name: 'Last_ModifiedBy__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        contact: {
          name: 'contact',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'CreatedBy__c',
          keyTo: 'sfdcId'
        },
        learningQuestion: {
          name: 'learningQuestion',
          type: 'LearningQuestion[]',
          model: 'LearningQuestion',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'Question_Line__c'
        },
      }
    }
  }
}
