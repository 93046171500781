/* tslint:disable */
import {
  Contact,Users
} from '../index';

declare var Object: any;
export interface UserDeviceInterface {
  "platform": string;
  "manufacturer"?: string;
  "model"?: string;
  "serial"?: string;
  "uuid": string;
  "version"?: string;
  "registrationType": string;
  "registrationId": string;
  "iotPrimaryKey"?: string;
  "iotSecondaryKey"?: string;
  "iotDeviceType"?: string;
  "iotGenerationId"?: string;
  "iotTag"?: string;
  "isActive"?: boolean;
  "userId": number;
  "contactId": number;
  "contactSfdcId": string;
  "OSType"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  contact?: Contact;
  users?: Users;
}

export class UserDevice implements UserDeviceInterface {
  "platform": string;
  "manufacturer": string;
  "model": string;
  "serial": string;
  "uuid": string;
  "version": string;
  "registrationType": string;
  "registrationId": string;
  "iotPrimaryKey": string;
  "iotSecondaryKey": string;
  "iotDeviceType": string;
  "iotGenerationId": string;
  "iotTag": string;
  "isActive": boolean;
  "userId": number;
  "contactId": number;
  "contactSfdcId": string;
  "OSType": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  contact: Contact;
  users: Users;
  constructor(data?: UserDeviceInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `UserDevice`.
   */
  public static getModelName() {
    return "UserDevice";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of UserDevice for dynamic purposes.
  **/
  public static factory(data: UserDeviceInterface): UserDevice{
    return new UserDevice(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'UserDevice',
      plural: 'UserDevices',
      path: 'UserDevices',
      idName: 'id',
      properties: {
        "platform": {
          name: 'platform',
          type: 'string'
        },
        "manufacturer": {
          name: 'manufacturer',
          type: 'string'
        },
        "model": {
          name: 'model',
          type: 'string'
        },
        "serial": {
          name: 'serial',
          type: 'string'
        },
        "uuid": {
          name: 'uuid',
          type: 'string'
        },
        "version": {
          name: 'version',
          type: 'string'
        },
        "registrationType": {
          name: 'registrationType',
          type: 'string'
        },
        "registrationId": {
          name: 'registrationId',
          type: 'string'
        },
        "iotPrimaryKey": {
          name: 'iotPrimaryKey',
          type: 'string'
        },
        "iotSecondaryKey": {
          name: 'iotSecondaryKey',
          type: 'string'
        },
        "iotDeviceType": {
          name: 'iotDeviceType',
          type: 'string'
        },
        "iotGenerationId": {
          name: 'iotGenerationId',
          type: 'string'
        },
        "iotTag": {
          name: 'iotTag',
          type: 'string'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "userId": {
          name: 'userId',
          type: 'number'
        },
        "contactId": {
          name: 'contactId',
          type: 'number'
        },
        "contactSfdcId": {
          name: 'contactSfdcId',
          type: 'string'
        },
        "OSType": {
          name: 'OSType',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        contact: {
          name: 'contact',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'contactSfdcId',
          keyTo: 'sfdcId'
        },
        users: {
          name: 'users',
          type: 'Users',
          model: 'Users',
          relationType: 'belongsTo',
                  keyFrom: 'contactSfdcId',
          keyTo: 'id'
        },
      }
    }
  }
}
