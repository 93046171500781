import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { actualCoverageHourValues } from '../../../models/static-list-data.service'

@Component({
  selector: 'app-actual-end-time-coverage-lookup',
  templateUrl: './actual-end-time-coverage-lookup.component.html',
  styleUrls: ['./actual-end-time-coverage-lookup.component.css']
})
export class ActualEndTimeCoverageLookupComponent implements OnInit {

  actualEndTimeCoverageList = [];
  actualEndTimeCoverageSelected = [];
  isMultipleSelected = true;

  @Input() set resetData(isClear) {
    if (isClear) {
      this.actualEndTimeCoverageSelected = [];
    }
  }
  @Output() selectedActualEndTimeCoverage: EventEmitter<any> = new EventEmitter;

  constructor() { }

  ngOnInit() {
    this.actualEndTimeCoverageList = actualCoverageHourValues;
    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
    this.actualEndTimeCoverageSelected = (preselected && preselected['actualEndTimeCoverage']) ? preselected['actualEndTimeCoverage'] : '';
  }
  onActualEndTimeCoverageChange() {
    this.selectedActualEndTimeCoverage.emit(this.actualEndTimeCoverageSelected);
  }
  clearActualEndTimeCoverage() {
    this.actualEndTimeCoverageSelected = [];
    this.selectedActualEndTimeCoverage.emit([]);
  }

}
