import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { async } from '@angular/core/testing';
import { CommonService } from './common.service';
import { LearningAssignmentApi } from 'shared/sdk';


@Injectable()
export class GlobalFilterService {

    private filters: BehaviorSubject<any> = new BehaviorSubject<any>({});
    private isOpened: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    private enableFilters: BehaviorSubject<any> = new BehaviorSubject<any>([]);

    constructor(private _commonService: CommonService,
        private _learningAssignmentApi: LearningAssignmentApi) { }

    setFilters(filters) {
        this.filters.next(filters);
    }
    getFilters() {
        return this.filters.asObservable();
    }

    setSidebarState(isOpened) {
        this.isOpened.next(isOpened);
    }
    getSidebarState() {
        return this.isOpened.asObservable();
    }
    setFiltersDate = async (days?) => {
        return new Promise((resolve, reject) => {
            const fromDate: Date = this.getDate(days ? days-1 : 90 - 1);
            fromDate.setHours(0, 0, 0, 0);
            const toDate: Date = new Date();
            toDate.setHours(0, 0, 0, 0)
            // const dateRange = {
            //   0: fromDate,
            //   1: toDate
            // }
            const dateRange = [];
            const filterObj = { dateOptions: [] }
            dateRange.push(fromDate);
            dateRange.push(toDate);
            const selectedRange = dateRange;
            if (selectedRange && selectedRange.length) {
                // logic for the same day
                // if (!(selectedRange[0] - selectedRange[1])) {
                //   selectedRange[1].setHours(23, 59, 59);
                // }
                const _dateFrom = (selectedRange && selectedRange[0])
                    ? selectedRange[0].setSeconds(0)
                    : '';
                let _dateTo = '';
                if (selectedRange && selectedRange[1]) {
                    selectedRange[1].setHours(23);
                    selectedRange[1].setMinutes(59);
                    selectedRange[1].setSeconds(59);
                    _dateTo = selectedRange[1].getTime();
                }
                if (_dateFrom && _dateTo) {
                    const timeZoneDate = this._commonService.getDateInterval(_dateFrom, _dateTo, '', 'YYYY-MM-DDTHH:mm:ssZZ');
                    const dateFrom = timeZoneDate['start'];
                    const dateTo = timeZoneDate['end'];
                    // in case of reset, date will be an empty array
                    if (dateFrom && dateTo) {
                        filterObj['dateOptions'] = [{ 0: dateFrom, 1: dateTo }];
                    } else if (dateFrom) {
                        filterObj['dateOptions'] = [{ 0: dateFrom }];
                    } else if (dateTo) {
                        filterObj['dateOptions'] = [{ 1: dateTo }];
                    }
                }
            }
            resolve(filterObj);
        });
    }

    /* THIS METHOD IS TO SET DATE FROM CURRENT TO LAST GIVEN NUMBER OF DAYS */
    getDate = (days) => {
        const result = new Date();
        result.setDate(result.getDate() - days);
        return result;
    }

    enableFilter(filters: string[]) {
        this.enableFilters.next(filters);
    }

    getEnabledFilters() {
        return this.enableFilters.asObservable();
    }

    setFilterStartFromDate = async (days?) => {
        return new Promise((resolve, reject) => {
            const fromDate: Date = this.getDate(days ? days - 1 : 30 - 1);
            const toDate: Date = new Date();
            const dateRange = [];
            const filterObj = { startFromDateOptions: [] }
            dateRange.push(fromDate);
            dateRange.push(toDate);
            const selectedRange = dateRange;
            if (selectedRange && selectedRange.length) {
                const _dateFrom = (selectedRange && selectedRange[0])
                    ? selectedRange[0] : '';
                let _dateTo = '';
                if (selectedRange && selectedRange[1]) {
                    _dateTo = selectedRange[1];
                }
                if (_dateFrom && _dateTo) {
                    const timeZoneDate = this._commonService.getDateInterval(_dateFrom, _dateTo, '', 'YYYY-MM-DD');
                    const dateFrom = timeZoneDate['start'];
                    const dateTo = timeZoneDate['end'];
                    // in case of reset, date will be an empty array
                    if (dateFrom && dateTo) {
                        filterObj['startFromDateOptions'] = [{ 0: dateFrom, 1: dateTo }];
                    } else if (dateFrom) {
                        filterObj['startFromDateOptions'] = [{ 0: dateFrom }];
                    } else if (dateTo) {
                        filterObj['startFromDateOptions'] = [{ 1: dateTo }];
                    }
                }
            }
            resolve(filterObj);
        });
    }

    getLocalFilterData() {
        return localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
    }
    updateLearningAssignment(){
        let videoPlayerArray = JSON.parse(localStorage.getItem("videoPlayerTime"));
        if (videoPlayerArray && videoPlayerArray.length > 0) {
            let req = { reqObj: videoPlayerArray}
            this._learningAssignmentApi.updateAllVideoTimeFromLocalStorage(req).subscribe(reply => {
                
              }, err => {
                console.log(err);
              })
        }
    }

}
