import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaseCategoryLookupComponent } from './case-category-lookup.component';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule
  ],
  declarations: [CaseCategoryLookupComponent],
  exports: [CaseCategoryLookupComponent],
  providers: []
})
export class CaseCategoryLookupModule { }
