import { MessageService } from 'shared/services/message.service';
import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment-timezone';
// import * as L from 'leaflet';
// import 'leaflet-routing-machine';
import { Observable, Subscription } from 'rxjs';
import { GpsSocketService } from './../../services/gps-socket.service'
import { ModalService } from 'shared/services/modal.service';
import { AppStateService } from 'shared/services/app-state.service';
import { CommonService } from 'shared/services/common.service';
import { environment } from 'environments/environment';
import { WorkerTrackerService } from './../../services/worker-tracker.service';
import axios from 'axios';
import * as querystring from 'querystring';
import { debounce } from 'lodash'
declare var L: any;

@Component({
  selector: 'app-worker-tracker-map',
  templateUrl: './worker-tracker-map.component.html',
  styleUrls: ['./worker-tracker-map.component.css']
})
export class WorkerTrackerMapComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() modelName: string
  @Input() fromPage: string
  @Input()
  set selectedJob(e) {
    this._selectedJob = e;
  }

  @Input()
  set customerContactDetial(e) {
    this._customerContactDetial = e;
  }
  @Input() set reloadMap(e) {
    if (e) {
      this.loadPageData();
      this.resetRoute();
    }
  }
  @Input() set resizeMap(e) {
    if (e) {
      this.resizeMapView();
    }
  }
  @Output() showJobDetails: EventEmitter<any> = new EventEmitter<any>();
  _selectedJob: any;
  _customerContactDetial: any;
  mapToggel = true;
  msgCount = 0;
  emailData = {};
  userType: any;
  imgpath: any;
  workerProfileImg: any = `assets/images/male-circle.png`;
  workerCountry: any;
  originIcon = 'assets/img/map/worker.svg';
  destinationIcon = 'assets/img/map/scale-Anim.gif';
  subscription: Subscription;
  jobsiteLocation: any;
  routeControl: any;
  jobMarkerControl: any;
  workerMarkerControl: any;
  endPosition: any;
  geofenceRadius = 0.5 * 1609; // 1 miles (1 miles = 1609 meteres)
  totalDistance: any;
  totalTime: any;
  currentSpeed: any;
  currentRoute: any;
  workerProfileToggle = false;
  lastUpdateTime: any;
  estimatedCompletionTime: any;
  group: any;
  baseRoute: any;
  trafficData: any;
  timeFormat = 'L, LT z';
  timeZone = '';
  @ViewChild('workerLiveTemplate') workerLiveTemp: ElementRef;
  @ViewChild('jobDetailsTemplate') jobDetailsTemp: ElementRef;
  @ViewChild('workerTrackerMapId') mapContainer: ElementRef;
  map: any;
  osmMapKey: any;
  msgData: any;
  constructor(
    private _gpsService: GpsSocketService,
    private _commonService: CommonService,
    private _modalService: ModalService,
    private _appState: AppStateService,
    private _workerTrackerService: WorkerTrackerService,
    private _messageService: MessageService,

  ) {
  }

  ngOnInit() {
    this.userType = this._appState.getAccessType();
    this.imgpath = environment.baseUrl + '/' + environment.apiVersion;
    this.loadPageData();
  }
  loadPageData() {
    this.endPosition = { lat: this._selectedJob.jobsite.geolocation__Latitude__s, lng: this._selectedJob.jobsite.geolocation__Longitude__s }
    this._gpsService.connectSocket(this._selectedJob.worker.sfdcId);
    this.setWorkerProfileImg();
    this.loadWorkerStartDetail({ jobSfdcId: this._selectedJob.sfdcId, workerSfdcId: this._selectedJob.worker.sfdcId });
    this.workerCountry = this._selectedJob.worker.contact.MailingCountry;
  }
  loadWorkerStartDetail(filter) {
    this._workerTrackerService.getJobWorkerTracker(filter, false).subscribe(res => {
      let workerCoordinate;
      if (res && res.length) {
        const payload = res[0];
        if (payload['jobSfdcId'] === this._selectedJob.sfdcId && payload['workerSfdcId'] === this._selectedJob.worker.sfdcId) {
          workerCoordinate = { lat: payload['startLatitude'], lng: payload['startLongitude'] };
        }
      } else if (this._selectedJob && this._selectedJob.worker && this._selectedJob.worker.contact &&
        this._selectedJob.worker.contact.Geo_Location__Latitude__s && this._selectedJob.worker.contact.Geo_Location__Longitude__s) {
        workerCoordinate = {
          lat: this._selectedJob.worker.contact.Geo_Location__Latitude__s,
          lng: this._selectedJob.worker.contact.Geo_Location__Longitude__s
        };
      } else if (this._selectedJob && this._selectedJob.vendorsite &&
        this._selectedJob.vendorsite.geolocation__Latitude__s && this._selectedJob.vendorsite.geolocation__Longitude__s) {
        workerCoordinate = {
          lat: this._selectedJob.vendorsite.geolocation__Latitude__s,
          lng: this._selectedJob.vendorsite.geolocation__Longitude__s
        };
      }
      if (workerCoordinate && workerCoordinate.lat) {
        this.setWorkerCoordinate(workerCoordinate);
      }
    });
  }
  /**
   * Prepair worker profile photo
   */
  setWorkerProfileImg() {
    const getProfileImageUrl = (job) => {
      if (this.imgpath.indexOf('localhost') !== -1) {
        this.imgpath = 'https://den.dev.serviceo.me/api/v1';
      }
      if (job && job.worker && job.worker.user) {
        if (job.worker.user.profileImage && job.worker.user.url) {
          return `${this.imgpath}/Containers${job.worker.user.url}${job.worker.user.profileImage}`;
        } else if (job.worker.user.gender && job.worker.user.gender === 'Female') {
          return `assets/images/female-circle.png`;
        } else {
          return `assets/images/male-circle.png`;
        }
      } else {
        return `assets/images/male-circle.png`;
      }
    };

    this.workerProfileImg = getProfileImageUrl(this._selectedJob);
    this.timeZone = this._selectedJob.jobsite.Time_Zone__c;
  }

  ngAfterViewInit() {
    this.osmMapKey = JSON.parse(localStorage.getItem('osmMapObjKey')).webApiKey;
     this.resetRoute()
    // this.setWorkerCoordinate({lat: 28.62071, lng: 77.28299 })
  }

  /**
 * On Worker selection
 */
 resetRoute() {
  this.loadmap();
  this.subscribeWorkerLocation();
  this.setJobInfoPopup();
 }

  /**
   * load map in mapContainer
   */
  loadmap() {
    if (this.map) {
      this.map.remove();
    }
    this.map = L.map('workerTrackerMapId', { zoomControl: false, closePopupOnClick: false }).setView(this.endPosition, 10);
    const url = this._commonService.fetchMapBoxTileLayerUrl();
    // add the OpenStreetMap tiles
    // tslint:disable-next-line:max-line-length
    L.tileLayer(url, {
      maxZoom: 19,
      // tslint:disable-next-line:max-line-length
      attribution: '&copy; <a href="javascrip:void(0)">OpenStreetMap</a>'
    }).addTo(this.map);
    this.addGeofence(this.endPosition);
  }

  addGeofence(jobSiteLoc) {
    L.circle(jobSiteLoc, {
      stroke: true,
      color: '#4095ef',
      weight: 1,
      opacity: 0.5,
      fillColor: '#4095ef',
      fillOpacity: 0.2,
      radius: this.geofenceRadius, // radius 1 miles
    }).addTo(this.map)
  }

  /**
   * subscribe worker current position coordinate
   */
  subscribeWorkerLocation() {
    this._gpsService.getWorkerGpsLog().subscribe((data) => {
      if (data.payload && data.payload.jobId === this._selectedJob.sfdcId && data.payload.workerId === this._selectedJob.worker.sfdcId) {
        this.setWorkerCoordinate({ lat: data.payload.latitude, lng: data.payload.longitude });
        console.log('Do something with this data.', data);
      }
    });
  }

  /**
   *
   * @param driverPosition set worker current poistion lat lng in route map
   */
  setWorkerCoordinate(driverPosition) {
    const _that = this;
    const url = 'https://api.mapbox.com/directions/v5/mapbox/driving-traffic?access_token=' + this.osmMapKey;
    const lngLat = driverPosition.lng + ',' + driverPosition.lat + ';' + this.endPosition.lng + ',' + this.endPosition.lat;
    axios.post(url, querystring.stringify({
      coordinates: lngLat,
      annotations: 'congestion',
      overview: 'full',
      geometries: 'geojson',
      steps: true,
      // banner_instructions: true
    }), {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
      .then(function (response) {
        _that.currentRoute = response.data.routes[0];
        _that.createCongestionMap(_that.currentRoute);
        const summary = { totalDistance: _that.currentRoute.distance, totalTime: _that.currentRoute.duration };
        // totaldistance, totaltime
        // tslint:disable-next-line:max-line-length
        const latlngbounds = [[_that.currentRoute.geometry.coordinates[0][1], _that.currentRoute.geometry.coordinates[0][0]], [_that.currentRoute.geometry.coordinates[_that.currentRoute.geometry.coordinates.length - 1][1], _that.currentRoute.geometry.coordinates[_that.currentRoute.geometry.coordinates.length - 1][0]]];
        let center_point = _that.currentRoute.geometry.coordinates.length / 2;
        center_point = center_point ? Math.round(center_point) : 0;
        const centerLatLng = _that.currentRoute.geometry.coordinates[center_point];
        // getting center of coordinates array; later set worker detail popup on this center ppoint
        _that.setWorkerInfoPopup(_that, response.data.waypoints[0], _that.currentRoute.legs[0].steps);
        _that.setRouteInfoPopup(centerLatLng, summary, latlngbounds);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  /**
   *@param data route data received from mapbox api
   */

  createCongestionMap(data) {
    const cords = data.geometry.coordinates;
    const congestion = data.legs[0].annotation.congestion;
    const colorMap = {
      'unknown': '#36baff',
      'low': '#36baff',
      'moderate': '#f18d00',
      'heavy': '#CD201F'
    }
    const newob = (congest, latlng) => {
      return {
        'type': 'Feature',
        'properties': {
          'congest': congest,
          'color': colorMap[congest]
        },
        'geometry': {
          'type': 'LineString',
          'coordinates': [latlng]
        }
      }
    }

    const ob = [
      {
        'type': 'Feature',
        'properties': {
          'congest': congestion[0],
          'color': colorMap[congestion[0]]
        },
        'geometry': {
          'type': 'LineString',
          'coordinates': [cords[0]]
        }
      }
    ]

    let x = 1;

    for (let i = 1; i < congestion.length; i++) {
      const t = congestion[i];
      if (t === ob[x - 1].properties.congest) {
        ob[x - 1].geometry.coordinates.push(cords[i])
      } else {
        ob[x] = newob(t, cords[i]);
        x = x + 1;
      }
    }

    // create dataset for normal geolayer and another for traffic geo layer
    const baseLayer = [{
      type: 'LineString',
      coordinates: cords
    }]

    const trafficLayer = ob;
    const baseStyle = {
      'color': '#36baff',
      'weight': 5,
      'opacity': 1,
      'lineCap' : 'round',
      'lineJoin': 'round'
    };


    if (this.baseRoute && this.trafficData) {
      // remove old geojson when route updates due to either traffic in current route or agent location change.
      this.group.clearLayers();
    }
    this.baseRoute = L.geoJSON(baseLayer, {
      style: baseStyle
    })

    this.trafficData = L.geoJson(trafficLayer, {
      style: function (feature) {
        return {
          'color': feature.properties.color,
          'weight': 5,
          'opacity': 1,
          'lineCap' : 'round',
          'lineJoin': 'round'
        }
      }
    })

    this.group = new L.FeatureGroup();
    this.group.addLayer(this.baseRoute);
    this.group.addLayer(this.trafficData);
    this.map.addLayer(this.group);

    // this code was not updating the geojson when agent moved/ location chnages based on traffic
    // this.baseRoute = L.geoJSON(baseLayer, {
    //   style: baseStyle
    // }).addTo(this.map);

    // this.trafficData = L.geoJson(trafficLayer, {
    //   style: function (feature) {
    //     return {
    //       'color': feature.properties.color,
    //       'weight': 5,
    //       'opacity': 1,
    //       'lineCap' : 'round',
    //       'lineJoin': 'round'
    //     }
    //   }
    // }).addTo(this.map);


  }

  /**
   * OLD CODE TO SHOW ROUTING VIA LEAFLET ROUTING MACHINE : NO TRAFFIC INFO DISPLAYED ON ROUTE
  setWorkerCoordinate(driverPosition) {
    const _that = this;
    if (this.routeControl !== undefined) {
      this.routeControl.getPlan().setWaypoints([L.latLng(driverPosition), L.latLng(this.endPosition)]);
    } else {
      this.routeControl = L.Routing.control({
        lineOptions: {
          styles: [
            { color: 'white', opacity: 0.9, weight: 9 },
            { color: '#36baff', opacity: 1, weight: 7 }
          ]
        },
        waypoints: [
          { latLng: driverPosition, name: 'worker', options: { allowUTurn: true } },
          { latLng: this.endPosition, name: 'Job', options: { allowUTurn: true } }
        ],
        units: 'imperial',
        // autoRoute: true,
        showAlternatives: false,
        fitSelectedRoutes: true,
        waypointMode: 'snap',
        addWaypoints: false,
        routeWhileDragging: false,
        router: L.Routing.mapbox(_that.osmMapKey),
        createMarker: function (i, wp) { },
      }).addTo(this.map);
    }

    this.routeControl.on('routesfound', function (e) {
      _that.currentRoute = e.routes[0];
      const summary = _that.currentRoute.summary;
      let center_point = _that.currentRoute.coordinates.length / 2;
      center_point = center_point ? Math.round(center_point) : 0;
      const centerLatLng = _that.currentRoute.coordinates[center_point];
      _that.setWorkerInfoPopup(_that, _that.currentRoute.inputWaypoints[0], _that.currentRoute.instructions[0]);
      _that.setRouteInfoPopup(centerLatLng, summary);
    });
    this.routeControl._container.style.display = 'None';
  }
  */

  /**
   * Bind Job information popup
   */
  setJobInfoPopup() {
    this.jobMarkerControl = L.marker(this.endPosition, {
      icon: L.icon({
        iconUrl: this.destinationIcon,
        iconSize: [70, 70],
        iconAnchor: [35, 47]
      })
    }).addTo(this.map);
    const _that = this;
    this.jobMarkerControl.on('click', function (e) {
      if (_that.modelName !== 'fleet') {
        const bindPopupL = _that.jobMarkerControl.getPopup()
        if ((_that.jobMarkerControl && _that.jobMarkerControl.isPopupOpen()) || bindPopupL) {
          _that.jobMarkerControl.closePopup();
          _that.jobMarkerControl.unbindPopup();
        }
        const customHtml = _that.jobDetailsTemp.nativeElement.innerHTML;
        const customOptions = {
          autoClose: false,
          maxWidth: '1040',
          className: 'job-popup'
        }
        _that.mapToggel = false;
        _that.jobMarkerControl.bindPopup(customHtml, customOptions).openPopup()
      } else {
        _that.showJobDetails.emit(true);
      }
    });
  }

  /**
   *
   * @param _that current this
   * @param workerCoord worker position
   * @param instructions live route information
   */
  setWorkerInfoPopup(_that, workerCoord, instructions) {
    if (!_that.workerMarkerControl || !_that.workerMarkerControl.isPopupOpen()) {
      if (_that.workerMarkerControl) {
        _that.map.removeLayer(this.workerMarkerControl);
      }

      const divIcon = L.divIcon({
        className: 'custom-div-icon',
        html: '<div class="marker-worker-pin"><div class="innerdiv"><img src="' + _that.workerProfileImg + '"></div></div> ',
        iconSize: [51, 51],
        iconAnchor: [25, 57]
      });

      _that.workerMarkerControl = L.marker(L.latLng([workerCoord.location[1], workerCoord.location[0]]), {
        icon: divIcon
      }).addTo(_that.map);
      _that.workerMarkerControl.on('click', function (e) {
        const bindPopupL = _that.workerMarkerControl.getPopup()
        if ((_that.workerMarkerControl && _that.workerMarkerControl.isPopupOpen()) || bindPopupL) {
          _that.workerMarkerControl.closePopup();
          _that.workerMarkerControl.unbindPopup();
        }
        const customHtml = _that.workerLiveTemp.nativeElement.innerHTML;
        const customOptions = {
          autoClose: false,
          maxWidth: 400,
          className: 'job-popup'
        }
        _that.workerMarkerControl.bindPopup(customHtml, customOptions).openPopup()
      });
    }
  }

  /**
   *
   * @param latLng center point of route
   * @param summary details need to view in center popup like distance and time
   * @param latLngbounds array of starting and ending point so set the bounds of the map within the viewport.
   */
  setRouteInfoPopup(latLng: any, summary: any, latLngbounds: any) {
    this.lastUpdateTime = this._commonService.dateFormate(moment(), this.timeZone, this.timeFormat);
    if (latLng && summary) {
      this.totalTime = '';
      this.totalDistance = (summary.totalDistance / 1609.344).toFixed(1); // In Miles
      let estimateMinutes = Math.round(Math.abs(summary.totalTime / 60)); // In Minutes
      const minutes = Math.round(Math.abs(summary.totalTime % 3600 / 60)); // In Minutes
      const hours = Math.floor(summary.totalTime / 3600); // In Hours
      if (hours) {
        this.totalTime = hours + 'h ';
      }
      this.totalTime = this.totalTime + minutes + (minutes > 1 ? ' mins' : ' min');
      this.currentSpeed = ((summary.totalDistance / summary.totalTime % 3600) * 2.23694).toFixed(0) + 'Mph';
      if (this._selectedJob.case && this._selectedJob.case.PPE_Hours__c) {
        estimateMinutes = estimateMinutes + this._selectedJob.case.PPE_Hours__c * 60;
      }
      this.estimatedCompletionTime = this._commonService.dateFormate(moment().add(estimateMinutes, 'minutes'),
        this.timeZone, this.timeFormat);
      L.popup(
        {
          className: 'custom-popups',
          closeButton: false,
          closeOnClick: false,
        }
      )
        .setLatLng([latLng[1], latLng[0]])
        .setContent('<p>' + this.totalDistance + ' Miles ' + ' <img src="assets/img/map/car-icon.svg" /> <b>'
          + this.totalTime + ' </b> </p>')
        .openOn(this.map);
    }
    const bounds = new L.LatLngBounds(latLngbounds);
    this.map.fitBounds(bounds);
  }

  /**
   *
   * @param template Open message popup
   */
  openJobMessagePopup(template) {
    this.prePareMessageData(this._selectedJob);
  //  this.mapToggel = false;
    this._modalService.open(template, 'lg');
  }


  /**
   * get user role permission and related relation
   * @param assign job info
   */
  prePareMessageData(job) {
    this._messageService.setchatRoomId({
      count: 0,
      id: 0,
      idx: 5,
      isPublic: false,
      label1: 'Public',
      label2: '(VMS/3PS/Job)',
      sfdcId: '',
      value: job['Iron_Job_num__c']
    });

    this.msgData = {
      Case__c: job['Case__c'] || '',
      count: 0,
      roomFilter: { modelId: job['id'].toString() },
      modelId: job['id'] || '',
      modelName: 'Job',
      job: job
    };
  }

  /**
   *
   * @param count number of message
   */
  setMessageCount(count) {
    this.msgCount = count;
    if (this._selectedJob && this._selectedJob.msgCount) {
      this._selectedJob.msgCount = count;
      this._selectedJob.msgUnReadCount = 0;
    }
  }


  toggleAll() {
    this.mapToggel = !this.mapToggel;
    this.workerProfileToggle = false;
  }

    /**
   * Open Escalation Model 
   * @param modal Escalation HTML Content
   */
  openProblemEscalation(modal) {
    this._modalService.open(modal, 'lg');
  }

 /**
  * After Escalation
  */
  escalationOnItemHandler(e){
  // after create Escalation
  }

  // resize the map
  resizeMapView() {
    setTimeout(() => {
      this.map && this.map.invalidateSize()
    }, 400);
  }

  /**
   * discount socket
   */
  ngOnDestroy() {
    const allWorkerPage = this.modelName && this.modelName === 'fleet' ? true : false;
    this._gpsService.workerDiscount(this._selectedJob.worker.sfdcId, allWorkerPage);
  }

}
