/* tslint:disable */
import {
  TimeBreak,
  TimeClockManager,
  Contact
} from '../index';

declare var Object: any;
export interface TimeChangeRequestInterface {
  "sfdcId"?: string;
  "CreatedById"?: string;
  "CurrencyIsoCode"?: string;
  "LastModifiedById"?: string;
  "OwnerId"?: string;
  "Name"?: string;
  "Actual_Break_End__c"?: Date;
  "Actual_Break_Start__c"?: Date;
  "Actual_Check_In__c"?: Date;
  "Approved_By__c"?: string;
  "Created_By__c"?: string;
  "Last_Modified_By__c"?: string;
  "Requested_Break_End__c"?: Date;
  "Requested_Break_Start__c"?: Date;
  "Requested_Check_In__c"?: Date;
  "Requested_Check_Out__c"?: Date;
  "Request_Type__c"?: string;
  "Status__c"?: string;
  "Time_Break__c"?: string;
  "Time_Change_Request_Reason__c"?: string;
  "Timeclock_Manager__c"?: string;
  "Actual_Check_Out__c"?: Date;
  "CreatedDate"?: Date;
  "IsDeleted"?: boolean;
  "LastModifiedDate"?: Date;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  timeBreaks?: TimeBreak;
  timeClockManager?: TimeClockManager;
  createdBy?: Contact;
  lastModifiedBy?: Contact;
  approvedBy?: Contact;
}

export class TimeChangeRequest implements TimeChangeRequestInterface {
  "sfdcId": string;
  "CreatedById": string;
  "CurrencyIsoCode": string;
  "LastModifiedById": string;
  "OwnerId": string;
  "Name": string;
  "Actual_Break_End__c": Date;
  "Actual_Break_Start__c": Date;
  "Actual_Check_In__c": Date;
  "Approved_By__c": string;
  "Created_By__c": string;
  "Last_Modified_By__c": string;
  "Requested_Break_End__c": Date;
  "Requested_Break_Start__c": Date;
  "Requested_Check_In__c": Date;
  "Requested_Check_Out__c": Date;
  "Request_Type__c": string;
  "Status__c": string;
  "Time_Break__c": string;
  "Time_Change_Request_Reason__c": string;
  "Timeclock_Manager__c": string;
  "Actual_Check_Out__c": Date;
  "CreatedDate": Date;
  "IsDeleted": boolean;
  "LastModifiedDate": Date;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  timeBreaks: TimeBreak;
  timeClockManager: TimeClockManager;
  createdBy: Contact;
  lastModifiedBy: Contact;
  approvedBy: Contact;
  constructor(data?: TimeChangeRequestInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `TimeChangeRequest`.
   */
  public static getModelName() {
    return "TimeChangeRequest";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of TimeChangeRequest for dynamic purposes.
  **/
  public static factory(data: TimeChangeRequestInterface): TimeChangeRequest{
    return new TimeChangeRequest(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'TimeChangeRequest',
      plural: 'TimeChangeRequests',
      path: 'TimeChangeRequests',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "Actual_Break_End__c": {
          name: 'Actual_Break_End__c',
          type: 'Date'
        },
        "Actual_Break_Start__c": {
          name: 'Actual_Break_Start__c',
          type: 'Date'
        },
        "Actual_Check_In__c": {
          name: 'Actual_Check_In__c',
          type: 'Date'
        },
        "Approved_By__c": {
          name: 'Approved_By__c',
          type: 'string'
        },
        "Created_By__c": {
          name: 'Created_By__c',
          type: 'string'
        },
        "Last_Modified_By__c": {
          name: 'Last_Modified_By__c',
          type: 'string'
        },
        "Requested_Break_End__c": {
          name: 'Requested_Break_End__c',
          type: 'Date'
        },
        "Requested_Break_Start__c": {
          name: 'Requested_Break_Start__c',
          type: 'Date'
        },
        "Requested_Check_In__c": {
          name: 'Requested_Check_In__c',
          type: 'Date'
        },
        "Requested_Check_Out__c": {
          name: 'Requested_Check_Out__c',
          type: 'Date'
        },
        "Request_Type__c": {
          name: 'Request_Type__c',
          type: 'string'
        },
        "Status__c": {
          name: 'Status__c',
          type: 'string'
        },
        "Time_Break__c": {
          name: 'Time_Break__c',
          type: 'string'
        },
        "Time_Change_Request_Reason__c": {
          name: 'Time_Change_Request_Reason__c',
          type: 'string'
        },
        "Timeclock_Manager__c": {
          name: 'Timeclock_Manager__c',
          type: 'string'
        },
        "Actual_Check_Out__c": {
          name: 'Actual_Check_Out__c',
          type: 'Date'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        timeBreaks: {
          name: 'timeBreaks',
          type: 'TimeBreak',
          model: 'TimeBreak',
          relationType: 'belongsTo',
                  keyFrom: 'Time_Break__c',
          keyTo: 'sfdcId'
        },
        timeClockManager: {
          name: 'timeClockManager',
          type: 'TimeClockManager',
          model: 'TimeClockManager',
          relationType: 'belongsTo',
                  keyFrom: 'Timeclock_Manager__c',
          keyTo: 'sfdcId'
        },
        createdBy: {
          name: 'createdBy',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Created_By__c',
          keyTo: 'sfdcId'
        },
        lastModifiedBy: {
          name: 'lastModifiedBy',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Last_Modified_By__c',
          keyTo: 'sfdcId'
        },
        approvedBy: {
          name: 'approvedBy',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Approved_By__c',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
