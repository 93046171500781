import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { ContactAccountLookupComponent } from './contact-account-lookup.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule
  ],
  declarations: [ContactAccountLookupComponent],
  exports: [ContactAccountLookupComponent],
  providers: []
})
export class ContactAccountLookupModule { }
