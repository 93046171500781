/* tslint:disable */
import {
  Contact,
  Worker,
  MyWorkLeaveTypeSetting
} from '../index';

declare var Object: any;
export interface MyWorkLeaveApprovalSettingInterface {
  "sfdcId"?: string;
  "OwnerId"?: string;
  "IsDeleted"?: boolean;
  "Name"?: string;
  "CreatedById"?: string;
  "CreatedDate"?: Date;
  "CurrencyIsoCode"?: string;
  "LastModifiedDate"?: Date;
  "LastModifiedById"?: string;
  "LastActivityDate"?: Date;
  "LastViewedDate"?: Date;
  "LastReferencedDate"?: Date;
  "Approval_Email_Address__c"?: string;
  "Approval_Order__c"?: number;
  "Approval_Requirement__c"?: string;
  "Approver_Type__c"?: string;
  "Created_By_Contact__c"?: string;
  "Modified_By_Contact__c"?: string;
  "MyWorkLeave_Type_Setting__c"?: string;
  "Worker__c"?: string;
  "Worker_Type__c"?: string;
  "Worker_Type1__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  createdByContact?: Contact;
  modifiedByContact?: Contact;
  worker?: Worker;
  myWorkLeaveTypeSetting?: MyWorkLeaveTypeSetting;
}

export class MyWorkLeaveApprovalSetting implements MyWorkLeaveApprovalSettingInterface {
  "sfdcId": string;
  "OwnerId": string;
  "IsDeleted": boolean;
  "Name": string;
  "CreatedById": string;
  "CreatedDate": Date;
  "CurrencyIsoCode": string;
  "LastModifiedDate": Date;
  "LastModifiedById": string;
  "LastActivityDate": Date;
  "LastViewedDate": Date;
  "LastReferencedDate": Date;
  "Approval_Email_Address__c": string;
  "Approval_Order__c": number;
  "Approval_Requirement__c": string;
  "Approver_Type__c": string;
  "Created_By_Contact__c": string;
  "Modified_By_Contact__c": string;
  "MyWorkLeave_Type_Setting__c": string;
  "Worker__c": string;
  "Worker_Type__c": string;
  "Worker_Type1__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  createdByContact: Contact;
  modifiedByContact: Contact;
  worker: Worker;
  myWorkLeaveTypeSetting: MyWorkLeaveTypeSetting;
  constructor(data?: MyWorkLeaveApprovalSettingInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `MyWorkLeaveApprovalSetting`.
   */
  public static getModelName() {
    return "MyWorkLeaveApprovalSetting";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of MyWorkLeaveApprovalSetting for dynamic purposes.
  **/
  public static factory(data: MyWorkLeaveApprovalSettingInterface): MyWorkLeaveApprovalSetting{
    return new MyWorkLeaveApprovalSetting(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'MyWorkLeaveApprovalSetting',
      plural: 'MyWorkLeaveApprovalSettings',
      path: 'MyWorkLeaveApprovalSettings',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "LastActivityDate": {
          name: 'LastActivityDate',
          type: 'Date'
        },
        "LastViewedDate": {
          name: 'LastViewedDate',
          type: 'Date'
        },
        "LastReferencedDate": {
          name: 'LastReferencedDate',
          type: 'Date'
        },
        "Approval_Email_Address__c": {
          name: 'Approval_Email_Address__c',
          type: 'string'
        },
        "Approval_Order__c": {
          name: 'Approval_Order__c',
          type: 'number'
        },
        "Approval_Requirement__c": {
          name: 'Approval_Requirement__c',
          type: 'string'
        },
        "Approver_Type__c": {
          name: 'Approver_Type__c',
          type: 'string'
        },
        "Created_By_Contact__c": {
          name: 'Created_By_Contact__c',
          type: 'string'
        },
        "Modified_By_Contact__c": {
          name: 'Modified_By_Contact__c',
          type: 'string'
        },
        "MyWorkLeave_Type_Setting__c": {
          name: 'MyWorkLeave_Type_Setting__c',
          type: 'string'
        },
        "Worker__c": {
          name: 'Worker__c',
          type: 'string'
        },
        "Worker_Type__c": {
          name: 'Worker_Type__c',
          type: 'string'
        },
        "Worker_Type1__c": {
          name: 'Worker_Type1__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        createdByContact: {
          name: 'createdByContact',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Created_By_Contact__c',
          keyTo: 'sfdcId'
        },
        modifiedByContact: {
          name: 'modifiedByContact',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Modified_By_Contact__c',
          keyTo: 'sfdcId'
        },
        worker: {
          name: 'worker',
          type: 'Worker',
          model: 'Worker',
          relationType: 'belongsTo',
                  keyFrom: 'Worker__c',
          keyTo: 'sfdcId'
        },
        myWorkLeaveTypeSetting: {
          name: 'myWorkLeaveTypeSetting',
          type: 'MyWorkLeaveTypeSetting',
          model: 'MyWorkLeaveTypeSetting',
          relationType: 'belongsTo',
                  keyFrom: 'MyWorkLeave_Type_Setting__c',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
