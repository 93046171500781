/* tslint:disable */

declare var Object: any;
export interface SprintMasterInterface {
  "sfdcId"?: string;
  "CreatedBy"?: string;
  "CurrencyIsoCode"?: string;
  "LastModifiedBy"?: string;
  "Owner"?: string;
  "Name"?: string;
  "IRON_ExtID__c"?: string;
  "Sprint_Cycle__c"?: string;
  "Sprint_Cycle_Number__c"?: number;
  "Sprint_End_Date__c"?: Date;
  "Sprint_Start_Date__c"?: Date;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class SprintMaster implements SprintMasterInterface {
  "sfdcId": string;
  "CreatedBy": string;
  "CurrencyIsoCode": string;
  "LastModifiedBy": string;
  "Owner": string;
  "Name": string;
  "IRON_ExtID__c": string;
  "Sprint_Cycle__c": string;
  "Sprint_Cycle_Number__c": number;
  "Sprint_End_Date__c": Date;
  "Sprint_Start_Date__c": Date;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: SprintMasterInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `SprintMaster`.
   */
  public static getModelName() {
    return "SprintMaster";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of SprintMaster for dynamic purposes.
  **/
  public static factory(data: SprintMasterInterface): SprintMaster{
    return new SprintMaster(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'SprintMaster',
      plural: 'SprintMasters',
      path: 'SprintMasters',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "CreatedBy": {
          name: 'CreatedBy',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "LastModifiedBy": {
          name: 'LastModifiedBy',
          type: 'string'
        },
        "Owner": {
          name: 'Owner',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "IRON_ExtID__c": {
          name: 'IRON_ExtID__c',
          type: 'string'
        },
        "Sprint_Cycle__c": {
          name: 'Sprint_Cycle__c',
          type: 'string'
        },
        "Sprint_Cycle_Number__c": {
          name: 'Sprint_Cycle_Number__c',
          type: 'number'
        },
        "Sprint_End_Date__c": {
          name: 'Sprint_End_Date__c',
          type: 'Date'
        },
        "Sprint_Start_Date__c": {
          name: 'Sprint_Start_Date__c',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
