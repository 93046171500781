import { CommonService } from 'shared/services/common.service';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';

@Component({
    selector: 'app-feed-detail',
    templateUrl: './feed-detail.component.html',
    styleUrls: ['./feed-detail.component.css']
})
export class FeedDetailComponent implements OnInit, OnDestroy {
    @Input() modelId: any;
    @Input() embeddedView = false;
    @Input() timeZone = '';
    @Input() set rowData(e) {
        this.feed = e;
        if (this.feed && this.feed.title) {
            this.feed.title = (this.feed.title === 'null from true') ? 'false from true' : this.feed.title;
        }
        const changelog = (e.changelog) ? this.IsJsonString(e.changelog) : '';
        if (changelog && changelog.length > 0) {
            this.formatLogs(changelog);
        }
    }
    errorMessage: string;
    feed: any;
    logDataItems: any = [];
    userTimeZone: any;

    constructor(
        private _commonService: CommonService
    ) { }

    ngOnInit() {
        // this._commonService.saveCurrentPageAnalytics('Activity Feed', 'Activity Details')
        this._commonService.saveCurrentPageAnalytics('Activity Feed', 'Activity Feed Details')
    }

    formatLogs(logData) {
        if (logData.length > 0) {
            logData.forEach((item) => {
                const description = (item.fieldLabel) ? item.fieldLabel : '';
                let type = 'string';
                const IsoDateRe = new RegExp('^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}.[0-9]{3}Z$');
                const matches = IsoDateRe.exec(item.oldValue) || IsoDateRe.exec(item.newValue);
                if (matches) {
                    type = 'date';
                    item.oldValue = this._commonService.dateFormate(item.oldValue,
                        this.timeZone, 'MM/D/YYYY, hh:mm A z');
                    item.newValue = this._commonService.dateFormate(item.newValue,
                        this.timeZone, 'MM/D/YYYY, hh:mm A z');
                }
                if ((item.oldValue === '' || item.oldValue === null) && item.newValue === true) {
                    item.oldValue = false;
                }
                this.logDataItems.push({
                    oldValue: item.oldValue,
                    newValue: item.newValue,
                    type: type,
                    description: description
                });
            });
        }
    }
    ngOnDestroy() { }

    /**
     * 
     * @param str | to validate JSON 
     */
    IsJsonString(str) {
        try {
            return JSON.parse(str);
        } catch (e) {
            return false;
        }
    }
}



