import { Component, OnInit, Input } from '@angular/core';
import { RequestFormReceiptApi } from '../../../../../sdk';
import { PreloaderService } from '../../../../../services/preloader.service';
import { CommonService } from '../../../../../services/common.service';

@Component({
    selector: 'job-orignal-message',
    templateUrl: './job-orignal-message.component.html',
    styleUrls: ['./job-orignal-message.component.css']
})

export class JobOrignalMessageComponent implements OnInit {

    @Input() jobId: string;
    @Input() caseJobOrderId: string;
    errorMessage = '';
    data: any;

    constructor(
        private _preloaderService: PreloaderService,
        private _commonService: CommonService,
        private _requestFormReceiptApi: RequestFormReceiptApi
    ) { }

    ngOnInit() {
        this._commonService.saveCurrentPageAnalytics('Job Manager Console - Details', 'B2B Message')
        if (this.jobId && this.caseJobOrderId) {
            this.loadData(this.caseJobOrderId);
        } else {
            this.errorMessage = 'No details to display.';
        }
    }

    loadData(caseJobOrderId) {
        const reqObj = {
            'where': { 'Work_Order__c': caseJobOrderId },
            'fields': ['id', 'sfdcId'],
            'include': [
                {
                    'relation': 'ironCustomField',
                    'scope': {
                        'fields': ['sfdcId', 'Request_Form_Receipt__c', 'id', 'B2B_Original_Message__c', 'Work_Order__c']
                    }
                }
            ]
        };
        this._requestFormReceiptApi.find(reqObj).subscribe(
            result => {
                if (result && Object.keys(result).length > 0) {
                    const resultData = result[0];
                    if (resultData && resultData['ironCustomField'] && resultData['ironCustomField'].B2B_Original_Message__c) {
                        this.data = JSON.parse(resultData['ironCustomField'].B2B_Original_Message__c);
                        this.errorMessage = '';
                    } else {
                        this.errorMessage = 'No details found.';
                    }
                } else {
                    this.errorMessage = 'No details found.';
                }
                this._preloaderService.hidePreloader();
            },
            error => {
                this.errorMessage = error.message;
                this._preloaderService.hidePreloader();
            }
        );
    }


}
