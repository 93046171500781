/* tslint:disable */

declare var Object: any;
export interface SiteVisitReportInterface {
  "sfdcId"?: string;
  "CreatedById"?: string;
  "CurrencyIsoCode"?: string;
  "LastModifiedById"?: string;
  "OwnerId"?: string;
  "Name"?: string;
  "Activities_Details_Actual__c"?: string;
  "Additional_Customer_POC_Email__c"?: string;
  "Additional_Customer_POC_Name__c"?: string;
  "Additional_Customer_POC_Phone__c"?: string;
  "Any_Roadblocks_In_Activity__c"?: string;
  "Appointment_End_Date_Actual__c"?: Date;
  "Customer_POC_Checkout_DateTime__c"?: Date;
  "Appointment_Start_Date_Actual__c"?: Date;
  "Customer_POC_Checkin_DateTime__c"?: Date;
  "Communication_Vendor_PGMO__c"?: string;
  "Communication_Worker_PGMO__c"?: string;
  "Customer_POC_Submitted_Date_Time__c"?: Date;
  "Engineer_Able_To_Communicate_Properly__c"?: string;
  "Engineer_In_Proper_Attire__c"?: string;
  "Engineer_Issue_Diagnosis_Resolution__c"?: string;
  "Engineer_Reached_On_Time__c"?: string;
  "Engineer_Was_Helpful__c"?: string;
  "Equipment_Make_Model_Performed_On__c"?: string;
  "Equipment_Serial_Performed_On__c"?: string;
  "Evaluated_The_Moment_Engg_Started_Work__c"?: string;
  "ICC_Submitted_DateTime__c"?: Date;
  "Job__c"?: string;
  "Overall_Satisfaction__c"?: string;
  "PGMO_Submitted_DateTime__c"?: Date;
  "POC_Signature_E_Sign__c"?: boolean;
  "Proactive_Resolution_By_Engineer__c"?: string;
  "Professionalism_Vendor_PGMO__c"?: string;
  "Professionalism_Worker_PGMO__c"?: string;
  "Quality_Vendor_PGMO__c"?: string;
  "Quality_Worker_PGMO__c"?: string;
  "Review_Vendor_PGMO__c"?: string;
  "Review_Worker_PGMO__c"?: string;
  "Service_Improvement_Remarks_If_Any__c"?: string;
  "Status__c"?: string;
  "Timecard__c"?: string;
  "Value_Vendor_PGMO__c"?: string;
  "Value_Worker_PGMO__c"?: string;
  "Vendor_Communication__c"?: string;
  "Vendor_Quality__c"?: string;
  "Vendor_Review__c"?: string;
  "Vendor_Value__c"?: string;
  "Vendor_Professionalism__c"?: string;
  "Verify_POC_Details__c"?: boolean;
  "Worker_Arrival_On_Time__c"?: string;
  "Worker_Arrival_POC__c"?: string;
  "Worker_Arrival_On_Time_Worker_PGMO__c"?: string;
  "Communication__c"?: string;
  "Communication_POC__c"?: string;
  "Value__c"?: string;
  "Value_POC__c"?: string;
  "Worker_Mobile_OS_Android_IOS__c"?: string;
  "Review__c"?: string;
  "Review_POC__c"?: string;
  "Professionalism__c"?: string;
  "Professionalism_POC__c"?: string;
  "Quality__c"?: string;
  "Quality_POC__c"?: string;
  "Worker_Resend_Email__c"?: string;
  "Worker_Submitted_DateTime__c"?: Date;
  "IsDeleted"?: boolean;
  "CreatedDate"?: Date;
  "LastModifiedDate"?: Date;
  "LastReferencedDate"?: Date;
  "LastViewedDate"?: Date;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class SiteVisitReport implements SiteVisitReportInterface {
  "sfdcId": string;
  "CreatedById": string;
  "CurrencyIsoCode": string;
  "LastModifiedById": string;
  "OwnerId": string;
  "Name": string;
  "Activities_Details_Actual__c": string;
  "Additional_Customer_POC_Email__c": string;
  "Additional_Customer_POC_Name__c": string;
  "Additional_Customer_POC_Phone__c": string;
  "Any_Roadblocks_In_Activity__c": string;
  "Appointment_End_Date_Actual__c": Date;
  "Customer_POC_Checkout_DateTime__c": Date;
  "Appointment_Start_Date_Actual__c": Date;
  "Customer_POC_Checkin_DateTime__c": Date;
  "Communication_Vendor_PGMO__c": string;
  "Communication_Worker_PGMO__c": string;
  "Customer_POC_Submitted_Date_Time__c": Date;
  "Engineer_Able_To_Communicate_Properly__c": string;
  "Engineer_In_Proper_Attire__c": string;
  "Engineer_Issue_Diagnosis_Resolution__c": string;
  "Engineer_Reached_On_Time__c": string;
  "Engineer_Was_Helpful__c": string;
  "Equipment_Make_Model_Performed_On__c": string;
  "Equipment_Serial_Performed_On__c": string;
  "Evaluated_The_Moment_Engg_Started_Work__c": string;
  "ICC_Submitted_DateTime__c": Date;
  "Job__c": string;
  "Overall_Satisfaction__c": string;
  "PGMO_Submitted_DateTime__c": Date;
  "POC_Signature_E_Sign__c": boolean;
  "Proactive_Resolution_By_Engineer__c": string;
  "Professionalism_Vendor_PGMO__c": string;
  "Professionalism_Worker_PGMO__c": string;
  "Quality_Vendor_PGMO__c": string;
  "Quality_Worker_PGMO__c": string;
  "Review_Vendor_PGMO__c": string;
  "Review_Worker_PGMO__c": string;
  "Service_Improvement_Remarks_If_Any__c": string;
  "Status__c": string;
  "Timecard__c": string;
  "Value_Vendor_PGMO__c": string;
  "Value_Worker_PGMO__c": string;
  "Vendor_Communication__c": string;
  "Vendor_Quality__c": string;
  "Vendor_Review__c": string;
  "Vendor_Value__c": string;
  "Vendor_Professionalism__c": string;
  "Verify_POC_Details__c": boolean;
  "Worker_Arrival_On_Time__c": string;
  "Worker_Arrival_POC__c": string;
  "Worker_Arrival_On_Time_Worker_PGMO__c": string;
  "Communication__c": string;
  "Communication_POC__c": string;
  "Value__c": string;
  "Value_POC__c": string;
  "Worker_Mobile_OS_Android_IOS__c": string;
  "Review__c": string;
  "Review_POC__c": string;
  "Professionalism__c": string;
  "Professionalism_POC__c": string;
  "Quality__c": string;
  "Quality_POC__c": string;
  "Worker_Resend_Email__c": string;
  "Worker_Submitted_DateTime__c": Date;
  "IsDeleted": boolean;
  "CreatedDate": Date;
  "LastModifiedDate": Date;
  "LastReferencedDate": Date;
  "LastViewedDate": Date;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: SiteVisitReportInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `SiteVisitReport`.
   */
  public static getModelName() {
    return "SiteVisitReport";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of SiteVisitReport for dynamic purposes.
  **/
  public static factory(data: SiteVisitReportInterface): SiteVisitReport{
    return new SiteVisitReport(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'SiteVisitReport',
      plural: 'SiteVisitReports',
      path: 'SiteVisitReports',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "Activities_Details_Actual__c": {
          name: 'Activities_Details_Actual__c',
          type: 'string'
        },
        "Additional_Customer_POC_Email__c": {
          name: 'Additional_Customer_POC_Email__c',
          type: 'string'
        },
        "Additional_Customer_POC_Name__c": {
          name: 'Additional_Customer_POC_Name__c',
          type: 'string'
        },
        "Additional_Customer_POC_Phone__c": {
          name: 'Additional_Customer_POC_Phone__c',
          type: 'string'
        },
        "Any_Roadblocks_In_Activity__c": {
          name: 'Any_Roadblocks_In_Activity__c',
          type: 'string'
        },
        "Appointment_End_Date_Actual__c": {
          name: 'Appointment_End_Date_Actual__c',
          type: 'Date'
        },
        "Customer_POC_Checkout_DateTime__c": {
          name: 'Customer_POC_Checkout_DateTime__c',
          type: 'Date'
        },
        "Appointment_Start_Date_Actual__c": {
          name: 'Appointment_Start_Date_Actual__c',
          type: 'Date'
        },
        "Customer_POC_Checkin_DateTime__c": {
          name: 'Customer_POC_Checkin_DateTime__c',
          type: 'Date'
        },
        "Communication_Vendor_PGMO__c": {
          name: 'Communication_Vendor_PGMO__c',
          type: 'string'
        },
        "Communication_Worker_PGMO__c": {
          name: 'Communication_Worker_PGMO__c',
          type: 'string'
        },
        "Customer_POC_Submitted_Date_Time__c": {
          name: 'Customer_POC_Submitted_Date_Time__c',
          type: 'Date'
        },
        "Engineer_Able_To_Communicate_Properly__c": {
          name: 'Engineer_Able_To_Communicate_Properly__c',
          type: 'string'
        },
        "Engineer_In_Proper_Attire__c": {
          name: 'Engineer_In_Proper_Attire__c',
          type: 'string'
        },
        "Engineer_Issue_Diagnosis_Resolution__c": {
          name: 'Engineer_Issue_Diagnosis_Resolution__c',
          type: 'string'
        },
        "Engineer_Reached_On_Time__c": {
          name: 'Engineer_Reached_On_Time__c',
          type: 'string'
        },
        "Engineer_Was_Helpful__c": {
          name: 'Engineer_Was_Helpful__c',
          type: 'string'
        },
        "Equipment_Make_Model_Performed_On__c": {
          name: 'Equipment_Make_Model_Performed_On__c',
          type: 'string'
        },
        "Equipment_Serial_Performed_On__c": {
          name: 'Equipment_Serial_Performed_On__c',
          type: 'string'
        },
        "Evaluated_The_Moment_Engg_Started_Work__c": {
          name: 'Evaluated_The_Moment_Engg_Started_Work__c',
          type: 'string'
        },
        "ICC_Submitted_DateTime__c": {
          name: 'ICC_Submitted_DateTime__c',
          type: 'Date'
        },
        "Job__c": {
          name: 'Job__c',
          type: 'string'
        },
        "Overall_Satisfaction__c": {
          name: 'Overall_Satisfaction__c',
          type: 'string'
        },
        "PGMO_Submitted_DateTime__c": {
          name: 'PGMO_Submitted_DateTime__c',
          type: 'Date'
        },
        "POC_Signature_E_Sign__c": {
          name: 'POC_Signature_E_Sign__c',
          type: 'boolean'
        },
        "Proactive_Resolution_By_Engineer__c": {
          name: 'Proactive_Resolution_By_Engineer__c',
          type: 'string'
        },
        "Professionalism_Vendor_PGMO__c": {
          name: 'Professionalism_Vendor_PGMO__c',
          type: 'string'
        },
        "Professionalism_Worker_PGMO__c": {
          name: 'Professionalism_Worker_PGMO__c',
          type: 'string'
        },
        "Quality_Vendor_PGMO__c": {
          name: 'Quality_Vendor_PGMO__c',
          type: 'string'
        },
        "Quality_Worker_PGMO__c": {
          name: 'Quality_Worker_PGMO__c',
          type: 'string'
        },
        "Review_Vendor_PGMO__c": {
          name: 'Review_Vendor_PGMO__c',
          type: 'string'
        },
        "Review_Worker_PGMO__c": {
          name: 'Review_Worker_PGMO__c',
          type: 'string'
        },
        "Service_Improvement_Remarks_If_Any__c": {
          name: 'Service_Improvement_Remarks_If_Any__c',
          type: 'string'
        },
        "Status__c": {
          name: 'Status__c',
          type: 'string'
        },
        "Timecard__c": {
          name: 'Timecard__c',
          type: 'string'
        },
        "Value_Vendor_PGMO__c": {
          name: 'Value_Vendor_PGMO__c',
          type: 'string'
        },
        "Value_Worker_PGMO__c": {
          name: 'Value_Worker_PGMO__c',
          type: 'string'
        },
        "Vendor_Communication__c": {
          name: 'Vendor_Communication__c',
          type: 'string'
        },
        "Vendor_Quality__c": {
          name: 'Vendor_Quality__c',
          type: 'string'
        },
        "Vendor_Review__c": {
          name: 'Vendor_Review__c',
          type: 'string'
        },
        "Vendor_Value__c": {
          name: 'Vendor_Value__c',
          type: 'string'
        },
        "Vendor_Professionalism__c": {
          name: 'Vendor_Professionalism__c',
          type: 'string'
        },
        "Verify_POC_Details__c": {
          name: 'Verify_POC_Details__c',
          type: 'boolean'
        },
        "Worker_Arrival_On_Time__c": {
          name: 'Worker_Arrival_On_Time__c',
          type: 'string'
        },
        "Worker_Arrival_POC__c": {
          name: 'Worker_Arrival_POC__c',
          type: 'string'
        },
        "Worker_Arrival_On_Time_Worker_PGMO__c": {
          name: 'Worker_Arrival_On_Time_Worker_PGMO__c',
          type: 'string'
        },
        "Communication__c": {
          name: 'Communication__c',
          type: 'string'
        },
        "Communication_POC__c": {
          name: 'Communication_POC__c',
          type: 'string'
        },
        "Value__c": {
          name: 'Value__c',
          type: 'string'
        },
        "Value_POC__c": {
          name: 'Value_POC__c',
          type: 'string'
        },
        "Worker_Mobile_OS_Android_IOS__c": {
          name: 'Worker_Mobile_OS_Android_IOS__c',
          type: 'string'
        },
        "Review__c": {
          name: 'Review__c',
          type: 'string'
        },
        "Review_POC__c": {
          name: 'Review_POC__c',
          type: 'string'
        },
        "Professionalism__c": {
          name: 'Professionalism__c',
          type: 'string'
        },
        "Professionalism_POC__c": {
          name: 'Professionalism_POC__c',
          type: 'string'
        },
        "Quality__c": {
          name: 'Quality__c',
          type: 'string'
        },
        "Quality_POC__c": {
          name: 'Quality_POC__c',
          type: 'string'
        },
        "Worker_Resend_Email__c": {
          name: 'Worker_Resend_Email__c',
          type: 'string'
        },
        "Worker_Submitted_DateTime__c": {
          name: 'Worker_Submitted_DateTime__c',
          type: 'Date'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "LastReferencedDate": {
          name: 'LastReferencedDate',
          type: 'Date'
        },
        "LastViewedDate": {
          name: 'LastViewedDate',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
