/* tslint:disable */
import {
  TemplateDetail
} from '../index';

declare var Object: any;
export interface TemplateHeaderInterface {
  "sfdcId"?: string;
  "Category__c"?: string;
  "Description__c"?: string;
  "Sub_Category__c"?: string;
  "Template_Name__c"?: string;
  "Template_Type__c"?: string;
  "Version__c"?: number;
  "createdOnServiceo"?: boolean;
  "isDeleted"?: boolean;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  templateDetail?: TemplateDetail[];
}

export class TemplateHeader implements TemplateHeaderInterface {
  "sfdcId": string;
  "Category__c": string;
  "Description__c": string;
  "Sub_Category__c": string;
  "Template_Name__c": string;
  "Template_Type__c": string;
  "Version__c": number;
  "createdOnServiceo": boolean;
  "isDeleted": boolean;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  templateDetail: TemplateDetail[];
  constructor(data?: TemplateHeaderInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `TemplateHeader`.
   */
  public static getModelName() {
    return "TemplateHeader";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of TemplateHeader for dynamic purposes.
  **/
  public static factory(data: TemplateHeaderInterface): TemplateHeader{
    return new TemplateHeader(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'TemplateHeader',
      plural: 'TemplateHeaders',
      path: 'TemplateHeaders',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "Category__c": {
          name: 'Category__c',
          type: 'string'
        },
        "Description__c": {
          name: 'Description__c',
          type: 'string'
        },
        "Sub_Category__c": {
          name: 'Sub_Category__c',
          type: 'string'
        },
        "Template_Name__c": {
          name: 'Template_Name__c',
          type: 'string'
        },
        "Template_Type__c": {
          name: 'Template_Type__c',
          type: 'string'
        },
        "Version__c": {
          name: 'Version__c',
          type: 'number'
        },
        "createdOnServiceo": {
          name: 'createdOnServiceo',
          type: 'boolean'
        },
        "isDeleted": {
          name: 'isDeleted',
          type: 'boolean'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        templateDetail: {
          name: 'templateDetail',
          type: 'TemplateDetail[]',
          model: 'TemplateDetail',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'PgMO_Template_Header__c'
        },
      }
    }
  }
}
