import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { ServiceoLoginLookupComponent } from './serviceo-login-lookup.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule
  ],
  declarations: [ServiceoLoginLookupComponent],
  exports: [ServiceoLoginLookupComponent]

})
export class ServiceoLoginLookupModule { }
