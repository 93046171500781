import { Component, OnInit, EventEmitter, Output, Input, SimpleChanges } from '@angular/core';
import { PreloaderService } from 'shared/services/preloader.service';
import { FilterServiceApi } from 'shared/sdk';
import { DualListComponent } from 'angular-dual-listbox';
import { Router } from '@angular/router';

@Component({
  selector: 'app-country-lookup',
  templateUrl: './country-lookup.component.html',
  styleUrls: ['./country-lookup.component.css']
})
export class CountryLookupComponent implements OnInit {

  @Input() isMultipleAllowed = true;
  @Input() bindLabel = 'Country__c';
  @Input() bindValue;
  @Input() returnCountryCode = false;
  @Input() returnCountryData = false;
  @Input() UseInGlobalFilter = true;
  @Input() searchable = true;
  @Input() isDualListType = false;
  @Input() customClass = false;
  @Input() custom = false;
  @Input() boxedLayout;

  // if true => pass countryName is the sfdcId
  @Input() isSfdcIdPass = false;
  // if value provided this will be selected by default for dropdown, UseInGlobalFilter key must be false.
  @Input() set countryName(val: string) {
    if (val) {
      if(this.returnCountryCode && this.countries && this.countries.length){
        let value = this.countries.find(i => i.Name == val);
        this.selectedValue = value ? value[this.bindLabel] : '';
      }else{
        if (this.isSfdcIdPass && this.countries && this.countries.length) {
          // input val is the sfdcId
          let value = this.countries.find(i => i.sfdcId == val);
          this.selectedValue = value ? value[this.bindLabel] : '';
        } else {
          this.selectedValue = val;
        }
      }      
    }
  }
  @Input() isdisabled = false;
  @Input() clearable = true;
  @Input() hideSelected = true;
  isLoaded: boolean;
  @Input() selectedItem: any = []; // for dual list auto selected 

  @Input() set resetData(isClear) {
    if(isClear){
      this.clearFromParent();
    }
    
  }
  @Input() set selectedRegionObj(e) {
    let Regions = e;
    let Region__c = [];
    if(Regions && Regions.length) {
      Regions.forEach(element => {
        if(element.Name) {
          Region__c.push(element['Name']);
        }
      });
    }
      const paramObj = {
        'models': ['Country'],
        'Region__c': Region__c,
        'lookup': true  
      };
    this.loadDropdownData(paramObj);
    this.selectedValue = null;
    this.selectedCountry.emit(this.selectedValue);
  }
  @Output() selectedCountry: EventEmitter<any> = new EventEmitter;
  countries: any = [];
  @Input() selectedValue;
  format = {
    add: 'Add', remove: 'Remove', direction: DualListComponent.LTR, draggable: true, locale: 'undefined'
  };
  pageFrom = '';
  constructor(private _preloaderService: PreloaderService,
    private _filterServiceApi: FilterServiceApi,
    private _router: Router) { 
     this.pageFrom = this._router.url;
    }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    this.selectedValue = this.selectedValue
    this.clearable = this.selectedValue?true:false  
  }
  ngOnInit() {
    // below code is for searchjob country filter
    localStorage.setItem('isCountryClear','no');
    const preSelected = localStorage.getItem('filterObj');
    if (this.UseInGlobalFilter) {
      if (preSelected && JSON.parse(preSelected) &&
        JSON.parse(preSelected)['countries'] && JSON.parse(preSelected)['countries'].length) {
        this.selectedValue = JSON.parse(preSelected)['countries'];
      }
    } else if (this.countryName) {
      this.selectedValue = this.countryName;
    }
    let Region__c = [];
    if (preSelected && JSON.parse(preSelected) && JSON.parse(preSelected)['regions'] && JSON.parse(preSelected)['regions'].length) {
      let Regions = JSON.parse(preSelected)['regions'];
      if(Regions && Regions.length){
        Regions.forEach(element => {
          if(element.Name) {
            Region__c.push(element['Name']);
          }
        });
      }
    }
    const paramObj = {
      'models': ['Country'],
      'lookup': true 
    };
    if(Region__c && Region__c.length) {
      paramObj['Region__c'] = Region__c;
    }
    if (this.isDualListType) {
      this.selectedValue = [];
    }
    this.loadDropdownData(paramObj);
    this.clearable = this.selectedValue?true:false
  }

  onChange(values) {
    // below code is for searchjob country filter
    if(this.pageFrom==='/vms/search-job'){
      localStorage.setItem('isCountryClear','yes');
    }
    if (this.returnCountryCode) {
      this.selectedCountry.emit((this.selectedValue) ? this.selectedValue.Name : null);
    } else if (this.isDualListType) {
      this.selectedCountry.emit(values);
    } else if (this.returnCountryData) {
      this.selectedCountry.emit((this.selectedValue) ? this.selectedValue : null);
    } else {
      this.selectedCountry.emit(this.selectedValue);
    }
  }

  loadDropdownData(paramObj) {
    // get data for filters
    this.isLoaded = false;
    this._preloaderService.showPreloader();
    this._filterServiceApi.getAllFiltersData(paramObj).subscribe(
      data => {
        if (data && data.data) {
          data = data.data;
          // countries
          if (paramObj['models'].indexOf('Country') !== -1 && data['countries'] && !data['countries']['error']) {
            if (this.bindLabel === 'custom') {
              data['countries']['list'].map(item => item['custom'] = `${item['Country__c']} (${item['Name']})`);
            }
            // for dual list source should be updated as per selected destination i.e source should not contain element that is present in destination while updating the page
            if (this.isDualListType && this.selectedItem && this.selectedItem.length != 0) {
              var countryListData = data['countries']['list'];
              var selectedOnlyValueArr = [...this.selectedItem];
              this.setCountriesParameters(countryListData, selectedOnlyValueArr);
            }
            if (this.returnCountryCode) {
              this.countries = data['countries']['list'].sort((a, b) => a.Name && a.Name.localeCompare(b.Name));
            } else {
              this.countries = data['countries']['list'];
            }
          }
          if (this.isSfdcIdPass && !this.returnCountryCode) {
            const value = this.countries.find(i => i.sfdcId === this.selectedValue);
            this.selectedValue = value ? value[this.bindLabel] : '';
          } else if (this.returnCountryCode) {
            const value = this.countries.find(i => i.Name === this.selectedValue);
            this.selectedValue = value ? value[this.bindLabel] : '';
          }
          this.isLoaded = true;
        }
        this._preloaderService.hidePreloader();

      },
      error => {
        console.log('Error fetching data>>', error.message);
        this._preloaderService.hidePreloader();
      }
    );
  }

  clearFromParent() {
    this.selectedValue = null;
  }

  setCountriesParameters(a, b) {
    this.selectedItem = a.filter(o => b.some(({ Country__c }) => o.Country__c === Country__c));
    this.removeDuplicates(a, this.selectedItem);
    this.countries = [...a];
  }

  removeDuplicates(a, b) {
    var i = a.length;
    while (i--) {
      for (var j of b) {
        if (a[i] && a[i].Country__c === j.Country__c) {
          a.splice(i, 1);
        }
      }
    }
  }

}
