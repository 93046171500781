/* tslint:disable */
import {
  Account,
  CountryCode,
  Contact,
  Jobsite,
  Project,
  StateManager
} from '../index';

declare var Object: any;
export interface MyWorkLeaveWorkerBalanceInterface {
  "sfdcId"?: string;
  "OwnerId"?: string;
  "IsDeleted"?: boolean;
  "Name"?: string;
  "CreatedById"?: string;
  "CreatedDate"?: Date;
  "CurrencyIsoCode"?: string;
  "LastModifiedDate"?: Date;
  "LastModifiedById"?: string;
  "LastActivityDate"?: Date;
  "LastViewedDate"?: Date;
  "LastReferencedDate"?: Date;
  "Created_By_Contact__c"?: string;
  "IRON_ExtID__c"?: string;
  "isActive__c"?: boolean;
  "Leave_Type__c"?: string;
  "Modified_By_Contact__c"?: string;
  "MyWorkLeave_Entitlement_Setting__c"?: string;
  "Total_Applied__c"?: number;
  "Total_Balance__c"?: number;
  "Total_Booked__c"?: number;
  "Total_Entitlement__c"?: number;
  "Worker__c"?: string;
  "id"?: number;
  "Account__c"?: string;
  "Country_Code__c"?: string;
  "Jobsite__c"?: string;
  "Program__c"?: string;
  "State__c"?: string;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  account?: Account;
  countryCode?: CountryCode;
  contact?: Contact;
  jobsite?: Jobsite;
  modifiedByContact?: Contact;
  project?: Project;
  stateManager?: StateManager;
}

export class MyWorkLeaveWorkerBalance implements MyWorkLeaveWorkerBalanceInterface {
  "sfdcId": string;
  "OwnerId": string;
  "IsDeleted": boolean;
  "Name": string;
  "CreatedById": string;
  "CreatedDate": Date;
  "CurrencyIsoCode": string;
  "LastModifiedDate": Date;
  "LastModifiedById": string;
  "LastActivityDate": Date;
  "LastViewedDate": Date;
  "LastReferencedDate": Date;
  "Created_By_Contact__c": string;
  "IRON_ExtID__c": string;
  "isActive__c": boolean;
  "Leave_Type__c": string;
  "Modified_By_Contact__c": string;
  "MyWorkLeave_Entitlement_Setting__c": string;
  "Total_Applied__c": number;
  "Total_Balance__c": number;
  "Total_Booked__c": number;
  "Total_Entitlement__c": number;
  "Worker__c": string;
  "id": number;
  "Account__c": string;
  "Country_Code__c": string;
  "Jobsite__c": string;
  "Program__c": string;
  "State__c": string;
  "createdAt": Date;
  "updatedAt": Date;
  account: Account;
  countryCode: CountryCode;
  contact: Contact;
  jobsite: Jobsite;
  modifiedByContact: Contact;
  project: Project;
  stateManager: StateManager;
  constructor(data?: MyWorkLeaveWorkerBalanceInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `MyWorkLeaveWorkerBalance`.
   */
  public static getModelName() {
    return "MyWorkLeaveWorkerBalance";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of MyWorkLeaveWorkerBalance for dynamic purposes.
  **/
  public static factory(data: MyWorkLeaveWorkerBalanceInterface): MyWorkLeaveWorkerBalance{
    return new MyWorkLeaveWorkerBalance(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'MyWorkLeaveWorkerBalance',
      plural: 'MyWorkLeaveWorkerBalances',
      path: 'MyWorkLeaveWorkerBalances',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "LastActivityDate": {
          name: 'LastActivityDate',
          type: 'Date'
        },
        "LastViewedDate": {
          name: 'LastViewedDate',
          type: 'Date'
        },
        "LastReferencedDate": {
          name: 'LastReferencedDate',
          type: 'Date'
        },
        "Created_By_Contact__c": {
          name: 'Created_By_Contact__c',
          type: 'string'
        },
        "IRON_ExtID__c": {
          name: 'IRON_ExtID__c',
          type: 'string'
        },
        "isActive__c": {
          name: 'isActive__c',
          type: 'boolean'
        },
        "Leave_Type__c": {
          name: 'Leave_Type__c',
          type: 'string'
        },
        "Modified_By_Contact__c": {
          name: 'Modified_By_Contact__c',
          type: 'string'
        },
        "MyWorkLeave_Entitlement_Setting__c": {
          name: 'MyWorkLeave_Entitlement_Setting__c',
          type: 'string'
        },
        "Total_Applied__c": {
          name: 'Total_Applied__c',
          type: 'number'
        },
        "Total_Balance__c": {
          name: 'Total_Balance__c',
          type: 'number'
        },
        "Total_Booked__c": {
          name: 'Total_Booked__c',
          type: 'number'
        },
        "Total_Entitlement__c": {
          name: 'Total_Entitlement__c',
          type: 'number'
        },
        "Worker__c": {
          name: 'Worker__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "Account__c": {
          name: 'Account__c',
          type: 'string'
        },
        "Country_Code__c": {
          name: 'Country_Code__c',
          type: 'string'
        },
        "Jobsite__c": {
          name: 'Jobsite__c',
          type: 'string'
        },
        "Program__c": {
          name: 'Program__c',
          type: 'string'
        },
        "State__c": {
          name: 'State__c',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'Account__c',
          keyTo: 'sfdcId'
        },
        countryCode: {
          name: 'countryCode',
          type: 'CountryCode',
          model: 'CountryCode',
          relationType: 'belongsTo',
                  keyFrom: 'Country_Code__c',
          keyTo: 'sfdcId'
        },
        contact: {
          name: 'contact',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Created_By_Contact__c',
          keyTo: 'sfdcId'
        },
        jobsite: {
          name: 'jobsite',
          type: 'Jobsite',
          model: 'Jobsite',
          relationType: 'belongsTo',
                  keyFrom: 'Jobsite__c',
          keyTo: 'sfdcId'
        },
        modifiedByContact: {
          name: 'modifiedByContact',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Modified_By_Contact__c',
          keyTo: 'sfdcId'
        },
        project: {
          name: 'project',
          type: 'Project',
          model: 'Project',
          relationType: 'belongsTo',
                  keyFrom: 'Program__c',
          keyTo: 'sfdcId'
        },
        stateManager: {
          name: 'stateManager',
          type: 'StateManager',
          model: 'StateManager',
          relationType: 'belongsTo',
                  keyFrom: 'State__c',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
