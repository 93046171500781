import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { TimesheetAddNewComponent } from './timesheet-add-new.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { NgSelectModule } from '@ng-select/ng-select';
import { JobLookupModule } from 'shared/components/lookup/job-lookup/job-lookup.module';
import { VendorAccountLookupModule } from 'shared/components/lookup/vendor-account/vendor-account-lookup.module';
import { WorkerLookupModule } from 'shared/components/lookup/worker-lookup/worker-lookup.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DocumentModule } from '../document/document.module';
import { PreloaderModule } from '../preloader/preloader.module';
import { AlertModule } from '../alert/alert.module';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    JobLookupModule,
    VendorAccountLookupModule,
    WorkerLookupModule,
    DocumentModule,
    PreloaderModule,
    AlertModule,
    NgbModule
  ],
  declarations: [TimesheetAddNewComponent],
  exports: [TimesheetAddNewComponent]
})
export class TimesheetAddNewModule { }
