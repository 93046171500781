import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-min-max-lookup',
  templateUrl: './min-max-lookup.component.html',
  styleUrls: ['./min-max-lookup.component.css']
})
export class MinMaxLookupComponent implements OnInit {
  disableInput=false;
  @Input() set resetData(isClear) {
    if (isClear) {
      this.selectedValue.min = '0';
      this.selectedValue.max = '0';
    }
  }
  @Input() isVendor:any;
  @Input() set setValue(e) {
    if (e.min) {
      this.selectedValue.min = e.min;
    }
    if (e.max) {
      this.selectedValue.max = e.max;
    }
    if (e.key) {
      this.key = e.key;
    }
   // this.getSelectedMinMax.emit(this.selectedValue);
  };

  selectedValue = {
    min: '1',
    max: '9999999999'
  };

  key = 'targetCost';

  @Output() getSelectedMinMax: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {
    if(this.isVendor==='vendor'){
      this.disableInput=true;
  }  
    const preSelected = localStorage.getItem('filterObj');
    if (preSelected && JSON.parse(preSelected) &&
      JSON.parse(preSelected)[this.key] && JSON.parse(preSelected)[this.key].length) {
      this.selectedValue = JSON.parse(preSelected)[this.key][0];
    }
  }

  onMinChange(min) {
    if (parseInt(min) > parseInt(this.selectedValue.max)) {
      if (parseInt(this.selectedValue.max)-1 < 1) {
        this.selectedValue.min = '1';
      } else {
        this.selectedValue.min = String(parseInt(this.selectedValue.max) - 1);
      }
    } else if(!parseInt(min)) {
      this.selectedValue.min = '0';
    }else {
      this.selectedValue.min = min;
    }
    this.getSelectedMinMax.emit(this.selectedValue);
  }

  onMaxChange(max) {
    if (parseInt(max) < parseInt(this.selectedValue.min)) {
      this.selectedValue.max = String(parseInt(this.selectedValue.min) + 1);
    }
    this.getSelectedMinMax.emit(this.selectedValue);
  }

  isNumber(evt) {
    evt = (evt) ? evt : window.event;
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode === 8 || charCode === 9) {
      return true;
    }
    return !((charCode > 31 && charCode < 48) || charCode > 57);
  }

}
