/* tslint:disable */

declare var Object: any;
export interface PageViewMappingInterface {
  "Name"?: string;
  "URL"?: string;
  "Tab"?: string;
  "id"?: number;
  "isMobile"?: boolean;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class PageViewMapping implements PageViewMappingInterface {
  "Name": string;
  "URL": string;
  "Tab": string;
  "id": number;
  "isMobile"?: boolean;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: PageViewMappingInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `PageViewMapping`.
   */
  public static getModelName() {
    return "PageViewMapping";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of PageViewMapping for dynamic purposes.
  **/
  public static factory(data: PageViewMappingInterface): PageViewMapping{
    return new PageViewMapping(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'PageViewMapping',
      plural: 'PageViewMappings',
      path: 'PageViewMappings',
      idName: 'id',
      properties: {
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "URL": {
          name: 'URL',
          type: 'string'
        },
        "Tab": {
          name: 'Tab',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isMobile": {
          name: 'isMobile',
          type: 'boolean'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
