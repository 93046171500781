import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { PipeModule } from '../../pipe/pipe.module';
import { NotificationModule } from '../notification/notification.module';
import { ResetPasswordModule } from '../../views/users/reset-password/reset-password.module';
import { RequestFormReceiptsModule } from '../request-form-receipts/request-form-receipts.module';

import { NavigationComponent } from './navigation.component';
import { MaintenanceScheduleModule } from '../maintenance-schedule/maintenance-schedule/maintenance-schedule.module';
import { ReleaseNotesModule } from '../release-notes/release-notes.module';
import { UploadPictureModule } from 'shared/views/users/upload-picture/upload-picture.module';
import { HelpdeskTaskModule } from '../helpdesk-task/helpdesk-task.module';

@NgModule({
    declarations: [
        NavigationComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        NgbModule,
        NgxDatatableModule,
        PipeModule,
        NotificationModule,
        ResetPasswordModule,
        RequestFormReceiptsModule,
        MaintenanceScheduleModule,
        ReleaseNotesModule,
        UploadPictureModule,
        HelpdeskTaskModule
    ],
    exports: [
        NavigationComponent
    ],
    schemas: []
})

export class NavigationModule { }
