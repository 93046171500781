import { CommonService } from './../../services/common.service';
import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { SharedService } from 'shared/services/pms/shared.services';
import { NotificationService } from '../../services/notification.service';
import { PreloaderService } from 'shared/services/preloader.service';
import { AppStateService } from '../../services/app-state.service';
import { JobApi, CaseApi } from 'shared/sdk';

@Component({
    selector: 'app-feed',
    templateUrl: './feed.component.html',
    styleUrls: ['./feed.component.css']
})

export class FeedComponent implements OnInit {
    @Input() set refreshHistoryList(e) {
        if (e === true) {
            this.filterCondition['Type'] = this.feedModal;
            setTimeout(() => {
                this.refreshView();
            }, 2000); 
        }
    }
    viewAsPartner: boolean;
    @Input() set filterObj(e) {
        const userType = this._appState.getAccessType();
        this.tableData = [];
        this.filterCondition = e;
        this.batchNumber = 1;
        if (userType === 'partner' && e) {
            this.getFeeds();
        } else if (userType !== 'partner') {
            this.getFeeds();
        }
    }
    @Input() feedModal = 'Timecard';
    @Input() timeZone = '';
    @Input() modelName;
    @Input() showTopOptions = true;
    @Input() isLoader = true;
    errorMessage = '';
    pageTitle: any;
    filterCondition: any;
    public feeds: Array<any>;
    /*Boot-Datatable params */
    tableData = [];
    loadingIndicator = false;
    orderBy = 'createdAt DESC';
    attrContent = 'data-content';
    ExpandViewTitle: any;
    currentExpandViewId: any;
    userAccessType: string;
    accountId: string;
    columns: any;
    @ViewChild('myTable') table: any;
    allColumns: any;
    activity: any; // to open view
    totalCount = 0;
    itemsPerPage = 25;
    batchNumber: number;
    batchSize = 200;

    constructor(
        private _loader: PreloaderService,
        private _modalService: ModalService,
        private _sharedservice: SharedService,
        private _el: ElementRef,
        private _commonService: CommonService,
        private _notificationService: NotificationService,
        private _appState: AppStateService,
        private _jobApi: JobApi,
        private _caseApi: CaseApi
    ) {
    }


    ngOnInit() {
        this._commonService.saveCurrentPageAnalytics('Activity Feed', 'Activity Feeds Listing')
        if (this._commonService.getUserProfile()) {
            this._commonService.getUserProfile().subscribe(userProfile => {
                if (userProfile && userProfile['timezone']) {
                    this.timeZone = userProfile['timezone'];
                }
            }, error => {
                console.log(error);
            });
        }
        this.pageTitle = 'Activity Feed List';
        this.userAccessType = this._appState.getAccessType();
        this.accountId = this._appState.getSelectedAccount();
        // vendor view filter for internal user
        if (localStorage.getItem('ImpersonationId')) {
            this.accountId = localStorage.getItem('ImpersonationId');
            this.userAccessType = 'vendor';
        }
        // To check if view as PMS mode is activated.
        if (localStorage.getItem('viewAsInfo')) {
            const viewAsInfo = JSON.parse(localStorage.getItem('viewAsInfo'));
            this.viewAsPartner = (viewAsInfo && viewAsInfo.viewAsType === 'Partner');
            this.userAccessType = (this.viewAsPartner) ? 'partner' : this.userAccessType;
        }
        if (this.userAccessType === 'partner') {
            this.columns = [
                { name: 'Model Name', prop: 'entity_name', width: 150, visible: true, sortable: true },
                { name: 'Case', prop: 'case_number', width: 200, visible: true, sortable: true },
                { name: 'Account', prop: 'account_name', width: 200, visible: true, sortable: true },
                { name: 'Program', prop: 'program_name', width: 400, visible: true, sortable: true },
                { name: 'Title', prop: 'title', width: 500, visible: true, sortable: true },
                { name: 'Activity By', prop: 'actor_name', width: 150, visible: true, sortable: true },
                { name: 'Date', prop: 'created_at', width: 250, visible: true, sortable: true },
                { name: 'Action', prop: 'action', width: 100, visible: true, sortable: false }
            ];
        } else {
            this.columns = [
                { name: 'Model Name', prop: 'entity_name', width: 150, visible: true, sortable: true },
                { name: 'Job', prop: 'iron_job_num__c', width: 200, visible: true, sortable: true },
                { name: 'Account', prop: 'account_name', width: 200, visible: true, sortable: true },
                { name: 'Program', prop: 'program_name', width: 400, visible: true, sortable: true },
                { name: 'Title', prop: 'title', width: 500, visible: true, sortable: true },
                { name: 'Activity By', prop: 'actor_name', width: 150, visible: true, sortable: true },
                { name: 'Date', prop: 'created_at', width: 250, visible: true, sortable: true },
                { name: 'Action', prop: 'action', width: 100, visible: true, sortable: false }
            ];
        }
        if (this.userAccessType === 'vendor') {
            this.columns.splice((this.columns.findIndex(x => (x.prop === 'account_name'))), 1);
        }
        // remove Job lable form job detail activity page
        if (this.modelName == 'Job') {
            this.columns.splice(1, 1);
        }
        if (this.modelName == 'Timecard') {
            this.columns.splice((this.columns.findIndex(x => (x.prop === 'account_name'))), 1);
            this.columns.splice((this.columns.findIndex(x => (x.prop === 'program_name'))), 1);
            this.columns.splice(1, 0, { name: 'Timecard/Timesheet', prop: 'timecard_name', width: 200, visible: true, sortable: true });
        }
        if (this.modelName == 'Account') {
            this.columns.splice((this.columns.findIndex(x => (x.prop === 'iron_job_num__c'))), 1);
            this.columns.splice((this.columns.findIndex(x => (x.prop === 'account_name'))), 1);
            this.columns.splice((this.columns.findIndex(x => (x.prop === 'program_name'))), 1);
        }
        this.allColumns = this.columns.slice(); // Used for Columns Toggling
    }

    getFeeds() {
        const result: any = [];
        if (this.isLoader) {
            this._loader.showPreloader();
            this.loadingIndicator = true;
        }
        this._notificationService.getActivityFeed(this.batchNumber, this.filterCondition, this.batchSize)
            .then(async data => {
                this._loader.hidePreloader();
                this.loadingIndicator = false;
                if (data['data']) {
                    if (data['data'].length > 0) {
                        if(data['activityType']) {
                            data['data'] = await this.getAdditionalData(data['data'], data['activityType']);
                        }
                        data['data'].forEach((e, i) => {
                            if (e.created_at) {
                                // data['data'][i].created_at = e.created_at + ' Z';
                                data['data'][i].created_at = this._commonService.dateFormate(e.created_at + 'Z', '', 'L LT');
                                if (!e.actor_name && e.actor_type === 'system') {
                                    e.actor_name = 'System';
                                }
                            }
                        });

                    }
                    this.totalCount = data['total'];
                    if (this.batchNumber > 1) {
                        this.tableData = this.tableData.concat(data['data']);
                    } else {
                        this.tableData = data['data'];
                    }
                    if (this.tableData && this.tableData.length) {
                        const that = this;
                        setTimeout(function () { that.initScroll(); }, 1000);
                    }
                } else {
                    this.loadingIndicator = false;
                    this._loader.hidePreloader();
                    this.tableData = [];
                }
            }).catch(err => {
                this.loadingIndicator = false;
                this._loader.hidePreloader();
                this.tableData = [];
            });
    }

    getAdditionalData(activityData, type) {
        /*
        hit the job or case api as per the userType and merege the accountName, PartnerName, jobNumber/caseNumber related data to acitivity response
        */
        return new Promise((resolve, reject) => {
            let mergedData: any = [];
            let activitySfdcIds = activityData.reduce((activityIds, activity) => {
                if (activity.entity_name === 'Job') {
                    activityIds.push(activity.sfdc_id);
                } else if (activity.entity_name === 'Case') {
                    activityIds.push(activity.sfdc_id);
                }
                return activityIds;
            }, []);

            activitySfdcIds = [...Array.from(new Set(activitySfdcIds))];
            if (type === 'jobs') {
                const filterObj = {
                    where: { sfdcId: { inq: activitySfdcIds } },
                    fields: ["id", "Case__c", "sfdcId", "Iron_Job_num__c", "CKSW_BASE__Account__c", "Project_SOP__c"],
                    include: [{
                        relation: 'partner',
                        scope: {
                            fields: ["Name"]
                        },
                    },
                    {
                        relation: 'program',
                        scope: {
                            fields: ["Project__c", "Name"]
                        },
                    }
                    ]
                }
                this._jobApi.find(filterObj).subscribe(async jobData => {
                    mergedData = await this.mergeDataWithActivity(jobData, activityData, type)
                    resolve(mergedData);
                }, err => {
                    console.log(`error in getAdditionalData() for type ${type}`, err);
                    resolve(activityData);
                })
            } else if (type === 'cases') {
                const filterObj = {
                  where: { sfdcId: { inq: activitySfdcIds } },
                  fields: ["id", "sfdcId", "CaseNumber", "AccountId", "Project_SOP__c"],
                  include: [{
                    relation: 'Account',
                    scope: {
                      fields: ["Name", "sfdcId"]
                    },
                  },
                  {
                    relation: 'program',
                    scope: {
                      fields: ["Project__c", "Name"]
                    },
                  }
                  ]
                }
                this._caseApi.find(filterObj).subscribe(async caseData => {
                    if (caseData && caseData.length) {
                        mergedData = await this.mergeDataWithActivity(caseData, activityData, type)
                        resolve(mergedData);
                    }  else {
                        resolve(activityData)
                    }
                }, err => {
                    console.log(`error in getAdditionalData() for type ${type}`, err);
                    resolve(activityData);
                });
              }
        })

    }

    mergeDataWithActivity(data, activityData, type) {
        return new Promise((resolve, reject) => {
            for (let activity of activityData) {
                if (type === 'cases') {
                    const caseData = data.filter(item => item.sfdcId == activity.sfdc_id);
                    activity['case_number'] = caseData && caseData.length ? caseData[0].CaseNumber : '';
                    activity['account_name'] = caseData && caseData.length && caseData[0].Account ? caseData[0].Account.Name : '';
                    activity['program_name'] = caseData && caseData.length && caseData[0].program ? caseData[0].program.Name + ' (' + caseData[0].program.Project__c + ')' : '';
                } else {
                    const jobData = data.filter(job => job.sfdcId == activity.sfdc_id);
                    activity['iron_job_num__c'] = jobData && jobData.length ? jobData[0].Iron_Job_num__c : '';
                    activity['account_name'] = jobData && jobData.length && jobData[0].partner ? jobData[0].partner.Name : '';
                    activity['program_name'] = jobData && jobData.length && jobData[0].program ? jobData[0].program.Name + ' (' + jobData[0].program.Project__c + ')' : '';
                    activity['sfdcId'] = jobData && jobData.length ? jobData[0].sfdcId : '';
                }
            }
            resolve(activityData)
        })
    }

    toggle(col) {
        col.visible = !col.visible;
    }

    refreshView() {
        let inputs;
        let index;
        inputs = document.getElementById('dataTable').getElementsByTagName('input');
        for (index = 0; index < inputs.length; ++index) {
            inputs[index].value = '';
        }
        this.columns.map(x => {
            x.visible = true;
        });
        this.batchNumber = 1;
        this.table.offset = 0;
        this.getFeeds();
    }

    exportCSV() {
        this._sharedservice.exportNgxData(this.tableData, this.columns, 'ActivityFeedList');
    }

    openDetails(content, size, row) {
        this.ExpandViewTitle = row.title;
        this.currentExpandViewId = row.id;
        this.activity = row;
        this._modalService.open(content, size);
    }

    initScroll() {
        let elHeader = this._el.nativeElement.querySelector('.datatable-header')
        let elBody = this._el.nativeElement.querySelector('datatable-body');
        elHeader.onscroll = () => {
            elBody.scrollLeft = elHeader.scrollLeft
        }
        elBody.onscroll = () => {
            elHeader.scrollLeft = elBody.scrollLeft
        }
    }

    /**
   * Populate the table with new data based on the page number
   */
    loadMoreRecords() {
        this.batchNumber++;
        this.getFeeds();
    }
}
