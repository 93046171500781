
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AlertModule } from 'shared/components/alert/alert.module';
import { WorkerScheduleComponent } from './worker-schedule/worker-schedule.component';
import { WorkerManagementComponent } from './worker-management/worker-management.component';
import { CheckinCheckoutJobManagerComponent } from './checkin-checkout-job-manager.component';
import { TimeClockManagerListModule } from './../../billing/contract-jobs/time-clock-manager/time-clock-manager-list/time-clock-manager-list.module';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    AlertModule,
    FormsModule,
    ReactiveFormsModule,
    TimeClockManagerListModule
  ],
  declarations: [CheckinCheckoutJobManagerComponent, WorkerManagementComponent, WorkerScheduleComponent],
  exports:[CheckinCheckoutJobManagerComponent, WorkerManagementComponent, WorkerScheduleComponent]
})
export class CheckinCheckoutJobManagerModule { }
