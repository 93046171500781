import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageNameLookupComponent } from './page-name-lookup/page-name-lookup.component';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule
  ],
  declarations: [PageNameLookupComponent],
  exports: [PageNameLookupComponent]
})
export class PageNameLookupModule { }
