import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonService } from './../../services/common.service';
import { AddFieldsComponent } from './add-fields.component';
import { ModalBoxModule } from '../modal-box/modal-box.module';
@NgModule({
  imports: [
    CommonModule,
    NgxDatatableModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    ModalBoxModule
  ],
  declarations: [AddFieldsComponent],
  exports: [AddFieldsComponent],
  providers: [CommonService]
})
export class AddFieldsModule { }
