import { Injectable } from "@angular/core";
import { Adapter } from "./adapter";
import { Member, MemberAdaptor } from "./member";
import { Document, DocumentAdaptor } from "./document";

export class ReplyMessage {
    constructor(
        public message: string = "",
        public isFile: boolean = false,
        public document: Document= new Document(),
        public message_id: string = "",
        public member: Member = new Member(),
    ){}
}

@Injectable({
    providedIn: "root"
})

export class ReplyMessageAdaptor implements Adapter<ReplyMessage> {
    constructor(private _memberAdapter: MemberAdaptor) {}
    adapt(item: any): ReplyMessage {
        let replyMessage = new ReplyMessage(
            (item.message ? item.message : ''),
            (item.isFile ? item.isFile : ''),
            (item.document ? item.document : ''),
            (item.id ? item.id : ''),
            (item.user && item.user.firstName ? this._memberAdapter.adapt(item.user) : new Member())
        );
        return replyMessage;
    }
    reverseAdapt(item: ReplyMessage): any {
        return
    }
}