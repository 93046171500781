import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PgmoDepartmentLookupComponent } from './pgmo-department-lookup.component';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule
  ],
  declarations: [PgmoDepartmentLookupComponent],
  exports: [PgmoDepartmentLookupComponent]
})
export class PgmoDepartmentLookupModule { }
