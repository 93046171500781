import { Component, OnInit, Input } from '@angular/core';
import { CommonService } from 'shared/services/common.service';

@Component({
  selector: 'app-checkin-checkout-job-manager',
  templateUrl: './checkin-checkout-job-manager.component.html',
  styleUrls: ['./checkin-checkout-job-manager.component.css']
})
export class CheckinCheckoutJobManagerComponent implements OnInit {
  
  selectedTabId = 'workerManagement'
  @Input() data: any;
  @Input()
  set filterData(e) {
    this._filterData = e;
  }
  _filterData: any;
  constructor(private _commonService: CommonService,
    ) { }

  ngOnInit() {
    this.saveAnalyticsData(this.selectedTabId);
  }

  onJobTabChange(e) {
  this._filterData = undefined;
  this.selectedTabId = e.nextId;
  if (this.selectedTabId) {
    this.saveAnalyticsData(this.selectedTabId);
  }
  }
  saveAnalyticsData(name) {
    if (name) {
      switch (name) {
        case 'workerManagement':
          this._commonService.saveCurrentPageAnalytics('CheckIn-CheckOut - Job Manager', 'Worker Check In/Out Management');
          break;
        case 'workerSchedule':
            this._commonService.saveCurrentPageAnalytics('CheckIn-CheckOut - Job Manager', 'Worker Schedule')
            break;
      }
    }
  }
}
