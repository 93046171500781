import { environment } from '../../../../environments/environment';
import { AccountApi } from './../../../sdk/services/custom/Account';
import { RecordTypeApi } from './../../../sdk/services/custom/RecordType';
import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { debounceTime, distinctUntilChanged, switchMap, filter } from 'rxjs/operators';

@Component({
  selector: 'app-customer-lookup',
  templateUrl: './customer-lookup.component.html',
  styleUrls: ['./customer-lookup.component.css']
})
export class CustomerLookupComponent implements OnInit {

  @Input() boxedLayout = false;
  @Input() set resetData(isClear) {
    this.clearFromParent();
  }
  @Input() isMultiple = true;
  @Output() selectedCustomer: EventEmitter<any> = new EventEmitter;
  selectedValue;
  noCustomer = 'No customer found.';
  pCustomerTypeahead = new EventEmitter<string>();
  @Input() set selectedPartnerAccountData(event) {
    if (!(event && event.length)) {
      this.selectedValue = [];
    }
    // this.selectedValue = [];
  }
  @Input() fromGlobalFilters = true;

  @Input() set setValueInEditMode(sfdcId) {
    if (sfdcId) {
      this.selectedValue = sfdcId;
      const whereObj = {
        sfdcId: this.selectedValue
      }
      this._accountApi.find({
        fields: ['sfdcId', 'Name'],
        where: whereObj
      }).subscribe(res => {
        this.customers = res;
      });
    }
  }
  @Input() includeVendors: Boolean = false;

  customers = [];
  recordTypeId;

  constructor(private _recordTypeApi: RecordTypeApi, private _accountApi: AccountApi,
    private _cd: ChangeDetectorRef) { }

  ngOnInit() {
    this.getRecordTypeId();
    this.getCustomers();
  }

  ngAfterContentInit() {
    if(this.fromGlobalFilters) {
      const preSelected = localStorage.getItem('filterObj');
      if (preSelected && JSON.parse(preSelected) &&
        JSON.parse(preSelected)['customers'] && JSON.parse(preSelected)['customers'].length) {
        this.selectedValue = JSON.parse(preSelected)['customers'];
        this.getCustomersList({ 'sfdcId': { inq: this.selectedValue } }).subscribe(reply => {
          this.customers = reply;
        }, err => {
          console.log(err);
        })
      }
      this.selectedCustomer.emit(this.selectedValue);
    }    
  }

  onChange() {
    this.selectedCustomer.emit(this.selectedValue);
  }

  clearFromParent() {
    this.selectedValue = null;
  }

  getRecordTypeId() {
    this._recordTypeApi.find({
      where: {
        'Name': (this.includeVendors ? {inq: ['Customer', 'Vendor']}:'Customer'),
        'SobjectType': 'Account'
      }
    }).subscribe(recordType => {
      let records = [];
      recordType.forEach(ele => {
        if (ele['sfdcId']) {
          records.push(ele['sfdcId']);
        }
      });
      if (records && records.length) {
        this.recordTypeId = { inq: records };
      }
    }, err => {
      console.log(err);
    });
  }

  getCustomers() {
    this.customers = [];
    this.pCustomerTypeahead
      .pipe(
        filter(term => term && term.trim().length > 2),
        distinctUntilChanged(),
        debounceTime(200),
        switchMap(term => {
          const searchPattern = { like: '%' + term.trim() + '%', options: 'i' };
          const whereObj = {
            'Name': searchPattern,
            'RecordTypeId': this.recordTypeId,
            'Partner_Account__c': environment.microsoftAccountId ? environment.microsoftAccountId : '0011a000005AHhxAAG'  // sfdc Id of account for Microsoft
          }
          if(this.includeVendors) {
            delete whereObj['Partner_Account__c'];
          }
          return this.getCustomersList(whereObj);
        })
      )
      .subscribe(
        x => {
          this._cd.markForCheck();
          this.customers = x;
          this.noCustomer = (this.customers.length === 0) ? 'No customer found.' : '';
        },
        err => {
          console.log(err);
          this.customers = [];
          this.noCustomer = '';
        }
      );
  }

  getCustomersList(whereObj) {
    return this._accountApi.find({
      fields: ['sfdcId', 'Name'],
      where: whereObj
    }).map(res => res);
  }

}
