import { OnInit, Component, Input, EventEmitter, Output } from '@angular/core';
import { PMSCaseStatus } from 'shared/models/static-list-data.service';

@Component({
    selector: 'app-pms-case-status-lookup',
    templateUrl: './pms-case-status-lookup.component.html',
    styleUrls: ['./pms-case-status-lookup.component.css']
})
export class PMSCaseStatusLookupComponent implements OnInit {

    @Input() placeholder = 'PMS Case Status';
    @Input() set resetData(isClear) {
        this.onClear(isClear);
    }
    @Output() selectedValue: EventEmitter<any> = new EventEmitter;
    caseStatus: any = [];
    selectedStatus: any = [];

    constructor() {
    }

    ngOnInit() {
        this.caseStatus = PMSCaseStatus;
        // to show selected workers
        const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
        this.selectedStatus = (preselected && preselected['pmsCaseStatus']) ? preselected['pmsCaseStatus'] : '';
    }

    onChange() {
        this.selectedValue.emit(this.selectedStatus);
    }

    onClear(isClear) {
        this.selectedStatus = [];
        if (isClear) {
            this.selectedValue.emit([]);
        }
    }
}
