// import { PreloaderService } from 'shared/services/preloader.service';
import { TalentTypeApi } from 'shared/sdk';
import { coverageHoursList, SLAList, talentLevelList } from 'shared/models/static-list-data.service';
import { FormBuilder, FormArray, FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-add-talent-information',
  templateUrl: './add-talent-information.component.html',
  styleUrls: ['./add-talent-information.component.css']
})
export class AddTalentInformationComponent implements OnInit {

  @Input() set saveTriggered(event) {
    if (event) {
      this.emitTalentInformationList.emit(this.talentInformation.value.filter(item => !item.isPending));
    }
  }

  @Input() set clearFromParent(event) {
    if (event) {
      this.ngOnInit();
    }
  }

  @Input() set talentInfo(talentInfoVal) {
    if (this.talentInformation.length) {
      while (this.talentInformation.length !== 0) {
          this.talentInformation.removeAt(0)
      }
  }
    if(talentInfoVal && talentInfoVal.length){
      talentInfoVal.forEach(element => {
        const talent = this.createTalentInfoRecord(element);
        if (talent)
          this.talentInformation.push(talent);
    });
    }

  }

  @Output() emitTalentInformationList: EventEmitter<any> = new EventEmitter();

  coverageHour = [];
  sla = [];
  talentLevel = [];
  talentType = [];

  constructor(private _fb: FormBuilder, private _talentTypeApi: TalentTypeApi) {
    this.coverageHour = [...coverageHoursList];
    this.sla = [...SLAList];
    this.talentLevel = [...talentLevelList];
  }

  userForm: FormGroup;

  ngOnInit() {
    this.userForm = this._fb.group({
      talentInformation: this._fb.array([])
    });
    this.getTalentTypeList();
  }

  getTalentTypeList() {
    this._talentTypeApi.find({
      // where: {isActive: true},
      fields: ['sfdcId', 'Talent_Type_Name__c', 'isActive']
    }).subscribe(reply => {
      reply.forEach(item => {
        this.talentType.push({ sfdcId: item['sfdcId'], Talent_Type_Name__c: item['Talent_Type_Name__c'] });
      });
    }, error => {
      console.log(error);
    });
  }


  private createTalentInfoRecord(editValues?): FormGroup {
    let talentTypeRecord;
    if (editValues) {
    talentTypeRecord = this.talentType.find(ele => ele.sfdcId === editValues.Talent_Type__c);
    talentTypeRecord.isPending = false;
    return this._fb.group({
        Talent_Type__c: [talentTypeRecord.sfdcId, Validators.required],
        Talent_Level__c: [editValues.Talent_Level__c, Validators.required],
        Coverage_Hours__c: [editValues.Coverage_Hours__c, Validators.required],
        SLA__c: [editValues.SLA__c && editValues.SLA__c.length ?
            editValues.SLA__c.split(';') : [], Validators.required],
        isPending: false
    });
  }

}


  removeTalentInfo(index) {
    this.talentInformation.removeAt(index);
  }

  get talentInformation() {
    return this.userForm.get('talentInformation') as FormArray;
  }

  addTalentInfo() {
    const group = new FormGroup({
      Talent_Type__c: new FormControl(null, [Validators.required]),
      Talent_Level__c: new FormControl(null, [Validators.required]),
      Coverage_Hours__c: new FormControl(null, [Validators.required]),
      SLA__c: new FormControl(null, [Validators.required]),
      isPending: new FormControl(true)
    });

    this.talentInformation.push(group);
  }

  onSLASelectionChange(event, index) {
    this.talentInformation.controls[index]['controls'].SLA__c.setValue(event);
  }

  onTalentInfoAssignment(index) {
    this.talentInformation.controls[index]['controls'].isPending.setValue(false);
  }

  getDropDownValue(index) {
    const selectedTalentType = this.talentType.filter(item => item.sfdcId === this.talentInformation.controls[index]['controls'].Talent_Type__c.value);
    return selectedTalentType[0]['Talent_Type_Name__c'];
  }

}
