import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { apvpGroupNumberValues } from 'shared/models/static-list-data.service';

@Component({
  selector: 'app-apvp-group-number-lookup',
  templateUrl: './apvp-group-number-lookup.component.html',
  styleUrls: ['./apvp-group-number-lookup.component.css']
})
export class ApvpGroupNumberLookupComponent implements OnInit {

  @Input() set resetData(isClear) {
    if (isClear) {
      this.apvpGroupSelected = [];
    }
  }
  @Output() selectedApvpGroup: EventEmitter<any> = new EventEmitter;

  apvpGroupList = [];
  apvpGroupSelected = [];
  isMultipleSelected = true;

  constructor() { }

  ngOnInit() {
    this.apvpGroupList = apvpGroupNumberValues;
    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
    this.apvpGroupSelected = (preselected &&
      preselected['apvpGroup']) ? preselected['apvpGroup'] : '';
  }

  onApvpGroupChange() {
    this.selectedApvpGroup.emit(this.apvpGroupSelected);
  }
  
  clearApvpGroup() {
    this.apvpGroupSelected = [];
    this.selectedApvpGroup.emit([]);
  }

}
