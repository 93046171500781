/* tslint:disable */
import {
  EngagementProgram
} from '../index';

declare var Object: any;
export interface EngagementStageInterface {
  "sfdcId"?: string;
  "CreatedById"?: string;
  "CurrencyIsoCode"?: string;
  "LastModifiedById"?: string;
  "Name"?: string;
  "ts2__AdditionalBCC__c"?: string;
  "ts2__AdditionalCC__c"?: string;
  "ts2__AdditionalTo__c"?: string;
  "ts2__AttachmentIds__c"?: string;
  "ts2__Custom_Template__c"?: string;
  "ts2__Engagement_Statuses__c"?: string;
  "ts2__Email_Template_Id__c"?: string;
  "ts2__Engagement_Activity_Count__c"?: number;
  "ts2__Engagement_Program__c"?: string;
  "ts2__OrderNumber__c"?: number;
  "ts2__Sending_User__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  engagementProgram?: EngagementProgram;
}

export class EngagementStage implements EngagementStageInterface {
  "sfdcId": string;
  "CreatedById": string;
  "CurrencyIsoCode": string;
  "LastModifiedById": string;
  "Name": string;
  "ts2__AdditionalBCC__c": string;
  "ts2__AdditionalCC__c": string;
  "ts2__AdditionalTo__c": string;
  "ts2__AttachmentIds__c": string;
  "ts2__Custom_Template__c": string;
  "ts2__Engagement_Statuses__c": string;
  "ts2__Email_Template_Id__c": string;
  "ts2__Engagement_Activity_Count__c": number;
  "ts2__Engagement_Program__c": string;
  "ts2__OrderNumber__c": number;
  "ts2__Sending_User__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  engagementProgram: EngagementProgram;
  constructor(data?: EngagementStageInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `EngagementStage`.
   */
  public static getModelName() {
    return "EngagementStage";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of EngagementStage for dynamic purposes.
  **/
  public static factory(data: EngagementStageInterface): EngagementStage{
    return new EngagementStage(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'EngagementStage',
      plural: 'EngagementStages',
      path: 'EngagementStages',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "ts2__AdditionalBCC__c": {
          name: 'ts2__AdditionalBCC__c',
          type: 'string'
        },
        "ts2__AdditionalCC__c": {
          name: 'ts2__AdditionalCC__c',
          type: 'string'
        },
        "ts2__AdditionalTo__c": {
          name: 'ts2__AdditionalTo__c',
          type: 'string'
        },
        "ts2__AttachmentIds__c": {
          name: 'ts2__AttachmentIds__c',
          type: 'string'
        },
        "ts2__Custom_Template__c": {
          name: 'ts2__Custom_Template__c',
          type: 'string'
        },
        "ts2__Engagement_Statuses__c": {
          name: 'ts2__Engagement_Statuses__c',
          type: 'string'
        },
        "ts2__Email_Template_Id__c": {
          name: 'ts2__Email_Template_Id__c',
          type: 'string'
        },
        "ts2__Engagement_Activity_Count__c": {
          name: 'ts2__Engagement_Activity_Count__c',
          type: 'number'
        },
        "ts2__Engagement_Program__c": {
          name: 'ts2__Engagement_Program__c',
          type: 'string'
        },
        "ts2__OrderNumber__c": {
          name: 'ts2__OrderNumber__c',
          type: 'number'
        },
        "ts2__Sending_User__c": {
          name: 'ts2__Sending_User__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        engagementProgram: {
          name: 'engagementProgram',
          type: 'EngagementProgram',
          model: 'EngagementProgram',
          relationType: 'belongsTo',
                  keyFrom: 'ts2__Engagement_Program__c',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
