import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { expenseTypeValues } from 'shared/models/static-list-data.service';
import { CommonService } from 'shared/services/common.service';

@Component({
  selector: 'app-expense-type-lookup',
  templateUrl: './expense-type-lookup.component.html',
  styleUrls: ['./expense-type-lookup.component.css']
})
export class ExpenseTypeLookupComponent implements OnInit {


  @Input() set resetData(isClear) {
    if (isClear) {
      this.expenseTypeSelected = [];
    }
  }
  @Input() placeholder = 'Expense Type';
  @Output() getExpenseTypeObj: EventEmitter<any> = new EventEmitter;
  expenseTypeOptionList = [];
  expenseTypeSelected = [];
  constructor(private _commonService: CommonService) { }

  ngOnInit() {
    this.expenseTypeOptionList = expenseTypeValues;
    const preSelected = localStorage.getItem('filterObj');
    if (preSelected && JSON.parse(preSelected) &&
      JSON.parse(preSelected)['expenseTypes'] && JSON.parse(preSelected)['expenseTypes'].length) {
      this.expenseTypeSelected = JSON.parse(preSelected)['expenseTypes'][0];
    }
  }
  onExpenseTypeChange() {
    this.getExpenseTypeObj.emit(this.expenseTypeSelected);
    this._commonService.setExpenseTypeList(this.expenseTypeSelected);
  }

  clearExpenseType() {
    this.expenseTypeSelected = [];
    this.getExpenseTypeObj.emit([]);
    this._commonService.setExpenseTypeList("");
  }

}
