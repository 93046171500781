import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TimecardModule } from '../timecard/timecard.module';
import { SelectWorkerModule } from '../select-worker/select-worker.module';
import { UpdateAppointmentModule } from '../update-appointment/update-appointment.module';
import { JobDetailInstructionsModule } from '../../views/vms/jobs/job-detail/job-detail-instructions/job-detail-instructions.module';
import { CustomerVisitPlanModule } from '../customer-visit-plan/customer-visit-plan.module';


import { WorkflowButtonsComponent } from './workflow-buttons.component';
import { AddWorkerModule } from 'shared/views/billing/talent-manager/add-worker/add-worker.module';

@NgModule({
  imports: [
    CommonModule,
    TimecardModule,
    SelectWorkerModule,
    UpdateAppointmentModule,
    JobDetailInstructionsModule,
    CustomerVisitPlanModule,
    AddWorkerModule
  ],
  declarations: [WorkflowButtonsComponent],
  exports: [WorkflowButtonsComponent]
})
export class WorkflowButtonsModule { }
