import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, AfterViewInit, AfterContentInit, OnChanges, SimpleChanges } from '@angular/core';
import { debounceTime, distinctUntilChanged, switchMap, filter } from 'rxjs/operators';
import { AppStateService } from 'shared/services/app-state.service';

import { AccountApi } from '../../../sdk';
@Component({
    selector: 'app-account-lookup',
    templateUrl: './account-lookup.component.html',
    styleUrls: ['./account-lookup.component.scss']
})
export class AccountLookupComponent implements OnInit, OnChanges, AfterContentInit {
    @Input() fromPage = '';
    @Input() isGlobalFilter = true;
    @Input() disabled = false;
    @Input() notFoundText = 'No partner account found.';
    @Input() boxedLayout = false;
    @Input() recordType = ['0121a000000QaUdAAK'];
    viewAsPartner: boolean;
    @Input() set resetData(isClear) {
        // this.pAccountList = [];
        if (this._appState.getAccessType() !== 'partner' && !this.viewAsPartner) {
            this.pAccountsSelected = [];
        }
    }
    @Input() isMultipleSelected = true;
    @Input() maxSelect = 20;
    @Input() set accountName(val: string) {
        if (val && !this.isMultipleSelected) {
          this.pAccountsSelected = val;
          this.addContact = true;
        }
    }
    @Input() set setValueInEditMode(sfdcId) {
        if(sfdcId) {
            setTimeout(() => {
                this.pAccountsSelected = sfdcId;
                this.ngAfterContentInit();
            }, 100);            
        }
    }
    @Input() partnersOnly: boolean = false;
    @Input() set addContactValue (val) {
        this.addContact = val;
    }
    @Input() selectedDefaultAccounts: Array<string>;
    @Input() from;

    @Output() getPartnerAccountObj: EventEmitter<any> = new EventEmitter;

    pAccountsTypeahead = new EventEmitter<string>();
    pAccounts: Array<any>;
    pAccountList = [];
    pAccountsSelected: string | Array<string>;
    addContact = false;
    placeholder = '';

    constructor(
        private _cd: ChangeDetectorRef,
        private _accountApi: AccountApi,
        private _appState: AppStateService
    ) {
         // To check is view As PMS mode is active and disable selection accordingly.
         const viewAsInfo = JSON.parse(localStorage.getItem('viewAsInfo'));
         this.viewAsPartner = (viewAsInfo && viewAsInfo.viewAsType === 'Partner');
    }

    ngOnInit() {
        this.getAccounts();
        this.placeholder = (this.fromPage === 'ai-candidate') ? 'Search Partner Account' : this.placeholder;
    }

    ngAfterContentInit() {
        let whereObj;
        if (this.isGlobalFilter) {
            // to show selected partner account
            if (!this.addContact && !this.partnersOnly) {
                const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
                this.pAccountsSelected = (preselected && preselected['accounts']) ? preselected['accounts'] : '';
                if (Array.isArray(this.pAccountsSelected)) {
                    whereObj = { sfdcId: { inq: this.pAccountsSelected }, RecordTypeId: '0121a000000QaUdAAK' };
                } else {
                    whereObj = { sfdcId: this.pAccountsSelected , RecordTypeId: '0121a000000QaUdAAK' };
                }
            } else {
                if (this.from && this.from === 'analyticsDashboard') {
                    if (this.selectedDefaultAccounts && this.selectedDefaultAccounts.length) {
                        this.pAccountsSelected = this.selectedDefaultAccounts;
                    } else {
                        this.pAccountsSelected = [];
                    }
                }
                whereObj = { sfdcId: this.pAccountsSelected };
            }
            this.getPartnerAccountObj.emit(this.pAccountsSelected);
            this.disabled = (this._appState.getAccessType() === 'partner' || this.viewAsPartner);
        } else {
            this.pAccountsSelected = this.setSelectedAccount(this.selectedDefaultAccounts);
            if (this.pAccountsSelected && this.pAccountsSelected.length) {
                whereObj = { sfdcId: { inq: this.makeArray(this.pAccountsSelected) }};
            }
        }

        if (whereObj && Object.keys(whereObj).length) {
            this.fetchAndSetAccounts(whereObj);
        }
    }

    setSelectedAccount(accounts) {
        const accountsArr = this.makeArray(accounts);
        return this.isMultipleSelected ? accountsArr : (accountsArr.length ? accountsArr[0] : null);
    }

    ngOnChanges(simpleChanges: SimpleChanges) {
        if (simpleChanges.selectedDefaultAccounts && !simpleChanges.selectedDefaultAccounts.firstChange) {
            this.pAccountsSelected = this.setSelectedAccount(this.selectedDefaultAccounts);
            if (this.makeArray(this.pAccountsSelected).some(sfdcId => !this.pAccountList.find(x => sfdcId === x.sfdcId))) {
                this.fetchAndSetAccounts({ sfdcId: { inq: this.makeArray(this.pAccountsSelected) } });
            }
        }
    }

    makeArray(data) {
        return data ? (Array.isArray(data) ? data : [data]) : [];
    }

    fetchAndSetAccounts(where) {
        this.getAccountList(where).subscribe(
            x => {
                this.pAccountList = x;
            },
            err => {
                console.error(err);
                this.pAccountList = [];
            }
        );
    }

    // get partner's accounts list by name
    getAccounts() {
        this.pAccountList = [];
        this.pAccountsTypeahead
            .pipe(
                filter(term => term && term.trim().length > 2),
                distinctUntilChanged(),
                debounceTime(200),
                switchMap(term => {
                    const searchPattern = { ilike: '%' + term.trim() + '%', options: 'i' };
                    const whereObj = {
                        Name: searchPattern,
                       // RecordTypeId: '0121a000000QaUdAAK'
                    };
                    if(!this.addContact){
                        whereObj['RecordTypeId'] = { inq: this.recordType };
                    }
                    return this.getAccountList(whereObj);
                })
            )
            .subscribe(
                x => {
                    this._cd.markForCheck();
                    this.pAccountList = x;
                },
                err => {
                    console.error(err);
                    this.pAccountList = [];
                }
            );
    }

    onAccountChange() {
        this.getPartnerAccountObj.emit(this.pAccountsSelected);
    }

    clearPAccount() {
        this.pAccountsSelected = [];
        this.getPartnerAccountObj.emit([]);
    }

    /**
     * 
     * @param whereObj 
     */

    getAccountList(whereObj) {
        return this._accountApi.find({
            where: whereObj,
            fields: ['Name', 'id', 'sfdcId'],
            order: 'CreatedDate DESC',
        }).map(res => res);
    }
}