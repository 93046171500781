import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuillModule } from 'ngx-quill';
import { HttpClientModule } from '@angular/common/http';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { UserLookupModule } from '../lookup/user-lookup/user-lookup.module';
import { HelpdeskTaskComponent } from './helpdesk-task.component';
import { DocumentModule } from '../document/document.module';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    QuillModule,
    HttpClientModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    UserLookupModule,
    NgbModule,
    DocumentModule
  ],
  declarations: [HelpdeskTaskComponent],
  exports: [HelpdeskTaskComponent]
})
export class HelpdeskTaskModule { }
