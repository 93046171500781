import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaskConversationActivityFeedComponent } from './task-conversation-activity-feed.component';
import { MatPaginatorModule } from '@angular/material/paginator';

@NgModule({
  imports: [
    CommonModule,
    MatPaginatorModule
  ],
  declarations: [TaskConversationActivityFeedComponent],
  exports: [TaskConversationActivityFeedComponent]
})
export class TaskConversationActivityFeedModule { }
