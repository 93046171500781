import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WoDetailInstructionsComponent } from './wo-detail-instructions.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [WoDetailInstructionsComponent],
  exports: [WoDetailInstructionsComponent]
})
export class WoDetailInstructionsModule { }
