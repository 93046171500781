import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StateLookupComponent } from './state-lookup.component';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgSelectModule
    ],
    declarations: [StateLookupComponent],
    exports: [StateLookupComponent]
})
export class StateLookupModule { }
