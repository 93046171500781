import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'environments/environment';
import { fileTypesOpenInBrowser } from 'shared/models/static-list-data.service';
import { CommonService } from 'shared/services/common.service';

@Component({
  selector: 'app-fleet-management-feed-list-item',
  templateUrl: './fleet-management-feed-list-item.component.html',
  styleUrls: ['./fleet-management-feed-list-item.component.css']
})
export class FleetManagementFeedListItemComponent implements OnInit {

  @Input() doc;
  @Output() delete = new EventEmitter<any>();
  @Output() expand = new EventEmitter<any>();
  constructor(private _commonService: CommonService) { }

  ngOnInit() {
  }

  /**
     * Method to download the document.
     * @param doc - the document object that to be download
     * @param type - type to handle the case of open the document in browser new tab.
     * The argument @param type is by default 0, represent download by defalut. 
     */
  downloadDocument = (doc, type = 0) => {
    let fileTypesOpenInBrowserList = fileTypesOpenInBrowser;
    if (type != 0) {
      // doc.fileType
      if (doc && doc.fileMeta && doc.fileMeta.extension in fileTypesOpenInBrowserList) {
        if (doc.shortUrl && doc.shortUrl) {
          window.open(doc.shortUrl, '_blank');
          return;
        }
      }
    }
    if (doc) {
      const item = doc;
      let files = [];
      const containerPath = environment.baseUrl + '/' + environment.apiVersion + '/Containers/';
      files.push({
        path: containerPath + (item.fileMeta.path),
        name: item.fileMeta.name
      });
      this._commonService.downloadMultiFiles(files);
    }
  }

}
