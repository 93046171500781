import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'environments/environment';
import { forkJoin } from 'rxjs';
import { CustomerCsatApi, LearningAssignmentApi, AnswerSequenceApi, ResponseApi } from 'shared/sdk';
import { AppStateService } from 'shared/services/app-state.service';
import { ModalService } from 'shared/services/modal.service';
import { PreloaderService } from 'shared/services/preloader.service';

@Component({
  selector: 'app-public-csat-feedback-form',
  templateUrl: './public-csat-feedback-form.component.html',
  styleUrls: ['./public-csat-feedback-form.component.css']
})
export class PublicCsatFeedbackFormComponent implements OnInit {
  myForm: FormGroup;
  get questions(): FormArray { return this.myForm.get('questions') as FormArray; }
  QuesSet = [];
  questionLine: any;
  answerSequence: any;
  learningAssignment: any;
  customerCSAT: any;
  currentUser: any;
  csatStatus: any;
  modalOpen = true;
  form = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.pattern('[a-zA-Z ]{3,30}')
    ]),
  })
  rating = 0;    
  constructor(private route: ActivatedRoute,
    private fb: FormBuilder,
    private _preloaderService: PreloaderService,
    private _customerCsatApi: CustomerCsatApi,
    // private _learningAssignmentApi: LearningAssignmentApi,
    private _answerSequenceApi: AnswerSequenceApi,
    private _responseApi: ResponseApi,
    private _appState: AppStateService,
    private _modalService: ModalService,
  ) {
    localStorage.removeItem('csatURL');
    this.route.params.subscribe(params => {
      console.log('CSATID>>>>', params);
      if (params && params.csatId && environment.csatLearningAssignmentId) {
        this.getPageData(params.csatId, environment.csatLearningAssignmentId);
      }
    })
  }

  ngOnInit() {
    this._appState.getAppState().subscribe(
      data => {
        if (data && data['user']) {
          this.currentUser = data['user'];
        }
      }, error => {
        console.log(error);
      });
    document.getElementById("formDetails").click();
    this.configForm();
  }

  /**
   * Create a from group
   */
  configForm() {
    this.myForm = this.fb.group({
      account: [''],
      program: [''],
      questions: this.fb.array([]), // method to add 1 item by default
    });
  }

  /**
 * Set all question
 */
  addQuestion() {
    this.QuesSet.map(item => {
      this.questions.push(this.setQuestionGroup(item))
    })
  }

  /**
   * Bind question in from array grop
   * @param item Question object
   * @returns array group
   */
  setQuestionGroup(item): FormGroup {
    return this.fb.group({
      sfdcId: [item.sfdcId, [Validators.required]],
      Type__c: [item.Type__c, [Validators.required]],
      Answer_Sequence__c: [item.Answer_Sequence__c],
      Description__c: [item.Description__c, [Validators.required]],
      CSAT_Anwers__c: [item.CSAT_Anwers__c, [Validators.required]]
    });
  }

  /**
   *  get data for customerCsat || LearningAssignment using fork join
   * @param csatSfdcId customerCsat sfdcId
   * @param lAssignmentSfdcId LearningAssignment sfdcId
   */
  getPageData(csatSfdcId, lAssignmentSfdcId) {
    this._preloaderService.showPreloader();
    const req = {
      customerCsat: this.getCsat(csatSfdcId),
      learningAssignment: this.getLearningAssignment(lAssignmentSfdcId)
    }
    this._customerCsatApi.getCustomerCsat(req).subscribe(result => {
      if (result.data.customerCsat && result.data.customerCsat.length) {
        this.prePairCsat(result.data.customerCsat[0]);
      } 
      if (result.data.learningAssignment && result.data.learningAssignment.length) {
        this.prelPairLAssignment(result.data.learningAssignment[0]);
      }
      this._preloaderService.hidePreloader();
    },
      error => {
        console.log(error);
        this._preloaderService.hidePreloader();
      }
    );
  }

  /**
   * cook data
   * @param data customerCsat object
   */
  prePairCsat(data) {
    this.customerCSAT = data;
    if (this.customerCSAT && this.customerCSAT.account) {
      this.myForm.controls.account.setValue(this.customerCSAT.account.Name);
    }
    if (this.customerCSAT && this.customerCSAT.project) {
      this.myForm.controls.program.setValue(this.customerCSAT.project.Name);
    }
    if (this.customerCSAT.Overall_CSAT_Sta__c === 'CSAT Submitted') {
      this.csatStatus = 'Feedback has been already submitted!';
    } else {
      this.updateResponseInCSAT(this.customerCSAT.id, 'Email reviewed by customer');
    }
  }

  /**
   * update Customer_Email_Ack_Date__c || Overall_CSAT_Sta__c
   * @param csatId customer cast id
   * @param overallCSATStatus__c Overall_CSAT_Sta__c is use below static code
   * 1. CSAT Request Created - This value updated when the CSAT record created - From ISG
   * 2. Request sent to customer - This value updated when the CSAT email has been sent to the customer - From ISG
   * 3. Email reviewed by customer - This value needs to be updated when the form is loaded- From Serviceo
   * 4. CSAT Submitted -This value needs to be updated when the form is submitted- From Serviceo"
   */
  updateResponseInCSAT(csatId, overallCSATStatus__c) {
    const patchObj = { Overall_CSAT_Sta__c: overallCSATStatus__c };
    if (overallCSATStatus__c === 'Email reviewed by customer') {
      patchObj['Customer_Email_Ack_Date__c'] = new Date();
    }
    const reqObj = {
      csatId: csatId,
      obj: patchObj
    }
    this._customerCsatApi.updateCsat(reqObj).subscribe((res: any) => {
      if (res.Overall_CSAT_Sta__c === 'CSAT Submitted') {
        this.csatStatus = this.questionLine && this.questionLine.Thank_you__c || 'Feedback has been submitted successfully!';
      }
      console.log('customer Cast Update>>>', res);
    }, err => {
      console.log(err);
    })
  }

  /**
   * cook data
   * @param data LearningAssignment Object
   */
  prelPairLAssignment(data) {
    this.learningAssignment = data;
    if (data.quiz && data.quiz.length && data.quiz[1] && data.quiz[1].questionLine &&
      data.quiz[1].questionLine.length && data.quiz[1].questionLine[0].learningQuestion &&
      data.quiz[1].questionLine[0].learningQuestion.length) {
      const answerSequenceArrObj = [];
      this.questionLine = data.quiz[1].questionLine[0];
      this.QuesSet = data.quiz[1].questionLine[0].learningQuestion;
      this.addQuestion();
      this.QuesSet.map(q => {
        if (q.Type__c === 'SelectOneQuestion' && q.Answer_Sequence__c) {
          answerSequenceArrObj.push(q.Answer_Sequence__c);
        }
      });
      if (answerSequenceArrObj && answerSequenceArrObj.length) {
        this.getAnswerSequence(answerSequenceArrObj);
      }
    }
  }


  /**
   * get customerCsat data
   * @param csatSfdcId customer CSAT sfdcId
   * @returns return customerCsat array object
   */
  getCsat(csatSfdcId) {
    // const appData = JSON.parse(localStorage.getItem('appData'));
    const csatReqObj = {
      where: { sfdcId: csatSfdcId},
        // , Overall_CSAT_Sta__c: { neq: 'CSAT Submitted' } },
      //  'or': [{ 'Customer_Delivery_Manager__c': appData.user.sfdcId }, { 'Customer_PM__c': appData.user.sfdcId }] },
      include: [{
        relation: 'account',
        scope: {
          fields: ['Name', 'sfdcId']
        }
      },
      {
        relation: 'project',
        scope: {
          fields: ['Name', 'sfdcId']
        }
      }]
    }

    return csatReqObj;
  }

  /**
   * get LearningAssignment
   * @param lAssignmentSfdcId LearningAssignment sfdcId
   * @returns return LearningAssignment array object
   */
  getLearningAssignment(lAssignmentSfdcId) {
    const reqObj = {
      where: { sfdcId: lAssignmentSfdcId },
      fields: ['Name', 'sfdcId', 'Learning__c'],
      include: [
        {
          relation: 'learning',
          scope: {
            fields: ['Name', 'sfdcId']
          }
        },
        {
          relation: 'quiz',
          scope: {
            fields: ['Name', 'sfdcId', 'Learning__c'],
            include: [
              {
                relation: 'questionLine',
                scope: {
                  fields: ['Name', 'sfdcId', 'Quiz__c', 'Answer_Sequence__c', 'Thank_you__c', 'Introduction__c'],
                  include: [
                    {
                      relation: 'learningQuestion',
                      scope: {
                        fields: ['Name', 'sfdcId', 'Answer_Sequence__c', 'Question_Line__c', 'Description_RTA__c', 'Description__c', 'Order__c', 'Type__c', 'Marks__c'],
                      }
                    }
                  ]
                }
              }
            ]
          }
        }
      ]
    };
    return reqObj;
  }

  /**
   * get all answer sequence
   * @param answerSequenceSfdcId answer Sequence SfdcId in Array Object
   */
  getAnswerSequence(answerSequenceSfdcId) {
    const reqObj = {
      where: { sfdcId: { inq: answerSequenceSfdcId } },
      fields: ['Name', 'sfdcId'],
      include: [
        {
          relation: 'answerSequenceItem',
          scope: {
            fields: ['Name', 'sfdcId', 'Answer_Sequence__c', 'Item_Id__c', 'Answer_Text__c', 'Order__c'],
            order: 'Item_Id__c DESC'
          }
        }
      ]
    }
    this._answerSequenceApi.getAnswerSequence(reqObj).subscribe(res => {
      this.answerSequence = res;
      console.log('_answerSequenceApi>>>>>>>', res)
    }, err => {
      console.log(err);
    })
  }


  setAnswerSequence(answerSequenceSfdcId) {
    let answerSequenceItem = [];
    if (answerSequenceSfdcId && this.answerSequence && this.answerSequence.length) {
      const answerSequence = this.answerSequence.find(ans => ans.sfdcId === answerSequenceSfdcId);
      answerSequenceItem = answerSequence && answerSequence.answerSequenceItem || [];
    }
    return answerSequenceItem;
  }
  /**
   * Save data in response tbl
   */
  submit() {
    console.log(this.rating);
    if (this.rating) {
      let answerSequenceObj = {};
      const responseArr = [];
      if (this.myForm.value.questions && this.myForm.value.questions.length) {
        this.myForm.value.questions.map(q => {
          answerSequenceObj = {
            User_Name__c: this.form.get('name').value || '',
            Correct_Response__c: true,
            Customer_CSAT__c: this.customerCSAT && this.customerCSAT.sfdcId || '',
            Learning_Assignment__c: this.learningAssignment && this.learningAssignment.sfdcId || '',
            Number_Response__c: this.getNumberofResponse(q),
            Question__c: q.sfdcId || '',
            Question_Type__c: q.Type__c || '',
            Response_Answer_Sequence_Label__c: this.rating,
            Response_Answer_Sequence_Long__c: this.rating,
          }
          responseArr.push(answerSequenceObj);
        });

        console.log('responseArr>>>', responseArr);
     //   this.createResponse(responseArr);
      }

    }
  }

  /**
   * get Answer Sequence Item length
   * @param q Answer Sequence
   */
  getNumberofResponse(q) {
    if (q.Type__c === 'SelectOneQuestion' && this.rating) {
      return this.rating.toFixed(2);
    } else {
      return '';
    }
  }

  /**
   * save all question info in response model
   * @param arrDataObj response array object
   */
  createResponse(arrDataObj) {
    this._preloaderService.showPreloader();
    this._responseApi.createResponseCsat(arrDataObj).subscribe(res => {
      this.updateResponseInCSAT(this.customerCSAT.id, 'CSAT Submitted');
      this._preloaderService.hidePreloader();
    }, err => {
      console.log(err);
      this._preloaderService.hidePreloader();
    })
  }

  /**
   * Reset form Value
   */
  resetForm() {
    this.configForm();
    this.addQuestion();
    this.rating = 0;
  }


  openModal(content) {
    this.modalOpen = true;
    this._modalService.open(content, 'sm');
  }
   
  nameDetailsSumbit(){
    console.log(JSON.stringify(this.form.get('name').value));
    this._modalService.closed();
    this.modalOpen = false;
  }

}
