import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefaultSkuLookupComponent } from './default-sku-lookup.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ModalBoxModule } from 'shared/components/modal-box/modal-box.module';

@NgModule({
  imports: [
    CommonModule,
    NgxDatatableModule,
    FormsModule,
    ModalBoxModule,
    NgSelectModule
  ],
  declarations: [DefaultSkuLookupComponent],
  exports: [DefaultSkuLookupComponent]
})
export class DefaultSkuLookupModule { }
