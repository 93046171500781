import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UserDeduplicationTableComponent } from './user-deduplication-table.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NguiMapModule } from '@ngui/map';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { NgxUploaderModule } from 'ngx-uploader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { SharedModule } from '../shared.module';
import { ConfirmDialogModule } from '../confirm-dialog/confirm-dialog.module';
import { ImageCropperModule } from 'ngx-image-cropper';


@NgModule({
    imports: [
        CommonModule,
        NgbModule.forRoot(),
        SharedModule,
        ConfirmDialogModule
    ],
    declarations: [
        UserDeduplicationTableComponent
    ],
    exports: [
        UserDeduplicationTableComponent
    ]
})

export class UserDeduplicationTableModule {

}
