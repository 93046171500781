/* tslint:disable */

declare var Object: any;
export interface JobSkillsInterface {
  "sfdcId"?: string;
  "ts2__Best_Fit_Skill__c"?: boolean;
  "CreatedById"?: string;
  "CurrencyIsoCode"?: string;
  "LastModifiedById"?: string;
  "Name"?: string;
  "ts2__Contact__c"?: string;
  "ts2__Discipline_Tree__c"?: string;
  "ts2__Experience__c"?: string;
  "ts2__ExperienceNumber__c"?: string;
  "ts2__Last_Used__c"?: string;
  "ts2__LevelPicklist__c"?: string;
  "ts2__Level__c"?: string;
  "ts2__Parsed__c"?: boolean;
  "ts2__Rating__c"?: number;
  "ts2__Skill_Name__c"?: string;
  "ts2__Taxonomy__c"?: string;
  "ts2__Taxonomy_Skill__c"?: string;
  "ts2__Type__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class JobSkills implements JobSkillsInterface {
  "sfdcId": string;
  "ts2__Best_Fit_Skill__c": boolean;
  "CreatedById": string;
  "CurrencyIsoCode": string;
  "LastModifiedById": string;
  "Name": string;
  "ts2__Contact__c": string;
  "ts2__Discipline_Tree__c": string;
  "ts2__Experience__c": string;
  "ts2__ExperienceNumber__c": string;
  "ts2__Last_Used__c": string;
  "ts2__LevelPicklist__c": string;
  "ts2__Level__c": string;
  "ts2__Parsed__c": boolean;
  "ts2__Rating__c": number;
  "ts2__Skill_Name__c": string;
  "ts2__Taxonomy__c": string;
  "ts2__Taxonomy_Skill__c": string;
  "ts2__Type__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: JobSkillsInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `JobSkills`.
   */
  public static getModelName() {
    return "JobSkills";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of JobSkills for dynamic purposes.
  **/
  public static factory(data: JobSkillsInterface): JobSkills{
    return new JobSkills(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'JobSkills',
      plural: 'JobSkills',
      path: 'JobSkills',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "ts2__Best_Fit_Skill__c": {
          name: 'ts2__Best_Fit_Skill__c',
          type: 'boolean'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "ts2__Contact__c": {
          name: 'ts2__Contact__c',
          type: 'string'
        },
        "ts2__Discipline_Tree__c": {
          name: 'ts2__Discipline_Tree__c',
          type: 'string'
        },
        "ts2__Experience__c": {
          name: 'ts2__Experience__c',
          type: 'string'
        },
        "ts2__ExperienceNumber__c": {
          name: 'ts2__ExperienceNumber__c',
          type: 'string'
        },
        "ts2__Last_Used__c": {
          name: 'ts2__Last_Used__c',
          type: 'string'
        },
        "ts2__LevelPicklist__c": {
          name: 'ts2__LevelPicklist__c',
          type: 'string'
        },
        "ts2__Level__c": {
          name: 'ts2__Level__c',
          type: 'string'
        },
        "ts2__Parsed__c": {
          name: 'ts2__Parsed__c',
          type: 'boolean'
        },
        "ts2__Rating__c": {
          name: 'ts2__Rating__c',
          type: 'number'
        },
        "ts2__Skill_Name__c": {
          name: 'ts2__Skill_Name__c',
          type: 'string'
        },
        "ts2__Taxonomy__c": {
          name: 'ts2__Taxonomy__c',
          type: 'string'
        },
        "ts2__Taxonomy_Skill__c": {
          name: 'ts2__Taxonomy_Skill__c',
          type: 'string'
        },
        "ts2__Type__c": {
          name: 'ts2__Type__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
