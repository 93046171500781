/* tslint:disable */
import {
  Users
} from '../index';

declare var Object: any;
export interface LearningInterface {
  "sfdcId"?: string;
  "IsDeleted"?: boolean;
  "CreatedDate"?: Date;
  "CreatedById"?: string;
  "CurrencyIsoCode"?: string;
  "RecordTypeId"?: string;
  "LastModifiedDate"?: Date;
  "LastModifiedById"?: string;
  "Name"?: string;
  "SystemModstamp"?: Date;
  "LastActivityDate"?: Date;
  "LastViewedDate"?: Date;
  "LastReferencedDate"?: Date;
  "Current_Published_Version_Formula__c"?: string;
  "Current_Published_Version__c"?: string;
  "Custom_Preview_URL__c"?: string;
  "Description__c"?: string;
  "Duration__hours__c"?: string;
  "Duration__c"?: string;
  "Is_Published__Formula__c"?: string;
  "Published__c"?: boolean;
  "Sync_progress__c"?: boolean;
  "Type_Image__c"?: string;
  "id"?: number;
  "OwnerId"?: string;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  CreatedBy?: Users;
  LastModifiedBy?: Users;
  Owner?: Users;
}

export class Learning implements LearningInterface {
  "sfdcId": string;
  "IsDeleted": boolean;
  "CreatedDate": Date;
  "CreatedById": string;
  "CurrencyIsoCode": string;
  "RecordTypeId": string;
  "LastModifiedDate": Date;
  "LastModifiedById": string;
  "Name": string;
  "SystemModstamp": Date;
  "LastActivityDate": Date;
  "LastViewedDate": Date;
  "LastReferencedDate": Date;
  "Current_Published_Version_Formula__c": string;
  "Current_Published_Version__c": string;
  "Custom_Preview_URL__c": string;
  "Description__c": string;
  "Duration__hours__c": string;
  "Duration__c": string;
  "Is_Published__Formula__c": string;
  "Published__c": boolean;
  "Sync_progress__c": boolean;
  "Type_Image__c": string;
  "id": number;
  "OwnerId": string;
  "createdAt": Date;
  "updatedAt": Date;
  CreatedBy: Users;
  LastModifiedBy: Users;
  Owner: Users;
  constructor(data?: LearningInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Learning`.
   */
  public static getModelName() {
    return "Learning";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Learning for dynamic purposes.
  **/
  public static factory(data: LearningInterface): Learning{
    return new Learning(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Learning',
      plural: 'Learnings',
      path: 'Learnings',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "RecordTypeId": {
          name: 'RecordTypeId',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "SystemModstamp": {
          name: 'SystemModstamp',
          type: 'Date'
        },
        "LastActivityDate": {
          name: 'LastActivityDate',
          type: 'Date'
        },
        "LastViewedDate": {
          name: 'LastViewedDate',
          type: 'Date'
        },
        "LastReferencedDate": {
          name: 'LastReferencedDate',
          type: 'Date'
        },
        "Current_Published_Version_Formula__c": {
          name: 'Current_Published_Version_Formula__c',
          type: 'string'
        },
        "Current_Published_Version__c": {
          name: 'Current_Published_Version__c',
          type: 'string'
        },
        "Custom_Preview_URL__c": {
          name: 'Custom_Preview_URL__c',
          type: 'string'
        },
        "Description__c": {
          name: 'Description__c',
          type: 'string'
        },
        "Duration__hours__c": {
          name: 'Duration__hours__c',
          type: 'string'
        },
        "Duration__c": {
          name: 'Duration__c',
          type: 'string'
        },
        "Is_Published__Formula__c": {
          name: 'Is_Published__Formula__c',
          type: 'string'
        },
        "Published__c": {
          name: 'Published__c',
          type: 'boolean'
        },
        "Sync_progress__c": {
          name: 'Sync_progress__c',
          type: 'boolean'
        },
        "Type_Image__c": {
          name: 'Type_Image__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        CreatedBy: {
          name: 'CreatedBy',
          type: 'Users',
          model: 'Users',
          relationType: 'belongsTo',
                  keyFrom: 'CreatedById',
          keyTo: 'sfdcId'
        },
        LastModifiedBy: {
          name: 'LastModifiedBy',
          type: 'Users',
          model: 'Users',
          relationType: 'belongsTo',
                  keyFrom: 'LastModifiedById',
          keyTo: 'sfdcId'
        },
        Owner: {
          name: 'Owner',
          type: 'Users',
          model: 'Users',
          relationType: 'belongsTo',
                  keyFrom: 'OwnerId',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
