
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DownloadsComponent } from './downloads.component';
@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        DownloadsComponent
    ],
    providers: [
    ],
    schemas: []
})

export class DownloadsModule { }