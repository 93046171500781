import { Injectable } from '@angular/core';
import { Angular2Csv } from '../libs/angular2-csv';
import { ContainerApi } from '../sdk/services/custom/Container';
import { environment } from '../../environments/environment';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AlertService } from 'shared/services/alert.service';
import { ModalService } from './modal.service';
import { TimecardApi, DocumentApi } from 'shared/sdk';
import { promise } from 'protractor';
import { PreloaderService } from './preloader.service';
import * as _ from 'lodash';
@Injectable()
export class UtilityService {
    datePipe: DatePipe;
    constructor(
        private _containerApi: ContainerApi,
        private _http: HttpClient,
        private _alertService: AlertService,
        private _modalService: ModalService,
        private _timecardApi: TimecardApi,
        private _documentApi: DocumentApi,
        private _preloaderService: PreloaderService
    ) { }
    /**
     * @Important : The below method will be removed in next sprint
     * Use exportData() of shared service to download/export feature.
     * @param csvData
     * @param fileName
     */
    csvExport(csvData, fileName) {
        const headers = Object.keys(csvData[0]);
        const options = {
            showLabels: true,
            showTitle: false,
            useBom: true,
            headers: headers
        };
        return new Angular2Csv(csvData, fileName, options);
    }
    downloadFile(filePath) {
        try {
            window.open(environment.baseUrl + '/' + environment.apiVersion + '/Containers/' + filePath);
        } catch (error) {
            console.log(error);
        }
    }
    deleteFile(fileName) {
        try {
            this._containerApi.removeFile('tmp', fileName).subscribe(success => {
            }, error => {
                console.log(error);
            })
        } catch (error) {
            console.log(error);
        }
    }

    downloadAndOpenPdfFile(filePath, fileName, isOpen = false) {
        return this._http.get(filePath, {
            responseType: 'blob'
        }).map(res => {
            return {
                filename: fileName ? fileName : 'ServiceO.pdf',
                data: res
            };
        }).subscribe(res => {
            const url = window.URL.createObjectURL(res.data);
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = res.filename;
            a.click();
            a.remove();
            // window.open(url);
            if (isOpen) {
                window.open(url);
            } else {
                window.URL.revokeObjectURL(url);
            }
        }, error => {
            this._alertService.clear();
            if (error && error.status === 404) {
                this._alertService.error('File not found');
            } else {
                this._alertService.error('something went wrong');
            }
            this._modalService.closed();

        }, () => {
            // console.log('Completed file download.')
        });
    }

    /**
     *
     * @param a  | data
     * @param b  | filter obj
     * @param c  | key
     */
    dataTableSearch(a, b, c) {
        let i = a[c];
        let result;
        switch (typeof i) {
            case 'string':
                result = i ? i.toLowerCase().indexOf(b[c].toLowerCase()) === -1 : true;
                break;
            case 'number':
                result = i >= 0 ? (i !== parseFloat(b[c])) : true;
                break;
            case 'boolean':
                result = i == null && i === undefined ? true : i !== (b[c] === 'true');
                break;
            case 'object':
                if (Array.isArray(i) && i.length) {
                    i.some(el => {
                        result = el ? el.toLowerCase().indexOf(b[c].toLowerCase()) === -1 : true;
                        return !result;
                    });
                } else {
                    result = true;
                }
                break;
            default:
                result = true;
        }
        return result;
    }
    /**
     *
     * @param value
     * @param dateformate
     */
    dateFormate(value: string, dateformate = 'short') {
        this.datePipe = new DatePipe('en-US');
        const formateddate = (value ? this.datePipe.transform(value, dateformate) : value);
        return formateddate;
    }

    // THIS METHOD IS FOR THE JOB MODEL
    getTimecardsCount = async (modeName, modelId) => {
        return new Promise((resolve, reject) => {
            const req = {
                'where': {
                    and: [
                        { Service_Dispatch__c: modelId },
                        { or: [{ 'IsServiceODelete': 0 }, { 'IsServiceODelete': null }] }
                    ]
                },
                'jobId': modelId,
                'count': true
            }
            this._timecardApi.getTimeCardList(req).subscribe(results => {
                resolve(results.count || 0);
            }, err => {
                console.log('timecard err:', err);
                resolve(0);
            })
        })
    }

    // THIS METHOD IS to get the documents count depending on the model name and model id.
    getDocumentsCount = async (modeName, modelIds) => {
        return new Promise((resolve, reject) => {
            const modelIdsArr = Array.isArray(modelIds) ? modelIds : [modelIds]
            this._preloaderService.showPreloader();
            this._documentApi.count({
                modelName: modeName,
                modelId: { inq: modelIdsArr },
                'isDeleted': false
            }).subscribe(results => {
                this._preloaderService.hidePreloader();
                resolve(results.count || 0);
            }, err => {
                this._preloaderService.hidePreloader();
                console.log('dcument count err:', err);
                resolve(0);
            })
        })

    }

    // this method flattens a nested object for eg:
    /**
     * let obj = {a : {b : {c : 1}}}
     * return will be => obj = {'a.b.c' : 1}
     */

    flattenObject(obj) {
        const delim = '.'
        const nobj = {}
        const self = this;
        _.each(obj, function (val, key) {
            // ensure is JSON key-value map, not array
            if (_.isObject(val) && !_.isArray(val)) {
                // union the returned result by concat all keys
                const strip = self.flattenObject(val)
                _.each(strip, function (v, k) {
                    nobj[key + delim + k] = v
                })
            } else {
                nobj[key] = val
            }
        })
        return nobj
    }

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
}
