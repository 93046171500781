import { OnInit, Component, Input, EventEmitter, Output } from '@angular/core';
import { ActivityType } from 'shared/models/static-list-data.service';

@Component({
    selector: 'app-activity-type-lookup',
    templateUrl: './activity-type-lookup.component.html',
    styleUrls: ['./activity-type-lookup.component.css']
})
export class ActivityTypeLookupComponent implements OnInit {

    @Input() placeholder = 'Activity Type';
    @Input() set resetData(isClear) {
        this.onClear(isClear);
    }
    @Output() selectedValue: EventEmitter<any> = new EventEmitter;
    activityTypeList: any = [];
    selectedActivityType: any = [];

    constructor() {
    }

    ngOnInit() {
         this.activityTypeList = ActivityType;
         const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
         this.selectedActivityType = (preselected && preselected['activityType']) ? preselected['activityType'] : '';
    }

    onChange() {
        this.selectedValue.emit(this.selectedActivityType);
    }

    onClear(isClear) {
        this.selectedActivityType = [];
        if (isClear) {
            this.selectedValue.emit([]);
        }
    }
}
