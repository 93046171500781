import { MatPaginatorModule } from '@angular/material/paginator';
import { ServiceManagerFeedItemComponent } from './service-manager-feed-item/service-manager-feed-item.component';
import { ServiceManagerFeedListComponent } from './service-manager-feed-list/service-manager-feed-list.component';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { PipeModule } from './../../pipe/pipe.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FeedComponent } from './feed.component';
import { FeedDetailComponent } from './feed-detail/feed-detail.component';
import { JobFeedListComponent } from './job-feed-list/job-feed-list.component';
import { FeedListDetailComponent } from './feed-list-detail/feed-list-detail.component';
@NgModule({
    declarations: [
        FeedComponent,
        FeedDetailComponent,
        JobFeedListComponent,
        FeedListDetailComponent,
        ServiceManagerFeedItemComponent,
        ServiceManagerFeedListComponent
    ],
    imports: [
        CommonModule,
        PipeModule,
        NgxDatatableModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule.forRoot(),
        RouterModule,
        MatPaginatorModule
    ],
    exports: [
        FeedComponent,
        FeedDetailComponent,
        JobFeedListComponent,
        FeedListDetailComponent,
        ServiceManagerFeedItemComponent,
        ServiceManagerFeedListComponent
    ],
    schemas: []
})

export class FeedModule { }
