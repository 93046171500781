import { CommonModule } from "@angular/common";
import { JobStatusUpdateTrackerComponent } from "./job-progress-update-tracker.component";
import { NgModule } from "@angular/core";

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        JobStatusUpdateTrackerComponent
    ],
    exports: [
        JobStatusUpdateTrackerComponent
    ]
})
export class JobStatusUpdateTrackerModule { }