import { ModalService } from 'shared/services/modal.service';
import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, OnChanges, AfterViewInit, OnDestroy } from '@angular/core';
import { JobApi } from '../../sdk';
import { CommonService } from 'shared/services/common.service';
import { AlertService } from 'shared/services/alert.service';
import { PreloaderService } from 'shared/services/preloader.service';
import { MapService } from 'shared/services/map.service';
import { Subscription } from 'rxjs';
import { AppStateService } from 'shared/services/app-state.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'environments/environment';
import * as L from 'leaflet';
import 'leaflet-routing-machine';
@Component({
    selector: 'app-job-detail-sidemenu',
    templateUrl: './job-detail-sidemenu.component.html',
    styleUrls: ['./job-detail-sidemenu.component.css'],
    providers: [MapService]
})
export class JobDetailSideMenuComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

    @Input()
    set record(e) {
        this.job = {};
        if (e && e.modelId && e.modelName === 'Job') {
            this.jobId = e.modelId;
        }
    }
    @Input() jobObj?: any;
    @Output() changeTab: EventEmitter<string> = new EventEmitter;
    jobId: string = '';
    job: any = {};
    jobTitle = '';
    timeZone = '';
    loadingIndicator = false;
    direction: any;
    isFTEJob: Boolean = false;
    // Accordian open close
    cVistPlanAccordian: Boolean = true;
    contactInfoAccordian: Boolean = true;
    privateInfoAccordian: Boolean = true;
    isPublic: Boolean = false;
    Customer_Appointment_DateTime_Updated :any;
    Customer_Appointment_Scheduled : any;

    roleSub: Subscription;
    userRole: any;
    zoomControlOptions = {
        position: 'TOP_RIGHT'
    }
    streetViewControlOptions = {
        position: 'TOP_LEFT'
    }
    mapTypeControlOptions = {
        position: 'BOTTOM_RIGHT'
    }
    // Customer details
    customerContactDetial: any = {};
    @ViewChild('map2') mapContainer: ElementRef;
    map2: any;
    routeControl: any;
    maplatLng: any;
    osmMapKey: any;
    constructor(
        private _route: ActivatedRoute,
        private _jobApi: JobApi,
        private _commonService: CommonService,
        private _alertService: AlertService,
        private _preloaderService: PreloaderService,
        private mapService: MapService,
        private _appState: AppStateService,
        private _modalService: ModalService

    ) {
        this.mapService.direction.subscribe(data => {
            this.direction = data;
        });
        if (this._route.snapshot.data['accessType'] && this._route.snapshot.data['accessType'] === 'public') {
            this.isPublic = true;
        }
    }
    ngOnInit() { }

    ngOnChanges() {
        this.roleSub = this._appState.getJobDetailUserRole().subscribe(role => {
            if (role && role['userRole']) {
                this.userRole = role['userRole'];
            }
            if (this.jobObj) {
                this.prepareJobResult(JSON.parse(this.jobObj));
            } else {
                if(this.jobId){
                    this.loadData(this.jobId);
                }
                
            }

            if (this.map2) {
                this.loadmap();
            }
        })

    }

    ngAfterViewInit() {
        this.osmMapKey = JSON.parse(localStorage.getItem('osmMapObjKey')).webApiKey;
        if (this.job && this.job.vendorsite) {
            this.maplatLng = {
                origin: { lat: this.job.vendorsite.geolocation__Latitude__s, lng: this.job.vendorsite.geolocation__Longitude__s },
                destination: { lat: this.job.jobsite.geolocation__Latitude__s, lng: this.job.jobsite.geolocation__Longitude__s }
            }
            if (this.job && this.job.worker && this.job.worker.contact && this.job.worker.contact.Geo_Location__Latitude__s) {
                // tslint:disable-next-line:max-line-length
                this.maplatLng['origin'] = {lat: this.job.worker.contact.Geo_Location__Latitude__s, lng: this.job.worker.contact.Geo_Location__Longitude__s}
            }
            this.loadmap();
        }
    }

    loadData(jobId) {
        this.loadingIndicator = true;
        this._preloaderService.showPreloader();
        this.job = {};
        const reqObj = {
            'fields': ['sfdcId', 'id', 'Iron_Job_num__c', 'Name', 'Vendorsite__c', 'workflowId', 'workflowStatusId', 'Work_Order__c',
                'CKSW_BASE__Account__c', 'RecordTypeId', 'isCancelled', 'Dispatch_Service_Resolution_Status__c', 'Vendor__c',
                'Customer_Service_Type_From_Program__c', 'Technical_Level__c', 'Service_Dispatch_SLA_Priority_FrmProgram__c',
                'acknowledge_instruction', 'pre_visit_checklist', 'Deliverable_Status__c', 'submittedTimecard', 'FileCounter',
                'Dispatch_Worker_Name__c', 'Dispatch_Worker_Name_Text__c', 'Dispatch_Worker_Phone__c', 'Case__c', 'CreatedDate',
                'CKSW_BASE__City__c', 'CKSW_BASE__Description__c', 'Project_SOP__c', 'CKSW_BASE__State__c', 'Jobsite__c', 'ICC_Case__c',
                'Service_Dispatch_SLA_Priority__c', 'Job_Status_Internal__c', 'systemTimecard', 'statusTracker'
                , 'Jobsite_Contact_Technical_Escalation__c', 'Jobsite_Contact_SDesk_Name_phone_email__c', 'Final_Timecard_Id',
                'Worker_Arrival_DateTime_Scheduled__c', 'Worker_End_DateTime_Scheduled__c', 'Final_Timecard_Submitted__c',
                'Is_Final_Timecard_Submitted'
            ],
            'where': { 'id': jobId },
            'include': [
                {
                    'relation': 'vendor',
                    'scope': {
                        'fields': ['sfdcId', 'Name']
                    }
                },
                {
                    'relation': 'worker',
                    'scope': {
                        'fields': ['sfdcId', 'Name', 'Worker_Type__c', 'Email__c', 'Contact__c', 'Country_Code__c',
                            'RecordTypeId', 'Work_Phone_Number__c'],
                        'include': [
                            {
                                'relation': 'user',
                                'scope': {
                                    'fields': ['id', 'sfdcId', 'email', 'firstname', 'gender', 'lastname', 'profileImage', 'url',
                                        'accessType']
                                }
                            },
                            {
                                'relation': 'contact',
                                'scope': {
                                    'fields': ['id', 'sfdcId', 'Title', 'MailingCountry', 'Phone', 'MailingCity', 'MailingState',
                                    'MailingPostalCode',	'MailingStreet', 'Geo_Location__Longitude__s', 'Geo_Location__Latitude__s']
                                }
                            }
                        ]
                    }
                },
                {
                    'relation': 'jobsite',
                    'scope': {
                        'fields': ['sfdcId', 'Name', 'geolocation__Latitude__s', 'geolocation__Longitude__s', 'City__c',
                            'Country__c', 'Street__c', 'Zip__c', 'Time_Zone__c', 'Abbreviation__c']
                    }
                },
                {
                    'relation': 'vendorsite',
                    'scope': {
                        'fields': ['sfdcId', 'Name', 'geolocation__Latitude__s', 'geolocation__Longitude__s', 'Street__c', 'City__c',
                            'State__c', 'Zip_Postal_Code__c', 'Country__c']
                    }
                },
                {
                    'relation': 'appointment',
                    'scope': {
                        'fields': [
                            'Customer_Appointment_Setup_Required__c',
                            'Customer_Appointment_DateTime_Scheduled__c',
                            'Worker_Arrival_DateTime_Cust_Requested__c',
                            'Worker_Arrival_Date_Customer_Req_End__c',
                            'Worker_Arrival_DateTime_Scheduled__c',
                            'Worker_Arrival_Date_Time_Actual__c',
                            'Customer_Appointment_Start_Scheduled__c',
                            'Customer_Appointment_Start_HRS_Scheduled__c',
                            'Customer_Apptmnt_Start_Minutes_Scheduled__c',
                            'Earliest_Start_Date_Permitted__c',
                            'Early_Start__c',
                            'Worker_Apptmnt_Customer_Approval_Status__c']
                    }
                },
                {
                    'relation': 'program',
                    'scope': { 'fields': ['Name'] },
                },
                {
                    'relation': 'partner',
                    'scope': {
                        'fields': ['sfdcId', 'Name']
                    }
                },
                {
                    'relation': 'case',
                    'scope': {
                        'fields': ['sfdcId', 'Job_Order__c', 'Partner_Case_Number__c', 'Talent_Type_ref__c',
                            'Coverage_Hours__c', 'PPE_Hours__c'],
                        'include': [
                            {
                                'relation': 'workOrder',
                                'scope': {
                                    'fields': ['sfdcId', 'Name']
                                }
                            },
                            {
                                'relation': 'talentType',
                                'scope': { 'fields': ['Talent_Type_Name__c'] }
                            }
                        ]
                    }
                }
            ]
        };

        this._jobApi.getJobDetailsById(reqObj).subscribe(result => {
            this.prepareJobResult(result);
        }, err => {
            this._alertService.error('Something went wrong!');
            this._preloaderService.hidePreloader();
            this.loadingIndicator = false;
            console.log(err.message);
        });
    }
    prepareJobResult(result) {
        if (result && result.id) {
            this.job = result;
            this.isFTEJob = result.RecordTypeId && result.RecordTypeId === '0121a0000006QniAAE' ? true : false;
            const timeFormat = 'L, LT z';
            this.job.TimeAgo = '';
            this.job.StartsIn = '';
            this.job.WokerStartsIn = '';
            if (this.job.Jobsite_Contact_SDesk_Name_phone_email__c) {
                const contactDetial = this.job.Jobsite_Contact_SDesk_Name_phone_email__c.split(';')
                this.customerContactDetial.name = typeof contactDetial[0] !== 'undefined' ? contactDetial[0] : '';
                this.customerContactDetial.phone = typeof contactDetial[1] !== 'undefined' ? contactDetial[1] : '';
                this.customerContactDetial.email = typeof contactDetial[2] !== 'undefined' ? contactDetial[2] : '';
            }

            this.jobTitle = this.job.Iron_Job_num__c + ' (' + this.job.Dispatch_Service_Resolution_Status__c + ')';
            // prepare jobsite data
            if (this.job.jobsite) {
                this.timeZone = this.job.jobsite.Time_Zone__c ? this.job.jobsite.Time_Zone__c : '';
                this.job.TimeAgo = this.job.CreatedDate;
                this.job.CreatedDate = this._commonService.dateFormate(this.job.CreatedDate, this.timeZone, timeFormat);
                this.job['jobsiteLocation'] = this.job.jobsite.Street__c + this.comma(this.job.CKSW_BASE__City__c) +
                    this.comma(this.job.CKSW_BASE__State__c) + this.comma(this.job.jobsite.Zip__c) +
                    this.comma(this.job.CKSW_BASE__Country__c);
            }
            // prepare appointment data
            if (this.job.appointment) {
                this.job.StartsIn = this.job.appointment.Customer_Appointment_DateTime_Scheduled__c;
                this.job.appointment.Early_Start__c = this._commonService.dateFormate(
                    this.job.appointment.Early_Start__c, this.timeZone, timeFormat);
                if (this.job.case && this.job.case.PPE_Hours__c) {
                    if (this.job.appointment.Worker_Arrival_DateTime_Cust_Requested__c) {
                        this.job.WokerStartsIn = this.job.appointment.Worker_Arrival_DateTime_Cust_Requested__c;
                        this.job.appointment.Worker_Arrival_Date_Customer_Req_End__c = this._commonService.addTime(
                            this.job.appointment.Worker_Arrival_DateTime_Cust_Requested__c, this.job.case.PPE_Hours__c, 'h');
                    }
                    if (this.job.appointment.Customer_Appointment_DateTime_Scheduled__c) {
                        this.job.appointment.Customer_Appointment_End_DateTime = this._commonService.addTime(
                            this.job.appointment.Customer_Appointment_DateTime_Scheduled__c, this.job.case.PPE_Hours__c, 'h');
                    }
                }
                 
                this.job.appointment.Worker_Arrival_DateTime_Cust_Requested__c = this._commonService.dateFormate(
                    this.job.appointment.Worker_Arrival_DateTime_Cust_Requested__c, this.timeZone, timeFormat);
                this.job.appointment.Customer_Appointment_DateTime_Scheduled__c = this._commonService.dateFormate(
                    this.job.appointment.Customer_Appointment_DateTime_Scheduled__c, this.timeZone, timeFormat);
                this.job.appointment.Worker_Arrival_Date_Customer_Req_End__c = this._commonService.dateFormate(
                    this.job.appointment.Worker_Arrival_Date_Customer_Req_End__c, this.timeZone, timeFormat);
                this.job.appointment.Customer_Appointment_End_DateTime = this._commonService.dateFormate(
                    this.job.appointment.Customer_Appointment_End_DateTime, this.timeZone, timeFormat);
                this.Customer_Appointment_DateTime_Updated= 
                    this._commonService.dateFormate(this.job.appointment.Worker_Arrival_DateTime_Scheduled__c, this.timeZone,timeFormat);
                this.Customer_Appointment_Scheduled=
                    this._commonService.dateFormate(this.job.appointment.Worker_Arrival_DateTime_Cust_Requested__c, this.timeZone,timeFormat);
                   
            }
            if (result.jobsite) {
                this.job.destination = result.jobsite.Street__c ? result.jobsite.Street__c : '';
                this.job.destination = this.job.destination ? this.job.destination + this.comma(result.jobsite.City__c) :
                    result.jobsite.City__c;
                this.job.destination = this.job.destination ? this.job.destination + this.comma(result.jobsite.State__c) :
                    result.jobsite.State__c;
                this.job.destination = this.job.destination ? this.job.destination + this.comma(result.jobsite.Zip_Postal_Code__c) :
                    result.jobsite.Zip_Postal_Code__c;
                this.job.destination = this.job.destination ? this.job.destination + this.comma(result.jobsite.Country__c) :
                    result.jobsite.Country__c;
            }
            if (this.job.worker && this.job.worker.contact && this.job.worker.contact.MailingCountry) {
                this.job.origin =
                    this.job.worker.contact.MailingStreet + ' ' +
                    this.job.worker.contact.MailingCity +
                    this.comma(this.job.worker.contact.MailingState) +
                    this.comma(this.job.worker.contact.MailingPostalCode) +
                    this.comma(this.job.worker.contact.MailingCountry);
            } else if (result.vendorsite) {
                this.job.origin = result.vendorsite.Street__c ? result.vendorsite.Street__c : '';
                this.job.origin = this.job.origin ? this.job.origin + this.comma(result.vendorsite.City__c) :
                    result.vendorsite.City__c;
                this.job.origin = this.job.origin ? this.job.origin + this.comma(result.vendorsite.State__c) :
                    result.vendorsite.State__c;
                this.job.origin = this.job.origin ? this.job.origin + this.comma(result.vendorsite.Zip_Postal_Code__c) :
                    result.vendorsite.Zip_Postal_Code__c;
                this.job.origin = this.job.origin ? this.job.origin + this.comma(result.vendorsite.Country__c) :
                    result.vendorsite.Country__c;
            }
            if (this.job.origin && this.job.destination) {
                this.mapService.setLocation(this.job.origin, this.job.destination)
            }
            // prepare workflow data
            this._preloaderService.hidePreloader();
            this.loadingIndicator = false;
        }
    }
    comma(prop) {
        return prop ? ', ' + prop : '';
    }
    refreshData(event) {
        this.loadData(this.job.id);
    }

    directionsChanged() {
        this.mapService.directionsChanged();
    }

    showDirection(event) {
        this.mapService.showDirection(event);
    }
    selectTab() {
        this.changeTab.emit('summary');
    }
    workflowUpdate(status) {
        this.job.workflowStatusId = status;
        this.changeTab.emit('refesh-job-detail');
        this.refreshData('');
    }

    initialiseMap(id) {
        // If map already initialised, then delete the instance
        let mapInstance = L.DomUtil.get(id);
        if (mapInstance != null) {
            mapInstance._leaflet_id = null;
        }
    }

    /**
     * load map in mapContainer
     */
    loadmap() {
        // check whether the view initialised or not
        const mapElement = document.getElementById('map2');
        if(!mapElement) return;

        // Before loading map, checking whether the map is already initialised or not.
        this.initialiseMap('map2');

        this.map2 = L.map('map2').setView(this.maplatLng.destination, 13);
        const url = this._commonService.fetchMapBoxTileLayerUrl();
        // tslint:disable-next-line:max-line-length
        L.tileLayer(url, {
            maxZoom: 19,
            // tslint:disable-next-line:max-line-length
            attribution: '&copy; <a href="javascrip:void(0)">OpenStreetMap</a>'
        }).addTo(this.map2);
        this.setWorkerCoordinate();
    }

    setWorkerCoordinate() {
        const _that = this;
        this.routeControl = L.Routing.control({
            lineOptions: {
                styles: [
                    { color: 'white', opacity: 0.9, weight: 8 },
                    { color: '#36baff', opacity: 1, weight: 6 }
                ]
            },
            waypoints: [
                { latLng: this.maplatLng.origin, name: 'worker', options: { allowUTurn: true } },
                { latLng: this.maplatLng.destination, name: 'Job', options: { allowUTurn: true } }
            ],
            units: 'imperial',
            showAlternatives: false,
            fitSelectedRoutes: true,
            waypointMode: 'snap',
            addWaypoints: false,
            routeWhileDragging: false,
            router: L.Routing.mapbox(_that.osmMapKey),
            createMarker: function (i, wp) {
                if (i === 0) {
                    return L.marker(wp.latLng, {
                        icon: L.icon({
                            iconUrl: 'assets/img/map/blue-pointer.png',
                            iconSize: [23, 38],
                            iconAnchor: [13, 38]
                        })
                    })
                } else {
                    return L.marker(wp.latLng, {
                        icon: L.icon({
                            iconUrl: 'assets/img/map/default-icon.png',
                            iconSize: [25, 39],
                            iconAnchor: [12, 38]
                        })
                    })
                }
            },
        }).addTo(this.map2);
        this.routeControl._container.style.display = 'None';
    }

    openWorkerTracker(template) {
        this._modalService.open(template, 'xxxl');
    }

    ngOnDestroy() {
        this.map2 = undefined;
        this.routeControl = undefined;
    }
}
