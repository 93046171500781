import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { includeTestAccount } from '../../../models/static-list-data.service'

@Component({
  selector: 'app-include-test-account-lookup',
  templateUrl: './include-test-account-lookup.component.html',
  styleUrls: ['./include-test-account-lookup.component.css']
})
export class IncludeTestAccountLookupComponent implements OnInit {

  includeTestAccountList = [];
  includeTestAccountSelected = [];
  isMultipleSelected = true;

  @Input() set resetData(isClear) {
    if (isClear) {
      this.includeTestAccountSelected = [];
    }
  }
  @Input() maxSelectedItems = 1;
  @Input() selectedDefaultUser;
  @Input() from;

  @Output() selectedincludeTestAccount: EventEmitter<any> = new EventEmitter;
  constructor() { }

  ngOnInit() {
    this.includeTestAccountList = [...includeTestAccount];
    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : ''
    this.includeTestAccountSelected = (preselected && preselected['includeTestAccount']) ? preselected['includeTestAccount'] : ['false'];
  }

  onincludeTestAccountChange() {
    this.selectedincludeTestAccount.emit(this.includeTestAccountSelected);
  }

  clearincludeTestAccount() {
    this.includeTestAccountSelected = [];
    this.selectedincludeTestAccount.emit([]);
  }



}
