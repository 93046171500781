import { Component, Input, OnInit } from '@angular/core';
import { FavoriteApi } from './../../sdk/services/custom/Favorite';
import { PreloaderService } from './../../services/preloader.service';
import { ModalService } from './../../services/modal.service';
@Component({
  selector: 'app-rhs-message-console-list',
  templateUrl: './rhs-message-console-list.component.html',
  styleUrls: ['./rhs-message-console-list.component.css']
})
export class RhsMessageConsoleListComponent implements OnInit {
  @Input() jobIds;

  selectedVMSJob: any;
  constructor(  private _modalService: ModalService,
    private _loader: PreloaderService,
    private _favoriteApi: FavoriteApi
    ) {
   }

  ngOnInit() {
  }


  /**
   * On selection we need to opn a popup
   * @param e Select card data object
   */
  onSelectedChange(e, modal) {
    console.log('SelectedRes>>>', e);
    if (e) {
      this.selectedVMSJob = e;
      this._modalService.open(modal, 'xl');
    }
  }
 
  /**
   *
   * @param e update fav object
   */
  onfavChange(e) {
    if (e.favoriteId) {
      this.deleteFavorite(e);
    } else {
      this.addFavorite(e);
    }
  }

  addFavorite(item) {
    this._loader.showPreloader();
    this._favoriteApi.upsert({
      modelId: item.modelId,
      modelName: item.modelName
    }).subscribe(
      result => {
        this._loader.hidePreloader();
        if (result && result['id']) {
          item.favoriteId = result['id'];
        }
      }, err => {
        this._loader.hidePreloader();
      }
    );
  }

  deleteFavorite(item) {
    this._loader.showPreloader();
    this._favoriteApi.deleteById(item.favoriteId).subscribe(
      result => {
        this._loader.hidePreloader();
        if (result && result['count']) {
          item.favoriteId = '';
        }
      }, err => {
        this._loader.hidePreloader();
      }
    );
  }
}
