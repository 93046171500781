/* tslint:disable */
import {
  Product,
  WorkOrder,
  TalentType,
  Account,
  PricelistItem,
  Jobsite,
  Job,
  Case,
  RecordType
} from '../index';

declare var Object: any;
export interface JobOrderItemInterface {
  "sfdcId"?: string;
  "Asset_Serial_Number__c"?: string;
  "Asset_Software_Revision__c"?: string;
  "Asset__c"?: string;
  "Auto_Replenishment__c"?: string;
  "Available_Stock__c"?: number;
  "B2B_Flow_Status__c"?: string;
  "B2B_ICC_Case_Created__c"?: boolean;
  "Cloned__c"?: boolean;
  "Contract_Line_Item_Ref__c"?: string;
  "CreatedById"?: string;
  "CreatedDate"?: Date;
  "Created_Automatically__c"?: boolean;
  "CurrencyIsoCode"?: string;
  "Custom_List_Price_Partner__c"?: number;
  "Custom_List_Price_Vendor__c"?: number;
  "Custom_Monthly_Price_Partner__c"?: number;
  "Custom_Monthly_Price_Vendor__c"?: number;
  "Custom_Partner_Price_on_SC__c"?: boolean;
  "Custom_Price_Version_Partner__c"?: string;
  "Custom_Price_Version_Vendor__c"?: string;
  "Custom_Vendor_Price_on_SC__c"?: boolean;
  "Customer_PO_Date__c"?: Date;
  "Customer_PO_Line__c"?: string;
  "Customer_Pricelist_Auto__c"?: string;
  "Customer_SO_Number__c"?: string;
  "Defective_Item_New_SN__c"?: string;
  "Defective_Item_Original_SN__c"?: string;
  "Description__c"?: string;
  "Dispatch_FRU__c"?: boolean;
  "Dispatch_Status__c"?: string;
  "FRU_Description_New_Text__c"?: string;
  "FRU_Description_Text__c"?: string;
  "FRU_Group_Name_Text__c"?: string;
  "FRU_Notes_Internal__c"?: string;
  "FRU_SKU_Capture_Option__c"?: string;
  "FTE_Unit_Count_Service_Contract__c"?: number;
  "FTE_Utilization_Monthly_Factor__c"?: string;
  "Has_Jobsite__c"?: boolean;
  "Has_Product_TT_JS_on_Item__c"?: boolean;
  "Has_Product__c"?: boolean;
  "Has_Talent_Type__c"?: boolean;
  "Health_Vendor_Pricelist_Status__c"?: string;
  "ICC_Case__c"?: string;
  "IRON_FRU_SKU_New_Text__c"?: string;
  "IRON_FRU_SKU_New__c"?: string;
  "IRON_FRU_SKU_New_lkp__c"?: string;
  "IRON_FRU_SKU_Original__c"?: string;
  "IRON_FRU_SKU_Text__c"?: string;
  "InActive__c"?: boolean;
  "Iron_FRU_SKU_Channged_Value__c"?: string;
  "Iron_Product_Type__c"?: string;
  "IsDeleted"?: boolean;
  "Item_Code__c"?: string;
  "Job_Order_Item_Name__c"?: string;
  "Job_Order__c"?: string;
  "Job__c"?: string;
  "Jobsite_Selection_Status__c"?: string;
  "Jobsite__c"?: string;
  "Kit_Item__c"?: string;
  "LastModifiedById"?: string;
  "LastModifiedDate"?: Date;
  "List_Price_Customer_Monthly__c"?: number;
  "List_Price_Customer__c"?: number;
  "List_Price_Vendor_Monthly__c"?: number;
  "List_Price_Vendor__c"?: number;
  "Manufacture_MPN_Text_New__c"?: string;
  "Manufacture_MPN_Text__c"?: string;
  "Model_Number__c"?: string;
  "Name"?: string;
  "New_MAC_Number__c"?: string;
  "Order_Quantity_FTE_Hours__c"?: number;
  "Order_Quantity__c"?: number;
  "Overall_Health_check__c"?: boolean;
  "PMC_Case__c"?: string;
  "PO_Line_Number__c"?: string;
  "Parent_Work_Order_Item__c"?: string;
  "Partner_Account__c"?: string;
  "Partner_Currency__c"?: string;
  "Partner_FRU_SKU_PN_Original_Text__c"?: string;
  "Partner_FRU_Sku_New__c"?: string;
  "Partner_FRU_Sku__c"?: string;
  "Partner_Manage_Inventory__c"?: boolean;
  "Primary_Contact_Email__c"?: string;
  "Primary_Contact_Name__c"?: string;
  "Primary_Contact_Phone__c"?: string;
  "Primary_Worker__c"?: string;
  "Product_Record_Type__c"?: string;
  "Product_Request_Line_Item__c"?: string;
  "Product_Routing_Enabled__c"?: string;
  "Rack_Kit_Top_of_Switch__c"?: string;
  "Rack_Serial__c"?: string;
  "Rack_Side__c"?: string;
  "Rack_Sub_Position_Slot__c"?: string;
  "Rack_Sub_Position__c"?: string;
  "Rack__c"?: string;
  "RecordTypeId"?: string;
  "Recordtypeid__c"?: string;
  "Reference_Number__c"?: string;
  "Replacement_New_SKU_Same_as_Original__c"?: boolean;
  "Return_Order_Type__c"?: string;
  "Routable_Auto_From_Product__c"?: string;
  "Row__c"?: string;
  "SKU__c"?: string;
  "Secondary_Worker__c"?: string;
  "Service_Billing_Health_check_Status__c"?: string;
  "Service_Billing_Status__c"?: boolean;
  "Service_Contract_duration_Months__c"?: number;
  "Service_Dispatch_Status__c"?: string;
  "Standard_List_Price_Customer_Auto__c"?: number;
  "Standard_List_Price_Vendor_Auto__c"?: number;
  "Standard_Quantity_Auto__c"?: number;
  "Suite__c"?: string;
  "SystemModstamp"?: Date;
  "Talent_Type__c"?: string;
  "Text_Product_NON_SKU__c"?: boolean;
  "Total_Cost_Customer_Pre_Tax_Auto_FTE__c"?: number;
  "Total_Cost_Customer_Pre_Tax_FTE_Hourly__c"?: number;
  "Total_Cost_Vendor_Pre_Tax_Auto_FTE__c"?: number;
  "Total_Cost_Vendor_Pre_Tax_Auto__c"?: number;
  "Total_Cost_Vendor_Pre_Tax_FTE_Hourly__c"?: number;
  "Total_Price_Customer_Pre_Tax_Auto__c"?: number;
  "Vendor_Currency__c"?: string;
  "Vendor_List_Price_Status__c"?: string;
  "Vendor_Pricelist_Auto__c"?: string;
  "Vendor_Selection_Status__c"?: string;
  "Vendor__c"?: string;
  "Worker_Available__c"?: boolean;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  Product?: Product;
  WorkOrder?: WorkOrder;
  TalentType?: TalentType;
  vendor?: Account;
  CustomerPricelistItem?: PricelistItem;
  VendorPricelistItem?: PricelistItem;
  jobsite?: Jobsite;
  job?: Job;
  IccCase?: Case;
  RecordType?: RecordType;
}

export class JobOrderItem implements JobOrderItemInterface {
  "sfdcId": string;
  "Asset_Serial_Number__c": string;
  "Asset_Software_Revision__c": string;
  "Asset__c": string;
  "Auto_Replenishment__c": string;
  "Available_Stock__c": number;
  "B2B_Flow_Status__c": string;
  "B2B_ICC_Case_Created__c": boolean;
  "Cloned__c": boolean;
  "Contract_Line_Item_Ref__c": string;
  "CreatedById": string;
  "CreatedDate": Date;
  "Created_Automatically__c": boolean;
  "CurrencyIsoCode": string;
  "Custom_List_Price_Partner__c": number;
  "Custom_List_Price_Vendor__c": number;
  "Custom_Monthly_Price_Partner__c": number;
  "Custom_Monthly_Price_Vendor__c": number;
  "Custom_Partner_Price_on_SC__c": boolean;
  "Custom_Price_Version_Partner__c": string;
  "Custom_Price_Version_Vendor__c": string;
  "Custom_Vendor_Price_on_SC__c": boolean;
  "Customer_PO_Date__c": Date;
  "Customer_PO_Line__c": string;
  "Customer_Pricelist_Auto__c": string;
  "Customer_SO_Number__c": string;
  "Defective_Item_New_SN__c": string;
  "Defective_Item_Original_SN__c": string;
  "Description__c": string;
  "Dispatch_FRU__c": boolean;
  "Dispatch_Status__c": string;
  "FRU_Description_New_Text__c": string;
  "FRU_Description_Text__c": string;
  "FRU_Group_Name_Text__c": string;
  "FRU_Notes_Internal__c": string;
  "FRU_SKU_Capture_Option__c": string;
  "FTE_Unit_Count_Service_Contract__c": number;
  "FTE_Utilization_Monthly_Factor__c": string;
  "Has_Jobsite__c": boolean;
  "Has_Product_TT_JS_on_Item__c": boolean;
  "Has_Product__c": boolean;
  "Has_Talent_Type__c": boolean;
  "Health_Vendor_Pricelist_Status__c": string;
  "ICC_Case__c": string;
  "IRON_FRU_SKU_New_Text__c": string;
  "IRON_FRU_SKU_New__c": string;
  "IRON_FRU_SKU_New_lkp__c": string;
  "IRON_FRU_SKU_Original__c": string;
  "IRON_FRU_SKU_Text__c": string;
  "InActive__c": boolean;
  "Iron_FRU_SKU_Channged_Value__c": string;
  "Iron_Product_Type__c": string;
  "IsDeleted": boolean;
  "Item_Code__c": string;
  "Job_Order_Item_Name__c": string;
  "Job_Order__c": string;
  "Job__c": string;
  "Jobsite_Selection_Status__c": string;
  "Jobsite__c": string;
  "Kit_Item__c": string;
  "LastModifiedById": string;
  "LastModifiedDate": Date;
  "List_Price_Customer_Monthly__c": number;
  "List_Price_Customer__c": number;
  "List_Price_Vendor_Monthly__c": number;
  "List_Price_Vendor__c": number;
  "Manufacture_MPN_Text_New__c": string;
  "Manufacture_MPN_Text__c": string;
  "Model_Number__c": string;
  "Name": string;
  "New_MAC_Number__c": string;
  "Order_Quantity_FTE_Hours__c": number;
  "Order_Quantity__c": number;
  "Overall_Health_check__c": boolean;
  "PMC_Case__c": string;
  "PO_Line_Number__c": string;
  "Parent_Work_Order_Item__c": string;
  "Partner_Account__c": string;
  "Partner_Currency__c": string;
  "Partner_FRU_SKU_PN_Original_Text__c": string;
  "Partner_FRU_Sku_New__c": string;
  "Partner_FRU_Sku__c": string;
  "Partner_Manage_Inventory__c": boolean;
  "Primary_Contact_Email__c": string;
  "Primary_Contact_Name__c": string;
  "Primary_Contact_Phone__c": string;
  "Primary_Worker__c": string;
  "Product_Record_Type__c": string;
  "Product_Request_Line_Item__c": string;
  "Product_Routing_Enabled__c": string;
  "Rack_Kit_Top_of_Switch__c": string;
  "Rack_Serial__c": string;
  "Rack_Side__c": string;
  "Rack_Sub_Position_Slot__c": string;
  "Rack_Sub_Position__c": string;
  "Rack__c": string;
  "RecordTypeId": string;
  "Recordtypeid__c": string;
  "Reference_Number__c": string;
  "Replacement_New_SKU_Same_as_Original__c": boolean;
  "Return_Order_Type__c": string;
  "Routable_Auto_From_Product__c": string;
  "Row__c": string;
  "SKU__c": string;
  "Secondary_Worker__c": string;
  "Service_Billing_Health_check_Status__c": string;
  "Service_Billing_Status__c": boolean;
  "Service_Contract_duration_Months__c": number;
  "Service_Dispatch_Status__c": string;
  "Standard_List_Price_Customer_Auto__c": number;
  "Standard_List_Price_Vendor_Auto__c": number;
  "Standard_Quantity_Auto__c": number;
  "Suite__c": string;
  "SystemModstamp": Date;
  "Talent_Type__c": string;
  "Text_Product_NON_SKU__c": boolean;
  "Total_Cost_Customer_Pre_Tax_Auto_FTE__c": number;
  "Total_Cost_Customer_Pre_Tax_FTE_Hourly__c": number;
  "Total_Cost_Vendor_Pre_Tax_Auto_FTE__c": number;
  "Total_Cost_Vendor_Pre_Tax_Auto__c": number;
  "Total_Cost_Vendor_Pre_Tax_FTE_Hourly__c": number;
  "Total_Price_Customer_Pre_Tax_Auto__c": number;
  "Vendor_Currency__c": string;
  "Vendor_List_Price_Status__c": string;
  "Vendor_Pricelist_Auto__c": string;
  "Vendor_Selection_Status__c": string;
  "Vendor__c": string;
  "Worker_Available__c": boolean;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  Product: Product;
  WorkOrder: WorkOrder;
  TalentType: TalentType;
  vendor: Account;
  CustomerPricelistItem: PricelistItem;
  VendorPricelistItem: PricelistItem;
  jobsite: Jobsite;
  job: Job;
  IccCase: Case;
  RecordType: RecordType;
  constructor(data?: JobOrderItemInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `JobOrderItem`.
   */
  public static getModelName() {
    return "JobOrderItem";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of JobOrderItem for dynamic purposes.
  **/
  public static factory(data: JobOrderItemInterface): JobOrderItem{
    return new JobOrderItem(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'JobOrderItem',
      plural: 'JobOrderItems',
      path: 'JobOrderItems',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "Asset_Serial_Number__c": {
          name: 'Asset_Serial_Number__c',
          type: 'string'
        },
        "Asset_Software_Revision__c": {
          name: 'Asset_Software_Revision__c',
          type: 'string'
        },
        "Asset__c": {
          name: 'Asset__c',
          type: 'string'
        },
        "Auto_Replenishment__c": {
          name: 'Auto_Replenishment__c',
          type: 'string'
        },
        "Available_Stock__c": {
          name: 'Available_Stock__c',
          type: 'number'
        },
        "B2B_Flow_Status__c": {
          name: 'B2B_Flow_Status__c',
          type: 'string'
        },
        "B2B_ICC_Case_Created__c": {
          name: 'B2B_ICC_Case_Created__c',
          type: 'boolean',
          default: false
        },
        "Cloned__c": {
          name: 'Cloned__c',
          type: 'boolean',
          default: false
        },
        "Contract_Line_Item_Ref__c": {
          name: 'Contract_Line_Item_Ref__c',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "Created_Automatically__c": {
          name: 'Created_Automatically__c',
          type: 'boolean',
          default: false
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "Custom_List_Price_Partner__c": {
          name: 'Custom_List_Price_Partner__c',
          type: 'number'
        },
        "Custom_List_Price_Vendor__c": {
          name: 'Custom_List_Price_Vendor__c',
          type: 'number'
        },
        "Custom_Monthly_Price_Partner__c": {
          name: 'Custom_Monthly_Price_Partner__c',
          type: 'number'
        },
        "Custom_Monthly_Price_Vendor__c": {
          name: 'Custom_Monthly_Price_Vendor__c',
          type: 'number'
        },
        "Custom_Partner_Price_on_SC__c": {
          name: 'Custom_Partner_Price_on_SC__c',
          type: 'boolean',
          default: false
        },
        "Custom_Price_Version_Partner__c": {
          name: 'Custom_Price_Version_Partner__c',
          type: 'string'
        },
        "Custom_Price_Version_Vendor__c": {
          name: 'Custom_Price_Version_Vendor__c',
          type: 'string'
        },
        "Custom_Vendor_Price_on_SC__c": {
          name: 'Custom_Vendor_Price_on_SC__c',
          type: 'boolean',
          default: false
        },
        "Customer_PO_Date__c": {
          name: 'Customer_PO_Date__c',
          type: 'Date'
        },
        "Customer_PO_Line__c": {
          name: 'Customer_PO_Line__c',
          type: 'string'
        },
        "Customer_Pricelist_Auto__c": {
          name: 'Customer_Pricelist_Auto__c',
          type: 'string'
        },
        "Customer_SO_Number__c": {
          name: 'Customer_SO_Number__c',
          type: 'string'
        },
        "Defective_Item_New_SN__c": {
          name: 'Defective_Item_New_SN__c',
          type: 'string'
        },
        "Defective_Item_Original_SN__c": {
          name: 'Defective_Item_Original_SN__c',
          type: 'string'
        },
        "Description__c": {
          name: 'Description__c',
          type: 'string'
        },
        "Dispatch_FRU__c": {
          name: 'Dispatch_FRU__c',
          type: 'boolean',
          default: false
        },
        "Dispatch_Status__c": {
          name: 'Dispatch_Status__c',
          type: 'string'
        },
        "FRU_Description_New_Text__c": {
          name: 'FRU_Description_New_Text__c',
          type: 'string'
        },
        "FRU_Description_Text__c": {
          name: 'FRU_Description_Text__c',
          type: 'string'
        },
        "FRU_Group_Name_Text__c": {
          name: 'FRU_Group_Name_Text__c',
          type: 'string'
        },
        "FRU_Notes_Internal__c": {
          name: 'FRU_Notes_Internal__c',
          type: 'string'
        },
        "FRU_SKU_Capture_Option__c": {
          name: 'FRU_SKU_Capture_Option__c',
          type: 'string'
        },
        "FTE_Unit_Count_Service_Contract__c": {
          name: 'FTE_Unit_Count_Service_Contract__c',
          type: 'number'
        },
        "FTE_Utilization_Monthly_Factor__c": {
          name: 'FTE_Utilization_Monthly_Factor__c',
          type: 'string'
        },
        "Has_Jobsite__c": {
          name: 'Has_Jobsite__c',
          type: 'boolean',
          default: false
        },
        "Has_Product_TT_JS_on_Item__c": {
          name: 'Has_Product_TT_JS_on_Item__c',
          type: 'boolean',
          default: false
        },
        "Has_Product__c": {
          name: 'Has_Product__c',
          type: 'boolean',
          default: false
        },
        "Has_Talent_Type__c": {
          name: 'Has_Talent_Type__c',
          type: 'boolean',
          default: false
        },
        "Health_Vendor_Pricelist_Status__c": {
          name: 'Health_Vendor_Pricelist_Status__c',
          type: 'string'
        },
        "ICC_Case__c": {
          name: 'ICC_Case__c',
          type: 'string'
        },
        "IRON_FRU_SKU_New_Text__c": {
          name: 'IRON_FRU_SKU_New_Text__c',
          type: 'string'
        },
        "IRON_FRU_SKU_New__c": {
          name: 'IRON_FRU_SKU_New__c',
          type: 'string'
        },
        "IRON_FRU_SKU_New_lkp__c": {
          name: 'IRON_FRU_SKU_New_lkp__c',
          type: 'string'
        },
        "IRON_FRU_SKU_Original__c": {
          name: 'IRON_FRU_SKU_Original__c',
          type: 'string'
        },
        "IRON_FRU_SKU_Text__c": {
          name: 'IRON_FRU_SKU_Text__c',
          type: 'string'
        },
        "InActive__c": {
          name: 'InActive__c',
          type: 'boolean',
          default: false
        },
        "Iron_FRU_SKU_Channged_Value__c": {
          name: 'Iron_FRU_SKU_Channged_Value__c',
          type: 'string'
        },
        "Iron_Product_Type__c": {
          name: 'Iron_Product_Type__c',
          type: 'string'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean',
          default: false
        },
        "Item_Code__c": {
          name: 'Item_Code__c',
          type: 'string'
        },
        "Job_Order_Item_Name__c": {
          name: 'Job_Order_Item_Name__c',
          type: 'string'
        },
        "Job_Order__c": {
          name: 'Job_Order__c',
          type: 'string'
        },
        "Job__c": {
          name: 'Job__c',
          type: 'string'
        },
        "Jobsite_Selection_Status__c": {
          name: 'Jobsite_Selection_Status__c',
          type: 'string'
        },
        "Jobsite__c": {
          name: 'Jobsite__c',
          type: 'string'
        },
        "Kit_Item__c": {
          name: 'Kit_Item__c',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "List_Price_Customer_Monthly__c": {
          name: 'List_Price_Customer_Monthly__c',
          type: 'number'
        },
        "List_Price_Customer__c": {
          name: 'List_Price_Customer__c',
          type: 'number'
        },
        "List_Price_Vendor_Monthly__c": {
          name: 'List_Price_Vendor_Monthly__c',
          type: 'number'
        },
        "List_Price_Vendor__c": {
          name: 'List_Price_Vendor__c',
          type: 'number'
        },
        "Manufacture_MPN_Text_New__c": {
          name: 'Manufacture_MPN_Text_New__c',
          type: 'string'
        },
        "Manufacture_MPN_Text__c": {
          name: 'Manufacture_MPN_Text__c',
          type: 'string'
        },
        "Model_Number__c": {
          name: 'Model_Number__c',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "New_MAC_Number__c": {
          name: 'New_MAC_Number__c',
          type: 'string'
        },
        "Order_Quantity_FTE_Hours__c": {
          name: 'Order_Quantity_FTE_Hours__c',
          type: 'number'
        },
        "Order_Quantity__c": {
          name: 'Order_Quantity__c',
          type: 'number'
        },
        "Overall_Health_check__c": {
          name: 'Overall_Health_check__c',
          type: 'boolean',
          default: false
        },
        "PMC_Case__c": {
          name: 'PMC_Case__c',
          type: 'string'
        },
        "PO_Line_Number__c": {
          name: 'PO_Line_Number__c',
          type: 'string'
        },
        "Parent_Work_Order_Item__c": {
          name: 'Parent_Work_Order_Item__c',
          type: 'string'
        },
        "Partner_Account__c": {
          name: 'Partner_Account__c',
          type: 'string'
        },
        "Partner_Currency__c": {
          name: 'Partner_Currency__c',
          type: 'string'
        },
        "Partner_FRU_SKU_PN_Original_Text__c": {
          name: 'Partner_FRU_SKU_PN_Original_Text__c',
          type: 'string'
        },
        "Partner_FRU_Sku_New__c": {
          name: 'Partner_FRU_Sku_New__c',
          type: 'string'
        },
        "Partner_FRU_Sku__c": {
          name: 'Partner_FRU_Sku__c',
          type: 'string'
        },
        "Partner_Manage_Inventory__c": {
          name: 'Partner_Manage_Inventory__c',
          type: 'boolean',
          default: false
        },
        "Primary_Contact_Email__c": {
          name: 'Primary_Contact_Email__c',
          type: 'string'
        },
        "Primary_Contact_Name__c": {
          name: 'Primary_Contact_Name__c',
          type: 'string'
        },
        "Primary_Contact_Phone__c": {
          name: 'Primary_Contact_Phone__c',
          type: 'string'
        },
        "Primary_Worker__c": {
          name: 'Primary_Worker__c',
          type: 'string'
        },
        "Product_Record_Type__c": {
          name: 'Product_Record_Type__c',
          type: 'string'
        },
        "Product_Request_Line_Item__c": {
          name: 'Product_Request_Line_Item__c',
          type: 'string'
        },
        "Product_Routing_Enabled__c": {
          name: 'Product_Routing_Enabled__c',
          type: 'string'
        },
        "Rack_Kit_Top_of_Switch__c": {
          name: 'Rack_Kit_Top_of_Switch__c',
          type: 'string'
        },
        "Rack_Serial__c": {
          name: 'Rack_Serial__c',
          type: 'string'
        },
        "Rack_Side__c": {
          name: 'Rack_Side__c',
          type: 'string'
        },
        "Rack_Sub_Position_Slot__c": {
          name: 'Rack_Sub_Position_Slot__c',
          type: 'string'
        },
        "Rack_Sub_Position__c": {
          name: 'Rack_Sub_Position__c',
          type: 'string'
        },
        "Rack__c": {
          name: 'Rack__c',
          type: 'string'
        },
        "RecordTypeId": {
          name: 'RecordTypeId',
          type: 'string'
        },
        "Recordtypeid__c": {
          name: 'Recordtypeid__c',
          type: 'string'
        },
        "Reference_Number__c": {
          name: 'Reference_Number__c',
          type: 'string'
        },
        "Replacement_New_SKU_Same_as_Original__c": {
          name: 'Replacement_New_SKU_Same_as_Original__c',
          type: 'boolean'
        },
        "Return_Order_Type__c": {
          name: 'Return_Order_Type__c',
          type: 'string'
        },
        "Routable_Auto_From_Product__c": {
          name: 'Routable_Auto_From_Product__c',
          type: 'string'
        },
        "Row__c": {
          name: 'Row__c',
          type: 'string'
        },
        "SKU__c": {
          name: 'SKU__c',
          type: 'string'
        },
        "Secondary_Worker__c": {
          name: 'Secondary_Worker__c',
          type: 'string'
        },
        "Service_Billing_Health_check_Status__c": {
          name: 'Service_Billing_Health_check_Status__c',
          type: 'string'
        },
        "Service_Billing_Status__c": {
          name: 'Service_Billing_Status__c',
          type: 'boolean',
          default: false
        },
        "Service_Contract_duration_Months__c": {
          name: 'Service_Contract_duration_Months__c',
          type: 'number'
        },
        "Service_Dispatch_Status__c": {
          name: 'Service_Dispatch_Status__c',
          type: 'string'
        },
        "Standard_List_Price_Customer_Auto__c": {
          name: 'Standard_List_Price_Customer_Auto__c',
          type: 'number'
        },
        "Standard_List_Price_Vendor_Auto__c": {
          name: 'Standard_List_Price_Vendor_Auto__c',
          type: 'number'
        },
        "Standard_Quantity_Auto__c": {
          name: 'Standard_Quantity_Auto__c',
          type: 'number'
        },
        "Suite__c": {
          name: 'Suite__c',
          type: 'string'
        },
        "SystemModstamp": {
          name: 'SystemModstamp',
          type: 'Date'
        },
        "Talent_Type__c": {
          name: 'Talent_Type__c',
          type: 'string'
        },
        "Text_Product_NON_SKU__c": {
          name: 'Text_Product_NON_SKU__c',
          type: 'boolean'
        },
        "Total_Cost_Customer_Pre_Tax_Auto_FTE__c": {
          name: 'Total_Cost_Customer_Pre_Tax_Auto_FTE__c',
          type: 'number'
        },
        "Total_Cost_Customer_Pre_Tax_FTE_Hourly__c": {
          name: 'Total_Cost_Customer_Pre_Tax_FTE_Hourly__c',
          type: 'number'
        },
        "Total_Cost_Vendor_Pre_Tax_Auto_FTE__c": {
          name: 'Total_Cost_Vendor_Pre_Tax_Auto_FTE__c',
          type: 'number'
        },
        "Total_Cost_Vendor_Pre_Tax_Auto__c": {
          name: 'Total_Cost_Vendor_Pre_Tax_Auto__c',
          type: 'number'
        },
        "Total_Cost_Vendor_Pre_Tax_FTE_Hourly__c": {
          name: 'Total_Cost_Vendor_Pre_Tax_FTE_Hourly__c',
          type: 'number'
        },
        "Total_Price_Customer_Pre_Tax_Auto__c": {
          name: 'Total_Price_Customer_Pre_Tax_Auto__c',
          type: 'number'
        },
        "Vendor_Currency__c": {
          name: 'Vendor_Currency__c',
          type: 'string'
        },
        "Vendor_List_Price_Status__c": {
          name: 'Vendor_List_Price_Status__c',
          type: 'string'
        },
        "Vendor_Pricelist_Auto__c": {
          name: 'Vendor_Pricelist_Auto__c',
          type: 'string'
        },
        "Vendor_Selection_Status__c": {
          name: 'Vendor_Selection_Status__c',
          type: 'string'
        },
        "Vendor__c": {
          name: 'Vendor__c',
          type: 'string'
        },
        "Worker_Available__c": {
          name: 'Worker_Available__c',
          type: 'boolean',
          default: false
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        Product: {
          name: 'Product',
          type: 'Product',
          model: 'Product',
          relationType: 'belongsTo',
                  keyFrom: 'SKU__c',
          keyTo: 'sfdcId'
        },
        WorkOrder: {
          name: 'WorkOrder',
          type: 'WorkOrder',
          model: 'WorkOrder',
          relationType: 'belongsTo',
                  keyFrom: 'Job_Order__c',
          keyTo: 'sfdcId'
        },
        TalentType: {
          name: 'TalentType',
          type: 'TalentType',
          model: 'TalentType',
          relationType: 'belongsTo',
                  keyFrom: 'Talent_Type__c',
          keyTo: 'sfdcId'
        },
        vendor: {
          name: 'vendor',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'Vendor__c',
          keyTo: 'sfdcId'
        },
        CustomerPricelistItem: {
          name: 'CustomerPricelistItem',
          type: 'PricelistItem',
          model: 'PricelistItem',
          relationType: 'belongsTo',
                  keyFrom: 'Customer_Pricelist_Auto__c',
          keyTo: 'sfdcId'
        },
        VendorPricelistItem: {
          name: 'VendorPricelistItem',
          type: 'PricelistItem',
          model: 'PricelistItem',
          relationType: 'belongsTo',
                  keyFrom: 'Vendor_Pricelist_Auto__c',
          keyTo: 'sfdcId'
        },
        jobsite: {
          name: 'jobsite',
          type: 'Jobsite',
          model: 'Jobsite',
          relationType: 'belongsTo',
                  keyFrom: 'Jobsite__c',
          keyTo: 'sfdcId'
        },
        job: {
          name: 'job',
          type: 'Job',
          model: 'Job',
          relationType: 'belongsTo',
                  keyFrom: 'Job__c',
          keyTo: 'sfdcId'
        },
        IccCase: {
          name: 'IccCase',
          type: 'Case',
          model: 'Case',
          relationType: 'belongsTo',
                  keyFrom: 'ICC_Case__c',
          keyTo: 'sfdcId'
        },
        RecordType: {
          name: 'RecordType',
          type: 'RecordType',
          model: 'RecordType',
          relationType: 'belongsTo',
                  keyFrom: 'RecordTypeId',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
