/* tslint:disable */

declare var Object: any;
export interface ReferralInterface {
  "sfdcId"?: string;
  "ts2rb__Account__c"?: string;
  "CreatedById"?: string;
  "CurrencyIsoCode"?: string;
  "LastModifiedById"?: string;
  "Name"?: string;
  "ts2__application_lookup__c"?: string;
  "ts2__Applied_Date__c"?: Date;
  "ts2__expire_date__c"?: Date;
  "ts2__applied__c"?: boolean;
  "ts2__internal_referral__c"?: boolean;
  "ts2__job_order_id__c"?: string;
  "ts2__message_body__c"?: string;
  "ts2__p_contact_lookup__c"?: string;
  "ts2__p_email__c"?: string;
  "ts2__p_first_name__c"?: string;
  "ts2__p_last_name__c"?: string;
  "ts2__referral_link__c"?: string;
  "ts2__r_contact_lookup__c"?: string;
  "ts2__r_email__c"?: string;
  "ts2__feedback__c"?: string;
  "ts2__r_first_name__c"?: string;
  "ts2__r_last_name__c"?: string;
  "ts2__referral_type__c"?: string;
  "ts2__Converted_to_Contact__c"?: boolean;
  "ts2__Employee_Referral__c"?: boolean;
  "ts2__Job_Order__c"?: string;
  "ts2__Prospect_Contact__c"?: string;
  "ts2__Prospect_Email__c"?: string;
  "ts2__Referral_Message__c"?: string;
  "ts2__Referrer_Contact__c"?: string;
  "ts2__Referrer_Email__c"?: string;
  "ts2__Referrer_Name__c"?: string;
  "ts2__Source__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class Referral implements ReferralInterface {
  "sfdcId": string;
  "ts2rb__Account__c": string;
  "CreatedById": string;
  "CurrencyIsoCode": string;
  "LastModifiedById": string;
  "Name": string;
  "ts2__application_lookup__c": string;
  "ts2__Applied_Date__c": Date;
  "ts2__expire_date__c": Date;
  "ts2__applied__c": boolean;
  "ts2__internal_referral__c": boolean;
  "ts2__job_order_id__c": string;
  "ts2__message_body__c": string;
  "ts2__p_contact_lookup__c": string;
  "ts2__p_email__c": string;
  "ts2__p_first_name__c": string;
  "ts2__p_last_name__c": string;
  "ts2__referral_link__c": string;
  "ts2__r_contact_lookup__c": string;
  "ts2__r_email__c": string;
  "ts2__feedback__c": string;
  "ts2__r_first_name__c": string;
  "ts2__r_last_name__c": string;
  "ts2__referral_type__c": string;
  "ts2__Converted_to_Contact__c": boolean;
  "ts2__Employee_Referral__c": boolean;
  "ts2__Job_Order__c": string;
  "ts2__Prospect_Contact__c": string;
  "ts2__Prospect_Email__c": string;
  "ts2__Referral_Message__c": string;
  "ts2__Referrer_Contact__c": string;
  "ts2__Referrer_Email__c": string;
  "ts2__Referrer_Name__c": string;
  "ts2__Source__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: ReferralInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Referral`.
   */
  public static getModelName() {
    return "Referral";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Referral for dynamic purposes.
  **/
  public static factory(data: ReferralInterface): Referral{
    return new Referral(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Referral',
      plural: 'Referrals',
      path: 'Referrals',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "ts2rb__Account__c": {
          name: 'ts2rb__Account__c',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "ts2__application_lookup__c": {
          name: 'ts2__application_lookup__c',
          type: 'string'
        },
        "ts2__Applied_Date__c": {
          name: 'ts2__Applied_Date__c',
          type: 'Date'
        },
        "ts2__expire_date__c": {
          name: 'ts2__expire_date__c',
          type: 'Date'
        },
        "ts2__applied__c": {
          name: 'ts2__applied__c',
          type: 'boolean'
        },
        "ts2__internal_referral__c": {
          name: 'ts2__internal_referral__c',
          type: 'boolean'
        },
        "ts2__job_order_id__c": {
          name: 'ts2__job_order_id__c',
          type: 'string'
        },
        "ts2__message_body__c": {
          name: 'ts2__message_body__c',
          type: 'string'
        },
        "ts2__p_contact_lookup__c": {
          name: 'ts2__p_contact_lookup__c',
          type: 'string'
        },
        "ts2__p_email__c": {
          name: 'ts2__p_email__c',
          type: 'string'
        },
        "ts2__p_first_name__c": {
          name: 'ts2__p_first_name__c',
          type: 'string'
        },
        "ts2__p_last_name__c": {
          name: 'ts2__p_last_name__c',
          type: 'string'
        },
        "ts2__referral_link__c": {
          name: 'ts2__referral_link__c',
          type: 'string'
        },
        "ts2__r_contact_lookup__c": {
          name: 'ts2__r_contact_lookup__c',
          type: 'string'
        },
        "ts2__r_email__c": {
          name: 'ts2__r_email__c',
          type: 'string'
        },
        "ts2__feedback__c": {
          name: 'ts2__feedback__c',
          type: 'string'
        },
        "ts2__r_first_name__c": {
          name: 'ts2__r_first_name__c',
          type: 'string'
        },
        "ts2__r_last_name__c": {
          name: 'ts2__r_last_name__c',
          type: 'string'
        },
        "ts2__referral_type__c": {
          name: 'ts2__referral_type__c',
          type: 'string'
        },
        "ts2__Converted_to_Contact__c": {
          name: 'ts2__Converted_to_Contact__c',
          type: 'boolean'
        },
        "ts2__Employee_Referral__c": {
          name: 'ts2__Employee_Referral__c',
          type: 'boolean'
        },
        "ts2__Job_Order__c": {
          name: 'ts2__Job_Order__c',
          type: 'string'
        },
        "ts2__Prospect_Contact__c": {
          name: 'ts2__Prospect_Contact__c',
          type: 'string'
        },
        "ts2__Prospect_Email__c": {
          name: 'ts2__Prospect_Email__c',
          type: 'string'
        },
        "ts2__Referral_Message__c": {
          name: 'ts2__Referral_Message__c',
          type: 'string'
        },
        "ts2__Referrer_Contact__c": {
          name: 'ts2__Referrer_Contact__c',
          type: 'string'
        },
        "ts2__Referrer_Email__c": {
          name: 'ts2__Referrer_Email__c',
          type: 'string'
        },
        "ts2__Referrer_Name__c": {
          name: 'ts2__Referrer_Name__c',
          type: 'string'
        },
        "ts2__Source__c": {
          name: 'ts2__Source__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
