import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { teamTypes } from 'shared/models/static-list-data.service';

@Component({
  selector: 'app-team-type-lookup',
  templateUrl: './team-type-lookup.component.html',
  styleUrls: ['./team-type-lookup.component.css']
})
export class TeamTypeLookupComponent implements OnInit {
  teamTypeList = [];
  teamTypeListSelected = [];
  isMultipleSelected = true;

  @Input() set resetData(isClear) {
    if (isClear) {
      this.teamTypeListSelected = [];
    }
  }
  @Output() getTeamType: EventEmitter<any> = new EventEmitter;
  constructor() { }

  ngOnInit() {
    this.teamTypeList = teamTypes;
    const preselected = localStorage.getItem('filterObj') && JSON.parse(localStorage.getItem('filterObj'));
    this.teamTypeListSelected = (preselected && preselected['teamTypes']) ? preselected['teamTypes'] : [];
  }

  onTeamTypeChange() {
    this.getTeamType.emit(this.teamTypeListSelected);
  }

  clearTeamType() {
    this.teamTypeListSelected = [];
    this.getTeamType.emit([]);
  }

}
