import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { taskPriorityValues } from '../../../models/static-list-data.service'

@Component({
  selector: 'app-task-priority',
  templateUrl: './task-priority.component.html',
  styleUrls: ['./task-priority.component.css']
})
export class TaskPriorityComponent implements OnInit {

  taskPriorityList = [];
  taskPriorityTypeSelected = [];
  isMultipleSelected = true;

  @Input() set resetData(isClear) {
    if (isClear) {
      this.taskPriorityTypeSelected = [];
    }
  }
  @Output() getTaskPriority: EventEmitter<any> = new EventEmitter;
  constructor() { }

  ngOnInit() {
    this.taskPriorityList = taskPriorityValues;
    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
    this.taskPriorityTypeSelected = (preselected && preselected['taskPriorities']) ? preselected['taskPriorities'] : '';
  }
  onTaskPriorityChange() {
    this.getTaskPriority.emit(this.taskPriorityTypeSelected);
  }
  clearTaskPriority() {
    this.taskPriorityTypeSelected = [];
    this.getTaskPriority.emit([]);
  }
}
