import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'shared/services/common.service';

@Component({
  selector: 'app-customer-requested-lookup',
  templateUrl: './customer-requested-lookup.component.html',
  styleUrls: ['./customer-requested-lookup.component.css']
})
export class CustomerRequestedLookupComponent implements OnInit {

  @Input() placeholder = 'Customer Requested';
  @Input() set resetData(isClear) {
      this.selectedDate = [];
  }
  @Output() selectedValue: EventEmitter<any> = new EventEmitter;
  selectedDate: any = [];
  max = new Date();

  constructor(private _commonService: CommonService,  private _route: ActivatedRoute,) { 
    this._route.queryParams.subscribe(params => {
        if(params.consoleType === 'TaskCase') {
            this.max = new Date(3000, 3, 21, 20, 30);
        }
      });
  }
  ngOnInit() {
      // to show selected date range
      const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
      if (preselected && preselected['customerDateOptions'] && preselected['customerDateOptions'][0]) {
          this.selectedDate = [this._commonService.applyNewTimeZone(preselected['customerDateOptions'][0][0], 'YYYY-MM-DDTHH:mm:ss'), this._commonService.applyNewTimeZone(preselected['customerDateOptions'][0][1], 'YYYY-MM-DDTHH:mm:ss')];
      }
  }
  onChange() {
      this.selectedValue.emit(this.selectedDate);
  }

  onClearDate() {
      this.selectedDate = [];
      this.selectedValue.emit([]);
  }

}
