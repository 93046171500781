/* tslint:disable */

declare var Object: any;
export interface TrainingResourceInterface {
  "sfdcId"?: string;
  "IsDeleted"?: boolean;
  "Name"?: string;
  "CurrencyIsoCode"?: string;
  "CreatedDate"?: Date;
  "CreatedById"?: string;
  "LastModifiedDate"?: Date;
  "LastModifiedById"?: string;
  "SystemModstamp"?: Date;
  "LastActivityDate"?: Date;
  "Learning__c"?: string;
  "Add_training_parameters_to_URL__c"?: boolean;
  "Description__c"?: string;
  "Display_in_Iframe__c"?: boolean;
  "Prevent_completion_on_launch__c"?: boolean;
  "Resource_URL__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class TrainingResource implements TrainingResourceInterface {
  "sfdcId": string;
  "IsDeleted": boolean;
  "Name": string;
  "CurrencyIsoCode": string;
  "CreatedDate": Date;
  "CreatedById": string;
  "LastModifiedDate": Date;
  "LastModifiedById": string;
  "SystemModstamp": Date;
  "LastActivityDate": Date;
  "Learning__c": string;
  "Add_training_parameters_to_URL__c": boolean;
  "Description__c": string;
  "Display_in_Iframe__c": boolean;
  "Prevent_completion_on_launch__c": boolean;
  "Resource_URL__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: TrainingResourceInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `TrainingResource`.
   */
  public static getModelName() {
    return "TrainingResource";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of TrainingResource for dynamic purposes.
  **/
  public static factory(data: TrainingResourceInterface): TrainingResource{
    return new TrainingResource(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'TrainingResource',
      plural: 'TrainingResources',
      path: 'TrainingResources',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "SystemModstamp": {
          name: 'SystemModstamp',
          type: 'Date'
        },
        "LastActivityDate": {
          name: 'LastActivityDate',
          type: 'Date'
        },
        "Learning__c": {
          name: 'Learning__c',
          type: 'string'
        },
        "Add_training_parameters_to_URL__c": {
          name: 'Add_training_parameters_to_URL__c',
          type: 'boolean'
        },
        "Description__c": {
          name: 'Description__c',
          type: 'string'
        },
        "Display_in_Iframe__c": {
          name: 'Display_in_Iframe__c',
          type: 'boolean'
        },
        "Prevent_completion_on_launch__c": {
          name: 'Prevent_completion_on_launch__c',
          type: 'boolean'
        },
        "Resource_URL__c": {
          name: 'Resource_URL__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
