import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { JobDetailInstructionsComponent } from './job-detail-instructions.component';

@NgModule({
    imports: [
        CommonModule,
        NgbModule.forRoot(),
        FormsModule
    ],
    declarations: [JobDetailInstructionsComponent],
    exports: [JobDetailInstructionsComponent],
})
export class JobDetailInstructionsModule { }
