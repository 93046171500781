/* tslint:disable */
import {
  AdjustmentReasonCode
} from '../index';

declare var Object: any;
export interface InvoiceAdjustmentInterface {
  "sfdcId"?: string;
  "CreatedById"?: string;
  "CurrencyIsoCode"?: string;
  "Name"?: string;
  "LastModifiedById"?: string;
  "OwnerId"?: string;
  "RecordTypeId"?: string;
  "Adjustment_Notes_External__c"?: string;
  "Adjustment_Notes__c"?: string;
  "Adjustment_Reason_Code__c"?: string;
  "Adjustment_Reason_Description__c"?: string;
  "Adjustment_Transaction_Number__c"?: string;
  "Adjustment_Type__c"?: string;
  "Amount_Variance__c"?: number;
  "Approver__c"?: string;
  "Date__c"?: Date;
  "Invoice__c"?: string;
  "Logged_in_Contact__c"?: string;
  "Monthly_Invoice__c"?: string;
  "New_Amount__c"?: number;
  "Old_Amount__c"?: number;
  "Ready_for_Approval__c"?: boolean;
  "Ready_for_Review__c"?: boolean;
  "Reviewer__c"?: string;
  "Status__c"?: string;
  "Vendor_Purchase_Receipt_Number__c"?: string;
  "id"?: number;
  adjustmentReasonCode?: AdjustmentReasonCode;
}

export class InvoiceAdjustment implements InvoiceAdjustmentInterface {
  "sfdcId": string;
  "CreatedById": string;
  "CurrencyIsoCode": string;
  "Name": string;
  "LastModifiedById": string;
  "OwnerId": string;
  "RecordTypeId": string;
  "Adjustment_Notes_External__c": string;
  "Adjustment_Notes__c": string;
  "Adjustment_Reason_Code__c": string;
  "Adjustment_Reason_Description__c": string;
  "Adjustment_Transaction_Number__c": string;
  "Adjustment_Type__c": string;
  "Amount_Variance__c": number;
  "Approver__c": string;
  "Date__c": Date;
  "Invoice__c": string;
  "Logged_in_Contact__c": string;
  "Monthly_Invoice__c": string;
  "New_Amount__c": number;
  "Old_Amount__c": number;
  "Ready_for_Approval__c": boolean;
  "Ready_for_Review__c": boolean;
  "Reviewer__c": string;
  "Status__c": string;
  "Vendor_Purchase_Receipt_Number__c": string;
  "id": number;
  adjustmentReasonCode: AdjustmentReasonCode;
  constructor(data?: InvoiceAdjustmentInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `InvoiceAdjustment`.
   */
  public static getModelName() {
    return "InvoiceAdjustment";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of InvoiceAdjustment for dynamic purposes.
  **/
  public static factory(data: InvoiceAdjustmentInterface): InvoiceAdjustment{
    return new InvoiceAdjustment(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'InvoiceAdjustment',
      plural: 'InvoiceAdjustments',
      path: 'InvoiceAdjustments',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "RecordTypeId": {
          name: 'RecordTypeId',
          type: 'string'
        },
        "Adjustment_Notes_External__c": {
          name: 'Adjustment_Notes_External__c',
          type: 'string'
        },
        "Adjustment_Notes__c": {
          name: 'Adjustment_Notes__c',
          type: 'string'
        },
        "Adjustment_Reason_Code__c": {
          name: 'Adjustment_Reason_Code__c',
          type: 'string'
        },
        "Adjustment_Reason_Description__c": {
          name: 'Adjustment_Reason_Description__c',
          type: 'string'
        },
        "Adjustment_Transaction_Number__c": {
          name: 'Adjustment_Transaction_Number__c',
          type: 'string'
        },
        "Adjustment_Type__c": {
          name: 'Adjustment_Type__c',
          type: 'string'
        },
        "Amount_Variance__c": {
          name: 'Amount_Variance__c',
          type: 'number'
        },
        "Approver__c": {
          name: 'Approver__c',
          type: 'string'
        },
        "Date__c": {
          name: 'Date__c',
          type: 'Date'
        },
        "Invoice__c": {
          name: 'Invoice__c',
          type: 'string'
        },
        "Logged_in_Contact__c": {
          name: 'Logged_in_Contact__c',
          type: 'string'
        },
        "Monthly_Invoice__c": {
          name: 'Monthly_Invoice__c',
          type: 'string'
        },
        "New_Amount__c": {
          name: 'New_Amount__c',
          type: 'number'
        },
        "Old_Amount__c": {
          name: 'Old_Amount__c',
          type: 'number'
        },
        "Ready_for_Approval__c": {
          name: 'Ready_for_Approval__c',
          type: 'boolean'
        },
        "Ready_for_Review__c": {
          name: 'Ready_for_Review__c',
          type: 'boolean'
        },
        "Reviewer__c": {
          name: 'Reviewer__c',
          type: 'string'
        },
        "Status__c": {
          name: 'Status__c',
          type: 'string'
        },
        "Vendor_Purchase_Receipt_Number__c": {
          name: 'Vendor_Purchase_Receipt_Number__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        adjustmentReasonCode: {
          name: 'adjustmentReasonCode',
          type: 'AdjustmentReasonCode',
          model: 'AdjustmentReasonCode',
          relationType: 'belongsTo',
                  keyFrom: 'Adjustment_Reason_Code__c',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
