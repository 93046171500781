import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PreloaderService } from 'shared/services/preloader.service';
import { DepartmentApi } from 'shared/sdk';

@Component({
  selector: 'app-pgmo-department-lookup',
  templateUrl: './pgmo-department-lookup.component.html',
  styleUrls: ['./pgmo-department-lookup.component.css']
})
export class PgmoDepartmentLookupComponent implements OnInit {

  @Input() set resetData(isClear) {
    this.clearFromParent();
  }
  @Input() from;
  @Input() selectedDefaultPgmoDepartment;
  @Output() selectedPgmoDepartment: EventEmitter<any> = new EventEmitter;
  selectedValue;
  departments: any;
  bindvalue = 'sfdcId';

  constructor(private _preloaderService: PreloaderService,
    private _departmentApi: DepartmentApi,
  ) { }
  ngOnInit() {
    // this.departments = departmentList;
    if (this.from && (this.from === 'globalChatReportAnalytics' || this.from === 'msp-checkin-out-list-view' || this.from == 'PCAnalyticsReport')) {
      this.bindvalue = 'Department_Name__c';
    }
    if (this.from && this.from === 'analyticsDashboard') {
      if (this.selectedDefaultPgmoDepartment && this.selectedDefaultPgmoDepartment.length) {
        this.selectedValue = this.selectedDefaultPgmoDepartment;
      } else {
        this.selectedValue = [];
      }
    } else {
      const preSelected = localStorage.getItem('filterObj');
      if (preSelected && JSON.parse(preSelected) &&
        JSON.parse(preSelected)['pgmo_departments'] && JSON.parse(preSelected)['pgmo_departments'].length) {
        this.selectedValue = JSON.parse(preSelected)['pgmo_departments'];
      }
    }
    this.getDepartmentData(0);
  }

  getDepartmentData(whereObj) {
    this._preloaderService.showPreloader();
    this._departmentApi.find({
      where: { sfdcId: { neq: null } },
      fields: ['sfdcId', 'Department_Name__c'],
      order: 'Department_Name__c ASC'
    }).subscribe(data => {
      this.departments = data;
      this._preloaderService.hidePreloader();
    }, err => {
      this._preloaderService.hidePreloader()
    })
  }


  onChange() {
    this.selectedPgmoDepartment.emit(this.selectedValue);
  }

  clearFromParent() {
    this.selectedValue = null;
  }

}
