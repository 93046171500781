import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, AfterViewInit, AfterContentInit } from '@angular/core';
import { debounceTime, distinctUntilChanged, switchMap, filter } from 'rxjs/operators';
import { AppStateService } from 'shared/services/app-state.service';

import { ContactApi } from 'shared/sdk/services/custom/Contact';
import { environment } from 'environments/environment';

@Component({
    selector: 'app-contact-lookup',
    templateUrl: './contact-lookup.component.html',
    styleUrls: ['./contact-lookup.component.scss']
})
export class ContactLookupComponent implements OnInit, AfterContentInit {
    @Input() set mspUserAccount(e){
        if(e && e.mspUserAccount) {
            this.mspUser = e.mspUserAccount;
        }
        if(e && e.isCommunityChanged) {
            this.contactList = [];
            this.contactSelected = [];
            this.contactName = [];
        }
    };
    @Input() fromPage;  // for custom field to show(Name + Email)
    @Input() iscontactRecordTypeId = true;
    @Input() set resetData(isClear) {
        this.contactList = [];
        this.contactSelected = [];
        this.contactName = [];
    }
    @Input()
    set setAccountObj(params) {
        this.contactList = [];
        this.contactSelected = [];
        this.contactName = [];
        if (params) {
            this.accountId = params;
            this.getContactList(this.accountId);
        }else{
            this.getContactList(this.accountId); 
        }
        this.isDisable = (this.fromPage==='disableContact') ? true : false;
    }
    @Input() set selectedContactValue (val) {
        if (val) {
            this.contactList = val;
            this.contactSelected = val && val[0] && val[0].sfdcId;
        }
    }
    @Input() boxedLayout = false;
    @Input() isMultipleSelected = true;
    @Input() candidateSfdcIds;
    @Input() groupWiseContact = [];
    @Output() getContactObj: EventEmitter<any> = new EventEmitter;
    noContact = 'No contact found.';
    contactTypeahead = new EventEmitter<string>();
    contacts: Array<any>;
    contactList = [];
    contactSelected = [];
    isDisable = false;
    accountId = '';
    mspUser:any;
    @Input()
    set preSelected(params) {
        this.contactSelected = [];
        this.contactName = [];
        if (params) {
           let whereObj = {};
            if (Array.isArray(params) && this.fromPage === 'interviewSfdcId') {
                this.contactSelected = params;              
                whereObj['sfdcId'] = { inq: params };
                whereObj['Email'] = { 'neq': null };
                whereObj['RecordTypeId'] = environment.contactRecordTypeId;
                this.findContact(whereObj);
            } else if (Array.isArray(params) && this.fromPage === 'interviewEmail') {
                this.contactSelected = params;
                whereObj['Email'] = { inq: params };
                whereObj['sfdcId'] = { 'neq': null };
                if(this.iscontactRecordTypeId){
                  whereObj['RecordTypeId'] = environment.contactRecordTypeId;
                }
                this.findContact(whereObj);
            } else {
                whereObj['sfdcId'] = params;
                this.findContact(whereObj);
            }
           
        }
    }
    @Output() controlBlur: EventEmitter<any> = new EventEmitter;
    @Input()
    set notInGlobalFilter(params) {
        if(params){
            this.accountId = '';
            this.getContactList('');
        }
    }
    @Input() placeholder = '';
    @Input() noRecordMsg = 'No account found.';
    @Input() onlyContactRecordTypeList = false;

    isLoading: boolean;
    contactName = [];
    cData = [];

    constructor(
        private _cd: ChangeDetectorRef,
        private _contactApi: ContactApi,
        private _appState: AppStateService
    ) {

    }

    ngOnInit() {}

    ngAfterContentInit() {
    }

    // get contacts list by name
    getContactList(accountId) {
        this.contactList = [];
        this.contactTypeahead
            .pipe(
                filter(term => term && term.trim().length > 2),
                distinctUntilChanged(),
                debounceTime(200),
                switchMap(term => {
                    const searchPattern = { like: '%' + term.trim() + '%', options: 'i' };
                    let whereObj = {};
                    if (this.accountId) {
                        whereObj['AccountId'] = accountId;
                    }
                    if(this.candidateSfdcIds && this.candidateSfdcIds.length) {
                        whereObj['sfdcId'] = { inq: this.candidateSfdcIds };
                    }
                    if (this.fromPage || this.onlyContactRecordTypeList) {
                        whereObj = {
                            and: [
                                { Email: { 'neq': null } },
                                { sfdcId: { 'neq': null } },
                                { RecordTypeId: environment.contactRecordTypeId },
                                { or: [{ Contact_Name__c: searchPattern }, { Email: searchPattern }] },
                            ]
                        }
                    }
                    if(this.groupWiseContact && this.groupWiseContact.length){
                        whereObj = {
                            and: [
                                { Email: { 'neq': null } },
                                { sfdcId: { 'neq': null } },
                                { PgMO_Departments__c: { inq: this.groupWiseContact } },
                                { or: [{ Contact_Name__c: searchPattern }, { Email: searchPattern }] },
                            ]
                        }
                    } else {
                        whereObj['Contact_Name__c'] = searchPattern;
                    }
                    this.isLoading = true;
                    return this.getcontactList(whereObj);
                })
            )
            .subscribe(
                x => {
                    this.isLoading = false;
                    this._cd.markForCheck();
                    if (this.fromPage) {
                        x.map(item => item['custom'] = `${item['Contact_Name__c']} (${item['Email']})`);
                    }
                    this.contactList = x;
                    if(this.contactList.length === 0 && this.candidateSfdcIds && this.candidateSfdcIds.length) {
                        this.noContact = 'No Candidate Found'
                    } else if(this.contactList.length === 0) {
                        this.noContact =  this.noRecordMsg;
                    } else {
                        this.noContact = ''
                    }
                },
                err => {
                    this.isLoading = false;
                    console.log(err);
                    this.contactList = [];
                    this.noContact = '';
                }
            );
    }

    onContactChange() {
        if (this.fromPage === 'interviewSfdcId' && this.contactList && this.contactList.length) {            
            this.contactName = [];
            this.setContactName(this.contactList);
            if(this.contactSelected && this.contactSelected.length){
                this.contactSelected.forEach(item=>{
                    this.contactName.push(this.cData[item]);
                });
            }
            this.getContactObj.emit({ 'sfdcId': this.contactSelected, 'Name': this.contactName});
        } else {
            this.getContactObj.emit(this.contactSelected);
        }
    }

    clearContactList() {
        this.contactList = [];
        this.contactSelected = [];
        this.contactName = [];
        this.getContactObj.emit([]);
    }

    /**
     *
     * @param whereObj
     */

    getcontactList(whereObj) {
        if(this.mspUser){
           // whereObj['AccountId'] = this.mspUser;//will enable when isg implement
           whereObj['Active__c'] = this.mspUser=='true' ? true : false;
        }
        return this._contactApi.find({
            where: whereObj,
            fields: ['Name', 'id', 'sfdcId', 'Contact_Name__c', 'Email','AccountId'] ,
            order: 'CreatedDate DESC',
        }).map(res => res);
    }

    findContact(whereObj) {
        this.getcontactList(whereObj).subscribe(
            x => {
                this._cd.markForCheck();
                if (this.fromPage) {
                    x.map(item => item['custom'] = `${item['Contact_Name__c']} (${item['Email']})`);
                }
                this.contactList = x;
                this.setContactName(this.contactList);
               if (this.contactList.length && !this.isMultipleSelected) {
                    this.contactSelected = this.contactList[0]['sfdcId'];
                }
              this.noContact = (this.contactList.length === 0) ? this.noRecordMsg : '';
            },
            err => {
                console.log(err);
                this.contactList = [];
                this.noContact = '';
            }
        );
    }

    onControlBlur() {
        this.controlBlur.emit(true);
    }

    setContactName(contactList){
        contactList.forEach(contact => {
            this.cData[contact.sfdcId] = contact.Name;
        });
    }
}
