/* tslint:disable */

declare var Object: any;
export interface ApplicantFavouriteInterface {
  "uuid"?: string;
  "profileId"?: string;
  "jobBoard"?: string;
  "recruiterId"?: string;
  "jobBoardId"?: string;
  "isFavoruite"?: boolean;
  "isShortlisted"?: boolean;
  "previewContainer"?: any;
  "id"?: any;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class ApplicantFavourite implements ApplicantFavouriteInterface {
  "uuid": string;
  "profileId": string;
  "jobBoard": string;
  "recruiterId": string;
  "jobBoardId": string;
  "isFavoruite": boolean;
  "isShortlisted": boolean;
  "previewContainer": any;
  "id": any;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: ApplicantFavouriteInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ApplicantFavourite`.
   */
  public static getModelName() {
    return "ApplicantFavourite";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ApplicantFavourite for dynamic purposes.
  **/
  public static factory(data: ApplicantFavouriteInterface): ApplicantFavourite{
    return new ApplicantFavourite(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ApplicantFavourite',
      plural: 'ApplicantFavourites',
      path: 'ApplicantFavourites',
      idName: 'id',
      properties: {
        "uuid": {
          name: 'uuid',
          type: 'string'
        },
        "profileId": {
          name: 'profileId',
          type: 'string'
        },
        "jobBoard": {
          name: 'jobBoard',
          type: 'string'
        },
        "recruiterId": {
          name: 'recruiterId',
          type: 'string'
        },
        "jobBoardId": {
          name: 'jobBoardId',
          type: 'string'
        },
        "isFavoruite": {
          name: 'isFavoruite',
          type: 'boolean',
          default: false
        },
        "isShortlisted": {
          name: 'isShortlisted',
          type: 'boolean',
          default: false
        },
        "previewContainer": {
          name: 'previewContainer',
          type: 'any'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
