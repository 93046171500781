/* tslint:disable */
import {
  Skilling
} from '../index';

declare var Object: any;
export interface SkillingCertificationInterface {
  "sfdcId"?: string;
  "Name"?: string;
  "CreatedById"?: string;
  "CurrencyIsoCode"?: string;
  "LastModifiedById"?: string;
  "Certification__c"?: string;
  "IRON_ExtID__c"?: string;
  "Other__c"?: string;
  "Resource_Worker__c"?: string;
  "Skilling__c"?: string;
  "Skill_Level__c"?: string;
  "IsDeleted"?: boolean;
  "LastModifiedDate"?: Date;
  "CreatedDate"?: Date;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  skilling?: Skilling;
}

export class SkillingCertification implements SkillingCertificationInterface {
  "sfdcId": string;
  "Name": string;
  "CreatedById": string;
  "CurrencyIsoCode": string;
  "LastModifiedById": string;
  "Certification__c": string;
  "IRON_ExtID__c": string;
  "Other__c": string;
  "Resource_Worker__c": string;
  "Skilling__c": string;
  "Skill_Level__c": string;
  "IsDeleted": boolean;
  "LastModifiedDate": Date;
  "CreatedDate": Date;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  skilling: Skilling;
  constructor(data?: SkillingCertificationInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `SkillingCertification`.
   */
  public static getModelName() {
    return "SkillingCertification";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of SkillingCertification for dynamic purposes.
  **/
  public static factory(data: SkillingCertificationInterface): SkillingCertification{
    return new SkillingCertification(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'SkillingCertification',
      plural: 'SkillingCertifications',
      path: 'SkillingCertifications',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "Certification__c": {
          name: 'Certification__c',
          type: 'string'
        },
        "IRON_ExtID__c": {
          name: 'IRON_ExtID__c',
          type: 'string'
        },
        "Other__c": {
          name: 'Other__c',
          type: 'string'
        },
        "Resource_Worker__c": {
          name: 'Resource_Worker__c',
          type: 'string'
        },
        "Skilling__c": {
          name: 'Skilling__c',
          type: 'string'
        },
        "Skill_Level__c": {
          name: 'Skill_Level__c',
          type: 'string'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        skilling: {
          name: 'skilling',
          type: 'Skilling',
          model: 'Skilling',
          relationType: 'belongsTo',
                  keyFrom: 'Skilling__c',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
