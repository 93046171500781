/* tslint:disable */

declare var Object: any;
export interface JobDataInterface {
  "id": number;
  "Job__sfdcId"?: string;
  "Job__CKSW_BASE_City"?: string;
  "Job__CKSW_BASE_Country"?: string;
  "Job__CKSW_BASE_Description"?: string;
  "Job__CKSW_BASE_Early_Start"?: Date;
  "Job__CKSW_BASE_Location"?: string;
  "Job__CKSW_BASE_State"?: string;
  "Job__Case"?: string;
  "Job__CreatedDate"?: Date;
  "Job__Customer_Appointment_DateTime_Scheduled"?: Date;
  "Job__Customer_Service_Type_From_Program"?: string;
  "Job__Customer_Service_Type"?: string;
  "Job__Dispatch_Service_Resolution_Status"?: string;
  "Job__Dispatch_Worker_Name"?: string;
  "Job__Earliest_Start_Date_Permitted"?: boolean;
  "Job__Iron_Job_num"?: string;
  "Job__Job_Status_Internal"?: string;
  "Job__Jobsite_Name"?: string;
  "Job__jobsite"?: string;
  "Job__Location_Type"?: string;
  "Job__Name"?: string;
  "Job__Project_SOP"?: string;
  "Job__Project"?: string;
  "Job__RecordTypeId"?: string;
  "Job__SKU"?: string;
  "Job__Service_Dispatch_SLA_Priority_FrmProgram"?: string;
  "Job__Service_Dispatch_SLA_Priority"?: string;
  "Job__Talent_Type"?: string;
  "Job__Technical_Level"?: string;
  "Job__Vendor"?: string;
  "Job__Vendorsite"?: string;
  "Job__Worker_Arrival_DateTime_Cust_Requested"?: Date;
  "Job__Worker_Arrival_DateTime_Scheduled"?: Date;
  "Job__csum"?: string;
  "Job__updatedAt"?: Date;
  "Job__createdAt"?: Date;
  "Appointment__sfdcId"?: string;
  "Appointment__Customer_Appointment_DateTime_Scheduled"?: Date;
  "Appointment__Customer_Appointment_Start_HRS_Scheduled"?: string;
  "Appointment__Customer_Appointment_Start_Scheduled"?: Date;
  "Appointment__Customer_Apptmnt_Start_Minutes_Scheduled"?: string;
  "Appointment__Earliest_Start_Date_Permitted"?: boolean;
  "Appointment__Early_Start"?: Date;
  "Appointment__ICC_Case"?: string;
  "Appointment__Worker_Arrival_DateTime_Cust_Requested"?: Date;
  "Appointment__Worker_Arrival_DateTime_Scheduled"?: Date;
  "Appointment__Worker_Arrival_Date_Time_Actual"?: Date;
  "Case__sfdcId"?: string;
  "Case__Coverage_Hours"?: string;
  "Case__Job_Order"?: string;
  "Case__PPE_Hours"?: string;
  "Case__Partner_Case_Number"?: string;
  "Case__Talent_Type_ref"?: string;
  "PMSCase__sfdcId"?: string;
  "PMSCase__CaseNumber"?: string;
  "TalentType__Talent_Type_Name"?: string;
  "Jobsite__Abbreviation"?: string;
  "Jobsite__Time_Zone"?: string;
  "Jobsite__Jobsite_ID"?: string;
  "Jobsite__Street"?: string;
  "Jobsite__Zip"?: string;
  "Jobsite__sfdcId"?: string;
  "Program__Name"?: string;
  "Program__sfdcId"?: string;
  "Product__Name"?: string;
  "Product__sfdcId"?: string;
  "Vendor__Name"?: string;
  "Vendor__sfdcId"?: string;
}

export class JobData implements JobDataInterface {
  "id": number;
  "Job__sfdcId": string;
  "Job__CKSW_BASE_City": string;
  "Job__CKSW_BASE_Country": string;
  "Job__CKSW_BASE_Description": string;
  "Job__CKSW_BASE_Early_Start": Date;
  "Job__CKSW_BASE_Location": string;
  "Job__CKSW_BASE_State": string;
  "Job__Case": string;
  "Job__CreatedDate": Date;
  "Job__Customer_Appointment_DateTime_Scheduled": Date;
  "Job__Customer_Service_Type_From_Program": string;
  "Job__Customer_Service_Type": string;
  "Job__Dispatch_Service_Resolution_Status": string;
  "Job__Dispatch_Worker_Name": string;
  "Job__Earliest_Start_Date_Permitted": boolean;
  "Job__Iron_Job_num": string;
  "Job__Job_Status_Internal": string;
  "Job__Jobsite_Name": string;
  "Job__jobsite": string;
  "Job__Location_Type": string;
  "Job__Name": string;
  "Job__Project_SOP": string;
  "Job__Project": string;
  "Job__RecordTypeId": string;
  "Job__SKU": string;
  "Job__Service_Dispatch_SLA_Priority_FrmProgram": string;
  "Job__Service_Dispatch_SLA_Priority": string;
  "Job__Talent_Type": string;
  "Job__Technical_Level": string;
  "Job__Vendor": string;
  "Job__Vendorsite": string;
  "Job__Worker_Arrival_DateTime_Cust_Requested": Date;
  "Job__Worker_Arrival_DateTime_Scheduled": Date;
  "Job__csum": string;
  "Job__updatedAt": Date;
  "Job__createdAt": Date;
  "Appointment__sfdcId": string;
  "Appointment__Customer_Appointment_DateTime_Scheduled": Date;
  "Appointment__Customer_Appointment_Start_HRS_Scheduled": string;
  "Appointment__Customer_Appointment_Start_Scheduled": Date;
  "Appointment__Customer_Apptmnt_Start_Minutes_Scheduled": string;
  "Appointment__Earliest_Start_Date_Permitted": boolean;
  "Appointment__Early_Start": Date;
  "Appointment__ICC_Case": string;
  "Appointment__Worker_Arrival_DateTime_Cust_Requested": Date;
  "Appointment__Worker_Arrival_DateTime_Scheduled": Date;
  "Appointment__Worker_Arrival_Date_Time_Actual": Date;
  "Case__sfdcId": string;
  "Case__Coverage_Hours": string;
  "Case__Job_Order": string;
  "Case__PPE_Hours": string;
  "Case__Partner_Case_Number": string;
  "Case__Talent_Type_ref": string;
  "PMSCase__sfdcId": string;
  "PMSCase__CaseNumber": string;
  "TalentType__Talent_Type_Name": string;
  "Jobsite__Abbreviation": string;
  "Jobsite__Time_Zone": string;
  "Jobsite__Jobsite_ID": string;
  "Jobsite__Street": string;
  "Jobsite__Zip": string;
  "Jobsite__sfdcId": string;
  "Program__Name": string;
  "Program__sfdcId": string;
  "Product__Name": string;
  "Product__sfdcId": string;
  "Vendor__Name": string;
  "Vendor__sfdcId": string;
  constructor(data?: JobDataInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `JobData`.
   */
  public static getModelName() {
    return "JobData";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of JobData for dynamic purposes.
  **/
  public static factory(data: JobDataInterface): JobData{
    return new JobData(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'JobData',
      plural: 'JobData',
      path: 'JobData',
      idName: '',
      properties: {
        "id": {
          name: 'id',
          type: 'number'
        },
        "Job__sfdcId": {
          name: 'Job__sfdcId',
          type: 'string'
        },
        "Job__CKSW_BASE_City": {
          name: 'Job__CKSW_BASE_City',
          type: 'string'
        },
        "Job__CKSW_BASE_Country": {
          name: 'Job__CKSW_BASE_Country',
          type: 'string'
        },
        "Job__CKSW_BASE_Description": {
          name: 'Job__CKSW_BASE_Description',
          type: 'string'
        },
        "Job__CKSW_BASE_Early_Start": {
          name: 'Job__CKSW_BASE_Early_Start',
          type: 'Date'
        },
        "Job__CKSW_BASE_Location": {
          name: 'Job__CKSW_BASE_Location',
          type: 'string'
        },
        "Job__CKSW_BASE_State": {
          name: 'Job__CKSW_BASE_State',
          type: 'string'
        },
        "Job__Case": {
          name: 'Job__Case',
          type: 'string'
        },
        "Job__CreatedDate": {
          name: 'Job__CreatedDate',
          type: 'Date'
        },
        "Job__Customer_Appointment_DateTime_Scheduled": {
          name: 'Job__Customer_Appointment_DateTime_Scheduled',
          type: 'Date'
        },
        "Job__Customer_Service_Type_From_Program": {
          name: 'Job__Customer_Service_Type_From_Program',
          type: 'string'
        },
        "Job__Customer_Service_Type": {
          name: 'Job__Customer_Service_Type',
          type: 'string'
        },
        "Job__Dispatch_Service_Resolution_Status": {
          name: 'Job__Dispatch_Service_Resolution_Status',
          type: 'string'
        },
        "Job__Dispatch_Worker_Name": {
          name: 'Job__Dispatch_Worker_Name',
          type: 'string'
        },
        "Job__Earliest_Start_Date_Permitted": {
          name: 'Job__Earliest_Start_Date_Permitted',
          type: 'boolean'
        },
        "Job__Iron_Job_num": {
          name: 'Job__Iron_Job_num',
          type: 'string'
        },
        "Job__Job_Status_Internal": {
          name: 'Job__Job_Status_Internal',
          type: 'string'
        },
        "Job__Jobsite_Name": {
          name: 'Job__Jobsite_Name',
          type: 'string'
        },
        "Job__jobsite": {
          name: 'Job__jobsite',
          type: 'string'
        },
        "Job__Location_Type": {
          name: 'Job__Location_Type',
          type: 'string'
        },
        "Job__Name": {
          name: 'Job__Name',
          type: 'string'
        },
        "Job__Project_SOP": {
          name: 'Job__Project_SOP',
          type: 'string'
        },
        "Job__Project": {
          name: 'Job__Project',
          type: 'string'
        },
        "Job__RecordTypeId": {
          name: 'Job__RecordTypeId',
          type: 'string'
        },
        "Job__SKU": {
          name: 'Job__SKU',
          type: 'string'
        },
        "Job__Service_Dispatch_SLA_Priority_FrmProgram": {
          name: 'Job__Service_Dispatch_SLA_Priority_FrmProgram',
          type: 'string'
        },
        "Job__Service_Dispatch_SLA_Priority": {
          name: 'Job__Service_Dispatch_SLA_Priority',
          type: 'string'
        },
        "Job__Talent_Type": {
          name: 'Job__Talent_Type',
          type: 'string'
        },
        "Job__Technical_Level": {
          name: 'Job__Technical_Level',
          type: 'string'
        },
        "Job__Vendor": {
          name: 'Job__Vendor',
          type: 'string'
        },
        "Job__Vendorsite": {
          name: 'Job__Vendorsite',
          type: 'string'
        },
        "Job__Worker_Arrival_DateTime_Cust_Requested": {
          name: 'Job__Worker_Arrival_DateTime_Cust_Requested',
          type: 'Date'
        },
        "Job__Worker_Arrival_DateTime_Scheduled": {
          name: 'Job__Worker_Arrival_DateTime_Scheduled',
          type: 'Date'
        },
        "Job__csum": {
          name: 'Job__csum',
          type: 'string'
        },
        "Job__updatedAt": {
          name: 'Job__updatedAt',
          type: 'Date'
        },
        "Job__createdAt": {
          name: 'Job__createdAt',
          type: 'Date'
        },
        "Appointment__sfdcId": {
          name: 'Appointment__sfdcId',
          type: 'string'
        },
        "Appointment__Customer_Appointment_DateTime_Scheduled": {
          name: 'Appointment__Customer_Appointment_DateTime_Scheduled',
          type: 'Date'
        },
        "Appointment__Customer_Appointment_Start_HRS_Scheduled": {
          name: 'Appointment__Customer_Appointment_Start_HRS_Scheduled',
          type: 'string'
        },
        "Appointment__Customer_Appointment_Start_Scheduled": {
          name: 'Appointment__Customer_Appointment_Start_Scheduled',
          type: 'Date'
        },
        "Appointment__Customer_Apptmnt_Start_Minutes_Scheduled": {
          name: 'Appointment__Customer_Apptmnt_Start_Minutes_Scheduled',
          type: 'string'
        },
        "Appointment__Earliest_Start_Date_Permitted": {
          name: 'Appointment__Earliest_Start_Date_Permitted',
          type: 'boolean',
          default: false
        },
        "Appointment__Early_Start": {
          name: 'Appointment__Early_Start',
          type: 'Date'
        },
        "Appointment__ICC_Case": {
          name: 'Appointment__ICC_Case',
          type: 'string'
        },
        "Appointment__Worker_Arrival_DateTime_Cust_Requested": {
          name: 'Appointment__Worker_Arrival_DateTime_Cust_Requested',
          type: 'Date'
        },
        "Appointment__Worker_Arrival_DateTime_Scheduled": {
          name: 'Appointment__Worker_Arrival_DateTime_Scheduled',
          type: 'Date'
        },
        "Appointment__Worker_Arrival_Date_Time_Actual": {
          name: 'Appointment__Worker_Arrival_Date_Time_Actual',
          type: 'Date'
        },
        "Case__sfdcId": {
          name: 'Case__sfdcId',
          type: 'string'
        },
        "Case__Coverage_Hours": {
          name: 'Case__Coverage_Hours',
          type: 'string'
        },
        "Case__Job_Order": {
          name: 'Case__Job_Order',
          type: 'string'
        },
        "Case__PPE_Hours": {
          name: 'Case__PPE_Hours',
          type: 'string'
        },
        "Case__Partner_Case_Number": {
          name: 'Case__Partner_Case_Number',
          type: 'string'
        },
        "Case__Talent_Type_ref": {
          name: 'Case__Talent_Type_ref',
          type: 'string'
        },
        "PMSCase__sfdcId": {
          name: 'PMSCase__sfdcId',
          type: 'string'
        },
        "PMSCase__CaseNumber": {
          name: 'PMSCase__CaseNumber',
          type: 'string'
        },
        "TalentType__Talent_Type_Name": {
          name: 'TalentType__Talent_Type_Name',
          type: 'string'
        },
        "Jobsite__Abbreviation": {
          name: 'Jobsite__Abbreviation',
          type: 'string'
        },
        "Jobsite__Time_Zone": {
          name: 'Jobsite__Time_Zone',
          type: 'string'
        },
        "Jobsite__Jobsite_ID": {
          name: 'Jobsite__Jobsite_ID',
          type: 'string'
        },
        "Jobsite__Street": {
          name: 'Jobsite__Street',
          type: 'string'
        },
        "Jobsite__Zip": {
          name: 'Jobsite__Zip',
          type: 'string'
        },
        "Jobsite__sfdcId": {
          name: 'Jobsite__sfdcId',
          type: 'string'
        },
        "Program__Name": {
          name: 'Program__Name',
          type: 'string'
        },
        "Program__sfdcId": {
          name: 'Program__sfdcId',
          type: 'string'
        },
        "Product__Name": {
          name: 'Product__Name',
          type: 'string'
        },
        "Product__sfdcId": {
          name: 'Product__sfdcId',
          type: 'string'
        },
        "Vendor__Name": {
          name: 'Vendor__Name',
          type: 'string'
        },
        "Vendor__sfdcId": {
          name: 'Vendor__sfdcId',
          type: 'string'
        },
      },
      relations: {
      }
    }
  }
}
