import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { PageViewGroupLookupComponent} from './page-view-group-lookup.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule
  ],
  declarations: [PageViewGroupLookupComponent],
  exports:[PageViewGroupLookupComponent]
})
export class PageViewGroupLookupModule { }
