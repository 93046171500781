/* tslint:disable */
import {
  DocumentCategory,
  DocumentTitle,
  Group,
  Worker
} from '../index';

declare var Object: any;
export interface DocumentRoleInterface {
  "CategoryId"?: string;
  "SubCategoryId"?: number;
  "Group"?: string;
  "Team"?: string;
  "Member"?: string;
  "GroupDelete"?: string;
  "TeamDelete"?: string;
  "MemberDelete"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  Category?: DocumentCategory;
  SubCategory?: DocumentTitle;
  member?: Worker;
}

export class DocumentRole implements DocumentRoleInterface {
  "CategoryId": string;
  "SubCategoryId": number;
  "Group": string;
  "Team": string;
  "Member": string;
  "GroupDelete": string;
  "TeamDelete": string;
  "MemberDelete": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  Category: DocumentCategory;
  SubCategory: DocumentTitle;
  member: Worker;
  constructor(data?: DocumentRoleInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `DocumentRole`.
   */
  public static getModelName() {
    return "DocumentRole";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of DocumentRole for dynamic purposes.
  **/
  public static factory(data: DocumentRoleInterface): DocumentRole{
    return new DocumentRole(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'DocumentRole',
      plural: 'DocumentRoles',
      path: 'DocumentRoles',
      idName: 'id',
      properties: {
        "CategoryId": {
          name: 'CategoryId',
          type: 'string'
        },
        "SubCategoryId": {
          name: 'SubCategoryId',
          type: 'number'
        },
        "Group": {
          name: 'Group',
          type: 'string'
        },
        "Team": {
          name: 'Team',
          type: 'string'
        },
        "Member": {
          name: 'Member',
          type: 'string'
        },
        "GroupDelete": {
          name: 'GroupDelete',
          type: 'string'
        },
        "TeamDelete": {
          name: 'TeamDelete',
          type: 'string'
        },
        "MemberDelete": {
          name: 'MemberDelete',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        Category: {
          name: 'Category',
          type: 'DocumentCategory',
          model: 'DocumentCategory',
          relationType: 'belongsTo',
                  keyFrom: 'CategoryId',
          keyTo: 'id'
        },
        SubCategory: {
          name: 'SubCategory',
          type: 'DocumentTitle',
          model: 'DocumentTitle',
          relationType: 'belongsTo',
                  keyFrom: 'SubCategoryId',
          keyTo: 'id'
        },
        Group: {
          name: 'Group',
          type: 'Group',
          model: 'Group',
          relationType: 'belongsTo',
                  keyFrom: 'Group',
          keyTo: 'sfdcId'
        },
        Team: {
          name: 'Team',
          type: 'Group',
          model: 'Group',
          relationType: 'belongsTo',
                  keyFrom: 'Team',
          keyTo: 'sfdcId'
        },
        member: {
          name: 'member',
          type: 'Worker',
          model: 'Worker',
          relationType: 'belongsTo',
                  keyFrom: 'CategoryId',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
