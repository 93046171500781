import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReceiptTabComponent } from './receipt-tab.component';
import { JobsiteContactModule } from '../jobsite-contact/jobsite-contact.module';

@NgModule({
  imports: [
    CommonModule,
    JobsiteContactModule
  ],
  declarations: [ReceiptTabComponent],
  exports: [ReceiptTabComponent]
})
export class ReceiptTabModule { }
