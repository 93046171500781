import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Project2LookupComponent } from './project2-lookup.component';

@NgModule({
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule
  ],
  declarations: [Project2LookupComponent],
  exports: [Project2LookupComponent]
})
export class Project2LookupModule { }
