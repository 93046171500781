import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimesheetListComponent } from './timesheet-list.component';
import { FormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ReadMoreModule } from 'shared/components/read-more/read-more.module';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { DocumentModule } from 'shared/components/document/document.module';
import { TimesheetAddNewModule } from 'shared/components/timesheet-add-new/timesheet-add-new.module';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgxDatatableModule,
    ReadMoreModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    DocumentModule,
    TimesheetAddNewModule,
    RouterModule
  ],
  declarations: [TimesheetListComponent],
  exports: [TimesheetListComponent]
})
export class TimesheetListModule { }
