/* tslint:disable */
import {
  Task,
  CorrectiveActionRequest
} from '../index';

declare var Object: any;
export interface AssociatedTaskInterface {
  "sfdcId"?: string;
  "Name"?: string;
  "OwnerId"?: string;
  "LastModifiedById"?: string;
  "CurrencyIsoCode"?: string;
  "CreatedById"?: string;
  "Associated_Pgmo_Problem__c"?: string;
  "PgMO_Problem__c"?: string;
  "Corrective_Action_Request__c"?: string;
  "IRON_ExtID__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  problemTask?: Task;
  associatedTask?: Task;
  car?: CorrectiveActionRequest;
}

export class AssociatedTask implements AssociatedTaskInterface {
  "sfdcId": string;
  "Name": string;
  "OwnerId": string;
  "LastModifiedById": string;
  "CurrencyIsoCode": string;
  "CreatedById": string;
  "Associated_Pgmo_Problem__c": string;
  "PgMO_Problem__c": string;
  "Corrective_Action_Request__c": string;
  "IRON_ExtID__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  problemTask: Task;
  associatedTask: Task;
  car: CorrectiveActionRequest;
  constructor(data?: AssociatedTaskInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `AssociatedTask`.
   */
  public static getModelName() {
    return "AssociatedTask";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of AssociatedTask for dynamic purposes.
  **/
  public static factory(data: AssociatedTaskInterface): AssociatedTask{
    return new AssociatedTask(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'AssociatedTask',
      plural: 'AssociatedTasks',
      path: 'AssociatedTasks',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "Associated_Pgmo_Problem__c": {
          name: 'Associated_Pgmo_Problem__c',
          type: 'string'
        },
        "PgMO_Problem__c": {
          name: 'PgMO_Problem__c',
          type: 'string'
        },
        "Corrective_Action_Request__c": {
          name: 'Corrective_Action_Request__c',
          type: 'string'
        },
        "IRON_ExtID__c": {
          name: 'IRON_ExtID__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        problemTask: {
          name: 'problemTask',
          type: 'Task',
          model: 'Task',
          relationType: 'belongsTo',
                  keyFrom: 'PgMO_Problem__c',
          keyTo: 'sfdcId'
        },
        associatedTask: {
          name: 'associatedTask',
          type: 'Task',
          model: 'Task',
          relationType: 'belongsTo',
                  keyFrom: 'Associated_Pgmo_Problem__c',
          keyTo: 'sfdcId'
        },
        car: {
          name: 'car',
          type: 'CorrectiveActionRequest',
          model: 'CorrectiveActionRequest',
          relationType: 'belongsTo',
                  keyFrom: 'Corrective_Action_Request__c',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
