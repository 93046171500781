import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { divisionList } from 'shared/models/static-list-data.service';

@Component({
  selector: 'app-division-lookup',
  templateUrl: './division-lookup.component.html',
  styleUrls: ['./division-lookup.component.css']
})
export class DivisionLookupComponent implements OnInit {

  @Input() set resetData(isClear) {
    this.clearFromParent();
  }
  @Output() selectedDivision: EventEmitter<any> = new EventEmitter;
  selectedValue;

  divisions = [];
  
  constructor() { }

  ngOnInit() {
    this.divisions = divisionList;
    const preSelected = localStorage.getItem('filterObj');
    if (preSelected && JSON.parse(preSelected) &&
      JSON.parse(preSelected)['divisions'] && JSON.parse(preSelected)['divisions'].length) {
      this.selectedValue = JSON.parse(preSelected)['divisions'];
    }
  }

  onChange() {
    this.selectedDivision.emit(this.selectedValue);
  }

  clearFromParent() {
    this.selectedValue = null;
  }

}
