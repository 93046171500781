import { PagesError404Component } from './pages-error-404/pages-error-404.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [PagesError404Component]
})
export class Error404Module { }