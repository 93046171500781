import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProjectConsoleTypes } from 'msp/enums/project-console-types.enum';
import { accountCommunityValues } from '../../../models/static-list-data.service';

@Component({
    selector: 'app-account-community-lookup',
    templateUrl: './account-community-lookup.component.html',
    styleUrls: ['./account-community-lookup.component.css']
})
export class AccountCommunityLookupComponent implements OnInit {

    @Input() disabled;
    @Input() isMultipleSelected = true;
    @Input() maxSelectedItems = 1;
    @Input() set resetData(isClear) {
        if (isClear) { this.accountCommunityTypeSelected = []; }
    }
    @Input() set accountCommunity(e) {
        if (e) { this.defaultAccCommunity = e; }
    }
    @Input() from;
    @Input() set selectedDefaultAccCommunity(e) {
        if (e) {
            this.defaultAccCommunity = e;
        } else {
            this.defaultAccCommunity = [];
        }
    }
    @Output() accountCommunityChange: EventEmitter<any> = new EventEmitter;

    defaultAccCommunity;
    accountCommunityList = [];
    accountCommunityTypeSelected = [];
    bindLable = 'label';
    bindvalue = 'value';

    constructor() { }

    ngOnInit() {
        if (this.from && (this.from === 'userAnalyticsActivity' || this.from === 'jobAccessAnalytics')) {
            this.bindLable = 'value';
        }
        this.accountCommunityList = accountCommunityValues;
        const defaultCommunity = { label: 'MSP', value: 'internal' };
        const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
        let selectedValue;
        if (this.defaultAccCommunity) {
            selectedValue = this.defaultAccCommunity;
        } else if (preselected && preselected['accountCommunities']) {
            selectedValue = preselected['accountCommunities'];
        } else {
            if (![ 'userAnalyticsActivity', 'globalChatReportAnalytics', 'jobAccessAnalytics', 'userCountAnalyticsActivity',
                `${ProjectConsoleTypes.taskCase}_projectsLevel`].includes(this.from)
            ) {
                selectedValue = defaultCommunity.value;
            }
        }
        this.accountCommunityTypeSelected = (selectedValue instanceof Array) ? selectedValue : selectedValue ? [selectedValue] : [];
        this.onAccountCommunityChange();
    }

    onAccountCommunityChange() {
        this.accountCommunityChange.emit(this.accountCommunityTypeSelected);
    }

    clearAccountCommunity() {
        this.accountCommunityTypeSelected = [];
        this.accountCommunityChange.emit([]);
    }

}
