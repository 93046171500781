import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { JobTypesFilterList } from 'shared/models/static-list-data.service';
import { PmsIccPmcRecordTypeMappingApi, RecordTypeApi } from 'shared/sdk/index';

@Component({
  selector: 'app-case-type-lookup',
  templateUrl: './case-type-lookup.component.html',
  styleUrls: ['./case-type-lookup.component.css']
})
export class CaseTypeLookupComponent implements OnInit {

  @Output() getCaseTypeObj: EventEmitter<any> = new EventEmitter;
  @Input() placeholder = 'Case (Job) Type';
  @Input() isdisabled = false;
  @Input() isMultipleSelected = true;
  @Input() set resetData(isClear) {
    if (isClear) {
      this.caseTypeSelected = [];
    }
  }
  @Input() viewType = '';

  jobTypeList: Array<any>;
  jobType = 'Searching...';
  jobTypeahead = new EventEmitter<string>();
  caseTypeSelected = [];
  filterJobType: any = {};
  jobRecordType: any = {};
  pmsRecordType: any = {};


  constructor(private _pmsIccPmcRecordTypeMappingApi: PmsIccPmcRecordTypeMappingApi,
    private _recordTypeApi: RecordTypeApi) {

  }

  ngOnInit() {
    this.getCaseTypeInfo();
    this.jobTypeList = JobTypesFilterList;
    // to show selected job types

    const selectFilter = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
    if (selectFilter && selectFilter['caseTypes']) {
      this.caseTypeSelected = selectFilter['caseTypes'];
    }
  }

  onJobTypeChange() {
    const recordTypeIds = this.getJobTypeFilterCondition(this.caseTypeSelected);
    this.getCaseTypeObj.emit({ caseTypes: this.caseTypeSelected, pmsRecordTypeIds: recordTypeIds });
  }

  clearJobType() {
    this.caseTypeSelected = [];
    this.getCaseTypeObj.emit({});
  }

  getCaseTypeInfo() {
    const reqObj = {
      'where': {
        'IsPMS__c': true
      },
      'fields': ['sfdcId', 'RecordTypes__c', 'IsPMS__c', 'X3PL__c', 'IsFTE__c', 'IsHMS__c'],
      'include': [
        {
          'relation': 'recordtype'
        }]
    };
    this._pmsIccPmcRecordTypeMappingApi.find(reqObj).subscribe((filterList: any) => {
      this.pmsRecordType['all'] = [];
      const fte = [];
      const fru = [];
      const fse = [];
      for (let zz = 0; zz < filterList.length; zz++) {
        const el = filterList[zz];
        if (el && el.recordtype && el.recordtype.sfdcId) {
          this.pmsRecordType['all'].push(el.recordtype.sfdcId);
        }
        if (el.IsFTE__c === true) {
          fte.push(el.recordtype.sfdcId);
        } else if (el.X3PL__c === true) {
          fru.push(el.recordtype.sfdcId);
        } else if (!el.X3PL__c && !el.IsFTE__c && !el.IsHMS__c) {
          fse.push(el.recordtype.sfdcId);
        }

      }
      this.pmsRecordType['fte'] = fte;
      this.pmsRecordType['fru'] = fru;
      this.pmsRecordType['fse'] = fse;

    }, error => {
      console.log('Error occured:', error);
    });
  }

  /**
   *
   * @param filterCondition | prepair job typs filter
   */
  getJobTypeFilterCondition(jobTypes) {
    const q = [];
    jobTypes.forEach(element => {
      switch (element) {
        case '0':
          q.push({ or: [{ and: [{ 'RecordTypeId': { inq: this.pmsRecordType.fse } }, { 'isFRU': { inq: [null, '', false] } }] }, { 'is_backfill_worker': true }] });
          break;
        case '1':
          q.push({ 'RecordTypeId': { inq: this.pmsRecordType.fte } });
          break;
        case '2':
          // q.push({ 'RecordTypeId': { inq: this.pmsRecordType.fse } });
          q.push({ and: [{ 'RecordTypeId': { inq: this.pmsRecordType.fse } }, { 'isFRU': { inq: [null, '', false] } }] });
          break;
        case '3':
          q.push({ and: [{ 'RecordTypeId': { inq: this.pmsRecordType.fte } }, { 'is_backfill_worker': true }] });
          break;
        case '4':
          q.push({ and: [{ 'RecordTypeId': { inq: this.pmsRecordType.fte } }, { 'is_backfill_worker': false }] });
          break;
        case '5':
          q.push({ and: [{ 'RecordTypeId': { inq: this.pmsRecordType.fse } }, { 'isFRU': true }] });
          break;
      }
    });
    return q;
  }

}
