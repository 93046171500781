import { CustomDualListModule } from 'shared/components/custom-dual-list/custom-dual-list.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StaticDataLookupComponent } from './static-data-lookup/static-data-lookup.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule,
    CustomDualListModule
  ],
  declarations: [StaticDataLookupComponent],
  exports: [StaticDataLookupComponent]
})
export class StaticDataLookupModule { }
