/* tslint:disable */

declare var Object: any;
export interface LearningAssignmentInterface {
  "sfdcId"?: string;
  "OwnerId"?: string;
  "IsDeleted"?: boolean;
  "Name"?: string;
  "CreatedById"?: string;
  "CreatedDate"?: Date;
  "CurrencyIsoCode"?: string;
  "LastModifiedDate"?: Date;
  "LastModifiedById"?: string;
  "SystemModstamp"?: Date;
  "LastActivityDate"?: Date;
  "LastViewedDate"?: Date;
  "LastReferencedDate"?: Date;
  "Approved__c"?: boolean;
  "Approved_By__c"?: string;
  "Approver_Notes__c"?: string;
  "Community_Id__c"?: string;
  "Completed_On__c"?: Date;
  "Contact__c"?: string;
  "Evaluation_Result__c"?: string;
  "Evaluation_Result_Text__c"?: string;
  "Evaluation_Score__c"?: number;
  "Evaluation_Times_Taken__c"?: number;
  "Is_Test_Assignment__c"?: boolean;
  "Last_Published_Learning_Version__c"?: string;
  "Last_Published_Learning_Version_Decision__c"?: string;
  "Learning__c"?: string;
  "Learning_Version__c"?: string;
  "Optional__c"?: boolean;
  "Progress__c"?: number;
  "Progress_Text__c"?: string;
  "Shared_Learning_Assignment__c"?: string;
  "Trainee__c"?: string;
  "Training_Plan__c"?: string;
  "Training_Plan_Section__c"?: string;
  "Training_Plan_Section_Assignment__c"?: string;
  "Training_Plan_Section_Item__c"?: string;
  "User__c"?: string;
  "User_Notes__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class LearningAssignment implements LearningAssignmentInterface {
  "sfdcId": string;
  "OwnerId": string;
  "IsDeleted": boolean;
  "Name": string;
  "CreatedById": string;
  "CreatedDate": Date;
  "CurrencyIsoCode": string;
  "LastModifiedDate": Date;
  "LastModifiedById": string;
  "SystemModstamp": Date;
  "LastActivityDate": Date;
  "LastViewedDate": Date;
  "LastReferencedDate": Date;
  "Approved__c": boolean;
  "Approved_By__c": string;
  "Approver_Notes__c": string;
  "Community_Id__c": string;
  "Completed_On__c": Date;
  "Contact__c": string;
  "Evaluation_Result__c": string;
  "Evaluation_Result_Text__c": string;
  "Evaluation_Score__c": number;
  "Evaluation_Times_Taken__c": number;
  "Is_Test_Assignment__c": boolean;
  "Last_Published_Learning_Version__c": string;
  "Last_Published_Learning_Version_Decision__c": string;
  "Learning__c": string;
  "Learning_Version__c": string;
  "Optional__c": boolean;
  "Progress__c": number;
  "Progress_Text__c": string;
  "Shared_Learning_Assignment__c": string;
  "Trainee__c": string;
  "Training_Plan__c": string;
  "Training_Plan_Section__c": string;
  "Training_Plan_Section_Assignment__c": string;
  "Training_Plan_Section_Item__c": string;
  "User__c": string;
  "User_Notes__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: LearningAssignmentInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `LearningAssignment`.
   */
  public static getModelName() {
    return "LearningAssignment";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of LearningAssignment for dynamic purposes.
  **/
  public static factory(data: LearningAssignmentInterface): LearningAssignment{
    return new LearningAssignment(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'LearningAssignment',
      plural: 'LearningAssignments',
      path: 'LearningAssignments',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "SystemModstamp": {
          name: 'SystemModstamp',
          type: 'Date'
        },
        "LastActivityDate": {
          name: 'LastActivityDate',
          type: 'Date'
        },
        "LastViewedDate": {
          name: 'LastViewedDate',
          type: 'Date'
        },
        "LastReferencedDate": {
          name: 'LastReferencedDate',
          type: 'Date'
        },
        "Approved__c": {
          name: 'Approved__c',
          type: 'boolean'
        },
        "Approved_By__c": {
          name: 'Approved_By__c',
          type: 'string'
        },
        "Approver_Notes__c": {
          name: 'Approver_Notes__c',
          type: 'string'
        },
        "Community_Id__c": {
          name: 'Community_Id__c',
          type: 'string'
        },
        "Completed_On__c": {
          name: 'Completed_On__c',
          type: 'Date'
        },
        "Contact__c": {
          name: 'Contact__c',
          type: 'string'
        },
        "Evaluation_Result__c": {
          name: 'Evaluation_Result__c',
          type: 'string'
        },
        "Evaluation_Result_Text__c": {
          name: 'Evaluation_Result_Text__c',
          type: 'string'
        },
        "Evaluation_Score__c": {
          name: 'Evaluation_Score__c',
          type: 'number'
        },
        "Evaluation_Times_Taken__c": {
          name: 'Evaluation_Times_Taken__c',
          type: 'number'
        },
        "Is_Test_Assignment__c": {
          name: 'Is_Test_Assignment__c',
          type: 'boolean'
        },
        "Last_Published_Learning_Version__c": {
          name: 'Last_Published_Learning_Version__c',
          type: 'string'
        },
        "Last_Published_Learning_Version_Decision__c": {
          name: 'Last_Published_Learning_Version_Decision__c',
          type: 'string'
        },
        "Learning__c": {
          name: 'Learning__c',
          type: 'string'
        },
        "Learning_Version__c": {
          name: 'Learning_Version__c',
          type: 'string'
        },
        "Optional__c": {
          name: 'Optional__c',
          type: 'boolean'
        },
        "Progress__c": {
          name: 'Progress__c',
          type: 'number'
        },
        "Progress_Text__c": {
          name: 'Progress_Text__c',
          type: 'string'
        },
        "Shared_Learning_Assignment__c": {
          name: 'Shared_Learning_Assignment__c',
          type: 'string'
        },
        "Trainee__c": {
          name: 'Trainee__c',
          type: 'string'
        },
        "Training_Plan__c": {
          name: 'Training_Plan__c',
          type: 'string'
        },
        "Training_Plan_Section__c": {
          name: 'Training_Plan_Section__c',
          type: 'string'
        },
        "Training_Plan_Section_Assignment__c": {
          name: 'Training_Plan_Section_Assignment__c',
          type: 'string'
        },
        "Training_Plan_Section_Item__c": {
          name: 'Training_Plan_Section_Item__c',
          type: 'string'
        },
        "User__c": {
          name: 'User__c',
          type: 'string'
        },
        "User_Notes__c": {
          name: 'User_Notes__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
