/* tslint:disable */
import {
  Account,
  Invoice,
  Job,
  Timecard,
  Users,
  PurchaseOrderItem,
  Pricelist,
  Jobsite,
  Project,
  Case,
  Contact,
  WorkOrder,
  Order,
  RequestFormReceipt,
  RequestFormReceiptEscalation
} from '../index';

declare var Object: any;
export interface PurchaseOrderInterface {
  "sfdcId"?: string;
  "AccountId"?: string;
  "Additional_Job_Time_Amount__c"?: number;
  "Address"?: string;
  "Already_Invoiced_Hours__c"?: number;
  "Amount_Already_invoiced__c"?: number;
  "Approved_Timecard_Hours_Overtime__c"?: number;
  "Approved_Timecard_Hours_Standard__c"?: number;
  "Approved_Timecard_Hours_Weekend__c"?: number;
  "AssetId"?: string;
  "BusinessHoursId"?: string;
  "CaseId"?: string;
  "Case_Record_Type__c"?: string;
  "City"?: string;
  "ContactId"?: string;
  "Country"?: string;
  "CreatedById"?: string;
  "CreatedDate"?: Date;
  "CurrencyIsoCode"?: string;
  "Custom_Hourly_SubTotal_Auto_PreTax_FTE__c"?: number;
  "Custom_Price_on_Service_Contract__c"?: boolean;
  "Customer_WO_Num__c"?: string;
  "Description"?: string;
  "Discount"?: number;
  "Dispatch_Automation_Message__c"?: string;
  "Duration"?: number;
  "DurationInMinutes"?: number;
  "DurationType"?: string;
  "EndDate"?: Date;
  "End_Date__c"?: Date;
  "EntitlementId"?: string;
  "Experience_Level__c"?: string;
  "FTE_Monthly_Hours__c"?: number;
  "FTE_Monthly_Rate_Custom__c"?: number;
  "FTE_Monthly_Rate__c"?: number;
  "FTE_Overtime_Hourly_Rate_Holiday__c"?: number;
  "FTE_Overtime_Hourly_Rate_Standard__c"?: number;
  "FTE_Utilization_factor__c"?: string;
  "GeocodeAccuracy"?: string;
  "GrandTotal"?: number;
  "Grand_Total_Total_Frm_Line_Item_Auto__c"?: number;
  "Grand_Total_Total_From_Line_Items__c"?: number;
  "IsClosed"?: boolean;
  "IsDeleted"?: boolean;
  "IsGeneratedFromMaintenancePlan"?: boolean;
  "IsStopped"?: boolean;
  "Job_Service_Type__c"?: string;
  "Jobsite__c"?: string;
  "LOTS__c"?: string;
  "LastModifiedById"?: string;
  "LastModifiedDate"?: Date;
  "LastReferencedDate"?: Date;
  "LastViewedDate"?: Date;
  "Latitude"?: number;
  "LineItemCount"?: number;
  "List_Price_Total_from_Line_Items__c"?: number;
  "LocationId"?: string;
  "Longitude"?: number;
  "MaintenancePlanId"?: string;
  "MilestoneStatus"?: string;
  "MinimumCrewSize"?: number;
  "OwnerId"?: string;
  "PTS__c"?: string;
  "ParentWorkOrderId"?: string;
  "Partner_Case_Number__c"?: string;
  "PostalCode"?: string;
  "Pricebook2Id"?: string;
  "Priority"?: string;
  "Program__c"?: string;
  "Program_has_Vendor_PPE__c"?: boolean;
  "ProjectId__c"?: string;
  "Project_Case_Description__c"?: string;
  "Project_Case_Subject__c"?: string;
  "Project__c"?: string;
  "Purchase_Order__c"?: string;
  "RecommendedCrewSize"?: number;
  "RecordTypeId"?: string;
  "RecordTypeid__c"?: string;
  "Regenerated__c"?: boolean;
  "Request_Form_Receipt__c"?: string;
  "ReturnOrderId"?: string;
  "ReturnOrderLineItemId"?: string;
  "Roll_up_Additional_Rate__c"?: number;
  "Roll_up_Discount__c"?: number;
  "Roll_up_FTE_OT_Holiday__c"?: number;
  "Roll_up_FTE_OT_Standard__c"?: number;
  "Roll_up_PPE_Hours__c"?: number;
  "Roll_up_Unit_Price__c"?: number;
  "Roll_up_Unit_Rate_Custom__c"?: number;
  "Roll_up_Unit_Rate__c"?: number;
  "Roll_up_VAT_Percent__c"?: number;
  "RootWorkOrderId"?: string;
  "SKU_Item__c"?: string;
  "SO_Profit_Percentage__c"?: number;
  "SO_has_Vendor__c"?: boolean;
  "Sales_Order_Type__c"?: string;
  "Sales_Order__c"?: string;
  "ServiceAppointmentCount"?: number;
  "ServiceContractId"?: string;
  "ServiceReportTemplateId"?: string;
  "ServiceTerritoryId"?: string;
  "Service_Assignment__c"?: string;
  "Service_Contract__c"?: string;
  "Service_Dispatch__c"?: string;
  "Service_Level_Agreement_SLA_Requirement__c"?: string;
  "SlaExitDate"?: Date;
  "SlaStartDate"?: Date;
  "SoW_Description_Customer__c"?: string;
  "Special_Instructions__c"?: string;
  "StartDate"?: Date;
  "Start_Date__c"?: Date;
  "State"?: string;
  "Status"?: string;
  "StatusCategory"?: string;
  "StopStartDate"?: Date;
  "Street"?: string;
  "Sub_Type__c"?: string;
  "Subject"?: string;
  "Subtotal"?: number;
  "SuggestedMaintenanceDate"?: Date;
  "Tax"?: number;
  "TotalPrice"?: number;
  "Total_Approved_Timecard_Hours__c"?: number;
  "Total_Approved_Timecards_Amount__c"?: number;
  "Total_Cost_Vendor_Pre_Tax_Auto_FTE__c"?: number;
  "Total_Custom_Cost_Vendor_Pre_Tax_FTE__c"?: number;
  "Total_FTE_Hours__c"?: number;
  "Type__c"?: string;
  "Vendor_PPE_Hours_Profile__c"?: string;
  "Vendor_Pricelist__c"?: string;
  "Vendor__c"?: string;
  "Vendor_has_pricelist__c"?: boolean;
  "WorkOrderNumber"?: string;
  "WorkTypeId"?: string;
  "Work_Order__c"?: string;
  "Work_Order_num__c"?: string;
  "Work_Summary__c"?: string;
  "X1_Hours_minus_Created__c"?: Date;
  "Purchase_Invoice_Status"?: string;
  "Region"?: string;
  "PMS_Case_Status"?: string;
  "Gross_Margin_Amount__c"?: number;
  "Gross_Margin__c"?: number;
  "id"?: number;
  vendor?: Account;
  invoice?: Invoice;
  job?: Job;
  account?: Account;
  timecards?: Timecard[];
  users?: Users;
  skuItems?: PurchaseOrderItem;
  lineItems?: PurchaseOrderItem[];
  pricelist?: Pricelist;
  jobsite?: Jobsite;
  project?: Project;
  case?: Case;
  contact?: Contact;
  workOrder?: WorkOrder;
  order?: Order;
  requestFormReceipt?: RequestFormReceipt;
  requestFormReceiptEscalation?: RequestFormReceiptEscalation[];
}

export class PurchaseOrder implements PurchaseOrderInterface {
  "sfdcId": string;
  "AccountId": string;
  "Additional_Job_Time_Amount__c": number;
  "Address": string;
  "Already_Invoiced_Hours__c": number;
  "Amount_Already_invoiced__c": number;
  "Approved_Timecard_Hours_Overtime__c": number;
  "Approved_Timecard_Hours_Standard__c": number;
  "Approved_Timecard_Hours_Weekend__c": number;
  "AssetId": string;
  "BusinessHoursId": string;
  "CaseId": string;
  "Case_Record_Type__c": string;
  "City": string;
  "ContactId": string;
  "Country": string;
  "CreatedById": string;
  "CreatedDate": Date;
  "CurrencyIsoCode": string;
  "Custom_Hourly_SubTotal_Auto_PreTax_FTE__c": number;
  "Custom_Price_on_Service_Contract__c": boolean;
  "Customer_WO_Num__c": string;
  "Description": string;
  "Discount": number;
  "Dispatch_Automation_Message__c": string;
  "Duration": number;
  "DurationInMinutes": number;
  "DurationType": string;
  "EndDate": Date;
  "End_Date__c": Date;
  "EntitlementId": string;
  "Experience_Level__c": string;
  "FTE_Monthly_Hours__c": number;
  "FTE_Monthly_Rate_Custom__c": number;
  "FTE_Monthly_Rate__c": number;
  "FTE_Overtime_Hourly_Rate_Holiday__c": number;
  "FTE_Overtime_Hourly_Rate_Standard__c": number;
  "FTE_Utilization_factor__c": string;
  "GeocodeAccuracy": string;
  "GrandTotal": number;
  "Grand_Total_Total_Frm_Line_Item_Auto__c": number;
  "Grand_Total_Total_From_Line_Items__c": number;
  "IsClosed": boolean;
  "IsDeleted": boolean;
  "IsGeneratedFromMaintenancePlan": boolean;
  "IsStopped": boolean;
  "Job_Service_Type__c": string;
  "Jobsite__c": string;
  "LOTS__c": string;
  "LastModifiedById": string;
  "LastModifiedDate": Date;
  "LastReferencedDate": Date;
  "LastViewedDate": Date;
  "Latitude": number;
  "LineItemCount": number;
  "List_Price_Total_from_Line_Items__c": number;
  "LocationId": string;
  "Longitude": number;
  "MaintenancePlanId": string;
  "MilestoneStatus": string;
  "MinimumCrewSize": number;
  "OwnerId": string;
  "PTS__c": string;
  "ParentWorkOrderId": string;
  "Partner_Case_Number__c": string;
  "PostalCode": string;
  "Pricebook2Id": string;
  "Priority": string;
  "Program__c": string;
  "Program_has_Vendor_PPE__c": boolean;
  "ProjectId__c": string;
  "Project_Case_Description__c": string;
  "Project_Case_Subject__c": string;
  "Project__c": string;
  "Purchase_Order__c": string;
  "RecommendedCrewSize": number;
  "RecordTypeId": string;
  "RecordTypeid__c": string;
  "Regenerated__c": boolean;
  "Request_Form_Receipt__c": string;
  "ReturnOrderId": string;
  "ReturnOrderLineItemId": string;
  "Roll_up_Additional_Rate__c": number;
  "Roll_up_Discount__c": number;
  "Roll_up_FTE_OT_Holiday__c": number;
  "Roll_up_FTE_OT_Standard__c": number;
  "Roll_up_PPE_Hours__c": number;
  "Roll_up_Unit_Price__c": number;
  "Roll_up_Unit_Rate_Custom__c": number;
  "Roll_up_Unit_Rate__c": number;
  "Roll_up_VAT_Percent__c": number;
  "RootWorkOrderId": string;
  "SKU_Item__c": string;
  "SO_Profit_Percentage__c": number;
  "SO_has_Vendor__c": boolean;
  "Sales_Order_Type__c": string;
  "Sales_Order__c": string;
  "ServiceAppointmentCount": number;
  "ServiceContractId": string;
  "ServiceReportTemplateId": string;
  "ServiceTerritoryId": string;
  "Service_Assignment__c": string;
  "Service_Contract__c": string;
  "Service_Dispatch__c": string;
  "Service_Level_Agreement_SLA_Requirement__c": string;
  "SlaExitDate": Date;
  "SlaStartDate": Date;
  "SoW_Description_Customer__c": string;
  "Special_Instructions__c": string;
  "StartDate": Date;
  "Start_Date__c": Date;
  "State": string;
  "Status": string;
  "StatusCategory": string;
  "StopStartDate": Date;
  "Street": string;
  "Sub_Type__c": string;
  "Subject": string;
  "Subtotal": number;
  "SuggestedMaintenanceDate": Date;
  "Tax": number;
  "TotalPrice": number;
  "Total_Approved_Timecard_Hours__c": number;
  "Total_Approved_Timecards_Amount__c": number;
  "Total_Cost_Vendor_Pre_Tax_Auto_FTE__c": number;
  "Total_Custom_Cost_Vendor_Pre_Tax_FTE__c": number;
  "Total_FTE_Hours__c": number;
  "Type__c": string;
  "Vendor_PPE_Hours_Profile__c": string;
  "Vendor_Pricelist__c": string;
  "Vendor__c": string;
  "Vendor_has_pricelist__c": boolean;
  "WorkOrderNumber": string;
  "WorkTypeId": string;
  "Work_Order__c": string;
  "Work_Order_num__c": string;
  "Work_Summary__c": string;
  "X1_Hours_minus_Created__c": Date;
  "Purchase_Invoice_Status": string;
  "Region": string;
  "PMS_Case_Status": string;
  "Gross_Margin_Amount__c": number;
  "Gross_Margin__c": number;
  "id": number;
  vendor: Account;
  invoice: Invoice;
  job: Job;
  account: Account;
  timecards: Timecard[];
  users: Users;
  skuItems: PurchaseOrderItem;
  lineItems: PurchaseOrderItem[];
  pricelist: Pricelist;
  jobsite: Jobsite;
  project: Project;
  case: Case;
  contact: Contact;
  workOrder: WorkOrder;
  order: Order;
  requestFormReceipt: RequestFormReceipt;
  requestFormReceiptEscalation: RequestFormReceiptEscalation[];
  constructor(data?: PurchaseOrderInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `PurchaseOrder`.
   */
  public static getModelName() {
    return "PurchaseOrder";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of PurchaseOrder for dynamic purposes.
  **/
  public static factory(data: PurchaseOrderInterface): PurchaseOrder{
    return new PurchaseOrder(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'PurchaseOrder',
      plural: 'PurchaseOrders',
      path: 'PurchaseOrders',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "AccountId": {
          name: 'AccountId',
          type: 'string'
        },
        "Additional_Job_Time_Amount__c": {
          name: 'Additional_Job_Time_Amount__c',
          type: 'number'
        },
        "Address": {
          name: 'Address',
          type: 'string'
        },
        "Already_Invoiced_Hours__c": {
          name: 'Already_Invoiced_Hours__c',
          type: 'number'
        },
        "Amount_Already_invoiced__c": {
          name: 'Amount_Already_invoiced__c',
          type: 'number'
        },
        "Approved_Timecard_Hours_Overtime__c": {
          name: 'Approved_Timecard_Hours_Overtime__c',
          type: 'number'
        },
        "Approved_Timecard_Hours_Standard__c": {
          name: 'Approved_Timecard_Hours_Standard__c',
          type: 'number'
        },
        "Approved_Timecard_Hours_Weekend__c": {
          name: 'Approved_Timecard_Hours_Weekend__c',
          type: 'number'
        },
        "AssetId": {
          name: 'AssetId',
          type: 'string'
        },
        "BusinessHoursId": {
          name: 'BusinessHoursId',
          type: 'string'
        },
        "CaseId": {
          name: 'CaseId',
          type: 'string'
        },
        "Case_Record_Type__c": {
          name: 'Case_Record_Type__c',
          type: 'string'
        },
        "City": {
          name: 'City',
          type: 'string'
        },
        "ContactId": {
          name: 'ContactId',
          type: 'string'
        },
        "Country": {
          name: 'Country',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "Custom_Hourly_SubTotal_Auto_PreTax_FTE__c": {
          name: 'Custom_Hourly_SubTotal_Auto_PreTax_FTE__c',
          type: 'number'
        },
        "Custom_Price_on_Service_Contract__c": {
          name: 'Custom_Price_on_Service_Contract__c',
          type: 'boolean'
        },
        "Customer_WO_Num__c": {
          name: 'Customer_WO_Num__c',
          type: 'string'
        },
        "Description": {
          name: 'Description',
          type: 'string'
        },
        "Discount": {
          name: 'Discount',
          type: 'number'
        },
        "Dispatch_Automation_Message__c": {
          name: 'Dispatch_Automation_Message__c',
          type: 'string'
        },
        "Duration": {
          name: 'Duration',
          type: 'number'
        },
        "DurationInMinutes": {
          name: 'DurationInMinutes',
          type: 'number'
        },
        "DurationType": {
          name: 'DurationType',
          type: 'string'
        },
        "EndDate": {
          name: 'EndDate',
          type: 'Date'
        },
        "End_Date__c": {
          name: 'End_Date__c',
          type: 'Date'
        },
        "EntitlementId": {
          name: 'EntitlementId',
          type: 'string'
        },
        "Experience_Level__c": {
          name: 'Experience_Level__c',
          type: 'string'
        },
        "FTE_Monthly_Hours__c": {
          name: 'FTE_Monthly_Hours__c',
          type: 'number'
        },
        "FTE_Monthly_Rate_Custom__c": {
          name: 'FTE_Monthly_Rate_Custom__c',
          type: 'number'
        },
        "FTE_Monthly_Rate__c": {
          name: 'FTE_Monthly_Rate__c',
          type: 'number'
        },
        "FTE_Overtime_Hourly_Rate_Holiday__c": {
          name: 'FTE_Overtime_Hourly_Rate_Holiday__c',
          type: 'number'
        },
        "FTE_Overtime_Hourly_Rate_Standard__c": {
          name: 'FTE_Overtime_Hourly_Rate_Standard__c',
          type: 'number'
        },
        "FTE_Utilization_factor__c": {
          name: 'FTE_Utilization_factor__c',
          type: 'string'
        },
        "GeocodeAccuracy": {
          name: 'GeocodeAccuracy',
          type: 'string'
        },
        "GrandTotal": {
          name: 'GrandTotal',
          type: 'number'
        },
        "Grand_Total_Total_Frm_Line_Item_Auto__c": {
          name: 'Grand_Total_Total_Frm_Line_Item_Auto__c',
          type: 'number'
        },
        "Grand_Total_Total_From_Line_Items__c": {
          name: 'Grand_Total_Total_From_Line_Items__c',
          type: 'number'
        },
        "IsClosed": {
          name: 'IsClosed',
          type: 'boolean'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "IsGeneratedFromMaintenancePlan": {
          name: 'IsGeneratedFromMaintenancePlan',
          type: 'boolean'
        },
        "IsStopped": {
          name: 'IsStopped',
          type: 'boolean'
        },
        "Job_Service_Type__c": {
          name: 'Job_Service_Type__c',
          type: 'string'
        },
        "Jobsite__c": {
          name: 'Jobsite__c',
          type: 'string'
        },
        "LOTS__c": {
          name: 'LOTS__c',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "LastReferencedDate": {
          name: 'LastReferencedDate',
          type: 'Date'
        },
        "LastViewedDate": {
          name: 'LastViewedDate',
          type: 'Date'
        },
        "Latitude": {
          name: 'Latitude',
          type: 'number'
        },
        "LineItemCount": {
          name: 'LineItemCount',
          type: 'number'
        },
        "List_Price_Total_from_Line_Items__c": {
          name: 'List_Price_Total_from_Line_Items__c',
          type: 'number'
        },
        "LocationId": {
          name: 'LocationId',
          type: 'string'
        },
        "Longitude": {
          name: 'Longitude',
          type: 'number'
        },
        "MaintenancePlanId": {
          name: 'MaintenancePlanId',
          type: 'string'
        },
        "MilestoneStatus": {
          name: 'MilestoneStatus',
          type: 'string'
        },
        "MinimumCrewSize": {
          name: 'MinimumCrewSize',
          type: 'number'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "PTS__c": {
          name: 'PTS__c',
          type: 'string'
        },
        "ParentWorkOrderId": {
          name: 'ParentWorkOrderId',
          type: 'string'
        },
        "Partner_Case_Number__c": {
          name: 'Partner_Case_Number__c',
          type: 'string'
        },
        "PostalCode": {
          name: 'PostalCode',
          type: 'string'
        },
        "Pricebook2Id": {
          name: 'Pricebook2Id',
          type: 'string'
        },
        "Priority": {
          name: 'Priority',
          type: 'string'
        },
        "Program__c": {
          name: 'Program__c',
          type: 'string'
        },
        "Program_has_Vendor_PPE__c": {
          name: 'Program_has_Vendor_PPE__c',
          type: 'boolean'
        },
        "ProjectId__c": {
          name: 'ProjectId__c',
          type: 'string'
        },
        "Project_Case_Description__c": {
          name: 'Project_Case_Description__c',
          type: 'string'
        },
        "Project_Case_Subject__c": {
          name: 'Project_Case_Subject__c',
          type: 'string'
        },
        "Project__c": {
          name: 'Project__c',
          type: 'string'
        },
        "Purchase_Order__c": {
          name: 'Purchase_Order__c',
          type: 'string'
        },
        "RecommendedCrewSize": {
          name: 'RecommendedCrewSize',
          type: 'number'
        },
        "RecordTypeId": {
          name: 'RecordTypeId',
          type: 'string'
        },
        "RecordTypeid__c": {
          name: 'RecordTypeid__c',
          type: 'string'
        },
        "Regenerated__c": {
          name: 'Regenerated__c',
          type: 'boolean'
        },
        "Request_Form_Receipt__c": {
          name: 'Request_Form_Receipt__c',
          type: 'string'
        },
        "ReturnOrderId": {
          name: 'ReturnOrderId',
          type: 'string'
        },
        "ReturnOrderLineItemId": {
          name: 'ReturnOrderLineItemId',
          type: 'string'
        },
        "Roll_up_Additional_Rate__c": {
          name: 'Roll_up_Additional_Rate__c',
          type: 'number'
        },
        "Roll_up_Discount__c": {
          name: 'Roll_up_Discount__c',
          type: 'number'
        },
        "Roll_up_FTE_OT_Holiday__c": {
          name: 'Roll_up_FTE_OT_Holiday__c',
          type: 'number'
        },
        "Roll_up_FTE_OT_Standard__c": {
          name: 'Roll_up_FTE_OT_Standard__c',
          type: 'number'
        },
        "Roll_up_PPE_Hours__c": {
          name: 'Roll_up_PPE_Hours__c',
          type: 'number'
        },
        "Roll_up_Unit_Price__c": {
          name: 'Roll_up_Unit_Price__c',
          type: 'number'
        },
        "Roll_up_Unit_Rate_Custom__c": {
          name: 'Roll_up_Unit_Rate_Custom__c',
          type: 'number'
        },
        "Roll_up_Unit_Rate__c": {
          name: 'Roll_up_Unit_Rate__c',
          type: 'number'
        },
        "Roll_up_VAT_Percent__c": {
          name: 'Roll_up_VAT_Percent__c',
          type: 'number'
        },
        "RootWorkOrderId": {
          name: 'RootWorkOrderId',
          type: 'string'
        },
        "SKU_Item__c": {
          name: 'SKU_Item__c',
          type: 'string'
        },
        "SO_Profit_Percentage__c": {
          name: 'SO_Profit_Percentage__c',
          type: 'number'
        },
        "SO_has_Vendor__c": {
          name: 'SO_has_Vendor__c',
          type: 'boolean'
        },
        "Sales_Order_Type__c": {
          name: 'Sales_Order_Type__c',
          type: 'string'
        },
        "Sales_Order__c": {
          name: 'Sales_Order__c',
          type: 'string'
        },
        "ServiceAppointmentCount": {
          name: 'ServiceAppointmentCount',
          type: 'number'
        },
        "ServiceContractId": {
          name: 'ServiceContractId',
          type: 'string'
        },
        "ServiceReportTemplateId": {
          name: 'ServiceReportTemplateId',
          type: 'string'
        },
        "ServiceTerritoryId": {
          name: 'ServiceTerritoryId',
          type: 'string'
        },
        "Service_Assignment__c": {
          name: 'Service_Assignment__c',
          type: 'string'
        },
        "Service_Contract__c": {
          name: 'Service_Contract__c',
          type: 'string'
        },
        "Service_Dispatch__c": {
          name: 'Service_Dispatch__c',
          type: 'string'
        },
        "Service_Level_Agreement_SLA_Requirement__c": {
          name: 'Service_Level_Agreement_SLA_Requirement__c',
          type: 'string'
        },
        "SlaExitDate": {
          name: 'SlaExitDate',
          type: 'Date'
        },
        "SlaStartDate": {
          name: 'SlaStartDate',
          type: 'Date'
        },
        "SoW_Description_Customer__c": {
          name: 'SoW_Description_Customer__c',
          type: 'string'
        },
        "Special_Instructions__c": {
          name: 'Special_Instructions__c',
          type: 'string'
        },
        "StartDate": {
          name: 'StartDate',
          type: 'Date'
        },
        "Start_Date__c": {
          name: 'Start_Date__c',
          type: 'Date'
        },
        "State": {
          name: 'State',
          type: 'string'
        },
        "Status": {
          name: 'Status',
          type: 'string'
        },
        "StatusCategory": {
          name: 'StatusCategory',
          type: 'string'
        },
        "StopStartDate": {
          name: 'StopStartDate',
          type: 'Date'
        },
        "Street": {
          name: 'Street',
          type: 'string'
        },
        "Sub_Type__c": {
          name: 'Sub_Type__c',
          type: 'string'
        },
        "Subject": {
          name: 'Subject',
          type: 'string'
        },
        "Subtotal": {
          name: 'Subtotal',
          type: 'number'
        },
        "SuggestedMaintenanceDate": {
          name: 'SuggestedMaintenanceDate',
          type: 'Date'
        },
        "Tax": {
          name: 'Tax',
          type: 'number'
        },
        "TotalPrice": {
          name: 'TotalPrice',
          type: 'number'
        },
        "Total_Approved_Timecard_Hours__c": {
          name: 'Total_Approved_Timecard_Hours__c',
          type: 'number'
        },
        "Total_Approved_Timecards_Amount__c": {
          name: 'Total_Approved_Timecards_Amount__c',
          type: 'number'
        },
        "Total_Cost_Vendor_Pre_Tax_Auto_FTE__c": {
          name: 'Total_Cost_Vendor_Pre_Tax_Auto_FTE__c',
          type: 'number'
        },
        "Total_Custom_Cost_Vendor_Pre_Tax_FTE__c": {
          name: 'Total_Custom_Cost_Vendor_Pre_Tax_FTE__c',
          type: 'number'
        },
        "Total_FTE_Hours__c": {
          name: 'Total_FTE_Hours__c',
          type: 'number'
        },
        "Type__c": {
          name: 'Type__c',
          type: 'string'
        },
        "Vendor_PPE_Hours_Profile__c": {
          name: 'Vendor_PPE_Hours_Profile__c',
          type: 'string'
        },
        "Vendor_Pricelist__c": {
          name: 'Vendor_Pricelist__c',
          type: 'string'
        },
        "Vendor__c": {
          name: 'Vendor__c',
          type: 'string'
        },
        "Vendor_has_pricelist__c": {
          name: 'Vendor_has_pricelist__c',
          type: 'boolean'
        },
        "WorkOrderNumber": {
          name: 'WorkOrderNumber',
          type: 'string'
        },
        "WorkTypeId": {
          name: 'WorkTypeId',
          type: 'string'
        },
        "Work_Order__c": {
          name: 'Work_Order__c',
          type: 'string'
        },
        "Work_Order_num__c": {
          name: 'Work_Order_num__c',
          type: 'string'
        },
        "Work_Summary__c": {
          name: 'Work_Summary__c',
          type: 'string'
        },
        "X1_Hours_minus_Created__c": {
          name: 'X1_Hours_minus_Created__c',
          type: 'Date'
        },
        "Purchase_Invoice_Status": {
          name: 'Purchase_Invoice_Status',
          type: 'string'
        },
        "Region": {
          name: 'Region',
          type: 'string'
        },
        "PMS_Case_Status": {
          name: 'PMS_Case_Status',
          type: 'string'
        },
        "Gross_Margin_Amount__c": {
          name: 'Gross_Margin_Amount__c',
          type: 'number'
        },
        "Gross_Margin__c": {
          name: 'Gross_Margin__c',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        vendor: {
          name: 'vendor',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'Vendor__c',
          keyTo: 'sfdcId'
        },
        invoice: {
          name: 'invoice',
          type: 'Invoice',
          model: 'Invoice',
          relationType: 'belongsTo',
                  keyFrom: 'sfdcId',
          keyTo: 'Purchase_Order__c'
        },
        job: {
          name: 'job',
          type: 'Job',
          model: 'Job',
          relationType: 'belongsTo',
                  keyFrom: 'Service_Dispatch__c',
          keyTo: 'sfdcId'
        },
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'AccountId',
          keyTo: 'sfdcId'
        },
        timecards: {
          name: 'timecards',
          type: 'Timecard[]',
          model: 'Timecard',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'Purchase_Order_Ref__c'
        },
        users: {
          name: 'users',
          type: 'Users',
          model: 'Users',
          relationType: 'belongsTo',
                  keyFrom: 'CreatedById',
          keyTo: 'sfdcId'
        },
        skuItems: {
          name: 'skuItems',
          type: 'PurchaseOrderItem',
          model: 'PurchaseOrderItem',
          relationType: 'belongsTo',
                  keyFrom: 'SKU_Item__c',
          keyTo: 'sfdcId'
        },
        lineItems: {
          name: 'lineItems',
          type: 'PurchaseOrderItem[]',
          model: 'PurchaseOrderItem',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'WorkOrderId'
        },
        pricelist: {
          name: 'pricelist',
          type: 'Pricelist',
          model: 'Pricelist',
          relationType: 'belongsTo',
                  keyFrom: 'Vendor_Pricelist__c',
          keyTo: 'sfdcId'
        },
        jobsite: {
          name: 'jobsite',
          type: 'Jobsite',
          model: 'Jobsite',
          relationType: 'belongsTo',
                  keyFrom: 'Jobsite__c',
          keyTo: 'sfdcId'
        },
        project: {
          name: 'project',
          type: 'Project',
          model: 'Project',
          relationType: 'belongsTo',
                  keyFrom: 'Project__c',
          keyTo: 'sfdcId'
        },
        case: {
          name: 'case',
          type: 'Case',
          model: 'Case',
          relationType: 'belongsTo',
                  keyFrom: 'CaseId',
          keyTo: 'sfdcId'
        },
        contact: {
          name: 'contact',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'ContactId',
          keyTo: 'sfdcId'
        },
        workOrder: {
          name: 'workOrder',
          type: 'WorkOrder',
          model: 'WorkOrder',
          relationType: 'belongsTo',
                  keyFrom: 'Work_Order__c',
          keyTo: 'sfdcId'
        },
        order: {
          name: 'order',
          type: 'Order',
          model: 'Order',
          relationType: 'belongsTo',
                  keyFrom: 'Sales_Order__c',
          keyTo: 'sfdcId'
        },
        requestFormReceipt: {
          name: 'requestFormReceipt',
          type: 'RequestFormReceipt',
          model: 'RequestFormReceipt',
          relationType: 'hasOne',
                  keyFrom: 'sfdcId',
          keyTo: 'Work_Order__c'
        },
        requestFormReceiptEscalation: {
          name: 'requestFormReceiptEscalation',
          type: 'RequestFormReceiptEscalation[]',
          model: 'RequestFormReceiptEscalation',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'Purchase_Order__c'
        },
      }
    }
  }
}
