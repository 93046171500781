/* tslint:disable */

declare var Object: any;
export interface ProgramHolidayApprovedCalendarInterface {
  "sfdcId"?: string;
  "CreatedById"?: string;
  "CurrencyIsoCode"?: string;
  "LastModifiedById"?: string;
  "Name"?: string;
  "OwnerId"?: string;
  "All_States__c"?: boolean;
  "All_States_DELETE__c"?: boolean;
  "Country__c"?: string;
  "Created_By_Contact__c"?: string;
  "Custom_All_States__c"?: boolean;
  "Custom_Holiday__c"?: string;
  "Custom_Holiday_Date__c"?: Date;
  "Custom_Holiday_Type__c"?: string;
  "Holiday__c"?: string;
  "Holiday_Date_Formula_field__c"?: string;
  "Holiday_Formula_Field__c"?: string;
  "Holiday_Month_Formula_field__c"?: string;
  "Holiday_Type_Formula_Field__c"?: string;
  "IRON_ExtID__c"?: string;
  "Is_Holiday__c"?: boolean;
  "Jobsite__c"?: string;
  "Partner_Account__c"?: string;
  "PHAC_Key__c"?: string;
  "Program__c"?: string;
  "State__c"?: string;
  "State_Abbrev__c"?: string;
  "State_Name__c"?: string;
  "Type__c"?: string;
  "Year__c"?: number;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class ProgramHolidayApprovedCalendar implements ProgramHolidayApprovedCalendarInterface {
  "sfdcId": string;
  "CreatedById": string;
  "CurrencyIsoCode": string;
  "LastModifiedById": string;
  "Name": string;
  "OwnerId": string;
  "All_States__c": boolean;
  "All_States_DELETE__c": boolean;
  "Country__c": string;
  "Created_By_Contact__c": string;
  "Custom_All_States__c": boolean;
  "Custom_Holiday__c": string;
  "Custom_Holiday_Date__c": Date;
  "Custom_Holiday_Type__c": string;
  "Holiday__c": string;
  "Holiday_Date_Formula_field__c": string;
  "Holiday_Formula_Field__c": string;
  "Holiday_Month_Formula_field__c": string;
  "Holiday_Type_Formula_Field__c": string;
  "IRON_ExtID__c": string;
  "Is_Holiday__c": boolean;
  "Jobsite__c": string;
  "Partner_Account__c": string;
  "PHAC_Key__c": string;
  "Program__c": string;
  "State__c": string;
  "State_Abbrev__c": string;
  "State_Name__c": string;
  "Type__c": string;
  "Year__c": number;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: ProgramHolidayApprovedCalendarInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ProgramHolidayApprovedCalendar`.
   */
  public static getModelName() {
    return "ProgramHolidayApprovedCalendar";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ProgramHolidayApprovedCalendar for dynamic purposes.
  **/
  public static factory(data: ProgramHolidayApprovedCalendarInterface): ProgramHolidayApprovedCalendar{
    return new ProgramHolidayApprovedCalendar(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ProgramHolidayApprovedCalendar',
      plural: 'ProgramHolidayApprovedCalendars',
      path: 'ProgramHolidayApprovedCalendars',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "All_States__c": {
          name: 'All_States__c',
          type: 'boolean',
          default: false
        },
        "All_States_DELETE__c": {
          name: 'All_States_DELETE__c',
          type: 'boolean',
          default: false
        },
        "Country__c": {
          name: 'Country__c',
          type: 'string'
        },
        "Created_By_Contact__c": {
          name: 'Created_By_Contact__c',
          type: 'string'
        },
        "Custom_All_States__c": {
          name: 'Custom_All_States__c',
          type: 'boolean',
          default: false
        },
        "Custom_Holiday__c": {
          name: 'Custom_Holiday__c',
          type: 'string'
        },
        "Custom_Holiday_Date__c": {
          name: 'Custom_Holiday_Date__c',
          type: 'Date'
        },
        "Custom_Holiday_Type__c": {
          name: 'Custom_Holiday_Type__c',
          type: 'string'
        },
        "Holiday__c": {
          name: 'Holiday__c',
          type: 'string'
        },
        "Holiday_Date_Formula_field__c": {
          name: 'Holiday_Date_Formula_field__c',
          type: 'string'
        },
        "Holiday_Formula_Field__c": {
          name: 'Holiday_Formula_Field__c',
          type: 'string'
        },
        "Holiday_Month_Formula_field__c": {
          name: 'Holiday_Month_Formula_field__c',
          type: 'string'
        },
        "Holiday_Type_Formula_Field__c": {
          name: 'Holiday_Type_Formula_Field__c',
          type: 'string'
        },
        "IRON_ExtID__c": {
          name: 'IRON_ExtID__c',
          type: 'string'
        },
        "Is_Holiday__c": {
          name: 'Is_Holiday__c',
          type: 'boolean',
          default: false
        },
        "Jobsite__c": {
          name: 'Jobsite__c',
          type: 'string'
        },
        "Partner_Account__c": {
          name: 'Partner_Account__c',
          type: 'string'
        },
        "PHAC_Key__c": {
          name: 'PHAC_Key__c',
          type: 'string'
        },
        "Program__c": {
          name: 'Program__c',
          type: 'string'
        },
        "State__c": {
          name: 'State__c',
          type: 'string'
        },
        "State_Abbrev__c": {
          name: 'State_Abbrev__c',
          type: 'string'
        },
        "State_Name__c": {
          name: 'State_Name__c',
          type: 'string'
        },
        "Type__c": {
          name: 'Type__c',
          type: 'string'
        },
        "Year__c": {
          name: 'Year__c',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
