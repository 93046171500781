/* tslint:disable */

declare var Object: any;
export interface JobWorkerRatingInterface {
  "sfdcId"?: string;
  "CreatedById"?: string;
  "CurrencyIsoCode"?: string;
  "LastModifiedById"?: string;
  "Name"?: string;
  "Communication__c"?: number;
  "Communication_Customer_POC__c"?: string;
  "Communication_PGMO__c"?: string;
  "Deliverables__c"?: number;
  "Job__c"?: string;
  "On_Time__c"?: number;
  "Overall_Rating__c"?: number;
  "Overall_Rating_Sec__c"?: number;
  "Professionalism__c"?: number;
  "Professionalism_Customer_POC__c"?: string;
  "Professionalism_PGMO__c"?: string;
  "Quality__c"?: number;
  "Quality_Customer_POC__c"?: string;
  "Quality_PGMO__c"?: string;
  "Rating_By__c"?: string;
  "Review__c"?: string;
  "Review_Customer_POC__c"?: string;
  "Review_PGMO__c"?: string;
  "Satisfaction__c"?: number;
  "Value__c"?: string;
  "Value_Customer_POC__c"?: string;
  "Value_PGMO__c"?: string;
  "Worker__c"?: string;
  "Worker_Arrival_On_Time_PGMO__c"?: string;
  "Worker_Arrival_On_Time_POC__c"?: string;
  "Worker_Arrival_On_Time__c"?: number;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class JobWorkerRating implements JobWorkerRatingInterface {
  "sfdcId": string;
  "CreatedById": string;
  "CurrencyIsoCode": string;
  "LastModifiedById": string;
  "Name": string;
  "Communication__c": number;
  "Communication_Customer_POC__c": string;
  "Communication_PGMO__c": string;
  "Deliverables__c": number;
  "Job__c": string;
  "On_Time__c": number;
  "Overall_Rating__c": number;
  "Overall_Rating_Sec__c": number;
  "Professionalism__c": number;
  "Professionalism_Customer_POC__c": string;
  "Professionalism_PGMO__c": string;
  "Quality__c": number;
  "Quality_Customer_POC__c": string;
  "Quality_PGMO__c": string;
  "Rating_By__c": string;
  "Review__c": string;
  "Review_Customer_POC__c": string;
  "Review_PGMO__c": string;
  "Satisfaction__c": number;
  "Value__c": string;
  "Value_Customer_POC__c": string;
  "Value_PGMO__c": string;
  "Worker__c": string;
  "Worker_Arrival_On_Time_PGMO__c": string;
  "Worker_Arrival_On_Time_POC__c": string;
  "Worker_Arrival_On_Time__c": number;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: JobWorkerRatingInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `JobWorkerRating`.
   */
  public static getModelName() {
    return "JobWorkerRating";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of JobWorkerRating for dynamic purposes.
  **/
  public static factory(data: JobWorkerRatingInterface): JobWorkerRating{
    return new JobWorkerRating(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'JobWorkerRating',
      plural: 'JobWorkerRatings',
      path: 'JobWorkerRatings',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "Communication__c": {
          name: 'Communication__c',
          type: 'number'
        },
        "Communication_Customer_POC__c": {
          name: 'Communication_Customer_POC__c',
          type: 'string'
        },
        "Communication_PGMO__c": {
          name: 'Communication_PGMO__c',
          type: 'string'
        },
        "Deliverables__c": {
          name: 'Deliverables__c',
          type: 'number'
        },
        "Job__c": {
          name: 'Job__c',
          type: 'string'
        },
        "On_Time__c": {
          name: 'On_Time__c',
          type: 'number'
        },
        "Overall_Rating__c": {
          name: 'Overall_Rating__c',
          type: 'number'
        },
        "Overall_Rating_Sec__c": {
          name: 'Overall_Rating_Sec__c',
          type: 'number'
        },
        "Professionalism__c": {
          name: 'Professionalism__c',
          type: 'number'
        },
        "Professionalism_Customer_POC__c": {
          name: 'Professionalism_Customer_POC__c',
          type: 'string'
        },
        "Professionalism_PGMO__c": {
          name: 'Professionalism_PGMO__c',
          type: 'string'
        },
        "Quality__c": {
          name: 'Quality__c',
          type: 'number'
        },
        "Quality_Customer_POC__c": {
          name: 'Quality_Customer_POC__c',
          type: 'string'
        },
        "Quality_PGMO__c": {
          name: 'Quality_PGMO__c',
          type: 'string'
        },
        "Rating_By__c": {
          name: 'Rating_By__c',
          type: 'string'
        },
        "Review__c": {
          name: 'Review__c',
          type: 'string'
        },
        "Review_Customer_POC__c": {
          name: 'Review_Customer_POC__c',
          type: 'string'
        },
        "Review_PGMO__c": {
          name: 'Review_PGMO__c',
          type: 'string'
        },
        "Satisfaction__c": {
          name: 'Satisfaction__c',
          type: 'number'
        },
        "Value__c": {
          name: 'Value__c',
          type: 'string'
        },
        "Value_Customer_POC__c": {
          name: 'Value_Customer_POC__c',
          type: 'string'
        },
        "Value_PGMO__c": {
          name: 'Value_PGMO__c',
          type: 'string'
        },
        "Worker__c": {
          name: 'Worker__c',
          type: 'string'
        },
        "Worker_Arrival_On_Time_PGMO__c": {
          name: 'Worker_Arrival_On_Time_PGMO__c',
          type: 'string'
        },
        "Worker_Arrival_On_Time_POC__c": {
          name: 'Worker_Arrival_On_Time_POC__c',
          type: 'string'
        },
        "Worker_Arrival_On_Time__c": {
          name: 'Worker_Arrival_On_Time__c',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
