import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BaseVendorViewComponent } from 'msp/shared/base-vendor-view/base-vendor-view.component';
import { Subscription } from 'rxjs';
import { AlertService } from 'shared/services/alert.service';
import { AppStateService } from 'shared/services/app-state.service';
import { CommonService } from 'shared/services/common.service';
import { GlobalChatService } from 'shared/services/global-chat.service';
import { MessageSocketService } from 'shared/services/message-socket.service';
import { MessageService } from 'shared/services/message.service';
import { PreloaderService } from 'shared/services/preloader.service';
import { CommentApi } from './../../../../sdk';

@Component({
  selector: 'app-job-message-list',
  templateUrl: './job-message-list.component.html',
  styleUrls: ['./job-message-list.component.css']
})
export class JobMessageListComponent extends BaseVendorViewComponent implements OnInit, OnDestroy {
  @Output() favUpdated = new EventEmitter();
  @Output() selected = new EventEmitter();
  @Output() savedFilterApplied = new EventEmitter();
  @Input() selectedTab: string;
  @Input() enableFilters: any = [];
  viewAsPartner: boolean;
  viewAsInfo: any;
  @Input()
  set refresh(e) {
    if (e && this.filterObj && this.filterObj['listType'] === 'FavJob') {
      this.onRefresh();
    }
  }
  @Input()
  set filterData(e) {
    this.listData = [];
    if (e) {
      this._loader.showPreloader();
      this.searchCaseMessages(e);
    }
  }
  @Input() type = '';
  @Input() isPinningEnabled;
  @Input() set dispatchJobIds(e) {
    this.rhsPage = true;
    if (e && e.length) {
      this.errorMessage = '';
      this.jobIds = e;
      this.loadListData();
    } else {
      this.errorMessage = 'No message found';
      this.jobIds = [];
      this.listData = [];
      this.pinnedData = [];
    }
  };
  @Input() autoSelected = true;
  listData = [];
  pinnedData = [];
  activeId: any;
  selectedIndex: number;
  isLoadMore = false;
  listType: String;
  limit = 100;
  filterObj = {};
  createdAt = null;
  showFilter = false;
  accessType: any;
  reloadList = false;
  defaultToggleView = true; // default 5 line
  private _statusCss = {
    'In-Progress': 'badge-warning',
    'Resolved': 'badge-info',
    'New': 'badge-danger',
    'Closed': 'badge-success'
  };
  private timerSub: Subscription;
  errorMessage = '';
  selectedTabId: String;
  filterCondition: any = {};
  PINNED_MESSAGE_ID: any;
  alreadyLoading: any = false;
  jobIds: any;
  rhsPage: any;
  private messageSubscription: Subscription;

  private _gotNewMessagesFromSocket = false;
  private _ticker;

  constructor(
    private _commentApi: CommentApi,
    private _loader: PreloaderService,
    private _alertService: AlertService,
    private _appState: AppStateService,
    private _commonService: CommonService,
    private _messageService: MessageService,
    private _globalChatService: GlobalChatService,
  ) {
    super(_appState);
    // this._messageService.setMsgViewType(true);
    this.accessType = this._appState.getAccessType();
    // To check if view as PMS mode is activated.
    if (!this.viewAsVendor && localStorage.getItem('viewAsInfo')) {
      this.viewAsInfo = JSON.parse(localStorage.getItem('viewAsInfo'));
      this.viewAsPartner = (this.viewAsInfo && this.viewAsInfo.viewAsType === 'Partner');
      this.accessType = (this.viewAsPartner) ? 'partner' : this.accessType;
    } else if(this.viewAsVendor) {
      this.accessType = 'vendor';
    }
  }

  initInterval() {
    let that = this;
    if(!this._ticker){
      this._ticker = setInterval(function () {
        // your code below
        if(that._gotNewMessagesFromSocket){
          that.loadListData(false);
          that._gotNewMessagesFromSocket = false;
        }
      }, 120000);
    }
  }

  ngOnInit() {
    // console.log('JOB LIST COMPONENT..');
    // to load already selected from storage
    this.initInterval();
    this.filterCondition = this._commonService.getGlobalFilter(this.enableFilters);
    if (Object.keys(this.filterCondition).length) {
      this.savedFilterApplied.emit(true);
    } else {
      this.savedFilterApplied.emit(false);
    }
    if (this.filterCondition['Customer__c']) {
      if (this.filterCondition['CKSW_BASE__Account__c']) {
        this.filterCondition['CKSW_BASE__Account__c']['inq'] = this.filterCondition['CKSW_BASE__Account__c']['inq'].concat(this.filterCondition['Customer__c']['inq']);
      } else {
        this.filterCondition['CKSW_BASE__Account__c '] = this.filterCondition['Customer__c'];
      }
      delete this.filterCondition['Customer__c'];
    }
    this.setProgramViewAsPMS();
    this.PINNED_MESSAGE_ID = this.selectedTab === 'caseWoTab' ? 'PINNED_WO_MESSAGE_ID' : 'PINNED_MESSAGE_ID';

    if (!this.jobIds) {
      this.loadListData(true);
      this.getRealtimeMessage();
    }

  }
  /**
   * Change list view
   * @param e default 5 line or 7 line
   */
  toggleListView() {
    this.defaultToggleView = !this.defaultToggleView;
    // this._messageService.setMsgViewType(this.defaultToggleView);
  }

  loadListData(loader = true) {
    if (!this.type) {
      return
    }
    if (this.type === 'favourite') {
      this.filterObj['listType'] = 'FavJob';
    } else if (this.type === 'escalation') {
      this.filterObj['listType'] = 'Escalation';
    } else if (this.type === 'myCases') {
      this.filterObj['listType'] = 'MyJob';
    } else if (this.type === 'taskCases') {
      this.filterObj['listType'] = 'TaskCase';
    }
    this.createdAt = '';
    this.unsubscribeTimer();
    delete this.filterObj['createdAt'];
    if (loader) {
      this._loader.showPreloader();
    }
    this.subscribeJob(0, false);
    /*
    this.timerSub = Observable.timer(0, 30000).subscribe(() => {
      this.subscribeJob(0, false)
    });
    */
  }


  /**load Job message console list data */
  subscribeJob(skip, loadMore) {
    if (this.createdAt && !loadMore) {
      this.filterObj['createdAt'] = { gte: this.createdAt };
      // this._messageService.setCountCall(true);
    } else {
      this._loader.showPreloader();
    }
    if (this.accessType === 'vendor') {
      this.filterObj['modelName'] = 'Job';
    }
    if (this.filterCondition && this.filterCondition['Iron_Case_Number__c'] !== undefined) {
      this.filterObj['Iron_Case_Number__c'] = this.filterCondition['Iron_Case_Number__c'];
    } else {
      delete this.filterObj['Iron_Case_Number__c'];
    }
    /*To get  programPMCConsoleTab */
    if (this.selectedTab === 'programPMCConsoleTab') {
      this.filterObj['commentType'] = 'pmc';
      this.filterObj['modelName'] = 'Job';
    }
      /*To get  billingSOPOConsole*/
    if (this.selectedTab === 'billingSOPOConsole') {
      this.filterObj['commentType'] = 'billing';
      this.filterObj['modelName'] = 'Job';
    }
    const filters = {
      where: this.filterObj,
      limit: this.limit,
      skip: skip
    };
    if (this.filterCondition && Object.keys(this.filterCondition).length) {
      delete this.filterCondition['Iron_Case_Number__c'];
      const preFilterObj = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : {};
      if (this.filterCondition['Jobsite__c'] && this.filterCondition['Jobsite__c']['inq'] &&
        preFilterObj['geoMetroJobSite'] && preFilterObj['geoMetroJobSite'].length && !this.enableFilters.includes('geoMetro')) {
        const jobSite = this.filterCondition['Jobsite__c']['inq'].filter(Boolean).filter(element => !preFilterObj['geoMetroJobSite'].includes(element));
        const localFilterCondition = this.filterCondition;
        if (jobSite && jobSite.length) {
          localFilterCondition['Jobsite__c']['inq'] = jobSite;
        } else {
          delete localFilterCondition['Jobsite__c'];
        }
        filters['jobFilter'] = localFilterCondition;
      } else {
        filters['jobFilter'] = this.filterCondition;
      }
    }

    // This is use for internal user chat room msg
    if (this.accessType === 'internal') {
      filters['enableChatRoom'] = true;
    }

    // It's work only when select case msg for MSP/Work order
    if (this.selectedTab === 'caseWoTab' || this.selectedTab === 'favouriteWoTab' || this.selectedTab === 'escalation' || this.selectedTab === 'programPMCConsoleTab' || this.selectedTab === 'billingSOPOConsole') {
      filters['enableWorkOrderChat'] = true;
    }
    if (this.type === 'favourite' || this.type === 'myCases') {
      delete filters['jobFilter'];
    }

    if (this.jobIds && this.jobIds.length) {
      filters['jobFilter'] = { id: { inq: this.jobIds } };
    }

    /* To get only case data in viewAsPMS mode as it is for actual PMS user */
    if (this.viewAsPartner) {
      filters['viewAsPartner'] = (this.type === 'favourite') ? false : true;
    }
    this._commentApi.getDistinctByType(filters).subscribe(async _data => {
      if (this.filterObj['createdAt']) {
        this.unshiftDataInList(_data);
      } else {
        this.listData = [..._data];
        if (this.autoSelected) {
          await this.selectFirstRecord();
        } else if (!this.autoSelected) {
          // get new index selected active id
          try {
            const index = this.listData.findIndex(item => item._id === this.activeId);
            this.selectedIndex = index; // selectedIndex
          } catch (error) {
            console.log('Error while getting index of current selected element in list ', error)
          }
        }
        if (this.filterCondition && Object.keys(this.filterCondition).length === 1 && this.filterCondition['Iron_Job_num__c']) {
          this.isLoadMore = false;
        } else {
          // this.isLoadMore = _data.length < this.limit ? false : true;
          this.isLoadMore = _data.length > 0 ? true : false;
        }
        if (this.type === 'all') {
          this.removePinnedRecords();
        }
      }
      this.createdAt = new Date();
      this.alreadyLoading = false;
      this._loader.hidePreloader();

    }, err => {
      this.alreadyLoading = false;
      this._loader.hidePreloader();
    });
  }

  /**
   * Remove all the pinned messages from current message list and push them in pinned data list
   */
  removePinnedRecords() {
    this.pinnedData = [];
    if (localStorage.getItem(this.PINNED_MESSAGE_ID)) {
      const localStoragePinnedData = JSON.parse(localStorage.getItem(this.PINNED_MESSAGE_ID));
      localStoragePinnedData.forEach(item => {
        this.listData.forEach((data, index) => {
          if (data && (data.modelId === item.id && data.modelName === item.modelName)) {
            const pinnedData = this.listData.splice(index, 1);
            if (pinnedData && pinnedData.length) {
              this.pinnedData.unshift(pinnedData[0]);
            }
          }
        })
      })
      if (this.pinnedData.length !== localStoragePinnedData.length) {
        this.getPinnedJobs();
      }
    }
  }

  /**
   * Get the data of pinned jobs
   */
  getPinnedJobs() {
    const localStoragePinnedData = JSON.parse(localStorage.getItem(this.PINNED_MESSAGE_ID));
    const case_c = [];
    localStoragePinnedData.forEach(item => {
      case_c.push(item.Case__c);
      if (item.PMC_Case_Number) {
        case_c.push(item.PMC_Case_Number);
      }
    })
    const filters = {
      where: {
        'Case__c': { '$in': case_c }
      },
      limit: this.limit,
      skip: 0
    };
    // This is use for internal user chat room msg
    if (this.accessType === 'internal') {
      filters['enableChatRoom'] = true;
    }

    // It's work only when select case msg for MSP/Work order
    if (this.selectedTab === 'caseWoTab') {
      filters['enableWorkOrderChat'] = true;
    }

    // if (this.jobIds && this.jobIds.length) {
    //   filters['jobFilter'] = {id: {inq: this.jobIds}};
    // }

    /* To get only case data in viewAsPMS mode as it is for actual PMS user */
    if (this.viewAsPartner) {
      filters['viewAsPartner'] = (this.type === 'favourite') ? false : true;
    }

    this._commentApi.getDistinctByType(filters).subscribe(_data => {
      if (this.rhsPage && this.jobIds && this.jobIds.length && _data && _data.length) {
        this.pinnedData = [];
        _data.forEach(c => {
          if (this.jobIds.includes(c.modelId)) {
            this.pinnedData.push(c);
          }
        });
      } else {
        this.pinnedData = _data;
      }
    }, err => {
      console.log(err);
    })
  }
  getRealtimeMessage() {
    let that = this;
    this.messageSubscription = this._messageService.getNewMessageJob().subscribe(result => {      
      console.log('NEW MESSAGE IN JOB MESSAGE LIST::::::::::JOB MESSAGE LIST', result);
      if (!this.alreadyLoading && typeof result[0].isFromMessageService !== 'undefined') {
        this.alreadyLoading = true;
        result = result[0];
        this.selectedIndex = this.getIndexOfSelected(this.activeId);
        const filterMatched = this._globalChatService.checkifDataFilterMatch({
          filterCondition: this.filterCondition,
          data: result
        });
        const messageIndex = this._globalChatService.checkIfCardExists(this.listData, result);
        if (messageIndex != null && filterMatched) {
          this.updateListData(messageIndex, result);
          this.sortListData();
        } else if (messageIndex == null && filterMatched && typeof this.filterObj['listType'] === 'undefined') {
          this.autoSelected = false;
          //this.loadListData(false);
          that._gotNewMessagesFromSocket = true;
        }
        this.alreadyLoading = false;
      } else {
        console.log('Already Loaded OR NOT FROM MESSAGE SERVICE.');
      }

    })
  }

  updateListData(index, data) {
    this.listData[index]['last_message'] = data.Case_Comment_Body__c;
    this.listData[index]['last_postByName'] = data.postByName;
    this.listData[index]['last_postByName'] = data.postByName;
    this.listData[index]['count'] = this.listData[index]['count'] + 1;
    // console.log("Active card ID, ", this.selectedIndex, ' :: INDEX :: ', index);
    if (this.selectedIndex !== index) {
      this.listData[index]['unread_comments'] = this.listData[index]['unread_comments'] + 1;
    }
    this.listData[index]['last_createdAt'] = data.createdAt;
  }

  unshiftDataInList(_data, isLoadMore?) {
    _data.forEach(_newItem => {
      let index;
      if (!this.listData.some((item, i) => { index = i; return item.modelId === _newItem.modelId })) {
        let jobFoundInPinned = false;
        this.pinnedData.forEach(pinnedItem => {
          if (pinnedItem.modelId === _newItem.modelId && pinnedItem.modelName === _newItem.modelName) {
            pinnedItem['last_postByName'] = _newItem['last_postByName'];
            pinnedItem['last_createdAt'] = _newItem['last_createdAt'];
            pinnedItem['last_message'] = _newItem['last_message'];
            pinnedItem['count'] += _newItem['count'];
            pinnedItem['unread_comments'] += _newItem['unread_comments'];
            jobFoundInPinned = true;
            this._messageService.setNewMessageJob(_newItem);
          }
        })
        if (!jobFoundInPinned) {
          if (isLoadMore) {
            this.listData.push(_newItem);
          } else {
            this.listData.unshift(_newItem);
          }
        }
      } else {

        this.listData[index]['last_postByName'] = _newItem['last_postByName'];
        this.listData[index]['last_createdAt'] = _newItem['last_createdAt'];
        this.listData[index]['last_message'] = _newItem['last_message'];

        this.listData[index]['count'] += _newItem['count'];
        const isTabOpen: any = this._appState.getCookie(`tab_open_for_id_${_newItem['commentId']}_` + this._appState.getTabUniqueId());
        // console.log('isTab Open ' + isTabOpen);
        if (isTabOpen === null) {
          this.listData[index]['unread_comments'] += _newItem['unread_comments'];
        }
        this._messageService.setNewMessageJob(_newItem);
      }
    });
  }

  /**
   * select first item of list
   */
  selectFirstRecord() {
    if (this.listData.length) {
      this.activeId = this.listData[0]._id;
      this.selectedIndex = 0; //selectedIndex
      this.onselectedChange(this.listData[0]);
      this.listData[0].unread_comments = 0;
    } else {
      this.onselectedChange({});
      this.errorMessage = 'No message found';
    }
  }

  /**
  *
  * @param e index of selected tab
  */
  onTabChange(tab) {
    if (tab.nextId === 'all') {
      delete this.filterObj['listType'];
    } else {
      this.filterObj['listType'] = 'FavJob';
    }
    this.selectedTabId = tab.nextId;
    // console.log(' SELECTED TAB :: ', this.selectedTabId);
    this.loadListData();
  }


  onClick(event: Event, item: any, button: string) {
    event.stopPropagation();
    if (button === 'fav') {
      this.onfavUpdatedChange(item);
    } else {
      this.activeId = item._id;
      // 
      this.selectedIndex = this.getIndexOfSelected(this.activeId);
      item.unread_comments = 0;
      this.onselectedChange(item);
    }
  }


  /**
   *
   * @param e object of selected row in message list
  */
  onselectedChange(e) {
    this.selected.emit(e);
  }

  /**
   *
   * @param e object of like and unlike.
   */
  onfavUpdatedChange(e) {
    this.favUpdated.emit(e);
  }

  /**
   *
   * @param e load more data
   */
  onloadMoreChange(e) {
    this._loader.showPreloader();
    delete this.filterObj['createdAt'];
    if (this.accessType === 'vendor') {
      this.filterObj['modelName'] = 'Job';
    }
    if (this.filterCondition && this.filterCondition['Iron_Case_Number__c'] !== undefined) {
      this.filterObj['Iron_Case_Number__c'] = this.filterCondition['Iron_Case_Number__c'];
    } else {
      delete this.filterObj['Iron_Case_Number__c'];
    }

    /*To get  programPMCConsoleTab */
    if (this.selectedTab === 'programPMCConsoleTab') {
      this.filterObj['commentType'] = 'pmc';
      this.filterObj['modelName'] = 'Job';
    }
      /*To get  billingSOPOConsole*/
    if (this.selectedTab === 'billingSOPOConsole') {
      this.filterObj['commentType'] = 'billing';
      this.filterObj['modelName'] = 'Job';
    }

    const filters = {
      where: this.filterObj,
      limit: this.limit,
      skip: this.listData.length
    };

    if (this.accessType === 'internal') {
      filters['enableChatRoom'] = true;
    }
    if (this.selectedTab === 'caseWoTab' || this.selectedTab === 'favouriteWoTab' || this.selectedTab === 'escalation' || this.selectedTab === 'taskCaseTab' || this.selectedTab === 'programPMCConsoleTab' || this.selectedTab === 'billingSOPOConsole') {
      filters['enableWorkOrderChat'] = true;
    }

    if (this.filterCondition && Object.keys(this.filterCondition).length) {
      delete this.filterCondition['Iron_Case_Number__c'];
      filters['jobFilter'] = this.filterCondition;
    }
    if (this.type === 'favourite' || this.type === 'myCases') {
      delete filters['jobFilter'];
    }
    if (this.jobIds && this.jobIds.length) {
      filters['jobFilter'] = { id: { inq: this.jobIds } };
    }
    /* To get only case data in viewAsPMS mode as it is for actual PMS user */
    if (this.viewAsPartner) {
      filters['viewAsPartner'] = (this.type === 'favourite') ? false : true;
    }
    this._commentApi.getDistinctByType(filters).subscribe(_data => {
      // this.listData = [this.listData, ..._data];
      this.unshiftDataInList(_data, true);
      this._loader.hidePreloader();
    }, err => {
      this._loader.hidePreloader();
    });
  }

  /**
   * refresh date
   */
  onRefresh() {
    this.loadListData();
  }


  getStatusClass(status: string) {
    if (this._statusCss.hasOwnProperty(status)) {
      return this._statusCss[status];
    } else {
      return 'badge-secondary';
    }
  }

  unsubscribeTimer() {
    if (this.timerSub) {
      this.timerSub.unsubscribe();
    }
  }

  ngOnDestroy() {
    this.unsubscribeTimer();
    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }
    if(this._ticker){
      clearInterval(this._ticker);
    }
  }
  /**
   * to open search filter box
   */
  // openFilterBox() {
  //   this.showFilter = this.showFilter ? false : true;
  //    if (this.showFilter) {
  //     document.getElementById('myNav').style.width = '43%';
  //   } else {
  //     this.closeFilterBox();
  //   }
  // }
  /**
     * to close search filter box
     */
  // closeFilterBox() {
  //   document.getElementById('myNav').style.width = '0';
  //   this.showFilter = false;
  // }
  /**
   * 
   * @param filterObj | pass filter obj sfdc value
   */
  searchCaseMessages(filterObj) {
    this.filterCondition = filterObj;
    if (this.filterCondition['Customer__c']) {
      if (this.filterCondition['CKSW_BASE__Account__c']) {
        this.filterCondition['CKSW_BASE__Account__c']['inq'] = this.filterCondition['CKSW_BASE__Account__c']['inq'].concat(this.filterCondition['Customer__c']['inq']);
      } else {
        this.filterCondition['CKSW_BASE__Account__c '] = this.filterCondition['Customer__c'];
      }
      delete this.filterCondition['Customer__c'];
    }
    this.setProgramViewAsPMS();
    this.loadListData();
  }

  /**
   * Pin or unpin action has been performed
   */
  onPinAction(jobData) {
    if (this.type !== 'all') {
      return;
    }
    const pinnedLocalData = localStorage.getItem(this.PINNED_MESSAGE_ID) ? JSON.parse(localStorage.getItem(this.PINNED_MESSAGE_ID)) : [];
    let alreadyPinnedData = false;
    pinnedLocalData.forEach((item, index) => {
      if (jobData && (item.id === jobData.modelId && item.modelName === jobData.modelName)) {
        pinnedLocalData.splice(index, 1);
        this.pinnedData.splice(this.pinnedData.indexOf(jobData), 1);
        localStorage.setItem(this.PINNED_MESSAGE_ID, JSON.stringify(pinnedLocalData));
        alreadyPinnedData = true;
      }
    })
    if (alreadyPinnedData) {
      if (Object.keys(this.filterCondition).length) {
        delete this.filterObj['createdAt'];
        this.createdAt = null;
        this.subscribeJob(0, false);
      } else {
        this.listData.push(jobData);
        this.sortListData();
      }
      return;
    }
    if (pinnedLocalData && pinnedLocalData.length === 2) {
      return this._alertService.error('You can not pin more than two items.');
    }

    const data = {
      id: jobData.modelId,
      modelName: jobData.modelName
    }

    if (jobData.job) {
      data['Case__c'] = jobData.job.Case__c;
      data['PMC_Case_Number'] = jobData.job.PMC_Case_Number;
    } else if (jobData.workOrder) {
      data['Case__c'] = jobData.Case__c;
      data['PMC_Case_Number'] = jobData.workOrder.JPC_Case__c;
    }

    pinnedLocalData.push(data)

    // pinnedLocalData.push(
    //   {
    //     id: jobData.modelId,
    //     Case__c: jobData.job.Case__c,
    //     PMC_Case_Number: jobData.job.PMC_Case_Number
    //   }
    // )
    localStorage.setItem(this.PINNED_MESSAGE_ID, JSON.stringify(pinnedLocalData));
    const pinnedData = this.listData.splice(this.listData.findIndex(item => (item.modelId === jobData.modelId && item.modelName === jobData.modelName)), 1);
    if (pinnedData && pinnedData.length) {
      this.pinnedData.unshift(pinnedData[0]);
    }
  }

  sortListData() {
    this.listData.sort((a, b) => {
      const bDateTime = new Date(Date.parse(b.last_createdAt));
      const aDateTime = new Date(Date.parse(a.last_createdAt));
      return bDateTime.getTime() - aDateTime.getTime();
    })
  }

  getIndexOfSelected(id) {
    let index = null;
    this.listData.filter(function (e, i) {
      if (e._id === id) {
        index = i;
      }
    });
    return index;
  }

  /* To set already choosen program in viewAsPMS mode */
  setProgramViewAsPMS() {
    if (this.viewAsPartner) {
      const program = this.viewAsInfo['program']['sfdcId'];
      this.filterCondition['Project_SOP__c'] = { inq: [program] };
    }
  }

}
