import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { WorkerType } from 'shared/models/static-list-data.service';

@Component({
  selector: 'app-worker-type-lookup',
  templateUrl: './worker-type-lookup.component.html',
  styleUrls: ['./worker-type-lookup.component.css']
})
export class WorkerTypeLookupComponent implements OnInit {

  @Input() placeholder = 'Worker Type';
  @Input() set resetData(isClear) {
      this.onClear(isClear);
  }
  @Output() selectedValue: EventEmitter<any> = new EventEmitter<any>();
  workerTypeList: any = [];
  selectedWorkerType: any = [];

  constructor() {
  }

  ngOnInit() {
       this.workerTypeList = WorkerType;
       const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
       this.selectedWorkerType = (preselected && preselected['workerType']) ? preselected['workerType'] : '';
  }

  onChange() {
      this.selectedValue.emit(this.selectedWorkerType);
  }

  onClear(isClear) {
      this.selectedWorkerType = [];
      if (isClear) {
          this.selectedValue.emit([]);
      }
  }
}
