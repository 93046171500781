import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpenseSubTypeLookupComponent } from './expense-sub-type-lookup.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule
  ],
  declarations: [ExpenseSubTypeLookupComponent],
  exports: [ExpenseSubTypeLookupComponent]
})
export class ExpenseSubTypeLookupModule { }
