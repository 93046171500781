import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExcludeMagiclinkLookupComponent } from './exclude-magiclink-lookup.component';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule
  ],
  declarations: [ExcludeMagiclinkLookupComponent],
  exports: [ExcludeMagiclinkLookupComponent]
})
export class ExcludeMagiclinkLookupModule { }
