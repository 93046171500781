/* tslint:disable */

declare var Object: any;
export interface AdjustmentReasonCodeInterface {
  "sfdcId"?: string;
  "Name"?: string;
  "CreatedById"?: string;
  "LastModifiedById"?: string;
  "CurrencyIsoCode"?: string;
  "Issue_Resolution_Short_Run__c"?: string;
  "Issue_Source__c"?: string;
  "Issue_Ownership_Team__c"?: string;
  "Issue_Resolution_Long_Run__c"?: string;
  "PMS_VMS__c"?: string;
  "Reason_code__c"?: string;
  "Reason_Description__c"?: string;
  "id"?: number;
}

export class AdjustmentReasonCode implements AdjustmentReasonCodeInterface {
  "sfdcId": string;
  "Name": string;
  "CreatedById": string;
  "LastModifiedById": string;
  "CurrencyIsoCode": string;
  "Issue_Resolution_Short_Run__c": string;
  "Issue_Source__c": string;
  "Issue_Ownership_Team__c": string;
  "Issue_Resolution_Long_Run__c": string;
  "PMS_VMS__c": string;
  "Reason_code__c": string;
  "Reason_Description__c": string;
  "id": number;
  constructor(data?: AdjustmentReasonCodeInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `AdjustmentReasonCode`.
   */
  public static getModelName() {
    return "AdjustmentReasonCode";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of AdjustmentReasonCode for dynamic purposes.
  **/
  public static factory(data: AdjustmentReasonCodeInterface): AdjustmentReasonCode{
    return new AdjustmentReasonCode(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'AdjustmentReasonCode',
      plural: 'AdjustmentReasonCodes',
      path: 'AdjustmentReasonCodes',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "Issue_Resolution_Short_Run__c": {
          name: 'Issue_Resolution_Short_Run__c',
          type: 'string'
        },
        "Issue_Source__c": {
          name: 'Issue_Source__c',
          type: 'string'
        },
        "Issue_Ownership_Team__c": {
          name: 'Issue_Ownership_Team__c',
          type: 'string'
        },
        "Issue_Resolution_Long_Run__c": {
          name: 'Issue_Resolution_Long_Run__c',
          type: 'string'
        },
        "PMS_VMS__c": {
          name: 'PMS_VMS__c',
          type: 'string'
        },
        "Reason_code__c": {
          name: 'Reason_code__c',
          type: 'string'
        },
        "Reason_Description__c": {
          name: 'Reason_Description__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
