import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ServiceTechnicalTalentTypeLookupComponent } from './service-technical-talent-type-lookup.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule
  ],
  declarations: [ServiceTechnicalTalentTypeLookupComponent],
  exports: [ServiceTechnicalTalentTypeLookupComponent],
  providers: []
})
export class ServiceTechnicalTalentTypeLookupModule { }
