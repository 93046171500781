import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Subject, BehaviorSubject, Observable, from } from 'rxjs';
import { JobWorkerTrackingApi } from './../sdk/services/custom/JobWorkerTracking';
@Injectable({
  providedIn: 'root'
})
export class WorkerTrackerService {
  private onSiteWorkers = new BehaviorSubject<object>(null);
  constructor(private _workerTracking: JobWorkerTrackingApi) { }

  transform(value: string) {
    const datePipe = new DatePipe('en-US');
    value = datePipe.transform(value, 'shortDate');
    return value;
  }
  setOnSiteWorkers(data) {
    this.onSiteWorkers.next(data);
  }

  getOnSiteWorkers(): Observable<any> {
    return this.onSiteWorkers.asObservable();
  }

  getJobWorkerTracker(workers, workerRelation = true) {
    let filter = {};
    if (Array.isArray(workers)) {
      const workerIds = [];
      const JobIds = [];
      workers.forEach(item => {
        workerIds.push(item.workerSfdcId);
        JobIds.push(item.jobSfdcId)
      });
      filter = { jobSfdcId: { inq: JobIds }, workerSfdcId: { inq: workerIds } };
    } else {
      filter = { jobSfdcId: workers.jobSfdcId, workerSfdcId: workers.workerSfdcId };
    }

    const fromdate = this.transform(new Date().toString()) + ' 00:00:00';
    filter['createdAt'] = { gte: fromdate };

    const filterObj = {
      where: filter,
      order: 'id DESC'
    }

    if (workerRelation) {
      filterObj['include'] = [
        {
          relation: 'worker',
          scope: {
            fields: ['sfdcId', 'Contact__c'],
            include: [
              {
                relation: 'user',
                scope: {
                  fields: ['sfdcId', 'picture', 'profileImage', 'url']
                }
              }
            ]
          }
        }
      ]
    }
    return this._workerTracking.find(filterObj).map(data => data);
  }
}
