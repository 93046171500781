import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { isActive } from '../../../models/static-list-data.service'

@Component({
    selector: 'app-is-ActiveUser-lookup',
    templateUrl: './is-ActiveUser-lookup.component.html',
    styleUrls: ['./is-ActiveUser-lookup.component.css']
})
export class IsActiveUserLookupComponent implements OnInit {

    isActiveUserList = [];
    isActiveUserSelected = [];
    isMultipleSelected = true;

    @Input() set resetData(isClear) {
        if (isClear) {
            this.isActiveUserSelected = [];
        }
    }
    @Input() maxSelectedItems = 1;

    @Input() selectedDefaultUser;
    @Input() from;

    @Output() selectedisActiveUser: EventEmitter<any> = new EventEmitter;


    constructor() { }

    ngOnInit() {
        this.isActiveUserList = isActive;
        if (this.from && this.from === 'analyticsDashboard') {
            if (this.selectedDefaultUser && this.selectedDefaultUser.length) {
                this.isActiveUserSelected = this.selectedDefaultUser;
            } else {
                this.isActiveUserSelected = [];
            }
        } else {
            const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';

            this.isActiveUserSelected = (preselected &&
                preselected['isActiveUser']) ? preselected['isActiveUser'] : ['true'];

        }

    }

    onisActiveUserChange() {
        this.selectedisActiveUser.emit(this.isActiveUserSelected);
    }

    clearisActiveUser() {

        this.isActiveUserSelected = [];
        this.selectedisActiveUser.emit([]);
    }
}
