import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { RhsNotificationsListComponent } from './rhs-notifications-list.component';

@NgModule({
  imports: [
    CommonModule,
    InfiniteScrollModule
  ],
  declarations: [RhsNotificationsListComponent],
  exports: [RhsNotificationsListComponent]
})
export class RhsNotificationsListModule { }
