export enum ProjectConsoleTypes {
    helpDesk = 'HelpDesk',
    billingManager = 'BillingManager',
    problemManagement = 'ProblemManagement',
    sales = 'Sales',
    account = 'Account',
    serviceManager = 'ServiceManager',
    taskCase = 'TaskCase',
    task2Order = 'Task2Order',
    fsd = 'FSD',
    others = 'Others',
    recruitmentManager = 'RecruitmentManager',
    globalMyTaskManger = 'GlobalMyTaskManager',
    okr = 'OKR',
    projectManager = 'ProjectManager',
    rmcEscalationCreation = 'RmcEscalationCreation'
}
