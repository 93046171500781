
import { Component, OnInit, Input } from '@angular/core';
import { NotificationService } from '../../../services/notification.service';
import { PreloaderService } from 'shared/services/preloader.service';
import { ModalService } from '../../../services/modal.service';
import { CommonService } from 'shared/services/common.service';

@Component({
    selector: 'notification-detail',
    templateUrl: './notification-detail.component.html',
    styleUrls: ['./notification-detail.component.css']
})

export class NotificationDetailComponent implements OnInit {
    @Input() set detialObj(e) {
        this.notificationObject = e;
    }

  @Input() saveAnalytics = true;
   
    public notificationObject: any = false;
    private _statusCss = {
        p1: 'p1',
        p2: 'p2',
        p3: 'p3',
        p4: 'p4',
        p5: 'p5',
        p6: 'p6'
      };    
    constructor(
        private _loader: PreloaderService,
        private _notificationService: NotificationService,
        private _modalService: ModalService,
        private _commonService: CommonService
    ) {
    }


    ngOnInit() {
      if (this.saveAnalytics) {
        this._commonService.saveCurrentPageAnalytics('Global Chat', 'Notification Details')
      }
    }

 getPriorityClass(priority: string = "") {
        priority = (priority && priority.length && priority.trim()) ? priority.trim().slice(0, 2).toLowerCase() : ''
        if (this._statusCss.hasOwnProperty(priority)) {
          return this._statusCss[priority];
        } else {
          return "default-priority";
        }
      }
   
}
