import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { taskTypeValues } from '../../../models/static-list-data.service';

@Component({
  selector: 'app-task-type-lookup',
  templateUrl: './task-type-lookup.component.html',
  styleUrls: ['./task-type-lookup.component.css']
})
export class TaskTypeLookupComponent implements OnInit {

  @Input() set resetData(isClear) {
    if (isClear) {
      this.taskTypeSelected = [];
    }
  }
  @Input() placeholder = 'Task Type';
  @Input() isMultipleSelected = true;
  @Output() getTaskTypeObj: EventEmitter<any> = new EventEmitter;

  @Input()
  set setTaskTypeObj(params) {
    this.taskTypeOptionList = [];
    // this.clearTaskType();
    this.whereObj = this.whereObj && Object.keys(this.whereObj).length ? this.whereObj : {};
  }

  taskTypeOptionList = [];
  taskTypeSelected = [];
  whereObj = {};

  constructor() { }

  ngOnInit() {
    this.taskTypeOptionList = taskTypeValues;
    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
    this.taskTypeSelected = (preselected && preselected['taskTypes']) ? preselected['taskTypes'] : '';
  }
  onTaskTypeChange() {
    this.getTaskTypeObj.emit(this.taskTypeSelected);
  }

  clearTaskType() {
    this.taskTypeSelected = [];
    this.getTaskTypeObj.emit([]);
  }

}
