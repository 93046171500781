import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appNumberLimit]'
})
export class NumberLimitDirective {
  @Input() maxLimit: number;
  @Input() minLimit: number;
  private specialKeys: Array<string> = ['Backspace', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];

  constructor(private el: ElementRef) {
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if(event.key === '-') {
      event.preventDefault();
      return;
    }
    const value = +(this.el.nativeElement.value + event.key);
    

    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    if (value > this.maxLimit) {
      this.el.nativeElement.value = this.maxLimit;
      event.preventDefault()
      return;
    }
    if (value < this.minLimit) {
      this.el.nativeElement.value = this.minLimit;
      event.preventDefault()
      return;
    }
  }


  @HostListener('blur', ['$event'])
  onBlur(event) {
    if (this.el.nativeElement.value === null || this.el.nativeElement.value === '') {
      this.el.nativeElement.value = 0;
    }
  }
}
