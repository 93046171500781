import { Component, OnInit } from '@angular/core';
import { CommonService } from 'shared/services/common.service';

@Component({
  selector: 'app-global-chat',
  templateUrl: './global-chat.component.html',
  styleUrls: ['./global-chat.component.css']
})
export class GlobalChatComponent implements OnInit {

  constructor(private _commonService: CommonService) {
    this._commonService.fetchJobTypeInfo();
   }

  ngOnInit() {
  }

}
