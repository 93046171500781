/* tslint:disable */

declare var Object: any;
export interface ServiceTerritoryInterface {
  "Name"?: string;
  "Address"?: string;
  "City"?: string;
  "Country"?: string;
  "Country_Lookup__c"?: string;
  "CreatedById"?: string;
  "createddate"?: Date;
  "CurrencyIsoCode"?: string;
  "Description"?: string;
  "GEO_Metro__c"?: string;
  "GeocodeAccuracy"?: string;
  "IsActive"?: boolean;
  "LastModifiedById"?: string;
  "LastModifiedDate"?: Date;
  "Latitude"?: number;
  "Longitude"?: number;
  "OperatingHoursId"?: string;
  "OwnerId"?: string;
  "ParentTerritoryId"?: string;
  "Primary__c"?: boolean;
  "Service_Territory_Code__c"?: string;
  "State"?: string;
  "State__c"?: string;
  "TopLevelTerritoryId"?: string;
  "TypicalInTerritoryTravelTime"?: number;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class ServiceTerritory implements ServiceTerritoryInterface {
  "Name": string;
  "Address": string;
  "City": string;
  "Country": string;
  "Country_Lookup__c": string;
  "CreatedById": string;
  "createddate": Date;
  "CurrencyIsoCode": string;
  "Description": string;
  "GEO_Metro__c": string;
  "GeocodeAccuracy": string;
  "IsActive": boolean;
  "LastModifiedById": string;
  "LastModifiedDate": Date;
  "Latitude": number;
  "Longitude": number;
  "OperatingHoursId": string;
  "OwnerId": string;
  "ParentTerritoryId": string;
  "Primary__c": boolean;
  "Service_Territory_Code__c": string;
  "State": string;
  "State__c": string;
  "TopLevelTerritoryId": string;
  "TypicalInTerritoryTravelTime": number;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: ServiceTerritoryInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ServiceTerritory`.
   */
  public static getModelName() {
    return "ServiceTerritory";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ServiceTerritory for dynamic purposes.
  **/
  public static factory(data: ServiceTerritoryInterface): ServiceTerritory{
    return new ServiceTerritory(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ServiceTerritory',
      plural: 'ServiceTerritories',
      path: 'ServiceTerritories',
      idName: 'id',
      properties: {
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "Address": {
          name: 'Address',
          type: 'string'
        },
        "City": {
          name: 'City',
          type: 'string'
        },
        "Country": {
          name: 'Country',
          type: 'string'
        },
        "Country_Lookup__c": {
          name: 'Country_Lookup__c',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "createddate": {
          name: 'createddate',
          type: 'Date'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "Description": {
          name: 'Description',
          type: 'string'
        },
        "GEO_Metro__c": {
          name: 'GEO_Metro__c',
          type: 'string'
        },
        "GeocodeAccuracy": {
          name: 'GeocodeAccuracy',
          type: 'string'
        },
        "IsActive": {
          name: 'IsActive',
          type: 'boolean'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "Latitude": {
          name: 'Latitude',
          type: 'number'
        },
        "Longitude": {
          name: 'Longitude',
          type: 'number'
        },
        "OperatingHoursId": {
          name: 'OperatingHoursId',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "ParentTerritoryId": {
          name: 'ParentTerritoryId',
          type: 'string'
        },
        "Primary__c": {
          name: 'Primary__c',
          type: 'boolean'
        },
        "Service_Territory_Code__c": {
          name: 'Service_Territory_Code__c',
          type: 'string'
        },
        "State": {
          name: 'State',
          type: 'string'
        },
        "State__c": {
          name: 'State__c',
          type: 'string'
        },
        "TopLevelTerritoryId": {
          name: 'TopLevelTerritoryId',
          type: 'string'
        },
        "TypicalInTerritoryTravelTime": {
          name: 'TypicalInTerritoryTravelTime',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
