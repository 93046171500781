
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MaketplaceCatalogComponent } from './maketplace-catalog.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ReadMoreModule } from 'shared/components/read-more/read-more.module';
import { FormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatDialogModule } from '@angular/material';
import { CatalogCardComponent } from './catalog-card/catalog-card.component';
@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    NgxDatatableModule,
    FormsModule,
    ReadMoreModule,
    NgxPaginationModule,
    MatDialogModule
  ],
  declarations: [MaketplaceCatalogComponent, CatalogCardComponent],
  exports: [MaketplaceCatalogComponent, CatalogCardComponent]
})
export class MaketplaceCatalogModule { }
