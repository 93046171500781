/* tslint:disable */
import {
  Worker,
  CompoffAutoExpireSetting
} from '../index';

declare var Object: any;
export interface CompOffRequestInterface {
  "sfdcId"?: string;
  "OwnerId"?: string;
  "IsDeleted"?: boolean;
  "Name"?: string;
  "CreatedById"?: string;
  "CreatedDate"?: Date;
  "CurrencyIsoCode"?: string;
  "LastModifiedDate"?: Date;
  "LastModifiedById"?: string;
  "Approve_Reject_Email_Sent__c"?: boolean;
  "Approved_Rejected_Datetime__c"?: Date;
  "Approver__c"?: string;
  "Compoff_Auto_Expire_Settings__c"?: string;
  "CompOff_End_Date__c"?: Date;
  "CompOff_Start_Date__c"?: Date;
  "Day__c"?: string;
  "Customer_Requested__c"?: string;
  "Days_To_Expire__c"?: number;
  "Expiration_Date__c"?: Date;
  "Expiration_Days_Limit__c"?: number;
  "is_Expired_Batch__c"?: boolean;
  "New_Email_Sent__c"?: boolean;
  "Reason__c"?: string;
  "Reject_Reason__c"?: string;
  "Status__c"?: string;
  "Updated_Expiration_Day__c"?: number;
  "Worked_Hours__c"?: number;
  "Worker__c"?: string;
  "CreatedBy__c"?: string;
  "LastModifiedBy__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  approver?: Worker;
  worker?: Worker;
  compoffAutoExpireSetting?: CompoffAutoExpireSetting;
}

export class CompOffRequest implements CompOffRequestInterface {
  "sfdcId": string;
  "OwnerId": string;
  "IsDeleted": boolean;
  "Name": string;
  "CreatedById": string;
  "CreatedDate": Date;
  "CurrencyIsoCode": string;
  "LastModifiedDate": Date;
  "LastModifiedById": string;
  "Approve_Reject_Email_Sent__c": boolean;
  "Approved_Rejected_Datetime__c": Date;
  "Approver__c": string;
  "Compoff_Auto_Expire_Settings__c": string;
  "CompOff_End_Date__c": Date;
  "CompOff_Start_Date__c": Date;
  "Day__c": string;
  "Customer_Requested__c": string;
  "Days_To_Expire__c": number;
  "Expiration_Date__c": Date;
  "Expiration_Days_Limit__c": number;
  "is_Expired_Batch__c": boolean;
  "New_Email_Sent__c": boolean;
  "Reason__c": string;
  "Reject_Reason__c": string;
  "Status__c": string;
  "Updated_Expiration_Day__c": number;
  "Worked_Hours__c": number;
  "Worker__c": string;
  "CreatedBy__c": string;
  "LastModifiedBy__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  approver: Worker;
  worker: Worker;
  compoffAutoExpireSetting: CompoffAutoExpireSetting;
  constructor(data?: CompOffRequestInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `CompOffRequest`.
   */
  public static getModelName() {
    return "CompOffRequest";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of CompOffRequest for dynamic purposes.
  **/
  public static factory(data: CompOffRequestInterface): CompOffRequest{
    return new CompOffRequest(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'CompOffRequest',
      plural: 'CompOffRequests',
      path: 'CompOffRequests',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "Approve_Reject_Email_Sent__c": {
          name: 'Approve_Reject_Email_Sent__c',
          type: 'boolean'
        },
        "Approved_Rejected_Datetime__c": {
          name: 'Approved_Rejected_Datetime__c',
          type: 'Date'
        },
        "Approver__c": {
          name: 'Approver__c',
          type: 'string'
        },
        "Compoff_Auto_Expire_Settings__c": {
          name: 'Compoff_Auto_Expire_Settings__c',
          type: 'string'
        },
        "CompOff_End_Date__c": {
          name: 'CompOff_End_Date__c',
          type: 'Date'
        },
        "CompOff_Start_Date__c": {
          name: 'CompOff_Start_Date__c',
          type: 'Date'
        },
        "Day__c": {
          name: 'Day__c',
          type: 'string'
        },
        "Customer_Requested__c": {
          name: 'Customer_Requested__c',
          type: 'string'
        },
        "Days_To_Expire__c": {
          name: 'Days_To_Expire__c',
          type: 'number'
        },
        "Expiration_Date__c": {
          name: 'Expiration_Date__c',
          type: 'Date'
        },
        "Expiration_Days_Limit__c": {
          name: 'Expiration_Days_Limit__c',
          type: 'number'
        },
        "is_Expired_Batch__c": {
          name: 'is_Expired_Batch__c',
          type: 'boolean'
        },
        "New_Email_Sent__c": {
          name: 'New_Email_Sent__c',
          type: 'boolean'
        },
        "Reason__c": {
          name: 'Reason__c',
          type: 'string'
        },
        "Reject_Reason__c": {
          name: 'Reject_Reason__c',
          type: 'string'
        },
        "Status__c": {
          name: 'Status__c',
          type: 'string'
        },
        "Updated_Expiration_Day__c": {
          name: 'Updated_Expiration_Day__c',
          type: 'number'
        },
        "Worked_Hours__c": {
          name: 'Worked_Hours__c',
          type: 'number'
        },
        "Worker__c": {
          name: 'Worker__c',
          type: 'string'
        },
        "CreatedBy__c": {
          name: 'CreatedBy__c',
          type: 'string'
        },
        "LastModifiedBy__c": {
          name: 'LastModifiedBy__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        approver: {
          name: 'approver',
          type: 'Worker',
          model: 'Worker',
          relationType: 'belongsTo',
                  keyFrom: 'Approver__c',
          keyTo: 'sfdcId'
        },
        worker: {
          name: 'worker',
          type: 'Worker',
          model: 'Worker',
          relationType: 'belongsTo',
                  keyFrom: 'Worker__c',
          keyTo: 'sfdcId'
        },
        compoffAutoExpireSetting: {
          name: 'compoffAutoExpireSetting',
          type: 'CompoffAutoExpireSetting',
          model: 'CompoffAutoExpireSetting',
          relationType: 'belongsTo',
                  keyFrom: 'Compoff_Auto_Expire_Settings__c',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
