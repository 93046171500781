import { Injectable } from '@angular/core';
import { ProjectConsoleTypes } from 'msp/enums/project-console-types.enum';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppStateService } from 'shared/services/app-state.service';
import { CommonService } from 'shared/services/common.service';
import { map } from 'rxjs/operators';
import { DepartmentApi, Group, GroupApi, MilestoneApi, ProgramGroupApi, Project2Api, TaskApi, WorkerApi } from 'shared/sdk';
import * as moment from 'moment-timezone';
@Injectable({
  providedIn: 'root'
})
export class DeliveryManagerService {

  private increamentTaskCount = new BehaviorSubject<any>({});
  private userDetail;
  public pageViewMode;


  constructor(
    private _commonService: CommonService,
    private _appState: AppStateService,
    private _workerApi: WorkerApi,
    private _departmentApi: DepartmentApi,
    private _programGroupApi: ProgramGroupApi,
    private _project2Api: Project2Api,
    private _taskApi: TaskApi,
    private _milestoneApi: MilestoneApi,
    private _groupApi: GroupApi
  ) { }

  getDate = (date, timezone?, format?) => {
    const dateTime = this._commonService.dateFormate(date, timezone ? timezone : '', format ? format : 'L, LT z').split(',');
    if (dateTime) {
      return dateTime[0];
    }
  }

  getConvertedTime(time) {
    let array = time.split(':');
    let hours = array[0];
    let minutes = array[1];
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    let convertedTime = hours + ':' + minutes + ' ' + ampm;
    return convertedTime;
  }

  getRecordTypeIdForTeam(): string {
    return '0121a000000VlrNAAS';
  }

  getRecordTypeIdForDepartment(): string {
    return '0121a000000VlrIAAS';
  }

  getRecordtypeIdForGroupMember(): string {
    return "0121a000000F1BCAA0";
  }
  getGroupWorkerList(item): string {
    const returnObj = [];
    if (item && item.length) {
      let index = 1;
      item.forEach((element) => {
        if (element && element.group && element.group.Department_Group_Code__c) {
          returnObj.push((index++) + '. ' + element.group.Department_Group_Code__c + '<br/>');
        }
        if (element && element.worker && element.worker.Name) {
          returnObj.push((index++) + '. ' + element.worker.Name + '<br/>');
        }
      });
    }
    return returnObj.join('');
  }

  replaceLineBreaksWithComma(data: any[]): any[] {
    if (data && data.length) {
      let tableData = JSON.parse(JSON.stringify(data));
      tableData.forEach(row => {
        if (row['Department_Group_Code__c'] && row['Department_Group_Code__c'].length) {
          row['Department_Group_Code__c'] = this._replaceBreakWithComma(row['Department_Group_Code__c']);
        }
        if (row['Department_Member__c'] && row['Department_Member__c'].length) {
          row['Department_Member__c'] = this._replaceBreakWithComma(row['Department_Member__c']);
        }
        if (row['Task_Department_Group_Code__c'] && row['Task_Department_Group_Code__c'].length) {
          row['Task_Department_Group_Code__c'] = this._replaceBreakWithComma(row['Task_Department_Group_Code__c']);
        }
        if (row['Task_Department_Member__c'] && row['Task_Department_Member__c'].length) {
          row['Task_Department_Member__c'] = this._replaceBreakWithComma(row['Task_Department_Member__c']);
        }
      });
      return tableData;
    } else {
      return [];
    }
  }

  private _replaceBreakWithComma(property: any): string {
    property = property.replace(/<br\/>/g, ', ');
    property = property.substring(0, property.length - 2);
    return property;
  }

  updateTaskCount(milestone) {
    this.increamentTaskCount.next(milestone);
  }

  shouldUpdateTaskCount(): Observable<any> {
    return this.increamentTaskCount.asObservable();
  }

  getIsoDateWithoutTimeInfluence(date: Date): string {
    let dateString = '';
    dateString += date.getFullYear();
    dateString += '-';
    dateString += ('0' + (date.getMonth() + 1)).slice(-2);
    dateString += '-';
    dateString += ('0' + (date.getDate())).slice(-2);
    dateString += 'T00:00:00.000Z';
    return dateString;
  }

  setPageViewMode(tab) {
    this.pageViewMode = tab;
  }
  getPageViewMode(){
    return this.pageViewMode;
  }


  viewType(consoleType) {
    const viewType = {
      taskList: true,
      viewMilestone: true,
      editMilestone: true,
      addMilestone: true,
      deleteMilestone: true,
      favTask: true,
      viewTask: true,
      editTask: true,
      addTask: true,
      addSubTask: true,
      editSubTask: false,
      viewSubTask: false,
      projectGlobalChats: false,
      milestoneGlobalChats: false,
      globalChats: true,
      projectFileManager: true,
      fileUpload: true,
      serviceManagerConsole: true,
      cloneMilestone: true,
      cloneTask: true,
      deleteTask: true,
      appointment: false,
      assignJob: false,
      leftNavigation: true,
      dashboardPerformance: true,
      teamMember : true,
      reportDetails : false,
      taskGovernance: false,
      milestoneGovernance: false,
      dashboardGlobalStatus: false,
      checkInCalendar: false,
      problemEscalationCompliance: false,
      sprintBoard: false,
      meetingCalendar: false,
      selectedList: false,
      createWO: false,
      timecard: false
    }
    // View tabs as per user roles
    if (this.isAdmin()) {
      viewType.deleteMilestone = true;
    } else {
      viewType.addMilestone = false;
      viewType.editMilestone = false;
      viewType.cloneMilestone = false;
      viewType.deleteTask = false;
      viewType.editTask = this.isInternalStandardUser(); // Enable edit task for internal standard users
    }
    if (consoleType === ProjectConsoleTypes.helpDesk) {
      viewType.serviceManagerConsole = false;
      viewType.problemEscalationCompliance = true;
      viewType.projectGlobalChats = true;
      viewType.milestoneGlobalChats = true;
    } else if (consoleType === ProjectConsoleTypes.billingManager) {
      viewType.serviceManagerConsole = false;
    } else if (consoleType === ProjectConsoleTypes.problemManagement) {
      viewType.serviceManagerConsole = false;
      viewType.leftNavigation = false;
      viewType.addTask = false;
      viewType.addSubTask = false;
      viewType.cloneTask = false;
      viewType.projectFileManager = false;
      viewType.addMilestone = false;
      viewType.cloneMilestone = false;
      viewType.editMilestone = false;
      viewType.deleteMilestone = false;
      viewType.taskGovernance = true;
      viewType.milestoneGovernance = true;
      viewType.dashboardGlobalStatus = true;
    } else if (consoleType === ProjectConsoleTypes.sales) {
      viewType.serviceManagerConsole = false;
    } else if (consoleType === ProjectConsoleTypes.account) {
      viewType.serviceManagerConsole = false;
    } else if (consoleType === ProjectConsoleTypes.serviceManager) {
      // viewType.editMilestone = false;
      // viewType.addMilestone = false;
      // viewType.editTask = false;
      // viewType.addTask = false;
      // viewType.addSubTask = false;
      // viewType.cloneMilestone = false;
      // viewType.deleteTask = false;
      // viewType.cloneTask = false;
    } else if (consoleType === ProjectConsoleTypes.taskCase || consoleType === ProjectConsoleTypes.projectManager) {
      viewType.projectFileManager = false;
      viewType.dashboardPerformance = false;
      viewType.teamMember = false;
      viewType.appointment = true;
      viewType.assignJob = true;
      viewType.addSubTask = false;
      viewType.addTask = false;
      viewType.problemEscalationCompliance = true;
      viewType.selectedList = true;
      viewType.createWO = true;
      viewType.projectGlobalChats = true;
      viewType.milestoneGlobalChats = true;
      viewType.timecard = true;
      viewType.projectFileManager = (this.pageViewMode === 'advanceView2') ? true : false;
    } else if (consoleType === ProjectConsoleTypes.fsd) {
      viewType.serviceManagerConsole = false;
    } else if (consoleType === ProjectConsoleTypes.others) {
      viewType.serviceManagerConsole = false;
    } else if (consoleType === ProjectConsoleTypes.recruitmentManager) {
      viewType.serviceManagerConsole = false;
      viewType.dashboardPerformance = false;
      viewType.teamMember = false;
      viewType.problemEscalationCompliance = true;
      // viewType.favTask = false;
    } else if (consoleType === ProjectConsoleTypes.globalMyTaskManger) {
      viewType.serviceManagerConsole = false;
      viewType.teamMember = false;
      viewType.reportDetails = true;
      viewType.projectFileManager = false;
    } else if (consoleType === ProjectConsoleTypes.okr) {
      viewType.serviceManagerConsole = false;
      viewType.teamMember = false;
      viewType.reportDetails = true;
      viewType.projectFileManager = false;
      viewType.reportDetails = false;
      viewType.favTask = false;
      viewType.fileUpload = false;
      viewType.checkInCalendar = true;
      viewType.meetingCalendar = true;
      viewType.addSubTask = true;
      viewType.viewSubTask = true;
      viewType.editSubTask = true;
      viewType.sprintBoard = true;
    }

    if (!(this._appState.getAccessType() === 'internal' &&
      (this._appState.getUserTypeSlug() === 'admin' ||
        this._appState.getUserTypeSlug() === 'super-admin'))) {
      viewType.addMilestone = false;
      viewType.editMilestone = false;
      viewType.cloneMilestone = false;
      // viewType.deleteTask = false;
      // viewType.editTask = false;
      viewType.deleteMilestone = false;
    }

    // if (this._appState.getAccessType() === 'internal' &&
    //   (this._appState.getUserTypeSlug() === 'admin' ||
    //     this._appState.getUserTypeSlug() === 'super-admin')) {
    //   viewType.deleteMilestone = true;
    // }

    // Enable edit task for standard users
    // if (this._appState.getAccessType() === 'internal' &&
    //   this._appState.getUserTypeSlug() === 'standard') {
    //   viewType.editTask = true;
    // }
    return viewType;
  }

  getDateTimeAsPriority(priority: string): Object {
    let finalValues = {
      startDate: this._getStartDate(),
      startTime: this._getStartTime(),
      dueDate: null,
      dueTime: null,
      duration: null,
      durationType: 'hours'
    }
    switch (priority) {
      case 'Very Critical (P0)': {
        const hour = 4;
        finalValues.dueDate = this._getDueDateTime(hour, true);
        finalValues.dueTime = this._getDueDateTime(hour);
        finalValues.duration = hour;
        break;
      }
      case 'Critical (P1)': {
        const hour = 4;
        finalValues.dueDate = this._getDueDateTime(hour, true);
        finalValues.dueTime = this._getDueDateTime(hour);
        finalValues.duration = hour;
        break;
      }
      case 'High (P2)': {
        const hour = 8;
        finalValues.dueDate = this._getDueDateTime(hour, true);
        finalValues.dueTime = this._getDueDateTime(hour);
        finalValues.duration = hour;
        break;
      }
      case 'Medium (P3)': {
        const hour = 24;
        finalValues.dueDate = this._getDueDateTime(hour, true);
        finalValues.dueTime = this._getDueDateTime(hour);
        finalValues.duration = (hour / 24);
        finalValues.durationType = 'days';
        break;
      }
      case 'Low (P4)': {
        const hour = 48;
        finalValues.dueDate = this._getDueDateTime(hour, true);
        finalValues.dueTime = this._getDueDateTime(hour);
        finalValues.duration = (hour / 24);
        finalValues.durationType = 'days';
        break;
      }
      case 'Very Low (P5)': {
        const hour = 120;
        finalValues.dueDate = this._getDueDateTime(hour, true);
        finalValues.dueTime = this._getDueDateTime(hour);
        finalValues.duration = (hour / 24);
        finalValues.durationType = 'days';
        break;
      }
      default: {
        finalValues.startTime = null;
        finalValues.durationType = 'none';
        break;
      }
    }
    return finalValues;
  }

  private _getStartDate(): any {
    let d = new Date();
    d.setHours(0, 0, 0, 0);
    return d;
  }

  private _getStartTime(): any {
    let d = new Date();
    return d;
  }

  private _getDueDateTime(h: number, dateOnly?: boolean) {
    let d = new Date();
    d.setTime(d.getTime() + h * 60 * 60 * 1000);
    if (dateOnly) {
      d.setHours(0, 0, 0, 0);
      return d;
    } else {
      return d;
    }
  }

  getAppData(): Object {
    return JSON.parse(localStorage.getItem('appData'));
  }

  async getCurrentUserGroupIds(params, consoleType?, isProjectByUser?) {
    return new Promise((resolve, reject) => {
      const appData: any = this.getAppData();
      this._workerApi.find({
        where: { 'Contact__c': appData && appData.user && appData.user.sfdcId },
        fields: ['sfdcId'],
        include: [
          {
            relation: 'departmentRoles',
            scope: {
              fields: ['sfdcId', 'PgMO_Departments__c', 'PgMO_Groups__c', 'RecordTypeId', 'Membership__c'],
              include: [
                {
                  relation: 'group',
                  scope: {
                    fields: ['sfdcId', 'Name', 'Department_Group_Code__c', 'Project__c']
                  }
                }
              ]
            }
          }
        ]
      }).subscribe(res => {
        const teamsSfdcIds = [];
        if (res && res.length) {
          const record = res[0] && res[0]['departmentRoles'];
          if (params.project) {
          record.forEach(role => {
            // both Group member and team member records to consider for program group project access.
            if (role.RecordTypeId === this.getRecordTypeIdForTeam() 
            || role.RecordTypeId === this.getRecordtypeIdForGroupMember() ||
            role.RecordTypeId=== this.getRecordTypeIdForDepartment()) {
              if (role && role.group && role.group.sfdcId) {
                teamsSfdcIds.push(role.group.sfdcId);
              }
            }
          })
          this.getAssignedProjects({ 'inq': teamsSfdcIds }, resolve, params.project, consoleType, isProjectByUser);
        } else if (params.groupMatching) {
          let checkGroupsAvailable = false;
          record.forEach(role => {
              if (role && role.group && role.group.sfdcId === 'a6S1a0000008UkcEAE') {
                checkGroupsAvailable = true;
               return;
              }
          })
          resolve(checkGroupsAvailable);
        }
        } else {
          resolve([]);
        }
      }, err => {
        resolve([]);
      })
    })
  }

  getAssignedProjects(groups, cb, projectArr, consoleType?, isProjectByUser?) {
    const filterObject: any = {
      and: [
        { 'PgMO_Groups__c': groups },
        { 'PgMO_Projects__c': { 'neq': null } }
      ]
    }
    if (projectArr && projectArr.length && !this.isRMC(consoleType)) {
      filterObject.and.push({ 'PgMO_Projects__c': { 'inq': projectArr.filter(proj => proj.sfdcId).map(({ sfdcId }) => sfdcId) } });
    }
    this._programGroupApi.find(
      this._setWhereCondition(filterObject, consoleType, isProjectByUser)
    ).subscribe(programGrps => {
      if (this.isRMC(consoleType)) {
        const userProjects = new Set();
        if (programGrps && programGrps.length && projectArr && projectArr.length) {
          programGrps.forEach(programGrp => {
            if (programGrp && programGrp['PgMO_Projects__c'] 
              && projectArr.some(({sfdcId}) => sfdcId === programGrp['PgMO_Projects__c'])) {
              userProjects.add(programGrp['PgMO_Projects__c']);
            }
          })
        }
        cb(Array.from(userProjects));
      } else {
        const projetssfdcId = [];
        if (programGrps && programGrps.length) {
          if (isProjectByUser) {
            programGrps.forEach(project => {
              if (project && project['PgMO_Projects__c'] && project['projects']) {
                projetssfdcId.push(project['PgMO_Projects__c']);
              }
            })
          } else {
            programGrps.forEach(project => {
              if (project && project['PgMO_Projects__c']){
                projetssfdcId.push(project['PgMO_Projects__c']);
              }
            })
          }
        }
        cb(projetssfdcId);
      }
    }, err => {
      console.error(err);
      cb([])
    })
  }

  private _setWhereCondition(filterObject, consoleType, isProjectByUser) {
    if (this.isHDC(consoleType) && isProjectByUser) {
      return {
        where: filterObject,
        fields: ['id', 'sfdcId', 'PgMO_Projects__c'],
        include: [
          {
            relation: 'projects',
            scope: {
              where: {'Project_Type__c': consoleType}
            }
          }
        ]
      }
    } else {
      return {
        where: filterObject,
        fields: ['id', 'sfdcId', 'PgMO_Projects__c']
      }
    }
  }

  isHDC(console) {
    return console === ProjectConsoleTypes.helpDesk;
  }

  isRMC(console) {
    return console === ProjectConsoleTypes.recruitmentManager;
  }

  isPMC(console) {
    return console === ProjectConsoleTypes.problemManagement;
  }

  isGTC(console) {
    return console === ProjectConsoleTypes.globalMyTaskManger;
  }

  isTaskCase(console) {
    return console === ProjectConsoleTypes.taskCase;
  }

  getJSDateFromTime(dbTime: string): Date {
    return dbTime ? this._commonService.getDateFromString(dbTime, 'hh:mm:ss.SSS') : null;
  }

  getTimeInDBFormat(jsDate: Date): string {
    return jsDate ? this._commonService.dateFormate(jsDate.toISOString(), '', 'HH:mm:ss.SSS') : '';
  }

  setMidnightDate(date: string): Date {
    if (date) {
      date = date.substring(0, 10);
      return new Date(date + ' 00:00:00');
    } else {
      return null
    }
  }

  async fetchPermissions(consoleType: string) {
    switch (consoleType.toLowerCase()) {
      case 'problemmanagement':
        return await this.problemManagementPerm();
      // case 'recruitmentmanager':
      //   return await this.recuritmentManagementPerm();
      default:
        return {};
    }
  }

  async recuritmentManagementPerm() {
    const groups = await this.getCurrentUserGroupIds({groupMatching: true});
      if (!((groups && this._appState.getAccessType() === 'internal') ||
          this._appState.getUserTypeSlug() === 'super-admin' || this._appState.getUserTypeSlug() === 'admin')
          ) {
      return {
        ADVANCE_VIEW: {
          VIEW: false
        }
      };
    }
    return {};
  }
  async problemManagementPerm() {
    const userDetails = await this.fetchLoggedInUserDetails();
    if (
      !(userDetails['Functional_Role__c'] === 'Vice President'
        || userDetails['Functional_Role__c'] === 'Director'
        || (this._appState.getAccessType() === 'internal'
          && (this._appState.getUserTypeSlug() === 'super-admin' || this._appState.getUserTypeSlug() === 'admin'))
        || (userDetails['Operation_Project_Management_Assignment__c']
          && userDetails['Operation_Project_Management_Assignment__c'].includes('Problem Escalation Management'))
      )
    ) {
      return {
        ADVANCE_VIEW: {
          VIEW: false
        }
      };
    }
    return {};
  }

  fetchLoggedInUserDetails() {
    return new Promise((resolve, reject) => {
      if (this.userDetail) { return resolve(this.userDetail); }
      const appData: any = this.getAppData();
      this._workerApi.findOne({
        where: { 'Contact__c': appData && appData.user && appData.user.sfdcId },
        fields: ['sfdcId', 'Name', 'Functional_Role__c', 'Operation_Project_Management_Assignment__c'],
      }).subscribe(res => {
        this.userDetail = res;
        resolve(this.userDetail);
      }, err => {
        resolve({});
      });
    });
  }

  fetchMilestoneColumnsByConsoleType(consoleType: string, viewTypeData: any , projectObj? :any ) {
    let columns = [];
    switch (consoleType) {
      case ProjectConsoleTypes.problemManagement:
        columns = [
          { name: 'Action', prop: '__', width: 150, visible: (viewTypeData.addMilestone || viewTypeData.editMilestone), sortable: false, frozenLeft: true, type: 'static', showMore: false },
          { name: 'Sequence', prop: 'sequence', width: 100, visible: true, sortable: false, frozenLeft: true, showMore: false },
          { name: 'Milestone', prop: 'Milestone_Name__c', width: 250, visible: true, sortable: true, frozenLeft: true, showMore: true },
          { name: 'Account/Program/Jobsite', prop: 'acccount_program_job', width: 250, visible: true, sortable: true, frozenLeft: false, showMore: true },
          { name: 'Task Count', prop: 'Task_No__c', width: 140, visible: true, sortable: true, showMore: false },
          { name: 'Description', prop: 'Description__c', width: 250, visible: true, sortable: true, showMore: true },
          { name: 'Request/Case Type', prop: 'Request_Type__c', width: 200, visible: true, sortable: true, showMore: true },
          { name: 'Source Department', prop: 'Source_Department__c', width: 200, visible: true, sortable: true, showMore: true },
          { name: 'Source Department/Group', prop: 'Source_DepartmentGroup__c', width: 250, visible: true, sortable: true, showMore: true },
          { name: 'Source Category', prop: 'Source_Category__c', width: 200, visible: true, sortable: true, showMore: true },
          { name: 'Source Sub Category', prop: 'Sub_Category__c', width: 200, visible: true, sortable: true, showMore: true },
          { name: 'Priority', prop: 'Priority__c', width: 200, visible: true, sortable: true, showMore: false },
          { name: 'Status', prop: 'Status__c', width: 200, visible: true, sortable: true, showMore: false },
          { name: 'Requestor', prop: 'Requestor__c', width: 200, visible: true, sortable: true, showMore: true },
          { name: 'Assignment', prop: 'Department_Group_Code__c', width: 200, visible: true, sortable: true, type: 'list', showMore: true },
          { name: 'Created By Contact', prop: 'Created_By_Contact__c', width: 200, visible: true, sortable: true, showMore: true },
          { name: 'Date Created', prop: 'createdDate', width: 180, visible: true, sortable: true, type: 'date', showMore: false },
          { name: 'ID', prop: 'id', visible: false }
        ];
        break;
      case ProjectConsoleTypes.okr:
        columns = [
          { name: 'Action', prop: '__', width: 150, visible: true, sortable: false, frozenLeft: true, type: 'static', showMore: false },
          { name: 'Sequence', prop: 'sequence', width: 100, visible: true, sortable: false, frozenLeft: true, showMore: false },
          { name: 'Objective', prop: 'Objective_Name__c', width: 250, visible: true, sortable: true, frozenLeft: true, showMore: true },
          { name: 'Objective Type', prop: 'Objective_Assignment_Type__c', width: 140, visible: true, sortable: true, frozenLeft: false, showMore: false },
          // { name: 'Parent', prop: 'Parent_Milestone__c', width: 250, visible: true, sortable: true, showMore: true },
          { name: 'Rating', prop: 'Objective_Rating__c', width: 140, visible: true, sortable: true, showMore: false },
          { name: 'Grade (Weightage)', prop: 'Complete__c', width: 180, visible: true, sortable: true, showMore: false },
          { name: 'Status', prop: 'Objective_Status__c', width: 140, visible: true, sortable: true, showMore: false },
          { name: 'Period', prop: 'Period__c', width: 140, visible: true, sortable: true, showMore: false },
          { name: 'Assignment Group/Personal', prop: 'Objective_Assignment', width: 250, visible: true, sortable: true, showMore: true },
          { name: 'Owner', prop: 'Requestor__c', width: 200, visible: true, sortable: true, showMore: true },
          { name: 'Initiative Milestone', prop: 'Initiative_Milestone__c', width: 180, visible: true, sortable: true, showMore: false },
        ];
        break;
        case ProjectConsoleTypes.taskCase:
        columns = [
          { name: 'Action', prop: '__', width: 200, visible: (viewTypeData.addMilestone || viewTypeData.editMilestone), sortable: false, frozenLeft: true, type: 'static', showMore: false },
          { name: 'Sequence', prop: 'sequence', width: 100, visible: true, sortable: false, frozenLeft: true, showMore: false, summaryFunc: (cells) => projectObj['sequence'] },
          { name: 'Milestone', prop: 'Milestone_Name__c', width: 250, visible: true, sortable: true, frozenLeft: true, showMore: true, summaryFunc: (cells) => projectObj['projectName'] },
          { name: 'Account/Program/Jobsite', prop: 'acccount_program_job', width: 220, visible: true, sortable: true, frozenLeft: false, showMore: true, summaryFunc: (cells) => projectObj['acccount_program_job']  },
          { name: 'Task Count', prop: 'Task_No__c', width: 140, visible: true, sortable: true, showMore: false },
          { name: 'Milestone Type', prop: 'Milestone_Request_Type__c', width: 220, visible: true, sortable: true, showMore: false },
          { name: 'Priority', prop: 'Priority__c', width: 200, visible: true, sortable: true, showMore: false },
          { name: 'Status (%)', prop: 'Status__c', width: 200, visible: true, sortable: true, showMore: false },
          { name: 'Form Name', prop: 'Form_Name__c', width: 200, visible: true, sortable: true, showMore: false },
          { name: 'Partner Case', prop: 'Partner_Case', width: 200, visible: true, sortable: true, showMore: false },
          { name: 'Work Order / Job Order', prop: 'Work_Order', width: 200, visible: true, sortable: true, showMore: false },
          { name: 'PMS (Status) / Job (Status)', prop: 'PMS_Status', width: 212, visible: true, sortable: true, showMore: false },
          { name: 'Work Order Plan Date', prop: 'Word_Order_Plan_Date', width: 200, visible: true, sortable: true,  type: 'date', showMore: false },
          { name: 'Start Date Time', prop: 'Start_Date__c', width: 180, visible: true, sortable: true, type: 'date', showMore: false },
          { name: 'End Date Time', prop: 'Due_Date__c', width: 180, visible: true, sortable: true, showMore: false, type: 'date' },
          { name: 'Customer Requested Date ISO', prop: 'Customer_Requested_Date_ISO', width: 270, visible: true, sortable: true, type: 'date', showMore: false },
          { name: 'Customer Requested Date', prop: 'Customer_Requested_Date', width: 210, visible: true, sortable: true, type: 'date', showMore: false },
          // { name: 'Worker Scheduled Date', prop: 'Worker_Scheduled_Date', width: 200, visible: true, sortable: true, showMore: false },
          // { name: 'Deliverables (L5) Due Date', prop: 'Deliverable_Due_Date', width: 210, visible: true, sortable: true, type: 'date', showMore: false },
          { name: 'PPE Hours', prop: 'PPE_Hours', width: 200, visible: true, sortable: true, showMore: false },
          { name: 'Assigned Vendor', prop: 'Assigned_Worker', width: 200, visible: true, sortable: true, showMore: false },
          { name: 'Assigned APVP Worker', prop: 'Assigned_APVP_Worker', width: 200, visible: true, sortable: true, showMore: false },
          { name: 'Assignment', prop: 'Department_Group_Code__c', width: 200, visible: true, sortable: true, type: 'list', showMore: true },
          { name: 'Membership', prop: 'Department_Member__c', width: 200, visible: true, sortable: true, type: 'list', showMore: true },
          { name: 'ID', prop: 'id', visible: false },
          { name: 'Created By Contact', prop: 'createdByContact', width: 200, visible: true, sortable: true, showMore: true },
          { name: 'Date Created', prop: 'createdDate', width: 180, visible: true, sortable: true, type: 'date', showMore: false },
        ];
        break;
      default:
        columns = [
          { name: 'Action', prop: '__', width: 150, visible: (viewTypeData.addMilestone || viewTypeData.editMilestone), sortable: false, frozenLeft: true, type: 'static', showMore: false },
          { name: 'Sequence', prop: 'sequence', width: 100, visible: true, sortable: false, frozenLeft: true, showMore: false },
          { name: 'Milestone', prop: 'Milestone_Name__c', width: 250, visible: true, sortable: true, frozenLeft: true, showMore: true },
          { name: 'Account/Program/Jobsite', prop: 'acccount_program_job', width: 220, visible: true, sortable: true, frozenLeft: false, showMore: true },
          { name: 'Task Count', prop: 'Task_No__c', width: 140, visible: true, sortable: true, showMore: false },
          { name: 'Status', prop: 'Status__c', width: 200, visible: true, sortable: true, showMore: false },
          { name: 'Start Date Time', prop: 'Start_Date__c', width: 180, visible: true, sortable: true, type: 'date', showMore: false },
          { name: 'End Date Time', prop: 'Due_Date__c', width: 180, visible: true, sortable: true, showMore: false, type: 'date' },
          { name: 'Assignment', prop: 'Department_Group_Code__c', width: 200, visible: true, sortable: true, type: 'list', showMore: true },
          { name: 'Membership', prop: 'Department_Member__c', width: 200, visible: true, sortable: true, type: 'list', showMore: true },
          { name: 'ID', prop: 'id', visible: false },
          { name: 'Created By Contact', prop: 'createdByContact', width: 200, visible: true, sortable: true, showMore: true },
          { name: 'Created At', prop: 'createdDate', width: 180, visible: true, sortable: true, type: 'date', showMore: false },
        ];
        break;
    }
    return columns.map(this.calculateMaxLength);
  }

  fetchTaskColumnsByConsoleType(consoleType: string, viewTypeData: any, milestoneObj: any, isVmsaUser?) {
    let columns = [];
    switch (consoleType) {
      case ProjectConsoleTypes.problemManagement:
        columns = [
          { name: 'Action', prop: '__', width: 240, visible: (viewTypeData.addTask || viewTypeData.editTask), sortable: false, frozenLeft: true, type: 'static', showMore: false, summaryFunc: (cells) => milestoneObj['Activity'] },
          { name: 'Sequence', prop: 'Task_sequence', width: 100, visible: true, sortable: false, frozenLeft: true, showMore: false, summaryFunc: (cells) => milestoneObj['sequence'] },
          { name: 'Task/Milestone', prop: 'Task_Name__c', width: 250, visible: true, sortable: true, frozenLeft: true, showMore: true, summaryFunc: (cells) => milestoneObj['Milestone_Name__c'] },
          { name: 'Account/Program/Jobsite', prop: 'Task_acccount_program_job', width: 220, visible: true, sortable: true, showMore: true, summaryFunc: (cells) => milestoneObj['acccount_program_job'] },
          { name: 'Task Count', prop: 'Task_Count', width: 180, visible: true, sortable: false, showMore: false, summaryFunc: (cells) => milestoneObj['Task_No__c'] },
          { name: 'Description', prop: 'description', width: 180, visible: true, sortable: true, showMore: true, summaryFunc: (cells) => milestoneObj['Description__c'] },
          { name: 'Partner Account', prop: 'partnerAccount', width: 180, visible: true, sortable: true, showMore: false, summaryFunc: (cells) => milestoneObj[''] },
          { name: 'Request/Case Type', prop: 'requestType', width: 180, visible: true, sortable: true, showMore: false },
          { name: 'Source Department', prop: 'sourceDept', width: 180, visible: true, sortable: true, type:'list', showMore: true },
          { name: 'Source Department/Group', prop: 'sourceDeptGroup', width: 210, visible: true, sortable: true, type:'list', showMore: true },
          { name: 'Source Category', prop: 'sourceCategory', width: 180, visible: true, sortable: true, showMore: false },
          { name: 'Source Sub Category', prop: 'subCategory', width: 180, visible: true, sortable: true, showMore: true },
          { name: 'Problem Reason Code', prop: 'reasonCode', width: 190, visible: true, sortable: true, showMore: false },
          { name: 'Problem Reason Sub Code', prop: 'reasonSubCode', width: 210, visible: true, sortable: true, showMore: false },
          { name: 'Priority', prop: 'priority', width: 200, visible: true, sortable: true, showMore: false, summaryFunc: (cells) => milestoneObj['Priority__c'] },
          { name: 'Problem Resolution Status', prop: 'problemResStatus', width: 220, visible: true, sortable: true, showMore: false, summaryFunc: (cells) => milestoneObj['Status__c'] },
          { name: 'Problem Date', prop: 'Task_Start_Date__c', width: 180, visible: true, sortable: true, type: 'date', showMore: false, summaryFunc: (cells) => milestoneObj['Start_Date__c'] },
          { name: 'Requestor', prop: 'requestor', width: 180, visible: true, sortable: true, showMore: false, summaryFunc: (cells) => milestoneObj['Requestor__c'] },
          { name: 'Assignment', prop: 'Task_Department_Group_Code__c', width: 200, visible: true, sortable: true, type:'list', showMore: true, summaryFunc: (cells) => milestoneObj['Department_Group_Code__c'] },
        ];
        break;
      case ProjectConsoleTypes.okr:
          columns = [
            { name: 'Action', prop: '__', width: 160, visible: (viewTypeData.addTask || viewTypeData.editTask), sortable: false, frozenLeft: true, type: 'static', showMore: false, summaryFunc: (cells) => milestoneObj['Activity'] },
            { name: 'Sequence', prop: 'Task_sequence', width: 100, visible: true, sortable: false, frozenLeft: true, showMore: false, summaryFunc: (cells) => milestoneObj['sequence'] },
            { name: 'Key Results/Initiatives', prop: 'Task_Name__c', width: 250, visible: true, sortable: true, frozenLeft: true, showMore: true, summaryFunc: (cells) => milestoneObj['Objective_Name__c'] },
            { name: 'Key Result Type', prop: 'Key_Result_Type__c', width: 180, visible: true, sortable: true, frozenLeft: false, showMore: false, summaryFunc: (cells) => milestoneObj['Objective_Assignment_Type__c']  },
            { name: 'Parent', prop: 'Parent_Milestone__c', width: 250, visible: true, sortable: true, showMore: true },
            { name: 'Rating', prop: 'Key_Results_Rating__c', width: 150, visible: true, sortable: true, showMore: false, summaryFunc: (cells) => milestoneObj['Objective_Rating__c']  },
            { name: 'Grade (Weightage)', prop: 'Complete__c', width: 180, visible: true, sortable: true, showMore: false, summaryFunc: (cells) => milestoneObj['Complete__c']  },
            { name: 'Status', prop: 'OKR_Status__c', width: 150, visible: true, sortable: true, showMore: false, summaryFunc: (cells) => milestoneObj['Objective_Status__c']  },
            // { name: 'Period', prop: 'Period__c', width: 140, visible: true, sortable: true, showMore: false, summaryFunc: (cells) => milestoneObj['Form_Name__c']  },
            { name: 'Assignment Group/Personal', prop: 'Objective_Assignment', width: 250, visible: true, sortable: true, showMore: true, summaryFunc: (cells) => milestoneObj['Objective_Assignment']  },
            { name: 'Owner', prop: 'requestor', width: 200, visible: true, sortable: true, showMore: true, summaryFunc: (cells) => milestoneObj['Requestor__c']  },
            { name: 'Initiative Milestone', prop: 'Initiative_Milestone__c', width: 180, visible: true, sortable: true, showMore: false, summaryFunc: (cells) => milestoneObj['Initiative_Milestone__c']  },
          ];
          break;
          case ProjectConsoleTypes.taskCase:
            columns = [
              { name: 'Action', prop: '__', width: (isVmsaUser ? 160 : 240), visible: true, sortable: false, frozenLeft: true, type: 'static', showMore: false, summaryFunc: (cells) => milestoneObj['Activity']  },
              { name: 'Sequence', prop: 'Task_sequence', width: 100, visible: isVmsaUser ? false : true, sortable: false, frozenLeft: true, showMore: false, summaryFunc: (cells) => milestoneObj['sequence'] },
              { name: 'Task/Milestone', prop: 'Task_Name__c', width: 250, visible: true, sortable: true, frozenLeft: true, showMore: true, summaryFunc: (cells) => milestoneObj['Milestone_Name__c'] },
              { name: 'Account/Program/Jobsite', prop: 'Task_acccount_program_job', width: 220, visible: true, sortable: true, showMore: true, summaryFunc: (cells) => milestoneObj['acccount_program_job']},
              { name: 'Program Name', prop: 'Program_Name', visible: false },
              { name: 'Task Count', prop: 'Task_Count', width: 180, visible: true, sortable: false, showMore: false, summaryFunc: (cells) => milestoneObj['Task_No__c'] },
              { name: 'Task Type', prop: 'Task_Type__c', width: 180, visible: true, sortable: false, showMore: false, summaryFunc: (cells) => milestoneObj['Task_Type__c'] },
              { name: 'NPT Program', prop: 'NPT_Program__c', width: 180, visible: true, sortable: false, showMore: false, summaryFunc: (cells) => milestoneObj['NPT_Program__c'] },
              { name: 'Priority', prop: 'priority', width: 200, visible: true, sortable: true, showMore: false, summaryFunc: (cells) => milestoneObj['Priority__c'] },
              { name: 'Status', prop: 'Task_Status__c', width: 200, visible: true, sortable: true, showMore: false , summaryFunc: (cells) => milestoneObj['Status__c']},
              { name: 'Form Name', prop: 'Form_Name__c', width: 180, visible: true, sortable: true, showMore: false, summaryFunc: (cells) => milestoneObj['Form_Name__c']},
              { name: 'Partner Case', prop: 'Partner_Case', width: 180, visible: true, sortable: true, showMore: false, summaryFunc: (cells) => milestoneObj['Partner_Case']},
              { name: 'Work Order / Job Order', prop: 'Work_Order', width: 186, visible: true, sortable: true, showMore: false, summaryFunc: (cells) => milestoneObj['Work_Order']},
              { name: 'PMS (Status) / Job (Status)', prop: 'PMS_Status', width: 212, visible: true, sortable: true, showMore: false, summaryFunc: (cells) => milestoneObj['PMS_Status']},
              { name: 'Work Order Plan Date', prop: 'Word_Order_Plan_Date', width: 180, visible: true, sortable: true, type: 'date', showMore: false, summaryFunc: (cells) => milestoneObj['Word_Order_Plan_Date']},
              { name: 'Customer Requested Date ISO', prop: 'Customer_Requested_Date_ISO', width: 270, visible: true, sortable: true, type: 'date', showMore: false, summaryFunc: (cells) => milestoneObj['Customer_Requested_Date_ISO']},
              { name: 'Customer Requested Date', prop: 'Customer_Requested_Date', width: 210, visible: true, sortable: true, type: 'date', showMore: false, summaryFunc: (cells) => milestoneObj['Customer_Requested_Date']},
              { name: 'Worker Scheduled Date', prop: 'Worker_Scheduled_Date', width: 200, visible: true, sortable: true, type: 'date', showMore: false },
              { name: 'Deliverables (L5) Due Date', prop: 'Deliverable_Due_Date', width: 210, visible: true, sortable: true, type: 'date', showMore: false, summaryFunc: (cells) => milestoneObj['Duration__c'] },
              { name: 'PPE Hours', prop: 'PPE_Hours', width: 180, visible: true, sortable: true, showMore: false },
              { name: 'Requested Worker/Vendor', prop: 'Requested_Worker', width: 204, visible: true, sortable: true, showMore: false },
              { name: 'Assigned Worker/Vendor', prop: 'Assigned_Worker', width: 204, visible: true, sortable: true, showMore: false },
              { name: 'WAR Status', prop: 'WAR_Status', width: 180, visible: true, sortable: true, showMore: false },
              { name: 'Start Date Time', prop: 'Task_Start_Date__c', width: 180, visible: true, sortable: true, type: 'date', showMore: false, summaryFunc: (cells) => milestoneObj['Start_Date__c'] },
              { name: 'End Date Time', prop: 'Task_Due_Date__c', width: 180, visible: true, sortable: true, showMore: false, type: 'date', summaryFunc: (cells) => milestoneObj['Due_Date__c']},
              { name: 'Assignment', prop: 'Task_Department_Group_Code__c', width: 200, visible: true, sortable: true, type: 'list', showMore: true, summaryFunc: (cells) => milestoneObj['Department_Group_Code__c']},
              { name: 'Membership', prop: 'Task_Department_Member__c', width: 200, visible: true, sortable: true, type: 'list', showMore: true, summaryFunc: (cells) => milestoneObj['Department_Member__c']}
            ];
            break;
       case ProjectConsoleTypes.rmcEscalationCreation:
          columns = [
            { name: 'Action', prop: '__', width: 500, visible: true, sortable: false, type: 'static', showMore: false, summaryFunc: (cells) => milestoneObj['Activity'] },
            { name: 'Sequence', prop: 'Task_sequence', width: 100, visible: isVmsaUser ? false : true, sortable: false, showMore: false, summaryFunc: (cells) => milestoneObj['sequence'] },
            { name: 'Task/Milestone', prop: 'Task_Name__c', width: 700, visible: true, sortable: true, showMore: true, summaryFunc: (cells) => milestoneObj['Milestone_Name__c'] },
            { name: 'Task Count', prop: 'Task_Count', width: 200, visible: true, sortable: false, showMore: false, summaryFunc: (cells) => milestoneObj['Task_No__c'] },
            ];
          break;      
      default:
        columns = [
          { name: 'Action', prop: '__', width: (isVmsaUser ? 160 : 240), visible: true, sortable: false, frozenLeft: true, type: 'static', showMore: false, summaryFunc: (cells) => milestoneObj['Activity'] },
          { name: 'Sequence', prop: 'Task_sequence', width: 100, visible: isVmsaUser ? false : true, sortable: false, frozenLeft: true, showMore: false, summaryFunc: (cells) => milestoneObj['sequence'] },
          { name: 'Task/Milestone', prop: 'Task_Name__c', width: 250, visible: true, sortable: true, frozenLeft: true, showMore: true, summaryFunc: (cells) => milestoneObj['Milestone_Name__c'] },
          { name: 'Account/Program/Jobsite', prop: 'Task_acccount_program_job', width: 220, visible: true, sortable: true, showMore: true, summaryFunc: (cells) => milestoneObj['acccount_program_job'] },
          { name: 'Program Name', prop: 'Program_Name', visible: false },
          { name: 'Task Count', prop: 'Task_Count', width: 180, visible: true, sortable: false, showMore: false, summaryFunc: (cells) => milestoneObj['Task_No__c'] },
          { name: 'Priority', prop: 'priority', width: 200, visible: true, sortable: true, showMore: false, summaryFunc: (cells) => milestoneObj['Priority__c'] },
          { name: 'Status', prop: 'Task_Status__c', width: 200, visible: true, sortable: true, showMore: false, summaryFunc: (cells) => milestoneObj['Status__c'] },
          { name: 'Start Date Time', prop: 'Task_Start_Date__c', width: 180, visible: true, sortable: true, type: 'date', showMore: false, summaryFunc: (cells) => milestoneObj['Start_Date__c'] },
          { name: 'End Date Time', prop: 'Task_Due_Date__c', width: 180, visible: true, sortable: true, showMore: false, type: 'date', summaryFunc: (cells) => milestoneObj['Due_Date__c'] },
          { name: 'Assignment', prop: 'Task_Department_Group_Code__c', width: 200, visible: true, sortable: true, type:'list', showMore: true, summaryFunc: (cells) => milestoneObj['Department_Group_Code__c'] },
          { name: 'Membership', prop: 'Task_Department_Member__c', width: 200, visible: true, sortable: true, type:'list', showMore: true, summaryFunc: (cells) => milestoneObj['Department_Member__c'] },
          { name: 'Business Requestor', prop: 'businessRequestor', width: 200, visible: true, sortable: true, showMore: true, summaryFunc: (cells) => milestoneObj['businessRequestor'] },
        ];
        break;
    }
    return columns.map(this.calculateMaxLength);
  };

  calculateMaxLength(column) {
    if (column.showMore) {
      // Down Arrow Width = 13.64, Three Dots Width = 17.27, Approx. Average Character Width = 9
      let occupiedWidth = 31;
      if (['Milestone_Name__c', 'Task_Name__c'].includes(column.prop)) 
        occupiedWidth += 17.5; // M or T icon width
      column.maxLength = Math.floor(((column.width || 100) - occupiedWidth)/8.5);;
    }
    return column;
  }

  removeDuplicatesFromArray(arr) {
    const teamsSfdcIds = arr.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });
    return teamsSfdcIds;
  }

  async getDepartmentGroups(deptSfdcIds?: Array<string>) {
    return new Promise((res, rej) => {
      const depts = [];
      const groups = [];
      if (deptSfdcIds && deptSfdcIds.length) {
        this._departmentApi.find({
          where: { sfdcId: { inq: deptSfdcIds } },
          fields: ['sfdcId', 'Department_Name__c'],
          include: [
            {
              relation: 'departmentRole',
              scope: {
                fields: ['sfdcId', 'PgMO_Departments__c', 'PgMO_Groups__c', 'RecordTypeId', 'Group_Community__c'],
                include: [
                  {
                    relation: 'group',
                    scope: {
                      fields: ['sfdcId', 'Name', 'Group_Code_Name__c', 'Department_Group_Code__c'],
                    }
                  }
                ]
              }
            }
          ]
        }).subscribe(departments => {
          if (departments && departments.length) {
            departments.forEach(dept => {
              if (dept && dept['sfdcId'] && !depts.some(({ sfdcId }) => sfdcId === dept['sfdcId'])) {
                depts.push(dept);
              }
              if (dept && dept['departmentRole']) {
                dept['departmentRole'].forEach(deptRole => {
                  if (deptRole && deptRole['group']
                    && !groups.some(({ sfdcId }) => sfdcId === deptRole['group']['sfdcId'])) {
                    const groupName = deptRole['group']['Name'] + ' (' + deptRole['group']['Department_Group_Code__c'] + ')';
                    groups.push({
                      sfdcId: deptRole['group']['sfdcId'],
                      name: groupName,
                    })
                  }
                });
              }
            });
          }
          res({ depts, groups });
        }, error => {
          console.error(error);
          res({ depts, groups });
        });
      } else {
        res({ depts, groups });
      }
    });
  }

  isAdmin() {
    return (this._appState.getAccessType() === 'internal' &&
      (this._appState.getUserTypeSlug() === 'admin' ||
        this._appState.getUserTypeSlug() === 'super-admin'));
  }

  isInternalStandardUser() {
    return (this._appState.getAccessType() === 'internal' &&
      this._appState.getUserTypeSlug() === 'standard');
  }

  prepareProject2Clause(consoleType) {
    // switch (consoleType) {
    //  case ProjectConsoleTypes.recruitmentManager:
    //     return { RecordTypeId: '0121a000000F1IEAA0', Sub_Catergory__c: 'Recruitment', Category__c: 'Others' };
    //   case ProjectConsoleTypes.globalMyTaskManger:
    //     return {};
    //   default:
    //     return { Project_Type__c: consoleType };
    // }
    var where: any = {};
    if(consoleType && consoleType.length) {
      if(consoleType.includes('RecruitmentManager')) {
        var consoles = JSON.parse(JSON.stringify(consoleType));
        let index = consoles.indexOf('RecruitmentManager');
        consoles.splice(index, 1);
        where = {
          or: [
            {
              Project_Type__c: { inq: consoles }
            },
            {
              and: [
                { RecordTypeId: '0121a000000F1IEAA0' },
                { Sub_Catergory__c: 'Recruitment' },
                { Category__c: 'Others' }
              ]
            }
          ]
        }
      } else {
        where['Project_Type__c'] = {inq: consoleType};
      }
    }
    if(where && where['or'] && where['or'][0] && where['or'][0]['Project_Type__c'] && where['or'][0]['Project_Type__c'].inq && where['or'][0]['Project_Type__c'].inq.length === 0){
      where = {
        and: [
          { RecordTypeId: '0121a000000F1IEAA0' },
          { Sub_Catergory__c: 'Recruitment' },
          { Category__c: 'Others' }
        ]
      }
    }
    return where;
  }

  getProjectsByConsole(consoleType, sfdcIdsOnly?: Boolean, limit?: number) {
    return new Promise(async (resolve, reject) => {
      // TODO :: Uncomment code once we done with assignment console type check
      // let whereCondition;
      // if (this.isAdmin()) {
      //   whereCondition = { 'Project_Type__c': consoleType };
      // } else {
      //   const projectsfdcIds = await this.getCurrentUserGroupIds();
      //   whereCondition = { 'sfdcId': { inq: projectsfdcIds } };
      // }
      const filterObj = {
        where: this.prepareProject2Clause(consoleType),
        fields: ['sfdcId', 'ProjectName__c', 'Project_No__c', 'Project_Type__c'],
        skip: 0,
        limit: limit ? limit : 1000,
        order: 'ProjectName__c  ASC',
      }
      this._project2Api.find(filterObj).subscribe(data => {
        let results;
        if (data && data.length) {
          if(sfdcIdsOnly) {
            results = data.map(x => x['sfdcId'])
          }else {
            results = data;
          }          
        } else {
          results = [];
        }
        resolve(results);
      }, err => {
        resolve({});
      });
    });
  }

  getMilestoneOfProject(whereObj) {
    return new Promise((resolve, reject) => {
      const milestoneFilterObj = {
        where: whereObj,
        fields: ['sfdcId', 'Milestone_No__c', 'Name', 'Account__c']
      }
      this._milestoneApi.find(milestoneFilterObj).subscribe(data => {
        let results;
        if (data && data.length) {
          results = data;
        } else {
          results = [];
        }
        resolve(results);
      }, error => {
        resolve({});
      });
    });
  }

  async fetchEscalatedTaskDetails(escTaskSfdcId) {
    const esclatedTaskFilter = {
      where: { sfdcId: escTaskSfdcId },
      fields: ['sfdcId', 'Task_No__c', 'Task_Number__c', 'Name', 'Description__c', 'Request_Description__c',
        'PgMO_Programs__c', 'Account__c', 'Requestor__c', 'PgMO_Projects__c',
        'PMS_Case_Number__c', 'Work_Order_Number__c', 'ICC_Case_Number__c', 'Job_Number__c',
        'Sales_Order__c', 'Purchase_Order__c', 'PgMO_Milestones__c', 'Related_Work_Order__c',
        'Request_Form_Receipt__c', 'Appointment__c'
      ],
      include: [
        {
          relation: 'appointment',
          scope: {
            fields: ['sfdcId', 'ICC_Case__c'],
            include: [
              {
                relation: 'Case', // ICC Case
                scope: {
                  fields: ['sfdcId', 'Sales_Order_Reference__c', 'Purchase_Order_Reference__c'],
                  include: [
                    {
                      relation: 'order', // Sales Order
                      scope: {
                        fields: ['sfdcId', 'OrderNumber']
                      }
                    },
                    {
                      relation: 'purchaseOrder',
                      scope: {
                        fields: ['sfdcId', 'Work_Order_num__c']
                      }
                    },
                  ]
                }
              }
            ]
          }
        },
        {
          relation: 'milestone',
          scope: {
            fields: ['sfdcId', 'Name', 'Milestone_No__c']
          }
        },
        {
          relation: 'program',
          scope: {
            fields: ['sfdcId', 'Name', 'ProgramName__c']
          }
        },
        {
          relation: 'account',
          scope: {
            fields: ['sfdcId', 'Name']
          }
        },
        {
          relation: 'requestorWorker',
          scope: {
            fields: ['sfdcId', 'Name']
          }
        },
        {
          relation: 'project',
          scope: {
            fields: ['sfdcId', 'Name', 'Project_Type__c']
          }
        },
        {
          relation: 'relatedWorkOrder',
          scope: {
            fields: ['sfdcId', 'Appointment__c', 'Name', 'PMS_Case__c', 'Jobsite__c'],
            include: [
              {
                relation: 'appointment',
                scope: {
                  fields: ['sfdcId', 'ICC_Case__c', 'Job__c'],
                  include: [
                    {
                      relation: 'Case', // ICC Case
                      scope: {
                        fields: ['sfdcId', 'CaseNumber', 'Sales_Order_Reference__c', 'Purchase_Order_Reference__c'],
                        include: [
                          {
                            relation: 'order', // Sales Order
                            scope: {
                              fields: ['sfdcId', 'OrderNumber']
                            }
                          },
                          {
                            relation: 'purchaseOrder',
                            scope: {
                              fields: ['sfdcId', 'Work_Order_num__c']
                            }
                          },
                        ]
                      }
                    },
                    {
                      relation: 'Job',
                      scope: {
                        fields: ['sfdcId', 'Name']
                      }
                    }
                  ]
                }
              },
              {
                relation: 'Case', // PMS Case
                scope: {
                  fields: ['sfdcId', 'Iron_Case_Number__c']
                }
              },
              {
                relation: 'Jobsite',
                scope: {
                  fields: ['sfdcId', 'Name', 'Region__c', 'Country__c', 'GEO_Metro__c'],
                  include: [
                    {
                      relation: 'GeoMetro',
                      scope: {
                        fields: ['sfdcId', 'Name', 'Region__c']
                      }
                    }
                  ]
                }
              }
            ]
          }
        },
        {
          relation: 'assignment',
          scope: {
            fields: ['sfdcId', 'PgMO_Groups__c', 'Worker__c', 'Assigned_to_Team_Shift__c', 'IsDeleted'],
            include: [
              {
                relation: 'group',
                scope: {
                  fields: ['sfdcId', 'Name', 'Department__c'],
                  include: [
                    {
                      relation: 'department',
                      scope: {
                        fields: ['sfdcId', 'Name'],
                        // include: [
                        //   {
                        //     relation: 'departmentRole',
                        //     scope: {
                        //       fields: ['sfdcId', 'PgMO_Departments__c', 'Worker__c'],
                        //       where: {
                        //         RecordTypeId: '0121a000000F1BCAA0', // Group Member
                        //       }
                        //       include: [
                        //         {
                        //           relation: 'worker',
                        //           scope: {
                        //             fields: ['sfdcId', 'Name']
                        //           }
                        //         }
                        //       ]
                        //     }
                        //   }
                        // ]
                      }
                    }
                  ]
                }
              },
              {
                relation: 'worker',
                scope: {
                  fields: ['sfdcId', 'Name']
                }
              },
              {
                relation: 'assignedToTeamShift',
                scope: {
                  fields: ['sfdcId', 'Name']
                }
              },
            ]
          }
        },
        {
          relation: 'accessControl',
          scope: {
            fields: ['sfdcId', 'Access__c', 'Member__c', 'PgMO_Groups__c', 'IsDeleted'],
            include: [
              {
                relation: 'group',
                scope: {
                  fields: ['sfdcId', 'Name', 'Department__c'],
                  include: [
                    {
                      relation: 'department',
                      scope: {
                        fields: ['sfdcId', 'Name']
                      }
                    }
                  ]
                }
              },
              {
                relation: 'worker',
                scope: {
                  fields: ['sfdcId', 'Name']
                }
              },
            ]
          }
        },
        {
          relation: 'requestFormReceipt',
          scope: {
            fields: ['Name', 'sfdcId', 'Case__c', 'Service_Dispatch_Priority__c', 'Service_Engineer_Talent_Type__c', 'Service_Coverage_Type__c', 'Service_Engineer_Expertise_Level__c', 'Service_Type__c', 'id', 'Service_Engineer_Talent_Type__r', 'Program__c', 'Partner_Case_Number__c', 'FRU_SKU__c', 'Account_Name__c', 'ICCCase__c', 'Work_Order__c', 'Jobsite__c', 'PMS_Case_Number__c'],
            include: [
              {
                relation: 'iccCase',
                scope: {
                  fields: ['sfdcId', 'CaseNumber', 'Case_Summary__c', 'Description']
                }
              },
              {
                relation: 'case',
                scope: {
                  fields: ['sfdcId', 'CaseNumber', 'Case_Summary__c', 'Description']
                }
              },
              {
                relation: 'workOrder',
                scope: {
                  fields: ['sfdcId', 'Name']
                }
              },
              {
                relation: 'jobsite',
                scope: {
                  fields: ['sfdcId', 'Name']
                }
              },
              {
                relation: 'project',
                scope: {
                  fields: ['sfdcId', 'Name']
                }
              },
              {
                relation: 'product',
                scope: {
                  fields: ['sfdcId', 'Name']
                }
              },
              {
                relation: 'talentType',
                scope: {
                  fields: ['sfdcId', 'Name']
                }
              },
            ],
          }
        },
        {
          relation: 'requestFormReceiptLine',
          scope: {
            fields: ['Name', 'Vendor__c', 'Job__c', 'sfdcId', 'Worker__c', 'Program__c', 'FRU_SKU__c', 'Account_Name__c', 'Work_Order__c'],
            include: [
              {
                relation: 'product',
                scope: {
                  fields: ['sfdcId', 'Name']
                }
              },
              {
                relation: 'account',
                scope: {
                  fields: ['sfdcId', 'Name']
                }
              },
              {
                relation: 'worker',
                scope: {
                  fields: ['sfdcId', 'Name']
                }
              },
              {
                relation: 'job',
                scope: {
                  fields: ['sfdcId', 'Name', 'Dispatch_Worker_Name__c', 'Vendor__c', 'Iron_Job_num__c'],
                  include: [
                    {
                      relation: 'worker',
                      scope: {
                        fields: ['sfdcId', 'Name', 'Dispatch_Worker_Name__r', 'Department_Name__c']
                      }
                    }
                  ]
                }
              },
            ],
          }
        },
      ]
    }
    return new Promise(resolve => {
      this._taskApi.findOne(esclatedTaskFilter).subscribe(
        res => {
          if (res) {
            const deptSfdcIds = new Set();
            res['assignment'].forEach(assignment => {
              if (assignment && assignment.group && assignment.group.department && assignment.group.department.sfdcId) {
                deptSfdcIds.add(assignment.group.department.sfdcId);
              }
            });
            this._departmentApi.find({
              where: { sfdcId: { inq: Array.from(deptSfdcIds) } },
              include: [
                {
                  relation: 'departmentRole',
                  scope: {
                    fields: ['sfdcId', 'PgMO_Groups__c', 'PgMO_Departments__c', 'Worker__c'],
                    where: {
                      RecordTypeId: '0121a000000F1BCAA0', // Group Member
                    },
                    include: [
                      {
                        relation: 'worker',
                        scope: {
                          fields: ['sfdcId', 'Name', 'Functional_Role__c'],
                          where: {
                            Functional_Role__c: { inq: ['Vice President', 'Director'] }
                          }
                        }
                      },
                      {
                        relation: 'group',
                        scope: {
                          fields: ['sfdcId'],
                          where: {
                            RecordTypeId: '0121a000000Vll7AAC' // Group (General / Everyone)
                          }
                        }
                      }
                    ]
                  }
                }
              ]
            }).subscribe(dept => {
              if (dept && dept.length) {
                res['assignment'].forEach(assignment => {
                  const vp = [];
                  const director = [];
                  if (assignment && assignment.group && assignment.group.department && assignment.group.department.sfdcId) {
                    const department = dept.find(ele => ele['sfdcId'] === assignment.group.department.sfdcId);
                    if (department && department['departmentRole'] && department['departmentRole'].length) {
                      department['departmentRole'].forEach(deptRole => {
                        if (deptRole && deptRole['worker'] && deptRole['worker']['Functional_Role__c']
                          && deptRole['group']) {
                          if (deptRole['worker']['Functional_Role__c'] === 'Vice President'
                            && !vp.some(({ sfdcId }) => sfdcId === deptRole['worker']['sfdcId'])) {
                            vp.push({ sfdcId: deptRole['worker']['sfdcId'], name: deptRole['worker']['Name'] })
                          } else if (deptRole['worker']['Functional_Role__c'] === 'Director'
                            && !director.some(({ sfdcId }) => sfdcId === deptRole['worker']['sfdcId'])) {
                            director.push({ sfdcId: deptRole['worker']['sfdcId'], name: deptRole['worker']['Name'] })
                          }
                        }
                      });
                    }
                  }
                  assignment['vpNames'] = vp.map(({ name }) => name).join(', ');
                  assignment['directorNames'] = director.map(({ name }) => name).join(', ');
                })
              }
              resolve(res);
            }, err => {
              console.error(err);
              resolve(res);
            });
          } else {
            resolve({});
          }
        }, err => {
          console.error(err);
          resolve({});
        }
      )
    });
  }

  fetchAllGeneralGroups() {
    return this._groupApi.find({
      where: {
        RecordTypeId: '0121a000000Vll7AAC',
        Community__c: 'MSP',
        Group_Code_Name__c: { neq: null }
      },
      fields: ['sfdcId', 'Department_Group_Code__c', 'Name', 'Department__c', 'Community__c'],
      include: [
        {
          relation: 'department',
          scope: {
            fields: ['sfdcId', 'Name']
          }
        }
      ]
    }).pipe(
      map((groups: Array<Group>) => {
        const generalGrp = [];
        groups.forEach(group => {
          generalGrp.push({
            sfdcId: group['sfdcId'],
            Name: group['Department_Group_Code__c']
              + ' (' + (group['department'] ? group['department']['Name'] : '')
              + ' - ' + group['Name'] + ') (' + group['Community__c'] + ')'
          })
        });
        return generalGrp;
      })
    )
  }

  modifiedTaskStatus(statusVal) {
    const statuses = new Set(statusVal.inq);
    if (statuses.has('AllOpen')) {
      statuses.delete('AllOpen');
      statuses.add('Assigned (Not Started) (2)').add('In Progress (4)').add('Delivered (Review in Progress) (5)')
        .add('Delivered (Ready for Review) (5)').add('Assigned (On Hold) (3)').add('Delivered (Review Failed) (6)')
        .add('New (1)').add('Need More Info (1)');
    }
    if (statuses.has('AllCompleted')) {
      statuses.delete('AllCompleted');
      statuses.add('Completed (Closed) (7)').add('Completed (Awaiting Close) (7)')
    }
    return Array.from(statuses);
  }

  modifiedProblemResolutionStatus(statusVal) {
    const statuses = new Set(statusVal.inq);
    if (statuses.has('AllOpen')) {
      statuses.delete('AllOpen');
      statuses.add('New (1)').add('Need More Info (1)').add('Assigned (Not Started) (2)').add('Assigned (On Hold) (2)').add('Assigned (In Progress) (2)')
        .add('In Progress (4)').add('Assigned (Challenged) (2)').add('Contained (Resolved, No Change) (4)')
        .add('Contained (Resolved, Change Required) (4)').add('Contained (Resolved, Change Purposed) (4)')
        .add('Change Approval (Ready for Review) (5)').add('Change Approval (Review in Progress) (5)')
        .add('Change Approval (Review Failed) (5)').add('Change Approval (Completed) (5)')
        .add('Implementation (Not Started) (6)').add('Implementation (In Progress) (6)');
    }
    if (statuses.has('AllCompleted')) {
      statuses.delete('AllCompleted');
      statuses.add('Completed (Awaiting Close) (7)').add('Completed (Closed) (7)');
    }
    return Array.from(statuses);
  }

  prepareJobOrderStatus(statusVal) {
    const statuses = new Set(statusVal.inq);
    if (statuses.has('Active')) {
      statuses.delete('Active');
      statuses.add('Open').add('In-Progress');
    }
    if (statuses.has('InActive')) {
      statuses.delete('InActive');
      statuses.add('Closed').add('Hold')
    }
    return Array.from(statuses);
  }

  isTesting() {
    return !!(localStorage.getItem('isTesting'));
  }

  getEditorConfig() {
    return {
      toolbar: {
        container: [
          ['bold', 'italic', 'underline', 'strike'],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'indent': '-1' }, { 'indent': '+1' }],
          [{ 'align': [] }],
        ],
      }
    }
  }

  prepareCustomerRequestedDateISO(requestFormReceipt) {
    let customerRequestedISO;
    if (Boolean(requestFormReceipt) && Boolean(requestFormReceipt.Customer_Appointment_Schedule_Start_Date__c)) {
      const requestedDate = moment(requestFormReceipt.Customer_Appointment_Schedule_Start_Date__c);
      customerRequestedISO = 'Y' + requestedDate.format('YYYY') + '-';
      customerRequestedISO += 'M' + requestedDate.format('MM') + '-';
      customerRequestedISO += 'W' + requestedDate.week() + '-';
      customerRequestedISO += 'D' + requestedDate.format('DD') + '-';
      customerRequestedISO += '(' + requestedDate.format('dddd') + ')';
    }
    return customerRequestedISO;
  }

  prepareCustomerRequestedDate(requestFormReceipt) {
    let requestedDate;
    if (Boolean(requestFormReceipt) && (Boolean(requestFormReceipt.Customer_Appointment_Schedule_Start_Date__c))) {
      requestedDate = this.getDate(requestFormReceipt.Customer_Appointment_Schedule_Start_Date__c);
      if (Boolean(requestFormReceipt.Customer_Appointment_Schedule_Start_Hour__c)) {
        requestedDate = requestedDate + ' ' + requestFormReceipt.Customer_Appointment_Schedule_Start_Hour__c;
        if (Boolean(requestFormReceipt.Customer_Appointment_Schedule_Start_Mi__c)) {
          requestedDate = requestedDate + ':' + requestFormReceipt.Customer_Appointment_Schedule_Start_Mi__c;
        } else {
          requestedDate = requestedDate + ':00';
        }
      } else {
        requestedDate = requestedDate + ' 00:00';
      }
    }
    return requestedDate;
  }

  getCurrentUserTeamsAndGroups() {
    return new Promise((resolve, reject) => {
      const appData: any = this.getAppData();
      this._workerApi.find({
        where: { 'Contact__c': appData && appData.user && appData.user.sfdcId },
        fields: ['sfdcId'],
        include: [
          {
            relation: 'departmentRoles',
            scope: {
              fields: ['sfdcId', 'PgMO_Departments__c', 'PgMO_Groups__c', 'RecordTypeId', 'Group_Community__c'],
              include: [
                {
                  relation: 'group',
                  scope: {
                    fields: ['sfdcId', 'Name', 'Department_Group_Code__c', 'Project__c'],
                  }
                },
                {
                    relation: 'department',
                    scope: {
                        fields: ['sfdcId', 'Department_Acronym__c', 'Name', 'Community__c'],
                        include: {
                          relation: 'group',
                          scope: {
                            fields: ['sfdcId', 'Department__c']
                          }
                        }
                    }
                }
              ]
            }
          }
        ]
      }).subscribe(res => {
        if (res && res.length) {
          const records = res[0] && res[0]['departmentRoles'];
          const teams = [];
          const departments = [];
          for(const departmentRole of records){
            console.log('In IF block', departmentRole['RecordTypeId']);
            if(
             (departmentRole['RecordTypeId'] === this.getRecordTypeIdForTeam() || departmentRole['RecordTypeId'] === this.getRecordtypeIdForGroupMember() && departmentRole['group'] && departmentRole['group']['sfdcId']) ) {
              console.log('In IF block', departmentRole);
              const group = departmentRole.group;
              const comm = departmentRole.Group_Community__c ? '(' + departmentRole.Group_Community__c + ')' : '';
              const name = `${group.Department_Group_Code__c} -(${(departmentRole.department && departmentRole.department.Name) || ''}  -  ${group.Name} ) ${comm}`;
              const teamObj = {
                sfdcId: group.sfdcId,
                name,
                count: 0
            }
            // if (!teams.some(({ sfdcId }) => sfdcId === group.sfdcId)){
              teams.push(teamObj);
            // }
            
              
            }else if(departmentRole['RecordTypeId'] === this.getRecordTypeIdForDepartment() && departmentRole['department'] && departmentRole['department']['sfdcId']){
              const dept = departmentRole.department;
              const comm = dept.Community__c ? `(${dept.Community__c})` : '';
              const name = `${dept.Department_Acronym__c} - (${dept.Name})${comm}`;
              if (!departments.some(({ sfdcId }) => sfdcId === dept.sfdcId)) {
                const deptObj = {
                    sfdcId: dept.sfdcId,
                    name,
                    count : 0,
                    groups: dept.group
                }
                departments.push(deptObj);
              }
            }
         }
         resolve({teams, departments });
        } else {
          resolve({teams : [], departments: [] });
        }
      }, err => {
        resolve({teams : [], departments: [] });
      })
    })
  }

}
