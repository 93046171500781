import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PreloaderService } from 'shared/services/preloader.service';
import { CaseApi } from 'shared/sdk';
import { ActivatedRoute } from '@angular/router';
import { GovernanceDataObject } from 'shared/models/static-list-data.service';

@Component({
  selector: 'app-sm-governance',
  templateUrl: './sm-governance.component.html',
  styleUrls: ['./sm-governance.component.css']
})
export class SmGovernanceComponent implements OnInit {

  caseData = { iccCaseSfdcId: '', pmsCaseSfdcId: '' };
  governanceData: any;

  public keepOriginalOrder = (a, b) => a.key;

  constructor(
    private _preloaderService: PreloaderService,
    private _caseApi: CaseApi,
    private _activatedRoute: ActivatedRoute,
  ) {
    this._activatedRoute.params.subscribe(params => {
      if (params && params.id) {
        this.caseData['pmsCaseSfdcId'] = params.id;
      }
    });
    // to read query strings from url
    this._activatedRoute.queryParams.subscribe(params => {
      if (params) {
        this.caseData['iccCaseSfdcId'] = params.iccCase;
      }
    });
  }

  ngOnInit() {
    this.governanceData = JSON.parse(JSON.stringify(GovernanceDataObject));
    this.getCaseDetails();
  }

  /**
   * Method to fetch the http response for the governance health checkups.
   * This method will call two seperated APIs.
   */
  getCaseDetails() {
    // if (this.caseData && this.caseData['iccCaseSfdcId']) {
    //   let filterObj = {
    //     where: { 'sfdcId': this.caseData.iccCaseSfdcId },
    //     fields: ['sfdcId', 'CaseNumber',
    //       'Health_Jobsite_Status__c', 'Health_Project_status__c', 'Health_OrderItem_Status__c', 'Health_Vendor_Status__c',
    //       'Health_Customer_Pricelist_Status__c', 'Health_Pricelist_Status__c', 'Health_Program_Pricelist__c',
    //       'Health_Asset_Sku_Comparison__c', 'Health_SDesk_Contact__c', 'Health_TEsc_Contact__c',
    //       'Health_SKU_Price_Status__c'],
    //   };

    //   this._preloaderService.showPreloader();
    //   this._caseApi.findOne(filterObj)
    //     .subscribe((data: any) => {
    //       this._preloaderService.hidePreloader();

    //       this.prepareHealthCheckCheckData(data, this.governanceData['iccCaseHealth']['data']);
    //     }, err => {
    //       console.log(err);
    //       this._preloaderService.hidePreloader();
    //     });
    // }

    // obj field are use for the pms case health check
    if (this.caseData && this.caseData['pmsCaseSfdcId']) {
      const filterObj = {
        where: { 'sfdcId': this.caseData.pmsCaseSfdcId },
        fields: ['sfdcId', 'CaseNumber', 'Service_Dispatch__c', 'Status', 'Job_Order__c', 'Jobsite__c', 'Project__c', 'Dispatch_Worker__c',
          'Health_Jobsite_Status__c', 'Health_Project_status__c', 'Health_OrderItem_Status__c', 'Health_Vendor_Status__c',
          'Health_Customer_Pricelist_Status__c', 'Health_Pricelist_Status__c', 'Health_Program_Pricelist__c',
          'Health_Asset_Sku_Comparison__c', 'Health_SDesk_Contact__c', 'Health_TEsc_Contact__c',
          'Health_SKU_Price_Status__c'],
        include: [
          {
            'relation': 'workOrder',
            'scope': {
              fields: ['Health_Appointment_status__c', 'Health_Customer_Price_Available__c', 'Health_Pricelist_Status__c',
                'Health_SOP_Profile_Status__c', 'Health_Vendor_Price_Available__c'
              ]
            }
          },
          {
            'relation': 'Job',
            'scope': {
              fields: ['id', 'sfdcId', 'Health_Vendor_Status__c', 'Health_Project_Status__c', 'Health_Case_Status__c', 'Health_Jobsite_Status__c',
                'Health_Worker_Status__c', 'Health_List_Price_Status__c', 'Health_FMS_Submission_Status__c', 'Health_Completion_Status__c'
              ]
            }
          }
        ]
      }

      this._preloaderService.showPreloader();
      this._caseApi.findOne(filterObj)
        .subscribe((data: any) => {
          this._preloaderService.hidePreloader();
          // as discussed, iccCaseHealth data is shown as same pmsCaseHealth
          this.prepareHealthCheckCheckData(data, this.governanceData['iccCaseHealth']['data']);
          this.prepareHealthCheckCheckData(data, this.governanceData['pmsCaseHealth']['data']);
          this.prepareHealthCheckCheckData(data.workOrder, this.governanceData['workOrderHealth']['data']);

          // check if there is iccCaseSfdcId then we will show jobHealth otherwise hide.
          if(this.caseData && this.caseData.iccCaseSfdcId) {
            this.prepareHealthCheckCheckData(data.Job, this.governanceData['jobHealth']['data']);
          } else {
            delete this.governanceData['jobHealth'];
          }

        }, err => {
          console.log(err);
          this._preloaderService.hidePreloader();
        });
    }
  }

  /**
   * General Method to stuctured the unstructured response.
   * The method is used to extract the values from the corresponding field' value which is image url.
   * @param source - The unstructured http response. 
   * @param destination - The converted values.
   */
  prepareHealthCheckCheckData(source, destination = []) {
    destination.forEach((res: any) => {
      let fieldValue = source[res.field];
      if (fieldValue && fieldValue && fieldValue.match('<img src=(.*)/>')) {
        const regex = /<img.*?src="(.*?)"/;
        const src = regex.exec(fieldValue) ? regex.exec(fieldValue)[1] : '';
        const imgFileName = src.split('/').pop();
        if (imgFileName === 'confirm32.png') {
          res.status = 'success';
        } else if (imgFileName === 'error32.png') {
          res.status = 'failed';
        } else {
          // uncheckedStatus32.png
          res.status = 'unchecked';
        }
      } else {
        res.status = 'unchecked';
      }
    });
  }
}
