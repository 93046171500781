/* tslint:disable */
import {
  Project2,
  Group,
  Worker,
  SprintMaster
} from '../index';

declare var Object: any;
export interface SprintInterface {
  "sfdcId"?: string;
  "CreatedBy"?: string;
  "CurrencyIsoCode"?: string;
  "LastModifiedBy"?: string;
  "Owner"?: string;
  "Name"?: string;
  "IRON_ExtID__c"?: string;
  "PgMO_Project__c"?: string;
  "Sprint_Master__c"?: string;
  "Sprint_Name__c"?: string;
  "Sprint_Owner_Group__c"?: string;
  "Sprint_Primary_Owner__c"?: string;
  "Sprint_Secondary_Owner__c"?: string;
  "Sprint_Status__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  project?: Project2;
  group?: Group;
  primaryWorker?: Worker;
  secondaryWorker?: Worker;
  sprintMaster?: SprintMaster;
}

export class Sprint implements SprintInterface {
  "sfdcId": string;
  "CreatedBy": string;
  "CurrencyIsoCode": string;
  "LastModifiedBy": string;
  "Owner": string;
  "Name": string;
  "IRON_ExtID__c": string;
  "PgMO_Project__c": string;
  "Sprint_Master__c": string;
  "Sprint_Name__c": string;
  "Sprint_Owner_Group__c": string;
  "Sprint_Primary_Owner__c": string;
  "Sprint_Secondary_Owner__c": string;
  "Sprint_Status__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  project: Project2;
  group: Group;
  primaryWorker: Worker;
  secondaryWorker: Worker;
  sprintMaster: SprintMaster;
  constructor(data?: SprintInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Sprint`.
   */
  public static getModelName() {
    return "Sprint";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Sprint for dynamic purposes.
  **/
  public static factory(data: SprintInterface): Sprint{
    return new Sprint(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Sprint',
      plural: 'Sprints',
      path: 'Sprints',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "CreatedBy": {
          name: 'CreatedBy',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "LastModifiedBy": {
          name: 'LastModifiedBy',
          type: 'string'
        },
        "Owner": {
          name: 'Owner',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "IRON_ExtID__c": {
          name: 'IRON_ExtID__c',
          type: 'string'
        },
        "PgMO_Project__c": {
          name: 'PgMO_Project__c',
          type: 'string'
        },
        "Sprint_Master__c": {
          name: 'Sprint_Master__c',
          type: 'string'
        },
        "Sprint_Name__c": {
          name: 'Sprint_Name__c',
          type: 'string'
        },
        "Sprint_Owner_Group__c": {
          name: 'Sprint_Owner_Group__c',
          type: 'string'
        },
        "Sprint_Primary_Owner__c": {
          name: 'Sprint_Primary_Owner__c',
          type: 'string'
        },
        "Sprint_Secondary_Owner__c": {
          name: 'Sprint_Secondary_Owner__c',
          type: 'string'
        },
        "Sprint_Status__c": {
          name: 'Sprint_Status__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        project: {
          name: 'project',
          type: 'Project2',
          model: 'Project2',
          relationType: 'belongsTo',
                  keyFrom: 'PgMO_Project__c',
          keyTo: 'sfdcId'
        },
        group: {
          name: 'group',
          type: 'Group',
          model: 'Group',
          relationType: 'belongsTo',
                  keyFrom: 'Sprint_Owner_Group__c',
          keyTo: 'sfdcId'
        },
        primaryWorker: {
          name: 'primaryWorker',
          type: 'Worker',
          model: 'Worker',
          relationType: 'belongsTo',
                  keyFrom: 'Sprint_Primary_Owner__c',
          keyTo: 'sfdcId'
        },
        secondaryWorker: {
          name: 'secondaryWorker',
          type: 'Worker',
          model: 'Worker',
          relationType: 'belongsTo',
                  keyFrom: 'Sprint_Secondary_Owner__c',
          keyTo: 'sfdcId'
        },
        sprintMaster: {
          name: 'sprintMaster',
          type: 'SprintMaster',
          model: 'SprintMaster',
          relationType: 'belongsTo',
                  keyFrom: 'Sprint_Master__c',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
