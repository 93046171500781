/* tslint:disable */

declare var Object: any;
export interface JobPlacementsInterface {
  "sfdcId"?: string;
  "ts2__Accounts_Payable__c"?: string;
  "CreatedById"?: string;
  "CurrencyIsoCode"?: string;
  "LastModifiedById"?: string;
  "Name"?: string;
  "RecordTypeId"?: string;
  "ts2extams__Action_Buttons__c"?: string;
  "Add_Hours_TM_Stand_Price_Part_c_Back__c"?: number;
  "Additional_Hours_TM_Standard_Price_Part__c"?: number;
  "Additional_Hours_TM_Stand_Price_Part_Ven__c"?: number;
  "Annual_Rate_Backfill_Vendor__c"?: number;
  "Annual_Rate_Partner__c"?: number;
  "Annual_Rate_Vendor__c"?: number;
  "Application_Has_Placement_Ref__c"?: string;
  "ts2__App_PDate__c"?: Date;
  "Approval_Date_Finance_Approval__c"?: Date;
  "Approval_Date_FTE_Helpdesk__c"?: Date;
  "Approval_Date_Hiring_Manager__c"?: Date;
  "Approval_Date_Hr__c"?: Date;
  "Approval_Date_MVPD_Approval__c"?: Date;
  "Approval_Date_Service_Delivery__c"?: Date;
  "Approval_Status_Finance_Approval__c"?: string;
  "Approval_Status_Finance_Approval_Calcula__c"?: string;
  "Approval_Status_FTE_Helpdesk__c"?: string;
  "Approval_Status_FTE_Helpdesk_Calculated__c"?: string;
  "Approval_Status_Hiring_Manager__c"?: string;
  "Approval_Status_Hiring_Manager_Calculate__c"?: string;
  "Approval_Status_Hr__c"?: string;
  "Approval_Status_Hr_Calculated__c"?: string;
  "Approval_Status_MVPD_Approval__c"?: string;
  "Approval_Status_MVPD_Approval_Calculated__c"?: string;
  "Approval_Status_Service_Delivery__c"?: string;
  "Approval_Status_Service_Delivery_Calcula__c"?: string;
  "Approval_Team__c"?: string;
  "Approver_Name_Finance_Approval__c"?: string;
  "Approver_Name_FTE_Helpdesk__c"?: string;
  "Approver_Name_Hiring_Manager__c"?: string;
  "Approver_Name_Hr__c"?: string;
  "Approver_Name_MVPD_Approval__c"?: string;
  "Approver_Name_Service_Delivery__c"?: string;
  "BGV_Drug_Available__c"?: string;
  "ts2__Bill_Rate__c"?: number;
  "Candidate_Type__c"?: string;
  "ts2__Client__c"?: string;
  "ts2__Client_for_Job__c"?: string;
  "ts2__Closed_Date__c"?: Date;
  "ts2__Code__c"?: string;
  "Comments_Finance_Approval__c"?: string;
  "Comments_FTE_Helpdesk__c"?: string;
  "Comments_Hiring_Manager__c"?: string;
  "Comments_Hr__c"?: string;
  "Comments_MVPD_Approval__c"?: string;
  "Comments_Service_Delivery__c"?: string;
  "ts2extams__Communication_Buttons__c"?: string;
  "ts2__Contact__c"?: string;
  "ts2__Contact_Email__c"?: string;
  "ts2__Hiring_Manager__c"?: string;
  "ts2__Contact_Phone__c"?: string;
  "ts2extams__Control_Buttons__c"?: string;
  "ts2__Conversion_Date__c"?: Date;
  "ts2__Conversion_Fee__c"?: number;
  "ts2__Conversion_Fee_Pct__c"?: number;
  "ts2__Conversion_Salary__c"?: number;
  "CreatedBy__c"?: string;
  "Create_Service_Contract__c"?: boolean;
  "Back_Fill_Vendor_Currency__c"?: string;
  "Customer_Price_Available__c"?: string;
  "Custom_Price_Available__c"?: string;
  "Custom_Price_Version_Created_Backfill_Ve__c"?: boolean;
  "Custom_Price_Version_Created_Partner__c"?: boolean;
  "Custom_Price_Version_Created_Vendor__c"?: boolean;
  "Custom_Pricing_Backfill_Vendor__c"?: boolean;
  "Custom_Pricing_Partner__c"?: boolean;
  "Custom_Pricing_Vendor__c"?: boolean;
  "PPE_8HR_Standard_Price_Partner__c"?: number;
  "PPE_8HR_Standard_Price_Vendor__c"?: number;
  "PPE_8HR_Standard_Price_Backfill_Vendor__c"?: number;
  "ts2__Days_to_Fill__c"?: string;
  "ts2__Discount__c"?: number;
  "ts2__Discount_Reason__c"?: string;
  "ts2__Employee__c"?: string;
  "Employment_Compliance_Available__c"?: string;
  "ts2__End_Date__c"?: Date;
  "ts2__Fall_Off_Date__c"?: Date;
  "ts2__Fee__c"?: string;
  "ts2__Fee2_Pct__c"?: number;
  "ts2__Fee_Pct__c"?: number;
  "FilledBy__c"?: string;
  "ts2__Filled_By__c"?: string;
  "FilledBy2__c"?: string;
  "ts2__Filled_By_2__c"?: string;
  "ts2__Filled_Pct__c"?: number;
  "ts2__Filled_Pct_2__c"?: number;
  "ts2__Flat_Fee_Internal__c"?: number;
  "FTE_Daily_Rate_Standard_Backfill_Vendo__c"?: number;
  "FTE_Daily_Rate_Standard_Partner__c"?: number;
  "FTE_Daily_Rate_Standard_Vendor__c"?: number;
  "FTE_Half_Day_Rate_Backfill_Vendor__c"?: number;
  "FTE_Half_Day_Rate_Partner__c"?: number;
  "FTE_Half_Day_Rate_Vendor__c"?: number;
  "FTE_Hourly_Rate_Holiday_Backfill_Vendo__c"?: number;
  "FTE_Hourly_Rate_Holiday_Partner__c"?: number;
  "FTE_Hourly_Rate_Holiday_Vendor__c"?: number;
  "FTE_Hourly_Rate_Overtime_Backfill_Vend__c"?: number;
  "FTE_Hourly_Rate_Overtime_Partner__c"?: number;
  "FTE_Hourly_Rate_Overtime_Vendor__c"?: number;
  "FTE_Hours_For_Service_Contract__c"?: number;
  "FTE_Monthly_Rate_Backfill_Vendor__c"?: number;
  "FTE_Monthly_Rate_Partner__c"?: number;
  "FTE_Monthly_Rate_Vendor__c"?: number;
  "Gross_Margin_Calculated__c"?: string;
  "Gross_Margin_Amount_Calculated_USD__c"?: string;
  "ts2__Guarantee__c"?: string;
  "Health_Employee_Available__c"?: string;
  "Health_End_Date_Available__c"?: string;
  "Health_Finance_Approval__c"?: string;
  "Health_FTE_Helpdesk__c"?: string;
  "Health_FTE_Hours_Available__c"?: string;
  "Health_Hiring_Manager__c"?: string;
  "Health_HR__c"?: string;
  "Health_Job_Available__c"?: string;
  "Health_Jobsite_Available__c"?: string;
  "Health_Job_Title_Available__c"?: string;
  "Health_MVPD__c"?: string;
  "Health_Partner_Available__c"?: string;
  "Health_Product_Available__c"?: string;
  "Health_Project_Available__c"?: string;
  "Health_Record_Type_Available__c"?: string;
  "Health_SC_Creation__c"?: string;
  "Health_Service_Delivery__c"?: string;
  "Health_Start_Date_Available__c"?: string;
  "Health_Talent_Type_Available__c"?: string;
  "Helpdesk_and_Dispatch_Fee_Backfill_Vendo__c"?: number;
  "Helpdesk_and_Dispatch_Fee_Partner__c"?: number;
  "Helpdesk_and_Dispatch_Fee_Vendor__c"?: number;
  "ts2__HiringManager__c"?: string;
  "Hiring_Manager__c"?: string;
  "PPE_1HR_Standard_Price_Partner__c"?: number;
  "PPE_1HR_Standard_Price_Vendor__c"?: number;
  "PPE_1HR_Standard_Price_Backfill_Vendor__c"?: number;
  "HR_Orientation_Conducted_By__c"?: string;
  "HR_Orientation_Date__c"?: Date;
  "HR_Orientations_Status__c"?: string;
  "ts2__Job__c"?: string;
  "ts2__Job_CDate__c"?: string;
  "Job_Has_Project__c"?: string;
  "Jobsite_Project__c"?: string;
  "ts2__Job_Status__c"?: string;
  "LastModifiedBy__c"?: string;
  "ts2__Legacy_PlacementID__c"?: string;
  "List_Price_Partner__c"?: number;
  "List_Price_Vendor__c"?: number;
  "List_Price_Backfill_Vendor__c"?: number;
  "ts2__Location__c"?: string;
  "Offered_Title__c"?: string;
  "Overall_Health_for_Service_Contract__c"?: string;
  "OverAll_Health_Status__c"?: string;
  "Overall_Placement_Available__c"?: string;
  "Partner_Currency__c"?: string;
  "Partner_Currency_Conversion_Rate__c"?: string;
  "ts2__Pay_Rate__c"?: number;
  "Positive_Margin_Available__c"?: string;
  "PPE_2HR_Standard_Price_Backfill_Vendor__c"?: number;
  "PPE_2HR_Standard_Price_Partner__c"?: number;
  "PPE_2HR_Standard_Price_Vendor__c"?: number;
  "PPE_3HR_Standard_Price_Backfill_Vendor__c"?: number;
  "PPE_3HR_Standard_Price_Partner__c"?: number;
  "PPE_3HR_Standard_Price_Vendor__c"?: number;
  "PPE_4HR_Standard_Price_Backfill_Vendor__c"?: number;
  "PPE_4HR_Standard_Price_Partner__c"?: number;
  "PPE_4HR_Standard_Price_Vendor__c"?: number;
  "Product__c"?: string;
  "ts2__Project__c"?: string;
  "Project_Orientation_Conducted_By__c"?: string;
  "Project_Orientation_Date__c"?: Date;
  "Project_Orientations_Status__c"?: string;
  "Reject_Reason__c"?: string;
  "ts2__Related_Application__c"?: string;
  "Request_Send_Date_Finance_Approval__c"?: Date;
  "Request_Send_Date_FTE_Helpdesk__c"?: Date;
  "Request_Send_Date_Hiring_Manager__c"?: Date;
  "Request_Send_Date_Hr__c"?: Date;
  "Request_Send_Date_MVPD_Approval__c"?: Date;
  "Request_Send_Date_Service_Delivery__c"?: Date;
  "ts2extams__Resume_Uploaded__c"?: string;
  "ts2__Salary__c"?: number;
  "SalesRep__c"?: string;
  "ts2__Sales_Rep__c"?: string;
  "SalesRep2__c"?: string;
  "ts2__Sales_Rep_2__c"?: string;
  "ts2__Sales_Rep_Pct__c"?: number;
  "ts2__Sales_Rep_Pct_2__c"?: number;
  "Service_Contract__c"?: string;
  "Service_Contract_Created__c"?: boolean;
  "Service_Contract_Record_Type__c"?: string;
  "ts2__Start_Date__c"?: Date;
  "ts2__Status__c"?: string;
  "ts2extams__Substatus__c"?: string;
  "TakenBy__c"?: string;
  "ts2__Taken_By__c"?: string;
  "TakenBy2__c"?: string;
  "ts2__Taken_By_2__c"?: string;
  "ts2__Taken_Pct__c"?: number;
  "ts2__Taken_Pct_2__c"?: number;
  "Temp_Currency_Rate__c"?: string;
  "ts2__Third_Party__c"?: boolean;
  "Total_Cost_Vendor_USD__c"?: string;
  "Total_Partner_Cost_USD__c"?: string;
  "ts2__Vendor__c"?: string;
  "Vendor__c"?: string;
  "Vendor_Cost_Available__c"?: string;
  "Vendor_Currency__c"?: string;
  "Vendor_Currency_Conversion_Rate__c"?: string;
  "ts2__Work_Class__c"?: string;
  "ts2__Work_Comp__c"?: string;
  "Work_Description__c"?: string;
  "Worker__c"?: string;
  "Worker_Approval_Date__c"?: Date;
  "Worker_Approval_Status__c"?: string;
  "Worker_Approval_Status_Calculated__c"?: string;
  "Worker_Billing_Type__c"?: string;
  "Worker_Record_Approver_Name__c"?: string;
  "Worker_Type__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class JobPlacements implements JobPlacementsInterface {
  "sfdcId": string;
  "ts2__Accounts_Payable__c": string;
  "CreatedById": string;
  "CurrencyIsoCode": string;
  "LastModifiedById": string;
  "Name": string;
  "RecordTypeId": string;
  "ts2extams__Action_Buttons__c": string;
  "Add_Hours_TM_Stand_Price_Part_c_Back__c": number;
  "Additional_Hours_TM_Standard_Price_Part__c": number;
  "Additional_Hours_TM_Stand_Price_Part_Ven__c": number;
  "Annual_Rate_Backfill_Vendor__c": number;
  "Annual_Rate_Partner__c": number;
  "Annual_Rate_Vendor__c": number;
  "Application_Has_Placement_Ref__c": string;
  "ts2__App_PDate__c": Date;
  "Approval_Date_Finance_Approval__c": Date;
  "Approval_Date_FTE_Helpdesk__c": Date;
  "Approval_Date_Hiring_Manager__c": Date;
  "Approval_Date_Hr__c": Date;
  "Approval_Date_MVPD_Approval__c": Date;
  "Approval_Date_Service_Delivery__c": Date;
  "Approval_Status_Finance_Approval__c": string;
  "Approval_Status_Finance_Approval_Calcula__c": string;
  "Approval_Status_FTE_Helpdesk__c": string;
  "Approval_Status_FTE_Helpdesk_Calculated__c": string;
  "Approval_Status_Hiring_Manager__c": string;
  "Approval_Status_Hiring_Manager_Calculate__c": string;
  "Approval_Status_Hr__c": string;
  "Approval_Status_Hr_Calculated__c": string;
  "Approval_Status_MVPD_Approval__c": string;
  "Approval_Status_MVPD_Approval_Calculated__c": string;
  "Approval_Status_Service_Delivery__c": string;
  "Approval_Status_Service_Delivery_Calcula__c": string;
  "Approval_Team__c": string;
  "Approver_Name_Finance_Approval__c": string;
  "Approver_Name_FTE_Helpdesk__c": string;
  "Approver_Name_Hiring_Manager__c": string;
  "Approver_Name_Hr__c": string;
  "Approver_Name_MVPD_Approval__c": string;
  "Approver_Name_Service_Delivery__c": string;
  "BGV_Drug_Available__c": string;
  "ts2__Bill_Rate__c": number;
  "Candidate_Type__c": string;
  "ts2__Client__c": string;
  "ts2__Client_for_Job__c": string;
  "ts2__Closed_Date__c": Date;
  "ts2__Code__c": string;
  "Comments_Finance_Approval__c": string;
  "Comments_FTE_Helpdesk__c": string;
  "Comments_Hiring_Manager__c": string;
  "Comments_Hr__c": string;
  "Comments_MVPD_Approval__c": string;
  "Comments_Service_Delivery__c": string;
  "ts2extams__Communication_Buttons__c": string;
  "ts2__Contact__c": string;
  "ts2__Contact_Email__c": string;
  "ts2__Hiring_Manager__c": string;
  "ts2__Contact_Phone__c": string;
  "ts2extams__Control_Buttons__c": string;
  "ts2__Conversion_Date__c": Date;
  "ts2__Conversion_Fee__c": number;
  "ts2__Conversion_Fee_Pct__c": number;
  "ts2__Conversion_Salary__c": number;
  "CreatedBy__c": string;
  "Create_Service_Contract__c": boolean;
  "Back_Fill_Vendor_Currency__c": string;
  "Customer_Price_Available__c": string;
  "Custom_Price_Available__c": string;
  "Custom_Price_Version_Created_Backfill_Ve__c": boolean;
  "Custom_Price_Version_Created_Partner__c": boolean;
  "Custom_Price_Version_Created_Vendor__c": boolean;
  "Custom_Pricing_Backfill_Vendor__c": boolean;
  "Custom_Pricing_Partner__c": boolean;
  "Custom_Pricing_Vendor__c": boolean;
  "PPE_8HR_Standard_Price_Partner__c": number;
  "PPE_8HR_Standard_Price_Vendor__c": number;
  "PPE_8HR_Standard_Price_Backfill_Vendor__c": number;
  "ts2__Days_to_Fill__c": string;
  "ts2__Discount__c": number;
  "ts2__Discount_Reason__c": string;
  "ts2__Employee__c": string;
  "Employment_Compliance_Available__c": string;
  "ts2__End_Date__c": Date;
  "ts2__Fall_Off_Date__c": Date;
  "ts2__Fee__c": string;
  "ts2__Fee2_Pct__c": number;
  "ts2__Fee_Pct__c": number;
  "FilledBy__c": string;
  "ts2__Filled_By__c": string;
  "FilledBy2__c": string;
  "ts2__Filled_By_2__c": string;
  "ts2__Filled_Pct__c": number;
  "ts2__Filled_Pct_2__c": number;
  "ts2__Flat_Fee_Internal__c": number;
  "FTE_Daily_Rate_Standard_Backfill_Vendo__c": number;
  "FTE_Daily_Rate_Standard_Partner__c": number;
  "FTE_Daily_Rate_Standard_Vendor__c": number;
  "FTE_Half_Day_Rate_Backfill_Vendor__c": number;
  "FTE_Half_Day_Rate_Partner__c": number;
  "FTE_Half_Day_Rate_Vendor__c": number;
  "FTE_Hourly_Rate_Holiday_Backfill_Vendo__c": number;
  "FTE_Hourly_Rate_Holiday_Partner__c": number;
  "FTE_Hourly_Rate_Holiday_Vendor__c": number;
  "FTE_Hourly_Rate_Overtime_Backfill_Vend__c": number;
  "FTE_Hourly_Rate_Overtime_Partner__c": number;
  "FTE_Hourly_Rate_Overtime_Vendor__c": number;
  "FTE_Hours_For_Service_Contract__c": number;
  "FTE_Monthly_Rate_Backfill_Vendor__c": number;
  "FTE_Monthly_Rate_Partner__c": number;
  "FTE_Monthly_Rate_Vendor__c": number;
  "Gross_Margin_Calculated__c": string;
  "Gross_Margin_Amount_Calculated_USD__c": string;
  "ts2__Guarantee__c": string;
  "Health_Employee_Available__c": string;
  "Health_End_Date_Available__c": string;
  "Health_Finance_Approval__c": string;
  "Health_FTE_Helpdesk__c": string;
  "Health_FTE_Hours_Available__c": string;
  "Health_Hiring_Manager__c": string;
  "Health_HR__c": string;
  "Health_Job_Available__c": string;
  "Health_Jobsite_Available__c": string;
  "Health_Job_Title_Available__c": string;
  "Health_MVPD__c": string;
  "Health_Partner_Available__c": string;
  "Health_Product_Available__c": string;
  "Health_Project_Available__c": string;
  "Health_Record_Type_Available__c": string;
  "Health_SC_Creation__c": string;
  "Health_Service_Delivery__c": string;
  "Health_Start_Date_Available__c": string;
  "Health_Talent_Type_Available__c": string;
  "Helpdesk_and_Dispatch_Fee_Backfill_Vendo__c": number;
  "Helpdesk_and_Dispatch_Fee_Partner__c": number;
  "Helpdesk_and_Dispatch_Fee_Vendor__c": number;
  "ts2__HiringManager__c": string;
  "Hiring_Manager__c": string;
  "PPE_1HR_Standard_Price_Partner__c": number;
  "PPE_1HR_Standard_Price_Vendor__c": number;
  "PPE_1HR_Standard_Price_Backfill_Vendor__c": number;
  "HR_Orientation_Conducted_By__c": string;
  "HR_Orientation_Date__c": Date;
  "HR_Orientations_Status__c": string;
  "ts2__Job__c": string;
  "ts2__Job_CDate__c": string;
  "Job_Has_Project__c": string;
  "Jobsite_Project__c": string;
  "ts2__Job_Status__c": string;
  "LastModifiedBy__c": string;
  "ts2__Legacy_PlacementID__c": string;
  "List_Price_Partner__c": number;
  "List_Price_Vendor__c": number;
  "List_Price_Backfill_Vendor__c": number;
  "ts2__Location__c": string;
  "Offered_Title__c": string;
  "Overall_Health_for_Service_Contract__c": string;
  "OverAll_Health_Status__c": string;
  "Overall_Placement_Available__c": string;
  "Partner_Currency__c": string;
  "Partner_Currency_Conversion_Rate__c": string;
  "ts2__Pay_Rate__c": number;
  "Positive_Margin_Available__c": string;
  "PPE_2HR_Standard_Price_Backfill_Vendor__c": number;
  "PPE_2HR_Standard_Price_Partner__c": number;
  "PPE_2HR_Standard_Price_Vendor__c": number;
  "PPE_3HR_Standard_Price_Backfill_Vendor__c": number;
  "PPE_3HR_Standard_Price_Partner__c": number;
  "PPE_3HR_Standard_Price_Vendor__c": number;
  "PPE_4HR_Standard_Price_Backfill_Vendor__c": number;
  "PPE_4HR_Standard_Price_Partner__c": number;
  "PPE_4HR_Standard_Price_Vendor__c": number;
  "Product__c": string;
  "ts2__Project__c": string;
  "Project_Orientation_Conducted_By__c": string;
  "Project_Orientation_Date__c": Date;
  "Project_Orientations_Status__c": string;
  "Reject_Reason__c": string;
  "ts2__Related_Application__c": string;
  "Request_Send_Date_Finance_Approval__c": Date;
  "Request_Send_Date_FTE_Helpdesk__c": Date;
  "Request_Send_Date_Hiring_Manager__c": Date;
  "Request_Send_Date_Hr__c": Date;
  "Request_Send_Date_MVPD_Approval__c": Date;
  "Request_Send_Date_Service_Delivery__c": Date;
  "ts2extams__Resume_Uploaded__c": string;
  "ts2__Salary__c": number;
  "SalesRep__c": string;
  "ts2__Sales_Rep__c": string;
  "SalesRep2__c": string;
  "ts2__Sales_Rep_2__c": string;
  "ts2__Sales_Rep_Pct__c": number;
  "ts2__Sales_Rep_Pct_2__c": number;
  "Service_Contract__c": string;
  "Service_Contract_Created__c": boolean;
  "Service_Contract_Record_Type__c": string;
  "ts2__Start_Date__c": Date;
  "ts2__Status__c": string;
  "ts2extams__Substatus__c": string;
  "TakenBy__c": string;
  "ts2__Taken_By__c": string;
  "TakenBy2__c": string;
  "ts2__Taken_By_2__c": string;
  "ts2__Taken_Pct__c": number;
  "ts2__Taken_Pct_2__c": number;
  "Temp_Currency_Rate__c": string;
  "ts2__Third_Party__c": boolean;
  "Total_Cost_Vendor_USD__c": string;
  "Total_Partner_Cost_USD__c": string;
  "ts2__Vendor__c": string;
  "Vendor__c": string;
  "Vendor_Cost_Available__c": string;
  "Vendor_Currency__c": string;
  "Vendor_Currency_Conversion_Rate__c": string;
  "ts2__Work_Class__c": string;
  "ts2__Work_Comp__c": string;
  "Work_Description__c": string;
  "Worker__c": string;
  "Worker_Approval_Date__c": Date;
  "Worker_Approval_Status__c": string;
  "Worker_Approval_Status_Calculated__c": string;
  "Worker_Billing_Type__c": string;
  "Worker_Record_Approver_Name__c": string;
  "Worker_Type__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: JobPlacementsInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `JobPlacements`.
   */
  public static getModelName() {
    return "JobPlacements";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of JobPlacements for dynamic purposes.
  **/
  public static factory(data: JobPlacementsInterface): JobPlacements{
    return new JobPlacements(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'JobPlacements',
      plural: 'JobPlacements',
      path: 'JobPlacements',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "ts2__Accounts_Payable__c": {
          name: 'ts2__Accounts_Payable__c',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "RecordTypeId": {
          name: 'RecordTypeId',
          type: 'string'
        },
        "ts2extams__Action_Buttons__c": {
          name: 'ts2extams__Action_Buttons__c',
          type: 'string'
        },
        "Add_Hours_TM_Stand_Price_Part_c_Back__c": {
          name: 'Add_Hours_TM_Stand_Price_Part_c_Back__c',
          type: 'number'
        },
        "Additional_Hours_TM_Standard_Price_Part__c": {
          name: 'Additional_Hours_TM_Standard_Price_Part__c',
          type: 'number'
        },
        "Additional_Hours_TM_Stand_Price_Part_Ven__c": {
          name: 'Additional_Hours_TM_Stand_Price_Part_Ven__c',
          type: 'number'
        },
        "Annual_Rate_Backfill_Vendor__c": {
          name: 'Annual_Rate_Backfill_Vendor__c',
          type: 'number'
        },
        "Annual_Rate_Partner__c": {
          name: 'Annual_Rate_Partner__c',
          type: 'number'
        },
        "Annual_Rate_Vendor__c": {
          name: 'Annual_Rate_Vendor__c',
          type: 'number'
        },
        "Application_Has_Placement_Ref__c": {
          name: 'Application_Has_Placement_Ref__c',
          type: 'string'
        },
        "ts2__App_PDate__c": {
          name: 'ts2__App_PDate__c',
          type: 'Date'
        },
        "Approval_Date_Finance_Approval__c": {
          name: 'Approval_Date_Finance_Approval__c',
          type: 'Date'
        },
        "Approval_Date_FTE_Helpdesk__c": {
          name: 'Approval_Date_FTE_Helpdesk__c',
          type: 'Date'
        },
        "Approval_Date_Hiring_Manager__c": {
          name: 'Approval_Date_Hiring_Manager__c',
          type: 'Date'
        },
        "Approval_Date_Hr__c": {
          name: 'Approval_Date_Hr__c',
          type: 'Date'
        },
        "Approval_Date_MVPD_Approval__c": {
          name: 'Approval_Date_MVPD_Approval__c',
          type: 'Date'
        },
        "Approval_Date_Service_Delivery__c": {
          name: 'Approval_Date_Service_Delivery__c',
          type: 'Date'
        },
        "Approval_Status_Finance_Approval__c": {
          name: 'Approval_Status_Finance_Approval__c',
          type: 'string'
        },
        "Approval_Status_Finance_Approval_Calcula__c": {
          name: 'Approval_Status_Finance_Approval_Calcula__c',
          type: 'string'
        },
        "Approval_Status_FTE_Helpdesk__c": {
          name: 'Approval_Status_FTE_Helpdesk__c',
          type: 'string'
        },
        "Approval_Status_FTE_Helpdesk_Calculated__c": {
          name: 'Approval_Status_FTE_Helpdesk_Calculated__c',
          type: 'string'
        },
        "Approval_Status_Hiring_Manager__c": {
          name: 'Approval_Status_Hiring_Manager__c',
          type: 'string'
        },
        "Approval_Status_Hiring_Manager_Calculate__c": {
          name: 'Approval_Status_Hiring_Manager_Calculate__c',
          type: 'string'
        },
        "Approval_Status_Hr__c": {
          name: 'Approval_Status_Hr__c',
          type: 'string'
        },
        "Approval_Status_Hr_Calculated__c": {
          name: 'Approval_Status_Hr_Calculated__c',
          type: 'string'
        },
        "Approval_Status_MVPD_Approval__c": {
          name: 'Approval_Status_MVPD_Approval__c',
          type: 'string'
        },
        "Approval_Status_MVPD_Approval_Calculated__c": {
          name: 'Approval_Status_MVPD_Approval_Calculated__c',
          type: 'string'
        },
        "Approval_Status_Service_Delivery__c": {
          name: 'Approval_Status_Service_Delivery__c',
          type: 'string'
        },
        "Approval_Status_Service_Delivery_Calcula__c": {
          name: 'Approval_Status_Service_Delivery_Calcula__c',
          type: 'string'
        },
        "Approval_Team__c": {
          name: 'Approval_Team__c',
          type: 'string'
        },
        "Approver_Name_Finance_Approval__c": {
          name: 'Approver_Name_Finance_Approval__c',
          type: 'string'
        },
        "Approver_Name_FTE_Helpdesk__c": {
          name: 'Approver_Name_FTE_Helpdesk__c',
          type: 'string'
        },
        "Approver_Name_Hiring_Manager__c": {
          name: 'Approver_Name_Hiring_Manager__c',
          type: 'string'
        },
        "Approver_Name_Hr__c": {
          name: 'Approver_Name_Hr__c',
          type: 'string'
        },
        "Approver_Name_MVPD_Approval__c": {
          name: 'Approver_Name_MVPD_Approval__c',
          type: 'string'
        },
        "Approver_Name_Service_Delivery__c": {
          name: 'Approver_Name_Service_Delivery__c',
          type: 'string'
        },
        "BGV_Drug_Available__c": {
          name: 'BGV_Drug_Available__c',
          type: 'string'
        },
        "ts2__Bill_Rate__c": {
          name: 'ts2__Bill_Rate__c',
          type: 'number'
        },
        "Candidate_Type__c": {
          name: 'Candidate_Type__c',
          type: 'string'
        },
        "ts2__Client__c": {
          name: 'ts2__Client__c',
          type: 'string'
        },
        "ts2__Client_for_Job__c": {
          name: 'ts2__Client_for_Job__c',
          type: 'string'
        },
        "ts2__Closed_Date__c": {
          name: 'ts2__Closed_Date__c',
          type: 'Date'
        },
        "ts2__Code__c": {
          name: 'ts2__Code__c',
          type: 'string'
        },
        "Comments_Finance_Approval__c": {
          name: 'Comments_Finance_Approval__c',
          type: 'string'
        },
        "Comments_FTE_Helpdesk__c": {
          name: 'Comments_FTE_Helpdesk__c',
          type: 'string'
        },
        "Comments_Hiring_Manager__c": {
          name: 'Comments_Hiring_Manager__c',
          type: 'string'
        },
        "Comments_Hr__c": {
          name: 'Comments_Hr__c',
          type: 'string'
        },
        "Comments_MVPD_Approval__c": {
          name: 'Comments_MVPD_Approval__c',
          type: 'string'
        },
        "Comments_Service_Delivery__c": {
          name: 'Comments_Service_Delivery__c',
          type: 'string'
        },
        "ts2extams__Communication_Buttons__c": {
          name: 'ts2extams__Communication_Buttons__c',
          type: 'string'
        },
        "ts2__Contact__c": {
          name: 'ts2__Contact__c',
          type: 'string'
        },
        "ts2__Contact_Email__c": {
          name: 'ts2__Contact_Email__c',
          type: 'string'
        },
        "ts2__Hiring_Manager__c": {
          name: 'ts2__Hiring_Manager__c',
          type: 'string'
        },
        "ts2__Contact_Phone__c": {
          name: 'ts2__Contact_Phone__c',
          type: 'string'
        },
        "ts2extams__Control_Buttons__c": {
          name: 'ts2extams__Control_Buttons__c',
          type: 'string'
        },
        "ts2__Conversion_Date__c": {
          name: 'ts2__Conversion_Date__c',
          type: 'Date'
        },
        "ts2__Conversion_Fee__c": {
          name: 'ts2__Conversion_Fee__c',
          type: 'number'
        },
        "ts2__Conversion_Fee_Pct__c": {
          name: 'ts2__Conversion_Fee_Pct__c',
          type: 'number'
        },
        "ts2__Conversion_Salary__c": {
          name: 'ts2__Conversion_Salary__c',
          type: 'number'
        },
        "CreatedBy__c": {
          name: 'CreatedBy__c',
          type: 'string'
        },
        "Create_Service_Contract__c": {
          name: 'Create_Service_Contract__c',
          type: 'boolean'
        },
        "Back_Fill_Vendor_Currency__c": {
          name: 'Back_Fill_Vendor_Currency__c',
          type: 'string'
        },
        "Customer_Price_Available__c": {
          name: 'Customer_Price_Available__c',
          type: 'string'
        },
        "Custom_Price_Available__c": {
          name: 'Custom_Price_Available__c',
          type: 'string'
        },
        "Custom_Price_Version_Created_Backfill_Ve__c": {
          name: 'Custom_Price_Version_Created_Backfill_Ve__c',
          type: 'boolean'
        },
        "Custom_Price_Version_Created_Partner__c": {
          name: 'Custom_Price_Version_Created_Partner__c',
          type: 'boolean'
        },
        "Custom_Price_Version_Created_Vendor__c": {
          name: 'Custom_Price_Version_Created_Vendor__c',
          type: 'boolean'
        },
        "Custom_Pricing_Backfill_Vendor__c": {
          name: 'Custom_Pricing_Backfill_Vendor__c',
          type: 'boolean'
        },
        "Custom_Pricing_Partner__c": {
          name: 'Custom_Pricing_Partner__c',
          type: 'boolean'
        },
        "Custom_Pricing_Vendor__c": {
          name: 'Custom_Pricing_Vendor__c',
          type: 'boolean'
        },
        "PPE_8HR_Standard_Price_Partner__c": {
          name: 'PPE_8HR_Standard_Price_Partner__c',
          type: 'number'
        },
        "PPE_8HR_Standard_Price_Vendor__c": {
          name: 'PPE_8HR_Standard_Price_Vendor__c',
          type: 'number'
        },
        "PPE_8HR_Standard_Price_Backfill_Vendor__c": {
          name: 'PPE_8HR_Standard_Price_Backfill_Vendor__c',
          type: 'number'
        },
        "ts2__Days_to_Fill__c": {
          name: 'ts2__Days_to_Fill__c',
          type: 'string'
        },
        "ts2__Discount__c": {
          name: 'ts2__Discount__c',
          type: 'number'
        },
        "ts2__Discount_Reason__c": {
          name: 'ts2__Discount_Reason__c',
          type: 'string'
        },
        "ts2__Employee__c": {
          name: 'ts2__Employee__c',
          type: 'string'
        },
        "Employment_Compliance_Available__c": {
          name: 'Employment_Compliance_Available__c',
          type: 'string'
        },
        "ts2__End_Date__c": {
          name: 'ts2__End_Date__c',
          type: 'Date'
        },
        "ts2__Fall_Off_Date__c": {
          name: 'ts2__Fall_Off_Date__c',
          type: 'Date'
        },
        "ts2__Fee__c": {
          name: 'ts2__Fee__c',
          type: 'string'
        },
        "ts2__Fee2_Pct__c": {
          name: 'ts2__Fee2_Pct__c',
          type: 'number'
        },
        "ts2__Fee_Pct__c": {
          name: 'ts2__Fee_Pct__c',
          type: 'number'
        },
        "FilledBy__c": {
          name: 'FilledBy__c',
          type: 'string'
        },
        "ts2__Filled_By__c": {
          name: 'ts2__Filled_By__c',
          type: 'string'
        },
        "FilledBy2__c": {
          name: 'FilledBy2__c',
          type: 'string'
        },
        "ts2__Filled_By_2__c": {
          name: 'ts2__Filled_By_2__c',
          type: 'string'
        },
        "ts2__Filled_Pct__c": {
          name: 'ts2__Filled_Pct__c',
          type: 'number'
        },
        "ts2__Filled_Pct_2__c": {
          name: 'ts2__Filled_Pct_2__c',
          type: 'number'
        },
        "ts2__Flat_Fee_Internal__c": {
          name: 'ts2__Flat_Fee_Internal__c',
          type: 'number'
        },
        "FTE_Daily_Rate_Standard_Backfill_Vendo__c": {
          name: 'FTE_Daily_Rate_Standard_Backfill_Vendo__c',
          type: 'number'
        },
        "FTE_Daily_Rate_Standard_Partner__c": {
          name: 'FTE_Daily_Rate_Standard_Partner__c',
          type: 'number'
        },
        "FTE_Daily_Rate_Standard_Vendor__c": {
          name: 'FTE_Daily_Rate_Standard_Vendor__c',
          type: 'number'
        },
        "FTE_Half_Day_Rate_Backfill_Vendor__c": {
          name: 'FTE_Half_Day_Rate_Backfill_Vendor__c',
          type: 'number'
        },
        "FTE_Half_Day_Rate_Partner__c": {
          name: 'FTE_Half_Day_Rate_Partner__c',
          type: 'number'
        },
        "FTE_Half_Day_Rate_Vendor__c": {
          name: 'FTE_Half_Day_Rate_Vendor__c',
          type: 'number'
        },
        "FTE_Hourly_Rate_Holiday_Backfill_Vendo__c": {
          name: 'FTE_Hourly_Rate_Holiday_Backfill_Vendo__c',
          type: 'number'
        },
        "FTE_Hourly_Rate_Holiday_Partner__c": {
          name: 'FTE_Hourly_Rate_Holiday_Partner__c',
          type: 'number'
        },
        "FTE_Hourly_Rate_Holiday_Vendor__c": {
          name: 'FTE_Hourly_Rate_Holiday_Vendor__c',
          type: 'number'
        },
        "FTE_Hourly_Rate_Overtime_Backfill_Vend__c": {
          name: 'FTE_Hourly_Rate_Overtime_Backfill_Vend__c',
          type: 'number'
        },
        "FTE_Hourly_Rate_Overtime_Partner__c": {
          name: 'FTE_Hourly_Rate_Overtime_Partner__c',
          type: 'number'
        },
        "FTE_Hourly_Rate_Overtime_Vendor__c": {
          name: 'FTE_Hourly_Rate_Overtime_Vendor__c',
          type: 'number'
        },
        "FTE_Hours_For_Service_Contract__c": {
          name: 'FTE_Hours_For_Service_Contract__c',
          type: 'number'
        },
        "FTE_Monthly_Rate_Backfill_Vendor__c": {
          name: 'FTE_Monthly_Rate_Backfill_Vendor__c',
          type: 'number'
        },
        "FTE_Monthly_Rate_Partner__c": {
          name: 'FTE_Monthly_Rate_Partner__c',
          type: 'number'
        },
        "FTE_Monthly_Rate_Vendor__c": {
          name: 'FTE_Monthly_Rate_Vendor__c',
          type: 'number'
        },
        "Gross_Margin_Calculated__c": {
          name: 'Gross_Margin_Calculated__c',
          type: 'string'
        },
        "Gross_Margin_Amount_Calculated_USD__c": {
          name: 'Gross_Margin_Amount_Calculated_USD__c',
          type: 'string'
        },
        "ts2__Guarantee__c": {
          name: 'ts2__Guarantee__c',
          type: 'string'
        },
        "Health_Employee_Available__c": {
          name: 'Health_Employee_Available__c',
          type: 'string'
        },
        "Health_End_Date_Available__c": {
          name: 'Health_End_Date_Available__c',
          type: 'string'
        },
        "Health_Finance_Approval__c": {
          name: 'Health_Finance_Approval__c',
          type: 'string'
        },
        "Health_FTE_Helpdesk__c": {
          name: 'Health_FTE_Helpdesk__c',
          type: 'string'
        },
        "Health_FTE_Hours_Available__c": {
          name: 'Health_FTE_Hours_Available__c',
          type: 'string'
        },
        "Health_Hiring_Manager__c": {
          name: 'Health_Hiring_Manager__c',
          type: 'string'
        },
        "Health_HR__c": {
          name: 'Health_HR__c',
          type: 'string'
        },
        "Health_Job_Available__c": {
          name: 'Health_Job_Available__c',
          type: 'string'
        },
        "Health_Jobsite_Available__c": {
          name: 'Health_Jobsite_Available__c',
          type: 'string'
        },
        "Health_Job_Title_Available__c": {
          name: 'Health_Job_Title_Available__c',
          type: 'string'
        },
        "Health_MVPD__c": {
          name: 'Health_MVPD__c',
          type: 'string'
        },
        "Health_Partner_Available__c": {
          name: 'Health_Partner_Available__c',
          type: 'string'
        },
        "Health_Product_Available__c": {
          name: 'Health_Product_Available__c',
          type: 'string'
        },
        "Health_Project_Available__c": {
          name: 'Health_Project_Available__c',
          type: 'string'
        },
        "Health_Record_Type_Available__c": {
          name: 'Health_Record_Type_Available__c',
          type: 'string'
        },
        "Health_SC_Creation__c": {
          name: 'Health_SC_Creation__c',
          type: 'string'
        },
        "Health_Service_Delivery__c": {
          name: 'Health_Service_Delivery__c',
          type: 'string'
        },
        "Health_Start_Date_Available__c": {
          name: 'Health_Start_Date_Available__c',
          type: 'string'
        },
        "Health_Talent_Type_Available__c": {
          name: 'Health_Talent_Type_Available__c',
          type: 'string'
        },
        "Helpdesk_and_Dispatch_Fee_Backfill_Vendo__c": {
          name: 'Helpdesk_and_Dispatch_Fee_Backfill_Vendo__c',
          type: 'number'
        },
        "Helpdesk_and_Dispatch_Fee_Partner__c": {
          name: 'Helpdesk_and_Dispatch_Fee_Partner__c',
          type: 'number'
        },
        "Helpdesk_and_Dispatch_Fee_Vendor__c": {
          name: 'Helpdesk_and_Dispatch_Fee_Vendor__c',
          type: 'number'
        },
        "ts2__HiringManager__c": {
          name: 'ts2__HiringManager__c',
          type: 'string'
        },
        "Hiring_Manager__c": {
          name: 'Hiring_Manager__c',
          type: 'string'
        },
        "PPE_1HR_Standard_Price_Partner__c": {
          name: 'PPE_1HR_Standard_Price_Partner__c',
          type: 'number'
        },
        "PPE_1HR_Standard_Price_Vendor__c": {
          name: 'PPE_1HR_Standard_Price_Vendor__c',
          type: 'number'
        },
        "PPE_1HR_Standard_Price_Backfill_Vendor__c": {
          name: 'PPE_1HR_Standard_Price_Backfill_Vendor__c',
          type: 'number'
        },
        "HR_Orientation_Conducted_By__c": {
          name: 'HR_Orientation_Conducted_By__c',
          type: 'string'
        },
        "HR_Orientation_Date__c": {
          name: 'HR_Orientation_Date__c',
          type: 'Date'
        },
        "HR_Orientations_Status__c": {
          name: 'HR_Orientations_Status__c',
          type: 'string'
        },
        "ts2__Job__c": {
          name: 'ts2__Job__c',
          type: 'string'
        },
        "ts2__Job_CDate__c": {
          name: 'ts2__Job_CDate__c',
          type: 'string'
        },
        "Job_Has_Project__c": {
          name: 'Job_Has_Project__c',
          type: 'string'
        },
        "Jobsite_Project__c": {
          name: 'Jobsite_Project__c',
          type: 'string'
        },
        "ts2__Job_Status__c": {
          name: 'ts2__Job_Status__c',
          type: 'string'
        },
        "LastModifiedBy__c": {
          name: 'LastModifiedBy__c',
          type: 'string'
        },
        "ts2__Legacy_PlacementID__c": {
          name: 'ts2__Legacy_PlacementID__c',
          type: 'string'
        },
        "List_Price_Partner__c": {
          name: 'List_Price_Partner__c',
          type: 'number'
        },
        "List_Price_Vendor__c": {
          name: 'List_Price_Vendor__c',
          type: 'number'
        },
        "List_Price_Backfill_Vendor__c": {
          name: 'List_Price_Backfill_Vendor__c',
          type: 'number'
        },
        "ts2__Location__c": {
          name: 'ts2__Location__c',
          type: 'string'
        },
        "Offered_Title__c": {
          name: 'Offered_Title__c',
          type: 'string'
        },
        "Overall_Health_for_Service_Contract__c": {
          name: 'Overall_Health_for_Service_Contract__c',
          type: 'string'
        },
        "OverAll_Health_Status__c": {
          name: 'OverAll_Health_Status__c',
          type: 'string'
        },
        "Overall_Placement_Available__c": {
          name: 'Overall_Placement_Available__c',
          type: 'string'
        },
        "Partner_Currency__c": {
          name: 'Partner_Currency__c',
          type: 'string'
        },
        "Partner_Currency_Conversion_Rate__c": {
          name: 'Partner_Currency_Conversion_Rate__c',
          type: 'string'
        },
        "ts2__Pay_Rate__c": {
          name: 'ts2__Pay_Rate__c',
          type: 'number'
        },
        "Positive_Margin_Available__c": {
          name: 'Positive_Margin_Available__c',
          type: 'string'
        },
        "PPE_2HR_Standard_Price_Backfill_Vendor__c": {
          name: 'PPE_2HR_Standard_Price_Backfill_Vendor__c',
          type: 'number'
        },
        "PPE_2HR_Standard_Price_Partner__c": {
          name: 'PPE_2HR_Standard_Price_Partner__c',
          type: 'number'
        },
        "PPE_2HR_Standard_Price_Vendor__c": {
          name: 'PPE_2HR_Standard_Price_Vendor__c',
          type: 'number'
        },
        "PPE_3HR_Standard_Price_Backfill_Vendor__c": {
          name: 'PPE_3HR_Standard_Price_Backfill_Vendor__c',
          type: 'number'
        },
        "PPE_3HR_Standard_Price_Partner__c": {
          name: 'PPE_3HR_Standard_Price_Partner__c',
          type: 'number'
        },
        "PPE_3HR_Standard_Price_Vendor__c": {
          name: 'PPE_3HR_Standard_Price_Vendor__c',
          type: 'number'
        },
        "PPE_4HR_Standard_Price_Backfill_Vendor__c": {
          name: 'PPE_4HR_Standard_Price_Backfill_Vendor__c',
          type: 'number'
        },
        "PPE_4HR_Standard_Price_Partner__c": {
          name: 'PPE_4HR_Standard_Price_Partner__c',
          type: 'number'
        },
        "PPE_4HR_Standard_Price_Vendor__c": {
          name: 'PPE_4HR_Standard_Price_Vendor__c',
          type: 'number'
        },
        "Product__c": {
          name: 'Product__c',
          type: 'string'
        },
        "ts2__Project__c": {
          name: 'ts2__Project__c',
          type: 'string'
        },
        "Project_Orientation_Conducted_By__c": {
          name: 'Project_Orientation_Conducted_By__c',
          type: 'string'
        },
        "Project_Orientation_Date__c": {
          name: 'Project_Orientation_Date__c',
          type: 'Date'
        },
        "Project_Orientations_Status__c": {
          name: 'Project_Orientations_Status__c',
          type: 'string'
        },
        "Reject_Reason__c": {
          name: 'Reject_Reason__c',
          type: 'string'
        },
        "ts2__Related_Application__c": {
          name: 'ts2__Related_Application__c',
          type: 'string'
        },
        "Request_Send_Date_Finance_Approval__c": {
          name: 'Request_Send_Date_Finance_Approval__c',
          type: 'Date'
        },
        "Request_Send_Date_FTE_Helpdesk__c": {
          name: 'Request_Send_Date_FTE_Helpdesk__c',
          type: 'Date'
        },
        "Request_Send_Date_Hiring_Manager__c": {
          name: 'Request_Send_Date_Hiring_Manager__c',
          type: 'Date'
        },
        "Request_Send_Date_Hr__c": {
          name: 'Request_Send_Date_Hr__c',
          type: 'Date'
        },
        "Request_Send_Date_MVPD_Approval__c": {
          name: 'Request_Send_Date_MVPD_Approval__c',
          type: 'Date'
        },
        "Request_Send_Date_Service_Delivery__c": {
          name: 'Request_Send_Date_Service_Delivery__c',
          type: 'Date'
        },
        "ts2extams__Resume_Uploaded__c": {
          name: 'ts2extams__Resume_Uploaded__c',
          type: 'string'
        },
        "ts2__Salary__c": {
          name: 'ts2__Salary__c',
          type: 'number'
        },
        "SalesRep__c": {
          name: 'SalesRep__c',
          type: 'string'
        },
        "ts2__Sales_Rep__c": {
          name: 'ts2__Sales_Rep__c',
          type: 'string'
        },
        "SalesRep2__c": {
          name: 'SalesRep2__c',
          type: 'string'
        },
        "ts2__Sales_Rep_2__c": {
          name: 'ts2__Sales_Rep_2__c',
          type: 'string'
        },
        "ts2__Sales_Rep_Pct__c": {
          name: 'ts2__Sales_Rep_Pct__c',
          type: 'number'
        },
        "ts2__Sales_Rep_Pct_2__c": {
          name: 'ts2__Sales_Rep_Pct_2__c',
          type: 'number'
        },
        "Service_Contract__c": {
          name: 'Service_Contract__c',
          type: 'string'
        },
        "Service_Contract_Created__c": {
          name: 'Service_Contract_Created__c',
          type: 'boolean'
        },
        "Service_Contract_Record_Type__c": {
          name: 'Service_Contract_Record_Type__c',
          type: 'string'
        },
        "ts2__Start_Date__c": {
          name: 'ts2__Start_Date__c',
          type: 'Date'
        },
        "ts2__Status__c": {
          name: 'ts2__Status__c',
          type: 'string'
        },
        "ts2extams__Substatus__c": {
          name: 'ts2extams__Substatus__c',
          type: 'string'
        },
        "TakenBy__c": {
          name: 'TakenBy__c',
          type: 'string'
        },
        "ts2__Taken_By__c": {
          name: 'ts2__Taken_By__c',
          type: 'string'
        },
        "TakenBy2__c": {
          name: 'TakenBy2__c',
          type: 'string'
        },
        "ts2__Taken_By_2__c": {
          name: 'ts2__Taken_By_2__c',
          type: 'string'
        },
        "ts2__Taken_Pct__c": {
          name: 'ts2__Taken_Pct__c',
          type: 'number'
        },
        "ts2__Taken_Pct_2__c": {
          name: 'ts2__Taken_Pct_2__c',
          type: 'number'
        },
        "Temp_Currency_Rate__c": {
          name: 'Temp_Currency_Rate__c',
          type: 'string'
        },
        "ts2__Third_Party__c": {
          name: 'ts2__Third_Party__c',
          type: 'boolean'
        },
        "Total_Cost_Vendor_USD__c": {
          name: 'Total_Cost_Vendor_USD__c',
          type: 'string'
        },
        "Total_Partner_Cost_USD__c": {
          name: 'Total_Partner_Cost_USD__c',
          type: 'string'
        },
        "ts2__Vendor__c": {
          name: 'ts2__Vendor__c',
          type: 'string'
        },
        "Vendor__c": {
          name: 'Vendor__c',
          type: 'string'
        },
        "Vendor_Cost_Available__c": {
          name: 'Vendor_Cost_Available__c',
          type: 'string'
        },
        "Vendor_Currency__c": {
          name: 'Vendor_Currency__c',
          type: 'string'
        },
        "Vendor_Currency_Conversion_Rate__c": {
          name: 'Vendor_Currency_Conversion_Rate__c',
          type: 'string'
        },
        "ts2__Work_Class__c": {
          name: 'ts2__Work_Class__c',
          type: 'string'
        },
        "ts2__Work_Comp__c": {
          name: 'ts2__Work_Comp__c',
          type: 'string'
        },
        "Work_Description__c": {
          name: 'Work_Description__c',
          type: 'string'
        },
        "Worker__c": {
          name: 'Worker__c',
          type: 'string'
        },
        "Worker_Approval_Date__c": {
          name: 'Worker_Approval_Date__c',
          type: 'Date'
        },
        "Worker_Approval_Status__c": {
          name: 'Worker_Approval_Status__c',
          type: 'string'
        },
        "Worker_Approval_Status_Calculated__c": {
          name: 'Worker_Approval_Status_Calculated__c',
          type: 'string'
        },
        "Worker_Billing_Type__c": {
          name: 'Worker_Billing_Type__c',
          type: 'string'
        },
        "Worker_Record_Approver_Name__c": {
          name: 'Worker_Record_Approver_Name__c',
          type: 'string'
        },
        "Worker_Type__c": {
          name: 'Worker_Type__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
