import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalService } from 'shared/services/modal.service';

@Component({
  selector: 'app-default-sku-lookup',
  templateUrl: './default-sku-lookup.component.html',
  styleUrls: ['./default-sku-lookup.component.css']
})
export class DefaultSkuLookupComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() set preSelectedValue(e) {
    if (e && e.length) {
      this.selectedSku = e;
    }
  }
  @Output() setFormValue: EventEmitter<any> = new EventEmitter;
  skuModalConfig: any;
  selectedSku: any;

  constructor(
    private _modalService: ModalService
  ) {
  }

  ngOnInit() {
    this.setSkuModalConfigData();
  }

  /* to get the list of product*/
  setSkuModalConfigData() {
    this.skuModalConfig = {
      dbConfig: {
        '_model': 'Product',
        '_fields': ['sfdcId', 'Name', 'Description', 'GEO_Metro_Vendor_FSL_code__c', 'ProductCode'],
        '_where': {}
      },
      columns: [
        { prop: 'Action', name: 'Action', visible: true, width: 100, sortable: false },
        { prop: 'Name', name: 'Name', visible: true, width: 230, sortable: true },
        { prop: 'Description', name: 'Description', visible: true, width: 200, sortable: true },
        { prop: 'GEO_Metro_Vendor_FSL_code__c', name: 'GEO Metro (Vendor FSL code)', visible: true, width: 200, sortable: true },
        { prop: 'ProductCode', name: 'Product Code', visible: true, width: 200, sortable: true }
      ],
      pageTitle: 'Product',
      isSetEnabled: true,
      isSearch: true // to enable/diable search option on top
    };
  }

  setUserValue(field, e) {
    if(field === 'SKU__c'){
      console.log(e);
      this.selectedSku = e.Name;
    }
    this.setFormValue.emit(e) 
  }

  openModal(content, size, event) {
    this._modalService.open(content, size);
  }

}
