/* tslint:disable */
import {
  RecordType
} from '../index';

declare var Object: any;
export interface PmsIccPmcRecordTypeMappingInterface {
  "sfdcId"?: string;
  "RecordTypes__c"?: string;
  "Group__c"?: string;
  "IsPMC__c"?: boolean;
  "IsPMS__c"?: boolean;
  "IsICC__c"?: boolean;
  "Name"?: string;
  "X3PL__c"?: boolean;
  "IsFTE__c"?: boolean;
  "IsHMS__c"?: boolean;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  recordtype?: RecordType;
}

export class PmsIccPmcRecordTypeMapping implements PmsIccPmcRecordTypeMappingInterface {
  "sfdcId": string;
  "RecordTypes__c": string;
  "Group__c": string;
  "IsPMC__c": boolean;
  "IsPMS__c": boolean;
  "IsICC__c": boolean;
  "Name": string;
  "X3PL__c": boolean;
  "IsFTE__c": boolean;
  "IsHMS__c": boolean;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  recordtype: RecordType;
  constructor(data?: PmsIccPmcRecordTypeMappingInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `PmsIccPmcRecordTypeMapping`.
   */
  public static getModelName() {
    return "PmsIccPmcRecordTypeMapping";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of PmsIccPmcRecordTypeMapping for dynamic purposes.
  **/
  public static factory(data: PmsIccPmcRecordTypeMappingInterface): PmsIccPmcRecordTypeMapping{
    return new PmsIccPmcRecordTypeMapping(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'PmsIccPmcRecordTypeMapping',
      plural: 'PmsIccPmcRecordTypeMappings',
      path: 'PmsIccPmcRecordTypeMappings',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "RecordTypes__c": {
          name: 'RecordTypes__c',
          type: 'string'
        },
        "Group__c": {
          name: 'Group__c',
          type: 'string'
        },
        "IsPMC__c": {
          name: 'IsPMC__c',
          type: 'boolean'
        },
        "IsPMS__c": {
          name: 'IsPMS__c',
          type: 'boolean'
        },
        "IsICC__c": {
          name: 'IsICC__c',
          type: 'boolean'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "X3PL__c": {
          name: 'X3PL__c',
          type: 'boolean'
        },
        "IsFTE__c": {
          name: 'IsFTE__c',
          type: 'boolean'
        },
        "IsHMS__c": {
          name: 'IsHMS__c',
          type: 'boolean'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        recordtype: {
          name: 'recordtype',
          type: 'RecordType',
          model: 'RecordType',
          relationType: 'belongsTo',
                  keyFrom: 'RecordTypes__c',
          keyTo: 'Name'
        },
      }
    }
  }
}
