/* tslint:disable */

declare var Object: any;
export interface NotesAttachmentInterface {
  "sfdcId"?: string;
  "CreatedById"?: string;
  "CreatedDate"?: Date;
  "LastModifiedById"?: string;
  "LastModifiedDate"?: Date;
  "OwnerId"?: string;
  "IsDeleted"?: boolean;
  "ParentId"?: string;
  "Title"?: string;
  "IsPrivate"?: boolean;
  "Body"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class NotesAttachment implements NotesAttachmentInterface {
  "sfdcId": string;
  "CreatedById": string;
  "CreatedDate": Date;
  "LastModifiedById": string;
  "LastModifiedDate": Date;
  "OwnerId": string;
  "IsDeleted": boolean;
  "ParentId": string;
  "Title": string;
  "IsPrivate": boolean;
  "Body": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: NotesAttachmentInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `NotesAttachment`.
   */
  public static getModelName() {
    return "NotesAttachment";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of NotesAttachment for dynamic purposes.
  **/
  public static factory(data: NotesAttachmentInterface): NotesAttachment{
    return new NotesAttachment(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'NotesAttachment',
      plural: 'NotesAttachments',
      path: 'NotesAttachments',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "ParentId": {
          name: 'ParentId',
          type: 'string'
        },
        "Title": {
          name: 'Title',
          type: 'string'
        },
        "IsPrivate": {
          name: 'IsPrivate',
          type: 'boolean'
        },
        "Body": {
          name: 'Body',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
