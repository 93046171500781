/* tslint:disable */
import {
  Asset,
  Product,
  ReturnOrder,
  Location,
  ProductRequestLineItem
} from '../index';

declare var Object: any;
export interface ReturnOrderLineItemInterface {
  "sfdcId"?: string;
  "IsDeleted"?: boolean;
  "ReturnOrderLineItemNumber"?: string;
  "CreatedById"?: string;
  "CreatedDate"?: Date;
  "CurrencyIsoCode"?: string;
  "Description"?: string;
  "DestinationLocationId"?: string;
  "ReturnOrderId"?: string;
  "ProductRequestLineItemId"?: string;
  "LastModifiedById"?: string;
  "LastModifiedDate"?: Date;
  "OrderItemId"?: string;
  "ProductItemId"?: string;
  "Product2Id"?: string;
  "QuantityReturned"?: number;
  "QuantityUnitOfMeasure"?: string;
  "AssetId"?: string;
  "ProcessingPlan"?: string;
  "SourceLocationId"?: string;
  "RepaymentMethod"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  asset?: Asset;
  product?: Product;
  returnOrder?: ReturnOrder;
  dlocation?: Location;
  slocation?: Location;
  productRequest?: ProductRequestLineItem;
}

export class ReturnOrderLineItem implements ReturnOrderLineItemInterface {
  "sfdcId": string;
  "IsDeleted": boolean;
  "ReturnOrderLineItemNumber": string;
  "CreatedById": string;
  "CreatedDate": Date;
  "CurrencyIsoCode": string;
  "Description": string;
  "DestinationLocationId": string;
  "ReturnOrderId": string;
  "ProductRequestLineItemId": string;
  "LastModifiedById": string;
  "LastModifiedDate": Date;
  "OrderItemId": string;
  "ProductItemId": string;
  "Product2Id": string;
  "QuantityReturned": number;
  "QuantityUnitOfMeasure": string;
  "AssetId": string;
  "ProcessingPlan": string;
  "SourceLocationId": string;
  "RepaymentMethod": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  asset: Asset;
  product: Product;
  returnOrder: ReturnOrder;
  dlocation: Location;
  slocation: Location;
  productRequest: ProductRequestLineItem;
  constructor(data?: ReturnOrderLineItemInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ReturnOrderLineItem`.
   */
  public static getModelName() {
    return "ReturnOrderLineItem";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ReturnOrderLineItem for dynamic purposes.
  **/
  public static factory(data: ReturnOrderLineItemInterface): ReturnOrderLineItem{
    return new ReturnOrderLineItem(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ReturnOrderLineItem',
      plural: 'ReturnOrderLineItems',
      path: 'ReturnOrderLineItems',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "ReturnOrderLineItemNumber": {
          name: 'ReturnOrderLineItemNumber',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "Description": {
          name: 'Description',
          type: 'string'
        },
        "DestinationLocationId": {
          name: 'DestinationLocationId',
          type: 'string'
        },
        "ReturnOrderId": {
          name: 'ReturnOrderId',
          type: 'string'
        },
        "ProductRequestLineItemId": {
          name: 'ProductRequestLineItemId',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "OrderItemId": {
          name: 'OrderItemId',
          type: 'string'
        },
        "ProductItemId": {
          name: 'ProductItemId',
          type: 'string'
        },
        "Product2Id": {
          name: 'Product2Id',
          type: 'string'
        },
        "QuantityReturned": {
          name: 'QuantityReturned',
          type: 'number'
        },
        "QuantityUnitOfMeasure": {
          name: 'QuantityUnitOfMeasure',
          type: 'string'
        },
        "AssetId": {
          name: 'AssetId',
          type: 'string'
        },
        "ProcessingPlan": {
          name: 'ProcessingPlan',
          type: 'string'
        },
        "SourceLocationId": {
          name: 'SourceLocationId',
          type: 'string'
        },
        "RepaymentMethod": {
          name: 'RepaymentMethod',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        asset: {
          name: 'asset',
          type: 'Asset',
          model: 'Asset',
          relationType: 'belongsTo',
                  keyFrom: 'AssetId',
          keyTo: 'sfdcId'
        },
        product: {
          name: 'product',
          type: 'Product',
          model: 'Product',
          relationType: 'belongsTo',
                  keyFrom: 'Product2Id',
          keyTo: 'sfdcId'
        },
        returnOrder: {
          name: 'returnOrder',
          type: 'ReturnOrder',
          model: 'ReturnOrder',
          relationType: 'belongsTo',
                  keyFrom: 'ReturnOrderId',
          keyTo: 'sfdcId'
        },
        dlocation: {
          name: 'dlocation',
          type: 'Location',
          model: 'Location',
          relationType: 'belongsTo',
                  keyFrom: 'DestinationLocationId',
          keyTo: 'sfdcId'
        },
        slocation: {
          name: 'slocation',
          type: 'Location',
          model: 'Location',
          relationType: 'belongsTo',
                  keyFrom: 'SourceLocationId',
          keyTo: 'sfdcId'
        },
        productRequest: {
          name: 'productRequest',
          type: 'ProductRequestLineItem',
          model: 'ProductRequestLineItem',
          relationType: 'belongsTo',
                  keyFrom: 'ProductRequestLineItemId',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
