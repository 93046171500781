import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { wslAccountList } from 'shared/models/static-list-data.service';

@Component({
  selector: 'app-wsl-account-lookup',
  templateUrl: './wsl-account-lookup.component.html',
  styleUrls: ['./wsl-account-lookup.component.css']
})
export class WslAccountLookupComponent implements OnInit {

  @Input() set resetData(isClear) {
    this.clearFromParent();
  }
  @Output() selectedWSLAccounts: EventEmitter<any> = new EventEmitter;
  selectedValue;

  wslAccounts = [];

  constructor() { }

  ngOnInit() {
    this.wslAccounts = wslAccountList;
    const preSelected = localStorage.getItem('filterObj');
    if (preSelected && JSON.parse(preSelected) &&
      JSON.parse(preSelected)['wslAccounts'] && JSON.parse(preSelected)['wslAccounts'].length) {
      this.selectedValue = JSON.parse(preSelected)['wslAccounts'];
    }
  }

  onChange() {
    this.selectedWSLAccounts.emit(this.selectedValue);
  }

  clearFromParent() {
    this.selectedValue = null;
  }

}
