/* tslint:disable */
import {
  Account,
  Assignment,
  AccessControl,
  Contact,
  Worker,
  WorkOrder,
  Program,
  Project2,
  Task
} from '../index';

declare var Object: any;
export interface MilestoneInterface {
  "sfdcId"?: string;
  "Account__c"?: string;
  "Complete__c"?: number;
  "Contact__c"?: string;
  "CreatedById"?: string;
  "CreatedDate"?: string;
  "Created_By_Contact__c"?: string;
  "CurrencyIsoCode"?: string;
  "Description__c"?: string;
  "Reason_Description__c"?: string;
  "Due_Date__c"?: Date;
  "Duration_Type__c"?: string;
  "Due_Time__c"?: string;
  "Start_Time__c"?: string;
  "Duration__c"?: number;
  "IsDeleted"?: boolean;
  "createdOnServiceo"?: boolean;
  "LastActivityDate"?: Date;
  "LastModifiedById"?: string;
  "LastModifiedDate"?: Date;
  "LastReferencedDate"?: Date;
  "LastViewedDate"?: Date;
  "Logged_In_Contact__c"?: string;
  "Last_Modified_By__c_On_Serviceo"?: string;
  "Milestone_Name__c"?: string;
  "Milestone_No__c"?: string;
  "Name"?: string;
  "OwnerId"?: string;
  "PgMO_Programs__c"?: string;
  "PgMO_Projects__c"?: string;
  "RecordTypeId"?: string;
  "Requestor__c"?: string;
  "Sequence_Number__c"?: number;
  "Start_Date__c"?: Date;
  "Status__c"?: string;
  "X1_Hours_minus_Created__c"?: Date;
  "Account_RFI_Form__c"?: string;
  "Appointment_Schedule_Start_Date__c"?: string;
  "Appointment_Schedule_Start_Hour__c"?: string;
  "Customer_Appointment_Date_Time_Schedule__c"?: string;
  "Customer_Appointment_Schedule_Start_Date__c"?: string;
  "Customer_Appointment_Schedule_Start_Hour__c"?: string;
  "Customer_Appointment_Schedule_Start_Mi__c"?: string;
  "Due_Date_Time__c"?: string;
  "End_Date__c"?: Date;
  "Form_Name__c"?: string;
  "Iron_Attachments__c"?: string;
  "Milestone_Library_Id__c"?: string;
  "Milestone_Type__c"?: string;
  "No_Of_Lines__c"?: number;
  "Objective_Assigned_To__c"?: string;
  "Objective_Assignment_Group__c"?: string;
  "Objective_Assignment_Individual__c"?: string;
  "Objective_Assignment_Team__c"?: string;
  "Objective_Assignment_Type__c"?: string;
  "Objective_For_Period__c"?: string;
  "Objective_Icon__c"?: string;
  "Objective_Icon_URL__c"?: string;
  "Objective_Month__c"?: string;
  "Objective_Number__c"?: string;
  "Period__c"?: string;
  "Objective_Quarter__c"?: string;
  "Objective_Rating__c"?: string;
  "Objective_Week__c"?: string;
  "Objective_Year__c"?: string;
  "Parent_Milestone__c"?: string;
  "PMS_Case_Number__c"?: string;
  "Project_Record_Type__c"?: string;
  "QuoteManager__c"?: string;
  "Recordtypeid__c"?: string;
  "Request_Form_Receipt__c"?: string;
  "Short_URL__c"?: string;
  "Start_Date_Time__c"?: string;
  "WO_Has_PgMo_Milestone__c"?: boolean;
  "Work_Order_Line_Number__c"?: string;
  "Work_Order_Number__c"?: string;
  "Work_Order_Plan_Date__c"?: Date;
  "WSC_Request_Number__c"?: string;
  "Only_Objective__c"?: boolean;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  account?: Account;
  assignment?: Assignment[];
  accessControl?: AccessControl[];
  updatedOnServiceoByContact?: Contact;
  createdByContact?: Contact;
  requestorWorker?: Worker;
  workOrder?: WorkOrder;
  program?: Program;
  project?: Project2;
  task?: Task[];
}

export class Milestone implements MilestoneInterface {
  "sfdcId": string;
  "Account__c": string;
  "Complete__c": number;
  "Contact__c": string;
  "CreatedById": string;
  "CreatedDate": string;
  "Created_By_Contact__c": string;
  "CurrencyIsoCode": string;
  "Description__c": string;
  "Reason_Description__c": string;
  "Due_Date__c": Date;
  "Duration_Type__c": string;
  "Due_Time__c": string;
  "Start_Time__c": string;
  "Duration__c": number;
  "IsDeleted": boolean;
  "createdOnServiceo": boolean;
  "LastActivityDate": Date;
  "LastModifiedById": string;
  "LastModifiedDate": Date;
  "LastReferencedDate": Date;
  "LastViewedDate": Date;
  "Logged_In_Contact__c": string;
  "Last_Modified_By__c_On_Serviceo": string;
  "Milestone_Name__c": string;
  "Milestone_No__c": string;
  "Name": string;
  "OwnerId": string;
  "PgMO_Programs__c": string;
  "PgMO_Projects__c": string;
  "RecordTypeId": string;
  "Requestor__c": string;
  "Sequence_Number__c": number;
  "Start_Date__c": Date;
  "Status__c": string;
  "X1_Hours_minus_Created__c": Date;
  "Account_RFI_Form__c": string;
  "Appointment_Schedule_Start_Date__c": string;
  "Appointment_Schedule_Start_Hour__c": string;
  "Customer_Appointment_Date_Time_Schedule__c": string;
  "Customer_Appointment_Schedule_Start_Date__c": string;
  "Customer_Appointment_Schedule_Start_Hour__c": string;
  "Customer_Appointment_Schedule_Start_Mi__c": string;
  "Due_Date_Time__c": string;
  "End_Date__c": Date;
  "Form_Name__c": string;
  "Iron_Attachments__c": string;
  "Milestone_Library_Id__c": string;
  "Milestone_Type__c": string;
  "No_Of_Lines__c": number;
  "Objective_Assigned_To__c": string;
  "Objective_Assignment_Group__c": string;
  "Objective_Assignment_Individual__c": string;
  "Objective_Assignment_Team__c": string;
  "Objective_Assignment_Type__c": string;
  "Objective_For_Period__c": string;
  "Objective_Icon__c": string;
  "Objective_Icon_URL__c": string;
  "Objective_Month__c": string;
  "Objective_Number__c": string;
  "Period__c": string;
  "Objective_Quarter__c": string;
  "Objective_Rating__c": string;
  "Objective_Week__c": string;
  "Objective_Year__c": string;
  "Parent_Milestone__c": string;
  "PMS_Case_Number__c": string;
  "Project_Record_Type__c": string;
  "QuoteManager__c": string;
  "Recordtypeid__c": string;
  "Request_Form_Receipt__c": string;
  "Short_URL__c": string;
  "Start_Date_Time__c": string;
  "WO_Has_PgMo_Milestone__c": boolean;
  "Work_Order_Line_Number__c": string;
  "Work_Order_Number__c": string;
  "Work_Order_Plan_Date__c": Date;
  "WSC_Request_Number__c": string;
  "Only_Objective__c": boolean;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  account: Account;
  assignment: Assignment[];
  accessControl: AccessControl[];
  updatedOnServiceoByContact: Contact;
  createdByContact: Contact;
  requestorWorker: Worker;
  workOrder: WorkOrder;
  program: Program;
  project: Project2;
  task: Task[];
  constructor(data?: MilestoneInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Milestone`.
   */
  public static getModelName() {
    return "Milestone";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Milestone for dynamic purposes.
  **/
  public static factory(data: MilestoneInterface): Milestone{
    return new Milestone(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Milestone',
      plural: 'Milestones',
      path: 'Milestones',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "Account__c": {
          name: 'Account__c',
          type: 'string'
        },
        "Complete__c": {
          name: 'Complete__c',
          type: 'number'
        },
        "Contact__c": {
          name: 'Contact__c',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'string'
        },
        "Created_By_Contact__c": {
          name: 'Created_By_Contact__c',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "Description__c": {
          name: 'Description__c',
          type: 'string'
        },
        "Reason_Description__c": {
          name: 'Reason_Description__c',
          type: 'string'
        },
        "Due_Date__c": {
          name: 'Due_Date__c',
          type: 'Date'
        },
        "Duration_Type__c": {
          name: 'Duration_Type__c',
          type: 'string'
        },
        "Due_Time__c": {
          name: 'Due_Time__c',
          type: 'string'
        },
        "Start_Time__c": {
          name: 'Start_Time__c',
          type: 'string'
        },
        "Duration__c": {
          name: 'Duration__c',
          type: 'number'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "createdOnServiceo": {
          name: 'createdOnServiceo',
          type: 'boolean'
        },
        "LastActivityDate": {
          name: 'LastActivityDate',
          type: 'Date'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "LastReferencedDate": {
          name: 'LastReferencedDate',
          type: 'Date'
        },
        "LastViewedDate": {
          name: 'LastViewedDate',
          type: 'Date'
        },
        "Logged_In_Contact__c": {
          name: 'Logged_In_Contact__c',
          type: 'string'
        },
        "Last_Modified_By__c_On_Serviceo": {
          name: 'Last_Modified_By__c_On_Serviceo',
          type: 'string'
        },
        "Milestone_Name__c": {
          name: 'Milestone_Name__c',
          type: 'string'
        },
        "Milestone_No__c": {
          name: 'Milestone_No__c',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "PgMO_Programs__c": {
          name: 'PgMO_Programs__c',
          type: 'string'
        },
        "PgMO_Projects__c": {
          name: 'PgMO_Projects__c',
          type: 'string'
        },
        "RecordTypeId": {
          name: 'RecordTypeId',
          type: 'string'
        },
        "Requestor__c": {
          name: 'Requestor__c',
          type: 'string'
        },
        "Sequence_Number__c": {
          name: 'Sequence_Number__c',
          type: 'number'
        },
        "Start_Date__c": {
          name: 'Start_Date__c',
          type: 'Date'
        },
        "Status__c": {
          name: 'Status__c',
          type: 'string'
        },
        "X1_Hours_minus_Created__c": {
          name: 'X1_Hours_minus_Created__c',
          type: 'Date'
        },
        "Account_RFI_Form__c": {
          name: 'Account_RFI_Form__c',
          type: 'string'
        },
        "Appointment_Schedule_Start_Date__c": {
          name: 'Appointment_Schedule_Start_Date__c',
          type: 'string'
        },
        "Appointment_Schedule_Start_Hour__c": {
          name: 'Appointment_Schedule_Start_Hour__c',
          type: 'string'
        },
        "Customer_Appointment_Date_Time_Schedule__c": {
          name: 'Customer_Appointment_Date_Time_Schedule__c',
          type: 'string'
        },
        "Customer_Appointment_Schedule_Start_Date__c": {
          name: 'Customer_Appointment_Schedule_Start_Date__c',
          type: 'string'
        },
        "Customer_Appointment_Schedule_Start_Hour__c": {
          name: 'Customer_Appointment_Schedule_Start_Hour__c',
          type: 'string'
        },
        "Customer_Appointment_Schedule_Start_Mi__c": {
          name: 'Customer_Appointment_Schedule_Start_Mi__c',
          type: 'string'
        },
        "Due_Date_Time__c": {
          name: 'Due_Date_Time__c',
          type: 'string'
        },
        "End_Date__c": {
          name: 'End_Date__c',
          type: 'Date'
        },
        "Form_Name__c": {
          name: 'Form_Name__c',
          type: 'string'
        },
        "Iron_Attachments__c": {
          name: 'Iron_Attachments__c',
          type: 'string'
        },
        "Milestone_Library_Id__c": {
          name: 'Milestone_Library_Id__c',
          type: 'string'
        },
        "Milestone_Type__c": {
          name: 'Milestone_Type__c',
          type: 'string'
        },
        "No_Of_Lines__c": {
          name: 'No_Of_Lines__c',
          type: 'number'
        },
        "Objective_Assigned_To__c": {
          name: 'Objective_Assigned_To__c',
          type: 'string'
        },
        "Objective_Assignment_Group__c": {
          name: 'Objective_Assignment_Group__c',
          type: 'string'
        },
        "Objective_Assignment_Individual__c": {
          name: 'Objective_Assignment_Individual__c',
          type: 'string'
        },
        "Objective_Assignment_Team__c": {
          name: 'Objective_Assignment_Team__c',
          type: 'string'
        },
        "Objective_Assignment_Type__c": {
          name: 'Objective_Assignment_Type__c',
          type: 'string'
        },
        "Objective_For_Period__c": {
          name: 'Objective_For_Period__c',
          type: 'string'
        },
        "Objective_Icon__c": {
          name: 'Objective_Icon__c',
          type: 'string'
        },
        "Objective_Icon_URL__c": {
          name: 'Objective_Icon_URL__c',
          type: 'string'
        },
        "Objective_Month__c": {
          name: 'Objective_Month__c',
          type: 'string'
        },
        "Objective_Number__c": {
          name: 'Objective_Number__c',
          type: 'string'
        },
        "Period__c": {
          name: 'Period__c',
          type: 'string'
        },
        "Objective_Quarter__c": {
          name: 'Objective_Quarter__c',
          type: 'string'
        },
        "Objective_Rating__c": {
          name: 'Objective_Rating__c',
          type: 'string'
        },
        "Objective_Week__c": {
          name: 'Objective_Week__c',
          type: 'string'
        },
        "Objective_Year__c": {
          name: 'Objective_Year__c',
          type: 'string'
        },
        "Parent_Milestone__c": {
          name: 'Parent_Milestone__c',
          type: 'string'
        },
        "PMS_Case_Number__c": {
          name: 'PMS_Case_Number__c',
          type: 'string'
        },
        "Project_Record_Type__c": {
          name: 'Project_Record_Type__c',
          type: 'string'
        },
        "QuoteManager__c": {
          name: 'QuoteManager__c',
          type: 'string'
        },
        "Recordtypeid__c": {
          name: 'Recordtypeid__c',
          type: 'string'
        },
        "Request_Form_Receipt__c": {
          name: 'Request_Form_Receipt__c',
          type: 'string'
        },
        "Short_URL__c": {
          name: 'Short_URL__c',
          type: 'string'
        },
        "Start_Date_Time__c": {
          name: 'Start_Date_Time__c',
          type: 'string'
        },
        "WO_Has_PgMo_Milestone__c": {
          name: 'WO_Has_PgMo_Milestone__c',
          type: 'boolean'
        },
        "Work_Order_Line_Number__c": {
          name: 'Work_Order_Line_Number__c',
          type: 'string'
        },
        "Work_Order_Number__c": {
          name: 'Work_Order_Number__c',
          type: 'string'
        },
        "Work_Order_Plan_Date__c": {
          name: 'Work_Order_Plan_Date__c',
          type: 'Date'
        },
        "WSC_Request_Number__c": {
          name: 'WSC_Request_Number__c',
          type: 'string'
        },
        "Only_Objective__c": {
          name: 'Only_Objective__c',
          type: 'boolean'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'Account__c',
          keyTo: 'sfdcId'
        },
        assignment: {
          name: 'assignment',
          type: 'Assignment[]',
          model: 'Assignment',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'PgMO_Milestones__c'
        },
        accessControl: {
          name: 'accessControl',
          type: 'AccessControl[]',
          model: 'AccessControl',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'PgMO_Milestones__c'
        },
        updatedOnServiceoByContact: {
          name: 'updatedOnServiceoByContact',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Last_Modified_By__c_On_Serviceo',
          keyTo: 'sfdcId'
        },
        createdByContact: {
          name: 'createdByContact',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Created_By_Contact__c',
          keyTo: 'sfdcId'
        },
        requestorWorker: {
          name: 'requestorWorker',
          type: 'Worker',
          model: 'Worker',
          relationType: 'belongsTo',
                  keyFrom: 'Requestor__c',
          keyTo: 'sfdcId'
        },
        workOrder: {
          name: 'workOrder',
          type: 'WorkOrder',
          model: 'WorkOrder',
          relationType: 'belongsTo',
                  keyFrom: 'Work_Order_Number__c',
          keyTo: 'sfdcId'
        },
        program: {
          name: 'program',
          type: 'Program',
          model: 'Program',
          relationType: 'belongsTo',
                  keyFrom: 'PgMO_Programs__c',
          keyTo: 'sfdcId'
        },
        project: {
          name: 'project',
          type: 'Project2',
          model: 'Project2',
          relationType: 'belongsTo',
                  keyFrom: 'PgMO_Projects__c',
          keyTo: 'sfdcId'
        },
        task: {
          name: 'task',
          type: 'Task[]',
          model: 'Task',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'PgMO_Milestones__c'
        },
      }
    }
  }
}
