/* tslint:disable */

declare var Object: any;
export interface SkillingInsuranceInterface {
  "sfdcId"?: string;
  "Coverage__c"?: string;
  "Industry__c"?: string;
  "Insurance_Type__c"?: string;
  "Resource_Worker__c"?: string;
  "PDF_Attached__c"?: boolean;
  "Policy_Number__c"?: string;
  "Provider__c"?: string;
  "Is_this_document_on_File__c"?: boolean;
  "Issue_Date__c"?: Date;
  "Expiration_Date__c"?: Date;
  "CreatedDate"?: Date;
  "IsDeleted"?: boolean;
  "LastModifiedDate"?: Date;
  "Name"?: string;
  "CreatedById"?: string;
  "CurrencyIsoCode"?: string;
  "LastModifiedById"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class SkillingInsurance implements SkillingInsuranceInterface {
  "sfdcId": string;
  "Coverage__c": string;
  "Industry__c": string;
  "Insurance_Type__c": string;
  "Resource_Worker__c": string;
  "PDF_Attached__c": boolean;
  "Policy_Number__c": string;
  "Provider__c": string;
  "Is_this_document_on_File__c": boolean;
  "Issue_Date__c": Date;
  "Expiration_Date__c": Date;
  "CreatedDate": Date;
  "IsDeleted": boolean;
  "LastModifiedDate": Date;
  "Name": string;
  "CreatedById": string;
  "CurrencyIsoCode": string;
  "LastModifiedById": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: SkillingInsuranceInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `SkillingInsurance`.
   */
  public static getModelName() {
    return "SkillingInsurance";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of SkillingInsurance for dynamic purposes.
  **/
  public static factory(data: SkillingInsuranceInterface): SkillingInsurance{
    return new SkillingInsurance(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'SkillingInsurance',
      plural: 'SkillingInsurances',
      path: 'SkillingInsurances',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "Coverage__c": {
          name: 'Coverage__c',
          type: 'string'
        },
        "Industry__c": {
          name: 'Industry__c',
          type: 'string'
        },
        "Insurance_Type__c": {
          name: 'Insurance_Type__c',
          type: 'string'
        },
        "Resource_Worker__c": {
          name: 'Resource_Worker__c',
          type: 'string'
        },
        "PDF_Attached__c": {
          name: 'PDF_Attached__c',
          type: 'boolean'
        },
        "Policy_Number__c": {
          name: 'Policy_Number__c',
          type: 'string'
        },
        "Provider__c": {
          name: 'Provider__c',
          type: 'string'
        },
        "Is_this_document_on_File__c": {
          name: 'Is_this_document_on_File__c',
          type: 'boolean'
        },
        "Issue_Date__c": {
          name: 'Issue_Date__c',
          type: 'Date'
        },
        "Expiration_Date__c": {
          name: 'Expiration_Date__c',
          type: 'Date'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
