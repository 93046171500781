import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { workerStatusList } from 'shared/models/static-list-data.service';

@Component({
  selector: 'app-worker-status-lookup',
  templateUrl: './worker-status-lookup.component.html',
  styleUrls: ['./worker-status-lookup.component.css']
})
export class WorkerStatusLookupComponent implements OnInit {

  @Input() set resetData(isClear) {
    this.clearFromParent();
  }
  @Output() selectedWorkerStatus: EventEmitter<any> = new EventEmitter;
  selectedValue;

  workerStatuses = [];

  constructor() { }

  ngOnInit() {
    this.workerStatuses = workerStatusList;
    const preSelected = localStorage.getItem('filterObj');
    if (preSelected && JSON.parse(preSelected) &&
      JSON.parse(preSelected)['workerStatus'] && JSON.parse(preSelected)['workerStatus'].length) {
      this.selectedValue = JSON.parse(preSelected)['workerStatus'];
    }
  }

  onChange() {
    this.selectedWorkerStatus.emit(this.selectedValue);
  }

  clearFromParent() {
    this.selectedValue = null;
  }

}
