/* tslint:disable */
import {
  GeoPoint
} from '../index';

declare var Object: any;
export interface ResourceInterface {
  "sfdcId"?: string;
  "Name"?: string;
  "CurrencyIsoCode"?: string;
  "CreatedById"?: string;
  "LastModifiedById"?: string;
  "CKSW_BASE__Active__c"?: boolean;
  "CKSW_BASE__Area__c"?: string;
  "CKSW_BASE__Auto_Geocode__c"?: boolean;
  "CKSW_BASE__City__c"?: string;
  "contact__c"?: string;
  "CKSW_BASE__Contract__c"?: string;
  "CKSW_BASE__Contractor__c"?: boolean;
  "CKSW_BASE__Country__c"?: string;
  "CKSW_BASE__Email__c"?: string;
  "CKSW_BASE__Gantt_Label__c"?: string;
  "CKSW_BASE__Homebase__c"?: GeoPoint;
  "CKSW_BASE__Location__c"?: string;
  "CKSW_BASE__Manager__c"?: string;
  "CKSW_BASE__Phone__c"?: string;
  "CKSW_BASE__Picture_Link__c"?: string;
  "Email__c"?: string;
  "CKSW_BASE__State__c"?: string;
  "CKSW_BASE__Street__c"?: string;
  "CKSW_BASE__Tooltip_Text__c"?: string;
  "CKSW_BASE__Travel_Speed__c"?: number;
  "CKSW_BASE__User__c"?: string;
  "CKSW_BASE__Zip_Postal_Code__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class Resource implements ResourceInterface {
  "sfdcId": string;
  "Name": string;
  "CurrencyIsoCode": string;
  "CreatedById": string;
  "LastModifiedById": string;
  "CKSW_BASE__Active__c": boolean;
  "CKSW_BASE__Area__c": string;
  "CKSW_BASE__Auto_Geocode__c": boolean;
  "CKSW_BASE__City__c": string;
  "contact__c": string;
  "CKSW_BASE__Contract__c": string;
  "CKSW_BASE__Contractor__c": boolean;
  "CKSW_BASE__Country__c": string;
  "CKSW_BASE__Email__c": string;
  "CKSW_BASE__Gantt_Label__c": string;
  "CKSW_BASE__Homebase__c": GeoPoint;
  "CKSW_BASE__Location__c": string;
  "CKSW_BASE__Manager__c": string;
  "CKSW_BASE__Phone__c": string;
  "CKSW_BASE__Picture_Link__c": string;
  "Email__c": string;
  "CKSW_BASE__State__c": string;
  "CKSW_BASE__Street__c": string;
  "CKSW_BASE__Tooltip_Text__c": string;
  "CKSW_BASE__Travel_Speed__c": number;
  "CKSW_BASE__User__c": string;
  "CKSW_BASE__Zip_Postal_Code__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: ResourceInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Resource`.
   */
  public static getModelName() {
    return "Resource";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Resource for dynamic purposes.
  **/
  public static factory(data: ResourceInterface): Resource{
    return new Resource(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Resource',
      plural: 'Resources',
      path: 'Resources',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "CKSW_BASE__Active__c": {
          name: 'CKSW_BASE__Active__c',
          type: 'boolean'
        },
        "CKSW_BASE__Area__c": {
          name: 'CKSW_BASE__Area__c',
          type: 'string'
        },
        "CKSW_BASE__Auto_Geocode__c": {
          name: 'CKSW_BASE__Auto_Geocode__c',
          type: 'boolean'
        },
        "CKSW_BASE__City__c": {
          name: 'CKSW_BASE__City__c',
          type: 'string'
        },
        "contact__c": {
          name: 'contact__c',
          type: 'string'
        },
        "CKSW_BASE__Contract__c": {
          name: 'CKSW_BASE__Contract__c',
          type: 'string'
        },
        "CKSW_BASE__Contractor__c": {
          name: 'CKSW_BASE__Contractor__c',
          type: 'boolean'
        },
        "CKSW_BASE__Country__c": {
          name: 'CKSW_BASE__Country__c',
          type: 'string'
        },
        "CKSW_BASE__Email__c": {
          name: 'CKSW_BASE__Email__c',
          type: 'string'
        },
        "CKSW_BASE__Gantt_Label__c": {
          name: 'CKSW_BASE__Gantt_Label__c',
          type: 'string'
        },
        "CKSW_BASE__Homebase__c": {
          name: 'CKSW_BASE__Homebase__c',
          type: 'GeoPoint'
        },
        "CKSW_BASE__Location__c": {
          name: 'CKSW_BASE__Location__c',
          type: 'string'
        },
        "CKSW_BASE__Manager__c": {
          name: 'CKSW_BASE__Manager__c',
          type: 'string'
        },
        "CKSW_BASE__Phone__c": {
          name: 'CKSW_BASE__Phone__c',
          type: 'string'
        },
        "CKSW_BASE__Picture_Link__c": {
          name: 'CKSW_BASE__Picture_Link__c',
          type: 'string'
        },
        "Email__c": {
          name: 'Email__c',
          type: 'string'
        },
        "CKSW_BASE__State__c": {
          name: 'CKSW_BASE__State__c',
          type: 'string'
        },
        "CKSW_BASE__Street__c": {
          name: 'CKSW_BASE__Street__c',
          type: 'string'
        },
        "CKSW_BASE__Tooltip_Text__c": {
          name: 'CKSW_BASE__Tooltip_Text__c',
          type: 'string'
        },
        "CKSW_BASE__Travel_Speed__c": {
          name: 'CKSW_BASE__Travel_Speed__c',
          type: 'number'
        },
        "CKSW_BASE__User__c": {
          name: 'CKSW_BASE__User__c',
          type: 'string'
        },
        "CKSW_BASE__Zip_Postal_Code__c": {
          name: 'CKSW_BASE__Zip_Postal_Code__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
