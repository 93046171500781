import { WorkOrderApi } from './../../../sdk/services/custom/WorkOrder';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CaseApi } from 'shared/sdk';
import { CommonService } from 'shared/services/common.service';
import { filter, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-work-order-case-id-lookup',
  templateUrl: './work-order-case-id-lookup.component.html',
  styleUrls: ['./work-order-case-id-lookup.component.css']
})
export class WorkOrderCaseIdLookupComponent implements OnInit {
  @Input() set resetData(isClear) {
    // this.pmsCaseIds = [];
    this.wokOrderCaseObj = [];
  }
  @Input() placeholder;
  @Output() getWorkOrderCaseIdsObj: EventEmitter<any> = new EventEmitter();

  wokOrderCaseObj: any
  workOrderCaseIds: any

  public searchInput = new EventEmitter<string>();
  itemsPerBatch = 50;
  notFoundText = 'No records found';
  orderBy = 'id DESC';
  isLoading = false;

  constructor(private _workOrderApi: WorkOrderApi) { }

  ngOnInit() {
    this.placeholder = this.placeholder === undefined ? 'Filter by WO Case Id' : this.placeholder;
    this.getWOCaseIds();
    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
    this.wokOrderCaseObj = (preselected && preselected['workOrderCaseIds']) ? preselected['workOrderCaseIds'] : '';
    if (this.wokOrderCaseObj && this.wokOrderCaseObj.length) {
      const filters = {
        where: { sfdcId: { inq: this.wokOrderCaseObj } },
        fields: ['sfdcId', 'Name']
      }
      this.isLoading = true;
      this._workOrderApi.find(filters).subscribe(
        x => {
          this.isLoading = false;
          this.workOrderCaseIds = x;
          this.notFoundText = (this.workOrderCaseIds.length === 0) ? 'No records found' : '';
        }, err => {
          this.isLoading = false;
          console.error(err);
        });
    }
  }

  getWOCaseIds() {
    this.searchInput
      .pipe(
        filter(search => search && search.trim().length > 2),
        debounceTime(200),
        distinctUntilChanged(),
        switchMap(search => this.loadWOCaseIds(search.trim()))
      ).subscribe(
        x => {
          this.isLoading = false;
          this.workOrderCaseIds = x;
          this.notFoundText = (this.workOrderCaseIds.length === 0) ? 'No records found' : '';
        },
        err => {
          this.isLoading = false;
          console.log(err);
          this.workOrderCaseIds = [];
        }
      );
  }
  loadWOCaseIds(search) {
    this.isLoading = true;
    const filters = {
      where: {
        Name: { like: '%' + search.trim() + '%', options: 'i' }
      },
      fields: ['sfdcId', 'Name']
    }
    return this._workOrderApi.find(filters);

  }
  clearWOCaseIds() {
    this.wokOrderCaseObj = [];
    this.notFoundText = 'Type to search WO Case Id';
    this.getWorkOrderCaseIdsObj.emit([]);
  }

  onWOCaseIdChange() {
    this.getWorkOrderCaseIdsObj.emit(this.wokOrderCaseObj);
  }

}
