import { RhsMessageConsoleListModule } from './../rhs-message-console-list/rhs-message-console-list.module';
import { CheckInCheckOutActivityFeedModule } from './../check-in-check-out-activity-feed/check-in-check-out-activity-feed.module';
import { TaskManagerDetailModule } from './../task-manager-detail/task-manager-detail.module';
import { FeedModule } from 'shared/components/feed/feed.module';
import { PmsGlobalFiltersModule } from './../pms-global-filters/pms-global-filters.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RightSidebarComponent } from './right-sidebar.component';
import { GlobalFiltersModule } from '../global-filters/global-filters.module';
import { JobStatusModule } from '../job-status/job-status.module';
import { WorkorderDetailModule } from '../workorder-detail/workorder-detail.module';
import { RequestFormReceiptsModule } from 'shared/components/request-form-receipts/request-form-receipts.module';
import { TimecardModule } from 'shared/components/timecard/timecard.module';
import { DocumentModule } from 'shared/components/document/document.module'
import { jobDetailSidemenu } from '../job-detail-sidemenu/job-detail-sidemenu.module';
import { ServiceManageSummaryModule } from 'shared/components/service-manage-summary/service-manage-summary.module';
import { SmGovernanceModule } from '../sm-governance/sm-governance.module';
import { ServiceManagerSidebarFilesModule } from 'shared/components/service-manager-sidebar-files/service-manager-sidebar-files.module';
import { ReceiptTabModule } from '../receipt-tab/receipt-tab.module';
import { TaskConversationActivityFeedModule } from '../task-conversation-activity-feed/task-conversation-activity-feed.module';
import { PurchaseOrderDetailModule } from '../purchase-order-detail/purchase-order-detail.module';
import { SalesOrderModule } from '../sales-order/sales-order.module';
import { WorkerTripDetailsSidebarModule } from './../worker-trip-details-sidebar/worker-trip-details-sidebar.module';
import { FilesListModule } from '../files-list/files-list.module';
import { FleetManagementActivityFeedListModule } from '../fleet-management-activity-feed-list/fleet-management-activity-feed-list.module';
import { RhsNotificationsListModule } from '../rhs-notifications-list/rhs-notifications-list.module';

@NgModule({
  imports: [
    GlobalFiltersModule,
    PmsGlobalFiltersModule,
    JobStatusModule,
    SalesOrderModule,
    WorkorderDetailModule,
    CommonModule,
    RequestFormReceiptsModule,
    TimecardModule,
    DocumentModule,
    jobDetailSidemenu,
    FeedModule,
    ServiceManageSummaryModule,
    SmGovernanceModule,
    ServiceManagerSidebarFilesModule,
    ReceiptTabModule,
    TaskManagerDetailModule,
    TaskConversationActivityFeedModule,
    PurchaseOrderDetailModule,
    WorkerTripDetailsSidebarModule,
    CheckInCheckOutActivityFeedModule,
    FilesListModule,
    RhsMessageConsoleListModule,
    FleetManagementActivityFeedListModule,
    RhsNotificationsListModule
  ],
  declarations: [RightSidebarComponent],
  exports: [RightSidebarComponent]
})
export class RightSidebarModule { }
