import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { PageViewMappingApi, UsersApi, UserTypeApi } from 'shared/sdk';
import { AppStateService } from 'shared/services/app-state.service';
import { debounceTime, distinctUntilChanged, switchMap, filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { CommonService } from 'shared/services/common.service';

@Component({
  selector: 'app-users-look-up',
  templateUrl: './users-look-up.component.html',
  styleUrls: ['./users-look-up.component.css']
})
export class UsersLookUpComponent implements OnInit, OnDestroy {

  @Input() set resetData(isClear) {
    this.userIdSelected = [];
  }
  @Input() from;
  @Input() selectLabel = 'Name';
  @Input() selectbindValue = 'id';
  @Input() isMultiSelect = 'false';
  @Input() set selectPageName(e) {
    if (e) {
      this.pageNameList = [];
      this.pageNameList.push(e);
      this.userIdSelected = e && e.id;
    }
  }
  // @Input() set preSelected(e) {
  //   if (e) {
  //     this.userIdSelected = e
  //     this.onPageNameChange()
  //   }
  // }
  @Input() isDisabled = false;
  @Output() getPageNameObj: EventEmitter<any> = new EventEmitter;

  // vendor User

  noPageName = 'Searching...';
  pageNameahead = new EventEmitter<string>();
  pageNameList = [];
  userIdSelected = [];
  isMultipleSelected = true;
  maxSelectedItems = 1;
  isMobile = false;

  constructor(
    private _usersApi: UsersApi,
    private _commonService: CommonService,
  ) { }

  ngOnInit() {
    this.getPageName();
    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
    this.userIdSelected = (preselected && preselected['userIdLookup']) ?
      preselected['userIdLookup'] : '';
    if (this.userIdSelected) {
      const obj = { id: this.userIdSelected };
      this.getPageNameList(obj).subscribe(x => {
        if (x && x.length) {
          this.pageNameList = x.map(user => {
            let Name = '';
            if (user['firstname']) {
              Name = Name + user['firstname'];
            }
            if (user['lastname']) {
              Name = Name + user['lastname'];
            }
            user['Name'] = Name;
            return user;
          });
        } else {
          this.pageNameList = [];
        }
        this.noPageName = (this.pageNameList.length === 0) ? 'No User found.' : '';
      },
        err => {
          console.log(err);
          this.pageNameList = [];
          this.noPageName = '';
        })
    }
    // this.onPageNameChange();
  }

  getPageName() {
    this.pageNameahead.pipe(
      filter(term => term && term.length > 2),
      distinctUntilChanged(),
      debounceTime(200),
      switchMap(term => {
        let toSearch: any = term.trim();
        toSearch = toSearch.split(' ');
        let whereObj = {};

        if (toSearch && toSearch[0]) { //FIRST NAME CHECK
          whereObj = {
            firstname: { like: '%' + toSearch[0] + '%', options: 'i' }
          }
        }

        if (toSearch && toSearch[1]) { // LAST NAME CHECK
          let lastname = ''
          toSearch.forEach((element, index) => {
            if (index !== 0) {
              lastname = (lastname) ? lastname + ' ' + element : lastname + element;
            }
          });
          whereObj = {
            firstname: { ilike: '%' + toSearch[0] + '%', options: 'i' },
            lastname: { ilike: '%' + lastname + '%', options: 'i' }
          }
        }
        return this.getPageNameList(whereObj);
      })
    ).subscribe(
      x => {
        if (x && x.length) {
          this.pageNameList = x.map(user => {
            let Name = '';
            if (user['firstname']) {
              Name = Name + user['firstname'];
            }
            if (user['lastname']) {
              Name = Name + ' ' + user['lastname'];
            }
            user['Name'] = Name;
            return user;
          });
        } else {
          this.pageNameList = [];
        }
        this.noPageName = (this.pageNameList.length === 0) ? 'No User found.' : '';
      },
      err => {
        console.log(err);
        this.pageNameList = [];
        this.noPageName = '';
      }
    );
  }

  onPageNameChange() {
    this.getPageNameObj.emit(this.userIdSelected);
  }

  clearPrograms() {
    this.userIdSelected = [];
    this.getPageNameObj.emit({});
  }

  getPageNameList(whereObj) {
    return this._usersApi.find({
      where: whereObj,
      fields: ['firstname', 'lastname', 'id'],
    }).map(res => res);
  }

  ngOnDestroy() { }

}
