/* tslint:disable */

declare var Object: any;
export interface ServiceContractLineItemInterface {
  "sfdcId"?: string;
  "AssetId"?: string;
  "Asset_Model__c"?: string;
  "Asset_Serial_Number__c"?: string;
  "Asset_Status__c"?: string;
  "CreatedById"?: string;
  "CreatedDate"?: Date;
  "CurrencyIsoCode"?: string;
  "Customer_PO_Date__c"?: Date;
  "Customer_Po_Line__c"?: string;
  "Customer_SO_Number__c"?: string;
  "Decommisioned_Date__c"?: Date;
  "Description"?: string;
  "Discount"?: number;
  "EndDate"?: Date;
  "IsDeleted"?: boolean;
  "LastModifiedById"?: string;
  "LastModifiedDate"?: Date;
  "LastReferencedDate"?: Date;
  "LastViewedDate"?: Date;
  "LineItemNumber"?: string;
  "ListPrice"?: number;
  "Manufacturer_part_number__c"?: string;
  "PO_Line_Number__c"?: string;
  "Partner_Asset_enrollment_number__c"?: string;
  "PricebookEntryId"?: string;
  "Pricelist__c"?: string;
  "Primary_FTE_role_Profile__c"?: string;
  "Product2Id"?: string;
  "Product_Code__c"?: string;
  "Program_Pricelist__c"?: string;
  "Quantity"?: number;
  "RecordTypeId"?: string;
  "Reference_Number__c"?: string;
  "ServiceContractId"?: string;
  "Ship_Date__c"?: Date;
  "StartDate"?: Date;
  "Status"?: string;
  "Subtotal"?: number;
  "TotalPrice"?: number;
  "Type__c"?: string;
  "UnitPrice"?: number;
  "Vendor__c"?: string;
  "Worker__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class ServiceContractLineItem implements ServiceContractLineItemInterface {
  "sfdcId": string;
  "AssetId": string;
  "Asset_Model__c": string;
  "Asset_Serial_Number__c": string;
  "Asset_Status__c": string;
  "CreatedById": string;
  "CreatedDate": Date;
  "CurrencyIsoCode": string;
  "Customer_PO_Date__c": Date;
  "Customer_Po_Line__c": string;
  "Customer_SO_Number__c": string;
  "Decommisioned_Date__c": Date;
  "Description": string;
  "Discount": number;
  "EndDate": Date;
  "IsDeleted": boolean;
  "LastModifiedById": string;
  "LastModifiedDate": Date;
  "LastReferencedDate": Date;
  "LastViewedDate": Date;
  "LineItemNumber": string;
  "ListPrice": number;
  "Manufacturer_part_number__c": string;
  "PO_Line_Number__c": string;
  "Partner_Asset_enrollment_number__c": string;
  "PricebookEntryId": string;
  "Pricelist__c": string;
  "Primary_FTE_role_Profile__c": string;
  "Product2Id": string;
  "Product_Code__c": string;
  "Program_Pricelist__c": string;
  "Quantity": number;
  "RecordTypeId": string;
  "Reference_Number__c": string;
  "ServiceContractId": string;
  "Ship_Date__c": Date;
  "StartDate": Date;
  "Status": string;
  "Subtotal": number;
  "TotalPrice": number;
  "Type__c": string;
  "UnitPrice": number;
  "Vendor__c": string;
  "Worker__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: ServiceContractLineItemInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ServiceContractLineItem`.
   */
  public static getModelName() {
    return "ServiceContractLineItem";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ServiceContractLineItem for dynamic purposes.
  **/
  public static factory(data: ServiceContractLineItemInterface): ServiceContractLineItem{
    return new ServiceContractLineItem(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ServiceContractLineItem',
      plural: 'ServiceContractLineItems',
      path: 'ServiceContractLineItems',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "AssetId": {
          name: 'AssetId',
          type: 'string'
        },
        "Asset_Model__c": {
          name: 'Asset_Model__c',
          type: 'string'
        },
        "Asset_Serial_Number__c": {
          name: 'Asset_Serial_Number__c',
          type: 'string'
        },
        "Asset_Status__c": {
          name: 'Asset_Status__c',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "Customer_PO_Date__c": {
          name: 'Customer_PO_Date__c',
          type: 'Date'
        },
        "Customer_Po_Line__c": {
          name: 'Customer_Po_Line__c',
          type: 'string'
        },
        "Customer_SO_Number__c": {
          name: 'Customer_SO_Number__c',
          type: 'string'
        },
        "Decommisioned_Date__c": {
          name: 'Decommisioned_Date__c',
          type: 'Date'
        },
        "Description": {
          name: 'Description',
          type: 'string'
        },
        "Discount": {
          name: 'Discount',
          type: 'number'
        },
        "EndDate": {
          name: 'EndDate',
          type: 'Date'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "LastReferencedDate": {
          name: 'LastReferencedDate',
          type: 'Date'
        },
        "LastViewedDate": {
          name: 'LastViewedDate',
          type: 'Date'
        },
        "LineItemNumber": {
          name: 'LineItemNumber',
          type: 'string'
        },
        "ListPrice": {
          name: 'ListPrice',
          type: 'number'
        },
        "Manufacturer_part_number__c": {
          name: 'Manufacturer_part_number__c',
          type: 'string'
        },
        "PO_Line_Number__c": {
          name: 'PO_Line_Number__c',
          type: 'string'
        },
        "Partner_Asset_enrollment_number__c": {
          name: 'Partner_Asset_enrollment_number__c',
          type: 'string'
        },
        "PricebookEntryId": {
          name: 'PricebookEntryId',
          type: 'string'
        },
        "Pricelist__c": {
          name: 'Pricelist__c',
          type: 'string'
        },
        "Primary_FTE_role_Profile__c": {
          name: 'Primary_FTE_role_Profile__c',
          type: 'string'
        },
        "Product2Id": {
          name: 'Product2Id',
          type: 'string'
        },
        "Product_Code__c": {
          name: 'Product_Code__c',
          type: 'string'
        },
        "Program_Pricelist__c": {
          name: 'Program_Pricelist__c',
          type: 'string'
        },
        "Quantity": {
          name: 'Quantity',
          type: 'number'
        },
        "RecordTypeId": {
          name: 'RecordTypeId',
          type: 'string'
        },
        "Reference_Number__c": {
          name: 'Reference_Number__c',
          type: 'string'
        },
        "ServiceContractId": {
          name: 'ServiceContractId',
          type: 'string'
        },
        "Ship_Date__c": {
          name: 'Ship_Date__c',
          type: 'Date'
        },
        "StartDate": {
          name: 'StartDate',
          type: 'Date'
        },
        "Status": {
          name: 'Status',
          type: 'string'
        },
        "Subtotal": {
          name: 'Subtotal',
          type: 'number'
        },
        "TotalPrice": {
          name: 'TotalPrice',
          type: 'number'
        },
        "Type__c": {
          name: 'Type__c',
          type: 'string'
        },
        "UnitPrice": {
          name: 'UnitPrice',
          type: 'number'
        },
        "Vendor__c": {
          name: 'Vendor__c',
          type: 'string'
        },
        "Worker__c": {
          name: 'Worker__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
