import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobSummaryComponent } from './job-summary.component';
import { PipeModule } from 'shared/pipe/pipe.module';

@NgModule({
    imports: [
        CommonModule,
        PipeModule
    ],
    declarations: [JobSummaryComponent],
    exports: [JobSummaryComponent],
})
export class JobSummaryModule { }
