/* tslint:disable */
import {
  Job
} from '../index';

declare var Object: any;
export interface FavoriteInterface {
  "modelId"?: number;
  "modelName"?: string;
  "caseId"?: number;
  "conversationId"?: number;
  "userId"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  job?: Job;
}

export class Favorite implements FavoriteInterface {
  "modelId": number;
  "modelName": string;
  "caseId": number;
  "conversationId": number;
  "userId": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  job: Job;
  constructor(data?: FavoriteInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Favorite`.
   */
  public static getModelName() {
    return "Favorite";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Favorite for dynamic purposes.
  **/
  public static factory(data: FavoriteInterface): Favorite{
    return new Favorite(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Favorite',
      plural: 'Favorites',
      path: 'Favorites',
      idName: 'id',
      properties: {
        "modelId": {
          name: 'modelId',
          type: 'number'
        },
        "modelName": {
          name: 'modelName',
          type: 'string'
        },
        "caseId": {
          name: 'caseId',
          type: 'number'
        },
        "conversationId": {
          name: 'conversationId',
          type: 'number'
        },
        "userId": {
          name: 'userId',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        job: {
          name: 'job',
          type: 'Job',
          model: 'Job',
          relationType: 'belongsTo',
                  keyFrom: 'modelId',
          keyTo: 'id'
        },
      }
    }
  }
}
