import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Rx';

import { AppStateService } from '../../services/app-state.service';
import { AlertService } from '../../services/alert.service';
import { SocketService } from '../../services/socket.service';

@Component({
    selector: 'app-notification-header',
    templateUrl: './notification-header.component.html'
})

export class NotificationHeaderComponent implements OnInit, OnDestroy {
    @Input() modelName: string;
    @Input() modelId: string;
    errorMessage: any;

    private subscription1: Subscription;
    public notificationLists: Array<any>;

    constructor(
        private _alertService: AlertService,
        private _appStateService: AppStateService,
        private _socketService: SocketService
    ) { }

    ngOnInit() {
        this.setHeaderNotification();
    }

    ngOnDestroy() {
        if (this.subscription1) {
            this.subscription1.unsubscribe();
        }

    }

    setHeaderNotification() {
        this._appStateService.getAppState().take(1).subscribe(appState => {
            if (appState && appState['user']) {
                this._socketService.setHeaderNotifications();
                this.subscription1 = this._socketService.getHeaderNotifications().subscribe(userNotification => {
                    this.notificationLists = userNotification;
                    this._socketService.getMarkRead().subscribe(data => {
                        if(data && data.id) {
                            this.notificationLists.forEach(item => {
                                if(data.id === item.id) {
                                    item['marked_read'] = true;
                                }
                            })
                        } else {
                            this.notificationLists.forEach(item => {
                                item['marked_read'] = true;
                            });
                        }
                    });
                    this.errorMessage = (this.notificationLists.length === 0) ? 'No new notification' : '';
                }, error => {
                    this._alertService.error(error);
                });
            }
        })
    }

    markAsRead(id = null) {
        if (id) {
            this._socketService.readNotification(id);
            return;
        }
        this._socketService.readAllNotification();
    }
}
