/* tslint:disable */

declare var Object: any;
export interface JobOrderInterface {
  "sfdcId"?: string;
  "ts2extams__Launch_AMS__c"?: string;
  "CreatedById"?: string;
  "CurrencyIsoCode"?: string;
  "Name"?: string;
  "LastModifiedById"?: string;
  "OwnerId"?: string;
  "RecordTypeId"?: string;
  "Any_specifications_required__c"?: string;
  "ts2__Application_Count__c"?: number;
  "ts2extams__Workflow__c"?: string;
  "ts2extams__Workflow_Link__c"?: string;
  "Approval_Status__c"?: string;
  "Approver__c"?: string;
  "Backfill__c"?: string;
  "Backup_PM__c"?: string;
  "ts2extams__Basic_Qualifications__c"?: string;
  "BGV_Requirements__c"?: string;
  "ts2__Bonus__c"?: string;
  "ts2extams__BQSearch__c"?: string;
  "ts2__Burden_Pct__c"?: number;
  "ts2__Business_Unit__c"?: string;
  "ts2__CDate__c"?: Date;
  "Certifications__c"?: string;
  "ts2__Chance_this_search_will_be_filled__c"?: number;
  "ts2__Chatter_Hash_Tags__c"?: string;
  "Child_Job_Openings__c"?: number;
  "ts2__JMCity__c"?: string;
  "ts2__Clearance__c"?: boolean;
  "ts2__Clearance_Type__c"?: string;
  "ts2__Account__c"?: string;
  "ts2__Client_Address__c"?: string;
  "ts2__Client_Phone__c"?: string;
  "ts2__Closed_Reason__c"?: string;
  "ts2__Close_Pct__c"?: number;
  "Comment__c"?: string;
  "Confidential__c"?: boolean;
  "Confirmed_Start_Date_HR_Head__c"?: Date;
  "ts2__Contact__c"?: string;
  "ts2__Contact_Email__c"?: string;
  "ts2__Contact_Phone__c"?: string;
  "ts2__Contract__c"?: string;
  "Contract_Type__c"?: string;
  "ts2__Conversion_Date__c"?: Date;
  "ts2__Conversion_Fee__c"?: string;
  "ts2__Conversion_Fee_Pct__c"?: number;
  "ts2__JMCountry__c"?: string;
  "Country_Code__c"?: string;
  "Country_List__c"?: string;
  "CR__c"?: string;
  "CreatedBy__c"?: string;
  "Currency__c"?: string;
  "Customer_Account__c"?: string;
  "Customer_Price__c"?: number;
  "ts2__Date_Filled__c"?: Date;
  "ts2__Date_Posted__c"?: Date;
  "ts2__Days_Gauge__c"?: string;
  "ts2__Days_Open__c"?: string;
  "Days_Open_Calculated__c"?: string;
  "ts2__Days_to_Fill__c"?: string;
  "ts2__Department__c"?: string;
  "Department__c"?: string;
  "ts2__Description__c"?: string;
  "ts2__Description_ID__c"?: string;
  "ts2__Apply_with_Indeed__c"?: boolean;
  "ts2__Disable_EEO__c"?: boolean;
  "Drug_Test_Requirement__c"?: string;
  "Educations__c"?: string;
  "Employment_Type__c"?: string;
  "ts2__Employment_Type__c"?: string;
  "ts2__Estimated_End_Date__c"?: Date;
  "ts2__Estimated_Fee__c"?: string;
  "ts2__Estimated_Gross_Margin__c"?: string;
  "ts2__Estimated_Hours__c"?: string;
  "ts2__Estimated_Start_Date__c"?: Date;
  "ts2__User__c"?: string;
  "Executive_Approver__c"?: string;
  "Expand_GEO_Search_Radius__c"?: boolean;
  "Expected_Start_Date_Recruitment_Head__c"?: Date;
  "ts2__Expiration_Date__c"?: Date;
  "ts2__Expires__c"?: boolean;
  "ts2__Facility__c"?: string;
  "ts2__Fee_Pct__c"?: number;
  "ts2__First_Year_Salary__c"?: number;
  "ts2__Forecasted_Revenue__c"?: string;
  "ts2__FTE__c"?: number;
  "MetroCodes__c"?: string;
  "Good_to_go_skills__c"?: string;
  "ts2__Manager__c"?: string;
  "Hiring_Manager__c"?: string;
  "Increase_target_cost__c"?: boolean;
  "ts2__Internal_Notes__c"?: string;
  "ts2__Internal_Passing_Score__c"?: number;
  "ts2__Internal_Question_Set__c"?: string;
  "ts2__Interview_Count__c"?: number;
  "Iron_email_id_required__c"?: boolean;
  "ts2__Job_Advertisement__c"?: string;
  "Job_Assignment_Level__c"?: string;
  "Job_board_database_50_miles__c"?: number;
  "Job_Creation_Reason__c"?: string;
  "Job_Creation_Reason_Formula__c"?: string;
  "Job_Creation_Reason_Replac__c"?: string;
  "ts2__Job_Description__c"?: string;
  "ts2__Job_Family__c"?: string;
  "ts2__Job_Function__c"?: string;
  "ts2__JobNotificationsSummary__c"?: number;
  "ts2__Job_Number__c"?: string;
  "jsjo__Job_Opportunity__c"?: string;
  "ts2__Job_Rank__c"?: string;
  "jobsite__c"?: string;
  "Jobsite_Project__c"?: string;
  "Job_Title__c"?: string;
  "ts2__Job_Title_Number__c"?: string;
  "ts2__KPI_Count__c"?: string;
  "Language_Preference__c"?: string;
  "LastModifiedBy__c"?: string;
  "ts2__Last_Modified_Date_for_S_Control__c"?: Date;
  "ts2__Legacy_JobID__c"?: string;
  "ts2__Localized_Job_Description__c"?: string;
  "ts2__Localized_Job_Title__c"?: string;
  "ts2__JMLocation__c"?: string;
  "ts2__Location__c"?: string;
  "Location_List_New__c"?: string;
  "Location_List__c"?: string;
  "ts2extams__Locked__c"?: boolean;
  "ts2extams__Locked_Date__c"?: Date;
  "ts2extams__Locked_User__c"?: string;
  "Mandatory_skills__c"?: string;
  "Margin__c"?: number;
  "ts2__Markup__c"?: boolean;
  "ts2__Markup_Bill__c"?: string;
  "ts2__Markup_Pay__c"?: number;
  "ts2__Markup_Pct__c"?: number;
  "Project_Name__c"?: string;
  "ts2__Max_Bill_Rate__c"?: number;
  "ts2__Max_Conversion_Salary__c"?: number;
  "ts2__Max_Pay_Rate__c"?: number;
  "ts2__Max_Salary__c"?: number;
  "ts2__JMMetro__c"?: string;
  "ts2__Metro__c"?: string;
  "ts2__Min_Bill_Rate__c"?: number;
  "ts2__Min_Conversion_Salary__c"?: number;
  "ts2__Min_Pay_Rate__c"?: number;
  "ts2__Min_Salary__c"?: number;
  "Negative_Keywords__c"?: string;
  "ts2__Offer_Count__c"?: number;
  "Offer_Count__c"?: number;
  "ts2__Openings__c"?: number;
  "ts2__Order__c"?: string;
  "Other_Job_Creation_Reason__c"?: string;
  "Parent_Job_Order__c"?: string;
  "ts2__Passing_Score__c"?: number;
  "ts2__referral_bonus__c"?: string;
  "ts2__Percentage_Fee__c"?: number;
  "ts2__Per_Diem__c"?: boolean;
  "ts2__Per_Diem_Bill_Rate__c"?: number;
  "ts2__Per_Diem_Diff_Pct__c"?: number;
  "ts2__Per_Diem_Pay_Rate__c"?: number;
  "ts2__Placement_Count__c"?: number;
  "Placement_Count_New__c"?: number;
  "Please_Specify_BGV_and_Drug_test_Require__c"?: string;
  "Please_Specify_Local_Language__c"?: string;
  "PgMo_Name__c"?: string;
  "Population_Radius_25_Miles__c"?: number;
  "Population_Radius_50_Miles__c"?: number;
  "ts2__JMPostalCode__c"?: string;
  "ts2__Posted_to_Prospects__c"?: boolean;
  "ts2__Posting_Status__c"?: string;
  "ts2__Post_Job__c"?: boolean;
  "ts2__Post_to_Chatter__c"?: boolean;
  "Post_to_DICE__c"?: boolean;
  "ts2__Recruiter__c"?: string;
  "Primary_Recruiter__c"?: string;
  "ts2__Priority__c"?: string;
  "Product__c"?: string;
  "ts2__Project__c"?: string;
  "ts2__Question_Set__c"?: string;
  "ts2__RadiusDistance__c"?: number;
  "ts2__RadiusDistanceUnit__c"?: string;
  "Reassign_job_to_new_recruitment_manager__c"?: boolean;
  "Region__c"?: string;
  "Relocate_Candidate_from_nearby_location__c"?: boolean;
  "ts2__Remaining__c"?: string;
  "Remaining_Actual__c"?: string;
  "Replacement_of_Worker__c"?: string;
  "Requisition_Issues__c"?: string;
  "Review_Term__c"?: string;
  "Salary_Range_Max__c"?: number;
  "Salary_Range_Min__c"?: number;
  "SAM_Name__c"?: string;
  "ts2__Screening_Requirements__c"?: string;
  "ts2__Search__c"?: number;
  "ts2__Search_Type__c"?: string;
  "ts2__Secondary_Recruiter__c"?: string;
  "Secondary_Recruiter__c"?: string;
  "ts2__Shared__c"?: boolean;
  "ts2__Shift__c"?: string;
  "ts2__Short_Description__c"?: string;
  "ts2__Job_Tag__c"?: string;
  "ts2__Skill_Codes__c"?: string;
  "SOW__c"?: string;
  "ts2__Specific_Requirements__c"?: string;
  "ts2__Stage__c"?: string;
  "ts2__Start_Date__c"?: string;
  "ts2__JMState__c"?: string;
  "ts2__Status__c"?: string;
  "ts2__JMStreet__c"?: string;
  "ts2__Submittal_Count__c"?: number;
  "Talent_Level__c"?: string;
  "talenttype__c"?: string;
  "ts2__Target_Base_Salary__c"?: number;
  "Target_Cost__c"?: string;
  "Term__c"?: string;
  "ts2__Term_Months__c"?: string;
  "Tertiary_Recruiter__c"?: string;
  "ts2__Text_Description__c"?: string;
  "ts2__Total_KPI_Count__c"?: string;
  "ts2__Travel__c"?: number;
  "Type_of_Requirement__c"?: string;
  "ts2__Waitlisted__c"?: boolean;
  "ts2__Work_Class__c"?: string;
  "ts2__TEST1__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class JobOrder implements JobOrderInterface {
  "sfdcId": string;
  "ts2extams__Launch_AMS__c": string;
  "CreatedById": string;
  "CurrencyIsoCode": string;
  "Name": string;
  "LastModifiedById": string;
  "OwnerId": string;
  "RecordTypeId": string;
  "Any_specifications_required__c": string;
  "ts2__Application_Count__c": number;
  "ts2extams__Workflow__c": string;
  "ts2extams__Workflow_Link__c": string;
  "Approval_Status__c": string;
  "Approver__c": string;
  "Backfill__c": string;
  "Backup_PM__c": string;
  "ts2extams__Basic_Qualifications__c": string;
  "BGV_Requirements__c": string;
  "ts2__Bonus__c": string;
  "ts2extams__BQSearch__c": string;
  "ts2__Burden_Pct__c": number;
  "ts2__Business_Unit__c": string;
  "ts2__CDate__c": Date;
  "Certifications__c": string;
  "ts2__Chance_this_search_will_be_filled__c": number;
  "ts2__Chatter_Hash_Tags__c": string;
  "Child_Job_Openings__c": number;
  "ts2__JMCity__c": string;
  "ts2__Clearance__c": boolean;
  "ts2__Clearance_Type__c": string;
  "ts2__Account__c": string;
  "ts2__Client_Address__c": string;
  "ts2__Client_Phone__c": string;
  "ts2__Closed_Reason__c": string;
  "ts2__Close_Pct__c": number;
  "Comment__c": string;
  "Confidential__c": boolean;
  "Confirmed_Start_Date_HR_Head__c": Date;
  "ts2__Contact__c": string;
  "ts2__Contact_Email__c": string;
  "ts2__Contact_Phone__c": string;
  "ts2__Contract__c": string;
  "Contract_Type__c": string;
  "ts2__Conversion_Date__c": Date;
  "ts2__Conversion_Fee__c": string;
  "ts2__Conversion_Fee_Pct__c": number;
  "ts2__JMCountry__c": string;
  "Country_Code__c": string;
  "Country_List__c": string;
  "CR__c": string;
  "CreatedBy__c": string;
  "Currency__c": string;
  "Customer_Account__c": string;
  "Customer_Price__c": number;
  "ts2__Date_Filled__c": Date;
  "ts2__Date_Posted__c": Date;
  "ts2__Days_Gauge__c": string;
  "ts2__Days_Open__c": string;
  "Days_Open_Calculated__c": string;
  "ts2__Days_to_Fill__c": string;
  "ts2__Department__c": string;
  "Department__c": string;
  "ts2__Description__c": string;
  "ts2__Description_ID__c": string;
  "ts2__Apply_with_Indeed__c": boolean;
  "ts2__Disable_EEO__c": boolean;
  "Drug_Test_Requirement__c": string;
  "Educations__c": string;
  "Employment_Type__c": string;
  "ts2__Employment_Type__c": string;
  "ts2__Estimated_End_Date__c": Date;
  "ts2__Estimated_Fee__c": string;
  "ts2__Estimated_Gross_Margin__c": string;
  "ts2__Estimated_Hours__c": string;
  "ts2__Estimated_Start_Date__c": Date;
  "ts2__User__c": string;
  "Executive_Approver__c": string;
  "Expand_GEO_Search_Radius__c": boolean;
  "Expected_Start_Date_Recruitment_Head__c": Date;
  "ts2__Expiration_Date__c": Date;
  "ts2__Expires__c": boolean;
  "ts2__Facility__c": string;
  "ts2__Fee_Pct__c": number;
  "ts2__First_Year_Salary__c": number;
  "ts2__Forecasted_Revenue__c": string;
  "ts2__FTE__c": number;
  "MetroCodes__c": string;
  "Good_to_go_skills__c": string;
  "ts2__Manager__c": string;
  "Hiring_Manager__c": string;
  "Increase_target_cost__c": boolean;
  "ts2__Internal_Notes__c": string;
  "ts2__Internal_Passing_Score__c": number;
  "ts2__Internal_Question_Set__c": string;
  "ts2__Interview_Count__c": number;
  "Iron_email_id_required__c": boolean;
  "ts2__Job_Advertisement__c": string;
  "Job_Assignment_Level__c": string;
  "Job_board_database_50_miles__c": number;
  "Job_Creation_Reason__c": string;
  "Job_Creation_Reason_Formula__c": string;
  "Job_Creation_Reason_Replac__c": string;
  "ts2__Job_Description__c": string;
  "ts2__Job_Family__c": string;
  "ts2__Job_Function__c": string;
  "ts2__JobNotificationsSummary__c": number;
  "ts2__Job_Number__c": string;
  "jsjo__Job_Opportunity__c": string;
  "ts2__Job_Rank__c": string;
  "jobsite__c": string;
  "Jobsite_Project__c": string;
  "Job_Title__c": string;
  "ts2__Job_Title_Number__c": string;
  "ts2__KPI_Count__c": string;
  "Language_Preference__c": string;
  "LastModifiedBy__c": string;
  "ts2__Last_Modified_Date_for_S_Control__c": Date;
  "ts2__Legacy_JobID__c": string;
  "ts2__Localized_Job_Description__c": string;
  "ts2__Localized_Job_Title__c": string;
  "ts2__JMLocation__c": string;
  "ts2__Location__c": string;
  "Location_List_New__c": string;
  "Location_List__c": string;
  "ts2extams__Locked__c": boolean;
  "ts2extams__Locked_Date__c": Date;
  "ts2extams__Locked_User__c": string;
  "Locked_User__c": string;
  "Mandatory_skills__c": string;
  "Margin__c": number;
  "ts2__Markup__c": boolean;
  "ts2__Markup_Bill__c": string;
  "ts2__Markup_Pay__c": number;
  "ts2__Markup_Pct__c": number;
  "Project_Name__c": string;
  "ts2__Max_Bill_Rate__c": number;
  "ts2__Max_Conversion_Salary__c": number;
  "ts2__Max_Pay_Rate__c": number;
  "ts2__Max_Salary__c": number;
  "ts2__JMMetro__c": string;
  "ts2__Metro__c": string;
  "ts2__Min_Bill_Rate__c": number;
  "ts2__Min_Conversion_Salary__c": number;
  "ts2__Min_Pay_Rate__c": number;
  "ts2__Min_Salary__c": number;
  "Negative_Keywords__c": string;
  "ts2__Offer_Count__c": number;
  "Offer_Count__c": number;
  "ts2__Openings__c": number;
  "ts2__Order__c": string;
  "Other_Job_Creation_Reason__c": string;
  "Parent_Job_Order__c": string;
  "ts2__Passing_Score__c": number;
  "ts2__referral_bonus__c": string;
  "ts2__Percentage_Fee__c": number;
  "ts2__Per_Diem__c": boolean;
  "ts2__Per_Diem_Bill_Rate__c": number;
  "ts2__Per_Diem_Diff_Pct__c": number;
  "ts2__Per_Diem_Pay_Rate__c": number;
  "ts2__Placement_Count__c": number;
  "Placement_Count_New__c": number;
  "Please_Specify_BGV_and_Drug_test_Require__c": string;
  "Please_Specify_Local_Language__c": string;
  "PgMo_Name__c": string;
  "Population_Radius_25_Miles__c": number;
  "Population_Radius_50_Miles__c": number;
  "ts2__JMPostalCode__c": string;
  "ts2__Posted_to_Prospects__c": boolean;
  "ts2__Posting_Status__c": string;
  "ts2__Post_Job__c": boolean;
  "ts2__Post_to_Chatter__c": boolean;
  "Post_to_DICE__c": boolean;
  "ts2__Recruiter__c": string;
  "Primary_Recruiter__c": string;
  "ts2__Priority__c": string;
  "Product__c": string;
  "ts2__Project__c": string;
  "ts2__Question_Set__c": string;
  "ts2__RadiusDistance__c": number;
  "ts2__RadiusDistanceUnit__c": string;
  "Reassign_job_to_new_recruitment_manager__c": boolean;
  "Region__c": string;
  "Relocate_Candidate_from_nearby_location__c": boolean;
  "ts2__Remaining__c": string;
  "Remaining_Actual__c": string;
  "Replacement_of_Worker__c": string;
  "Requisition_Issues__c": string;
  "Review_Term__c": string;
  "Salary_Range_Max__c": number;
  "Salary_Range_Min__c": number;
  "SAM_Name__c": string;
  "ts2__Screening_Requirements__c": string;
  "ts2__Search__c": number;
  "ts2__Search_Type__c": string;
  "ts2__Secondary_Recruiter__c": string;
  "Secondary_Recruiter__c": string;
  "ts2__Shared__c": boolean;
  "ts2__Shift__c": string;
  "ts2__Short_Description__c": string;
  "ts2__Job_Tag__c": string;
  "ts2__Skill_Codes__c": string;
  "SOW__c": string;
  "ts2__Specific_Requirements__c": string;
  "ts2__Stage__c": string;
  "ts2__Start_Date__c": string;
  "ts2__JMState__c": string;
  "ts2__Status__c": string;
  "ts2__JMStreet__c": string;
  "ts2__Submittal_Count__c": number;
  "Talent_Level__c": string;
  "talenttype__c": string;
  "ts2__Target_Base_Salary__c": number;
  "Target_Cost__c": string;
  "Term__c": string;
  "ts2__Term_Months__c": string;
  "Tertiary_Recruiter__c": string;
  "ts2__Text_Description__c": string;
  "ts2__Total_KPI_Count__c": string;
  "ts2__Travel__c": number;
  "Type_of_Requirement__c": string;
  "ts2__Waitlisted__c": boolean;
  "ts2__Work_Class__c": string;
  "ts2__TEST1__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: JobOrderInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `JobOrder`.
   */
  public static getModelName() {
    return "JobOrder";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of JobOrder for dynamic purposes.
  **/
  public static factory(data: JobOrderInterface): JobOrder{
    return new JobOrder(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'JobOrder',
      plural: 'JobOrders',
      path: 'JobOrders',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "ts2extams__Launch_AMS__c": {
          name: 'ts2extams__Launch_AMS__c',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "RecordTypeId": {
          name: 'RecordTypeId',
          type: 'string'
        },
        "Any_specifications_required__c": {
          name: 'Any_specifications_required__c',
          type: 'string'
        },
        "ts2__Application_Count__c": {
          name: 'ts2__Application_Count__c',
          type: 'number'
        },
        "ts2extams__Workflow__c": {
          name: 'ts2extams__Workflow__c',
          type: 'string'
        },
        "ts2extams__Workflow_Link__c": {
          name: 'ts2extams__Workflow_Link__c',
          type: 'string'
        },
        "Approval_Status__c": {
          name: 'Approval_Status__c',
          type: 'string'
        },
        "Approver__c": {
          name: 'Approver__c',
          type: 'string'
        },
        "Backfill__c": {
          name: 'Backfill__c',
          type: 'string'
        },
        "Backup_PM__c": {
          name: 'Backup_PM__c',
          type: 'string'
        },
        "ts2extams__Basic_Qualifications__c": {
          name: 'ts2extams__Basic_Qualifications__c',
          type: 'string'
        },
        "BGV_Requirements__c": {
          name: 'BGV_Requirements__c',
          type: 'string'
        },
        "ts2__Bonus__c": {
          name: 'ts2__Bonus__c',
          type: 'string'
        },
        "ts2extams__BQSearch__c": {
          name: 'ts2extams__BQSearch__c',
          type: 'string'
        },
        "ts2__Burden_Pct__c": {
          name: 'ts2__Burden_Pct__c',
          type: 'number'
        },
        "ts2__Business_Unit__c": {
          name: 'ts2__Business_Unit__c',
          type: 'string'
        },
        "ts2__CDate__c": {
          name: 'ts2__CDate__c',
          type: 'Date'
        },
        "Certifications__c": {
          name: 'Certifications__c',
          type: 'string'
        },
        "ts2__Chance_this_search_will_be_filled__c": {
          name: 'ts2__Chance_this_search_will_be_filled__c',
          type: 'number'
        },
        "ts2__Chatter_Hash_Tags__c": {
          name: 'ts2__Chatter_Hash_Tags__c',
          type: 'string'
        },
        "Child_Job_Openings__c": {
          name: 'Child_Job_Openings__c',
          type: 'number'
        },
        "ts2__JMCity__c": {
          name: 'ts2__JMCity__c',
          type: 'string'
        },
        "ts2__Clearance__c": {
          name: 'ts2__Clearance__c',
          type: 'boolean'
        },
        "ts2__Clearance_Type__c": {
          name: 'ts2__Clearance_Type__c',
          type: 'string'
        },
        "ts2__Account__c": {
          name: 'ts2__Account__c',
          type: 'string'
        },
        "ts2__Client_Address__c": {
          name: 'ts2__Client_Address__c',
          type: 'string'
        },
        "ts2__Client_Phone__c": {
          name: 'ts2__Client_Phone__c',
          type: 'string'
        },
        "ts2__Closed_Reason__c": {
          name: 'ts2__Closed_Reason__c',
          type: 'string'
        },
        "ts2__Close_Pct__c": {
          name: 'ts2__Close_Pct__c',
          type: 'number'
        },
        "Comment__c": {
          name: 'Comment__c',
          type: 'string'
        },
        "Confidential__c": {
          name: 'Confidential__c',
          type: 'boolean'
        },
        "Confirmed_Start_Date_HR_Head__c": {
          name: 'Confirmed_Start_Date_HR_Head__c',
          type: 'Date'
        },
        "ts2__Contact__c": {
          name: 'ts2__Contact__c',
          type: 'string'
        },
        "ts2__Contact_Email__c": {
          name: 'ts2__Contact_Email__c',
          type: 'string'
        },
        "ts2__Contact_Phone__c": {
          name: 'ts2__Contact_Phone__c',
          type: 'string'
        },
        "ts2__Contract__c": {
          name: 'ts2__Contract__c',
          type: 'string'
        },
        "Contract_Type__c": {
          name: 'Contract_Type__c',
          type: 'string'
        },
        "ts2__Conversion_Date__c": {
          name: 'ts2__Conversion_Date__c',
          type: 'Date'
        },
        "ts2__Conversion_Fee__c": {
          name: 'ts2__Conversion_Fee__c',
          type: 'string'
        },
        "ts2__Conversion_Fee_Pct__c": {
          name: 'ts2__Conversion_Fee_Pct__c',
          type: 'number'
        },
        "ts2__JMCountry__c": {
          name: 'ts2__JMCountry__c',
          type: 'string'
        },
        "Country_Code__c": {
          name: 'Country_Code__c',
          type: 'string'
        },
        "Country_List__c": {
          name: 'Country_List__c',
          type: 'string'
        },
        "CR__c": {
          name: 'CR__c',
          type: 'string'
        },
        "CreatedBy__c": {
          name: 'CreatedBy__c',
          type: 'string'
        },
        "Currency__c": {
          name: 'Currency__c',
          type: 'string'
        },
        "Customer_Account__c": {
          name: 'Customer_Account__c',
          type: 'string'
        },
        "Customer_Price__c": {
          name: 'Customer_Price__c',
          type: 'number'
        },
        "ts2__Date_Filled__c": {
          name: 'ts2__Date_Filled__c',
          type: 'Date'
        },
        "ts2__Date_Posted__c": {
          name: 'ts2__Date_Posted__c',
          type: 'Date'
        },
        "ts2__Days_Gauge__c": {
          name: 'ts2__Days_Gauge__c',
          type: 'string'
        },
        "ts2__Days_Open__c": {
          name: 'ts2__Days_Open__c',
          type: 'string'
        },
        "Days_Open_Calculated__c": {
          name: 'Days_Open_Calculated__c',
          type: 'string'
        },
        "ts2__Days_to_Fill__c": {
          name: 'ts2__Days_to_Fill__c',
          type: 'string'
        },
        "ts2__Department__c": {
          name: 'ts2__Department__c',
          type: 'string'
        },
        "Department__c": {
          name: 'Department__c',
          type: 'string'
        },
        "ts2__Description__c": {
          name: 'ts2__Description__c',
          type: 'string'
        },
        "ts2__Description_ID__c": {
          name: 'ts2__Description_ID__c',
          type: 'string'
        },
        "ts2__Apply_with_Indeed__c": {
          name: 'ts2__Apply_with_Indeed__c',
          type: 'boolean'
        },
        "ts2__Disable_EEO__c": {
          name: 'ts2__Disable_EEO__c',
          type: 'boolean'
        },
        "Drug_Test_Requirement__c": {
          name: 'Drug_Test_Requirement__c',
          type: 'string'
        },
        "Educations__c": {
          name: 'Educations__c',
          type: 'string'
        },
        "Employment_Type__c": {
          name: 'Employment_Type__c',
          type: 'string'
        },
        "ts2__Employment_Type__c": {
          name: 'ts2__Employment_Type__c',
          type: 'string'
        },
        "ts2__Estimated_End_Date__c": {
          name: 'ts2__Estimated_End_Date__c',
          type: 'Date'
        },
        "ts2__Estimated_Fee__c": {
          name: 'ts2__Estimated_Fee__c',
          type: 'string'
        },
        "ts2__Estimated_Gross_Margin__c": {
          name: 'ts2__Estimated_Gross_Margin__c',
          type: 'string'
        },
        "ts2__Estimated_Hours__c": {
          name: 'ts2__Estimated_Hours__c',
          type: 'string'
        },
        "ts2__Estimated_Start_Date__c": {
          name: 'ts2__Estimated_Start_Date__c',
          type: 'Date'
        },
        "ts2__User__c": {
          name: 'ts2__User__c',
          type: 'string'
        },
        "Executive_Approver__c": {
          name: 'Executive_Approver__c',
          type: 'string'
        },
        "Expand_GEO_Search_Radius__c": {
          name: 'Expand_GEO_Search_Radius__c',
          type: 'boolean'
        },
        "Expected_Start_Date_Recruitment_Head__c": {
          name: 'Expected_Start_Date_Recruitment_Head__c',
          type: 'Date'
        },
        "ts2__Expiration_Date__c": {
          name: 'ts2__Expiration_Date__c',
          type: 'Date'
        },
        "ts2__Expires__c": {
          name: 'ts2__Expires__c',
          type: 'boolean'
        },
        "ts2__Facility__c": {
          name: 'ts2__Facility__c',
          type: 'string'
        },
        "ts2__Fee_Pct__c": {
          name: 'ts2__Fee_Pct__c',
          type: 'number'
        },
        "ts2__First_Year_Salary__c": {
          name: 'ts2__First_Year_Salary__c',
          type: 'number'
        },
        "ts2__Forecasted_Revenue__c": {
          name: 'ts2__Forecasted_Revenue__c',
          type: 'string'
        },
        "ts2__FTE__c": {
          name: 'ts2__FTE__c',
          type: 'number'
        },
        "MetroCodes__c": {
          name: 'MetroCodes__c',
          type: 'string'
        },
        "Good_to_go_skills__c": {
          name: 'Good_to_go_skills__c',
          type: 'string'
        },
        "ts2__Manager__c": {
          name: 'ts2__Manager__c',
          type: 'string'
        },
        "Hiring_Manager__c": {
          name: 'Hiring_Manager__c',
          type: 'string'
        },
        "Increase_target_cost__c": {
          name: 'Increase_target_cost__c',
          type: 'boolean'
        },
        "ts2__Internal_Notes__c": {
          name: 'ts2__Internal_Notes__c',
          type: 'string'
        },
        "ts2__Internal_Passing_Score__c": {
          name: 'ts2__Internal_Passing_Score__c',
          type: 'number'
        },
        "ts2__Internal_Question_Set__c": {
          name: 'ts2__Internal_Question_Set__c',
          type: 'string'
        },
        "ts2__Interview_Count__c": {
          name: 'ts2__Interview_Count__c',
          type: 'number'
        },
        "Iron_email_id_required__c": {
          name: 'Iron_email_id_required__c',
          type: 'boolean'
        },
        "ts2__Job_Advertisement__c": {
          name: 'ts2__Job_Advertisement__c',
          type: 'string'
        },
        "Job_Assignment_Level__c": {
          name: 'Job_Assignment_Level__c',
          type: 'string'
        },
        "Job_board_database_50_miles__c": {
          name: 'Job_board_database_50_miles__c',
          type: 'number'
        },
        "Job_Creation_Reason__c": {
          name: 'Job_Creation_Reason__c',
          type: 'string'
        },
        "Job_Creation_Reason_Formula__c": {
          name: 'Job_Creation_Reason_Formula__c',
          type: 'string'
        },
        "Job_Creation_Reason_Replac__c": {
          name: 'Job_Creation_Reason_Replac__c',
          type: 'string'
        },
        "ts2__Job_Description__c": {
          name: 'ts2__Job_Description__c',
          type: 'string'
        },
        "ts2__Job_Family__c": {
          name: 'ts2__Job_Family__c',
          type: 'string'
        },
        "ts2__Job_Function__c": {
          name: 'ts2__Job_Function__c',
          type: 'string'
        },
        "ts2__JobNotificationsSummary__c": {
          name: 'ts2__JobNotificationsSummary__c',
          type: 'number'
        },
        "ts2__Job_Number__c": {
          name: 'ts2__Job_Number__c',
          type: 'string'
        },
        "jsjo__Job_Opportunity__c": {
          name: 'jsjo__Job_Opportunity__c',
          type: 'string'
        },
        "Location6__c": {
          name: 'Location6__c',
          type: 'string'
        },
        "ts2__Job_Rank__c": {
          name: 'ts2__Job_Rank__c',
          type: 'string'
        },
        "jobsite__c": {
          name: 'jobsite__c',
          type: 'string'
        },
        "Jobsite_Project__c": {
          name: 'Jobsite_Project__c',
          type: 'string'
        },
        "Job_Title__c": {
          name: 'Job_Title__c',
          type: 'string'
        },
        "ts2__Job_Title_Number__c": {
          name: 'ts2__Job_Title_Number__c',
          type: 'string'
        },
        "ts2__KPI_Count__c": {
          name: 'ts2__KPI_Count__c',
          type: 'string'
        },
        "Language_Preference__c": {
          name: 'Language_Preference__c',
          type: 'string'
        },
        "LastModifiedBy__c": {
          name: 'LastModifiedBy__c',
          type: 'string'
        },
        "ts2__Last_Modified_Date_for_S_Control__c": {
          name: 'ts2__Last_Modified_Date_for_S_Control__c',
          type: 'Date'
        },
        "ts2__Legacy_JobID__c": {
          name: 'ts2__Legacy_JobID__c',
          type: 'string'
        },
        "ts2__Localized_Job_Description__c": {
          name: 'ts2__Localized_Job_Description__c',
          type: 'string'
        },
        "ts2__Localized_Job_Title__c": {
          name: 'ts2__Localized_Job_Title__c',
          type: 'string'
        },
        "ts2__JMLocation__c": {
          name: 'ts2__JMLocation__c',
          type: 'string'
        },
        "ts2__Location__c": {
          name: 'ts2__Location__c',
          type: 'string'
        },
        "Location_List_New__c": {
          name: 'Location_List_New__c',
          type: 'string'
        },
        "Location_List__c": {
          name: 'Location_List__c',
          type: 'string'
        },
        "ts2extams__Locked__c": {
          name: 'ts2extams__Locked__c',
          type: 'boolean'
        },
        "ts2extams__Locked_Date__c": {
          name: 'ts2extams__Locked_Date__c',
          type: 'Date'
        },
        "ts2extams__Locked_User__c": {
          name: 'ts2extams__Locked_User__c',
          type: 'string'
        },
        "Mandatory_skills__c": {
          name: 'Mandatory_skills__c',
          type: 'string'
        },
        "Margin__c": {
          name: 'Margin__c',
          type: 'number'
        },
        "ts2__Markup__c": {
          name: 'ts2__Markup__c',
          type: 'boolean'
        },
        "ts2__Markup_Bill__c": {
          name: 'ts2__Markup_Bill__c',
          type: 'string'
        },
        "ts2__Markup_Pay__c": {
          name: 'ts2__Markup_Pay__c',
          type: 'number'
        },
        "ts2__Markup_Pct__c": {
          name: 'ts2__Markup_Pct__c',
          type: 'number'
        },
        "Project_Name__c": {
          name: 'Project_Name__c',
          type: 'string'
        },
        "ts2__Max_Bill_Rate__c": {
          name: 'ts2__Max_Bill_Rate__c',
          type: 'number'
        },
        "ts2__Max_Conversion_Salary__c": {
          name: 'ts2__Max_Conversion_Salary__c',
          type: 'number'
        },
        "ts2__Max_Pay_Rate__c": {
          name: 'ts2__Max_Pay_Rate__c',
          type: 'number'
        },
        "ts2__Max_Salary__c": {
          name: 'ts2__Max_Salary__c',
          type: 'number'
        },
        "ts2__JMMetro__c": {
          name: 'ts2__JMMetro__c',
          type: 'string'
        },
        "ts2__Metro__c": {
          name: 'ts2__Metro__c',
          type: 'string'
        },
        "ts2__Min_Bill_Rate__c": {
          name: 'ts2__Min_Bill_Rate__c',
          type: 'number'
        },
        "ts2__Min_Conversion_Salary__c": {
          name: 'ts2__Min_Conversion_Salary__c',
          type: 'number'
        },
        "ts2__Min_Pay_Rate__c": {
          name: 'ts2__Min_Pay_Rate__c',
          type: 'number'
        },
        "ts2__Min_Salary__c": {
          name: 'ts2__Min_Salary__c',
          type: 'number'
        },
        "Negative_Keywords__c": {
          name: 'Negative_Keywords__c',
          type: 'string'
        },
        "ts2__Offer_Count__c": {
          name: 'ts2__Offer_Count__c',
          type: 'number'
        },
        "Offer_Count__c": {
          name: 'Offer_Count__c',
          type: 'number'
        },
        "ts2__Openings__c": {
          name: 'ts2__Openings__c',
          type: 'number'
        },
        "ts2__Order__c": {
          name: 'ts2__Order__c',
          type: 'string'
        },
        "Other_Job_Creation_Reason__c": {
          name: 'Other_Job_Creation_Reason__c',
          type: 'string'
        },
        "Parent_Job_Order__c": {
          name: 'Parent_Job_Order__c',
          type: 'string'
        },
        "ts2__Passing_Score__c": {
          name: 'ts2__Passing_Score__c',
          type: 'number'
        },
        "ts2__referral_bonus__c": {
          name: 'ts2__referral_bonus__c',
          type: 'string'
        },
        "ts2__Percentage_Fee__c": {
          name: 'ts2__Percentage_Fee__c',
          type: 'number'
        },
        "ts2__Per_Diem__c": {
          name: 'ts2__Per_Diem__c',
          type: 'boolean'
        },
        "ts2__Per_Diem_Bill_Rate__c": {
          name: 'ts2__Per_Diem_Bill_Rate__c',
          type: 'number'
        },
        "ts2__Per_Diem_Diff_Pct__c": {
          name: 'ts2__Per_Diem_Diff_Pct__c',
          type: 'number'
        },
        "ts2__Per_Diem_Pay_Rate__c": {
          name: 'ts2__Per_Diem_Pay_Rate__c',
          type: 'number'
        },
        "ts2__Placement_Count__c": {
          name: 'ts2__Placement_Count__c',
          type: 'number'
        },
        "Placement_Count_New__c": {
          name: 'Placement_Count_New__c',
          type: 'number'
        },
        "Please_Specify_BGV_and_Drug_test_Require__c": {
          name: 'Please_Specify_BGV_and_Drug_test_Require__c',
          type: 'string'
        },
        "Please_Specify_Local_Language__c": {
          name: 'Please_Specify_Local_Language__c',
          type: 'string'
        },
        "PgMo_Name__c": {
          name: 'PgMo_Name__c',
          type: 'string'
        },
        "Population_Radius_25_Miles__c": {
          name: 'Population_Radius_25_Miles__c',
          type: 'number'
        },
        "Population_Radius_50_Miles__c": {
          name: 'Population_Radius_50_Miles__c',
          type: 'number'
        },
        "ts2__JMPostalCode__c": {
          name: 'ts2__JMPostalCode__c',
          type: 'string'
        },
        "ts2__Posted_to_Prospects__c": {
          name: 'ts2__Posted_to_Prospects__c',
          type: 'boolean'
        },
        "ts2__Posting_Status__c": {
          name: 'ts2__Posting_Status__c',
          type: 'string'
        },
        "ts2__Post_Job__c": {
          name: 'ts2__Post_Job__c',
          type: 'boolean'
        },
        "ts2__Post_to_Chatter__c": {
          name: 'ts2__Post_to_Chatter__c',
          type: 'boolean'
        },
        "Post_to_DICE__c": {
          name: 'Post_to_DICE__c',
          type: 'boolean'
        },
        "ts2__Recruiter__c": {
          name: 'ts2__Recruiter__c',
          type: 'string'
        },
        "Primary_Recruiter__c": {
          name: 'Primary_Recruiter__c',
          type: 'string'
        },
        "ts2__Priority__c": {
          name: 'ts2__Priority__c',
          type: 'string'
        },
        "Product__c": {
          name: 'Product__c',
          type: 'string'
        },
        "ts2__Project__c": {
          name: 'ts2__Project__c',
          type: 'string'
        },
        "ts2__Question_Set__c": {
          name: 'ts2__Question_Set__c',
          type: 'string'
        },
        "ts2__RadiusDistance__c": {
          name: 'ts2__RadiusDistance__c',
          type: 'number'
        },
        "ts2__RadiusDistanceUnit__c": {
          name: 'ts2__RadiusDistanceUnit__c',
          type: 'string'
        },
        "Reassign_job_to_new_recruitment_manager__c": {
          name: 'Reassign_job_to_new_recruitment_manager__c',
          type: 'boolean'
        },
        "Region__c": {
          name: 'Region__c',
          type: 'string'
        },
        "Relocate_Candidate_from_nearby_location__c": {
          name: 'Relocate_Candidate_from_nearby_location__c',
          type: 'boolean'
        },
        "ts2__Remaining__c": {
          name: 'ts2__Remaining__c',
          type: 'string'
        },
        "Remaining_Actual__c": {
          name: 'Remaining_Actual__c',
          type: 'string'
        },
        "Replacement_of_Worker__c": {
          name: 'Replacement_of_Worker__c',
          type: 'string'
        },
        "Requisition_Issues__c": {
          name: 'Requisition_Issues__c',
          type: 'string'
        },
        "Review_Term__c": {
          name: 'Review_Term__c',
          type: 'string'
        },
        "Salary_Range_Max__c": {
          name: 'Salary_Range_Max__c',
          type: 'number'
        },
        "Salary_Range_Min__c": {
          name: 'Salary_Range_Min__c',
          type: 'number'
        },
        "SAM_Name__c": {
          name: 'SAM_Name__c',
          type: 'string'
        },
        "ts2__Screening_Requirements__c": {
          name: 'ts2__Screening_Requirements__c',
          type: 'string'
        },
        "ts2__Search__c": {
          name: 'ts2__Search__c',
          type: 'number'
        },
        "ts2__Search_Type__c": {
          name: 'ts2__Search_Type__c',
          type: 'string'
        },
        "ts2__Secondary_Recruiter__c": {
          name: 'ts2__Secondary_Recruiter__c',
          type: 'string'
        },
        "Secondary_Recruiter__c": {
          name: 'Secondary_Recruiter__c',
          type: 'string'
        },
        "ts2__Shared__c": {
          name: 'ts2__Shared__c',
          type: 'boolean'
        },
        "ts2__Shift__c": {
          name: 'ts2__Shift__c',
          type: 'string'
        },
        "ts2__Short_Description__c": {
          name: 'ts2__Short_Description__c',
          type: 'string'
        },
        "ts2__Job_Tag__c": {
          name: 'ts2__Job_Tag__c',
          type: 'string'
        },
        "ts2__Skill_Codes__c": {
          name: 'ts2__Skill_Codes__c',
          type: 'string'
        },
        "SOW__c": {
          name: 'SOW__c',
          type: 'string'
        },
        "ts2__Specific_Requirements__c": {
          name: 'ts2__Specific_Requirements__c',
          type: 'string'
        },
        "ts2__Stage__c": {
          name: 'ts2__Stage__c',
          type: 'string'
        },
        "ts2__Start_Date__c": {
          name: 'ts2__Start_Date__c',
          type: 'string'
        },
        "ts2__JMState__c": {
          name: 'ts2__JMState__c',
          type: 'string'
        },
        "ts2__Status__c": {
          name: 'ts2__Status__c',
          type: 'string'
        },
        "ts2__JMStreet__c": {
          name: 'ts2__JMStreet__c',
          type: 'string'
        },
        "ts2__Submittal_Count__c": {
          name: 'ts2__Submittal_Count__c',
          type: 'number'
        },
        "Talent_Level__c": {
          name: 'Talent_Level__c',
          type: 'string'
        },
        "talenttype__c": {
          name: 'talenttype__c',
          type: 'string'
        },
        "ts2__Target_Base_Salary__c": {
          name: 'ts2__Target_Base_Salary__c',
          type: 'number'
        },
        "Target_Cost__c": {
          name: 'Target_Cost__c',
          type: 'string'
        },
        "Term__c": {
          name: 'Term__c',
          type: 'string'
        },
        "ts2__Term_Months__c": {
          name: 'ts2__Term_Months__c',
          type: 'string'
        },
        "Tertiary_Recruiter__c": {
          name: 'Tertiary_Recruiter__c',
          type: 'string'
        },
        "ts2__Text_Description__c": {
          name: 'ts2__Text_Description__c',
          type: 'string'
        },
        "ts2__Total_KPI_Count__c": {
          name: 'ts2__Total_KPI_Count__c',
          type: 'string'
        },
        "ts2__Travel__c": {
          name: 'ts2__Travel__c',
          type: 'number'
        },
        "Type_of_Requirement__c": {
          name: 'Type_of_Requirement__c',
          type: 'string'
        },
        "ts2__Waitlisted__c": {
          name: 'ts2__Waitlisted__c',
          type: 'boolean'
        },
        "ts2__Work_Class__c": {
          name: 'ts2__Work_Class__c',
          type: 'string'
        },
        "ts2__TEST1__c": {
          name: 'ts2__TEST1__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
