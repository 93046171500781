import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-job-schedule',
  templateUrl: './job-schedule.component.html',
  styleUrls: ['./job-schedule.component.css']
})
export class JobScheduleComponent implements OnInit {

  jobScheduleData = [];
  @Input()
  set data(e) {
    if (e) {
      this.jobScheduleData = Array.isArray(e) ? e : [e];
    }
  }
  
  constructor() { }

  ngOnInit() {
  }

}
