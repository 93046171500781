import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServiceManagerSummaryComponent } from './service-manager-summary.component';
import { StatusComponent } from './status/status.component';
import { JobSiteComponent } from './job-site/job-site.component';
import { DeliverablesComponent } from './deliverables/deliverables.component';
import { InstructionsComponent } from './instructions/instructions.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ServiceManagerSummaryComponent,
    StatusComponent,
    DeliverablesComponent,
    InstructionsComponent,
    StatusComponent,
    JobSiteComponent
  ],
  exports: [
    ServiceManagerSummaryComponent,
    StatusComponent,
    DeliverablesComponent,
    InstructionsComponent,
    StatusComponent,
    JobSiteComponent
  ]
})
export class ServiceManageSummaryModule { }
