import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';

import { PreloaderService } from '../../../../../services/preloader.service';
import { AppStateService } from './../../../../../services/app-state.service';
import { CommonService } from './../../../../../services/common.service';

import { JobApi, JobsiteContactApi } from '../../../../../sdk';
import { BitlyServiceApi } from '../../../../../sdk/services/custom/BitlyService';

import { environment } from '../../../../../../environments/environment';
import { isNullOrUndefined } from 'util';

@Component({
    selector: 'job-detail-summary',
    templateUrl: './job-detail-summary.component.html',
    styleUrls: ['./job-detail-summary.component.css']
})

export class JobDetailSummaryComponent implements OnInit, OnDestroy {

    @Input() jobId?: string;
    @Input() jobObj?: any;

    @Input() timeZone: any;
    job: any;
    errorMessage = '';
    tabsStatus: any = {
        overview: true,
        information: true,
        jInsturction: true,
        dispatchDesk: true,
        dispatchDeskWorker: true,
        servicParts: true,
        newEquipment: true,
        technicalEscalationCustomer: true,
        technicalEscalationIron: true,
        paymentTerms: true,
        jobInstructions: true
    };
    private _statusCss = {
        delivered: 'delivered',
        completed: 'completed',
        started: 'started',
        resolved: 'resolved',
        closed: 'closed',
        cancelled: 'cancelled',
        declined: 'declined',
        invoiced: 'invoiced',
        paid: 'paid',
        invited: 'invited',
        assigned: 'assigned',
        accepted: 'accepted',
        new: 'new',
        'in-progress': 'in-progress',
    };
    baseUrl: any;
    accessType: any;
    userRole: any;
    roleSub: Subscription;

    constructor(
        private _preloaderService: PreloaderService,
        private _jobApi: JobApi,
        private _commonService: CommonService,
        private _appState: AppStateService,
        private _bitlyService: BitlyServiceApi,
        private _jobsiteContactApi: JobsiteContactApi
    ) { }
    ngOnInit() { }
    ngOnChanges() {
        this.initialize();
    }


    ngOnDestroy() {
        if (this.roleSub) {
            this.roleSub.unsubscribe();
        }
    }

    initialize() {
        this._commonService.saveCurrentPageAnalytics('Job Manager Console - Details', 'Summary');
        if (this.jobId) {
            this.loadData(this.jobId);
        } else if (this.jobObj) {
            this.prepareJobResult(JSON.parse(this.jobObj));
        } else {
            this.errorMessage = 'No details to display.';
        }
    }

    loadData(jobId) {
        this._preloaderService.showPreloader();
        this.errorMessage = '';
        const reqObj = {
            'fields': ['sfdcId', 'id', 'Dispatch_Service_Resolution_Status__c', 'Customer_Service_Type_From_Program__c',
                'Service_Dispatch_SLA_Priority_FrmProgram__c', 'Max_Hours_Units__c', 'csum__c', 'CKSW_BASE__Description__c',
                'Special_Instruction_from_PMS_Case_Auto__c', 'Asset_Model__c', 'Asset_Serial__c', 'Custom_work_order_instructions__c',
                'Jobsite_Name__c', 'Partner_Case_Number__c', 'Customer_Contact_Email__c', 'Customer_Contact_Phone__c',
                'Does_this_Job_require_Service_Parts__c', 'Service_Parts_Local_Pickup_Required__c', 'Technical_Level__c',
                'Does_this_Job_require_New_Equip_Hardware__c', 'Equipment_Local_Pickup_Required__c', 'Case_Number__c', 'SKU__c',
                'Equipment_Delivery_Date_ETA__c', 'Equipment_Delivery_Time_ETA_Hours__c', 'Case__c', 'Partner_Case_Error_Description__c',
                'Jobsite_Contact_SDesk_Name_phone_email__c', 'Iron_Case__c', 'Service_Dispatch_SLA_Priority__c', 'Equipment_Tracking__c',
                'Jobsite_Contact_Technical_Escalation__c', 'SOW_Work_Order__c', 'Job_Instruction_Details_Long__c', 'Work_Order__c',
                'RecordTypeId', 'Dispatch_Worker_Name__c', 'Iron_Job_num__c', 'Jobsite_Contact_TEsc_Name_phone_email__c', 'Project__c'
            ],
            'where': { 'sfdcId': jobId },
            'include': [
                {
                    'relation': 'appointment',
                    'scope': {
                        'fields': ['sfdcId', 'Worker_Arrival_DateTime_Cust_Requested__c',
                            'Customer_Appointment_DateTime_Scheduled__c', 'Worker_Arrival_DateTime_Scheduled__c']
                    }
                },
                {
                    'relation': 'worker',
                    'scope': {
                        'fields': ['sfdcId', 'Name']
                    }
                },
                {
                    'relation': 'product',
                    'scope': {
                        'fields': ['sfdcId', 'Name']
                    }
                },
                {
                    'relation': 'project',
                    'scope': {
                        'fields': ['sfdcId', 'Project__c', 'Name']
                    }
                },
                {
                    'relation': 'purchaseOrder',
                    'scope': {
                        'fields': ['sfdcId', 'Service_Dispatch__c', 'Work_Order_num__c', 'WorkOrderNumber']
                    }
                },
                {
                    'relation': 'case',
                    'scope': {
                        'fields': ['sfdcId', 'Job_Order__c', 'ParentId'],
                        'include': [
                            {
                                'relation': 'workOrder',
                                'scope': {
                                    'fields': ['sfdcId', 'Name']
                                }
                            },
                            {
                                'relation': 'case',
                                'scope': {
                                    'fields': ['sfdcId', 'CaseNumber']
                                }
                            }
                        ]
                    }
                }
            ]
        };
        this._jobApi.getJobDetailsById(reqObj).subscribe(
            result => {
                this.prepareJobResult(result);
            },
            error => {
                this.errorMessage = error.message;
                this._preloaderService.hidePreloader();
            }
        );
    }

    prepareJobResult(result) {
        if (result && Object.keys(result).length > 0) {
            this.job = result;

            this.job.publicUrl = environment.communityUrl + '/public/jobs/' + this.job.sfdcId;
            this._bitlyService.getBitlyUrl(this.job.publicUrl).map(response => JSON.parse(response)).subscribe(res => {
                this.job.shortUrl = (res && res.data && res.data['url']) ? res.data['url'] : this.job.publicUrl;
            });
            this.job.Custom_work_order_instructions__c = (this.job.Custom_work_order_instructions__c) ?
                this.job.Custom_work_order_instructions__c : '';
            this.job.Job_Instruction_Details_Long__c = (this.job.Job_Instruction_Details_Long__c) ?
                this.job.Job_Instruction_Details_Long__c.replace(/null/g, '&nbsp;') : '';

            if (this.job.appointment) {
                const timeFormat = 'L, LT z';
                this.job.appointment.Worker_Arrival_DateTime_Cust_Requested__c =
                    this._commonService.dateFormate(this.job.appointment.Worker_Arrival_DateTime_Cust_Requested__c,
                        this.timeZone, timeFormat);
                this.job.appointment.Customer_Appointment_DateTime_Scheduled__c =
                    this._commonService.dateFormate(this.job.appointment.Customer_Appointment_DateTime_Scheduled__c,
                        this.timeZone, timeFormat);
                this.job.appointment.Worker_Arrival_DateTime_Scheduled__c =
                    this._commonService.dateFormate(this.job.appointment.Worker_Arrival_DateTime_Scheduled__c,
                        this.timeZone, timeFormat);
            }
            this.job.CaseNumber = (this.job && this.job.case && this.job.case.case && this.job.case.case.CaseNumber) ?
                this.job.case.case.CaseNumber : '';

            // to show Service Contact Details and Technical Escalation Manager when it is null
            if (this.job && this.job.jobsite && this.job.jobsite.sfdcId) {
                const _filter = {
                    fields: ['id', 'sfdcId', 'Contact__c'],
                    where: {
                        Jobsite__c: this.job.jobsite.sfdcId,
                    },
                    include: {
                        relation: 'contact',
                        scope: {
                            fields: ['id', 'Name', 'Phone', 'Email']
                        }
                    }
                };
                if (this.job.Jobsite_Contact_SDesk_Name_phone_email__c === '') {
                    _filter['where']['Jobsite_Contact_Type__c'] = 'Customer Site (Service Desk)';
                    this._jobsiteContactApi.find(_filter).subscribe(
                        sConatct => {
                            this.job.Jobsite_Contact_SDesk_Name_phone_email__c =
                                sConatct[0] && sConatct[0]['contact']
                                    ? sConatct[0]['contact']['Name'] +
                                    '; ' +
                                    sConatct[0]['contact']['Phone'] +
                                    '; ' +
                                    sConatct[0]['contact']['Email']
                                    : '';
                        },
                        error => { }
                    );
                }
                if (this.job.Jobsite_Contact_TEsc_Name_phone_email__c === '') {
                    _filter['where']['Jobsite_Contact_Type__c'] = 'Customer Site (Technical Escalation)';
                    this._jobsiteContactApi.find(_filter).subscribe(
                        tSConatct => {
                            this.job.Jobsite_Contact_TEsc_Name_phone_email__c =
                                tSConatct[0] && tSConatct[0]['contact']
                                    ? tSConatct[0]['contact']['Name'] +
                                    '; ' +
                                    tSConatct[0]['contact']['Phone'] +
                                    '; ' +
                                    tSConatct[0]['contact']['Email']
                                    : '';
                        },
                        error => { }
                    );
                }
            }

        } else {
            this.errorMessage = 'No details to display.';
        }
        this._preloaderService.hidePreloader();
    }
    copyToClipboard(element) {
        element.select();
        document.execCommand('copy');
        element.setSelectionRange(0, 0);
    }
    // get serial number
    getSrno(no: number) {
        let number: number = this.job.RecordTypeId == '0121a000000F1eoAAC' ? no + 2 : no;
        return number + '.';
    }
    getPriorityClass(priority: string = "") {
        priority = (priority && priority.length && priority.trim()) ? priority.trim().toLowerCase() : '';
        if (this._statusCss.hasOwnProperty(priority)) {
            return this._statusCss[priority];
        } else {
            return 'default-priority';
        }
    }
    getContactDetailsFromString(input: string, key: number) {
        if (isNullOrUndefined(input)) {
            return null;
        } else {
            const detials = input.split(';');
            return typeof detials[key] !== 'undefined' ? detials[key] : 'N/A';
        }
    }
}
