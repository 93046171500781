import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { mielstoneTaskValues } from '../../../models/static-list-data.service'

@Component({
  selector: 'app-milestone-task-lookup',
  templateUrl: './milestone-task-lookup.component.html',
  styleUrls: ['./milestone-task-lookup.component.css']
})
export class MilestoneTaskLookupComponent implements OnInit {

  
  milestonTaskList = [];
  milestonTaskSelected = [];
  _isMultipleSelected = true;
  whereObj = {};

  @Input() set consoleType(e) {
    if (e && (e[0] === "ProjectManager" || e[0] === "TaskCase")) {
      this._isMultipleSelected = false;
    }
  }
  @Input() set resetData(isClear) {
    if (isClear) {
      this.milestonTaskSelected = [];
    }
  }

  @Input()
  set setMilestoneTaskObj(params) {
    this.milestonTaskList = [];
    // this.clearTaskStatus();
    this.whereObj = this.whereObj && Object.keys(this.whereObj).length ? this.whereObj : {};
  }

  @Output() getMilestoneTaskObj: EventEmitter<any> = new EventEmitter;
 

  constructor() { }

  ngOnInit() {
    this.milestonTaskList = mielstoneTaskValues;
    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
    this.milestonTaskSelected = (preselected && preselected['milestoneTask']) ? preselected['milestoneTask'] : [];
    this.onMilestoneTaskChange();
  }
  onMilestoneTaskChange() {
    this.getMilestoneTaskObj.emit(this.milestonTaskSelected);
  }
  clearMilestoneTask() {
    this.milestonTaskSelected = [];
    this.getMilestoneTaskObj.emit([]);
  }

}
