import { Injectable } from "@angular/core";
import { AppStateService } from "shared/services/app-state.service";
import { Adapter } from "./adapter";
import { Member, MemberAdaptor } from "./member";
import { ReplyMessage, ReplyMessageAdaptor } from "./reply-message";
import { Document, DocumentAdaptor } from "./document";


export class Message {
    constructor(
        public conversation_id: string = "",
        public message: string = "",
        public message_id: number = null,
        public reply: ReplyMessage = new ReplyMessage(),
        public sent_at: string = "",
        public sent_by: Member = new Member(),
        public type: string = "",
        public initatedByME: boolean = false,
        public isFile: boolean = false,
        public isForward: boolean = false,
        public document: Document = new Document(),
        public isRead: boolean = false,
        public isMsgRead: boolean = false,
        public reply_id: string = "",
        public priority: string = "",
        public documentId: number = null,
        public tagUsers: string = "",
        public disableDeleteEveryone: boolean = false
    ) { }
}

@Injectable({
    providedIn: "root"
})

export class MessageAdaptor implements Adapter<Message> {
    private _userData: any;
    private _mySfdcId: string = '';
    private _isRead: boolean = false;
    private _isMsgRead: boolean;

    constructor(private _memberAdapter: MemberAdaptor, private _replyMessageAdapter: ReplyMessageAdaptor, private _appState: AppStateService) {
        const currentUser = JSON.parse(localStorage.getItem('appData'));
        if (currentUser && currentUser.user) {
            this._mySfdcId = currentUser.user.sfdcId;
        }
    }

    adapt(item: any): Message {
        // console.log('adapt ::: ', item);
        let _disableDeleteEveryone = false;
        if (Boolean(item.MessageRecipients)){
            this._isRead = item.MessageRecipients.every(recep => recep.isRead === true);
            _disableDeleteEveryone = item.MessageRecipients.some(recep => recep.isRead === true && recep.userId !== item.user.id);            
        }

        if (item.MessageRecipients && item.MessageRecipients.length) {
            const user = item.MessageRecipients.find(user => user && user.user && user.user.sfdcId === this._mySfdcId && user.isRead);
            this._isMsgRead = Boolean(user) ? user.isRead : false;
        }
        
        let message = new Message(
            item.channelId,
            item.message,
            item.id,
            (item.parentMessage ? this._replyMessageAdapter.adapt(item.parentMessage) : new ReplyMessage()),
            item.createdAt,
            (item.user ? this._memberAdapter.adapt(item.user) : new Member()),
            item.type ? item.type : 'SEND',
            (item.user && item.user.sfdcId && item.user.sfdcId === this._mySfdcId ? true : false),
            item.isFile,
            item.isForward,
            item.document ? item.document : '',
            this._isRead,
            this._isMsgRead,
            '',
            '',
            null,
            '',
            _disableDeleteEveryone
        )
        // console.log('message new >>> ', message);
        return message;
    }


    reverseAdapt(item: any): any {
        // console.log('item from send message reverse adapt >>> ', item);
        let Message = {
            message: item.message ? item.message : '',
            messageId: item.message_id ? item.message_id : null,
            parentMessageId: item.reply_id ? item.reply_id : null,
            channelId: item.conversation_id ? item.conversation_id : null,
            priority: 'STANDARD',
            type: item.type ? item.type : 'SEND',
            documentId: item.documentId ? item.documentId : null,
            tagUsers: item.tagUsers ? item.tagUsers : [],
            "source": "Web"
        }
        if(item.type && item.type === 'DELETE') {
            Message['deleteForMe'] = item.deleteForMe;
        }
        return Message;
    }

    readMessageAdapt(item: Message): any {
        let Message = {
            channelId: item.conversation_id ? item.conversation_id : '',
        }
        return Message;
    }
}