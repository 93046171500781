import { Component, OnInit, Input } from '@angular/core';
import { WorkerApi, UserAnalyticsApi } from 'shared/sdk';
import { AppStateService } from 'shared/services/app-state.service';
import { CommonService } from 'shared/services/common.service';
import { PreloaderService } from 'shared/services/preloader.service';

@Component({
  selector: 'app-worker-info',
  templateUrl: './worker-info.component.html',
  styleUrls: ['./worker-info.component.css']
})
export class WorkerInfoComponent implements OnInit {
  worker: any;
  workerAnalytics: any;
  errorMsg: String = '';
  timeFormat = 'L, LT z';
  @Input() showVendor = false;
  @Input() jobId = '';
  // Pass worker Data. or just pass its sfdcId. 

  @Input()
  set workerData(w) {
    this.errorMsg = '';
    if (w && w.id) {
      this.worker = w;
      this.worker['talentTypeName'] = (this.worker['skilling'] && this.worker['skilling']['talentType']) ?
            this.worker['skilling']['talentType']['Name'] : '';
      if(w.sfdcId)
        this.getAnalyticsData(w.sfdcId);      
    } else if (w && w.filter) {
      this.loadWorkerData(w.filter);
    } else {
      this.errorMsg = 'No Worker Data Available';
    }
  }

  accessType: string;

  constructor(
    private _workerApi: WorkerApi,
    private _userAnalyticsApi: UserAnalyticsApi,
    private _preloaderService: PreloaderService,
    private _appState: AppStateService,
    private _commonService: CommonService
  ) { }

  ngOnInit() {
    this.accessType = this._appState.getAccessType();
    if (this.accessType === 'partner') {
      this._commonService.saveCurrentPageAnalytics('Service Manager Console(Order Details)', 'Worker Info');
    } else {
      this._commonService.saveCurrentPageAnalytics('Job Manager Console - Details', 'Worker Info');
    }
    // To check if view as PMS mode is activated.
    if (localStorage.getItem('viewAsInfo')) {
      const viewAsInfo = JSON.parse(localStorage.getItem('viewAsInfo'));
      const viewAsPartner = (viewAsInfo && viewAsInfo.viewAsType === 'Partner');
      this.accessType = (viewAsPartner) ? 'partner' : this.accessType;
    }
  }
  getAnalyticsData(workerId){
    let filter = {workerId: workerId }
    if(this.jobId){
      filter['jobId'] = this.jobId
    }
    this._userAnalyticsApi.getWorkerJobAccessData(filter).subscribe(
      res => {
        this.workerAnalytics = res;
        this.workerAnalytics['lastLogin'] = this.workerAnalytics.lastLogin ? this._commonService.dateFormate(this.workerAnalytics.lastLogin, '', this.timeFormat) : '';
        this.workerAnalytics['lastJobAccess'] = this.workerAnalytics.lastJobAccess ? this._commonService.dateFormate(this.workerAnalytics.lastJobAccess, '', this.timeFormat) : '';
        this.workerAnalytics['userRegistration'] = this.workerAnalytics.userRegistration ? this._commonService.dateFormate(this.workerAnalytics.userRegistration, '', this.timeFormat) : ''
        this.workerAnalytics['userDeviceRegistration'] = this.workerAnalytics.userDeviceRegistration ? this._commonService.dateFormate(this.workerAnalytics.userDeviceRegistration, '', this.timeFormat) : ''
      },
      err => {
        console.log(err)
      }
    )
  }

  loadWorkerData(whereFilter) {
    this._preloaderService.showPreloader();
    this._workerApi.findOne({
      where: whereFilter,
      fields: ['id', 'sfdcId', 'Primary_Worker_Skilling_Profile__c', 'Name', 'Worker_Type__c', 'Work_Phone_Number__c',
        'Dispatch_Worker_num__c', 'Worker_Rating__c'],
      include: [
        {
          relation: 'skilling',
          scope: {
            fields: ['sfdcId', 'Name', 'Primary_Talent_Type__c', 'Status__c'],
            include: {
              relation: 'talentType',
              scope: {
                'fields': ['sfdcId', 'Name']
              }
            }
          }
        }
      ]
    }).subscribe(
      res => {
        if (res) {
          this.worker = res;
          this.worker['talentTypeName'] = (this.worker['skilling'] && this.worker['skilling']['talentType']) ?
            this.worker['skilling']['talentType']['Name'] : '';
          if(this.jobId && this.worker.sfdcId){
            this.getAnalyticsData(this.worker.sfdcId)
          }
        }
        this._preloaderService.hidePreloader();
      },
      err => {
        this._preloaderService.hidePreloader();
        this.errorMsg = err.message;
      }
    );
  }

}