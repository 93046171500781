import { CustomDualListModule } from 'shared/components/custom-dual-list/custom-dual-list.module';
import { AngularDualListBoxModule } from 'angular-dual-listbox';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountryLookupComponent } from './country-lookup.component';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    CustomDualListModule
  ],
  declarations: [CountryLookupComponent],
  exports: [CountryLookupComponent]
})
export class CountryLookupModule { }
