import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalTalentPoolLookupComponent } from './global-talent-pool-lookup/global-talent-pool-lookup.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule
  ],
  declarations: [GlobalTalentPoolLookupComponent],
  exports: [GlobalTalentPoolLookupComponent]
})
export class GlobalTalentPoolLookupModule { }
