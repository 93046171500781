import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectCategoryLookupComponent } from './project-category-lookup/project-category-lookup.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule
  ],
  declarations: [ProjectCategoryLookupComponent],
  exports: [ProjectCategoryLookupComponent],
})
export class ProjectCategoryLookupModule { }
