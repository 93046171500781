import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IsActiveUserLookupComponent } from './is-ActiveUser-lookup.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule
  ],
  declarations: [IsActiveUserLookupComponent],
  exports: [IsActiveUserLookupComponent]

})
export class IsActiveUserLookupModule { }
