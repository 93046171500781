import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EscalationTypeFilterList } from 'shared/models/static-list-data.service';

@Component({
  selector: 'app-escalation-type-lookup',
  templateUrl: './escalation-type-lookup.component.html',
  styleUrls: ['./escalation-type-lookup.component.css']
})
export class EscalationTypeLookupComponent implements OnInit {

  @Input() set resetData(isClear) {
    if (isClear) {
      this.escalationTypeSelected = [];
    }
  }
  @Input() placeholder = 'Escalation Type';
  @Input() isdisabled = false;
  @Input() isMultipleSelected = true;
  @Input() viewType = '';

  @Output() getEscalationTypeObj: EventEmitter<any> = new EventEmitter;


  escalationTypeOptionList = [];
  escalationTypeSelected = [];

  /**
   * @constructor
   * Represents a EscalationTypeLookupComponent.
   */
  constructor() {
  }

  ngOnInit() {
    this.escalationTypeOptionList = EscalationTypeFilterList;
    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
    this.escalationTypeSelected = (preselected && preselected['escalationType']) ? preselected['escalationType'] : '';
  }

  onEscalationTypeChange() {
    this.getEscalationTypeObj.emit(this.escalationTypeSelected);
  }

  clearEscalationType() {
    this.escalationTypeSelected = [];
    this.getEscalationTypeObj.emit([]);
  }

}
