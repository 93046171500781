import { Component, OnInit, Input } from '@angular/core';
import { CaseApi, DocumentApi } from 'shared/sdk';
import { CommonService } from 'shared/services/common.service';
import { PreloaderService } from 'shared/services/preloader.service';

@Component({
  selector: 'app-files-tab',
  templateUrl: './files-tab.component.html',
  styleUrls: ['./files-tab.component.css']
})
export class FilesTabComponent implements OnInit {
  @Input() caseData;
  @Input() modelNames;
  @Input() modelIds;

  departmentList: any;
  categoryList: any;
  titleList: any;
  relatedToList: any;

  departmentSelected: any = '';
  categorySelected: any = '';
  titleSelected: any = '';
  relatedToSelected: any = '';
  accountName: any = '';
  programName: any = '';
  uploadedDate: any = '';
  categories = [
    {
      name: 'Files (For Workers)',
      accessType: 'internal',
      documents: [],
      showMore: false
    },
    {
      name: 'Files (From Workers)',
      accessType: 'vendor',
      documents: [],
      showMore: false
    }
  ];
  constructor(
    private _caseApi: CaseApi,
    private _commonService: CommonService,
    private _preloaderService: PreloaderService,
    private _documentApi: DocumentApi,
  ) { }

  ngOnInit() {
    // TODO: WHEN FILTER HAS TO BE APPLIED ON TO UNCOMMENT THIS
    // this.getInitialDate();

    if (this.modelNames && this.modelIds) {
      this.getDocuments();
    }
  }

  // getInitialDate = () => {
  //   if (this.caseData.iccCaseSfdcId) {
  //     this.getCaseData()
  //   } else if (this.caseData.pmsCaseSfdcId) {
  //     this.getWorkOrderData();
  //   }
  // }
  // getCaseData = () => {
  //   this._preloaderService.showPreloader();
  //   this._caseApi.find({
  //     where: { 'sfdcId': this.caseData.iccCaseSfdcId },
  //     fields: ['id', 'sfdcId', 'AccountId', 'Project_SOP__c',],
  //     include: [{
  //       relation: 'program',
  //       scope: {
  //         fields: ['id', 'sfdcId', 'Project__c']
  //       }
  //     }, {
  //       relation: 'Account',
  //       scope: {
  //         fields: ['id', 'sfdcId', 'Name']
  //       }
  //     }]
  //   }).subscribe(res => {
  //     this._preloaderService.hidePreloader()
  //     console.log(res);
  //     this.setProgramAndAccount(res);
  //   }, err => {
  //     this._preloaderService.hidePreloader()
  //   })

  // }
  // getWorkOrderData = () => {
  // }

  // setProgramAndAccount = (data) => {
  //   if (data && data.length) {
  //     this.programName = data[0] && data[0].program && data[0].program && data[0].program.Project__c || '';
  //     this.accountName = data[0] && data[0].Account && data[0].Account && data[0].Account.Name || '';
  //   }
  // }

  // updateFieldValues = (field, value) => {
  //   switch (field) {
  //     case 'department':
  //       this.departmentSelected = value || '';
  //       break;
  //     case 'category':
  //       this.categorySelected = value || '';
  //       break;
  //     case 'title':
  //       this.titleSelected = value || '';
  //       break;
  //     case 'relatedTo':
  //       this.relatedToSelected = value || '';
  //       break;
  //     case 'date':
  //       this.uploadedDate = value || '';
  //       break;
  //   }
  // }

  getDocuments() {
    this._preloaderService.showPreloader();
    let modelIdsArray = []
    if (this.modelIds) {
      this.modelIds.forEach(element => {
        if (element) {
          if (element[Object.keys(element)[0]].length > 0) {
            modelIdsArray = modelIdsArray.concat(element[Object.keys(element)[0]])
          }
        }
      });
    }
    this._documentApi.find({
      where: {
        modelName: this.modelNames,
        modelId: { inq: modelIdsArray },
        isDeleted: 0,
      },
      order: 'createdAt desc',
      include: [{ relation: 'owner', scope: { fields: ['accessType'] } }]
    }).subscribe(
      results => {
        if (results && results.length > 0) {
          results.forEach((doc: any) => {
            // to check if document belongs to the logged in user
            if (doc.ownerId) {
              // filter vendor user files
              if (doc.owner && doc.owner.accessType && doc.owner.accessType === 'vendor') {
                this.categories[1].documents.push(doc);
              } else {
                this.categories[0].documents.push(doc);
              }
            }
          });
        } else {
        }

        this._preloaderService.hidePreloader();
      },
      err => {
        this._preloaderService.hidePreloader();
      }
    );
  }

}
