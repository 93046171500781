import { Subscription } from 'rxjs';
import { AlertService } from './../../../../services/alert.service';
import { MessageService } from './../../../../services/message.service';
import { CaseApi } from './../../../../sdk/services/custom/Case';

import { AppStateService } from './../../../../services/app-state.service';
import { JobApi, CommentApi } from './../../../../sdk';
import { Component, OnInit, Output, Input, EventEmitter, OnDestroy } from '@angular/core';
import { PreloaderService } from 'shared/services/preloader.service';
import { DomSanitizer } from '@angular/platform-browser';
import { chatRoomTypes } from './../../../../models/static-list-data.service';
import { MatDialog } from '@angular/material/dialog';
import { JobMessageEmailPopupComponent } from '../job-message-email-popup/job-message-email-popup.component';
import { UtilityService } from 'shared/services/utility.service';
import { CommonService } from 'shared/services/common.service';
import { BaseVendorViewComponent } from 'msp/shared/base-vendor-view/base-vendor-view.component';

@Component({
  selector: 'app-job-message-details',
  templateUrl: './job-message-details.component.html',
  styleUrls: ['./job-message-details.component.css']
})
export class JobMessageDetailsComponent extends BaseVendorViewComponent implements OnInit, OnDestroy {

  @Output() favUpdated = new EventEmitter();
  disableMessageEmail = true;

  @Input()
  set record(e) {
    this.selectedItem = {};
    // this.data = e;
    if (e && e.modelId) {
      this.selectedItem = e;
      if ((this.accessType === 'vendor' || this.viewAsVendor) && e.modelId) {
        this.loadJobPermissionData(e.modelId);
      }
      this.showContent = false;
      this.loadChatRoom(true);
    }
  }
  @Input() saveAnalytics = true;
  @Input() activeTab;

  showLoaderSection = true;
  emailData = {};
  selectedRoom = {};
  chatRoomTypesList = [];
  roomFilter: any;
  selectedItem: any = {};
  accessType: any;
  job: any;
  // data: any;
  showContent = false;
  userType: String;
  enabledChatRoom = [0, 1, 2];
  teamSelected = '';

  documentCount: any = 0;
  relatedOrderCount: any = 0;
  reqObj: any = {};
  selectedChatData = [];
  pmsStatus: any;
  msgSub: Subscription
  msgRepostActive = true;


  // private _statusCss = {
  //   'In-Progress': 'badge-warning',
  //   'Resolved': 'badge-info',
  //   'New': 'badge-danger',
  //   'Closed': 'badge-success'
  // };
  private _statusCss = {
    resolved: 'resolved',
    closed: 'closed',
    new: 'new',
    'in-progress': 'in-progress',
    active: 'active',
    'in-active': 'in-active'
  };
  messageCount: any;
  fileCount: any;
  private countSubscription: Subscription;

  constructor(private _jobApi: JobApi,
    private _loader: PreloaderService,
    private _caseApi: CaseApi,
    private _commentApi: CommentApi,
    private _appState: AppStateService,
    private _messageService: MessageService,
    private _sanitizer: DomSanitizer,
    private _dialog: MatDialog,
    private _alertService: AlertService,
    private _utilityService: UtilityService,
    private _commonService: CommonService
  ) {
    super(_appState);
   }

  ngOnInit() {
    if (this.saveAnalytics) {
      this._commonService.saveCurrentPageAnalytics('Case Messaging Console', 'Case Messaging Console')    }
    this.accessType = this._appState.getAccessType();
    // To check if view as PMS mode is activated.
    if (localStorage.getItem('viewAsInfo')) {
      const viewAsInfo = JSON.parse(localStorage.getItem('viewAsInfo'));
      const viewAsPartner = (viewAsInfo && viewAsInfo.viewAsType === 'Partner');
      this.accessType = (viewAsPartner) ? 'partner' : this.accessType;
    }
    // If user type is vendor then check permission.
    if (this.accessType === 'partner') {
      this._appState.setJobDetailUserRole({ 'userRole': 'public_agency' });
    } else if (this.accessType === 'vendor' || this.viewAsVendor) {
      this.loadJobPermissionData(this.selectedItem.modelId);
    } else {
      this._appState.setJobDetailUserRole({ 'userRole': 'msp' });
    }
    // Get latest msg information
    this.msgSub = this._messageService.getNewMessageJob().subscribe(msg => {
      /** 
      if (msg.modelId === this.selectedItem.modelId) {
        this.loadChatRoom(false);
      } else if (this.selectedItem && (this.selectedItem.isPopup || this.selectedItem.fromSM || this.selectedItem.teamFilter)
        && Array.isArray(msg) && msg.length) {
        this.loadChatRoom(false);
      }
      */
    });

    this.countSubscription = this._messageService.updateRoomCount().subscribe(msg => {
      if (this.chatRoomTypesList.length > 0) {
        let isChatRoomOpen = false;
        // check for (PMC/Triage) message
        if (msg.Case__c === this.chatRoomTypesList[2].sfdcId) {
          this.chatRoomTypesList[2].count++;
          isChatRoomOpen = true;
        }
        // check for (3PS/ICC) message
        if (msg.Case__c === this.chatRoomTypesList[3].sfdcId) {
          this.chatRoomTypesList[3].count++;
          isChatRoomOpen = true;
        }
        // check for (Billing sales order) message  Work_Order__c
        if (msg.Case__c === this.chatRoomTypesList[7].sfdcId
          && typeof this.selectedItem !== 'undefined' && typeof this.selectedItem.job !== 'undefined'
          && typeof this.selectedItem.job.Sales_Order__c !== 'undefined'
          && this.selectedItem.job.Sales_Order__c === msg.Order__c
        ) {
          this.chatRoomTypesList[7].count++;
          isChatRoomOpen = true;
        }
        // check for (Billing purchase order) message Order__c
        if (msg.Case__c === this.chatRoomTypesList[9].sfdcId
          && typeof this.selectedItem !== 'undefined' && typeof this.selectedItem.job !== 'undefined'
          && typeof this.selectedItem.job.Work_Order__c !== 'undefined'
          && this.selectedItem.job.Work_Order__c === msg.Work_Order__c
        ) {
          this.chatRoomTypesList[9].count++;
          isChatRoomOpen = true;
        }
        let isJobMessageAdded = false;
        // Update Count of job message and 3PS message count as well.
        if (
          this.selectedItem.modelName === "Job"
          && msg.modelName === "Job"
          && this.selectedItem.modelId === msg.modelId
        ) {
          this.chatRoomTypesList[5].count++;
          this.chatRoomTypesList[3].count++;
          isJobMessageAdded = true;
          isChatRoomOpen = true;
        }
        // Update all chat room count if message is for current chat room.
        if (isChatRoomOpen) {
          this.chatRoomTypesList[0].count++;
        }
        // Update public chat room count if message is marked as public.
        if (isChatRoomOpen && msg.Is_Public__c) {
          this.chatRoomTypesList[1].count++;
        }
        /**
         * Count update of VMS/3PS/JOB if message is sent for job as well.
         */
        if (isChatRoomOpen && !isJobMessageAdded && msg.Is_Public_Job__c) {
          this.chatRoomTypesList[5].count++;
        }
        if (isChatRoomOpen) {
          this._appState.setCookie(msg.messageKey, new Date().getTime().toString(), 45);
        }
      }
    });
  }

  /**
   * cook chat room data
   * @param selected only when new msg come in same chat room
   */
  loadChatRoom(selected?) {
    this._loader.showPreloader();
    if (!this.accessType) {
      this.accessType = this._appState.getAccessType();
    }
    if (!this.viewAsVendor && this.accessType === 'internal') {
      if (selected) {
        this.selectedRoom = chatRoomTypes[0];
        this.setRepostStatus();
        this.chatRoomTypesList = chatRoomTypes;
        this.enabledChatRoom = [0, 1, 2];
        this._messageService.setchatRoomId(this.selectedRoom);
        // this chat room enabled when job available in case
        if (this.selectedItem.job) {
          this.enabledChatRoom = this.enabledChatRoom.concat([3, 5, 7, 9])
        }
      }
      let filterCondition;
      this.setChatRoomCount(selected);
      const teamFilter = this.selectedItem.teamFilter;
      if (teamFilter) {
        if (teamFilter === 'pmc') {
          this.enabledChatRoom = [0, 1, 2];
          this.teamSelected = 'PMC';
          if (this.selectedItem.job) {
            this.enabledChatRoom = this.enabledChatRoom.concat([3, 5, 7, 9])
          }
        } else if (teamFilter === 'icc') {
          this.enabledChatRoom = [0, 1, 2, 3, 5, 7, 9];
          this.teamSelected = 'ICC';
        } else if (teamFilter === 'billing') {
          this.enabledChatRoom = [0, 1, 2, 3, 5, 7, 9];
          this.teamSelected = 'Billing';
        } else if (teamFilter === 'escalations') {
          this.selectedRoom = chatRoomTypes[2];
          this.enabledChatRoom = [2];
          this.teamSelected = 'PMC';
        }
        if (selected) {
          if (teamFilter !== 'escalations') {
            this.selectedRoom = this.chatRoomTypesList[0];
          }
          this.setRepostStatus();
        }
        this.selectedItem['roomFilter'] = { idx: this.selectedRoom['idx'], Case__c: this.selectedRoom['sfdcId'] };
        this._messageService.setchatRoomId(this.selectedRoom);
      }
      if (this.selectedItem.job) {
        // It's for Case Messaging Console(VMS/Job)
        const caseArr = [this.selectedItem.job.Case__c.toString()];
        if (this.selectedItem.job.PMS_Case_Number) {
          caseArr.push(this.selectedItem.job.PMS_Case_Number);
        }
        if (this.selectedItem.job && this.selectedItem.job.PMC_Case_Number) {
          caseArr.push(this.selectedItem.job.PMC_Case_Number);
        }
        filterCondition = { sfdcId: { inq: caseArr } };
      } else {
        // It's for Case Messaging Console(MSP/WO)
        filterCondition = { Job_Order__c: this.selectedItem.workOrder.sfdcId };
      }
      this.getChatRoomCount(filterCondition);
      this.getRelatedJobAndWorkOrderChatsDetails();
    }
    if (this.accessType === 'partner') {
      this.getRelatedJobAndWorkOrderChatsDetails();
    }
  }

  /**
   * It's working only in if case created in any case.
   */
  setChatRoomCount(selected) {
    // It's for 
    if (this.selectedItem.job) {
      // It's for Case Messaging Console(VMS/Job)
      this.chatRoomTypesList.map(item => {
        item['count'] = selected ? 0 : item['count'];
        item['sfdcId'] = '';
        item['value'] = 'XXXX0000';
        if (item.idx === 0) { // It's for all
          const teamFilter = this.selectedItem.teamFilter;
          if (teamFilter !== 'pmc' && teamFilter !== 'billing') {
            item['count'] = this.selectedItem.count ? this.selectedItem.count : 0;
          }
        } else if (item.idx === 1) { // It's for pms
          item['sfdcId'] = this.selectedItem.job ? this.selectedItem.job.PMS_Case_Number : '';
        } else if (item.idx === 2) { // It's for PMC
          item['sfdcId'] = this.selectedItem.job ? this.selectedItem.job.PMC_Case_Number : '';
        } else if (item.idx === 3) { // It's for ICC
          item['sfdcId'] = this.selectedItem.job ? this.selectedItem.job.Case__c : '';
        } else if (item.idx === 5) { // It's for Job 3ps
          item['value'] = this.selectedItem.job ? this.selectedItem.job.Iron_Job_num__c : '';
        } else if (item.idx === 7) { // It's for 3ps salesorder
          item['sfdcId'] = this.selectedItem.job ? this.selectedItem.job.Case__c : '';
          item['value'] = this.selectedItem.job && this.selectedItem.job.order ? this.selectedItem.job.order.OrderNumber : '';
        } else if (item.idx === 9) { // It's for 3ps purchase order
          item['sfdcId'] = this.selectedItem.job ? this.selectedItem.job.Case__c : '';
          item['value'] = this.selectedItem.job && this.selectedItem.job.purchaseOrder ?
            this.selectedItem.job.purchaseOrder.Work_Order_num__c : '';

        }
      });
    } else {
      // It's for Case Messaging Console(MSP/WO)
      this.chatRoomTypesList.map(item => {
        item['count'] = selected ? 0 : item['count'];
        item['sfdcId'] = '';
        item['value'] = 'XXXX0000';
        if (item.idx === 0) { // It's for all
          const teamFilter = this.selectedItem.teamFilter;
          if (teamFilter !== 'pmc' && teamFilter !== 'billing') {
            item['count'] = this.selectedItem.count ? this.selectedItem.count : 0;
          }
        } else if (item.idx === 1) { // It's for pms
          item['sfdcId'] = (this.selectedItem.workOrder && this.selectedItem.workOrder.Case) ?
            this.selectedItem.workOrder.Case.sfdcId : '';
        } else if (item.idx === 2) { // It's for PMC
          item['sfdcId'] = (this.selectedItem.workOrder && this.selectedItem.workOrder.jpcCase) ?
            this.selectedItem.workOrder.jpcCase.sfdcId : '';
        }
      });
    }
  }

  /**
 *
 * @param filter get comment related to selected case
 */
  getChatRoomCount(filter) {
    const commentFilter = {
      modelName: 'Case'
    }
    // to introduce message type filter
    const filterCondition = JSON.parse(localStorage.getItem('filterCondition'));
    if (filterCondition && filterCondition['Is_System_Generated__c'] && filterCondition['Is_System_Generated__c']['inq'] &&
      filterCondition['Is_System_Generated__c']['inq'].length) {
      if (filterCondition['Is_System_Generated__c']['inq'][0] === true) {
        commentFilter['Contact__c'] = { $exists: false };
      } else {
        commentFilter['Contact__c'] = { $exists: true };
      }
    }
    const filteObj = {
      where: filter,
      fields: ['id', 'sfdcId', 'CaseNumber', 'Sales_Order_Reference__c', 'Purchase_Order_Reference__c'],
      skip: 0,
      limit: 15,
      include: [
        {
          relation: 'comment',
          scope: {
            fields: ['sfdcId', 'Case__c', 'Is_Public_Job__c', 'Order__c', 'Work_Order__c', 'commentType', 'Contact__c', 'modelName', 'Is_Public__c', 'Is_System_Generated__c'],
            where: commentFilter
          }
        },
        {
          relation: 'order',
          scope: {
            fields: ['id', 'OrderNumber']
          }
        },
        {
          relation: 'purchaseOrder',
          scope: {
            fields: ['id', 'Customer_WO_Num__c', 'Work_Order_num__c']
          }
        },
      ]
    }
    this._caseApi.find(filteObj).subscribe(res => {
      if (res && res.length) {
        if (this.selectedItem && this.selectedItem.job && this.selectedItem.job.id) {
          const commentCountFilter = {
            modelId: this.selectedItem.job.id.toString(),
            modelName: 'Job',
            Is_Public_Job__c: true
          };
          if (filterCondition && filterCondition['Is_System_Generated__c'] && filterCondition['Is_System_Generated__c']['inq'] &&
            filterCondition['Is_System_Generated__c']['inq'].length) {
            if (filterCondition['Is_System_Generated__c']['inq'][0] === true) {
              commentCountFilter['Contact__c'] = { $exists: false };
            } else {
              commentCountFilter['Contact__c'] = { $exists: true };
            }
          }
          this._commentApi.count(commentCountFilter).subscribe(jobCommentCount => {
            const jobCount = jobCommentCount ? jobCommentCount.count : 0;
            this.setChatRoomValue(res, jobCount);
          });
        } else {
          this.setChatRoomValue(res, 0);
        }
      }
    });

  }

  /**
   * update all chat room count and value
   * @param res case array object with comment
   */
  setChatRoomValue(res, jobCount) {
    const teamFilter = this.selectedItem.teamFilter;
    res.forEach((item, index) => {
      this.chatRoomTypesList.map(roomType => {
        if (this.selectedItem.job && roomType['idx'] === 5) { // It's for VMS 3ps Job Room
          roomType['count'] = jobCount;
        } else if (this.selectedItem.job && roomType['idx'] === 7) { // salesorder
          if (item['order']
            && item['order']['id'] && this.selectedItem.job.Sales_Order__c) {
            roomType['id'] = item['id'];
            roomType['count'] = item['comment'] ?
              item['comment'].filter(i => i.Order__c === this.selectedItem.job.Sales_Order__c).length : 0;
          } else {
            roomType['id'] = item['id'];
            roomType['count'] = 0;
          }
        } else if (this.selectedItem.job && roomType['idx'] === 9 && item['purchaseOrder'] &&
          item['purchaseOrder']['id'] && this.selectedItem.job.Work_Order__c) {// purchaseorder
          roomType['id'] = item['id'];
          roomType['count'] = item['comment'] ?
            item['comment'].filter(i => i.Work_Order__c === this.selectedItem.job.Work_Order__c).length : 0;
        } else if (roomType['sfdcId'] === item['sfdcId']) { // It's for PMS | PMC | ICC Chat Room
          roomType['id'] = item['id'];
          roomType['value'] = item['CaseNumber'];
          roomType['count'] = item['comment'] ? item['comment'].filter(i => !(i.Order__c || i.Work_Order__c)).length : 0;
        } else if (roomType['idx'] === 0) { // It's for ALL chat room
          if (this.selectedItem.isPopup) {
            let allCount = 0;
            if (item.comment[0] && item.comment[0].commentType === 'pmc') {
              allCount = item['comment'] ? item['comment'].filter(i => !i.Is_Public__c).length : 0;
            } else if (item.comment[0] && item.comment[0].commentType === 'pms') {
              allCount = item['comment'] ? item['comment'].length : 0;
            }
            if (!index) {
              roomType['count'] = allCount;
            } else {
              roomType['count'] = roomType['count'] + allCount;
            }
            // }
          } else {
            const allCount = item['comment'] ? item['comment'].filter(i => !(i.commentType === 'pms' && i.Contact__c)).length : 0;
            if (!index) {
              roomType['count'] = allCount;
            } else {
              roomType['count'] = roomType['count'] + allCount;
            }
          }
        }
      });
    });

    if (this.selectedItem && this.selectedItem.isPopup) {
      // this work when chat room open from work order list tab or from service manager tab (having no job)
      this.selectedItem.count = this.chatRoomTypesList[0]['count'];
    }
    // if (this.selectedItem && this.selectedItem.fromSM) { // It's work only for when chat room open from sm
    //  this.chatRoomTypesList[0]['count'] = this.chatRoomTypesList[2]['count'] + this.chatRoomTypesList[3]['count']
    //    + this.chatRoomTypesList[7]['count'] + this.chatRoomTypesList[9]['count'];
    //  this.selectedItem.count = this.chatRoomTypesList[0]['count'];
    // } else if (this.selectedItem && this.selectedItem.teamFilter) {
    // if (teamFilter === 'pmc') {
    //   this.chatRoomTypesList[0]['count'] = this.chatRoomTypesList[2]['count'];
    //   this.selectedItem.count = this.chatRoomTypesList[0]['count'];
    // } else if (teamFilter === 'billing') {
    //   this.chatRoomTypesList[0]['count'] = this.chatRoomTypesList[7]['count'] + this.chatRoomTypesList[9]['count'];
    //   this.selectedItem.count = this.chatRoomTypesList[0]['count'];
    // } else {
    //   this.chatRoomTypesList[0]['count'] = this.chatRoomTypesList[3]['count'];
    //   this.selectedItem.count = this.chatRoomTypesList[0]['count'];
    // }
    // }

    if (this.selectedRoom && this.selectedRoom['count']) {
      this.disableMessageEmail = false;
    } else {
      this.disableMessageEmail = true;
    }
  }

  /**
    * set user role for job Message List page
    * @param jobId modelId
    */
  loadJobPermissionData(jobId) {
    this._loader.showPreloader();
    const reqObj = {
      'fields': ['id', 'Dispatch_Worker_Name__c'],
      'where': { 'id': jobId },
      'include': [
        {
          'relation': 'worker',
          'scope': {
            'fields': ['sfdcId', 'Name', 'Worker_Type__c', 'Contact__c', 'RecordTypeId']
          }
        }
      ]
    };
    this._jobApi.getJobDetailsById(reqObj).subscribe(
      result => {
        this._loader.hidePreloader();
        if (result && result.id) {
          this.job = result;
          if (this.job['userRole']) {
            this._appState.setJobDetailUserRole({ 'userRole': this.job['userRole'] });
          }
        }
      }, err => {
        this._loader.hidePreloader();
      }
    );
  }

  /**
   * Selected room object
   */
  onClickChatRoom(room) {
    this.selectedRoom = room;
    this.setRepostStatus();
    if (room.count) {
      this.disableMessageEmail = false;
    } else {
      this.disableMessageEmail = true;
    }
    if (this.selectedRoom['idx'] === 5) { // It's for 3ps job chat room
      this.roomFilter = { idx: this.selectedRoom['idx'], modelId: this.selectedItem.modelId };
    } else if (this.selectedRoom['idx'] !== 0) { // It's for another remaining  chat room
      this.roomFilter = { idx: this.selectedRoom['idx'], Case__c: this.selectedRoom['sfdcId'] };
    } else if (this.selectedItem.job) { // It's for all chat room 1
      // It's for Case Messaging Console(VMS/Job)
      const caseArr = [this.selectedItem.job.Case__c];
      if (this.selectedItem.job && this.selectedItem.job.PMC_Case_Number) {
        caseArr.push(this.selectedItem.job.PMC_Case_Number);
      }
      this.roomFilter = {
        idx: this.selectedRoom['idx'], or: [
          {
            Case__c: { inq: caseArr },
          },
          {
            and: [
              { Case__c: this.selectedItem.job.PMS_Case_Number },
              { Contact__c: { "exists": false } }
            ]
          }
        ]
      };
    } else {
      // It's for Case Messaging Console(MSP/WO)
      const caseArr = [this.selectedItem.workOrder.jpcCase.sfdcId];
      this.roomFilter = { idx: this.selectedRoom['idx'], Case__c: { inq: caseArr } };
    }

    this._messageService.setchatRoomId(this.selectedRoom);
  }

  setRepostStatus() {
    if (this.selectedRoom['idx'] === 0 || this.selectedRoom['idx'] === 1) {
      this.msgRepostActive = false;
    } else {
      this.msgRepostActive = true;
    }
  }

  openEmailPopup() {
    if (this.disableMessageEmail) {
      return;
    }
    const dialogRef = this._dialog.open(JobMessageEmailPopupComponent, {
      width: '60%',
      data: {},
      panelClass: 'job-message-email-popup-dialog-container',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.length > 0) {
        let type = '';
        let count = 0;
        if (this.selectedItem) {
          count = this.selectedItem.count;
          if (this.selectedItem.job) {
            type = 'Job: ' + this.selectedItem.job.Iron_Job_num__c
          }
          if (this.selectedItem.workOrder && this.selectedItem.workOrder.Name) {
            type = 'WO: XX' + this.selectedItem.workOrder.Name.substr(this.selectedItem.workOrder.Name.length - 6);
          }
        }
        this.emailData = {
          recipents: result,
          subject: 'Message @ ' + type + ' in Chatroom ' + this.selectedRoom['label1'] + ' ' +
            (this.selectedRoom['label2'] ? this.selectedRoom['label2'] : '') +
            ' (' + this.selectedRoom['count'] + ')'
        };
      }
    });
  }


  /**
  * Get Files Count
  */
  getFilesCount = async () => {
    if (this.selectedItem.job) {
      this.documentCount = await this._utilityService.getDocumentsCount('Job', this.selectedChatData['id']);
    } else if (this.selectedChatData['Case'] && this.selectedChatData['Case'].workOrder && this.selectedChatData['Case'].workOrder.jobOrderItems && this.selectedChatData['Case'].workOrder.jobOrderItems.length) {
      this.documentCount = await this._utilityService.getDocumentsCount('JobOrderItem', this.selectedChatData['Case'].workOrder.jobOrderItems[0].id);
    } else {
      this.documentCount = 0;
    }
  }

  /**
  * Get Realted Order Count
  */
  getRelatedOrderCount() {
    if (this.selectedItem.job) {
      // let mode = 'all';
      const reqObj = {
        id: { neq: this.selectedChatData['id'] },
        Work_Order_Number: this.selectedChatData['Work_Order_Number']
      }
      this._jobApi.count(reqObj).subscribe(
        results => {
          if (results.count > 0) {
            this.relatedOrderCount = results.count;
          } else {
            this.relatedOrderCount = 0
          }
        },
        error => {
        }
      );
    } else {
    }
  }

  /**
  * Status Class Color Change
   */
  getStatusClass(status: string = '') {
    if (status) {
      status = status.toLowerCase();
      if (this._statusCss.hasOwnProperty(status)) {
        return this._statusCss[status];
      } else {
        return '';
      }
    }

  }

  /**
     * Get Realted Chats for WorkOrder and Jobs
     */
  getRelatedJobAndWorkOrderChatsDetails() {
    this._loader.showPreloader();
    if (this.selectedItem.job) {
      this.reqObj = {
        job: {
          where: { sfdcId: this.selectedItem.job.sfdcId }
        }
      }
    } else if (!this.selectedItem.job) {
      this.reqObj = {
        workOrder: {
          where: { sfdcId: this.selectedItem.workOrder.sfdcId }
        }
      }
    }
    this._commentApi.getRelatedChatsStatus(this.reqObj).subscribe(
      results => {
        this._loader.hidePreloader(); 
        this.selectedChatData = results[0];
        this.getFilesCount();
        this.getRelatedOrderCount();
        this.showLoaderSection = false;
      },
      error => {
        this._loader.hidePreloader();
        this.showLoaderSection = false;
      }
    );

  }

  messageUpdate(event) {
    if(event) {
      this.disableMessageEmail = false;
      this.messageCount = (event && event.messageCount) ? event.messageCount : 0;
      this.fileCount = (event && event.fileCount) ? event.fileCount : 0;
    }
  }

  ngOnDestroy() {
    if (this.msgSub) {
      this.msgSub.unsubscribe();
    }
    if (this.countSubscription) {
      this.countSubscription.unsubscribe();
    }
  }
}
