/* tslint:disable */
import {
  Account,
  Job,
  Users,
  PurchaseOrder,
  Worker,
  RecordType
} from '../index';

declare var Object: any;
export interface TimecardInterface {
  "sfdcId"?: string;
  "Agreed_Rate_on_Service_Dispatch__c"?: number;
  "Any_Observation_or_Suggestions_Notes__c"?: string;
  "At_least_One_day_worked__c"?: boolean;
  "Both_Approved__c"?: boolean;
  "CreatedById"?: string;
  "CreatedDate"?: Date;
  "CurrencyIsoCode"?: string;
  "Customer_Approval_Status__c"?: string;
  "Customer_Approval_Time__c"?: number;
  "Customer_Site_Signoff_Name__c"?: string;
  "Customerflag__c"?: boolean;
  "Difference_Between_Job_Schedule_Actual__c"?: number;
  "Final_Timecard_Already_Submitted__c"?: boolean;
  "Final_Timecard__c"?: boolean;
  "Friday_Hours_Actual__c"?: number;
  "Friday_Hours_Job_Schedule__c"?: number;
  "Friday_Job_Schedule_Hours__c"?: number;
  "Friday_Tasks_Performed__c"?: string;
  "IRON_Approval_Time__c"?: number;
  "Incurred_Date__c"?: Date;
  "Ironflag__c"?: boolean;
  "IsDeleted"?: boolean;
  "Is_Friday_Holiday_in_Schedule__c"?: boolean;
  "Is_Monday_Holiday_in_Schedule__c"?: boolean;
  "Is_Saturday_Holiday_in_Schedule__c"?: boolean;
  "Is_Sunday_Holiday_in_Schedule__c"?: boolean;
  "Is_Thursday_Holiday_in_Schedule__c"?: boolean;
  "Is_Tuesday_Holiday_in_Schedule__c"?: boolean;
  "Is_Wednesday_Holiday_in_Schedule__c"?: boolean;
  "LastActivityDate"?: Date;
  "LastModifiedById"?: string;
  "LastModifiedDate"?: Date;
  "LastReferencedDate"?: Date;
  "LastViewedDate"?: Date;
  "Monday_Hours_Job_Schedule__c"?: number;
  "Monday_Hours__c"?: number;
  "Monday_Job_Schedule_Hours__c"?: number;
  "Monday_Task_Performed__c"?: string;
  "Name"?: string;
  "Need_Approval_Auto__c"?: boolean;
  "Need_Approval__c"?: boolean;
  "Overtime_Hours_Manual__c"?: number;
  "Overtime_Hours__c"?: number;
  "Overtime_Timecard_Hours__c"?: number;
  "Overtime__c"?: boolean;
  "PPE_Hours_on_Dispatch__c"?: number;
  "Project_Jobsite_Project__c"?: string;
  "Purchase_Invoice_Created__c"?: boolean;
  "Purchase_Order_Ref__c"?: string;
  "RecordTypeId"?: string;
  "Refresh_Flag__c"?: boolean;
  "Sales_Invoice_Created__c"?: boolean;
  "Sales_Order_Ref__c"?: string;
  "Saturday_Hours_Actual__c"?: number;
  "Saturday_Hours_Job_Schedule__c"?: number;
  "Saturday_Job_Schedule_Hours__c"?: number;
  "Saturday_Tasks_Performed__c"?: string;
  "Service_Dispatch__c"?: string;
  "Status__c"?: string;
  "Sunday_Hours_Actual__c"?: number;
  "Sunday_Hours_Job_Schedule__c"?: number;
  "Sunday_Job_Schedule_Hours__c"?: number;
  "Sunday_Tasks_Performed__c"?: string;
  "Thursday_Hours_Actual__c"?: number;
  "Thursday_Hours_Job_Schedule__c"?: number;
  "Thursday_Job_Schedule_Hours__c"?: number;
  "Thursday_Tasks_Performed__c"?: string;
  "Time_Card_Approved_Customer_Date_Time__c"?: Date;
  "Time_Card_Approved_IRON_Date_Time__c"?: Date;
  "Time_Card_Created_Date_Time__c"?: Date;
  "Time_Card_Submitted_for_Approval_Date__c"?: Date;
  "Timecard_Calc_Base_Bk__c"?: number;
  "Timecard_Calc_Base_Final__c"?: number;
  "Timescard_Increment_Offset__c"?: number;
  "Timesheet_End_Week__c"?: Date;
  "Timesheet_Hours_Calc_Base_Bk__c"?: number;
  "Timesheet_Hours_Calc_Base__c"?: number;
  "Timesheet_Hours_Calc_Rounded__c"?: number;
  "Timesheet_Hours_Calc__c"?: number;
  "Timesheet_Month__c"?: string;
  "Timesheet_Start_Date__c"?: Date;
  "Timesheet_Start_Week__c"?: Date;
  "Timesheet_Week_Number_ISO_Auto__c"?: string;
  "Timesheet_Week_Number_ISO__c"?: number;
  "Timesheet_Year__c"?: number;
  "Total_Approved_Timecard_Hours__c"?: number;
  "Total_Number_of_Timecards_Submitted__c"?: number;
  "Total_Overtime_Hours_Holiday_Weekday__c"?: number;
  "Total_Overtime_Hours_Holiday_Weekend__c"?: number;
  "Total_Overtime_Hours_Standard_Weekday__c"?: number;
  "Total_Overtime_Hours_Standard_Weekend__c"?: number;
  "Total_Planned_Hours_Job_Schedule__c"?: number;
  "Total_Short_Worked_Hours__c"?: number;
  "Total_Timecard_Hours__c"?: number;
  "Total_Timesheet_Hours__c"?: number;
  "Total_Worked_Hours_Without_Offset__c"?: number;
  "Total_Worked_Hours__c"?: number;
  "Tuesday_Hours_Actual__c"?: number;
  "Tuesday_Hours_Job_Schedule__c"?: number;
  "Tuesday_Job_Schedule_Hours__c"?: number;
  "Tuesday_Tasks_Performed__c"?: string;
  "Type__c"?: string;
  "Vendor_Auto__c"?: string;
  "Vendor_Daily_Hours__c"?: number;
  "Vendor_Time_Card_Notes_Tasks_Performed__c"?: string;
  "Vendor_Time_Card_Time_Out_Actual__c"?: Date;
  "Vendor_Time_Card_Time_in_Actual__c"?: Date;
  "Vendor__c"?: string;
  "Vendor_on_Job__c"?: string;
  "Vendor_time_Actuals_Calc__c"?: string;
  "Visit_Final__c"?: number;
  "Visit_Number_Calc__c"?: number;
  "Wednesday_Hours_Actual__c"?: number;
  "Wednesday_Hours_Job_Schedule__c"?: number;
  "Wednesday_Job_Schedule_Hours__c"?: number;
  "Wednesday_Tasks_Performed__c"?: string;
  "Week_Start_Date__c"?: Date;
  "Weekend_Hours_Worked__c"?: number;
  "Worker_Auto__c"?: string;
  "Worker_Name__c"?: string;
  "Worker__c"?: string;
  "Worker_on_Job__c"?: string;
  "update_PO_Flag__c"?: boolean;
  "Signature_Image"?: string;
  "Recipient_Name"?: string;
  "IsServiceODelete"?: boolean;
  "Is_Monday_Holiday_in_Jobsite__c"?: boolean;
  "Is_Tuesday_Holiday_in_Jobsite__c"?: boolean;
  "Is_Wednesday_Holiday_in_Jobsite__c"?: boolean;
  "Is_Thursday_Holiday_in_Jobsite__c"?: boolean;
  "Is_Friday_Holiday_in_Jobsite__c"?: boolean;
  "Is_Saturday_Holiday_in_Jobsite__c"?: boolean;
  "Is_Sunday_Holiday_in_Jobsite__c"?: boolean;
  "Is_Monday_Public_Holiday__c"?: boolean;
  "Is_Tuesday_Public_Holiday__c"?: boolean;
  "Is_Wednesday_Public_Holiday__c"?: boolean;
  "Is_Thursday_Public_Holiday__c"?: boolean;
  "Is_Friday_Public_Holiday__c"?: boolean;
  "Is_Saturday_Public_Holiday__c"?: boolean;
  "Is_Sunday_Public_Holiday__c"?: boolean;
  "Business_Start_Time__c"?: Date;
  "Signature_Date"?: Date;
  "Actual_Coverage_Hour__c"?: string;
  "Actual_End_Time_Coverage__c"?: string;
  "Approval_Levels_Required__c"?: string;
  "Auto_Created_From_Timeclock__c"?: boolean;
  "Automation_Message__c"?: string;
  "Break_Duration_Minutes__c"?: number;
  "Current_Approval_Level__c"?: string;
  "Day_of_Timesheet_Start_Date__c"?: string;
  "Difference_In_Out__c"?: number;
  "Friday_AFTH__c"?: number;
  "Friday_BEFH__c"?: number;
  "Friday_End_Time_From_Jobsite__c"?: string;
  "Friday_OT_Holiday__c"?: number;
  "Friday_start_Time_From_Jobsite__c"?: string;
  "Has_PI_Amount__c"?: boolean;
  "Has_SI_Amount__c"?: boolean;
  "Have_ICC_Access__c"?: boolean;
  "Have_PgMO_Access__c"?: boolean;
  "Health_Profit__c"?: string;
  "Health_SKU__c"?: string;
  "Health_Worker__c"?: string;
  "Incurred_Date_Month_Calculated__c"?: string;
  "Is_Holiday__c"?: boolean;
  "Is_Saturday_Holiday_in_Schedule_Stored__c"?: boolean;
  "Is_Sunday_Holiday_in_Schedule_Stored__c"?: boolean;
  "Job_Has_Jobsite__c"?: boolean;
  "Job_Has_Schedule_Ref__c"?: boolean;
  "Job_Number__c"?: string;
  "Job_Schedule_Hours__c"?: number;
  "Job_has_PO__c"?: boolean;
  "Job_has_SO__c"?: boolean;
  "Job_has_Vendor__c"?: boolean;
  "Last_Day_of_Month__c"?: Date;
  "Last_Day_of_previous_month__c"?: number;
  "Logged_in_Contact__c"?: string;
  "Monday_AFTH__c"?: number;
  "Monday_BEFH__c"?: number;
  "Monday_End_Time_From_Jobsite__c"?: string;
  "Monday_OT_Holiday__c"?: number;
  "Monday_Start_Time_From_Jobsite__c"?: string;
  "Month_Offset__c"?: number;
  "Month_of_Timesheet_Start_Date__c"?: string;
  "Need_SKU_Change_on_SO_PO__c"?: boolean;
  "Offset_Calculation_steps__c"?: string;
  "Partner_SG_Number__c"?: string;
  "Recordtypeid__c"?: string;
  "Refresh_SKU__c"?: boolean;
  "SC_Has_Custom_Price__c"?: boolean;
  "SO_List_Price__c"?: number;
  "Saturday_AFTH__c"?: number;
  "Saturday_BEFH__c"?: number;
  "Saturday_End_Time_From_Jobsite__c"?: string;
  "Saturday_OT_Holiday__c"?: number;
  "Saturday_Start_Time_From_Jobsite__c"?: string;
  "Service_Contract_Offset_Stored__c"?: number;
  "Service_Contract_Offset__c"?: number;
  "Sunday_AFTH__c"?: number;
  "Sunday_BEFH__c"?: number;
  "Sunday_OT_Holiday__c"?: number;
  "Sunday_End_Time_From_Jobsite__c"?: string;
  "Sunday_Start_Time_From_Jobsite__c"?: string;
  "Temp1__c"?: number;
  "Temp2__c"?: string;
  "Temp4__c"?: string;
  "Temp__c"?: string;
  "Thursday_AFTH__c"?: number;
  "Thursday_BEFH__c"?: number;
  "Thursday_OT_Holiday__c"?: number;
  "Thursday_End_Time_From_Jobsite__c"?: string;
  "Thursday_Start_Time_From_Jobsite__c"?: string;
  "Timecard_Normal_Hours__c"?: number;
  "Timecard_OT_AFTH_DNU__c"?: number;
  "Timecard_OT_AFTH__c"?: number;
  "Timecard_OT_Holiday__c"?: number;
  "Timecard_OT_STD__c"?: number;
  "Timecard_OT_Weekend_DNU__c"?: number;
  "Timecard_OT_Weekend_MF__c"?: number;
  "Timecard_OT_Weekend_SS__c"?: number;
  "Timecard_OT_Weekend__c"?: number;
  "Timesheet_OT_AFTH__c"?: number;
  "Timesheet_OT_WKND__c"?: number;
  "Timesheet_Short_Hours_Day_Calculations_P__c"?: number;
  "Tuesday_AFTH__c"?: number;
  "Tuesday_BEFH__c"?: number;
  "Tuesday_OT_Holiday__c"?: number;
  "Tuesday_End_Time_From_Jobsite__c"?: string;
  "Tuesday_start_Time_From_Jobsite__c"?: string;
  "Wednesday_AFTH__c"?: number;
  "Wednesday_BEFH__c"?: number;
  "Wednesday_OT_Holiday__c"?: number;
  "Wednesday_End_Time_From_Jobsite__c"?: string;
  "Wednesday_start_Time_From_Jobsite__c"?: string;
  "Worker_same_as_Job_Worker__c"?: boolean;
  "X1_Hours_minus_Created__c"?: Date;
  "X1st_Day_of_Month__c"?: Date;
  "Have_files"?: boolean;
  "File_URL"?: string;
  "Business_Start_Time_Formula__c"?: string;
  "Absence_Hours__c"?: number;
  "Total_Paid_Holiday_Hours__c"?: number;
  "Last_Day_of_Month_Stored__c"?: Date;
  "Month_Offset_Stored__c"?: number;
  "Purchase_Invoice_Ref__c"?: string;
  "Sales_Invoice_Ref__c"?: string;
  "Saturday_Job_Schedule_Hours_Stored__c"?: number;
  "Sunday_Job_Schedule_Hours_Stored__c"?: number;
  "Timesheet_Short_Hours_Day_Calculations__c"?: number;
  "Timesheet_Short_Hours_Days__c"?: number;
  "X1st_Day_of_Month_Stored__c"?: Date;
  "VerifyTimesheet__c"?: boolean;
  "Master_Timecard_Ref__c"?: string;
  "Equipment_Make_Model_Performed_on__c"?: string;
  "Equipment_Serial_Performed_on__c"?: string;
  "Worker_Mobile_OS_Android_iOS__c"?: string;
  "Replacement_SKU_for_PO__c"?: string;
  "Replacement_SKU_for_SO__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  vendor?: Account;
  job?: Job;
  users?: Users;
  purchaseOrder?: PurchaseOrder;
  worker?: Worker;
  recordType?: RecordType;
}

export class Timecard implements TimecardInterface {
  "sfdcId": string;
  "Agreed_Rate_on_Service_Dispatch__c": number;
  "Any_Observation_or_Suggestions_Notes__c": string;
  "At_least_One_day_worked__c": boolean;
  "Both_Approved__c": boolean;
  "CreatedById": string;
  "CreatedDate": Date;
  "CurrencyIsoCode": string;
  "Customer_Approval_Status__c": string;
  "Customer_Approval_Time__c": number;
  "Customer_Site_Signoff_Name__c": string;
  "Customerflag__c": boolean;
  "Difference_Between_Job_Schedule_Actual__c": number;
  "Final_Timecard_Already_Submitted__c": boolean;
  "Final_Timecard__c": boolean;
  "Friday_Hours_Actual__c": number;
  "Friday_Hours_Job_Schedule__c": number;
  "Friday_Job_Schedule_Hours__c": number;
  "Friday_Tasks_Performed__c": string;
  "IRON_Approval_Time__c": number;
  "Incurred_Date__c": Date;
  "Ironflag__c": boolean;
  "IsDeleted": boolean;
  "Is_Friday_Holiday_in_Schedule__c": boolean;
  "Is_Monday_Holiday_in_Schedule__c": boolean;
  "Is_Saturday_Holiday_in_Schedule__c": boolean;
  "Is_Sunday_Holiday_in_Schedule__c": boolean;
  "Is_Thursday_Holiday_in_Schedule__c": boolean;
  "Is_Tuesday_Holiday_in_Schedule__c": boolean;
  "Is_Wednesday_Holiday_in_Schedule__c": boolean;
  "LastActivityDate": Date;
  "LastModifiedById": string;
  "LastModifiedDate": Date;
  "LastReferencedDate": Date;
  "LastViewedDate": Date;
  "Monday_Hours_Job_Schedule__c": number;
  "Monday_Hours__c": number;
  "Monday_Job_Schedule_Hours__c": number;
  "Monday_Task_Performed__c": string;
  "Name": string;
  "Need_Approval_Auto__c": boolean;
  "Need_Approval__c": boolean;
  "Overtime_Hours_Manual__c": number;
  "Overtime_Hours__c": number;
  "Overtime_Timecard_Hours__c": number;
  "Overtime__c": boolean;
  "PPE_Hours_on_Dispatch__c": number;
  "Project_Jobsite_Project__c": string;
  "Purchase_Invoice_Created__c": boolean;
  "Purchase_Order_Ref__c": string;
  "RecordTypeId": string;
  "Refresh_Flag__c": boolean;
  "Sales_Invoice_Created__c": boolean;
  "Sales_Order_Ref__c": string;
  "Saturday_Hours_Actual__c": number;
  "Saturday_Hours_Job_Schedule__c": number;
  "Saturday_Job_Schedule_Hours__c": number;
  "Saturday_Tasks_Performed__c": string;
  "Service_Dispatch__c": string;
  "Status__c": string;
  "Sunday_Hours_Actual__c": number;
  "Sunday_Hours_Job_Schedule__c": number;
  "Sunday_Job_Schedule_Hours__c": number;
  "Sunday_Tasks_Performed__c": string;
  "Thursday_Hours_Actual__c": number;
  "Thursday_Hours_Job_Schedule__c": number;
  "Thursday_Job_Schedule_Hours__c": number;
  "Thursday_Tasks_Performed__c": string;
  "Time_Card_Approved_Customer_Date_Time__c": Date;
  "Time_Card_Approved_IRON_Date_Time__c": Date;
  "Time_Card_Created_Date_Time__c": Date;
  "Time_Card_Submitted_for_Approval_Date__c": Date;
  "Timecard_Calc_Base_Bk__c": number;
  "Timecard_Calc_Base_Final__c": number;
  "Timescard_Increment_Offset__c": number;
  "Timesheet_End_Week__c": Date;
  "Timesheet_Hours_Calc_Base_Bk__c": number;
  "Timesheet_Hours_Calc_Base__c": number;
  "Timesheet_Hours_Calc_Rounded__c": number;
  "Timesheet_Hours_Calc__c": number;
  "Timesheet_Month__c": string;
  "Timesheet_Start_Date__c": Date;
  "Timesheet_Start_Week__c": Date;
  "Timesheet_Week_Number_ISO_Auto__c": string;
  "Timesheet_Week_Number_ISO__c": number;
  "Timesheet_Year__c": number;
  "Total_Approved_Timecard_Hours__c": number;
  "Total_Number_of_Timecards_Submitted__c": number;
  "Total_Overtime_Hours_Holiday_Weekday__c": number;
  "Total_Overtime_Hours_Holiday_Weekend__c": number;
  "Total_Overtime_Hours_Standard_Weekday__c": number;
  "Total_Overtime_Hours_Standard_Weekend__c": number;
  "Total_Planned_Hours_Job_Schedule__c": number;
  "Total_Short_Worked_Hours__c": number;
  "Total_Timecard_Hours__c": number;
  "Total_Timesheet_Hours__c": number;
  "Total_Worked_Hours_Without_Offset__c": number;
  "Total_Worked_Hours__c": number;
  "Tuesday_Hours_Actual__c": number;
  "Tuesday_Hours_Job_Schedule__c": number;
  "Tuesday_Job_Schedule_Hours__c": number;
  "Tuesday_Tasks_Performed__c": string;
  "Type__c": string;
  "Vendor_Auto__c": string;
  "Vendor_Daily_Hours__c": number;
  "Vendor_Time_Card_Notes_Tasks_Performed__c": string;
  "Vendor_Time_Card_Time_Out_Actual__c": Date;
  "Vendor_Time_Card_Time_in_Actual__c": Date;
  "Vendor__c": string;
  "Vendor_on_Job__c": string;
  "Vendor_time_Actuals_Calc__c": string;
  "Visit_Final__c": number;
  "Visit_Number_Calc__c": number;
  "Wednesday_Hours_Actual__c": number;
  "Wednesday_Hours_Job_Schedule__c": number;
  "Wednesday_Job_Schedule_Hours__c": number;
  "Wednesday_Tasks_Performed__c": string;
  "Week_Start_Date__c": Date;
  "Weekend_Hours_Worked__c": number;
  "Worker_Auto__c": string;
  "Worker_Name__c": string;
  "Worker__c": string;
  "Worker_on_Job__c": string;
  "update_PO_Flag__c": boolean;
  "Signature_Image": string;
  "Recipient_Name": string;
  "IsServiceODelete": boolean;
  "Is_Monday_Holiday_in_Jobsite__c": boolean;
  "Is_Tuesday_Holiday_in_Jobsite__c": boolean;
  "Is_Wednesday_Holiday_in_Jobsite__c": boolean;
  "Is_Thursday_Holiday_in_Jobsite__c": boolean;
  "Is_Friday_Holiday_in_Jobsite__c": boolean;
  "Is_Saturday_Holiday_in_Jobsite__c": boolean;
  "Is_Sunday_Holiday_in_Jobsite__c": boolean;
  "Is_Monday_Public_Holiday__c": boolean;
  "Is_Tuesday_Public_Holiday__c": boolean;
  "Is_Wednesday_Public_Holiday__c": boolean;
  "Is_Thursday_Public_Holiday__c": boolean;
  "Is_Friday_Public_Holiday__c": boolean;
  "Is_Saturday_Public_Holiday__c": boolean;
  "Is_Sunday_Public_Holiday__c": boolean;
  "Business_Start_Time__c": Date;
  "Signature_Date": Date;
  "Actual_Coverage_Hour__c": string;
  "Actual_End_Time_Coverage__c": string;
  "Approval_Levels_Required__c": string;
  "Auto_Created_From_Timeclock__c": boolean;
  "Automation_Message__c": string;
  "Break_Duration_Minutes__c": number;
  "Current_Approval_Level__c": string;
  "Day_of_Timesheet_Start_Date__c": string;
  "Difference_In_Out__c": number;
  "Friday_AFTH__c": number;
  "Friday_BEFH__c": number;
  "Friday_End_Time_From_Jobsite__c": string;
  "Friday_OT_Holiday__c": number;
  "Friday_start_Time_From_Jobsite__c": string;
  "Has_PI_Amount__c": boolean;
  "Has_SI_Amount__c": boolean;
  "Have_ICC_Access__c": boolean;
  "Have_PgMO_Access__c": boolean;
  "Health_Profit__c": string;
  "Health_SKU__c": string;
  "Health_Worker__c": string;
  "Incurred_Date_Month_Calculated__c": string;
  "Is_Holiday__c": boolean;
  "Is_Saturday_Holiday_in_Schedule_Stored__c": boolean;
  "Is_Sunday_Holiday_in_Schedule_Stored__c": boolean;
  "Job_Has_Jobsite__c": boolean;
  "Job_Has_Schedule_Ref__c": boolean;
  "Job_Number__c": string;
  "Job_Schedule_Hours__c": number;
  "Job_has_PO__c": boolean;
  "Job_has_SO__c": boolean;
  "Job_has_Vendor__c": boolean;
  "Last_Day_of_Month__c": Date;
  "Last_Day_of_previous_month__c": number;
  "Logged_in_Contact__c": string;
  "Monday_AFTH__c": number;
  "Monday_BEFH__c": number;
  "Monday_End_Time_From_Jobsite__c": string;
  "Monday_OT_Holiday__c": number;
  "Monday_Start_Time_From_Jobsite__c": string;
  "Month_Offset__c": number;
  "Month_of_Timesheet_Start_Date__c": string;
  "Need_SKU_Change_on_SO_PO__c": boolean;
  "Offset_Calculation_steps__c": string;
  "Partner_SG_Number__c": string;
  "Recordtypeid__c": string;
  "Refresh_SKU__c": boolean;
  "SC_Has_Custom_Price__c": boolean;
  "SO_List_Price__c": number;
  "Saturday_AFTH__c": number;
  "Saturday_BEFH__c": number;
  "Saturday_End_Time_From_Jobsite__c": string;
  "Saturday_OT_Holiday__c": number;
  "Saturday_Start_Time_From_Jobsite__c": string;
  "Service_Contract_Offset_Stored__c": number;
  "Service_Contract_Offset__c": number;
  "Sunday_AFTH__c": number;
  "Sunday_BEFH__c": number;
  "Sunday_OT_Holiday__c": number;
  "Sunday_End_Time_From_Jobsite__c": string;
  "Sunday_Start_Time_From_Jobsite__c": string;
  "Temp1__c": number;
  "Temp2__c": string;
  "Temp4__c": string;
  "Temp__c": string;
  "Thursday_AFTH__c": number;
  "Thursday_BEFH__c": number;
  "Thursday_OT_Holiday__c": number;
  "Thursday_End_Time_From_Jobsite__c": string;
  "Thursday_Start_Time_From_Jobsite__c": string;
  "Timecard_Normal_Hours__c": number;
  "Timecard_OT_AFTH_DNU__c": number;
  "Timecard_OT_AFTH__c": number;
  "Timecard_OT_Holiday__c": number;
  "Timecard_OT_STD__c": number;
  "Timecard_OT_Weekend_DNU__c": number;
  "Timecard_OT_Weekend_MF__c": number;
  "Timecard_OT_Weekend_SS__c": number;
  "Timecard_OT_Weekend__c": number;
  "Timesheet_OT_AFTH__c": number;
  "Timesheet_OT_WKND__c": number;
  "Timesheet_Short_Hours_Day_Calculations_P__c": number;
  "Tuesday_AFTH__c": number;
  "Tuesday_BEFH__c": number;
  "Tuesday_OT_Holiday__c": number;
  "Tuesday_End_Time_From_Jobsite__c": string;
  "Tuesday_start_Time_From_Jobsite__c": string;
  "Wednesday_AFTH__c": number;
  "Wednesday_BEFH__c": number;
  "Wednesday_OT_Holiday__c": number;
  "Wednesday_End_Time_From_Jobsite__c": string;
  "Wednesday_start_Time_From_Jobsite__c": string;
  "Worker_same_as_Job_Worker__c": boolean;
  "X1_Hours_minus_Created__c": Date;
  "X1st_Day_of_Month__c": Date;
  "Have_files": boolean;
  "File_URL": string;
  "Business_Start_Time_Formula__c": string;
  "Absence_Hours__c": number;
  "Total_Paid_Holiday_Hours__c": number;
  "Last_Day_of_Month_Stored__c": Date;
  "Month_Offset_Stored__c": number;
  "Purchase_Invoice_Ref__c": string;
  "Sales_Invoice_Ref__c": string;
  "Saturday_Job_Schedule_Hours_Stored__c": number;
  "Sunday_Job_Schedule_Hours_Stored__c": number;
  "Timesheet_Short_Hours_Day_Calculations__c": number;
  "Timesheet_Short_Hours_Days__c": number;
  "X1st_Day_of_Month_Stored__c": Date;
  "VerifyTimesheet__c": boolean;
  "Master_Timecard_Ref__c": string;
  "Equipment_Make_Model_Performed_on__c": string;
  "Equipment_Serial_Performed_on__c": string;
  "Worker_Mobile_OS_Android_iOS__c": string;
  "Replacement_SKU_for_PO__c": string;
  "Replacement_SKU_for_SO__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  vendor: Account;
  job: Job;
  users: Users;
  purchaseOrder: PurchaseOrder;
  worker: Worker;
  recordType: RecordType;
  constructor(data?: TimecardInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Timecard`.
   */
  public static getModelName() {
    return "Timecard";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Timecard for dynamic purposes.
  **/
  public static factory(data: TimecardInterface): Timecard{
    return new Timecard(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Timecard',
      plural: 'Timecards',
      path: 'Timecards',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "Agreed_Rate_on_Service_Dispatch__c": {
          name: 'Agreed_Rate_on_Service_Dispatch__c',
          type: 'number'
        },
        "Any_Observation_or_Suggestions_Notes__c": {
          name: 'Any_Observation_or_Suggestions_Notes__c',
          type: 'string'
        },
        "At_least_One_day_worked__c": {
          name: 'At_least_One_day_worked__c',
          type: 'boolean',
          default: false
        },
        "Both_Approved__c": {
          name: 'Both_Approved__c',
          type: 'boolean',
          default: false
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "Customer_Approval_Status__c": {
          name: 'Customer_Approval_Status__c',
          type: 'string'
        },
        "Customer_Approval_Time__c": {
          name: 'Customer_Approval_Time__c',
          type: 'number'
        },
        "Customer_Site_Signoff_Name__c": {
          name: 'Customer_Site_Signoff_Name__c',
          type: 'string'
        },
        "Customerflag__c": {
          name: 'Customerflag__c',
          type: 'boolean',
          default: false
        },
        "Difference_Between_Job_Schedule_Actual__c": {
          name: 'Difference_Between_Job_Schedule_Actual__c',
          type: 'number'
        },
        "Final_Timecard_Already_Submitted__c": {
          name: 'Final_Timecard_Already_Submitted__c',
          type: 'boolean',
          default: false
        },
        "Final_Timecard__c": {
          name: 'Final_Timecard__c',
          type: 'boolean',
          default: false
        },
        "Friday_Hours_Actual__c": {
          name: 'Friday_Hours_Actual__c',
          type: 'number'
        },
        "Friday_Hours_Job_Schedule__c": {
          name: 'Friday_Hours_Job_Schedule__c',
          type: 'number'
        },
        "Friday_Job_Schedule_Hours__c": {
          name: 'Friday_Job_Schedule_Hours__c',
          type: 'number'
        },
        "Friday_Tasks_Performed__c": {
          name: 'Friday_Tasks_Performed__c',
          type: 'string'
        },
        "IRON_Approval_Time__c": {
          name: 'IRON_Approval_Time__c',
          type: 'number'
        },
        "Incurred_Date__c": {
          name: 'Incurred_Date__c',
          type: 'Date'
        },
        "Ironflag__c": {
          name: 'Ironflag__c',
          type: 'boolean',
          default: false
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "Is_Friday_Holiday_in_Schedule__c": {
          name: 'Is_Friday_Holiday_in_Schedule__c',
          type: 'boolean'
        },
        "Is_Monday_Holiday_in_Schedule__c": {
          name: 'Is_Monday_Holiday_in_Schedule__c',
          type: 'boolean'
        },
        "Is_Saturday_Holiday_in_Schedule__c": {
          name: 'Is_Saturday_Holiday_in_Schedule__c',
          type: 'boolean'
        },
        "Is_Sunday_Holiday_in_Schedule__c": {
          name: 'Is_Sunday_Holiday_in_Schedule__c',
          type: 'boolean'
        },
        "Is_Thursday_Holiday_in_Schedule__c": {
          name: 'Is_Thursday_Holiday_in_Schedule__c',
          type: 'boolean'
        },
        "Is_Tuesday_Holiday_in_Schedule__c": {
          name: 'Is_Tuesday_Holiday_in_Schedule__c',
          type: 'boolean'
        },
        "Is_Wednesday_Holiday_in_Schedule__c": {
          name: 'Is_Wednesday_Holiday_in_Schedule__c',
          type: 'boolean'
        },
        "LastActivityDate": {
          name: 'LastActivityDate',
          type: 'Date'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "LastReferencedDate": {
          name: 'LastReferencedDate',
          type: 'Date'
        },
        "LastViewedDate": {
          name: 'LastViewedDate',
          type: 'Date'
        },
        "Monday_Hours_Job_Schedule__c": {
          name: 'Monday_Hours_Job_Schedule__c',
          type: 'number'
        },
        "Monday_Hours__c": {
          name: 'Monday_Hours__c',
          type: 'number'
        },
        "Monday_Job_Schedule_Hours__c": {
          name: 'Monday_Job_Schedule_Hours__c',
          type: 'number'
        },
        "Monday_Task_Performed__c": {
          name: 'Monday_Task_Performed__c',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "Need_Approval_Auto__c": {
          name: 'Need_Approval_Auto__c',
          type: 'boolean',
          default: false
        },
        "Need_Approval__c": {
          name: 'Need_Approval__c',
          type: 'boolean'
        },
        "Overtime_Hours_Manual__c": {
          name: 'Overtime_Hours_Manual__c',
          type: 'number'
        },
        "Overtime_Hours__c": {
          name: 'Overtime_Hours__c',
          type: 'number'
        },
        "Overtime_Timecard_Hours__c": {
          name: 'Overtime_Timecard_Hours__c',
          type: 'number'
        },
        "Overtime__c": {
          name: 'Overtime__c',
          type: 'boolean',
          default: false
        },
        "PPE_Hours_on_Dispatch__c": {
          name: 'PPE_Hours_on_Dispatch__c',
          type: 'number'
        },
        "Project_Jobsite_Project__c": {
          name: 'Project_Jobsite_Project__c',
          type: 'string'
        },
        "Purchase_Invoice_Created__c": {
          name: 'Purchase_Invoice_Created__c',
          type: 'boolean',
          default: false
        },
        "Purchase_Order_Ref__c": {
          name: 'Purchase_Order_Ref__c',
          type: 'string'
        },
        "RecordTypeId": {
          name: 'RecordTypeId',
          type: 'string'
        },
        "Refresh_Flag__c": {
          name: 'Refresh_Flag__c',
          type: 'boolean'
        },
        "Sales_Invoice_Created__c": {
          name: 'Sales_Invoice_Created__c',
          type: 'boolean',
          default: false
        },
        "Sales_Order_Ref__c": {
          name: 'Sales_Order_Ref__c',
          type: 'string'
        },
        "Saturday_Hours_Actual__c": {
          name: 'Saturday_Hours_Actual__c',
          type: 'number'
        },
        "Saturday_Hours_Job_Schedule__c": {
          name: 'Saturday_Hours_Job_Schedule__c',
          type: 'number'
        },
        "Saturday_Job_Schedule_Hours__c": {
          name: 'Saturday_Job_Schedule_Hours__c',
          type: 'number'
        },
        "Saturday_Tasks_Performed__c": {
          name: 'Saturday_Tasks_Performed__c',
          type: 'string'
        },
        "Service_Dispatch__c": {
          name: 'Service_Dispatch__c',
          type: 'string'
        },
        "Status__c": {
          name: 'Status__c',
          type: 'string'
        },
        "Sunday_Hours_Actual__c": {
          name: 'Sunday_Hours_Actual__c',
          type: 'number'
        },
        "Sunday_Hours_Job_Schedule__c": {
          name: 'Sunday_Hours_Job_Schedule__c',
          type: 'number'
        },
        "Sunday_Job_Schedule_Hours__c": {
          name: 'Sunday_Job_Schedule_Hours__c',
          type: 'number'
        },
        "Sunday_Tasks_Performed__c": {
          name: 'Sunday_Tasks_Performed__c',
          type: 'string'
        },
        "Thursday_Hours_Actual__c": {
          name: 'Thursday_Hours_Actual__c',
          type: 'number'
        },
        "Thursday_Hours_Job_Schedule__c": {
          name: 'Thursday_Hours_Job_Schedule__c',
          type: 'number'
        },
        "Thursday_Job_Schedule_Hours__c": {
          name: 'Thursday_Job_Schedule_Hours__c',
          type: 'number'
        },
        "Thursday_Tasks_Performed__c": {
          name: 'Thursday_Tasks_Performed__c',
          type: 'string'
        },
        "Time_Card_Approved_Customer_Date_Time__c": {
          name: 'Time_Card_Approved_Customer_Date_Time__c',
          type: 'Date'
        },
        "Time_Card_Approved_IRON_Date_Time__c": {
          name: 'Time_Card_Approved_IRON_Date_Time__c',
          type: 'Date'
        },
        "Time_Card_Created_Date_Time__c": {
          name: 'Time_Card_Created_Date_Time__c',
          type: 'Date'
        },
        "Time_Card_Submitted_for_Approval_Date__c": {
          name: 'Time_Card_Submitted_for_Approval_Date__c',
          type: 'Date'
        },
        "Timecard_Calc_Base_Bk__c": {
          name: 'Timecard_Calc_Base_Bk__c',
          type: 'number'
        },
        "Timecard_Calc_Base_Final__c": {
          name: 'Timecard_Calc_Base_Final__c',
          type: 'number'
        },
        "Timescard_Increment_Offset__c": {
          name: 'Timescard_Increment_Offset__c',
          type: 'number'
        },
        "Timesheet_End_Week__c": {
          name: 'Timesheet_End_Week__c',
          type: 'Date'
        },
        "Timesheet_Hours_Calc_Base_Bk__c": {
          name: 'Timesheet_Hours_Calc_Base_Bk__c',
          type: 'number'
        },
        "Timesheet_Hours_Calc_Base__c": {
          name: 'Timesheet_Hours_Calc_Base__c',
          type: 'number'
        },
        "Timesheet_Hours_Calc_Rounded__c": {
          name: 'Timesheet_Hours_Calc_Rounded__c',
          type: 'number'
        },
        "Timesheet_Hours_Calc__c": {
          name: 'Timesheet_Hours_Calc__c',
          type: 'number'
        },
        "Timesheet_Month__c": {
          name: 'Timesheet_Month__c',
          type: 'string'
        },
        "Timesheet_Start_Date__c": {
          name: 'Timesheet_Start_Date__c',
          type: 'Date'
        },
        "Timesheet_Start_Week__c": {
          name: 'Timesheet_Start_Week__c',
          type: 'Date'
        },
        "Timesheet_Week_Number_ISO_Auto__c": {
          name: 'Timesheet_Week_Number_ISO_Auto__c',
          type: 'string'
        },
        "Timesheet_Week_Number_ISO__c": {
          name: 'Timesheet_Week_Number_ISO__c',
          type: 'number'
        },
        "Timesheet_Year__c": {
          name: 'Timesheet_Year__c',
          type: 'number'
        },
        "Total_Approved_Timecard_Hours__c": {
          name: 'Total_Approved_Timecard_Hours__c',
          type: 'number'
        },
        "Total_Number_of_Timecards_Submitted__c": {
          name: 'Total_Number_of_Timecards_Submitted__c',
          type: 'number'
        },
        "Total_Overtime_Hours_Holiday_Weekday__c": {
          name: 'Total_Overtime_Hours_Holiday_Weekday__c',
          type: 'number'
        },
        "Total_Overtime_Hours_Holiday_Weekend__c": {
          name: 'Total_Overtime_Hours_Holiday_Weekend__c',
          type: 'number'
        },
        "Total_Overtime_Hours_Standard_Weekday__c": {
          name: 'Total_Overtime_Hours_Standard_Weekday__c',
          type: 'number'
        },
        "Total_Overtime_Hours_Standard_Weekend__c": {
          name: 'Total_Overtime_Hours_Standard_Weekend__c',
          type: 'number'
        },
        "Total_Planned_Hours_Job_Schedule__c": {
          name: 'Total_Planned_Hours_Job_Schedule__c',
          type: 'number'
        },
        "Total_Short_Worked_Hours__c": {
          name: 'Total_Short_Worked_Hours__c',
          type: 'number'
        },
        "Total_Timecard_Hours__c": {
          name: 'Total_Timecard_Hours__c',
          type: 'number'
        },
        "Total_Timesheet_Hours__c": {
          name: 'Total_Timesheet_Hours__c',
          type: 'number'
        },
        "Total_Worked_Hours_Without_Offset__c": {
          name: 'Total_Worked_Hours_Without_Offset__c',
          type: 'number'
        },
        "Total_Worked_Hours__c": {
          name: 'Total_Worked_Hours__c',
          type: 'number'
        },
        "Tuesday_Hours_Actual__c": {
          name: 'Tuesday_Hours_Actual__c',
          type: 'number'
        },
        "Tuesday_Hours_Job_Schedule__c": {
          name: 'Tuesday_Hours_Job_Schedule__c',
          type: 'number'
        },
        "Tuesday_Job_Schedule_Hours__c": {
          name: 'Tuesday_Job_Schedule_Hours__c',
          type: 'number'
        },
        "Tuesday_Tasks_Performed__c": {
          name: 'Tuesday_Tasks_Performed__c',
          type: 'string'
        },
        "Type__c": {
          name: 'Type__c',
          type: 'string'
        },
        "Vendor_Auto__c": {
          name: 'Vendor_Auto__c',
          type: 'string'
        },
        "Vendor_Daily_Hours__c": {
          name: 'Vendor_Daily_Hours__c',
          type: 'number'
        },
        "Vendor_Time_Card_Notes_Tasks_Performed__c": {
          name: 'Vendor_Time_Card_Notes_Tasks_Performed__c',
          type: 'string'
        },
        "Vendor_Time_Card_Time_Out_Actual__c": {
          name: 'Vendor_Time_Card_Time_Out_Actual__c',
          type: 'Date'
        },
        "Vendor_Time_Card_Time_in_Actual__c": {
          name: 'Vendor_Time_Card_Time_in_Actual__c',
          type: 'Date'
        },
        "Vendor__c": {
          name: 'Vendor__c',
          type: 'string'
        },
        "Vendor_on_Job__c": {
          name: 'Vendor_on_Job__c',
          type: 'string'
        },
        "Vendor_time_Actuals_Calc__c": {
          name: 'Vendor_time_Actuals_Calc__c',
          type: 'string'
        },
        "Visit_Final__c": {
          name: 'Visit_Final__c',
          type: 'number'
        },
        "Visit_Number_Calc__c": {
          name: 'Visit_Number_Calc__c',
          type: 'number'
        },
        "Wednesday_Hours_Actual__c": {
          name: 'Wednesday_Hours_Actual__c',
          type: 'number'
        },
        "Wednesday_Hours_Job_Schedule__c": {
          name: 'Wednesday_Hours_Job_Schedule__c',
          type: 'number'
        },
        "Wednesday_Job_Schedule_Hours__c": {
          name: 'Wednesday_Job_Schedule_Hours__c',
          type: 'number'
        },
        "Wednesday_Tasks_Performed__c": {
          name: 'Wednesday_Tasks_Performed__c',
          type: 'string'
        },
        "Week_Start_Date__c": {
          name: 'Week_Start_Date__c',
          type: 'Date'
        },
        "Weekend_Hours_Worked__c": {
          name: 'Weekend_Hours_Worked__c',
          type: 'number'
        },
        "Worker_Auto__c": {
          name: 'Worker_Auto__c',
          type: 'string'
        },
        "Worker_Name__c": {
          name: 'Worker_Name__c',
          type: 'string'
        },
        "Worker__c": {
          name: 'Worker__c',
          type: 'string'
        },
        "Worker_on_Job__c": {
          name: 'Worker_on_Job__c',
          type: 'string'
        },
        "update_PO_Flag__c": {
          name: 'update_PO_Flag__c',
          type: 'boolean',
          default: false
        },
        "Signature_Image": {
          name: 'Signature_Image',
          type: 'string'
        },
        "Recipient_Name": {
          name: 'Recipient_Name',
          type: 'string'
        },
        "IsServiceODelete": {
          name: 'IsServiceODelete',
          type: 'boolean'
        },
        "Is_Monday_Holiday_in_Jobsite__c": {
          name: 'Is_Monday_Holiday_in_Jobsite__c',
          type: 'boolean'
        },
        "Is_Tuesday_Holiday_in_Jobsite__c": {
          name: 'Is_Tuesday_Holiday_in_Jobsite__c',
          type: 'boolean'
        },
        "Is_Wednesday_Holiday_in_Jobsite__c": {
          name: 'Is_Wednesday_Holiday_in_Jobsite__c',
          type: 'boolean'
        },
        "Is_Thursday_Holiday_in_Jobsite__c": {
          name: 'Is_Thursday_Holiday_in_Jobsite__c',
          type: 'boolean'
        },
        "Is_Friday_Holiday_in_Jobsite__c": {
          name: 'Is_Friday_Holiday_in_Jobsite__c',
          type: 'boolean'
        },
        "Is_Saturday_Holiday_in_Jobsite__c": {
          name: 'Is_Saturday_Holiday_in_Jobsite__c',
          type: 'boolean'
        },
        "Is_Sunday_Holiday_in_Jobsite__c": {
          name: 'Is_Sunday_Holiday_in_Jobsite__c',
          type: 'boolean'
        },
        "Is_Monday_Public_Holiday__c": {
          name: 'Is_Monday_Public_Holiday__c',
          type: 'boolean'
        },
        "Is_Tuesday_Public_Holiday__c": {
          name: 'Is_Tuesday_Public_Holiday__c',
          type: 'boolean'
        },
        "Is_Wednesday_Public_Holiday__c": {
          name: 'Is_Wednesday_Public_Holiday__c',
          type: 'boolean'
        },
        "Is_Thursday_Public_Holiday__c": {
          name: 'Is_Thursday_Public_Holiday__c',
          type: 'boolean'
        },
        "Is_Friday_Public_Holiday__c": {
          name: 'Is_Friday_Public_Holiday__c',
          type: 'boolean'
        },
        "Is_Saturday_Public_Holiday__c": {
          name: 'Is_Saturday_Public_Holiday__c',
          type: 'boolean'
        },
        "Is_Sunday_Public_Holiday__c": {
          name: 'Is_Sunday_Public_Holiday__c',
          type: 'boolean'
        },
        "Business_Start_Time__c": {
          name: 'Business_Start_Time__c',
          type: 'Date'
        },
        "Signature_Date": {
          name: 'Signature_Date',
          type: 'Date'
        },
        "Actual_Coverage_Hour__c": {
          name: 'Actual_Coverage_Hour__c',
          type: 'string'
        },
        "Actual_End_Time_Coverage__c": {
          name: 'Actual_End_Time_Coverage__c',
          type: 'string'
        },
        "Approval_Levels_Required__c": {
          name: 'Approval_Levels_Required__c',
          type: 'string'
        },
        "Auto_Created_From_Timeclock__c": {
          name: 'Auto_Created_From_Timeclock__c',
          type: 'boolean'
        },
        "Automation_Message__c": {
          name: 'Automation_Message__c',
          type: 'string'
        },
        "Break_Duration_Minutes__c": {
          name: 'Break_Duration_Minutes__c',
          type: 'number'
        },
        "Current_Approval_Level__c": {
          name: 'Current_Approval_Level__c',
          type: 'string'
        },
        "Day_of_Timesheet_Start_Date__c": {
          name: 'Day_of_Timesheet_Start_Date__c',
          type: 'string'
        },
        "Difference_In_Out__c": {
          name: 'Difference_In_Out__c',
          type: 'number'
        },
        "Friday_AFTH__c": {
          name: 'Friday_AFTH__c',
          type: 'number'
        },
        "Friday_BEFH__c": {
          name: 'Friday_BEFH__c',
          type: 'number'
        },
        "Friday_End_Time_From_Jobsite__c": {
          name: 'Friday_End_Time_From_Jobsite__c',
          type: 'string'
        },
        "Friday_OT_Holiday__c": {
          name: 'Friday_OT_Holiday__c',
          type: 'number'
        },
        "Friday_start_Time_From_Jobsite__c": {
          name: 'Friday_start_Time_From_Jobsite__c',
          type: 'string'
        },
        "Has_PI_Amount__c": {
          name: 'Has_PI_Amount__c',
          type: 'boolean'
        },
        "Has_SI_Amount__c": {
          name: 'Has_SI_Amount__c',
          type: 'boolean'
        },
        "Have_ICC_Access__c": {
          name: 'Have_ICC_Access__c',
          type: 'boolean'
        },
        "Have_PgMO_Access__c": {
          name: 'Have_PgMO_Access__c',
          type: 'boolean'
        },
        "Health_Profit__c": {
          name: 'Health_Profit__c',
          type: 'string'
        },
        "Health_SKU__c": {
          name: 'Health_SKU__c',
          type: 'string'
        },
        "Health_Worker__c": {
          name: 'Health_Worker__c',
          type: 'string'
        },
        "Incurred_Date_Month_Calculated__c": {
          name: 'Incurred_Date_Month_Calculated__c',
          type: 'string'
        },
        "Is_Holiday__c": {
          name: 'Is_Holiday__c',
          type: 'boolean'
        },
        "Is_Saturday_Holiday_in_Schedule_Stored__c": {
          name: 'Is_Saturday_Holiday_in_Schedule_Stored__c',
          type: 'boolean'
        },
        "Is_Sunday_Holiday_in_Schedule_Stored__c": {
          name: 'Is_Sunday_Holiday_in_Schedule_Stored__c',
          type: 'boolean'
        },
        "Job_Has_Jobsite__c": {
          name: 'Job_Has_Jobsite__c',
          type: 'boolean'
        },
        "Job_Has_Schedule_Ref__c": {
          name: 'Job_Has_Schedule_Ref__c',
          type: 'boolean'
        },
        "Job_Number__c": {
          name: 'Job_Number__c',
          type: 'string'
        },
        "Job_Schedule_Hours__c": {
          name: 'Job_Schedule_Hours__c',
          type: 'number'
        },
        "Job_has_PO__c": {
          name: 'Job_has_PO__c',
          type: 'boolean'
        },
        "Job_has_SO__c": {
          name: 'Job_has_SO__c',
          type: 'boolean'
        },
        "Job_has_Vendor__c": {
          name: 'Job_has_Vendor__c',
          type: 'boolean'
        },
        "Last_Day_of_Month__c": {
          name: 'Last_Day_of_Month__c',
          type: 'Date'
        },
        "Last_Day_of_previous_month__c": {
          name: 'Last_Day_of_previous_month__c',
          type: 'number'
        },
        "Logged_in_Contact__c": {
          name: 'Logged_in_Contact__c',
          type: 'string'
        },
        "Monday_AFTH__c": {
          name: 'Monday_AFTH__c',
          type: 'number'
        },
        "Monday_BEFH__c": {
          name: 'Monday_BEFH__c',
          type: 'number'
        },
        "Monday_End_Time_From_Jobsite__c": {
          name: 'Monday_End_Time_From_Jobsite__c',
          type: 'string'
        },
        "Monday_OT_Holiday__c": {
          name: 'Monday_OT_Holiday__c',
          type: 'number'
        },
        "Monday_Start_Time_From_Jobsite__c": {
          name: 'Monday_Start_Time_From_Jobsite__c',
          type: 'string'
        },
        "Month_Offset__c": {
          name: 'Month_Offset__c',
          type: 'number'
        },
        "Month_of_Timesheet_Start_Date__c": {
          name: 'Month_of_Timesheet_Start_Date__c',
          type: 'string'
        },
        "Need_SKU_Change_on_SO_PO__c": {
          name: 'Need_SKU_Change_on_SO_PO__c',
          type: 'boolean'
        },
        "Offset_Calculation_steps__c": {
          name: 'Offset_Calculation_steps__c',
          type: 'string'
        },
        "Partner_SG_Number__c": {
          name: 'Partner_SG_Number__c',
          type: 'string'
        },
        "Recordtypeid__c": {
          name: 'Recordtypeid__c',
          type: 'string'
        },
        "Refresh_SKU__c": {
          name: 'Refresh_SKU__c',
          type: 'boolean'
        },
        "SC_Has_Custom_Price__c": {
          name: 'SC_Has_Custom_Price__c',
          type: 'boolean'
        },
        "SO_List_Price__c": {
          name: 'SO_List_Price__c',
          type: 'number'
        },
        "Saturday_AFTH__c": {
          name: 'Saturday_AFTH__c',
          type: 'number'
        },
        "Saturday_BEFH__c": {
          name: 'Saturday_BEFH__c',
          type: 'number'
        },
        "Saturday_End_Time_From_Jobsite__c": {
          name: 'Saturday_End_Time_From_Jobsite__c',
          type: 'string'
        },
        "Saturday_OT_Holiday__c": {
          name: 'Saturday_OT_Holiday__c',
          type: 'number'
        },
        "Saturday_Start_Time_From_Jobsite__c": {
          name: 'Saturday_Start_Time_From_Jobsite__c',
          type: 'string'
        },
        "Service_Contract_Offset_Stored__c": {
          name: 'Service_Contract_Offset_Stored__c',
          type: 'number'
        },
        "Service_Contract_Offset__c": {
          name: 'Service_Contract_Offset__c',
          type: 'number'
        },
        "Sunday_AFTH__c": {
          name: 'Sunday_AFTH__c',
          type: 'number'
        },
        "Sunday_BEFH__c": {
          name: 'Sunday_BEFH__c',
          type: 'number'
        },
        "Sunday_OT_Holiday__c": {
          name: 'Sunday_OT_Holiday__c',
          type: 'number'
        },
        "Sunday_End_Time_From_Jobsite__c": {
          name: 'Sunday_End_Time_From_Jobsite__c',
          type: 'string'
        },
        "Sunday_Start_Time_From_Jobsite__c": {
          name: 'Sunday_Start_Time_From_Jobsite__c',
          type: 'string'
        },
        "Temp1__c": {
          name: 'Temp1__c',
          type: 'number'
        },
        "Temp2__c": {
          name: 'Temp2__c',
          type: 'string'
        },
        "Temp4__c": {
          name: 'Temp4__c',
          type: 'string'
        },
        "Temp__c": {
          name: 'Temp__c',
          type: 'string'
        },
        "Thursday_AFTH__c": {
          name: 'Thursday_AFTH__c',
          type: 'number'
        },
        "Thursday_BEFH__c": {
          name: 'Thursday_BEFH__c',
          type: 'number'
        },
        "Thursday_OT_Holiday__c": {
          name: 'Thursday_OT_Holiday__c',
          type: 'number'
        },
        "Thursday_End_Time_From_Jobsite__c": {
          name: 'Thursday_End_Time_From_Jobsite__c',
          type: 'string'
        },
        "Thursday_Start_Time_From_Jobsite__c": {
          name: 'Thursday_Start_Time_From_Jobsite__c',
          type: 'string'
        },
        "Timecard_Normal_Hours__c": {
          name: 'Timecard_Normal_Hours__c',
          type: 'number'
        },
        "Timecard_OT_AFTH_DNU__c": {
          name: 'Timecard_OT_AFTH_DNU__c',
          type: 'number'
        },
        "Timecard_OT_AFTH__c": {
          name: 'Timecard_OT_AFTH__c',
          type: 'number'
        },
        "Timecard_OT_Holiday__c": {
          name: 'Timecard_OT_Holiday__c',
          type: 'number'
        },
        "Timecard_OT_STD__c": {
          name: 'Timecard_OT_STD__c',
          type: 'number'
        },
        "Timecard_OT_Weekend_DNU__c": {
          name: 'Timecard_OT_Weekend_DNU__c',
          type: 'number'
        },
        "Timecard_OT_Weekend_MF__c": {
          name: 'Timecard_OT_Weekend_MF__c',
          type: 'number'
        },
        "Timecard_OT_Weekend_SS__c": {
          name: 'Timecard_OT_Weekend_SS__c',
          type: 'number'
        },
        "Timecard_OT_Weekend__c": {
          name: 'Timecard_OT_Weekend__c',
          type: 'number'
        },
        "Timesheet_OT_AFTH__c": {
          name: 'Timesheet_OT_AFTH__c',
          type: 'number'
        },
        "Timesheet_OT_WKND__c": {
          name: 'Timesheet_OT_WKND__c',
          type: 'number'
        },
        "Timesheet_Short_Hours_Day_Calculations_P__c": {
          name: 'Timesheet_Short_Hours_Day_Calculations_P__c',
          type: 'number'
        },
        "Tuesday_AFTH__c": {
          name: 'Tuesday_AFTH__c',
          type: 'number'
        },
        "Tuesday_BEFH__c": {
          name: 'Tuesday_BEFH__c',
          type: 'number'
        },
        "Tuesday_OT_Holiday__c": {
          name: 'Tuesday_OT_Holiday__c',
          type: 'number'
        },
        "Tuesday_End_Time_From_Jobsite__c": {
          name: 'Tuesday_End_Time_From_Jobsite__c',
          type: 'string'
        },
        "Tuesday_start_Time_From_Jobsite__c": {
          name: 'Tuesday_start_Time_From_Jobsite__c',
          type: 'string'
        },
        "Wednesday_AFTH__c": {
          name: 'Wednesday_AFTH__c',
          type: 'number'
        },
        "Wednesday_BEFH__c": {
          name: 'Wednesday_BEFH__c',
          type: 'number'
        },
        "Wednesday_OT_Holiday__c": {
          name: 'Wednesday_OT_Holiday__c',
          type: 'number'
        },
        "Wednesday_End_Time_From_Jobsite__c": {
          name: 'Wednesday_End_Time_From_Jobsite__c',
          type: 'string'
        },
        "Wednesday_start_Time_From_Jobsite__c": {
          name: 'Wednesday_start_Time_From_Jobsite__c',
          type: 'string'
        },
        "Worker_same_as_Job_Worker__c": {
          name: 'Worker_same_as_Job_Worker__c',
          type: 'boolean'
        },
        "X1_Hours_minus_Created__c": {
          name: 'X1_Hours_minus_Created__c',
          type: 'Date'
        },
        "X1st_Day_of_Month__c": {
          name: 'X1st_Day_of_Month__c',
          type: 'Date'
        },
        "Have_files": {
          name: 'Have_files',
          type: 'boolean'
        },
        "File_URL": {
          name: 'File_URL',
          type: 'string'
        },
        "Business_Start_Time_Formula__c": {
          name: 'Business_Start_Time_Formula__c',
          type: 'string'
        },
        "Absence_Hours__c": {
          name: 'Absence_Hours__c',
          type: 'number'
        },
        "Total_Paid_Holiday_Hours__c": {
          name: 'Total_Paid_Holiday_Hours__c',
          type: 'number'
        },
        "Last_Day_of_Month_Stored__c": {
          name: 'Last_Day_of_Month_Stored__c',
          type: 'Date'
        },
        "Month_Offset_Stored__c": {
          name: 'Month_Offset_Stored__c',
          type: 'number'
        },
        "Purchase_Invoice_Ref__c": {
          name: 'Purchase_Invoice_Ref__c',
          type: 'string'
        },
        "Sales_Invoice_Ref__c": {
          name: 'Sales_Invoice_Ref__c',
          type: 'string'
        },
        "Saturday_Job_Schedule_Hours_Stored__c": {
          name: 'Saturday_Job_Schedule_Hours_Stored__c',
          type: 'number'
        },
        "Sunday_Job_Schedule_Hours_Stored__c": {
          name: 'Sunday_Job_Schedule_Hours_Stored__c',
          type: 'number'
        },
        "Timesheet_Short_Hours_Day_Calculations__c": {
          name: 'Timesheet_Short_Hours_Day_Calculations__c',
          type: 'number'
        },
        "Timesheet_Short_Hours_Days__c": {
          name: 'Timesheet_Short_Hours_Days__c',
          type: 'number'
        },
        "X1st_Day_of_Month_Stored__c": {
          name: 'X1st_Day_of_Month_Stored__c',
          type: 'Date'
        },
        "VerifyTimesheet__c": {
          name: 'VerifyTimesheet__c',
          type: 'boolean'
        },
        "Master_Timecard_Ref__c": {
          name: 'Master_Timecard_Ref__c',
          type: 'string'
        },
        "Equipment_Make_Model_Performed_on__c": {
          name: 'Equipment_Make_Model_Performed_on__c',
          type: 'string'
        },
        "Equipment_Serial_Performed_on__c": {
          name: 'Equipment_Serial_Performed_on__c',
          type: 'string'
        },
        "Worker_Mobile_OS_Android_iOS__c": {
          name: 'Worker_Mobile_OS_Android_iOS__c',
          type: 'string'
        },
        "Replacement_SKU_for_PO__c": {
          name: 'Replacement_SKU_for_PO__c',
          type: 'string'
        },
        "Replacement_SKU_for_SO__c": {
          name: 'Replacement_SKU_for_SO__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        vendor: {
          name: 'vendor',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'Vendor__c',
          keyTo: 'sfdcId'
        },
        job: {
          name: 'job',
          type: 'Job',
          model: 'Job',
          relationType: 'belongsTo',
                  keyFrom: 'Service_Dispatch__c',
          keyTo: 'sfdcId'
        },
        users: {
          name: 'users',
          type: 'Users',
          model: 'Users',
          relationType: 'belongsTo',
                  keyFrom: 'CreatedById',
          keyTo: 'sfdcId'
        },
        purchaseOrder: {
          name: 'purchaseOrder',
          type: 'PurchaseOrder',
          model: 'PurchaseOrder',
          relationType: 'belongsTo',
                  keyFrom: 'Purchase_Order_Ref__c',
          keyTo: 'sfdcId'
        },
        worker: {
          name: 'worker',
          type: 'Worker',
          model: 'Worker',
          relationType: 'belongsTo',
                  keyFrom: 'Worker__c',
          keyTo: 'sfdcId'
        },
        recordType: {
          name: 'recordType',
          type: 'RecordType',
          model: 'RecordType',
          relationType: 'belongsTo',
                  keyFrom: 'RecordTypeId',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
