import { Component, OnInit, Input } from '@angular/core';
import { CaseApi } from 'shared/sdk';
import { PreloaderService } from 'shared/services/preloader.service';

@Component({
  selector: 'app-deliverables',
  templateUrl: './deliverables.component.html',
  styleUrls: ['./deliverables.component.css']
})
export class DeliverablesComponent implements OnInit {

  @Input() caseData;

  deliverableStatus: any = '';
  error: String = '';
  constructor(
    private _caseApi: CaseApi,
    private _preloaderService: PreloaderService
  ) { }

  ngOnInit() {
    this.getDeliverableData();
  }

  getDeliverableData = () => {
    if (this.caseData.iccCaseSfdcId) {
      this._preloaderService.showPreloader();
      this._caseApi.find({
        where: { 'sfdcId': this.caseData.iccCaseSfdcId },
        fields: ['id', 'sfdcId', 'Service_Dispatch__c'],
        include: [
          {
            relation: 'Job',
            scope: {
              fields: ['id', 'sfdcId', 'Deliverable_Status__c']
            }
          }
        ]
      }).subscribe(deliverableData => {
        this._preloaderService.hidePreloader();
        if (deliverableData && deliverableData.length) {
          this.deliverableStatus = deliverableData[0] && deliverableData[0]['Job'] && deliverableData[0]['Job'].Deliverable_Status__c
        } else {
          this.error = 'No Data Found.'
        }
      }, err => {
        this._preloaderService.hidePreloader();
      })
    }
  }

}
