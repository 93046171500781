import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { isServiceoLogin } from '../../../models/static-list-data.service'

@Component({
  selector: 'app-serviceo-login-lookup',
  templateUrl: './serviceo-login-lookup.component.html',
  styleUrls: ['./serviceo-login-lookup.component.css']
})
export class ServiceoLoginLookupComponent implements OnInit {

  serviceoLoginList = [];
  isServiceoLoginSelected = [];
  isMultipleSelected = true;

  @Input() set resetData(isClear) {
    if (isClear) {
      this.isServiceoLoginSelected = [];
    }
  }
  @Input() maxSelectedItems = 1;
  @Input() selectedDefaultUser;
  @Input() from;

  @Output() selectedisServiceoLogin: EventEmitter<any> = new EventEmitter;


  constructor() { }

  ngOnInit() {
    this.serviceoLoginList = isServiceoLogin;
    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : ''
    this.isServiceoLoginSelected = (preselected &&
      preselected['isServiceoLogin']) ? preselected['isServiceoLogin'] : ['true'];
    this.onServiceoLoginChange();
  }

  onServiceoLoginChange() {
    this.selectedisServiceoLogin.emit(this.isServiceoLoginSelected);
  }

  clearServiceoLogin() {
    this.isServiceoLoginSelected = [];
    this.selectedisServiceoLogin.emit([]);
  }

}
