import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-job-summary',
    templateUrl: './job-summary.component.html',
    styleUrls: ['./job-summary.component.css']
})
export class JobSummaryComponent implements OnInit {

    @Input()
    set data(e) {
        this.job = {};
        if (e && e.job) {
            this.job = e;
        }
    }

    job: any = {};

    constructor() { }

    ngOnInit() { }

    onLinkClick() {
        let url = '/vms/jobs/view/' + this.job.sfdcId;
        window.open(url, '_blank');
    }
}
