/* tslint:disable */
import {
  Account,
  Job,
  Project,
  JobsiteProjects,
  Contact,
  CountryCode,
  GeoMetro,
  JobsiteContact,
  GeoPoint
} from '../index';

declare var Object: any;
export interface JobsiteInterface {
  "sfdcId"?: string;
  "Account__c"?: string;
  "City__c"?: string;
  "Count_of_Service_Desk_Contacts__c"?: number;
  "Count_of_Technical_Escalation_Contacts__c"?: number;
  "Country__c"?: string;
  "CreatedById"?: string;
  "CreatedDate"?: Date;
  "GEO_Metro__c"?: string;
  "Jobsite_ID__c"?: string;
  "Jobsite_Status__c"?: string;
  "LastModifiedById"?: string;
  "LastModifiedDate"?: Date;
  "Name"?: string;
  "State__c"?: string;
  "Street__c"?: string;
  "Type__c"?: string;
  "Zip__c"?: string;
  "Contact_Email__c"?: string;
  "Contact_Phone__c"?: string;
  "Jobsite_Description__c"?: string;
  "Jobsite_Key_Contact__c"?: string;
  "Partner__c"?: string;
  "partner_provided_Jobsite_id__c"?: string;
  "geolocation__c"?: GeoPoint;
  "geolocation__Latitude__s"?: number;
  "geolocation__Longitude__s"?: number;
  "Jobsite_Approval_Status__c"?: string;
  "Time_Zone__c"?: string;
  "Abbreviation__c"?: string;
  "CurrencyIsoCode"?: string;
  "IsDeleted"?: boolean;
  "Jobsite_Safe_Id__c"?: string;
  "LastReferencedDate"?: Date;
  "LastViewedDate"?: Date;
  "StateManager__c"?: string;
  "isAddressChanged__c"?: boolean;
  "Is_Monday_Holiday__c"?: boolean;
  "Is_Tuesday_Holiday__c"?: boolean;
  "Is_Wednesday_Holiday__c"?: boolean;
  "Is_Thursday_Holiday__c"?: boolean;
  "Is_Friday_Holiday__c"?: boolean;
  "Is_Saturday_Holiday__c"?: boolean;
  "Is_Sunday_Holiday__c"?: boolean;
  "Monday_start_Time__c"?: string;
  "Tuesday_start_Time__c"?: string;
  "Wednesday_start_Time__c"?: string;
  "Thursday_start_Time__c"?: string;
  "Friday_start_Time__c"?: string;
  "Saturday_Start_Time__c"?: string;
  "Sunday_Start_Time__c"?: string;
  "Monday_End_Time__c"?: string;
  "Tuesday_End_Time__c"?: string;
  "Wednesday_End_Time__c"?: string;
  "Thursday_End_Time__c"?: string;
  "Friday_End_Time__c"?: string;
  "Saturday_End_Time__c"?: string;
  "Sunday_End_Time__c"?: string;
  "Checkin_Notification_Contact__c"?: string;
  "Default_Location__c"?: string;
  "Enable_Checkin_Email_Notification__c"?: boolean;
  "Has_Checkin_out_Notification_Contact__c"?: string;
  "Time_Zone_Name__c"?: string;
  "Timezone_ISO_Code__c"?: string;
  "UTC_Offset__c"?: string;
  "Non_Dispatched__c"?: boolean;
  "Vendor_Type__c"?: string;
  "Vendor__c"?: string;
  "Checkin_out_Notification_Contact_Count__c"?: number;
  "LastActivityDate"?: Date;
  "SystemModstamp"?: Date;
  "Jobsite_Staging__c"?: string;
  "Device_Count__c"?: number;
  "Created_By__c"?: string;
  "Region__c"?: string;
  "NPT_Stage__c"?: string;
  "Jobsite_Projects_Count__c"?: number;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "Jobsite__c"?: string;
  partner?: Account;
  account?: Account;
  jobs?: Job[];
  vendor?: Account;
  Projects?: Project[];
  JobsiteProjects?: JobsiteProjects;
  jobsiteKeyContact?: Contact;
  countryCode?: CountryCode;
  GeoMetro?: GeoMetro;
  contacts?: Contact[];
  jobsiteContacts?: JobsiteContact[];
}

export class Jobsite implements JobsiteInterface {
  "sfdcId": string;
  "Account__c": string;
  "City__c": string;
  "Count_of_Service_Desk_Contacts__c": number;
  "Count_of_Technical_Escalation_Contacts__c": number;
  "Country__c": string;
  "CreatedById": string;
  "CreatedDate": Date;
  "GEO_Metro__c": string;
  "Jobsite_ID__c": string;
  "Jobsite_Status__c": string;
  "LastModifiedById": string;
  "LastModifiedDate": Date;
  "Name": string;
  "State__c": string;
  "Street__c": string;
  "Type__c": string;
  "Zip__c": string;
  "Contact_Email__c": string;
  "Contact_Phone__c": string;
  "Jobsite_Description__c": string;
  "Jobsite_Key_Contact__c": string;
  "Partner__c": string;
  "partner_provided_Jobsite_id__c": string;
  "geolocation__c": GeoPoint;
  "geolocation__Latitude__s": number;
  "geolocation__Longitude__s": number;
  "Jobsite_Approval_Status__c": string;
  "Time_Zone__c": string;
  "Abbreviation__c": string;
  "CurrencyIsoCode": string;
  "IsDeleted": boolean;
  "Jobsite_Safe_Id__c": string;
  "LastReferencedDate": Date;
  "LastViewedDate": Date;
  "StateManager__c": string;
  "isAddressChanged__c": boolean;
  "Is_Monday_Holiday__c": boolean;
  "Is_Tuesday_Holiday__c": boolean;
  "Is_Wednesday_Holiday__c": boolean;
  "Is_Thursday_Holiday__c": boolean;
  "Is_Friday_Holiday__c": boolean;
  "Is_Saturday_Holiday__c": boolean;
  "Is_Sunday_Holiday__c": boolean;
  "Monday_start_Time__c": string;
  "Tuesday_start_Time__c": string;
  "Wednesday_start_Time__c": string;
  "Thursday_start_Time__c": string;
  "Friday_start_Time__c": string;
  "Saturday_Start_Time__c": string;
  "Sunday_Start_Time__c": string;
  "Monday_End_Time__c": string;
  "Tuesday_End_Time__c": string;
  "Wednesday_End_Time__c": string;
  "Thursday_End_Time__c": string;
  "Friday_End_Time__c": string;
  "Saturday_End_Time__c": string;
  "Sunday_End_Time__c": string;
  "Checkin_Notification_Contact__c": string;
  "Default_Location__c": string;
  "Enable_Checkin_Email_Notification__c": boolean;
  "Has_Checkin_out_Notification_Contact__c": string;
  "Time_Zone_Name__c": string;
  "Timezone_ISO_Code__c": string;
  "UTC_Offset__c": string;
  "Non_Dispatched__c": boolean;
  "Vendor_Type__c": string;
  "Vendor__c": string;
  "Checkin_out_Notification_Contact_Count__c": number;
  "LastActivityDate": Date;
  "SystemModstamp": Date;
  "Jobsite_Staging__c": string;
  "Device_Count__c": number;
  "Created_By__c": string;
  "Region__c": string;
  "NPT_Stage__c": string;
  "Jobsite_Projects_Count__c": number;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  "Jobsite__c": string;
  partner: Account;
  account: Account;
  jobs: Job[];
  vendor: Account;
  Projects: Project[];
  JobsiteProjects: JobsiteProjects;
  jobsiteKeyContact: Contact;
  countryCode: CountryCode;
  GeoMetro: GeoMetro;
  contacts: Contact[];
  jobsiteContacts: JobsiteContact[];
  constructor(data?: JobsiteInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Jobsite`.
   */
  public static getModelName() {
    return "Jobsite";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Jobsite for dynamic purposes.
  **/
  public static factory(data: JobsiteInterface): Jobsite{
    return new Jobsite(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Jobsite',
      plural: 'Jobsites',
      path: 'Jobsites',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "Account__c": {
          name: 'Account__c',
          type: 'string'
        },
        "City__c": {
          name: 'City__c',
          type: 'string'
        },
        "Count_of_Service_Desk_Contacts__c": {
          name: 'Count_of_Service_Desk_Contacts__c',
          type: 'number'
        },
        "Count_of_Technical_Escalation_Contacts__c": {
          name: 'Count_of_Technical_Escalation_Contacts__c',
          type: 'number'
        },
        "Country__c": {
          name: 'Country__c',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "GEO_Metro__c": {
          name: 'GEO_Metro__c',
          type: 'string'
        },
        "Jobsite_ID__c": {
          name: 'Jobsite_ID__c',
          type: 'string'
        },
        "Jobsite_Status__c": {
          name: 'Jobsite_Status__c',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "State__c": {
          name: 'State__c',
          type: 'string'
        },
        "Street__c": {
          name: 'Street__c',
          type: 'string'
        },
        "Type__c": {
          name: 'Type__c',
          type: 'string'
        },
        "Zip__c": {
          name: 'Zip__c',
          type: 'string'
        },
        "Contact_Email__c": {
          name: 'Contact_Email__c',
          type: 'string'
        },
        "Contact_Phone__c": {
          name: 'Contact_Phone__c',
          type: 'string'
        },
        "Jobsite_Description__c": {
          name: 'Jobsite_Description__c',
          type: 'string'
        },
        "Jobsite_Key_Contact__c": {
          name: 'Jobsite_Key_Contact__c',
          type: 'string'
        },
        "Partner__c": {
          name: 'Partner__c',
          type: 'string'
        },
        "partner_provided_Jobsite_id__c": {
          name: 'partner_provided_Jobsite_id__c',
          type: 'string'
        },
        "geolocation__c": {
          name: 'geolocation__c',
          type: 'GeoPoint'
        },
        "geolocation__Latitude__s": {
          name: 'geolocation__Latitude__s',
          type: 'number'
        },
        "geolocation__Longitude__s": {
          name: 'geolocation__Longitude__s',
          type: 'number'
        },
        "Jobsite_Approval_Status__c": {
          name: 'Jobsite_Approval_Status__c',
          type: 'string'
        },
        "Time_Zone__c": {
          name: 'Time_Zone__c',
          type: 'string'
        },
        "Abbreviation__c": {
          name: 'Abbreviation__c',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "Jobsite_Safe_Id__c": {
          name: 'Jobsite_Safe_Id__c',
          type: 'string'
        },
        "LastReferencedDate": {
          name: 'LastReferencedDate',
          type: 'Date'
        },
        "LastViewedDate": {
          name: 'LastViewedDate',
          type: 'Date'
        },
        "StateManager__c": {
          name: 'StateManager__c',
          type: 'string'
        },
        "isAddressChanged__c": {
          name: 'isAddressChanged__c',
          type: 'boolean'
        },
        "Is_Monday_Holiday__c": {
          name: 'Is_Monday_Holiday__c',
          type: 'boolean'
        },
        "Is_Tuesday_Holiday__c": {
          name: 'Is_Tuesday_Holiday__c',
          type: 'boolean'
        },
        "Is_Wednesday_Holiday__c": {
          name: 'Is_Wednesday_Holiday__c',
          type: 'boolean'
        },
        "Is_Thursday_Holiday__c": {
          name: 'Is_Thursday_Holiday__c',
          type: 'boolean'
        },
        "Is_Friday_Holiday__c": {
          name: 'Is_Friday_Holiday__c',
          type: 'boolean'
        },
        "Is_Saturday_Holiday__c": {
          name: 'Is_Saturday_Holiday__c',
          type: 'boolean'
        },
        "Is_Sunday_Holiday__c": {
          name: 'Is_Sunday_Holiday__c',
          type: 'boolean'
        },
        "Monday_start_Time__c": {
          name: 'Monday_start_Time__c',
          type: 'string'
        },
        "Tuesday_start_Time__c": {
          name: 'Tuesday_start_Time__c',
          type: 'string'
        },
        "Wednesday_start_Time__c": {
          name: 'Wednesday_start_Time__c',
          type: 'string'
        },
        "Thursday_start_Time__c": {
          name: 'Thursday_start_Time__c',
          type: 'string'
        },
        "Friday_start_Time__c": {
          name: 'Friday_start_Time__c',
          type: 'string'
        },
        "Saturday_Start_Time__c": {
          name: 'Saturday_Start_Time__c',
          type: 'string'
        },
        "Sunday_Start_Time__c": {
          name: 'Sunday_Start_Time__c',
          type: 'string'
        },
        "Monday_End_Time__c": {
          name: 'Monday_End_Time__c',
          type: 'string'
        },
        "Tuesday_End_Time__c": {
          name: 'Tuesday_End_Time__c',
          type: 'string'
        },
        "Wednesday_End_Time__c": {
          name: 'Wednesday_End_Time__c',
          type: 'string'
        },
        "Thursday_End_Time__c": {
          name: 'Thursday_End_Time__c',
          type: 'string'
        },
        "Friday_End_Time__c": {
          name: 'Friday_End_Time__c',
          type: 'string'
        },
        "Saturday_End_Time__c": {
          name: 'Saturday_End_Time__c',
          type: 'string'
        },
        "Sunday_End_Time__c": {
          name: 'Sunday_End_Time__c',
          type: 'string'
        },
        "Checkin_Notification_Contact__c": {
          name: 'Checkin_Notification_Contact__c',
          type: 'string'
        },
        "Default_Location__c": {
          name: 'Default_Location__c',
          type: 'string'
        },
        "Enable_Checkin_Email_Notification__c": {
          name: 'Enable_Checkin_Email_Notification__c',
          type: 'boolean'
        },
        "Has_Checkin_out_Notification_Contact__c": {
          name: 'Has_Checkin_out_Notification_Contact__c',
          type: 'string'
        },
        "Time_Zone_Name__c": {
          name: 'Time_Zone_Name__c',
          type: 'string'
        },
        "Timezone_ISO_Code__c": {
          name: 'Timezone_ISO_Code__c',
          type: 'string'
        },
        "UTC_Offset__c": {
          name: 'UTC_Offset__c',
          type: 'string'
        },
        "Non_Dispatched__c": {
          name: 'Non_Dispatched__c',
          type: 'boolean'
        },
        "Vendor_Type__c": {
          name: 'Vendor_Type__c',
          type: 'string'
        },
        "Vendor__c": {
          name: 'Vendor__c',
          type: 'string'
        },
        "Checkin_out_Notification_Contact_Count__c": {
          name: 'Checkin_out_Notification_Contact_Count__c',
          type: 'number'
        },
        "LastActivityDate": {
          name: 'LastActivityDate',
          type: 'Date'
        },
        "SystemModstamp": {
          name: 'SystemModstamp',
          type: 'Date'
        },
        "Jobsite_Staging__c": {
          name: 'Jobsite_Staging__c',
          type: 'string'
        },
        "Device_Count__c": {
          name: 'Device_Count__c',
          type: 'number'
        },
        "Created_By__c": {
          name: 'Created_By__c',
          type: 'string'
        },
        "Region__c": {
          name: 'Region__c',
          type: 'string'
        },
        "NPT_Stage__c": {
          name: 'NPT_Stage__c',
          type: 'string'
        },
        "Jobsite_Projects_Count__c": {
          name: 'Jobsite_Projects_Count__c',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "Jobsite__c": {
          name: 'Jobsite__c',
          type: 'string'
        },
      },
      relations: {
        partner: {
          name: 'partner',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'Partner__c',
          keyTo: 'sfdcId'
        },
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'Account__c',
          keyTo: 'sfdcId'
        },
        jobs: {
          name: 'jobs',
          type: 'Job[]',
          model: 'Job',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'Jobsite__c'
        },
        vendor: {
          name: 'vendor',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'Vendor__c',
          keyTo: 'sfdcId'
        },
        Projects: {
          name: 'Projects',
          type: 'Project[]',
          model: 'Project',
          relationType: 'hasMany',
          modelThrough: 'JobsiteProjects',
          keyThrough: 'projectId',
          keyFrom: 'id',
          keyTo: 'Jobsite__c'
        },
        JobsiteProjects: {
          name: 'JobsiteProjects',
          type: 'JobsiteProjects',
          model: 'JobsiteProjects',
          relationType: 'belongsTo',
                  keyFrom: 'sfdcId',
          keyTo: 'Jobsite__c'
        },
        jobsiteKeyContact: {
          name: 'jobsiteKeyContact',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Jobsite_Key_Contact__c',
          keyTo: 'sfdcId'
        },
        countryCode: {
          name: 'countryCode',
          type: 'CountryCode',
          model: 'CountryCode',
          relationType: 'belongsTo',
                  keyFrom: 'Country__c',
          keyTo: 'Name'
        },
        GeoMetro: {
          name: 'GeoMetro',
          type: 'GeoMetro',
          model: 'GeoMetro',
          relationType: 'belongsTo',
                  keyFrom: 'GEO_Metro__c',
          keyTo: 'sfdcId'
        },
        contacts: {
          name: 'contacts',
          type: 'Contact[]',
          model: 'Contact',
          relationType: 'hasMany',
          modelThrough: 'JobsiteContact',
          keyThrough: 'contactId',
          keyFrom: 'id',
          keyTo: 'Jobsite__c'
        },
        jobsiteContacts: {
          name: 'jobsiteContacts',
          type: 'JobsiteContact[]',
          model: 'JobsiteContact',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'Jobsite__c'
        },
      }
    }
  }
}
