import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { AppStateService } from '../../../../../services/app-state.service';
import { JobApi } from '../../../../../sdk';
import * as accessRight from '../job-detail.access';
import { CommonService } from 'shared/services/common.service';

@Component({
    selector: 'job-detail-billing',
    templateUrl: './job-detail-billing.component.html',
    styleUrls: ['./job-detail-billing.component.css']
})

export class JobDetailBillingComponent implements OnInit, OnDestroy {

    @Input() jobId?: string;
    @Input() jobObj?: any;
    @Input() timeZone: any = '';
    @Input() job_status: string;
    @Output() uploaded: EventEmitter<any> = new EventEmitter();
    userRole: any;
    errorMessage = '';
    job: any;
    roleSub: Subscription;
    isVisble: any;
    mode: any;
    BillingTab: any = {
        purchaseOrder: true,
        purchaseInvoice: true,
        payment: true,
        sendMessage: true,
    };

    constructor(
        private _appState: AppStateService,
        private _jobApi: JobApi,
        private _commonService: CommonService,
    ) { }
    ngOnInit() {
        this._commonService.saveCurrentPageAnalytics('Job Manager Console - Details', 'Billing');
    }
    ngOnChanges() {
        this.roleSub = this._appState.getJobDetailUserRole().subscribe(role => {
            if (role && role['userRole']) {
                this.userRole = role['userRole'];
                const roles = ['vms_agency', 'public_agency', 'msp'];
                if (roles.indexOf(role['userRole']) !== -1) {
                    this.isVisble = true;
                } else {
                    this.isVisble = false;
                }
                if (this.jobId) {
                    this.loadData(this.jobId);
                } else if (this.jobObj) {
                    this.prepareJobResult(JSON.parse(this.jobObj));
                } else {
                    this.errorMessage = 'No details to display.';
                }
            }
        })


    }

    ngOnDestroy() {
        if (this.roleSub) {
            this.roleSub.unsubscribe();
        }
    }

    loadData(jobId) {
        this.errorMessage = '';
        const reqObj = {
            'where': { 'sfdcId': jobId },
            'fields': ['id', 'sfdcId', 'Case__c'],
        };
        this._jobApi.getJobDetailsById(reqObj).subscribe(
            result => {
                this.prepareJobResult(result);
            },
            error => {
                this.errorMessage = error.message;
            }
        );
    }
    prepareJobResult(result) {
        if (result && Object.keys(result).length > 0) {
            this.job = result;
            this.jobId = this.job.sfdcId;
        } else {
            this.errorMessage = 'No details to display.';
        }
    }
    fileUploaded() {
        this.uploaded.emit();
    }
}
