import { JobDataCardModule } from './../../../../components/job-data-card/job-data-card.module';
import { JobMessageListComponent } from './job-message-list.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { MatDialogModule } from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    InfiniteScrollModule,
    NgxDatatableModule,
    FormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MatDialogModule,
    ReactiveFormsModule,
    JobDataCardModule
  ],
  declarations: [JobMessageListComponent],
  exports: [JobMessageListComponent]
})
export class JobMessageListModule { }
