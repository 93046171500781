import { ReadMoreModule } from 'shared/components/read-more/read-more.module';
import { FormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgModule } from '@angular/core';
import {RouterModule} from '@angular/router';
import { CommonModule } from '@angular/common';
import { TimeClockManagerListComponent } from './time-clock-manager-list.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        NgxDatatableModule,
        ReadMoreModule
    ],
    declarations: [TimeClockManagerListComponent],
    exports: [TimeClockManagerListComponent]
})
export class TimeClockManagerListModule {
}
