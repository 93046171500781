import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkerEmailLookupComponent } from './worker-email-lookup.component';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule
  ],
  declarations: [WorkerEmailLookupComponent],
  exports: [WorkerEmailLookupComponent],
  providers: []
})
export class WorkerEmailLookupModule { }
