import { OnInit, Component, Input, Output, EventEmitter } from '@angular/core';
import { JobStatusInternalValues } from 'shared/models/static-list-data.service';

@Component({
    selector: 'app-job-status-lookup',
    templateUrl: './job-status-lookup.component.html',
    styleUrls: ['./job-status-lookup.component.css']
})
export class JobStatusLookupComponent implements OnInit {
    jobStatusesSelected: any[] = [];
    jobStatuses: any;
    @Input() set resetData(isClear) {
        this.jobStatusesSelected = [];
    }
    @Input() isMultiSelect = true;
    @Input() placeholder;
    @Output() getJobStatusObj: EventEmitter<any> = new EventEmitter();

    constructor() {
        // this.jobStatuses = JobStatusInternalValues;
        // this.jobStatusesSelected = [];
    }
    ngOnInit() {
        this.jobStatuses = JobStatusInternalValues;
        const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
        this.jobStatusesSelected = (preselected && preselected['jobStatus']) ? preselected['jobStatus'] : '';
    }

    clearJobStatus() {
        this.jobStatusesSelected = [];
        this.getJobStatusObj.emit([]);
    }

    onJobStatusChange() {
        this.getJobStatusObj.emit(this.jobStatusesSelected);
    }
}
