/* tslint:disable */

declare var Object: any;
export interface PayslipItemInterface {
  "sfdcId"?: string;
  "Amount__c"?: string;
  "Hours__c"?: string;
  "Hours_Spent__c"?: number;
  "Multiplier__c"?: number;
  "Overtime_Amount__c"?: number;
  "Overtime_Hours__c"?: number;
  "Rate__c"?: number;
  "Total_Amount__c"?: number;
  "Adjustment_Amount__c"?: number;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class PayslipItem implements PayslipItemInterface {
  "sfdcId": string;
  "Amount__c": string;
  "Hours__c": string;
  "Hours_Spent__c": number;
  "Multiplier__c": number;
  "Overtime_Amount__c": number;
  "Overtime_Hours__c": number;
  "Rate__c": number;
  "Total_Amount__c": number;
  "Adjustment_Amount__c": number;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: PayslipItemInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `PayslipItem`.
   */
  public static getModelName() {
    return "PayslipItem";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of PayslipItem for dynamic purposes.
  **/
  public static factory(data: PayslipItemInterface): PayslipItem{
    return new PayslipItem(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'PayslipItem',
      plural: 'PayslipItems',
      path: 'PayslipItems',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "Amount__c": {
          name: 'Amount__c',
          type: 'string'
        },
        "Hours__c": {
          name: 'Hours__c',
          type: 'string'
        },
        "Hours_Spent__c": {
          name: 'Hours_Spent__c',
          type: 'number'
        },
        "Multiplier__c": {
          name: 'Multiplier__c',
          type: 'number'
        },
        "Overtime_Amount__c": {
          name: 'Overtime_Amount__c',
          type: 'number'
        },
        "Overtime_Hours__c": {
          name: 'Overtime_Hours__c',
          type: 'number'
        },
        "Rate__c": {
          name: 'Rate__c',
          type: 'number'
        },
        "Total_Amount__c": {
          name: 'Total_Amount__c',
          type: 'number'
        },
        "Adjustment_Amount__c": {
          name: 'Adjustment_Amount__c',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
