/* tslint:disable */
import {
  Task,
  Conversation,
  Favorite
} from '../index';

declare var Object: any;
export interface ConversationRelationInterface {
  "sfdcId"?: string;
  "Name"?: string;
  "OwnerId"?: string;
  "LastModifiedById"?: string;
  "CurrencyIsoCode"?: string;
  "CreatedById"?: string;
  "PgMO_Conversation__c"?: string;
  "Related_to_Object__c"?: string;
  "Related_to_Record_Id__c"?: string;
  "Initated_By_Member__c"?: string;
  "LastModifiedDate"?: Date;
  "CreatedDate"?: Date;
  "IsDeleted"?: boolean;
  "ProjectType"?: string;
  "AccountId"?: string;
  "ProjectId"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  task?: Task;
  mainConversation?: Conversation;
  replyConversation?: Conversation[];
  favorite?: Favorite[];
}

export class ConversationRelation implements ConversationRelationInterface {
  "sfdcId": string;
  "Name": string;
  "OwnerId": string;
  "LastModifiedById": string;
  "CurrencyIsoCode": string;
  "CreatedById": string;
  "PgMO_Conversation__c": string;
  "Related_to_Object__c": string;
  "Related_to_Record_Id__c": string;
  "Initated_By_Member__c": string;
  "LastModifiedDate": Date;
  "CreatedDate": Date;
  "IsDeleted": boolean;
  "ProjectType": string;
  "AccountId": string;
  "ProjectId": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  task: Task;
  mainConversation: Conversation;
  replyConversation: Conversation[];
  favorite: Favorite[];
  constructor(data?: ConversationRelationInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ConversationRelation`.
   */
  public static getModelName() {
    return "ConversationRelation";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ConversationRelation for dynamic purposes.
  **/
  public static factory(data: ConversationRelationInterface): ConversationRelation{
    return new ConversationRelation(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ConversationRelation',
      plural: 'ConversationRelations',
      path: 'ConversationRelations',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "PgMO_Conversation__c": {
          name: 'PgMO_Conversation__c',
          type: 'string'
        },
        "Related_to_Object__c": {
          name: 'Related_to_Object__c',
          type: 'string'
        },
        "Related_to_Record_Id__c": {
          name: 'Related_to_Record_Id__c',
          type: 'string'
        },
        "Initated_By_Member__c": {
          name: 'Initated_By_Member__c',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "ProjectType": {
          name: 'ProjectType',
          type: 'string'
        },
        "AccountId": {
          name: 'AccountId',
          type: 'string'
        },
        "ProjectId": {
          name: 'ProjectId',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        task: {
          name: 'task',
          type: 'Task',
          model: 'Task',
          relationType: 'belongsTo',
                  keyFrom: 'Related_to_Record_Id__c',
          keyTo: 'sfdcId'
        },
        mainConversation: {
          name: 'mainConversation',
          type: 'Conversation',
          model: 'Conversation',
          relationType: 'belongsTo',
                  keyFrom: 'PgMO_Conversation__c',
          keyTo: 'sfdcId'
        },
        replyConversation: {
          name: 'replyConversation',
          type: 'Conversation[]',
          model: 'Conversation',
          relationType: 'hasMany',
                  keyFrom: 'PgMO_Conversation__c',
          keyTo: 'Parent_Conversation__c'
        },
        favorite: {
          name: 'favorite',
          type: 'Favorite[]',
          model: 'Favorite',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'conversationId'
        },
      }
    }
  }
}
