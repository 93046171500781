import { AppStateService } from './../../../services/app-state.service';
import { filter } from 'rxjs/operators';
import { Component, OnInit, Input } from '@angular/core';
import { NotificationService } from 'shared/services/notification.service';
import { PreloaderService } from 'shared/services/preloader.service';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-service-manager-feed-list',
  templateUrl: './service-manager-feed-list.component.html',
  styleUrls: ['./service-manager-feed-list.component.css']
})
export class ServiceManagerFeedListComponent implements OnInit {

  serviceManagerDetailData;
  @Input() set serviceDetailData(e) {
    this.serviceManagerDetailData = e || {};
    if (e) {
      this.getFeeds(this.page);
    }
  }
  page = {
    size: 20,
    totalElements: 0,
    totalPages: 0,
    pageNumber: 1
  };
  activities = []
  pageEvent: PageEvent;
  noDataText = 'No Activity Feed Found';
  accessType: any;

  constructor(private _notificationService: NotificationService,
    private _appState: AppStateService,
    private _loader: PreloaderService) {
      this.accessType = this._appState.getAccessType();
      // To check if view as PMS mode is activated.
      if (localStorage.getItem('viewAsInfo')) {
        const viewAsInfo = JSON.parse(localStorage.getItem('viewAsInfo'));
        const viewAsPartner = (viewAsInfo && viewAsInfo.viewAsType === 'Partner');
        this.accessType = (viewAsPartner) ? 'partner' : this.accessType;
      }
    }

  ngOnInit() {  
    this.setPage(this.page);
  }

  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.pageNumber;
    this.getFeeds(this.page);
  }

  getFeeds(offset) {
    if (this.serviceManagerDetailData && Object.keys(this.serviceManagerDetailData).length) {
      let filter = {};
      if (this.serviceManagerDetailData.job && this.serviceManagerDetailData.job.id && this.accessType !== 'partner' )  {
        filter = {
          'Case_Id': this.serviceManagerDetailData.modelId,
          'Job_Id': this.serviceManagerDetailData.job.id
        };
      } else {
        filter = {
          'Case_Id': this.serviceManagerDetailData.modelId
        };
      }
      this._loader.showPreloader();
      this._notificationService
        .getServiceManagerActivityFeed(offset["pageNumber"], filter).then((data: any) => {
          this._loader.hidePreloader();
          if (data && data["data"]) {
            this.activities = data["data"];
            this.page.totalElements = data["total"] ? data["total"] : 0;
            this.page.totalPages = data["lastPage"] ? data["lastPage"] : 0;
          }
        })
        .catch(err => {
          this._loader.hidePreloader();
          console.log(err);
        });
    }
  }

  getServerData(event: PageEvent) {
    this.page.pageNumber = event.pageIndex + 1;
    this.getFeeds(this.page);
  }

}
