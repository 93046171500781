/* tslint:disable */
import {
  Contact,
  Jobsite,
  Job,
  Milestone,
  Task,
  Account,
  WorkOrder,
  Invoice,
  ExpenseCode
} from '../index';

declare var Object: any;
export interface ExpenseReportInterface {
  "Amount__c"?: number;
  "Approval_Levels_Required__c"?: string;
  "Approver__c"?: string;
  "BillReceipt_Ref__c"?: string;
  "Business_Purpose__c"?: string;
  "Comment__c"?: string;
  "Current_Approval_Level__c"?: string;
  "Customer_Approval_Status__c"?: string;
  "Delegated_Approver__c"?: string;
  "Department__c"?: string;
  "Destination_Jobsite__c"?: string;
  "Division__c"?: string;
  "Expense_Code__c"?: string;
  "Expense_Report_Name__c"?: string;
  "Expense_sub_Type__c"?: string;
  "Expense_Type__c"?: string;
  "Incurred_By__c"?: string;
  "Incurred_By_Name__c"?: string;
  "IRON_Approval_Status__c"?: string;
  "Is_Billable__c"?: boolean;
  "Job__c"?: string;
  "Payment_Type__c"?: string;
  "Personal_Expense_do_not_reimburse__c"?: boolean;
  "isDeleted"?: boolean;
  "PgMO_Milestone__c"?: string;
  "PgMO_Tasks__c"?: string;
  "Rejection_Reason__c"?: string;
  "Rejection_Reason_for_Email__c"?: string;
  "Relative_travel_distance__c"?: number;
  "Report_Date__c"?: Date;
  "Source_Jobsite__c"?: string;
  "Travel_time__c"?: number;
  "Vendor__c"?: string;
  "WO_Has_Partner__c"?: boolean;
  "WO_Has_PgMO_Milestone__c"?: boolean;
  "LastModifiedById"?: string;
  "CurrencyIsoCode"?: string;
  "CreatedById"?: string;
  "Name"?: string;
  "Work_Order__c"?: string;
  "sfdcId"?: string;
  "expenseJobRecordType"?: string;
  "Claim_Reimburesment__c"?: string;
  "Created_By__c"?: string;
  "Expense_Description__c"?: string;
  "Expense_Invoice_Ref__c"?: string;
  "Last_Modified_By__c"?: string;
  "Last_Modified_Date__c"?: string;
  "Status__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  approver?: Contact;
  delegatedApprover?: Contact;
  destinationJobsite?: Jobsite;
  incurredBy?: Contact;
  job?: Job;
  milestone?: Milestone;
  task?: Task;
  sourceJobsite?: Jobsite;
  vendor?: Account;
  workOrder?: WorkOrder;
  invoice?: Invoice;
  createdBy?: Contact;
  lastModifiedBy?: Contact;
  expenseCode?: ExpenseCode;
}

export class ExpenseReport implements ExpenseReportInterface {
  "Amount__c": number;
  "Approval_Levels_Required__c": string;
  "Approver__c": string;
  "BillReceipt_Ref__c": string;
  "Business_Purpose__c": string;
  "Comment__c": string;
  "Current_Approval_Level__c": string;
  "Customer_Approval_Status__c": string;
  "Delegated_Approver__c": string;
  "Department__c": string;
  "Destination_Jobsite__c": string;
  "Division__c": string;
  "Expense_Code__c": string;
  "Expense_Report_Name__c": string;
  "Expense_sub_Type__c": string;
  "Expense_Type__c": string;
  "Incurred_By__c": string;
  "Incurred_By_Name__c": string;
  "IRON_Approval_Status__c": string;
  "Is_Billable__c": boolean;
  "Job__c": string;
  "Payment_Type__c": string;
  "Personal_Expense_do_not_reimburse__c": boolean;
  "isDeleted": boolean;
  "PgMO_Milestone__c": string;
  "PgMO_Tasks__c": string;
  "Rejection_Reason__c": string;
  "Rejection_Reason_for_Email__c": string;
  "Relative_travel_distance__c": number;
  "Report_Date__c": Date;
  "Source_Jobsite__c": string;
  "Travel_time__c": number;
  "Vendor__c": string;
  "WO_Has_Partner__c": boolean;
  "WO_Has_PgMO_Milestone__c": boolean;
  "LastModifiedById": string;
  "CurrencyIsoCode": string;
  "CreatedById": string;
  "Name": string;
  "Work_Order__c": string;
  "sfdcId": string;
  "expenseJobRecordType": string;
  "Claim_Reimburesment__c": string;
  "Created_By__c": string;
  "Expense_Description__c": string;
  "Expense_Invoice_Ref__c": string;
  "Last_Modified_By__c": string;
  "Last_Modified_Date__c": string;
  "Status__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  approver: Contact;
  delegatedApprover: Contact;
  destinationJobsite: Jobsite;
  incurredBy: Contact;
  job: Job;
  milestone: Milestone;
  task: Task;
  sourceJobsite: Jobsite;
  vendor: Account;
  workOrder: WorkOrder;
  invoice: Invoice;
  createdBy: Contact;
  lastModifiedBy: Contact;
  expenseCode: ExpenseCode;
  constructor(data?: ExpenseReportInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ExpenseReport`.
   */
  public static getModelName() {
    return "ExpenseReport";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ExpenseReport for dynamic purposes.
  **/
  public static factory(data: ExpenseReportInterface): ExpenseReport{
    return new ExpenseReport(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ExpenseReport',
      plural: 'ExpenseReports',
      path: 'ExpenseReports',
      idName: 'id',
      properties: {
        "Amount__c": {
          name: 'Amount__c',
          type: 'number'
        },
        "Approval_Levels_Required__c": {
          name: 'Approval_Levels_Required__c',
          type: 'string'
        },
        "Approver__c": {
          name: 'Approver__c',
          type: 'string'
        },
        "BillReceipt_Ref__c": {
          name: 'BillReceipt_Ref__c',
          type: 'string'
        },
        "Business_Purpose__c": {
          name: 'Business_Purpose__c',
          type: 'string'
        },
        "Comment__c": {
          name: 'Comment__c',
          type: 'string'
        },
        "Current_Approval_Level__c": {
          name: 'Current_Approval_Level__c',
          type: 'string'
        },
        "Customer_Approval_Status__c": {
          name: 'Customer_Approval_Status__c',
          type: 'string'
        },
        "Delegated_Approver__c": {
          name: 'Delegated_Approver__c',
          type: 'string'
        },
        "Department__c": {
          name: 'Department__c',
          type: 'string'
        },
        "Destination_Jobsite__c": {
          name: 'Destination_Jobsite__c',
          type: 'string'
        },
        "Division__c": {
          name: 'Division__c',
          type: 'string'
        },
        "Expense_Code__c": {
          name: 'Expense_Code__c',
          type: 'string'
        },
        "Expense_Report_Name__c": {
          name: 'Expense_Report_Name__c',
          type: 'string'
        },
        "Expense_sub_Type__c": {
          name: 'Expense_sub_Type__c',
          type: 'string'
        },
        "Expense_Type__c": {
          name: 'Expense_Type__c',
          type: 'string'
        },
        "Incurred_By__c": {
          name: 'Incurred_By__c',
          type: 'string'
        },
        "Incurred_By_Name__c": {
          name: 'Incurred_By_Name__c',
          type: 'string'
        },
        "IRON_Approval_Status__c": {
          name: 'IRON_Approval_Status__c',
          type: 'string'
        },
        "Is_Billable__c": {
          name: 'Is_Billable__c',
          type: 'boolean',
          default: false
        },
        "Job__c": {
          name: 'Job__c',
          type: 'string'
        },
        "Payment_Type__c": {
          name: 'Payment_Type__c',
          type: 'string'
        },
        "Personal_Expense_do_not_reimburse__c": {
          name: 'Personal_Expense_do_not_reimburse__c',
          type: 'boolean',
          default: false
        },
        "isDeleted": {
          name: 'isDeleted',
          type: 'boolean',
          default: false
        },
        "PgMO_Milestone__c": {
          name: 'PgMO_Milestone__c',
          type: 'string'
        },
        "PgMO_Tasks__c": {
          name: 'PgMO_Tasks__c',
          type: 'string'
        },
        "Rejection_Reason__c": {
          name: 'Rejection_Reason__c',
          type: 'string'
        },
        "Rejection_Reason_for_Email__c": {
          name: 'Rejection_Reason_for_Email__c',
          type: 'string'
        },
        "Relative_travel_distance__c": {
          name: 'Relative_travel_distance__c',
          type: 'number'
        },
        "Report_Date__c": {
          name: 'Report_Date__c',
          type: 'Date'
        },
        "Source_Jobsite__c": {
          name: 'Source_Jobsite__c',
          type: 'string'
        },
        "Travel_time__c": {
          name: 'Travel_time__c',
          type: 'number'
        },
        "Vendor__c": {
          name: 'Vendor__c',
          type: 'string'
        },
        "WO_Has_Partner__c": {
          name: 'WO_Has_Partner__c',
          type: 'boolean',
          default: false
        },
        "WO_Has_PgMO_Milestone__c": {
          name: 'WO_Has_PgMO_Milestone__c',
          type: 'boolean',
          default: false
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "Work_Order__c": {
          name: 'Work_Order__c',
          type: 'string'
        },
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "expenseJobRecordType": {
          name: 'expenseJobRecordType',
          type: 'string'
        },
        "Claim_Reimburesment__c": {
          name: 'Claim_Reimburesment__c',
          type: 'string'
        },
        "Created_By__c": {
          name: 'Created_By__c',
          type: 'string'
        },
        "Expense_Description__c": {
          name: 'Expense_Description__c',
          type: 'string'
        },
        "Expense_Invoice_Ref__c": {
          name: 'Expense_Invoice_Ref__c',
          type: 'string'
        },
        "Last_Modified_By__c": {
          name: 'Last_Modified_By__c',
          type: 'string'
        },
        "Last_Modified_Date__c": {
          name: 'Last_Modified_Date__c',
          type: 'string'
        },
        "Status__c": {
          name: 'Status__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        approver: {
          name: 'approver',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Approver__c',
          keyTo: 'sfdcId'
        },
        delegatedApprover: {
          name: 'delegatedApprover',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Delegated_Approver__c',
          keyTo: 'sfdcId'
        },
        destinationJobsite: {
          name: 'destinationJobsite',
          type: 'Jobsite',
          model: 'Jobsite',
          relationType: 'belongsTo',
                  keyFrom: 'Destination_Jobsite__c',
          keyTo: 'sfdcId'
        },
        incurredBy: {
          name: 'incurredBy',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Incurred_By__c',
          keyTo: 'sfdcId'
        },
        job: {
          name: 'job',
          type: 'Job',
          model: 'Job',
          relationType: 'belongsTo',
                  keyFrom: 'Job__c',
          keyTo: 'sfdcId'
        },
        milestone: {
          name: 'milestone',
          type: 'Milestone',
          model: 'Milestone',
          relationType: 'belongsTo',
                  keyFrom: 'PgMO_Milestone__c',
          keyTo: 'sfdcId'
        },
        task: {
          name: 'task',
          type: 'Task',
          model: 'Task',
          relationType: 'belongsTo',
                  keyFrom: 'PgMO_Tasks__c',
          keyTo: 'sfdcId'
        },
        sourceJobsite: {
          name: 'sourceJobsite',
          type: 'Jobsite',
          model: 'Jobsite',
          relationType: 'belongsTo',
                  keyFrom: 'Source_Jobsite__c',
          keyTo: 'sfdcId'
        },
        vendor: {
          name: 'vendor',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'Vendor__c',
          keyTo: 'sfdcId'
        },
        workOrder: {
          name: 'workOrder',
          type: 'WorkOrder',
          model: 'WorkOrder',
          relationType: 'belongsTo',
                  keyFrom: 'Work_Order__c',
          keyTo: 'sfdcId'
        },
        invoice: {
          name: 'invoice',
          type: 'Invoice',
          model: 'Invoice',
          relationType: 'belongsTo',
                  keyFrom: 'Expense_Invoice_Ref__c',
          keyTo: 'sfdcId'
        },
        createdBy: {
          name: 'createdBy',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Created_By__c',
          keyTo: 'sfdcId'
        },
        lastModifiedBy: {
          name: 'lastModifiedBy',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Last_Modified_By__c',
          keyTo: 'sfdcId'
        },
        expenseCode: {
          name: 'expenseCode',
          type: 'ExpenseCode',
          model: 'ExpenseCode',
          relationType: 'belongsTo',
                  keyFrom: 'Expense_Code__c',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
