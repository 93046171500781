/* tslint:disable */
import {
  Account,
  Contact,
  Pricelist,
  Case,
  Worker,
  TalentType,
  Product,
  Jobsite,
  GeoMetro,
  QuoteManager,
  MetroVirtualVendorPool,
  RecordType,
  Task,
  CsqdleadTalenttypeMapping
} from '../index';

declare var Object: any;
export interface QuoteLineManagerInterface {
  "sfdcId"?: string;
  "IsDeleted"?: boolean;
  "Name"?: string;
  "CurrencyIsoCode"?: string;
  "RecordTypeId"?: string;
  "CreatedDate"?: Date;
  "CreatedById"?: string;
  "LastModifiedDate"?: Date;
  "LastModifiedById"?: string;
  "SystemModstamp"?: Date;
  "QuoteManager__c"?: string;
  "Added_To_Cart__c"?: boolean;
  "Available_Stock__c"?: number;
  "Contract_Line_Item_Ref__c"?: string;
  "Created_Automatically__c"?: boolean;
  "Customer_PO_Date__c"?: Date;
  "Customer_PO_Line__c"?: string;
  "Customer_Pricelist_Auto__c"?: string;
  "Customer_SO_Number__c"?: string;
  "Description__c"?: string;
  "Item_Code__c"?: string;
  "Job_Order_Item_Name__c"?: string;
  "Jobsite_Selection_Status__c"?: string;
  "Jobsite__c"?: string;
  "Kit_Item__c"?: string;
  "List_Price_Customer__c"?: number;
  "List_Price_Vendor__c"?: number;
  "Order_Quantity_FTE_Hours__c"?: number;
  "Order_Quantity__c"?: number;
  "PMC_Case__c"?: string;
  "PO_Line_Number__c"?: string;
  "Primary_Contact_Email__c"?: string;
  "Primary_Contact_Name__c"?: string;
  "Primary_Contact_Phone__c"?: string;
  "Primary_Worker__c"?: string;
  "Product__c"?: string;
  "Talent_Type__c"?: string;
  "Dispatch_Service_Profiles__c"?: string;
  "Price__c"?: number;
  "Profit_Price__c"?: number;
  "Vendor_Cost__c"?: number;
  "Vendor__c"?: string;
  "Vendor_Account__c"?: string;
  "CoverageHour__c"?: string;
  "Dispatch_SLA_Priority__c"?: string;
  "Service_Engineer_Technical_Level__c"?: string;
  "Service_Type__c"?: string;
  "Service_Zone__c"?: string;
  "Status__c"?: string;
  "Account_RFI_Form__c"?: string;
  "Additional_Hours_T_M_AFTH_Multiplier__c"?: number;
  "Additional_Hours_T_M_HLDY_Multiplier__c"?: number;
  "Additional_Hours_T_M_Standard_Price__c"?: number;
  "Additional_Hours_T_M_WKND_Multiplier__c"?: number;
  "Annual_Rate__c"?: number;
  "Coverage_Hours__c"?: string;
  "Created_By__c"?: string;
  "FTE_Daily_Rate_Standard__c"?: number;
  "FTE_Monthly_Rate__c"?: number;
  "GEO_Code__c"?: string;
  "Global_Talent_Pool__c"?: string;
  "Gross_Profit_Estimated_Margin_Percent__c"?: number;
  "Gross_Profit_Estimated_Margin__c"?: number;
  "Half_Day_Rate__c"?: number;
  "Language__c"?: string;
  "Last_Modified_By__c"?: string;
  "Line_Total_Sales_Price__c"?: number;
  "Margin_Amount__c"?: number;
  "Metro_Pool__c"?: string;
  "Minimum_Hours_of_Dispatch__c"?: string;
  "PPE_1HR_Standard_Price__c"?: number;
  "PPE_2HR_Standard_Price__c"?: number;
  "PPE_4HR_Standard_Price__c"?: number;
  "PPE_8HR_Standard_Price__c"?: number;
  "SLA_Coverage_Radius_From_Postal_Code__c"?: string;
  "SLA__c"?: string;
  "Sales_Price__c"?: number;
  "Service_Technical_Level__c"?: string;
  "Total_Gross_Margin__c"?: string;
  "UnitPrice__c"?: number;
  "Vendor_PPE_Hours_Profile__c"?: string;
  "Vendor_Rating__c"?: number;
  "PgMO_Tasks__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  AccountRFI?: Account;
  Contact?: Contact;
  PriceBook?: Pricelist;
  LastModifiedContact?: Contact;
  pmcCase?: Case;
  PrimaryWorker?: Worker;
  TalentType?: TalentType;
  Product?: Product;
  Jobsite?: Jobsite;
  GeoMetro?: GeoMetro;
  Account?: Account;
  VendorAccount?: Account;
  QuoteManager?: QuoteManager;
  MetroVirtualVendorPool?: MetroVirtualVendorPool;
  RecordType?: RecordType;
  Task?: Task;
  CsqdLeadTalentType?: CsqdleadTalenttypeMapping;
}

export class QuoteLineManager implements QuoteLineManagerInterface {
  "sfdcId": string;
  "IsDeleted": boolean;
  "Name": string;
  "CurrencyIsoCode": string;
  "RecordTypeId": string;
  "CreatedDate": Date;
  "CreatedById": string;
  "LastModifiedDate": Date;
  "LastModifiedById": string;
  "SystemModstamp": Date;
  "QuoteManager__c": string;
  "Added_To_Cart__c": boolean;
  "Available_Stock__c": number;
  "Contract_Line_Item_Ref__c": string;
  "Created_Automatically__c": boolean;
  "Customer_PO_Date__c": Date;
  "Customer_PO_Line__c": string;
  "Customer_Pricelist_Auto__c": string;
  "Customer_SO_Number__c": string;
  "Description__c": string;
  "Item_Code__c": string;
  "Job_Order_Item_Name__c": string;
  "Jobsite_Selection_Status__c": string;
  "Jobsite__c": string;
  "Kit_Item__c": string;
  "List_Price_Customer__c": number;
  "List_Price_Vendor__c": number;
  "Order_Quantity_FTE_Hours__c": number;
  "Order_Quantity__c": number;
  "PMC_Case__c": string;
  "PO_Line_Number__c": string;
  "Primary_Contact_Email__c": string;
  "Primary_Contact_Name__c": string;
  "Primary_Contact_Phone__c": string;
  "Primary_Worker__c": string;
  "Product__c": string;
  "Talent_Type__c": string;
  "Dispatch_Service_Profiles__c": string;
  "Price__c": number;
  "Profit_Price__c": number;
  "Vendor_Cost__c": number;
  "Vendor__c": string;
  "Vendor_Account__c": string;
  "CoverageHour__c": string;
  "Dispatch_SLA_Priority__c": string;
  "Service_Engineer_Technical_Level__c": string;
  "Service_Type__c": string;
  "Service_Zone__c": string;
  "Status__c": string;
  "Account_RFI_Form__c": string;
  "Additional_Hours_T_M_AFTH_Multiplier__c": number;
  "Additional_Hours_T_M_HLDY_Multiplier__c": number;
  "Additional_Hours_T_M_Standard_Price__c": number;
  "Additional_Hours_T_M_WKND_Multiplier__c": number;
  "Annual_Rate__c": number;
  "Coverage_Hours__c": string;
  "Created_By__c": string;
  "FTE_Daily_Rate_Standard__c": number;
  "FTE_Monthly_Rate__c": number;
  "GEO_Code__c": string;
  "Global_Talent_Pool__c": string;
  "Gross_Profit_Estimated_Margin_Percent__c": number;
  "Gross_Profit_Estimated_Margin__c": number;
  "Half_Day_Rate__c": number;
  "Language__c": string;
  "Last_Modified_By__c": string;
  "Line_Total_Sales_Price__c": number;
  "Margin_Amount__c": number;
  "Metro_Pool__c": string;
  "Minimum_Hours_of_Dispatch__c": string;
  "PPE_1HR_Standard_Price__c": number;
  "PPE_2HR_Standard_Price__c": number;
  "PPE_4HR_Standard_Price__c": number;
  "PPE_8HR_Standard_Price__c": number;
  "SLA_Coverage_Radius_From_Postal_Code__c": string;
  "SLA__c": string;
  "Sales_Price__c": number;
  "Service_Technical_Level__c": string;
  "Total_Gross_Margin__c": string;
  "UnitPrice__c": number;
  "Vendor_PPE_Hours_Profile__c": string;
  "Vendor_Rating__c": number;
  "PgMO_Tasks__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  AccountRFI: Account;
  Contact: Contact;
  PriceBook: Pricelist;
  LastModifiedContact: Contact;
  pmcCase: Case;
  PrimaryWorker: Worker;
  TalentType: TalentType;
  Product: Product;
  Jobsite: Jobsite;
  GeoMetro: GeoMetro;
  Account: Account;
  VendorAccount: Account;
  QuoteManager: QuoteManager;
  MetroVirtualVendorPool: MetroVirtualVendorPool;
  RecordType: RecordType;
  Task: Task;
  CsqdLeadTalentType: CsqdleadTalenttypeMapping;
  constructor(data?: QuoteLineManagerInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `QuoteLineManager`.
   */
  public static getModelName() {
    return "QuoteLineManager";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of QuoteLineManager for dynamic purposes.
  **/
  public static factory(data: QuoteLineManagerInterface): QuoteLineManager{
    return new QuoteLineManager(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'QuoteLineManager',
      plural: 'QuoteLineManagers',
      path: 'QuoteLineManagers',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "RecordTypeId": {
          name: 'RecordTypeId',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "SystemModstamp": {
          name: 'SystemModstamp',
          type: 'Date'
        },
        "QuoteManager__c": {
          name: 'QuoteManager__c',
          type: 'string'
        },
        "Added_To_Cart__c": {
          name: 'Added_To_Cart__c',
          type: 'boolean'
        },
        "Available_Stock__c": {
          name: 'Available_Stock__c',
          type: 'number'
        },
        "Contract_Line_Item_Ref__c": {
          name: 'Contract_Line_Item_Ref__c',
          type: 'string'
        },
        "Created_Automatically__c": {
          name: 'Created_Automatically__c',
          type: 'boolean'
        },
        "Customer_PO_Date__c": {
          name: 'Customer_PO_Date__c',
          type: 'Date'
        },
        "Customer_PO_Line__c": {
          name: 'Customer_PO_Line__c',
          type: 'string'
        },
        "Customer_Pricelist_Auto__c": {
          name: 'Customer_Pricelist_Auto__c',
          type: 'string'
        },
        "Customer_SO_Number__c": {
          name: 'Customer_SO_Number__c',
          type: 'string'
        },
        "Description__c": {
          name: 'Description__c',
          type: 'string'
        },
        "Item_Code__c": {
          name: 'Item_Code__c',
          type: 'string'
        },
        "Job_Order_Item_Name__c": {
          name: 'Job_Order_Item_Name__c',
          type: 'string'
        },
        "Jobsite_Selection_Status__c": {
          name: 'Jobsite_Selection_Status__c',
          type: 'string'
        },
        "Jobsite__c": {
          name: 'Jobsite__c',
          type: 'string'
        },
        "Kit_Item__c": {
          name: 'Kit_Item__c',
          type: 'string'
        },
        "List_Price_Customer__c": {
          name: 'List_Price_Customer__c',
          type: 'number'
        },
        "List_Price_Vendor__c": {
          name: 'List_Price_Vendor__c',
          type: 'number'
        },
        "Order_Quantity_FTE_Hours__c": {
          name: 'Order_Quantity_FTE_Hours__c',
          type: 'number'
        },
        "Order_Quantity__c": {
          name: 'Order_Quantity__c',
          type: 'number'
        },
        "PMC_Case__c": {
          name: 'PMC_Case__c',
          type: 'string'
        },
        "PO_Line_Number__c": {
          name: 'PO_Line_Number__c',
          type: 'string'
        },
        "Primary_Contact_Email__c": {
          name: 'Primary_Contact_Email__c',
          type: 'string'
        },
        "Primary_Contact_Name__c": {
          name: 'Primary_Contact_Name__c',
          type: 'string'
        },
        "Primary_Contact_Phone__c": {
          name: 'Primary_Contact_Phone__c',
          type: 'string'
        },
        "Primary_Worker__c": {
          name: 'Primary_Worker__c',
          type: 'string'
        },
        "Product__c": {
          name: 'Product__c',
          type: 'string'
        },
        "Talent_Type__c": {
          name: 'Talent_Type__c',
          type: 'string'
        },
        "Dispatch_Service_Profiles__c": {
          name: 'Dispatch_Service_Profiles__c',
          type: 'string'
        },
        "Price__c": {
          name: 'Price__c',
          type: 'number'
        },
        "Profit_Price__c": {
          name: 'Profit_Price__c',
          type: 'number'
        },
        "Vendor_Cost__c": {
          name: 'Vendor_Cost__c',
          type: 'number'
        },
        "Vendor__c": {
          name: 'Vendor__c',
          type: 'string'
        },
        "Vendor_Account__c": {
          name: 'Vendor_Account__c',
          type: 'string'
        },
        "CoverageHour__c": {
          name: 'CoverageHour__c',
          type: 'string'
        },
        "Dispatch_SLA_Priority__c": {
          name: 'Dispatch_SLA_Priority__c',
          type: 'string'
        },
        "Service_Engineer_Technical_Level__c": {
          name: 'Service_Engineer_Technical_Level__c',
          type: 'string'
        },
        "Service_Type__c": {
          name: 'Service_Type__c',
          type: 'string'
        },
        "Service_Zone__c": {
          name: 'Service_Zone__c',
          type: 'string'
        },
        "Status__c": {
          name: 'Status__c',
          type: 'string'
        },
        "Account_RFI_Form__c": {
          name: 'Account_RFI_Form__c',
          type: 'string'
        },
        "Additional_Hours_T_M_AFTH_Multiplier__c": {
          name: 'Additional_Hours_T_M_AFTH_Multiplier__c',
          type: 'number'
        },
        "Additional_Hours_T_M_HLDY_Multiplier__c": {
          name: 'Additional_Hours_T_M_HLDY_Multiplier__c',
          type: 'number'
        },
        "Additional_Hours_T_M_Standard_Price__c": {
          name: 'Additional_Hours_T_M_Standard_Price__c',
          type: 'number'
        },
        "Additional_Hours_T_M_WKND_Multiplier__c": {
          name: 'Additional_Hours_T_M_WKND_Multiplier__c',
          type: 'number'
        },
        "Annual_Rate__c": {
          name: 'Annual_Rate__c',
          type: 'number'
        },
        "Coverage_Hours__c": {
          name: 'Coverage_Hours__c',
          type: 'string'
        },
        "Created_By__c": {
          name: 'Created_By__c',
          type: 'string'
        },
        "FTE_Daily_Rate_Standard__c": {
          name: 'FTE_Daily_Rate_Standard__c',
          type: 'number'
        },
        "FTE_Monthly_Rate__c": {
          name: 'FTE_Monthly_Rate__c',
          type: 'number'
        },
        "GEO_Code__c": {
          name: 'GEO_Code__c',
          type: 'string'
        },
        "Global_Talent_Pool__c": {
          name: 'Global_Talent_Pool__c',
          type: 'string'
        },
        "Gross_Profit_Estimated_Margin_Percent__c": {
          name: 'Gross_Profit_Estimated_Margin_Percent__c',
          type: 'number'
        },
        "Gross_Profit_Estimated_Margin__c": {
          name: 'Gross_Profit_Estimated_Margin__c',
          type: 'number'
        },
        "Half_Day_Rate__c": {
          name: 'Half_Day_Rate__c',
          type: 'number'
        },
        "Language__c": {
          name: 'Language__c',
          type: 'string'
        },
        "Last_Modified_By__c": {
          name: 'Last_Modified_By__c',
          type: 'string'
        },
        "Line_Total_Sales_Price__c": {
          name: 'Line_Total_Sales_Price__c',
          type: 'number'
        },
        "Margin_Amount__c": {
          name: 'Margin_Amount__c',
          type: 'number'
        },
        "Metro_Pool__c": {
          name: 'Metro_Pool__c',
          type: 'string'
        },
        "Minimum_Hours_of_Dispatch__c": {
          name: 'Minimum_Hours_of_Dispatch__c',
          type: 'string'
        },
        "PPE_1HR_Standard_Price__c": {
          name: 'PPE_1HR_Standard_Price__c',
          type: 'number'
        },
        "PPE_2HR_Standard_Price__c": {
          name: 'PPE_2HR_Standard_Price__c',
          type: 'number'
        },
        "PPE_4HR_Standard_Price__c": {
          name: 'PPE_4HR_Standard_Price__c',
          type: 'number'
        },
        "PPE_8HR_Standard_Price__c": {
          name: 'PPE_8HR_Standard_Price__c',
          type: 'number'
        },
        "SLA_Coverage_Radius_From_Postal_Code__c": {
          name: 'SLA_Coverage_Radius_From_Postal_Code__c',
          type: 'string'
        },
        "SLA__c": {
          name: 'SLA__c',
          type: 'string'
        },
        "Sales_Price__c": {
          name: 'Sales_Price__c',
          type: 'number'
        },
        "Service_Technical_Level__c": {
          name: 'Service_Technical_Level__c',
          type: 'string'
        },
        "Total_Gross_Margin__c": {
          name: 'Total_Gross_Margin__c',
          type: 'string'
        },
        "UnitPrice__c": {
          name: 'UnitPrice__c',
          type: 'number'
        },
        "Vendor_PPE_Hours_Profile__c": {
          name: 'Vendor_PPE_Hours_Profile__c',
          type: 'string'
        },
        "Vendor_Rating__c": {
          name: 'Vendor_Rating__c',
          type: 'number'
        },
        "PgMO_Tasks__c": {
          name: 'PgMO_Tasks__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        AccountRFI: {
          name: 'AccountRFI',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'Account_RFI_Form__c',
          keyTo: 'sfdcId'
        },
        Contact: {
          name: 'Contact',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Created_By__c',
          keyTo: 'sfdcId'
        },
        PriceBook: {
          name: 'PriceBook',
          type: 'Pricelist',
          model: 'Pricelist',
          relationType: 'belongsTo',
                  keyFrom: 'Customer_Pricelist_Auto__c',
          keyTo: 'sfdcId'
        },
        LastModifiedContact: {
          name: 'LastModifiedContact',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Last_Modified_By__c',
          keyTo: 'sfdcId'
        },
        pmcCase: {
          name: 'pmcCase',
          type: 'Case',
          model: 'Case',
          relationType: 'belongsTo',
                  keyFrom: 'PMC_Case__c',
          keyTo: 'sfdcId'
        },
        PrimaryWorker: {
          name: 'PrimaryWorker',
          type: 'Worker',
          model: 'Worker',
          relationType: 'belongsTo',
                  keyFrom: 'Primary_Worker__c',
          keyTo: 'sfdcId'
        },
        TalentType: {
          name: 'TalentType',
          type: 'TalentType',
          model: 'TalentType',
          relationType: 'belongsTo',
                  keyFrom: 'Talent_Type__c',
          keyTo: 'sfdcId'
        },
        Product: {
          name: 'Product',
          type: 'Product',
          model: 'Product',
          relationType: 'belongsTo',
                  keyFrom: 'Product__c',
          keyTo: 'sfdcId'
        },
        Jobsite: {
          name: 'Jobsite',
          type: 'Jobsite',
          model: 'Jobsite',
          relationType: 'belongsTo',
                  keyFrom: 'Jobsite__c',
          keyTo: 'sfdcId'
        },
        GeoMetro: {
          name: 'GeoMetro',
          type: 'GeoMetro',
          model: 'GeoMetro',
          relationType: 'belongsTo',
                  keyFrom: 'GEO_Code__c',
          keyTo: 'sfdcId'
        },
        Account: {
          name: 'Account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'Vendor_Account__c',
          keyTo: 'sfdcId'
        },
        VendorAccount: {
          name: 'VendorAccount',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'Vendor__c',
          keyTo: 'sfdcId'
        },
        QuoteManager: {
          name: 'QuoteManager',
          type: 'QuoteManager',
          model: 'QuoteManager',
          relationType: 'belongsTo',
                  keyFrom: 'QuoteManager__c',
          keyTo: 'sfdcId'
        },
        MetroVirtualVendorPool: {
          name: 'MetroVirtualVendorPool',
          type: 'MetroVirtualVendorPool',
          model: 'MetroVirtualVendorPool',
          relationType: 'belongsTo',
                  keyFrom: 'Vendor__c',
          keyTo: 'sfdcId'
        },
        RecordType: {
          name: 'RecordType',
          type: 'RecordType',
          model: 'RecordType',
          relationType: 'belongsTo',
                  keyFrom: 'RecordTypeId',
          keyTo: 'sfdcId'
        },
        Task: {
          name: 'Task',
          type: 'Task',
          model: 'Task',
          relationType: 'belongsTo',
                  keyFrom: 'PgMO_Tasks__c',
          keyTo: 'sfdcId'
        },
        CsqdLeadTalentType: {
          name: 'CsqdLeadTalentType',
          type: 'CsqdleadTalenttypeMapping',
          model: 'CsqdleadTalenttypeMapping',
          relationType: 'belongsTo',
                  keyFrom: 'Talent_Type__c',
          keyTo: 'talentType'
        },
      }
    }
  }
}
