import { Component, OnInit, Input } from '@angular/core';
import { PurchaseOrderApi, CaseApi } from 'shared/sdk';

@Component({
    selector: 'app-purchase-order-detail',
    templateUrl: './purchase-order-detail.component.html',
    styleUrls: ['./purchase-order-detail.component.scss']
})
export class PurchaseOrderDetailComponent implements OnInit {

    @Input() set record(e) {
        if (e && e.job && e.job.Work_Order__c) {
            this.purchaseOrderDetail = null;
            this.isDataAvailable = true;
            this.loadData(e);
        } else {
            this.purchaseOrderDetail = null;
            this.isDataAvailable = false;
        }
    }
    public purchaseOrderDetail = null;
    public expandedLineItems = false;
    public isDataAvailable = true;


    constructor(private _purchaseOrderApi: PurchaseOrderApi) { }

    ngOnInit() { }

    loadData(selectedItem) {
        let filterCondition = {};
        if (selectedItem.job) {
            // It's for Case Messaging Console(VMS/Job)
            filterCondition = { sfdcId: selectedItem.job.Work_Order__c };
        }

        const obj = {
            where: filterCondition,
            skip: 0,
            limit: 1,
            fields: ['sfdcId', 'WorkOrderNumber', 'Vendor__c', 'AccountId', 'Jobsite__c', 'Service_Dispatch__c', 'Project__c',
                'Status', 'Type__c', 'Sub_Type__c', 'Roll_up_FTE_OT_Standard__c', 'Roll_up_FTE_OT_Holiday__c', 'Roll_up_PPE_Hours__c',
                'Roll_up_Additional_Rate__c', 'Roll_up_Unit_Rate__c', 'Roll_up_Unit_Price__c', 'Grand_Total_Total_From_Line_Items__c',
                'Sales_Order__c', 'CaseId', 'Work_Order_num__c'],
            include: [
                {
                    relation: 'vendor',
                    scope: {
                        fields: ['sfdcId', 'Name']
                    }
                },
                {
                    relation: 'jobsite',
                    scope: {
                        fields: ['sfdcId', 'Name']
                    }
                },
                {
                    relation: 'job',
                    scope: {
                        fields: ['sfdcId', 'Iron_Job_num__c']
                    }
                },
                {
                    relation: 'lineItems',
                    scope: {
                        fields: ['sfdcId', 'LineItemNumber', 'List_Price__c', 'PPE__c', 'Sub_Total_Auto__c', 'VAT_GST__c',
                            'VAT_GST_percent__c', 'Discount__c', 'Final_Total_After_Discount_Tax__c', 'VAT_Tax__c']
                    }
                },
                {
                    relation: 'account',
                    scope: {
                        fields: ['sfdcId', 'Name', 'Default_Pricelist__c'],
                        include: [{
                            relation: 'pricelist',
                            scope: {
                                fields: ['sfdcId', 'Name']
                            }
                        }]
                    }
                },
                {
                    relation: 'project',
                    scope: {
                        fields: ['sfdcId', 'Name']
                    }
                },
                {
                    relation: 'case',
                    scope: {
                        fields: ['sfdcId', 'CaseNumber', 'Purchase_Order_Reference__c', 'Sales_Order_Reference__c']
                    }
                },
                {
                    relation: 'order',
                    scope: {
                        fields: ['sfdcId', 'OrderNumber']
                    }
                }
            ]
        }
        this._purchaseOrderApi.find(obj).subscribe((result) => {
            this.purchaseOrderDetail = {};
            if(result && result.length) {
                this.purchaseOrderDetail = result[0];
                if(this.purchaseOrderDetail && this.purchaseOrderDetail.sfdcId){
                    this.isDataAvailable = true;
                } else {
                    this.isDataAvailable = false;
                }
            }else {
                this.purchaseOrderDetail = null;
                this.isDataAvailable = false;
            }
        });
    }

    toggleLineItems() {
        this.expandedLineItems = !this.expandedLineItems;
    }

}
