import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { isBillable } from '../../../models/static-list-data.service'

@Component({
  selector: 'app-is-billable-lookup',
  templateUrl: './is-billable-lookup.component.html',
  styleUrls: ['./is-billable-lookup.component.css']
})
export class IsBillableLookupComponent implements OnInit {

  isBillableList = [];
  isBillableSelected = [];
  isMultipleSelected = true;

  @Input() set resetData(isClear) {
    if (isClear) {
      this.isBillableSelected = [];
    }
  }

  @Output() selectedisBillable: EventEmitter<any> = new EventEmitter;

  constructor() { }

  ngOnInit() {
    this.isBillableList = isBillable;
    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
    this.isBillableSelected = (preselected &&
      preselected['isBillable']) ? preselected['isBillable'] : '';
  }
  onisBillableChange() {
    this.selectedisBillable.emit(this.isBillableSelected);
  }
  clearisBillable() {
    this.isBillableSelected = [];
    this.selectedisBillable.emit([]);
  }


}
