import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { RequestFormReceiptsComponent } from './request-form-receipts.component';
import { FseRelatedFormsComponent } from './fse-related-forms/fse-related-forms.component';


@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule
  ],
  exports: [
    FseRelatedFormsComponent
  ],
  declarations: [RequestFormReceiptsComponent, FseRelatedFormsComponent]
})
export class RequestFormReceiptsModule { }
