import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from './../../services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-callback',
    templateUrl: './callback.component.html',
    styleUrls: ['./callback.component.css']
})
export class CallbackComponent implements OnInit {
    loggedInSub: Subscription;

    constructor(private auth: AuthService, private _activatedRoute: ActivatedRoute) {
        // Parse authentication hash
    }

    ngOnInit() {
        this.auth.handleLoginCallback(this._activatedRoute.snapshot.data.appName);
    }

}
