import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PriorityFilterList } from 'shared/models/static-list-data.service';

@Component({
  selector: 'app-priority-lookup',
  templateUrl: './priority-lookup.component.html',
  styleUrls: ['./priority-lookup.component.css']
})
export class PriorityLookupComponent implements OnInit {

  @Input() set resetData(isClear) {
    if (isClear) {
      this.prioritySelected = [];
    }
  }
  @Input() placeholder = 'Priority';
  @Input() isdisabled = false;
  @Input() filterObjValue = 'priority';
  @Input() isMultipleSelected = true;
  @Input() viewType = '';

  @Output() getPriorityObj: EventEmitter<any> = new EventEmitter;


  priorityOptionList = [];
  prioritySelected = [];

  /**
   * @constructor
   * Represents a PriorityLookupComponent.
   */
  constructor() {
  }

  ngOnInit() {
    this.priorityOptionList = PriorityFilterList;
    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
    this.prioritySelected = (preselected && preselected[this.filterObjValue]) ? preselected[this.filterObjValue] : '';
  }

  onPriorityChange() {
    this.getPriorityObj.emit(this.prioritySelected);
  }

  clearPriority() {
    this.prioritySelected = [];
    this.getPriorityObj.emit([]);
  }

}
