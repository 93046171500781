import { Injectable } from "@angular/core";
import { Adapter } from "./adapter";
import { Member, MemberAdaptor } from "./member";
import { Message, MessageAdaptor } from "./message";
import { AppStateService } from "shared/services/app-state.service";


export class Conversation {
    constructor(
        public conversation_id: number = null,
        public last_message: Message = new Message(),
        public last_message_id: number = null,
        public member: Member = new Member(),
        public message_id: number = null,
        public related_record_id: string = "",
        public title: string = "",
        public total_members: string = "",
        public type: string = "",
        public icon: string = "",
        public unread_count: string = "",
        public updated_at: string = "" ,    
        public isActive: boolean = false,
        public isFavorite: boolean = false,
        public category: string = '',
        public description: string = '',
        public marked_unread: boolean = false
    ){}
}

@Injectable({
    providedIn: "root"
})

export class ConversationAdaptor implements Adapter<Conversation> {
    private _sfdcId: any;
    constructor(private _memberAdapter: MemberAdaptor, private _messageAdapter: MessageAdaptor, private _appState: AppStateService) { 
        const currentUser = JSON.parse(localStorage.getItem('appData'));
        if (currentUser && currentUser.user && currentUser.user.sfdcId) {
        this._sfdcId = currentUser.user.sfdcId;
        }
    }

    adapt(item: any): Conversation {
        let  Participants = {}
            if(item.type === 'PERSONAL'){
                const cuserId = this._sfdcId;
                const index = item.participants.findIndex(x => x.sfdcId === cuserId);
                if (index > -1) {
                    item.participants.splice(index, 1); // 2nd parameter means remove one item only
                }
                Participants = {
                    id: item.participants[0].id ? item.participants[0].id : '',
                    firstName: item.participants[0].firstName ? item.participants[0].firstName : '',
                    lastName: item.participants[0].lastName ? item.participants[0].lastName : '',
                    profileImage: item.participants[0].profileImage ? item.participants[0].profileImage : '',
                    sfdcId: item.participants[0].sfdcId ? item.participants[0].sfdcId : '',
                    isActive: item.participants[0].isActive ? item.participants[0].isActive : false
                }
                item.channelName = item.participants[0].firstName ? `${item.participants[0].firstName} ${item.participants[0].lastName}` : ''
            }
            if(item.documenturl){
                item.isFile = true;
            }
            let lastMessage = {
                channelId: item.channelId ? item.channelId : '',
                message: (item.message || item.documenturl) ? (item.message || item.documenturl) : '',
                isFile: item.isFile ? item.isFile : '',
                id: item.messageid ? item.messageid : '',
                createdAt: item.createdAt ? item.createdAt : null,
                user: {
                    id: item.sender ? item.sender : '',
                    sfdcId:item.sfdcId ? item.sfdcId : '',
                    firstName: item.firstName ? item.firstName : '',
                    lastName: item.lastName ? item.lastName : '',
                    profileImage: item.profileImage ? item.profileImage : ''
                }
            }
            console.log("Item", item);
        let conversation = new Conversation(
            item.channelId ? item.channelId : '',
            (lastMessage ? this._messageAdapter.adapt(lastMessage) : new Message()),
            (item.message && item.message.id) ? item.message.id : '',
            (Participants ? this._memberAdapter.adapt(Participants) : new Member()),
            (item.message && item.messageid) ? item.messageid : '',
            item.related_record_id ? item.related_record_id : '',
            item.channelName ? item.channelName : '',
            item.participants && item.participants.length ? item.participants.length : '',
            item.type ? this.adaptChannelType(item.type) : '',
            item.icon ? item.icon : null,
            item.unread_count ? item.unread_count : '',
            item.updatedAt ? item.updatedAt : '',
            item.isActive ? item.isActive : 'false',
            item.isFavorite ? item.isFavorite : false,
            item.category ? item.category : '',
            item.description ? item.description : '',
            item.markedUnread ? item.markedUnread : false 
        )
        return conversation;
    }

    adaptNew(item: any): Conversation {
        let  Participants = {}
        if(item.type === 'PERSONAL'){            
            Participants = {
                id: item.participantId ? item.participantId : '',
                firstName: item.participantFirstName ? item.participantFirstName : '',
                lastName: item.participantLastName ? item.participantLastName : '',
                profileImage: item.participantProfileImage ? item.participantProfileImage : '',
                sfdcId: item.participantSdfcId ? item.participantSdfcId : '',
                isActive: item.participantIsActive ? item.participantIsActive : false
            }
            item.channelName = Participants['firstName'] ? `${Participants['firstName']} ${Participants['lastName']}` : ''
        }
        if(item.documenturl){
            item.isFile = true;
        }
        let lastMessage = {
            channelId: item.id ? item.id : '',
            message: (item.message || item.documenturl) ? (item.message || item.documenturl) : '',
            isFile: item.isFile ? item.isFile : '',
            id: item.messageid ? item.messageid : '',
            createdAt: item.createdAt ? item.createdAt : null,
            user: {
                id: item.sender ? item.sender : '',
                sfdcId:item.sfdcId ? item.sfdcId : '',
                firstName: item.firstName ? item.firstName : '',
                lastName: item.lastName ? item.lastName : '',
                profileImage: item.profileImage ? item.profileImage : ''
            }
        }
        console.log("Item", item);
        let conversation = new Conversation(
            item.id ? item.id : '',
            (lastMessage ? this._messageAdapter.adapt(lastMessage) : new Message()),
            item.messageId ? item.messageId : '',
            (Participants ? this._memberAdapter.adapt(Participants) : new Member()),
            item.messageId ? item.messageId : '',
            item.related_record_id ? item.related_record_id : '',
            item.channelName ? item.channelName : '',
            '',
            item.type ? this.adaptChannelType(item.type) : '',
            item.icon ? item.icon : null,
            item.unread_count ? item.unread_count : '',
            item.updatedAt ? item.updatedAt : '',
            item.isActive ? item.isActive : 'false',
            item.isFavorite ? item.isFavorite : false,
            item.category ? item.category : '',
            item.description ? item.description : '',
            item.markedUnread ? item.markedUnread : false 
        )
        return conversation;
    }

    reverseAdapt(item: any) {
        let conversation = {
            channelName: item.title ? item.title : '',
            participants: item.members ? item.members : [],
            channelType: this.reverseAdaptChannelType(item.conversation_type),
            icon: item.icon ? item.icon : '',
            category: item.category ? item.category : '',
            "source": "Web"
        };
        if (!Boolean(conversation.channelName)) {
            delete conversation.category
        }      
        return conversation;
    }

    reverseEditConverstionAdapt(item: any, titleNotChanged: boolean, descriptionNotChanged: boolean, categoryNotChanged: boolean, channelIcon:string = '') {
        let conversation = {
            channelId: item.conversation_id ? item.conversation_id : 0,
            channelName: item.title ? item.title : '',
            description: item.description ? item.description : '',
            participants: item.members ? item.members : [],
            channelType: this.reverseAdaptChannelType(item.conversation_type),
            category: item.category ? item.category : 'member',
            icon : channelIcon
        };

        if (channelIcon == '') {
            delete conversation.icon ;
        }
        if (titleNotChanged) {
            delete conversation.channelName;
        }   
        if (descriptionNotChanged) {
            delete conversation.description;
        }
        if (categoryNotChanged) {
            delete conversation.category;
        }    
        return conversation;
    }

    reverseFavAdapt(favItem: any){
        let favConversation = {
            isFavorite: favItem.is_fav
        }
        return favConversation;
    }

    reverseAdaptUnreadCount(item: any){
        let unreadCountsObj = {
            channelIds: (item && item.length) ? item : []
        } 
        return unreadCountsObj;
    }

    adaptChannelType(type: string){
        let channelType = '';
        switch (type) {
            case "PERSONAL":
                channelType = 'ONETOONE';
                break;
            case "MYCHANNEL":
                channelType = 'GROUP';
                break;
            case "FAVORITE":
                channelType = 'FC';
                break;    
            case "TEAM":
                channelType = 'TC';
                break;
            case "GROUP":
                channelType = 'GC';
                break; 
            case "CASE":
                channelType = 'CASE';
                break;   
            default:
                break;
        }
        return channelType;        
    }

    reverseAdaptChannelType(type: string){
        let channelType = '';
        switch (type) {
            case "ONETOONE":
                channelType = 'PERSONAL';
                break;
            case "GROUP":
                channelType = 'MYCHANNEL';
                break;
            case "FC":
                channelType = 'FAVORITE';
                break;
            case "TC":
                channelType = 'TEAM';
                break;
            case "GC":
                channelType = 'GROUP';
                break;     
            default:
                channelType = 'ALL';
                break;
        }
        return channelType;        
    }

    reverseAdaptAddConversationMembers(item: any){
        let conversation = {
            sfdcIds: item.members ? item.members : [],
        };
        return conversation;
    }
} 