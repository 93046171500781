import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { yearValues } from '../../../models/static-list-data.service'

@Component({
  selector: 'app-year-lookup',
  templateUrl: './year-lookup.component.html',
  styleUrls: ['./year-lookup.component.css']
})
export class YearLookupComponent implements OnInit {

  @Input() set resetData(isClear) {
    if (isClear) {
      this.yearSelected = [];
    }
  }
  @Input() placeholder = 'year';
  // @Input() isdisabled = false;
  @Input() isMultipleSelected = true;
  @Input() selectedDefaultYear
  @Input() viewType = '';
  @Input() disable ;
  @Output() selectedYear: EventEmitter<any> = new EventEmitter;
  yearOptionList = [];
  yearSelected = [];
  constructor() { }

  ngOnInit() {
    //this.yearOptionList = yearValues;
    const currentYear = new Date().getFullYear();
    for(let i=2019;i<=currentYear;i++){
      this.yearOptionList.push({value: i, label: i});
    }
    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
    this.yearSelected = (preselected && preselected['year']) ? preselected['year'] : '';
    if(this.selectedDefaultYear){
      this.yearSelected = this.selectedDefaultYear;
    }
  }

  onYearChange() {
    this.selectedYear.emit(this.yearSelected);
  }

  clearYear() {
    this.yearSelected = [];
    this.selectedYear.emit([]);
  }
}
