import { EmailServiceApi } from './../../../sdk/services/custom/EmailService';
import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { VirtualScrollModule } from 'angular2-virtual-scroll';
import { Subscription } from 'rxjs';

import { CommonService } from './../../../services/common.service';
import { MessageService } from '../../../services/message.service';
import { AppStateService } from '../../../services/app-state.service';
import { MessageTypes } from '../../../models/static-list-data.service';

import { environment } from '../../../../environments/environment';
import { PreloaderService } from '../../../services/preloader.service';
import * as moment from 'moment';
import { AlertService } from 'shared/services/alert.service';
import { BaseVendorViewComponent } from 'msp/shared/base-vendor-view/base-vendor-view.component';
import { DocumentApi } from 'shared/sdk';
@Component({
    selector: 'app-message-list',
    templateUrl: './message-list.component.html',
    styleUrls: ['./message-list.component.scss'],
    providers: [VirtualScrollModule]
})

export class MessageListComponent extends BaseVendorViewComponent implements OnInit, OnDestroy {
    @Input() showRepostOption = true;
    @Output() messageCount = new EventEmitter();
    @Output() messageUpdate = new EventEmitter();
    lastValue: string;
    lastRead: boolean;
    unReadCount: number;
    [x: string]: any;
    @Input() modelName: string;
    @Input() job_status: string;
    @Input() workOrderId: string;
    @Input()
    set roomFilter(e) {
        if (e && (e.Case__c || e.modelId || e.or[0].Case__c)) {
            this.loading = true;
            this._selectedChatRoom = e;
            this.errorMessage = '';
            this.loadMessageList(e);
        }
    }
    @Input()
    set modelId(e) {
        this._modelId = e;
        // this.loadMessageList();
    }
    @Input()
    set selectedItem(e) {
        this.loading = true;
        this.errorMessage = '';
        this._modelId = e.modelId ? e.modelId : e.id;
        this.selectedJob = e;
        this.commandCenterCount = 0;
        this.workerCenterCount = 0;
        this.loadMessageList(e.roomFilter);
    }
    @Input()
    set emailData(e) {
        if (e && Object.keys(e).length > 0) {
            this.sendJobMessageEmails(e);
        }
    }

    @Output() uploaded: EventEmitter<any> = new EventEmitter();
    @Input() activeTab = 'all';
    messageTypeFilterTabs = ['caseWoTab', 'caseTab', 'esclationsTab'];
    roleSub: Subscription;
    comments = [];
    imgpath: any;
    selectedJob: any;
    userAccessType: string;
    messageTypes = MessageTypes;
    errorMessage = '';
    userRole: any;
    _modelId: any;
    currentUser: any;
    private msgListSub: Subscription;
    commandCenterCount = 0;
    workerCenterCount = 0;
    repostMessage = '';
    loading = true;
    hidePostMSGArr = [0, 1];
    hidePostMSG = false;
    _selectedChatRoom: any;

    constructor(
        private _cd: ChangeDetectorRef,
        private _messageService: MessageService,
        private _appState: AppStateService,
        private _sanitizer: DomSanitizer,
        private _loader: PreloaderService,
        private _emailServiceApi: EmailServiceApi,
        private _alertService: AlertService,
        private _documentApi: DocumentApi
    ) {
        super(_appState);
        this._appState.getAppState().subscribe(
            data => {
                if (data && data['user']) {
                    this.currentUser = data['user'];
                }
            }
        );
    }


    ngOnInit() {
        this.userAccessType = this._appState.getAccessType();
        // To check if view as PMS mode is activated.
        if (localStorage.getItem('viewAsInfo')) {
            const viewAsInfo = JSON.parse(localStorage.getItem('viewAsInfo'));
            const viewAsPartner = (viewAsInfo && viewAsInfo.viewAsType === 'Partner');
            this.userAccessType = (viewAsPartner) ? 'partner' : this.userAccessType;
        }  else if(this.viewAsVendor) {
            this.userAccessType = 'vendor';
        }
        if(this.userAccessType === 'partner') {
            this.hidePostMSGArr = [0]
        }
        this._messageService.getchatRoomId().subscribe(res => {
            if (res && this.hidePostMSGArr.indexOf(res.idx) !== -1) {
                this.hidePostMSG = true;
            } else {
                this.hidePostMSG = false;
            }
        });
    }

    loadMessageList(selectedChatRoom?) {
        // get messages
        this.comments = [];
        this._messageService.unSubscribeMessageSub();
        if (this.msgListSub) {
            this.msgListSub.unsubscribe();
        }
        this.setUserPermisssionRole();
        if (this.modelName && this._modelId) {
            // this.errorMessage = '';
            this.imgpath = environment.baseUrl + '/' + environment.apiVersion;
            // get messages
            this._loader.showPreloader();
            let filter = {};
            if ((!this.viewAsVendor && this.currentUser && this.currentUser.accessType !== 'internal' && this.currentUser.accessType !== 'partner') || this.viewAsVendor) {
                filter = { modelName: this.modelName, modelId: this._modelId.toString() };
            } else if (selectedChatRoom && selectedChatRoom.Case__c) {  // This is use for all | PMS | PMC chat room.
                // this is used for All chat room on work order list tab
                if (this.selectedJob && this.selectedJob.isPopup && selectedChatRoom.idx === 0) {
                    filter = {
                        or: [
                            { Case__c: this.selectedJob.workOrder && this.selectedJob.workOrder.PMS_Case__c },
                            { Case__c: selectedChatRoom.Case__c, Is_Public__c: false }
                        ]
                    };
                } else {
                    if (typeof selectedChatRoom.or !== 'undefined') {
                        filter['or'] = selectedChatRoom.or;
                    } else if (typeof selectedChatRoom.Case__c !== 'undefined') {
                        filter['Case__c'] = selectedChatRoom.Case__c;
                    }
                    filter['modelName'] = 'Case';
                }
            } else if (selectedChatRoom && selectedChatRoom.modelId) {  // This is use for VMS chat room.
                filter['modelId'] = selectedChatRoom.modelId;
                filter['modelName'] = 'Job';
                filter['Is_Public_Job__c'] = true;
            } else {
                // this is used for All chat room on work order list tab for the first load when no chat room selected
                if (this.selectedJob && this.selectedJob.isPopup && !selectedChatRoom) {
                    filter = {
                        or: [
                            { Case__c: this.selectedJob.workOrder && this.selectedJob.workOrder.PMS_Case__c },
                            { Case__c: this.selectedJob.Case__c, Is_Public__c: false }
                        ]
                    };
                } else if (this.selectedJob && this.selectedJob.fromJobDetailPage) {
                    filter = { modelName: 'Case', Case__c: { inq: this.selectedJob.caseIds } }
                } else {
                    const caseArr = (this.selectedJob.job && this.selectedJob.job.Case__c) ?
                        [this.selectedJob.job.Case__c.toString()] : [];
                    if (this.selectedJob.job && this.selectedJob.job.PMC_Case_Number) {
                        caseArr.push(this.selectedJob.job.PMC_Case_Number);
                    }
                    if (caseArr && caseArr.length) {
                        if (this.selectedJob.job && this.selectedJob.job.PMS_Case_Number) {
                            filter['or'] = [{ and: [{ Case__c: this.selectedJob.job.PMS_Case_Number }, { Contact__c: { "exists": false } }] }, { Case__c: { inq: caseArr } }];
                        } else {
                            filter['Case__c'] = { inq: caseArr };
                        }
                    } else {
                        if (this.selectedJob.job && this.selectedJob.job.PMS_Case_Number) {
                            filter['or'] = [
                                { and: [
                                    { Case__c: this.selectedJob.job.PMS_Case_Number }, 
                                    { Contact__c: { "exists": false }}
                                ]}, 
                                { Case__c: this.selectedJob.Case__c }];
                        } else {
                            filter['Case__c'] = this.selectedJob.Case__c;
                        }
                    }
                    filter['modelName'] = 'Case';
                }
            }

            // This is use for sales order chat room.
            if (selectedChatRoom && selectedChatRoom.idx === 7) {
                filter['Order__c'] = this.selectedJob.job && this.selectedJob.job.Sales_Order__c;
            }
            // This is use for sales order chat room.
            if (selectedChatRoom && selectedChatRoom.idx === 9) {
                filter['Work_Order__c'] = this.selectedJob.job && this.selectedJob.job.Work_Order__c
            }
            const filterCondition = JSON.parse(localStorage.getItem('filterCondition'));
            // if (this.activeTab && this.messageTypeFilterTabs.includes(this.activeTab) && filterCondition &&
            //     filterCondition['Is_System_Generated__c'] && filterCondition['Is_System_Generated__c']['inq'] &&
            //     filterCondition['Is_System_Generated__c']['inq'].length) {
            //     if (filterCondition['Is_System_Generated__c']['inq'][0] === true) {
            //         filter['Contact__c'] = { $exists: false };
            //     } else {
            //         filter['Contact__c'] = { $exists: true };
            //     }
            // }
            if (this.activeTab && this.messageTypeFilterTabs.includes(this.activeTab) && filterCondition){
                if(filterCondition['Is_System_Generated__c'] && filterCondition['Is_System_Generated__c']['inq'] && filterCondition['Is_System_Generated__c']['inq'].length) {
                    if (filterCondition['Is_System_Generated__c']['inq'][0] === true) {
                        filter['Contact__c'] = { $exists: false };
                    } else {
                        if(this.currentUser.accessType === 'internal' && filterCondition['employeeTypeList'] && filterCondition['employeeTypeList']['inq'] && filterCondition['employeeTypeList']['inq'] && filterCondition['employeeTypeList']['inq'][0].length) {
                            filter['employeeTypeFilter'] = filterCondition['employeeTypeList'];
                        } else {
                            filter['Contact__c'] = { $exists: true };
                        }
                    }
                }
                if(this.currentUser.accessType === 'internal' && filterCondition['employeeTypeList'] && filterCondition['employeeTypeList']['inq'] && filterCondition['employeeTypeList']['inq'] && filterCondition['employeeTypeList']['inq'][0].length) {
                    filter['employeeTypeFilter'] = filterCondition['employeeTypeList'];
                }
            }
            this.msgListSub = this._messageService.getMessages(filter).subscribe(results => {
                this.loading = false;
                this._loader.hidePreloader();
                if (results) {
                    this.prepareMessagesData(results);
                } else {
                    this.errorMessage = 'No messages available';
                }
                this._documentApi.find({where: filter}).subscribe(document => {
                    document = document ? document : [];
                    this.messageUpdate.emit({ 'messageCount': this.comments.length, 'fileCount': document.length });
                })
            });
        } else {
            this._loader.hidePreloader();
            this.errorMessage = 'Some error occurred!';
        }
    }

    setUserPermisssionRole() {
        this.roleSub = this._appState.getJobDetailUserRole().subscribe(publicUser => {
            if (publicUser && publicUser['userRole']) {
                this.userRole = publicUser['userRole'];
            }
        });
    }
    /**
     * 
     * @param messages | pass message to modify
     */
    prepareMessagesData(messages) {
        const getProfileImageUrl = (msg) => {
            if (this.imgpath.indexOf('localhost') !== -1) {
                this.imgpath = 'https://den.dev.serviceo.me/api/v1';
            }
            if (msg && msg.owner) {
                if (msg.owner.profileImage && msg.owner.url) {
                    return `${this.imgpath}/Containers${msg.owner.url}${msg.owner.profileImage}`;
                } else {
                    if (msg.owner.gender === 'Male') {
                        return `assets/images/male-circle.png`;
                    } else if (msg.owner.gender === 'Female') {
                        return `assets/images/female-circle.png`;
                    } else {
                        return `assets/images/exclamation.png`;
                    }
                }
            } else if (msg && !msg.owner && !msg.Contact__c) {
                return `assets/images/computer.png`;
            } else {
                return `assets/images/exclamation.png`;
            }
        };
        messages.map(msg => {
            if (!this.comments.some((item) => item.id === msg.id)) {

                // This is for get date group in Today || Yeasterday || Month
                const diff = moment().diff(msg.createdAt, 'days');
                const createdAt = moment(msg.createdAt).format('D MMMM YYYY');
                const todayDate = moment().format('D MMMM YYYY');
                const yesterday = moment().subtract(1, 'days').format('D MMMM YYYY');

                let currentValue = '';
                if (createdAt == todayDate) {
                    currentValue = 'Today';
                } else if (createdAt == yesterday) {
                    currentValue = 'Yesterday';
                } else {
                    currentValue = createdAt;
                }

                // This is for msg Today || Yesterday || Month
                if (currentValue !== this.lastValue) {
                    this.lastValue = currentValue;
                    msg['groupName'] = currentValue;
                }
                // This is for unread msg group.
                if (this.lastRead && msg.isRead == "false") {
                    this.lastRead = false;
                    msg['UnReadMsg'] = true;
                }

                // This is for unread msg count.
                if (msg.isRead == "false") {
                    this.unReadCount += 1;
                }

                if (msg.owner && msg.owner.accessType == 'internal') {
                    this.commandCenterCount++;
                } else if (msg.owner && msg.owner.accessType == 'vendor') {
                    this.workerCenterCount++;
                }
                // This is for icc case => No need to view SO and PO message in this chat room
                if (!(this._selectedChatRoom && this._selectedChatRoom.idx === 3 && (msg.Order__c || msg.Work_Order__c))) {
                    this.comments.push(msg);
                    this.messageCount.emit(this.comments.length);
                }

                // This is for fetching user profile image
                msg['profileImg'] = getProfileImageUrl(msg);
                this.detectChanges();
            }
        });
    }
    convertToUrl(text) {
        if (text && !text.match('<p>(.*)</p>') && !text.match('<a(.*)</a>') && !text.match('<img src=(.*)/>')) {
            const exp1 = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
            const text1 = text.replace(exp1, '<a href="$1" target="_blank">$1</a>');
            const exp2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
            return text1.replace(exp2, '$1<a target="_blank" href="http://$2">$2</a>');
        } else {
            return text;
        }
    }

    fileUploaded() {
        this.uploaded.emit();
    }

    detectChanges() {
        if (!this._cd['destroyed']) {
            this._cd.detectChanges();
        }
    }
    ngOnDestroy() {
        if (this.roleSub) {
            this.roleSub.unsubscribe();
        }
        if (this.msgListSub) {
            this.msgListSub.unsubscribe();
        }
        this._messageService.unSubscribeMessageSub();
    }
    setProfileName(comment) {
        let abbr = "";
        let str = (comment && comment.postByName) ? comment.postByName.split(' ') : '';
        if (str) {
            for (let i = 0; i < str.length; i++) {
                abbr += str[i].substr(0, 1);
            }
            comment['profileName'] = abbr.slice(0, 2).toUpperCase();
        }
        else {
            comment['profileName'] = "";
        }

    }
    /**
     * 
     * @param e | pass latest added msg to show instant msg list
     */
    messageAdded(e) {
        // const owner = this.currentUser;
        // if (e['Contact__c']) {
        //     const owner = this.currentUser;
        //     e['owner'] = {
        //         accessType: owner.accessType ? owner.accessType : '',
        //         firstname: owner.firstname ? owner.firstname : '',
        //         id: owner.id ? owner.id : '',
        //         lastname: owner.lastname ? owner.lastname : '',
        //         profileImage: owner.profileImage ? owner.profileImage : '',
        //         sfdcId: owner.sfdcId ? owner.sfdcId : '',
        //         url: owner.url ? owner.url : '',
        //     }
        // } else {
        //     e['postByName'] = e['postByName'] ? e['postByName'] : "Auto";
        // }
        // e['postByName'] = (e['owner'] && e['owner'].firstname) ? e['owner'].firstname + ' ' + e['owner'].lastname : e['postByName'];
        // const newMsg = [];
        // newMsg.push(e);
        // if (e['Contact__c']) {
        //     const owner = this.currentUser;
        //     let comment;
        //     const messageTypeArray = [];
        //     const _newcomments = [];
        //     this.messageTypes.forEach(type => {
        //         messageTypeArray[type.value] = type.label;
        //     });
        //     if (e.messageType) {
        //         let messageType = comment.messageType;
        //         messageType = messageTypeArray[comment.messageType] ?
        //             messageTypeArray[comment.messageType] : comment.messageType;
        //         comment.messageTypeLabel = messageType;
        //         comment.Case_Comment_Body__c = this.convertToUrl(comment.Case_Comment_Body__c);
        //         comment.Case_Comment_Body__c = this._sanitizer.bypassSecurityTrustHtml(comment.Case_Comment_Body__c);
        //     }

        //     comment.userProfileImage = owner.profileImage ? owner.profileImage : '';
        //     comment.url = owner.url ? owner.url : '';
        //     comment.userFirstname = owner.firstname ? owner.firstname : '';
        //     comment.userLastname = owner.lastname ? owner.lastname : '';
        //     comment.userId = owner.id ? owner.id : '';
        //     comment.userType = owner.accessType ? owner.accessType : '';
        //     comment.postByName = (comment.userFirstname) ? (comment.userFirstname + ' ' + comment.userLastname) : comment.postByName;
        //     comment.postByName = comment['postByName'] ? comment['postByName'] : "Auto";
        //     comment['owner'] = { url: comment.url, profileImage: comment.userProfileImage }
        //     const imageUrl = (comment.url && comment.userProfileImage) ?
        //         this.imgpath + '/Containers' + comment.url + '' + comment.userProfileImage : '';
        //     this._commonService.imageExists(imageUrl, function (exists: any) {
        //         comment['isProfile'] = exists;
        //     });
        //     if (!comment['isProfile']) {
        //         this.setProfileName(comment);
        //     }
        //     comment['Is_Auto_Bitly'] = comment['Is_Auto_Bitly'] ? comment['Is_Auto_Bitly'] : false;
        //     _newcomments.push(comment);
        //     this.comments = [...this.comments, ..._newcomments];
        //     this.messageCount.emit(this.comments.length);
        //     this.detectChanges();
        // }
    }

    /**
   * For worker || command || you Label 
   * @param msg Item Object
   */
    getUserType(msg) {
        const userId = !this.viewAsVendor ? this.currentUser && this.currentUser.id : this.vendorAccessTypes.userObj && this.vendorAccessTypes.userObj.id;
        if (msg.owner && userId && msg.owner.id == userId) {
            return 'You';
        } else if (msg.owner && msg.owner.accessType == 'internal') {
            return 'Command Center';
        } else if (msg.owner && msg.owner.accessType == 'vendor')  {
            return (msg.owner.userTypeId == '2') ? 'Vendor' : 'Worker';
        }
    }

    /**
   * This is class using in message left, right, global, lock, worker,command, you.
   * @param msg Item Object
   */
    getMsgClasses(msg) {
        let messageClass = {};
        // For Left || right class 
        msg['#showMobile'] = msg.Is_sent_by_mobile__c;
        const userId = !this.viewAsVendor ? this.currentUser && this.currentUser.id : this.vendorAccessTypes.userObj && this.vendorAccessTypes.userObj.id;
        if (msg.owner && userId && msg.owner.id == userId) {
            messageClass['right'] = true;
            msg['#alignment'] = 'right';    
        } else {
            messageClass['left'] = true;
            msg['#alignment'] = 'left';
        }
        // For globe || lock class 
        if (msg.Is_Public__c) {
            messageClass['globe'] = true;
        } else {
            messageClass['lock'] = true;
        }
        // For worker || command || you class || Auto
        if (msg.Is_Auto_Bitly) {
            messageClass['auto'] = true;
        } else if (msg.owner && userId && msg.owner.id == userId) {
            messageClass['you'] = true;
        } else if (msg.owner && msg.owner.accessType == 'internal') {
            messageClass['command'] = true;
        } else if (msg.owner && msg.owner.accessType == 'vendor') {
            messageClass['worker'] = true;
        } else {
            messageClass['auto'] = true;
        }

        // if (msg.owner && this.currentUser && msg.owner.id == this.currentUser.id) {
        //     delete messageClass['globe'];
        //     delete messageClass['lock'];
        // }
        // console.log(messageClass)
        return messageClass;
    }
    /**
     * 
     * @param repostMsg | report message 
     */
    onRepostClick(repostMsg: any) {
        this.repostMessage = repostMsg.replace(/<[^>]+>/g, '');
    }

    sendJobMessageEmails(emailData) {
        const userId = !this.viewAsVendor ? this.currentUser && this.currentUser.id : this.vendorAccessTypes.userObj && this.vendorAccessTypes.userObj.id;
        emailData['comments'] = this.comments;
        emailData['currentUserId'] = (userId) ? userId : -1;
        this._loader.showPreloader();
        this._emailServiceApi.sendJobMessageEmail(emailData).subscribe(reply => {
            console.log(reply);
            this._loader.hidePreloader();
            this._alertService.success('Email sent successfully');
        }, error => {
            console.log(error);
            this._loader.hidePreloader();
            this._alertService.error('Something went wrong');
        });
    }
}
