import { MatDialogModule } from '@angular/material/dialog';
import { JobMessageEmailPopupComponent } from './../../../global-chat/job-message-console/job-message-email-popup/job-message-email-popup.component';
import { GlobalChatModule } from 'shared/views/global-chat/global-chat.module';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { UserInfoComponent } from './../user-info/user-info.component';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NguiMapModule } from '@ngui/map'; // Google nguimap integration
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../../../../components/shared.module';
import { TimecardModule } from '../../../../components/timecard/timecard.module';
import { InvoiceModule } from '../../../../components/invoice/invoice.module';
import { DocumentModule } from '../../../../components/document/document.module';
import { PaymentModule } from '../../../../components/payment/payment.module';
import { OrderDetailsModule } from '../../../../components/order-details/order-details.module';
import { FeedModule } from '../../../../components/feed/feed.module';
import { FeedTimelineModule } from '../../../../components/feed-timeline/feed-timeline.module';
import { MessageModule } from '../../../../components/message/message.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { InternationalPhoneNumberModule } from 'ng-phone-number';
import { RequestFormReceiptsModule } from '../../../../components/request-form-receipts/request-form-receipts.module';

import { ConfirmDialogService } from '../../../../services/confirm-dialog.service';
import { SurveyService } from 'shared/services/survey.service';

import { JobDetailComponent } from './job-detail.component';
import { JobDetailSummaryComponent } from './job-detail-summary/job-detail-summary.component';
import { JobDetailManagerComponent } from './job-detail-manager/job-detail-manager.component';
import { JobDetailBillingComponent } from './job-detail-billing/job-detail-billing.component';
import { JobDetailSidebarComponent } from './job-detail-sidebar/job-detail-sidebar.component';
import { JobDetailWorkerStatusComponent } from './job-detail-worker-status/job-detail-worker-status.component';
import { AddFruItemComponent } from './add-fru-item/add-fru-item.component';
import { JobDetailVendorComponent } from './job-detail-vendor/job-detail-vendor.component';
import { JobDetailWorkerComponent } from './job-detail-worker/job-detail-worker.component';
import { JobDetailHardwareInfoComponent } from './job-detail-hardware-info/job-detail-hardware-info.component';
import { JobRelatedJoblistComponent } from './job-related-joblist/job-related-joblist.component';
import { JobOrderItemlistComponent } from './job-order-item-list/job-order-item-list.component';
import { JobOrignalMessageComponent } from './job-orignal-message/job-orignal-message.component';
import { JobSurveyFormComponent } from './job-survey-form/job-survey-form.component';
import { FteJobScheduleComponent } from './fte-job-schedule/fte-job-schedule.component';

import { environment } from 'environments/environment';
import { SelectWorkerModule } from 'shared/components/select-worker/select-worker.module';
import { UpdateAppointmentModule } from 'shared/components/update-appointment/update-appointment.module';
import { JobDetailInstructionsModule } from './job-detail-instructions/job-detail-instructions.module';
import { JobStatusModule } from 'shared/components/job-status/job-status.module';
import { ProgressBarModule } from './../../../../../shared/components/progress-bar/progress-bar.module';
import { RightSidebarModule } from 'shared/components/right-sidebar/right-sidebar.module';
import { WorkerInfoModule } from 'shared/components/worker-info/worker-info.module';
import { WorkerListApvpModule } from './worker-list-apvp/worker-list-apvp.module';
import { CheckinCheckoutJobManagerModule } from 'shared/views/workforce-checkin-checkout/checkin-checkout-job-manager/checkin-checkout-job-manager.module';
import { TimesheetListModule } from 'shared/views/billing/contract-jobs/timesheet-list/timesheet-list.module';
import { MaketplaceCatalogModule } from 'shared/components/sg-marketplace/maketplace-catalog/maketplace-catalog.module';
import { ScheduledSmsListModule } from '../scheduled-sms-list/schedules-sms-list.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        NgbModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        NguiMapModule.forRoot({ apiUrl: 'https://maps.google.com/maps/api/js?key=' + environment.googleMapKey }),
        NgSelectModule,
        SharedModule,
        TimecardModule,
        InvoiceModule,
        DocumentModule,
        PaymentModule,
        OrderDetailsModule,
        FeedModule,
        FeedTimelineModule,
        MessageModule,
        NgxDatatableModule,
        NgxJsonViewerModule,
        InternationalPhoneNumberModule,
        RequestFormReceiptsModule,
        SelectWorkerModule,
        UpdateAppointmentModule,
        JobDetailInstructionsModule,
        JobStatusModule,
        ProgressBarModule,
        RightSidebarModule,
        WorkerInfoModule,
        GlobalChatModule,
        MatDialogModule,
        WorkerListApvpModule,
        CheckinCheckoutJobManagerModule,
        TimesheetListModule,
        MaketplaceCatalogModule,
        ScheduledSmsListModule

    ],
    providers: [ConfirmDialogService, SurveyService],
    declarations: [
        JobDetailComponent,
        JobDetailSummaryComponent,
        JobDetailManagerComponent,
        JobDetailBillingComponent,
        JobDetailSidebarComponent,
        JobDetailWorkerStatusComponent,
        JobDetailVendorComponent,
        JobDetailWorkerComponent,
        JobDetailHardwareInfoComponent,
        JobRelatedJoblistComponent,
        JobOrignalMessageComponent,
        UserInfoComponent,
        AddFruItemComponent,
        JobOrderItemlistComponent,
        JobSurveyFormComponent,
        FteJobScheduleComponent
    ],
    entryComponents: [
        JobMessageEmailPopupComponent
    ],
    schemas: [],
})

export class JobDetailModule {

}
