import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WeeklyDateRangeLookupComponent } from './weekly-date-range-lookup.component';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule
  ],
  declarations: [WeeklyDateRangeLookupComponent],
  exports:[WeeklyDateRangeLookupComponent]
})
export class WeeklyDateRangeLookupModule { }
