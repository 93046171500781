import { Component, EventEmitter, OnInit, Input, Output, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalService } from '../../services/modal.service';
import { AlertService } from '../../services/alert.service';
import { PreloaderService } from '../../services/preloader.service';
import { CommonService } from '../../services/common.service'
import { AppointmentApi } from '../../sdk';
import { Observable, forkJoin, Subscription } from 'rxjs';
import { ReasonForDeviation } from 'shared/models/static-list-data.service';

@Component({
    selector: 'app-update-appointment',
    templateUrl: './update-appointment.component.html',
    styleUrls: ['./update-appointment.component.css']
})

export class UpdateAppointmentComponent implements OnInit { 
    // , OnDestroy {
    // workerPhone: string;
    @Input() workerPhone: string;
    // @Input() getWorkers: Observable<any>;
    @Input() workerName: string;
    // @Input() workerSfdcId: string;
    @Input() appointmentC: string;
    @Input() sfdcId: string;
    @Input() timeZone = '';
    @Input() job;


    @Output() savedAppointment: EventEmitter<any> = new EventEmitter();
    formAppointmentWorker: FormGroup;
    jobData: any;
    appointmentData: any;
    error = [];
    hasAppointment = false;
    hasWroker = false;
    Worker_End_DateTime_Scheduled: any;
    Worker_Arrival_DateTime_Scheduled: any;
    showWorkerAppointment = true;
    defaultWorkerArrivalDateTIme = new Date();
    defaultCurrentDateTime  = new Date();
    // subscriptions: Subscription;
    ReasonForDeviation = ReasonForDeviation;
    showReasonForDeviationSection: boolean = true;
    showOtherChangeReason:boolean;
    isChecked: boolean;

    constructor(
        private _modalService: ModalService,
        private _alertService: AlertService,
        private _preloaderService: PreloaderService,
        private _commonService: CommonService,
        private _appointmentApi: AppointmentApi,
        private _fb: FormBuilder,
        private _cd: ChangeDetectorRef
    ) { }

    ngOnInit() {
        if (this.sfdcId) {
            if (this.appointmentC) {
                this.buildForm();
                this.getAppointment(this.appointmentC);
            } else {
                this.hasAppointment = false;
                this.error.push('Appointment is not associated with this Job. Please contact Adminstrator.');
            }
        } else {
            this.error.push('Job not found.');
        }
        if (this.job) {
            if(this.job.appointment['Appointment_Status__c']=="Same As Customer Requested"){
                this.isChecked=true;
                this.setFormValue('Same_as_Customer_Requested__c', true);
            }else if(this.job.appointment['Appointment_Status__c']=="Different from Customer Request"){
                this.isChecked=false;
                this.setFormValue('Same_as_Customer_Requested__c', false);
            }else if(this.job.appointment['Appointment_Status__c']=="Pending"){
                this.isChecked=true;
                this.setFormValue('Same_as_Customer_Requested__c', false);
            }else{
                this.isChecked=true;
            }
            this.jobData = { ...this.job };
            if (this.jobData && this.jobData.appointment) {
                if (this.jobData.appointment.Worker_Arrival_DateTime_Cust_Requested__c) {
                    this.jobData.appointment.Worker_Arrival_DateTime_Cust_Requested__c = this.jobData.appointment.Worker_Arrival_DateTime_Cust_Requested__c.substr(0, 20);
                    this.defaultWorkerArrivalDateTIme = new Date(this.job.appointment.Worker_Arrival_DateTime_Cust_Requested__c);
                }
                if (this.jobData.appointment.Worker_Arrival_Date_Customer_Req_End__c) {
                    this.jobData.appointment.Worker_Arrival_Date_Customer_Req_End__c = this.jobData.appointment.Worker_Arrival_Date_Customer_Req_End__c.substr(0, 20);
                }
            }
        }
    }

    buildForm() {
        this.formAppointmentWorker = this._fb.group({
            Worker_Arrival_DateTime_Scheduled__c: [''],
            Worker_End_DateTime_Scheduled__c: [''],
            Customer_Appointment_Change_Reason_Codes__c: [''],
            Reason_Customer__c:[''],
            Same_as_Customer_Requested__c: [true],
            Job__c: [this.sfdcId, Validators.required],
            Appointment_Status__c: ['Pending'],
            Worker_Apptmnt_Customer_Approval_Status__c: ['Pending']
        });
    }


    getAppointment(appointmentC) {
        this.error = [];
        if (this.workerName) {
            this.hasWroker = true;
        } else {
            this.hasWroker = false;
            this.error.push('Worker is not associated with this Job.');
        }
        // return this._appointmentApi.find({
        this._appointmentApi.find({
            'where': { ICC_Case__c: appointmentC },
            'fields': ['id', 'sfdcId', 'Worker_Arrival_DateTime_Scheduled__c', 'Worker_End_DateTime_Scheduled__c', 'ICC_Case__c', 'Same_as_Customer_Requested__c', 'Customer_Appointment_Change_Reason_Codes__c', 'Appointment_Status__c','Reason_Customer__c'],
            'include': [
                {
                    'relation': 'Case',
                    'scope': {
                        'fields': ['id', 'sfdcId', 'PPE_Hours__c']
                    }
                }]
        }).subscribe(
            data => {
                this.appointmentData = data[0];
                if (this.appointmentData) {
                    this.hasAppointment = true;
                    this.Worker_Arrival_DateTime_Scheduled = this.appointmentData.Worker_Arrival_DateTime_Scheduled__c ?
                        this._commonService.applyNewTimeZone(this.appointmentData.Worker_Arrival_DateTime_Scheduled__c, this.timeZone) :
                        null;
                    this.Worker_End_DateTime_Scheduled = this.appointmentData.Worker_End_DateTime_Scheduled__c ?
                        this._commonService.addTime(this.Worker_Arrival_DateTime_Scheduled,
                            this.appointmentData.Case.PPE_Hours__c, 'h') : null;
                    // this.Worker_End_DateTime_Scheduled = this.appointmentData.Worker_End_DateTime_Scheduled__c ?
                    //     this._commonService.applyNewTimeZone(this.appointmentData.Worker_End_DateTime_Scheduled__c, this.timeZone) : null;
                    this.formAppointmentWorker.controls['Worker_Arrival_DateTime_Scheduled__c'].
                        setValue(this.appointmentData.Worker_Arrival_DateTime_Scheduled__c);
                    this.formAppointmentWorker.controls['Worker_End_DateTime_Scheduled__c'].
                        setValue(this.appointmentData.Worker_End_DateTime_Scheduled__c);
                    if (this.Worker_Arrival_DateTime_Scheduled) {
                        this.showWorkerAppointment = false;
                    }
                    if (this.appointmentData.Customer_Appointment_Change_Reason_Codes__c) {
                        this.setFormValue('Customer_Appointment_Change_Reason_Codes__c', this.appointmentData.Customer_Appointment_Change_Reason_Codes__c);
                    }
                    if(this.appointmentData.Reason_Customer__c){
                        this.setFormValue('Reason_Customer__c',this.appointmentData.Reason_Customer__c);
                    }
                    if (this.appointmentData.Customer_Appointment_Change_Reason_Codes__c === 'Other'){
                        this.showOtherChangeReason = true;
                    }
                    if (this.appointmentData.Appointment_Status__c) {
                        this.setFormValue('Appointment_Status__c', this.appointmentData.Appointment_Status__c);
                    }
                    if (this.appointmentData.Same_as_Customer_Requested__c === false && this.appointmentData.Worker_Arrival_DateTime_Scheduled__c == null) {
                        if (this.appointmentData.Appointment_Status__c == null) {
                            this.setFormValue('Same_as_Customer_Requested__c', true);
                            this.showReasonForDeviationSection = false;
                        } else {
                            this.setFormValue('Same_as_Customer_Requested__c', this.appointmentData.Same_as_Customer_Requested__c);
                            this.showReasonForDeviationSection = true;
                        }
                    } else {
                        this.setFormValue('Same_as_Customer_Requested__c', true);
                        this.showReasonForDeviationSection = false;
                    }
                    this.setFormValue('Worker_Apptmnt_Customer_Approval_Status__c', 'Pending');
                } else {
                    this.hasAppointment = false;
                    this.error.push('Appointment is not associated with this Job. Please contact Adminstrator.');
                }
            },
            err => {
                this.error.push(err);
            }
        );
    }

    updateAppointment() {
        this.error = [];
        this.appointmentUpdate();
        const values = this.formAppointmentWorker.value;
        this._preloaderService.showPreloader();
        if (this.formAppointmentWorker.valid) {
            if (values.Same_as_Customer_Requested__c && this.timeZone) {
                values.Worker_Arrival_DateTime_Scheduled__c = this._commonService.setNewTimeZone(
                    this.jobData.appointment.Worker_Arrival_DateTime_Cust_Requested__c, this.timeZone);
                values.Worker_End_DateTime_Scheduled__c = this._commonService.setNewTimeZone(
                    this.jobData.appointment.Worker_Arrival_Date_Customer_Req_End__c, this.timeZone);
            } else if (this.timeZone) {
                values.Worker_Arrival_DateTime_Scheduled__c = this._commonService.setNewTimeZone(
                    values.Worker_Arrival_DateTime_Scheduled__c, '');
                values.Worker_End_DateTime_Scheduled__c = this._commonService.setNewTimeZone(
                    values.Worker_End_DateTime_Scheduled__c, '');
            }

            this._appointmentApi.patchAttributes(this.appointmentData.id, values).subscribe(
                result => {
                    this._preloaderService.hidePreloader();
                    this._alertService.success('Appointment has been updated successfully');
                    this.savedAppointment.emit(1);
                    this._modalService.closed();
                },
                err => {
                    this._preloaderService.hidePreloader();
                    this._alertService.error(err.message);
                    this._modalService.closed();
                }
            );
        } else {
            this.error = ['Please fill Required Fields'];
            this._preloaderService.hidePreloader();
        }
    }

    onDateChange(e, field) {
        const newValue = e.value;
        const newValueStr = newValue.toISOString();
        const tranformedValue = this._commonService.setNewTimeZone(newValueStr, this.timeZone);
        if (field === 'Worker_Arrival_DateTime_Scheduled__c') {
            this.formAppointmentWorker.controls.Worker_Arrival_DateTime_Scheduled__c.setValue(tranformedValue);
            this.Worker_Arrival_DateTime_Scheduled = newValueStr;

            // Adding PPE Hours for getting End Date
            if (this.appointmentData && this.appointmentData.Case && this.appointmentData.Case.PPE_Hours__c) {
                const endDatTime = this._commonService.addTime(newValue, this.appointmentData.Case.PPE_Hours__c, 'h');
                this.formAppointmentWorker.controls.Worker_End_DateTime_Scheduled__c.setValue(endDatTime)
                this.Worker_End_DateTime_Scheduled = endDatTime;

            } else {
                this.formAppointmentWorker.value.Worker_End_DateTime_Scheduled__c = newValueStr;
                this.Worker_End_DateTime_Scheduled = newValueStr;
            }
        }
        this._cd.detectChanges();
    }

    appointmentUpdate() {
        this.showWorkerAppointment = this.formAppointmentWorker.value.Same_as_Customer_Requested__c || false;
        if (this.showWorkerAppointment) {
            this.setFormValue('Appointment_Status__c', 'Same As Customer Requested');
            this.disableControl('Customer_Appointment_Change_Reason_Codes__c');
            this.disableControl('Worker_Arrival_DateTime_Scheduled__c');
            // this.clearFormFields(['Reason_for_different_from_customer_reqst__c', 'Worker_Arrival_DateTime_Scheduled__c', 'Worker_End_DateTime_Scheduled__c'])
            this.showReasonForDeviationSection = false; 

        } else {
            this.setFormValue('Appointment_Status__c', 'Awaiting Customer Approval');
            this.enableControl('Worker_Arrival_DateTime_Scheduled__c');
            this.enableControl('Customer_Appointment_Change_Reason_Codes__c');
            this.showReasonForDeviationSection = true;
        }
    }

    disableControl(field) {
        if (this.formAppointmentWorker && this.formAppointmentWorker.controls && this.formAppointmentWorker.controls[field]) {
            this.formAppointmentWorker.controls[field].clearValidators();
            this.formAppointmentWorker.controls[field].updateValueAndValidity();
            // this.formAppointmentWorker.controls[field].setValue('');
        }
    }

    enableControl(field, validations?) {
        if (this.formAppointmentWorker && this.formAppointmentWorker.controls && this.formAppointmentWorker.controls[field]) {
            const userTypeValidator = Validators.compose([Validators.required]);
            this.formAppointmentWorker.controls[field].setValidators(userTypeValidator);
            this.formAppointmentWorker.controls[field].updateValueAndValidity();
        }
    }

    clearFormFields(fields) {
        fields.forEach(element => {
            this.formAppointmentWorker.controls[element].setValue('');
            if (element === 'Worker_Arrival_DateTime_Scheduled__c') {
                this.Worker_Arrival_DateTime_Scheduled = '';
            }
            if (element === 'Worker_End_DateTime_Scheduled__c') {
                this.Worker_End_DateTime_Scheduled = '';
            }
        });
    }

    setFormValue(field, value) {
        this.formAppointmentWorker.controls[field].setValue(value);
    }

    onChangeReasonCode(val) {
        if (val === 'Other') {
          this.showOtherChangeReason = true;
          this.enableControl('Reason_Customer__c');
        } else {
          this.showOtherChangeReason = false;
          this.disableControl('Reason_Customer__c');
        }
      }

    getFormValue(field) {
        return this.formAppointmentWorker.controls[field].value;
    }

}