
import { JobApi } from './../../sdk/services/custom/Job';
import { PreloaderService } from './../../services/preloader.service';
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-worker-trip-details-sidebar',
  templateUrl: './worker-trip-details-sidebar.component.html',
  styleUrls: ['./worker-trip-details-sidebar.component.css']
})
export class WorkerTripDetailsSidebarComponent implements OnInit {
  job: any;
  customerContactDetial: any = {};
  @Input() set selectedJob(e) {
    this.job = e;
    this.setCustomerContactDetial()
  }
  constructor(
    private _router: Router,
    private _jobApi: JobApi,
    private _preloaderService: PreloaderService,
  ) { }

  ngOnInit() {
    this.subscribeJobStatus();
  }

  /**
 * Update Job Status
 */
  subscribeJobStatus() {
    if (this.job && this.job.id) {
      this._preloaderService.showPreloader();
      this._jobApi.findById(this.job.id, { fields: ['sfdcId', 'id', 'Job_Status_Internal__c'] }).subscribe(res => {
        if (res && res['Job_Status_Internal__c']) {
          this.job['Job_Status_Internal__c'] = res['Job_Status_Internal__c']
        }
        this._preloaderService.hidePreloader();
      });
    }
  }

  setCustomerContactDetial() {
    if (this.job && this.job.Jobsite_Contact_SDesk_Name_phone_email__c) {
      const contactDetial = this.job.Jobsite_Contact_SDesk_Name_phone_email__c.split(';')
      this.customerContactDetial.name = typeof contactDetial[0] !== 'undefined' ? contactDetial[0] : '';
      this.customerContactDetial.phone = typeof contactDetial[1] !== 'undefined' ? contactDetial[1] : '';
      this.customerContactDetial.email = typeof contactDetial[2] !== 'undefined' ? contactDetial[2] : '';
  }
  }

  openWorkerTracker(template) {
    // this._modalService.open(template, 'xxxl');
    this._router.navigate(['/pms/dispatch-fleet-management/gps-work-force']);
}

}
