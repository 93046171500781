import { Component, OnInit, Input } from '@angular/core';
import { JobApi } from '../../../../../sdk';
import { PreloaderService } from '../../../../../services/preloader.service';

@Component({
    selector: 'job-detail-worker',
    templateUrl: './job-detail-worker.component.html',
    styleUrls: ['./job-detail-worker.component.css']
})
export class JobDetailWorkerComponent implements OnInit {
    @Input() jobId?: string;
    @Input() jobObj?: any;
    job: any;
    errorMessage = '';
    loading: boolean;

    constructor(
        private _preloaderService: PreloaderService,
        private _jobApi: JobApi,
    ) { }

    ngOnInit() {
        
    }
    ngOnChanges() {
        this.loading = true;
        if (this.jobId) {
            this.loadData(this.jobId);
        } else if(this.jobObj) {
            this.prepareJobResult(JSON.parse(this.jobObj));
        } else {
            this.errorMessage = 'No details to display.';
        }
    }
    loadData(jobId) {
        const reqObj = {
            'where': { 'sfdcId': jobId },
            'fields': ['id', 'sfdcId', 'Dispatch_Worker_Name__c'],
            'include': [
                {
                    'relation': 'worker',
                    'scope': {
                        'fields': ['id', 'sfdcId', 'Name', 'Dispatch_Worker_num__c', 'Work_Phone_Number__c', 'Primary_Worker_Skilling_Profile__c',
                            'Worker_Type__c', 'Worker_Rating__c'],
                        'include': {
                            'relation': 'skilling',
                            'scope': {
                                'fields': ['sfdcId', 'Name', 'Primary_Talent_Type__c'],
                                'include': {
                                    'relation': 'talentType',
                                    'scope': {
                                        'fields': ['sfdcId', 'Name']
                                    }
                                }
                            }
                        }
                    }
                }
            ]
        };
        this._jobApi.getJobDetailsById(reqObj).subscribe(
            result => {
                this.prepareJobResult(result);
            },
            error => {
                this.loading = false;
                this.errorMessage = error.message;
                this._preloaderService.hidePreloader();
            }
        );
    }
    prepareJobResult(result){
        if (result && Object.keys(result).length > 0) {
            this.job = result;
            this.job['talentTypeName'] = (result['worker'] && result['worker']['skilling'] && result['worker']['skilling']['talentType']) ?
                result['worker']['skilling']['talentType']['Name'] : '';
        }
        this.errorMessage = this.job && this.job.worker ? '' : 'Worker is not assigned yet';
        this.loading = false;
        this._preloaderService.hidePreloader();
    }
}
