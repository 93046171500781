import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SendSmsComponent } from './send-sms.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JobLookupModule } from '../lookup/job-lookup/job-lookup.module';
import { AlertModule } from '../alert/alert.module';
import { WorkerLookupModule } from '../lookup/worker-lookup/worker-lookup.module';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    JobLookupModule,
    WorkerLookupModule,
    AlertModule,
    NgSelectModule
  ],
  declarations: [SendSmsComponent],
  exports: [SendSmsComponent],
})
export class SendSMSModule { }
