import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule, Router } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { SharedModule } from '../shared/components/shared.module';
import { SDKBrowserModule } from './../shared/sdk/index';
import { Error404Module } from '../shared/views/error/404/404.module';
import { Error403Module } from '../shared/views/error/403/403.module';
import { DownloadsModule } from './../shared/views/downloads/downloads.module';
import { JobDetailModule } from '../shared/views/vms/jobs/job-detail/job-detail.module';

import { AppStateService } from './../shared/services/app-state.service';
import { AuthGuard, ActiveUserAuthGuard } from './../shared/services/auth-guard.service';
import { AuthService } from './../shared/services/authentication.service';
import { InterceptorService } from '../shared/services/token.interceptor';
import { AuthorizationService } from '../shared/services/authorization.service';
import { CommonService } from './../shared/services/common.service';
import { ModalService } from './../shared/services/modal.service';
import { AlertService } from './../shared/services/alert.service';
import { PreloaderService } from './../shared/services/preloader.service';
import { WorkflowService } from './../shared/services/workflow.service';
import { MessageService } from './../shared/services/message.service';
import { DocumentService } from '../shared/services/document.service';
import { DepartmentService } from '../shared/services/department.service';
import { GlobalFilterService } from '../shared/services/global-filter.service';

import { AppRoutingModule } from './app.routes';
import { AppComponent } from './app.component';
import { CallbackComponent } from '../shared/views/callback/callback.component';

import { DataFormatter } from './../shared/libs/dataFormatter';
import { SocketService } from './../shared/services/socket.service';
import { MessageSocketService } from './../shared/services/message-socket.service';
import { CookieService } from 'ngx-cookie-service';
import { Angulartics2Module } from 'angulartics2';
import { NotificationService } from './../shared/services/notification.service';
import { GpsSocketService } from './../shared/services/gps-socket.service';
import { PublicCaqModule } from 'shared/components/public-caq/public-caq.module';
import { NotificationSocketService } from 'shared/services/notification-socket.service';
import { MatDialogModule } from '@angular/material';
import { SharedLoginLogComponent } from 'shared/components/shared-login-log/shared-login-log.component';
import { SharedLoginLogModule } from 'shared/components/shared-login-log/shared-login-log.module';


@NgModule({
    declarations: [
        AppComponent,
        CallbackComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        NgbModule.forRoot(),
        SDKBrowserModule.forRoot(),
        RouterModule,
        AppRoutingModule,
        SharedModule,
        Error404Module,
        Error403Module,
        HttpClientModule,
        DownloadsModule,
        JobDetailModule,
        PublicCaqModule,
        Angulartics2Module.forRoot(),
        SharedLoginLogModule,
        MatDialogModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptorService,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthorizationService,
            multi: true
        },
        AppStateService,
        AuthGuard,
        AuthService,
        AlertService,
        ActiveUserAuthGuard,
        CommonService,
        ModalService,
        PreloaderService,
        WorkflowService,
        MessageService,
        DocumentService,
        DepartmentService,
        DataFormatter,
        SocketService,
        MessageSocketService,
        NotificationSocketService,
        CookieService,
        NotificationService,
        GlobalFilterService,
        GpsSocketService,
    ],
    bootstrap: [AppComponent],
    entryComponents: [SharedLoginLogComponent]
})
export class AppModule {
}
