import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { SmsStatusLookupComponent } from './sms-status-lookup.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule
  ],
  declarations: [SmsStatusLookupComponent],
  exports: [SmsStatusLookupComponent],
  providers: []
})
export class SmsStatusLookupModule { }
