/* tslint:disable */

declare var Object: any;
export interface SkillingBackgroundCheckInterface {
  "sfdcId"?: string;
  "CreatedById"?: string;
  "CurrencyIsoCode"?: string;
  "LastModifiedById"?: string;
  "RecordTypeId"?: string;
  "Name"?: string;
  "Verification_Test_Status__c"?: string;
  "Identity_Expiry_Date__c"?: Date;
  "Identity_Issue_Date__c"?: Date;
  "Identity_Proof_Status__c"?: string;
  "Iron_Approved__c"?: boolean;
  "Is_this_document_on_File__c"?: boolean;
  "PDF_Attached__c"?: boolean;
  "Report_Link__c"?: string;
  "Resource_Worker__c"?: string;
  "Skilling__c"?: string;
  "Type_of_Identity_Proof__c"?: string;
  "Verification_Test_Date__c"?: Date;
  "Verification_Test_Result__c"?: string;
  "Worker__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class SkillingBackgroundCheck implements SkillingBackgroundCheckInterface {
  "sfdcId": string;
  "CreatedById": string;
  "CurrencyIsoCode": string;
  "LastModifiedById": string;
  "RecordTypeId": string;
  "Name": string;
  "Verification_Test_Status__c": string;
  "Identity_Expiry_Date__c": Date;
  "Identity_Issue_Date__c": Date;
  "Identity_Proof_Status__c": string;
  "Iron_Approved__c": boolean;
  "Is_this_document_on_File__c": boolean;
  "PDF_Attached__c": boolean;
  "Report_Link__c": string;
  "Resource_Worker__c": string;
  "Skilling__c": string;
  "Type_of_Identity_Proof__c": string;
  "Verification_Test_Date__c": Date;
  "Verification_Test_Result__c": string;
  "Worker__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: SkillingBackgroundCheckInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `SkillingBackgroundCheck`.
   */
  public static getModelName() {
    return "SkillingBackgroundCheck";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of SkillingBackgroundCheck for dynamic purposes.
  **/
  public static factory(data: SkillingBackgroundCheckInterface): SkillingBackgroundCheck{
    return new SkillingBackgroundCheck(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'SkillingBackgroundCheck',
      plural: 'SkillingBackgroundChecks',
      path: 'SkillingBackgroundChecks',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "RecordTypeId": {
          name: 'RecordTypeId',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "Verification_Test_Status__c": {
          name: 'Verification_Test_Status__c',
          type: 'string'
        },
        "Identity_Expiry_Date__c": {
          name: 'Identity_Expiry_Date__c',
          type: 'Date'
        },
        "Identity_Issue_Date__c": {
          name: 'Identity_Issue_Date__c',
          type: 'Date'
        },
        "Identity_Proof_Status__c": {
          name: 'Identity_Proof_Status__c',
          type: 'string'
        },
        "Iron_Approved__c": {
          name: 'Iron_Approved__c',
          type: 'boolean'
        },
        "Is_this_document_on_File__c": {
          name: 'Is_this_document_on_File__c',
          type: 'boolean'
        },
        "PDF_Attached__c": {
          name: 'PDF_Attached__c',
          type: 'boolean'
        },
        "Report_Link__c": {
          name: 'Report_Link__c',
          type: 'string'
        },
        "Resource_Worker__c": {
          name: 'Resource_Worker__c',
          type: 'string'
        },
        "Skilling__c": {
          name: 'Skilling__c',
          type: 'string'
        },
        "Type_of_Identity_Proof__c": {
          name: 'Type_of_Identity_Proof__c',
          type: 'string'
        },
        "Verification_Test_Date__c": {
          name: 'Verification_Test_Date__c',
          type: 'Date'
        },
        "Verification_Test_Result__c": {
          name: 'Verification_Test_Result__c',
          type: 'string'
        },
        "Worker__c": {
          name: 'Worker__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
