import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, AfterContentInit } from '@angular/core';
import { filter, distinctUntilChanged, debounceTime, switchMap } from 'rxjs/operators';
import { TemplateHeaderApi } from 'shared/sdk/services/custom/TemplateHeader';

@Component({
  selector: 'app-template-name-lookup',
  templateUrl: './template-name-lookup.component.html',
  styleUrls: ['./template-name-lookup.component.css']
})
export class TemplateNameLookupComponent implements OnInit, AfterContentInit {

  @Input() set resetData(isClear) {
    this.templateSelected = [];
  }
  @Input() isMultipleSelected = true;
  @Output() getTemplateName: EventEmitter<any> = new EventEmitter;
  noTemplateFound = 'No Template found.';
  maxSelectedItems = 20;
  templateTypeahead = new EventEmitter<string>();
  templateList = [];
  templateSelected = [];

  constructor(
    private _cd: ChangeDetectorRef,
    private _templateHeaderApi: TemplateHeaderApi,
  ) { }

  ngOnInit() {
    this.getTemplates();
  }

  ngAfterContentInit() {
    // To show selected template
    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
    this.templateSelected = (preselected && preselected['templateName']) ? preselected['templateName'] : '';
    if (this.templateSelected && this.templateSelected.length) {
      this.getTemplateList({ sfdcId: { inq: this.templateSelected }}).subscribe(
          x => {
              this.templateList = x;
              this.noTemplateFound = (this.templateList.length === 0) ? 'No account found.' : '';
          },
          err => {
              console.log(err);
              this.templateList = [];
              this.noTemplateFound = '';
          }
      );
      this.getTemplateName.emit(this.templateSelected);
    }
  }

  getTemplates() {
    this.templateList = [];
      this.templateTypeahead
        .pipe(
          filter(term => term && term.trim().length > 2),
          distinctUntilChanged(),
          debounceTime(200),
          switchMap(term => {
            const searchPattern = { like: '%' + term.trim() + '%', options: 'i' };
            const whereObj = {
              Template_Name__c: searchPattern
            };
            return this.getTemplateList(whereObj);
          })
        )
        .subscribe(
          x => {
            this._cd.markForCheck();
            this.templateList = x;
            this.noTemplateFound = (this.templateList.length === 0) ? 'No template found.' : '';
          },
          err => {
            console.log(err);
            this.templateList = [];
            this.noTemplateFound = '';
          }
        );
  }

  getTemplateList(whereObj) {
    return this._templateHeaderApi.find({
      where: whereObj,
      fields: ['Template_Name__c', 'id', 'sfdcId'],
      order: 'CreatedAt DESC'
    }).map(res => res);
  }

  onTemplateChange() {
    this.getTemplateName.emit(this.templateSelected);
  }

  clearTemplates() {
    this.templateSelected = [];
    this.getTemplateName.emit([]);
  }

}
