/* tslint:disable */

declare var Object: any;
export interface PeriodInterface {
  "sfdcId"?: string;
  "createdOnServiceo"?: boolean;
  "OwnerId"?: string;
  "CurrencyIsoCode"?: string;
  "CreatedById"?: string;
  "LastModifiedById"?: string;
  "LastModifiedDate"?: Date;
  "Cycle__c"?: string;
  "End_Date__c"?: Date;
  "IRON_ExtID__c"?: string;
  "Month__c"?: string;
  "Quarter__c"?: string;
  "Start_Date__c"?: Date;
  "Title__c"?: string;
  "Week__c"?: string;
  "Year__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class Period implements PeriodInterface {
  "sfdcId": string;
  "createdOnServiceo": boolean;
  "OwnerId": string;
  "CurrencyIsoCode": string;
  "CreatedById": string;
  "LastModifiedById": string;
  "LastModifiedDate": Date;
  "Cycle__c": string;
  "End_Date__c": Date;
  "IRON_ExtID__c": string;
  "Month__c": string;
  "Quarter__c": string;
  "Start_Date__c": Date;
  "Title__c": string;
  "Week__c": string;
  "Year__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: PeriodInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Period`.
   */
  public static getModelName() {
    return "Period";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Period for dynamic purposes.
  **/
  public static factory(data: PeriodInterface): Period{
    return new Period(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Period',
      plural: 'Periods',
      path: 'Periods',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "createdOnServiceo": {
          name: 'createdOnServiceo',
          type: 'boolean'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "Cycle__c": {
          name: 'Cycle__c',
          type: 'string'
        },
        "End_Date__c": {
          name: 'End_Date__c',
          type: 'Date'
        },
        "IRON_ExtID__c": {
          name: 'IRON_ExtID__c',
          type: 'string'
        },
        "Month__c": {
          name: 'Month__c',
          type: 'string'
        },
        "Quarter__c": {
          name: 'Quarter__c',
          type: 'string'
        },
        "Start_Date__c": {
          name: 'Start_Date__c',
          type: 'Date'
        },
        "Title__c": {
          name: 'Title__c',
          type: 'string'
        },
        "Week__c": {
          name: 'Week__c',
          type: 'string'
        },
        "Year__c": {
          name: 'Year__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
