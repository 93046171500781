/* tslint:disable */
import {
  RecordType
} from '../index';

declare var Object: any;
export interface QuizInterface {
  "sfdcId"?: string;
  "IsDeleted"?: boolean;
  "CreatedDate"?: Date;
  "CreatedById"?: string;
  "CurrencyIsoCode"?: string;
  "RecordTypeId"?: string;
  "LastModifiedDate"?: Date;
  "LastModifiedById"?: string;
  "Name"?: string;
  "LastActivityDate"?: Date;
  "LastViewedDate"?: Date;
  "LastReferencedDate"?: Date;
  "Learning__c"?: string;
  "Delete_Responses_On_Submission__c"?: boolean;
  "Description__c"?: string;
  "Max_Attempts__c"?: number;
  "Passing_Score__c"?: number;
  "Total_Questions__c"?: number;
  "TestDome_Test_Description__c"?: string;
  "TestDome_Test_Id__c"?: string;
  "Time_Allotted__c"?: number;
  "CreatedBy__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  recordType?: RecordType;
}

export class Quiz implements QuizInterface {
  "sfdcId": string;
  "IsDeleted": boolean;
  "CreatedDate": Date;
  "CreatedById": string;
  "CurrencyIsoCode": string;
  "RecordTypeId": string;
  "LastModifiedDate": Date;
  "LastModifiedById": string;
  "Name": string;
  "LastActivityDate": Date;
  "LastViewedDate": Date;
  "LastReferencedDate": Date;
  "Learning__c": string;
  "Delete_Responses_On_Submission__c": boolean;
  "Description__c": string;
  "Max_Attempts__c": number;
  "Passing_Score__c": number;
  "Total_Questions__c": number;
  "TestDome_Test_Description__c": string;
  "TestDome_Test_Id__c": string;
  "Time_Allotted__c": number;
  "CreatedBy__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  recordType: RecordType;
  constructor(data?: QuizInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Quiz`.
   */
  public static getModelName() {
    return "Quiz";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Quiz for dynamic purposes.
  **/
  public static factory(data: QuizInterface): Quiz{
    return new Quiz(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Quiz',
      plural: 'Quizzes',
      path: 'Quizzes',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "RecordTypeId": {
          name: 'RecordTypeId',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "LastActivityDate": {
          name: 'LastActivityDate',
          type: 'Date'
        },
        "LastViewedDate": {
          name: 'LastViewedDate',
          type: 'Date'
        },
        "LastReferencedDate": {
          name: 'LastReferencedDate',
          type: 'Date'
        },
        "Learning__c": {
          name: 'Learning__c',
          type: 'string'
        },
        "Delete_Responses_On_Submission__c": {
          name: 'Delete_Responses_On_Submission__c',
          type: 'boolean'
        },
        "Description__c": {
          name: 'Description__c',
          type: 'string'
        },
        "Max_Attempts__c": {
          name: 'Max_Attempts__c',
          type: 'number'
        },
        "Passing_Score__c": {
          name: 'Passing_Score__c',
          type: 'number'
        },
        "Total_Questions__c": {
          name: 'Total_Questions__c',
          type: 'number'
        },
        "TestDome_Test_Description__c": {
          name: 'TestDome_Test_Description__c',
          type: 'string'
        },
        "TestDome_Test_Id__c": {
          name: 'TestDome_Test_Id__c',
          type: 'string'
        },
        "Time_Allotted__c": {
          name: 'Time_Allotted__c',
          type: 'number'
        },
        "CreatedBy__c": {
          name: 'CreatedBy__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        recordType: {
          name: 'recordType',
          type: 'RecordType',
          model: 'RecordType',
          relationType: 'belongsTo',
                  keyFrom: 'RecordTypeId',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
