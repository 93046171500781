/* tslint:disable */

declare var Object: any;
export interface MeetingsKRInterface {
  "sfdcId"?: string;
  "CreatedById"?: string;
  "CreatedDate"?: Date;
  "CurrencyIsoCode"?: string;
  "LastModifiedById"?: string;
  "Name"?: string;
  "OwnerId"?: string;
  "IRON_ExtID__c"?: string;
  "Key_Result__c"?: string;
  "Meeting__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class MeetingsKR implements MeetingsKRInterface {
  "sfdcId": string;
  "CreatedById": string;
  "CreatedDate": Date;
  "CurrencyIsoCode": string;
  "LastModifiedById": string;
  "Name": string;
  "OwnerId": string;
  "IRON_ExtID__c": string;
  "Key_Result__c": string;
  "Meeting__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: MeetingsKRInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `MeetingsKR`.
   */
  public static getModelName() {
    return "MeetingsKR";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of MeetingsKR for dynamic purposes.
  **/
  public static factory(data: MeetingsKRInterface): MeetingsKR{
    return new MeetingsKR(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'MeetingsKR',
      plural: 'MeetingsKRs',
      path: 'MeetingsKRs',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "IRON_ExtID__c": {
          name: 'IRON_ExtID__c',
          type: 'string'
        },
        "Key_Result__c": {
          name: 'Key_Result__c',
          type: 'string'
        },
        "Meeting__c": {
          name: 'Meeting__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
