/* tslint:disable */
import {
  Contact,
  Worker,
  MyWorkLeaveRequest,
  MyWorkLeaveTypeSetting,
  MyWorkLeaveWorkerBalance
} from '../index';

declare var Object: any;
export interface MyWorkLeaveEntitlementSettingInterface {
  "sfdcId"?: string;
  "OwnerId"?: string;
  "IsDeleted"?: boolean;
  "Name"?: string;
  "CreatedById"?: string;
  "CreatedDate"?: Date;
  "CurrencyIsoCode"?: string;
  "LastModifiedDate"?: Date;
  "LastModifiedById"?: string;
  "LastActivityDate"?: Date;
  "LastViewedDate"?: Date;
  "LastReferencedDate"?: Date;
  "Created_By_Contact__c"?: string;
  "IRON_ExtID__c"?: number;
  "Leave_Type_ID__c"?: string;
  "Modified_By_Contact__c"?: string;
  "Number_of_Unit_In_Hours__c"?: number;
  "Number_of_Units_Allowed__c"?: number;
  "Number_of_Units_In_Hours__c"?: number;
  "Validity_End__c"?: Date;
  "Validity_Start__c"?: Date;
  "id"?: number;
  "Worker__c"?: string;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "Leave_Request_ID__c"?: string;
  "MyWorkLeave_Type_Setting__c"?: string;
  createdByContact?: Contact;
  modifiedByContact?: Contact;
  worker?: Worker;
  myWorkLeaveRequest?: MyWorkLeaveRequest;
  myWorkLeaveTypeSetting?: MyWorkLeaveTypeSetting;
  myWorkLeaveWorkerBalances?: MyWorkLeaveWorkerBalance[];
}

export class MyWorkLeaveEntitlementSetting implements MyWorkLeaveEntitlementSettingInterface {
  "sfdcId": string;
  "OwnerId": string;
  "IsDeleted": boolean;
  "Name": string;
  "CreatedById": string;
  "CreatedDate": Date;
  "CurrencyIsoCode": string;
  "LastModifiedDate": Date;
  "LastModifiedById": string;
  "LastActivityDate": Date;
  "LastViewedDate": Date;
  "LastReferencedDate": Date;
  "Created_By_Contact__c": string;
  "IRON_ExtID__c": number;
  "Leave_Type_ID__c": string;
  "Modified_By_Contact__c": string;
  "Number_of_Unit_In_Hours__c": number;
  "Number_of_Units_Allowed__c": number;
  "Number_of_Units_In_Hours__c": number;
  "Validity_End__c": Date;
  "Validity_Start__c": Date;
  "id": number;
  "Worker__c": string;
  "createdAt": Date;
  "updatedAt": Date;
  "Leave_Request_ID__c": string;
  "MyWorkLeave_Type_Setting__c": string;
  createdByContact: Contact;
  modifiedByContact: Contact;
  worker: Worker;
  myWorkLeaveRequest: MyWorkLeaveRequest;
  myWorkLeaveTypeSetting: MyWorkLeaveTypeSetting;
  myWorkLeaveWorkerBalances: MyWorkLeaveWorkerBalance[];
  constructor(data?: MyWorkLeaveEntitlementSettingInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `MyWorkLeaveEntitlementSetting`.
   */
  public static getModelName() {
    return "MyWorkLeaveEntitlementSetting";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of MyWorkLeaveEntitlementSetting for dynamic purposes.
  **/
  public static factory(data: MyWorkLeaveEntitlementSettingInterface): MyWorkLeaveEntitlementSetting{
    return new MyWorkLeaveEntitlementSetting(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'MyWorkLeaveEntitlementSetting',
      plural: 'MyWorkLeaveEntitlementSettings',
      path: 'MyWorkLeaveEntitlementSettings',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "LastActivityDate": {
          name: 'LastActivityDate',
          type: 'Date'
        },
        "LastViewedDate": {
          name: 'LastViewedDate',
          type: 'Date'
        },
        "LastReferencedDate": {
          name: 'LastReferencedDate',
          type: 'Date'
        },
        "Created_By_Contact__c": {
          name: 'Created_By_Contact__c',
          type: 'string'
        },
        "IRON_ExtID__c": {
          name: 'IRON_ExtID__c',
          type: 'number'
        },
        "Leave_Type_ID__c": {
          name: 'Leave_Type_ID__c',
          type: 'string'
        },
        "Modified_By_Contact__c": {
          name: 'Modified_By_Contact__c',
          type: 'string'
        },
        "Number_of_Unit_In_Hours__c": {
          name: 'Number_of_Unit_In_Hours__c',
          type: 'number'
        },
        "Number_of_Units_Allowed__c": {
          name: 'Number_of_Units_Allowed__c',
          type: 'number'
        },
        "Number_of_Units_In_Hours__c": {
          name: 'Number_of_Units_In_Hours__c',
          type: 'number'
        },
        "Validity_End__c": {
          name: 'Validity_End__c',
          type: 'Date'
        },
        "Validity_Start__c": {
          name: 'Validity_Start__c',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "Worker__c": {
          name: 'Worker__c',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "Leave_Request_ID__c": {
          name: 'Leave_Request_ID__c',
          type: 'string'
        },
        "MyWorkLeave_Type_Setting__c": {
          name: 'MyWorkLeave_Type_Setting__c',
          type: 'string'
        },
      },
      relations: {
        createdByContact: {
          name: 'createdByContact',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Created_By_Contact__c',
          keyTo: 'sfdcId'
        },
        modifiedByContact: {
          name: 'modifiedByContact',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Modified_By_Contact__c',
          keyTo: 'sfdcId'
        },
        worker: {
          name: 'worker',
          type: 'Worker',
          model: 'Worker',
          relationType: 'belongsTo',
                  keyFrom: 'Worker__c',
          keyTo: 'sfdcId'
        },
        myWorkLeaveRequest: {
          name: 'myWorkLeaveRequest',
          type: 'MyWorkLeaveRequest',
          model: 'MyWorkLeaveRequest',
          relationType: 'belongsTo',
                  keyFrom: 'Leave_Request_ID__c',
          keyTo: 'sfdcId'
        },
        myWorkLeaveTypeSetting: {
          name: 'myWorkLeaveTypeSetting',
          type: 'MyWorkLeaveTypeSetting',
          model: 'MyWorkLeaveTypeSetting',
          relationType: 'belongsTo',
                  keyFrom: 'MyWorkLeave_Type_Setting__c',
          keyTo: 'sfdcId'
        },
        myWorkLeaveWorkerBalances: {
          name: 'myWorkLeaveWorkerBalances',
          type: 'MyWorkLeaveWorkerBalance[]',
          model: 'MyWorkLeaveWorkerBalance',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'MyWorkLeave_Entitlement_Setting__c'
        },
      }
    }
  }
}
