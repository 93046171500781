/* tslint:disable */
import {
  Job,
  TimeBreak,
  Note,
  TimeChangeRequest
} from '../index';

declare var Object: any;
export interface TimeClockManagerInterface {
  "sfdcId"?: string;
  "Name"?: string;
  "CreatedById"?: string;
  "CurrencyIsoCode"?: string;
  "LastModifiedById"?: string;
  "Account_Partner__c"?: string;
  "Break_In__c"?: Date;
  "Break_Out__c"?: Date;
  "Check_In_Time_Change_Request__c"?: Date;
  "Check_In_Day_of_Week__c"?: string;
  "Check_in_Time__c"?: Date;
  "Check_In_Time_GMT__c"?: string;
  "Check_In_Time_GMT_with_Offset__c"?: string;
  "Check_In_Time_GMT_with_Offset_Contract__c"?: string;
  "Check_Out_Time_Change_Request__c"?: Date;
  "Check_Out_Time__c"?: Date;
  "Check_Out_Time_GMT__c"?: string;
  "Check_Out_Time_GMT_with_Offset_Auto15__c"?: string;
  "Check_out_Time_GMT_with_Offset_Contract__c"?: string;
  "Contract_has_Job_Schedule__c"?: boolean;
  "End_Break__c"?: Date;
  "IPAddress__c"?: string;
  "IPCity__c"?: string;
  "IPCountry__c"?: string;
  "IPRegion__c"?: string;
  "Is_Timesheet_Created__c"?: boolean;
  "Is_Time_spent_more_than_scheduled_Hours__c"?: boolean;
  "Is_Monday__c"?: boolean;
  "Job__c"?: string;
  "Job_Has_SC__c"?: boolean;
  "MSP_Attention_Required__c"?: boolean;
  "On_Time__c"?: boolean;
  "Job_has_Worker__c"?: boolean;
  "OT_Code_Required_MSP__c"?: boolean;
  "Job_Schedule__c"?: string;
  "Returned_from_an_overseas__c"?: string;
  "OT_Code_MSP__c"?: string;
  "OT_Code_Worker__c"?: string;
  "Last_Break__c"?: string;
  "MSP_Comment__c"?: string;
  "Notes_Worker__c"?: string;
  "Notes_MSP__c"?: string;
  "Late_by_Hrs__c"?: string;
  "Long_Break__c"?: string;
  "Roll_up_Total_Time_on_Break_Minutes__c"?: string;
  "Schedule_Hours__c"?: number;
  "Service_Contract__c"?: string;
  "Short_Break__c"?: string;
  "Start_Break__c"?: Date;
  "Status__c"?: string;
  "Timecard_Timesheet__c"?: string;
  "Sympt_more_than_3_days__c"?: string;
  "Time_Change_Request_Reason__c"?: string;
  "Time_In__c"?: number;
  "Time_Out__c"?: number;
  "Time_Zone__c"?: string;
  "Timezone_Auto__c"?: number;
  "Time_Zone_Value__c"?: string;
  "Tiredness__c"?: string;
  "Total_Hours_Spent__c"?: number;
  "Total_Time_on_Break_Minutes__c"?: number;
  "VFMS_WM_ID__c"?: string;
  "Whats_your_current_Health_Status__c"?: string;
  "Worker_Health_Check__c"?: string;
  "Work_Performed__c"?: string;
  "Absent_Code_MSP__c"?: string;
  "Work_Location__c"?: string;
  "Absent_Code_Required_MSP__c"?: string;
  "Any_1_Around_with_Symptoms__c"?: string;
  "Are_you_available_to_Work__c"?: string;
  "Body_ache__c"?: string;
  "Checkout_Feed_Sent__c"?: boolean;
  "Exception__c"?: boolean;
  "Clock_In_Early__c"?: string;
  "Clock_in_Late__c"?: string;
  "Clock_Out_Early__c"?: string;
  "Clock_Out_Late__c"?: string;
  "Difficulty_in_breathing__c"?: string;
  "Dry_cough__c"?: string;
  "Exception_Code__c"?: string;
  "Fever__c"?: string;
  "Getting_the_basic_necessities__c"?: string;
  "Created_Date_Date_Only__c"?: Date;
  "CreatedDate"?: Date;
  "LastModifiedDate"?: Date;
  "LastReferencedDate"?: Date;
  "LastViewedDate"?: Date;
  "IsDeleted"?: boolean;
  "ActionType"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  jobs?: Job;
  timeBreaks?: TimeBreak[];
  notes?: Note[];
  timeChangeRequests?: TimeChangeRequest[];
}

export class TimeClockManager implements TimeClockManagerInterface {
  "sfdcId": string;
  "Name": string;
  "CreatedById": string;
  "CurrencyIsoCode": string;
  "LastModifiedById": string;
  "Account_Partner__c": string;
  "Break_In__c": Date;
  "Break_Out__c": Date;
  "Check_In_Time_Change_Request__c": Date;
  "Check_In_Day_of_Week__c": string;
  "Check_in_Time__c": Date;
  "Check_In_Time_GMT__c": string;
  "Check_In_Time_GMT_with_Offset__c": string;
  "Check_In_Time_GMT_with_Offset_Contract__c": string;
  "Check_Out_Time_Change_Request__c": Date;
  "Check_Out_Time__c": Date;
  "Check_Out_Time_GMT__c": string;
  "Check_Out_Time_GMT_with_Offset_Auto15__c": string;
  "Check_out_Time_GMT_with_Offset_Contract__c": string;
  "Contract_has_Job_Schedule__c": boolean;
  "End_Break__c": Date;
  "IPAddress__c": string;
  "IPCity__c": string;
  "IPCountry__c": string;
  "IPRegion__c": string;
  "Is_Timesheet_Created__c": boolean;
  "Is_Time_spent_more_than_scheduled_Hours__c": boolean;
  "Is_Monday__c": boolean;
  "Job__c": string;
  "Job_Has_SC__c": boolean;
  "MSP_Attention_Required__c": boolean;
  "On_Time__c": boolean;
  "Job_has_Worker__c": boolean;
  "OT_Code_Required_MSP__c": boolean;
  "Job_Schedule__c": string;
  "Returned_from_an_overseas__c": string;
  "OT_Code_MSP__c": string;
  "OT_Code_Worker__c": string;
  "Last_Break__c": string;
  "MSP_Comment__c": string;
  "Notes_Worker__c": string;
  "Notes_MSP__c": string;
  "Late_by_Hrs__c": string;
  "Long_Break__c": string;
  "Roll_up_Total_Time_on_Break_Minutes__c": string;
  "Schedule_Hours__c": number;
  "Service_Contract__c": string;
  "Short_Break__c": string;
  "Start_Break__c": Date;
  "Status__c": string;
  "Timecard_Timesheet__c": string;
  "Sympt_more_than_3_days__c": string;
  "Time_Change_Request_Reason__c": string;
  "Time_In__c": number;
  "Time_Out__c": number;
  "Time_Zone__c": string;
  "Timezone_Auto__c": number;
  "Time_Zone_Value__c": string;
  "Tiredness__c": string;
  "Total_Hours_Spent__c": number;
  "Total_Time_on_Break_Minutes__c": number;
  "VFMS_WM_ID__c": string;
  "Whats_your_current_Health_Status__c": string;
  "Worker_Health_Check__c": string;
  "Work_Performed__c": string;
  "Absent_Code_MSP__c": string;
  "Work_Location__c": string;
  "Absent_Code_Required_MSP__c": string;
  "Any_1_Around_with_Symptoms__c": string;
  "Are_you_available_to_Work__c": string;
  "Body_ache__c": string;
  "Checkout_Feed_Sent__c": boolean;
  "Exception__c": boolean;
  "Clock_In_Early__c": string;
  "Clock_in_Late__c": string;
  "Clock_Out_Early__c": string;
  "Clock_Out_Late__c": string;
  "Difficulty_in_breathing__c": string;
  "Dry_cough__c": string;
  "Exception_Code__c": string;
  "Fever__c": string;
  "Getting_the_basic_necessities__c": string;
  "Created_Date_Date_Only__c": Date;
  "CreatedDate": Date;
  "LastModifiedDate": Date;
  "LastReferencedDate": Date;
  "LastViewedDate": Date;
  "IsDeleted": boolean;
  "ActionType": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  jobs: Job;
  timeBreaks: TimeBreak[];
  notes: Note[];
  timeChangeRequests: TimeChangeRequest[];
  constructor(data?: TimeClockManagerInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `TimeClockManager`.
   */
  public static getModelName() {
    return "TimeClockManager";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of TimeClockManager for dynamic purposes.
  **/
  public static factory(data: TimeClockManagerInterface): TimeClockManager{
    return new TimeClockManager(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'TimeClockManager',
      plural: 'TimeClockManagers',
      path: 'TimeClockManagers',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "Account_Partner__c": {
          name: 'Account_Partner__c',
          type: 'string'
        },
        "Break_In__c": {
          name: 'Break_In__c',
          type: 'Date'
        },
        "Break_Out__c": {
          name: 'Break_Out__c',
          type: 'Date'
        },
        "Check_In_Time_Change_Request__c": {
          name: 'Check_In_Time_Change_Request__c',
          type: 'Date'
        },
        "Check_In_Day_of_Week__c": {
          name: 'Check_In_Day_of_Week__c',
          type: 'string'
        },
        "Check_in_Time__c": {
          name: 'Check_in_Time__c',
          type: 'Date'
        },
        "Check_In_Time_GMT__c": {
          name: 'Check_In_Time_GMT__c',
          type: 'string'
        },
        "Check_In_Time_GMT_with_Offset__c": {
          name: 'Check_In_Time_GMT_with_Offset__c',
          type: 'string'
        },
        "Check_In_Time_GMT_with_Offset_Contract__c": {
          name: 'Check_In_Time_GMT_with_Offset_Contract__c',
          type: 'string'
        },
        "Check_Out_Time_Change_Request__c": {
          name: 'Check_Out_Time_Change_Request__c',
          type: 'Date'
        },
        "Check_Out_Time__c": {
          name: 'Check_Out_Time__c',
          type: 'Date'
        },
        "Check_Out_Time_GMT__c": {
          name: 'Check_Out_Time_GMT__c',
          type: 'string'
        },
        "Check_Out_Time_GMT_with_Offset_Auto15__c": {
          name: 'Check_Out_Time_GMT_with_Offset_Auto15__c',
          type: 'string'
        },
        "Check_out_Time_GMT_with_Offset_Contract__c": {
          name: 'Check_out_Time_GMT_with_Offset_Contract__c',
          type: 'string'
        },
        "Contract_has_Job_Schedule__c": {
          name: 'Contract_has_Job_Schedule__c',
          type: 'boolean'
        },
        "End_Break__c": {
          name: 'End_Break__c',
          type: 'Date'
        },
        "IPAddress__c": {
          name: 'IPAddress__c',
          type: 'string'
        },
        "IPCity__c": {
          name: 'IPCity__c',
          type: 'string'
        },
        "IPCountry__c": {
          name: 'IPCountry__c',
          type: 'string'
        },
        "IPRegion__c": {
          name: 'IPRegion__c',
          type: 'string'
        },
        "Is_Timesheet_Created__c": {
          name: 'Is_Timesheet_Created__c',
          type: 'boolean'
        },
        "Is_Time_spent_more_than_scheduled_Hours__c": {
          name: 'Is_Time_spent_more_than_scheduled_Hours__c',
          type: 'boolean'
        },
        "Is_Monday__c": {
          name: 'Is_Monday__c',
          type: 'boolean'
        },
        "Job__c": {
          name: 'Job__c',
          type: 'string'
        },
        "Job_Has_SC__c": {
          name: 'Job_Has_SC__c',
          type: 'boolean'
        },
        "MSP_Attention_Required__c": {
          name: 'MSP_Attention_Required__c',
          type: 'boolean'
        },
        "On_Time__c": {
          name: 'On_Time__c',
          type: 'boolean'
        },
        "Job_has_Worker__c": {
          name: 'Job_has_Worker__c',
          type: 'boolean'
        },
        "OT_Code_Required_MSP__c": {
          name: 'OT_Code_Required_MSP__c',
          type: 'boolean'
        },
        "Job_Schedule__c": {
          name: 'Job_Schedule__c',
          type: 'string'
        },
        "Returned_from_an_overseas__c": {
          name: 'Returned_from_an_overseas__c',
          type: 'string'
        },
        "OT_Code_MSP__c": {
          name: 'OT_Code_MSP__c',
          type: 'string'
        },
        "OT_Code_Worker__c": {
          name: 'OT_Code_Worker__c',
          type: 'string'
        },
        "Last_Break__c": {
          name: 'Last_Break__c',
          type: 'string'
        },
        "MSP_Comment__c": {
          name: 'MSP_Comment__c',
          type: 'string'
        },
        "Notes_Worker__c": {
          name: 'Notes_Worker__c',
          type: 'string'
        },
        "Notes_MSP__c": {
          name: 'Notes_MSP__c',
          type: 'string'
        },
        "Late_by_Hrs__c": {
          name: 'Late_by_Hrs__c',
          type: 'string'
        },
        "Long_Break__c": {
          name: 'Long_Break__c',
          type: 'string'
        },
        "Roll_up_Total_Time_on_Break_Minutes__c": {
          name: 'Roll_up_Total_Time_on_Break_Minutes__c',
          type: 'string'
        },
        "Schedule_Hours__c": {
          name: 'Schedule_Hours__c',
          type: 'number'
        },
        "Service_Contract__c": {
          name: 'Service_Contract__c',
          type: 'string'
        },
        "Short_Break__c": {
          name: 'Short_Break__c',
          type: 'string'
        },
        "Start_Break__c": {
          name: 'Start_Break__c',
          type: 'Date'
        },
        "Status__c": {
          name: 'Status__c',
          type: 'string'
        },
        "Timecard_Timesheet__c": {
          name: 'Timecard_Timesheet__c',
          type: 'string'
        },
        "Sympt_more_than_3_days__c": {
          name: 'Sympt_more_than_3_days__c',
          type: 'string'
        },
        "Time_Change_Request_Reason__c": {
          name: 'Time_Change_Request_Reason__c',
          type: 'string'
        },
        "Time_In__c": {
          name: 'Time_In__c',
          type: 'number'
        },
        "Time_Out__c": {
          name: 'Time_Out__c',
          type: 'number'
        },
        "Time_Zone__c": {
          name: 'Time_Zone__c',
          type: 'string'
        },
        "Timezone_Auto__c": {
          name: 'Timezone_Auto__c',
          type: 'number'
        },
        "Time_Zone_Value__c": {
          name: 'Time_Zone_Value__c',
          type: 'string'
        },
        "Tiredness__c": {
          name: 'Tiredness__c',
          type: 'string'
        },
        "Total_Hours_Spent__c": {
          name: 'Total_Hours_Spent__c',
          type: 'number'
        },
        "Total_Time_on_Break_Minutes__c": {
          name: 'Total_Time_on_Break_Minutes__c',
          type: 'number'
        },
        "VFMS_WM_ID__c": {
          name: 'VFMS_WM_ID__c',
          type: 'string'
        },
        "Whats_your_current_Health_Status__c": {
          name: 'Whats_your_current_Health_Status__c',
          type: 'string'
        },
        "Worker_Health_Check__c": {
          name: 'Worker_Health_Check__c',
          type: 'string'
        },
        "Work_Performed__c": {
          name: 'Work_Performed__c',
          type: 'string'
        },
        "Absent_Code_MSP__c": {
          name: 'Absent_Code_MSP__c',
          type: 'string'
        },
        "Work_Location__c": {
          name: 'Work_Location__c',
          type: 'string'
        },
        "Absent_Code_Required_MSP__c": {
          name: 'Absent_Code_Required_MSP__c',
          type: 'string'
        },
        "Any_1_Around_with_Symptoms__c": {
          name: 'Any_1_Around_with_Symptoms__c',
          type: 'string'
        },
        "Are_you_available_to_Work__c": {
          name: 'Are_you_available_to_Work__c',
          type: 'string'
        },
        "Body_ache__c": {
          name: 'Body_ache__c',
          type: 'string'
        },
        "Checkout_Feed_Sent__c": {
          name: 'Checkout_Feed_Sent__c',
          type: 'boolean'
        },
        "Exception__c": {
          name: 'Exception__c',
          type: 'boolean'
        },
        "Clock_In_Early__c": {
          name: 'Clock_In_Early__c',
          type: 'string'
        },
        "Clock_in_Late__c": {
          name: 'Clock_in_Late__c',
          type: 'string'
        },
        "Clock_Out_Early__c": {
          name: 'Clock_Out_Early__c',
          type: 'string'
        },
        "Clock_Out_Late__c": {
          name: 'Clock_Out_Late__c',
          type: 'string'
        },
        "Difficulty_in_breathing__c": {
          name: 'Difficulty_in_breathing__c',
          type: 'string'
        },
        "Dry_cough__c": {
          name: 'Dry_cough__c',
          type: 'string'
        },
        "Exception_Code__c": {
          name: 'Exception_Code__c',
          type: 'string'
        },
        "Fever__c": {
          name: 'Fever__c',
          type: 'string'
        },
        "Getting_the_basic_necessities__c": {
          name: 'Getting_the_basic_necessities__c',
          type: 'string'
        },
        "Created_Date_Date_Only__c": {
          name: 'Created_Date_Date_Only__c',
          type: 'Date'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "LastReferencedDate": {
          name: 'LastReferencedDate',
          type: 'Date'
        },
        "LastViewedDate": {
          name: 'LastViewedDate',
          type: 'Date'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "ActionType": {
          name: 'ActionType',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        jobs: {
          name: 'jobs',
          type: 'Job',
          model: 'Job',
          relationType: 'belongsTo',
                  keyFrom: 'Job__c',
          keyTo: 'sfdcId'
        },
        timeBreaks: {
          name: 'timeBreaks',
          type: 'TimeBreak[]',
          model: 'TimeBreak',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'Timeclock_Entry__c'
        },
        notes: {
          name: 'notes',
          type: 'Note[]',
          model: 'Note',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'Timeclock_Manager__c'
        },
        timeChangeRequests: {
          name: 'timeChangeRequests',
          type: 'TimeChangeRequest[]',
          model: 'TimeChangeRequest',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'Timeclock_Manager__c'
        },
      }
    }
  }
}
