import { Component, OnInit, EventEmitter, Input, Output, ViewChild, OnChanges} from "@angular/core";
import { humanizeBytes, UploadInput, UploadFile, UploaderOptions, UploadOutput } from "ngx-uploader";
import { environment } from "environments/environment";
import { AlertService } from "shared/services/alert.service";
import { AppStateService } from 'shared/services/app-state.service';

interface FormData {
  concurrency: number;
  autoUpload: boolean;
  verbose: boolean;
}

@Component({
  selector: "app-inline-document-upload",
  templateUrl: "./inline-document-upload.component.html",
  styleUrls: ["./inline-document-upload.component.css"],
})
export class InlineDocumentUploadComponent implements OnInit, OnChanges {
  @Input() sowsfdcId ='';
  @Input() index =0;
  @Input() fromPage: any;
  @Input() resetFiles: boolean;
  @Input() modelId;
  @Input() modelName;
  @Input() source = '';
  @Input() disableButton3;
  @Input() disableButton4;
  @Input() disableButton5;
  @Input() disableButton6;
  @Input() disableButton7;
  @Input() disableButton8;
  @Output() fileAdded = new EventEmitter<any>();
  @Output() uploadDone = new EventEmitter<any>();
  @ViewChild("myInputFile") myInputFile: any;
  @Input() set uploadStarts(e) {
    if (e === true) {
      this.startUpload();
    }
  }
  @Input() set clearFileName(e) {
    if (e === true) {
      this.fromProfileValidate = e;
      this.myInputFile.nativeElement.value = '';
    }
  }
  @Input() autoUpload: boolean;
  @Input() catId;
  @Input() set subCatId(e){
    this.error = '';
    if(e){
      this.subCategoryId = e;
      const filePermissions = this._appState.getFileManagerPermissions();
      const allowedCategoryList = (filePermissions['uploads'] && filePermissions['uploads']['allowedCategoryList']) ? filePermissions['uploads']['allowedCategoryList'] : [];
      const allowedSubCategoryList = (filePermissions['uploads'] && filePermissions['uploads']['allowedSubCategoryList']) ? filePermissions['uploads']['allowedSubCategoryList'] : [];
      let matchCat = allowedCategoryList.find( cat  => cat == this.catId);
      let matchSCat= allowedSubCategoryList.find(sCat  => sCat == e);
      if(e && matchCat && matchSCat){
        this.isButtonEnabled = true;
      }else{
        this.isButtonEnabled = false;
        this.error = "You are not allow to upload file(s)."
      }
    }
    
  };  
  @Input() set isUploadButtonEnabled(e) {
    if(this.fromPage=='admin-release-notes'){
      this.isButtonEnabled = (e) ? e : false;
    }else{
      this.isButtonEnabled = (e && this.isButtonEnabled) ? e : false;
    } 
  };
  @Input() isButtonEnabled = true;
  @Input() uploadFileAllowed;
  @Input() isMultipleFileAllowed = true;
  @Input() fromProfile;
  @Input() hiddenVisibility: boolean;
  @Output() resetFile = new EventEmitter<any>();
  formData: FormData;
  files: UploadFile[];
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  options: UploaderOptions;
  imgpath: string;
  token: any;
  path: string;
  error: string;
  fileName: string;

  fileOperation = "";
  /// end of file setting
  /// for validation bindings
  document = {
    description: "",
    categoryId: "",
    files: "",
    parentId: "",
    department: "",
  };
  isValid = false;
  query = {
    total: 0,
    processed: 0,
    success: 0,
    error: 0,
  };
  MAX_FILE_SIZE = environment.fileUpload.maxFileSize || 20971520; // maximum file size allowed for upload (20 MB)
  MAX_FILE_UPLOAD = environment.fileUpload.maxFileLimit || 5; // no. of files can be uploaded at a time
  isUploadAllowed = false;
  subCategoryId:string;
  fromProfileValidate =false;

   constructor(
    private alertService: AlertService,
    private _appState: AppStateService) {
    // file setting
    this.formData = {
      concurrency: 0,
      autoUpload: false,
      verbose: true,
    };

    this.files = []; // local uploading files array
    this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
    this.humanizeBytes = humanizeBytes;
    //this.returnObj = [];
    this.options = {
      concurrency: 1,
      // maxUploads: this.MAX_FILE_UPLOAD, //Commnented to restrict the file length having max value than MAX_FILE_UPLOAD
      allowedContentTypes: ["*"],
      // maxFileSize: this.MAX_FILE_SIZE,
    };
    this.imgpath = environment.baseUrl + "/" + environment.apiVersion;
    this.token = localStorage.getItem("accessToken");
    this.path =
      environment.baseUrl +
      "/" +
      environment.apiVersion +
      "/Documents/uploadDocument?access_token=" +
      this.token;
  }

  ngOnInit() {
    if (this.fromProfile==='vaccination') {
      this.MAX_FILE_SIZE = 5242880;
    }
    if (this.fromProfile==='admin-release-notes') {
      this.MAX_FILE_SIZE = 104857600;
    }

    this.options = {
      concurrency: 1,
      // maxUploads: this.MAX_FILE_UPLOAD, //Commnented to restrict the file length having max value than MAX_FILE_UPLOAD
      allowedContentTypes: ["*"],
      // maxFileSize: this.MAX_FILE_SIZE,
    };
    this.imgpath = environment.baseUrl + "/" + environment.apiVersion;
    this.token = localStorage.getItem("accessToken");
    this.path =
      environment.baseUrl +
      "/" +
      environment.apiVersion +
      "/Documents/uploadDocument?access_token=" +
      this.token;
  }

  onUploadOutput(output: UploadOutput): void {
    switch (output.type) {
      case "allAddedToQueue":
        if (this.autoUpload === true) {
          this.startUpload();
        }
        this.validateFileSize('', true);
        break;
      case "addedToQueue":
        this.error = '';
        if (typeof output.file !== "undefined") {
          output.file["sizeMB"] = this.humanizeBytes(output.file.size);
          this.fileName = output.file.name;
          if (!this.isMultipleFileAllowed && this.files.length > 0) {
              this.uploadInput.emit({ type: 'remove', id: this.files[0]['id'] });
            this.files = [];
          }
          // Validate File For Size before adding
          this.validateFileSize(output.file);
        }
        break;
      case "uploading":
        if (typeof output.file !== "undefined") {
          // update current data in files array for uploading file
          let index = this.files.findIndex(
            (file) =>
              typeof output.file !== "undefined" && file.id === output.file.id
          );
          index = (index>=0)?index:0
          this.files[index] = output.file;
        }
        break;
      case "rejected":
        this.validateFileSize(output.file);
        break;
      //Commnented to restrict the file length having max value than MAX_FILE_UPLOAD
      // case "rejected":
      //   console.log(
      //     output.file.name +
      //     "(" +
      //     this.humanizeBytes(output.file.size) +
      //     ")" +
      //     " rejected"
      //   );
      //   this.error =
      //     "Error! Maximum size allowed for a file is " +
      //     this.humanizeBytes(this.MAX_FILE_SIZE);
      //   this.alertService.error(this.error);
      //   this.fileAdded.emit('');
      //   break;
      case "done":
        const msg = output.file.response;
        const progress = this.files[0]
          ? this.files[0].progress.data.percentage
          : "";
          console.log('this.files',this.files);
        if (msg !== "" && !msg.error && msg.status.data.success && progress === 100
        ) {
          this.alertService.success(msg.status.data.success.message);
          if(this.fromPage=='admin-release-notes' || this.fromProfile==='admin-release-notes'){
            this.uploadDone.emit({isUploaded:true,message: msg.status.data.success.message, index:this.index});
          }else{
            this.uploadDone.emit(true);
          }
        } else {
          this.error +=
            (msg.status && msg.status.data.error) ?
              msg.status.data.error.message :
              ' Error while uploading ' + output.file.name;
          this.alertService.error(this.error);
          if(this.fromPage=='admin-release-notes' || this.fromProfile==='admin-release-notes'){
            this.uploadDone.emit({isUploaded:false,message: this.error,index:this.index});
            
          }else{
            this.uploadDone.emit(false);
          }
        }
        break;
      default:
        this.fileOperation = output && output.type ? output.type : "undefined";
    }
  }
  ngAfterViewInit() {
    if (this.fromProfile) {
      this.myInputFile.nativeElement.accept = [".jpeg", ".pdf", ".jpg", ".png"];
    }
    if (this.fromProfile && this.fromProfileValidate ) {
      this.myInputFile.nativeElement.value = '';
    }
  }

  validateFileSize(file?, allAddedToQueue?) {
    if (file) {
      this.files.push(file);
      //this.fileAdded.emit(file);
      if(this.fromPage=='admin-release-notes' || this.fromProfile==='admin-release-notes'){       
        this.fileAdded.emit({file:file,index:this.index} );
      }else{
        this.fileAdded.emit(file);
      }
    }

    //this.isValid = this.files.length > this.MAX_FILE_UPLOAD ? false : true;
    const maxFile = this.files.find(file => file.nativeFile.size > this.MAX_FILE_SIZE);

    if ((this.files.length > this.MAX_FILE_UPLOAD || maxFile) && allAddedToQueue) {
      if(maxFile)
      this.error = `${maxFile.name} ! Maximum size allowed for a file is ${this.humanizeBytes(this.MAX_FILE_SIZE)}`;
      else
        this.error = "Only " + this.MAX_FILE_UPLOAD + " Files are allowed to upload at a time.";
      this.alertService.error(this.error);
      // for(const file of this.files)
      //   this.uploadInput.emit({ type: 'remove', id: file['id'] });
      this.uploadInput.emit({ type: 'removeAll' });
      this.files = [];
      if(this.fromPage=='admin-release-notes' || this.fromProfile==='admin-release-notes'){       
        this.fileAdded.emit({file:false,index:this.index} );
      }else{
        this.fileAdded.emit(false);
      }
      this.myInputFile.nativeElement.value = '';    
    }
    //this.query.total = this.files.length;
  }

  resetFileInput() {
    //this.isClose = true;
    this.isValid = false;
    this.error = "";
    if (this.myInputFile && this.myInputFile.nativeElement) {
      this.myInputFile.nativeElement.value = "";
    }
  }

  startUpload(): void {
    // manually start uploading concurrency: 1 // set sequential uploading of files with concurrency 1
    //this.isClose = false;
    let dataObj = {
      modelName: this.modelName,
      modelId: this.modelId,
      //description: model.description,
      categoryId: this.catId,
      subCategoryId: this.subCategoryId,
    }
    if(this.sowsfdcId){
      dataObj['sowsfdcId'] = this.sowsfdcId;
    }
    
    this.error = '';
    const event: UploadInput = {
      type: 'uploadAll',
      url: environment.baseUrl +
        '/' +
        environment.apiVersion +
        '/Documents/uploadDocument?access_token=' + this.token + '&modelName=' + this.modelName + '&cId=' + this.catId,
      method: 'POST',
      data:dataObj
    };
    this.uploadInput.emit(event);
  }

  //On CHANGING the dropdown selection from parent component file data needs to reset
  ngOnChanges(val) {
    if (this.resetFiles) {
      this.myInputFile.nativeElement.value = '';
      this.files = [];
      if (this.fromProfile) {
        this.error = '';
      }
    }
    if ((val.subCatId && val.subCatId.currentValue) !== (val.subCatId && val.subCatId.previousValue)) {
     if (this.myInputFile && this.myInputFile.nativeElement.value !== '') {
        this.myInputFile.nativeElement.value = '';
        this.resetFile.emit(true);
      }
    }
}

onBrowseCancel(){
  if(this.myInputFile.nativeElement.value ===''){
    this.resetFile.emit(true);
  }  
}
checkErrorMsg(){
  if((this.error).includes('contentType')){
    return true
  }
  return false
}
}
