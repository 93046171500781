import { OnInit, Component, Input, EventEmitter, Output } from '@angular/core';
import { CommonService } from 'shared/services/common.service';

@Component({
  selector: 'app-customer-request-range-lookup',
  templateUrl: './customer-request-range-lookup.component.html',
  styleUrls: ['./customer-request-range-lookup.component.css']
})
export class CustomerRequestRangeLookupComponent implements OnInit {

  @Input() placeholder = 'Customer Requested Date';
  @Input() set resetData(isClear) {
    this.selectedDate = [];
  }
  @Output() selectedValue: EventEmitter<any> = new EventEmitter;
  selectedDate: any = [];
  max = new Date();

  constructor(private _commonService: CommonService) { }
  ngOnInit() {
    // to show selected date range
    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
    if (preselected && preselected['custReqDate'] && preselected['custReqDate'][0]) {
      this.selectedDate = [this._commonService.applyNewTimeZone(preselected['custReqDate'][0][0], 'YYYY-MM-DDTHH:mm:ss'), this._commonService.applyNewTimeZone(preselected['custReqDate'][0][1], 'YYYY-MM-DDTHH:mm:ss')];
    }
    this.max = new Date(3000, 3, 21, 20, 30);
  
  }
 
  onChange() {
    this.selectedValue.emit(this.selectedDate);
  }

  onClearDate() {
    this.selectedDate = [];
    this.selectedValue.emit([]);
  }
}