import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-jobsite-contact',
  templateUrl: './jobsite-contact.component.html',
  styleUrls: ['./jobsite-contact.component.css']
})
export class JobsiteContactComponent implements OnInit {

  jobsiteContactData: any = {};
  @Input()
  set data(e) {
    if (e && Object.keys(e).length) {
      this.jobsiteContactData = e;
    }
  }

  @Input() receiptTabFields = false;

  constructor() { }

  ngOnInit() {
  }

}
