import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { FormNameLookupComponent } from './form-name-lookup.component';

@NgModule({
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule
  ],
  declarations: [FormNameLookupComponent],
  exports: [FormNameLookupComponent]
})
export class FormNameLookupModule { }
