export const access = {
    'msp': {
        'summary': {
            'job': {
                'Dispatch_Service_Resolution_Status__c': {
                    'read': true,
                    'write': true
                },
                'Customer_Service_Type_From_Program__c': {
                    'read': true,
                    'write': true
                },
                'Technical_Level__c': {
                    'read': true,
                    'write': true
                },
                'Service_Dispatch_SLA_Priority_FrmProgram__c': {
                    'read': true,
                    'write': true
                },
                'csum__c': {
                    'read': true,
                    'write': true
                },
                'CKSW_BASE__Description__c': {
                    'read': true,
                    'write': true
                },
                'Special_Instruction_from_PMS_Case_Auto__c': {
                    'read': true,
                    'write': true
                },
                'Asset_Model__c': {
                    'read': true,
                    'write': true
                },
                'Custom_work_order_instructions__c': {
                    'read': true,
                    'write': true
                },
                'Jobsite_Contact_SDesk_Name_phone_email__c': {
                    'read': true,
                    'write': true
                },
                'Jobsite_Name__c': {
                    'read': true,
                    'write': true
                },
                'Partner_Case_Number__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Arrival_DateTime_Cust_Requested__c': {
                    'read': true,
                    'write': true
                },
                'Does_this_Job_require_Service_Parts__c': {
                    'read': true,
                    'write': true
                },
                'Service_Parts_Local_Pickup_Required__c': {
                    'read': true,
                    'write': true
                },
                'Does_this_Job_require_New_Equip_Hardware__c': {
                    'read': true,
                    'write': true
                },
                'Equipment_Local_Pickup_Required__c': {
                    'read': true,
                    'write': true
                },
                'Equipment_Delivery_Date_ETA__c': {
                    'read': true,
                    'write': true
                },
                'Equipment_Delivery_Time_ETA_Hours__c': {
                    'read': true,
                    'write': true
                },
                'Equipment_Tracking__c': {
                    'read': true,
                    'write': true
                },
                'Iron_Case__c': {
                    'read': true,
                    'write': true
                },
                'Jobsite_Contact_Technical_Escalation__c': {
                    'read': true,
                    'write': true
                },
                'SOW_Work_Order__c': {
                    'read': true,
                    'write': true
                },
                'Job_Instruction_Details_Long__c': {
                    'read': true,
                    'write': true
                }
            },
            'project': {
                'Project__c': {
                    'read': true,
                    'write': true
                },
            },
            'appointment': {
                'Worker_Arrival_DateTime_Cust_Requested__c': {
                    'read': true,
                    'write': true
                },
                'Customer_Appointment_DateTime_Scheduled__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Arrival_DateTime_Scheduled__c': {
                    'read': true,
                    'write': true
                },
                'Max_Hours_Units__c': {
                    'read': true,
                    'write': true
                }
            },
            'work-order': {
                'Name': {
                    'read': true,
                    'write': true
                }
            },
            'worker': {
                'Name': {
                    'read': true,
                    'write': true
                }
            }
        },

        'manager': {
            'job': {
                'Job_Status_Internal__c': {
                    'read': true,
                    'write': true
                },
                'Dispatch_Service_Resolution_Status__c': {
                    'read': true,
                    'write': true
                },
                'Service_Parts_Local_Pickup_Required__c': {
                    'read': true,
                    'write': true
                },
                'Appointment_Schedule_Status_Customer__c': {
                    'read': true,
                    'write': true
                },
                'Appointment_Schedule_Status_Customer_vms__c': {
                    'read': true,
                    'write': true
                },
                'Phone_Scheduling_1st_Attempt_Unreachable__c': {
                    'read': true,
                    'write': true
                },
                'Phone_Scheduling_2nd_Attempt_Unreachable__c': {
                    'read': true,
                    'write': true
                },
                'Phone_Scheduling_3rd_Attempt_Unreachable__c': {
                    'read': true,
                    'write': true
                },
                'Appointment_Call_Notes__c': {
                    'read': true,
                    'write': true
                },
                'Dispatch_Worker_Name__c': {
                    'read': true,
                    'write': true
                },
                'Dispatch_Worker_Phone': {
                    'read': true,
                    'write': true
                },
                'CKSW_BASE__Incomplete_reason__c': {
                    'read': true,
                    'write': true
                },
                'CKSW_BASE__Other_Incomplete_Reason__c': {
                    'read': true,
                    'write': true
                }
            },
            'appointment': {
                'Customer_Appointment_Setup_Required__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Arrival_DateTime_Cust_Requested__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Arrival_Date_Customer_Req_End__c': {
                    'read': true,
                    'write': true
                },
                'Customer_Appointment_DateTime_Scheduled__c': {
                    'read': true,
                    'write': true
                },
                'Customer_Appointment_Start_Scheduled__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Arrival_DateTime_Scheduled__c': {
                    'read': true,
                    'write': true
                },
                'Worker_End_DateTime_Scheduled__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Departure_Date_Time_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Arrival_Date_Time_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Worker_InProgress_Start_DateTime_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Finish_Date_Time_Actual__c': {
                    'read': true,
                    'write': true
                }
            },
            'message': {
                'Is_Public__c': {
                    'read': true,
                    'write': true
                },
                'Is_Public_Job__c': {
                    'read': true,
                    'write': true
                },
                'contentText': {
                    'read': true,
                    'write': true
                }
            }
        },

        'worker-status': {
            'job': {
                'Service_Parts_Local_Pickup_Required__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Departure_Date_Time_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Arrival_Date_Time_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Worker_InProgress_Start_DateTime_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Finish_Date_Time_Actual__c': {
                    'read': true,
                    'write': true
                },
            },
            'deliverable': {
                'Name': {
                    'read': true,
                    'write': true
                },
                'Deliverable_Type__c': {
                    'read': true,
                    'write': true
                },
                'File_upload_required__c': {
                    'read': true,
                    'write': true
                },
                'Description__c': {
                    'read': true,
                    'write': true
                },
                'Instructions__c': {
                    'read': true,
                    'write': true
                },
                'Deliverable_Status__c': {
                    'read': true,
                    'write': true
                },
            },
            'timecard': {
                'vendorName': {
                    'read': true,
                    'write': true
                },
                'Name': {
                    'read': true,
                    'write': true
                },
                'Work_Order_num__c': {
                    'read': true,
                    'write': true
                },
                'Iron_Job_num__c': {
                    'read': true,
                    'write': true
                },
                'Final_Timecard': {
                    'read': true,
                    'write': true
                },
                'Customer_Approval_Status__c': {
                    'read': true,
                    'write': true
                },
                'Visit_Number_Calc__c': {
                    'read': true,
                    'write': true
                },
                'Vendor_Time_Card_Time_in_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Vendor_Time_Card_Time_Out_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Total_Worked_Hours_Without_Offset__c': {
                    'read': true,
                    'write': true
                },
                'workerName': {
                    'read': true,
                    'write': true
                },
                'Customer_Site_Signoff_Name__c': {
                    'read': true,
                    'write': true
                },
                'Equipment_Serial_Performed_on__c': {
                    'read': true,
                    'write': true
                },
                'Equipment_Make_Model_Performed_on__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Mobile_OS_Android_iOS__c': {
                    'read': true,
                    'write': true
                },
                'CreatedDate': {
                    'read': true,
                    'write': true
                }
            },
            'comment': {
                'Is_Public__c': {
                    'read': true,
                    'write': true
                },
                'Is_Public_Job__c': {
                    'read': true,
                    'write': true
                },
                'contentText': {
                    'read': true,
                    'write': true
                }
            }
        },
        'message': {
            'comment': {
                'postByName': {
                    'read': true,
                    'write': true
                },
                'messageType': {
                    'read': true,
                    'write': true
                },
                'createdAt': {
                    'read': true,
                    'write': true
                },
                'Case_Comment_Body__c': {
                    'read': true,
                    'write': true
                },
                'Is_Public_Job__c': {
                    'read': true,
                    'write': true
                },
                'Is_Public__c': {
                    'read': true,
                    'write': true
                }
            }
        },
        'timecard': {
            'account': {
                'Name': {
                    'read': true,
                    'write': true
                }
            },
            'timecard': {
                'Name': {
                    'read': true,
                    'write': true
                },
                'Final_Timecard': {
                    'read': true,
                    'write': true
                },
                'Customer_Approval_Status__c': {
                    'read': true,
                    'write': true
                },
                'Visit_Number_Calc__c': {
                    'read': true,
                    'write': true
                },
                'Vendor_Time_Card_Time_in_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Vendor_Time_Card_Time_Out_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Total_Worked_Hours_Without_Offset__c': {
                    'read': true,
                    'write': true
                },
                'Customer_Site_Signoff_Name__c': {
                    'read': true,
                    'write': true
                },
                'Equipment_Serial_Performed_on__c': {
                    'read': true,
                    'write': true
                },
                'Equipment_Make_Model_Performed_on__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Mobile_OS_Android_iOS__c': {
                    'read': true,
                    'write': true
                },
                'CreatedDate': {
                    'read': true,
                    'write': true
                }
            },
            'purchase-order': {
                'Work_Order_num__c': {
                    'read': true,
                    'write': true
                }
            },
            'job': {
                'Iron_Job_num__c': {
                    'read': true,
                    'write': true
                }
            },
            'worker': {
                'Name': {
                    'read': true,
                    'write': true
                }
            }
        },
        'billing': {
            'comment': {
                'messageType': {
                    'read': true,
                    'write': true
                },
                'Case_Comment_Body__c': {
                    'read': true,
                    'write': true
                },
                'Is_Public_Job_c': {
                    'read': true,
                    'write': true
                },
                'Is_Public__c': {
                    'read': true,
                    'write': true
                }
            }
        }
    },
    'vms_agency': {
        'summary': {
            'job': {
                'Dispatch_Service_Resolution_Status__c': {
                    'read': true,
                    'write': false
                },
                'Customer_Service_Type_From_Program__c': {
                    'read': true,
                    'write': false
                },
                'Technical_Level__c': {
                    'read': true,
                    'write': false
                },
                'Service_Dispatch_SLA_Priority_FrmProgram__c': {
                    'read': true,
                    'write': false
                },
                'csum__c': {
                    'read': true,
                    'write': false
                },
                'CKSW_BASE__Description__c': {
                    'read': true,
                    'write': false
                },
                'Special_Instruction_from_PMS_Case_Auto__c': {
                    'read': true,
                    'write': false
                },
                'Asset_Model__c': {
                    'read': true,
                    'write': false
                },
                'Custom_work_order_instructions__c': {
                    'read': true,
                    'write': false
                },
                'Jobsite_Contact_SDesk_Name_phone_email__c': {
                    'read': true,
                    'write': false
                },
                'Jobsite_Name__c': {
                    'read': true,
                    'write': false
                },
                'Partner_Case_Number__c': {
                    'read': true,
                    'write': false
                },
                'Worker_Arrival_DateTime_Cust_Requested__c': {
                    'read': true,
                    'write': false
                },
                'Does_this_Job_require_Service_Parts__c': {
                    'read': true,
                    'write': false
                },
                'Service_Parts_Local_Pickup_Required__c': {
                    'read': true,
                    'write': false
                },
                'Does_this_Job_require_New_Equip_Hardware__c': {
                    'read': true,
                    'write': false
                },
                'Equipment_Local_Pickup_Required__c': {
                    'read': true,
                    'write': false
                },
                'Equipment_Delivery_Date_ETA__c': {
                    'read': true,
                    'write': false
                },
                'Equipment_Delivery_Time_ETA_Hours__c': {
                    'read': true,
                    'write': false
                },
                'Equipment_Tracking__c': {
                    'read': true,
                    'write': false
                },
                'Iron_Case__c': {
                    'read': false,
                    'write': false
                },
                'Jobsite_Contact_Technical_Escalation__c': {
                    'read': true,
                    'write': false
                },
                'SOW_Work_Order__c': {
                    'read': true,
                    'write': false
                },
                'Job_Instruction_Details_Long__c': {
                    'read': true,
                    'write': false
                }
            },
            'project': {
                'Project__c': {
                    'read': false,
                    'write': false
                },
            },
            'appointment': {
                'Worker_Arrival_DateTime_Cust_Requested__c': {
                    'read': true,
                    'write': false
                },
                'Customer_Appointment_DateTime_Scheduled__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Arrival_DateTime_Scheduled__c': {
                    'read': true,
                    'write': true
                },
                'Max_Hours_Units__c': {
                    'read': true,
                    'write': false
                }
            },
            'work-order': {
                'Name': {
                    'read': true,
                    'write': false
                }
            },
            'worker': {
                'Name': {
                    'read': true,
                    'write': true
                }
            }
        },

        'manager': {
            'job': {
                'Job_Status_Internal__c': {
                    'read': true,
                    'write': false
                },
                'Dispatch_Service_Resolution_Status__c': {
                    'read': true,
                    'write': false
                },
                'Service_Parts_Local_Pickup_Required__c': {
                    'read': true,
                    'write': false
                },
                'Appointment_Schedule_Status_Customer__c': {
                    'read': true,
                    'write': false
                },
                'Appointment_Schedule_Status_Customer_vms__c': {
                    'read': true,
                    'write': false
                },
                'Phone_Scheduling_1st_Attempt_Unreachable__c': {
                    'read': true,
                    'write': false
                },
                'Phone_Scheduling_2nd_Attempt_Unreachable__c': {
                    'read': true,
                    'write': false
                },
                'Phone_Scheduling_3rd_Attempt_Unreachable__c': {
                    'read': true,
                    'write': false
                },
                'Appointment_Call_Notes__c': {
                    'read': true,
                    'write': false
                },
                'Dispatch_Worker_Name__c': {
                    'read': true,
                    'write': true
                },
                'Dispatch_Worker_Phone': {
                    'read': true,
                    'write': true
                },
                'CKSW_BASE__Incomplete_reason__c': {
                    'read': true,
                    'write': false
                },
                'CKSW_BASE__Other_Incomplete_Reason__c': {
                    'read': true,
                    'write': false
                }
            },
            'appointment': {
                'Customer_Appointment_Setup_Required__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Arrival_DateTime_Cust_Requested__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Arrival_Date_Customer_Req_End__c': {
                    'read': true,
                    'write': true
                },
                'Customer_Appointment_DateTime_Scheduled__c': {
                    'read': true,
                    'write': true
                },
                'Customer_Appointment_Start_Scheduled__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Arrival_DateTime_Scheduled__c': {
                    'read': true,
                    'write': true
                },
                'Worker_End_DateTime_Scheduled__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Departure_Date_Time_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Arrival_Date_Time_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Worker_InProgress_Start_DateTime_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Finish_Date_Time_Actual__c': {
                    'read': true,
                    'write': true
                }
            },
            'message': {
                'Is_Public__c': {
                    'read': true,
                    'write': true
                },
                'Is_Public_Job__c': {
                    'read': true,
                    'write': true
                },
                'contentText': {
                    'read': true,
                    'write': true
                }
            }
        },

        'worker-status': {
            'job': {
                'Service_Parts_Local_Pickup_Required__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Departure_Date_Time_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Arrival_Date_Time_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Worker_InProgress_Start_DateTime_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Finish_Date_Time_Actual__c': {
                    'read': true,
                    'write': true
                },
            },
            'deliverable': {
                'Name': {
                    'read': true,
                    'write': true
                },
                'Deliverable_Type__c': {
                    'read': true,
                    'write': true
                },
                'File_upload_required__c': {
                    'read': true,
                    'write': true
                },
                'Description__c': {
                    'read': true,
                    'write': true
                },
                'Instructions__c': {
                    'read': true,
                    'write': true
                },
                'Deliverable_Status__c': {
                    'read': true,
                    'write': true
                },
            },
            'timecard': {
                'vendorName': {
                    'read': true,
                    'write': true
                },
                'Name': {
                    'read': true,
                    'write': true
                },
                'Work_Order_num__c': {
                    'read': true,
                    'write': true
                },
                'Iron_Job_num__c': {
                    'read': true,
                    'write': true
                },
                'Final_Timecard': {
                    'read': true,
                    'write': true
                },
                'Customer_Approval_Status__c': {
                    'read': true,
                    'write': true
                },
                'Visit_Number_Calc__c': {
                    'read': true,
                    'write': true
                },
                'Vendor_Time_Card_Time_in_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Vendor_Time_Card_Time_Out_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Total_Worked_Hours_Without_Offset__c': {
                    'read': true,
                    'write': true
                },
                'workerName': {
                    'read': true,
                    'write': true
                },
                'Customer_Site_Signoff_Name__c': {
                    'read': true,
                    'write': true
                },
                'Equipment_Serial_Performed_on__c': {
                    'read': true,
                    'write': true
                },
                'Equipment_Make_Model_Performed_on__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Mobile_OS_Android_iOS__c': {
                    'read': true,
                    'write': true
                },
                'CreatedDate': {
                    'read': true,
                    'write': true
                }
            },
            'comment': {
                'Is_Public__c': {
                    'read': true,
                    'write': true
                },
                'Is_Public_Job__c': {
                    'read': true,
                    'write': true
                },
                'contentText': {
                    'read': true,
                    'write': true
                }
            }
        },

        'message': {
            'user': {
                'profileImage': {
                    'read': true,
                    'write': true
                }
            },
            'comment': {
                'postByName': {
                    'read': true,
                    'write': true
                },
                'messageType': {
                    'read': true,
                    'write': true
                },
                'createdAt': {
                    'read': true,
                    'write': true
                },
                'Case_Comment_Body__c': {
                    'read': true,
                    'write': true
                },
                'Is_Public_Job_c': {
                    'read': true,
                    'write': true
                },
                'Is_Public_c': {
                    'read': true,
                    'write': true
                }
            }
        },

        'timecard': {
            'account': {
                'Name': {
                    'read': true,
                    'write': true
                }
            },
            'timecard': {
                'Name': {
                    'read': true,
                    'write': true
                },
                'Final_Timecard': {
                    'read': true,
                    'write': true
                },
                'Customer_Approval_Status__c': {
                    'read': true,
                    'write': true
                },
                'Visit_Number_Calc__c': {
                    'read': true,
                    'write': true
                },
                'Vendor_Time_Card_Time_in_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Vendor_Time_Card_Time_Out_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Total_Worked_Hours_Without_Offset__c': {
                    'read': true,
                    'write': true
                },
                'Customer_Site_Signoff_Name__c': {
                    'read': true,
                    'write': true
                },
                'Equipment_Serial_Performed_on__c': {
                    'read': true,
                    'write': true
                },
                'Equipment_Make_Model_Performed_on__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Mobile_OS_Android_iOS__c': {
                    'read': true,
                    'write': true
                },
                'CreatedDate': {
                    'read': true,
                    'write': true
                }
            },
            'purchase-order': {
                'Work_Order_num__c': {
                    'read': true,
                    'write': true
                }
            },
            'job': {
                'Iron_Job_num__c': {
                    'read': true,
                    'write': true
                }
            },
            'worker': {
                'Name': {
                    'read': true,
                    'write': true
                }
            }
        },

        'billing': {
            'comment': {
                'messageType': {
                    'read': true,
                    'write': true
                },
                'Case_Comment_Body__c': {
                    'read': true,
                    'write': true
                },
                'Is_Public_Job_c': {
                    'read': true,
                    'write': true
                },
                'Is_Public__c': {
                    'read': true,
                    'write': true
                }
            }
        }
    },
    'vms_fse_assigned': {
        'summary': {
            'job': {
                'Dispatch_Service_Resolution_Status__c': {
                    'read': true,
                    'write': false
                },
                'Customer_Service_Type_From_Program__c': {
                    'read': true,
                    'write': false
                },
                'Technical_Level__c': {
                    'read': true,
                    'write': false
                },
                'Service_Dispatch_SLA_Priority_FrmProgram__c': {
                    'read': true,
                    'write': false
                },
                'csum__c': {
                    'read': true,
                    'write': false
                },
                'CKSW_BASE__Description__c': {
                    'read': true,
                    'write': false
                },
                'Special_Instruction_from_PMS_Case_Auto__c': {
                    'read': true,
                    'write': false
                },
                'Asset_Model__c': {
                    'read': true,
                    'write': false
                },
                'Custom_work_order_instructions__c': {
                    'read': true,
                    'write': false
                },
                'Jobsite_Contact_SDesk_Name_phone_email__c': {
                    'read': true,
                    'write': false
                },
                'Jobsite_Name__c': {
                    'read': true,
                    'write': false
                },
                'Partner_Case_Number__c': {
                    'read': true,
                    'write': false
                },
                'Worker_Arrival_DateTime_Cust_Requested__c': {
                    'read': true,
                    'write': false
                },
                'Does_this_Job_require_Service_Parts__c': {
                    'read': true,
                    'write': false
                },
                'Service_Parts_Local_Pickup_Required__c': {
                    'read': true,
                    'write': false
                },
                'Does_this_Job_require_New_Equip_Hardware__c': {
                    'read': true,
                    'write': false
                },
                'Equipment_Local_Pickup_Required__c': {
                    'read': true,
                    'write': false
                },
                'Equipment_Delivery_Date_ETA__c': {
                    'read': true,
                    'write': false
                },
                'Equipment_Delivery_Time_ETA_Hours__c': {
                    'read': true,
                    'write': false
                },
                'Equipment_Tracking__c': {
                    'read': true,
                    'write': false
                },
                'Iron_Case__c': {
                    'read': false,
                    'write': false
                },
                'Jobsite_Contact_Technical_Escalation__c': {
                    'read': true,
                    'write': false
                },
                'SOW_Work_Order__c': {
                    'read': true,
                    'write': false
                },
                'Job_Instruction_Details_Long__c': {
                    'read': true,
                    'write': false
                }
            },
            'project': {
                'Project__c': {
                    'read': false,
                    'write': false
                },
            },
            'appointment': {
                'Worker_Arrival_DateTime_Cust_Requested__c': {
                    'read': true,
                    'write': false
                },
                'Customer_Appointment_DateTime_Scheduled__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Arrival_DateTime_Scheduled__c': {
                    'read': true,
                    'write': true
                },
                'Max_Hours_Units__c': {
                    'read': true,
                    'write': false
                }
            },
            'work-order': {
                'Name': {
                    'read': true,
                    'write': false
                }
            },
            'worker': {
                'Name': {
                    'read': true,
                    'write': true
                }
            }
        },
        'manager': {
            'job': {
                'Job_Status_Internal__c': {
                    'read': true,
                    'write': false
                },
                'Dispatch_Service_Resolution_Status__c': {
                    'read': true,
                    'write': false
                },
                'Service_Parts_Local_Pickup_Required__c': {
                    'read': true,
                    'write': false
                },
                'Appointment_Schedule_Status_Customer__c': {
                    'read': true,
                    'write': false
                },
                'Appointment_Schedule_Status_Customer_vms__c': {
                    'read': true,
                    'write': true
                },
                'Phone_Scheduling_1st_Attempt_Unreachable__c': {
                    'read': true,
                    'write': true
                },
                'Phone_Scheduling_2nd_Attempt_Unreachable__c': {
                    'read': true,
                    'write': true
                },
                'Phone_Scheduling_3rd_Attempt_Unreachable__c': {
                    'read': true,
                    'write': true
                },
                'Appointment_Call_Notes__c': {
                    'read': true,
                    'write': true
                },
                'Dispatch_Worker_Name__c': {
                    'read': true,
                    'write': true
                },
                'Dispatch_Worker_Phone': {
                    'read': true,
                    'write': true
                },
                'CKSW_BASE__Incomplete_reason__c': {
                    'read': true,
                    'write': true
                },
                'CKSW_BASE__Other_Incomplete_Reason__c': {
                    'read': true,
                    'write': true
                }
            },
            'appointment': {
                'Customer_Appointment_Setup_Required__c': {
                    'read': true,
                    'write': false
                },
                'Worker_Arrival_DateTime_Cust_Requested__c': {
                    'read': true,
                    'write': false
                },
                'Worker_Arrival_Date_Customer_Req_End__c': {
                    'read': true,
                    'write': false
                },
                'Customer_Appointment_DateTime_Scheduled__c': {
                    'read': true,
                    'write': true
                },
                'Customer_Appointment_Start_Scheduled__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Arrival_DateTime_Scheduled__c': {
                    'read': true,
                    'write': true
                },
                'Worker_End_DateTime_Scheduled__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Departure_Date_Time_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Arrival_Date_Time_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Worker_InProgress_Start_DateTime_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Finish_Date_Time_Actual__c': {
                    'read': true,
                    'write': true
                }
            },
            'message': {
                'Is_Public__c': {
                    'read': true,
                    'write': true
                },
                'Is_Public_Job__c': {
                    'read': true,
                    'write': true
                },
                'contentText': {
                    'read': true,
                    'write': true
                }
            }
        },
        'worker-status': {
            'job': {
                'Service_Parts_Local_Pickup_Required__c': {
                    'read': true,
                    'write': false
                },
                'Worker_Departure_Date_Time_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Arrival_Date_Time_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Worker_InProgress_Start_DateTime_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Finish_Date_Time_Actual__c': {
                    'read': true,
                    'write': true
                },
            },
            'deliverable': {
                'Name': {
                    'read': true,
                    'write': true
                },
                'Deliverable_Type__c': {
                    'read': true,
                    'write': true
                },
                'File_upload_required__c': {
                    'read': true,
                    'write': true
                },
                'Description__c': {
                    'read': true,
                    'write': true
                },
                'Instructions__c': {
                    'read': true,
                    'write': true
                },
                'Deliverable_Status__c': {
                    'read': true,
                    'write': true
                },
            },
            'timecard': {
                'vendorName': {
                    'read': true,
                    'write': true
                },
                'Name': {
                    'read': true,
                    'write': false
                },
                'Work_Order_num__c': {
                    'read': true,
                    'write': false
                },
                'Iron_Job_num__c': {
                    'read': true,
                    'write': true
                },
                'Final_Timecard': {
                    'read': true,
                    'write': true
                },
                'Customer_Approval_Status__c': {
                    'read': true,
                    'write': true
                },
                'Visit_Number_Calc__c': {
                    'read': true,
                    'write': true
                },
                'Vendor_Time_Card_Time_in_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Vendor_Time_Card_Time_Out_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Total_Worked_Hours_Without_Offset__c': {
                    'read': true,
                    'write': true
                },
                'workerName': {
                    'read': true,
                    'write': true
                },
                'Customer_Site_Signoff_Name__c': {
                    'read': true,
                    'write': true
                },
                'Equipment_Serial_Performed_on__c': {
                    'read': true,
                    'write': true
                },
                'Equipment_Make_Model_Performed_on__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Mobile_OS_Android_iOS__c': {
                    'read': true,
                    'write': true
                },
                'CreatedDate': {
                    'read': true,
                    'write': false
                }
            },
            'comment': {
                'Is_Public__c': {
                    'read': true,
                    'write': true
                },
                'Is_Public_Job__c': {
                    'read': true,
                    'write': true
                },
                'contentText': {
                    'read': true,
                    'write': true
                }
            }
        },
        'message': {
            'comment': {
                'messageType': {
                    'read': true,
                    'write': false
                },
                'Case_Comment_Body__c': {
                    'read': true,
                    'write': true
                },
                'Is_Public_Job_c': {
                    'read': true,
                    'write': true
                },
                'Is_Public_c': {
                    'read': true,
                    'write': true
                }
            }
        },

        'timecard': {
            'account': {
                'Name': {
                    'read': true,
                    'write': true
                }
            },
            'timecard': {
                'Name': {
                    'read': true,
                    'write': false
                },
                'Final_Timecard': {
                    'read': true,
                    'write': true
                },
                'Customer_Approval_Status__c': {
                    'read': true,
                    'write': true
                },
                'Visit_Number_Calc__c': {
                    'read': true,
                    'write': false
                },
                'Vendor_Time_Card_Time_in_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Vendor_Time_Card_Time_Out_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Total_Worked_Hours_Without_Offset__c': {
                    'read': true,
                    'write': true
                },
                'Customer_Site_Signoff_Name__c': {
                    'read': true,
                    'write': true
                },
                'Equipment_Serial_Performed_on__c': {
                    'read': true,
                    'write': true
                },
                'Equipment_Make_Model_Performed_on__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Mobile_OS_Android_iOS__c': {
                    'read': true,
                    'write': true
                },
                'CreatedDate': {
                    'read': true,
                    'write': false
                }
            },
            'purchase-order': {
                'Work_Order_num__c': {
                    'read': true,
                    'write': false
                }
            },
            'job': {
                'Iron_Job_num__c': {
                    'read': true,
                    'write': true
                }
            },
            'worker': {
                'Name': {
                    'read': true,
                    'write': true
                }
            }
        },

        'billing': {
            'comment': {
                'messageType': {
                    'read': false,
                    'write': false
                },
                'Case_Comment_Body__c': {
                    'read': false,
                    'write': false
                },
                'Is_Public_Job_c': {
                    'read': false,
                    'write': false
                },
                'Is_Public_c': {
                    'read': false,
                    'write': false
                }
            }
        }
    },
    'vms_fte_assigned': {
        'summary': {
            'job': {
                'Dispatch_Service_Resolution_Status__c': {
                    'read': true,
                    'write': false
                },
                'Customer_Service_Type_From_Program__c': {
                    'read': true,
                    'write': false
                },
                'Technical_Level__c': {
                    'read': true,
                    'write': false
                },
                'Service_Dispatch_SLA_Priority_FrmProgram__c': {
                    'read': true,
                    'write': false
                },
                'csum__c': {
                    'read': true,
                    'write': false
                },
                'CKSW_BASE__Description__c': {
                    'read': true,
                    'write': false
                },
                'Special_Instruction_from_PMS_Case_Auto__c': {
                    'read': true,
                    'write': false
                },
                'Asset_Model__c': {
                    'read': true,
                    'write': false
                },
                'Custom_work_order_instructions__c': {
                    'read': true,
                    'write': false
                },
                'Jobsite_Contact_SDesk_Name_phone_email__c': {
                    'read': true,
                    'write': false
                },
                'Jobsite_Name__c': {
                    'read': true,
                    'write': false
                },
                'Partner_Case_Number__c': {
                    'read': true,
                    'write': false
                },
                'Worker_Arrival_DateTime_Cust_Requested__c': {
                    'read': true,
                    'write': false
                },
                'Does_this_Job_require_Service_Parts__c': {
                    'read': true,
                    'write': false
                },
                'Service_Parts_Local_Pickup_Required__c': {
                    'read': true,
                    'write': false
                },
                'Does_this_Job_require_New_Equip_Hardware__c': {
                    'read': true,
                    'write': false
                },
                'Equipment_Local_Pickup_Required__c': {
                    'read': true,
                    'write': false
                },
                'Equipment_Delivery_Date_ETA__c': {
                    'read': true,
                    'write': false
                },
                'Equipment_Delivery_Time_ETA_Hours__c': {
                    'read': true,
                    'write': false
                },
                'Equipment_Tracking__c': {
                    'read': true,
                    'write': false
                },
                'Iron_Case__c': {
                    'read': false,
                    'write': false
                },
                'Jobsite_Contact_Technical_Escalation__c': {
                    'read': true,
                    'write': false
                },
                'SOW_Work_Order__c': {
                    'read': true,
                    'write': false
                },
                'Job_Instruction_Details_Long__c': {
                    'read': true,
                    'write': false
                }
            },
            'project': {
                'Project__c': {
                    'read': false,
                    'write': false
                },
            },
            'appointment': {
                'Worker_Arrival_DateTime_Cust_Requested__c': {
                    'read': true,
                    'write': false
                },
                'Customer_Appointment_DateTime_Scheduled__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Arrival_DateTime_Scheduled__c': {
                    'read': true,
                    'write': true
                },
                'Max_Hours_Units__c': {
                    'read': true,
                    'write': false
                }
            },
            'work-order': {
                'Name': {
                    'read': true,
                    'write': false
                }
            },
            'worker': {
                'Name': {
                    'read': true,
                    'write': true
                }
            }
        },

        'manager': {
            'job': {
                'Job_Status_Internal__c': {
                    'read': true,
                    'write': false
                },
                'Dispatch_Service_Resolution_Status__c': {
                    'read': true,
                    'write': false
                },
                'Service_Parts_Local_Pickup_Required__c': {
                    'read': true,
                    'write': false
                },
                'Appointment_Schedule_Status_Customer__c': {
                    'read': true,
                    'write': false
                },
                'Appointment_Schedule_Status_Customer_vms__c': {
                    'read': true,
                    'write': true
                },
                'Phone_Scheduling_1st_Attempt_Unreachable__c': {
                    'read': true,
                    'write': true
                },
                'Phone_Scheduling_2nd_Attempt_Unreachable__c': {
                    'read': true,
                    'write': true
                },
                'Phone_Scheduling_3rd_Attempt_Unreachable__c': {
                    'read': true,
                    'write': true
                },
                'Appointment_Call_Notes__c': {
                    'read': true,
                    'write': true
                },
                'Dispatch_Worker_Name__c': {
                    'read': true,
                    'write': true
                },
                'Dispatch_Worker_Phone': {
                    'read': true,
                    'write': true
                },
                'CKSW_BASE__Incomplete_reason__c': {
                    'read': true,
                    'write': true
                },
                'CKSW_BASE__Other_Incomplete_Reason__c': {
                    'read': true,
                    'write': true
                }
            },
            'appointment': {
                'Customer_Appointment_Setup_Required__c': {
                    'read': true,
                    'write': false
                },
                'Worker_Arrival_DateTime_Cust_Requested__c': {
                    'read': true,
                    'write': false
                },
                'Worker_Arrival_Date_Customer_Req_End__c': {
                    'read': true,
                    'write': false
                },
                'Customer_Appointment_DateTime_Scheduled__c': {
                    'read': true,
                    'write': true
                },
                'Customer_Appointment_Start_Scheduled__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Arrival_DateTime_Scheduled__c': {
                    'read': true,
                    'write': true
                },
                'Worker_End_DateTime_Scheduled__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Departure_Date_Time_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Arrival_Date_Time_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Worker_InProgress_Start_DateTime_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Finish_Date_Time_Actual__c': {
                    'read': true,
                    'write': true
                }
            },
            'message': {
                'Is_Public__c': {
                    'read': true,
                    'write': true
                },
                'Is_Public_Job__c': {
                    'read': true,
                    'write': true
                },
                'contentText': {
                    'read': true,
                    'write': true
                }
            }
        },

        'worker-status': {
            'job': {
                'Service_Parts_Local_Pickup_Required__c': {
                    'read': true,
                    'write': false
                },
                'Worker_Departure_Date_Time_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Arrival_Date_Time_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Worker_InProgress_Start_DateTime_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Finish_Date_Time_Actual__c': {
                    'read': true,
                    'write': true
                },
            },
            'deliverable': {
                'Name': {
                    'read': true,
                    'write': true
                },
                'Deliverable_Type__c': {
                    'read': true,
                    'write': true
                },
                'File_upload_required__c': {
                    'read': true,
                    'write': true
                },
                'Description__c': {
                    'read': true,
                    'write': true
                },
                'Instructions__c': {
                    'read': true,
                    'write': true
                },
                'Deliverable_Status__c': {
                    'read': true,
                    'write': true
                },
            },
            'timecard': {
                'vendorName': {
                    'read': true,
                    'write': true
                },
                'Name': {
                    'read': true,
                    'write': false
                },
                'Work_Order_num__c': {
                    'read': true,
                    'write': false
                },
                'Iron_Job_num__c': {
                    'read': true,
                    'write': true
                },
                'Final_Timecard': {
                    'read': true,
                    'write': true
                },
                'Customer_Approval_Status__c': {
                    'read': true,
                    'write': true
                },
                'Visit_Number_Calc__c': {
                    'read': true,
                    'write': true
                },
                'Vendor_Time_Card_Time_in_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Vendor_Time_Card_Time_Out_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Total_Worked_Hours_Without_Offset__c': {
                    'read': true,
                    'write': true
                },
                'workerName': {
                    'read': true,
                    'write': true
                },
                'Customer_Site_Signoff_Name__c': {
                    'read': true,
                    'write': true
                },
                'Equipment_Serial_Performed_on__c': {
                    'read': true,
                    'write': true
                },
                'Equipment_Make_Model_Performed_on__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Mobile_OS_Android_iOS__c': {
                    'read': true,
                    'write': true
                },
                'CreatedDate': {
                    'read': true,
                    'write': false
                }
            },
            'comment': {
                'Is_Public__c': {
                    'read': true,
                    'write': true
                },
                'Is_Public_Job__c': {
                    'read': true,
                    'write': true
                },
                'contentText': {
                    'read': true,
                    'write': true
                }
            }
        },

        'message': {
            'comment': {
                'Case_Comment_Body__c': {
                    'read': true,
                    'write': true
                },
                'Is_Public_Job_c': {
                    'read': true,
                    'write': true
                },
                'Is_Public_c': {
                    'read': true,
                    'write': true
                }
            }
        },

        'timecard': {
            'account': {
                'Name': {
                    'read': true,
                    'write': true
                }
            },
            'timecard': {
                'Name': {
                    'read': true,
                    'write': false
                },
                'Final_Timecard': {
                    'read': true,
                    'write': true
                },
                'Customer_Approval_Status__c': {
                    'read': true,
                    'write': true
                },
                'Visit_Number_Calc__c': {
                    'read': true,
                    'write': false
                },
                'Vendor_Time_Card_Time_in_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Vendor_Time_Card_Time_Out_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Total_Worked_Hours_Without_Offset__c': {
                    'read': true,
                    'write': true
                },
                'Customer_Site_Signoff_Name__c': {
                    'read': true,
                    'write': true
                },
                'Equipment_Serial_Performed_on__c': {
                    'read': true,
                    'write': true
                },
                'Equipment_Make_Model_Performed_on__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Mobile_OS_Android_iOS__c': {
                    'read': true,
                    'write': true
                },
                'CreatedDate': {
                    'read': true,
                    'write': false
                }
            },
            'purchase-order': {
                'Work_Order_num__c': {
                    'read': true,
                    'write': false
                }
            },
            'job': {
                'Iron_Job_num__c': {
                    'read': true,
                    'write': true
                }
            },
            'worker': {
                'Name': {
                    'read': true,
                    'write': true
                }
            }
        },

        'billing': {
            'comment': {
                'messageType': {
                    'read': false,
                    'write': false
                },
                'Case_Comment_Body__c': {
                    'read': false,
                    'write': false
                },
                'Is_Public_Job_c': {
                    'read': false,
                    'write': false
                },
                'Is_Public_c': {
                    'read': false,
                    'write': false
                }
            }
        }
    },
    'vms_fse_worker': {
        'summary': {
            'job': {
                'Dispatch_Service_Resolution_Status__c': {
                    'read': true,
                    'write': false
                },
                'Customer_Service_Type_From_Program__c': {
                    'read': true,
                    'write': false
                },
                'Technical_Level__c': {
                    'read': true,
                    'write': false
                },
                'Service_Dispatch_SLA_Priority_FrmProgram__c': {
                    'read': true,
                    'write': false
                },
                'csum__c': {
                    'read': true,
                    'write': false
                },
                'CKSW_BASE__Description__c': {
                    'read': true,
                    'write': false
                },
                'Special_Instruction_from_PMS_Case_Auto__c': {
                    'read': true,
                    'write': false
                },
                'Asset_Model__c': {
                    'read': true,
                    'write': false
                },
                'Custom_work_order_instructions__c': {
                    'read': true,
                    'write': false
                },
                'Jobsite_Contact_SDesk_Name_phone_email__c': {
                    'read': true,
                    'write': false
                },
                'Jobsite_Name__c': {
                    'read': true,
                    'write': false
                },
                'Partner_Case_Number__c': {
                    'read': true,
                    'write': false
                },
                'Worker_Arrival_DateTime_Cust_Requested__c': {
                    'read': true,
                    'write': false
                },
                'Does_this_Job_require_Service_Parts__c': {
                    'read': true,
                    'write': false
                },
                'Service_Parts_Local_Pickup_Required__c': {
                    'read': true,
                    'write': false
                },
                'Does_this_Job_require_New_Equip_Hardware__c': {
                    'read': true,
                    'write': false
                },
                'Equipment_Local_Pickup_Required__c': {
                    'read': true,
                    'write': false
                },
                'Equipment_Delivery_Date_ETA__c': {
                    'read': true,
                    'write': false
                },
                'Equipment_Delivery_Time_ETA_Hours__c': {
                    'read': true,
                    'write': false
                },
                'Equipment_Tracking__c': {
                    'read': true,
                    'write': false
                },
                'Iron_Case__c': {
                    'read': false,
                    'write': false
                },
                'Jobsite_Contact_Technical_Escalation__c': {
                    'read': true,
                    'write': false
                },
                'SOW_Work_Order__c': {
                    'read': true,
                    'write': false
                },
                'Job_Instruction_Details_Long__c': {
                    'read': true,
                    'write': false
                }
            },
            'project': {
                'Project__c': {
                    'read': false,
                    'write': false
                },
            },
            'appointment': {
                'Worker_Arrival_DateTime_Cust_Requested__c': {
                    'read': true,
                    'write': false
                },
                'Customer_Appointment_DateTime_Scheduled__c': {
                    'read': true,
                    'write': false
                },
                'Worker_Arrival_DateTime_Scheduled__c': {
                    'read': true,
                    'write': false
                },
                'Max_Hours_Units__c': {
                    'read': true,
                    'write': false
                }
            },
            'work-order': {
                'Name': {
                    'read': true,
                    'write': false
                }
            },
            'worker': {
                'Name': {
                    'read': true,
                    'write': false
                }
            }
        },

        'manager': {
            'job': {
                'Job_Status_Internal__c': {
                    'read': true,
                    'write': false
                },
                'Dispatch_Service_Resolution_Status__c': {
                    'read': true,
                    'write': false
                },
                'Service_Parts_Local_Pickup_Required__c': {
                    'read': true,
                    'write': false
                },
                'Appointment_Schedule_Status_Customer__c': {
                    'read': true,
                    'write': false
                },
                'Appointment_Schedule_Status_Customer_vms__c': {
                    'read': true,
                    'write': false
                },
                'Phone_Scheduling_1st_Attempt_Unreachable__c': {
                    'read': true,
                    'write': false
                },
                'Phone_Scheduling_2nd_Attempt_Unreachable__c': {
                    'read': true,
                    'write': false
                },
                'Phone_Scheduling_3rd_Attempt_Unreachable__c': {
                    'read': true,
                    'write': false
                },
                'Appointment_Call_Notes__c': {
                    'read': true,
                    'write': false
                },
                'Dispatch_Worker_Name__c': {
                    'read': true,
                    'write': false
                },
                'Dispatch_Worker_Phone': {
                    'read': true,
                    'write': false
                },
                'CKSW_BASE__Incomplete_reason__c': {
                    'read': true,
                    'write': false
                },
                'CKSW_BASE__Other_Incomplete_Reason__c': {
                    'read': true,
                    'write': false
                }
            },
            'appointment': {
                'Customer_Appointment_Setup_Required__c': {
                    'read': true,
                    'write': false
                },
                'Worker_Arrival_DateTime_Cust_Requested__c': {
                    'read': true,
                    'write': false
                },
                'Worker_Arrival_Date_Customer_Req_End__c': {
                    'read': true,
                    'write': false
                },
                'Customer_Appointment_DateTime_Scheduled__c': {
                    'read': true,
                    'write': false
                },
                'Customer_Appointment_Start_Scheduled__c': {
                    'read': true,
                    'write': false
                },
                'Worker_Arrival_DateTime_Scheduled__c': {
                    'read': true,
                    'write': false
                },
                'Worker_End_DateTime_Scheduled__c': {
                    'read': true,
                    'write': false
                },
                'Worker_Departure_Date_Time_Actual__c': {
                    'read': true,
                    'write': false
                },
                'Worker_Arrival_Date_Time_Actual__c': {
                    'read': true,
                    'write': false
                },
                'Worker_InProgress_Start_DateTime_Actual__c': {
                    'read': true,
                    'write': false
                },
                'Worker_Finish_Date_Time_Actual__c': {
                    'read': true,
                    'write': false
                }
            },
            'message': {
                'Is_Public__c': {
                    'read': true,
                    'write': false
                },
                'Is_Public_Job__c': {
                    'read': true,
                    'write': false
                },
                'contentText': {
                    'read': true,
                    'write': false
                }
            }
        },

        'worker-status': {
            'job': {
                'Service_Parts_Local_Pickup_Required__c': {
                    'read': true,
                    'write': false
                },
                'Worker_Departure_Date_Time_Actual__c': {
                    'read': true,
                    'write': false
                },
                'Worker_Arrival_Date_Time_Actual__c': {
                    'read': true,
                    'write': false
                },
                'Worker_InProgress_Start_DateTime_Actual__c': {
                    'read': true,
                    'write': false
                },
                'Worker_Finish_Date_Time_Actual__c': {
                    'read': true,
                    'write': false
                },
            },
            'deliverable': {
                'Name': {
                    'read': true,
                    'write': false
                },
                'Deliverable_Type__c': {
                    'read': true,
                    'write': false
                },
                'File_upload_required__c': {
                    'read': true,
                    'write': false
                },
                'Description__c': {
                    'read': true,
                    'write': false
                },
                'Instructions__c': {
                    'read': true,
                    'write': false
                },
                'Deliverable_Status__c': {
                    'read': true,
                    'write': false
                },
            },
            'timecard': {
                'vendorName': {
                    'read': true,
                    'write': false
                },
                'Name': {
                    'read': true,
                    'write': false
                },
                'Work_Order_num__c': {
                    'read': true,
                    'write': false
                },
                'Iron_Job_num__c': {
                    'read': true,
                    'write': false
                },
                'Final_Timecard': {
                    'read': true,
                    'write': false
                },
                'Customer_Approval_Status__c': {
                    'read': true,
                    'write': false
                },
                'Visit_Number_Calc__c': {
                    'read': true,
                    'write': false
                },
                'Vendor_Time_Card_Time_in_Actual__c': {
                    'read': true,
                    'write': false
                },
                'Vendor_Time_Card_Time_Out_Actual__c': {
                    'read': true,
                    'write': false
                },
                'Total_Worked_Hours_Without_Offset__c': {
                    'read': true,
                    'write': false
                },
                'workerName': {
                    'read': true,
                    'write': false
                },
                'Customer_Site_Signoff_Name__c': {
                    'read': true,
                    'write': false
                },
                'Equipment_Serial_Performed_on__c': {
                     'read': true,
                    'write': false
                },
                'Equipment_Make_Model_Performed_on__c': {
                     'read': true,
                    'write': false
                },
                'Worker_Mobile_OS_Android_iOS__c': {
                     'read': true,
                    'write': false
                },
                'CreatedDate': {
                    'read': true,
                    'write': false
                }
            },
            'comment': {
                'Is_Public__c': {
                    'read': true,
                    'write': false
                },
                'Is_Public_Job__c': {
                    'read': true,
                    'write': false
                },
                'contentText': {
                    'read': true,
                    'write': false
                }
            }
        },

        'message': {
            'user': {
                'profileImage': {
                    'read': true,
                    'write': false
                }
            },
            'comment': {
                'postByName': {
                    'read': true,
                    'write': false
                },
                'messageType': {
                    'read': true,
                    'write': false
                },
                'createdAt': {
                    'read': true,
                    'write': false
                },
                'Case_Comment_Body__c': {
                    'read': true,
                    'write': false
                },
                'Is_Public_Job_c': {
                    'read': true,
                    'write': false
                },
                'Is_Public_c': {
                    'read': true,
                    'write': false
                }
            }
        },

        'timecard': {
            'account': {
                'Name': {
                    'read': true,
                    'write': false
                }
            },
            'timecard': {
                'Name': {
                    'read': true,
                    'write': false
                },
                'Final_Timecard': {
                    'read': true,
                    'write': false
                },
                'Customer_Approval_Status__c': {
                    'read': true,
                    'write': false
                },
                'Visit_Number_Calc__c': {
                    'read': true,
                    'write': false
                },
                'Vendor_Time_Card_Time_in_Actual__c': {
                    'read': true,
                    'write': false
                },
                'Vendor_Time_Card_Time_Out_Actual__c': {
                    'read': true,
                    'write': false
                },
                'Total_Worked_Hours_Without_Offset__c': {
                    'read': true,
                    'write': false
                },
                'Customer_Site_Signoff_Name__c': {
                    'read': true,
                    'write': false
                },
                'Equipment_Serial_Performed_on__c': {
                    'read': true,
                    'write': false
                },
                'Equipment_Make_Model_Performed_on__c': {
                    'read': true,
                    'write': false
                },
                'Worker_Mobile_OS_Android_iOS__c': {
                    'read': true,
                    'write': false
                },
                'CreatedDate': {
                    'read': true,
                    'write': false
                }
            },
            'purchase-order': {
                'Work_Order_num__c': {
                    'read': true,
                    'write': false
                }
            },
            'job': {
                'Iron_Job_num__c': {
                    'read': true,
                    'write': false
                }
            },
            'worker': {
                'Name': {
                    'read': true,
                    'write': false
                }
            }
        },

        'billing': {
            'comment': {
                'messageType': {
                    'read': false,
                    'write': false
                },
                'Case_Comment_Body__c': {
                    'read': false,
                    'write': false
                },
                'Is_Public_Job_c': {
                    'read': false,
                    'write': false
                },
                'Is_Public_c': {
                    'read': false,
                    'write': false
                }
            }
        }
    },
    'vms_fte_worker': {
        'summary': {
            'job': {
                'Dispatch_Service_Resolution_Status__c': {
                    'read': true,
                    'write': false
                },
                'Customer_Service_Type_From_Program__c': {
                    'read': true,
                    'write': false
                },
                'Technical_Level__c': {
                    'read': true,
                    'write': false
                },
                'Service_Dispatch_SLA_Priority_FrmProgram__c': {
                    'read': true,
                    'write': false
                },
                'csum__c': {
                    'read': true,
                    'write': false
                },
                'CKSW_BASE__Description__c': {
                    'read': true,
                    'write': false
                },
                'Special_Instruction_from_PMS_Case_Auto__c': {
                    'read': true,
                    'write': false
                },
                'Asset_Model__c': {
                    'read': true,
                    'write': false
                },
                'Custom_work_order_instructions__c': {
                    'read': true,
                    'write': false
                },
                'Jobsite_Contact_SDesk_Name_phone_email__c': {
                    'read': true,
                    'write': false
                },
                'Jobsite_Name__c': {
                    'read': true,
                    'write': false
                },
                'Partner_Case_Number__c': {
                    'read': true,
                    'write': false
                },
                'Worker_Arrival_DateTime_Cust_Requested__c': {
                    'read': true,
                    'write': false
                },
                'Does_this_Job_require_Service_Parts__c': {
                    'read': true,
                    'write': false
                },
                'Service_Parts_Local_Pickup_Required__c': {
                    'read': true,
                    'write': false
                },
                'Does_this_Job_require_New_Equip_Hardware__c': {
                    'read': true,
                    'write': false
                },
                'Equipment_Local_Pickup_Required__c': {
                    'read': true,
                    'write': false
                },
                'Equipment_Delivery_Date_ETA__c': {
                    'read': true,
                    'write': false
                },
                'Equipment_Delivery_Time_ETA_Hours__c': {
                    'read': true,
                    'write': false
                },
                'Equipment_Tracking__c': {
                    'read': true,
                    'write': false
                },
                'Iron_Case__c': {
                    'read': false,
                    'write': false
                },
                'Jobsite_Contact_Technical_Escalation__c': {
                    'read': true,
                    'write': false
                },
                'SOW_Work_Order__c': {
                    'read': true,
                    'write': false
                },
                'Job_Instruction_Details_Long__c': {
                    'read': true,
                    'write': false
                }
            },
            'project': {
                'Project__c': {
                    'read': false,
                    'write': false
                },
            },
            'appointment': {
                'Worker_Arrival_DateTime_Cust_Requested__c': {
                    'read': true,
                    'write': false
                },
                'Customer_Appointment_DateTime_Scheduled__c': {
                    'read': true,
                    'write': false
                },
                'Worker_Arrival_DateTime_Scheduled__c': {
                    'read': true,
                    'write': false
                },
                'Max_Hours_Units__c': {
                    'read': true,
                    'write': false
                }
            },
            'work-order': {
                'Name': {
                    'read': true,
                    'write': false
                }
            },
            'worker': {
                'Name': {
                    'read': true,
                    'write': false
                }
            }
        },

        'manager': {
            'job': {
                'Job_Status_Internal__c': {
                    'read': true,
                    'write': false
                },
                'Dispatch_Service_Resolution_Status__c': {
                    'read': true,
                    'write': false
                },
                'Service_Parts_Local_Pickup_Required__c': {
                    'read': true,
                    'write': false
                },
                'Appointment_Schedule_Status_Customer__c': {
                    'read': true,
                    'write': false
                },
                'Appointment_Schedule_Status_Customer_vms__c': {
                    'read': true,
                    'write': false
                },
                'Phone_Scheduling_1st_Attempt_Unreachable__c': {
                    'read': true,
                    'write': false
                },
                'Phone_Scheduling_2nd_Attempt_Unreachable__c': {
                    'read': true,
                    'write': false
                },
                'Phone_Scheduling_3rd_Attempt_Unreachable__c': {
                    'read': true,
                    'write': false
                },
                'Appointment_Call_Notes__c': {
                    'read': true,
                    'write': false
                },
                'Dispatch_Worker_Name__c': {
                    'read': true,
                    'write': false
                },
                'Dispatch_Worker_Phone': {
                    'read': true,
                    'write': false
                },
                'CKSW_BASE__Incomplete_reason__c': {
                    'read': true,
                    'write': false
                },
                'CKSW_BASE__Other_Incomplete_Reason__c': {
                    'read': true,
                    'write': false
                }
            },
            'appointment': {
                'Customer_Appointment_Setup_Required__c': {
                    'read': true,
                    'write': false
                },
                'Worker_Arrival_DateTime_Cust_Requested__c': {
                    'read': true,
                    'write': false
                },
                'Worker_Arrival_Date_Customer_Req_End__c': {
                    'read': true,
                    'write': false
                },
                'Customer_Appointment_DateTime_Scheduled__c': {
                    'read': true,
                    'write': false
                },
                'Customer_Appointment_Start_Scheduled__c': {
                    'read': true,
                    'write': false
                },
                'Worker_Arrival_DateTime_Scheduled__c': {
                    'read': true,
                    'write': false
                },
                'Worker_End_DateTime_Scheduled__c': {
                    'read': true,
                    'write': false
                },
                'Worker_Departure_Date_Time_Actual__c': {
                    'read': true,
                    'write': false
                },
                'Worker_Arrival_Date_Time_Actual__c': {
                    'read': true,
                    'write': false
                },
                'Worker_InProgress_Start_DateTime_Actual__c': {
                    'read': true,
                    'write': false
                },
                'Worker_Finish_Date_Time_Actual__c': {
                    'read': true,
                    'write': false
                }
            },
            'message': {
                'Is_Public__c': {
                    'read': true,
                    'write': false
                },
                'Is_Public_Job__c': {
                    'read': true,
                    'write': false
                },
                'contentText': {
                    'read': true,
                    'write': false
                }
            }
        },

        'worker-status': {
            'job': {
                'Service_Parts_Local_Pickup_Required__c': {
                    'read': true,
                    'write': false
                },
                'Worker_Departure_Date_Time_Actual__c': {
                    'read': true,
                    'write': false
                },
                'Worker_Arrival_Date_Time_Actual__c': {
                    'read': true,
                    'write': false
                },
                'Worker_InProgress_Start_DateTime_Actual__c': {
                    'read': true,
                    'write': false
                },
                'Worker_Finish_Date_Time_Actual__c': {
                    'read': true,
                    'write': false
                },
            },
            'deliverable': {
                'Name': {
                    'read': true,
                    'write': false
                },
                'Deliverable_Type__c': {
                    'read': true,
                    'write': false
                },
                'File_upload_required__c': {
                    'read': true,
                    'write': false
                },
                'Description__c': {
                    'read': true,
                    'write': false
                },
                'Instructions__c': {
                    'read': true,
                    'write': false
                },
                'Deliverable_Status__c': {
                    'read': true,
                    'write': false
                },
            },
            'timecard': {
                'vendorName': {
                    'read': true,
                    'write': false
                },
                'Name': {
                    'read': true,
                    'write': false
                },
                'Work_Order_num__c': {
                    'read': true,
                    'write': false
                },
                'Iron_Job_num__c': {
                    'read': true,
                    'write': false
                },
                'Final_Timecard': {
                    'read': true,
                    'write': false
                },
                'Customer_Approval_Status__c': {
                    'read': true,
                    'write': false
                },
                'Visit_Number_Calc__c': {
                    'read': true,
                    'write': false
                },
                'Vendor_Time_Card_Time_in_Actual__c': {
                    'read': true,
                    'write': false
                },
                'Vendor_Time_Card_Time_Out_Actual__c': {
                    'read': true,
                    'write': false
                },
                'Total_Worked_Hours_Without_Offset__c': {
                    'read': true,
                    'write': false
                },
                'workerName': {
                    'read': true,
                    'write': false
                },
                'Customer_Site_Signoff_Name__c': {
                    'read': true,
                    'write': false
                },
                'Equipment_Serial_Performed_on__c': {
                    'read': true,
                    'write': false
                },
                'Equipment_Make_Model_Performed_on__c': {
                    'read': true,
                    'write': false
                },
                'Worker_Mobile_OS_Android_iOS__c': {
                    'read': true,
                    'write': false
                },
                'CreatedDate': {
                    'read': true,
                    'write': false
                }
            },
            'comment': {
                'Is_Public__c': {
                    'read': true,
                    'write': false
                },
                'Is_Public_Job__c': {
                    'read': true,
                    'write': false
                },
                'contentText': {
                    'read': true,
                    'write': false
                }
            }
        },

        'message': {
            'user': {
                'profileImage': {
                    'read': true,
                    'write': false
                }
            },
            'comment': {
                'postByName': {
                    'read': true,
                    'write': false
                },
                'messageType': {
                    'read': true,
                    'write': false
                },
                'createdAt': {
                    'read': true,
                    'write': false
                },
                'Case_Comment_Body__c': {
                    'read': true,
                    'write': false
                },
                'Is_Public_Job_c': {
                    'read': true,
                    'write': false
                },
                'Is_Public_c': {
                    'read': true,
                    'write': false
                }
            }
        },

        'timecard': {
            'account': {
                'Name': {
                    'read': true,
                    'write': false
                }
            },
            'timecard': {
                'Name': {
                    'read': true,
                    'write': false
                },
                'Final_Timecard': {
                    'read': true,
                    'write': false
                },
                'Customer_Approval_Status__c': {
                    'read': true,
                    'write': false
                },
                'Visit_Number_Calc__c': {
                    'read': true,
                    'write': false
                },
                'Vendor_Time_Card_Time_in_Actual__c': {
                    'read': true,
                    'write': false
                },
                'Vendor_Time_Card_Time_Out_Actual__c': {
                    'read': true,
                    'write': false
                },
                'Total_Worked_Hours_Without_Offset__c': {
                    'read': true,
                    'write': false
                },
                'Customer_Site_Signoff_Name__c': {
                    'read': true,
                    'write': false
                },
                'Equipment_Serial_Performed_on__c': {
                    'read': true,
                    'write': false
                },
                'Equipment_Make_Model_Performed_on__c': {
                    'read': true,
                    'write': false
                },
                'Worker_Mobile_OS_Android_iOS__c': {
                    'read': true,
                    'write': false
                },
                'CreatedDate': {
                    'read': true,
                    'write': false
                }
            },
            'purchase-order': {
                'Work_Order_num__c': {
                    'read': true,
                    'write': false
                }
            },
            'job': {
                'Iron_Job_num__c': {
                    'read': true,
                    'write': false
                }
            },
            'worker': {
                'Name': {
                    'read': true,
                    'write': false
                }
            }
        },

        'billing': {
            'comment': {
                'messageType': {
                    'read': false,
                    'write': false
                },
                'Case_Comment_Body__c': {
                    'read': false,
                    'write': false
                },
                'Is_Public_Job_c': {
                    'read': false,
                    'write': false
                },
                'Is_Public_c': {
                    'read': false,
                    'write': false
                }
            }
        }
    },
    'public_agency': {
        'summary': {
            'job': {
                'Dispatch_Service_Resolution_Status__c': {
                    'read': true,
                    'write': false
                },
                'Customer_Service_Type_From_Program__c': {
                    'read': true,
                    'write': false
                },
                'Technical_Level__c': {
                    'read': true,
                    'write': false
                },
                'Service_Dispatch_SLA_Priority_FrmProgram__c': {
                    'read': true,
                    'write': false
                },
                'csum__c': {
                    'read': true,
                    'write': false
                },
                'CKSW_BASE__Description__c': {
                    'read': true,
                    'write': false
                },
                'Special_Instruction_from_PMS_Case_Auto__c': {
                    'read': true,
                    'write': false
                },
                'Asset_Model__c': {
                    'read': true,
                    'write': false
                },
                'Custom_work_order_instructions__c': {
                    'read': true,
                    'write': false
                },
                'Jobsite_Contact_SDesk_Name_phone_email__c': {
                    'read': true,
                    'write': false
                },
                'Jobsite_Name__c': {
                    'read': true,
                    'write': false
                },
                'Partner_Case_Number__c': {
                    'read': true,
                    'write': false
                },
                'Worker_Arrival_DateTime_Cust_Requested__c': {
                    'read': true,
                    'write': false
                },
                'Does_this_Job_require_Service_Parts__c': {
                    'read': true,
                    'write': false
                },
                'Service_Parts_Local_Pickup_Required__c': {
                    'read': true,
                    'write': false
                },
                'Does_this_Job_require_New_Equip_Hardware__c': {
                    'read': true,
                    'write': false
                },
                'Equipment_Local_Pickup_Required__c': {
                    'read': true,
                    'write': false
                },
                'Equipment_Delivery_Date_ETA__c': {
                    'read': true,
                    'write': false
                },
                'Equipment_Delivery_Time_ETA_Hours__c': {
                    'read': true,
                    'write': false
                },
                'Equipment_Tracking__c': {
                    'read': true,
                    'write': false
                },
                'Iron_Case__c': {
                    'read': false,
                    'write': false
                },
                'Jobsite_Contact_Technical_Escalation__c': {
                    'read': true,
                    'write': false
                },
                'SOW_Work_Order__c': {
                    'read': true,
                    'write': false
                },
                'Job_Instruction_Details_Long__c': {
                    'read': true,
                    'write': false
                }
            },
            'project': {
                'Project__c': {
                    'read': false,
                    'write': false
                },
            },
            'appointment': {
                'Worker_Arrival_DateTime_Cust_Requested__c': {
                    'read': true,
                    'write': false
                },
                'Customer_Appointment_DateTime_Scheduled__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Arrival_DateTime_Scheduled__c': {
                    'read': true,
                    'write': true
                },
                'Max_Hours_Units__c': {
                    'read': true,
                    'write': false
                }
            },
            'work-order': {
                'Name': {
                    'read': true,
                    'write': false
                }
            },
            'worker': {
                'Name': {
                    'read': true,
                    'write': true
                }
            }
        },

        'manager': {
            'job': {
                'Job_Status_Internal__c': {
                    'read': true,
                    'write': false
                },
                'Dispatch_Service_Resolution_Status__c': {
                    'read': true,
                    'write': false
                },
                'Service_Parts_Local_Pickup_Required__c': {
                    'read': true,
                    'write': false
                },
                'Appointment_Schedule_Status_Customer__c': {
                    'read': true,
                    'write': false
                },
                'Appointment_Schedule_Status_Customer_vms__c': {
                    'read': true,
                    'write': true
                },
                'Phone_Scheduling_1st_Attempt_Unreachable__c': {
                    'read': true,
                    'write': true
                },
                'Phone_Scheduling_2nd_Attempt_Unreachable__c': {
                    'read': true,
                    'write': true
                },
                'Phone_Scheduling_3rd_Attempt_Unreachable__c': {
                    'read': true,
                    'write': true
                },
                'Appointment_Call_Notes__c': {
                    'read': true,
                    'write': true
                },
                'Dispatch_Worker_Name__c': {
                    'read': true,
                    'write': true
                },
                'Dispatch_Worker_Phone': {
                    'read': true,
                    'write': true
                },
                'CKSW_BASE__Incomplete_reason__c': {
                    'read': true,
                    'write': true
                },
                'CKSW_BASE__Other_Incomplete_Reason__c': {
                    'read': true,
                    'write': true
                }
            },
            'appointment': {
                'Customer_Appointment_Setup_Required__c': {
                    'read': true,
                    'write': false
                },
                'Worker_Arrival_DateTime_Cust_Requested__c': {
                    'read': true,
                    'write': false
                },
                'Worker_Arrival_Date_Customer_Req_End__c': {
                    'read': true,
                    'write': false
                },
                'Customer_Appointment_DateTime_Scheduled__c': {
                    'read': true,
                    'write': true
                },
                'Customer_Appointment_Start_Scheduled__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Arrival_DateTime_Scheduled__c': {
                    'read': true,
                    'write': true
                },
                'Worker_End_DateTime_Scheduled__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Departure_Date_Time_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Arrival_Date_Time_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Worker_InProgress_Start_DateTime_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Finish_Date_Time_Actual__c': {
                    'read': true,
                    'write': true
                }
            },
            'message': {
                'Is_Public__c': {
                    'read': true,
                    'write': true
                },
                'Is_Public_Job__c': {
                    'read': true,
                    'write': true
                },
                'contentText': {
                    'read': true,
                    'write': true
                }
            }
        },

        'worker-status': {
            'job': {
                'Service_Parts_Local_Pickup_Required__c': {
                    'read': true,
                    'write': false
                },
                'Worker_Departure_Date_Time_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Arrival_Date_Time_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Worker_InProgress_Start_DateTime_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Finish_Date_Time_Actual__c': {
                    'read': true,
                    'write': true
                },
            },
            'deliverable': {
                'Name': {
                    'read': true,
                    'write': true
                },
                'Deliverable_Type__c': {
                    'read': true,
                    'write': true
                },
                'File_upload_required__c': {
                    'read': true,
                    'write': true
                },
                'Description__c': {
                    'read': true,
                    'write': true
                },
                'Instructions__c': {
                    'read': true,
                    'write': true
                },
                'Deliverable_Status__c': {
                    'read': true,
                    'write': true
                },
            },
            'timecard': {
                'vendorName': {
                    'read': true,
                    'write': true
                },
                'Name': {
                    'read': true,
                    'write': false
                },
                'Work_Order_num__c': {
                    'read': true,
                    'write': false
                },
                'Iron_Job_num__c': {
                    'read': true,
                    'write': true
                },
                'Final_Timecard': {
                    'read': true,
                    'write': true
                },
                'Customer_Approval_Status__c': {
                    'read': true,
                    'write': true
                },
                'Visit_Number_Calc__c': {
                    'read': true,
                    'write': true
                },
                'Vendor_Time_Card_Time_in_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Vendor_Time_Card_Time_Out_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Total_Worked_Hours_Without_Offset__c': {
                    'read': true,
                    'write': true
                },
                'workerName': {
                    'read': true,
                    'write': true
                },
                'Customer_Site_Signoff_Name__c': {
                    'read': true,
                    'write': true
                },
                'Equipment_Serial_Performed_on__c': {
                    'read': true,
                    'write': true
                },
                'Equipment_Make_Model_Performed_on__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Mobile_OS_Android_iOS__c': {
                    'read': true,
                    'write': true
                },
                'CreatedDate': {
                    'read': true,
                    'write': false
                }
            },
            'comment': {
                'Is_Public__c': {
                    'read': true,
                    'write': true
                },
                'Is_Public_Job__c': {
                    'read': true,
                    'write': true
                },
                'contentText': {
                    'read': true,
                    'write': true
                }
            }
        },

        'message': {
            'user': {
                'profileImage': {
                    'read': true,
                    'write': false
                }
            },
            'comment': {
                'postByName': {
                    'read': true,
                    'write': false
                },
                'messageType': {
                    'read': true,
                    'write': true
                },
                'createdAt': {
                    'read': true,
                    'write': false
                },
                'Case_Comment_Body__c': {
                    'read': true,
                    'write': true
                },
                'Is_Public_Job_c': {
                    'read': true,
                    'write': true
                },
                'Is_Public_c': {
                    'read': true,
                    'write': true
                }
            }
        },

        'timecard': {
            'account': {
                'Name': {
                    'read': true,
                    'write': true
                }
            },
            'timecard': {
                'Name': {
                    'read': true,
                    'write': false
                },
                'Final_Timecard': {
                    'read': true,
                    'write': true
                },
                'Customer_Approval_Status__c': {
                    'read': true,
                    'write': true
                },
                'Visit_Number_Calc__c': {
                    'read': true,
                    'write': false
                },
                'Vendor_Time_Card_Time_in_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Vendor_Time_Card_Time_Out_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Total_Worked_Hours_Without_Offset__c': {
                    'read': true,
                    'write': true
                },
                'Customer_Site_Signoff_Name__c': {
                    'read': true,
                    'write': true
                },
                'Equipment_Serial_Performed_on__c': {
                    'read': true,
                    'write': true
                },
                'Equipment_Make_Model_Performed_on__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Mobile_OS_Android_iOS__c': {
                    'read': true,
                    'write': true
                },
                'CreatedDate': {
                    'read': true,
                    'write': false
                }
            },
            'purchase-order': {
                'Work_Order_num__c': {
                    'read': true,
                    'write': false
                }
            },
            'job': {
                'Iron_Job_num__c': {
                    'read': true,
                    'write': true
                }
            },
            'worker': {
                'Name': {
                    'read': true,
                    'write': true
                }
            }
        },

        'billing': {
            'comment': {
                'messageType': {
                    'read': true,
                    'write': true
                },
                'Case_Comment_Body__c': {
                    'read': true,
                    'write': true
                },
                'Is_Public_Job_c': {
                    'read': true,
                    'write': true
                },
                'Is_Public_c': {
                    'read': true,
                    'write': true
                }
            }
        }
    },
    'public_worker': {
        'summary': {
            'job': {
                'Dispatch_Service_Resolution_Status__c': {
                    'read': true,
                    'write': false
                },
                'Customer_Service_Type_From_Program__c': {
                    'read': true,
                    'write': false
                },
                'Technical_Level__c': {
                    'read': true,
                    'write': false
                },
                'Service_Dispatch_SLA_Priority_FrmProgram__c': {
                    'read': true,
                    'write': false
                },
                'csum__c': {
                    'read': true,
                    'write': false
                },
                'CKSW_BASE__Description__c': {
                    'read': true,
                    'write': false
                },
                'Special_Instruction_from_PMS_Case_Auto__c': {
                    'read': true,
                    'write': true
                },
                'Asset_Model__c': {
                    'read': true,
                    'write': false
                },
                'Custom_work_order_instructions__c': {
                    'read': true,
                    'write': false
                },
                'Jobsite_Contact_SDesk_Name_phone_email__c': {
                    'read': true,
                    'write': false
                },
                'Jobsite_Name__c': {
                    'read': true,
                    'write': false
                },
                'Partner_Case_Number__c': {
                    'read': true,
                    'write': false
                },
                'Worker_Arrival_DateTime_Cust_Requested__c': {
                    'read': true,
                    'write': false
                },
                'Does_this_Job_require_Service_Parts__c': {
                    'read': true,
                    'write': false
                },
                'Service_Parts_Local_Pickup_Required__c': {
                    'read': true,
                    'write': false
                },
                'Does_this_Job_require_New_Equip_Hardware__c': {
                    'read': true,
                    'write': false
                },
                'Equipment_Local_Pickup_Required__c': {
                    'read': true,
                    'write': false
                },
                'Equipment_Delivery_Date_ETA__c': {
                    'read': true,
                    'write': false
                },
                'Equipment_Delivery_Time_ETA_Hours__c': {
                    'read': true,
                    'write': false
                },
                'Equipment_Tracking__c': {
                    'read': true,
                    'write': false
                },
                'Iron_Case__c': {
                    'read': false,
                    'write': false
                },
                'Jobsite_Contact_Technical_Escalation__c': {
                    'read': true,
                    'write': false
                },
                'SOW_Work_Order__c': {
                    'read': true,
                    'write': false
                },
                'Job_Instruction_Details_Long__c': {
                    'read': true,
                    'write': false
                }
            },
            'project': {
                'Project__c': {
                    'read': false,
                    'write': false
                },
            },
            'appointment': {
                'Worker_Arrival_DateTime_Cust_Requested__c': {
                    'read': true,
                    'write': false
                },
                'Customer_Appointment_DateTime_Scheduled__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Arrival_DateTime_Scheduled__c': {
                    'read': true,
                    'write': true
                },
                'Max_Hours_Units__c': {
                    'read': true,
                    'write': false
                }
            },
            'work-order': {
                'Name': {
                    'read': true,
                    'write': false
                }
            },
            'worker': {
                'Name': {
                    'read': true,
                    'write': true
                }
            }
        },

        'manager': {
            'job': {
                'Job_Status_Internal__c': {
                    'read': true,
                    'write': false
                },
                'Dispatch_Service_Resolution_Status__c': {
                    'read': true,
                    'write': false
                },
                'Service_Parts_Local_Pickup_Required__c': {
                    'read': true,
                    'write': false
                },
                'Appointment_Schedule_Status_Customer__c': {
                    'read': true,
                    'write': false
                },
                'Appointment_Schedule_Status_Customer_vms__c': {
                    'read': true,
                    'write': true
                },
                'Phone_Scheduling_1st_Attempt_Unreachable__c': {
                    'read': true,
                    'write': true
                },
                'Phone_Scheduling_2nd_Attempt_Unreachable__c': {
                    'read': true,
                    'write': true
                },
                'Phone_Scheduling_3rd_Attempt_Unreachable__c': {
                    'read': true,
                    'write': true
                },
                'Appointment_Call_Notes__c': {
                    'read': true,
                    'write': true
                },
                'Dispatch_Worker_Name__c': {
                    'read': true,
                    'write': true
                },
                'Dispatch_Worker_Phone': {
                    'read': true,
                    'write': true
                },
                'CKSW_BASE__Incomplete_reason__c': {
                    'read': true,
                    'write': true
                },
                'CKSW_BASE__Other_Incomplete_Reason__c': {
                    'read': true,
                    'write': true
                }
            },
            'appointment': {
                'Customer_Appointment_Setup_Required__c': {
                    'read': true,
                    'write': false
                },
                'Worker_Arrival_DateTime_Cust_Requested__c': {
                    'read': true,
                    'write': false
                },
                'Worker_Arrival_Date_Customer_Req_End__c': {
                    'read': true,
                    'write': false
                },
                'Customer_Appointment_DateTime_Scheduled__c': {
                    'read': true,
                    'write': true
                },
                'Customer_Appointment_Start_Scheduled__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Arrival_DateTime_Scheduled__c': {
                    'read': true,
                    'write': true
                },
                'Worker_End_DateTime_Scheduled__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Departure_Date_Time_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Arrival_Date_Time_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Worker_InProgress_Start_DateTime_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Finish_Date_Time_Actual__c': {
                    'read': true,
                    'write': true
                }
            },
            'message': {
                'Is_Public__c': {
                    'read': true,
                    'write': true
                },
                'Is_Public_Job__c': {
                    'read': true,
                    'write': true
                },
                'contentText': {
                    'read': true,
                    'write': true
                }
            }
        },

        'worker-status': {
            'job': {
                'Service_Parts_Local_Pickup_Required__c': {
                    'read': true,
                    'write': false
                },
                'Worker_Departure_Date_Time_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Arrival_Date_Time_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Worker_InProgress_Start_DateTime_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Finish_Date_Time_Actual__c': {
                    'read': true,
                    'write': true
                },
            },
            'deliverable': {
                'Name': {
                    'read': true,
                    'write': true
                },
                'Deliverable_Type__c': {
                    'read': true,
                    'write': true
                },
                'File_upload_required__c': {
                    'read': true,
                    'write': true
                },
                'Description__c': {
                    'read': true,
                    'write': true
                },
                'Instructions__c': {
                    'read': true,
                    'write': true
                },
                'Deliverable_Status__c': {
                    'read': true,
                    'write': true
                },
            },
            'timecard': {
                'vendorName': {
                    'read': true,
                    'write': true
                },
                'Name': {
                    'read': true,
                    'write': false
                },
                'Work_Order_num__c': {
                    'read': true,
                    'write': false
                },
                'Iron_Job_num__c': {
                    'read': true,
                    'write': true
                },
                'Final_Timecard': {
                    'read': true,
                    'write': true
                },
                'Customer_Approval_Status__c': {
                    'read': true,
                    'write': true
                },
                'Visit_Number_Calc__c': {
                    'read': true,
                    'write': true
                },
                'Vendor_Time_Card_Time_in_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Vendor_Time_Card_Time_Out_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Total_Worked_Hours_Without_Offset__c': {
                    'read': true,
                    'write': true
                },
                'workerName': {
                    'read': true,
                    'write': true
                },
                'Customer_Site_Signoff_Name__c': {
                    'read': true,
                    'write': true
                },
                'Equipment_Serial_Performed_on__c': {
                    'read': true,
                    'write': true
                },
                'Equipment_Make_Model_Performed_on__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Mobile_OS_Android_iOS__c': {
                    'read': true,
                    'write': true
                },
                'CreatedDate': {
                    'read': true,
                    'write': false
                }
            },
            'comment': {
                'Is_Public__c': {
                    'read': true,
                    'write': true
                },
                'Is_Public_Job__c': {
                    'read': true,
                    'write': true
                },
                'contentText': {
                    'read': true,
                    'write': true
                }
            }
        },

        'message': {
            'user': {
                'profileImage': {
                    'read': true,
                    'write': false
                }
            },
            'comment': {
                'postByName': {
                    'read': true,
                    'write': false
                },
                'messageType': {
                    'read': true,
                    'write': true
                },
                'createdAt': {
                    'read': true,
                    'write': false
                },
                'Case_Comment_Body__c': {
                    'read': true,
                    'write': true
                },
                'Is_Public_Job_c': {
                    'read': true,
                    'write': true
                },
                'Is_Public_c': {
                    'read': true,
                    'write': true
                }
            }
        },

        'timecard': {
            'account': {
                'Name': {
                    'read': true,
                    'write': true
                }
            },
            'timecard': {
                'Name': {
                    'read': true,
                    'write': false
                },
                'Final_Timecard': {
                    'read': true,
                    'write': true
                },
                'Customer_Approval_Status__c': {
                    'read': true,
                    'write': true
                },
                'Visit_Number_Calc__c': {
                    'read': true,
                    'write': false
                },
                'Vendor_Time_Card_Time_in_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Vendor_Time_Card_Time_Out_Actual__c': {
                    'read': true,
                    'write': true
                },
                'Total_Worked_Hours_Without_Offset__c': {
                    'read': true,
                    'write': true
                },
                'Customer_Site_Signoff_Name__c': {
                    'read': true,
                    'write': true
                },
                'Equipment_Serial_Performed_on__c': {
                    'read': true,
                    'write': true
                },
                'Equipment_Make_Model_Performed_on__c': {
                    'read': true,
                    'write': true
                },
                'Worker_Mobile_OS_Android_iOS__c': {
                    'read': true,
                    'write': true
                },
                'CreatedDate': {
                    'read': true,
                    'write': false
                }
            },
            'purchase-order': {
                'Work_Order_num__c': {
                    'read': true,
                    'write': false
                }
            },
            'job': {
                'Iron_Job_num__c': {
                    'read': true,
                    'write': true
                }
            },
            'worker': {
                'Name': {
                    'read': true,
                    'write': true
                }
            }
        },

        'billing': {
            'comment': {
                'messageType': {
                    'read': false,
                    'write': false
                },
                'Case_Comment_Body__c': {
                    'read': false,
                    'write': false
                },
                'Is_Public_Job_c': {
                    'read': false,
                    'write': false
                },
                'Is_Public_c': {
                    'read': false,
                    'write': false
                }
            }
        }
    },
    'public_anonymous': {
        'summary': {
            'job': {
                'Dispatch_Service_Resolution_Status__c': {
                    'read': true,
                    'write': false
                },
                'Customer_Service_Type_From_Program__c': {
                    'read': true,
                    'write': false
                },
                'Technical_Level__c': {
                    'read': true,
                    'write': false
                },
                'Service_Dispatch_SLA_Priority_FrmProgram__c': {
                    'read': true,
                    'write': false
                },
                'csum__c': {
                    'read': true,
                    'write': false
                },
                'CKSW_BASE__Description__c': {
                    'read': true,
                    'write': false
                },
                'Special_Instruction_from_PMS_Case_Auto__c': {
                    'read': true,
                    'write': false
                },
                'Asset_Model__c': {
                    'read': true,
                    'write': false
                },
                'Custom_work_order_instructions__c': {
                    'read': true,
                    'write': false
                },
                'Jobsite_Contact_SDesk_Name_phone_email__c': {
                    'read': true,
                    'write': false
                },
                'Jobsite_Name__c': {
                    'read': true,
                    'write': false
                },
                'Partner_Case_Number__c': {
                    'read': true,
                    'write': false
                },
                'Worker_Arrival_DateTime_Cust_Requested__c': {
                    'read': true,
                    'write': false
                },
                'Does_this_Job_require_Service_Parts__c': {
                    'read': true,
                    'write': false
                },
                'Service_Parts_Local_Pickup_Required__c': {
                    'read': true,
                    'write': false
                },
                'Does_this_Job_require_New_Equip_Hardware__c': {
                    'read': true,
                    'write': false
                },
                'Equipment_Local_Pickup_Required__c': {
                    'read': true,
                    'write': false
                },
                'Equipment_Delivery_Date_ETA__c': {
                    'read': true,
                    'write': false
                },
                'Equipment_Delivery_Time_ETA_Hours__c': {
                    'read': true,
                    'write': false
                },
                'Equipment_Tracking__c': {
                    'read': true,
                    'write': false
                },
                'Iron_Case__c': {
                    'read': false,
                    'write': false
                },
                'Jobsite_Contact_Technical_Escalation__c': {
                    'read': true,
                    'write': false
                },
                'SOW_Work_Order__c': {
                    'read': true,
                    'write': false
                },
                'Job_Instruction_Details_Long__c': {
                    'read': true,
                    'write': false
                }
            },
            'project': {
                'Project__c': {
                    'read': false,
                    'write': false
                },
            },
            'appointment': {
                'Worker_Arrival_DateTime_Cust_Requested__c': {
                    'read': true,
                    'write': false
                },
                'Customer_Appointment_DateTime_Scheduled__c': {
                    'read': true,
                    'write': false
                },
                'Worker_Arrival_DateTime_Scheduled__c': {
                    'read': true,
                    'write': false
                },
                'Max_Hours_Units__c': {
                    'read': true,
                    'write': false
                }
            },
            'work-order': {
                'Name': {
                    'read': true,
                    'write': false
                }
            },
            'worker': {
                'Name': {
                    'read': true,
                    'write': false
                }
            }
        },

        'manager': {
            'job': {
                'Job_Status_Internal__c': {
                    'read': false,
                    'write': false
                },
                'Dispatch_Service_Resolution_Status__c': {
                    'read': false,
                    'write': false
                },
                'Service_Parts_Local_Pickup_Required__c': {
                    'read': false,
                    'write': false
                },
                'Appointment_Schedule_Status_Customer__c': {
                    'read': false,
                    'write': false
                },
                'Appointment_Schedule_Status_Customer_vms__c': {
                    'read': false,
                    'write': false
                },
                'Phone_Scheduling_1st_Attempt_Unreachable__c': {
                    'read': false,
                    'write': false
                },
                'Phone_Scheduling_2nd_Attempt_Unreachable__c': {
                    'read': false,
                    'write': false
                },
                'Phone_Scheduling_3rd_Attempt_Unreachable__c': {
                    'read': false,
                    'write': false
                },
                'Appointment_Call_Notes__c': {
                    'read': false,
                    'write': false
                },
                'Dispatch_Worker_Name__c': {
                    'read': false,
                    'write': false
                },
                'Dispatch_Worker_Phone': {
                    'read': false,
                    'write': false
                },
                'CKSW_BASE__Incomplete_reason__c': {
                    'read': false,
                    'write': false
                },
                'CKSW_BASE__Other_Incomplete_Reason__c': {
                    'read': false,
                    'write': false
                }
            },
            'appointment': {
                'Customer_Appointment_Setup_Required__c': {
                    'read': false,
                    'write': false
                },
                'Worker_Arrival_DateTime_Cust_Requested__c': {
                    'read': false,
                    'write': false
                },
                'Worker_Arrival_Date_Customer_Req_End__c': {
                    'read': false,
                    'write': false
                },
                'Customer_Appointment_DateTime_Scheduled__c': {
                    'read': false,
                    'write': false
                },
                'Customer_Appointment_Start_Scheduled__c': {
                    'read': false,
                    'write': false
                },
                'Worker_Arrival_DateTime_Scheduled__c': {
                    'read': false,
                    'write': false
                },
                'Worker_End_DateTime_Scheduled__c': {
                    'read': false,
                    'write': false
                },
                'Worker_Departure_Date_Time_Actual__c': {
                    'read': false,
                    'write': false
                },
                'Worker_Arrival_Date_Time_Actual__c': {
                    'read': false,
                    'write': false
                },
                'Worker_InProgress_Start_DateTime_Actual__c': {
                    'read': false,
                    'write': false
                },
                'Worker_Finish_Date_Time_Actual__c': {
                    'read': false,
                    'write': false
                }
            },
            'message': {
                'Is_Public__c': {
                    'read': false,
                    'write': false
                },
                'Is_Public_Job__c': {
                    'read': false,
                    'write': false
                },
                'contentText': {
                    'read': false,
                    'write': false
                }
            }
        },

        'worker-status': {
            'job': {
                'Service_Parts_Local_Pickup_Required__c': {
                    'read': false,
                    'write': false
                },
                'Worker_Departure_Date_Time_Actual__c': {
                    'read': false,
                    'write': false
                },
                'Worker_Arrival_Date_Time_Actual__c': {
                    'read': false,
                    'write': false
                },
                'Worker_InProgress_Start_DateTime_Actual__c': {
                    'read': false,
                    'write': false
                },
                'Worker_Finish_Date_Time_Actual__c': {
                    'read': false,
                    'write': false
                },
            },
            'deliverable': {
                'Name': {
                    'read': false,
                    'write': false
                },
                'Deliverable_Type__c': {
                    'read': false,
                    'write': false
                },
                'File_upload_required__c': {
                    'read': false,
                    'write': false
                },
                'Description__c': {
                    'read': false,
                    'write': false
                },
                'Instructions__c': {
                    'read': false,
                    'write': false
                },
                'Deliverable_Status__c': {
                    'read': false,
                    'write': false
                },
            },
            'timecard': {
                'vendorName': {
                    'read': false,
                    'write': false
                },
                'Name': {
                    'read': false,
                    'write': false
                },
                'Work_Order_num__c': {
                    'read': false,
                    'write': false
                },
                'Iron_Job_num__c': {
                    'read': false,
                    'write': false
                },
                'Final_Timecard': {
                    'read': false,
                    'write': false
                },
                'Customer_Approval_Status__c': {
                    'read': false,
                    'write': false
                },
                'Visit_Number_Calc__c': {
                    'read': false,
                    'write': false
                },
                'Vendor_Time_Card_Time_in_Actual__c': {
                    'read': false,
                    'write': false
                },
                'Vendor_Time_Card_Time_Out_Actual__c': {
                    'read': false,
                    'write': false
                },
                'Total_Worked_Hours_Without_Offset__c': {
                    'read': false,
                    'write': false
                },
                'workerName': {
                    'read': false,
                    'write': false
                },
                'Customer_Site_Signoff_Name__c': {
                    'read': false,
                    'write': false
                },
                'Equipment_Serial_Performed_on__c': {
                    'read': false,
                    'write': false
                },
                'Equipment_Make_Model_Performed_on__c': {
                    'read': false,
                    'write': false
                },
                'Worker_Mobile_OS_Android_iOS__c': {
                    'read': false,
                    'write': false
                },
                'CreatedDate': {
                    'read': false,
                    'write': false
                }
            },
            'comment': {
                'Is_Public__c': {
                    'read': false,
                    'write': false
                },
                'Is_Public_Job__c': {
                    'read': false,
                    'write': false
                },
                'contentText': {
                    'read': false,
                    'write': false
                }
            }
        },

        'message': {
            'user': {
                'profileImage': {
                    'read': false,
                    'write': false
                }
            },
            'comment': {
                'postByName': {
                    'read': false,
                    'write': false
                },
                'messageType': {
                    'read': false,
                    'write': false
                },
                'createdAt': {
                    'read': false,
                    'write': false
                },
                'Case_Comment_Body__c': {
                    'read': false,
                    'write': false
                },
                'Is_Public_Job_c': {
                    'read': false,
                    'write': false
                },
                'Is_Public_c': {
                    'read': false,
                    'write': false
                }
            }
        },

        'timecard': {
            'account': {
                'Name': {
                    'read': false,
                    'write': false
                }
            },
            'timecard': {
                'Name': {
                    'read': false,
                    'write': false
                },
                'Final_Timecard': {
                    'read': false,
                    'write': false
                },
                'Customer_Approval_Status__c': {
                    'read': false,
                    'write': false
                },
                'Visit_Number_Calc__c': {
                    'read': false,
                    'write': false
                },
                'Vendor_Time_Card_Time_in_Actual__c': {
                    'read': false,
                    'write': false
                },
                'Vendor_Time_Card_Time_Out_Actual__c': {
                    'read': false,
                    'write': false
                },
                'Total_Worked_Hours_Without_Offset__c': {
                    'read': false,
                    'write': false
                },
                'Customer_Site_Signoff_Name__c': {
                    'read': false,
                    'write': false
                },
                'Equipment_Serial_Performed_on__c': {
                    'read': false,
                    'write': false
                },
                'Equipment_Make_Model_Performed_on__c': {
                    'read': false,
                    'write': false
                },
                'Worker_Mobile_OS_Android_iOS__c': {
                    'read': false,
                    'write': false
                },
                'CreatedDate': {
                    'read': false,
                    'write': false
                }
            },
            'purchase-order': {
                'Work_Order_num__c': {
                    'read': false,
                    'write': false
                }
            },
            'job': {
                'Iron_Job_num__c': {
                    'read': false,
                    'write': false
                }
            },
            'worker': {
                'Name': {
                    'read': false,
                    'write': false
                }
            }
        },

        'billing': {
            'comment': {
                'messageType': {
                    'read': false,
                    'write': false
                },
                'Case_Comment_Body__c': {
                    'read': false,
                    'write': false
                },
                'Is_Public_Job_c': {
                    'read': false,
                    'write': false
                },
                'Is_Public_c': {
                    'read': false,
                    'write': false
                }
            }
        }
    }
};
