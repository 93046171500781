import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { weeklyDateRangeList } from '../../../models/static-list-data.service'
@Component({
  selector: 'app-weekly-date-range-lookup',
  templateUrl: './weekly-date-range-lookup.component.html',
  styleUrls: ['./weekly-date-range-lookup.component.css']
})
export class WeeklyDateRangeLookupComponent implements OnInit {
  weeklyDateRangeList = [];
  weeklyDateRangeelected = [];
  isMultipleSelected = true;
  @Input() set resetData(isClear) {
    if (isClear) {
      this.weeklyDateRangeelected = [];
    }
  }
  @Input() maxSelectedItems = 1;
  @Input() disable ;
  @Input() from;
  @Input() selectedDefaultValue;
  @Output() selectedWeeklyDateRange: EventEmitter<any> = new EventEmitter;
  constructor() { }

  ngOnInit() {
    this.weeklyDateRangeList = [...weeklyDateRangeList];
    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : ''
    this.weeklyDateRangeelected = (preselected && preselected['weeklyDateOption']) ? preselected['weeklyDateOption'] : ['Current Week'];
  }

  onWeeklyDateRangeChange() {
    this.selectedWeeklyDateRange.emit(this.weeklyDateRangeelected);
  }

  clearWeeklyDateRange() {
    this.weeklyDateRangeelected = [];
    this.selectedWeeklyDateRange.emit([]);
  }

}
