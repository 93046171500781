import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MaintenanceScheduleInterface } from 'shared/sdk';
import { MaintenanceScheduleApi } from 'shared/sdk/services/custom/MaintenanceSchedule';
import { CommonService } from 'shared/services/common.service';

@Component({
  selector: 'app-maintenance-schedule',
  templateUrl: './maintenance-schedule.component.html',
  styleUrls: ['./maintenance-schedule.component.css']
})
export class MaintenanceScheduleComponent implements OnInit {

  public schedule: MaintenanceScheduleInterface;

  @Output() cancel: EventEmitter<boolean> = new EventEmitter;

  constructor(private _maintenanceApi: MaintenanceScheduleApi, public commonService: CommonService) { }

  ngOnInit() {
    this.getScheduleData();
  }

  getScheduleData() {
    var filter = {
      where: {
          isActive: true,
          isEnabled: true,
          endDate: {gt: new Date()}
      },
      order: "startDate ASC",
      limit: 1
  }
    this._maintenanceApi.find(filter).subscribe(res => {
      if (res && res.length && Object.keys(res[0]).length)
      this.schedule = res[0];
    });
  }

  onClose() {
    localStorage.setItem('maintenanceModalViewed', 'true');
    this.cancel.emit(true);
  }

}
