/* tslint:disable */
import {
  Account
} from '../index';

declare var Object: any;
export interface ContractInterface {
  "Name"?: string;
  "sfdcId"?: string;
  "AccountId"?: string;
  "ActivatedById"?: string;
  "ActivatedDate"?: Date;
  "AlternateContractReferance__c"?: string;
  "Asset_and_FRU_Group__c"?: string;
  "BillingAddress"?: string;
  "BillingCity"?: string;
  "BillingCountry"?: string;
  "BillingGeocodeAccuracy"?: string;
  "BillingLatitude"?: number;
  "BillingLongitude"?: number;
  "BillingPostalCode"?: string;
  "BillingState"?: string;
  "BillingStreet"?: string;
  "CompanySignedDate"?: Date;
  "CompanySignedId"?: string;
  "ContractNumber"?: string;
  "ContractTerm"?: number;
  "Contract_Days_Left__c"?: number;
  "Contract_End_Date_YYYY_MM_DD__c"?: string;
  "Contract_End_Date__c"?: Date;
  "Contract_Ownership__c"?: string;
  "Contract_Term_months__c"?: number;
  "Contracts_Reference_SFDC__c"?: string;
  "CreatedById"?: string;
  "CreatedDate"?: Date;
  "CurrencyIsoCode"?: string;
  "CustomerSignedDate"?: Date;
  "CustomerSignedId"?: string;
  "CustomerSignedTitle"?: string;
  "Description"?: string;
  "EndDate"?: Date;
  "FSLLocation__c"?: string;
  "FSL_Location_Ready__c"?: boolean;
  "FSL_Location_SLA_Supported_Setup__c"?: string;
  "IRON_Contract_End_Date_YYYY_MM_DD__c"?: string;
  "IRON_Contract_End_Date__c"?: Date;
  "IRON_Contract_Start_Date_YYYY_MM_DD__c"?: string;
  "IRON_Contract_Start_Date__c"?: Date;
  "IRON_Contract_TermsDate__c"?: number;
  "Initiate_Migration__c"?: boolean;
  "Install_Base_Distanc_Under_100_Miles__c"?: string;
  "Install_Base_Distanc_Under_50_Miles__c"?: string;
  "IsDeleted"?: boolean;
  "LastActivityDate"?: Date;
  "LastApprovedDate"?: Date;
  "LastModifiedById"?: string;
  "LastModifiedDate"?: Date;
  "LastReferencedDate"?: Date;
  "LastViewedDate"?: string;
  "Migrated__c"?: boolean;
  "OwnerExpirationNotice"?: string;
  "OwnerId"?: string;
  "PS_Vendor_Contract_Start_Date_YY_MM_DD__c"?: string;
  "Partner_Account__c"?: string;
  "Partner_Contract__c"?: string;
  "Partner_Renewal_Contract_PO__c"?: string;
  "Partner_Status__c"?: string;
  "Primary_Asset_For_Migration__c"?: string;
  "Service_Contract__c"?: string;
  "Service_SKU__c"?: string;
  "Service_Type__c"?: string;
  "ShippingAddress"?: string;
  "ShippingCity"?: string;
  "ShippingCountry"?: string;
  "ShippingGeocodeAccuracy"?: string;
  "ShippingLatitude"?: number;
  "ShippingLongitude"?: number;
  "ShippingPostalCode"?: string;
  "ShippingState"?: string;
  "ShippingStreet"?: string;
  "SpecialTerms"?: string;
  "StartDate"?: Date;
  "StartDate_YYYY_MM_DD__c"?: string;
  "Status"?: string;
  "StatusCode"?: string;
  "Sub_Inventory_Location_WSL__c"?: string;
  "Sub_Inventory_Location__c"?: string;
  "SystemModstamp"?: Date;
  "Temp_Flag__c"?: boolean;
  "WSL_Location_Ready__c"?: boolean;
  "Warranty_SLA_3PL_FRU_POD__c"?: number;
  "Warranty_SLA_3PS_FE_Arrival__c"?: number;
  "Warranty_Status__c"?: string;
  "Warranty_Support_Status__c"?: string;
  "Warranty_Term_in_Month__c"?: number;
  "Warranty_Type__c"?: string;
  "Warranty__c"?: string;
  "X3PL_FSL_Location_GatewayOutPut__c"?: string;
  "X3PL_FSL_Location_Gateway__c"?: string;
  "X3PL_Vendor_WSL__c"?: string;
  "X3PL_Vendor__c"?: string;
  "X3PL_WSL_Location_Default__c"?: string;
  "X3PL_WSL_Location_Gateway_WSL__c"?: string;
  "X3PSVendor__c"?: string;
  "X3PS_Vendor_Contract_End_Date_YYYY_MM_D__c"?: string;
  "X3PS_Vendor_Contract_End_Date__c"?: Date;
  "X3PS_Vendor_Contract_Reference_Number__c"?: string;
  "X3PS_Vendor_Contract_Start_Date__c"?: Date;
  "X3PS_Vendor_Contract_Terms__c"?: number;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  account?: Account;
}

export class Contract implements ContractInterface {
  "Name": string;
  "sfdcId": string;
  "AccountId": string;
  "ActivatedById": string;
  "ActivatedDate": Date;
  "AlternateContractReferance__c": string;
  "Asset_and_FRU_Group__c": string;
  "BillingAddress": string;
  "BillingCity": string;
  "BillingCountry": string;
  "BillingGeocodeAccuracy": string;
  "BillingLatitude": number;
  "BillingLongitude": number;
  "BillingPostalCode": string;
  "BillingState": string;
  "BillingStreet": string;
  "CompanySignedDate": Date;
  "CompanySignedId": string;
  "ContractNumber": string;
  "ContractTerm": number;
  "Contract_Days_Left__c": number;
  "Contract_End_Date_YYYY_MM_DD__c": string;
  "Contract_End_Date__c": Date;
  "Contract_Ownership__c": string;
  "Contract_Term_months__c": number;
  "Contracts_Reference_SFDC__c": string;
  "CreatedById": string;
  "CreatedDate": Date;
  "CurrencyIsoCode": string;
  "CustomerSignedDate": Date;
  "CustomerSignedId": string;
  "CustomerSignedTitle": string;
  "Description": string;
  "EndDate": Date;
  "FSLLocation__c": string;
  "FSL_Location_Ready__c": boolean;
  "FSL_Location_SLA_Supported_Setup__c": string;
  "IRON_Contract_End_Date_YYYY_MM_DD__c": string;
  "IRON_Contract_End_Date__c": Date;
  "IRON_Contract_Start_Date_YYYY_MM_DD__c": string;
  "IRON_Contract_Start_Date__c": Date;
  "IRON_Contract_TermsDate__c": number;
  "Initiate_Migration__c": boolean;
  "Install_Base_Distanc_Under_100_Miles__c": string;
  "Install_Base_Distanc_Under_50_Miles__c": string;
  "IsDeleted": boolean;
  "LastActivityDate": Date;
  "LastApprovedDate": Date;
  "LastModifiedById": string;
  "LastModifiedDate": Date;
  "LastReferencedDate": Date;
  "LastViewedDate": string;
  "Migrated__c": boolean;
  "OwnerExpirationNotice": string;
  "OwnerId": string;
  "PS_Vendor_Contract_Start_Date_YY_MM_DD__c": string;
  "Partner_Account__c": string;
  "Partner_Contract__c": string;
  "Partner_Renewal_Contract_PO__c": string;
  "Partner_Status__c": string;
  "Primary_Asset_For_Migration__c": string;
  "Service_Contract__c": string;
  "Service_SKU__c": string;
  "Service_Type__c": string;
  "ShippingAddress": string;
  "ShippingCity": string;
  "ShippingCountry": string;
  "ShippingGeocodeAccuracy": string;
  "ShippingLatitude": number;
  "ShippingLongitude": number;
  "ShippingPostalCode": string;
  "ShippingState": string;
  "ShippingStreet": string;
  "SpecialTerms": string;
  "StartDate": Date;
  "StartDate_YYYY_MM_DD__c": string;
  "Status": string;
  "StatusCode": string;
  "Sub_Inventory_Location_WSL__c": string;
  "Sub_Inventory_Location__c": string;
  "SystemModstamp": Date;
  "Temp_Flag__c": boolean;
  "WSL_Location_Ready__c": boolean;
  "Warranty_SLA_3PL_FRU_POD__c": number;
  "Warranty_SLA_3PS_FE_Arrival__c": number;
  "Warranty_Status__c": string;
  "Warranty_Support_Status__c": string;
  "Warranty_Term_in_Month__c": number;
  "Warranty_Type__c": string;
  "Warranty__c": string;
  "X3PL_FSL_Location_GatewayOutPut__c": string;
  "X3PL_FSL_Location_Gateway__c": string;
  "X3PL_Vendor_WSL__c": string;
  "X3PL_Vendor__c": string;
  "X3PL_WSL_Location_Default__c": string;
  "X3PL_WSL_Location_Gateway_WSL__c": string;
  "X3PSVendor__c": string;
  "X3PS_Vendor_Contract_End_Date_YYYY_MM_D__c": string;
  "X3PS_Vendor_Contract_End_Date__c": Date;
  "X3PS_Vendor_Contract_Reference_Number__c": string;
  "X3PS_Vendor_Contract_Start_Date__c": Date;
  "X3PS_Vendor_Contract_Terms__c": number;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  account: Account;
  constructor(data?: ContractInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Contract`.
   */
  public static getModelName() {
    return "Contract";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Contract for dynamic purposes.
  **/
  public static factory(data: ContractInterface): Contract{
    return new Contract(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Contract',
      plural: 'Contracts',
      path: 'Contracts',
      idName: 'id',
      properties: {
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "AccountId": {
          name: 'AccountId',
          type: 'string'
        },
        "ActivatedById": {
          name: 'ActivatedById',
          type: 'string'
        },
        "ActivatedDate": {
          name: 'ActivatedDate',
          type: 'Date'
        },
        "AlternateContractReferance__c": {
          name: 'AlternateContractReferance__c',
          type: 'string'
        },
        "Asset_and_FRU_Group__c": {
          name: 'Asset_and_FRU_Group__c',
          type: 'string'
        },
        "BillingAddress": {
          name: 'BillingAddress',
          type: 'string'
        },
        "BillingCity": {
          name: 'BillingCity',
          type: 'string'
        },
        "BillingCountry": {
          name: 'BillingCountry',
          type: 'string'
        },
        "BillingGeocodeAccuracy": {
          name: 'BillingGeocodeAccuracy',
          type: 'string'
        },
        "BillingLatitude": {
          name: 'BillingLatitude',
          type: 'number'
        },
        "BillingLongitude": {
          name: 'BillingLongitude',
          type: 'number'
        },
        "BillingPostalCode": {
          name: 'BillingPostalCode',
          type: 'string'
        },
        "BillingState": {
          name: 'BillingState',
          type: 'string'
        },
        "BillingStreet": {
          name: 'BillingStreet',
          type: 'string'
        },
        "CompanySignedDate": {
          name: 'CompanySignedDate',
          type: 'Date'
        },
        "CompanySignedId": {
          name: 'CompanySignedId',
          type: 'string'
        },
        "ContractNumber": {
          name: 'ContractNumber',
          type: 'string'
        },
        "ContractTerm": {
          name: 'ContractTerm',
          type: 'number'
        },
        "Contract_Days_Left__c": {
          name: 'Contract_Days_Left__c',
          type: 'number'
        },
        "Contract_End_Date_YYYY_MM_DD__c": {
          name: 'Contract_End_Date_YYYY_MM_DD__c',
          type: 'string'
        },
        "Contract_End_Date__c": {
          name: 'Contract_End_Date__c',
          type: 'Date'
        },
        "Contract_Ownership__c": {
          name: 'Contract_Ownership__c',
          type: 'string'
        },
        "Contract_Term_months__c": {
          name: 'Contract_Term_months__c',
          type: 'number'
        },
        "Contracts_Reference_SFDC__c": {
          name: 'Contracts_Reference_SFDC__c',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "CustomerSignedDate": {
          name: 'CustomerSignedDate',
          type: 'Date'
        },
        "CustomerSignedId": {
          name: 'CustomerSignedId',
          type: 'string'
        },
        "CustomerSignedTitle": {
          name: 'CustomerSignedTitle',
          type: 'string'
        },
        "Description": {
          name: 'Description',
          type: 'string'
        },
        "EndDate": {
          name: 'EndDate',
          type: 'Date'
        },
        "FSLLocation__c": {
          name: 'FSLLocation__c',
          type: 'string'
        },
        "FSL_Location_Ready__c": {
          name: 'FSL_Location_Ready__c',
          type: 'boolean'
        },
        "FSL_Location_SLA_Supported_Setup__c": {
          name: 'FSL_Location_SLA_Supported_Setup__c',
          type: 'string'
        },
        "IRON_Contract_End_Date_YYYY_MM_DD__c": {
          name: 'IRON_Contract_End_Date_YYYY_MM_DD__c',
          type: 'string'
        },
        "IRON_Contract_End_Date__c": {
          name: 'IRON_Contract_End_Date__c',
          type: 'Date'
        },
        "IRON_Contract_Start_Date_YYYY_MM_DD__c": {
          name: 'IRON_Contract_Start_Date_YYYY_MM_DD__c',
          type: 'string'
        },
        "IRON_Contract_Start_Date__c": {
          name: 'IRON_Contract_Start_Date__c',
          type: 'Date'
        },
        "IRON_Contract_TermsDate__c": {
          name: 'IRON_Contract_TermsDate__c',
          type: 'number'
        },
        "Initiate_Migration__c": {
          name: 'Initiate_Migration__c',
          type: 'boolean'
        },
        "Install_Base_Distanc_Under_100_Miles__c": {
          name: 'Install_Base_Distanc_Under_100_Miles__c',
          type: 'string'
        },
        "Install_Base_Distanc_Under_50_Miles__c": {
          name: 'Install_Base_Distanc_Under_50_Miles__c',
          type: 'string'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "LastActivityDate": {
          name: 'LastActivityDate',
          type: 'Date'
        },
        "LastApprovedDate": {
          name: 'LastApprovedDate',
          type: 'Date'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "LastReferencedDate": {
          name: 'LastReferencedDate',
          type: 'Date'
        },
        "LastViewedDate": {
          name: 'LastViewedDate',
          type: 'string'
        },
        "Migrated__c": {
          name: 'Migrated__c',
          type: 'boolean'
        },
        "OwnerExpirationNotice": {
          name: 'OwnerExpirationNotice',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "PS_Vendor_Contract_Start_Date_YY_MM_DD__c": {
          name: 'PS_Vendor_Contract_Start_Date_YY_MM_DD__c',
          type: 'string'
        },
        "Partner_Account__c": {
          name: 'Partner_Account__c',
          type: 'string'
        },
        "Partner_Contract__c": {
          name: 'Partner_Contract__c',
          type: 'string'
        },
        "Partner_Renewal_Contract_PO__c": {
          name: 'Partner_Renewal_Contract_PO__c',
          type: 'string'
        },
        "Partner_Status__c": {
          name: 'Partner_Status__c',
          type: 'string'
        },
        "Primary_Asset_For_Migration__c": {
          name: 'Primary_Asset_For_Migration__c',
          type: 'string'
        },
        "Service_Contract__c": {
          name: 'Service_Contract__c',
          type: 'string'
        },
        "Service_SKU__c": {
          name: 'Service_SKU__c',
          type: 'string'
        },
        "Service_Type__c": {
          name: 'Service_Type__c',
          type: 'string'
        },
        "ShippingAddress": {
          name: 'ShippingAddress',
          type: 'string'
        },
        "ShippingCity": {
          name: 'ShippingCity',
          type: 'string'
        },
        "ShippingCountry": {
          name: 'ShippingCountry',
          type: 'string'
        },
        "ShippingGeocodeAccuracy": {
          name: 'ShippingGeocodeAccuracy',
          type: 'string'
        },
        "ShippingLatitude": {
          name: 'ShippingLatitude',
          type: 'number'
        },
        "ShippingLongitude": {
          name: 'ShippingLongitude',
          type: 'number'
        },
        "ShippingPostalCode": {
          name: 'ShippingPostalCode',
          type: 'string'
        },
        "ShippingState": {
          name: 'ShippingState',
          type: 'string'
        },
        "ShippingStreet": {
          name: 'ShippingStreet',
          type: 'string'
        },
        "SpecialTerms": {
          name: 'SpecialTerms',
          type: 'string'
        },
        "StartDate": {
          name: 'StartDate',
          type: 'Date'
        },
        "StartDate_YYYY_MM_DD__c": {
          name: 'StartDate_YYYY_MM_DD__c',
          type: 'string'
        },
        "Status": {
          name: 'Status',
          type: 'string'
        },
        "StatusCode": {
          name: 'StatusCode',
          type: 'string'
        },
        "Sub_Inventory_Location_WSL__c": {
          name: 'Sub_Inventory_Location_WSL__c',
          type: 'string'
        },
        "Sub_Inventory_Location__c": {
          name: 'Sub_Inventory_Location__c',
          type: 'string'
        },
        "SystemModstamp": {
          name: 'SystemModstamp',
          type: 'Date'
        },
        "Temp_Flag__c": {
          name: 'Temp_Flag__c',
          type: 'boolean'
        },
        "WSL_Location_Ready__c": {
          name: 'WSL_Location_Ready__c',
          type: 'boolean'
        },
        "Warranty_SLA_3PL_FRU_POD__c": {
          name: 'Warranty_SLA_3PL_FRU_POD__c',
          type: 'number'
        },
        "Warranty_SLA_3PS_FE_Arrival__c": {
          name: 'Warranty_SLA_3PS_FE_Arrival__c',
          type: 'number'
        },
        "Warranty_Status__c": {
          name: 'Warranty_Status__c',
          type: 'string'
        },
        "Warranty_Support_Status__c": {
          name: 'Warranty_Support_Status__c',
          type: 'string'
        },
        "Warranty_Term_in_Month__c": {
          name: 'Warranty_Term_in_Month__c',
          type: 'number'
        },
        "Warranty_Type__c": {
          name: 'Warranty_Type__c',
          type: 'string'
        },
        "Warranty__c": {
          name: 'Warranty__c',
          type: 'string'
        },
        "X3PL_FSL_Location_GatewayOutPut__c": {
          name: 'X3PL_FSL_Location_GatewayOutPut__c',
          type: 'string'
        },
        "X3PL_FSL_Location_Gateway__c": {
          name: 'X3PL_FSL_Location_Gateway__c',
          type: 'string'
        },
        "X3PL_Vendor_WSL__c": {
          name: 'X3PL_Vendor_WSL__c',
          type: 'string'
        },
        "X3PL_Vendor__c": {
          name: 'X3PL_Vendor__c',
          type: 'string'
        },
        "X3PL_WSL_Location_Default__c": {
          name: 'X3PL_WSL_Location_Default__c',
          type: 'string'
        },
        "X3PL_WSL_Location_Gateway_WSL__c": {
          name: 'X3PL_WSL_Location_Gateway_WSL__c',
          type: 'string'
        },
        "X3PSVendor__c": {
          name: 'X3PSVendor__c',
          type: 'string'
        },
        "X3PS_Vendor_Contract_End_Date_YYYY_MM_D__c": {
          name: 'X3PS_Vendor_Contract_End_Date_YYYY_MM_D__c',
          type: 'string'
        },
        "X3PS_Vendor_Contract_End_Date__c": {
          name: 'X3PS_Vendor_Contract_End_Date__c',
          type: 'Date'
        },
        "X3PS_Vendor_Contract_Reference_Number__c": {
          name: 'X3PS_Vendor_Contract_Reference_Number__c',
          type: 'string'
        },
        "X3PS_Vendor_Contract_Start_Date__c": {
          name: 'X3PS_Vendor_Contract_Start_Date__c',
          type: 'Date'
        },
        "X3PS_Vendor_Contract_Terms__c": {
          name: 'X3PS_Vendor_Contract_Terms__c',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'AccountId',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
