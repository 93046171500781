import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HealthCheckComponent } from './health-check.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [HealthCheckComponent],
  exports: [HealthCheckComponent]
})
export class HealthCheckModule { }
