import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { uniqueUsers, uniqueUsersOption } from '../../../../models/static-list-data.service';

@Component({
  selector: 'app-uniqu-user-lookup',
  templateUrl: './uniqu-user-lookup.component.html',
  styleUrls: ['./uniqu-user-lookup.component.css']
})
export class UniquUserLookupComponent implements OnInit {

  uniqueUsersList = [];
  uniqueUserSelected = [];
  isMultipleSelected = true;

  @Input() booleanUniqueUser = false;
  @Input() set resetData(isClear) {
    if (isClear) {
      this.uniqueUserSelected = [];
    }
  }
  @Input() maxSelectedItems = 1;
  @Input() from;
  @Input() selectedDefaultValue;

  @Output() selectedUniqueUser: EventEmitter<any> = new EventEmitter;


  constructor() { }

  ngOnInit() {
    if(this.booleanUniqueUser) {
      this.uniqueUsersList = uniqueUsers;
    } else {
      this.uniqueUsersList = uniqueUsersOption;
    }
    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : ''

    this.uniqueUserSelected = (this.selectedDefaultValue) ? this.selectedDefaultValue : (preselected &&
      preselected['uniqueUser']) ? preselected['uniqueUser'] : ['false'];
  }

  onUniqueUserChange() {
    this.selectedUniqueUser.emit(this.uniqueUserSelected);
  }

  clearUniqueUser() {
    this.uniqueUserSelected = [];
    this.selectedUniqueUser.emit([]);
  }


}
