/* tslint:disable */
import {
  Account,
  Case,
  Location,
  WorkOrder,
  JobOrderItem
} from '../index';

declare var Object: any;
export interface ProductRequestInterface {
  "sfdcId"?: string;
  "AccountId"?: string;
  "CaseId"?: string;
  "CreatedById"?: string;
  "CreatedDate"?: Date;
  "CurrencyIsoCode"?: string;
  "Description"?: string;
  "DestinationLocationId"?: string;
  "LastModifiedById"?: string;
  "LastModifiedDate"?: Date;
  "NeedByDate"?: Date;
  "OwnerId"?: string;
  "ProductRequestNumber"?: number;
  "ShipmentType"?: string;
  "ShipToAddress"?: string;
  "ShipToStreet"?: string;
  "ShipToCity"?: string;
  "ShipToState"?: string;
  "ShipToPostalCode"?: string;
  "ShipToCountry"?: string;
  "ShipToLatitude"?: string;
  "ShipToLongitude"?: string;
  "ShipToGeocodeAccuracy"?: string;
  "SourceLocationId"?: string;
  "Status"?: string;
  "WorkOrderId"?: string;
  "WorkOrderLineItemId"?: string;
  "OEM_RMA_Case_Number__c"?: string;
  "Parts_Requested_Arrival_Date_Time__c"?: string;
  "Parts_Requested_Arrival_Date_Time_Local__c"?: string;
  "Work_Order_B2B__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  account?: Account;
  case?: Case;
  dlocation?: Location;
  slocation?: Location;
  workOrder?: WorkOrder;
  workOrderItem?: JobOrderItem;
  b2bWorkOrder?: WorkOrder;
}

export class ProductRequest implements ProductRequestInterface {
  "sfdcId": string;
  "AccountId": string;
  "CaseId": string;
  "CreatedById": string;
  "CreatedDate": Date;
  "CurrencyIsoCode": string;
  "Description": string;
  "DestinationLocationId": string;
  "LastModifiedById": string;
  "LastModifiedDate": Date;
  "NeedByDate": Date;
  "OwnerId": string;
  "ProductRequestNumber": number;
  "ShipmentType": string;
  "ShipToAddress": string;
  "ShipToStreet": string;
  "ShipToCity": string;
  "ShipToState": string;
  "ShipToPostalCode": string;
  "ShipToCountry": string;
  "ShipToLatitude": string;
  "ShipToLongitude": string;
  "ShipToGeocodeAccuracy": string;
  "SourceLocationId": string;
  "Status": string;
  "WorkOrderId": string;
  "WorkOrderLineItemId": string;
  "OEM_RMA_Case_Number__c": string;
  "Parts_Requested_Arrival_Date_Time__c": string;
  "Parts_Requested_Arrival_Date_Time_Local__c": string;
  "Work_Order_B2B__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  account: Account;
  case: Case;
  dlocation: Location;
  slocation: Location;
  workOrder: WorkOrder;
  workOrderItem: JobOrderItem;
  b2bWorkOrder: WorkOrder;
  constructor(data?: ProductRequestInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ProductRequest`.
   */
  public static getModelName() {
    return "ProductRequest";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ProductRequest for dynamic purposes.
  **/
  public static factory(data: ProductRequestInterface): ProductRequest{
    return new ProductRequest(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ProductRequest',
      plural: 'ProductRequests',
      path: 'ProductRequests',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "AccountId": {
          name: 'AccountId',
          type: 'string'
        },
        "CaseId": {
          name: 'CaseId',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "Description": {
          name: 'Description',
          type: 'string'
        },
        "DestinationLocationId": {
          name: 'DestinationLocationId',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "NeedByDate": {
          name: 'NeedByDate',
          type: 'Date'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "ProductRequestNumber": {
          name: 'ProductRequestNumber',
          type: 'number'
        },
        "ShipmentType": {
          name: 'ShipmentType',
          type: 'string'
        },
        "ShipToAddress": {
          name: 'ShipToAddress',
          type: 'string'
        },
        "ShipToStreet": {
          name: 'ShipToStreet',
          type: 'string'
        },
        "ShipToCity": {
          name: 'ShipToCity',
          type: 'string'
        },
        "ShipToState": {
          name: 'ShipToState',
          type: 'string'
        },
        "ShipToPostalCode": {
          name: 'ShipToPostalCode',
          type: 'string'
        },
        "ShipToCountry": {
          name: 'ShipToCountry',
          type: 'string'
        },
        "ShipToLatitude": {
          name: 'ShipToLatitude',
          type: 'string'
        },
        "ShipToLongitude": {
          name: 'ShipToLongitude',
          type: 'string'
        },
        "ShipToGeocodeAccuracy": {
          name: 'ShipToGeocodeAccuracy',
          type: 'string'
        },
        "SourceLocationId": {
          name: 'SourceLocationId',
          type: 'string'
        },
        "Status": {
          name: 'Status',
          type: 'string'
        },
        "WorkOrderId": {
          name: 'WorkOrderId',
          type: 'string'
        },
        "WorkOrderLineItemId": {
          name: 'WorkOrderLineItemId',
          type: 'string'
        },
        "OEM_RMA_Case_Number__c": {
          name: 'OEM_RMA_Case_Number__c',
          type: 'string'
        },
        "Parts_Requested_Arrival_Date_Time__c": {
          name: 'Parts_Requested_Arrival_Date_Time__c',
          type: 'string'
        },
        "Parts_Requested_Arrival_Date_Time_Local__c": {
          name: 'Parts_Requested_Arrival_Date_Time_Local__c',
          type: 'string'
        },
        "Work_Order_B2B__c": {
          name: 'Work_Order_B2B__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'AccountId',
          keyTo: 'sfdcId'
        },
        case: {
          name: 'case',
          type: 'Case',
          model: 'Case',
          relationType: 'belongsTo',
                  keyFrom: 'CaseId',
          keyTo: 'sfdcId'
        },
        dlocation: {
          name: 'dlocation',
          type: 'Location',
          model: 'Location',
          relationType: 'belongsTo',
                  keyFrom: 'DestinationLocationId',
          keyTo: 'sfdcId'
        },
        slocation: {
          name: 'slocation',
          type: 'Location',
          model: 'Location',
          relationType: 'belongsTo',
                  keyFrom: 'SourceLocationId',
          keyTo: 'sfdcId'
        },
        workOrder: {
          name: 'workOrder',
          type: 'WorkOrder',
          model: 'WorkOrder',
          relationType: 'belongsTo',
                  keyFrom: 'WorkOrderId',
          keyTo: 'sfdcId'
        },
        workOrderItem: {
          name: 'workOrderItem',
          type: 'JobOrderItem',
          model: 'JobOrderItem',
          relationType: 'belongsTo',
                  keyFrom: 'WorkOrderLineItemId',
          keyTo: 'sfdcId'
        },
        b2bWorkOrder: {
          name: 'b2bWorkOrder',
          type: 'WorkOrder',
          model: 'WorkOrder',
          relationType: 'belongsTo',
                  keyFrom: 'Work_Order_B2B__c',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
