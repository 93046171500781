/* tslint:disable */

declare var Object: any;
export interface AnswerSequenceInterface {
  "sfdcId"?: string;
  "OwnerId"?: string;
  "IsDeleted"?: boolean;
  "CreatedDate"?: Date;
  "CreatedById"?: string;
  "CurrencyIsoCode"?: string;
  "LastModifiedDate"?: Date;
  "LastModifiedById"?: string;
  "Name"?: string;
  "SystemModstamp"?: Date;
  "LastActivityDate"?: Date;
  "Internal_Notes__c"?: string;
  "Answer_Notes__c"?: string;
  "Is_Answer_Note_Required__c"?: boolean;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class AnswerSequence implements AnswerSequenceInterface {
  "sfdcId": string;
  "OwnerId": string;
  "IsDeleted": boolean;
  "CreatedDate": Date;
  "CreatedById": string;
  "CurrencyIsoCode": string;
  "LastModifiedDate": Date;
  "LastModifiedById": string;
  "Name": string;
  "SystemModstamp": Date;
  "LastActivityDate": Date;
  "Internal_Notes__c": string;
  "Answer_Notes__c": string;
  "Is_Answer_Note_Required__c": boolean;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: AnswerSequenceInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `AnswerSequence`.
   */
  public static getModelName() {
    return "AnswerSequence";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of AnswerSequence for dynamic purposes.
  **/
  public static factory(data: AnswerSequenceInterface): AnswerSequence{
    return new AnswerSequence(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'AnswerSequence',
      plural: 'AnswerSequences',
      path: 'AnswerSequences',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "SystemModstamp": {
          name: 'SystemModstamp',
          type: 'Date'
        },
        "LastActivityDate": {
          name: 'LastActivityDate',
          type: 'Date'
        },
        "Internal_Notes__c": {
          name: 'Internal_Notes__c',
          type: 'string'
        },
        "Answer_Notes__c": {
          name: 'Answer_Notes__c',
          type: 'string'
        },
        "Is_Answer_Note_Required__c": {
          name: 'Is_Answer_Note_Required__c',
          type: 'boolean'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
