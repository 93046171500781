import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { TaxonomiesApi, TaxonomySkillsApi } from 'shared/sdk';
import { AppStateService } from 'shared/services/app-state.service';
import { debounceTime, distinctUntilChanged, switchMap, filter } from 'rxjs/operators';

@Component({
  selector: 'app-taxonomies-skill-lookup',
  templateUrl: './taxonomies-skill-lookup.component.html',
  styleUrls: ['./taxonomies-skill-lookup.component.css']
})
export class TaxonomiesSkillLookupComponent implements OnInit {

  @Input() set resetData(isClear) {
    this.taxonomySkillSelected = [];
  }
  @Input() from;
  @Input() selectLabel = 'Name';
  @Input() selectbindValue = 'sfdcId';
  @Input() isMultiSelect = 'false';
  @Input() set selectTaxonomySkill(e) {
    if (e) {
      this.taxonomySkillList = [];
      this.taxonomySkillList.push(e);
      this.taxonomySkillSelected = e && e.sfdcId;
    }
  }
  @Input() set preSelected(e) {
    if (e) {
      this.taxonomySkillSelected = e
      this.onTaxonomiesChange()
    }
  }
  @Input() isDisabled = false;
  @Output() getTaxonomiesObj: EventEmitter<any> = new EventEmitter;

  // vendor taxonomySkill

  notaxonomySkill = 'Searching...';
  taxonomySkillsTypeahead = new EventEmitter<string>();
  taxonomySkills: Array<any>;
  taxonomySkillList = [];
  taxonomySkillSelected = [];

  constructor(
    private _taxonomySkillsApi: TaxonomySkillsApi,
    private _appState: AppStateService,
  ) { }

  ngOnInit() {
    this.getTaxonomiess();
  }

  getTaxonomiess() {
    this.taxonomySkillsTypeahead.pipe(
      filter(term => term && term.length > 2),
      distinctUntilChanged(),
      debounceTime(200),
      switchMap(term => {
        const searchPattern = { like: '%' + term.trim() + '%', options: 'i' };
        const whereObj = {
          Name: searchPattern,
        };
        return this.getTaxonomiesList(whereObj);
      })
    ).subscribe(
      x => {
        this.taxonomySkillList = x;
        this.notaxonomySkill = (this.taxonomySkillList.length === 0) ? 'No taxonomySkill found.' : '';
      },
      err => {
        console.log(err);
        this.taxonomySkillList = [];
        this.notaxonomySkill = '';
      }
    );
  }

  onTaxonomiesChange() {
    this.getTaxonomiesObj.emit(this.taxonomySkillSelected);
  }

  clearPrograms() {
    this.taxonomySkillSelected = [];
    this.getTaxonomiesObj.emit({});
  }

  getTaxonomiesList(whereObj) {
    return this._taxonomySkillsApi.find({
      where: whereObj,
      fields: ['Name', 'id', 'sfdcId'],
      order: 'createdAt DESC',
    }).map(res => res);
  }
}
