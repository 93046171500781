import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'shared/services/common.service';
import { NotificationService } from 'shared/services/notification.service';
import { PreloaderService } from 'shared/services/preloader.service';
import { UtilityService } from 'shared/services/utility.service';
import { JobApi, CaseApi } from 'shared/sdk';

@Component({
  selector: 'app-fleet-management-activity-feed-list',
  templateUrl: './fleet-management-activity-feed-list.component.html',
  styleUrls: ['./fleet-management-activity-feed-list.component.css']
})
export class FleetManagementActivityFeedListComponent implements OnInit {

  @Input() jobIds;
  pageNo = 1;
  perPageSize = 10;
  total = 0;
  lastPage = 1;
  feeds = [];
  isLoadMore = false;

  constructor(private _notificationService: NotificationService, private _loader: PreloaderService,
    private _utilityService: UtilityService, private _commonService: CommonService, private _jobApi: JobApi) { }

  ngOnInit() {
    this.getActivityFeedList();
  }

  getActivityFeedList(pageNo = 1) {
    this._loader.showPreloader();
    this._notificationService
      .getActivityFeed(pageNo, {
        // 'id': { inq: this.jobIds }
        'id': this.jobIds
      }, this.perPageSize).then((res: any) => {
        this._loader.hidePreloader();
        if (res && res['data']) {
          this.modifyData(res['data']);
          this.lastPage = res['lastPage'];
          this.total = res['total'];
          this.pageNo = res['page'];
          this.isLoadMore = this.pageNo < this.lastPage;
        }
      })
      .catch(err => {
        this._loader.hidePreloader();
        console.log(err);
      });
  }

  loadMoreData() {
    this.getActivityFeedList(this.pageNo + 1);
  }

  async modifyData(data) {
    data = await this.getAdditionalData(data);
    data.forEach(element => {
      if (element && element.created_at) {
        // element.created_at = this._commonService.dateFormate(element.created_at + 'Z', '', 'short');
        element.created_at = this._utilityService.dateFormate(element.created_at + 'Z');
        if (!element.actor_name && element.actor_type === 'system') {
          element.actor_name = 'System';
        }
      }
      element.expanded = false;
      this.feeds.push(element);
    });
  }

  getAdditionalData(activityData) {
    /*
    hit the job or case api as per the userType and merege the accountName, PartnerName, jobNumber/caseNumber related data to acitivity response
    */
    return new Promise((resolve, reject) => {
      let mergedData: any = [];
      let activitySfdcIds = activityData.reduce((activityIds, activity) => {
        if (activity.entity_name === 'Job') {
          activityIds.push(activity.sfdc_id);
        }
        return activityIds;
      }, []);

      activitySfdcIds = [...Array.from(new Set(activitySfdcIds))];
      const filterObj = {
        where: { sfdcId: { inq: activitySfdcIds } },
        fields: ["id", "Case__c", "sfdcId", "Iron_Job_num__c", "CKSW_BASE__Account__c", "Project_SOP__c"],
        include: [{
          relation: 'partner',
          scope: {
            fields: ["Name"]
          },
        },
        {
          relation: 'program',
          scope: {
            fields: ["Project__c", "Name"]
          },
        }
        ]
      }
      this._jobApi.find(filterObj).subscribe(async jobData => {
        mergedData = await this.mergeDataWithActivity(jobData, activityData)
        resolve(mergedData);
      }, err => {
        console.log(`error in getAdditionalData()`, err);
        resolve(activityData);
      })
    })

  }

  mergeDataWithActivity(data, activityData) {
    return new Promise((resolve, reject) => {
      for (let activity of activityData) {

        const jobData = data.filter(job => job.sfdcId == activity.sfdc_id);
        // activity['iron_job_num__c'] = jobData && jobData.length ? jobData[0].Iron_Job_num__c : '';
        activity['account_name'] = jobData && jobData.length && jobData[0].partner ? jobData[0].partner.Name : '';
        activity['program_name'] = jobData && jobData.length && jobData[0].program ? jobData[0].program.Name + ' (' + jobData[0].program.Project__c + ')' : '';
        // activity['sfdcId'] = jobData && jobData.length ? jobData[0].sfdcId : '';
      }
      resolve(activityData)
    })
  }

  expandTheCard(item) {
    if (item) {
      this.feeds.forEach(element => {
        if (item.id === element.id) {
          element.expanded = !element.expanded;
        } else {
          element.expanded = false;
        }
      })
    }
  }

}
