import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { UsersApi } from 'shared/sdk';
import { filter, distinctUntilChanged, debounceTime, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-approver-lookup',
  templateUrl: './approver-lookup.component.html',
  styleUrls: ['./approver-lookup.component.css']
})
export class ApproverLookupComponent implements OnInit {

  @Input() set resetData(isClear) {
    // this.usersList = [];
    this._userSelected = [];
  }
  @Input()
  set isMultipleSelected(e) {
    this._isMultipleSelected = e;
    this.maxSelectedItems = e ? 20 : 1;
  }
  @Input() isdisabled = false;
  @Input() hideSelected = false;
  @Output() getUserObj: EventEmitter<any> = new EventEmitter;
  noUserFound = 'No user found.';
  usersTypeahead = new EventEmitter<string>();
  usersList = [];
  @Input()
  set userSelected(e) {
    this._userSelected = e;
    this.getSelectedUserData();
  }
  @Input() isGlobalFilter = true;

  maxSelectedItems = 20;
  _isMultipleSelected = true;
  _userSelected = [];
  constructor(
    private _cd: ChangeDetectorRef,
    private _usersApi: UsersApi,
  ) {

  }

  ngOnInit() {
    this.getUsers();
    // to show selected user
    if (this.isGlobalFilter) {
      const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
      this._userSelected = (preselected && preselected['users']) ? preselected['users'] : '';
    }
    this.getSelectedUserData();
  }

  getSelectedUserData() {
    if (this._userSelected && this._userSelected.length) {
      let whereObj = {};
      if (Array.isArray(this._userSelected)) {
        whereObj['sfdcId'] = { inq: this._userSelected };
      } else {
        whereObj['sfdcId'] = this._userSelected;
      }
      whereObj['accessType'] = 'internal';
      whereObj['sfdcId'] = { neq: null };

      this.getUsersList(whereObj).subscribe(
        (x: any) => {
          x.forEach(item => {
            item['Name'] = `${item.firstname + " " + item.lastname}`
          })
          this.usersList = x;
          this.noUserFound = (this.usersList.length === 0) ? 'No User found.' : '';
        },
        err => {
          console.log(err);
          this.usersList = [];
          this.noUserFound = '';
        }
      );
    }
    this.getUserObj.emit(this._userSelected);
  }

  // get partner's accounts list by name
  getUsers() {
    this.usersList = [];
    this.usersTypeahead
      .pipe(
        filter(term => term && term.trim().length > 2),
        distinctUntilChanged(),
        debounceTime(200),
        switchMap(term => {
          const searchPattern = { like: '%' + term.trim() + '%', options: 'i' };
          const whereObj = {
            firstname: searchPattern,
            accessType: "internal",
            sfdcId: { neq: null }
          };
          return this.getUsersList(whereObj);
        })
      )
      .subscribe(
        (x: any) => {
          this._cd.markForCheck();
          x.forEach(item => {
            item['Name'] = `${item.firstname + " " + item.lastname}`
          })
          this.usersList = x;
          this.noUserFound = (this.usersList.length === 0) ? 'No User found.' : '';
        },
        err => {
          console.log(err);
          this.usersList = [];
          this.noUserFound = '';
        }
      );
  }

  onUsersChange() {
    this.getUserObj.emit(this._userSelected);
  }

  clearUsers() {
    this._userSelected = [];
    this.getUserObj.emit([]);
  }

  /**
   * 
   * @param whereObj 
   */

  getUsersList(whereObj) {
    return this._usersApi.find({
      where: whereObj,
      fields: ['sfdcId', 'firstname', 'lastname']
    }).map(res => res);
  }
}
