/* tslint:disable */

declare var Object: any;
export interface PasscodeInterface {
  "contactId"?: string;
  "references"?: any;
  "code"?: string;
  "expireIn"?: number;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class Passcode implements PasscodeInterface {
  "contactId": string;
  "references": any;
  "code": string;
  "expireIn": number;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: PasscodeInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Passcode`.
   */
  public static getModelName() {
    return "Passcode";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Passcode for dynamic purposes.
  **/
  public static factory(data: PasscodeInterface): Passcode{
    return new Passcode(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Passcode',
      plural: 'Passcodes',
      path: 'Passcodes',
      idName: 'id',
      properties: {
        "contactId": {
          name: 'contactId',
          type: 'string'
        },
        "references": {
          name: 'references',
          type: 'any'
        },
        "code": {
          name: 'code',
          type: 'string'
        },
        "expireIn": {
          name: 'expireIn',
          type: 'number',
          default: 0
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
