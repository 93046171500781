import { Component, OnInit, Input } from '@angular/core';
import { AlertService } from '../../../../../services/alert.service';
import { PreloaderService } from '../../../../../services/preloader.service';
import { JobInstructionApi } from '../../../../../sdk';
import { CommonService } from 'shared/services/common.service';

@Component({
    selector: 'job-detail-instructions',
    templateUrl: './job-detail-instructions.component.html',
    styleUrls: ['./job-detail-instructions.component.css']
})

export class JobDetailInstructionsComponent implements OnInit {
    @Input() jobId: string;
    @Input() hideInstruction: Boolean = false;
    jobInstruction: Array<any>;
    errorMessage: string;
    tools: Array<any>;

    constructor(
        private _jobInstructionApi: JobInstructionApi,
        private _alertService: AlertService,
        private _preloaderService: PreloaderService,
        private _commonService: CommonService
    ) { }

    ngOnInit() {
        this._commonService.saveCurrentPageAnalytics('Job Manager Console - Details', 'Global Program Instructions');
        if (this.jobId) {
            if (sessionStorage.getItem(`jobInstruction_${this.jobId}`) === null) {
                this.getInstructionDetails(this.jobId);
            } else {
                this.jobInstruction = JSON.parse(sessionStorage.getItem(`jobInstruction_${this.jobId}`));
                this.errorMessage = (this.jobInstruction.length > 0) ? '' : 'Global Program Instructions not available for this job.';
            }
        }
    }

    getInstructionDetails(id) {
        this._preloaderService.showPreloader();
        this._jobInstructionApi.find({
            'where': {
                'Job__c': id,
                'IsDeleted': 0
            },
            'include': [
                {
                    'relation': 'Job',
                    'scope': {
                        'fields': ['Special_Instruction_from_PMS_Case_Auto__c', 'csum__c','Custom_work_order_instructions__c', 'Partner_Case_Error_Description__c']
                    }
                }
            ]
        }).subscribe(
            jobInstruction => {
                if (jobInstruction && jobInstruction.length) {
                    this.jobInstruction = jobInstruction;
                    this.jobInstruction.forEach(function (ele) {
                        ele['tools'] = ele.Job_Instructions_Required_Tools__c && ele.Job_Instructions_Required_Tools__c.length
                            ? ele.Job_Instructions_Required_Tools__c.split('\r\n') : [];
                    });
                    sessionStorage.setItem(`jobInstruction_${this.jobId}`, JSON.stringify(this.jobInstruction))
                } else {
                    this.errorMessage = 'Global Program Instructions not available for this job.';
                }
                this._preloaderService.hidePreloader();
            }, error => {
                this._preloaderService.hidePreloader();
                this.errorMessage = error.message;
            }
        );
    }
}
