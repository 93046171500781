import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaintenanceScheduleComponent } from './maintenance-schedule.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [MaintenanceScheduleComponent],
  exports: [MaintenanceScheduleComponent]
})

export class MaintenanceScheduleModule {
}