import { Component, OnInit, AfterViewInit, Input, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DocumentCategoryApi, DocumentTitleApi, DocumentApi, JobApi } from '../../../../../sdk';
import { PreloaderService } from '../../../../../services/preloader.service';
import { Subscription } from 'rxjs';
import { GlobalFilterService } from 'shared/services/global-filter.service';
import { CommonService } from 'shared/services/common.service';
import { AppStateService } from 'shared/services/app-state.service';
@Component({
    selector: 'app-category-and-title-view',
    templateUrl: 'category-and-title-view.component.html',
    styleUrls: ['category-and-title-view.component.css']
})

export class CategoryAndTitleViewComponent implements OnInit, OnDestroy, AfterViewInit {

    viewType: string = 'categoryView';
    data: any = [];
    count: any = 0;
    categoryId: any;
    titleId: any;
    categoryName: any;
    params: any;
    filterCondition: any = {};
    @Input() currentLevel = 'one';
    breadCrumbs: any;
    documentList: any;
    documentCount: any = 0;
    isTableData = false;
    noRecords = false;
    itemsPerBatch = 200;
    offset = 0;
    isLoadMore = false;
    columns: any;
    defaultConfig: any = { _itemsPerPage: 25, _deleteDoc: true };
    dataConfig: any = {};
    globalFilters: any = {};
    globalFilterSub: Subscription;
    documentSub: Subscription;
    documentTitleSub: Subscription;
    documentCategorySub: Subscription;
    view = 'Folder';

    internalUserFilters = [
        'accounts',
        'vendorAccounts',
        'workerNames',
    ];
    enableFilters = [
        'dateOptions',
        'programs',
        'jobSites',
        'statgeStatus',
        'talentTypes',
        'jobs',
        'jobTypes',
        'jobStatus',
        'vendorSites',
        'caseCategory',
        'priority',
        'geoMetro',
        'pmsCaseStatus',
        'pmsCaseNumbers'
    ];

    accessType = '';
    accountId = '';
    isFilterApply = false;
    viewAsInfo: any;
    viewAsPartner: boolean;

    constructor(
        private _preloaderService: PreloaderService,
        private _router: Router,
        private _route: ActivatedRoute,
        private _documentTitleApi: DocumentTitleApi,
        private _documentCategoryApi: DocumentCategoryApi,
        private _documentApi: DocumentApi,
        private _jobApi: JobApi,
        private _globalFilterService: GlobalFilterService,
        private _commonService: CommonService,
        private _appState: AppStateService
    ) {
        this.globalFilterSub = this._globalFilterService.getFilters().subscribe(filters => {
            // if filter is set in local storage
            this.accessType = this._appState.getAccessType();
            this.accountId = this._appState.getSelectedAccount();
            const userType = this._appState.getUserTypeSlug();
            // To check if view as PMS mode is activated.
            if (localStorage.getItem('viewAsInfo')) {
                this.viewAsInfo = JSON.parse(localStorage.getItem('viewAsInfo'));
                this.viewAsPartner = (this.viewAsInfo && this.viewAsInfo.viewAsType === 'Partner');
                this.accessType = (this.viewAsPartner) ? 'partner' : this.accessType;
                this.accountId = (this.viewAsInfo.account) ? this.viewAsInfo.account.sfdcId : this.accountId;
            }

            if (this.accessType === 'internal') {
                this.enableFilters = this.enableFilters.concat(this.internalUserFilters);
            }
            if (this.accessType === 'vendor' && userType !== 'standard') {
                this.enableFilters = this.enableFilters.concat(['workerNames']);
            }

            if (!filters.clicked) {
                this.globalFilters = this._commonService.getGlobalFilter(this.enableFilters);
            }
            // while search and clear filter apply
            if (filters.clicked) {
                this.globalFilters = filters.data;
            }
            if (this.viewAsPartner) {
                this.globalFilters['Project_SOP__c'] = { inq: [this.viewAsInfo.program.sfdcId] }
            }
            this.reInitialize();
            if (this.viewType === 'categoryView') {
                this.getDocumentCategory();
            } else if (this.viewType === 'listView') {
                this.getDocumentList();
            } else if (this.viewType === 'titleView') {
                this.getDocumentTitle();
            }
        });

        this._route.queryParams.subscribe(params => {
            this.initPages(params);
        });
    }

    ngOnInit() {
        this.defaultConfig['_columns'] = [
            {
                prop: 'selected',
                name: '',
                sortable: false,
                canAutoResize: false,
                draggable: false,
                resizable: false,
                headerCheckboxable: true,
                checkboxable: true,
                width: 40,
                minWidth: 40,
                visible: true
            },
            { name: 'Action', prop: 'action', minWidth: 75, width: 75, sortable: false, resizeable: false, visible: true },
            { name: 'File Name', prop: 'Name', minWidth: 200, width: 200, sortable: true, resizeable: false, visible: true },
            { name: 'Account', prop: 'accountName', minWidth: 200, width: 200, showMore: true, sortable: true, resizeable: true, visible: true },
            { name: 'Program', prop: 'programName', minWidth: 200, width: 200, showMore: true, sortable: true, resizeable: true, visible: true },
            { name: 'Document Title', prop: 'subCategory', minWidth: 200, showMore: true, width: 200, sortable: true, resizeable: false, visible: true },
            { name: 'ICC Case', prop: 'iccCaseNumber', minWidth: 140, width: 140, sortable: true, resizeable: false, visible: true },
            { name: 'PMS Case', prop: 'pmsCaseNumber', minWidth: 140, width: 140, sortable: true, resizeable: false, visible: true },
            { name: 'Partner Case', prop: 'partnerCaseNumber', minWidth: 140, width: 140, resizeable: false, visible: true },
            { name: 'Job ID', prop: 'jobNumber', minWidth: 140, width: 140, resizeable: false, sortable: true, visible: true },
            { name: 'Jobsite', prop: 'JobsiteName', minWidth: 200, width: 200, resizeable: false, sortable: true, visible: true },
            // { name: 'Workorder No', prop: 'workOrderNumber', minWidth: 150, width: 150, resizeable: false, visible: true },            
            { name: 'Description', prop: 'description', minWidth: 250, width: 250, showMore: true, resizeable: false, sortable: true, visible: true },
            { name: 'Uploaded By', prop: 'uploadedBy', minWidth: 150, width: 150, resizeable: false, sortable: true, visible: true },
            { name: 'Date Uploaded', prop: 'createdAt', minWidth: 150, width: 150, resizeable: false, sortable: true, visible: true, type: 'date' },
            { name: 'Task No', prop: 'taskNumber', minWidth: 150, width: 150, resizeable: false, sortable: true, visible: true },
        ];
    }

    ngAfterViewInit() {
    }

    reInitialize() {
        this.data = [];
        this.documentList = [];
        this.isTableData = false;
        this.offset = 0;
        this.isLoadMore = false;
        this.noRecords = false;
    }

    changeView(e) {
        this._router.navigate(['app', 'file-manager', 'list-view']);
        /*if (e.target.checked) {
            this._router.navigate(['app', 'file-manager']);
        }*/
    }

    initPages(params) {
        this.reInitialize();
        if (params['cId'] && !params['sTitle']) {
            this.categoryId = params['cId'];
            this.titleId = '';
            this.viewType = 'titleView';
            this.getDocumentTitle();
            this.setBreadCrumb();
        } else if (params['cId'] && params['sTitle']) {
            this.viewType = 'listView';
            this.categoryId = params['cId'];
            this.titleId = params['sTitle'];
            this.params = {
                where: { subCategoryId: params['sTitle'] }
            }
            this.getDocumentList();
            this.setBreadCrumb();
        } else {
            this.categoryId = '';
            this.titleId = '';
            this.viewType = 'categoryView';
            this.getDocumentCategory();
        }
    }

    setBreadCrumb() {
        if (this.categoryId && !this.titleId) {
            const reqObj = {
                'getCategory': {
                    'where': { id: this.categoryId },
                    'fields': ['id', 'title']
                }
            };
            this._documentApi.getBreadCrumb(reqObj).subscribe((response: any) => {
                this.currentLevel = 'two';
                if (response && response['category']) {
                    this.breadCrumbs = [{
                        name: response['category']['title']
                    }];
                }
            },
                error => {
                    console.log('error==>>>', error);
                });
        }
        if (this.categoryId && this.titleId) {
            const reqObj = {
                'getCategory': {
                    'where': { id: this.categoryId },
                    'fields': ['id', 'title']
                },
                'getTitle': {
                    'where': { id: this.titleId },
                    'fields': ['id', 'title']
                }
            };
            this._documentApi.getBreadCrumb(reqObj).subscribe((response: any) => {
                this.currentLevel = 'two';
                if (response) {
                    if (response['category']) {
                        this.breadCrumbs = [{
                            name: response['category']['title'],
                            categoryId: response['category']['id']
                        }];
                    }
                    if (response['categoryTitle']) {
                        this.breadCrumbs.push({
                            name: response['categoryTitle']['title']
                        });
                    }
                }

            },
                error => {
                    console.log('error==>>>', error);
                });
        }

    }

    getDocumentList() {
        if (this.documentSub) {
            this.documentSub.unsubscribe();
        }

        const reqObj = {
            "viewType": 'listView',
            "order": 'createdAt DESC',
            "limit": this.itemsPerBatch,
            'skip': this.offset,
            jobFilter: this.globalFilters,
            where: {}
        }

        if (this.params) {
            reqObj["where"] = this.params['where'];
        }

        // exclude the soft deleted files
        reqObj['where']['isDeleted'] = 0;

        if (this.viewAsPartner) {
            reqObj['viewAsPartner'] = true;
            reqObj['partnerAccountId'] = this.viewAsInfo.account.sfdcId;
        }

        this._preloaderService.showPreloader();
        this.documentSub = this._documentApi.getDocumentForCategory(reqObj).subscribe(res => {
            this._preloaderService.hidePreloader();
            if (res.length < this.itemsPerBatch) {
                this.noRecords = true;
            } else {
                this.noRecords = false;
            }
            if (!this.isLoadMore) {
                this.documentList = res;
                if (this.documentList.length === 0) {
                    this.documentList.push({
                        'message': 'No data to display.',
                    });
                }
                this.isTableData = true;
            } else {
                res.forEach(c => {
                    this.documentList.push(c);
                });
                this.documentList = [...this.documentList];
            }
            this.documentCount = res.length;
        }, err => {
            this.isTableData = true;
            this._preloaderService.hidePreloader();
            console.log(err);
        });
    }

    /**
    * Fetch all the active document categories
    */
    async getDocumentCategory() {
        if (this.documentCategorySub) {
            this.documentCategorySub.unsubscribe();
        }
        this.isFilterApply = true;
        const reqObj = {
            'where': { 'isActive': true },
            "limit": this.itemsPerBatch,
            'skip': this.offset,
            'globalFilter': this.globalFilters
        };
        if (this.viewAsPartner) {
            reqObj['viewAsPartner'] = true;
            reqObj['partnerAccountId'] = this.viewAsInfo.account.sfdcId;
        }
        this._preloaderService.showPreloader();
        this.documentCategorySub = this._documentCategoryApi.getDataForFolderView(reqObj).subscribe((documentCategories: any) => {
            documentCategories = this.modifyData(documentCategories);
            if (documentCategories && documentCategories.length < this.itemsPerBatch) {
                this.noRecords = true;
            }
            if (!this.isLoadMore) {
                this.data = documentCategories;
            } else {
                documentCategories.forEach(c => {
                    this.data.push(c);
                });
                this.data = [...this.data];
            }
            this._preloaderService.hidePreloader();
        }, error => {
            console.log('error==>>>', error);
            this._preloaderService.hidePreloader();
        });

    }

    async getJobIds() {
        let filters = this.globalFilters
        if (this.accessType === 'vendor') {
            if (filters && Object.keys(filters).length) {
                filters['Vendor__c'] = { inq: [this.accountId] }
            } else {
                filters = { Vendor__c: { inq: [this.accountId] } }
            }
        } else  if (this.accessType === 'partner') {
            if (filters && Object.keys(filters).length) {
                filters['CKSW_BASE__Account__c'] = { inq: [this.accountId] }
            } else {
                filters = { CKSW_BASE__Account__c: { inq: [this.accountId] } }
            }
        }
        const filterObj = {
            where: filters,
            fields: ["id"],
            order: 'createdAt desc',
            skip: 0,
            limit: 500
        };
        return new Promise(resolve => {
            this._jobApi.find(filterObj).subscribe(
                jobIds => {
                    let ids = [];
                    jobIds.forEach(item => {
                        ids.push(item['id']);
                    })
                    resolve(ids);
                }, err => {
                    resolve([]);
                }
            )
        })
    }


    /**
     * Method to refresh the file manager  table list view
     */
    refreshDataTable() {
        // this.documentList = [];
        this.reInitialize()
        this.getDocumentList();
    }

    /**
     * Fetch all the active document titles based on document categoryID
     */
    async getDocumentTitle() {
        if (this.documentTitleSub) {
            this.documentTitleSub.unsubscribe();
        }

        let jobIds: any;
        if (this.globalFilters && Object.keys(this.globalFilters).length) {
            jobIds = await this.getJobIds();
        } else if (this.accessType === 'vendor' || this.accessType === 'partner') {
            jobIds = await this.getJobIds();
        }

        const reqObj = {
            'where': { 'documentCategoryId': this.categoryId, 'isActive': true },
            'fields': ['id', 'title'],
            'include': [
                {
                    'relation': 'document',
                    'scope': {
                        'fields': ['id', 'title']
                    }
                }
            ],
            "limit": this.itemsPerBatch,
            'skip': this.offset
        }

        if (jobIds) {
            reqObj['include'][0]['scope']['where'] = { 'context.jobId': { '$in': jobIds } };
        }

        this._preloaderService.showPreloader();
        this.documentTitleSub = this._documentTitleApi.find(reqObj).subscribe(
            documentTitles => {
                documentTitles = this.modifyData(documentTitles);
                if (documentTitles && documentTitles.length < this.itemsPerBatch) {
                    this.noRecords = true;
                }
                if (!this.isLoadMore) {
                    this.data = documentTitles;
                } else {
                    documentTitles.forEach(c => {
                        this.data.push(c);
                    });
                    this.data = [...this.data];
                }
                this._preloaderService.hidePreloader();
            }, error => {
                console.log('error==>>>', error);
                this._preloaderService.hidePreloader();
            }
        );
    }

    /**
     * Prepare data for folder view
     * @param data document category and title data
     */
    modifyData(data) {
        if (data && data.length) {
            data.forEach(element => {
                element['_id'] = element.id;
                element['categoryId'] = this.viewType === 'categoryView' ? element.id : '';
                element['subCategoryId'] = this.viewType !== 'categoryView' ? element.id : '';
                element['name'] = (element.title) ? element.title : '';
                element['titleCount'] = (element.documentTitle && element.documentTitle.length > 0) ? element.documentTitle.length : 0;
                element['fileCount'] = (element.document && element.document.length > 0) ? element.document.length : 0;
                if (element.documentTitle && element.documentTitle.length) {
                    element.documentTitle.forEach(subElement => {
                        subElement['title'] = (subElement.title) ? subElement.title : '';
                    })
                }
            });

            // Modify data if filter apply
            if (this.isFilterApply) {
                let sum, tempList;
                data = data.filter((listItem) => {
                    sum = 0;
                    if (listItem.documentTitle && listItem.documentTitle.length > 0) {
                        tempList = listItem.documentTitle.filter((documentItem) => {
                            if (documentItem.document && (documentItem.document.length) > 0) {
                                sum += documentItem.document.length;
                                return true;
                            } else {
                                return false;
                            }
                        })
                        listItem.documentTitle = tempList;
                    }
                    if (sum === 0) {
                        return false;
                    } else {
                        return true;
                    }
                })
            }
            return data;
        } else {
            return [];
        }
    }

    onBreadCrumbClick(obj) {
        let queryParams = {};
        if (obj !== 'home') {
            this.viewType = 'categoryView';
            queryParams = { queryParams: { cId: obj['categoryId'] } };
        } else {
            this.currentLevel = 'one';
        }
        this._router.navigate(['app', 'file-manager', 'folder-view'], queryParams);
    }

    loadMoreRecords(event) {
        this.isLoadMore = true;
        if (event === 'categoryView') {
            this.offset = this.data.length;
            this.getDocumentCategory();
        } else if (event === 'titleView') {
            this.offset = this.data.length;
            this.getDocumentTitle();
        } else {
            this.offset = this.documentList.length;
            this.getDocumentList();
        }
    }

    ngOnDestroy() {
        if (this.globalFilterSub) {
            this.globalFilterSub.unsubscribe();
        }
        if (this.documentSub) {
            this.documentSub.unsubscribe();
        }
        if (this.documentTitleSub) {
            this.documentTitleSub.unsubscribe();
        }
        if (this.documentCategorySub) {
            this.documentCategorySub.unsubscribe();
        }
    }
}