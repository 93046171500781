import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoopBackAuth } from './../sdk/services/core/auth.service';
import { Angulartics2 } from 'angulartics2';

@Injectable()
export class AuthorizationService implements HttpInterceptor {

    constructor(
        private router: Router,
        private auth: LoopBackAuth,
        private _angulartics2: Angulartics2
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.error) {
                this._angulartics2.eventTrack.next({
                    action: err.name,
                    properties: {
                        errorCode: err.status,
                        errorUrl: err.url,
                        errorMessage: err.message,
                    },
                });
                // auto logout if 401 response returned from api when token got expired
                if (err.status === 401 && err.error === 'Invalid token, or no token supplied!') {
                    this.auth.clear();
                    localStorage.clear();
                    if (this.router.url.indexOf('login') === -1) {
                        if (this.router.url && this.router.url !== '/') {
                            localStorage.setItem('redirectUrl', this.router.url);
                            window.location.href = '/login?returnUrl=' + this.router.url;
                        } else {

                            window.location.href = '/login';
                        }
                    }

                }

            }
            return throwError(err);
        }))
    }
}
