import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerRequestedLookupComponent } from './customer-requested-lookup.component';
import { FormsModule } from '@angular/forms';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule
  ],
  declarations: [CustomerRequestedLookupComponent],
  exports: [CustomerRequestedLookupComponent]
})
export class CustomerRequestedLookupModule { }
