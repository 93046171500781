import { Subscription } from 'rxjs';
import { MapService } from 'shared/services/map.service';
import { Component, Input, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { CommonService } from 'shared/services/common.service';
import * as L from 'leaflet';
import 'leaflet-routing-machine';

@Component({
    selector: 'app-jobsite-routemap',
    templateUrl: './jobsite-routemap.component.html',
    styleUrls: ['./jobsite-routemap.component.css'],
    providers: [MapService]
})
export class JobSiteRouteMapComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() job;
    @Input() fromPage;
    direction: any;
    directionSubscription: Subscription;
    mapJob: any;
    routeControl: any;
    maplatLng: any;
    osmMapKey: any;
    // @ViewChild('mapJobId') mapContainer: ElementRef;

    constructor(private mapService: MapService, private _commonService: CommonService) {
    }

    ngOnInit() {
        if (this.job) {
            this.getDirections();
            this.prepareData();
        }
    }

    getLatLngFromAddress() {
        // this.setWorkerCoordinate();  this.maplatLng['origin'] = {lat : , lng:}
    }

    ngAfterViewInit() {
        this.osmMapKey = JSON.parse(localStorage.getItem('osmMapObjKey')).webApiKey;
        this.maplatLng = {
            // tslint:disable-next-line:max-line-length
            origin: { lat: (this.job && this.job.vendorsite && this.job.vendorsite.geolocation__Latitude__s) ? this.job.vendorsite.geolocation__Latitude__s : '', lng: (this.job && this.job.vendorsite && this.job.vendorsite.geolocation__Longitude__s) ? this.job.vendorsite.geolocation__Longitude__s : '' },
            destination: { lat: (this.job && this.job.jobsite && this.job.jobsite.geolocation__Latitude__s) ? this.job.jobsite.geolocation__Latitude__s : '', lng: (this.job && this.job.jobsite && this.job.jobsite.geolocation__Longitude__s) ? this.job.jobsite.geolocation__Longitude__s : '' }
        }
        if (this.job && this.job.worker && this.job.worker.contact && this.job.worker.contact.Geo_Location__Latitude__s) {
            // tslint:disable-next-line:max-line-length
            this.maplatLng['origin'] = {lat: this.job.worker.contact.Geo_Location__Latitude__s, lng: this.job.worker.contact.Geo_Location__Longitude__s}
        }
        this.loadmap();
    }

    loadmap() {
        if (this.fromPage && this.fromPage === 'fleet-management') {
            this.mapJob = L.map('fleetMapJobId').setView(this.maplatLng.destination, 13);
        } else {
            this.mapJob = L.map('mapJobId').setView(this.maplatLng.destination, 13);
        }
        // this.mapJob = L.map('mapJobId').setView(this.maplatLng.destination, 13);
        const url = this._commonService.fetchMapBoxTileLayerUrl();
        // tslint:disable-next-line:max-line-length
        L.tileLayer(url, {
            maxZoom: 19,
            // tslint:disable-next-line:max-line-length
            attribution: '&copy; <a href="javascrip:void(0)">OpenStreetMap</a>'
        }).addTo(this.mapJob);
        if (this.maplatLng.origin && this.maplatLng.origin.lat && this.maplatLng.destination && this.maplatLng.destination.lat) {
            this.setWorkerCoordinate();
        } else {
            this.bindMarker(this.maplatLng.destination);
        }
    }

    setWorkerCoordinate() {
        const _that = this;
        this.routeControl = L.Routing.control({
            lineOptions: {
                styles: [
                    { color: 'white', opacity: 0.9, weight: 8 },
                    { color: '#36baff', opacity: 1, weight: 6 }
                ]
            },
            waypoints: [
                { latLng: this.maplatLng.origin, name: 'worker', options: { allowUTurn: true } },
                { latLng: this.maplatLng.destination, name: 'Job', options: { allowUTurn: true } }
            ],
            units: 'imperial',
            showAlternatives: false,
            fitSelectedRoutes: true,
            waypointMode: 'snap',
            addWaypoints: false,
            routeWhileDragging: false,
            router: L.Routing.mapbox(_that.osmMapKey),
            createMarker: function (i, wp) {
                if (i === 0) {
                    if (_that.fromPage === 'fleet-management' && _that.job.workerProfileImage) {
                        return L.marker(wp.latLng, {
                            icon: L.divIcon({
                                className: 'custom-div-icon',
                                // tslint:disable-next-line:max-line-length
                                html: '<div class="marker-worker-pin"><div class="innerdiv"><img src="' + _that.job.workerProfileImage + '"></div></div> ',
                                iconSize: [51, 51],
                                iconAnchor: [25, 57]
                            })
                        })
                    } else {
                        return L.marker(wp.latLng, {
                            icon: L.icon({
                                iconUrl: 'assets/img/map/blue-pointer.png',
                                iconSize: [23, 38],
                                iconAnchor: [13, 38]
                            })
                        })
                    }
                } else {
                    return L.marker(wp.latLng, {
                        icon: L.icon({
                            iconUrl: 'assets/img/map/default-icon.png',
                            iconSize: [25, 39],
                            iconAnchor: [12, 38]
                        })
                    })
                }
            },
        }).addTo(this.mapJob);
        this.routeControl._container.style.display = 'None';
    }

    /**
   * 
   * @param destination single marker object
   */
    bindMarker(destination) {
        L.marker([destination.lat, destination.lng], {
            icon: L.icon({
                iconUrl: 'assets/img/map/default-icon.png',
                iconSize: [25, 39],
                iconAnchor: [12, 38]
            })
        }).addTo(this.mapJob)
    }

    getDirections() {
        this.directionSubscription = this.mapService.direction.subscribe(data => {
            this.direction = data;
            this.getLatLngFromAddress();
        });
    }

    prepareData() {
        // It's for jobsite location
        if (this.job.jobsite) {
            this.job.destination =
                this.job.jobsite.Street__c +
                this.comma(this.job.jobsite.City__c) +
                this.comma(this.job.jobsite.State__c) +
                this.comma(this.job.jobsite.Zip_Postal_Code__c) +
                this.comma(this.job.jobsite.Country__c);
        }
        // It's for worker location
        if (this.job.worker && this.job.worker.contact && this.job.worker.contact.MailingCountry) {
            this.job.origin =
            this.job.worker.contact.MailingStreet + ' ' +
            this.job.worker.contact.MailingCity +
            this.comma(this.job.worker.contact.MailingState) +
            this.comma(this.job.worker.contact.MailingPostalCode) +
            this.comma(this.job.worker.contact.MailingCountry);
        // It's for vendorsite location
        } else if (this.job.vendorsite) {
            this.job.origin =
                this.job.vendorsite.Street__c + ' ' +
                this.job.vendorsite.City__c +
                this.comma(this.job.vendorsite.State__c) +
                this.comma(this.job.vendorsite.Zip_Postal_Code__c) +
                this.comma(this.job.vendorsite.Country__c);
        }

        this.mapService.setLocation(this.job.origin, this.job.destination);
    }

    comma(prop) {
        return prop ? ', ' + prop : '';
    }

    ngOnDestroy() {
        if (this.directionSubscription) {
            this.directionSubscription.unsubscribe();
        }
        // this.mapJob = undefined;
        if (this.mapJob) {
            // this.mapJob.off();
            this.mapJob.remove();
            // this.mapJob.invalidateSize()
            if (this.routeControl) {
                this.routeControl.remove();
            }
        }
    }
}
