import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PreloaderService } from '../../services/preloader.service';
import { AlertService } from '../../services/alert.service';
import { ModalService } from '../../services/modal.service';
import { CommonService } from '../../services/common.service';
import { Timecard, TimecardApi, JobApi } from '../../sdk';
import { AppStateService } from 'shared/services/app-state.service';
import * as accessRight from './../../views/vms/jobs/job-detail/job-detail.access';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MobileOS } from '../../models/static-list-data.service';
@Component({
    moduleId: module.id,
    selector: 'app-timecard-add',
    templateUrl: './timecard-add.component.html',
    styleUrls: ['./timecard-add.component.css']
})

export class TimecardAddComponent implements OnInit, OnDestroy {

    @Input() modelName: string;
    @Input() modelId: string;
    @Input() timecardId: string;
    @Input() timecardSfdcId: string;
    @Input() source: string;
    @Input() timeZone = '';
    // tslint:disable-next-line:no-output-rename
    @Output('saved') loadedTimecard = new EventEmitter();
    @Output() updateData: EventEmitter<any> = new EventEmitter;

    @ViewChild('confirmBoxModal') confirmBoxModal: ElementRef; // For Update Appointmet modal used by workflow

    private sub: any;
    error: string;
    timecardForm: FormGroup;
    timecard: Timecard;
    sfdcId: any;
    worker: any;
    workersTypeahead = new EventEmitter<string>();
    minTimeInDate: any;
    minTimeOutDate: any;
    defaultCheckInTime: any;
    defaultCheckOutTime: any;
    minTimeIncurredDate: any
    timecardFields: any;
    mode: any;
    roleSub: any;
    dataToPapulate: any;
    allowedNewTimeCard = false;
    newTimecardData: any;
    modalRef: any;
    confirmationMessage: string;
    confirmationBoxTitle: string;
    mobileOs = [...MobileOS];

    constructor(
        private _cd: ChangeDetectorRef,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private _timeCardApi: TimecardApi,
        private _alertService: AlertService,
        private _modalService: ModalService,
        private _jobApi: JobApi,
        private _appState: AppStateService,
        private _preloaderService: PreloaderService,
        private _commonService: CommonService,
        private _ngbModal: NgbModal
    ) {
        this.sub = this.route.params.subscribe(params => {
            this.sfdcId = params['id'];
        });
    }

    ngOnInit() {
        this.roleSub = this._appState.getJobDetailUserRole().subscribe(role => {
            if (role && role['userRole']) {
                this.mode = accessRight.access[role['userRole']]['timecard']['timecard'];
            }
        })
        this.error = '';
        this.worker = {};
        if (this.modelId) {
            this.buildForm();
            if (this.timecardId || this.timecardSfdcId ) {
                this.getTimecardData();
            } else {
                this.getNewTimeCardData();
            }
        } else {
            this.error = 'Job not found';
        }
    }

    ngOnDestroy() {
        if (this.roleSub) {
            this.roleSub.unsubscribe();
        }
    }

    getNewTimeCardData() {
        const reqObj = {
            mode: 'New',
            jobId: this.modelId
        }
        this._timeCardApi.getTimecardData(reqObj).subscribe(
            res => {
                if (res) {
                    this.newTimecardData = res.data;
                    this.allowedNewTimeCard = res.data.allowedNewTimecard;
                    if (!res.data.allowedNewTimecard) {
                        this.error = res.data.message;
                    } else {
                        if (res.data.worker) {
                            this.worker['sfdcId'] = res.data['worker']['sfdcId'];
                            this.worker['Name'] = res.data['worker']['Name'];
                        }
                        if (res.data.lastCheckOutTime) {
                            // this.minTimeInDate = this.getVendorTime(res.data.lastCheckOutTime);
                            const lastCheckOutTime = this._commonService.applyNewTimeZone(res.data.lastCheckOutTime,this.timeZone) ;
                            this.minTimeInDate = this._commonService.addTime(lastCheckOutTime, 1, 'm', true).toISOString();
                            this.minTimeOutDate = this._commonService.addTime(this.minTimeInDate, 1, 'm', true).toISOString();
                            this.minTimeIncurredDate = new Date(this.minTimeOutDate);
                            this.minTimeIncurredDate.setHours(0);
                            this.minTimeIncurredDate.setMinutes(0);
                            this.minTimeIncurredDate.setSeconds(0);
                            this.newTimecardData.minTimeIncurredDate = this.minTimeIncurredDate;
                            this.newTimecardData.minTimeInDate = this.minTimeInDate;
                            this.newTimecardData.minTimeOutDate = this.minTimeOutDate;
                            this.defaultCheckOutTime = this.newTimecardData.minTimeOutDate;
                        }
                    }
                }
            },
            err => {
                this.error = err.message;
            }
        )
    }

    getTimecardData() {
        const fields = ['Name', 'Any_Observation_or_Suggestions_Notes__c', 'Customer_Site_Signoff_Name__c', 'Incurred_Date__c',
            'Service_Dispatch__c', 'Status__c', 'Vendor_Time_Card_Notes_Tasks_Performed__c', 'Vendor_Time_Card_Time_Out_Actual__c',
            'Vendor_Time_Card_Time_in_Actual__c', 'Worker__c', 'createdAt', 'id', 'sfdcId', 'updatedAt', 'Purchase_Order_Ref__c',
            'Equipment_Serial_Performed_on__c', 'Equipment_Make_Model_Performed_on__c', 'Worker_Mobile_OS_Android_iOS__c'];
        const include = [
            { relation: 'worker', scope: { fields: ['sfdcId', 'Name'] } },
            { relation: 'job', scope: { fields: ['Iron_Job_num__c', 'submittedTimecard', 'systemTimecard'] } },
            { relation: 'purchaseOrder', scope: { fields: ['Work_Order_num__c'] } }
        ];

        if (this._appState.getAccessType() === 'internal') {
            fields.push('Vendor__c');
            include.push({ relation: 'vendor', scope: { fields: ['Name', 'Timesheet_Offset__c'] } });
        }

        let filter = {}
        if (this.timecardId){
            filter = {where: {id: this.timecardId}, fields: fields, include: [include]}
        } else if (this.timecardSfdcId){
            filter = {where: {sfdcId: this.timecardSfdcId}, fields: fields, include: [include]}
        }

        if (filter && filter['where']) {
            this._preloaderService.showPreloader();
            this._timeCardApi.findOne(filter).subscribe(
                data => {
                    this.allowedNewTimeCard = true;
                    this.worker = data['worker'];
                    this.dataToPapulate = {
                        Name: data['Name'],
                        worker: data['worker'],
                        job: data['job'],
                        purchaseOrder: data['purchaseOrder'],
                        vendor: data['vendor']
                    };
                    delete data['job'];
                    delete data['purchaseOrder'];
                    delete data['vendor'];
                    delete data['worker'];
                    this.timecardFields = data;
                    // setting checkout time as current time if it is not availble and source is checkout
                    if (this.source === 'checkout-btn' && !this.timecardFields.Vendor_Time_Card_Time_Out_Actual__c) {
                        const currTime = new Date();
                        const timeDiff = this._commonService.getTimeDiff(
                            this.timecardFields.Vendor_Time_Card_Time_Out_Actual__c, this.timecardFields.Vendor_Time_Card_Time_in_Actual__c);
                        this.timecardFields.Vendor_Time_Card_Time_Out_Actual__c = timeDiff > 0 ? currTime : null;
                    }
                    this.setTimecardValues();
                    this._preloaderService.hidePreloader();
                },
                err => {
                    this.error = err.message;
                    this._preloaderService.hidePreloader();
                }
            );
        } else {
            this.error = 'Error in fetching Timecard';

        }
    }


    /**
     * Formcontrol custom validator - to check white spaces
     * @param control {FormControl}
     */
    noWhitespaceValidator(control: FormControl) {
        const isWhitespace = (control.value || '').trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true };
    }

    buildForm() {
        this.timecardForm = this.fb.group({
            Service_Dispatch__c: [this.modelId],
            Incurred_Date__c: ['', [Validators.required]],
            Vendor_Time_Card_Notes_Tasks_Performed__c: ['', [Validators.required, this.noWhitespaceValidator,]],
            Any_Observation_or_Suggestions_Notes__c: [''],
            Vendor_Time_Card_Time_in_Actual__c: [{
                value: '',
                disabled: !this.mode['Vendor_Time_Card_Time_in_Actual__c'].write
            }, [Validators.required]],
            Vendor_Time_Card_Time_Out_Actual__c: [{
                value: '',
                disabled: !this.mode['Vendor_Time_Card_Time_Out_Actual__c'].write
            }, [Validators.required]],
            Customer_Site_Signoff_Name__c: [{
                value: '',
                disabled: !this.mode['Customer_Site_Signoff_Name__c'].write
            }, [Validators.required, this.noWhitespaceValidator, Validators.maxLength(100)]],
            Equipment_Serial_Performed_on__c: [{
                value: '',
                disabled: !this.mode['Equipment_Serial_Performed_on__c'].write
            }, [Validators.required, this.noWhitespaceValidator, Validators.maxLength(255)]],
            Equipment_Make_Model_Performed_on__c: [{
                value: '',
                disabled: !this.mode['Equipment_Make_Model_Performed_on__c'].write
            }, [Validators.required, this.noWhitespaceValidator, Validators.maxLength(255)]],
            Worker_Mobile_OS_Android_iOS__c: [{
                value: '',
                disabled: !this.mode['Worker_Mobile_OS_Android_iOS__c'].write
            }, [Validators.required, this.noWhitespaceValidator, Validators.maxLength(255)]]
        });
    }

    setTimecardValues() {
        if (this.timecardFields) {
            this.timecardForm.controls['Service_Dispatch__c'].setValue(this.timecardFields['Service_Dispatch__c']);
            this.timecardForm.controls['Incurred_Date__c'].setValue(
                this.getVendorTime(this.timecardFields.Vendor_Time_Card_Time_in_Actual__c));
            this.timecardForm.controls['Vendor_Time_Card_Time_in_Actual__c'].setValue(
                this.getVendorTime(this.timecardFields.Vendor_Time_Card_Time_in_Actual__c));
            this.timecardForm.controls['Vendor_Time_Card_Time_Out_Actual__c'].setValue(
                this.getVendorTime(this.timecardFields.Vendor_Time_Card_Time_Out_Actual__c));
            this.timecardForm.controls['Customer_Site_Signoff_Name__c'].setValue(this.timecardFields['Customer_Site_Signoff_Name__c']);
            this.timecardForm.controls['Equipment_Serial_Performed_on__c'].setValue(this.timecardFields['Equipment_Serial_Performed_on__c']);
            this.timecardForm.controls['Equipment_Make_Model_Performed_on__c'].setValue(this.timecardFields['Equipment_Make_Model_Performed_on__c']);
            this.timecardForm.controls['Worker_Mobile_OS_Android_iOS__c'].setValue(this.timecardFields['Worker_Mobile_OS_Android_iOS__c']);
            this.timecardForm.controls['Vendor_Time_Card_Notes_Tasks_Performed__c'].setValue(
                this.timecardFields['Vendor_Time_Card_Notes_Tasks_Performed__c']
            );
            this.timecardForm.controls['Any_Observation_or_Suggestions_Notes__c'].setValue(
                this.timecardFields['Any_Observation_or_Suggestions_Notes__c']
            );
            if (this.timecardFields['worker'] && this.timecardFields['worker']['Name']) {
                this.worker['sfdcId'] = this.timecardFields['worker']['sfdcId'];
                this.worker['Name'] = this.timecardFields['worker']['Name'];
            }
            this.minTimeInDate = this.timecardForm.controls['Vendor_Time_Card_Time_in_Actual__c'].value;
            // this.minTimeOutDate = this.timecardForm.controls['Vendor_Time_Card_Time_Out_Actual__c'].value ?
            //     this.timecardForm.controls['Vendor_Time_Card_Time_Out_Actual__c'].value :
            //     this._commonService.addTime(this.minTimeInDate, 1, 'm').toISOString();

            this.minTimeOutDate = this.minTimeInDate ?
                this._commonService.addTime(this.minTimeInDate, 1, 'm', true).toISOString() : '';
            this.defaultCheckOutTime = this.timecardForm.controls['Vendor_Time_Card_Time_Out_Actual__c'].value ?
                this.timecardForm.controls['Vendor_Time_Card_Time_Out_Actual__c'].value : this.minTimeOutDate;

        }
        this._cd.detectChanges();
    }

    saveTimecard() {
        let isFinalTimecard = false;
        let reqObj = {};
        if (this.timecardFields) {
            reqObj = this.timecardFields;
            delete reqObj['worker'];
        }
        if (this.timeZone) {
            this.timecardForm.controls.Vendor_Time_Card_Time_in_Actual__c.setValue(
                this._commonService.setNewTimeZone(this.timecardForm.controls['Vendor_Time_Card_Time_in_Actual__c'].value, this.timeZone));
            this.timecardForm.controls.Vendor_Time_Card_Time_Out_Actual__c.setValue(
                this._commonService.setNewTimeZone(this.timecardForm.controls['Vendor_Time_Card_Time_Out_Actual__c'].value, this.timeZone));
        }

        for (const key in this.timecardForm.value) {
            if (key) {
                reqObj[key] = typeof this.timecardForm.value[key] === "string" ? this.timecardForm.value[key].trim() : this.timecardForm.value[key];
            }
        }
        reqObj['Worker__c'] = this.worker['sfdcId'];
        for (const key in this.mode) {
            if (this.mode[key]['write'] === false && reqObj[key]) {
                delete reqObj[key]
            }
        }
        if (this.source === 'checkout-btn') {
            this.confirmationBoxTitle = 'Warning!!';
            this.confirmationMessage = 'Is this your final timecard ?';
            this.modalRef = this._ngbModal.open(this.confirmBoxModal, {
                backdrop: 'static'
            });
            this.modalRef.result.then((userResponse) => {
                isFinalTimecard = userResponse;
                if (isFinalTimecard) {
                    reqObj['Final_Timecard__c'] = true;
                }
                this.updateTimecard(reqObj);
            });
        } else {
            if (this.source === 'submit-timecard') {
                reqObj['Final_Timecard__c'] = true;
            }
            this.updateTimecard(reqObj);
        }
    }

    updateTimecard(reqObj) {
        this._preloaderService.showPreloader();
        if (reqObj['id']) {
            this._timeCardApi.patchAttributes(reqObj['id'], reqObj).subscribe(
                data => {
                    if (data) {
                        data['worker'] = this.worker['Name'];
                        const _data = Object.assign(data, this.dataToPapulate);
                        this.loadedTimecard.emit({ type: 'Edit', _data: _data });
                        this._modalService.closed();
                        this._preloaderService.hidePreloader();
                        this._alertService.success('Timecard has been updated successfully.');
                    } else {
                        this.error = 'Failed to save timecard.';
                        this._preloaderService.hidePreloader();
                    }
                },
                err => {
                    this._preloaderService.hidePreloader();
                    this.error = err.message;
                }
            );
        } else {
            this._timeCardApi.createTimecard(reqObj).subscribe(
                data => {
                    if (data) {
                        data['worker'] = this.worker['Name'];
                        this.loadedTimecard.emit({ type: 'Add', _data: data });
                        this._modalService.closed();
                        this._alertService.success('Timecard has been created successfully.');
                        this._commonService.setTimeCardAddSubject(true);
                    } else {
                        this.error = 'Failed to save timecard.';
                    }
                    this._preloaderService.hidePreloader();
                },
                err => {
                    this._preloaderService.hidePreloader();
                    this.error = err.message;
                }
            );
        }
    }

    transform(value: string, format: string) {
        const datePipe = new DatePipe('en-US');
        value = datePipe.transform(value, 'shortDate');
        return new Date(value);
    }

    onDateChange(e, source) {
        const newValue = e.value;
        const newValueStr = newValue.toISOString();
        this.timecardForm.controls[source].setValue(newValue);
        

        // if (source === 'Incurred_Date__c') {
        // this.timecardForm.controls.Vendor_Time_Card_Time_in_Actual__c.setValue(
        //     this.setDate(this.timecardForm.controls.Vendor_Time_Card_Time_in_Actual__c.value, newValue));
        // this.minTimeInDate = this.transform(newValue, 'shortDate');
        // this.minTimeOutDate = this._commonService.addTime(
        //     this.timecardForm.controls.Vendor_Time_Card_Time_in_Actual__c.value, 1, 'm', true).toISOString();
        // if (this.newTimecardData && this.newTimecardData.minTimeInDate) {
        //     if (this.newTimecardData.minTimeInDate > newValueStr) {
        //         // this.timecardForm.controls.Vendor_Time_Card_Time_in_Actual__c.setValue(this.setDate(
        //         //     this.timecardForm.controls.Vendor_Time_Card_Time_in_Actual__c.value, this.newTimecardData.minTimeInDate));
        //         this.timecardForm.controls.Vendor_Time_Card_Time_in_Actual__c.setValue(this.newTimecardData.minTimeInDate);
        //         this.defaultCheckOutTime = (this.newTimecardData.minTimeOutDate <
        //             this.timecardForm.controls.Vendor_Time_Card_Time_in_Actual__c.value) ? this._commonService.addTime(
        //                 this.timecardForm.controls.Vendor_Time_Card_Time_in_Actual__c.value, 1, 'm', true).toISOString() :
        //             this.newTimecardData.minTimeOutDate;
        //     }
        //     this.minTimeInDate = (this.newTimecardData.minTimeInDate < newValueStr) ?
        //         this.transform(newValueStr, 'shortDate') : this.newTimecardData.minTimeInDate;
        //     this.minTimeOutDate = (this.newTimecardData.minTimeOutDate < newValueStr) ?
        //         newValue : this.newTimecardData.minTimeOutDate;

        // }
        // if (newValueStr > this.timecardForm.controls['Vendor_Time_Card_Time_Out_Actual__c'].value) {
        //     this.timecardForm.controls.Vendor_Time_Card_Time_Out_Actual__c.setValue(null);
        // } else {
        //     this.minTimeOutDate = this._commonService.addTime(
        //         this.timecardForm.controls.Vendor_Time_Card_Time_in_Actual__c.value, 1, 'm', true).toISOString();
        // }
        // } else 
        if (source === 'Vendor_Time_Card_Time_in_Actual__c') {
            this.timecardForm.controls.Incurred_Date__c.setValue(
                this.setDate(this.timecardForm.controls.Incurred_Date__c.value, newValue));
            this.minTimeOutDate = this._commonService.addTime(newValue, 1, 'm', true).toISOString();
            this.defaultCheckOutTime = (this.defaultCheckOutTime < newValueStr) ?
                this._commonService.addTime(newValueStr, 1, 'm', true).toISOString()
                : this.defaultCheckOutTime;
            if (this.timecardForm.controls['Vendor_Time_Card_Time_in_Actual__c'].value >
                new Date(this.timecardForm.controls['Vendor_Time_Card_Time_Out_Actual__c'].value)) {
                this.timecardForm.controls.Vendor_Time_Card_Time_Out_Actual__c.setValue(null);
            }
        } else if (source === 'Vendor_Time_Card_Time_Out_Actual__c') {
            this.defaultCheckOutTime = newValueStr;
        }
        this._cd.detectChanges();
    }

    resetForm() {
        this.buildForm();
        this.minTimeInDate = '';
        this.minTimeOutDate = '';
        if (this.timecardFields && this.timecardId) {
            this.setTimecardValues();
        } else {
            this.minTimeIncurredDate = this.newTimecardData.minTimeIncurredDate ? this.newTimecardData.minTimeIncurredDate : '';
            this.minTimeInDate = this.newTimecardData.minTimeInDate ? this.newTimecardData.minTimeInDate : '';
            this.minTimeOutDate = this.newTimecardData.minTimeOutDate ? this.newTimecardData.minTimeOutDate : '';
        }
    }

    getVendorTime(date) {
        date = date ? new Date(date) : '';
        return date ? this._commonService.applyNewTimeZone(date.toISOString(), this.timeZone) : null;
    }

    setDate(date1, date2) {
        const dt1 = date1 ? new Date(date1) : new Date();
        const dt2 = date2 ? new Date(date2) : new Date();
        dt1.setDate(dt2.getDate());
        dt1.setMonth(dt2.getMonth());
        dt1.setFullYear(dt2.getFullYear());
        return dt1;
    }
}