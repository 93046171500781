import { Component, Input, OnInit } from '@angular/core';
import { NotificationService } from 'shared/services/notification.service';
import { PreloaderService } from 'shared/services/preloader.service';

@Component({
  selector: 'app-rhs-notifications-list',
  templateUrl: './rhs-notifications-list.component.html',
  styleUrls: ['./rhs-notifications-list.component.css']
})
export class RhsNotificationsListComponent implements OnInit {

  @Input() jobId = '';
  notifications = [];
  page = {
    size: 20,
    totalElements: 0,
    totalPages: 0,
    pageNumber: 1
  }

  private _statusCss = {
    p1: 'p1',
    p2: 'p2',
    p3: 'p3',
    p4: 'p4',
    p5: 'p5',
    p6: 'p6'
  };
  isLoadMore = false;

  constructor(
    private _notificationService: NotificationService,
    private _loader: PreloaderService,
  ) { }

  ngOnInit() {
    this.getNotifications(this.page)
  }

  getNotifications(offset) {
    this._loader.showPreloader();
    this._notificationService.getNotifications(
      offset["pageNumber"],
      this.page.size,
      {
        entity_name: "Job"
      },
      { id: { 'inq': this.jobId } }
    ).
      then((data: any) => {
        this._loader.hidePreloader();
        if (data && data['data']) {
          data['data'].forEach(element => {
            element.expanded = false;
          });
          this.notifications = [...this.notifications, ...data['data']];
          this.page.pageNumber = offset['pageNumber'] + 1;
          this.isLoadMore = (this.notifications && this.notifications.length < data['total']) ? true : false;

        }
      }).catch((err: any) => {
        this._loader.hidePreloader();
        console.log('Error!  ', err);
      })
  }

  getPriorityClass(priority: string = "") {
    priority = (priority && priority.length && priority.trim()) ? priority.trim().slice(0, 2).toLowerCase() : ''
    if (this._statusCss.hasOwnProperty(priority)) {
      return this._statusCss[priority];
    } else {
      return "default-priority";
    }
  }

  expandTheCard(item) {
    if (item) {
      this.notifications.forEach(element => {
        if (item.activity_id === element.activity_id) {
          if (!item.marked_read) {
            this.markReadById(item);
          }
          element.expanded = !element.expanded;
        } else {
          element.expanded = false;
        }
      })
      // const obj = this.notifications.find(obj => obj.activity_id === notification.activity_id);
      // if (obj) {
      //   obj.expanded = !obj.expanded;
      // }
    }
  }
  loadMoreCardViewData() {
    this.getNotifications(this.page);
  }

  markReadById(item: any) {
    this._loader.showPreloader();
    this._notificationService.readByactivityId(item.activity_id)
      .then(res => {
        item.marked_read = true;
        this._loader.hidePreloader();
      }).catch(err => {
        this._loader.hidePreloader();
        console.log(err);
      });
  }

}
