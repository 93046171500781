import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { GlobalFilterService } from 'shared/services/global-filter.service';
import { PreloaderService } from 'shared/services/preloader.service';
import { DocumentTitleApi, DocumentApi } from 'shared/sdk';
import { CommonService } from 'shared/services/common.service';


@Component({
    selector: 'file-manager-list-card',
    templateUrl: 'file-manager-list-card.component.html',
    styleUrls: ['file-manager-list-card.component.css']
})
export class FileManagerListCardComponent implements OnInit, OnDestroy {

    @Output() loadMorePage: EventEmitter<any> = new EventEmitter;
    @Input() viewType: string = 'categoryView';
    @Input()
    set dataConfig(e) {
        if (e && e['data']) {
            this.sortDataByCount(e['data']);
            this.noRecords = (e['_noRecords']) ? e['_noRecords'] : false;
        }
    }
    @Input() set defaultConfig(e) {
        this.itemsPerPage = e['_itemsPerPage'];
    }
    itemsPerPage = 10;
    noRecords = false;
    listData: any = [];
    @Output() selected = new EventEmitter<any>();
    queryParams = {};
    categoryId: any;
    titleId: any;
    loadingIndicator = true;
    sidebarStateSub: Subscription;
    childrow: any = [];
    rowDatas: any = [];
    breadCrumbs: any;
    currentLevel = '';

    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private _globalFilterService: GlobalFilterService,
        private _preloaderService: PreloaderService,
        private _documentTitleApi: DocumentTitleApi,
        private _documentApi: DocumentApi,
        private _commonService: CommonService
    ) {
        this._route.queryParams.subscribe(params => {
            this.initPages(params);

        });

        this.sidebarStateSub = this._globalFilterService.getSidebarState().subscribe(isOpened => {
            setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
            }, 250);
        })
    }

    ngOnInit() {
        this._commonService.saveCurrentPageAnalytics('File Manager', 'File Manager - Folder View');
    }

    initPages(params) {
        if (params['cId'] && !params['sTitle']) {
            this.categoryId = params['cId'];
        } else if (params['cId'] && params['sTitle']) {
            this.categoryId = params['cId'];
            this.titleId = params['sTitle'];
        } else {
            this.categoryId = '';
            this.titleId = '';
        }
    }

    /**
     * order by count
     * @param list document category and document title list
     */

    sortDataByCount(list) {
        const sortColumn = this.viewType === 'categoryView' ? 'titleCount' : 'fileCount';
        if (list && list.length) {
            this.listData = list.sort((a, b) => (a[sortColumn] < b[sortColumn]) ? 1 : -1);
        } else if (list && list.length == 0) {
            this.listData = [];
        }
        this.loadingIndicator = false;
    }

    /**
     * 
     * @param item Output parameter
     */
    onClickItem(item) {
        if ((this.viewType == 'categoryView' && item.titleCount) || (this.viewType != 'categoryView' && item.fileCount)) {
            if (this.categoryId) {
                this.queryParams = { queryParams: { cId: this.categoryId, sTitle: item['id'] } };
            } else {
                this.queryParams = { queryParams: { cId: item['id'] } };
            }
            this._router.navigate(['app', 'file-manager', 'folder-view'], this.queryParams);
        } else {
            // this._router.navigate(['app', 'file-manager', 'folder-view'], this.queryParams);
        }
    }

    onClickSubItem(item) {
        if (item.document.length) {
            /*if (this.categoryId) {
                this.queryParams = { queryParams: { cId: this.categoryId, sTitle: item['id'] } };
            } else {
                this.queryParams = { queryParams: { cId: item['id'] } };
            }*/
            this.queryParams = { queryParams: { cId: item.documentCategoryId, sTitle: item['id'] } };
            this._router.navigate(['app', 'file-manager', 'folder-view'], this.queryParams);
        } else {
            // this._router.navigate(['app', 'file-manager', 'folder-view'], this.queryParams);
        }
    }

    loadMoreRecords() {
        this.loadMorePage.emit(this.viewType)
    }

    ngOnDestroy() {
        if (this.sidebarStateSub) {
            this.sidebarStateSub.unsubscribe();
        }
    }
}
