import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { debounceTime, distinctUntilChanged, switchMap, filter } from 'rxjs/operators';
import { JobApplicationApi } from 'shared/sdk';

@Component({
    selector: 'app-job-application-lookup',
    templateUrl: './job-application-lookup.component.html',
    styleUrls: ['./job-application-lookup.component.css']
})
export class JobApplicationLookupComponent implements OnInit {

    @Input()
    set resetData(isClear) {
        if (isClear) {
            this.applicationSelectedList = [];
            // this.applicationOptionList = [];
        }
    }

    @Input() placeholder = 'Application';
    @Input() isdisabled;

    @Input()
    set isMultipleSelected(e) {
        this._multipleSelect = e;
        this.maxSelectedItems = e ? 20 : 1;
    }

    @Output() getApplicationObj: EventEmitter<any> = new EventEmitter;
    public searchInput = new EventEmitter<string>();

    @Input()
    set setApplicationObj(params) {
        this.applicationOptionList = [];
        this.clearApplications();
        this.whereObj = this.whereObj && Object.keys(this.whereObj).length ? this.whereObj : {};
        if (params && Object.keys(params).length) {
            if (params['PgMO_Projects__c'] && params['PgMO_Projects__c'].length) {
                this.whereObj['PgMO_Projects__c'] = { inq: params['PgMO_Projects__c'] };
            }
            if (params['Account__c'] && params['Account__c'].length) {
                this.whereObj['Account__c'] = { inq: params['Account__c'] };
            }
            if (params['PgMO_Milestones__c'] && params['PgMO_Milestones__c'].length) {
                this.whereObj['PgMO_Milestones__c'] = { inq: params['PgMO_Milestones__c'] };
            }
            if (params['rmc'] && params['rmc'].length) {
               this.whereObj = {ts2__Agency__c:params['rmc'][0]['ts2__Agency__c']};
            }
        }
    }

    selectedApplication: any;
    whereObj = {};
    _multipleSelect = true;
    maxSelectedItems = 20;

    applicationOptionList = [];
    applicationSelectedList = [];



    constructor(
        private _jobApplicationApi: JobApplicationApi
    ) {
    }

    ngOnInit() {
        const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
        if (preselected && preselected['applications'] && preselected['applications'].length) {
            this.applicationSelectedList = preselected['applications'];
            this.onApplicationChange();
            const whereObj = this.whereObj;
            whereObj['sfdcId'] = { inq: this.applicationSelectedList };
            const filterObj = {
                where: whereObj,
                fields: ['id', 'sfdcId', 'Name'],
                order: 'createdAt desc',
                limit: 200
            }
            this._jobApplicationApi.find(filterObj).subscribe(data => {
                if (data && data.length) {
                    data.forEach((item: any) => {
                        item.label = item.Name;
                    })
                    this.applicationOptionList = data;
                } else {
                    this.applicationOptionList = [];
                }
            }, error => {
                this.applicationOptionList = [];
                console.log(error);
            })
        }
        this.getApplicationObj.emit(this.applicationSelectedList);
        this.getApplicationList();
        if (this.selectedApplication) {
            this.applicationSelectedList = this.selectedApplication && this.selectedApplication.sfdcId;
            this.formatSelectedApplications();
        }
    }

    getApplicationList() {
        this.searchInput
            .pipe(
                filter(search => search && search.trim().length > 2),
                debounceTime(200),
                distinctUntilChanged(),
                switchMap(search =>
                    this.getApplications(search)
                )
            ).subscribe(
                data => {
                    if (data && data.length) {
                        data.forEach((item: any) => {
                            item.label = item.Name;
                        })
                        this.applicationOptionList = data;
                    } else {
                        this.applicationOptionList = [];
                    }
                },
                err => {
                    console.log(err);
                    this.applicationOptionList = [];
                }
            );
    }


    getApplications(search) {
        this.whereObj['sfdcId'] = { 'neq': null };
        this.whereObj['Name'] = { like: '%' + search.trim() + '%', options: 'i' };
        const filterObj = {
            where: this.whereObj,
            fields: ['id', 'sfdcId', 'Name'],
            order: 'createdAt desc',
            limit: 200
        }
        return this._jobApplicationApi.find(filterObj);
    }

    formatSelectedApplications() {
        this.selectedApplication.label = this.selectedApplication.Name;
        this.applicationOptionList = [];
        this.applicationOptionList.push(this.selectedApplication);
        this.getApplicationObj.emit(this.applicationSelectedList);
    }

    onApplicationChange() {
        this.getApplicationObj.emit(this.applicationSelectedList);
    }

    clearApplications() {
        this.applicationSelectedList = [];
        this.getApplicationObj.emit([]);
    }
}
