import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { MilestoneTaskLookupComponent } from './milestone-task-lookup.component';

@NgModule({
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule
  ],
  declarations: [MilestoneTaskLookupComponent],
  exports: [MilestoneTaskLookupComponent]
})
export class MilestoneTaskLookupModule { }
