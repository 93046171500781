import { Component, OnInit, Input } from '@angular/core';
import { WorkOrderApi } from 'shared/sdk';
import { PreloaderService } from 'shared/services/preloader.service';
import { AppStateService } from 'shared/services/app-state.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-wo-detail-instructions',
  templateUrl: './wo-detail-instructions.component.html',
  styleUrls: ['./wo-detail-instructions.component.css']
})
export class WoDetailInstructionsComponent implements OnInit {
  errorMsg = '';
  workorder: any;
  accessType: any;
  caseType: any;
  jobAvailable = true;
  @Input() isjobsiteContactPage = false;
  @Input() set caseData(cd) {
    if (cd) {
      this.workorder = {};
      this.workorder['Job_Instructions_Service_Deliverables__c'] = (cd.workOrder && cd.workOrder.Job_Instructions_Service_Deliverables__c) ? cd.workOrder.Job_Instructions_Service_Deliverables__c : '';
      this.workorder['Job_Instructions_Deliverables_Summary__c'] = (cd.workOrder && cd.workOrder.Job_Instructions_Deliverables_Summary__c) ? cd.workOrder.Job_Instructions_Deliverables_Summary__c : '';
      this.workorder['Job_Instructions_Special_Instructions__c'] = (cd.workOrder && cd.workOrder.Job_Instructions_Special_Instructions__c) ? cd.workOrder.Job_Instructions_Special_Instructions__c : '';
      this.workorder['Job_Instructions_Training_Documents__c'] = (cd.workOrder && cd.workOrder.Job_Instructions_Training_Documents__c) ? cd.workOrder.Job_Instructions_Training_Documents__c : '';
      this.workorder['Job_Instructions_Required_Tools__c'] = (cd.workOrder && cd.workOrder) ? cd.workOrder.Job_Instructions_Required_Tools__c : '';
      if (cd.Job && cd.Job.jobInstruction) {
        this.jobAvailable = true;
        //this.workorder['Job_Instructions_Deliverables_Summary__c'] = cd.Job.jobInstruction.Job_Instructions_Deliverables_Summary__c;
        //this.workorder['Job_Instructions_Special_Instructions__c'] = cd.Job.jobInstruction.Job_Ins_Special_Service_Instructions__c;
        this.workorder['Job_Instructions_Service_Description__c'] = (cd.Job.jobInstruction && cd.Job.jobInstruction.Job_Instructions_Service_Description__c) ? cd.Job.jobInstruction.Job_Instructions_Service_Description__c : '';
        //this.workorder['Job_Instructions_Training_Documents__c'] = cd.Job.jobInstruction.Job_Instructions_Training_Documents__c;
        this.workorder['Special_Instructions__c'] = (cd.Job.jobInstruction && cd.Job.jobInstruction.Job_Instructions_Worker_Specific_Request__c) ? cd.Job.jobInstruction.Job_Instructions_Worker_Specific_Request__c : '';
        //this.workorder['Job_Instructions_Required_Tools__c'] = cd.Job.jobInstruction.Job_Instructions_Required_Tools__c;
      } else if (cd.project && cd.project.masterProject) {
        this.jobAvailable = false;
        //this.workorder['Job_Instructions_Deliverables_Summary__c'] = cd.project.masterProject.SOW_Description_Customer_Long__c;
        this.workorder['Job_Instructions_Special_Instructions__c'] = cd.X3PS_Vendor_Special_Instructions__c;
        //this.workorder['Job_Instructions_Training_Documents__c'] = cd.project.masterProject.Description__c;
        //this.workorder['Job_Instructions_Required_Tools__c'] = cd.project.masterProject.Required_Tools__c;
        this.workorder['Custom_work_order_instructions__c'] = cd.Custom_work_order_instructions__c;
      }
    }
  }
  @Input()
  set workOrderData(wo) {
    this.errorMsg = '';
    if ((wo && wo.id) || (wo && wo.jobInstruction)) {
      this.workorder = wo;
      this.workorder['Job_Instructions_Service_Description__c'] = (wo.jobOrderItems && wo.jobOrderItems.length && wo.jobOrderItems[0].job && wo.jobOrderItems[0].job.jobInstruction) ? wo.jobOrderItems[0].job.jobInstruction.Job_Instructions_Service_Description__c:'';
      this.workorder['Special_Instructions__c'] = (wo.jobOrderItems && wo.jobOrderItems.length && wo.jobOrderItems[0].job && wo.jobOrderItems[0].job.jobInstruction) ? wo.jobOrderItems[0].job.jobInstruction.Job_Instructions_Worker_Specific_Request__c:'';
    } else if (wo && wo.filter) {
      this.loadWorkOrderData(wo.filter);
    }
    else {
      this.errorMsg = 'No Data Available';
    }
  }
  constructor(
    private _preloaderService: PreloaderService,
    private _workOrderApi: WorkOrderApi,
    private _appState: AppStateService,
    private _activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.accessType = this._appState.getAccessType();
    // To check if view as PMS mode is activated.
    if (localStorage.getItem('viewAsInfo')) {
      const viewAsInfo = JSON.parse(localStorage.getItem('viewAsInfo'));
      const viewAsPartner = (viewAsInfo && viewAsInfo.viewAsType === 'Partner');
      this.accessType = (viewAsPartner) ? 'partner' : this.accessType;
    }
    this._activatedRoute.queryParams.subscribe(params => {
      if (params) {
        this.caseType = params.caseType; // initializing caseType from params;
        if(!params.JobNumber)
          this.jobAvailable = false;
      }
    })
  }

  loadWorkOrderData(whereFilter) {
    this._preloaderService.showPreloader();
    const filterObj = {
      fields: ['sfdcId', 'id', 'Name', 'Job_Instructions_Deliverables_Summary__c', 'Job_Instructions_Required_Tools__c',
        'Job_Instructions_Service_Deliverables__c', 'Job_Instructions_Special_Instructions__c', 'Special_Instructions__c',
        'Job_Instructions_Training_Documents__c'],
      where: whereFilter
    }
    this._workOrderApi.findOne(filterObj).subscribe(
      res => {
        if (res) {
          this.workorder = res;
        }
        this._preloaderService.hidePreloader()
      }, err => {
        this._preloaderService.hidePreloader()
      })
  }
}
