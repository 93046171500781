/* tslint:disable */
import {
  Jobsite,
  Worker,
  DepartmentRole,
  Department,
  Group,
  Program,
  Project2,
  QuoteLineManager,
  QuoteManager,
  Account,
  Contact,
  Conversation,
  Milestone,
  Task,
  Case,
  TeamShift,
  WorkOrder
} from '../index';

declare var Object: any;
export interface CSQDSessionDataInterface {
  "sfdcId"?: string;
  "Account__c"?: string;
  "Account_ID__c"?: string;
  "Application__c"?: string;
  "Application_Source__c"?: string;
  "Application_Stage__c"?: string;
  "Case_Number__c"?: string;
  "Contact__c"?: string;
  "Country__c"?: string;
  "Coverage_Hours_Account__c"?: string;
  "Coverage_Hours_MVVP__c"?: string;
  "Dispatch_Service_Profiles__c"?: string;
  "Dispatch_Service_Resolution_Status_FSE__c"?: string;
  "End_Date__c"?: string;
  "GEO_Code__c"?: string;
  "GTP_Community_Type__c"?: string;
  "GTP_Member_Type__c"?: string;
  "GTP_Pool_Access_Type__c"?: string;
  "GTP_Resource_Type__c"?: string;
  "Job__c"?: string;
  "Job_Order_Number__c"?: string;
  "Jobsite__c"?: string;
  "Job_Title__c"?: string;
  "Location__c"?: string;
  "Login_Ref__c"?: string;
  "Member__c"?: string;
  "Partner_Case_Number__c"?: string;
  "Password__c"?: string;
  "PgMO_Department_Role__c"?: string;
  "PgMO_Departments__c"?: string;
  "PgMO_Groups__c"?: string;
  "PgMO_Programs__c"?: string;
  "PgMO_Projects__c"?: string;
  "Primary_Recruiter__c"?: string;
  "Priority__c"?: string;
  "Program__c"?: string;
  "Program_ID__c"?: string;
  "Program_Service_Profile_MVVP__c"?: string;
  "Project_Type__c"?: string;
  "Quote_Line_Manager__c"?: string;
  "QuoteManager__c"?: string;
  "Region__c"?: string;
  "Selected_Account__c"?: string;
  "Selected_Case_Category__c"?: string;
  "Selected_Community__c"?: string;
  "Selected_Contact__c"?: string;
  "Selected_Country__c"?: string;
  "Selected_Created_By_Contact__c"?: string;
  "Selected_Due_Date__c"?: Date;
  "Selected_Group_Team__c"?: string;
  "SelectedLogin__c"?: string;
  "Selected_Member__c"?: string;
  "Selected_PgMO_Conversation__c"?: string;
  "Selected_PgMO_Milestones__c"?: string;
  "Selected_PgMO_Programs__c"?: string;
  "Selected_PgMO_Projects__c"?: string;
  "Selected_PgMO_Tasks__c"?: string;
  "Selected_PMS_Case__c"?: string;
  "Selected_Project_Type__c"?: string;
  "Selected_Start_Date__c"?: Date;
  "Selected_Task_Priority__c"?: string;
  "Selected_Task_Status__c"?: string;
  "Selected_Task_Type__c"?: string;
  "Selected_Team_Shift__c"?: string;
  "Selected_Work_Order__c"?: string;
  "Service_Contract_Category__c"?: string;
  "Serviceo_Password__c"?: string;
  "Serviceo_User_Name__c"?: string;
  "Service_Technical_Level_Account__c"?: string;
  "Service_Technical_Level_MVVP__c"?: string;
  "SessionID__c"?: string;
  "SLA_Account__c"?: string;
  "SLA_MVVP__c"?: string;
  "Stage__c"?: string;
  "Start_Date__c"?: string;
  "State__c"?: string;
  "Status__c"?: string;
  "Talent_Type_Account__c"?: string;
  "Talent_Type_MVVP__c"?: string;
  "User_Name__c"?: string;
  "id"?: number;
  jobsite?: Jobsite;
  worker?: Worker;
  departmentRole?: DepartmentRole;
  department?: Department;
  group?: Group;
  program?: Program;
  project?: Project2;
  quoteLineManager?: QuoteLineManager;
  quoteManager?: QuoteManager;
  account?: Account;
  contact?: Contact;
  selectedContact?: Contact;
  selectedMember?: Worker;
  conversation?: Conversation;
  milestone?: Milestone;
  selectedProgram?: Program;
  selectedProject2?: Project2;
  task?: Task;
  seletedCase?: Case;
  selectedTeamShift?: TeamShift;
  workOrder?: WorkOrder;
}

export class CSQDSessionData implements CSQDSessionDataInterface {
  "sfdcId": string;
  "Account__c": string;
  "Account_ID__c": string;
  "Application__c": string;
  "Application_Source__c": string;
  "Application_Stage__c": string;
  "Case_Number__c": string;
  "Contact__c": string;
  "Country__c": string;
  "Coverage_Hours_Account__c": string;
  "Coverage_Hours_MVVP__c": string;
  "Dispatch_Service_Profiles__c": string;
  "Dispatch_Service_Resolution_Status_FSE__c": string;
  "End_Date__c": string;
  "GEO_Code__c": string;
  "GTP_Community_Type__c": string;
  "GTP_Member_Type__c": string;
  "GTP_Pool_Access_Type__c": string;
  "GTP_Resource_Type__c": string;
  "Job__c": string;
  "Job_Order_Number__c": string;
  "Jobsite__c": string;
  "Job_Title__c": string;
  "Location__c": string;
  "Login_Ref__c": string;
  "Member__c": string;
  "Partner_Case_Number__c": string;
  "Password__c": string;
  "PgMO_Department_Role__c": string;
  "PgMO_Departments__c": string;
  "PgMO_Groups__c": string;
  "PgMO_Programs__c": string;
  "PgMO_Projects__c": string;
  "Primary_Recruiter__c": string;
  "Priority__c": string;
  "Program__c": string;
  "Program_ID__c": string;
  "Program_Service_Profile_MVVP__c": string;
  "Project_Type__c": string;
  "Quote_Line_Manager__c": string;
  "QuoteManager__c": string;
  "Region__c": string;
  "Selected_Account__c": string;
  "Selected_Case_Category__c": string;
  "Selected_Community__c": string;
  "Selected_Contact__c": string;
  "Selected_Country__c": string;
  "Selected_Created_By_Contact__c": string;
  "Selected_Due_Date__c": Date;
  "Selected_Group_Team__c": string;
  "SelectedLogin__c": string;
  "Selected_Member__c": string;
  "Selected_PgMO_Conversation__c": string;
  "Selected_PgMO_Milestones__c": string;
  "Selected_PgMO_Programs__c": string;
  "Selected_PgMO_Projects__c": string;
  "Selected_PgMO_Tasks__c": string;
  "Selected_PMS_Case__c": string;
  "Selected_Project_Type__c": string;
  "Selected_Start_Date__c": Date;
  "Selected_Task_Priority__c": string;
  "Selected_Task_Status__c": string;
  "Selected_Task_Type__c": string;
  "Selected_Team_Shift__c": string;
  "Selected_Work_Order__c": string;
  "Service_Contract_Category__c": string;
  "Serviceo_Password__c": string;
  "Serviceo_User_Name__c": string;
  "Service_Technical_Level_Account__c": string;
  "Service_Technical_Level_MVVP__c": string;
  "SessionID__c": string;
  "SLA_Account__c": string;
  "SLA_MVVP__c": string;
  "Stage__c": string;
  "Start_Date__c": string;
  "State__c": string;
  "Status__c": string;
  "Talent_Type_Account__c": string;
  "Talent_Type_MVVP__c": string;
  "User_Name__c": string;
  "id": number;
  jobsite: Jobsite;
  worker: Worker;
  departmentRole: DepartmentRole;
  department: Department;
  group: Group;
  program: Program;
  project: Project2;
  quoteLineManager: QuoteLineManager;
  quoteManager: QuoteManager;
  account: Account;
  contact: Contact;
  selectedContact: Contact;
  selectedMember: Worker;
  conversation: Conversation;
  milestone: Milestone;
  selectedProgram: Program;
  selectedProject2: Project2;
  task: Task;
  seletedCase: Case;
  selectedTeamShift: TeamShift;
  workOrder: WorkOrder;
  constructor(data?: CSQDSessionDataInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `CSQDSessionData`.
   */
  public static getModelName() {
    return "CSQDSessionData";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of CSQDSessionData for dynamic purposes.
  **/
  public static factory(data: CSQDSessionDataInterface): CSQDSessionData{
    return new CSQDSessionData(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'CSQDSessionData',
      plural: 'CSQDSessionData',
      path: 'CSQDSessionData',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "Account__c": {
          name: 'Account__c',
          type: 'string'
        },
        "Account_ID__c": {
          name: 'Account_ID__c',
          type: 'string'
        },
        "Application__c": {
          name: 'Application__c',
          type: 'string'
        },
        "Application_Source__c": {
          name: 'Application_Source__c',
          type: 'string'
        },
        "Application_Stage__c": {
          name: 'Application_Stage__c',
          type: 'string'
        },
        "Case_Number__c": {
          name: 'Case_Number__c',
          type: 'string'
        },
        "Contact__c": {
          name: 'Contact__c',
          type: 'string'
        },
        "Country__c": {
          name: 'Country__c',
          type: 'string'
        },
        "Coverage_Hours_Account__c": {
          name: 'Coverage_Hours_Account__c',
          type: 'string'
        },
        "Coverage_Hours_MVVP__c": {
          name: 'Coverage_Hours_MVVP__c',
          type: 'string'
        },
        "Dispatch_Service_Profiles__c": {
          name: 'Dispatch_Service_Profiles__c',
          type: 'string'
        },
        "Dispatch_Service_Resolution_Status_FSE__c": {
          name: 'Dispatch_Service_Resolution_Status_FSE__c',
          type: 'string'
        },
        "End_Date__c": {
          name: 'End_Date__c',
          type: 'string'
        },
        "GEO_Code__c": {
          name: 'GEO_Code__c',
          type: 'string'
        },
        "GTP_Community_Type__c": {
          name: 'GTP_Community_Type__c',
          type: 'string'
        },
        "GTP_Member_Type__c": {
          name: 'GTP_Member_Type__c',
          type: 'string'
        },
        "GTP_Pool_Access_Type__c": {
          name: 'GTP_Pool_Access_Type__c',
          type: 'string'
        },
        "GTP_Resource_Type__c": {
          name: 'GTP_Resource_Type__c',
          type: 'string'
        },
        "Job__c": {
          name: 'Job__c',
          type: 'string'
        },
        "Job_Order_Number__c": {
          name: 'Job_Order_Number__c',
          type: 'string'
        },
        "Jobsite__c": {
          name: 'Jobsite__c',
          type: 'string'
        },
        "Job_Title__c": {
          name: 'Job_Title__c',
          type: 'string'
        },
        "Location__c": {
          name: 'Location__c',
          type: 'string'
        },
        "Login_Ref__c": {
          name: 'Login_Ref__c',
          type: 'string'
        },
        "Member__c": {
          name: 'Member__c',
          type: 'string'
        },
        "Partner_Case_Number__c": {
          name: 'Partner_Case_Number__c',
          type: 'string'
        },
        "Password__c": {
          name: 'Password__c',
          type: 'string'
        },
        "PgMO_Department_Role__c": {
          name: 'PgMO_Department_Role__c',
          type: 'string'
        },
        "PgMO_Departments__c": {
          name: 'PgMO_Departments__c',
          type: 'string'
        },
        "PgMO_Groups__c": {
          name: 'PgMO_Groups__c',
          type: 'string'
        },
        "PgMO_Programs__c": {
          name: 'PgMO_Programs__c',
          type: 'string'
        },
        "PgMO_Projects__c": {
          name: 'PgMO_Projects__c',
          type: 'string'
        },
        "Primary_Recruiter__c": {
          name: 'Primary_Recruiter__c',
          type: 'string'
        },
        "Priority__c": {
          name: 'Priority__c',
          type: 'string'
        },
        "Program__c": {
          name: 'Program__c',
          type: 'string'
        },
        "Program_ID__c": {
          name: 'Program_ID__c',
          type: 'string'
        },
        "Program_Service_Profile_MVVP__c": {
          name: 'Program_Service_Profile_MVVP__c',
          type: 'string'
        },
        "Project_Type__c": {
          name: 'Project_Type__c',
          type: 'string'
        },
        "Quote_Line_Manager__c": {
          name: 'Quote_Line_Manager__c',
          type: 'string'
        },
        "QuoteManager__c": {
          name: 'QuoteManager__c',
          type: 'string'
        },
        "Region__c": {
          name: 'Region__c',
          type: 'string'
        },
        "Selected_Account__c": {
          name: 'Selected_Account__c',
          type: 'string'
        },
        "Selected_Case_Category__c": {
          name: 'Selected_Case_Category__c',
          type: 'string'
        },
        "Selected_Community__c": {
          name: 'Selected_Community__c',
          type: 'string'
        },
        "Selected_Contact__c": {
          name: 'Selected_Contact__c',
          type: 'string'
        },
        "Selected_Country__c": {
          name: 'Selected_Country__c',
          type: 'string'
        },
        "Selected_Created_By_Contact__c": {
          name: 'Selected_Created_By_Contact__c',
          type: 'string'
        },
        "Selected_Due_Date__c": {
          name: 'Selected_Due_Date__c',
          type: 'Date'
        },
        "Selected_Group_Team__c": {
          name: 'Selected_Group_Team__c',
          type: 'string'
        },
        "SelectedLogin__c": {
          name: 'SelectedLogin__c',
          type: 'string'
        },
        "Selected_Member__c": {
          name: 'Selected_Member__c',
          type: 'string'
        },
        "Selected_PgMO_Conversation__c": {
          name: 'Selected_PgMO_Conversation__c',
          type: 'string'
        },
        "Selected_PgMO_Milestones__c": {
          name: 'Selected_PgMO_Milestones__c',
          type: 'string'
        },
        "Selected_PgMO_Programs__c": {
          name: 'Selected_PgMO_Programs__c',
          type: 'string'
        },
        "Selected_PgMO_Projects__c": {
          name: 'Selected_PgMO_Projects__c',
          type: 'string'
        },
        "Selected_PgMO_Tasks__c": {
          name: 'Selected_PgMO_Tasks__c',
          type: 'string'
        },
        "Selected_PMS_Case__c": {
          name: 'Selected_PMS_Case__c',
          type: 'string'
        },
        "Selected_Project_Type__c": {
          name: 'Selected_Project_Type__c',
          type: 'string'
        },
        "Selected_Start_Date__c": {
          name: 'Selected_Start_Date__c',
          type: 'Date'
        },
        "Selected_Task_Priority__c": {
          name: 'Selected_Task_Priority__c',
          type: 'string'
        },
        "Selected_Task_Status__c": {
          name: 'Selected_Task_Status__c',
          type: 'string'
        },
        "Selected_Task_Type__c": {
          name: 'Selected_Task_Type__c',
          type: 'string'
        },
        "Selected_Team_Shift__c": {
          name: 'Selected_Team_Shift__c',
          type: 'string'
        },
        "Selected_Work_Order__c": {
          name: 'Selected_Work_Order__c',
          type: 'string'
        },
        "Service_Contract_Category__c": {
          name: 'Service_Contract_Category__c',
          type: 'string'
        },
        "Serviceo_Password__c": {
          name: 'Serviceo_Password__c',
          type: 'string'
        },
        "Serviceo_User_Name__c": {
          name: 'Serviceo_User_Name__c',
          type: 'string'
        },
        "Service_Technical_Level_Account__c": {
          name: 'Service_Technical_Level_Account__c',
          type: 'string'
        },
        "Service_Technical_Level_MVVP__c": {
          name: 'Service_Technical_Level_MVVP__c',
          type: 'string'
        },
        "SessionID__c": {
          name: 'SessionID__c',
          type: 'string'
        },
        "SLA_Account__c": {
          name: 'SLA_Account__c',
          type: 'string'
        },
        "SLA_MVVP__c": {
          name: 'SLA_MVVP__c',
          type: 'string'
        },
        "Stage__c": {
          name: 'Stage__c',
          type: 'string'
        },
        "Start_Date__c": {
          name: 'Start_Date__c',
          type: 'string'
        },
        "State__c": {
          name: 'State__c',
          type: 'string'
        },
        "Status__c": {
          name: 'Status__c',
          type: 'string'
        },
        "Talent_Type_Account__c": {
          name: 'Talent_Type_Account__c',
          type: 'string'
        },
        "Talent_Type_MVVP__c": {
          name: 'Talent_Type_MVVP__c',
          type: 'string'
        },
        "User_Name__c": {
          name: 'User_Name__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        jobsite: {
          name: 'jobsite',
          type: 'Jobsite',
          model: 'Jobsite',
          relationType: 'belongsTo',
                  keyFrom: 'Jobsite__c',
          keyTo: 'sfdcId'
        },
        worker: {
          name: 'worker',
          type: 'Worker',
          model: 'Worker',
          relationType: 'belongsTo',
                  keyFrom: 'Member__c',
          keyTo: 'sfdcId'
        },
        departmentRole: {
          name: 'departmentRole',
          type: 'DepartmentRole',
          model: 'DepartmentRole',
          relationType: 'belongsTo',
                  keyFrom: 'PgMO_Department_Role__c',
          keyTo: 'sfdcId'
        },
        department: {
          name: 'department',
          type: 'Department',
          model: 'Department',
          relationType: 'belongsTo',
                  keyFrom: 'PgMO_Departments__c',
          keyTo: 'sfdcId'
        },
        group: {
          name: 'group',
          type: 'Group',
          model: 'Group',
          relationType: 'belongsTo',
                  keyFrom: 'PgMO_Groups__c',
          keyTo: 'sfdcId'
        },
        program: {
          name: 'program',
          type: 'Program',
          model: 'Program',
          relationType: 'belongsTo',
                  keyFrom: 'PgMO_Programs__c',
          keyTo: 'sfdcId'
        },
        project: {
          name: 'project',
          type: 'Project2',
          model: 'Project2',
          relationType: 'belongsTo',
                  keyFrom: 'PgMO_Projects__c',
          keyTo: 'sfdcId'
        },
        quoteLineManager: {
          name: 'quoteLineManager',
          type: 'QuoteLineManager',
          model: 'QuoteLineManager',
          relationType: 'belongsTo',
                  keyFrom: 'Quote_Line_Manager__c',
          keyTo: 'sfdcId'
        },
        quoteManager: {
          name: 'quoteManager',
          type: 'QuoteManager',
          model: 'QuoteManager',
          relationType: 'belongsTo',
                  keyFrom: 'QuoteManager__c',
          keyTo: 'sfdcId'
        },
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'Selected_Account__c',
          keyTo: 'sfdcId'
        },
        contact: {
          name: 'contact',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Selected_Contact__c',
          keyTo: 'sfdcId'
        },
        selectedContact: {
          name: 'selectedContact',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Selected_Created_By_Contact__c',
          keyTo: 'sfdcId'
        },
        selectedMember: {
          name: 'selectedMember',
          type: 'Worker',
          model: 'Worker',
          relationType: 'belongsTo',
                  keyFrom: 'Selected_Member__c',
          keyTo: 'sfdcId'
        },
        conversation: {
          name: 'conversation',
          type: 'Conversation',
          model: 'Conversation',
          relationType: 'belongsTo',
                  keyFrom: 'Selected_PgMO_Conversation__c',
          keyTo: 'sfdcId'
        },
        milestone: {
          name: 'milestone',
          type: 'Milestone',
          model: 'Milestone',
          relationType: 'belongsTo',
                  keyFrom: 'Selected_PgMO_Milestones__c',
          keyTo: 'sfdcId'
        },
        selectedProgram: {
          name: 'selectedProgram',
          type: 'Program',
          model: 'Program',
          relationType: 'belongsTo',
                  keyFrom: 'Selected_PgMO_Programs__c',
          keyTo: 'sfdcId'
        },
        selectedProject2: {
          name: 'selectedProject2',
          type: 'Project2',
          model: 'Project2',
          relationType: 'belongsTo',
                  keyFrom: 'Selected_PgMO_Projects__c',
          keyTo: 'sfdcId'
        },
        task: {
          name: 'task',
          type: 'Task',
          model: 'Task',
          relationType: 'belongsTo',
                  keyFrom: 'Selected_PgMO_Tasks__c',
          keyTo: 'sfdcId'
        },
        seletedCase: {
          name: 'seletedCase',
          type: 'Case',
          model: 'Case',
          relationType: 'belongsTo',
                  keyFrom: 'Selected_PMS_Case__c',
          keyTo: 'sfdcId'
        },
        selectedTeamShift: {
          name: 'selectedTeamShift',
          type: 'TeamShift',
          model: 'TeamShift',
          relationType: 'belongsTo',
                  keyFrom: 'Selected_Team_Shift__c',
          keyTo: 'sfdcId'
        },
        workOrder: {
          name: 'workOrder',
          type: 'WorkOrder',
          model: 'WorkOrder',
          relationType: 'belongsTo',
                  keyFrom: 'Selected_Work_Order__c',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
