import { Component, OnInit, Input, ChangeDetectorRef, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { ControlContainer, FormGroupDirective, FormControl, Validators, FormGroup, AbstractControl } from '@angular/forms';
import { FilterServiceApi } from './../../sdk';
import { ModalService } from '../../services/modal.service';
@Component({
  selector: 'app-add-fields',
  templateUrl: './add-fields.component.html',
  styleUrls: ['./add-fields.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class AddFieldsComponent implements OnInit {

  @Input() form: FormGroup;
  @Output() setFormValue: EventEmitter<any> = new EventEmitter;
  @Input()
  set fieldOptions(fieldsOption) {
    this.buildPage(fieldsOption);
    // this.detectChanges();
  }
  eleConfig = {};
  option: any;
  title: string;
  formErrors: any = {};
  validationMessages: any;
  modalConfig = [];
  @ViewChild('inputBox') inputBox: ElementRef

  constructor(
    private _filterServiceApi: FilterServiceApi,
    private _modalService: ModalService,
    private _parent: FormGroupDirective,
    private _cd: ChangeDetectorRef
  ) {
    this.option = {
      'contactType': [],
      'workerBillingType': [],
      'userInternal': [],
      'expenseCode': []
    };
  }

  ngOnInit() {
    this.setValidationMsg();
  }

  /**
  * 
  * @param fieldsOption | pass options like below
  * {
        'type': 'select',
        'label': 'Contact Type',
        'name': 'contact_Type'
    }, {
        'type': 'input',
        'label': 'My Home Metro',
        'name': 'myhome_Metro'
    }
  */
  buildPage(fieldsOption) {
    if (fieldsOption) {
      fieldsOption.forEach(element => {
        if (element['name'] === 'myhome_Metro') {
          this.title = 'GEO Codes';
          element['click'] = true;
          const columns = [
            { prop: 'Action', name: 'Action', visible: true, width: 120, sortable: false },
            { prop: 'Name', name: 'GEO Metro', visible: true, width: 100, sortable: true },
            { prop: 'Street__c', name: 'Street', visible: true, width: 200, sortable: true },
            { prop: 'City__c', name: 'City', visible: true, width: 100, sortable: true },
            { prop: 'State_Province__c', name: 'State/Province', visible: true, width: 120, sortable: true }
          ];
          this.modalConfig[element['name']] = {
            dbConfig: {
              '_model': 'myHomeMetro',
              '_fields': ['Name', 'Street__c', 'City__c', 'State_Province__c', 'sfdcId'],
              '_where': { Name: { like: 'A' + '%', options: 'i' }, }
            },
            columns: columns,
            pageTitle: 'GEO Codes',
            isSetEnabled: true,
            isSearch: true // to enable/diable search option on top
          }
        } else if (element['name'] === 'primary_worker_skill_profile') {
          this.title = 'Skilling Profile';
          element['click'] = true;
          const columns = [
            { prop: 'Action', name: 'Action', visible: true, width: 150, sortable: false },
            { prop: 'Name', name: 'Worker Skilling Profile Name', visible: true, width: 300, sortable: true },
            { prop: 'workerName', name: 'Worker', visible: true, width: 100, sortable: true },
            { prop: 'Education__c', name: 'Education', visible: true, width: 100, sortable: true },
            { prop: 'Hourly_Rate__c', name: 'Hourly Rate (Onsite)', visible: true, width: 100, sortable: true },
            { prop: 'Hourly_Rate_Virtual__c', name: 'Hourly Rate (Virtual)', visible: true, width: 100, sortable: true },
            { prop: 'Skill_Level__c', name: 'Skill Level', visible: true, width: 100, sortable: true },
            { prop: 'Skills__c', name: 'Skills', visible: true, width: 100, sortable: true },
            { prop: 'Certifications__c', name: 'Certifications', visible: true, width: 100, sortable: true },
            { prop: 'Resource__c', name: 'Contact', visible: true, width: 100, sortable: true },
            { prop: 'Status__c', name: 'Status', visible: true, width: 100, sortable: true }

          ];
          this.modalConfig[element['name']] = {
            dbConfig: {
              '_model': 'talentProfile',
              '_fields': ['sfdcId', 'Name', 'workerName', 'Education__c', 'Hourly_Rate__c', 'Hourly_Rate_Virtual__c', 'Skill_Level__c',
                'Skills__c', 'Certifications__c', 'Resource__c', 'Status__c'],
              // '_where': { Name: { like: 'A' + '%', options: 'i' }, }
            },
            columns: columns,
            pageTitle: 'Skilling Profile',
            isSetEnabled: true,
            isSearch: true // to enable/diable search option on top
          }
        } else if (element['name'] === 'vendorsite' && element['where']) {
          this.title = 'Vendorsites';
          element['click'] = true;
          const columns = [
            { prop: 'Action', name: 'Action', visible: true, width: 200, sortable: false },
            { prop: 'Name', name: 'Name', visible: true, width: 450, sortable: true }
          ];
          this.modalConfig[element['name']] = {
            dbConfig: {
              '_model': 'vendorsite',
              '_fields': ['sfdcId', 'Name', 'GEO_Metro__c'],
              '_where': Object.assign(element['where'])
              // '_where': element['where']
            },
            columns: columns,
            pageTitle: 'Vendorsites',
            isSetEnabled: true,
            isSearch: true // to enable/diable search option on top
          }
        } else if (element['col_type'] === 'user_internal') {
          this.title = 'Internal Users';
          element['click'] = true;
          const columns = [
            { prop: 'Action', name: 'Action', visible: true, width: 200, sortable: false },
            { prop: 'firstname', name: 'Name', visible: true, width: 130, sortable: true },
            { prop: 'email', name: 'Email', visible: true, width: 210, sortable: false },
            { prop: 'accessType', name: 'Access Type', visible: true, width: 130, sortable: false },
          ];
          this.modalConfig[element['name']] = {
            dbConfig: {
              '_model': 'userInternal',
              '_fields': ['sfdcId', 'firstname', 'email', 'accessType'],
              '_where': {
                and: [
                  { accessType: 'internal' },
                  { Name: { like: 'A', options: 'i' } }
                ]
              },
            },
            columns: columns,
            pageTitle: 'Internal Users',
            isSetEnabled: true,
            isSearch: true // to enable/diable search option on top
          }
        } else if (element['col_type'] === 'approver') {
          this.title = 'Approver List';
          element['click'] = true;
          const columns = [
            { prop: 'Action', name: 'Action', visible: true, width: 200, sortable: false },
            { prop: 'firstname', name: 'First Name', visible: true, width: 130, sortable: true },
            { prop: 'lastname', name: 'Last Name', visible: true, width: 130, sortable: true },
            { prop: 'email', name: 'Email', visible: true, width: 210, sortable: false },
            { prop: 'accessType', name: 'Access Type', visible: true, width: 130, sortable: false },
          ];
          this.modalConfig[element['name']] = {
            dbConfig: {
              '_model': 'userInternal',
              '_fields': ['sfdcId', 'firstname', 'lastname', , 'email', 'accessType'],
              '_where': {
                and: [
                  { accessType: 'internal' },
                  { Name: { like: 'A', options: 'i' } }
                ]
              },
            },
            columns: columns,
            pageTitle: 'Approver',
            isSetEnabled: true,
            isSearch: true // to enable/diable search option on top
          }
        } else if (element['name'] === 'expense-code') {
          this.title = element['label'];
          element['click'] = true;
          const columns = [
            { prop: 'Action', name: 'Action', visible: true, width: 200, sortable: false },
            { prop: 'Name', name: 'Name', visible: true, width: 200, sortable: true },
            { prop: 'Expense_Description__c', name: 'Expense Description', visible: true, width: 270, sortable: false }
          ];
          this.modalConfig[element['name']] = {
            dbConfig: {
              '_model': 'expenseCode',
              '_fields': ['sfdcId', 'Name', 'Expense_Description__c'],
              '_where': { Name: { like: 'A', options: 'i' } }
            },
            columns: columns,
            pageTitle: element['label'],
            isSetEnabled: true,
            isSearch: true // to enable/disable search option on top
          };
        } else if (element['control_type'] === 'VendorPPEHours') {   // To get results from VendorPPEHours model
          this.title = element['label'];
          element['click'] = true;
          const columns = [
            { prop: 'Action', name: 'Action', visible: true, width: 223, sortable: false },
            { prop: 'Name', name: 'Name', visible: true, width: 221, sortable: true },
            { prop: 'Vendor_PPE_Hours_For_NBD__c', name: 'PPE Hours', visible: true, width: 221, sortable: false },
          ];
          this.modalConfig[element['name']] = {
            dbConfig: {
              '_model': 'VendorPPEHours',
              '_fields': ['sfdcId', 'Name', 'Vendor_PPE_Hours_For_NBD__c'],
              '_where': {
                and: [
                  { Name: { like: 'V%', options: 'i' } }
                ]
              },
            },
            columns: columns,
            pageTitle: this.title,
            isSetEnabled: true,
            isSearch: true // to enable/diable search option on top
          }
        } else {
          element['click'] = false;
        }
        // if required
        if (element["required"]) {
          if (element["name"] === "worker_rating") {
            this.form.addControl(
              element["name"],
              new FormControl(this.getValue(element["name"]), [
                Validators.required,
                Validators.min(1),
                Validators.max(100),
              ])
            );
          } else if (element["name"] === "user_Internal") {
            this.form.addControl(
              element["name"],
              new FormControl(
                this.getUserInternal(element["name"]),
                Validators.required
              )
            );
          } else {
            this.form.addControl(
              element["name"],
              new FormControl(
                this.getValue(element["name"]),
                Validators.required
              )
            );
          }
        } else if (element["name"] === "user_Internal") {
          this.form.addControl(
            element["name"],
            new FormControl(this.getUserInternal(element["name"]), [])
          );
        } else {
          this.form.addControl(
            element["name"],
            new FormControl(this.getValue(element["name"]), [])
          );
        }
        
        this.form.valueChanges.subscribe(data => this.onFormChanged(data));
      });
      this.eleConfig = fieldsOption;
    }
  }

  setValidationMsg() {
    this.formErrors = {
      'contact_Type': '',
      'myhome_Metro': '',
      'user_Internal': '',
      'worker_rating': '',
      'worker_billing_type': '',
      'vendor_profile': '',
    };
    this.validationMessages = {
      'contact_Type': {
        'required': 'Contact Type is required.'
      },
      'myhome_Metro': {
        'required': 'My home metro is required.'
      },
      'user_Internal': {
        'required': 'Internal User is required.'
      },
      'worker_rating': {
        'required': 'Worker rating is required.',
        'min': 'Worker rating should be between 1-100',
        'max': 'Worker rating should be between 1-100'
      },
      'worker_billing_type': {
        'required': 'Worker billing type is required.'
      },
      'vendor_profile': {
        'required': 'Vendor profile is required.'
      }
    };
  }

  onFormChanged(data?: any) {
    this.setValidationMsg();
    if (!this._parent.form) { return; }
    const form = this._parent.form
    // tslint:disable-next-line:forin
    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = '';

      const control = form.get(field);
      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];
        // tslint:disable-next-line:forin
        for (const key in control.errors) {
          this.formErrors[field] += messages[key] + ' ';
        }
      }
    }
  }
  getUserInternal(optionName){
    const paramObj = {};
      if (optionName === 'user_internal') {
        paramObj['models'] = ['userInternal'];
        // get data for filters
        this._filterServiceApi.getAllFiltersData(paramObj).subscribe(
          data => {
            if (data.data) {
              data = data.data;
              // contactType
              if (optionName === 'user_internal' && data['userInternal'] && data['userInternal']['list']) {
                this.option.user_internal = data['userInternal']['list'];
              }
            }
          });
      }
  }
  getValue(optionName) {
    const paramObj = {};
    if (optionName === 'contact_Type' || optionName === 'worker_billing_type') {
      paramObj['models'] = ['ContactType', 'workerBillingType'];
      // get data for filters
      this._filterServiceApi.getAllFiltersData(paramObj).subscribe(
        data => {
          if (data.data) {
            data = data.data;
            // contactType
            if (optionName === 'contact_Type' && data['contactType'] && data['contactType']['list']) {
              this.option.contact_Type = data['contactType']['list'];
            } else if (optionName === 'worker_billing_type' && data['workerBillingType'] && data['workerBillingType']['list']) {
              this.option.worker_billing_type = data['workerBillingType']['list'];
            }
          }
        });
    }

  }

  openModal(content, key, size, event) {
    if (this.modalConfig[key]['isSetEnabled']) {
      if (event.target.attributes.getNamedItem('class').nodeValue != 'search') {
        const inputName = event.target.attributes.getNamedItem('name').value;
        this.modalConfig[key]['reflectKey'] = inputName;
      } else {
        this.modalConfig[key]['reflectKey'] = key
      }
    }
    this._modalService.open(content, size);
  }

  setFieldValue(e) {
    if (e && e['Name']) {
      this._parent.form.get(e['Key']).setValue(e['Name']);
      this.setFormValue.emit(e);
    } else if (e && e['firstname'] && e['lastname']) {
      this._parent.form.get(e['Key']).setValue(`${e['firstname']} ${e['lastname']}`);
      this.setFormValue.emit(e);
    } else if (e && e['firstname']) {
      this._parent.form.get(e['Key']).setValue(e['firstname']);
      this.setFormValue.emit(e);
    }
  }

  detectChanges() {
    if (!this._cd['destroyed']) {
      this._cd.detectChanges();
    }
  }

  setFocusInputBox() {
    this.inputBox.nativeElement.click();
  }

  isRequired(field) {
    const control = this.form.controls[field];
    let required = false;
    if (control && control.validator) {
      const validator = control.validator({} as AbstractControl);
      if (validator && validator.required) {
        required = true;
      }
    }
    return required;
  }

}
