/* tslint:disable */
import {
  Account,
  Contact,
  Project,
  Worker
} from '../index';

declare var Object: any;
export interface CompoffAutoExpireSettingInterface {
  "sfdcId"?: string;
  "OwnerId"?: string;
  "IsDeleted"?: boolean;
  "Name"?: string;
  "CreatedById"?: string;
  "CreatedDate"?: Date;
  "CurrencyIsoCode"?: string;
  "LastModifiedDate"?: Date;
  "LastModifiedById"?: string;
  "Account__c"?: string;
  "isActive__c"?: boolean;
  "Created_By__c"?: string;
  "Days__c"?: number;
  "Default__c"?: boolean;
  "Group__c"?: string;
  "Program__c"?: string;
  "Type__c"?: string;
  "Worker__c"?: string;
  "CreatedBy__c"?: string;
  "Lastmodifed_By__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  account?: Account;
  contact?: Contact;
  modifiedByContact?: Contact;
  project?: Project;
  worker?: Worker;
}

export class CompoffAutoExpireSetting implements CompoffAutoExpireSettingInterface {
  "sfdcId": string;
  "OwnerId": string;
  "IsDeleted": boolean;
  "Name": string;
  "CreatedById": string;
  "CreatedDate": Date;
  "CurrencyIsoCode": string;
  "LastModifiedDate": Date;
  "LastModifiedById": string;
  "Account__c": string;
  "isActive__c": boolean;
  "Created_By__c": string;
  "Days__c": number;
  "Default__c": boolean;
  "Group__c": string;
  "Program__c": string;
  "Type__c": string;
  "Worker__c": string;
  "CreatedBy__c": string;
  "Lastmodifed_By__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  account: Account;
  contact: Contact;
  modifiedByContact: Contact;
  project: Project;
  worker: Worker;
  constructor(data?: CompoffAutoExpireSettingInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `CompoffAutoExpireSetting`.
   */
  public static getModelName() {
    return "CompoffAutoExpireSetting";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of CompoffAutoExpireSetting for dynamic purposes.
  **/
  public static factory(data: CompoffAutoExpireSettingInterface): CompoffAutoExpireSetting{
    return new CompoffAutoExpireSetting(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'CompoffAutoExpireSetting',
      plural: 'CompoffAutoExpireSettings',
      path: 'CompoffAutoExpireSettings',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "Account__c": {
          name: 'Account__c',
          type: 'string'
        },
        "isActive__c": {
          name: 'isActive__c',
          type: 'boolean'
        },
        "Created_By__c": {
          name: 'Created_By__c',
          type: 'string'
        },
        "Days__c": {
          name: 'Days__c',
          type: 'number'
        },
        "Default__c": {
          name: 'Default__c',
          type: 'boolean'
        },
        "Group__c": {
          name: 'Group__c',
          type: 'string'
        },
        "Program__c": {
          name: 'Program__c',
          type: 'string'
        },
        "Type__c": {
          name: 'Type__c',
          type: 'string'
        },
        "Worker__c": {
          name: 'Worker__c',
          type: 'string'
        },
        "CreatedBy__c": {
          name: 'CreatedBy__c',
          type: 'string'
        },
        "Lastmodifed_By__c": {
          name: 'Lastmodifed_By__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'Account__c',
          keyTo: 'sfdcId'
        },
        contact: {
          name: 'contact',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Created_By__c',
          keyTo: 'sfdcId'
        },
        modifiedByContact: {
          name: 'modifiedByContact',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Lastmodifed_By__c',
          keyTo: 'sfdcId'
        },
        project: {
          name: 'project',
          type: 'Project',
          model: 'Project',
          relationType: 'belongsTo',
                  keyFrom: 'Program__c',
          keyTo: 'sfdcId'
        },
        worker: {
          name: 'worker',
          type: 'Worker',
          model: 'Worker',
          relationType: 'belongsTo',
                  keyFrom: 'Worker__c',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
