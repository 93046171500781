/* tslint:disable */

declare var Object: any;
export interface QuestionSetInterface {
  "sfdcId"?: string;
  "ts2__Business_Unit__c"?: string;
  "ts2__Language__c"?: string;
  "ts2__Legacy_QuestionSetID__c"?: string;
  "ts2__Passing_Score__c"?: number;
  "ts2__Skill_Codes__c"?: string;
  "ts2__Status__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class QuestionSet implements QuestionSetInterface {
  "sfdcId": string;
  "ts2__Business_Unit__c": string;
  "ts2__Language__c": string;
  "ts2__Legacy_QuestionSetID__c": string;
  "ts2__Passing_Score__c": number;
  "ts2__Skill_Codes__c": string;
  "ts2__Status__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: QuestionSetInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `QuestionSet`.
   */
  public static getModelName() {
    return "QuestionSet";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of QuestionSet for dynamic purposes.
  **/
  public static factory(data: QuestionSetInterface): QuestionSet{
    return new QuestionSet(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'QuestionSet',
      plural: 'QuestionSets',
      path: 'QuestionSets',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "ts2__Business_Unit__c": {
          name: 'ts2__Business_Unit__c',
          type: 'string'
        },
        "ts2__Language__c": {
          name: 'ts2__Language__c',
          type: 'string'
        },
        "ts2__Legacy_QuestionSetID__c": {
          name: 'ts2__Legacy_QuestionSetID__c',
          type: 'string'
        },
        "ts2__Passing_Score__c": {
          name: 'ts2__Passing_Score__c',
          type: 'number'
        },
        "ts2__Skill_Codes__c": {
          name: 'ts2__Skill_Codes__c',
          type: 'string'
        },
        "ts2__Status__c": {
          name: 'ts2__Status__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
