export interface IAlert {
    type: string;
    message: string;
}

export enum AlertType {
    Success,
    Error,
    Info,
    Warning
}

export interface ValueList {
    value: string;
    label: string;
}

export interface StageList {
    id: number;
    value: string;
    label: string;
}

export interface ILableValue {
    label: string;
    value: string;
}

export interface IExpenseType {
    label: string;
    value: IExpenseSubType[];
    expenseSubType: ValueList[];
}

export interface IExpenseSubType extends ILableValue {}
export interface IExpenseDivision extends ILableValue {
    department: ValueList[];
}
export interface IExpenseDepartment extends ILableValue {}
export interface IExpensePaymentType extends ILableValue {}
export interface ICustApprovalStatus extends ILableValue {}
export interface IIronApprovalStatus extends ILableValue {}
export interface IAccountCurrency extends ILableValue {}
