import { ConfirmDialogService } from 'shared/services/confirm-dialog.service';
import { AppStateService } from 'shared/services/app-state.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TimecardApi, DocumentApi, WorkerApi } from 'shared/sdk';
import { CommonService } from 'shared/services/common.service';
import { Component, OnInit, ViewChild, Input, AfterViewInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { PreloaderService } from 'shared/services/preloader.service';
import { UtilityService } from 'shared/services/utility.service';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { ExportToExcelService } from 'shared/services/export-to-excel.service';
import { GlobalFilterService } from 'shared/services/global-filter.service';
import { environment } from 'environments/environment';
import { AlertService } from 'shared/services/alert.service';
import { ModalService } from 'shared/services/modal.service';
import { BaseVendorViewComponent } from 'msp/shared/base-vendor-view/base-vendor-view.component';

@Component({
  selector: 'app-timesheet-list',
  templateUrl: './timesheet-list.component.html',
  styleUrls: ['./timesheet-list.component.css']
})
export class TimesheetListComponent extends BaseVendorViewComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() fromcheckInOut = false;   // To determine if the parent component is checkin-checkout-home.
  @Input() fromBillingManager = false;   // To determine if the parent component is checkin-checkout-home.
  @Input() set workerData(e){
    this.getWorkerInfo(e.contactSfdcId)
  }; // To recieve worker data from checkin-checkout-home.
  @Input() enableFilterList; // To recieve list of enable filter from checkin-checkout-home.
  @Output() childFilters: EventEmitter<any> = new EventEmitter;
  serviceContract: any;
  @Input()
  set gloablFilterData(e) {
    if (e) {
      if (e.jobId) {
        this.JobDetailTimesheet = e.jobId;
      } else {
        this.globalFilterCondition = e;
      }
      this.getData();
    }
  }
  isLoading: boolean;
  timesLoadedMore: number;
  itemsPerBatch = 200;
  itemsPerPage = 25;
  tableData: any[] = [];
  noRecords: boolean;
  allItems: any[];
  filterObj = {};
  columns: any;
  datePipe: any;
  totalCount: number;
  @ViewChild('myTable') table: any;
  recordTypeIds;
  accessType: any;
  internalUserFilters = [
    'accounts',
    'vendorAccounts',
    'programs',
    'workerBillingTypes',
    'employeeType'
  ]
  enableFilters = [
    'dateOptions',
    'regions',
    'countries',
    'jobSites',
    'geoMetro',
    'workerScheduled',
    'workerNames',
    'ironApprovalStatuses'
  ];

  private _statusCss = {
    'approved': 'approved',
    'submitted': 'submitted',
    'not submitted': 'not submittedd',
  };

  globalFilterCondition;
  sidebarStateSub: Subscription;
  JobDetailTimesheet: string;
  documentModelId: string;
  systemCurrentDate: any;
  timesheetData: any = {};
  workerId : any;
  _data : any;
  jobDetail = {}
  constructor(private _loader: PreloaderService,
    private _timecardApi: TimecardApi,
    private _utilityService: UtilityService,
    private _commonService: CommonService,
    private router: Router,
    private _exportToExcelService: ExportToExcelService,
    private _appStateService: AppStateService,
    private _globalFilterService: GlobalFilterService,
    private _alertService: AlertService,
    private _modelService: ModalService,
    private _documentApi: DocumentApi,
    private _activateRoute: ActivatedRoute,
    private _confirmDialogService: ConfirmDialogService,
    private _workerApi : WorkerApi
  ) {
    super(_appStateService);
   }

  ngOnInit() {
    this._commonService.saveCurrentPageAnalytics('Billing Manager - Contract Jobs', 'Timesheets');
    this.sidebarStateSub = this._globalFilterService.getSidebarState().subscribe(isOpened => {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 0);
    })
    this.accessType = this._appStateService.getAccessType();
    const appData = JSON.parse(localStorage.getItem('appData'));
    if (!this.viewAsVendor && this.accessType === 'internal') {
      this.enableFilters = this.enableFilters.concat(this.internalUserFilters);
    }
    this.getWorkerServiceContract();
    this.columnInitialize();
    if (this.router.url.indexOf('/jobs/view') === -1) {
      this.getData();
    }
  }

  ngAfterViewInit() {
    this.childFilters.emit(this.enableFilters);
  }

  getWorkerServiceContract() {
    this._commonService.getWorkerServiceContract().then(res => { 
        this.serviceContract = res; 
    });
  }

  columnInitialize() {
    this.columns = [
      {
        prop: '##Action',
        name: 'Action',
        visible: true,
        width: 160,
        type: 'string',
        sortable: false,
        frozenLeft: true
      },
      {
        prop: 'time',
        name: 'Timesheet #',
        visible: true,
        width: 160,
        type: 'string',
        sortable: true,
        frozenLeft: true
      },
      {
        prop: 'programName',
        name: 'Program',
        visible: true,
        width: 180,
        type: 'string',
        sortable: true,
        frozenLeft: false,
      },
      {
        prop: 'Status__c',
        name: 'IRON Approval Status',
        visible: true,
        width: 180,
        type: 'string',
        sortable: true,
        frozenLeft: false
      },
      {
        prop: 'Customer_Approval_Status__c',
        name: 'Customer Approval Status',
        visible: true,
        width: 250,
        type: 'string',
        sortable: true,
        frozenLeft: false
      },
      {
        prop: 'iron_approval_date',
        name: 'Iron Approval Date',
        visible: true,
        width: 180,
        type: 'date',
        sortable: true,
        frozenLeft: false
      },
      {
        prop: 'customer_approval_date',
        name: 'Customer Approval Date',
        visible: true,
        width: 200,
        type: 'date',
        sortable: true,
        frozenLeft: false
      },
      {
        prop: 'timesheet_type',
        name: 'Timesheet Type',
        visible: true,
        width: 180,
        type: 'string',
        sortable: true,
        frozenLeft: false
      },
      {
        prop: 'timesheet_start_date',
        name: 'Timesheet Start Date',
        visible: true,
        width: 180,
        type: 'date',
        sortable: true,
        frozenLeft: false
      },
      {
        prop: 'timesheet_year',
        name: 'Timesheet Year',
        visible: true,
        width: 180,
        type: 'string',
        sortable: true,
        frozenLeft: false
      },
      {
        prop: 'timesheet_month',
        name: 'Timesheet Month',
        visible: true,
        width: 180,
        type: 'string',
        sortable: true,
        frozenLeft: false
      },
      {
        prop: 'job',
        name: 'Job',
        width: 140,
        visible: true,
        type: 'string',
        sortable: true,
        frozenLeft: false
      },
      {
        prop: 'jobSnumber',
        name: 'Job (S Number)',
        width: 140,
        visible: true,
        type: 'string',
        sortable: true,
        frozenLeft: false
      },
      {
        prop: 'timesheet_worker',
        name: 'Timesheet Worker',
        width: 180,
        visible: true,
        type: 'string',
        sortable: true
      },
      {
        prop: 'job_worker',
        name: 'Job Worker',
        width: 180,
        type: 'string',
        visible: true,
        sortable: true
      },
      {
        prop: 'same_worker',
        name: 'Same Worker in Timesheet/Job',
        width: 240,
        type: 'string',
        visible: true,
        sortable: true
      },
      {
        prop: 'vendor',
        name: 'Vendor',
        width: 180,
        type: 'string',
        visible: true,
        sortable: true
      },
      {
        prop: 'customer_site',
        name: 'Customer Site Signoff Name',
        width: 220,
        type: 'string',
        visible: true,
        sortable: true,
        showMore: true
      },
      {
        prop: 'observation',
        name: 'Observations/ Suggestions',
        width: 220,
        type: 'string',
        visible: true,
        sortable: true,
        showMore: true
      },
      {
        prop: 'projectName',
        name: 'Project',
        width: 220,
        type: 'string',
        visible: true,
        sortable: true,
        showMore: true
      },
      {
        prop: 'region',
        name: 'Region',
        type: 'string',
        width: 180,
        visible: true,
        sortable: true
      },
      {
        prop: 'country',
        name: 'Country',
        type: 'string',
        width: 180,
        visible: true,
        sortable: true
      },
      {
        prop: 'timesheet_week_number_iso_auto',
        name: 'Timesheet Week Number (ISO) - Auto',
        type: 'string',
        width: 280,
        visible: true,
        sortable: true
      },
      {
        prop: 'Timesheet_Start_Week__c',
        name: 'Timesheet Start Week',
        type: 'string',
        width: 250,
        visible: true,
        sortable: true
      },
      {
        prop: 'Timesheet_End_Week__c',
        name: 'Timesheet End Week',
        type: 'string',
        width: 250,
        visible: true,
        sortable: true
      },
      {
        prop: 'final_timesheet',
        name: 'Final Timesheet?',
        type: 'string',
        width: 180,
        visible: true,
        sortable: true
      },
      {
        prop: 'Monday_Hours__c',
        name: 'Monday Hours (Actual)',
        type: 'string',
        width: 250,
        visible: true,
        sortable: true
      },
      {
        prop: 'Monday_Job_Schedule_Hours__c',
        name: 'Monday Jobs Schedule Hours',
        type: 'string',
        width: 250,
        visible: true,
        sortable: true
      },
      {
        prop: 'Is_Monday_Holiday_in_Job_Schedule',
        name: 'Is Monday Holiday in Job Schedule?',
        type: 'string',
        width: 300,
        visible: true,
        sortable: true
      },
      {
        prop: 'Is_Monday_Public_Holiday__c',
        name: 'Is Monday Public Holiday',
        type: 'string',
        width: 250,
        visible: true,
        sortable: true
      },
      {
        prop: 'Tuesday_Hours_Actual__c',
        name: 'Tuesday Hours (Actual)',
        type: 'string',
        width: 250,
        visible: true,
        sortable: true
      },
      {
        prop: 'Tuesday_Job_Schedule_Hours__c',
        name: 'Tuesday Jobs Schedule Hours',
        type: 'string',
        width: 250,
        visible: true,
        sortable: true
      },
      {
        prop: 'Is_Tuesday_Holiday_in_Jobsite__c',
        name: 'Is Tuesday Holiday in Job Schedule?',
        type: 'string',
        width: 300,
        visible: true,
        sortable: true
      },
      {
        prop: 'Is_Tuesday_Public_Holiday__c',
        name: 'Is Tuesday Public Holiday',
        type: 'string',
        width: 250,
        visible: true,
        sortable: true
      },
      {
        prop: 'Wednesday_Hours_Actual__c',
        name: 'Wednesday Hours (Actual)',
        type: 'string',
        width: 250,
        visible: true,
        sortable: true
      },
      {
        prop: 'Wednesday_Job_Schedule_Hours__c',
        name: 'Wednesday Jobs Schedule Hours',
        type: 'string',
        width: 250,
        visible: true,
        sortable: true
      },
      {
        prop: 'Is_Wednesday_Holiday_in_Jobsite__c',
        name: 'Is Wednesday Holiday in Job Schedule?',
        type: 'string',
        width: 300,
        visible: true,
        sortable: true
      },
      {
        prop: 'Is_Wednesday_Public_Holiday__c',
        name: 'Is Wednesday Public Holiday',
        type: 'string',
        width: 250,
        visible: true,
        sortable: true
      },
      {
        prop: 'Thursday_Hours_Actual__c',
        name: 'Thursday Hours (Actual)',
        type: 'string',
        width: 250,
        visible: true,
        sortable: true
      },
      {
        prop: 'Thursday_Job_Schedule_Hours__c',
        name: 'Thursday Jobs Schedule Hours',
        type: 'string',
        width: 250,
        visible: true,
        sortable: true
      },
      {
        prop: 'Is_Thursday_Holiday_in_Jobsite__c',
        name: 'Is Thursday Holiday in Job Schedule?',
        type: 'string',
        width: 300,
        visible: true,
        sortable: true
      },
      {
        prop: 'Is_Thursday_Public_Holiday__c',
        name: 'Is Thursday Public Holiday',
        type: 'string',
        width: 250,
        visible: true,
        sortable: true
      },
      {
        prop: 'Friday_Hours_Actual__c',
        name: 'Friday Hours (Actual)',
        type: 'string',
        width: 250,
        visible: true,
        sortable: true
      },
      {
        prop: 'Friday_Job_Schedule_Hours__c',
        name: 'Friday Jobs Schedule Hours',
        type: 'string',
        width: 250,
        visible: true,
        sortable: true
      },
      {
        prop: 'Is_Friday_Holiday_in_Jobsite__c',
        name: 'Is Friday Holiday in Job Schedule?',
        type: 'string',
        width: 300,
        visible: true,
        sortable: true
      },
      {
        prop: 'Is_Friday_Public_Holiday__c',
        name: 'Is Friday Public Holiday',
        type: 'string',
        width: 250,
        visible: true,
        sortable: true
      },
      {
        prop: 'Saturday_Hours_Actual__c',
        name: 'Saturday Hours (Actual)',
        type: 'string',
        width: 250,
        visible: true,
        sortable: true
      },
      {
        prop: 'Saturday_Job_Schedule_Hours__c',
        name: 'Saturday Jobs Schedule Hours',
        type: 'string',
        width: 250,
        visible: true,
        sortable: true
      },
      {
        prop: 'Is_Saturday_Holiday_in_Jobsite__c',
        name: 'Is Saturday Holiday in Job Schedule?',
        type: 'string',
        width: 300,
        visible: true,
        sortable: true
      },
      {
        prop: 'Is_Saturday_Public_Holiday__c',
        name: 'Is Saturday Public Holiday',
        type: 'string',
        width: 250,
        visible: true,
        sortable: true
      },
      {
        prop: 'Sunday_Hours_Actual__c',
        name: 'Sunday Hours (Actual)',
        type: 'string',
        width: 250,
        visible: true,
        sortable: true
      },
      {
        prop: 'Sunday_Job_Schedule_Hours__c',
        name: 'Sunday Jobs Schedule Hours',
        type: 'string',
        width: 250,
        visible: true,
        sortable: true
      },
      {
        prop: 'Is_Sunday_Holiday_in_Jobsite__c',
        name: 'Is Sunday Holiday in Job Schedule?',
        type: 'string',
        width: 300,
        visible: true,
        sortable: true
      },
      {
        prop: 'Is_Sunday_Public_Holiday__c',
        name: 'Is Sunday Public Holiday',
        type: 'string',
        width: 250,
        visible: true,
        sortable: true
      },
      {
        prop: 'Monday_Task_Performed__c',
        name: 'Monday Task Performed',
        type: 'string',
        width: 250,
        visible: true,
        sortable: true,
        showMore: true
      },
      {
        prop: 'Tuesday_Tasks_Performed__c',
        name: 'Tuesday Tasks Performed',
        type: 'string',
        width: 250,
        visible: true,
        sortable: true,
        showMore: true
      },
      {
        prop: 'Wednesday_Tasks_Performed__c',
        name: 'Wednesday Task Performed',
        type: 'string',
        width: 250,
        visible: true,
        sortable: true,
        showMore: true
      },
      {
        prop: 'Thursday_Tasks_Performed__c',
        name: 'Thursday Tasks Performed',
        type: 'string',
        width: 250,
        visible: true,
        sortable: true,
        showMore: true
      },
      {
        prop: 'Friday_Tasks_Performed__c',
        name: 'Friday Tasks Performed',
        type: 'string',
        width: 250,
        visible: true,
        sortable: true,
        showMore: true
      },
      {
        prop: 'Saturday_Tasks_Performed__c',
        name: 'Saturday Tasks Performed',
        type: 'string',
        width: 250,
        visible: true,
        sortable: true,
        showMore: true
      },
      {
        prop: 'Sunday_Tasks_Performed__c',
        name: 'Sunday Tasks Performed',
        type: 'string',
        width: 250,
        visible: true,
        sortable: true,
        showMore: true
      },
      {
        prop: 'total_timesheet_hours',
        name: 'Total Timesheet Hours',
        type: 'string',
        width: 200,
        visible: true,
        sortable: true
      },
      {
        prop: 'overtime',
        name: 'Overtime?',
        type: 'string',
        width: 180,
        visible: true,
        sortable: true
      },
      {
        prop: 'vendor_daily_hours',
        name: 'Vendor Daily Hours',
        type: 'number',
        width: 200,
        visible: true,
        sortable: true,
      },
      {
        prop: 'overtime_standard_hours_manual',
        name: 'Overtime Standard Hours (Manual Override)',
        type: 'string',
        width: 320,
        visible: true,
        sortable: true
      },
      {
        prop: 'overtime_hours',
        name: 'Overtime Hours Based on Job Schedule',
        type: 'number',
        width: 300,
        visible: true,
        sortable: true
      },
      {
        prop: 'overtime_hours_holiday',
        name: 'Overtime Hours (Holiday)',
        type: 'number',
        width: 200,
        visible: true,
        sortable: true
      },
      {
        prop: 'overtime_hours_standard',
        name: 'Overtime Hours (Standard)',
        type: 'number',
        width: 210,
        visible: true,
        sortable: true
      },
      {
        prop: 'total_planned_hours',
        name: 'Total Planned Hours (Job Schedule)',
        type: 'number',
        width: 270,
        visible: true,
        sortable: true
      },
      {
        prop: 'total_short_hours',
        name: 'Total Short Hours',
        type: 'number',
        width: 180,
        visible: true,
        sortable: true
      },
      {
        prop: 'total_worked_hours_wt_offset',
        name: 'Total Worked Hours (Without Offset)',
        type: 'number',
        width: 270,
        visible: true,
        sortable: true
      },
      {
        prop: 'total_worked_hours_offset',
        name: 'Total Worked Hours (With Offset)',
        type: 'number',
        width: 250,
        visible: true,
        sortable: true
      },
      {
        prop: 'is_file_attached',
        name: 'Is File Attached',
        type: 'string',
        width: 220,
        visible: true,
        sortable: true
      },
      {
        prop: 'createdDate',
        name: 'Created Date',
        type: 'date',
        width: 180,
        visible: true,
        sortable: true
      },
      {
        prop: 'createdBy',
        name: 'Created By',
        type: 'string',
        width: 180,
        visible: true,
        sortable: true,
        showMore: true
      },
      {
        prop: 'lastModifiedDate',
        name: 'Last Modified Date',
        type: 'date',
        width: 180,
        visible: true,
        sortable: true
      }
    ];
    this.hideColumnFromCheckInOut();
  }

  hideColumnFromCheckInOut() {
    if (this.fromcheckInOut || this.fromBillingManager) {
      const hiddenColumn = ['programName', 'Customer_Approval_Status__c', 'jobSnumber',
        'observation', 'Timesheet_Start_Week__c', 'Timesheet_End_Week__c',
        'Monday_Hours__c', 'Monday_Job_Schedule_Hours__c', 'Is_Monday_Holiday_in_Job_Schedule', 'Is_Monday_Public_Holiday__c',
        'Tuesday_Hours_Actual__c', 'Tuesday_Job_Schedule_Hours__c', 'Is_Tuesday_Holiday_in_Jobsite__c', 'Is_Tuesday_Public_Holiday__c',
        'Wednesday_Hours_Actual__c', 'Wednesday_Job_Schedule_Hours__c', 'Is_Wednesday_Holiday_in_Jobsite__c',
        'Is_Wednesday_Public_Holiday__c', 'Thursday_Hours_Actual__c', 'Thursday_Job_Schedule_Hours__c',
        'Monday_Task_Performed__c', 'Tuesday_Tasks_Performed__c', 'Wednesday_Tasks_Performed__c', 'Thursday_Tasks_Performed__c', 'Friday_Tasks_Performed__c', 'Saturday_Tasks_Performed__c', 'Sunday_Tasks_Performed__c',
        'Is_Thursday_Holiday_in_Jobsite__c', 'Is_Thursday_Public_Holiday__c', 'Friday_Hours_Actual__c',
        'Friday_Job_Schedule_Hours__c', 'Is_Friday_Holiday_in_Jobsite__c', 'Is_Friday_Public_Holiday__c',
        'Saturday_Hours_Actual__c', 'Saturday_Job_Schedule_Hours__c', 'Is_Saturday_Holiday_in_Jobsite__c', 'Is_Saturday_Public_Holiday__c',
        'Sunday_Hours_Actual__c', 'Sunday_Job_Schedule_Hours__c', 'Is_Sunday_Holiday_in_Jobsite__c', 'Is_Sunday_Public_Holiday__c'];
      this.columns = this.columns.filter(col => !hiddenColumn.includes(col.prop));
    } 
    // else if (this.accessType === 'vendor') {
    //   const hiddenColumn = ['##Action'];
    //   this.columns = this.columns.filter(col => !hiddenColumn.includes(col.prop));
    // }
  }

   async getData() {
    await this._commonService.getTimecardRecordTypeIds();
    this.isLoading = true;
    this.timesLoadedMore = 0;
    this._loader.showPreloader();
    if (localStorage.getItem('timecardRecordTypeIds') && JSON.parse(localStorage.getItem('timecardRecordTypeIds'))) {
      this.recordTypeIds = JSON.parse(localStorage.getItem('timecardRecordTypeIds'));
    }
    if (localStorage.getItem('filterCondition')) {
      this.enableFilters = ((this.fromcheckInOut || this.fromBillingManager) && this.enableFilterList && this.enableFilterList.length) ?
        this.enableFilterList : this.enableFilters;
      this.globalFilterCondition = this._commonService.getGlobalFilter(this.enableFilters);
    }
    this.getTimecardListing(0).subscribe(response => {
      this.tableData = this.prepareJobData(response.data.data);
      this.totalCount = response.data.count;
      this.noRecords = (response.data && response.data.data && response.data.data.length < this.itemsPerBatch) ? true : false;
      this.allItems = this.tableData.slice();
      this.setEmptyMessage();
      this.filterData();
    },
      (error) => {
        this.isLoading = false;
        this._loader.hidePreloader();
      },
      () => {
        this.isLoading = false;
        this._loader.hidePreloader();
      }
    );
  }

  getTimecardListing(offset = 0) {
    if (this.recordTypeIds && this.recordTypeIds['contract']) {
      this.globalFilterCondition['RecordTypeId'] = { inq: this.recordTypeIds['contract'] };
    }
    if (this.globalFilterCondition['Jobsite__c'] && this.globalFilterCondition['Jobsite__c']['inq']) {
      this.globalFilterCondition['Jobsite__c']['inq'] = this.globalFilterCondition['Jobsite__c']['inq'].filter(Boolean);
    }
    if ((this.fromcheckInOut || this.fromBillingManager) && this.workerData && this.workerData.jobs && this.workerData.jobs) {
      this.globalFilterCondition['Service_Contract__c'] = { inq: [this.workerData.jobs.Service_Contract__c] };
    }
    if (this.JobDetailTimesheet) {
      delete this.globalFilterCondition['CreatedDate'];
      this.globalFilterCondition['timesheetForJob'] = this.JobDetailTimesheet;
    }
    const filterCondition = this.globalFilterCondition;
    if (filterCondition['WorkerScheduled']) {
      filterCondition['Timesheet_Start_Date__c'] = filterCondition['WorkerScheduled'];
      delete filterCondition['WorkerScheduled'];
    }
    if (this._activateRoute.snapshot.queryParamMap.get('purchaseOrderId')) {
      this.globalFilterCondition['Purchase_Order_Ref__c'] = this._activateRoute.snapshot.queryParamMap.get('purchaseOrderId');
    }
    const filterObj = {
      filterCondition: filterCondition,
      order: 'CreatedDate  DESC',
      limit: this.itemsPerBatch,
      skip: offset
    };
    if (this.globalFilterCondition['IRON_Approval_Status__c']) {
      filterObj['where'] = { Status__c: filterObj.filterCondition.IRON_Approval_Status__c };
      delete filterObj.filterCondition.IRON_Approval_Status__c;
    }
    const userData = JSON.parse(localStorage.getItem('appData'));
    if (!this.viewAsVendor && (userData && userData['user'] && userData['user']['accessType'] && userData['user']['accessType'] === 'vendor' &&
      userData['user']['AccountId'])) {
      if (filterObj['where']) {
        filterObj['where']['Vendor__c'] = userData['user']['AccountId'];
      } else {
        filterObj['where'] = { Vendor__c: userData['user']['AccountId'] };
      }
    } else if(this.viewAsVendor) {
      if (filterObj['where']) {
      filterObj['where']['Vendor__c'] = this.vendorAccessTypes.userObj && this.vendorAccessTypes.userObj.AccountId;
      } else {
        filterObj['where'] = { Vendor__c: this.vendorAccessTypes.userObj && this.vendorAccessTypes.userObj.AccountId };
      }
    }
    if(this.workerData && this.workerData.isWorker) {
      filterObj['where'] = { ...filterObj['where'], Worker__c: this.workerData.workerSfdcId };
    }
    return this._timecardApi.filterTimeCardList(filterObj);
  }

  filterData(event?, type?) {
    if (event) {
      if (type === 'date') {
        if (event.value === '') {
          if (this.filterObj[event.input.id + '_temp']) {
            delete this.filterObj[event.input.id];
          }
        } else {
          const date = this.transform(event.value).split(',');
         this.filterObj[event.input.id] = date[0];
        }
      } else {
        if (event.target.value === '') {
          delete this.filterObj[event.currentTarget.id];
        } else {
          this.filterObj[event.currentTarget.id] = event.target.value;
        }
      }
    }
    this.tableData = this.allItems.filter((item) => {
      const notMatchingField = Object.keys(this.filterObj).find((key) =>
        this._utilityService.dataTableSearch(item, this.filterObj, key)
      );
      return !notMatchingField;
    });
   if (this.table) {
      this.table['offset'] = 0;
    }
    this.setEmptyMessage();
  }

  transform(value: string, type?) {
    let dateformate: string;
    type ? (dateformate = type) : (dateformate = 'short');
    this.datePipe = new DatePipe('en-US');
    const formateddate = value
      ? this.datePipe.transform(value, dateformate)
      : value;
    return formateddate;
  }

  setEmptyMessage() {
    const msg = 'No data to display';
    if (!this.tableData.length) {
      this.tableData = [
        {
          message: msg,
        },
      ];
      this.tableData[0][this.columns[0]['prop']] = msg;
    } else {
      if (this.tableData[0].hasOwnProperty('message')) {
        this.tableData.shift();
      }
    }
  }

  prepareJobData(data) {
    const jobData = [];
    let dateFormat = (this.fromcheckInOut || this.fromBillingManager) ? 'MM/DD/YYYY': 'L, LT z';
    if (data) {
      let obj;
      data.forEach((item, index) => {
        obj = {
          'id': item.id,
          'time': item.Name || '',
          'Status__c': item.Status__c || '',
          'iron_approval_date': item.Time_Card_Approved_IRON_Date_Time__c ?
            this._utilityService.dateFormate(item.Time_Card_Approved_IRON_Date_Time__c, 'short') : '',
          'customer_approval_date': item.Time_Card_Approved_Customer_Date_Time__c ?
            this._utilityService.dateFormate(item.Time_Card_Approved_Customer_Date_Time__c, 'short') : '',
          'timesheet_type': 'Timesheet (FTE)',
          'timesheet_start_date': item.Timesheet_Start_Date__c ?
            this._utilityService.dateFormate(item.Timesheet_Start_Date__c, 'short') : '',
          'timesheet_year': item.Timesheet_Year__c || '',
          'timesheet_month': item.Timesheet_Month__c || '',
          'job': (item.job && item.job.Iron_Job_num__c) || '',
          'timesheet_worker': item.worker ? item.worker.Name : '',
          'job_worker': item.job && item.job.worker ? item.job.worker.Name : '',
          'same_worker': (item.worker && item.job && item.job.worker && item.worker.sfdcId === item.job.worker.sfdcId) ? 'TRUE' : 'FALSE',
          'vendor': (item.vendor && item.vendor.Name) || '',
          'customer_site': item.Customer_Site_Signoff_Name__c || '',
          'region': item.job && item.job.jobsite && item.job.jobsite.GeoMetro ? item.job.jobsite.GeoMetro.Region__c : '',
          'country': item.job && item.job.jobsite && item.job.jobsite.GeoMetro ? item.job.jobsite.GeoMetro.Country__c : '',
          'timesheet_week_number_iso_auto': item.Timesheet_Week_Number_ISO_Auto__c || '',
          'final_timesheet': item.Final_Timecard__c ? 'Yes' : 'No',
          'total_timesheet_hours': (item.Total_Timesheet_Hours__c) ? item.Total_Timesheet_Hours__c : 0,
          'overtime': item.Overtime__c ? 'Yes' : 'No',
          'vendor_daily_hours': item.Vendor_Daily_Hours__c || '',
          'overtime_standard_hours_manual': item.Overtime_Hours_Manual__c || '',
          'overtime_hours': (item.Total_Timesheet_Hours__c) ? item.Total_Timesheet_Hours__c : 0,
          'overtime_hours_holiday': (item.Total_Overtime_Hours_Holiday_Weekday__c) ? item.Total_Overtime_Hours_Holiday_Weekday__c : 0,
          'overtime_hours_standard': (item.Total_Overtime_Hours_Standard_Weekday__c) ? item.Total_Overtime_Hours_Standard_Weekday__c : 0,
          'total_planned_hours': (item.Total_Planned_Hours_Job_Schedule__c) ? item.Total_Planned_Hours_Job_Schedule__c : 0,
          'total_short_hours': (item.Total_Short_Worked_Hours__c) ? item.Total_Short_Worked_Hours__c : 0,
          'total_worked_hours_wt_offset': (item.Total_Worked_Hours_Without_Offset__c) ? item.Total_Worked_Hours_Without_Offset__c : 0,
          'total_worked_hours_offset': (item.Total_Worked_Hours__c) ? item.Total_Worked_Hours__c : 0,
          'is_file_attached': (item.Have_files) ? 'Yes' : 'No',
          'shortUrl': item.File_URL || '',
          'createdDate': item.CreatedDate ? this._utilityService.dateFormate(item.CreatedDate, 'short') : '',
          'createdBy': (item.job && item.job.vendor && item.job.vendor.Name) ? item.job.vendor.Name : '',
          'lastModifiedDate': item.LastModifiedDate ? this._utilityService.dateFormate(item.LastModifiedDate, 'short') : '',
          'programName': (item.job && item.job.program && item.job.program.Name) ? item.job.program.Name : '',
          'Customer_Approval_Status__c': item.Customer_Approval_Status__c || '',
          'jobSnumber': (item.job && item.job.Name) || '',
          'observation': (item.Any_Observation_or_Suggestions_Notes__c) || '',
          'projectName': (item.job && item.job.project && item.job.project.Name) ? item.job.project.Name : '',
          'Timesheet_Start_Week__c': item.Timesheet_Start_Week__c ?
            this._utilityService.dateFormate(item.Timesheet_Start_Week__c, 'short') : '',
          'Timesheet_End_Week__c': item.Timesheet_End_Week__c ?
            this._utilityService.dateFormate(item.Timesheet_End_Week__c, 'short') : '',
          'Monday_Hours__c': (item.Monday_Hours__c) ? item.Monday_Hours__c : 0,
          'Monday_Job_Schedule_Hours__c': (item.Monday_Job_Schedule_Hours__c) ? item.Monday_Job_Schedule_Hours__c : 0,
          'Is_Monday_Holiday_in_Job_Schedule': (item.Is_Monday_Holiday_in_Jobsite__c) ? 'Yes' : 'No',
          'Is_Monday_Public_Holiday__c': (item.Is_Monday_Public_Holiday__c) ? 'Yes' : 'No',
          'Tuesday_Hours_Actual__c': (item.Tuesday_Hours_Actual__c ) ? item.Tuesday_Hours_Actual__c :  0,
          'Tuesday_Job_Schedule_Hours__c': (item.Tuesday_Job_Schedule_Hours__c) ? item.Tuesday_Job_Schedule_Hours__c : 0,
          'Is_Tuesday_Holiday_in_Jobsite__c': (item.Is_Tuesday_Holiday_in_Jobsite__c) ? 'Yes' : 'No',
          'Is_Tuesday_Public_Holiday__c': (item.Is_Tuesday_Public_Holiday__c) ? 'Yes' : 'No',
          'Wednesday_Hours_Actual__c': (item.Wednesday_Hours_Actual__c) ? item.Wednesday_Hours_Actual__c : 0,
          'Wednesday_Job_Schedule_Hours__c': (item.Wednesday_Job_Schedule_Hours__c) ? item.Wednesday_Job_Schedule_Hours__c : 0,
          'Is_Wednesday_Holiday_in_Jobsite__c': (item.Is_Wednesday_Holiday_in_Jobsite__c) ? 'Yes' : 'No',
          'Is_Wednesday_Public_Holiday__c': (item.Is_Wednesday_Public_Holiday__c) ? 'Yes' : 'No',
          'Thursday_Hours_Actual__c': (item.Thursday_Hours_Actual__c) ? item.Thursday_Hours_Actual__c : 0,
          'Thursday_Job_Schedule_Hours__c': (item.Thursday_Job_Schedule_Hours__c) ? item.Thursday_Job_Schedule_Hours__c : 0,
          'Is_Thursday_Holiday_in_Jobsite__c': (item.Is_Thursday_Holiday_in_Jobsite__c) ? 'Yes' : 'No',
          'Is_Thursday_Public_Holiday__c': (item.Is_Thursday_Public_Holiday__c) ? 'Yes' : 'No',
          'Friday_Hours_Actual__c': (item.Friday_Hours_Actual__c) ? item.Friday_Hours_Actual__c : 0,
          'Friday_Job_Schedule_Hours__c': (item.Friday_Job_Schedule_Hours__c ) ? item.Friday_Job_Schedule_Hours__c : 0,
          'Is_Friday_Holiday_in_Jobsite__c': (item.Is_Friday_Holiday_in_Jobsite__c) ? 'Yes' : 'No',
          'Is_Friday_Public_Holiday__c': (item.Is_Friday_Public_Holiday__c) ? 'Yes' : 'No',
          'Saturday_Hours_Actual__c': (item.Saturday_Hours_Actual__c) ? item.Saturday_Hours_Actual__c : 0,
          'Saturday_Job_Schedule_Hours__c': (item.Saturday_Job_Schedule_Hours__c ) ? item.Saturday_Job_Schedule_Hours__c : 0,
          'Is_Saturday_Holiday_in_Jobsite__c': (item.Is_Saturday_Holiday_in_Jobsite__c) ? 'Yes' : 'No',
          'Is_Saturday_Public_Holiday__c': (item.Is_Saturday_Public_Holiday__c) ? 'Yes' : 'No',
          'Sunday_Hours_Actual__c': (item.Sunday_Hours_Actual__c) ? item.Sunday_Hours_Actual__c : 0,
          'Sunday_Job_Schedule_Hours__c': (item.Sunday_Job_Schedule_Hours__c) ? item.Sunday_Job_Schedule_Hours__c : 0,
          'Is_Sunday_Holiday_in_Jobsite__c': (item.Is_Sunday_Holiday_in_Jobsite__c) ? 'Yes' : 'No',
          'Is_Sunday_Public_Holiday__c': (item.Is_Sunday_Public_Holiday__c) ? 'Yes' : 'No',
          'Monday_Task_Performed__c': item.Monday_Task_Performed__c || '',
          'Tuesday_Tasks_Performed__c': item.Tuesday_Tasks_Performed__c || '',
          'Wednesday_Tasks_Performed__c': item.Wednesday_Tasks_Performed__c || '',
          'Thursday_Tasks_Performed__c': item.Thursday_Tasks_Performed__c || '',
          'Friday_Tasks_Performed__c': item.Friday_Tasks_Performed__c || '',
          'Saturday_Tasks_Performed__c': item.Saturday_Tasks_Performed__c || '',
          'Sunday_Tasks_Performed__c': item.Sunday_Tasks_Performed__c || ''
        };
        jobData.push(obj);
      });
    }
    return jobData;
  }

  clearSearch(col) {
    this.filterObj[col] = '';
    delete this.filterObj[col];
    this.tableData = this.allItems.filter((item) => {
      const notMatchingField = Object.keys(this.filterObj).find((key) =>
        this._utilityService.dataTableSearch(item, this.filterObj, key)
      );
      return !notMatchingField;
    });
   this.table.offset = 0;
    this.setEmptyMessage();
  }

  loadMoreRecords() {
    this._loader.showPreloader();
    this.timesLoadedMore += 1;
    this.getTimecardListing(this.itemsPerBatch * this.timesLoadedMore).subscribe(
      (response) => {
        const currentData = this.prepareJobData(response.data.data);
        currentData.forEach((c) => {
          this.allItems.push(c);
        });
        this.tableData = [...this.allItems];
        this.allItems = this.tableData.slice();
        this.filterData();
      },
      (error) => {
        this._loader.hidePreloader();
      },
      () => {
        this._loader.hidePreloader();
      }
    );
  }

  // on click of link in table
  redirectToDetails(data, value) {
    const purchaseOrderId = this._activateRoute.snapshot.queryParamMap.get('purchaseOrderId');
    if (value) {
      value = value.toLowerCase();
      let url: string;
      const params = { isTimeSheet: true };
      if (this.JobDetailTimesheet) {
        params['backTo'] = 'vms/jobs/view/' + this.JobDetailTimesheet;
      }
      if (this.fromcheckInOut) {
        params['fromcheckInOut'] = this.fromcheckInOut;
        url = 'app/workforce-checkin-checkout/billing-manager/'
      } else if (this.fromBillingManager) {
        params['fromBillingManager'] = this.fromBillingManager;
        url = 'vms/billing-manager/timesheet-detail/'
      } else {
        const path = ((!this.viewAsVendor && this.accessType === 'vendor') || this.viewAsVendor)? '/vms' : '/pms';
        url = path + '/vms-billing-manager/timsheetSheetdetails/';
      }
      url = url + value;
      this.router.navigate([url], { queryParams: params });
      if (purchaseOrderId) {
        this.router.navigate([url], { queryParams: { source: 'TimeSheetListingPO', purchaseOrderId: purchaseOrderId } });
      }
    }
  }

  export() {
    this._exportToExcelService.exportToExcel(this.tableData, this.columns, 'Timesheet')
  }

  ngOnDestroy() {
    this.sidebarStateSub.unsubscribe();
  }

  /**
   * Method to download the document.
   * @param doc - the document object that to be download
   * @param type - type to handle the case of open the document in browser new tab.
   * The argument @param type is by default 0, represent download by defalut. 
   */
  downloadDocument(modelId) {
    this._documentApi.find({
      where: {
        modelName: 'Timecard',
        modelId: modelId,
      },
      order: 'id DESC'
    }).subscribe((results: any) => {
      if (results && results.length > 0) {
        const file = results[0];
        const containerPath = environment.baseUrl + '/' + environment.apiVersion + '/Containers/'
        const filePath = containerPath + file.fileMeta.path;
        this._commonService.download(filePath, file.fileMeta.name);
      }
    },
      err => {
        this._alertService.error((err && err.message) ? err.message : err);
      }
    );
  }
  /**
   *
   * @param modal
   * @param size
   * @param row
   * @param value
   * this will open modal to upload doc when doc is not there/ download latest doc
   */
  uploadDocument(modal, size, row, value) {
    this.documentModelId = row['id'];
    if (value === 'Yes') {
      this.downloadDocument(this.documentModelId);
    } else {
      if (this.documentModelId) {
        this._modelService.open(modal, size);
      }

    }

  }
  /**
   *
   * @param event | receiving the uploaded doc response
   */
  documentUploaded(event) {
    const doc = (event && event.length > 0) ? event[0] : event;
    if (doc && this.tableData && this.tableData.length) {
      this.tableData.forEach(item => {
        if (item.id === doc.modelId) {
          item['is_file_attached'] = 'Yes';
          console.log(item)
        }
      });
      this.tableData = [...this.tableData]; //refresh data list
    }
  }

  /**
   * Status Class Color Change
   */
  getStatusClass(status: string = '') {
    if (status) {
      status = status.toLowerCase();
      if (this._statusCss.hasOwnProperty(status)) {
        return this._statusCss[status];
      } else {
        return '';
      }
    }

  }

  currentDate() {
    const d = new Date();
    this.systemCurrentDate = d.toISOString();
  }

  /**
  * On Action click status change
  */
  onClickActionButton(row) {
    this.currentDate();
    const msg = (row.Status__c === 'Approved') ? 'Customer' : 'Iron';
    const reqObj = Object.assign({});
    reqObj['id'] = row.id;
    if (row.Status__c !== 'Approved') {
      reqObj['Status__c'] = 'Approved';
      reqObj['Time_Card_Approved_IRON_Date_Time__c'] = this.systemCurrentDate;
    } else if (row.Status__c === 'Approved') {
      reqObj['Customer_Approval_Status__c'] = 'Approved';
      reqObj['Status__c'] = 'Approved';
      reqObj['Time_Card_Approved_Customer_Date_Time__c'] = this.systemCurrentDate;
    }
    const _thisEvnt = this;
    _thisEvnt._confirmDialogService.confirmThis(
      {
        title: 'Warning!!',
        titleIcon: 'mdi mdi-alert text-warning',
        text: 'Are you sure to make ' + msg + ' ' + 'Approval?'
      },
      function () {
        _thisEvnt._loader.showPreloader();
        _thisEvnt._timecardApi.patchAttributes(row.id, reqObj).subscribe(res => {
          if (res) {
            _thisEvnt.tableData = [];
            _thisEvnt.allItems = [];
            _thisEvnt.getData();
            _thisEvnt._alertService.success(
              (row.Status__c === 'Approved') ? 'Customer Approval Status is Approved' : 'Iron Approval Status is Approved'
              );
            _thisEvnt._loader.hidePreloader();
          }

        }, err => {
          _thisEvnt._alertService.warn('Oops! something went wrong.');
          _thisEvnt._loader.hidePreloader();
        })
      },
      function () {
        //Do nothing on cancel
        _thisEvnt._loader.hidePreloader();
      }
    );
  }

  addTimecard(content, size, data: any) {
    this.timesheetData = data;
    this._modelService.open(content, size);
  }
  getWorkerInfo(userSfdcId) {
    this._loader.showPreloader();
    const filter = {
      where: { Contact__c: userSfdcId },
      fields: ['sfdcId', 'Name', 'Worker_Type__c', 'Contact__c', 'Account_Name__c', 'Account__c'],
      include: [
        {
          relation: 'user',
          scope: {
            fields: ['sfdcId', 'profileImage', 'url', 'email']
          }
        },
        {
          relation: 'jobs',
          scope: {
            fields: ['sfdcId', 'Name', 'RecordTypeId' ,'Iron_Job_num__c','Vendor__c','Work_Order_Number'],
            where: { RecordTypeId: '0121a0000006QniAAE' },
            skip: 0,
            order: 'createdAt DESC'
          }
        },
        {
          relation: 'account',
          scope: {
            fields: ['Community_Type__c']
          }
        }
      ],
      skip: 0,
      limit: 1,
      order: 'createdAt DESC'
    }
    this._workerApi.find(filter).subscribe((res) => {
      this._loader.hidePreloader();
      if (res && res.length) {
        this._data = res;
        this.jobDetail = {
        IronJobNum : this._data[0]["jobs"][0]["Iron_Job_num__c"],
        jobName : this._data[0]["jobs"][0]["Name"],
        recordType : this._data[0]["jobs"][0]["RecordTypeId"],
        vendorC : this._data[0]["jobs"][0]["Vendor__c"],
        workOrderNumer : this._data[0]["jobs"][0]["Work_Order_Number"],
        jobsfdcId : this._data[0]["jobs"][0]["sfdcId"],
        workerName : this._data[0]["Name"],
        vendorAccount : this._data[0]["Account_Name__c"]
        }        
      }
      (err) => {
        console.log(err.message);
      };
    });
  }
}
