import { Component, ElementRef, EventEmitter, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MilestoneApi, GroupApi, TaskApi } from 'shared/sdk';
import { DeliveryManagerService } from 'shared/views/pms/delivery-manager/delivery-manager.service';
import { CommonService } from '../../services/common.service';
import { PreloaderService } from '../../services/preloader.service';
import { AlertService } from 'shared/services/alert.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { forkJoin } from 'rxjs';
import { otherHelpdeskProjects, hrHelpdeskCategories } from './../../../shared/models/static-list-data.service';

@Component({
  selector: 'app-helpdesk-task',
  templateUrl: './helpdesk-task.component.html',
  styleUrls: ['./helpdesk-task.component.css']
})
export class HelpdeskTaskComponent implements OnInit, OnChanges {

  quillFile: any;
  meQuillRef: any;
  quillBugRef: any;
  quillDescRef: any;
  private _token: string;
  @ViewChild('quillDescFile') quillDescFileRef: ElementRef;
  @Output() cancel: EventEmitter<boolean> = new EventEmitter;
  minDueDate = this._getStartDate();
  user: string;
  fileName = '';
  uniqueModelId = '';
  uploadStarts: boolean = false;
  projectDetails = environment.helpdeskProjects;
  usersITHelpDeskSfdcId: any;
  helpdeskProjects = otherHelpdeskProjects;
  hrHelpdeskCategories = hrHelpdeskCategories;

  constructor(
    private _preloader: PreloaderService,
    private _commonService: CommonService,
    private _httpCient: HttpClient,
    public _deliveryManagerService: DeliveryManagerService,
    private alertService: AlertService,
    private _taskApi: TaskApi,
    private _fb: FormBuilder,
    private _groupApi: GroupApi,
    private _milestoneApi: MilestoneApi
  ) {
  }
  public formTask: FormGroup;

  ngOnInit() {
    this._token = localStorage.getItem('accessToken');
    this._createForm();
  }

  ngOnChanges() {
    this._resetPage();
  }

  private _createForm() {
    this.formTask = this._fb.group({
      projectType: 'IT',
      projectSubType: 'hrIndia',
      selectedProject: [''],
      selectedCategory: ['generalQuery'],
      projectCategory: [''],
      project: '',
      milestone: '',
      groups: '',
      requestTitle: ['', [Validators.required, Validators.maxLength(80), this._commonService.removeWhiteSpaces]],
      requestor: this.loggedInWorkerId,
      businessRequestor: this.loggedInWorkerId,
      requestType: 'Task (Action)',
      requestPriority: ['Medium (P3)', Validators.required],
      completePercentage: 0,
      requestStatus: 'New (1)',
      createdBy: this.loggedInWorkerId,
      startDate: [{ value: this._getStartDate() }],
      startTime: new Date,
      dueDate: [{ value: this._getStartDate(), disabled: true }, Validators.required],
      dueTime: new Date,
      duration: '',
      durationType: '',
      requestDescription: [null, [Validators.required, this._commonService.removeWhiteSpaces]],
      assignmentGroup: this._fb.array([]),
      assignmentUser: this._fb.array([]),
      membershipGroup: this._fb.array([]),
      membershipUser: this._fb.array([])
    });
    this._prefilledForm();

    this.formTask.controls['selectedProject'].valueChanges.subscribe(value => {
      const helpdeskType = this.formTask.controls['projectType'].value;
      if (value && helpdeskType && helpdeskType === 'others') {
        this._getDetailsByTicketType(value);
      }
    })

    this.formTask.controls['selectedCategory'].valueChanges.subscribe(value => {
      const helpdeskType = this.formTask.controls['projectSubType'].value;
      if (value && helpdeskType && helpdeskType.includes('hr')) {
        this.formTask.controls['milestone'].setValue(this._getMilestoneByCategory(helpdeskType));
      }
    })
  }

  chooseProject(e) {
    const type = e.target.value === 'HR' ? 'hrIndia' : e.target.value;
    this.formTask.get('projectCategory').setValue('');
    if (type === 'others') {
      this.formTask.get('selectedProject').setValidators(Validators.required);
      this.formTask.get('selectedProject').updateValueAndValidity();
    } else {
      this.formTask.get('selectedProject').setValue('');
      this.formTask.get('selectedProject').clearValidators();
      this.formTask.get('selectedProject').updateValueAndValidity();
      this._getDetailsByTicketType(type);
    }
  }

  private _prefilledForm() {
    this._getDetailsByTicketType(this.formTask.controls['projectType'].value);
    this.getPriority();
    this.formTask.controls['dueDate'].setValue(this.minDueDate);
  }

  private _getStartDate(): any {
    const d = new Date();
    d.setHours(0, 0, 0, 0);
    return d
  }

  public onSubmit() {
    if (this.formTask.invalid) { return };
    // Send CreatedBy field to Add user SfdcID in 'Created_By_Contact__c' 
    this.user = JSON.parse(localStorage.getItem('appData'));

    let finalData = this.formTask.value;
    finalData = this._prepareDataForFormArray(this.formTask.controls['groups'].value);
    finalData['createdBy'] = this.user['user']['sfdcId'];
    finalData['startDate'] = this.formTask.controls['startDate'].value ? this._deliveryManagerService.getIsoDateWithoutTimeInfluence(this.formTask.controls['startDate'].value) : null;
    finalData['startTime'] = this._deliveryManagerService.getTimeInDBFormat(this.formTask.controls['startTime'].value);
    finalData['dueDate'] = this.formTask.controls['dueDate'].value ? this._deliveryManagerService.getIsoDateWithoutTimeInfluence(this.formTask.controls['dueDate'].value) : null;
    finalData['dueTime'] = this._deliveryManagerService.getTimeInDBFormat(this.formTask.controls['dueTime'].value);
    finalData = this.prepareDataForProjectCategory(finalData);
    this._onCreateTask(finalData);
  }

  prepareDataForProjectCategory(finalData) {
    if(finalData.projectCategory) {
      finalData.requestTitle = finalData.projectCategory + '-' + finalData.requestTitle;
    }  
    return finalData;
  }

  private _onCreateTask(data) {
    this._preloader.showPreloader();
    this._taskApi.createTask(JSON.stringify(data)).subscribe(result => {
      this._preloader.hidePreloader();
      this.uniqueModelId = result.taskDetails[0].id;
      if (this.fileName) {
        this.uploadStarts = true;
      }
      this.alertService.success('Task (' + result.taskDetails[0].Task_Number__c + ') is created successfully', false, 30);
      setTimeout(() => {
        this.cancel.emit(true);
      }, 2000);
    }, err => {
      this._preloader.hidePreloader();
      this.cancel.emit(true);
      this.alertService.error('Something went wrong');
    }, () => {
      this._preloader.hidePreloader();
    });
  }
  
  private async _getDetailsByTicketType(type) {
    type = type === 'HR' ? 'hrIndia' : type; 
    if (type.includes('hr')) {
      this.formTask.controls['milestone'].setValue(this._getMilestoneByCategory(type));
    } else {
      this.formTask.controls['milestone'].setValue(this.projectDetails[type]['milestone']);
    }
    this.formTask.controls['project'].setValue(this.projectDetails[type]['sfdcId']);
    this.formTask.controls['groups'].setValue(this.projectDetails[type]['groups']);
    await this._getUsersFromGroup(type);
  }

  private _prepareDataForFormArray(groupSfdcIds): Object {
    const form = JSON.parse(JSON.stringify(this.formTask.value));
    form.assignmentGroup = this.dataForAssignmentGroup(groupSfdcIds);
    form.assignmentUser = this.dataForAssignmentUser();
    form.membershipGroup = this.dataForMembershipGroup(groupSfdcIds);
    form.membershipUser = this.dataForMembershipUser();
    return form;
  }

  private _getMilestoneByCategory(type) {
    switch (this.formTask.controls['selectedCategory'].value) {
      case 'payrollTaxation':
        return type === 'hrUsa' ? 'a6c4z000000IHjVAAW' : 'a6c4z000000IHj6AAG';
      case 'performanceMeasurement':
        return type === 'hrUsa' ? 'a6c4z000000IHjaAAG' : 'a6c4z000000IHjBAAW';
      case 'attendance':
        return type === 'hrUsa' ? 'a6c4z000000IHjbAAG' : 'a6c4z000000IHjGAAW';
      case 'onboarding':
        return type === 'hrUsa' ? 'a6c2L00000093PkQAI' : 'a6c2L0000009TtzQAE';
      case 'separation':
        return type === 'hrUsa' ? 'a6c2L00000093PkQAI' : 'a6c4z000000IHjLAAW';
      case 'employeeGrievance':
        return type === 'hrUsa' ? 'a6c4z000000IHjfAAG' : 'a6c4z000000IHjQAAW';
      case 'generalQuery':
        return type === 'hrUsa' ? 'a6c4z000000IHjkAAG' : 'a6c2L0000009Tu9QAE';
    }
  }

  // getting the users from the group sfdcId
  private async _getUsersFromGroup(type) {
    const groupSfdcIds = this.projectDetails[type]['groups'][0];
    if (type === 'jobOrderDesk') {
      let newuserSfdcIDs = [];
      newuserSfdcIDs.push(this.projectDetails[type]['milestoneOwner']);
      this.usersITHelpDeskSfdcId = this._removeDuplicateFromArray(newuserSfdcIDs);
    } else {
      const milestoneReqObj = {
        where: { 'sfdcId': this.formTask.controls['milestone'].value },
        fields: ['Milestone_Primary_Owner__c'],
        include: {
          relation: 'milestoneOwner',
          scope: {
            fields: ['sfdcId', 'Contact__c']
          }
        }
      };
      const groupsReqObj = {
        where: { 'sfdcId': groupSfdcIds },
        fields: ['sfdcId'],
        include: {
          relation: 'departmentRoles',
          scope: {
            fields: ['sfdcId', 'Worker__c'],
            include: {
              relation: 'worker',
              scope: {
                where: this._setWhereCondition(type),
                fields: ['id', 'sfdcId', 'Name', 'Contact__c'],
                include: {
                  relation: 'contact',
                  scope: {
                    fields: ['sfdcId', 'Disable_PMS_Portal_Login__c']
                  }
                }
              }
            }
          }
        }
      }
      forkJoin(
        this._milestoneApi.findOne(milestoneReqObj),
        this._groupApi.findOne(groupsReqObj)
      ).subscribe(([milestoneRes, groupsRes]): any => {
        let newuserSfdcIDs = [];
        [(milestoneRes), (groupsRes)].forEach((workerList: any) => {
          if ((workerList.milestoneOwner && workerList.milestoneOwner.sfdcId) || Boolean(this.projectDetails[type]['milestoneOwner'])) {
            workerList.milestoneOwner && workerList.milestoneOwner.sfdcId ? newuserSfdcIDs.push(workerList.milestoneOwner.sfdcId) : newuserSfdcIDs.push(this.projectDetails[type]['milestoneOwner']);
          }
          if (workerList.departmentRoles && workerList.departmentRoles.length) {
            workerList.departmentRoles.forEach((drData, index) => {
              if (!drData['worker']) {
                return;
              }
              if (!newuserSfdcIDs.includes(drData['worker'].sfdcId) && !drData['worker']['contact']['Disable_PMS_Portal_Login__c']) {
                newuserSfdcIDs.push(drData['worker'].sfdcId)
              }
            })
          }
        })
        this.usersITHelpDeskSfdcId = this._removeDuplicateFromArray(newuserSfdcIDs);
      })
    }
  }

  private _setWhereCondition(type) {
    return type === 'IT' ?
      {
        and: [
          { 'Name': { inq: ['Kafil Ahmad', 'Bipin Kumar Singh', 'Prem Singh Rana', 'Gaurav Singh'] } },
          { 'Worker_Type_Sub__c': 'MSP' }
        ]
      } :
      {}
  }

  dataForAssignmentGroup(groupSfdcIds) {
    const groupArray = [];
    if (groupSfdcIds && groupSfdcIds.length) {
      groupSfdcIds.forEach(group => {
        groupArray.push({ groupOrMember: group });
      });
    }
    return groupArray;
  }

  dataForAssignmentUser() {
    const userArray = [];
    if (this.usersITHelpDeskSfdcId && this.usersITHelpDeskSfdcId.length) {
      this.usersITHelpDeskSfdcId.forEach(userSfdcIds => {
        userArray.push({ groupOrMember: userSfdcIds });
      });
    }
    return userArray;
  }

  dataForMembershipGroup(groupSfdcIds) {
    const groupMembershipArray = [];
    if (groupSfdcIds && groupSfdcIds.length) {
      groupSfdcIds.forEach(group => {
        groupMembershipArray.push({ groupOrMember: group, isMembership: "No" });
      });
    }
    return groupMembershipArray;
  }

  dataForMembershipUser() {
    const userMembershipArray = [];
    if (this.usersITHelpDeskSfdcId && this.usersITHelpDeskSfdcId.length) {
      this.usersITHelpDeskSfdcId.forEach(userSfdcIds => {
        userMembershipArray.push({ groupOrMember: userSfdcIds, isMembership: "Yes" });
      });
    }
    return userMembershipArray;
  }

  get f() {
    return this.formTask.controls;
  }

  getPriority() {
    const priority = this.f['requestPriority'].value;
    const finalValues = this._deliveryManagerService.getDateTimeAsPriority(priority);
    this.formTask.patchValue(finalValues);
    this.minDueDate = finalValues['dueDate'];
  };

  get loggedInWorkerId() {
    const { sfdcId } = this._commonService.loggedInWorker();
    return sfdcId;
  }

  private _resetPage() {
    if (this.formTask && this.formTask.value) {
      this._resetForm();
    } else {
      this._createForm();
    }
  }

  private _resetForm() {
    this.formTask.reset({
      sfdcId: null,
      requestor: '',
      requestorName: '',
      requestTitle: '',
      requestPriority: 'Very Low (P5)',
      project: '',
      milestone: '',
      startDate: this._getStartDate(),
      dueDate: null,
      startTime: null,
      dueTime: null,
      requestDescription: null,
      createdBy: null,
      assignmentGroup: this._fb.array([]),

    });
    this._prefilledForm();
    this.formTask.controls['startDate'].setValue(new Date());
  }

  public getEditorConfig(control: string): object {
    return {
      toolbar: {
        container: [
          ['bold', 'italic', 'underline', 'strike'],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'indent': '-1' }, { 'indent': '+1' }],
          [{ 'align': [] }],
          ['link', 'image']
        ],
        handlers: {
          image: (image) => {
            if (control === 'description') {
              this.customDescImageUpload(image);
            }
          }
        }
      }
    }
  }

  public onPaste(event, editor: string) {
    if (editor === 'description') {
      this.meQuillRef = this.quillDescRef;
    } else if (editor === 'bug') {
      this.meQuillRef = this.quillBugRef;
    }
    const IMAGE_ALLOWED_REGEX = /^image\/(p?jpeg|jpg|gif|png)$/i;
    const items = event.clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      if (IMAGE_ALLOWED_REGEX.test(items[i].type)) {
        this._uploadImage(items[i].getAsFile());
        return false;
      }
    }
  }

  public getMeEditorInstance(editorInstance: any, control: string) {
    if (control === 'description') {
      this.quillDescRef = editorInstance;
    }
  }

  public customDescImageUpload(image: any) {
    /* Here we trigger a click action on the file input field, this will open a file chooser on a client computer */
    this.meQuillRef = this.quillDescRef;
    this.quillDescFileRef.nativeElement.click();
  }

  public quillFileSelected(ev: any) {
    /* After the file is selected from the file chooser, we handle the upload process */
    this.quillFile = ev.target.files[0];
    this._uploadImage(this.quillFile);
  }

  private _uploadImage(file) {
    this._preloader.showPreloader();
    const path = environment.baseUrl + '/' + environment.apiVersion + '/Documents/uploadDocument?access_token=' + this._token + '&modelName=TaskConversation&cId=8';
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('modelName', 'Task');
    formData.append('modelId', '');
    formData.append('description', 'Compliance Management');
    formData.append('categoryId', '23');
    formData.append('subCategoryId', '169');

    this._httpCient.post(path, formData).subscribe((res: any) => {
      this._preloader.hidePreloader();
      const dataObj = res.status && res.status.data;
      if (dataObj && dataObj.file && dataObj.file.shortUrl) {
        this._printImageInEditor(dataObj.file.shortUrl, dataObj.file.id);
      }
    }, (error) => {
      console.log(error);
      this._preloader.hidePreloader();
    });
  }

  private _printImageInEditor(filePath, docId) {
    let range: any;
    const img = '<img class="img-within" src="' + filePath + '" data-docId="' + docId + '" />';
    range = this.meQuillRef.getSelection();
    this.meQuillRef.clipboard.dangerouslyPasteHTML(range.index, img);
  }

  setDocumentUploaded(event) {
    this.fileName = event.name;
  }

  private _removeDuplicateFromArray(arr) {
    return arr.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });
  }

}
