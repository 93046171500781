import { OnInit, Component, Input, EventEmitter, Output } from '@angular/core';
import { AppStateService } from 'shared/services/app-state.service';
import { CommonService } from 'shared/services/common.service';
import { GlobalFilterService } from 'shared/services/global-filter.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-date-range-lookup',
    templateUrl: './date-range-lookup.component.html',
    styleUrls: ['./date-range-lookup.component.css']
})
export class DateRangeLookupComponent implements OnInit {
    @Input() placeholder = 'Created Date';
    @Input() jobCreatedDate = false;
    @Input() messageScheduledDate = false;
    @Input() disable ;
    @Input() set resetData(isClear) {
        this.selectedDate = [];
    }
    @Input() set from(e) {
        if (e) {
            this._from = e;
        } else {
            this._from = '';
        }
    }
    @Input() selectedDefaultDate = [];

    @Output() selectedValue: EventEmitter<any> = new EventEmitter;
    selectedDate: any = [];
    _from = '';
    max = new Date();
    min: any;
    showMin = false;
    isPaySlipTab: boolean = false;
    isCheckinCheckoutTab: boolean = false;
    isVendorRJ : boolean = false;
    constructor(
        private _commonService: CommonService,
        private _appState: AppStateService,
        private _globalFilterService: GlobalFilterService,
        private _activatedRoute: ActivatedRoute,
        private router: Router) {

        this._activatedRoute.queryParams.subscribe(params => {
            if (params['tabId'] && (params['tabId'] === 'paySlips')) {
                this.isPaySlipTab = true;
            }
            if (params['activeId'] && (params['activeId'] === 'checkinCheckoutChart')) {
                this.isCheckinCheckoutTab = true;
            }
        });
        if(this.router.url == '/app/marketplace-recruitment-jobs'){
            this.isVendorRJ = true;
        }
    }
    ngOnInit() {
        if(this._from != 'analyticsPageViewReport'){
            this.disable = 0;
        }

        // to show selected date range
        const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
        if (this._from && this._from === 'startFromDate') {
            if (preselected && preselected['startFromDateOptions'] && preselected['startFromDateOptions'][0]) {
                this.selectedDate = [this._commonService.applyNewTimeZone(preselected['startFromDateOptions'][0][0], 'YYYY-MM-DDTHH:mm:ss'), this._commonService.applyNewTimeZone(preselected['startFromDateOptions'][0][1], 'YYYY-MM-DDTHH:mm:ss')];
            }
            this.max = new Date(3000, 3, 21, 20, 30);
        } else if(this._from && this._from === 'customerScheduled') {
            if (preselected && preselected['customerScheduled'] && preselected['customerScheduled'][0]) {
                this.selectedDate = [this._commonService.applyNewTimeZone(preselected['customerScheduled'][0][0], 'YYYY-MM-DDTHH:mm:ss'), this._commonService.applyNewTimeZone(preselected['customerScheduled'][0][1], 'YYYY-MM-DDTHH:mm:ss')];
            }
        } else if (this._from && this._from === 'analyticsDashboard') {
            if (this.selectedDefaultDate && this.selectedDefaultDate.length) {
                this.selectedDate = [this._commonService.applyNewTimeZone(this.selectedDefaultDate[0][0], 'YYYY-MM-DDTHH:mm:ss'), this._commonService.applyNewTimeZone(this.selectedDefaultDate[0][1], 'YYYY-MM-DDTHH:mm:ss')];
            } else {
                this.selectedDate = [];
            }
        } else if (this.jobCreatedDate) {
            if (preselected && preselected['jobCreatedDate'] && preselected['jobCreatedDate'][0]) {
                this.selectedDate = [this._commonService.applyNewTimeZone(preselected['jobCreatedDate'][0][0], 'YYYY-MM-DDTHH:mm:ss'), this._commonService.applyNewTimeZone(preselected['jobCreatedDate'][0][1], 'YYYY-MM-DDTHH:mm:ss')];
            } else {
                this.selectedDate = [];
            }
        } else if(this.messageScheduledDate) {
            var currentDate = new Date();                    
            this.max = new Date(currentDate.getFullYear(), currentDate.getMonth()+1, currentDate.getDate())
            if (preselected && preselected['messageScheduledDate'] && preselected['messageScheduledDate'][0]) {
                this.selectedDate = [this._commonService.applyNewTimeZone(preselected['messageScheduledDate'][0][0], 'YYYY-MM-DDTHH:mm:ss'), this._commonService.applyNewTimeZone(preselected['messageScheduledDate'][0][1], 'YYYY-MM-DDTHH:mm:ss')];
            } else {
                this.selectedDate = [];
            }
        } else {
            if (preselected && preselected['dateOptions'] && preselected['dateOptions'][0]) {
                this.selectedDate = [this._commonService.applyNewTimeZone(preselected['dateOptions'][0][0], 'YYYY-MM-DDTHH:mm:ss'), this._commonService.applyNewTimeZone(preselected['dateOptions'][0][1], 'YYYY-MM-DDTHH:mm:ss')];
            }
        }
        const userType = this._appState.getAccessType();
        if (userType && userType !== 'internal' && !this.isPaySlipTab && !this.isCheckinCheckoutTab && !this.isVendorRJ) {
            // only if current tab is not payslip set min value of calender
            this.getMinimumDateValue();
            this.showMin = true;
        } else if (this.isCheckinCheckoutTab) {
            this.getMinimumDateValueMonthWise(90);
            this.showMin = true;
        } else if(this.router.url == '/app/analytics/user-activity' || this.router.url == '/app/analytics/mobile-user-activity' || this.isVendorRJ ) {
            this.min = new Date(2021, 0, 1);
            this.showMin = true;
        } else if(this.router.url == '/app/admin-release-notes') {            
            var currentDate = new Date();                    
            this.max = new Date(currentDate.getFullYear() + 1, currentDate.getMonth(), currentDate.getDate())
        }
    }
    onChange() {
        this.selectedValue.emit(this.selectedDate);
    }

    onClearDate() {
        this.selectedDate = [];
        this.selectedValue.emit([]);
    }

    async getMinimumDateValue(setSelectedDate?) {
        const defaultDate = await this._globalFilterService.setFiltersDate(180);
        let startDate = [this._commonService.applyNewTimeZone(defaultDate['dateOptions'][0][0], 'YYYY-MM-DDTHH:mm:ss'), this._commonService.applyNewTimeZone(defaultDate['dateOptions'][0][1], 'YYYY-MM-DDTHH:mm:ss')];
        this.min = startDate[0];
    }

    async getMinimumDateValueMonthWise(setSelectedDate?) {
        const defaultDate = await this._globalFilterService.setFiltersDate(setSelectedDate);
        let startDate = [this._commonService.applyNewTimeZone(defaultDate['dateOptions'][0][0], 'YYYY-MM-DDTHH:mm:ss'), this._commonService.applyNewTimeZone(defaultDate['dateOptions'][0][1], 'YYYY-MM-DDTHH:mm:ss')];
        this.min = startDate[0];
    }
}
