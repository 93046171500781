import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RhsMessageConsoleListComponent } from './rhs-message-console-list.component';
import { JobMessageListModule } from 'shared/views/global-chat/job-message-console/job-message-list/job-message-list.module';
import { JobMessageDetailsModule } from 'shared/views/global-chat/job-message-console/job-message-details/job-message-details.module';

@NgModule({
  imports: [
    CommonModule,
    JobMessageListModule,
    JobMessageDetailsModule
  ],
  declarations: [RhsMessageConsoleListComponent],
  exports: [RhsMessageConsoleListComponent]
})
export class RhsMessageConsoleListModule { }
