import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxUploaderModule } from 'ngx-uploader';

import { UploadPictureComponent } from './upload-picture.component';
import { ImageCropperModule } from 'ngx-image-cropper';

@NgModule({
    imports: [
        CommonModule,
        NgbModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        NgxUploaderModule,
        ImageCropperModule
    ],
    declarations: [
        UploadPictureComponent
    ],
    exports: [
        UploadPictureComponent,
    ]
})

export class UploadPictureModule {

}
