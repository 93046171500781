/* tslint:disable */

declare var Object: any;
export interface JobTemplateInterface {
  "Name"?: string;
  "sfdcId"?: string;
  "OwnerId"?: string;
  "LastModifiedById"?: string;
  "CurrencyIsoCode"?: string;
  "CreatedById"?: string;
  "ts2__Activities__c"?: string;
  "ts2__Answers_1__c"?: string;
  "ts2__Business_Unit__c"?: string;
  "ts2__Certificates_Licenses__c"?: string;
  "ts2__Correct_Answer_1__c"?: string;
  "ts2__Description__c"?: string;
  "ts2__Desc_Tags__c"?: string;
  "ts2__Education_and_Experience__c"?: string;
  "ts2__EEO_Code__c"?: string;
  "ts2__Essential_Duties__c"?: string;
  "ts2__Interests__c"?: string;
  "ts2__Job_Template_Mapping__c"?: string;
  "ts2__Knowledge__c"?: string;
  "ts2__Language_Skills__c"?: string;
  "ts2__Legacy_JobTemplateID__c"?: string;
  "ts2__Payroll_Job_Code__c"?: string;
  "ts2__Prepared__c"?: string;
  "ts2__Prepared_Date__c"?: Date;
  "ts2__Qualifications__c"?: string;
  "ts2__Question_1__c"?: string;
  "ts2__Question_2__c"?: string;
  "ts2__Question_3__c"?: string;
  "ts2__Rule_Out_Questions__c"?: string;
  "ts2__Salary_Low__c"?: number;
  "ts2__Skills__c"?: string;
  "ts2__SOC_code__c"?: string;
  "ts2__Supv_Responsibili__c"?: string;
  "ts2__Travel_Requirement__c"?: string;
  "ts2__Work_Context__c"?: string;
  "ts2__Work_Style__c"?: string;
  "ts2__Work_Values__c"?: string;
  "ts2__Abilities__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class JobTemplate implements JobTemplateInterface {
  "Name": string;
  "sfdcId": string;
  "OwnerId": string;
  "LastModifiedById": string;
  "CurrencyIsoCode": string;
  "CreatedById": string;
  "ts2__Activities__c": string;
  "ts2__Answers_1__c": string;
  "ts2__Business_Unit__c": string;
  "ts2__Certificates_Licenses__c": string;
  "ts2__Correct_Answer_1__c": string;
  "ts2__Description__c": string;
  "ts2__Desc_Tags__c": string;
  "ts2__Education_and_Experience__c": string;
  "ts2__EEO_Code__c": string;
  "ts2__Essential_Duties__c": string;
  "ts2__Interests__c": string;
  "ts2__Job_Template_Mapping__c": string;
  "ts2__Knowledge__c": string;
  "ts2__Language_Skills__c": string;
  "ts2__Legacy_JobTemplateID__c": string;
  "ts2__Payroll_Job_Code__c": string;
  "ts2__Prepared__c": string;
  "ts2__Prepared_Date__c": Date;
  "ts2__Qualifications__c": string;
  "ts2__Question_1__c": string;
  "ts2__Question_2__c": string;
  "ts2__Question_3__c": string;
  "ts2__Rule_Out_Questions__c": string;
  "ts2__Salary_Low__c": number;
  "ts2__Skills__c": string;
  "ts2__SOC_code__c": string;
  "ts2__Supv_Responsibili__c": string;
  "ts2__Travel_Requirement__c": string;
  "ts2__Work_Context__c": string;
  "ts2__Work_Style__c": string;
  "ts2__Work_Values__c": string;
  "ts2__Abilities__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: JobTemplateInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `JobTemplate`.
   */
  public static getModelName() {
    return "JobTemplate";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of JobTemplate for dynamic purposes.
  **/
  public static factory(data: JobTemplateInterface): JobTemplate{
    return new JobTemplate(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'JobTemplate',
      plural: 'JobTemplates',
      path: 'JobTemplates',
      idName: 'id',
      properties: {
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "ts2__Activities__c": {
          name: 'ts2__Activities__c',
          type: 'string'
        },
        "ts2__Answers_1__c": {
          name: 'ts2__Answers_1__c',
          type: 'string'
        },
        "ts2__Business_Unit__c": {
          name: 'ts2__Business_Unit__c',
          type: 'string'
        },
        "ts2__Certificates_Licenses__c": {
          name: 'ts2__Certificates_Licenses__c',
          type: 'string'
        },
        "ts2__Correct_Answer_1__c": {
          name: 'ts2__Correct_Answer_1__c',
          type: 'string'
        },
        "ts2__Description__c": {
          name: 'ts2__Description__c',
          type: 'string'
        },
        "ts2__Desc_Tags__c": {
          name: 'ts2__Desc_Tags__c',
          type: 'string'
        },
        "ts2__Education_and_Experience__c": {
          name: 'ts2__Education_and_Experience__c',
          type: 'string'
        },
        "ts2__EEO_Code__c": {
          name: 'ts2__EEO_Code__c',
          type: 'string'
        },
        "ts2__Essential_Duties__c": {
          name: 'ts2__Essential_Duties__c',
          type: 'string'
        },
        "ts2__Interests__c": {
          name: 'ts2__Interests__c',
          type: 'string'
        },
        "ts2__Job_Template_Mapping__c": {
          name: 'ts2__Job_Template_Mapping__c',
          type: 'string'
        },
        "ts2__Knowledge__c": {
          name: 'ts2__Knowledge__c',
          type: 'string'
        },
        "ts2__Language_Skills__c": {
          name: 'ts2__Language_Skills__c',
          type: 'string'
        },
        "ts2__Legacy_JobTemplateID__c": {
          name: 'ts2__Legacy_JobTemplateID__c',
          type: 'string'
        },
        "ts2__Payroll_Job_Code__c": {
          name: 'ts2__Payroll_Job_Code__c',
          type: 'string'
        },
        "ts2__Prepared__c": {
          name: 'ts2__Prepared__c',
          type: 'string'
        },
        "ts2__Prepared_Date__c": {
          name: 'ts2__Prepared_Date__c',
          type: 'Date'
        },
        "ts2__Qualifications__c": {
          name: 'ts2__Qualifications__c',
          type: 'string'
        },
        "ts2__Question_1__c": {
          name: 'ts2__Question_1__c',
          type: 'string'
        },
        "ts2__Question_2__c": {
          name: 'ts2__Question_2__c',
          type: 'string'
        },
        "ts2__Question_3__c": {
          name: 'ts2__Question_3__c',
          type: 'string'
        },
        "ts2__Rule_Out_Questions__c": {
          name: 'ts2__Rule_Out_Questions__c',
          type: 'string'
        },
        "ts2__Salary_Low__c": {
          name: 'ts2__Salary_Low__c',
          type: 'number'
        },
        "ts2__Skills__c": {
          name: 'ts2__Skills__c',
          type: 'string'
        },
        "ts2__SOC_code__c": {
          name: 'ts2__SOC_code__c',
          type: 'string'
        },
        "ts2__Supv_Responsibili__c": {
          name: 'ts2__Supv_Responsibili__c',
          type: 'string'
        },
        "ts2__Travel_Requirement__c": {
          name: 'ts2__Travel_Requirement__c',
          type: 'string'
        },
        "ts2__Work_Context__c": {
          name: 'ts2__Work_Context__c',
          type: 'string'
        },
        "ts2__Work_Style__c": {
          name: 'ts2__Work_Style__c',
          type: 'string'
        },
        "ts2__Work_Values__c": {
          name: 'ts2__Work_Values__c',
          type: 'string'
        },
        "ts2__Abilities__c": {
          name: 'ts2__Abilities__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
