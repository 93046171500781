import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { templateCategory } from '../../../models/static-list-data.service';

@Component({
  selector: 'app-template-category-lookup',
  templateUrl: './template-category-lookup.component.html',
  styleUrls: ['./template-category-lookup.component.css']
})
export class TemplateCategoryLookupComponent implements OnInit {
  templateCategoryList = [];
  templateCategoryTypeSelected = {};
  isMultipleSelected = true;
  maxSelectedItems = 1;

  @Input() set resetData(isClear) {
    if (isClear) {
      this.templateCategoryTypeSelected = {};
    }
  }
  @Output() getTemplateCategory: EventEmitter<any> = new EventEmitter;

  constructor() { }

  ngOnInit() {
    this.templateCategoryList = templateCategory.filter((category) =>
      (category.value !== '' && category.value !== undefined && category.value !== null));
    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
    this.templateCategoryTypeSelected = (preselected && preselected['templateCategories']) ? preselected['templateCategories'] : '';
  }

  onTemplateCategoryChange() {
    this.getTemplateCategory.emit(this.templateCategoryTypeSelected);
  }

  clearTemplateCategory() {
    this.templateCategoryTypeSelected = {};
    this.getTemplateCategory.emit([]);
  }

}
