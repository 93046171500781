import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { isTestUser } from '../../../models/static-list-data.service'

@Component({
  selector: 'app-is-TestUser-lookup',
  templateUrl: './is-TestUser-lookup.component.html',
  styleUrls: ['./is-TestUser-lookup.component.css']
})
export class IsTestUserLookupComponent implements OnInit {

  isTestUserList = [];
  isTestUserSelected = [];
  isMultipleSelected = true;

  @Input() set resetData(isClear) {
    if (isClear) {
      this.isTestUserSelected = [];
    }
  }
  @Input() maxSelectedItems = 1;
  @Input() selectedDefaultUser;
  @Input() from;

  @Output() selectedisTestUser: EventEmitter<any> = new EventEmitter;


  constructor() { }

  ngOnInit() {
    this.isTestUserList = isTestUser;
    if (this.from && this.from === 'analyticsDashboard') {
      if (this.selectedDefaultUser && this.selectedDefaultUser.length) {
        this.isTestUserSelected = this.selectedDefaultUser;
      } else {
        this.isTestUserSelected = [];
      }
    } else {
      const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : ''
      this.isTestUserSelected = (preselected &&
        preselected['isTestUser']) ? preselected['isTestUser'] : ['false'];
    }
  }

  onisTestUserChange() {
    this.selectedisTestUser.emit(this.isTestUserSelected);
  }

  clearisTestUser() {
    this.isTestUserSelected = [];
    this.selectedisTestUser.emit([]);
  }


}
