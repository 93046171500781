import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublicCaqComponent } from './public-caq.component';
import { PrescreenQuestionsModule } from 'shared/components/prescreen-questions/prescreen-questions.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
import { AlertModule } from '../alert/alert.module';
import { PreloaderModule } from '../preloader/preloader.module';
@NgModule({
    imports: [
        NgbModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PrescreenQuestionsModule,
        AlertModule,
        PreloaderModule
    ],
    declarations: [PublicCaqComponent],
    exports: [PublicCaqComponent]
})
export class PublicCaqModule { }
