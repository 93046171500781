import { Injectable } from "@angular/core";
import { Adapter } from "./adapter";

export class Member {
    constructor(
        public id: string = "",
        public name: string = "",
        public profile_picture: string = "",
        public sfdcId: string = "",
        public isActive: boolean = false
    ){}
}

@Injectable({
    providedIn: "root"
})

export class MemberAdaptor implements Adapter<Member> {
    adapt(item: any): Member {
        //console.log('member >>> ', item);
        let member = new Member(
            (item.id ? item.id : ''),
            (item.firstName ? (item.firstName + ' ' + item.lastName) : ''),
            (item.profileImage ? item.profileImage : ''),
            (item.sfdcId ? item.sfdcId : ''),
            (item.isActive ? item.isActive: false)
        );
        return member;
    }
    reverseAdapt(item: Member): any {
        return
    }
}