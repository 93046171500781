import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { communityTypeValues } from 'shared/models/static-list-data.service';

@Component({
  selector: 'app-community-type-lookup',
  templateUrl: './community-type-lookup.component.html',
  styleUrls: ['./community-type-lookup.component.css']
})
export class CommunityTypeLookupComponent implements OnInit {

  @Input() set resetData(isClear) {
    if (isClear) {
      this.communityTypeSelected = [];
    }
  }
  @Output() selectedCommunityType: EventEmitter<any> = new EventEmitter;

  communityTypeList = [];
  communityTypeSelected = [];
  isMultipleSelected = true;
  
  constructor() { }

  ngOnInit() {
    this.communityTypeList = communityTypeValues;
    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
    this.communityTypeSelected = (preselected &&
      preselected['communityType']) ? preselected['communityType'] : '';
  }

  onCommunityTypeChange() {
    this.selectedCommunityType.emit(this.communityTypeSelected);
  }
  
  clearCommunityType() {
    this.communityTypeSelected = [];
    this.selectedCommunityType.emit([]);
  }

}
