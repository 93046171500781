import { Component, OnInit, Input } from "@angular/core";
import { CommonService } from "shared/services/common.service";
import * as moment from "moment";
import { MapService } from "shared/services/map.service";

@Component({
  selector: "app-customer-visit-plan",
  templateUrl: "./customer-visit-plan.component.html",
  styleUrls: ["./customer-visit-plan.component.scss"],
  providers: [MapService]
})
export class CustomerVisitPlanComponent implements OnInit {
  @Input() job;
  open = false;

  constructor() {
  }

  ngOnInit() {
  }
}
