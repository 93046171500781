/* tslint:disable */
import {
  Order,
  Product
} from '../index';

declare var Object: any;
export interface OrderItemInterface {
  "sfdcId"?: string;
  "Additional_Hours_T_M_Price__c"?: number;
  "Additional_Hours_T_M__c"?: number;
  "Additional_Update__c"?: boolean;
  "Admin_Price__c"?: number;
  "AvailableQuantity"?: number;
  "Available_SLAs__c"?: string;
  "CreatedById"?: string;
  "CreatedDate"?: Date;
  "CurrencyIsoCode"?: string;
  "Customer_Tier__c"?: string;
  "Description"?: string;
  "Discount__c"?: number;
  "FTE_Hours__c"?: number;
  "FTE_Monthly_Rate__c"?: number;
  "FTE_Overtime_Holiday__c"?: number;
  "FTE_Overtime_Standard__c"?: number;
  "FTE_Utilization_factor__c"?: string;
  "Final_Total_Before_Discount_Tax__c"?: number;
  "Grand_Total_for_Roll_up__c"?: number;
  "IsDeleted"?: boolean;
  "LastModifiedById"?: string;
  "LastModifiedDate"?: Date;
  "ListPrice"?: number;
  "OrderId"?: string;
  "OrderItemNumber"?: string;
  "OriginalOrderItemId"?: string;
  "PPE_Hours__c"?: string;
  "PPE_Unit_Price_FSE_Auto__c"?: number;
  "Partner_Pricelist__c"?: string;
  "PricebookEntryId"?: string;
  "Product2Id"?: string;
  "Quantity"?: number;
  "Recordid__c"?: string;
  "Refresh__c"?: boolean;
  "SKU__c"?: string;
  "SLA__c"?: string;
  "Service_Contract_Record_Type__c"?: string;
  "Sub_Total_Auto__c"?: number;
  "Tier_Discount_Calc__c"?: number;
  "Tier_Discount__c"?: string;
  "TotalPrice"?: number;
  "Total_Approved_Timecard_Hours_Order__c"?: number;
  "Total_Approved_Timecards_Amount_Auto__c"?: number;
  "Total_Cost_Customer_Pre_Tax_Auto_FTE__c"?: number;
  "Total_Price_Auto__c"?: number;
  "UnitPrice"?: number;
  "VAT_Excluded__c"?: boolean;
  "VAT_GST_Country__c"?: string;
  "VAT_GST_For_Roll_up__c"?: number;
  "VAT_GST__c"?: number;
  "VAT_Tax__c"?: number;
  "Weekend_Hours_Unit_Price__c"?: number;
  "id"?: number;
  order?: Order;
  product?: Product;
}

export class OrderItem implements OrderItemInterface {
  "sfdcId": string;
  "Additional_Hours_T_M_Price__c": number;
  "Additional_Hours_T_M__c": number;
  "Additional_Update__c": boolean;
  "Admin_Price__c": number;
  "AvailableQuantity": number;
  "Available_SLAs__c": string;
  "CreatedById": string;
  "CreatedDate": Date;
  "CurrencyIsoCode": string;
  "Customer_Tier__c": string;
  "Description": string;
  "Discount__c": number;
  "FTE_Hours__c": number;
  "FTE_Monthly_Rate__c": number;
  "FTE_Overtime_Holiday__c": number;
  "FTE_Overtime_Standard__c": number;
  "FTE_Utilization_factor__c": string;
  "Final_Total_Before_Discount_Tax__c": number;
  "Grand_Total_for_Roll_up__c": number;
  "IsDeleted": boolean;
  "LastModifiedById": string;
  "LastModifiedDate": Date;
  "ListPrice": number;
  "OrderId": string;
  "OrderItemNumber": string;
  "OriginalOrderItemId": string;
  "PPE_Hours__c": string;
  "PPE_Unit_Price_FSE_Auto__c": number;
  "Partner_Pricelist__c": string;
  "PricebookEntryId": string;
  "Product2Id": string;
  "Quantity": number;
  "Recordid__c": string;
  "Refresh__c": boolean;
  "SKU__c": string;
  "SLA__c": string;
  "Service_Contract_Record_Type__c": string;
  "Sub_Total_Auto__c": number;
  "Tier_Discount_Calc__c": number;
  "Tier_Discount__c": string;
  "TotalPrice": number;
  "Total_Approved_Timecard_Hours_Order__c": number;
  "Total_Approved_Timecards_Amount_Auto__c": number;
  "Total_Cost_Customer_Pre_Tax_Auto_FTE__c": number;
  "Total_Price_Auto__c": number;
  "UnitPrice": number;
  "VAT_Excluded__c": boolean;
  "VAT_GST_Country__c": string;
  "VAT_GST_For_Roll_up__c": number;
  "VAT_GST__c": number;
  "VAT_Tax__c": number;
  "Weekend_Hours_Unit_Price__c": number;
  "id": number;
  order: Order;
  product: Product;
  constructor(data?: OrderItemInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `OrderItem`.
   */
  public static getModelName() {
    return "OrderItem";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of OrderItem for dynamic purposes.
  **/
  public static factory(data: OrderItemInterface): OrderItem{
    return new OrderItem(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'OrderItem',
      plural: 'OrderItems',
      path: 'OrderItems',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "Additional_Hours_T_M_Price__c": {
          name: 'Additional_Hours_T_M_Price__c',
          type: 'number'
        },
        "Additional_Hours_T_M__c": {
          name: 'Additional_Hours_T_M__c',
          type: 'number'
        },
        "Additional_Update__c": {
          name: 'Additional_Update__c',
          type: 'boolean'
        },
        "Admin_Price__c": {
          name: 'Admin_Price__c',
          type: 'number'
        },
        "AvailableQuantity": {
          name: 'AvailableQuantity',
          type: 'number'
        },
        "Available_SLAs__c": {
          name: 'Available_SLAs__c',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "Customer_Tier__c": {
          name: 'Customer_Tier__c',
          type: 'string'
        },
        "Description": {
          name: 'Description',
          type: 'string'
        },
        "Discount__c": {
          name: 'Discount__c',
          type: 'number'
        },
        "FTE_Hours__c": {
          name: 'FTE_Hours__c',
          type: 'number'
        },
        "FTE_Monthly_Rate__c": {
          name: 'FTE_Monthly_Rate__c',
          type: 'number'
        },
        "FTE_Overtime_Holiday__c": {
          name: 'FTE_Overtime_Holiday__c',
          type: 'number'
        },
        "FTE_Overtime_Standard__c": {
          name: 'FTE_Overtime_Standard__c',
          type: 'number'
        },
        "FTE_Utilization_factor__c": {
          name: 'FTE_Utilization_factor__c',
          type: 'string'
        },
        "Final_Total_Before_Discount_Tax__c": {
          name: 'Final_Total_Before_Discount_Tax__c',
          type: 'number'
        },
        "Grand_Total_for_Roll_up__c": {
          name: 'Grand_Total_for_Roll_up__c',
          type: 'number'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "ListPrice": {
          name: 'ListPrice',
          type: 'number'
        },
        "OrderId": {
          name: 'OrderId',
          type: 'string'
        },
        "OrderItemNumber": {
          name: 'OrderItemNumber',
          type: 'string'
        },
        "OriginalOrderItemId": {
          name: 'OriginalOrderItemId',
          type: 'string'
        },
        "PPE_Hours__c": {
          name: 'PPE_Hours__c',
          type: 'string'
        },
        "PPE_Unit_Price_FSE_Auto__c": {
          name: 'PPE_Unit_Price_FSE_Auto__c',
          type: 'number'
        },
        "Partner_Pricelist__c": {
          name: 'Partner_Pricelist__c',
          type: 'string'
        },
        "PricebookEntryId": {
          name: 'PricebookEntryId',
          type: 'string'
        },
        "Product2Id": {
          name: 'Product2Id',
          type: 'string'
        },
        "Quantity": {
          name: 'Quantity',
          type: 'number'
        },
        "Recordid__c": {
          name: 'Recordid__c',
          type: 'string'
        },
        "Refresh__c": {
          name: 'Refresh__c',
          type: 'boolean'
        },
        "SKU__c": {
          name: 'SKU__c',
          type: 'string'
        },
        "SLA__c": {
          name: 'SLA__c',
          type: 'string'
        },
        "Service_Contract_Record_Type__c": {
          name: 'Service_Contract_Record_Type__c',
          type: 'string'
        },
        "Sub_Total_Auto__c": {
          name: 'Sub_Total_Auto__c',
          type: 'number'
        },
        "Tier_Discount_Calc__c": {
          name: 'Tier_Discount_Calc__c',
          type: 'number'
        },
        "Tier_Discount__c": {
          name: 'Tier_Discount__c',
          type: 'string'
        },
        "TotalPrice": {
          name: 'TotalPrice',
          type: 'number'
        },
        "Total_Approved_Timecard_Hours_Order__c": {
          name: 'Total_Approved_Timecard_Hours_Order__c',
          type: 'number'
        },
        "Total_Approved_Timecards_Amount_Auto__c": {
          name: 'Total_Approved_Timecards_Amount_Auto__c',
          type: 'number'
        },
        "Total_Cost_Customer_Pre_Tax_Auto_FTE__c": {
          name: 'Total_Cost_Customer_Pre_Tax_Auto_FTE__c',
          type: 'number'
        },
        "Total_Price_Auto__c": {
          name: 'Total_Price_Auto__c',
          type: 'number'
        },
        "UnitPrice": {
          name: 'UnitPrice',
          type: 'number'
        },
        "VAT_Excluded__c": {
          name: 'VAT_Excluded__c',
          type: 'boolean'
        },
        "VAT_GST_Country__c": {
          name: 'VAT_GST_Country__c',
          type: 'string'
        },
        "VAT_GST_For_Roll_up__c": {
          name: 'VAT_GST_For_Roll_up__c',
          type: 'number'
        },
        "VAT_GST__c": {
          name: 'VAT_GST__c',
          type: 'number'
        },
        "VAT_Tax__c": {
          name: 'VAT_Tax__c',
          type: 'number'
        },
        "Weekend_Hours_Unit_Price__c": {
          name: 'Weekend_Hours_Unit_Price__c',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        order: {
          name: 'order',
          type: 'Order',
          model: 'Order',
          relationType: 'belongsTo',
                  keyFrom: 'OrderId',
          keyTo: 'sfdcId'
        },
        product: {
          name: 'product',
          type: 'Product',
          model: 'Product',
          relationType: 'belongsTo',
                  keyFrom: 'Product2Id',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
