/* tslint:disable */

declare var Object: any;
export interface CsqdleadTalenttypeMappingInterface {
  "csqdleadTalentType"?: string;
  "fseOrFte"?: string;
  "talentType"?: string;
  "quoteManagerProperty"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class CsqdleadTalenttypeMapping implements CsqdleadTalenttypeMappingInterface {
  "csqdleadTalentType": string;
  "fseOrFte": string;
  "talentType": string;
  "quoteManagerProperty": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: CsqdleadTalenttypeMappingInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `CsqdleadTalenttypeMapping`.
   */
  public static getModelName() {
    return "CsqdleadTalenttypeMapping";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of CsqdleadTalenttypeMapping for dynamic purposes.
  **/
  public static factory(data: CsqdleadTalenttypeMappingInterface): CsqdleadTalenttypeMapping{
    return new CsqdleadTalenttypeMapping(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'CsqdleadTalenttypeMapping',
      plural: 'CsqdleadTalenttypeMappings',
      path: 'CsqdleadTalenttypeMappings',
      idName: 'id',
      properties: {
        "csqdleadTalentType": {
          name: 'csqdleadTalentType',
          type: 'string'
        },
        "fseOrFte": {
          name: 'fseOrFte',
          type: 'string'
        },
        "talentType": {
          name: 'talentType',
          type: 'string'
        },
        "quoteManagerProperty": {
          name: 'quoteManagerProperty',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
