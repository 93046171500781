/* tslint:disable */

declare var Object: any;
export interface JobOrderJobBoardPostingInterface {
  "sfdcId"?: string;
  "jobBoardName"?: string;
  "recruiterId"?: string;
  "jobOrderId"?: string;
  "status"?: string;
  "isDeleted"?: boolean;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class JobOrderJobBoardPosting implements JobOrderJobBoardPostingInterface {
  "sfdcId": string;
  "jobBoardName": string;
  "recruiterId": string;
  "jobOrderId": string;
  "status": string;
  "isDeleted": boolean;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: JobOrderJobBoardPostingInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `JobOrderJobBoardPosting`.
   */
  public static getModelName() {
    return "JobOrderJobBoardPosting";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of JobOrderJobBoardPosting for dynamic purposes.
  **/
  public static factory(data: JobOrderJobBoardPostingInterface): JobOrderJobBoardPosting{
    return new JobOrderJobBoardPosting(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'JobOrderJobBoardPosting',
      plural: 'JobOrderJobBoardPostings',
      path: 'JobOrderJobBoardPostings',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "jobBoardName": {
          name: 'jobBoardName',
          type: 'string'
        },
        "recruiterId": {
          name: 'recruiterId',
          type: 'string'
        },
        "jobOrderId": {
          name: 'jobOrderId',
          type: 'string'
        },
        "status": {
          name: 'status',
          type: 'string'
        },
        "isDeleted": {
          name: 'isDeleted',
          type: 'boolean',
          default: false
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
