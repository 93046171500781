import { Component, Input, OnInit } from '@angular/core';
import { PreloaderService } from 'shared/services/preloader.service';
import { JobApi } from 'shared/sdk';
import { environment } from 'environments/environment';
import { CommonService } from 'shared/services/common.service';


@Component({
  selector: 'app-fte-job-schedule',
  templateUrl: './fte-job-schedule.component.html',
  styleUrls: ['./fte-job-schedule.component.css']
})
export class FteJobScheduleComponent implements OnInit {
  @Input() jobId?: string;
  jobData: any;
  isLoaded: Boolean = false;
  imgpath = environment.baseUrl + '/' + environment.apiVersion;

  constructor(
    private _preloaderService: PreloaderService,
    private _jobApi: JobApi,
    private _commonService: CommonService
  ) { }

  ngOnInit() {
    if (this.imgpath.indexOf('localhost') !== -1) {
      this.imgpath = 'https://den.dev.serviceo.me/api/v1';
    }
    this.getJobData()
  }

  getJobData() {
    this._preloaderService.showPreloader();
    const filter = {

      fields: ['sfdcId', 'Name', 'Iron_Job_num__c', 'Job_Type__c', 'Job_Status_Internal__c', 'Jobsite__c', 'Job_Schedule__c',
        'Service_Contract__c', 'Jobsite_Name__c', 'Customer_Window_Start_Date__c', 'Customer_Window_End_Date__c',
        'CKSW_BASE__Status__c', 'Work_Order_Number', 'createdAt', 'id', 'Dispatch_Worker_Name__c'],
      include: [
        {
          relation: 'jobsite',
          scope: {
            fields: ['Street__c', 'Zip__c', 'City__c', 'State__c', 'Zip_Postal_Code__c', 'Time_Zone__c', 'Country__c']
          }
        },
        {
          relation: 'serviceContract',
          scope: {
            fields: ['sfdcId', 'Status', 'ContractNumber', 'Service_Contract_Number__c', 'StartDate', 'EndDate', 'Job_Reference_Auto__c',
              'Primary_Job_Schedule__c', 'Worker__c', 'RecordTypeId', 'Name', 'createdAt', 'id'],
          }
        },
        {
          relation: 'jobSchedule',
          scope: {
            fields: ['sfdcId', 'Name', 'Status__c', 'Break_Duration_Minutes__c', 'Calculation_Frequency_Daily_Weekly__c',
              'Recordtypeid__c', 'Total_Weekly_Hours__c', 'Yearly_Hours__c', 'Schedule__c', 'Days_Week_Schedule__c',
              'Business_Start_Time__c', 'Monday_Business_End_Time__c', 'Monday_Schedule__c', 'Monday_Hours__c',
              'Is_Holiday_Monday__c', 'Tuesday_Business_Start_Time__c', 'Tuesday_Business_End_Time__c', 'Tuesday_Schedule__c',
              'Tuesday_Hours__c', 'Is_Holiday_Tuesday__c', 'Wednesday_Business_Start_Time__c', 'Wednesday_Business_End_Time__c',
              'Wednesday_Schedule__c', 'Wednesday_Hours__c', 'Is_Holiday_Wednesday__c', 'Thursday_Business_Start_Time__c',
              'Thursday_Business_End_Time__c', 'Thursday_Schedule__c', 'Thursday_Hours__c', 'Is_Holiday_Thursday__c',
              'Friday_Business_Start_Time__c', 'Friday_Business_End_Time__c', 'Friday_Schedule__c', 'Friday_Hours__c',
              'Is_Holiday_Friday__c', 'Saturday_Business_Start_Time__c', 'Saturday_Business_End_Time__c', 'Saturday_Hours__c',
              'Is_Holiday_Saturday__c', 'Sunday_Business_Start_Time__c', 'Sunday_Business_End_Time__c', 'Sunday_Hours__c',
              'CreatedDate', 'Auto_Calculate_End_Times__c', 'createdAt', 'updatedAt', 'Work_Order__c', 'RecordTypeId',
              'Is_Holiday_Sunday__c', 'Saturday_Schedule__c', 'Sunday_Schedule__c'],
            include: [
              {
                relation: 'recordType',
                scope: {
                  fields: ['sfdcId', 'Name']
                }
              }
            ]
          }
        },
        {
          relation: 'worker',
          scope: {
            fields: ['sfdcId', 'Name', 'Worker_Type__c', 'Contact__c'],
            include: [
              {
                relation: 'user',
                scope: {
                  fields: ['sfdcId', 'profileImage', 'url', 'email']
                }
              }

            ]
          }
        },
        {
          relation: 'workOrder',
          scope: {
            fields: ['sfdcId', 'Work_Order_Start_Date__c', 'Work_Order_End_Date__c', 'Name']
          }
        },
      ],
      where: { sfdcId: this.jobId, RecordTypeId: '0121a0000006QniAAE' },
      skip: 0,
      limit: 1,
      order: 'createdAt DESC'
    }
    this._jobApi.find(filter).subscribe(
      res => {
        if (res && res.length) {
          const serviceContract = res[0] && res[0]['serviceContract'] ? res[0]['serviceContract'] : {};
          const worker = res[0] && res[0]['worker'] ? res[0]['worker'] : {};
          let data = { jobs: res[0], serviceContract: serviceContract, worker: worker };
          if (res[0] && res[0]['worker']) {
            data['Name'] = worker && worker.Name ? worker.Name : '';
            data['Contact__c'] = worker && worker.Contact__c ? worker.Contact__c : '';
            data['Worker_Type__c'] = worker && worker.Worker_Type__c ? worker.Worker_Type__c : '';
            data['sfdcId'] = worker && worker.sfdcId ? worker.sfdcId : '';
            if (worker && worker.user && worker.user.profileImage && worker.user.url) {
              const imageUrl = `${this.imgpath}/Containers${worker.user.url}${worker.user.profileImage}`;
              this._commonService.imageExists(imageUrl, function (exists: any) {
                data['profileImage'] = exists ? imageUrl : `assets/images/male-circle.png`;
              });
            } else {
              data['profileImage'] =  `assets/images/male-circle.png`;
            }
          }
          this.jobData = data;
        }
        this.isLoaded = true;
        this._preloaderService.hidePreloader();
      },
      err => {
        console.log(err)
        this._preloaderService.hidePreloader();
      })
  }
}
