import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';
import { MilestoneLibraryApi } from 'shared/sdk';
import { PreloaderService } from 'shared/services/preloader.service';

@Component({
  selector: 'app-milestone-library-lookup',
  templateUrl: './milestone-library-lookup.component.html',
  styleUrls: ['./milestone-library-lookup.component.css']
})
export class MilestoneLibraryLookupComponent implements OnInit {

  @Input() set resetData(isClear) {
    if (isClear) {
      this.milestoneSelectedList = [];
      this.milestoneOptionList = [];
    }
  }
  @Input() set isMultipleSelected(e) {
    this._multipleSelect = e;
    this.maxSelectedItems = e ? 20 : 1;
  }
  @Input() placeholder = 'Milestone Template';
  @Input() isdisabled = false;

  @Output() getMilestoneObj: EventEmitter<any> = new EventEmitter;
  @Output() setInitialMilestone: EventEmitter<any> = new EventEmitter;

  searchInput = new Subject<string>();
  librariesLoading = false;
  selectedMilestone: any;
  whereObj = {};

  maxSelectedItems = 20;
  _multipleSelect = true;

  milestoneOptionList: any = [];
  milestoneSelectedList = [];

  constructor(
    private _milestoneLibraryApi: MilestoneLibraryApi,
    private _preloaderService: PreloaderService
  ) { }

  ngOnInit() {
    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
    const milestoneSelectedList = (preselected && preselected['milestoneLibraries']) || [];
    if (milestoneSelectedList && milestoneSelectedList.length) {
      this.milestoneSelectedList = milestoneSelectedList;
      this.onMilestoneChange();
      this._preloaderService.showPreloader();
      this.fetchMilestoneLibraries({ sfdcId: { inq: milestoneSelectedList } }).subscribe(data => {
        if (data && data.length) {
          this.milestoneOptionList = data;
        } else {
          this.milestoneOptionList = [];
        }
        this._preloaderService.hidePreloader();
      }, error => {
        console.error(error);
        this.milestoneOptionList = [];
        this._preloaderService.hidePreloader();
      });
    }
    this.registerAutocomplete();
  }

  registerAutocomplete() {
    this.searchInput
      .pipe(
        filter(search => search && search.trim().length > 3),
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => this.librariesLoading = true),
        switchMap(search =>
          this.fetchMilestoneLibraries(this.prepareWhereClause(search))
        )
      ).subscribe(data => {
        if (data && data.length) {
          this.milestoneOptionList = data;
        } else {
          this.milestoneOptionList = [];
        }
        this.librariesLoading = false;
      }, err => {
        console.error(err);
        this.milestoneOptionList = [];
        this.librariesLoading = false;
      });
  }

  prepareWhereClause(search) {
    if (search) {
      return {
        or: [
          // { Name: { like: '%' + search.trim() + '%', options: 'i' } },
          { Milestone_Name__c: { like: '%' + search.trim() + '%', options: 'i' } }
        ]
      };
    }
    return {};
  }

  fetchMilestoneLibraries(whereObj?) {
    const filterObj = {
      where: whereObj ? whereObj : {},
      fields: ['id', 'sfdcId', 'Name', 'Milestone_Name__c'],
      order: 'createdAt desc',
      limit: 200
    }
    return this._milestoneLibraryApi.find(filterObj);
  }

  onMilestoneChange() {
    this.getMilestoneObj.emit(this.milestoneSelectedList);
  }

  clearMilestone() {
    this.milestoneSelectedList = [];
    this.onMilestoneChange();
  }

}
