import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ProjectConsoleTypes } from 'msp/enums/project-console-types.enum';
import { taskRequestorValues } from '../../../models/static-list-data.service'
@Component({
    selector: 'app-task-requestor-lookup',
    templateUrl: './task-requestor-lookup.component.html',
    styleUrls: ['./task-requestor-lookup.component.css']
})
export class TaskRequestorLookupComponent implements OnInit {

    taskRequestorList = [];
    taskRequestorTypeSelected = [];
    isMultipleSelected = true;
    maxSelectedItems = 1;
    whereObj = {};

    @Input() set resetData(isClear) {
        if (isClear) {
            this.taskRequestorTypeSelected = [];
        }
    }
    @Input() set setProjectConsoleType(e) {
        if (e && e.length && e.includes(ProjectConsoleTypes.helpDesk)) {
            taskRequestorValues.push({ value: 'Requested for', label: 'Requested for' });
        } else {
            taskRequestorValues.filter(taskReq => {
                if (taskReq.value === 'Requested for') {
                    const index = taskRequestorValues.indexOf(taskReq);
                    taskRequestorValues.splice(index, 1);
                }
            })
        }
    }
    @Input() set realtimeTaskConversation(e) {
        // console.log('realtimeTaskConversation >>>> ', e);
        if(e) {
            const index = taskRequestorValues.findIndex(x => x.value === 'Requested by My Team');
            if(index >= 0){
                taskRequestorValues.splice(index, 1);
            }
        } else {
            const index = taskRequestorValues.findIndex(x => x.value === 'Requested by My Team');
            if(index < 0) {
                taskRequestorValues.push({ value: 'Requested by My Team', label: 'Requested by My Team' });
            }
        }
    }
    @Output() getTaskRequestorObj: EventEmitter<any> = new EventEmitter;
    public searchInput = new EventEmitter<string>();

      // setTaskObj will be the object, {'PgMo_Projects_c': [], 'PgMO_Milestones__c': []}
  @Input()
  set setTaskRequestorObj(params) {
    this.taskRequestorList = [];
    // this.clearTaskRequestor();
    this.whereObj = this.whereObj && Object.keys(this.whereObj).length ? this.whereObj : {};
  }
    constructor() { }

    ngOnInit() {
        this.taskRequestorList = taskRequestorValues;
        const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
        this.taskRequestorTypeSelected = (preselected && preselected['taskRequestors']) ? preselected['taskRequestors'] : '';
    }
    onTaskRequestorChange() {
        this.getTaskRequestorObj.emit(this.taskRequestorTypeSelected);
    }
    clearTaskRequestor() {
        this.taskRequestorTypeSelected = [];
        this.getTaskRequestorObj.emit([]);
    }
}
