import { HealthCheckModule } from 'shared/components/health-check/health-check.module';
import { WorkerListApvpComponent } from './worker-list-apvp.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ReadMoreModule } from 'shared/components/read-more/read-more.module';
import { FormsModule } from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        HealthCheckModule,
        NgxDatatableModule,
        ReadMoreModule
        ],
    declarations: [WorkerListApvpComponent],
    exports: [WorkerListApvpComponent]
})
export class WorkerListApvpModule {
}
