import { Component, OnInit, Input, PipeTransform, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { PreloaderService } from 'shared/services/preloader.service';
import { JobStatusInternalValues } from '../../../../../models/static-list-data.service';
import { CommonService } from './../../../../../services/common.service';
import { AppStateService } from '../../../../../services/app-state.service';
import { UtilityService } from '../../../../../services/utility.service';
import { SharedService } from 'shared/services/pms/shared.services';
import { ModalService } from '../../../../../services/modal.service';

import { JobApi } from '../../../../../sdk';

@Component({
    selector: 'job-related-joblist',
    templateUrl: './job-related-joblist.component.html',
    styleUrls: ['./job-related-joblist.component.css']
})
export class JobRelatedJoblistComponent implements OnInit, OnDestroy, PipeTransform {

    @Input() jobObj: any;

    @ViewChild('myTable') table: any;
    job: any;
    pageTitle: string;
    pageSubTitle = '';
    datePipe: DatePipe;
    searchArray: any;
    /*Boot-Datatable params */
    tableData = [];
    dataList: any;
    loadingIndicator = false;
    itemsPerPage = 10;
    isLoadMore = false;
    itemsPerBatch = 200;
    orderBy = 'CreatedDate  DESC';
    errorMessage = 'Loading...';
    filterObj = {};
    sSfilter = {};
    filteredItems: any;
    columns: any;
    allColumns: any;
    JobStatusInternalValues = JobStatusInternalValues;
    filterJobValue = '';
    timeZone: any;
    sizes = [10, 25, 50, 100];
    totalCount: number;
    isPublic = false;
    userId: any;
    userData: any;
    roleSub: Subscription;

    constructor(
        private _route: ActivatedRoute,
        private _appState: AppStateService,
        private _utilityService: UtilityService,
        private _jobApi: JobApi,
        private _sharedservice: SharedService,
        private _modalService: ModalService,
        private _el: ElementRef,
        private _loader: PreloaderService,
        private _commonService: CommonService
    ) { }

    initScroll() {
        const elHeader = this._el.nativeElement.querySelector('.datatable-header')
        const elBody = this._el.nativeElement.querySelector('datatable-body');
        if (elHeader) {
            elHeader.onscroll = () => {
                elBody.scrollLeft = elHeader.scrollLeft
            }
        }
        if (elBody) {
            elBody.onscroll = () => {
                elHeader.scrollLeft = elBody.scrollLeft
            }
        }
    }

    ngOnInit() {
        this._commonService.saveCurrentPageAnalytics('Job Manager Console - Details', 'Related Jobs');
        if (this._route.snapshot.data['accessType'] && this._route.snapshot.data['accessType'] === 'public') {
            this.isPublic = true;
        }

        this.roleSub = this._appState.getJobDetailUserRole().subscribe(role => {
            if (role && role['id']) {
                this.userId = role['id'];
            }
        })
    }

    ngOnChanges() {
        this.initialize();
    }

    initialize() {
        this._commonService.getUserProfile().subscribe(userProfile => {
            if (userProfile) {
                this.userData = userProfile;
                if (this.userData['accessType'] === 'vendor') {
                    this.searchArray['Vendor__c'] = this.userData['AccountId'];
                }
            }
        }, error => {
            console.log(error);
        });

        if (this.jobObj) {
            this.prepareJobs(JSON.parse(this.jobObj));
        } else {
            this.errorMessage = 'No details to display.';
        }
    }

    prepareJobs(jobData) {
        this.searchArray = {};
        if (jobData && jobData.id && jobData.Work_Order_Number) {
            this.searchArray['Work_Order_Number'] = jobData.Work_Order_Number;
            this.searchArray['id'] = { neq: jobData.id }

            this.columns = [
                { prop: 'Iron_Job_num__c', name: 'Job ID', visible: true, width: 250, sortable: true },
                { prop: 'Job_Status_Internal__c', name: 'Status', visible: true, width: 200, sortable: true },
                { prop: 'Dispatch_Service_Resolution_Status__c', name: 'Resolution', visible: true, width: 200, sortable: true },
                { prop: 'programName', name: 'Program', visible: true, width: 200, sortable: true },
                { prop: 'vendorName', name: 'Vendor(s)', visible: true, width: 200, sortable: true },
                { prop: 'Jobsite_Name__c', name: 'Jobsite Location', visible: true, width: 200, sortable: true },
                { prop: 'CKSW_BASE__Country__c', name: 'Country', visible: true, width: 100, sortable: true },
                { prop: 'csum__c', name: 'Summary', visible: true, width: 200, sortable: true },
                { prop: 'Customer_Service_Type__c', name: 'Service Type', visible: true, width: 200, sortable: true },
                { prop: 'Talent_Type__c', name: 'Talent Type', visible: true, width: 200, sortable: true },
                { prop: 'Technical_Level__c', name: 'Service Level', visible: true, width: 120, sortable: true },
                { prop: 'Service_Dispatch_SLA_Priority__c', name: 'Priority', visible: true, width: 100, sortable: true },
                { prop: 'SKU', name: 'Coverage', visible: true, width: 200, sortable: true },
                { prop: 'Customer_Requested', name: 'Customer Requested', visible: true, width: 200, sortable: true, type: 'date', format: 'short' },
                { prop: 'Cust_Scheduled__c', name: 'Customer Scheduled', visible: true, width: 200, sortable: true, type: 'date', format: 'short' },
                { prop: 'Worker_Scheduled__c', name: 'Worker Scheduled', visible: true, width: 200, sortable: true, type: 'date', format: 'short' },
                { prop: 'Worker_Arrival__c', name: 'Worker Arrival', visible: true, width: 200, sortable: true, type: 'date', format: 'short' },
                { prop: 'CreatedDate', name: 'Date Posted', visible: true, width: 200, sortable: true, type: 'date', format: 'short' },
                { prop: 'updatedAt', name: 'Last updated', visible: true, width: 200, sortable: true, type: 'date', format: 'short' }
            ];

            if (this.userData['accessType'] === 'vendor') {
                this.columns.splice((this.columns.findIndex(x => x.prop === 'vendorName')), 1);
            }
            this.allColumns = this.columns.slice(); // Used for Columns Toggling
            this.getJobListings(this.searchArray, 0, true);
        }
    }

    ngOnDestroy() {
        if (this.roleSub) {
            this.roleSub.unsubscribe();
        }
    }

    setEmptyMessage() {
        const msg = 'No data to display.';
        if (!this.filteredItems.length) {
            this.filteredItems = [{
                'message': msg
            }];
            this.filteredItems[0][this.columns[0]['prop']] = msg;
        } else {
            if (this.filteredItems[0].hasOwnProperty('message')) {
                this.filteredItems.shift();
            }
        }
    }

    refreshView() {
        this.isLoadMore = false;
        if (this.table) {
            this.table['offset'] = 0
        }
        this.tableData = [];
        let inputs;
        let selects;
        inputs = document.getElementsByTagName('input');
        selects = document.getElementsByTagName('select');
        for (let index = 0; index < inputs.length; ++index) {
            inputs[index].value = '';
        }
        for (let index = 0; index < selects.length; ++index) {
            selects[index].value = '';
        }
        this.filterObj = {};
        this.getJobListings(this.searchArray, 0, true);
    }

    toggle(col) {
        col.visible = !col.visible;
    }

    getJobListings(searchArg: any, offset: number, newState: boolean) {
        this.loadingIndicator = true;
        if (newState) {
            this.dataList = [];
            this.tableData = [];
        }

        let mode = 'all';
        if (this.isPublic && this.userId) {
            mode = 'public&userId=' + this.userId;
        }
        // to fetch data initially and after search
        if (mode) {
            this._jobApi.getList(mode, {
                where: searchArg,
                limit: this.itemsPerBatch,
                order: this.orderBy,
                skip: offset
            }).subscribe(
                results => {
                    this.isLoadMore = (results.length === this.itemsPerBatch) ? true : false;
                    results = this.prepareJobData(results);
                    this.dataList = this.dataList.concat(results);
                    this.tableData = [...this.dataList];

                    // this.filteredItems = this.dataList.slice();
                    if (Object.keys(this.filterObj).length > 0 && this.filterObj.constructor === Object) {
                        this.loadingIndicator = false;
                        this.filteredItems = this.tableData.filter(item => {
                            const notMatchingField = Object.keys(this.filterObj).find(key =>
                                this._utilityService.dataTableSearch(item, this.filterObj, key));
                            return !notMatchingField;
                        });
                    } else {
                        this.filteredItems = this.tableData;
                    }
                    if (this.table) {
                        this.table.count = this.filteredItems.length;
                    }
                    if (this.tableData.length) {
                        const _that = this;
                        setTimeout(function () { _that.initScroll(); }, 1000);
                    }
                    this.setEmptyMessage();
                    this.loadingIndicator = false;
                    this._loader.hidePreloader();
                },
                error => {
                    this.loadingIndicator = false;
                    this._loader.hidePreloader();
                }
            )
        }
    }


    /*Data Table funcation start here*/
    loadMoreRecords() {
        this.isLoadMore = true;
        this._loader.showPreloader();
        if (this.dataList.length <= this.itemsPerBatch) {
            this.getJobListings(this.searchArray, this.itemsPerBatch, false);
        } else {
            this.getJobListings(this.searchArray, this.dataList.length, false);
        }
    }

    prepareJobData(data) {
        const jobData = [];
        if (data) {
            let obj;
            data.forEach((item, index) => {
                obj = {};
                obj = {
                    'sfdcId': item.sfdcId ? item.sfdcId : '',
                    'RecordTypeId': item.RecordTypeId ? item.RecordTypeId : '',
                    'Iron_Job_num__c': item.Iron_Job_num__c ? (item.Iron_Job_num__c + ' ' + item.Name) : '',
                    'Job_Status_Internal__c': item.Job_Status_Internal__c ? item.Job_Status_Internal__c : '',
                    'Dispatch_Service_Resolution_Status__c': item.Dispatch_Service_Resolution_Status__c ?
                        item.Dispatch_Service_Resolution_Status__c : '',
                    'programName': item.program ? item.program.Name ? item.program.Name : '' : '',
                    'vendorName': item.vendor ? item.vendor.Name ? item.vendor.Name : '' : '',
                    'Jobsite_Name__c': item.Jobsite_Name__c ? item.Jobsite_Name__c : '',
                    'CKSW_BASE__Country__c': item.CKSW_BASE__Country__c ? item.CKSW_BASE__Country__c : '',
                    'Customer_Service_Type__c': item.Customer_Service_Type__c ? item.Customer_Service_Type__c : '',
                    'Talent_Type__c': item.Talent_Type__c ? item.Talent_Type__c : '',
                    'Service_Dispatch_SLA_Priority__c': item.Service_Dispatch_SLA_Priority__c ? item.Service_Dispatch_SLA_Priority__c : '',
                    'SKU': item.product ? item.product.Name ? item.product.Name : '' : '',
                    'csum__c': item.csum__c ? item.csum__c : '',
                    'Technical_Level__c': (item.Talent_Type__c && item.Technical_Level__c) ?
                        (item.Talent_Type__c + '-' + item.Technical_Level__c) :
                        (item.Talent_Type__c ? item.Talent_Type__c :
                            (item.Technical_Level__c ? item.Technical_Level__c : '')),
                    'Cust_Scheduled__c': item.appointment ? item.appointment.Customer_Appointment_DateTime_Scheduled__c ?
                        this.transform(item.appointment.Customer_Appointment_DateTime_Scheduled__c) : '' : '',
                    'Worker_Scheduled__c': item.appointment ? item.appointment.Worker_Arrival_DateTime_Scheduled__c ?
                        this.transform(item.appointment.Worker_Arrival_DateTime_Scheduled__c) : '' : '',
                    'Worker_Arrival__c': item.appointment ? item.appointment.Worker_Arrival_Date_Time_Actual__c ?
                        this.transform(item.appointment.Worker_Arrival_Date_Time_Actual__c) : '' : '',
                    'CreatedDate': item.CreatedDate ? this.transform(item.CreatedDate) : '',
                    'updatedAt': item.updatedAt ? this.transform(item.updatedAt) : '',
                    'Customer_Requested': item.Customer_Requested ? this.transform(item.Customer_Requested) : ''
                };
                if (item.appointment && item.appointment.Customer_Appointment_Start_Scheduled__c) {
                    const date = new Date(item.appointment.Customer_Appointment_Start_Scheduled__c);
                    date.setHours(item.appointment.Customer_Appointment_Start_HRS_Scheduled__c)
                    date.setMinutes(item.appointment.Customer_Apptmnt_Start_Minutes_Scheduled__c)
                    obj['Customer_Requested'] = this.transform(date.toISOString())
                } else {
                    obj['Customer_Requested'] = null;
                }
                jobData.push(obj);
            });
        }

        return jobData;
    }

    transform(value: string, type?) {
        let dateformate: string;
        type ? dateformate = type : dateformate = 'short';
        this.datePipe = new DatePipe('en-US');
        const formateddate = (value ? this.datePipe.transform(value, dateformate) : value);
        return formateddate;
    }


    filterData(event, type) {
        if (type === 'date') {
            if (event.value === '') {
                if (this.filterObj[event.input.id + '_temp']) {
                    delete this.filterObj[event.input.id];
                }
            } else {
                const date = this.transform(event.value).split(',');
                this.filterObj[event.input.id] = date[0];
            }
            this.filteredItems = this.tableData.filter(item => {
                const notMatchingField = Object.keys(this.filterObj).find(key =>
                    this._utilityService.dataTableSearch(item, this.filterObj, key));
                return !notMatchingField;
            });

        } else {
            if (event.target.value === '') {
                delete this.filterObj[event.currentTarget.id];
            } else {
                this.filterObj[event.currentTarget.id] = event.target.value;
            }
            this.filteredItems = this.tableData.filter(item => {
                const notMatchingField = Object.keys(this.filterObj).find(key =>
                    this._utilityService.dataTableSearch(item, this.filterObj, key));
                return !notMatchingField;
            });
        }
        this.setEmptyMessage();
        this.table.offset = 0;

    }

    sSfilterData() {
        const filter = {};
        const dateProp = this.columns.filter(x => x.type === 'date' ? x.prop : '');
        for (const key in this.sSfilter) {
            if (dateProp.findIndex(x => x.prop === key) === -1) {
                const searchPattern = { like: '%' + this.sSfilter[key].toLowerCase() + '%', options: 'i' };
                filter[key] = searchPattern
            } else {
                let dateFrom: any;
                let dateTo: any;
                if (this.sSfilter[key] && this.sSfilter[key].length > 0) {
                    dateFrom = this.sSfilter[key][0]
                        ? this.transform(this.sSfilter[key][0], 'MM-dd-yyyy') + ' 00:00:00'
                        : '';
                    dateTo = this.sSfilter[key][1]
                        ? this.transform(this.sSfilter[key][1], 'MM-dd-yyyy') + ' 23:59:59'
                        : '';
                    dateFrom = new Date(dateFrom);
                    dateTo = new Date(dateTo);
                }
                const searchPattern = { between: [dateFrom, dateTo] }
                filter[key] = searchPattern
            }
        }
        this.table.offset = 0;
        this.getJobListings(filter, 0, true);
        this._modalService.closed();
    }

    exportCSV() {
        this._sharedservice.exportNgxData(this.filteredItems, this.columns, 'JobList');
    }

    clearSearch(col) {
        this.filterObj[col] = ''
        delete this.filterObj[col];
        this.filteredItems = this.tableData.filter(item => {
            const notMatchingField = Object.keys(this.filterObj).find(key =>
                this._utilityService.dataTableSearch(item, this.filterObj, key));
            return !notMatchingField;
        });
        this.setEmptyMessage();
        this.table.offset = 0;
    }
    clearsSfilter(col) {
        delete this.sSfilter[col];
    }
}
