import { NgModule } from '@angular/core';
import { NgxResizeWatcherDirective } from '../directives/ngx-datatable/ngx-resize-watcher.directive';
import { NgxDoubleScrollDirective } from '../directives/ngx-datatable/ngx-double-scroll.directive';
import { ReadMoreDirective } from '../directives/showMore/read-more.directive';
import { DisableControlDirective } from './disable-controle/disable-control.directive';
import { NumberLimitDirective } from './number-limit/number-limit.directive';
import { TwoDigitDecimaNumberDirective } from './two-decimal-number/two-digit-decimal-number.directive';

@NgModule({
    declarations: [
        NgxResizeWatcherDirective,
        NgxDoubleScrollDirective,
        ReadMoreDirective,
        DisableControlDirective,
        NumberLimitDirective,
        TwoDigitDecimaNumberDirective
    ],
    exports: [
        NgxResizeWatcherDirective,
        NgxDoubleScrollDirective,
        ReadMoreDirective,
        DisableControlDirective,
        NumberLimitDirective,
        TwoDigitDecimaNumberDirective
    ]
})

export class DirectivesModule { }
