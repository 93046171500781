/* tslint:disable */
import {
  Users,
  Vendorsite,
  Worker
} from '../index';

declare var Object: any;
export interface WorkerVendorsiteInterface {
  "Name"?: string;
  "CreatedById"?: string;
  "CurrencyIsoCode"?: string;
  "sfdcId"?: string;
  "LastModifiedById"?: string;
  "Notes__c"?: string;
  "Primary_Vendorsite__c"?: boolean;
  "Vendorsite__c"?: string;
  "Vendorsite_Name__c"?: string;
  "Worker__c"?: string;
  "Worker_Name__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  createdBy?: Users;
  vendorsite?: Vendorsite;
  worker?: Worker;
}

export class WorkerVendorsite implements WorkerVendorsiteInterface {
  "Name": string;
  "CreatedById": string;
  "CurrencyIsoCode": string;
  "sfdcId": string;
  "LastModifiedById": string;
  "Notes__c": string;
  "Primary_Vendorsite__c": boolean;
  "Vendorsite__c": string;
  "Vendorsite_Name__c": string;
  "Worker__c": string;
  "Worker_Name__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  createdBy: Users;
  vendorsite: Vendorsite;
  worker: Worker;
  constructor(data?: WorkerVendorsiteInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `WorkerVendorsite`.
   */
  public static getModelName() {
    return "WorkerVendorsite";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of WorkerVendorsite for dynamic purposes.
  **/
  public static factory(data: WorkerVendorsiteInterface): WorkerVendorsite{
    return new WorkerVendorsite(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'WorkerVendorsite',
      plural: 'WorkerVendorsites',
      path: 'WorkerVendorsites',
      idName: 'id',
      properties: {
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "Notes__c": {
          name: 'Notes__c',
          type: 'string'
        },
        "Primary_Vendorsite__c": {
          name: 'Primary_Vendorsite__c',
          type: 'boolean'
        },
        "Vendorsite__c": {
          name: 'Vendorsite__c',
          type: 'string'
        },
        "Vendorsite_Name__c": {
          name: 'Vendorsite_Name__c',
          type: 'string'
        },
        "Worker__c": {
          name: 'Worker__c',
          type: 'string'
        },
        "Worker_Name__c": {
          name: 'Worker_Name__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        createdBy: {
          name: 'createdBy',
          type: 'Users',
          model: 'Users',
          relationType: 'belongsTo',
                  keyFrom: 'CreatedById',
          keyTo: 'sfdcId'
        },
        vendorsite: {
          name: 'vendorsite',
          type: 'Vendorsite',
          model: 'Vendorsite',
          relationType: 'belongsTo',
                  keyFrom: 'Vendorsite__c',
          keyTo: 'sfdcId'
        },
        worker: {
          name: 'worker',
          type: 'Worker',
          model: 'Worker',
          relationType: 'belongsTo',
                  keyFrom: 'Worker__c',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
