import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkerTypeSubLookupComponent} from './worker-type-sub-lookup.component';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule
  ],
  declarations: [WorkerTypeSubLookupComponent],
  exports: [WorkerTypeSubLookupComponent]
})
export class WorkerTypeSubLookupModule { }
