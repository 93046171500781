import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { pcUserCountList } from '../../../models/static-list-data.service'
@Component({
  selector: 'app-pc-user-count-lookup',
  templateUrl: './pc-user-count-lookup.component.html',
  styleUrls: ['./pc-user-count-lookup.component.css']
})
export class PcUserCountLookupComponent implements OnInit {

  pcUserCountList = [];
  pcUserCountelected = [];
  isMultipleSelected = true;
  @Input() set resetData(isClear) {
    if (isClear) {
      this.pcUserCountelected = [];
    }
  }
  @Input() maxSelectedItems = 1;
  @Input() from;
  @Input() selectedDefaultValue;

  @Output() selectedpcUserCount: EventEmitter<any> = new EventEmitter;


  constructor() { }

  ngOnInit() {
    this.pcUserCountList = [...pcUserCountList];
    
    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : ''

    this.pcUserCountelected = (preselected && preselected['pcUserCount']) ? preselected['pcUserCount'] : ['1'];
  }

  onpcUserCountChange() {
    this.selectedpcUserCount.emit(this.pcUserCountelected);
  }

  clearpcUserCount() {
    this.pcUserCountelected = [];
    this.selectedpcUserCount.emit([]);
  }

}
