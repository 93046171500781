import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StaticListLookupComponent } from './static-list-lookup.component';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule
    
  ],
  declarations: [StaticListLookupComponent],
  exports: [StaticListLookupComponent]
})
export class StaticListLookupModule { }
