import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FTEServiceContractCategoryFilterList } from 'shared/models/static-list-data.service';
import { RecordTypeApi } from 'shared/sdk';

@Component({
    selector: 'app-contract-category-lookup',
    templateUrl: './contract-category-lookup.component.html',
    styleUrls: ['./contract-category-lookup.component.css']
})
export class ContractCategoryLookupComponent implements OnInit {

    @Input() page: string;
    @Input() set resetData(isClear) {
        this.clearFromParent();
    }
    @Output() selectedContractCategory: EventEmitter<any> = new EventEmitter;
    selectedValue;
    contractCategory: any = [];

    constructor(
        private _recordType: RecordTypeApi
    ) { }

    ngOnInit() {
        this.loadData();
        const preSelected = localStorage.getItem('filterObj');
        if (preSelected && JSON.parse(preSelected) &&
            JSON.parse(preSelected)['contractCategory'] && JSON.parse(preSelected)['contractCategory'].length) {
            this.selectedValue = JSON.parse(preSelected)['contractCategory'];
        }
    }

    loadData() {
        const req = {
            fields: ['sfdcId', 'Name'],
            where: {
                'SobjectType': 'ServiceContract'
            }
        }
        this._recordType.find(req).subscribe(data => {
            if (data && data.length) {
                this.contractCategory = data;
            }
        }, error => {
            console.log(error.message);
        })
    }

    onChange() {
        this.selectedContractCategory.emit(this.selectedValue);
    }

    clearFromParent() {
        this.selectedValue = null;
    }

}
