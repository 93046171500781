import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobStatusComponent } from './job-status.component';
import { JobSummaryModule } from '../job-summary/job-summary.module';
import { DocumentModule } from '../document/document.module';
import { PipeModule } from 'shared/pipe/pipe.module';
import { FilesListModule } from '../files-list/files-list.module';
import { JobStatusUpdateTrackerModule } from '../job-progress-update-tracker/job-progress-update-tracker.module';
import { CustomerVisitPlanModule } from '../customer-visit-plan/customer-visit-plan.module';
import { WorkflowButtonsModule } from 'shared/components/workflow-buttons/workflow-buttons.module'
import { JobsiteRouteMapModule } from '../jobsite-routemap/jobsite-routemap.module';

@NgModule({
    imports: [
        CommonModule,
        JobSummaryModule,
        DocumentModule,
        PipeModule,
        FilesListModule,
        JobStatusUpdateTrackerModule,
        CustomerVisitPlanModule,
        WorkflowButtonsModule,
        JobsiteRouteMapModule
    ],
    declarations: [JobStatusComponent],
    exports: [JobStatusComponent],
})
export class JobStatusModule { }
