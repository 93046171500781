import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { debounceTime, distinctUntilChanged, switchMap, filter, tap, catchError } from 'rxjs/operators';
import { CaseApi } from 'shared/sdk';
import { CommonService } from 'shared/services/common.service';

@Component({
  selector: 'app-pms-case-num-lookup',
  templateUrl: './pms-case-num-lookup.component.html',
  styleUrls: ['./pms-case-num-lookup.component.scss']
})
export class PmsCaseNumLookupComponent implements OnInit {
  @Input() set resetData(isClear) {
    // this.pmsCaseIds = [];
    this.pmsCaseObj = [];
  }
  @Input() placeholder;
  @Output() getPMSCaseIdsObj: EventEmitter<any> = new EventEmitter();

  pmsCaseObj: any
  pmsCaseIds: any

  public searchInput = new EventEmitter<string>();
  itemsPerBatch = 50;
  notFoundText: string;
  orderBy = 'id DESC';

  constructor(private _caseApi: CaseApi,
    private _commonService: CommonService) {
  }

  ngOnInit() {
    this.placeholder = this.placeholder === undefined ? 'Filter by PMS Case Number' : this.placeholder;
    this.notFoundText = 'PMS Case Number Not Found';
    this.getPMSCaseIds();
    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
    this.pmsCaseObj = (preselected && preselected['pmsCaseNumbers']) ? preselected['pmsCaseNumbers'] : '';
    if (this.pmsCaseObj && this.pmsCaseObj.length) {
      const filters = {
        where: { sfdcId: { inq: this.pmsCaseObj } },
        fields: ['sfdcId', 'Partner_Case_Number__c', 'ParentId', 'CaseNumber', 'Iron_Case_Number__c']
      }
      this._caseApi.find(filters).subscribe(
        x => {
          this.pmsCaseIds = x;
          this.notFoundText = (this.pmsCaseIds.length === 0) ? 'PMS Case Number found.' : '';
        });
    }
  }

  getPMSCaseIds() {
    this.searchInput
      .pipe(
        filter(search => search && search.length > 2),
        debounceTime(200),
        distinctUntilChanged(),
        switchMap(search => this.loadPMSCaseIds(search))
      ).subscribe(
        x => {
          this.pmsCaseIds = x;
          this.notFoundText = (this.pmsCaseIds.length === 0) ? 'PMS Case Number not found.' : '';
        },
        err => {
          console.log(err);
          this.pmsCaseIds = [];
        }

      );
  }
  loadPMSCaseIds(search) {
    if (this._commonService.pmsRecordType['all']) {
      const filters = {
        where: {
          RecordTypeId: { inq: this._commonService.pmsRecordType['all'] },
          CaseNumber: { like: '%' + search.trim() + '%', options: 'i' }
        },
        fields: ['sfdcId', 'Partner_Case_Number__c', 'ParentId', 'CaseNumber', 'Iron_Case_Number__c']
      }
      return this._caseApi.find(filters);
    } else {
      return [];
    }

  }
  clearPMSCaseIds() {
    this.pmsCaseObj = [];
    this.notFoundText = 'Type to search PMS Case Number';
    this.getPMSCaseIdsObj.emit([]);
  }

  onPMSCaseIdChange() {
    this.getPMSCaseIdsObj.emit(this.pmsCaseObj);
  }

}
