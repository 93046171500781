/* tslint:disable */

declare var Object: any;
export interface ApiKeyInterface {
  "apiName"?: string;
  "webApiKey"?: string;
  "mobApiKey"?: string;
  "webAccountName"?: string;
  "mobAccountName"?: string;
  "webStyleKey"?: string;
  "mobStyleKey"?: string;
  "isActive"?: boolean;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class ApiKey implements ApiKeyInterface {
  "apiName": string;
  "webApiKey": string;
  "mobApiKey": string;
  "webAccountName": string;
  "mobAccountName": string;
  "webStyleKey": string;
  "mobStyleKey": string;
  "isActive": boolean;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: ApiKeyInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ApiKey`.
   */
  public static getModelName() {
    return "ApiKey";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ApiKey for dynamic purposes.
  **/
  public static factory(data: ApiKeyInterface): ApiKey{
    return new ApiKey(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ApiKey',
      plural: 'ApiKeys',
      path: 'ApiKeys',
      idName: 'id',
      properties: {
        "apiName": {
          name: 'apiName',
          type: 'string'
        },
        "webApiKey": {
          name: 'webApiKey',
          type: 'string'
        },
        "mobApiKey": {
          name: 'mobApiKey',
          type: 'string'
        },
        "webAccountName": {
          name: 'webAccountName',
          type: 'string'
        },
        "mobAccountName": {
          name: 'mobAccountName',
          type: 'string'
        },
        "webStyleKey": {
          name: 'webStyleKey',
          type: 'string'
        },
        "mobStyleKey": {
          name: 'mobStyleKey',
          type: 'string'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
