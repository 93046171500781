/* tslint:disable */
import { Injectable } from '@angular/core';
import { Account } from '../../models/Account';
import { Job } from '../../models/Job';
import { JobInstruction } from '../../models/JobInstruction';
import { JobComment } from '../../models/JobComment';
import { Timecard } from '../../models/Timecard';
import { Users } from '../../models/Users';
import { Payment } from '../../models/Payment';
import { Invoice } from '../../models/Invoice';
import { PurchaseOrder } from '../../models/PurchaseOrder';
import { PurchaseOrderItem } from '../../models/PurchaseOrderItem';
import { Pricelist } from '../../models/Pricelist';
import { PricelistItem } from '../../models/PricelistItem';
import { Jobsite } from '../../models/Jobsite';
import { Document } from '../../models/Document';
import { DocumentShare } from '../../models/DocumentShare';
import { Timezone } from '../../models/Timezone';
import { UserType } from '../../models/UserType';
import { Country } from '../../models/Country';
import { Deliverable } from '../../models/Deliverable';
import { Vendorsite } from '../../models/Vendorsite';
import { VendorsiteContact } from '../../models/VendorsiteContact';
import { Appointment } from '../../models/Appointment';
import { Worker } from '../../models/Worker';
import { Container } from '../../models/Container';
import { DocumentCategory } from '../../models/DocumentCategory';
import { DocumentTitle } from '../../models/DocumentTitle';
import { Skill } from '../../models/Skill';
import { UserSkill } from '../../models/UserSkill';
import { Project } from '../../models/Project';
import { Case } from '../../models/Case';
import { JobsiteProjects } from '../../models/JobsiteProjects';
import { Product } from '../../models/Product';
import { ApprovedProjectVendorPool } from '../../models/ApprovedProjectVendorPool';
import { Contact } from '../../models/Contact';
import { CountryCode } from '../../models/CountryCode';
import { JobsiteContact } from '../../models/JobsiteContact';
import { GeoMetro } from '../../models/GeoMetro';
import { TalentType } from '../../models/TalentType';
import { WorkOrder } from '../../models/WorkOrder';
import { QuoteLineManager } from '../../models/QuoteLineManager';
import { QuoteManager } from '../../models/QuoteManager';
import { MetroVirtualVendorPool } from '../../models/MetroVirtualVendorPool';
import { JobOrderItem } from '../../models/JobOrderItem';
import { ServiceCategorySetting } from '../../models/ServiceCategorySetting';
import { RecordType } from '../../models/RecordType';
import { CaseComment } from '../../models/CaseComment';
import { Workflow } from '../../models/Workflow';
import { WorkflowStage } from '../../models/WorkflowStage';
import { WorkflowStatus } from '../../models/WorkflowStatus';
import { WorkflowTransition } from '../../models/WorkflowTransition';
import { Order } from '../../models/Order';
import { FilterService } from '../../models/FilterService';
import { Dashboard } from '../../models/Dashboard';
import { RequestFormReceipt } from '../../models/RequestFormReceipt';
import { RequestFormReceiptLine } from '../../models/RequestFormReceiptLine';
import { IronCustomField } from '../../models/IronCustomField';
import { Asset } from '../../models/Asset';
import { Skilling } from '../../models/Skilling';
import { CsqdCaseComment } from '../../models/CsqdCaseComment';
import { AssetGroup } from '../../models/AssetGroup';
import { Contract } from '../../models/Contract';
import { Attachment } from '../../models/Attachment';
import { PmsIccPmcRecordTypeMapping } from '../../models/PmsIccPmcRecordTypeMapping';
import { GeoMessage } from '../../models/GeoMessage';
import { SupportedAssetModelNumbers } from '../../models/SupportedAssetModelNumbers';
import { FSLFRUTransactionList } from '../../models/FSLFRUTransactionList';
import { MapSettings } from '../../models/MapSettings';
import { NeighboringCountries } from '../../models/NeighboringCountries';
import { NeighboringCountriesHeader } from '../../models/NeighboringCountriesHeader';
import { GeoSessionLog } from '../../models/GeoSessionLog';
import { CsqdActivity } from '../../models/CsqdActivity';
import { ServiceActivityMapping } from '../../models/ServiceActivityMapping';
import { GoogleService } from '../../models/GoogleService';
import { WorkflowStageLog } from '../../models/WorkflowStageLog';
import { VatMaster } from '../../models/VatMaster';
import { Alert } from '../../models/Alert';
import { UserAlert } from '../../models/UserAlert';
import { Activity } from '../../models/Activity';
import { ProjectWorker } from '../../models/ProjectWorker';
import { Comment } from '../../models/Comment';
import { GlobalTalentPool } from '../../models/GlobalTalentPool';
import { Department } from '../../models/Department';
import { EmailService } from '../../models/EmailService';
import { OrderItem } from '../../models/OrderItem';
import { ContactType } from '../../models/ContactType';
import { AccessControl } from '../../models/AccessControl';
import { Assignment } from '../../models/Assignment';
import { DepartmentRole } from '../../models/DepartmentRole';
import { Group } from '../../models/Group';
import { MemberRole } from '../../models/MemberRole';
import { Milestone } from '../../models/Milestone';
import { ProgramGroup } from '../../models/ProgramGroup';
import { Program } from '../../models/Program';
import { Project2 } from '../../models/Project2';
import { Task } from '../../models/Task';
import { WorkerShift } from '../../models/WorkerShift';
import { Channel } from '../../models/Channel';
import { ConversationFile } from '../../models/ConversationFile';
import { ConversationParticipant } from '../../models/ConversationParticipant';
import { ConversationRelation } from '../../models/ConversationRelation';
import { ConversationTracker } from '../../models/ConversationTracker';
import { Conversation } from '../../models/Conversation';
import { FileCategory } from '../../models/FileCategory';
import { Files } from '../../models/Files';
import { Favorite } from '../../models/Favorite';
import { LibraryDetail } from '../../models/LibraryDetail';
import { LibraryHeader } from '../../models/LibraryHeader';
import { Library } from '../../models/Library';
import { TaskAttribute } from '../../models/TaskAttribute';
import { TaskGroupAttribute } from '../../models/TaskGroupAttribute';
import { TaskGroup } from '../../models/TaskGroup';
import { EdiTransaction } from '../../models/EdiTransaction';
import { BitlyService } from '../../models/BitlyService';
import { SMSService } from '../../models/SMSService';
import { TalentPoolWorkers } from '../../models/TalentPoolWorkers';
import { ReturnOrder } from '../../models/ReturnOrder';
import { ReturnOrderLineItem } from '../../models/ReturnOrderLineItem';
import { Location } from '../../models/Location';
import { ProductRequest } from '../../models/ProductRequest';
import { ProductRequestLineItem } from '../../models/ProductRequestLineItem';
import { ProductTransfer } from '../../models/ProductTransfer';
import { ServiceContract } from '../../models/ServiceContract';
import { ServiceContractLineItem } from '../../models/ServiceContractLineItem';
import { JobApplication } from '../../models/JobApplication';
import { UserDevice } from '../../models/UserDevice';
import { State } from '../../models/State';
import { JobData } from '../../models/JobData';
import { Feedback } from '../../models/Feedback';
import { AppVersion } from '../../models/AppVersion';
import { TalentInformation } from '../../models/TalentInformation';
import { TalentProfile } from '../../models/TalentProfile';
import { IronAttachment } from '../../models/IronAttachment';
import { TeamShift } from '../../models/TeamShift';
import { RequestorDepartment } from '../../models/RequestorDepartment';
import { RequestFormReceiptEscalation } from '../../models/RequestFormReceiptEscalation';
import { VoiceService } from '../../models/VoiceService';
import { JobWorkerTracking } from '../../models/JobWorkerTracking';
import { ApiKey } from '../../models/ApiKey';
import { TimeClockManager } from '../../models/TimeClockManager';
import { ExpenseReport } from '../../models/ExpenseReport';
import { PublicAccess } from '../../models/PublicAccess';
import { ExpenseCode } from '../../models/ExpenseCode';
import { MonthlyInvoice } from '../../models/MonthlyInvoice';
import { VendorPPEHours } from '../../models/VendorPPEHours';
import { ApvpDistributionListWorkers } from '../../models/ApvpDistributionListWorkers';
import { ApvpWorkersVms } from '../../models/ApvpWorkersVms';
import { WorkerVendorsite } from '../../models/WorkerVendorsite';
import { VCSQDLead } from '../../models/VCSQDLead';
import { SkillingVerify } from '../../models/SkillingVerify';
import { CSQDSessionData } from '../../models/CSQDSessionData';
import { TimeBreak } from '../../models/TimeBreak';
import { SkillingBackgroundCheck } from '../../models/SkillingBackgroundCheck';
import { JobSchedule } from '../../models/JobSchedule';
import { Note } from '../../models/Note';
import { TimeChangeRequest } from '../../models/TimeChangeRequest';
import { InvoiceAdjustment } from '../../models/InvoiceAdjustment';
import { MonthlyPayment } from '../../models/MonthlyPayment';
import { MonthlyInvoiceItem } from '../../models/MonthlyInvoiceItem';
import { CsqdleadTalenttypeMapping } from '../../models/CsqdleadTalenttypeMapping';
import { AdjustmentReasonCode } from '../../models/AdjustmentReasonCode';
import { MilestoneLibrary } from '../../models/MilestoneLibrary';
import { TaskLibrary } from '../../models/TaskLibrary';
import { RequestFormReceiptTemplate } from '../../models/RequestFormReceiptTemplate';
import { TemplateHeader } from '../../models/TemplateHeader';
import { MilestoneTaskLibraryMap } from '../../models/MilestoneTaskLibraryMap';
import { TemplateDetail } from '../../models/TemplateDetail';
import { Address } from '../../models/Address';
import { ServiceTerritory } from '../../models/ServiceTerritory';
import { JobSearchAlert } from '../../models/JobSearchAlert';
import { Answers } from '../../models/Answers';
import { Certification } from '../../models/Certification';
import { EducationHistory } from '../../models/EducationHistory';
import { EmploymentHistory } from '../../models/EmploymentHistory';
import { EngagementActivity } from '../../models/EngagementActivity';
import { EngagementLink } from '../../models/EngagementLink';
import { EngagementProgram } from '../../models/EngagementProgram';
import { EngagementStage } from '../../models/EngagementStage';
import { InterviewFeedback } from '../../models/InterviewFeedback';
import { JobBoardSetup } from '../../models/JobBoardSetup';
import { JobInterviews } from '../../models/JobInterviews';
import { JobLocation } from '../../models/JobLocation';
import { JobOffers } from '../../models/JobOffers';
import { JobOpportunityMapping } from '../../models/JobOpportunityMapping';
import { JobOpportunity } from '../../models/JobOpportunity';
import { JobOrderJobBoardAssociation } from '../../models/JobOrderJobBoardAssociation';
import { JobOrder } from '../../models/JobOrder';
import { JobPlacements } from '../../models/JobPlacements';
import { JobPrescreen } from '../../models/JobPrescreen';
import { JobSkills } from '../../models/JobSkills';
import { JobSubmittals } from '../../models/JobSubmittals';
import { JobTemplate } from '../../models/JobTemplate';
import { Qualification } from '../../models/Qualification';
import { QuestionSet } from '../../models/QuestionSet';
import { Questions } from '../../models/Questions';
import { Reference } from '../../models/Reference';
import { Referral } from '../../models/Referral';
import { StandardQuestion } from '../../models/StandardQuestion';
import { StateManager } from '../../models/StateManager';
import { UserAnalytics } from '../../models/UserAnalytics';
import { ReleaseNotes } from '../../models/ReleaseNotes';
import { PageViewMapping } from '../../models/PageViewMapping';
import { Source } from '../../models/Source';
import { DocumentRole } from '../../models/DocumentRole';
import { ConversationDeleteTracker } from '../../models/ConversationDeleteTracker';
import { ProblemTaskReasonCode } from '../../models/ProblemTaskReasonCode';
import { ChangeAssignedToGroup } from '../../models/ChangeAssignedToGroup';
import { ChangeAssignedToVendorWorker } from '../../models/ChangeAssignedToVendorWorker';
import { ProjectGeo } from '../../models/ProjectGeo';
import { ServiceoProblemReasonCodes } from '../../models/ServiceoProblemReasonCodes';
import { Taxonomies } from '../../models/Taxonomies';
import { TaxonomySkills } from '../../models/TaxonomySkills';
import { AnswerSequenceItem } from '../../models/AnswerSequenceItem';
import { AnswerSequence } from '../../models/AnswerSequence';
import { LearningAssignment } from '../../models/LearningAssignment';
import { LearningCategory } from '../../models/LearningCategory';
import { LearningVersion } from '../../models/LearningVersion';
import { Learning } from '../../models/Learning';
import { LearningQuestion } from '../../models/LearningQuestion';
import { Quiz } from '../../models/Quiz';
import { Response } from '../../models/Response';
import { TrainingPlanCategory } from '../../models/TrainingPlanCategory';
import { TrainingPlanSectionItem } from '../../models/TrainingPlanSectionItem';
import { TrainingPlanSection } from '../../models/TrainingPlanSection';
import { TrainingPlan } from '../../models/TrainingPlan';
import { TrainingResource } from '../../models/TrainingResource';
import { QuestionLine } from '../../models/QuestionLine';
import { TrainingPlanAssignment } from '../../models/TrainingPlanAssignment';
import { TrainingPlanAccount } from '../../models/TrainingPlanAccount';
import { TrainingPlanFeedback } from '../../models/TrainingPlanFeedback';
import { Achievement } from '../../models/Achievement';
import { TrainingPlanAchievement } from '../../models/TrainingPlanAchievement';
import { TrainingPlanSectionAssignment } from '../../models/TrainingPlanSectionAssignment';
import { JobWorkerRating } from '../../models/JobWorkerRating';
import { SiteVisitReport } from '../../models/SiteVisitReport';
import { VendorRating } from '../../models/VendorRating';
import { CustomerCsat } from '../../models/CustomerCsat';
import { LocationJobRecruitment } from '../../models/LocationJobRecruitment';
import { MaintenanceSchedule } from '../../models/MaintenanceSchedule';
import { PayslipManager } from '../../models/PayslipManager';
import { PayslipItem } from '../../models/PayslipItem';
import { Resource } from '../../models/Resource';
import { ServiceType } from '../../models/ServiceType';
import { CustomPricebookEntry } from '../../models/CustomPricebookEntry';
import { SkillingInsurance } from '../../models/SkillingInsurance';
import { MyWorkLeaveApprovalSetting } from '../../models/MyWorkLeaveApprovalSetting';
import { MyWorkLeaveApproval } from '../../models/MyWorkLeaveApproval';
import { MyWorkLeaveEntitlementSetting } from '../../models/MyWorkLeaveEntitlementSetting';
import { MyWorkLeaveRequest } from '../../models/MyWorkLeaveRequest';
import { MyWorkLeaveTypeSetting } from '../../models/MyWorkLeaveTypeSetting';
import { MyWorkLeaveType2ApprovalSetting } from '../../models/MyWorkLeaveType2ApprovalSetting';
import { MyWorkLeaveWorkerBalance } from '../../models/MyWorkLeaveWorkerBalance';
import { MyWorkplaceTemplateScheduler } from '../../models/MyWorkplaceTemplateScheduler';
import { SkillingLicenses } from '../../models/SkillingLicenses';
import { Passcode } from '../../models/Passcode';
import { CorrectiveActionRequest } from '../../models/CorrectiveActionRequest';
import { CorrectiveActionRequestLine } from '../../models/CorrectiveActionRequestLine';
import { AssociatedTask } from '../../models/AssociatedTask';
import { ExportLog } from '../../models/ExportLog';
import { PageViewGroup } from '../../models/PageViewGroup';
import { PageViewTitle } from '../../models/PageViewTitle';
import { Period } from '../../models/Period';
import { SkillingCertification } from '../../models/SkillingCertification';
import { NotesAttachment } from '../../models/NotesAttachment';
import { JobAssessment } from '../../models/JobAssessment';
import { ApplicationAssessment } from '../../models/ApplicationAssessment';
import { CheckInCalendar } from '../../models/CheckInCalendar';
import { SharedLoginLog } from '../../models/SharedLoginLog';
import { Sprint } from '../../models/Sprint';
import { SprintMaster } from '../../models/SprintMaster';
import { Meeting } from '../../models/Meeting';
import { MeetingAttendees} from '../../models/MeetingAttendees';
import { MeetingParticipant } from '../../models/MeetingParticipant';
import { MeetingMinutes } from '../../models/MeetingMinutes';
import { MeetingsKR } from '../../models/MeetingsKR';
import { WSCRequestHeader } from '../../models/WSCRequestHeader';
import { WSCRequestDetail } from '../../models/WSCRequestDetail';
import { ProgramHolidayApprovedCalendar } from '../../models/ProgramHolidayApprovedCalendar';
import { ApplicantPreview } from '../../models/ApplicantPreview';
import { ApplicantResumeParse } from '../../models/ApplicantResumeParse';
import { JobOrderJobBoardPosting } from '../../models/JobOrderJobBoardPosting';
import { BusinessUnit } from '../../models/BusinessUnit';
import { RequestFormJobsite } from '../../models/RequestFormJobsite';
import { PrePostTaskCase } from '../../models/PrePostTaskCase';
import { ApplicantFavourite } from 'shared/sdk/models/ApplicantFavourite';
import { ExpenseLineItem } from 'shared/sdk/models/ExpenseLineItem';
import { SOWDetail } from '../../models/SOWDetail';
import { JobPosting } from '../../models/JobPosting';
import { JobAlertEmailTracking } from 'shared/sdk/models/JobAlertEmailTracking';
import { CompOffRequest } from '../../models/CompOffRequest';
import { CompoffAutoExpireSetting } from '../../models/CompoffAutoExpireSetting';

export interface Models { [name: string]: any }

@Injectable()
export class SDKModels {

  private models: Models = {
    Account: Account,
    Job: Job,
    JobInstruction: JobInstruction,
    JobComment: JobComment,
    Timecard: Timecard,
    Users: Users,
    Payment: Payment,
    Invoice: Invoice,
    PurchaseOrder: PurchaseOrder,
    PurchaseOrderItem: PurchaseOrderItem,
    Pricelist: Pricelist,
    PricelistItem: PricelistItem,
    Jobsite: Jobsite,
    Document: Document,
    DocumentShare: DocumentShare,
    Timezone: Timezone,
    UserType: UserType,
    Country: Country,
    Deliverable: Deliverable,
    Vendorsite: Vendorsite,
    VendorsiteContact: VendorsiteContact,
    Appointment: Appointment,
    Worker: Worker,
    Container: Container,
    DocumentCategory: DocumentCategory,
    DocumentTitle: DocumentTitle,
    Skill: Skill,
    UserSkill: UserSkill,
    Project: Project,
    Case: Case,
    JobsiteProjects: JobsiteProjects,
    Product: Product,
    ApprovedProjectVendorPool: ApprovedProjectVendorPool,
    Contact: Contact,
    CountryCode: CountryCode,
    GeoMetro: GeoMetro,
    JobsiteContact: JobsiteContact,
    TalentType: TalentType,
    WorkOrder: WorkOrder,
    QuoteLineManager: QuoteLineManager,
    QuoteManager: QuoteManager,
    MetroVirtualVendorPool: MetroVirtualVendorPool,
    JobOrderItem: JobOrderItem,
    ServiceCategorySetting: ServiceCategorySetting,
    RecordType: RecordType,
    CaseComment: CaseComment,
    Workflow: Workflow,
    WorkflowStage: WorkflowStage,
    WorkflowStatus: WorkflowStatus,
    WorkflowTransition: WorkflowTransition,
    Order: Order,
    FilterService: FilterService,
    Dashboard: Dashboard,
    RequestFormReceipt: RequestFormReceipt,
    RequestFormReceiptLine: RequestFormReceiptLine,
    IronCustomField: IronCustomField,
    Asset: Asset,
    Skilling: Skilling,
    CsqdCaseComment: CsqdCaseComment,
    AssetGroup: AssetGroup,
    Contract: Contract,
    Attachment: Attachment,
    PmsIccPmcRecordTypeMapping: PmsIccPmcRecordTypeMapping,
    GeoMessage: GeoMessage,
    SupportedAssetModelNumbers: SupportedAssetModelNumbers,
    FSLFRUTransactionList: FSLFRUTransactionList,
    MapSettings: MapSettings,
    NeighboringCountries: NeighboringCountries,
    NeighboringCountriesHeader: NeighboringCountriesHeader,
    GeoSessionLog: GeoSessionLog,
    CsqdActivity: CsqdActivity,
    ServiceActivityMapping: ServiceActivityMapping,
    GoogleService: GoogleService,
    WorkflowStageLog: WorkflowStageLog,
    VatMaster: VatMaster,
    Alert: Alert,
    UserAlert: UserAlert,
    Activity: Activity,
    ProjectWorker: ProjectWorker,
    Comment: Comment,
    GlobalTalentPool: GlobalTalentPool,
    Department: Department,
    EmailService: EmailService,
    OrderItem: OrderItem,
    ContactType: ContactType,
    AccessControl: AccessControl,
    Assignment: Assignment,
    DepartmentRole: DepartmentRole,
    Group: Group,
    MemberRole: MemberRole,
    Milestone: Milestone,
    ProgramGroup: ProgramGroup,
    Program: Program,
    Project2: Project2,
    Task: Task,
    WorkerShift: WorkerShift,
    Channel: Channel,
    ConversationFile: ConversationFile,
    ConversationParticipant: ConversationParticipant,
    ConversationRelation: ConversationRelation,
    ConversationTracker: ConversationTracker,
    Conversation: Conversation,
    FileCategory: FileCategory,
    Files: Files,
    Favorite: Favorite,
    LibraryDetail: LibraryDetail,
    LibraryHeader: LibraryHeader,
    Library: Library,
    TaskAttribute: TaskAttribute,
    TaskGroupAttribute: TaskGroupAttribute,
    TaskGroup: TaskGroup,
    EdiTransaction: EdiTransaction,
    BitlyService: BitlyService,
    SMSService: SMSService,
    TalentPoolWorkers: TalentPoolWorkers,
    ReturnOrder: ReturnOrder,
    ReturnOrderLineItem: ReturnOrderLineItem,
    Location: Location,
    ProductRequest: ProductRequest,
    ProductRequestLineItem: ProductRequestLineItem,
    ProductTransfer: ProductTransfer,
    ServiceContract: ServiceContract,
    ServiceContractLineItem: ServiceContractLineItem,
    JobApplication: JobApplication,
    UserDevice: UserDevice,
    State: State,
    JobData: JobData,
    Feedback: Feedback,
    AppVersion: AppVersion,
    TalentInformation: TalentInformation,
    TalentProfile: TalentProfile,
    IronAttachment: IronAttachment,
    TeamShift: TeamShift,
    RequestorDepartment: RequestorDepartment,
    RequestFormReceiptEscalation: RequestFormReceiptEscalation,
    VoiceService: VoiceService,
    JobWorkerTracking: JobWorkerTracking,
    ApiKey: ApiKey,
    TimeClockManager: TimeClockManager,
    ExpenseReport: ExpenseReport,
    PublicAccess: PublicAccess,
    ExpenseCode: ExpenseCode,
    MonthlyInvoice: MonthlyInvoice,
    VendorPPEHours: VendorPPEHours,
    ApvpDistributionListWorkers: ApvpDistributionListWorkers,
    ApvpWorkersVms: ApvpWorkersVms,
    WorkerVendorsite: WorkerVendorsite,
    VCSQDLead: VCSQDLead,
    SkillingVerify: SkillingVerify,
    CSQDSessionData: CSQDSessionData,
    TimeBreak: TimeBreak,
    SkillingBackgroundCheck: SkillingBackgroundCheck,
    JobSchedule: JobSchedule,
    Note: Note,
    TimeChangeRequest: TimeChangeRequest,
    InvoiceAdjustment: InvoiceAdjustment,
    MonthlyPayment: MonthlyPayment,
    MonthlyInvoiceItem: MonthlyInvoiceItem,
    CsqdleadTalenttypeMapping: CsqdleadTalenttypeMapping,
    AdjustmentReasonCode: AdjustmentReasonCode,
    MilestoneLibrary: MilestoneLibrary,
    TaskLibrary: TaskLibrary,
    RequestFormReceiptTemplate: RequestFormReceiptTemplate,
    TemplateHeader: TemplateHeader,
    MilestoneTaskLibraryMap: MilestoneTaskLibraryMap,
    TemplateDetail: TemplateDetail,
    Address: Address,
    ServiceTerritory: ServiceTerritory,
    JobSearchAlert: JobSearchAlert,
    Answers: Answers,
    Certification: Certification,
    EducationHistory: EducationHistory,
    EmploymentHistory: EmploymentHistory,
    EngagementActivity: EngagementActivity,
    EngagementLink: EngagementLink,
    EngagementProgram: EngagementProgram,
    EngagementStage: EngagementStage,
    InterviewFeedback: InterviewFeedback,
    JobBoardSetup: JobBoardSetup,
    JobInterviews: JobInterviews,
    JobLocation: JobLocation,
    JobOffers: JobOffers,
    JobOpportunityMapping: JobOpportunityMapping,
    JobOpportunity: JobOpportunity,
    JobOrderJobBoardAssociation: JobOrderJobBoardAssociation,
    JobOrder: JobOrder,
    JobPlacements: JobPlacements,
    JobPrescreen: JobPrescreen,
    JobSkills: JobSkills,
    JobSubmittals: JobSubmittals,
    JobTemplate: JobTemplate,
    Qualification: Qualification,
    QuestionSet: QuestionSet,
    Questions: Questions,
    Reference: Reference,
    Referral: Referral,
    StandardQuestion: StandardQuestion,
    StateManager: StateManager,
    UserAnalytics: UserAnalytics,
    ReleaseNotes: ReleaseNotes,
    PageViewMapping: PageViewMapping,
    Source: Source,
    DocumentRole: DocumentRole,
    ConversationDeleteTracker: ConversationDeleteTracker,
    ProblemTaskReasonCode: ProblemTaskReasonCode,
    ChangeAssignedToGroup: ChangeAssignedToGroup,
    ChangeAssignedToVendorWorker: ChangeAssignedToVendorWorker,
    ProjectGeo: ProjectGeo,
    ServiceoProblemReasonCodes: ServiceoProblemReasonCodes,
    Taxonomies: Taxonomies,
    TaxonomySkills: TaxonomySkills,
    AnswerSequenceItem: AnswerSequenceItem,
    AnswerSequence: AnswerSequence,
    LearningAssignment: LearningAssignment,
    LearningCategory: LearningCategory,
    LearningVersion: LearningVersion,
    Learning: Learning,
    LearningQuestion: LearningQuestion,
    Quiz: Quiz,
    Response: Response,
    TrainingPlanCategory: TrainingPlanCategory,
    TrainingPlanSectionItem: TrainingPlanSectionItem,
    TrainingPlanSection: TrainingPlanSection,
    TrainingPlan: TrainingPlan,
    TrainingResource: TrainingResource,
    QuestionLine: QuestionLine,
    TrainingPlanAssignment: TrainingPlanAssignment,
    TrainingPlanAccount: TrainingPlanAccount,
    TrainingPlanFeedback: TrainingPlanFeedback,
    Achievement: Achievement,
    TrainingPlanAchievement: TrainingPlanAchievement,
    TrainingPlanSectionAssignment: TrainingPlanSectionAssignment,
    JobWorkerRating: JobWorkerRating,
    SiteVisitReport: SiteVisitReport,
    VendorRating: VendorRating,
    CustomerCsat: CustomerCsat,
    LocationJobRecruitment: LocationJobRecruitment,
    MaintenanceSchedule: MaintenanceSchedule,
    PayslipManager: PayslipManager,
    PayslipItem: PayslipItem,
    Resource: Resource,
    ServiceType: ServiceType,
    CustomPricebookEntry: CustomPricebookEntry,
    SkillingInsurance: SkillingInsurance,
    MyWorkLeaveApprovalSetting: MyWorkLeaveApprovalSetting,
    MyWorkLeaveApproval: MyWorkLeaveApproval,
    MyWorkLeaveEntitlementSetting: MyWorkLeaveEntitlementSetting,
    MyWorkLeaveRequest: MyWorkLeaveRequest,
    MyWorkLeaveTypeSetting: MyWorkLeaveTypeSetting,
    MyWorkLeaveType2ApprovalSetting: MyWorkLeaveType2ApprovalSetting,
    MyWorkLeaveWorkerBalance: MyWorkLeaveWorkerBalance,
    MyWorkplaceTemplateScheduler: MyWorkplaceTemplateScheduler,
    SkillingLicenses: SkillingLicenses,
    Passcode:Passcode,
    CorrectiveActionRequest: CorrectiveActionRequest,
    CorrectiveActionRequestLine: CorrectiveActionRequestLine,
    AssociatedTask: AssociatedTask,
    ExportLog:ExportLog,
    PageViewGroup: PageViewGroup,
    PageViewTitle: PageViewTitle,
    Period: Period,
    SkillingCertification: SkillingCertification,
    NotesAttachment: NotesAttachment,
    JobAssessment:JobAssessment,
    ApplicationAssessment: ApplicationAssessment,
    CheckInCalendar: CheckInCalendar,
    SharedLoginLog: SharedLoginLog,
    Sprint: Sprint,
    SprintMaster: SprintMaster,
    Meeting: Meeting,
    MeetingAttendees: MeetingAttendees,
    MeetingParticipant: MeetingParticipant,
    MeetingMinutes: MeetingMinutes,
    MeetingsKR: MeetingsKR,
    WSCRequestHeader: WSCRequestHeader,
    WSCRequestDetail: WSCRequestDetail,
    ProgramHolidayApprovedCalendar: ProgramHolidayApprovedCalendar,
    ApplicantPreview: ApplicantPreview,
    ApplicantResumeParse: ApplicantResumeParse,
    JobOrderJobBoardPosting: JobOrderJobBoardPosting,
    BusinessUnit: BusinessUnit,
    RequestFormJobsite: RequestFormJobsite,
    PrePostTaskCase: PrePostTaskCase,
    ApplicantFavourite: ApplicantFavourite,
    ExpenseLineItem: ExpenseLineItem,
    SOWDetail:SOWDetail,
    JobPosting:JobPosting,
    JobAlertEmailTracking: JobAlertEmailTracking,
    CompOffRequest: CompOffRequest,
    CompoffAutoExpireSetting: CompoffAutoExpireSetting
  };

  public get(modelName: string): any {
    return this.models[modelName];
  }

  public getAll(): Models {
    return this.models;
  }

  public getModelNames(): string[] {
    return Object.keys(this.models);
  }
}
