import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LeaveStatusTypeList } from '../../../models/static-list-data.service'

@Component({
  selector: 'app-leave-status-type-lookup',
  templateUrl: './leave-status-type-lookup.component.html',
  styleUrls: ['./leave-status-type-lookup.component.css']
})
export class LeaveStatusTypeLookupComponent implements OnInit {

  @Input() set resetData(isClear) {
    if (isClear) {
      this.leaveStatusTypeSelected = [];
    }
  }
  @Input() set setPreselectedValue(value) {
    if (value)
      this.leaveStatusTypeSelected = value;
  }
  @Input() placeholder = 'Status';
  @Input() viewType = '';
  @Output() getEmployeeTypeObj: EventEmitter<any> = new EventEmitter;
  @Input() fromPage = '';
  LeaveTypeStatusOptionList = [];
  leaveStatusTypeSelected: any = '';
  constructor() { }

  ngOnInit() {
    this.LeaveTypeStatusOptionList = LeaveStatusTypeList;
    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
    if (!this.leaveStatusTypeSelected)
      this.leaveStatusTypeSelected = (preselected && preselected['leaveStatusType']) ? preselected['leaveStatusType'] : (this.leaveStatusTypeSelected ? this.leaveStatusTypeSelected : '');
  }

  onLeaveStatusTypeChange() {
    this.getEmployeeTypeObj.emit(this.leaveStatusTypeSelected);
  }

  clearLeaveStatusType() {
    this.leaveStatusTypeSelected = [];
    this.getEmployeeTypeObj.emit(this.leaveStatusTypeSelected);
  }

  ngAfterViewInit() {
    if (this.fromPage === 'leave-approval-list') {
      const leaveStatusTypeLookupInput: any = document.querySelector('#leave_status_type_lookup .ng-input');
      const leaveStatusTypeLookupArrow: any = document.querySelector('#leave_status_type_lookup .ng-arrow-wrapper');

      if (leaveStatusTypeLookupInput) {
        leaveStatusTypeLookupInput.style.top = '0';
        leaveStatusTypeLookupInput.style.width = '90%';
      }
      if (leaveStatusTypeLookupArrow)
        leaveStatusTypeLookupArrow.style.bottom = '3px';

    }
  }

  changeStyleClear() {
    const leaveStatusTypeLookupClear: any = document.querySelector('#leave_status_type_lookup .ng-clear-wrapper');
    if (leaveStatusTypeLookupClear)
      leaveStatusTypeLookupClear.style.bottom = '0';
  }

}
