import { Component, OnInit, Input } from '@angular/core';
import { CaseApi, JobInstructionApi } from 'shared/sdk';
import { PreloaderService } from 'shared/services/preloader.service';

@Component({
  selector: 'app-instructions',
  templateUrl: './instructions.component.html',
  styleUrls: ['./instructions.component.css']
})
export class InstructionsComponent implements OnInit {

  @Input() caseData;

  instructionData: any = [];
  error = '';
  customerInstruction = '';

  constructor(
    private _caseApi: CaseApi,
    private _jobInstructionApi: JobInstructionApi,
    private _preloaderService: PreloaderService,
  ) { }

  ngOnInit() {
    // this.setUpInstructionsStructure();
    if (this.caseData) { this.getStatusData() }
  }

  // setUpInstructionsStructure = () => {
  //   this.instructionData = []

  // }

  getStatusData = () => {
    if (this.caseData.iccCaseSfdcId) {
      this._preloaderService.showPreloader();
      this._caseApi.find({
        where: { 'sfdcId': this.caseData.iccCaseSfdcId },
        fields: ['id', 'sfdcId', 'Service_Dispatch__c'],
        include: [
          {
            relation: 'Job',
            scope: {
              fields: ['id', 'sfdcId', 'Custom_work_order_instructions__c']
            }
          }
        ]
      }).subscribe(statusData => {
        // this._preloaderService.hidePreloader();
        if (statusData && statusData.length) {
          const jobId = statusData[0]['Job'] && statusData[0]['Job'].sfdcId || '';
          this.customerInstruction = statusData[0]['Job'] && statusData[0]['Job'].Custom_work_order_instructions__c || '';
          this.getCustomerInstruction();
          if (jobId) {
            this._jobInstructionApi.find({
              where: {
                'Job__c': jobId,
                'IsDeleted': 0
              }
            }).subscribe(jobInstruction => {
              this._preloaderService.hidePreloader();
              if (jobInstruction && jobInstruction.length) {
                jobInstruction.forEach(function (ele) {
                  ele['tools'] = ele['Job_Instructions_Required_Tools__c'] && ele['Job_Instructions_Required_Tools__c'].length
                    ? ele['Job_Instructions_Required_Tools__c'].split('\r\n') : [];
                });
                this.instructionData = jobInstruction[0];
              }
            }, err => {
              this._preloaderService.hidePreloader();
              this.error = 'No Data Found.'
            })
          }
        } else {

          this.error = 'No Data Found.'
        }
      }, err => {
        this._preloaderService.hidePreloader();
      })
    } else if (this.caseData && this.caseData.pmsCaseSfdcId) {
      this._preloaderService.showPreloader();
      const findQuery = {
        'where': { 'sfdcId': this.caseData.pmsCaseSfdcId },
        'source': 'sm-details-pms-tab'
      };
      this._caseApi.getServiceManagerDetailsTabData(findQuery)
        .subscribe((data) => {
          this._preloaderService.hidePreloader();
          if (data && data[0].workOrder) {
            this.prepareInstructionData(data[0].workOrder);
          }
          this._preloaderService.hidePreloader();
        }, err => {
          console.log(err);
          this._preloaderService.hidePreloader();
        });
    }
  }

  prepareInstructionData = (data) => {
    this.instructionData = data;
    this.instructionData['tools'] = data && data.Job_Instructions_Required_Tools__c
      && data.Job_Instructions_Required_Tools__c.split('\r\n') || '';

  }

  getCustomerInstruction = () => {
    if (this.customerInstruction) {
      const cutomerHtml = document.getElementById('customerInformation');
      const parsedDocument = new DOMParser().parseFromString(this.customerInstruction, 'text/html');
      cutomerHtml.innerHTML = parsedDocument.getElementsByTagName('html')[0].innerHTML
    }
    return '';
  }


}
