import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { taskStatusValues } from '../../../models/static-list-data.service'
@Component({
  selector: 'app-task-status-lookup',
  templateUrl: './task-status-lookup.component.html',
  styleUrls: ['./task-status-lookup.component.css']
})
export class TaskStatusLookupComponent implements OnInit {

  taskStatusList = [];
  taskStatusTypeSelected = [];
  isMultipleSelected = true;
  whereObj = {};


  @Input() set resetData(isClear) {
    if (isClear) {
      this.taskStatusTypeSelected = [];
    }
  }
  @Output() getTaskStatusObj: EventEmitter<any> = new EventEmitter;

  @Input()
  set setTaskStatusObj(params) {
    this.taskStatusList = [];
    // this.clearTaskStatus();
    this.whereObj = this.whereObj && Object.keys(this.whereObj).length ? this.whereObj : {};
  }

  constructor() { }

  ngOnInit() {
    this.taskStatusList = taskStatusValues;
    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
    this.taskStatusTypeSelected = (preselected && preselected['taskStatuses']) ? preselected['taskStatuses'] : [];
    this.onTaskStatusChange();
  }
  onTaskStatusChange() {
    this.getTaskStatusObj.emit(this.taskStatusTypeSelected);
  }
  clearTaskStatus() {
    this.taskStatusTypeSelected = [];
    this.getTaskStatusObj.emit([]);
  }
}

