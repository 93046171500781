/* tslint:disable */

declare var Object: any;
export interface JobPostingInterface {
  "sfdcId"?: string;
  "JobBoardName"?: string;
  "JobOrderId"?: string;
  "RecuriterId"?: string;
  "ExpiryDate"?: Date;
  "JobPostedDate"?: Date;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "id"?: number;
}

export class JobPosting implements JobPostingInterface {
  "sfdcId": string;
  "JobBoardName": string;
  "JobOrderId": string;
  "RecuriterId": string;
  "ExpiryDate": Date;
  "JobPostedDate": Date;
  "createdAt": Date;
  "updatedAt": Date;
  "id": number;
  constructor(data?: JobPostingInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `JobPosting`.
   */
  public static getModelName() {
    return "JobPosting";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of JobPosting for dynamic purposes.
  **/
  public static factory(data: JobPostingInterface): JobPosting{
    return new JobPosting(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'JobPosting',
      plural: 'JobPostings',
      path: 'JobPostings',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "JobBoardName": {
          name: 'JobBoardName',
          type: 'string'
        },
        "JobOrderId": {
          name: 'JobOrderId',
          type: 'string'
        },
        "RecuriterId": {
          name: 'RecuriterId',
          type: 'string'
        },
        "ExpiryDate": {
          name: 'ExpiryDate',
          type: 'Date'
        },
        "JobPostedDate": {
          name: 'JobPostedDate',
          type: 'Date'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
