import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { DataFormatter } from 'shared/libs/dataFormatter';
import { CommonService } from './common.service';
import { ExportLogApi } from 'shared/sdk';
import { Router } from '@angular/router';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExportToExcelService {

  maxSheetLength = 25; // 31 is the max limit for the name of a sheet in Excel

  constructor(
    private _dataFormatter: DataFormatter,
    private _commonService: CommonService,
    private _exportLogApi:ExportLogApi,
    private _router: Router
     ) { }

  public exportToExcel(json: any[], tableColumn: any[], excelFileName: string, sheetName? :string): void {
    if (json && json.length) {
      if (tableColumn && tableColumn.length) {
       this.initiateTitleMapping(json, tableColumn, excelFileName, sheetName);
      } else {
       this.initiateExportProcess(json, excelFileName);
      }
    }
  }

  // initiateTitleMapping(json, tableColumn, excelFileName) {
  //   let execelJson = [];
  //   json.filter(obj => {
  //     let excelObj = {};
  //     tableColumn.filter(col => {
  //       console.log(col, obj);
  //       const prop = col.prop;
  //       if (obj[prop]) {
  //         excelObj[col.name] = obj[prop];
  //       }
  //     })
  //     if (Object.keys(excelObj).length > 0) {
  //       execelJson.push(excelObj)
  //     }
  //   });
  //   this.initiateExportProcess(execelJson, excelFileName)
  // }

  initiateTitleMapping(data, columns, excelFileName, sheetName?: string) {
    const execelJson = [
      Object.assign({}, ...(columns.map(element => {
        if (element.name && element.name.toLowerCase() !== 'action' && element.name.toLowerCase() !== 'rfi action' && element.name.toLowerCase() !== 'initite rfi email') {
          return { [element.prop]: element.name }
        }
      })))
    ];
    data.forEach(obj => {
        const resObj = {};
        columns.forEach(element => {
            // Handle case for escaping ACTION column from tables
            if (element.name && element.name.toLowerCase() !== 'action' && element.name.toLowerCase() !== 'rfi action' &&  element.name.toLowerCase() !== 'initite rfi email') {
                // Handle case for exporting only visible columns
              if (element.visible) {
                let property = obj[element.prop];
                if (element.type === 'percentage') {
                  property = this._dataFormatter.percentageFormate(property, element.format);
                } else if (element.type === 'currency') {
                  property = this._dataFormatter.currencyFormate(property, element.format);
                } else if (element.type === 'decimal') {
                  property = this._dataFormatter.decimalFormate(property, element.format);
                } else if (element.type === 'list') {
                  property = this._commonService.replaceBreakWithComma(property);
                } else if (element.type === 'NA') {
                  property = property ? property : 'x';
                } else if (element.type === 'date' && element.dateFormat) {
                  property = this._dataFormatter.transform(property, element.dateFormat);
                } else if (element.type === 'array' && Array.isArray(property)) {
                  property = property.join();
                }
                resObj[element.prop] = (property == null || property === undefined) ? '' : property;
              }
            }
        });
        execelJson.push(resObj);
    });
    this.initiateExportProcess(execelJson, excelFileName, sheetName)
}


  initiateExportProcess(json, fileName, sheetName?: string) {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, {skipHeader: true});
    // console.log('worksheet', worksheet);
    // const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    
    // It make sheet name same as file name
    let excelBuffer: any;
    if (sheetName) {
      sheetName = sheetName.substring(0, this.maxSheetLength)
      const workbook: XLSX.WorkBook = { Sheets: { [`${sheetName}`] : worksheet }, SheetNames: [`${sheetName}`] };
      excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    } else {
      const tmpSheetName = fileName.substring(0, this.maxSheetLength)

      const workbook: XLSX.WorkBook = { Sheets: { [`${tmpSheetName}`] : worksheet }, SheetNames: [`${tmpSheetName}`] };
      excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    }
    
    
    this.saveAsExcelFile(excelBuffer, fileName);
    //track userInfo to exportLog
    this.exportLog(fileName, Object(json).length);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName  + new Date().toLocaleDateString() + '_' + new Date().toLocaleTimeString() + EXCEL_EXTENSION);
  }
 
  /**
  * 
  */
  exportLog(excelFileName, downloads){
    const dataObj = {
      filename: excelFileName+'.xlsx',
      page: this._router.url,
      downloads:downloads
    }
    this._exportLogApi.create(dataObj).subscribe((res:any)=>{ 
    }); 
  }
}
