import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpenseTypeLookupComponent } from './expense-type-lookup.component';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule
  ],
  declarations: [ExpenseTypeLookupComponent],
  exports: [ExpenseTypeLookupComponent]
})
export class ExpenseTypeLookupModule { }
