import { LocationJobRecruitmentApi } from './../../../sdk/services/custom/LocationJobRecruitment';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { debounceTime, distinctUntilChanged, switchMap, filter } from 'rxjs/operators';


@Component({
    selector: 'app-location-lookup',
    templateUrl: './location-lookup.component.html',
    styleUrls: ['./location-lookup.component.css']
})
export class LocationLookupComponent implements OnInit {

    @Input()
    set resetData(isClear) {
        if (isClear) {
            this.locationSelectedList = [];
            this.locationOptionList = [];
        }
    }

    @Input() placeholder = 'Location';
    @Input() isdisabled;

    @Input()
    set isMultipleSelected(e) {
        this._multipleSelect = e;
        this.maxSelectedItems = e ? 20 : 1;
    }

    @Output() getLocationObj: EventEmitter<any> = new EventEmitter;
    public searchInput = new EventEmitter<string>();

    _multipleSelect = true;
    maxSelectedItems = 20;

    locationOptionList = [];
    locationSelectedList = [];

    constructor(
        private _locationJobApi: LocationJobRecruitmentApi) {
    }

    ngOnInit() {
        const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
        if (preselected && preselected['locations'] && preselected['locations'].length) {
            this.locationSelectedList = preselected['locations'];
            this.onLocationChange();
            const whereObj = {};
            whereObj['Value'] = { inq: this.locationSelectedList };
            const filterObj = {
                where: whereObj,
                fields: ['id', 'DisplayName', 'Value'],
                order: 'DisplayName asc',
                limit: 200
            }
            this._locationJobApi.find(filterObj).subscribe(data => {
                if (data && data.length) {                    
                    this.locationOptionList = data;
                } else {
                    this.locationOptionList = [];
                }
            }, error => {
                this.locationOptionList = [];
                console.log(error);
            })
        }
        this.getLocationObj.emit(this.locationSelectedList);
        this.getLocationList();
    }

    getLocationList() {
        this.searchInput
            .pipe(
                filter(search => search && search.trim().length > 2),
                debounceTime(200),
                distinctUntilChanged(),
                switchMap(search =>
                    this.getLocations(search)
                )
            ).subscribe(
                data => {
                    if (data && data.length) {
                        this.locationOptionList = data;
                    } else {
                        this.locationOptionList = [];
                    }
                },
                err => {
                    console.log(err);
                    this.locationOptionList = [];
                }
            );
    }


    getLocations(search) {
        let andObj = [];
        andObj = [
            { DisplayName: { like: '%' + search.trim() + '%', options: 'i' } }
        ];
        const filterObj = {
            where: { and: andObj },
            fields: ['id', 'DisplayName', 'Value'],
            order: 'createdAt desc',
            limit: 200
        }
        return this._locationJobApi.find(filterObj);
    }

    onLocationChange() {
        this.getLocationObj.emit(this.locationSelectedList);
    }

    clearLocations() {
        this.locationSelectedList = [];
        this.getLocationObj.emit([]);
    }
}
