import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { JobPrescreenApi , QuestionsApi, PasscodeApi} from 'shared/sdk';
import { PreloaderService } from 'shared/services/preloader.service';
import { AlertService } from 'shared/services/alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'shared/services/common.service';
import { ConfirmDialogService } from 'shared/services/confirm-dialog.service';
import { environment } from 'environments/environment';
import { AppStateService } from 'shared/services/app-state.service';
@Component({
  selector: 'app-prescreen-questions',
  templateUrl: './prescreen-questions.component.html',
  styleUrls: ['./prescreen-questions.component.css']
})
export class PrescreenQuestionsComponent implements OnInit {
  @Input() showQuestHead = true;
  @Input() set jobOrder(e) {
    if (e) {
      this.jobApplicationData = e;
      this.getJobApplicationData(e);
    }
  };
  @Output() close: EventEmitter<any> = new EventEmitter;
  prescreenForm: FormGroup = new FormGroup({});
  questions = [];
  jobApplicationData;
  preScreens:any;
  isLoading = true;
  jobsfdcId: string;
  submitted = false;
  cqaSubmitted = false;
  message = '';
  accessType: string;
  userType: string;
  isMagiclinkTest = false;
  
  constructor(
    public _fb: FormBuilder, 
    private _prescreenApi: JobPrescreenApi,
    private _preloaderService: PreloaderService, 
    private _questionsAPi: QuestionsApi,
    private _alertService: AlertService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _commonService: CommonService,
    private _confirmDialogService: ConfirmDialogService,
    private _appState: AppStateService,
    private _passcodeApi:PasscodeApi) { 
      this._activatedRoute.params.subscribe(params => {
        if (params && params.sfdcId) {
          this.jobsfdcId = params.sfdcId;
        }
      });
    }

  ngOnInit() {
    const appData = JSON.parse(localStorage.getItem('appData'));
    this.isMagiclinkTest = (appData.user && appData.user.isMagiclinkTest) ? appData.user.isMagiclinkTest: false;
    this.accessType = this._appState.getAccessType();
    this.userType = this._appState.getUserTypeSlug();
    this._commonService.saveCurrentPageAnalytics('Recruitment-Job-Detail(Candidate-CAQ)', 'Candidate CAQ');
  }

  getJobApplicationData(e) {
    if(e['ts2__Internal_Question_Set__c']){
      this.getQuestions(e['ts2__Internal_Question_Set__c']);
    }else{
      this.questions = [];
    }
  }

  getQuestions(sfdcId) {
    this._preloaderService.showPreloader();
    this.isLoading = true;
    this._questionsAPi.find({
      where: { ts2__Question_Set__c: sfdcId , sfdcId:{neq:null}},
      include:[
        {
          relation:'questionSet'
        },
        {
          relation: 'answer',
          scope: {
            fields: ['sfdcId', 'ts2__Question__c', 'ts2__Score__c','ts2__Answer__c'],
            order: 'ts2__Order__c asc'
          }
        }
      ],
      order: 'ts2__Order__c asc'
    }).subscribe(reply => {
      this.isLoading = false;
      this._preloaderService.hidePreloader();
      this.questions = reply.filter((ele,i)=>{
        //as name length is define 80 on ISG so sone case full question name is not coming
        ele['Name'] = (ele['ts2__Rich_Text_Question__c']) ? ele['ts2__Rich_Text_Question__c'] : (ele['ts2__Question__c']) ? ele['ts2__Question__c'] : ele['Name'];
        // if(ele['ts2__Question__c']){
        //   ele['ts2__Question__c'] = (i+1)+'. '+ele['ts2__Question__c']+'<span class="required">*</span>';
        // }
        // if(ele['ts2__Rich_Text_Question__c']){
        //   ele['ts2__Rich_Text_Question__c'] = (i+1)+'. '+ele['ts2__Rich_Text_Question__c']+'<span class="required">*</span>';
        // }
        if(!ele['ts2__IsTxt__c'] && ele['answer'].length===0){
          delete reply[i];
        }
        return reply[i];
      });
      this.createFormControls();
    }, err => {
      this.isLoading = false;
      this._preloaderService.hidePreloader();
      console.log(err);
    });
  }

  createFormControls() {
    let formGroup = {};
    this.questions.forEach((item, index) => {
      if(item.sfdcId){
        formGroup['id_' + item.sfdcId] = new FormControl('');
        formGroup['answer_' + item.sfdcId] = new FormControl('');
        formGroup['answer_txt_' + item.sfdcId] = new FormControl('', [Validators.required]);
      }
        
    });
    this.prescreenForm = new FormGroup(formGroup);
    this.getJobPrescreen(this.jobApplicationData['appicationSfdcId']);
  }

  applyPrescreenSave() { 
    if (this.prescreenForm.invalid) {
      this.submitted = true;
      return false;
    };
    var finalObj = [];
    for (var x = 0; x < this.questions.length; x++) {
      if(this.prescreenForm.get('answer_txt_' +this.questions[x].sfdcId).value !== ''){
        const item = {
          ts2__Application__c:this.jobApplicationData['appicationSfdcId'],
          ts2__Question_Set__c:this.questions[x].ts2__Question_Set__c,
          Name:this.questions[x].ts2__Order__c,
          ts2__Question_ID__c:this.questions[x].sfdcId,
          ts2__Question_Size__c:this.questions[x].ts2__Question_Size__c,
          ts2__Rich_Text_Question__c:this.questions[x].ts2__Rich_Text_Question__c,
          ts2__Question__c:this.questions[x].Name
        }
        // ts2__IsTxt__c is true the use dropdownvalue in ts2__Answer_ID__c
        if(!this.questions[x].ts2__IsTxt__c && this.prescreenForm.get('answer_txt_' + this.questions[x].sfdcId).value){
          const answerSfdcId = this.prescreenForm.get('answer_txt_' + this.questions[x].sfdcId).value;
          item['ts2__Answer_ID__c'] = answerSfdcId; 
          item['ts2__Answer__c'] = this.questions[x].answer.find(item => item.sfdcId===answerSfdcId).ts2__Answer__c;
          item['ts2__Score__c'] = this.questions[x].answer.find(item => item.sfdcId===answerSfdcId).ts2__Score__c;
        }else{
          item['ts2__Answer_Txt__c'] = this.prescreenForm.get('answer_txt_' + this.questions[x].sfdcId).value; 
        }

        if(this.prescreenForm.get('id_' + this.questions[x].sfdcId).value){
          item['id'] = this.prescreenForm.get('id_' + this.questions[x].sfdcId).value;
        }
        finalObj.push(item);
      }
    }
    this._preloaderService.showPreloader();
    this._prescreenApi.CaqAns({data:finalObj}).subscribe(response => {
      this._preloaderService.hidePreloader();
      this.submitted = false;
      this.message = 'Your response has been saved successfully.'
      this._alertService.success(this.message);
      // this.goBack();
      if(this.jobApplicationData && this.jobApplicationData['passcodeId']){
        const passcodeId = this.jobApplicationData['passcodeId'];
        this.updatePasscode(passcodeId);
      }
      this.cqaSubmitted = true;
    }, err => {
      this.cqaSubmitted = false;
      this._alertService.error(err.message);
      this._preloaderService.hidePreloader();
      console.log(err);
    });
  }

  cancel() {
    this.close.emit('close');
  }
  getJobPrescreen(appSfdcId){
    this._prescreenApi.find({
      where: { ts2__Application__c: appSfdcId },
    }).subscribe(preScreen => {
      this._preloaderService.hidePreloader();
      preScreen.forEach((item) => {
        if(this.prescreenForm.get('answer_'+ item['ts2__Question_ID__c'])){
          this.prescreenForm.get('answer_'+ item['ts2__Question_ID__c']).setValue(item['ts2__Answer__c']);
        }
        if(this.prescreenForm.get('answer_txt_'+ item['ts2__Question_ID__c'])){
          this.prescreenForm.get('answer_txt_'+ item['ts2__Question_ID__c']).setValue(item['ts2__Answer_Txt__c']);
        }
        if(this.prescreenForm.get('id_'+ item['ts2__Question_ID__c'])){
          this.prescreenForm.get('id_'+ item['ts2__Question_ID__c']).setValue(item['id']);
        }
    });
    }, err => {
      this._preloaderService.hidePreloader();
      console.log(err);
    });
  }

  goBack(){
    this._router.navigate(['/app/marketplace-recruitment-jobs/staffing-job-manager-detail/'+this.jobsfdcId]);
  }

  resetForm(){
    this.prescreenForm.reset();
  }

  sendCaqEmail(){
    const _thisEvent = this;
    const email = (_thisEvent.jobApplicationData['public'] && _thisEvent.jobApplicationData['public']['email']) ? _thisEvent.jobApplicationData['public']['email'] : '';
    this._confirmDialogService.confirmThis(
      {
        title: 'Confirm!!',
        titleIcon: 'mdi mdi-info text-info',
        text: 'Are you sure you want to send Email : ' + email
      },
      function () {
        _thisEvent._preloaderService.showPreloader();
          const url = environment.communityUrl+'/public/caq/'+_thisEvent.jobApplicationData['ts2__Internal_Question_Set__c'];
          const dataObj = { appSfdcId: _thisEvent.jobApplicationData['appicationSfdcId'], url: url , queSet: _thisEvent.jobApplicationData['ts2__Internal_Question_Set__c'],
          queName:_thisEvent.jobApplicationData['internal_Question_Set_Name'],
          jobName:_thisEvent.jobApplicationData['jobOrderNumber']};
        _thisEvent._prescreenApi.sendCaqLink({ data: dataObj}).subscribe(
          result => {
            _thisEvent._preloaderService.hidePreloader();
            _thisEvent._alertService.success('Email has been sent successfully.');
          }, err => {
            _thisEvent._alertService.warn('Oops! something went wrong.');
            _thisEvent._preloaderService.hidePreloader();
          }
        );
      },
      function () {
        // Do nothing on cancel
        _thisEvent._preloaderService.hidePreloader();
      }
    );
  }

  updatePasscode(passcodeId){
    this._passcodeApi.patchAttributes(passcodeId,{isSubmitted:1}).subscribe(res=>{

    },error=>{

    })
  }
}
