/* tslint:disable */
import {
  Account,
  Case,
  Contact,
  Users,
  Order,
  Location,
  ProductRequest
} from '../index';

declare var Object: any;
export interface ReturnOrderInterface {
  "sfdcId"?: string;
  "AccountId"?: string;
  "CaseId"?: string;
  "ContactId"?: string;
  "CreatedById"?: string;
  "CreatedDate"?: Date;
  "CurrencyIsoCode"?: string;
  "Description"?: string;
  "DestinationLocationId"?: string;
  "ExpectedArrivalDate"?: Date;
  "LastModifiedById"?: string;
  "LastModifiedDate"?: Date;
  "OrderId"?: string;
  "OwnerId"?: string;
  "ProductRequestId"?: string;
  "ReturnedById"?: string;
  "ReturnOrderNumber"?: number;
  "ShipFromAddress"?: string;
  "ShipmentType"?: string;
  "SourceLocationId"?: string;
  "Status"?: string;
  "IsDeleted"?: boolean;
  "LastReferencedDate"?: Date;
  "LastViewedDate"?: Date;
  "ShipFromCity"?: string;
  "ShipFromCountry"?: string;
  "ShipFromPostalCode"?: string;
  "ShipFromState"?: string;
  "ShipFromStreet"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  account?: Account;
  case?: Case;
  contact?: Contact;
  users?: Users;
  order?: Order;
  dlocation?: Location;
  slocation?: Location;
  productRequest?: ProductRequest;
}

export class ReturnOrder implements ReturnOrderInterface {
  "sfdcId": string;
  "AccountId": string;
  "CaseId": string;
  "ContactId": string;
  "CreatedById": string;
  "CreatedDate": Date;
  "CurrencyIsoCode": string;
  "Description": string;
  "DestinationLocationId": string;
  "ExpectedArrivalDate": Date;
  "LastModifiedById": string;
  "LastModifiedDate": Date;
  "OrderId": string;
  "OwnerId": string;
  "ProductRequestId": string;
  "ReturnedById": string;
  "ReturnOrderNumber": number;
  "ShipFromAddress": string;
  "ShipmentType": string;
  "SourceLocationId": string;
  "Status": string;
  "IsDeleted": boolean;
  "LastReferencedDate": Date;
  "LastViewedDate": Date;
  "ShipFromCity": string;
  "ShipFromCountry": string;
  "ShipFromPostalCode": string;
  "ShipFromState": string;
  "ShipFromStreet": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  account: Account;
  case: Case;
  contact: Contact;
  users: Users;
  order: Order;
  dlocation: Location;
  slocation: Location;
  productRequest: ProductRequest;
  constructor(data?: ReturnOrderInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ReturnOrder`.
   */
  public static getModelName() {
    return "ReturnOrder";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ReturnOrder for dynamic purposes.
  **/
  public static factory(data: ReturnOrderInterface): ReturnOrder{
    return new ReturnOrder(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ReturnOrder',
      plural: 'ReturnOrders',
      path: 'ReturnOrders',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "AccountId": {
          name: 'AccountId',
          type: 'string'
        },
        "CaseId": {
          name: 'CaseId',
          type: 'string'
        },
        "ContactId": {
          name: 'ContactId',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "Description": {
          name: 'Description',
          type: 'string'
        },
        "DestinationLocationId": {
          name: 'DestinationLocationId',
          type: 'string'
        },
        "ExpectedArrivalDate": {
          name: 'ExpectedArrivalDate',
          type: 'Date'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "OrderId": {
          name: 'OrderId',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "ProductRequestId": {
          name: 'ProductRequestId',
          type: 'string'
        },
        "ReturnedById": {
          name: 'ReturnedById',
          type: 'string'
        },
        "ReturnOrderNumber": {
          name: 'ReturnOrderNumber',
          type: 'number'
        },
        "ShipFromAddress": {
          name: 'ShipFromAddress',
          type: 'string'
        },
        "ShipmentType": {
          name: 'ShipmentType',
          type: 'string'
        },
        "SourceLocationId": {
          name: 'SourceLocationId',
          type: 'string'
        },
        "Status": {
          name: 'Status',
          type: 'string'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "LastReferencedDate": {
          name: 'LastReferencedDate',
          type: 'Date'
        },
        "LastViewedDate": {
          name: 'LastViewedDate',
          type: 'Date'
        },
        "ShipFromCity": {
          name: 'ShipFromCity',
          type: 'string'
        },
        "ShipFromCountry": {
          name: 'ShipFromCountry',
          type: 'string'
        },
        "ShipFromPostalCode": {
          name: 'ShipFromPostalCode',
          type: 'string'
        },
        "ShipFromState": {
          name: 'ShipFromState',
          type: 'string'
        },
        "ShipFromStreet": {
          name: 'ShipFromStreet',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'AccountId',
          keyTo: 'sfdcId'
        },
        case: {
          name: 'case',
          type: 'Case',
          model: 'Case',
          relationType: 'belongsTo',
                  keyFrom: 'CaseId',
          keyTo: 'sfdcId'
        },
        contact: {
          name: 'contact',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'ContactId',
          keyTo: 'sfdcId'
        },
        users: {
          name: 'users',
          type: 'Users',
          model: 'Users',
          relationType: 'belongsTo',
                  keyFrom: 'CreatedById',
          keyTo: 'sfdcId'
        },
        order: {
          name: 'order',
          type: 'Order',
          model: 'Order',
          relationType: 'belongsTo',
                  keyFrom: 'OrderId',
          keyTo: 'sfdcId'
        },
        dlocation: {
          name: 'dlocation',
          type: 'Location',
          model: 'Location',
          relationType: 'belongsTo',
                  keyFrom: 'DestinationLocationId',
          keyTo: 'sfdcId'
        },
        slocation: {
          name: 'slocation',
          type: 'Location',
          model: 'Location',
          relationType: 'belongsTo',
                  keyFrom: 'SourceLocationId',
          keyTo: 'sfdcId'
        },
        productRequest: {
          name: 'productRequest',
          type: 'ProductRequest',
          model: 'ProductRequest',
          relationType: 'belongsTo',
                  keyFrom: 'ProductRequestId',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
