import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterPipe } from './forFilter/filter.pipe';
import { FilesizePipe } from './filesize/filesize.pipe';
import { FileiconPipe } from './fileicon/fileicon.pipe';
import { CountCarPipe } from './countChar/count.char.pipe';
import { UniquePipe } from './unique/unique.pipe';
import { CommaPipe } from './comma/comma.pipe';
import { TimeAgoPipe } from './time-ago/time-ago.pipe';
import { SliceContentPipe } from './slice/slice-content.pipe';
import { SliceArrayPipe } from './sliceArray/slice-array.pipe';
import { RemoveImage } from './htmlfilter/img.pipe';
import { KeyValuePipe } from './keyValue/keyValue.pipe';
import { IsInUsePipe } from './isInUse/isInUse.pipe';
import { IsInSelectedTeamPipe } from './isInSelectedTeam/isInSelectedTeam.pipe';
import { SafeHtmlPipe } from './safeHtml/safe-html.pipe';
import { AuthImagePipe } from './imageFetch/authImage.pipe';
import { NoRejectedPipe } from './rejectedApplicants/noRejected.pipe';
import { NodaysOpenPipe } from './daysOpen/daysOpen.pipe';
@NgModule({
   imports: [
      CommonModule
   ],
   declarations: [
      FilterPipe,
      FilesizePipe,
      FileiconPipe,
      CountCarPipe,
      UniquePipe,
      CommaPipe,
      SliceContentPipe,
      TimeAgoPipe,
      SliceArrayPipe,
      RemoveImage,
      KeyValuePipe,
      IsInUsePipe,
      IsInSelectedTeamPipe,
      SafeHtmlPipe,
      AuthImagePipe,
      NoRejectedPipe,
      NodaysOpenPipe
   ],
   exports: [
      FilterPipe,
      FilesizePipe,
      FileiconPipe,
      CountCarPipe,
      UniquePipe,
      CommaPipe,
      SliceContentPipe,
      TimeAgoPipe,
      SliceArrayPipe,
      RemoveImage,
      KeyValuePipe,
      IsInUsePipe,
      IsInSelectedTeamPipe,
      SafeHtmlPipe,
      AuthImagePipe,
      NoRejectedPipe,
      NodaysOpenPipe
   ]
})
export class PipeModule { }
