import { Router } from '@angular/router';
import { CommonService } from 'shared/services/common.service';
import { TaskApi } from './../../sdk/services/custom/Task';
import { Component, OnInit, Input } from '@angular/core';
import { DeliveryManagerService } from 'shared/views/pms/delivery-manager/delivery-manager.service';
import { PreloaderService } from '../../services/preloader.service';

@Component({
  selector: 'app-task-manager-detail',
  templateUrl: './task-manager-detail.component.html',
  styleUrls: ['./task-manager-detail.component.css']
})
export class TaskManagerDetailComponent implements OnInit {

  @Input()
  set selectedTask(e) {
    this._selectedItem = e;
    if (e && e.task) {
      this.getTaskDetail();
    } else {
      this.taskDetails = null;
    }
  }
  _selectedItem;
  taskDetails;
  noDetailMsg = 'No Detail found';
  canEdit = false;
  durationVal: any;
  
  constructor(
    private _taskAPI: TaskApi,
    private _commonService: CommonService,
    private _deliveryManagerService: DeliveryManagerService,
    public _router: Router,
    public _preloaderService: PreloaderService
  ) { }

  ngOnInit() {
  }

  getTaskDetail() {
    let filterObj = {
      include: [
        {
          relation: 'account',
          scope: {
            fields: ['Name']
          }
        },
        {
          relation: 'project',
          scope: {
            fields: ['id', 'sfdcId', 'Name', 'ProjectName__c', 'Project_No__c', 'Project_Type__c', 'RecordTypeId', 'Sub_Catergory__c', 'Category__c']
          }
        },
        {
          relation: 'milestone',
          scope: {
            fields: ['id', 'sfdcId', 'Name', 'Milestone_No__c', 'Sequence_Number__c']
          }
        },
        {
          relation: 'createdByContact',
          scope: {
            fields: ['Name']
          }
        },
        {
          relation: 'updatedByContact',
          scope: {
            fields: ['sfdcId', 'Contact_Name__c']
          }
        },
        {
          relation: 'requestorWorker',
          scope: {
            fields: ['Name']
          }
        },
        {
          relation: 'assignment',
          scope: {
            fields: ['sfdcId', 'id', 'PgMO_Tasks__c', 'PgMO_Milestones__c', 'PgMO_Groups__c', 'Worker__c'],
            include: [
              {
                relation: 'group',
                scope: {
                  fields: ['sfdcId', 'Department_Group_Code__c', 'Name', 'Department__c', 'Community__c'],
                  include: [
                    {
                      relation: 'department',
                      scope: {
                        fields: ['sfdcId', 'Name']
                      }
                    }
                  ]
                }
              },
              {
                relation: 'worker',
                scope: {
                  fields: ['sfdcId', 'Contact__c'],
                  include: [{
                    relation: 'contact',
                    scope: {
                      fields: ['sfdcId', 'FirstName', 'LastName']
                    }
                  }]
                }
              }
            ]
          }
        },
        {
          relation: 'accessControl',
          scope: {
            fields: ['sfdcId', 'id', 'PgMO_Tasks__c', 'PgMO_Milestones__c', 'PgMO_Groups__c', 'Member__c', 'Access__c'],
            include: [
              {
                relation: 'group',
                scope: {
                  fields: ['sfdcId', 'Department_Group_Code__c', 'Name', 'Department__c', 'Community__c'],
                  include: [{
                    relation: 'department',
                    scope: {
                      fields: ['sfdcId', 'Name']
                    }
                  }]
                }
              },
              {
                relation: 'worker',
                scope: {
                  fields: ['sfdcId', 'Contact__c'],
                  include: [{
                    relation: 'contact',
                    scope: {
                      fields: ['sfdcId', 'FirstName', 'LastName']
                    }
                  }]
                }
              }
            ]
          }
        },
      ]
    };
    const whereObj = this._setWhereCondition();
    filterObj['where'] = whereObj;
    this._taskAPI.find(filterObj).subscribe(
      data => {
        if (data && data.length) {
          this.taskDetails = data[0];
          this.formatTaskDetailData();
        }
      },
      err => {
        console.log('Error fetching Task', err.message);
      }
    );
  }

  private _setWhereCondition() {
    return this._selectedItem && this._selectedItem.task && this._selectedItem.task.id && this._selectedItem.task.sfdcId ?
      { id: this._selectedItem.task.id } : { sfdcId: this._selectedItem.task.sfdcId };
  }

  formatTaskDetailData() {
    if (this.taskDetails) {
      if (this.taskDetails.createdAt) {
        this.taskDetails.createdAt = this._commonService.dateFormate(this.taskDetails.createdAt);
      }
      if (this.taskDetails.Start_Date__c) {
        this.taskDetails.Start_Date__c = this._commonService.dateFormate(this.taskDetails.Start_Date__c, '', 'MMM D, YYYY');
      }
      if (this.taskDetails.Due_Date__c) {
        this.taskDetails.Due_Date__c = this._commonService.dateFormate(this.taskDetails.Due_Date__c, '', 'MMM D, YYYY');
      }
      if (this.taskDetails.updatedAt) {
        this.taskDetails.updatedAt = this._commonService.dateFormate(this.taskDetails.updatedAt);
      }
      if (this.taskDetails.Work_Order_Plan_Date__c) {
        this.taskDetails.Work_Order_Plan_Date__c = this._commonService.dateFormate(this.taskDetails.Work_Order_Plan_Date__c);
      }
      if (this.taskDetails.Deliverables_L5_Due_Date__c) {
        this.taskDetails.Deliverables_L5_Due_Date__c = this._commonService.dateFormate(this.taskDetails.Deliverables_L5_Due_Date__c);
      }
      // need to show 'Duration__c' based on database as we enabled it in task edit view in task conversation
      // if (this.taskDetails.Duration_Type__c) {
      //   const finalValues = this._deliveryManagerService.getDateTimeAsPriority(this.taskDetails.Priority__c);
      //    this.durationVal = finalValues['duration'] + ' ' + finalValues['durationType']
      // }
      if (this.taskDetails.Start_Time__c) {
        this.taskDetails.Start_Time__c = this._deliveryManagerService.getConvertedTime(this.taskDetails.Start_Time__c);
      }
      if (this.taskDetails.Due_Time__c) {
        this.taskDetails.Due_Time__c = this._deliveryManagerService.getConvertedTime(this.taskDetails.Due_Time__c);
      }
    }
  }

  openLink(type) {
    this._preloaderService.showPreloader();
    const consoleTypeUrl = this.getConsoleTypeUrl();
    if (consoleTypeUrl) {
      this._router.navigate([consoleTypeUrl], { queryParams: { consoleType: this.taskDetails.project.Project_Type__c, useDefaultFilters: true } });
      const filterCondition = JSON.parse(localStorage.getItem('filterCondition'));
      const filterObj = JSON.parse(localStorage.getItem('filterObj'));
      switch (type) {
        case 'project':
          const projectSfdcId = new Array(this.taskDetails.project.sfdcId);
          filterCondition['PgMO_Projects__c'] = { inq: projectSfdcId };
          filterObj['projects'] = projectSfdcId;
          break
        case 'milestone':
          const milestoneSfdcId = new Array(this.taskDetails.milestone.sfdcId);
          filterCondition['PgMO_Milestones__c'] = { inq: milestoneSfdcId };
          filterObj['milestones'] = milestoneSfdcId;
          break
        case 'task':
          const taskSfdcId = new Array(this.taskDetails.sfdcId);
          filterCondition['PgMo_Task__c'] = { inq: taskSfdcId };
          filterObj['tasks'] = taskSfdcId;
          break
      }
      localStorage.setItem('filterCondition', JSON.stringify(filterCondition));
      localStorage.setItem('filterObj', JSON.stringify(filterObj));
      this._preloaderService.hidePreloader();
    } else {
      alert('This console not implemented yet!');
    }
  }

  getConsoleTypeUrl() {
    const PM_NON_DELIVERY_RECORDID = '0121a000000F1IEAA0';
    const TASKCASEFORM_RECORDID = '0122L000000RRiSQAW';
    const project = this.taskDetails.project || '';
    if (project && project.RecordTypeId === PM_NON_DELIVERY_RECORDID && project.Sub_Catergory__c === 'Recruitment' && project.Category__c === 'Others') {
      return '/pms/recruitment-manager-console-new';
    } else if (project && project.RecordTypeId === TASKCASEFORM_RECORDID) {
      return '/pms/project-taskCase-management-console';
    } else if (project && project.Project_Type__c === 'ProblemManagement') {
      return '/pms/problem-management-desk-console-new';
    } else if (project && project.Project_Type__c === 'HelpDesk') {
      return '/pms/help-desk-console-new';
    } else {
      return '';
    }
  }

}
