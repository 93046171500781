/* tslint:disable */
import {
  DepartmentRole
} from '../index';

declare var Object: any;
export interface DepartmentInterface {
  "sfdcId"?: string;
  "Community__c"?: string;
  "CreatedById"?: string;
  "CreatedDate"?: Date;
  "CurrencyIsoCode"?: string;
  "Default__c"?: boolean;
  "Department_Access__c"?: string;
  "Department_Acronym__c"?: string;
  "Department_Name__c"?: string;
  "Description_Long__c"?: string;
  "Description_Private__c"?: string;
  "Description_Short__c"?: string;
  "IsDeleted"?: boolean;
  "LastActivityDate"?: Date;
  "LastModifiedById"?: string;
  "LastModifiedDate"?: Date;
  "LastReferencedDate"?: Date;
  "LastViewedDate"?: Date;
  "Name"?: string;
  "OwnerId"?: string;
  "RecordTypeId"?: string;
  "Serviceo_Department_Id__c"?: string;
  "IsActive"?: boolean;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  departmentRole?: DepartmentRole[];
}

export class Department implements DepartmentInterface {
  "sfdcId": string;
  "Community__c": string;
  "CreatedById": string;
  "CreatedDate": Date;
  "CurrencyIsoCode": string;
  "Default__c": boolean;
  "Department_Access__c": string;
  "Department_Acronym__c": string;
  "Department_Name__c": string;
  "Description_Long__c": string;
  "Description_Private__c": string;
  "Description_Short__c": string;
  "IsDeleted": boolean;
  "LastActivityDate": Date;
  "LastModifiedById": string;
  "LastModifiedDate": Date;
  "LastReferencedDate": Date;
  "LastViewedDate": Date;
  "Name": string;
  "OwnerId": string;
  "RecordTypeId": string;
  "Serviceo_Department_Id__c": string;
  "IsActive": boolean;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  departmentRole: DepartmentRole[];
  constructor(data?: DepartmentInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Department`.
   */
  public static getModelName() {
    return "Department";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Department for dynamic purposes.
  **/
  public static factory(data: DepartmentInterface): Department{
    return new Department(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Department',
      plural: 'Departments',
      path: 'Departments',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "Community__c": {
          name: 'Community__c',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "Default__c": {
          name: 'Default__c',
          type: 'boolean'
        },
        "Department_Access__c": {
          name: 'Department_Access__c',
          type: 'string'
        },
        "Department_Acronym__c": {
          name: 'Department_Acronym__c',
          type: 'string'
        },
        "Department_Name__c": {
          name: 'Department_Name__c',
          type: 'string'
        },
        "Description_Long__c": {
          name: 'Description_Long__c',
          type: 'string'
        },
        "Description_Private__c": {
          name: 'Description_Private__c',
          type: 'string'
        },
        "Description_Short__c": {
          name: 'Description_Short__c',
          type: 'string'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "LastActivityDate": {
          name: 'LastActivityDate',
          type: 'Date'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "LastReferencedDate": {
          name: 'LastReferencedDate',
          type: 'Date'
        },
        "LastViewedDate": {
          name: 'LastViewedDate',
          type: 'Date'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "RecordTypeId": {
          name: 'RecordTypeId',
          type: 'string'
        },
        "Serviceo_Department_Id__c": {
          name: 'Serviceo_Department_Id__c',
          type: 'string'
        },
        "IsActive": {
          name: 'IsActive',
          type: 'boolean'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        departmentRole: {
          name: 'departmentRole',
          type: 'DepartmentRole[]',
          model: 'DepartmentRole',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'PgMO_Departments__c'
        },
      }
    }
  }
}
