import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { MilestoneApi } from 'shared/sdk';

@Component({
  selector: 'app-form-name-lookup',
  templateUrl: './form-name-lookup.component.html',
  styleUrls: ['./form-name-lookup.component.css']
})
export class FormNameLookupComponent implements OnInit {

  public searchInput = new EventEmitter<string>();

  @Input() set resetData(isClear) {
    if (isClear) {
      this.selectedRecord = null;
    }
  }

  @Input() isMultiSelect = true;
  @Input() placeholder = 'Form Name';
  @Output() getEventObj: EventEmitter<any> = new EventEmitter();
  recordList: any;
  itemsPerBatch = 50;
  notFoundText: string;
  orderBy = 'id DESC';
  whereFilter: any = {};
  selectedRecord = null;
  maxSelectedItems = 1;

  constructor(private _milestoneApi: MilestoneApi) { }

  ngOnInit() {
    this.preselectedRecord();
    this.getRecordsList();
    this.getSelectedRecordsList();
  }

  preselectedRecord() {
    const preSelected = localStorage.getItem('filterObj');
    if(!this.selectedRecord) {
      this.selectedRecord = JSON.parse(preSelected)['formName'];
    }
  }

  getSelectedRecordsList() {
    if (this.selectedRecord) {
      this.loadRecordsList().subscribe(
        x => {
          x.map(ele => { ele['Label'] = ele['Form_Name__c'] });
          this.recordList = x;
          this.notFoundText = (this.recordList.length === 0) ? 'No Form Name found.' : '';
        },
        err => {
          this.recordList = [];
        }
      )
    }
  }

  getRecordsList() {
    this.searchInput
      .pipe(
        filter(search => search && search.length > 3),
        debounceTime(200),
        distinctUntilChanged(),
        switchMap(search => this.loadRecordsList(search))
      ).subscribe(
        x => {
          x.map(ele => { ele['Label'] = ele['Form_Name__c'] });
          this.recordList = x;
          this.notFoundText = (this.recordList.length === 0) ? 'No Form Name found.' : '';
        },
        err => {
          console.log(err);
          this.recordList = [];
        }

      );
  }

  loadRecordsList(search?) {
    if (search) {
      const searchPattern = { like: '%' + search.trim() + '%', options: 'i' };
      this.whereFilter = { Form_Name__c: searchPattern };
    }
    if (!search && this.selectedRecord) {
      this.whereFilter['Form_Name__c'] = this.selectedRecord;
    }

    const reqObj = {
      'fields': ['Form_Name__c', 'sfdcId'],
      'where': this.whereFilter,
      limit: this.itemsPerBatch,
      order: this.orderBy
    };
    return this._milestoneApi.find(reqObj);
  }

  clearRecords() {
    this.selectedRecord = null;
    this.notFoundText = 'Type to search period.';
    this.getEventObj.emit(null);
  }

  onRecordsChange() {
    this.getEventObj.emit(this.selectedRecord);
  }

}
