import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { milestoneTmpltLibraryTypes } from 'shared/models/static-list-data.service';

@Component({
  selector: 'app-template-library-type-lookup',
  templateUrl: './template-library-type-lookup.component.html',
  styleUrls: ['./template-library-type-lookup.component.css']
})
export class TemplateLibraryTypeLookupComponent implements OnInit {

  tmpltLibraryList = [];
  tmpltLibraryListSelected = [];
  isMultipleSelected = true;

  @Input() set resetData(isClear) {
    if (isClear) {
      this.tmpltLibraryListSelected = [];
    }
  }
  @Output() selected: EventEmitter<any> = new EventEmitter;
  constructor() { }

  ngOnInit() {
    this.tmpltLibraryList = milestoneTmpltLibraryTypes;
    const preselected = localStorage.getItem('filterObj') && JSON.parse(localStorage.getItem('filterObj'));
    this.tmpltLibraryListSelected = (preselected && preselected['tmpltLibraryTypes']) ? preselected['tmpltLibraryTypes'] : [];
  }

  onTmpltLibraryChange() {
    this.selected.emit(this.tmpltLibraryListSelected);
  }

  clearTmpltLibrary() {
    this.tmpltLibraryListSelected = [];
    this.selected.emit([]);
  }
}
