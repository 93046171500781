/* tslint:disable */

declare var Object: any;
export interface SourceInterface {
  "sfdcId"?: string;
  "ts2__Active__c"?: boolean;
  "jsrecruitdash__Cost__c"?: number;
  "ts2__Descrition__c"?: any;
  "ts2__End_Date__c"?: Date;
  "ts2__ExternalID__c"?: string;
  "ts2__General_Source_Type__c"?: string;
  "ts2__Source_Email__c"?: string;
  "ts2__Start_Date__c"?: Date;
  "ts2__TXT_Key__c"?: string;
  "ts2__View_on_Job_Board__c"?: boolean;
  "Name"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class Source implements SourceInterface {
  "sfdcId": string;
  "ts2__Active__c": boolean;
  "jsrecruitdash__Cost__c": number;
  "ts2__Descrition__c": any;
  "ts2__End_Date__c": Date;
  "ts2__ExternalID__c": string;
  "ts2__General_Source_Type__c": string;
  "ts2__Source_Email__c": string;
  "ts2__Start_Date__c": Date;
  "ts2__TXT_Key__c": string;
  "ts2__View_on_Job_Board__c": boolean;
  "Name": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: SourceInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Source`.
   */
  public static getModelName() {
    return "Source";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Source for dynamic purposes.
  **/
  public static factory(data: SourceInterface): Source{
    return new Source(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Source',
      plural: 'Sources',
      path: 'Sources',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "ts2__Active__c": {
          name: 'ts2__Active__c',
          type: 'boolean'
        },
        "jsrecruitdash__Cost__c": {
          name: 'jsrecruitdash__Cost__c',
          type: 'number'
        },
        "ts2__Descrition__c": {
          name: 'ts2__Descrition__c',
          type: 'any'
        },
        "ts2__End_Date__c": {
          name: 'ts2__End_Date__c',
          type: 'Date'
        },
        "ts2__ExternalID__c": {
          name: 'ts2__ExternalID__c',
          type: 'string'
        },
        "ts2__General_Source_Type__c": {
          name: 'ts2__General_Source_Type__c',
          type: 'string'
        },
        "ts2__Source_Email__c": {
          name: 'ts2__Source_Email__c',
          type: 'string'
        },
        "ts2__Start_Date__c": {
          name: 'ts2__Start_Date__c',
          type: 'Date'
        },
        "ts2__TXT_Key__c": {
          name: 'ts2__TXT_Key__c',
          type: 'string'
        },
        "ts2__View_on_Job_Board__c": {
          name: 'ts2__View_on_Job_Board__c',
          type: 'boolean'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
