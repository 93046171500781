/* tslint:disable */

declare var Object: any;
export interface StateInterface {
  "name"?: string;
  "country_code"?: string;
  "country_name"?: string;
  "id"?: number;
}

export class State implements StateInterface {
  "name": string;
  "country_code": string;
  "country_name": string;
  "id": number;
  constructor(data?: StateInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `State`.
   */
  public static getModelName() {
    return "State";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of State for dynamic purposes.
  **/
  public static factory(data: StateInterface): State{
    return new State(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'State',
      plural: 'States',
      path: 'States',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "country_code": {
          name: 'country_code',
          type: 'string'
        },
        "country_name": {
          name: 'country_name',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
