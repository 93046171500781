import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MessageSentLookupComponent } from './message-sent-lookup.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
  ],
  declarations: [MessageSentLookupComponent],
  exports: [MessageSentLookupComponent],
  providers: []
})
export class MessageSentLookupModule { }
