import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';

import { JobStatusLookupComponent } from './job-status-lookup.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgSelectModule
    ],
    declarations: [JobStatusLookupComponent],
    exports: [
        JobStatusLookupComponent
    ]
})
export class JobStatusLookupModule { }
