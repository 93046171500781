import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {PageViewGroupApi} from 'shared/sdk'

@Component({
  selector: 'app-page-view-group-lookup',
  templateUrl: './page-view-group-lookup.component.html',
  styleUrls: ['./page-view-group-lookup.component.css']
})
export class PageViewGroupLookupComponent implements OnInit {
 
  @Input() set resetData(isClear) {
      this.clearFromParent();
  }
  @Output() getPageViewGroupObj: EventEmitter<any> = new EventEmitter;
  selectedValue;
  pageGroups: any = [];

  constructor(
    private _pageViewGroupApi: PageViewGroupApi
  ) { }

  ngOnInit() {
    this.loadData();
    const preSelected = localStorage.getItem('filterObj');
    if (preSelected && JSON.parse(preSelected) &&
        JSON.parse(preSelected)['pageViewGroup'] && JSON.parse(preSelected)['pageViewGroup'].length) {
        this.selectedValue = JSON.parse(preSelected)['pageViewGroup'];
    }
  }

  loadData() {
    const req = {
        fields: ['id', 'GroupName', 'GroupCode'],
        order: 'order_num',
        where: {'isMasterGroup': 0}
    }
    this._pageViewGroupApi.find(req).subscribe(data => {
      this.pageGroups = [];
      if (data && data.length) {
        this.pageGroups = data;        
        this.pageGroups.forEach(i => {
          i['label'] = i['GroupCode'] + ' - ' + i['GroupName']
        });
      }
    }, error => {
        console.log(error.message);
    })
  }

  onChange() {
    this.getPageViewGroupObj.emit(this.selectedValue);
  }

  
  clearFromParent() {
    this.selectedValue = null;
  }


}
