import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { pageType } from '../../../models/static-list-data.service'
@Component({
  selector: 'app-page-type-lookup',
  templateUrl: './page-type-lookup.component.html',
  styleUrls: ['./page-type-lookup.component.css']
})
export class PageTypeLookupComponent implements OnInit {

  pageType = [];
  pageTypeelected = [];
  isMultipleSelected = true;
  @Input() set resetData(isClear) {
    if (isClear) {
      this.pageTypeelected = [];
    }
  }
  @Input() maxSelectedItems = 1;
  @Input() from;
  @Input() selectedDefaultValue;
  @Output() selectedpageType: EventEmitter<any> = new EventEmitter;
  constructor() { }

  ngOnInit() {
    this.pageType = [...pageType];
    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : ''
    this.pageTypeelected = (preselected && preselected['pageType']) ? preselected['pageType'] : 'web';
  }

  onpageTypeChange() {
    this.selectedpageType.emit(this.pageTypeelected);
  }

  clearpageType() {
    this.pageTypeelected = [];
    this.selectedpageType.emit([]);
  }

}
