import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrescreenQuestionsComponent } from './prescreen-questions.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertModule } from '../alert/alert.module';
import { ConfirmDialogService } from '../../../shared/services/confirm-dialog.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AlertModule
  ],
  declarations: [PrescreenQuestionsComponent],
  exports: [PrescreenQuestionsComponent],
  providers: [
    ConfirmDialogService
]
})
export class PrescreenQuestionsModule { }
