import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';

import { JobsiteLookupComponent } from './jobsite-lookup.component';
@NgModule({
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule
  ],
  declarations: [JobsiteLookupComponent],
  exports: [JobsiteLookupComponent],
  providers: []
})
export class JobsiteLookupModule { }
