import { Component, Output, EventEmitter, OnInit, Input } from '@angular/core';
import { WorkerShiftApi } from 'shared/sdk';
import { Observable } from 'rxjs';
import { CommonService } from 'shared/services/common.service';

@Component({
    selector: 'app-team-shift-lookup',
    templateUrl: './team-shift-lookup.component.html',
    styleUrls: ['./team-shift-lookup.component.css']
})

export class TeamShiftLookupComponent implements OnInit {

    @Input() placeholder = 'Team Shift';
    @Input() isdisabled = false;
    @Input() isMultipleSelected = true;

    @Input() set resetData(isClear) {
        this.onClear(isClear);
    }
    @Output() selectedValue: EventEmitter<any> = new EventEmitter;

    teamShiftOptionList: Array<any> = [];
    selectedTeamShift = [];

    constructor(
        private _commonService: CommonService
    ) { }

    ngOnInit() {
        // to show selected workers
        this._commonService.fetchTeamShiftData().subscribe((data) => {
            if (data && data.length > 0) {
                this.teamShiftOptionList = data;
            }
        },
        err => {
            console.log('error occured', err)
            this.teamShiftOptionList = [];
        });
        const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
        this.selectedTeamShift = (preselected && preselected['teamShifts']) ? preselected['teamShifts'] : '';
    }

    onChange() {
        let teamShiftIccCaseNumberList = [];
        this.selectedTeamShift.forEach(id => {
            teamShiftIccCaseNumberList.push(...this._commonService.teamShiftIccCaseNumber[id]);
        })
        this.selectedValue.emit({ teamShifts: this.selectedTeamShift, teamShiftIccCaseNumberList });
    }

    onClear(isClear) {
        if (isClear) {
            this.selectedTeamShift = [];
            this.selectedValue.emit([]);
        }
    }
}
