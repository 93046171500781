import { Component, OnInit, Input } from '@angular/core';
import { CaseApi } from 'shared/sdk';
import { PreloaderService } from 'shared/services/preloader.service';

@Component({
  selector: 'app-job-site',
  templateUrl: './job-site.component.html',
  styleUrls: ['./job-site.component.css']
})
export class JobSiteComponent implements OnInit {

  @Input() caseData;

  jobSiteData: any = {
    'Jobsite Detail': [],
    'Contact Information': [],
  }
  jobSiteInformation = [];
  contactInformation = [];
  error: String = '';


  constructor(
    private _caseApi: CaseApi,
    private _preloaderService: PreloaderService
  ) { }

  ngOnInit() {
    this.setUpJobSiteStructure();
    if (this.caseData) { this.getJobSiteData() }
  }
  setUpJobSiteStructure = (data?) => {
    this.jobSiteData = {
      'Jobsite Detail': [],
      'Contact Information': [],
    }
    // JOBSITE DETAILS
    // JOB SUMMARY
    this.jobSiteData['Jobsite Detail'].push({ 'name': 'Jobsite Name', 'value': data && data.Jobsite.Name });
    this.jobSiteData['Jobsite Detail'].push({ 'name': 'Type', 'value': data && data.Jobsite.Type__c });
    this.jobSiteData['Jobsite Detail'].push({ 'name': 'Account Name', 'value': data && data.Account && data.Account.Name });
    this.jobSiteData['Jobsite Detail'].push({ 'name': 'Jobsite ID', 'value': data && data.Jobsite.Jobsite_ID__c });
    this.jobSiteData['Jobsite Detail'].push({ 'name': 'Jobsite Request Status', 'value': data && data.Jobsite.Jobsite_Status__c });
    this.jobSiteData['Jobsite Detail'].push({
      'name': 'Service Zone',
      'value': data && data.Jobsite && data.Jobsite.GeoMetro && data.Jobsite.GeoMetro.Name || ''
    });
    this.jobSiteData['Jobsite Detail'].push({
      'name': 'Jobsite Approval Status',
      'value': data && data.Jobsite.Jobsite_Approval_Status__c
    });
    this.jobSiteData['Jobsite Detail'].push({
      'name': 'Jobsite Key Contact',
      'value': data && data.Jobsite && data.Jobsite.jobsiteKeyContact && data.Jobsite.jobsiteKeyContact.Contact_Name__c
    });
    this.jobSiteData['Jobsite Detail'].push({ 'name': 'Country', 'value': data && data.Jobsite.Country__c });
    this.jobSiteData['Jobsite Detail'].push({ 'name': 'State', 'value': data && data.Jobsite.State__c });
    this.jobSiteData['Jobsite Detail'].push({ 'name': 'City', 'value': data && data.Jobsite.City__c });
    this.jobSiteData['Jobsite Detail'].push({ 'name': 'Street', 'value': data && data.Jobsite.Street__c });
    this.jobSiteData['Jobsite Detail'].push({ 'name': 'Zip Code', 'value': data && data.Jobsite && data.Jobsite.Zip__c });

    // CONTACT INFORMATION
    // this.jobSiteData['Jobsite Detail'].push({ 'name': 'Customer Name', 'value': '' });
    // this.jobSiteData['Jobsite Detail'].push({ 'name': 'Customer Phone', 'value': '' });
    // this.jobSiteData['Jobsite Detail'].push({ 'name': 'Customer Help Desk Phone (Escalation 1)', 'value': '' });
    // this.jobSiteData['Jobsite Detail'].push({ 'name': 'IRON Service Global Help Desk Phone (Escalation 2)', 'value': '' });

    this.jobSiteInformation = this.jobSiteData['Jobsite Detail']
  }

  getJobSiteData = () => {
    this._preloaderService.showPreloader();
    if (this.caseData.iccCaseSfdcId) {
      this._caseApi.find({
        where: { 'sfdcId': this.caseData.iccCaseSfdcId },
        fields: ['id', 'sfdcId', 'Jobsite__c', 'AccountId'],
        include: [
          {
            relation: 'Jobsite',
            scope: {
              fields: [
                'id', 'sfdcId', 'Name',
                'Type__c', 'City__c',
                'State__c', 'Country__c',
                'Jobsite_Status__c',
                'Time_Zone__c',
                'Jobsite_Approval_Status__c',
                'Jobsite_Key_Contact__c',
                'Street__c', 'Jobsite_ID__c', 'Zip__c', 'GEO_Metro__c'
              ],
              include: [
                {
                  relation: 'jobsiteKeyContact',
                  scope: {
                    fields: ['Contact_Name__c']
                  }
                }, {
                  relation: 'GeoMetro',
                  scope: {
                    fields: ['Name']
                  }
                }
              ]
            }
          }, {
            relation: 'Account',
            scope: {
              fields: ['id', 'sfdcId', 'Account_Name__c', 'Name']
            }
          }
        ]
      }).subscribe(statusData => {
        this._preloaderService.hidePreloader();
        if (statusData && statusData.length) {
          this.setUpJobSiteStructure(statusData[0])
          // this.statusData = tempData;
        } else {
          this.error = 'No Data Found.'
        }
      }, err => {
        this._preloaderService.hidePreloader();
      })
    } else if (this.caseData.pmsCaseSfdcId) {
      this._preloaderService.showPreloader();
      this._caseApi.find({
        where: { 'sfdcId': this.caseData.pmsCaseSfdcId },
        fields: ['id', 'sfdcId', 'Job_Order__c'],
        include: [
          {
            relation: 'workOrder',
            scope: {
              fields: ['id', 'sfdcId', 'Name', 'Jobsite__c'],
              include: [
                {
                  relation: 'Jobsite',
                  scope: {
                    fields: [
                      'id', 'sfdcId', 'Name',
                      'Type__c', 'City__c',
                      'State__c', 'Country__c',
                      'Jobsite_Status__c',
                      'Time_Zone__c',
                      'Jobsite_Approval_Status__c',
                      'Jobsite_Key_Contact__c',
                      'Street__c', 'Account__c', 'Jobsite_ID__c', 'Zip__c', 'GEO_Metro__c'
                    ],
                    include: [
                      {
                        relation: 'account',
                        scope: {
                          fields: ['id', 'sfdcId', 'Name']
                        }
                      },
                      {
                        relation: 'jobsiteKeyContact',
                        scope: {
                          fields: ['Name']
                        }
                      }, {
                        relation: 'GeoMetro',
                        scope: {
                          fields: ['Name']
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }).subscribe(statusData => {
        this._preloaderService.hidePreloader();
        if (statusData && statusData.length) {
          statusData[0]['Jobsite'] = statusData[0]['workOrder'].Jobsite
          statusData[0]['Account'] = statusData[0]['workOrder'].Jobsite.account
          this.setUpJobSiteStructure(statusData[0])
          // this.statusData = tempData;
        } else {
          this.error = 'No Data Found.'
        }
      }, err => {
        this._preloaderService.hidePreloader();
      })
    }
  }

}
