import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { TaskApi } from 'shared/sdk';

@Component({
  selector: 'app-key-result-lookup',
  templateUrl: './key-result-lookup.component.html',
  styleUrls: ['./key-result-lookup.component.css']
})
export class KeyResultLookupComponent implements OnInit {

  private _initialized = false;
  public searchInput = new EventEmitter<string>();

  @Input() set resetData(isClear) {
    if (isClear) {
      this.selectedRecord = null;
    }
  }

  @Input() set setKeyResultObj(event) {
    this.selectedRecord = event;
    this.getSelectedRecordsList();
  }
  @Input() boxedLayout;

  @Input() set selectedObjective(event) {
    if(this._initialized) {
      if(event && event.length) {
        this.selectedRecord = [];
        this.recordList = [];
        this.getEventObj.emit([]);
        this.whereFilter['PgMO_Milestones__c'] = {inq: event};
      } else {
        if(this.whereFilter['PgMO_Milestones__c']) {
          delete this.whereFilter['PgMO_Milestones__c'];
        }
      }
    }    
  }

  @Input() isMultiSelect;
  @Input() placeholder = 'Key Result';
  @Output() getEventObj: EventEmitter<any> = new EventEmitter();
  recordList: any;
  itemsPerBatch = 50;
  notFoundText: string;
  orderBy = 'id DESC';
  maxSelectedItems = 1;
  private _andCondition = {or:[{PgMO_Parent_Task__c: null}, {PgMO_Parent_Task__c: ''}]};
  whereFilter: any = {PgMO_Projects__c: 'a6f2L0000004E3lQAE'};
  selectedRecord = null;

  constructor(private _recordsApi: TaskApi) { }

  ngOnInit() {
    this.preselectedRecord();
    this.getRecordsList();
    this.getSelectedRecordsList();
    this._initialized = true;
  }

  preselectedRecord() {
    const preSelected = localStorage.getItem('filterObj');
    if(!this.selectedRecord) {
      this.selectedRecord = JSON.parse(preSelected)['keyResult'];
    }
  }

  getSelectedRecordsList() {
    if (this.selectedRecord) {
      this.loadRecordsList().subscribe(
        x => {
          x.map(ele => { ele['Label'] = ele['Form_Name__c'] + ' (' + ele['Record_Number__c'] + ')' });
          this.recordList = x;
          this.notFoundText = (this.recordList.length === 0) ? 'No key result found.' : '';
        },
        err => {
          this.recordList = [];
        }
      )
    }
  }

  getRecordsList() {
    this.searchInput
      .pipe(
        filter(search => search && search.length > 3),
        debounceTime(200),
        distinctUntilChanged(),
        switchMap(search => this.loadRecordsList(search))
      ).subscribe(
        x => {
          x.map(ele => { ele['Label'] = ele['Form_Name__c'] + ' (' + ele['Record_Number__c'] + ')' });
          this.recordList = x;
          this.notFoundText = (this.recordList.length === 0) ? 'No key result found.' : '';
        },
        err => {
          console.log(err);
          this.recordList = [];
        }

      );
  }

  loadRecordsList(search?) {
    if (search) {
      const searchPattern = { like: '%' + search.trim() + '%', options: 'i' };
      if(this.whereFilter['and']) {
        delete this.whereFilter['and'];
      }
      this.whereFilter['and'] = [
        {or: [{Form_Name__c: searchPattern}, {Record_Number__c: searchPattern}]},
        this._andCondition
      ];
    }
    if (!search && this.selectedRecord && this.selectedRecord.length) {
      this.whereFilter['sfdcId'] = this.selectedRecord;
    } else if (this.whereFilter['sfdcId']) {
      delete this.whereFilter['sfdcId'];
    }

    const reqObj = {
      'fields': ['Record_Number__c', 'Form_Name__c', 'sfdcId'],
      'where': this.whereFilter,
      limit: this.itemsPerBatch,
      order: this.orderBy
    };
    return this._recordsApi.find(reqObj);
  }

  clearRecords() {
    this.selectedRecord = [];
    this.notFoundText = 'Type to search key result.';
    this.getEventObj.emit([]);
  }

  onRecordsChange() {
    this.getEventObj.emit(this.selectedRecord);
  }
}
