import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { JobApi } from 'shared/sdk';
import { AlertService } from 'shared/services/alert.service';
import { PreloaderService } from 'shared/services/preloader.service';
import { CommonService } from 'shared/services/common.service';
import { AuthService } from 'shared/services/authentication.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-job-survey-form',
  templateUrl: './job-survey-form.component.html',
  styleUrls: ['./job-survey-form.component.css']
})
export class JobSurveyFormComponent implements OnInit {
  @Input() jobId?: string;
  @Input() timeZone = '';
  @Output() updateData: EventEmitter<any> = new EventEmitter;
  errorMessage = '';
  jobData: any;
  templateList: any;
  formStatus: any;
  isLoaded = false;
  formName = '';
  userRole: any;
  roleSub: Subscription;
  allowSurveyAssign = false;
  token = '';

  tabsStatus: any = {
    assigendForms: true,
    assigenSurvey: true
  }

  constructor(
    private auth: AuthService,
    private _jobApi: JobApi,
    private _alertService: AlertService,
    private _commonService: CommonService,
    private _preloaderService: PreloaderService
  ) { }

  ngOnInit() {
    this.getJobData();
    this.auth.token$.subscribe(token => {
      this.token = token
    })
  }

  getJobData() {
    if (this.jobId) {
      const reqObj = {
        where: { sfdcId: this.jobId },
        fields: ['id', 'sfdcId', 'Iron_Job_num__c', 'Survey_Template', 'Survey_Link', 'Survey_Status', 'Dispatch_Worker_Name__c',
          'Survey_Created_Date', 'Survey_Due_Date', 'Job_Status_Internal__c'],
        include: [{
          relation: 'worker',
          scope: {
            'fields': ['Contact__c',
              'RecordTypeId',
              'Vendorsite__c',
              'id',
              'sfdcId',
              'Name',
              'Country_Code__c',
              'Email__c',
              'Dispatch_Worker_num__c',
              'Work_Phone_Number__c',
              'Primary_Worker_Skilling_Profile__c',
              'Worker_Type__c',
              'Worker_Rating__c']
          }
        }]
      }
      this._preloaderService.showPreloader();
      this.allowSurveyAssign = false;
      this._jobApi.getJobDetailsById(reqObj).subscribe(
        res => {
          if (res && res.id) {
            this.jobData = res;
            if (this.jobData && this.jobData.worker && this.jobData.id) {
              this.jobData.Survey_Created_Date =
                this._commonService.dateFormate(this.jobData.Survey_Created_Date, this.timeZone, 'L, LT z');
              if (this.jobData.Survey_Link && this.jobData.Survey_Link.length) {
                this.getStatus();
              } else {
                if (this.jobData.userRole === 'msp') {
                  this.allowSurveyAssign = true;
                  this.getTemplate();
                } else {
                  this.isLoaded = true;
                  this.errorMessage = 'No Survey Form Assigned'
                  this._preloaderService.hidePreloader();
                }
              }
            } else {
              this.isLoaded = true;
              this.errorMessage = 'Worker not assigned to this Job.';
              this._preloaderService.hidePreloader();
            }
          } else {
            this.isLoaded = true;
            this.errorMessage = 'Job Details not available';
          }
        },
        err => {
          this.isLoaded = true;
          this._alertService.error(err.message, true);
          this.errorMessage = 'Job Details not available';
          this._preloaderService.hidePreloader();
        }
      );
    } else {
      this.errorMessage = 'Job Data is not available';
    }
  }

  getStatus() {
    this.isLoaded = true;
    this._preloaderService.hidePreloader();
    const surveyData = {
      type: 'formStatus',
      data: {
        JobID: this.jobData.Iron_Job_num__c,
      }
    }
    this._jobApi.surveyRequest(surveyData).subscribe(
      res => {
        if (res && res.SuccessCode === 1000) {
          if (res.status !== this.jobData.Survey_Status) {
            this.jobData.Survey_Status = res.status;
          }
        } else {
          this._alertService.error(res.msg);
        }
        this.isLoaded = true;
        this._preloaderService.hidePreloader();
      },
      err => {
        this.isLoaded = true;
        this._preloaderService.hidePreloader();
        this._alertService.error(err.msg);
      })
  }

  createTicket() {

    this.isLoaded = true;
    this._preloaderService.hidePreloader();
    const surveyData = {
      type: 'createTicket',
      data: {
        JobID: this.jobData.Iron_Job_num__c,
        TemplateName: this.formName,
        FullTemplateName: this.templateList && this.templateList.length ? this.templateList.filter(item => item.TemplateName === this.formName)[0].FullTemplateName : '',
        WorkerId: this.jobData.Dispatch_Worker_Name__c,
        WorkerName: this.jobData && this.jobData.worker && this.jobData.worker.Name ? this.jobData.worker.Name : ''
      }
    }
    this._jobApi.surveyRequest(surveyData).subscribe(
      res => {
        if (res && res.SuccessCode === 1000) {
          this._alertService.success('Ticket Created Successfully');
          this.updateData.emit('sidebar');
        }
      },
      err => {
        console.log(err);
        this._alertService.error('Something Went Wrong');
      });
  }

  getTemplate() {
    this.isLoaded = true;
    this._preloaderService.hidePreloader();
    const surveyData = {
      type: 'getTemplate',
      data: {}
    }
    this._jobApi.surveyRequest(surveyData).subscribe(
      res => {
        this._preloaderService.hidePreloader();
        if (res && res.SuccessCode === 1000) {
          this.templateList = res.status;
        } else {
          this._alertService.error(res.msg)
        }
        this.isLoaded = true;
        this._preloaderService.hidePreloader();
      },
      err => {
        this.isLoaded = true;
        this._preloaderService.hidePreloader();
        this._alertService.error(err.msg)
      })
  }

  copyToClipboard(element) {
    element.select();
    document.execCommand('copy');
    element.setSelectionRange(0, 0);
  }
}
