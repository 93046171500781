/* tslint:disable */

declare var Object: any;
export interface PublicAccessInterface {
  "userName"?: string;
  "email"?: string;
  "model"?: string;
  "modelSfdcId"?: string;
  "modelDisplayData"?: string;
  "messageSent"?: boolean;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class PublicAccess implements PublicAccessInterface {
  "userName": string;
  "email": string;
  "model": string;
  "modelSfdcId": string;
  "modelDisplayData": string;
  "messageSent": boolean;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: PublicAccessInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `PublicAccess`.
   */
  public static getModelName() {
    return "PublicAccess";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of PublicAccess for dynamic purposes.
  **/
  public static factory(data: PublicAccessInterface): PublicAccess{
    return new PublicAccess(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'PublicAccess',
      plural: 'PublicAccesses',
      path: 'PublicAccesses',
      idName: 'id',
      properties: {
        "userName": {
          name: 'userName',
          type: 'string'
        },
        "email": {
          name: 'email',
          type: 'string'
        },
        "model": {
          name: 'model',
          type: 'string'
        },
        "modelSfdcId": {
          name: 'modelSfdcId',
          type: 'string'
        },
        "modelDisplayData": {
          name: 'modelDisplayData',
          type: 'string'
        },
        "messageSent": {
          name: 'messageSent',
          type: 'boolean',
          default: false
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
