import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EmployeeTypeValues, EmployeeTypeList } from '../../../models/static-list-data.service';

@Component({
  selector: 'app-employee-type-lookup',
  templateUrl: './employee-type-lookup.component.html',
  styleUrls: ['./employee-type-lookup.component.css']
})
export class EmployeeTypeLookupComponent implements OnInit {

  @Input() fromPage;
  @Input() set resetData(isClear) {
    if (isClear) {
      this.employeeTypeSelected = [];
    }
  }
  @Input() placeholder = 'Employee Type';
  // @Input() isdisabled = false;
  @Input() isMultipleSelected = true;
  @Input() viewType = '';
  @Output() getEmployeeTypeObj: EventEmitter<any> = new EventEmitter;
  employeeTypeOptionList = [];
  employeeTypeSelected = [];
  constructor() { }

  ngOnInit() {
    if (this.fromPage && this.fromPage === 'leave-approval-list')
      this.employeeTypeOptionList = EmployeeTypeList;
    else
      this.employeeTypeOptionList = EmployeeTypeValues;
    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
    this.employeeTypeSelected = (preselected && preselected['employeeType']) ? preselected['employeeType'] : (this.fromPage && this.fromPage === 'leave-approval-list' ? [] : '');
  }
  onemployeeTypeChange() {
    this.getEmployeeTypeObj.emit(this.employeeTypeSelected);
  }

  clearemployeeType() {
    this.employeeTypeSelected = [];
    this.getEmployeeTypeObj.emit([]);
  }

  ngAfterViewInit() {
    if (this.fromPage === 'leave-approval-list') {
      const employeeTypeLookupInput: any = document.querySelector('#employee_type_lookup .ng-input');
      const employeeTypeLookupArrow: any = document.querySelector('#employee_type_lookup .ng-arrow-wrapper');

      if (employeeTypeLookupInput) {
        employeeTypeLookupInput.style.top = '0';
        employeeTypeLookupInput.style.width = '90%';
      }
      if (employeeTypeLookupArrow)
        employeeTypeLookupArrow.style.bottom = '3px';
    }
  }

  changeStyleClear() {
    const employeeTypeLookupClear: any = document.querySelector('#employee_type_lookup .ng-clear-wrapper');
    if (employeeTypeLookupClear)
      employeeTypeLookupClear.style.bottom = '0';
  }
}
