/* tslint:disable */
import {
  Worker,
  Users,
  UserType,
  Account,
  PageViewMapping
} from '../index';

declare var Object: any;
export interface UserAnalyticsInterface {
  "UserId"?: number;
  "Account_Id"?: string;
  "Program_Id"?: string;
  "Contact"?: string;
  "IP_Address"?: string;
  "Page_Screen_From"?: string;
  "URL_From"?: string;
  "Page_Screens_To"?: number;
  "URL_To"?: string;
  "Access_Start_Date_Time"?: Date;
  "Access_End_Date_Time"?: Date;
  "Current_GeoLocation"?: string;
  "Duration"?: string;
  "Action"?: string;
  "userAccount"?: string;
  "userAccessType"?: string;
  "userTypeId"?: number;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  worker?: Worker;
  users?: Users;
  userType?: UserType;
  account?: Account;
  pageTo?: PageViewMapping;
}

export class UserAnalytics implements UserAnalyticsInterface {
  "UserId": number;
  "Account_Id": string;
  "Program_Id": string;
  "Contact": string;
  "IP_Address": string;
  "Page_Screen_From": string;
  "URL_From": string;
  "Page_Screens_To": number;
  "URL_To": string;
  "Access_Start_Date_Time": Date;
  "Access_End_Date_Time": Date;
  "Current_GeoLocation": string;
  "Duration": string;
  "Action": string;
  "userAccount": string;
  "userAccessType": string;
  "userTypeId": number;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  worker: Worker;
  users: Users;
  userType: UserType;
  account: Account;
  pageTo: PageViewMapping;
  constructor(data?: UserAnalyticsInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `UserAnalytics`.
   */
  public static getModelName() {
    return "UserAnalytics";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of UserAnalytics for dynamic purposes.
  **/
  public static factory(data: UserAnalyticsInterface): UserAnalytics{
    return new UserAnalytics(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'UserAnalytics',
      plural: 'UserAnalytics',
      path: 'UserAnalytics',
      idName: 'id',
      properties: {
        "UserId": {
          name: 'UserId',
          type: 'number'
        },
        "Account_Id": {
          name: 'Account_Id',
          type: 'string'
        },
        "Program_Id": {
          name: 'Program_Id',
          type: 'string'
        },
        "Contact": {
          name: 'Contact',
          type: 'string'
        },
        "IP_Address": {
          name: 'IP_Address',
          type: 'string'
        },
        "Page_Screen_From": {
          name: 'Page_Screen_From',
          type: 'string'
        },
        "URL_From": {
          name: 'URL_From',
          type: 'string'
        },
        "Page_Screens_To": {
          name: 'Page_Screens_To',
          type: 'number'
        },
        "URL_To": {
          name: 'URL_To',
          type: 'string'
        },
        "Access_Start_Date_Time": {
          name: 'Access_Start_Date_Time',
          type: 'Date'
        },
        "Access_End_Date_Time": {
          name: 'Access_End_Date_Time',
          type: 'Date'
        },
        "Current_GeoLocation": {
          name: 'Current_GeoLocation',
          type: 'string'
        },
        "Duration": {
          name: 'Duration',
          type: 'string'
        },
        "Action": {
          name: 'Action',
          type: 'string'
        },
        "userAccount": {
          name: 'userAccount',
          type: 'string'
        },
        "userAccessType": {
          name: 'userAccessType',
          type: 'string'
        },
        "userTypeId": {
          name: 'userTypeId',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        worker: {
          name: 'worker',
          type: 'Worker',
          model: 'Worker',
          relationType: 'belongsTo',
                  keyFrom: 'Contact',
          keyTo: 'Contact__c'
        },
        users: {
          name: 'users',
          type: 'Users',
          model: 'Users',
          relationType: 'belongsTo',
                  keyFrom: 'UserId',
          keyTo: 'id'
        },
        userType: {
          name: 'userType',
          type: 'UserType',
          model: 'UserType',
          relationType: 'belongsTo',
                  keyFrom: 'userTypeId',
          keyTo: 'id'
        },
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'userAccount',
          keyTo: 'sfdcId'
        },
        pageTo: {
          name: 'pageTo',
          type: 'PageViewMapping',
          model: 'PageViewMapping',
          relationType: 'belongsTo',
                  keyFrom: 'Page_Screens_To',
          keyTo: 'id'
        },
      }
    }
  }
}
