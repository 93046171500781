/* tslint:disable */
import {
  Project,
  Account,
  Case,
  Jobsite,
  Contact,
  Appointment,
  PurchaseOrder,
  JobOrderItem,
  RecordType,
  RequestFormReceipt,
  Asset,
  ServiceContract,
  ServiceContractLineItem,
  RequestorDepartment,
  RequestFormReceiptEscalation
} from '../index';

declare var Object: any;
export interface WorkOrderInterface {
  "sfdcId"?: string;
  "Acknowledgement_Sent__c"?: string;
  "Actual_Shipping_Date__c"?: Date;
  "Appointment__c"?: string;
  "Asset_Serial_Number__c"?: string;
  "Automation_Status__c"?: string;
  "B2B_Service_Category_Type__c"?: string;
  "B2B_Submission__c"?: boolean;
  "Bill_To_Party__c"?: string;
  "Billing_Type__c"?: string;
  "Carrier_Instructions__c"?: string;
  "Carrier_Scac__c"?: string;
  "Case_Event_Priority_Level__c"?: string;
  "Cloned__c"?: boolean;
  "Committed_Shipping_Date__c"?: Date;
  "Count_of_WO_Items__c"?: number;
  "Create_ICC_Case__c"?: boolean;
  "Create_PMS_PMC_Case__c"?: boolean;
  "CreatedById"?: string;
  "CreatedDate"?: Date;
  "Created_by_Contact__c"?: string;
  "CurrencyIsoCode"?: string;
  "Current_Status__c"?: string;
  "Custom_work_order_instructions__c"?: string;
  "Customer_Account__c"?: string;
  "Customer_PO_Number__c"?: string;
  "Customer_Service_Type_WO__c"?: string;
  "Customs_Broker__c"?: string;
  "Cycle_Time_WO_Completion__c"?: Date;
  "Cycle_Time_WO_Dispatching__c"?: number;
  "Deliverable__c"?: string;
  "Delivery_Date__c"?: Date;
  "Description__c"?: string;
  "EOR_Party__c"?: string;
  "End_User__c"?: string;
  "Expected_Shipping_Date__c"?: Date;
  "Extended_Amount_Excl_Tax__c"?: number;
  "FTE_Configuration_Type__c"?: string;
  "Has_Job_Schedule__c"?: boolean;
  "Has_Partner__c"?: boolean;
  "Has_Product_TT_JS_on_Item__c"?: number;
  "Has_Project__c"?: boolean;
  "Has_SC_Line_Item__c"?: boolean;
  "Has_SC__c"?: boolean;
  "Health_Appointment_status__c"?: string;
  "Health_Customer_Price_Available__c"?: string;
  "Health_Pricelist_Status__c"?: string;
  "Health_SOP_Profile_Status__c"?: string;
  "Health_Vendor_Price_Available__c"?: string;
  "ICC_Case_Created_Date_Time__c"?: Date;
  "ICC_Case_Created__c"?: boolean;
  "ICC_Case__c"?: string;
  "IOR_Party__c"?: string;
  "Importer_IOR__c"?: string;
  "Instructions__c"?: string;
  "Insurance_Party__c"?: string;
  "International_Shipment__c"?: string;
  "Inventory_Pool__c"?: string;
  "Iron_Sales_Order__c"?: string;
  "Iron_Service_Contract__c"?: string;
  "IsDeleted"?: boolean;
  "JPC_Case__c"?: string;
  "Job_Instructions_Deliverables_Summary__c"?: string;
  "Job_Instructions_Required_Tools__c"?: string;
  "Job_Instructions_Service_Deliverables__c"?: string;
  "Job_Instructions_Special_Instructions__c"?: string;
  "Job_Instructions_Training_Documents__c"?: string;
  "Job_Schedule__c"?: string;
  "Jobsite_Project__c"?: string;
  "Jobsite__c"?: string;
  "LastModifiedById"?: string;
  "LastModifiedDate"?: Date;
  "LastReferencedDate"?: Date;
  "LastViewedDate"?: Date;
  "Logic_App_Run_ID__c"?: string;
  "Name"?: string;
  "OpsChain_Order_Created_Date__c"?: Date;
  "OpsChain_Order_Number__c"?: string;
  "Order_Confirmation__c"?: string;
  "Order_Sent_to_Lots__c"?: boolean;
  "Overall_Health__c"?: boolean;
  "PMS_Case__c"?: string;
  "PMS_PMC_Case_Created__c"?: boolean;
  "Partner_Case_Error_Description__c"?: string;
  "Partner_Case_Number__c"?: string;
  "Partner_FRU_Sku__c"?: string;
  "Partner_Order_Notes__c"?: string;
  "Partner_PO_Number__c"?: string;
  "Partner_Pricelist_from_SOP_Auto__c"?: string;
  "Partner_Reference_Number__c"?: string;
  "Partner__c"?: string;
  "Payment_Term__c"?: string;
  "Primary_SKU__c"?: string;
  "Project_Profile__c"?: string;
  "Project_SOP__c"?: string;
  "Ready_for_Case_Creation__c"?: boolean;
  "RecordTypeId"?: string;
  "RecordTypeId__c"?: string;
  "Request_Form_Receipt__c"?: string;
  "Requested_Shipping_Date__c"?: Date;
  "SC_EndDate__c"?: Date;
  "SC_StartDate__c"?: Date;
  "Schedule_Type_Weekly_Monthly__c"?: string;
  "Schedule__c"?: string;
  "Send_to_OpsChain__c"?: boolean;
  "Service_Category_Type__c"?: string;
  "Service_Contract_Job_Schedule_Count__c"?: number;
  "Service_Contract_Line_Item__c"?: string;
  "Service_Contract__c"?: string;
  "Service_Dispatch_Frequency__c"?: string;
  "Serviceo_Case_Number__c"?: string;
  "Ship_Mode__c"?: string;
  "Ship_Partial__c"?: string;
  "Ship_To_Party__c"?: string;
  "Sold_To_Party__c"?: string;
  "Special_Instructions__c"?: string;
  "Special_Terms__c"?: string;
  "Standard_Project_on_Program__c"?: boolean;
  "Status__c"?: string;
  "Tentative_Shipping_Date__c"?: Date;
  "Term_months__c"?: number;
  "Total_Amount__c"?: number;
  "Total_Cost_Vendor_PreTax_Rollup__c"?: number;
  "Total_Price_Customer_PreTax_Rollup__c"?: number;
  "Type_Facebook_Ticket_Type__c"?: string;
  "User_Name__c"?: string;
  "VAT_Payee__c"?: string;
  "VAT_payee_account__c"?: string;
  "Vendor_Override__c"?: string;
  "WO_Id__c"?: string;
  "Work_Description_Long__c"?: string;
  "Work_Description__c"?: string;
  "Work_Order_Approval_Time__c"?: number;
  "Work_Order_Approved_Date_Time__c"?: Date;
  "Work_Order_Completed_Date_Time__c"?: Date;
  "Work_Order_Created_Date_Time__c"?: Date;
  "Work_Order_End_Date_Time__c"?: Date;
  "Work_Order_End_Date__c"?: Date;
  "Work_Order_Item__c"?: string;
  "Work_Order_Start_Date_Time__c"?: Date;
  "Work_Order_Start_Date__c"?: Date;
  "Work_Order__c"?: string;
  "Work_Summary__c"?: string;
  "X1_Hours_minus_Created__c"?: Date;
  "has_description__c"?: boolean;
  "has_summary__c"?: boolean;
  "incoterm__c"?: string;
  "Request_Form_Type__c"?: string;
  "Has_Backfill_Worker__c"?: boolean;
  "WorkOrder_Subject__c"?: string;
  "Asset_Idntification__c"?: string;
  "Asset_Tag_Number__c"?: string;
  "Asset_Serial_Number_Text__c"?: string;
  "Asset_Software_Revision__c"?: string;
  "Appointment_Auto_created__c"?: boolean;
  "Health_Job_Schedule_Status__c"?: string;
  "Case_Type_Internal_Status__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  JobsiteProject?: Project;
  partner?: Account;
  program?: Project;
  Case?: Case;
  Jobsite?: Jobsite;
  contact?: Contact;
  jpcCase?: Case;
  appointment?: Appointment;
  billToParty?: Account;
  customerAccount?: Account;
  endUser?: Account;
  purchaseOrder?: PurchaseOrder;
  jobOrderItems?: JobOrderItem[];
  RecordType?: RecordType;
  RequestFormReceipt?: RequestFormReceipt;
  asset?: Asset;
  serviceContract?: ServiceContract;
  contractService?: ServiceContract;
  serviceContractLineItem?: ServiceContractLineItem;
  requestorDepartment?: RequestorDepartment[];
  requestFormReceiptEscalation?: RequestFormReceiptEscalation[];
}

export class WorkOrder implements WorkOrderInterface {
  "sfdcId": string;
  "Acknowledgement_Sent__c": string;
  "Actual_Shipping_Date__c": Date;
  "Appointment__c": string;
  "Asset_Serial_Number__c": string;
  "Automation_Status__c": string;
  "B2B_Service_Category_Type__c": string;
  "B2B_Submission__c": boolean;
  "Bill_To_Party__c": string;
  "Billing_Type__c": string;
  "Carrier_Instructions__c": string;
  "Carrier_Scac__c": string;
  "Case_Event_Priority_Level__c": string;
  "Cloned__c": boolean;
  "Committed_Shipping_Date__c": Date;
  "Count_of_WO_Items__c": number;
  "Create_ICC_Case__c": boolean;
  "Create_PMS_PMC_Case__c": boolean;
  "CreatedById": string;
  "CreatedDate": Date;
  "Created_by_Contact__c": string;
  "CurrencyIsoCode": string;
  "Current_Status__c": string;
  "Custom_work_order_instructions__c": string;
  "Customer_Account__c": string;
  "Customer_PO_Number__c": string;
  "Customer_Service_Type_WO__c": string;
  "Customs_Broker__c": string;
  "Cycle_Time_WO_Completion__c": Date;
  "Cycle_Time_WO_Dispatching__c": number;
  "Deliverable__c": string;
  "Delivery_Date__c": Date;
  "Description__c": string;
  "EOR_Party__c": string;
  "End_User__c": string;
  "Expected_Shipping_Date__c": Date;
  "Extended_Amount_Excl_Tax__c": number;
  "FTE_Configuration_Type__c": string;
  "Has_Job_Schedule__c": boolean;
  "Has_Partner__c": boolean;
  "Has_Product_TT_JS_on_Item__c": number;
  "Has_Project__c": boolean;
  "Has_SC_Line_Item__c": boolean;
  "Has_SC__c": boolean;
  "Health_Appointment_status__c": string;
  "Health_Customer_Price_Available__c": string;
  "Health_Pricelist_Status__c": string;
  "Health_SOP_Profile_Status__c": string;
  "Health_Vendor_Price_Available__c": string;
  "ICC_Case_Created_Date_Time__c": Date;
  "ICC_Case_Created__c": boolean;
  "ICC_Case__c": string;
  "IOR_Party__c": string;
  "Importer_IOR__c": string;
  "Instructions__c": string;
  "Insurance_Party__c": string;
  "International_Shipment__c": string;
  "Inventory_Pool__c": string;
  "Iron_Sales_Order__c": string;
  "Iron_Service_Contract__c": string;
  "IsDeleted": boolean;
  "JPC_Case__c": string;
  "Job_Instructions_Deliverables_Summary__c": string;
  "Job_Instructions_Required_Tools__c": string;
  "Job_Instructions_Service_Deliverables__c": string;
  "Job_Instructions_Special_Instructions__c": string;
  "Job_Instructions_Training_Documents__c": string;
  "Job_Schedule__c": string;
  "Jobsite_Project__c": string;
  "Jobsite__c": string;
  "LastModifiedById": string;
  "LastModifiedDate": Date;
  "LastReferencedDate": Date;
  "LastViewedDate": Date;
  "Logic_App_Run_ID__c": string;
  "Name": string;
  "OpsChain_Order_Created_Date__c": Date;
  "OpsChain_Order_Number__c": string;
  "Order_Confirmation__c": string;
  "Order_Sent_to_Lots__c": boolean;
  "Overall_Health__c": boolean;
  "PMS_Case__c": string;
  "PMS_PMC_Case_Created__c": boolean;
  "Partner_Case_Error_Description__c": string;
  "Partner_Case_Number__c": string;
  "Partner_FRU_Sku__c": string;
  "Partner_Order_Notes__c": string;
  "Partner_PO_Number__c": string;
  "Partner_Pricelist_from_SOP_Auto__c": string;
  "Partner_Reference_Number__c": string;
  "Partner__c": string;
  "Payment_Term__c": string;
  "Primary_SKU__c": string;
  "Project_Profile__c": string;
  "Project_SOP__c": string;
  "Ready_for_Case_Creation__c": boolean;
  "RecordTypeId": string;
  "RecordTypeId__c": string;
  "Request_Form_Receipt__c": string;
  "Requested_Shipping_Date__c": Date;
  "SC_EndDate__c": Date;
  "SC_StartDate__c": Date;
  "Schedule_Type_Weekly_Monthly__c": string;
  "Schedule__c": string;
  "Send_to_OpsChain__c": boolean;
  "Service_Category_Type__c": string;
  "Service_Contract_Job_Schedule_Count__c": number;
  "Service_Contract_Line_Item__c": string;
  "Service_Contract__c": string;
  "Service_Dispatch_Frequency__c": string;
  "Serviceo_Case_Number__c": string;
  "Ship_Mode__c": string;
  "Ship_Partial__c": string;
  "Ship_To_Party__c": string;
  "Sold_To_Party__c": string;
  "Special_Instructions__c": string;
  "Special_Terms__c": string;
  "Standard_Project_on_Program__c": boolean;
  "Status__c": string;
  "Tentative_Shipping_Date__c": Date;
  "Term_months__c": number;
  "Total_Amount__c": number;
  "Total_Cost_Vendor_PreTax_Rollup__c": number;
  "Total_Price_Customer_PreTax_Rollup__c": number;
  "Type_Facebook_Ticket_Type__c": string;
  "User_Name__c": string;
  "VAT_Payee__c": string;
  "VAT_payee_account__c": string;
  "Vendor_Override__c": string;
  "WO_Id__c": string;
  "Work_Description_Long__c": string;
  "Work_Description__c": string;
  "Work_Order_Approval_Time__c": number;
  "Work_Order_Approved_Date_Time__c": Date;
  "Work_Order_Completed_Date_Time__c": Date;
  "Work_Order_Created_Date_Time__c": Date;
  "Work_Order_End_Date_Time__c": Date;
  "Work_Order_End_Date__c": Date;
  "Work_Order_Item__c": string;
  "Work_Order_Start_Date_Time__c": Date;
  "Work_Order_Start_Date__c": Date;
  "Work_Order__c": string;
  "Work_Summary__c": string;
  "X1_Hours_minus_Created__c": Date;
  "has_description__c": boolean;
  "has_summary__c": boolean;
  "incoterm__c": string;
  "Request_Form_Type__c": string;
  "Has_Backfill_Worker__c": boolean;
  "WorkOrder_Subject__c": string;
  "Asset_Idntification__c": string;
  "Asset_Tag_Number__c": string;
  "Asset_Serial_Number_Text__c": string;
  "Asset_Software_Revision__c": string;
  "Appointment_Auto_created__c": boolean;
  "Health_Job_Schedule_Status__c": string;
  "Case_Type_Internal_Status__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  JobsiteProject: Project;
  partner: Account;
  program: Project;
  Case: Case;
  Jobsite: Jobsite;
  contact: Contact;
  jpcCase: Case;
  appointment: Appointment;
  billToParty: Account;
  customerAccount: Account;
  endUser: Account;
  purchaseOrder: PurchaseOrder;
  jobOrderItems: JobOrderItem[];
  RecordType: RecordType;
  RequestFormReceipt: RequestFormReceipt;
  asset: Asset;
  serviceContract: ServiceContract;
  contractService: ServiceContract;
  serviceContractLineItem: ServiceContractLineItem;
  requestorDepartment: RequestorDepartment[];
  requestFormReceiptEscalation: RequestFormReceiptEscalation[];
  constructor(data?: WorkOrderInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `WorkOrder`.
   */
  public static getModelName() {
    return "WorkOrder";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of WorkOrder for dynamic purposes.
  **/
  public static factory(data: WorkOrderInterface): WorkOrder{
    return new WorkOrder(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'WorkOrder',
      plural: 'WorkOrders',
      path: 'WorkOrders',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "Acknowledgement_Sent__c": {
          name: 'Acknowledgement_Sent__c',
          type: 'string'
        },
        "Actual_Shipping_Date__c": {
          name: 'Actual_Shipping_Date__c',
          type: 'Date'
        },
        "Appointment__c": {
          name: 'Appointment__c',
          type: 'string'
        },
        "Asset_Serial_Number__c": {
          name: 'Asset_Serial_Number__c',
          type: 'string'
        },
        "Automation_Status__c": {
          name: 'Automation_Status__c',
          type: 'string'
        },
        "B2B_Service_Category_Type__c": {
          name: 'B2B_Service_Category_Type__c',
          type: 'string'
        },
        "B2B_Submission__c": {
          name: 'B2B_Submission__c',
          type: 'boolean'
        },
        "Bill_To_Party__c": {
          name: 'Bill_To_Party__c',
          type: 'string'
        },
        "Billing_Type__c": {
          name: 'Billing_Type__c',
          type: 'string'
        },
        "Carrier_Instructions__c": {
          name: 'Carrier_Instructions__c',
          type: 'string'
        },
        "Carrier_Scac__c": {
          name: 'Carrier_Scac__c',
          type: 'string'
        },
        "Case_Event_Priority_Level__c": {
          name: 'Case_Event_Priority_Level__c',
          type: 'string'
        },
        "Cloned__c": {
          name: 'Cloned__c',
          type: 'boolean'
        },
        "Committed_Shipping_Date__c": {
          name: 'Committed_Shipping_Date__c',
          type: 'Date'
        },
        "Count_of_WO_Items__c": {
          name: 'Count_of_WO_Items__c',
          type: 'number'
        },
        "Create_ICC_Case__c": {
          name: 'Create_ICC_Case__c',
          type: 'boolean'
        },
        "Create_PMS_PMC_Case__c": {
          name: 'Create_PMS_PMC_Case__c',
          type: 'boolean'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "Created_by_Contact__c": {
          name: 'Created_by_Contact__c',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "Current_Status__c": {
          name: 'Current_Status__c',
          type: 'string'
        },
        "Custom_work_order_instructions__c": {
          name: 'Custom_work_order_instructions__c',
          type: 'string'
        },
        "Customer_Account__c": {
          name: 'Customer_Account__c',
          type: 'string'
        },
        "Customer_PO_Number__c": {
          name: 'Customer_PO_Number__c',
          type: 'string'
        },
        "Customer_Service_Type_WO__c": {
          name: 'Customer_Service_Type_WO__c',
          type: 'string'
        },
        "Customs_Broker__c": {
          name: 'Customs_Broker__c',
          type: 'string'
        },
        "Cycle_Time_WO_Completion__c": {
          name: 'Cycle_Time_WO_Completion__c',
          type: 'Date'
        },
        "Cycle_Time_WO_Dispatching__c": {
          name: 'Cycle_Time_WO_Dispatching__c',
          type: 'number'
        },
        "Deliverable__c": {
          name: 'Deliverable__c',
          type: 'string'
        },
        "Delivery_Date__c": {
          name: 'Delivery_Date__c',
          type: 'Date'
        },
        "Description__c": {
          name: 'Description__c',
          type: 'string'
        },
        "EOR_Party__c": {
          name: 'EOR_Party__c',
          type: 'string'
        },
        "End_User__c": {
          name: 'End_User__c',
          type: 'string'
        },
        "Expected_Shipping_Date__c": {
          name: 'Expected_Shipping_Date__c',
          type: 'Date'
        },
        "Extended_Amount_Excl_Tax__c": {
          name: 'Extended_Amount_Excl_Tax__c',
          type: 'number'
        },
        "FTE_Configuration_Type__c": {
          name: 'FTE_Configuration_Type__c',
          type: 'string'
        },
        "Has_Job_Schedule__c": {
          name: 'Has_Job_Schedule__c',
          type: 'boolean'
        },
        "Has_Partner__c": {
          name: 'Has_Partner__c',
          type: 'boolean'
        },
        "Has_Product_TT_JS_on_Item__c": {
          name: 'Has_Product_TT_JS_on_Item__c',
          type: 'number'
        },
        "Has_Project__c": {
          name: 'Has_Project__c',
          type: 'boolean'
        },
        "Has_SC_Line_Item__c": {
          name: 'Has_SC_Line_Item__c',
          type: 'boolean'
        },
        "Has_SC__c": {
          name: 'Has_SC__c',
          type: 'boolean'
        },
        "Health_Appointment_status__c": {
          name: 'Health_Appointment_status__c',
          type: 'string'
        },
        "Health_Customer_Price_Available__c": {
          name: 'Health_Customer_Price_Available__c',
          type: 'string'
        },
        "Health_Pricelist_Status__c": {
          name: 'Health_Pricelist_Status__c',
          type: 'string'
        },
        "Health_SOP_Profile_Status__c": {
          name: 'Health_SOP_Profile_Status__c',
          type: 'string'
        },
        "Health_Vendor_Price_Available__c": {
          name: 'Health_Vendor_Price_Available__c',
          type: 'string'
        },
        "ICC_Case_Created_Date_Time__c": {
          name: 'ICC_Case_Created_Date_Time__c',
          type: 'Date'
        },
        "ICC_Case_Created__c": {
          name: 'ICC_Case_Created__c',
          type: 'boolean'
        },
        "ICC_Case__c": {
          name: 'ICC_Case__c',
          type: 'string'
        },
        "IOR_Party__c": {
          name: 'IOR_Party__c',
          type: 'string'
        },
        "Importer_IOR__c": {
          name: 'Importer_IOR__c',
          type: 'string'
        },
        "Instructions__c": {
          name: 'Instructions__c',
          type: 'string'
        },
        "Insurance_Party__c": {
          name: 'Insurance_Party__c',
          type: 'string'
        },
        "International_Shipment__c": {
          name: 'International_Shipment__c',
          type: 'string'
        },
        "Inventory_Pool__c": {
          name: 'Inventory_Pool__c',
          type: 'string'
        },
        "Iron_Sales_Order__c": {
          name: 'Iron_Sales_Order__c',
          type: 'string'
        },
        "Iron_Service_Contract__c": {
          name: 'Iron_Service_Contract__c',
          type: 'string'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "JPC_Case__c": {
          name: 'JPC_Case__c',
          type: 'string'
        },
        "Job_Instructions_Deliverables_Summary__c": {
          name: 'Job_Instructions_Deliverables_Summary__c',
          type: 'string'
        },
        "Job_Instructions_Required_Tools__c": {
          name: 'Job_Instructions_Required_Tools__c',
          type: 'string'
        },
        "Job_Instructions_Service_Deliverables__c": {
          name: 'Job_Instructions_Service_Deliverables__c',
          type: 'string'
        },
        "Job_Instructions_Special_Instructions__c": {
          name: 'Job_Instructions_Special_Instructions__c',
          type: 'string'
        },
        "Job_Instructions_Training_Documents__c": {
          name: 'Job_Instructions_Training_Documents__c',
          type: 'string'
        },
        "Job_Schedule__c": {
          name: 'Job_Schedule__c',
          type: 'string'
        },
        "Jobsite_Project__c": {
          name: 'Jobsite_Project__c',
          type: 'string'
        },
        "Jobsite__c": {
          name: 'Jobsite__c',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "LastReferencedDate": {
          name: 'LastReferencedDate',
          type: 'Date'
        },
        "LastViewedDate": {
          name: 'LastViewedDate',
          type: 'Date'
        },
        "Logic_App_Run_ID__c": {
          name: 'Logic_App_Run_ID__c',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "OpsChain_Order_Created_Date__c": {
          name: 'OpsChain_Order_Created_Date__c',
          type: 'Date'
        },
        "OpsChain_Order_Number__c": {
          name: 'OpsChain_Order_Number__c',
          type: 'string'
        },
        "Order_Confirmation__c": {
          name: 'Order_Confirmation__c',
          type: 'string'
        },
        "Order_Sent_to_Lots__c": {
          name: 'Order_Sent_to_Lots__c',
          type: 'boolean'
        },
        "Overall_Health__c": {
          name: 'Overall_Health__c',
          type: 'boolean'
        },
        "PMS_Case__c": {
          name: 'PMS_Case__c',
          type: 'string'
        },
        "PMS_PMC_Case_Created__c": {
          name: 'PMS_PMC_Case_Created__c',
          type: 'boolean'
        },
        "Partner_Case_Error_Description__c": {
          name: 'Partner_Case_Error_Description__c',
          type: 'string'
        },
        "Partner_Case_Number__c": {
          name: 'Partner_Case_Number__c',
          type: 'string'
        },
        "Partner_FRU_Sku__c": {
          name: 'Partner_FRU_Sku__c',
          type: 'string'
        },
        "Partner_Order_Notes__c": {
          name: 'Partner_Order_Notes__c',
          type: 'string'
        },
        "Partner_PO_Number__c": {
          name: 'Partner_PO_Number__c',
          type: 'string'
        },
        "Partner_Pricelist_from_SOP_Auto__c": {
          name: 'Partner_Pricelist_from_SOP_Auto__c',
          type: 'string'
        },
        "Partner_Reference_Number__c": {
          name: 'Partner_Reference_Number__c',
          type: 'string'
        },
        "Partner__c": {
          name: 'Partner__c',
          type: 'string'
        },
        "Payment_Term__c": {
          name: 'Payment_Term__c',
          type: 'string'
        },
        "Primary_SKU__c": {
          name: 'Primary_SKU__c',
          type: 'string'
        },
        "Project_Profile__c": {
          name: 'Project_Profile__c',
          type: 'string'
        },
        "Project_SOP__c": {
          name: 'Project_SOP__c',
          type: 'string'
        },
        "Ready_for_Case_Creation__c": {
          name: 'Ready_for_Case_Creation__c',
          type: 'boolean'
        },
        "RecordTypeId": {
          name: 'RecordTypeId',
          type: 'string'
        },
        "RecordTypeId__c": {
          name: 'RecordTypeId__c',
          type: 'string'
        },
        "Request_Form_Receipt__c": {
          name: 'Request_Form_Receipt__c',
          type: 'string'
        },
        "Requested_Shipping_Date__c": {
          name: 'Requested_Shipping_Date__c',
          type: 'Date'
        },
        "SC_EndDate__c": {
          name: 'SC_EndDate__c',
          type: 'Date'
        },
        "SC_StartDate__c": {
          name: 'SC_StartDate__c',
          type: 'Date'
        },
        "Schedule_Type_Weekly_Monthly__c": {
          name: 'Schedule_Type_Weekly_Monthly__c',
          type: 'string'
        },
        "Schedule__c": {
          name: 'Schedule__c',
          type: 'string'
        },
        "Send_to_OpsChain__c": {
          name: 'Send_to_OpsChain__c',
          type: 'boolean'
        },
        "Service_Category_Type__c": {
          name: 'Service_Category_Type__c',
          type: 'string'
        },
        "Service_Contract_Job_Schedule_Count__c": {
          name: 'Service_Contract_Job_Schedule_Count__c',
          type: 'number'
        },
        "Service_Contract_Line_Item__c": {
          name: 'Service_Contract_Line_Item__c',
          type: 'string'
        },
        "Service_Contract__c": {
          name: 'Service_Contract__c',
          type: 'string'
        },
        "Service_Dispatch_Frequency__c": {
          name: 'Service_Dispatch_Frequency__c',
          type: 'string'
        },
        "Serviceo_Case_Number__c": {
          name: 'Serviceo_Case_Number__c',
          type: 'string'
        },
        "Ship_Mode__c": {
          name: 'Ship_Mode__c',
          type: 'string'
        },
        "Ship_Partial__c": {
          name: 'Ship_Partial__c',
          type: 'string'
        },
        "Ship_To_Party__c": {
          name: 'Ship_To_Party__c',
          type: 'string'
        },
        "Sold_To_Party__c": {
          name: 'Sold_To_Party__c',
          type: 'string'
        },
        "Special_Instructions__c": {
          name: 'Special_Instructions__c',
          type: 'string'
        },
        "Special_Terms__c": {
          name: 'Special_Terms__c',
          type: 'string'
        },
        "Standard_Project_on_Program__c": {
          name: 'Standard_Project_on_Program__c',
          type: 'boolean'
        },
        "Status__c": {
          name: 'Status__c',
          type: 'string'
        },
        "Tentative_Shipping_Date__c": {
          name: 'Tentative_Shipping_Date__c',
          type: 'Date'
        },
        "Term_months__c": {
          name: 'Term_months__c',
          type: 'number'
        },
        "Total_Amount__c": {
          name: 'Total_Amount__c',
          type: 'number'
        },
        "Total_Cost_Vendor_PreTax_Rollup__c": {
          name: 'Total_Cost_Vendor_PreTax_Rollup__c',
          type: 'number'
        },
        "Total_Price_Customer_PreTax_Rollup__c": {
          name: 'Total_Price_Customer_PreTax_Rollup__c',
          type: 'number'
        },
        "Type_Facebook_Ticket_Type__c": {
          name: 'Type_Facebook_Ticket_Type__c',
          type: 'string'
        },
        "User_Name__c": {
          name: 'User_Name__c',
          type: 'string'
        },
        "VAT_Payee__c": {
          name: 'VAT_Payee__c',
          type: 'string'
        },
        "VAT_payee_account__c": {
          name: 'VAT_payee_account__c',
          type: 'string'
        },
        "Vendor_Override__c": {
          name: 'Vendor_Override__c',
          type: 'string'
        },
        "WO_Id__c": {
          name: 'WO_Id__c',
          type: 'string'
        },
        "Work_Description_Long__c": {
          name: 'Work_Description_Long__c',
          type: 'string'
        },
        "Work_Description__c": {
          name: 'Work_Description__c',
          type: 'string'
        },
        "Work_Order_Approval_Time__c": {
          name: 'Work_Order_Approval_Time__c',
          type: 'number'
        },
        "Work_Order_Approved_Date_Time__c": {
          name: 'Work_Order_Approved_Date_Time__c',
          type: 'Date'
        },
        "Work_Order_Completed_Date_Time__c": {
          name: 'Work_Order_Completed_Date_Time__c',
          type: 'Date'
        },
        "Work_Order_Created_Date_Time__c": {
          name: 'Work_Order_Created_Date_Time__c',
          type: 'Date'
        },
        "Work_Order_End_Date_Time__c": {
          name: 'Work_Order_End_Date_Time__c',
          type: 'Date'
        },
        "Work_Order_End_Date__c": {
          name: 'Work_Order_End_Date__c',
          type: 'Date'
        },
        "Work_Order_Item__c": {
          name: 'Work_Order_Item__c',
          type: 'string'
        },
        "Work_Order_Start_Date_Time__c": {
          name: 'Work_Order_Start_Date_Time__c',
          type: 'Date'
        },
        "Work_Order_Start_Date__c": {
          name: 'Work_Order_Start_Date__c',
          type: 'Date'
        },
        "Work_Order__c": {
          name: 'Work_Order__c',
          type: 'string'
        },
        "Work_Summary__c": {
          name: 'Work_Summary__c',
          type: 'string'
        },
        "X1_Hours_minus_Created__c": {
          name: 'X1_Hours_minus_Created__c',
          type: 'Date'
        },
        "has_description__c": {
          name: 'has_description__c',
          type: 'boolean'
        },
        "has_summary__c": {
          name: 'has_summary__c',
          type: 'boolean'
        },
        "incoterm__c": {
          name: 'incoterm__c',
          type: 'string'
        },
        "Request_Form_Type__c": {
          name: 'Request_Form_Type__c',
          type: 'string'
        },
        "Has_Backfill_Worker__c": {
          name: 'Has_Backfill_Worker__c',
          type: 'boolean'
        },
        "WorkOrder_Subject__c": {
          name: 'WorkOrder_Subject__c',
          type: 'string'
        },
        "Asset_Idntification__c": {
          name: 'Asset_Idntification__c',
          type: 'string'
        },
        "Asset_Tag_Number__c": {
          name: 'Asset_Tag_Number__c',
          type: 'string'
        },
        "Asset_Serial_Number_Text__c": {
          name: 'Asset_Serial_Number_Text__c',
          type: 'string'
        },
        "Asset_Software_Revision__c": {
          name: 'Asset_Software_Revision__c',
          type: 'string'
        },
        "Appointment_Auto_created__c": {
          name: 'Appointment_Auto_created__c',
          type: 'boolean'
        },
        "Health_Job_Schedule_Status__c": {
          name: 'Health_Job_Schedule_Status__c',
          type: 'string'
        },
        "Case_Type_Internal_Status__c": {
          name: 'Case_Type_Internal_Status__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        JobsiteProject: {
          name: 'JobsiteProject',
          type: 'Project',
          model: 'Project',
          relationType: 'belongsTo',
                  keyFrom: 'Jobsite_Project__c',
          keyTo: 'sfdcId'
        },
        partner: {
          name: 'partner',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'Partner__c',
          keyTo: 'sfdcId'
        },
        program: {
          name: 'program',
          type: 'Project',
          model: 'Project',
          relationType: 'belongsTo',
                  keyFrom: 'Project_SOP__c',
          keyTo: 'sfdcId'
        },
        Case: {
          name: 'Case',
          type: 'Case',
          model: 'Case',
          relationType: 'belongsTo',
                  keyFrom: 'PMS_Case__c',
          keyTo: 'sfdcId'
        },
        Jobsite: {
          name: 'Jobsite',
          type: 'Jobsite',
          model: 'Jobsite',
          relationType: 'belongsTo',
                  keyFrom: 'Jobsite__c',
          keyTo: 'sfdcId'
        },
        contact: {
          name: 'contact',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Created_by_Contact__c',
          keyTo: 'sfdcId'
        },
        jpcCase: {
          name: 'jpcCase',
          type: 'Case',
          model: 'Case',
          relationType: 'belongsTo',
                  keyFrom: 'JPC_Case__c',
          keyTo: 'sfdcId'
        },
        appointment: {
          name: 'appointment',
          type: 'Appointment',
          model: 'Appointment',
          relationType: 'belongsTo',
                  keyFrom: 'Appointment__c',
          keyTo: 'sfdcId'
        },
        billToParty: {
          name: 'billToParty',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'Bill_To_Party__c',
          keyTo: 'sfdcId'
        },
        customerAccount: {
          name: 'customerAccount',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'Customer_Account__c',
          keyTo: 'sfdcId'
        },
        endUser: {
          name: 'endUser',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'End_User__c',
          keyTo: 'sfdcId'
        },
        purchaseOrder: {
          name: 'purchaseOrder',
          type: 'PurchaseOrder',
          model: 'PurchaseOrder',
          relationType: 'belongsTo',
                  keyFrom: 'sfdcId',
          keyTo: 'Work_Order__c'
        },
        jobOrderItems: {
          name: 'jobOrderItems',
          type: 'JobOrderItem[]',
          model: 'JobOrderItem',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'Job_Order__c'
        },
        RecordType: {
          name: 'RecordType',
          type: 'RecordType',
          model: 'RecordType',
          relationType: 'belongsTo',
                  keyFrom: 'RecordTypeId',
          keyTo: 'sfdcId'
        },
        RequestFormReceipt: {
          name: 'RequestFormReceipt',
          type: 'RequestFormReceipt',
          model: 'RequestFormReceipt',
          relationType: 'belongsTo',
                  keyFrom: 'Request_Form_Receipt__c',
          keyTo: 'sfdcId'
        },
        asset: {
          name: 'asset',
          type: 'Asset',
          model: 'Asset',
          relationType: 'belongsTo',
                  keyFrom: 'Asset_Serial_Number__c',
          keyTo: 'sfdcId'
        },
        serviceContract: {
          name: 'serviceContract',
          type: 'ServiceContract',
          model: 'ServiceContract',
          relationType: 'belongsTo',
                  keyFrom: 'Iron_Service_Contract__c',
          keyTo: 'sfdcId'
        },
        contractService: {
          name: 'contractService',
          type: 'ServiceContract',
          model: 'ServiceContract',
          relationType: 'belongsTo',
                  keyFrom: 'Service_Contract__c',
          keyTo: 'sfdcId'
        },
        serviceContractLineItem: {
          name: 'serviceContractLineItem',
          type: 'ServiceContractLineItem',
          model: 'ServiceContractLineItem',
          relationType: 'belongsTo',
                  keyFrom: 'Service_Contract_Line_Item__c',
          keyTo: 'sfdcId'
        },
        requestorDepartment: {
          name: 'requestorDepartment',
          type: 'RequestorDepartment[]',
          model: 'RequestorDepartment',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'Work_Order__c'
        },
        requestFormReceiptEscalation: {
          name: 'requestFormReceiptEscalation',
          type: 'RequestFormReceiptEscalation[]',
          model: 'RequestFormReceiptEscalation',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'Work_Order__c'
        },
      }
    }
  }
}
