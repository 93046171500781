/* tslint:disable */
import {
  Worker,
  Contact,
  Group,
  Milestone,
  Task
} from '../index';

declare var Object: any;
export interface AccessControlInterface {
  "sfdcId"?: string;
  "Access__c"?: string;
  "Account__c"?: string;
  "Community__c"?: string;
  "CreatedById"?: string;
  "CreatedDate"?: Date;
  "CurrencyIsoCode"?: string;
  "Department_Group__c"?: string;
  "IsDeleted"?: boolean;
  "LastModifiedById"?: string;
  "LastModifiedDate"?: Date;
  "Logged_In_Contact__c"?: string;
  "Member__c"?: string;
  "Name"?: string;
  "OwnerId"?: string;
  "PgMO_Groups__c"?: string;
  "PgMO_Milestones__c"?: string;
  "PgMO_Programs__c"?: string;
  "PgMO_Projects__c"?: string;
  "PgMO_Tasks__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  worker?: Worker;
  contact?: Contact;
  group?: Group;
  milestone?: Milestone;
  task?: Task;
}

export class AccessControl implements AccessControlInterface {
  "sfdcId": string;
  "Access__c": string;
  "Account__c": string;
  "Community__c": string;
  "CreatedById": string;
  "CreatedDate": Date;
  "CurrencyIsoCode": string;
  "Department_Group__c": string;
  "IsDeleted": boolean;
  "LastModifiedById": string;
  "LastModifiedDate": Date;
  "Logged_In_Contact__c": string;
  "Member__c": string;
  "Name": string;
  "OwnerId": string;
  "PgMO_Groups__c": string;
  "PgMO_Milestones__c": string;
  "PgMO_Programs__c": string;
  "PgMO_Projects__c": string;
  "PgMO_Tasks__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  worker: Worker;
  contact: Contact;
  group: Group;
  milestone: Milestone;
  task: Task;
  constructor(data?: AccessControlInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `AccessControl`.
   */
  public static getModelName() {
    return "AccessControl";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of AccessControl for dynamic purposes.
  **/
  public static factory(data: AccessControlInterface): AccessControl{
    return new AccessControl(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'AccessControl',
      plural: 'AccessControls',
      path: 'AccessControls',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "Access__c": {
          name: 'Access__c',
          type: 'string'
        },
        "Account__c": {
          name: 'Account__c',
          type: 'string'
        },
        "Community__c": {
          name: 'Community__c',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "Department_Group__c": {
          name: 'Department_Group__c',
          type: 'string'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "Logged_In_Contact__c": {
          name: 'Logged_In_Contact__c',
          type: 'string'
        },
        "Member__c": {
          name: 'Member__c',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "PgMO_Groups__c": {
          name: 'PgMO_Groups__c',
          type: 'string'
        },
        "PgMO_Milestones__c": {
          name: 'PgMO_Milestones__c',
          type: 'string'
        },
        "PgMO_Programs__c": {
          name: 'PgMO_Programs__c',
          type: 'string'
        },
        "PgMO_Projects__c": {
          name: 'PgMO_Projects__c',
          type: 'string'
        },
        "PgMO_Tasks__c": {
          name: 'PgMO_Tasks__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        worker: {
          name: 'worker',
          type: 'Worker',
          model: 'Worker',
          relationType: 'belongsTo',
                  keyFrom: 'Member__c',
          keyTo: 'sfdcId'
        },
        contact: {
          name: 'contact',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Logged_In_Contact__c',
          keyTo: 'sfdcId'
        },
        group: {
          name: 'group',
          type: 'Group',
          model: 'Group',
          relationType: 'belongsTo',
                  keyFrom: 'PgMO_Groups__c',
          keyTo: 'sfdcId'
        },
        milestone: {
          name: 'milestone',
          type: 'Milestone',
          model: 'Milestone',
          relationType: 'belongsTo',
                  keyFrom: 'PgMO_Milestones__c',
          keyTo: 'sfdcId'
        },
        task: {
          name: 'task',
          type: 'Task',
          model: 'Task',
          relationType: 'belongsTo',
                  keyFrom: 'PgMO_Tasks__c',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
