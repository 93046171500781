import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectWorkerComponent } from './select-worker.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { InternationalPhoneNumberModule } from 'ng-phone-number';

@NgModule({
    imports: [
        CommonModule,
        NgbModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule,
        InternationalPhoneNumberModule
    ],
    declarations: [SelectWorkerComponent],
    exports: [SelectWorkerComponent],
})
export class SelectWorkerModule { }
