/* tslint:disable */

declare var Object: any;
export interface ServiceTypeInterface {
  "Name"?: string;
  "CurrencyIsoCode"?: string;
  "CreatedById"?: string;
  "LastModifiedById"?: string;
  "CKSW_BASE__Due_Date_Offset__c"?: number;
  "CKSW_BASE__Duration__c"?: number;
  "CKSW_BASE__Duration_Type__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class ServiceType implements ServiceTypeInterface {
  "Name": string;
  "CurrencyIsoCode": string;
  "CreatedById": string;
  "LastModifiedById": string;
  "CKSW_BASE__Due_Date_Offset__c": number;
  "CKSW_BASE__Duration__c": number;
  "CKSW_BASE__Duration_Type__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: ServiceTypeInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ServiceType`.
   */
  public static getModelName() {
    return "ServiceType";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ServiceType for dynamic purposes.
  **/
  public static factory(data: ServiceTypeInterface): ServiceType{
    return new ServiceType(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ServiceType',
      plural: 'ServiceTypes',
      path: 'ServiceTypes',
      idName: 'id',
      properties: {
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "CKSW_BASE__Due_Date_Offset__c": {
          name: 'CKSW_BASE__Due_Date_Offset__c',
          type: 'number'
        },
        "CKSW_BASE__Duration__c": {
          name: 'CKSW_BASE__Duration__c',
          type: 'number'
        },
        "CKSW_BASE__Duration_Type__c": {
          name: 'CKSW_BASE__Duration_Type__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
