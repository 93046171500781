import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { JobApi, DocumentApi, JobOrderItemApi } from '../../sdk';
import { CommonService } from 'shared/services/common.service';
import { AlertService } from 'shared/services/alert.service';
import { PreloaderService } from 'shared/services/preloader.service';
import { ModalService } from 'shared/services/modal.service';
import { Observable, empty } from 'rxjs';
import { UtilityService } from 'shared/services/utility.service';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';
import { SharedService } from 'shared/services/pms/shared.services';
import { AppStateService } from 'shared/services/app-state.service';


@Component({
    selector: 'app-job-status',
    templateUrl: './job-status.component.html',
    styleUrls: ['./job-status.component.css']
})
export class JobStatusComponent implements OnInit, OnDestroy {

    @Input()
    set record(e) {
        this.job = {};
        this.jobObj = {};
        if (e && e.modelId && e.modelName === 'Job') {
            this.loadData(e.modelId);
        }
    }

    job: any = {};
    jobTitle = '';
    timeZone = '';
    modalRef: any;
    jobObj: any = {};
    currentWfButton: any;
    documentCount: any = 0;
    timeCardCount: any = 0;
    workers: Observable<any>;
    disabledSubmitBtn: boolean;
    confirmationMessage: string;
    confirmationBoxTitle: string;
    loadingIndicator = false;
    jobEscalationSubsciption: Subscription;
    userRole: any;
    roleSub: Subscription;
    hideActivityFeed: any;

    constructor(
        private _jobApi: JobApi,
        private _documentApi: DocumentApi,
        private _jobOrderItemApi: JobOrderItemApi,
        private _commonService: CommonService,
        private _alertService: AlertService,
        private _preloaderService: PreloaderService,
        private _modalService: ModalService,
        private _utilityService: UtilityService,
        private _sharedService: SharedService,
        private _appState: AppStateService,
    ) { }

    ngOnInit() {
        this.hideActivityFeed = environment.hideActivityFeed
        this.roleSub = this._appState.getJobDetailUserRole().subscribe(role => {
            if (role && role['userRole']) {
                this.userRole = role['userRole'];
            }
        })
        // this._commonService.saveCurrentPageAnalytics('Global Chat', 'Job Details')
        this.jobEscalationSubsciption = this._sharedService.jobEscalationSubject.subscribe(job => {
            if (job && job.id) {
                this.getEscalatedTaskNumber(job.id);

            }
        })
    }

    loadData(jobId) {
        this.loadingIndicator = true;
        this._preloaderService.showPreloader();
        this.job = {};
        this.jobObj = {};
        const reqObj = {
            'fields': ['sfdcId', 'id', 'Iron_Job_num__c', 'Name', 'Vendorsite__c', 'workflowId', 'workflowStatusId', 'Work_Order__c',
                'CKSW_BASE__Account__c', 'RecordTypeId', 'isCancelled', 'Dispatch_Service_Resolution_Status__c', 'Vendor__c',
                'Customer_Service_Type_From_Program__c', 'Technical_Level__c', 'Service_Dispatch_SLA_Priority_FrmProgram__c',
                'acknowledge_instruction', 'pre_visit_checklist', 'Deliverable_Status__c', 'submittedTimecard', 'FileCounter',
                'Dispatch_Worker_Name__c', 'Dispatch_Worker_Name_Text__c', 'Dispatch_Worker_Phone__c', 'Case__c', 'CreatedDate',
                'CKSW_BASE__City__c', 'CKSW_BASE__Description__c', 'Project_SOP__c', 'CKSW_BASE__State__c', 'Jobsite__c', 'ICC_Case__c',
                'Service_Dispatch_SLA_Priority__c', 'Job_Status_Internal__c', 'systemTimecard', 'statusTracker', 'CKSW_BASE__Country__c',
                'RequestFormReceiptLine', 'Final_Timecard_Id', 'Final_Timecard_Submitted__c', 'Is_Final_Timecard_Submitted'],
            'where': { 'id': jobId },
            'include': [
                {
                    'relation': 'vendor',
                    'scope': {
                        'fields': ['sfdcId', 'Name']
                    }
                },
                {
                    'relation': 'worker',
                    'scope': {
                        'fields': ['sfdcId', 'Name', 'Worker_Type__c', 'Contact__c', 'RecordTypeId', 'Dispatch_Worker_num__c'],
                        'include': [
                            {
                                'relation': 'user',
                                'scope': {
                                    'fields': ['sfdcId', 'email', 'picture', 'firstname', 'lastname', 'url']
                                }
                            },
                            {
                                'relation': 'contact',
                                'scope': {
                                    'fields': ['sfdcId', 'MyHomeMetro_Code__c', 'MailingCountry', 'MailingCity', 'MailingState',
                                        'MailingPostalCode', 'MailingStreet', 'Geo_Location__Longitude__s', 'Geo_Location__Latitude__s'],
                                    'include': [
                                        {
                                            'relation': 'geometro',
                                            'scope': {
                                                'fields': ['sfdcId', 'City__c', 'Country__c']
                                            }
                                        }
                                    ]
                                }

                            }
                        ]
                    }
                },
                {
                    'relation': 'jobsite',
                    'scope': {
                        'fields': ['Jobsite_ID__c', 'Street__c', 'Zip__c', 'City__c', 'Abbreviation__c', 'State__c', 'Zip_Postal_Code__c',
               'Country__c', 'Time_Zone__c', 'geolocation__Latitude__s', 'geolocation__Longitude__s']
                    }
                },
                {
                    'relation': 'vendorsite',
                    'scope': {
                        'fields': ['sfdcId', 'Name', 'geolocation__Latitude__s', 'geolocation__Longitude__s', 'Street__c', 'City__c', 'State__c', 'Zip_Postal_Code__c', 'Country__c']
                    }
                },
                {
                    'relation': 'appointment',
                    'scope': {
                        'fields': ['Customer_Appointment_DateTime_Scheduled__c',
                            'Worker_Arrival_DateTime_Cust_Requested__c',
                            'Worker_Arrival_Date_Customer_Req_End__c',
                            'Worker_Arrival_DateTime_Scheduled__c',
                            'Worker_End_DateTime_Scheduled__c',
                            'Worker_Arrival_Date_Time_Actual__c',
                            'Customer_Appointment_Start_Scheduled__c',
                            'Customer_Appointment_Start_HRS_Scheduled__c',
                            'Customer_Apptmnt_Start_Minutes_Scheduled__c',
                            'Earliest_Start_Date_Permitted__c',
                            'Early_Start__c']
                    }
                },
                {
                    'relation': 'program',
                    'scope': { 'fields': ['Name'] },
                },
                {
                    'relation': 'partner',
                    'scope': {
                        'fields': ['sfdcId', 'Name']
                    }
                },
                {
                    'relation': 'case',
                    'scope': {
                        'fields': ['sfdcId', 'Job_Order__c', 'Partner_Case_Number__c', 'Talent_Type_ref__c',
                            'Coverage_Hours__c', 'PPE_Hours__c'],
                        'include': [
                            {
                                'relation': 'workOrder',
                                'scope': {
                                    'fields': ['sfdcId', 'Name']
                                }
                            },
                            {
                                'relation': 'talentType',
                                'scope': { 'fields': ['Talent_Type_Name__c'] }
                            }
                        ]
                    }
                }, {
                    relation: 'escalationJob',
                    scope: {
                        fields: ['id', 'sfdcId', 'Job__c', 'RequestFormReceiptLine', 'Requested_By__c'],
                        include: [{
                            relation: 'task',
                            scope: {
                                fields: ['id', 'sfdcId', 'Task_No__c', 'Task_Number__c', 'Task_Description__c']
                            }
                        }]
                    }
                }
            ]
        };
        this._jobApi.getJobDetailsById(reqObj).subscribe(result => {
            if (result && result.id) {
                this.job = result;
                //  console.log("this.job", this.job);
                const timeFormat = 'L, LT z';
                this.job.TimeAgo = '';
                this.job.StartsIn = '';
                this.job.WokerStartsIn = '';
                this.jobTitle = this.job.Iron_Job_num__c + ' (' + this.job.Dispatch_Service_Resolution_Status__c + ')';
                // prepare jobsite data
                if (this.job.jobsite) {
                    this.timeZone = this.job.jobsite.Time_Zone__c ? this.job.jobsite.Time_Zone__c : '';
                    this.job.TimeAgo = this.job.CreatedDate;
                    this.job.CreatedDate = this._commonService.dateFormate(this.job.CreatedDate, this.timeZone, timeFormat);
                    this.job['jobsiteLocation'] = this.job.jobsite.Street__c + this.comma(this.job.CKSW_BASE__City__c) +
                        this.comma(this.job.CKSW_BASE__State__c) + this.comma(this.job.jobsite.Zip__c) +
                        this.comma(this.job.CKSW_BASE__Country__c);
                }
                // prepare appointment data
                if (this.job.appointment) {
                    this.job.StartsIn = this.job.appointment.Customer_Appointment_DateTime_Scheduled__c;
                    this.job.appointment.Early_Start__c = this._commonService.dateFormate(
                        this.job.appointment.Early_Start__c, this.timeZone, timeFormat);
                    if (this.job.case && this.job.case.PPE_Hours__c) {
                        if (this.job.appointment.Worker_Arrival_DateTime_Cust_Requested__c) {
                            this.job.WokerStartsIn = this.job.appointment.Worker_Arrival_DateTime_Cust_Requested__c;
                            this.job.appointment.Worker_Arrival_Date_Customer_Req_End__c = this._commonService.addTime(
                                this.job.appointment.Worker_Arrival_DateTime_Cust_Requested__c, this.job.case.PPE_Hours__c, 'h');
                        }
                        if (this.job.appointment.Customer_Appointment_DateTime_Scheduled__c) {
                            this.job.appointment.Customer_Appointment_End_DateTime = this._commonService.addTime(
                                this.job.appointment.Customer_Appointment_DateTime_Scheduled__c, this.job.case.PPE_Hours__c, 'h');
                        }
                    }
                    this.job.appointment.Worker_Arrival_DateTime_Cust_Requested__c = this._commonService.dateFormate(
                        this.job.appointment.Worker_Arrival_DateTime_Cust_Requested__c, this.timeZone, timeFormat);
                    this.job.appointment.Customer_Appointment_DateTime_Scheduled__c = this._commonService.dateFormate(
                        this.job.appointment.Customer_Appointment_DateTime_Scheduled__c, this.timeZone, timeFormat);
                    this.job.appointment.Worker_Arrival_Date_Customer_Req_End__c = this._commonService.dateFormate(
                        this.job.appointment.Worker_Arrival_Date_Customer_Req_End__c, this.timeZone, timeFormat);
                    this.job.appointment.Customer_Appointment_End_DateTime = this._commonService.dateFormate(
                        this.job.appointment.Customer_Appointment_End_DateTime, this.timeZone, timeFormat);
                    this.job.appointment.Worker_Arrival_DateTime_Scheduled__c = this._commonService.dateFormate(
                        this.job.appointment.Worker_Arrival_DateTime_Scheduled__c, this.timeZone, timeFormat);
                    this.job.appointment.Worker_End_DateTime_Scheduled__c = this._commonService.dateFormate(
                        this.job.appointment.Worker_End_DateTime_Scheduled__c, this.timeZone, timeFormat);
                        
                }

                // prepare woker data

                if (this.job.worker) {
                    this.job.Dispatch_Number = this.job.worker.Dispatch_Worker_num__c ? this.job.worker.Dispatch_Worker_num__c : '';
                    this.job.Record_Type = this.job.worker.Worker_Type__c ? this.job.worker.Worker_Type__c : '';
                    this.job.Email = this.job.worker.user && this.job.worker.user.email ? this.job.worker.user.email : '';
                    let imgpath = environment.baseUrl + '/' + environment.apiVersion;
                    if (imgpath.indexOf('localhost') !== -1) {
                        imgpath = 'https://den.dev.serviceo.me/api/v1';
                    }
                    const imageUrl = (this.job.worker.user && this.job.worker.user['url'] && this.job.worker.user['picture']) ?
                        imgpath + '/Containers' + this.job.worker.user['url'] + '' + this.job.worker.user['picture'] : '';
                    this.job.Profile_Picture = imageUrl;
                    this.job.Worker_Phone = this.job.Dispatch_Worker_Phone__c ? this.job.Dispatch_Worker_Phone__c : '';
                    this.job.firstname = this.job.worker.user && this.job.worker.user.firstname ? this.job.worker.user.firstname : '';
                    this.job.lastname = this.job.worker.user && this.job.worker.user.lastname ? this.job.worker.user.lastname : '';
                    if (this.job.worker.contact && this.job.worker.contact.geometro) {
                        this.job.Country = this._commonService.concatinateStrings(this.job.worker.contact.geometro.City__c,
                            this.job.worker.contact.geometro.Country__c);
                    } else {
                        this.job.Country = '';
                    }
                }
                this.getJobEscalationTaskNumber(this.job);

                this.getDocumentsCount();
                this.getTimecardsCount();
                // this.workers = this.searchWorkerNames();
                this.jobObj = this.preparejobObj();
                this._preloaderService.hidePreloader();
                this.loadingIndicator = false;
            }
        }, err => {
            this._alertService.error('Something went wrong!');
            this._preloaderService.hidePreloader();
            this.loadingIndicator = false;
            console.log(err.message);
        });
    }

    getEscalatedTaskNumber(id) {
        this._preloaderService.showPreloader();
        const filterObj = {
            fields: ['id', 'sfdcId'],
            include: [{
                relation: 'escalationJob',
                scope: {
                    fields: ['id', 'sfdcId', 'Job__c', 'RequestFormReceiptLine', 'Requested_By__c'],
                    include: [{
                        relation: 'task',
                        scope: {
                            fields: ['id', 'sfdcId', 'Task_No__c', 'Task_Number__c', 'Task_Description__c']
                        }
                    }]
                }
            }]
        }
        this._jobApi.findById(id, filterObj).subscribe(job => {
            this._preloaderService.hidePreloader();
            if (job) {
                this.getJobEscalationTaskNumber(job);
            }
        }, err => {
            this._preloaderService.hidePreloader();

        })
    }

    getJobEscalationTaskNumber(job) {
        // AN ESCALATED JOB WILL NOT HAVE Requested_By__c AS null
        if (job.escalationJob && job.escalationJob[0].Requested_By__c) {
            this.job.escalatedTaskNumber = (job.escalationJob && job.escalationJob[0].task
                && job.escalationJob[0].task.Task_Number__c) || '';
        }
    }

    getDocumentsCount = async () => {
        this.documentCount = await this._utilityService.getDocumentsCount('Job', this.job.id);
    }

    getTimecardsCount = async () => {
        this.timeCardCount = await this._utilityService.getTimecardsCount('Job', this.job.sfdcId);
    }

    preparejobObj() {
        return {
            job: this.job,
            sfdcId: this.job.sfdcId,
            documentCount: this.documentCount,
            jobsiteLocation: this.job.jobsiteLocation,
            jobsite: this.job.jobsite,
            Iron_Job_num__c: this.job.Iron_Job_num__c,
            CreatedDate: this.job.CreatedDate,
            TimeAgo: this.job.TimeAgo,
            CKSW_BASE__Description__c: this.job.CKSW_BASE__Description__c,
            case: this.job.case,
            talentType: this.job.case.talentType.Talent_Type_Name__c ? this.job.case.talentType.Talent_Type_Name__c + ', ' : '',
            Technical_Level__c: this.job.Technical_Level__c ? this.job.Technical_Level__c + ', ' : '',
            Service_Dispatch_SLA_Priority__c: this.job.Service_Dispatch_SLA_Priority__c ?
                this.job.Service_Dispatch_SLA_Priority__c + ', ' : '',
            Coverage_Hours__c: this.job.case.Coverage_Hours__c,
            Customer_Service_Type_From_Program__c: this.job.Customer_Service_Type_From_Program__c,
            program: this.job.program.Name ? ' (' + this.job.program.Name + ')' : '',
            worker: this.job.worker ? this.job.worker.Name : '',
            Earliest_Start_Date_Permitted__c: this.job.appointment ? this.job.appointment.Earliest_Start_Date_Permitted__c : '',
            Early_Start__c: this.job.appointment ? this.job.appointment.Early_Start__c : '',
            Customer_Appointment_DateTime_Scheduled__c: this.job.appointment ?
                this.job.appointment.Customer_Appointment_DateTime_Scheduled__c : '',
            Worker_Arrival_DateTime_Scheduled__c: this.job.appointment ?
                this.job.appointment.Worker_Arrival_DateTime_Scheduled__c : '',
            Worker_End_DateTime_Scheduled__c: this.job.appointment ?
                this.job.appointment.Worker_End_DateTime_Scheduled__c : '',
            StartsIn: this.job.StartsIn,
            Customer_Appointment_End_DateTime: this.job.appointment ? this.job.appointment.Customer_Appointment_End_DateTime : '',
            Abbreviation__c: this.job.jobsite.Abbreviation__c,
            PPE_Hours__c: this.job.case.PPE_Hours__c,
            Worker_Arrival_DateTime_Cust_Requested__c: this.job.appointment ?
                this.job.appointment.Worker_Arrival_DateTime_Cust_Requested__c : '',
            Worker_Arrival_Date_Customer_Req_End__c: this.job.appointment ?
                this.job.appointment.Worker_Arrival_Date_Customer_Req_End__c : '',
            WokerStartsIn: this.job.WorkerStartsIn,
            CKSW_BASE__City__c: this.job.CKSW_BASE__City__c ? this.job.CKSW_BASE__City__c + ', ' : '',
            CKSW_BASE__State__c: this.job.CKSW_BASE__State__c ? this.job.CKSW_BASE__State__c + ', ' : '',
            CKSW_BASE__Country__c: this.job.CKSW_BASE__Country__c ? this.job.CKSW_BASE__Country__c + ', ' : '',
            //worker Information
            Profile_Picture: this.job.Profile_Picture,
            Dispatch_Number: this.job.Dispatch_Number,
            Record_Type: this.job.Record_Type,
            Email: this.job.Email,
            Worker_Phone: this.job.Worker_Phone,
            firstname: this.job.firstname,
            lastname: this.job.lastname,
            Country: this.job.Country

        }
    }

    comma(prop) {
        return prop ? ', ' + prop : '';
    }

    documentUploaded(event) {
        this.getDocumentsCount();
    }

    documentDeleted(event) {
        this.getDocumentsCount();
    }

    refreshData(event) {
        this.loadData(this.job.id);
    }

    onHeaderButtonClick(button, modal, size) {
        if (button === 'Timecard') {
            this._modalService.open(modal, size);
        } else if (button === 'Upload') {
            this._modalService.open(modal, size);
        } else {
            this._modalService.open(modal, size);
        }
    }

    isBase64(str) {
        try {
            return btoa(atob(str)) === str;
        } catch (err) {
            return false;
        }
    }

    workflowUpdate(status) {
        this.job.workflowStatusId = status;
        this.refreshData('');
    }


    onLinkClick(tabId) {
        let url = '';

        if (tabId === 'Direction') {
            let origin = '', destination = '';
            // if (this.job.vendorsite) {
            //     origin = this.job.vendorsite.geolocation__Latitude__s + ',' + this.job.vendorsite.geolocation__Longitude__s;
            // }
            if (this.job.jobsite) {
                destination = this.job.jobsite.geolocation__Latitude__s + ',' + this.job.jobsite.geolocation__Longitude__s;
            }
            url = 'https://www.google.com/maps/dir/?api=1&origin=' + origin + '&destination=' + destination + '&travelmode=driving';
        } else {
            url = '/vms/jobs/view/' + this.job.sfdcId;
            if (tabId) {
                url += '?activeId=' + tabId;
            }
        }
        window.open(url, '_blank');
    }

    ngOnDestroy() {
        this.jobEscalationSubsciption && this.jobEscalationSubsciption.unsubscribe();
        if (this.roleSub) {
            this.roleSub.unsubscribe();
        }
    }

}
