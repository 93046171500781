import { Component, OnInit, Input, PipeTransform, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { PreloaderService } from 'shared/services/preloader.service';
import { JobStatusInternalValues } from '../../../../../models/static-list-data.service';
import { AppStateService } from '../../../../../services/app-state.service';
import { UtilityService } from '../../../../../services/utility.service';
import { SharedService } from 'shared/services/pms/shared.services';
import { ModalService } from '../../../../../services/modal.service';

import { JobOrderItemApi } from '../../../../../sdk';
import * as moment from "moment";
import { CommonService } from 'shared/services/common.service';

@Component({
    selector: 'job-order-item-list',
    templateUrl: './job-order-item-list.component.html',
    styleUrls: ['./job-order-item-list.component.css']
})
export class JobOrderItemlistComponent implements OnInit, OnDestroy, PipeTransform {
    @Input() workOrderId: String;
    @Input() allowedAdd: any;
    @Input() jobId: String;
    @Input() partnerAccountId: String;
    @ViewChild('myTable') table: any;
    pageTitle: string;
    pageSubTitle = '';
    datePipe: DatePipe;
    searchArray: any;
    /*Boot-Datatable params */
    tableData = [];
    dataList: any;
    loadingIndicator = false;
    itemsPerPage = 10;
    isLoadMore = false;
    itemsPerBatch = 200;
    orderBy = 'CreatedDate  DESC';
    errorMessage = 'Loading...';
    filterObj = {};
    sSfilter = {};
    filteredItems: any;
    columns: any;
    allColumns: any;
    JobStatusInternalValues = JobStatusInternalValues;
    filterJobValue = '';
    timeZone: any;
    sizes = [10, 25, 50, 100];
    totalCount: number;
    userRole: any;
    roleSub: Subscription;
    currentExpandViewId: any
    ExpandViewTitle = '';
    lastRefreshTime: any = moment(new Date()).format('MM/DD/YYYY h:mm:ss a');

    constructor(
        private _route: ActivatedRoute,
        private _appState: AppStateService,
        private _utilityService: UtilityService,
        private _jobOrderItemApi: JobOrderItemApi,
        private _sharedservice: SharedService,
        private _modalService: ModalService,
        private _el: ElementRef,
        private _loader: PreloaderService,
        private _commonService: CommonService
    ) { }
    initScroll() {
        let elHeader = this._el.nativeElement.querySelector('.datatable-header')
        let elBody = this._el.nativeElement.querySelector('datatable-body');
        if (elHeader && elBody) {
            elHeader.onscroll = () => {
                elBody.scrollLeft = elHeader.scrollLeft
            }
            elBody.onscroll = () => {
                elHeader.scrollLeft = elBody.scrollLeft
            }
        }

    }

    ngOnInit() {
        this._commonService.saveCurrentPageAnalytics('Job Manager Console - Details', 'Work Order Line Items');
        this.roleSub = this._appState.getJobDetailUserRole().subscribe(role => {
            if (role && role['userRole']) {
                this.userRole = role['userRole'];
            }
        });
        this.allowedAdd = JSON.parse(this.allowedAdd);
        this.searchArray = {};
        if (this.workOrderId) {
            this.searchArray['Job_Order__c'] = this.workOrderId;
            this.getJobListings(this.searchArray, 0, true);
            // Action, Line#, SKU Type, Inventory Type, Service Type, Service Name, Service Description, Qty, Price, Dispatch Status
            // Dispaly action button only if recordTypeId = 0122L000000RRFjQAO || 0121a000000F1eqAAC
            this.columns = [
                { prop: 'action', name: 'Action', width: 160, visible: true, sortable: false },
                { prop: 'SkuType', name: 'SKU Type', visible: true, width: 150, sortable: true },
                { prop: 'InventoryType', name: 'Inventory Type', visible: true, width: 150, sortable: true },
                { prop: 'ServiceType', name: 'Service Type', visible: true, width: 150, sortable: true },
                { prop: 'ServiceName', name: 'Service Name', visible: true, width: 150, sortable: true },
                { prop: 'ServiceDescription', name: 'Service Description', visible: true, width: 150, sortable: true },
                { prop: 'Order_Quantity__c', name: 'Qty', visible: true, width: 150, sortable: true },
                { prop: 'Price', name: 'Price', visible: true, width: 150, sortable: true },
                { prop: 'Dispatch_Status__c', name: 'Dispatch Status', visible: true, width: 150, sortable: true },
            ];
            this.allColumns = this.columns.slice(); // Used for Columns Toggling
            this.allColumns = this.columns.slice(1, this.columns.length);
        }
        this.lastRefreshTime = moment(new Date()).format('MM/DD/YYYY h:mm:ss a');
    }

    ngOnDestroy() {
        if (this.roleSub) {
            this.roleSub.unsubscribe();
        }
    }

    setEmptyMessage() {
        const msg = 'No data to display.';
        if (!this.filteredItems.length) {
            this.filteredItems = [{
                'message': msg
            }];
            this.filteredItems[0][this.columns[0]['prop']] = msg;
        } else {
            if (this.filteredItems[0].hasOwnProperty('message')) {
                this.filteredItems.shift();
            }
        }
    }

    refreshView() {
        this.isLoadMore = false;
        if (this.table) {
            this.table['offset'] = 0
        }
        this.tableData = [];
        let inputs;
        let selects;
        inputs = document.getElementsByTagName('input');
        selects = document.getElementsByTagName('select');
        for (let index = 0; index < inputs.length; ++index) {
            inputs[index].value = '';
        }
        for (let index = 0; index < selects.length; ++index) {
            selects[index].value = '';
        }
        this.filterObj = {};
        this.lastRefreshTime = moment(new Date()).format('MM/DD/YYYY h:mm:ss a');
        this.getJobListings(this.searchArray, 0, true);
    }

    toggle(col) {
        col.visible = !col.visible;
    }

    getJobListings(searchArg: any, offset: number, newState: boolean) {
        this.loadingIndicator = true;
        this._loader.showPreloader();
        if (newState) {
            this.dataList = [];
            this.tableData = [];
        }
        this._jobOrderItemApi.find({
            where: searchArg,
            limit: this.itemsPerBatch,
            order: this.orderBy,
            skip: offset,
            fields: ['IRON_FRU_SKU_Text__c', 'Partner_FRU_SKU_PN_Original_Text__c', 'Manufacture_MPN_Text__c', 'IRON_FRU_SKU_New__c',
                'Partner_FRU_Sku_New__c', 'Order_Quantity__c', 'Defective_Item_Original_SN__c', 'Defective_Item_New_SN__c',
                'CreatedAt', 'sfdcId', 'id', 'Job_Order__c', 'Partner_Account__c', 'RecordTypeId', 'SKU__c',
                'Dispatch_Status__c', 'Total_Price_Customer_Pre_Tax_Auto__c', 'FRU_Description_Text__c'
            ],
            include: [{
                relation: 'Product',
                fields: ['id', 'sfdcId', 'Name', 'Description']
            },
            {
                relation: 'RecordType',
                fields: ['id', 'sfdcId', 'Name']
            }
            ]
        }).subscribe(
            results => {
                this.isLoadMore = (results.length === this.itemsPerBatch) ? true : false;
                results = this.prepareJobOrderData(results);
                this.dataList = this.dataList.concat(results);
                this.tableData = [...this.dataList];

                // this.filteredItems = this.dataList.slice();
                if (Object.keys(this.filterObj).length > 0 && this.filterObj.constructor === Object) {
                    this.filteredItems = this.tableData.filter(item => {
                        const notMatchingField = Object.keys(this.filterObj).find(key =>
                            this._utilityService.dataTableSearch(item, this.filterObj, key));
                        return !notMatchingField;
                    });
                } else {
                    this.filteredItems = this.tableData;
                }
                if (this.table) {
                    this.table.count = this.filteredItems.length;
                }
                if (this.tableData.length) {
                    const _that = this;
                    setTimeout(function () { _that.initScroll(); }, 1000);
                }
                this.setEmptyMessage();
                this.loadingIndicator = false;
                this._loader.hidePreloader();
            },
            error => {
                this.loadingIndicator = false;
                this._loader.hidePreloader();
            }
        )

    }


    /*Data Table funcation start here*/
    loadMoreRecords() {
        this.isLoadMore = true;
        this._loader.showPreloader();
        if (this.dataList.length <= this.itemsPerBatch) {
            this.getJobListings(this.searchArray, this.itemsPerBatch, false);
        } else {
            this.getJobListings(this.searchArray, this.dataList.length, false);
        }
    }

    prepareJobOrderData(data) {
        const jobOrderItemData = [];
        if (data) {
            let obj;
            data.forEach((item, index) => {
                obj = {};
                obj = {
                    'sfdcId': item.sfdcId ? item.sfdcId : '',
                    'IRON_FRU_SKU_Text__c': item.IRON_FRU_SKU_Text__c ? item.IRON_FRU_SKU_Text__c : '',
                    'Partner_FRU_SKU_PN_Original_Text__c': item.Partner_FRU_SKU_PN_Original_Text__c ?
                        item.Partner_FRU_SKU_PN_Original_Text__c : '',
                    'Manufacture_MPN_Text__c': item.Manufacture_MPN_Text__c ?
                        item.Manufacture_MPN_Text__c : '',
                    'programName': item.program ? item.program.Name ? item.program.Name : '' : '',
                    'vendorName': item.vendor ? item.vendor.Name ? item.vendor.Name : '' : '',
                    'IRON_FRU_SKU_New__c': item.IRON_FRU_SKU_New__c ? item.IRON_FRU_SKU_New__c : '',
                    'Partner_FRU_Sku_New__c': item.Partner_FRU_Sku_New__c ? item.Partner_FRU_Sku_New__c : '',
                    'Order_Quantity__c': item.Order_Quantity__c ? item.Order_Quantity__c : '',
                    'Defective_Item_Original_SN__c': item.Defective_Item_Original_SN__c ? item.Defective_Item_Original_SN__c : '',
                    'Defective_Item_New_SN__c': item.Defective_Item_New_SN__c ? item.Defective_Item_New_SN__c : '',
                    'CreatedDate': item.CreatedAt ? this.transform(item.CreatedAt) : '',
                    'Partner_Account__c': item.Partner_Account__c ? item.Partner_Account__c : '',
                    'RecordTypeId': item.RecordTypeId ? item.RecordTypeId : '',
                    'id': item.id ? item.id : '',
                    'ServiceName': (item.Product && item.Product.Name) ? item.Product.Name : '',
                    'ServiceDescription': item.FRU_Description_Text__c ? item.FRU_Description_Text__c : '',
                    'Price': item.Total_Price_Customer_Pre_Tax_Auto__c ? item.Total_Price_Customer_Pre_Tax_Auto__c : '',
                    'Dispatch_Status__c': item.Dispatch_Status__c ? item.Dispatch_Status__c : '',

                };
                if (item.RecordTypeId === '0121a0000006RdhAAE') {
                    obj['ServiceType'] = 'Service Dispatch Order (3PS/FSE)';
                }
                if (item.RecordTypeId === '0122L000000RRFjQAO') {
                    obj['ServiceType'] = 'Service Dispatch Order (3PL/FRU)';
                }
                if (item.RecordTypeId === '0121a000000F27gAAC') {
                    obj['ServiceType'] = 'Service Billing (FRU)';
                }
                if (item.RecordTypeId === '0122L000000RRFjQAO') {
                    obj['ServiceType'] = 'Service Dispatch Form (3PL/FRU-3rd Party)';
                    obj['SkuType'] = '3rd Party SKU';
                    obj['InventoryType'] = 'Inventory (Non-Serialized)';
                    if (item.Iron_Product_Type__c === 'Iron Product FRU SKU') {
                        obj['SkuType'] = 'Non-Inventory SKU';
                    }
                    if (item.Iron_Product_Type__c === 'Iron Product as Text (Non-SKU)') {
                        obj['SkuType'] = 'Non-Inventory SKU (Text Form)';
                    }
                }

                jobOrderItemData.push(obj);
            });
        }

        return jobOrderItemData;
    }

    transform(value: string, type?) {
        let dateformate: string;
        type ? dateformate = type : dateformate = 'short';
        this.datePipe = new DatePipe('en-US');
        const formateddate = (value ? this.datePipe.transform(value, dateformate) : value);
        return formateddate;
    }


    filterData(event, type) {
        if (type === 'date') {
            if (event.value === '') {
                if (this.filterObj[event.input.id + '_temp']) {
                    delete this.filterObj[event.input.id];
                }
            } else {
                const date = this.transform(event.value).split(',');
                this.filterObj[event.input.id] = date[0];
            }
            this.filteredItems = this.tableData.filter(item => {
                const notMatchingField = Object.keys(this.filterObj).find(key =>
                    this._utilityService.dataTableSearch(item, this.filterObj, key));
                return !notMatchingField;
            });

        } else {
            if (event.target.value === '') {
                delete this.filterObj[event.currentTarget.id];
            } else {
                this.filterObj[event.currentTarget.id] = event.target.value;
            }
            this.filteredItems = this.tableData.filter(item => {
                const notMatchingField = Object.keys(this.filterObj).find(key =>
                    this._utilityService.dataTableSearch(item, this.filterObj, key));
                return !notMatchingField;
            });
        }
        this.setEmptyMessage();
        this.table.offset = 0;

    }

    sSfilterData() {
        const filter = {};
        const dateProp = this.columns.filter(x => x.type === 'date' ? x.prop : '');
        for (const key in this.sSfilter) {
            if (dateProp.findIndex(x => x.prop === key) === -1) {
                const searchPattern = { like: '%' + this.sSfilter[key].toLowerCase() + '%', options: 'i' };
                filter[key] = searchPattern
            } else {
                let dateFrom: any;
                let dateTo: any;
                if (this.sSfilter[key] && this.sSfilter[key].length > 0) {
                    dateFrom = this.sSfilter[key][0]
                        ? this.transform(this.sSfilter[key][0], 'MM-dd-yyyy') + ' 00:00:00'
                        : '';
                    dateTo = this.sSfilter[key][1]
                        ? this.transform(this.sSfilter[key][1], 'MM-dd-yyyy') + ' 23:59:59'
                        : '';
                    dateFrom = new Date(dateFrom);
                    dateTo = new Date(dateTo);
                }
                const searchPattern = { between: [dateFrom, dateTo] }
                filter[key] = searchPattern
            }
        }
        this.table.offset = 0;
        this.getJobListings(filter, 0, true);
        this._modalService.closed();
    }

    exportCSV() {
        this._sharedservice.exportNgxData(this.filteredItems, this.columns, 'Work_Order_line_item_');
    }

    clearSearch(col) {
        this.filterObj[col] = ''
        delete this.filterObj[col];
        this.filteredItems = this.tableData.filter(item => {
            const notMatchingField = Object.keys(this.filterObj).find(key =>
                this._utilityService.dataTableSearch(item, this.filterObj, key));
            return !notMatchingField;
        });
        this.setEmptyMessage();
        this.table.offset = 0;
    }
    clearsSfilter(col) {
        delete this.sSfilter[col];
    }

    editFruItem(content, size, id = '') {
        this.ExpandViewTitle = id ? 'Update FRU Item' : 'Add New FRU Item';
        this.currentExpandViewId = id;
        this._modalService.open(content, size);
    }
}
