import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { GeoMetroLookupComponent } from './geo-metro-lookup.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule
  ],
  declarations: [GeoMetroLookupComponent],
  exports: [GeoMetroLookupComponent],
  providers: []
})
export class GeoMetroLookupModule { }
