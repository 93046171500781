import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RadiusLookupComponent } from './radius-lookup.component';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule
  ],
  declarations: [RadiusLookupComponent],
  exports: [RadiusLookupComponent]
})
export class RadiusLookupModule { }
