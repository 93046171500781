import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { paymentTypeList } from 'shared/models/static-list-data.service';

@Component({
  selector: 'app-payment-type-lookup',
  templateUrl: './payment-type-lookup.component.html',
  styleUrls: ['./payment-type-lookup.component.css']
})
export class PaymentTypeLookupComponent implements OnInit {

  @Input() set resetData(isClear) {
    this.clearFromParent();
  }
  @Output() selectedPaymentType: EventEmitter<any> = new EventEmitter;
  selectedValue;

  paymentTypes = [];

  constructor() { }

  ngOnInit() {
    this.paymentTypes = paymentTypeList;
    const preSelected = localStorage.getItem('filterObj');
    if (preSelected && JSON.parse(preSelected) &&
      JSON.parse(preSelected)['paymentTypes'] && JSON.parse(preSelected)['paymentTypes'].length) {
      this.selectedValue = JSON.parse(preSelected)['paymentTypes'];
    }
  }

  onChange() {
    this.selectedPaymentType.emit(this.selectedValue);
  }

  clearFromParent() {
    this.selectedValue = null;
  }

}
