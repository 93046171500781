import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaxonomiesSkillLookupComponent } from './taxonomies-skill-lookup.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule
  ],
  declarations: [TaxonomiesSkillLookupComponent],
  exports: [TaxonomiesSkillLookupComponent],
})
export class TaxonomiesSkillLookupModule { }
