import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DepartmentRoleLookupComponent } from './department-role-lookup.component';
import { AngularDualListBoxModule } from 'angular-dual-listbox';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    AngularDualListBoxModule
  ],
  declarations: [DepartmentRoleLookupComponent],
  exports: [DepartmentRoleLookupComponent]
})
export class DepartmentRoleLookupModule { }
