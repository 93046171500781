/* tslint:disable */

declare var Object: any;
export interface InterviewFeedbackInterface {
  "sfdcId"?: string;
  "Accountability_Comment__c"?: string;
  "CreatedById"?: string;
  "CurrencyIsoCode"?: string;
  "Name"?: string;
  "LastModifiedById"?: string;
  "OwnerId"?: string;
  "Accountability_Rating__c"?: string;
  "Accountability_Score__c"?: number;
  "Adaptability_Comment__c"?: string;
  "Adaptability_Rating__c"?: string;
  "Adaptability_Score__c"?: number;
  "Assigned_To__c"?: string;
  "Attitude_Comment__c"?: string;
  "Attitude_Rating__c"?: string;
  "Attitude_Score__c"?: number;
  "Bridge_Details__c"?: string;
  "Communication_Comment__c"?: string;
  "Communication_Rating__c"?: string;
  "Communication_Score__c"?: number;
  "Created_By__c"?: string;
  "Customer_Service_Focus_Comment__c"?: string;
  "Customer_Service_Focus_Rating__c"?: string;
  "Customer_Service_Focus_Score__c"?: number;
  "Description__c"?: string;
  "Drive_Comment__c"?: string;
  "Drive_Rating__c"?: string;
  "Drive_Score__c"?: number;
  "End_Date__c"?: Date;
  "Feedback_by__c"?: string;
  "Health_Check__c"?: string;
  "Innovation_Comment__c"?: string;
  "Innovation_Rating__c"?: string;
  "Innovation_Score__c"?: number;
  "Integrity_Comment__c"?: string;
  "Integrity_Rating__c"?: string;
  "Integrity_Score__c"?: number;
  "Interview__c"?: string;
  "InterviewerName__c"?: string;
  "Interview_Feedback_Form__c"?: string;
  "Interview_Feedbacks__c"?: string;
  "Interview_Type__c"?: string;
  "IsFeedbackSubmitted__c"?: boolean;
  "Last_Modified_By__c"?: string;
  "Leadership_Comment__c"?: string;
  "Leadership_Rating__c"?: string;
  "Leadership_Score__c"?: number;
  "Location__c"?: string;
  "Overall_Comments__c"?: string;
  "Overall_Rating__c"?: string;
  "Overall_Score__c"?: string;
  "Overall_Score_Out_of_55__c"?: string;
  "Parent_Interview_Feedback__c"?: string;
  "Passion_Comment__c"?: string;
  "Passion_Rating__c"?: string;
  "Passion_Score__c"?: number;
  "Recommendation__c"?: string;
  "Score__c"?: number;
  "Send_Email_To__c"?: string;
  "Skill_Comment__c"?: string;
  "Skill_Score__c"?: number;
  "Skills_Rating__c"?: string;
  "Start_Date__c"?: Date;
  "Start_Date_Local__c"?: Date;
  "Status__c"?: string;
  "Subject__c"?: string;
  "SubStatus__c"?: string;
  "TimeZone__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class InterviewFeedback implements InterviewFeedbackInterface {
  "sfdcId": string;
  "Accountability_Comment__c": string;
  "CreatedById": string;
  "CurrencyIsoCode": string;
  "Name": string;
  "LastModifiedById": string;
  "OwnerId": string;
  "Accountability_Rating__c": string;
  "Accountability_Score__c": number;
  "Adaptability_Comment__c": string;
  "Adaptability_Rating__c": string;
  "Adaptability_Score__c": number;
  "Assigned_To__c": string;
  "Attitude_Comment__c": string;
  "Attitude_Rating__c": string;
  "Attitude_Score__c": number;
  "Bridge_Details__c": string;
  "Communication_Comment__c": string;
  "Communication_Rating__c": string;
  "Communication_Score__c": number;
  "Created_By__c": string;
  "Customer_Service_Focus_Comment__c": string;
  "Customer_Service_Focus_Rating__c": string;
  "Customer_Service_Focus_Score__c": number;
  "Description__c": string;
  "Drive_Comment__c": string;
  "Drive_Rating__c": string;
  "Drive_Score__c": number;
  "End_Date__c": Date;
  "Feedback_by__c": string;
  "Health_Check__c": string;
  "Innovation_Comment__c": string;
  "Innovation_Rating__c": string;
  "Innovation_Score__c": number;
  "Integrity_Comment__c": string;
  "Integrity_Rating__c": string;
  "Integrity_Score__c": number;
  "Interview__c": string;
  "InterviewerName__c": string;
  "Interview_Feedback_Form__c": string;
  "Interview_Feedbacks__c": string;
  "Interview_Type__c": string;
  "IsFeedbackSubmitted__c": boolean;
  "Last_Modified_By__c": string;
  "Leadership_Comment__c": string;
  "Leadership_Rating__c": string;
  "Leadership_Score__c": number;
  "Location__c": string;
  "Overall_Comments__c": string;
  "Overall_Rating__c": string;
  "Overall_Score__c": string;
  "Overall_Score_Out_of_55__c": string;
  "Parent_Interview_Feedback__c": string;
  "Passion_Comment__c": string;
  "Passion_Rating__c": string;
  "Passion_Score__c": number;
  "Recommendation__c": string;
  "Score__c": number;
  "Send_Email_To__c": string;
  "Skill_Comment__c": string;
  "Skill_Score__c": number;
  "Skills_Rating__c": string;
  "Start_Date__c": Date;
  "Start_Date_Local__c": Date;
  "Status__c": string;
  "Subject__c": string;
  "SubStatus__c": string;
  "TimeZone__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: InterviewFeedbackInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `InterviewFeedback`.
   */
  public static getModelName() {
    return "InterviewFeedback";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of InterviewFeedback for dynamic purposes.
  **/
  public static factory(data: InterviewFeedbackInterface): InterviewFeedback{
    return new InterviewFeedback(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'InterviewFeedback',
      plural: 'InterviewFeedbacks',
      path: 'InterviewFeedbacks',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "Accountability_Comment__c": {
          name: 'Accountability_Comment__c',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "Accountability_Rating__c": {
          name: 'Accountability_Rating__c',
          type: 'string'
        },
        "Accountability_Score__c": {
          name: 'Accountability_Score__c',
          type: 'number'
        },
        "Adaptability_Comment__c": {
          name: 'Adaptability_Comment__c',
          type: 'string'
        },
        "Adaptability_Rating__c": {
          name: 'Adaptability_Rating__c',
          type: 'string'
        },
        "Adaptability_Score__c": {
          name: 'Adaptability_Score__c',
          type: 'number'
        },
        "Assigned_To__c": {
          name: 'Assigned_To__c',
          type: 'string'
        },
        "Attitude_Comment__c": {
          name: 'Attitude_Comment__c',
          type: 'string'
        },
        "Attitude_Rating__c": {
          name: 'Attitude_Rating__c',
          type: 'string'
        },
        "Attitude_Score__c": {
          name: 'Attitude_Score__c',
          type: 'number'
        },
        "Bridge_Details__c": {
          name: 'Bridge_Details__c',
          type: 'string'
        },
        "Communication_Comment__c": {
          name: 'Communication_Comment__c',
          type: 'string'
        },
        "Communication_Rating__c": {
          name: 'Communication_Rating__c',
          type: 'string'
        },
        "Communication_Score__c": {
          name: 'Communication_Score__c',
          type: 'number'
        },
        "Created_By__c": {
          name: 'Created_By__c',
          type: 'string'
        },
        "Customer_Service_Focus_Comment__c": {
          name: 'Customer_Service_Focus_Comment__c',
          type: 'string'
        },
        "Customer_Service_Focus_Rating__c": {
          name: 'Customer_Service_Focus_Rating__c',
          type: 'string'
        },
        "Customer_Service_Focus_Score__c": {
          name: 'Customer_Service_Focus_Score__c',
          type: 'number'
        },
        "Description__c": {
          name: 'Description__c',
          type: 'string'
        },
        "Drive_Comment__c": {
          name: 'Drive_Comment__c',
          type: 'string'
        },
        "Drive_Rating__c": {
          name: 'Drive_Rating__c',
          type: 'string'
        },
        "Drive_Score__c": {
          name: 'Drive_Score__c',
          type: 'number'
        },
        "End_Date__c": {
          name: 'End_Date__c',
          type: 'Date'
        },
        "Feedback_by__c": {
          name: 'Feedback_by__c',
          type: 'string'
        },
        "Health_Check__c": {
          name: 'Health_Check__c',
          type: 'string'
        },
        "Innovation_Comment__c": {
          name: 'Innovation_Comment__c',
          type: 'string'
        },
        "Innovation_Rating__c": {
          name: 'Innovation_Rating__c',
          type: 'string'
        },
        "Innovation_Score__c": {
          name: 'Innovation_Score__c',
          type: 'number'
        },
        "Integrity_Comment__c": {
          name: 'Integrity_Comment__c',
          type: 'string'
        },
        "Integrity_Rating__c": {
          name: 'Integrity_Rating__c',
          type: 'string'
        },
        "Integrity_Score__c": {
          name: 'Integrity_Score__c',
          type: 'number'
        },
        "Interview__c": {
          name: 'Interview__c',
          type: 'string'
        },
        "InterviewerName__c": {
          name: 'InterviewerName__c',
          type: 'string'
        },
        "Interview_Feedback_Form__c": {
          name: 'Interview_Feedback_Form__c',
          type: 'string'
        },
        "Interview_Feedbacks__c": {
          name: 'Interview_Feedbacks__c',
          type: 'string'
        },
        "Interview_Type__c": {
          name: 'Interview_Type__c',
          type: 'string'
        },
        "IsFeedbackSubmitted__c": {
          name: 'IsFeedbackSubmitted__c',
          type: 'boolean'
        },
        "Last_Modified_By__c": {
          name: 'Last_Modified_By__c',
          type: 'string'
        },
        "Leadership_Comment__c": {
          name: 'Leadership_Comment__c',
          type: 'string'
        },
        "Leadership_Rating__c": {
          name: 'Leadership_Rating__c',
          type: 'string'
        },
        "Leadership_Score__c": {
          name: 'Leadership_Score__c',
          type: 'number'
        },
        "Location__c": {
          name: 'Location__c',
          type: 'string'
        },
        "Overall_Comments__c": {
          name: 'Overall_Comments__c',
          type: 'string'
        },
        "Overall_Rating__c": {
          name: 'Overall_Rating__c',
          type: 'string'
        },
        "Overall_Score__c": {
          name: 'Overall_Score__c',
          type: 'string'
        },
        "Overall_Score_Out_of_55__c": {
          name: 'Overall_Score_Out_of_55__c',
          type: 'string'
        },
        "Parent_Interview_Feedback__c": {
          name: 'Parent_Interview_Feedback__c',
          type: 'string'
        },
        "Passion_Comment__c": {
          name: 'Passion_Comment__c',
          type: 'string'
        },
        "Passion_Rating__c": {
          name: 'Passion_Rating__c',
          type: 'string'
        },
        "Passion_Score__c": {
          name: 'Passion_Score__c',
          type: 'number'
        },
        "Recommendation__c": {
          name: 'Recommendation__c',
          type: 'string'
        },
        "Score__c": {
          name: 'Score__c',
          type: 'number'
        },
        "Send_Email_To__c": {
          name: 'Send_Email_To__c',
          type: 'string'
        },
        "Skill_Comment__c": {
          name: 'Skill_Comment__c',
          type: 'string'
        },
        "Skill_Score__c": {
          name: 'Skill_Score__c',
          type: 'number'
        },
        "Skills_Rating__c": {
          name: 'Skills_Rating__c',
          type: 'string'
        },
        "Start_Date__c": {
          name: 'Start_Date__c',
          type: 'Date'
        },
        "Start_Date_Local__c": {
          name: 'Start_Date_Local__c',
          type: 'Date'
        },
        "Status__c": {
          name: 'Status__c',
          type: 'string'
        },
        "Subject__c": {
          name: 'Subject__c',
          type: 'string'
        },
        "SubStatus__c": {
          name: 'SubStatus__c',
          type: 'string'
        },
        "TimeZone__c": {
          name: 'TimeZone__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
