import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { regionList, jobOrderRegionList } from 'shared/models/static-list-data.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
@Component({
  selector: 'app-region-lookup',
  templateUrl: './region-lookup.component.html',
  styleUrls: ['./region-lookup.component.css']
})
export class RegionLookupComponent implements OnInit {

  @Input() fromPage;
  @Input() set resetData(isClear) {
    this.clearFromParent();
  }
  @Output() selectedRegion: EventEmitter<any> = new EventEmitter;
  selectedValue;
  regions = [];

  constructor(
    private router : Router) {}
   ngOnInit() {
    this.regions = (this.router.url.indexOf('/app/marketplace-recruitment-jobs') !== -1 || this.router.url.indexOf('/app/msp-marketplace-recruitment-jobs') !== -1 ||
     this.router.url.indexOf('/app/workforce-checkin-checkout/listview') !== -1 ||
     this.router.url.indexOf('/vms/search-job') !== -1 ||  this.router.url.indexOf('vms-billing-manager?activeId=talentManager') !== -1 || this.router.url.indexOf('app/program-manager/program-talent-cloud-apvp-console') !== -1) ? jobOrderRegionList : regionList;
     if(this.fromPage=='PDMC') {
      this.regions = [...jobOrderRegionList];
    }
     const preSelected = localStorage.getItem('filterObj');
    if (preSelected && JSON.parse(preSelected) &&
      JSON.parse(preSelected)['regions'] && JSON.parse(preSelected)['regions'].length) {
      this.selectedValue = JSON.parse(preSelected)['regions'];
    }
  }

  onChange() {
    this.selectedRegion.emit(this.selectedValue);
  }

  clearFromParent() {
    this.selectedValue = null;
  }

}
