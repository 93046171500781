/* tslint:disable */
import {
  Worker,
  Contact,
  MyWorkLeaveTypeSetting
} from '../index';

declare var Object: any;
export interface MyWorkLeaveRequestInterface {
  "sfdcId"?: string;
  "OwnerId"?: string;
  "IsDeleted"?: boolean;
  "Name"?: string;
  "CreatedById"?: string;
  "CreatedDate"?: Date;
  "CurrencyIsoCode"?: string;
  "LastModifiedDate"?: Date;
  "LastModifiedById"?: string;
  "LastActivityDate"?: Date;
  "LastViewedDate"?: Date;
  "LastReferencedDate"?: Date;
  "Approver__c"?: string;
  "Comment__c"?: string;
  "Created_By_Contact__c"?: string;
  "Days__c"?: number;
  "End_Date_Unit__c"?: string;
  "End_Time__c"?: number;
  "LeaveEndDate__c"?: Date;
  "Leave_Request_Status__c"?: string;
  "LeaveStartDate__c"?: Date;
  "Leave_Type__c"?: string;
  "Modified_By_Contact__c"?: string;
  "Name__c"?: string;
  "Number_of_Units__c"?: number;
  "Reason_for_Leave__c"?: string;
  "RequestedDate__c"?: Date;
  "Requestor__c"?: string;
  "Start_Date_Unit__c"?: string;
  "Start_Time__c"?: string;
  "Status__c"?: string;
  "Unit__c"?: string;
  "Worker__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  approver?: Worker;
  createdByContact?: Contact;
  modifiedByContact?: Contact;
  requestor?: Worker;
  worker?: Worker;
  myWorkLeaveTypeSetting?: MyWorkLeaveTypeSetting;
}

export class MyWorkLeaveRequest implements MyWorkLeaveRequestInterface {
  "sfdcId": string;
  "OwnerId": string;
  "IsDeleted": boolean;
  "Name": string;
  "CreatedById": string;
  "CreatedDate": Date;
  "CurrencyIsoCode": string;
  "LastModifiedDate": Date;
  "LastModifiedById": string;
  "LastActivityDate": Date;
  "LastViewedDate": Date;
  "LastReferencedDate": Date;
  "Approver__c": string;
  "Comment__c": string;
  "Created_By_Contact__c": string;
  "Days__c": number;
  "End_Date_Unit__c": string;
  "End_Time__c": number;
  "LeaveEndDate__c": Date;
  "Leave_Request_Status__c": string;
  "LeaveStartDate__c": Date;
  "Leave_Type__c": string;
  "Modified_By_Contact__c": string;
  "Name__c": string;
  "Number_of_Units__c": number;
  "Reason_for_Leave__c": string;
  "RequestedDate__c": Date;
  "Requestor__c": string;
  "Start_Date_Unit__c": string;
  "Start_Time__c": string;
  "Status__c": string;
  "Unit__c": string;
  "Worker__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  approver: Worker;
  createdByContact: Contact;
  modifiedByContact: Contact;
  requestor: Worker;
  worker: Worker;
  myWorkLeaveTypeSetting: MyWorkLeaveTypeSetting;
  constructor(data?: MyWorkLeaveRequestInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `MyWorkLeaveRequest`.
   */
  public static getModelName() {
    return "MyWorkLeaveRequest";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of MyWorkLeaveRequest for dynamic purposes.
  **/
  public static factory(data: MyWorkLeaveRequestInterface): MyWorkLeaveRequest{
    return new MyWorkLeaveRequest(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'MyWorkLeaveRequest',
      plural: 'MyWorkLeaveRequests',
      path: 'MyWorkLeaveRequests',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "LastActivityDate": {
          name: 'LastActivityDate',
          type: 'Date'
        },
        "LastViewedDate": {
          name: 'LastViewedDate',
          type: 'Date'
        },
        "LastReferencedDate": {
          name: 'LastReferencedDate',
          type: 'Date'
        },
        "Approver__c": {
          name: 'Approver__c',
          type: 'string'
        },
        "Comment__c": {
          name: 'Comment__c',
          type: 'string'
        },
        "Created_By_Contact__c": {
          name: 'Created_By_Contact__c',
          type: 'string'
        },
        "Days__c": {
          name: 'Days__c',
          type: 'number'
        },
        "End_Date_Unit__c": {
          name: 'End_Date_Unit__c',
          type: 'string'
        },
        "End_Time__c": {
          name: 'End_Time__c',
          type: 'number'
        },
        "LeaveEndDate__c": {
          name: 'LeaveEndDate__c',
          type: 'Date'
        },
        "Leave_Request_Status__c": {
          name: 'Leave_Request_Status__c',
          type: 'string'
        },
        "LeaveStartDate__c": {
          name: 'LeaveStartDate__c',
          type: 'Date'
        },
        "Leave_Type__c": {
          name: 'Leave_Type__c',
          type: 'string'
        },
        "Modified_By_Contact__c": {
          name: 'Modified_By_Contact__c',
          type: 'string'
        },
        "Name__c": {
          name: 'Name__c',
          type: 'string'
        },
        "Number_of_Units__c": {
          name: 'Number_of_Units__c',
          type: 'number'
        },
        "Reason_for_Leave__c": {
          name: 'Reason_for_Leave__c',
          type: 'string'
        },
        "RequestedDate__c": {
          name: 'RequestedDate__c',
          type: 'Date'
        },
        "Requestor__c": {
          name: 'Requestor__c',
          type: 'string'
        },
        "Start_Date_Unit__c": {
          name: 'Start_Date_Unit__c',
          type: 'string'
        },
        "Start_Time__c": {
          name: 'Start_Time__c',
          type: 'string'
        },
        "Status__c": {
          name: 'Status__c',
          type: 'string'
        },
        "Unit__c": {
          name: 'Unit__c',
          type: 'string'
        },
        "Worker__c": {
          name: 'Worker__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        approver: {
          name: 'approver',
          type: 'Worker',
          model: 'Worker',
          relationType: 'belongsTo',
                  keyFrom: 'Approver__c',
          keyTo: 'sfdcId'
        },
        createdByContact: {
          name: 'createdByContact',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Created_By_Contact__c',
          keyTo: 'sfdcId'
        },
        modifiedByContact: {
          name: 'modifiedByContact',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Modified_By_Contact__c',
          keyTo: 'sfdcId'
        },
        requestor: {
          name: 'requestor',
          type: 'Worker',
          model: 'Worker',
          relationType: 'belongsTo',
                  keyFrom: 'Requestor__c',
          keyTo: 'sfdcId'
        },
        worker: {
          name: 'worker',
          type: 'Worker',
          model: 'Worker',
          relationType: 'belongsTo',
                  keyFrom: 'Worker__c',
          keyTo: 'sfdcId'
        },
        myWorkLeaveTypeSetting: {
          name: 'myWorkLeaveTypeSetting',
          type: 'MyWorkLeaveTypeSetting',
          model: 'MyWorkLeaveTypeSetting',
          relationType: 'belongsTo',
                  keyFrom: 'Leave_Type__c',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
