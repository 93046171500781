import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-text-notification-popup',
  templateUrl: './text-notification-popup.html',
  styleUrls: ['./text-notification-popup.css'],
})
export class TextNotificationPopupComponent implements OnInit {
    @Input() set _rowData(e) {
        this.rowdata = e;
    }
    rowdata;
    showMore = false;

    ngOnInit(): void {
    }
    
    changeShowMore(){
        this.showMore = !this.showMore;
    }
}