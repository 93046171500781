/* tslint:disable */
import {
  Account,
  Jobsite,
  Milestone,
  Project2,
  // PrePostTaskCase,
  Project,
  RequestFormReceipt,
  RequestFormReceiptTemplate,
  TalentType,
  Product,
  Worker,
  WorkOrder
} from '../index';

declare var Object: any;
export interface RequestFormJobsiteInterface {
  "sfdcId"?: string;
  "CreatedById"?: string;
  "CurrencyIsoCode"?: string;
  "LastModifiedById"?: string;
  "OwnerId"?: string;
  "Name"?: string;
  "Account__c"?: string;
  "Case_Event_Priority_Level__c"?: string;
  "Case_Summary__c"?: string;
  "Clone_Task_Appointment_Options__c"?: string;
  "CreatedBy__c"?: string;
  "Create_WAR__c"?: boolean;
  "Customer_Appointment_Date_Time_Schedule__c"?: Date;
  "Customer_Appointment_Schedule_Start_Date__c"?: Date;
  "Customer_Appointment_Schedule_Start_Hour__c"?: string;
  "Customer_Appointment_Schedule_Start_Mi__c"?: string;
  "Earliest_Start_Date_Permitted__c"?: boolean;
  "Early_Start__c"?: Date;
  "IRON_ExtID__c"?: string;
  "Jobsite__c"?: string;
  "Jobsite_City__c"?: string;
  "Jobsite_Company_End_User_or_Ship_To__c"?: string;
  "Jobsite_Contact_Email_End_User_or_Sh__c"?: string;
  "Jobsite_Contact_Name_End_User_or_Shi__c"?: string;
  "Jobsite_Contact_Phone_End_User__c"?: string;
  "Jobsite_Country__c"?: string;
  "Jobsite_Postal_Code__c"?: string;
  "Jobsite_State__c"?: string;
  "Jobsite_Street__c"?: string;
  "LastModifiedBy__c"?: string;
  "Maintenance_Event_Duration_PPM_Hours__c"?: string;
  "No_Of_Lines__c"?: number;
  "Partner_Case_Number__c"?: string;
  "PgMO_Milestones__c"?: string;
  "PgMO_Projects__c"?: string;
  "Pre_Post_Task_Case__c"?: string;
  "Project__c"?: string;
  "Request_Form_Jobsite_Parent__c"?: string;
  "Request_Form_Receipt__c"?: string;
  "Request_Form_Receipt_Template__c"?: string;
  "Service_Coverage_Type__c"?: string;
  "Service_Dispatch_Priority__c"?: string;
  "Service_Engineer_Expertise_Level__c"?: string;
  "Service_Engineer_Talent_Type__c"?: string;
  "Service_Type__c"?: string;
  "SKU__c"?: string;
  "Task_Request_Receipt_Location__c"?: string;
  "Vendor__c"?: string;
  "Worker__c"?: string;
  "Work_Order__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  account?: Account;
  jobsite?: Jobsite;
  milestone?: Milestone;
  project2?: Project2;
  // prePostTaskCase?: PrePostTaskCase;
  project?: Project;
  parentRequestFormJobsite?: RequestFormJobsite;
  requestFormReceipt?: RequestFormReceipt;
  requestFormReceiptTemplate?: RequestFormReceiptTemplate;
  talentType?: TalentType;
  product?: Product;
  vendor?: Account;
  worker?: Worker;
  workOrder?: WorkOrder;
}

export class RequestFormJobsite implements RequestFormJobsiteInterface {
  "sfdcId": string;
  "CreatedById": string;
  "CurrencyIsoCode": string;
  "LastModifiedById": string;
  "OwnerId": string;
  "Name": string;
  "Account__c": string;
  "Case_Event_Priority_Level__c": string;
  "Case_Summary__c": string;
  "Clone_Task_Appointment_Options__c": string;
  "CreatedBy__c": string;
  "Create_WAR__c": boolean;
  "Customer_Appointment_Date_Time_Schedule__c": Date;
  "Customer_Appointment_Schedule_Start_Date__c": Date;
  "Customer_Appointment_Schedule_Start_Hour__c": string;
  "Customer_Appointment_Schedule_Start_Mi__c": string;
  "Earliest_Start_Date_Permitted__c": boolean;
  "Early_Start__c": Date;
  "IRON_ExtID__c": string;
  "Jobsite__c": string;
  "Jobsite_City__c": string;
  "Jobsite_Company_End_User_or_Ship_To__c": string;
  "Jobsite_Contact_Email_End_User_or_Sh__c": string;
  "Jobsite_Contact_Name_End_User_or_Shi__c": string;
  "Jobsite_Contact_Phone_End_User__c": string;
  "Jobsite_Country__c": string;
  "Jobsite_Postal_Code__c": string;
  "Jobsite_State__c": string;
  "Jobsite_Street__c": string;
  "LastModifiedBy__c": string;
  "Maintenance_Event_Duration_PPM_Hours__c": string;
  "No_Of_Lines__c": number;
  "Partner_Case_Number__c": string;
  "PgMO_Milestones__c": string;
  "PgMO_Projects__c": string;
  "Pre_Post_Task_Case__c": string;
  "Project__c": string;
  "Request_Form_Jobsite_Parent__c": string;
  "Request_Form_Receipt__c": string;
  "Request_Form_Receipt_Template__c": string;
  "Service_Coverage_Type__c": string;
  "Service_Dispatch_Priority__c": string;
  "Service_Engineer_Expertise_Level__c": string;
  "Service_Engineer_Talent_Type__c": string;
  "Service_Type__c": string;
  "SKU__c": string;
  "Task_Request_Receipt_Location__c": string;
  "Vendor__c": string;
  "Worker__c": string;
  "Work_Order__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  account: Account;
  jobsite: Jobsite;
  milestone: Milestone;
  project2: Project2;
  // prePostTaskCase: PrePostTaskCase;
  project: Project;
  parentRequestFormJobsite: RequestFormJobsite;
  requestFormReceipt: RequestFormReceipt;
  requestFormReceiptTemplate: RequestFormReceiptTemplate;
  talentType: TalentType;
  product: Product;
  vendor: Account;
  worker: Worker;
  workOrder: WorkOrder;
  constructor(data?: RequestFormJobsiteInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `RequestFormJobsite`.
   */
  public static getModelName() {
    return "RequestFormJobsite";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of RequestFormJobsite for dynamic purposes.
  **/
  public static factory(data: RequestFormJobsiteInterface): RequestFormJobsite{
    return new RequestFormJobsite(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'RequestFormJobsite',
      plural: 'RequestFormJobsites',
      path: 'RequestFormJobsites',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "Account__c": {
          name: 'Account__c',
          type: 'string'
        },
        "Case_Event_Priority_Level__c": {
          name: 'Case_Event_Priority_Level__c',
          type: 'string'
        },
        "Case_Summary__c": {
          name: 'Case_Summary__c',
          type: 'string'
        },
        "Clone_Task_Appointment_Options__c": {
          name: 'Clone_Task_Appointment_Options__c',
          type: 'string'
        },
        "CreatedBy__c": {
          name: 'CreatedBy__c',
          type: 'string'
        },
        "Create_WAR__c": {
          name: 'Create_WAR__c',
          type: 'boolean'
        },
        "Customer_Appointment_Date_Time_Schedule__c": {
          name: 'Customer_Appointment_Date_Time_Schedule__c',
          type: 'Date'
        },
        "Customer_Appointment_Schedule_Start_Date__c": {
          name: 'Customer_Appointment_Schedule_Start_Date__c',
          type: 'Date'
        },
        "Customer_Appointment_Schedule_Start_Hour__c": {
          name: 'Customer_Appointment_Schedule_Start_Hour__c',
          type: 'string'
        },
        "Customer_Appointment_Schedule_Start_Mi__c": {
          name: 'Customer_Appointment_Schedule_Start_Mi__c',
          type: 'string'
        },
        "Earliest_Start_Date_Permitted__c": {
          name: 'Earliest_Start_Date_Permitted__c',
          type: 'boolean'
        },
        "Early_Start__c": {
          name: 'Early_Start__c',
          type: 'Date'
        },
        "IRON_ExtID__c": {
          name: 'IRON_ExtID__c',
          type: 'string'
        },
        "Jobsite__c": {
          name: 'Jobsite__c',
          type: 'string'
        },
        "Jobsite_City__c": {
          name: 'Jobsite_City__c',
          type: 'string'
        },
        "Jobsite_Company_End_User_or_Ship_To__c": {
          name: 'Jobsite_Company_End_User_or_Ship_To__c',
          type: 'string'
        },
        "Jobsite_Contact_Email_End_User_or_Sh__c": {
          name: 'Jobsite_Contact_Email_End_User_or_Sh__c',
          type: 'string'
        },
        "Jobsite_Contact_Name_End_User_or_Shi__c": {
          name: 'Jobsite_Contact_Name_End_User_or_Shi__c',
          type: 'string'
        },
        "Jobsite_Contact_Phone_End_User__c": {
          name: 'Jobsite_Contact_Phone_End_User__c',
          type: 'string'
        },
        "Jobsite_Country__c": {
          name: 'Jobsite_Country__c',
          type: 'string'
        },
        "Jobsite_Postal_Code__c": {
          name: 'Jobsite_Postal_Code__c',
          type: 'string'
        },
        "Jobsite_State__c": {
          name: 'Jobsite_State__c',
          type: 'string'
        },
        "Jobsite_Street__c": {
          name: 'Jobsite_Street__c',
          type: 'string'
        },
        "LastModifiedBy__c": {
          name: 'LastModifiedBy__c',
          type: 'string'
        },
        "Maintenance_Event_Duration_PPM_Hours__c": {
          name: 'Maintenance_Event_Duration_PPM_Hours__c',
          type: 'string'
        },
        "No_Of_Lines__c": {
          name: 'No_Of_Lines__c',
          type: 'number'
        },
        "Partner_Case_Number__c": {
          name: 'Partner_Case_Number__c',
          type: 'string'
        },
        "PgMO_Milestones__c": {
          name: 'PgMO_Milestones__c',
          type: 'string'
        },
        "PgMO_Projects__c": {
          name: 'PgMO_Projects__c',
          type: 'string'
        },
        "Pre_Post_Task_Case__c": {
          name: 'Pre_Post_Task_Case__c',
          type: 'string'
        },
        "Project__c": {
          name: 'Project__c',
          type: 'string'
        },
        "Request_Form_Jobsite_Parent__c": {
          name: 'Request_Form_Jobsite_Parent__c',
          type: 'string'
        },
        "Request_Form_Receipt__c": {
          name: 'Request_Form_Receipt__c',
          type: 'string'
        },
        "Request_Form_Receipt_Template__c": {
          name: 'Request_Form_Receipt_Template__c',
          type: 'string'
        },
        "Service_Coverage_Type__c": {
          name: 'Service_Coverage_Type__c',
          type: 'string'
        },
        "Service_Dispatch_Priority__c": {
          name: 'Service_Dispatch_Priority__c',
          type: 'string'
        },
        "Service_Engineer_Expertise_Level__c": {
          name: 'Service_Engineer_Expertise_Level__c',
          type: 'string'
        },
        "Service_Engineer_Talent_Type__c": {
          name: 'Service_Engineer_Talent_Type__c',
          type: 'string'
        },
        "Service_Type__c": {
          name: 'Service_Type__c',
          type: 'string'
        },
        "SKU__c": {
          name: 'SKU__c',
          type: 'string'
        },
        "Task_Request_Receipt_Location__c": {
          name: 'Task_Request_Receipt_Location__c',
          type: 'string'
        },
        "Vendor__c": {
          name: 'Vendor__c',
          type: 'string'
        },
        "Worker__c": {
          name: 'Worker__c',
          type: 'string'
        },
        "Work_Order__c": {
          name: 'Work_Order__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'Account__c',
          keyTo: 'sfdcId'
        },
        jobsite: {
          name: 'jobsite',
          type: 'Jobsite',
          model: 'Jobsite',
          relationType: 'belongsTo',
                  keyFrom: 'Jobsite__c',
          keyTo: 'sfdcId'
        },
        milestone: {
          name: 'milestone',
          type: 'Milestone',
          model: 'Milestone',
          relationType: 'belongsTo',
                  keyFrom: 'PgMO_Milestones__c',
          keyTo: 'sfdcId'
        },
        project2: {
          name: 'project2',
          type: 'Project2',
          model: 'Project2',
          relationType: 'belongsTo',
                  keyFrom: 'PgMO_Projects__c',
          keyTo: 'sfdcId'
        },
        prePostTaskCase: {
          name: 'prePostTaskCase',
          type: 'PrePostTaskCase',
          model: 'PrePostTaskCase',
          relationType: 'belongsTo',
                  keyFrom: 'Pre_Post_Task_Case__c',
          keyTo: 'sfdcId'
        },
        project: {
          name: 'project',
          type: 'Project',
          model: 'Project',
          relationType: 'belongsTo',
                  keyFrom: 'Project__c',
          keyTo: 'sfdcId'
        },
        parentRequestFormJobsite: {
          name: 'parentRequestFormJobsite',
          type: 'RequestFormJobsite',
          model: 'RequestFormJobsite',
          relationType: 'belongsTo',
                  keyFrom: 'Request_Form_Jobsite_Parent__c',
          keyTo: 'sfdcId'
        },
        requestFormReceipt: {
          name: 'requestFormReceipt',
          type: 'RequestFormReceipt',
          model: 'RequestFormReceipt',
          relationType: 'belongsTo',
                  keyFrom: 'Request_Form_Receipt__c',
          keyTo: 'sfdcId'
        },
        requestFormReceiptTemplate: {
          name: 'requestFormReceiptTemplate',
          type: 'RequestFormReceiptTemplate',
          model: 'RequestFormReceiptTemplate',
          relationType: 'belongsTo',
                  keyFrom: 'Request_Form_Receipt_Template__c',
          keyTo: 'sfdcId'
        },
        talentType: {
          name: 'talentType',
          type: 'TalentType',
          model: 'TalentType',
          relationType: 'belongsTo',
                  keyFrom: 'Service_Engineer_Talent_Type__c',
          keyTo: 'sfdcId'
        },
        product: {
          name: 'product',
          type: 'Product',
          model: 'Product',
          relationType: 'belongsTo',
                  keyFrom: 'SKU__c',
          keyTo: 'sfdcId'
        },
        vendor: {
          name: 'vendor',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'Vendor__c',
          keyTo: 'sfdcId'
        },
        worker: {
          name: 'worker',
          type: 'Worker',
          model: 'Worker',
          relationType: 'belongsTo',
                  keyFrom: 'Worker__c',
          keyTo: 'sfdcId'
        },
        workOrder: {
          name: 'workOrder',
          type: 'WorkOrder',
          model: 'WorkOrder',
          relationType: 'belongsTo',
                  keyFrom: 'Work_Order__c',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
