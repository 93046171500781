/* tslint:disable */
import {
  Account
} from '../index';

declare var Object: any;
export interface VendorPPEHoursInterface {
  "sfdcId"?: string;
  "Vendor__c"?: string;
  "Vendor_PPE_Hours_For_2NBD__c"?: number;
  "Vendor_PPE_Hours_For_3NBD__c"?: number;
  "Vendor_PPE_Hours_For_5NBD__c"?: number;
  "Vendor_PPE_Hours_For_NBD__c"?: number;
  "Vendor_PPE_Hours_For_SBD_4HR__c"?: number;
  "Vendor_PPE_Hours_For_SBD_6HR__c"?: number;
  "CreatedById"?: string;
  "CurrencyIsoCode"?: string;
  "LastModifiedById"?: string;
  "Name"?: string;
  "id"?: number;
  Account?: Account;
}

export class VendorPPEHours implements VendorPPEHoursInterface {
  "sfdcId": string;
  "Vendor__c": string;
  "Vendor_PPE_Hours_For_2NBD__c": number;
  "Vendor_PPE_Hours_For_3NBD__c": number;
  "Vendor_PPE_Hours_For_5NBD__c": number;
  "Vendor_PPE_Hours_For_NBD__c": number;
  "Vendor_PPE_Hours_For_SBD_4HR__c": number;
  "Vendor_PPE_Hours_For_SBD_6HR__c": number;
  "CreatedById": string;
  "CurrencyIsoCode": string;
  "LastModifiedById": string;
  "Name": string;
  "id": number;
  Account: Account;
  constructor(data?: VendorPPEHoursInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `VendorPPEHours`.
   */
  public static getModelName() {
    return "VendorPPEHours";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of VendorPPEHours for dynamic purposes.
  **/
  public static factory(data: VendorPPEHoursInterface): VendorPPEHours{
    return new VendorPPEHours(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'VendorPPEHours',
      plural: 'VendorPPEHours',
      path: 'VendorPPEHours',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "Vendor__c": {
          name: 'Vendor__c',
          type: 'string'
        },
        "Vendor_PPE_Hours_For_2NBD__c": {
          name: 'Vendor_PPE_Hours_For_2NBD__c',
          type: 'number'
        },
        "Vendor_PPE_Hours_For_3NBD__c": {
          name: 'Vendor_PPE_Hours_For_3NBD__c',
          type: 'number'
        },
        "Vendor_PPE_Hours_For_5NBD__c": {
          name: 'Vendor_PPE_Hours_For_5NBD__c',
          type: 'number'
        },
        "Vendor_PPE_Hours_For_NBD__c": {
          name: 'Vendor_PPE_Hours_For_NBD__c',
          type: 'number'
        },
        "Vendor_PPE_Hours_For_SBD_4HR__c": {
          name: 'Vendor_PPE_Hours_For_SBD_4HR__c',
          type: 'number'
        },
        "Vendor_PPE_Hours_For_SBD_6HR__c": {
          name: 'Vendor_PPE_Hours_For_SBD_6HR__c',
          type: 'number'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        Account: {
          name: 'Account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'Vendor__c',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
