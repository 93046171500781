import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersLookUpComponent } from './users-look-up/users-look-up.component';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule
  ],
  declarations: [UsersLookUpComponent],
  exports: [UsersLookUpComponent],
})
export class UsersLookUpModule { }
