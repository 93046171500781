/* tslint:disable */

declare var Object: any;
export interface TaxonomiesInterface {
  "sfdcId"?: string;
  "LastModifiedById"?: string;
  "Name"?: string;
  "CurrencyIsoCode"?: string;
  "OwnerId"?: string;
  "ts2__ParentId__c"?: string;
  "ts2__ParentId_txt__c"?: string;
  "ts2__Taxonomy_ExternalID__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class Taxonomies implements TaxonomiesInterface {
  "sfdcId": string;
  "LastModifiedById": string;
  "Name": string;
  "CurrencyIsoCode": string;
  "OwnerId": string;
  "ts2__ParentId__c": string;
  "ts2__ParentId_txt__c": string;
  "ts2__Taxonomy_ExternalID__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: TaxonomiesInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Taxonomies`.
   */
  public static getModelName() {
    return "Taxonomies";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Taxonomies for dynamic purposes.
  **/
  public static factory(data: TaxonomiesInterface): Taxonomies{
    return new Taxonomies(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Taxonomies',
      plural: 'Taxonomies',
      path: 'Taxonomies',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "ts2__ParentId__c": {
          name: 'ts2__ParentId__c',
          type: 'string'
        },
        "ts2__ParentId_txt__c": {
          name: 'ts2__ParentId_txt__c',
          type: 'string'
        },
        "ts2__Taxonomy_ExternalID__c": {
          name: 'ts2__Taxonomy_ExternalID__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
