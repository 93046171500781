import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BusinessUnitApi } from 'shared/sdk/services/custom/BusinessUnit';

@Component({
  selector: 'app-business-unit-lookup',
  templateUrl: './business-unit-lookup.component.html',
  styleUrls: ['./business-unit-lookup.component.scss']
})
export class BusinessUnitLookupComponent implements OnInit {

  @Input() set resetData(isClear) {
    this.clearFromParent();
  }
  @Output() selectedBusinessUnit: EventEmitter<any> = new EventEmitter;
  selectedValue;

  businessUnits:any = [];
  @Input() boxedLayout;

  constructor(
    private _BUApi: BusinessUnitApi,
  ) { }

 async ngOnInit() {
    this.businessUnits = await this.getBusinessUnits();
    const preSelected = localStorage.getItem('filterObj');
    if (preSelected && JSON.parse(preSelected) &&
      JSON.parse(preSelected)['businessUnit'] && JSON.parse(preSelected)['businessUnit'].length) {
      this.selectedValue = JSON.parse(preSelected)['businessUnit'];
    }
  }

  onChange() {
    this.selectedBusinessUnit.emit(this.selectedValue);
  }

  clearFromParent() {
    this.selectedValue = null;
  }

  getBusinessUnits(){
    return new Promise((resolve, reject) => {
      const bUnits = []
      const findObj = {
         fields: ['Business_Unit_Name__c','sfdcId','Description__c','Name'],
         order : 'Name ASC'
      }
      this._BUApi.find(findObj).subscribe(results => {
        results.forEach(item => {
          let obj = {
            Name: item['Name'],
            sfdcId: item['sfdcId']
          };
          bUnits.push(obj);
        });
        resolve(bUnits);
      }, error => {
          reject();
      })
  });
  }

}
