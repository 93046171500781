import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequestFormReceiptsModule } from 'shared/components/request-form-receipts/request-form-receipts.module';
import { JobDataCardComponent } from './job-data-card.component';

@NgModule({
  imports: [
    CommonModule,
    RequestFormReceiptsModule
  ],
  declarations: [JobDataCardComponent],
  exports: [JobDataCardComponent],
})
export class JobDataCardModule { }
