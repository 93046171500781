import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { serviceContractCategoryValue } from '../../../models/static-list-data.service';

@Component({
  selector: 'app-service-contract-category-lookup',
  templateUrl: './service-contract-category-lookup.component.html',
  styleUrls: ['./service-contract-category-lookup.component.css']
})
export class ServiceContractCategoryLookupComponent implements OnInit {

  @Input() set resetData(isClear) {
    if (isClear) {
      this.serviceContractCategorySelected = [];
    }
  }
  @Input() placeholder = 'Employee Type';
  // @Input() isdisabled = false;
  @Input() isMultipleSelected = true;
  @Input() viewType = '';
  @Output() getServiceContractCategoryObj: EventEmitter<any> = new EventEmitter;
  serviceContractCategoryList = [];
  serviceContractCategorySelected = [];
  constructor() { }

  ngOnInit() {
    this.serviceContractCategoryList = serviceContractCategoryValue;
    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
    this.serviceContractCategorySelected = (preselected && preselected['serviceContractCategory']) ? preselected['serviceContractCategory'] : '';
  }
  serviceContractCategoryChange() {
    this.getServiceContractCategoryObj.emit(this.serviceContractCategorySelected);
  }

  clearserviceContractCategory() {
    this.serviceContractCategorySelected = [];
    this.getServiceContractCategoryObj.emit([]);
  }

}
