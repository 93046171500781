import { Component, OnInit, Input } from '@angular/core';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-worker-schedule',
  templateUrl: './worker-schedule.component.html',
  styleUrls: ['./worker-schedule.component.css']
})
export class WorkerScheduleComponent implements OnInit {
  @Input() data: any;
  scheduleData: any;
  constructor() { }

  ngOnInit() {
    this.prepareData();
  }

  /* Method to prepare schedule data of all days for a worker */
  prepareData() {
    this.scheduleData = {};
    if (!isNullOrUndefined(this.data) && !isNullOrUndefined(this.data.serviceContract)) {
      this.scheduleData['service_contract_number'] = this.data.serviceContract.ContractNumber;
      this.scheduleData['service_contract_name'] = this.data.serviceContract.Name;
    }
    if (!isNullOrUndefined(this.data) && !isNullOrUndefined(this.data.jobs) && !isNullOrUndefined(this.data.jobs.jobSchedule)) {
      const jobSchedule = this.data.jobs.jobSchedule;
      this.scheduleData['Name'] = jobSchedule.Name;
      this.scheduleData['Status'] = jobSchedule.Status__c;
      this.scheduleData['Total_Weekly_Hours__c'] = jobSchedule.Total_Weekly_Hours__c;
      this.scheduleData['RecordType'] = (jobSchedule.recordType) ? jobSchedule.recordType.Name : '';

      /* prepare Schedule data for Monday */
      this.scheduleData['Monday_Schedule__c'] = jobSchedule.Monday_Schedule__c;
      this.scheduleData['Monday_Hours__c'] = jobSchedule.Monday_Hours__c;
      this.scheduleData['Business_Start_Time__c'] = jobSchedule.Business_Start_Time__c;
      this.scheduleData['Monday_Business_End_Time__c'] = jobSchedule.Monday_Business_End_Time__c;
      this.scheduleData['Is_Holiday_Monday__c'] = jobSchedule.Is_Holiday_Monday__c;

      /* prepare Schedule data for Tuesday */
      this.scheduleData['Tuesday_Schedule__c'] = jobSchedule.Tuesday_Schedule__c;
      this.scheduleData['Tuesday_Hours__c'] = jobSchedule.Tuesday_Hours__c;
      this.scheduleData['Tuesday_Business_Start_Time__c'] = jobSchedule.Tuesday_Business_Start_Time__c;
      this.scheduleData['Tuesday_Business_End_Time__c'] = jobSchedule.Tuesday_Business_End_Time__c;
      this.scheduleData['Is_Holiday_Tuesday__c'] = jobSchedule.Is_Holiday_Tuesday__c;

      /* prepare Schedule data for Wednesday */
      this.scheduleData['Wednesday_Schedule__c'] = jobSchedule.Wednesday_Schedule__c;
      this.scheduleData['Wednesday_Hours__c'] = jobSchedule.Wednesday_Hours__c;
      this.scheduleData['Wednesday_Business_Start_Time__c'] = jobSchedule.Wednesday_Business_Start_Time__c;
      this.scheduleData['Wednesday_Business_End_Time__c'] = jobSchedule.Wednesday_Business_End_Time__c;
      this.scheduleData['Is_Holiday_Wednesday__c'] = jobSchedule.Is_Holiday_Wednesday__c;

      /* prepare Schedule data for Thursday */
      this.scheduleData['Thursday_Schedule__c'] = jobSchedule.Thursday_Schedule__c;
      this.scheduleData['Thursday_Hours__c'] = jobSchedule.Thursday_Hours__c;
      this.scheduleData['Thursday_Business_Start_Time__c'] = jobSchedule.Thursday_Business_Start_Time__c;
      this.scheduleData['Thursday_Business_End_Time__c'] = jobSchedule.Thursday_Business_End_Time__c;
      this.scheduleData['Is_Holiday_Thursday__c'] = jobSchedule.Is_Holiday_Thursday__c;

      /* prepare Schedule data for Friday */
      this.scheduleData['Friday_Schedule__c'] = jobSchedule.Friday_Schedule__c;
      this.scheduleData['Friday_Hours__c'] = jobSchedule.Friday_Hours__c;
      this.scheduleData['Friday_Business_Start_Time__c'] = jobSchedule.Friday_Business_Start_Time__c;
      this.scheduleData['Friday_Business_End_Time__c'] = jobSchedule.Friday_Business_End_Time__c;
      this.scheduleData['Is_Holiday_Friday__c'] = jobSchedule.Is_Holiday_Friday__c;

      /* prepare Schedule data for Saturday */
      this.scheduleData['Saturday_Schedule__c'] = jobSchedule.Saturday_Schedule__c;
      this.scheduleData['Saturday_Hours__c'] = jobSchedule.Saturday_Hours__c;
      this.scheduleData['Saturday_Business_Start_Time__c'] = jobSchedule.Saturday_Business_Start_Time__c;
      this.scheduleData['Saturday_Business_End_Time__c'] = jobSchedule.Saturday_Business_End_Time__c;
      this.scheduleData['Is_Holiday_Saturday__c'] = jobSchedule.Is_Holiday_Saturday__c;

      /* prepare Schedule data for Sunday */
      this.scheduleData['Sunday_Schedule__c'] = jobSchedule.Sunday_Schedule__c;
      this.scheduleData['Sunday_Hours__c'] = jobSchedule.Sunday_Hours__c;
      this.scheduleData['Sunday_Business_Start_Time__c'] = jobSchedule.Sunday_Business_Start_Time__c;
      this.scheduleData['Sunday_Business_End_Time__c'] = jobSchedule.Sunday_Business_End_Time__c;
      this.scheduleData['Is_Holiday_Sunday__c'] = jobSchedule.Is_Holiday_Sunday__c;
    }
  }

}
