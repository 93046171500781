/* tslint:disable */
import {
  Job,
  Account,
  Case,
  Contact,
  WorkOrder
} from '../index';

declare var Object: any;
export interface AppointmentInterface {
  "sfdcId"?: string;
  "Name"?: string;
  "CurrencyIsoCode"?: string;
  "Appointment_Call_or_Delay_Notes__c"?: string;
  "Contact__c"?: string;
  "CreatedById"?: string;
  "CreatedDate"?: Date;
  "Customer_Appointment_Date_Time_Schedule__c"?: Date;
  "Customer_Appointment_DateTime_Scheduled__c"?: Date;
  "Customer_Appointment_Setup_Required__c"?: string;
  "Customer_Appointment_Start_Scheduled__c"?: Date;
  "Customer_Appointment_Start_HRS_Scheduled__c"?: string;
  "Customer_Apptmnt_Start_Minutes_Scheduled__c"?: string;
  "Earliest_Start_Date_Permitted__c"?: boolean;
  "Early_Start__c"?: Date;
  "Email__c"?: string;
  "Expected_Delivery_Date_Time__c"?: Date;
  "Expected_Delivery_Date_Time_Local__c"?: string;
  "FRU_ICC_Case__c"?: string;
  "IsDeleted"?: boolean;
  "ICC_Case__c"?: string;
  "Job__c"?: string;
  "LastModifiedById"?: string;
  "LastModifiedDate"?: Date;
  "LastReferencedDate"?: Date;
  "LastViewedDate"?: Date;
  "Mobile_Phone_Number__c"?: string;
  "Partner__c"?: string;
  "Parts_Arrival_Date_Time_Requested__c"?: Date;
  "Parts_Delivery_Confirmation_Date_Time__c"?: Date;
  "Parts_Delivery_Date_Time_Scheduled_ETA__c"?: Date;
  "Parts_Ship_Confirmation_Date_Time_Shippd__c"?: Date;
  "Phone_Scheduling_1st_Attempt_Unreachable__c"?: Date;
  "Phone_Scheduling_2nd_Attempt_Unreachable__c"?: Date;
  "Phone_Scheduling_3rd_Attempt_Unreachable__c"?: Date;
  "PMS_Case__c"?: string;
  "User_Name__c"?: string;
  "Worker_Departure_Date_Time_Actual__c"?: Date;
  "Worker_Arrival_Date_Time_Actual__c"?: Date;
  "Worker_Arrival_Date_Time_Scheduled_ETA__c"?: Date;
  "Worker_Arrival_Date_Customer_Req_End__c"?: Date;
  "Worker_Arrival_Date_Customer_Requested__c"?: Date;
  "Worker_Arrival_Date_Scheduled__c"?: Date;
  "Worker_Arrival_Date_Local_Time_Zone__c"?: Date;
  "Worker_Arrival_DateTime_Cust_Requested__c"?: Date;
  "Worker_Arrival_DateTime_Scheduled__c"?: Date;
  "Worker_Arrival_Hours_Actual__c"?: string;
  "Worker_Arrival_Hours_Customer_Requested__c"?: string;
  "Worker_End_DateTime_Scheduled__c"?: Date;
  "Worker_Arrival_Minute_Customer_Requested__c"?: string;
  "Worker_Arrival_Minutes_Actual__c"?: string;
  "Worker_Arrival_Time_Hours_Scheduled__c"?: string;
  "Worker_Arrival_Time_Minutes_Scheduled__c"?: string;
  "Worker_End_Date_Scheduled__c"?: Date;
  "Worker_End_Time_Hours_Scheduled__c"?: string;
  "Worker_End_Time_Minutes_Scheduled__c"?: string;
  "Worker_Finish_Date_Time_Actual__c"?: Date;
  "Worker_Finish_Time_Hour_Actual__c"?: string;
  "Worker_Finish_Time_Minutes_Actual__c"?: string;
  "Worker_InProgress_Start_DateTime_Actual__c"?: Date;
  "Worker_In_Progress_Start_Hour_Actual__c"?: string;
  "Worker_In_Progress_Start_Minute_Actual__c"?: string;
  "Work_Order__c"?: string;
  "Job_Order__c"?: string;
  "Vendor__c"?: string;
  "Worker__c"?: string;
  "id"?: number;
  Job?: Job;
  Account?: Account;
  Case?: Case;
  fruICCCase?: Case;
  PMSCase?: Case;
  contact?: Contact;
  JobOrder?: WorkOrder;
  WorkOrder?: WorkOrder;
}

export class Appointment implements AppointmentInterface {
  "sfdcId": string;
  "Name": string;
  "CurrencyIsoCode": string;
  "Appointment_Call_or_Delay_Notes__c": string;
  "Contact__c": string;
  "CreatedById": string;
  "CreatedDate": Date;
  "Customer_Appointment_Date_Time_Schedule__c": Date;
  "Customer_Appointment_DateTime_Scheduled__c": Date;
  "Customer_Appointment_Setup_Required__c": string;
  "Customer_Appointment_Start_Scheduled__c": Date;
  "Customer_Appointment_Start_HRS_Scheduled__c": string;
  "Customer_Apptmnt_Start_Minutes_Scheduled__c": string;
  "Earliest_Start_Date_Permitted__c": boolean;
  "Early_Start__c": Date;
  "Email__c": string;
  "Expected_Delivery_Date_Time__c": Date;
  "Expected_Delivery_Date_Time_Local__c": string;
  "FRU_ICC_Case__c": string;
  "IsDeleted": boolean;
  "ICC_Case__c": string;
  "Job__c": string;
  "LastModifiedById": string;
  "LastModifiedDate": Date;
  "LastReferencedDate": Date;
  "LastViewedDate": Date;
  "Mobile_Phone_Number__c": string;
  "Partner__c": string;
  "Parts_Arrival_Date_Time_Requested__c": Date;
  "Parts_Delivery_Confirmation_Date_Time__c": Date;
  "Parts_Delivery_Date_Time_Scheduled_ETA__c": Date;
  "Parts_Ship_Confirmation_Date_Time_Shippd__c": Date;
  "Phone_Scheduling_1st_Attempt_Unreachable__c": Date;
  "Phone_Scheduling_2nd_Attempt_Unreachable__c": Date;
  "Phone_Scheduling_3rd_Attempt_Unreachable__c": Date;
  "PMS_Case__c": string;
  "User_Name__c": string;
  "Worker_Departure_Date_Time_Actual__c": Date;
  "Worker_Arrival_Date_Time_Actual__c": Date;
  "Worker_Arrival_Date_Time_Scheduled_ETA__c": Date;
  "Worker_Arrival_Date_Customer_Req_End__c": Date;
  "Worker_Arrival_Date_Customer_Requested__c": Date;
  "Worker_Arrival_Date_Scheduled__c": Date;
  "Worker_Arrival_Date_Local_Time_Zone__c": Date;
  "Worker_Arrival_DateTime_Cust_Requested__c": Date;
  "Worker_Arrival_DateTime_Scheduled__c": Date;
  "Worker_Arrival_Hours_Actual__c": string;
  "Worker_Arrival_Hours_Customer_Requested__c": string;
  "Worker_End_DateTime_Scheduled__c": Date;
  "Worker_Arrival_Minute_Customer_Requested__c": string;
  "Worker_Arrival_Minutes_Actual__c": string;
  "Worker_Arrival_Time_Hours_Scheduled__c": string;
  "Worker_Arrival_Time_Minutes_Scheduled__c": string;
  "Worker_End_Date_Scheduled__c": Date;
  "Worker_End_Time_Hours_Scheduled__c": string;
  "Worker_End_Time_Minutes_Scheduled__c": string;
  "Worker_Finish_Date_Time_Actual__c": Date;
  "Worker_Finish_Time_Hour_Actual__c": string;
  "Worker_Finish_Time_Minutes_Actual__c": string;
  "Worker_InProgress_Start_DateTime_Actual__c": Date;
  "Worker_In_Progress_Start_Hour_Actual__c": string;
  "Worker_In_Progress_Start_Minute_Actual__c": string;
  "Work_Order__c": string;
  "Job_Order__c": string;
  "Vendor__c": string;
  "Worker__c": string;
  "id": number;
  Job: Job;
  Account: Account;
  Case: Case;
  fruICCCase: Case;
  PMSCase: Case;
  contact: Contact;
  JobOrder: WorkOrder;
  WorkOrder: WorkOrder;
  constructor(data?: AppointmentInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Appointment`.
   */
  public static getModelName() {
    return "Appointment";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Appointment for dynamic purposes.
  **/
  public static factory(data: AppointmentInterface): Appointment{
    return new Appointment(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Appointment',
      plural: 'Appointments',
      path: 'Appointments',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "Appointment_Call_or_Delay_Notes__c": {
          name: 'Appointment_Call_or_Delay_Notes__c',
          type: 'string'
        },
        "Contact__c": {
          name: 'Contact__c',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "Customer_Appointment_Date_Time_Schedule__c": {
          name: 'Customer_Appointment_Date_Time_Schedule__c',
          type: 'Date'
        },
        "Customer_Appointment_DateTime_Scheduled__c": {
          name: 'Customer_Appointment_DateTime_Scheduled__c',
          type: 'Date'
        },
        "Customer_Appointment_Setup_Required__c": {
          name: 'Customer_Appointment_Setup_Required__c',
          type: 'string'
        },
        "Customer_Appointment_Start_Scheduled__c": {
          name: 'Customer_Appointment_Start_Scheduled__c',
          type: 'Date'
        },
        "Customer_Appointment_Start_HRS_Scheduled__c": {
          name: 'Customer_Appointment_Start_HRS_Scheduled__c',
          type: 'string'
        },
        "Customer_Apptmnt_Start_Minutes_Scheduled__c": {
          name: 'Customer_Apptmnt_Start_Minutes_Scheduled__c',
          type: 'string'
        },
        "Earliest_Start_Date_Permitted__c": {
          name: 'Earliest_Start_Date_Permitted__c',
          type: 'boolean',
          default: false
        },
        "Early_Start__c": {
          name: 'Early_Start__c',
          type: 'Date'
        },
        "Email__c": {
          name: 'Email__c',
          type: 'string'
        },
        "Expected_Delivery_Date_Time__c": {
          name: 'Expected_Delivery_Date_Time__c',
          type: 'Date'
        },
        "Expected_Delivery_Date_Time_Local__c": {
          name: 'Expected_Delivery_Date_Time_Local__c',
          type: 'string'
        },
        "FRU_ICC_Case__c": {
          name: 'FRU_ICC_Case__c',
          type: 'string'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "ICC_Case__c": {
          name: 'ICC_Case__c',
          type: 'string'
        },
        "Job__c": {
          name: 'Job__c',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "LastReferencedDate": {
          name: 'LastReferencedDate',
          type: 'Date'
        },
        "LastViewedDate": {
          name: 'LastViewedDate',
          type: 'Date'
        },
        "Mobile_Phone_Number__c": {
          name: 'Mobile_Phone_Number__c',
          type: 'string'
        },
        "Partner__c": {
          name: 'Partner__c',
          type: 'string'
        },
        "Parts_Arrival_Date_Time_Requested__c": {
          name: 'Parts_Arrival_Date_Time_Requested__c',
          type: 'Date'
        },
        "Parts_Delivery_Confirmation_Date_Time__c": {
          name: 'Parts_Delivery_Confirmation_Date_Time__c',
          type: 'Date'
        },
        "Parts_Delivery_Date_Time_Scheduled_ETA__c": {
          name: 'Parts_Delivery_Date_Time_Scheduled_ETA__c',
          type: 'Date'
        },
        "Parts_Ship_Confirmation_Date_Time_Shippd__c": {
          name: 'Parts_Ship_Confirmation_Date_Time_Shippd__c',
          type: 'Date'
        },
        "Phone_Scheduling_1st_Attempt_Unreachable__c": {
          name: 'Phone_Scheduling_1st_Attempt_Unreachable__c',
          type: 'Date'
        },
        "Phone_Scheduling_2nd_Attempt_Unreachable__c": {
          name: 'Phone_Scheduling_2nd_Attempt_Unreachable__c',
          type: 'Date'
        },
        "Phone_Scheduling_3rd_Attempt_Unreachable__c": {
          name: 'Phone_Scheduling_3rd_Attempt_Unreachable__c',
          type: 'Date'
        },
        "PMS_Case__c": {
          name: 'PMS_Case__c',
          type: 'string'
        },
        "User_Name__c": {
          name: 'User_Name__c',
          type: 'string'
        },
        "Worker_Departure_Date_Time_Actual__c": {
          name: 'Worker_Departure_Date_Time_Actual__c',
          type: 'Date'
        },
        "Worker_Arrival_Date_Time_Actual__c": {
          name: 'Worker_Arrival_Date_Time_Actual__c',
          type: 'Date'
        },
        "Worker_Arrival_Date_Time_Scheduled_ETA__c": {
          name: 'Worker_Arrival_Date_Time_Scheduled_ETA__c',
          type: 'Date'
        },
        "Worker_Arrival_Date_Customer_Req_End__c": {
          name: 'Worker_Arrival_Date_Customer_Req_End__c',
          type: 'Date'
        },
        "Worker_Arrival_Date_Customer_Requested__c": {
          name: 'Worker_Arrival_Date_Customer_Requested__c',
          type: 'Date'
        },
        "Worker_Arrival_Date_Scheduled__c": {
          name: 'Worker_Arrival_Date_Scheduled__c',
          type: 'Date'
        },
        "Worker_Arrival_Date_Local_Time_Zone__c": {
          name: 'Worker_Arrival_Date_Local_Time_Zone__c',
          type: 'Date'
        },
        "Worker_Arrival_DateTime_Cust_Requested__c": {
          name: 'Worker_Arrival_DateTime_Cust_Requested__c',
          type: 'Date'
        },
        "Worker_Arrival_DateTime_Scheduled__c": {
          name: 'Worker_Arrival_DateTime_Scheduled__c',
          type: 'Date'
        },
        "Worker_Arrival_Hours_Actual__c": {
          name: 'Worker_Arrival_Hours_Actual__c',
          type: 'string'
        },
        "Worker_Arrival_Hours_Customer_Requested__c": {
          name: 'Worker_Arrival_Hours_Customer_Requested__c',
          type: 'string'
        },
        "Worker_End_DateTime_Scheduled__c": {
          name: 'Worker_End_DateTime_Scheduled__c',
          type: 'Date'
        },
        "Worker_Arrival_Minute_Customer_Requested__c": {
          name: 'Worker_Arrival_Minute_Customer_Requested__c',
          type: 'string'
        },
        "Worker_Arrival_Minutes_Actual__c": {
          name: 'Worker_Arrival_Minutes_Actual__c',
          type: 'string'
        },
        "Worker_Arrival_Time_Hours_Scheduled__c": {
          name: 'Worker_Arrival_Time_Hours_Scheduled__c',
          type: 'string'
        },
        "Worker_Arrival_Time_Minutes_Scheduled__c": {
          name: 'Worker_Arrival_Time_Minutes_Scheduled__c',
          type: 'string'
        },
        "Worker_End_Date_Scheduled__c": {
          name: 'Worker_End_Date_Scheduled__c',
          type: 'Date'
        },
        "Worker_End_Time_Hours_Scheduled__c": {
          name: 'Worker_End_Time_Hours_Scheduled__c',
          type: 'string'
        },
        "Worker_End_Time_Minutes_Scheduled__c": {
          name: 'Worker_End_Time_Minutes_Scheduled__c',
          type: 'string'
        },
        "Worker_Finish_Date_Time_Actual__c": {
          name: 'Worker_Finish_Date_Time_Actual__c',
          type: 'Date'
        },
        "Worker_Finish_Time_Hour_Actual__c": {
          name: 'Worker_Finish_Time_Hour_Actual__c',
          type: 'string'
        },
        "Worker_Finish_Time_Minutes_Actual__c": {
          name: 'Worker_Finish_Time_Minutes_Actual__c',
          type: 'string'
        },
        "Worker_InProgress_Start_DateTime_Actual__c": {
          name: 'Worker_InProgress_Start_DateTime_Actual__c',
          type: 'Date'
        },
        "Worker_In_Progress_Start_Hour_Actual__c": {
          name: 'Worker_In_Progress_Start_Hour_Actual__c',
          type: 'string'
        },
        "Worker_In_Progress_Start_Minute_Actual__c": {
          name: 'Worker_In_Progress_Start_Minute_Actual__c',
          type: 'string'
        },
        "Work_Order__c": {
          name: 'Work_Order__c',
          type: 'string'
        },
        "Job_Order__c": {
          name: 'Job_Order__c',
          type: 'string'
        },
        "Vendor__c": {
          name: 'Vendor__c',
          type: 'string'
        },
        "Worker__c": {
          name: 'Worker__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        Job: {
          name: 'Job',
          type: 'Job',
          model: 'Job',
          relationType: 'belongsTo',
                  keyFrom: 'Job__c',
          keyTo: 'sfdcId'
        },
        Account: {
          name: 'Account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'Partner__c',
          keyTo: 'sfdcId'
        },
        Case: {
          name: 'Case',
          type: 'Case',
          model: 'Case',
          relationType: 'belongsTo',
                  keyFrom: 'ICC_Case__c',
          keyTo: 'sfdcId'
        },
        fruICCCase: {
          name: 'fruICCCase',
          type: 'Case',
          model: 'Case',
          relationType: 'belongsTo',
                  keyFrom: 'FRU_ICC_Case__c',
          keyTo: 'sfdcId'
        },
        PMSCase: {
          name: 'PMSCase',
          type: 'Case',
          model: 'Case',
          relationType: 'belongsTo',
                  keyFrom: 'PMS_Case__c',
          keyTo: 'sfdcId'
        },
        contact: {
          name: 'contact',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Contact__c',
          keyTo: 'sfdcId'
        },
        JobOrder: {
          name: 'JobOrder',
          type: 'WorkOrder',
          model: 'WorkOrder',
          relationType: 'belongsTo',
                  keyFrom: 'Job_Order__c',
          keyTo: 'sfdcId'
        },
        WorkOrder: {
          name: 'WorkOrder',
          type: 'WorkOrder',
          model: 'WorkOrder',
          relationType: 'belongsTo',
                  keyFrom: 'Work_Order__c',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
