/* tslint:disable */
import {
  Project,
  Case,
  Users
} from '../index';

declare var Object: any;
export interface EdiTransactionInterface {
  "AccountId": string;
  "ProgramId": string;
  "AccountName": string;
  "Type": string;
  "Message": string;
  "RequestDataRaw"?: string;
  "RequestDataBase64"?: string;
  "ResortTicketNumber"?: string;
  "IsTransactionAcceptable"?: boolean;
  "ValidatedBy"?: number;
  "ReattemptCount"?: number;
  "Retries"?: string;
  "Request_Form_Receipt__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  program?: Project;
  case?: Case;
  users?: Users;
}

export class EdiTransaction implements EdiTransactionInterface {
  "AccountId": string;
  "ProgramId": string;
  "AccountName": string;
  "Type": string;
  "Message": string;
  "RequestDataRaw": string;
  "RequestDataBase64": string;
  "ResortTicketNumber": string;
  "IsTransactionAcceptable": boolean;
  "ValidatedBy": number;
  "ReattemptCount": number;
  "Retries": string;
  "Request_Form_Receipt__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  program: Project;
  case: Case;
  users: Users;
  constructor(data?: EdiTransactionInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `EdiTransaction`.
   */
  public static getModelName() {
    return "EdiTransaction";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of EdiTransaction for dynamic purposes.
  **/
  public static factory(data: EdiTransactionInterface): EdiTransaction{
    return new EdiTransaction(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'EdiTransaction',
      plural: 'EdiTransactions',
      path: 'EdiTransactions',
      idName: 'id',
      properties: {
        "AccountId": {
          name: 'AccountId',
          type: 'string'
        },
        "ProgramId": {
          name: 'ProgramId',
          type: 'string'
        },
        "AccountName": {
          name: 'AccountName',
          type: 'string'
        },
        "Type": {
          name: 'Type',
          type: 'string'
        },
        "Message": {
          name: 'Message',
          type: 'string'
        },
        "RequestDataRaw": {
          name: 'RequestDataRaw',
          type: 'string'
        },
        "RequestDataBase64": {
          name: 'RequestDataBase64',
          type: 'string'
        },
        "ResortTicketNumber": {
          name: 'ResortTicketNumber',
          type: 'string'
        },
        "IsTransactionAcceptable": {
          name: 'IsTransactionAcceptable',
          type: 'boolean',
          default: false
        },
        "ValidatedBy": {
          name: 'ValidatedBy',
          type: 'number'
        },
        "ReattemptCount": {
          name: 'ReattemptCount',
          type: 'number'
        },
        "Retries": {
          name: 'Retries',
          type: 'string'
        },
        "Request_Form_Receipt__c": {
          name: 'Request_Form_Receipt__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        program: {
          name: 'program',
          type: 'Project',
          model: 'Project',
          relationType: 'belongsTo',
                  keyFrom: 'ProgramId',
          keyTo: 'sfdcId'
        },
        case: {
          name: 'case',
          type: 'Case',
          model: 'Case',
          relationType: 'belongsTo',
                  keyFrom: 'ResortTicketNumber',
          keyTo: 'Partner_Case_Number__c'
        },
        users: {
          name: 'users',
          type: 'Users',
          model: 'Users',
          relationType: 'belongsTo',
                  keyFrom: 'ValidatedBy',
          keyTo: 'id'
        },
      }
    }
  }
}
