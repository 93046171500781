/* tslint:disable */
import {
  Account,
  Worker,
  Group
} from '../index';

declare var Object: any;
export interface ChangeAssignedToVendorWorkerInterface {
  "sfdcId"?: string;
  "CreatedById"?: string;
  "CurrencyIsoCode"?: string;
  "LastModifiedById"?: string;
  "OwnerId"?: string;
  "Name"?: string;
  "Community__c"?: string;
  "Internal_Account__c"?: string;
  "Partner_Account__c"?: string;
  "PgMO_Tasks__c"?: string;
  "Vendor_Account__c"?: string;
  "Worker__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  internalAccount?: Account;
  partnerAccount?: Account;
  vendorAccount?: Account;
  worker?: Worker;
  task?: Group;
}

export class ChangeAssignedToVendorWorker implements ChangeAssignedToVendorWorkerInterface {
  "sfdcId": string;
  "CreatedById": string;
  "CurrencyIsoCode": string;
  "LastModifiedById": string;
  "OwnerId": string;
  "Name": string;
  "Community__c": string;
  "Internal_Account__c": string;
  "Partner_Account__c": string;
  "PgMO_Tasks__c": string;
  "Vendor_Account__c": string;
  "Worker__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  internalAccount: Account;
  partnerAccount: Account;
  vendorAccount: Account;
  worker: Worker;
  task: Group;
  constructor(data?: ChangeAssignedToVendorWorkerInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ChangeAssignedToVendorWorker`.
   */
  public static getModelName() {
    return "ChangeAssignedToVendorWorker";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ChangeAssignedToVendorWorker for dynamic purposes.
  **/
  public static factory(data: ChangeAssignedToVendorWorkerInterface): ChangeAssignedToVendorWorker{
    return new ChangeAssignedToVendorWorker(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ChangeAssignedToVendorWorker',
      plural: 'ChangeAssignedToVendorWorkers',
      path: 'ChangeAssignedToVendorWorkers',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "Community__c": {
          name: 'Community__c',
          type: 'string'
        },
        "Internal_Account__c": {
          name: 'Internal_Account__c',
          type: 'string'
        },
        "Partner_Account__c": {
          name: 'Partner_Account__c',
          type: 'string'
        },
        "PgMO_Tasks__c": {
          name: 'PgMO_Tasks__c',
          type: 'string'
        },
        "Vendor_Account__c": {
          name: 'Vendor_Account__c',
          type: 'string'
        },
        "Worker__c": {
          name: 'Worker__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        internalAccount: {
          name: 'internalAccount',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'Internal_Account__c',
          keyTo: 'sfdcId'
        },
        partnerAccount: {
          name: 'partnerAccount',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'Partner_Account__c',
          keyTo: 'sfdcId'
        },
        vendorAccount: {
          name: 'vendorAccount',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'Vendor_Account__c',
          keyTo: 'sfdcId'
        },
        worker: {
          name: 'worker',
          type: 'Worker',
          model: 'Worker',
          relationType: 'belongsTo',
                  keyFrom: 'Worker__c',
          keyTo: 'sfdcId'
        },
        task: {
          name: 'task',
          type: 'Group',
          model: 'Group',
          relationType: 'belongsTo',
                  keyFrom: 'PgMO_Tasks__c',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
