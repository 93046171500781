/* tslint:disable */
import {
  GeoMetro,
  GeoPoint
} from '../index';

declare var Object: any;
export interface AddressInterface {
  "sfdcId"?: string;
  "AddressType"?: string;
  "Description"?: string;
  "DrivingDirections"?: string;
  "LocationType"?: string;
  "ParentId"?: string;
  "TimeZone"?: string;
  "Contact__c"?: string;
  "Geo_Metro__c"?: string;
  "Geo_location__c"?: GeoPoint;
  "Geo_location__Latitude__s"?: number;
  "Geo_location__Longitude__s"?: number;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  geoMetro?: GeoMetro;
}

export class Address implements AddressInterface {
  "sfdcId": string;
  "AddressType": string;
  "Description": string;
  "DrivingDirections": string;
  "LocationType": string;
  "ParentId": string;
  "TimeZone": string;
  "Contact__c": string;
  "Geo_Metro__c": string;
  "Geo_location__c": GeoPoint;
  "Geo_location__Latitude__s": number;
  "Geo_location__Longitude__s": number;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  geoMetro: GeoMetro;
  constructor(data?: AddressInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Address`.
   */
  public static getModelName() {
    return "Address";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Address for dynamic purposes.
  **/
  public static factory(data: AddressInterface): Address{
    return new Address(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Address',
      plural: 'Addresses',
      path: 'Addresses',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "AddressType": {
          name: 'AddressType',
          type: 'string'
        },
        "Description": {
          name: 'Description',
          type: 'string'
        },
        "DrivingDirections": {
          name: 'DrivingDirections',
          type: 'string'
        },
        "LocationType": {
          name: 'LocationType',
          type: 'string'
        },
        "ParentId": {
          name: 'ParentId',
          type: 'string'
        },
        "TimeZone": {
          name: 'TimeZone',
          type: 'string'
        },
        "Contact__c": {
          name: 'Contact__c',
          type: 'string'
        },
        "Geo_Metro__c": {
          name: 'Geo_Metro__c',
          type: 'string'
        },
        "Geo_location__c": {
          name: 'Geo_location__c',
          type: 'GeoPoint'
        },
        "Geo_location__Latitude__s": {
          name: 'Geo_location__Latitude__s',
          type: 'number'
        },
        "Geo_location__Longitude__s": {
          name: 'Geo_location__Longitude__s',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        geoMetro: {
          name: 'geoMetro',
          type: 'GeoMetro',
          model: 'GeoMetro',
          relationType: 'belongsTo',
                  keyFrom: 'Geo_Metro__c',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
