/* tslint:disable */
import {
  Taxonomies
} from '../index';

declare var Object: any;
export interface TaxonomySkillsInterface {
  "sfdcId"?: string;
  "LastModifiedById"?: string;
  "Name"?: string;
  "CurrencyIsoCode"?: string;
  "ts2__ParentSkillId__c"?: string;
  "ts2__ParentSkillId_txt__c"?: string;
  "ts2__SkillsExternalID__c"?: string;
  "ts2__Skills_ExternalID__c"?: string;
  "ts2__TaxonomyId__c"?: string;
  "ts2__TaxonomyId_txt__c"?: string;
  "ts2__Term__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  taxanomies?: Taxonomies;
}

export class TaxonomySkills implements TaxonomySkillsInterface {
  "sfdcId": string;
  "LastModifiedById": string;
  "Name": string;
  "CurrencyIsoCode": string;
  "ts2__ParentSkillId__c": string;
  "ts2__ParentSkillId_txt__c": string;
  "ts2__SkillsExternalID__c": string;
  "ts2__Skills_ExternalID__c": string;
  "ts2__TaxonomyId__c": string;
  "ts2__TaxonomyId_txt__c": string;
  "ts2__Term__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  taxanomies: Taxonomies;
  constructor(data?: TaxonomySkillsInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `TaxonomySkills`.
   */
  public static getModelName() {
    return "TaxonomySkills";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of TaxonomySkills for dynamic purposes.
  **/
  public static factory(data: TaxonomySkillsInterface): TaxonomySkills{
    return new TaxonomySkills(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'TaxonomySkills',
      plural: 'TaxonomySkills',
      path: 'TaxonomySkills',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "ts2__ParentSkillId__c": {
          name: 'ts2__ParentSkillId__c',
          type: 'string'
        },
        "ts2__ParentSkillId_txt__c": {
          name: 'ts2__ParentSkillId_txt__c',
          type: 'string'
        },
        "ts2__SkillsExternalID__c": {
          name: 'ts2__SkillsExternalID__c',
          type: 'string'
        },
        "ts2__Skills_ExternalID__c": {
          name: 'ts2__Skills_ExternalID__c',
          type: 'string'
        },
        "ts2__TaxonomyId__c": {
          name: 'ts2__TaxonomyId__c',
          type: 'string'
        },
        "ts2__TaxonomyId_txt__c": {
          name: 'ts2__TaxonomyId_txt__c',
          type: 'string'
        },
        "ts2__Term__c": {
          name: 'ts2__Term__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        taxanomies: {
          name: 'taxanomies',
          type: 'Taxonomies',
          model: 'Taxonomies',
          relationType: 'belongsTo',
                  keyFrom: 'ts2__TaxonomyId__c',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
