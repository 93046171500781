/* tslint:disable */

declare var Object: any;
export interface FeedbackInterface {
  "userId"?: string;
  "userName"?: string;
  "userEmail"?: string;
  "userType"?: string;
  "categoryId"?: string;
  "content"?: string;
  "platform"?: string;
  "location"?: string;
  "appVersion"?: string;
  "status"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class Feedback implements FeedbackInterface {
  "userId": string;
  "userName": string;
  "userEmail": string;
  "userType": string;
  "categoryId": string;
  "content": string;
  "platform": string;
  "location": string;
  "appVersion": string;
  "status": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: FeedbackInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Feedback`.
   */
  public static getModelName() {
    return "Feedback";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Feedback for dynamic purposes.
  **/
  public static factory(data: FeedbackInterface): Feedback{
    return new Feedback(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Feedback',
      plural: 'Feedbacks',
      path: 'Feedbacks',
      idName: 'id',
      properties: {
        "userId": {
          name: 'userId',
          type: 'string'
        },
        "userName": {
          name: 'userName',
          type: 'string'
        },
        "userEmail": {
          name: 'userEmail',
          type: 'string'
        },
        "userType": {
          name: 'userType',
          type: 'string'
        },
        "categoryId": {
          name: 'categoryId',
          type: 'string'
        },
        "content": {
          name: 'content',
          type: 'string'
        },
        "platform": {
          name: 'platform',
          type: 'string'
        },
        "location": {
          name: 'location',
          type: 'string'
        },
        "appVersion": {
          name: 'appVersion',
          type: 'string'
        },
        "status": {
          name: 'status',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
