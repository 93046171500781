import { WorkerTrackerMapModule } from './../worker-tracker-map/worker-tracker-map.module';
import { JobsiteRouteMapModule } from './../jobsite-routemap/jobsite-routemap.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkerTripDetailsSidebarComponent } from './worker-trip-details-sidebar.component';

@NgModule({
  imports: [
    CommonModule,
    JobsiteRouteMapModule,
    WorkerTrackerMapModule
  ],
  exports: [WorkerTripDetailsSidebarComponent],
  declarations: [WorkerTripDetailsSidebarComponent]
})
export class WorkerTripDetailsSidebarModule { }
